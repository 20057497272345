/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo, useContext, useState, useCallback, FC, useEffect } from 'react'

import { PanelContextValueType } from 'utils/types'

const PanelContext = React.createContext<PanelContextValueType>({
  closePanel: () => {},
  isPanelOpen: false,
  openPanel: () => {},
  panelContent: {},
  setPanelContent: () => {},
  setPanelHeader: () => {},
})

const PanelProvider: FC<{ children: React.ReactNode }> = memo(({ children }) => {
  const [isPanelOpen, setIsPanelVisible] = useState(false)
  const [panelContent, setPanelContent] = useState<{ header?: string, content?: any, isBig?: boolean }>()

  const closePanel = useCallback(() => {
    const event = new CustomEvent('closePanel', { cancelable: true })
    document.dispatchEvent(event)
    if (!event.defaultPrevented) {
      setIsPanelVisible(false)
    }
  }, [])

  const openPanel = useCallback(() => setIsPanelVisible(true), [])

  const handleEscPress = useCallback((e) => {
    if (e.key === 'Escape') closePanel()
  }, [])

  const setPanelHeader = (newHeader: string) => {
    if (newHeader !== panelContent?.header) setPanelContent({ ...panelContent, header: newHeader })
  }

  useEffect(() => {
    if (isPanelOpen) {
      window.addEventListener('keydown', handleEscPress)
    }
    return () => {
      window.removeEventListener('keydown', handleEscPress)
    }
  }, [isPanelOpen])

  return (
    <PanelContext.Provider
      value={{
        closePanel,
        isPanelOpen,
        openPanel,
        panelContent,
        setPanelContent,
        setPanelHeader,
      }}>
      {children}
    </PanelContext.Provider>
  )
})

PanelProvider.displayName = 'Panel context'

const usePanel = () => useContext(PanelContext)

export { PanelProvider, usePanel }
