import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import last from 'lodash/last'

import SettingsDropdown from 'components/SettingsDropdown'
import { AddIcon, FilterIcon, TrashIcon } from 'components/Icons'

import { fetchRecordsByParams, removeFilterById, useFilters } from 'features/record'
import { clearFilters } from 'features/record'

import MyRecordsFilter from 'components/RecordFilters/MyRecordsFilter'
import { ListType } from '../../utils/types'
import { fetchListsByStationId } from '../../features/list'
import { clearCustomListsFiltersOptions, updateCustomListsFiltersOptions } from '../../features/station/stationSlice'
import { useAppDispatch } from '../../store'
import SingleFilter from './SingleFilter'
import PriorityFilter from './PriorityFilter'
import HealthFilter from './HealthFilter'

const RecordFilters: FC<{
  isCustomWidget?: boolean,
  activeFilter?: boolean,
  selectedElements?: ListType[],
  showBasics?: boolean,
  onClose?: () => void,
}> = ({ isCustomWidget = false, selectedElements, activeFilter, showBasics, onClose }) => {
  const [arrayOfFilters, setArrayOfFilters] = useState<number[]>([])
  const [isLastRemoved, setIsLastRemoved] = useState(false)
  const { viewId, listId }: { listId: string, viewType: string, viewId: string } = useParams()
  const allFilters = useFilters()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('records')
  const { pathname } = useLocation()

  // TODO: When we add custom views, this should not fetch unless the viewtypeid has changed
  useEffect(() => {
    if ((allFilters.length || isLastRemoved) && !isCustomWidget && listId) {
      setIsLastRemoved(false)
      dispatch(
        fetchRecordsByParams({
          listId,
          queryParams: allFilters,
          viewId,
        }),
      )
    }
  }, [allFilters, viewId, isCustomWidget])

  useEffect(() => {
    if (allFilters.length > arrayOfFilters.length) {
      const arrayOfNumbers = Array.from(Array(allFilters.length).keys()).map((index) => index + 1)
      setArrayOfFilters(arrayOfNumbers)
    }
  }, [allFilters])

  useEffect(() => {
    if (!showBasics) {
      dispatch(clearFilters())
      setArrayOfFilters([])
    }
  }, [pathname])

  useEffect(() => {
    if (isCustomWidget && !showBasics) {
      const listsIds = selectedElements?.map((list: ListType) => +list?.legacyId)
      appDispatch(fetchListsByStationId({ archiveStatus: 0, stationId: selectedElements[0]?.id })).then((res) => {
        const selectedLists = res?.payload?.filter((l: ListType) => listsIds.includes(l.id))
        selectedLists.map((lst: ListType) => {
          const owners = lst.listMembers.map((user) => ({
            label: user.firstName + ' ' + user.lastName,
            value: user.id,
          }))
          dispatch(updateCustomListsFiltersOptions({ labels: lst.labels, owners, statuses: lst.statuses }))
        })
      })
    }
    return () => {
      dispatch(clearCustomListsFiltersOptions())
    }
  }, [isCustomWidget])

  const removeFilter = (filterItem: [], filterIndex: number) => {
    if (filterItem && allFilters.length > 0) {
      dispatch(removeFilterById(+filterItem[2]))
    }
    const newFiltersArray = arrayOfFilters.filter((index, i) => index !== filterIndex)
    setArrayOfFilters(newFiltersArray)
    if (newFiltersArray.length === 0) {
      setIsLastRemoved(true)
    }
  }

  const FilterButton = () => {
    const filtersLength = allFilters.length
    const text = filtersLength === 0 ? t('filter') : t('filterSelected', { count: filtersLength })
    return (
      <div
        className={classnames('flex items-center p-2 hover:text-primary', {
          'bg-tertiary-light/5 text-tertiary-light rounded-sm': filtersLength > 0,
        })}>
        <FilterIcon className="w-5 me-1 stroke-2" />
        {text}
      </div>
    )
  }

  return (
    <SettingsDropdown
      ButtonChildren={FilterButton}
      childClasses="w-96 rounded-ts-none"
      isClickDisabled={isCustomWidget && selectedElements?.length < 2}
      isCustomWidget={isCustomWidget}
      onClose={onClose}
      wrapperClasses="">
      <MyRecordsFilter />
      <HealthFilter />
      <PriorityFilter />
      {arrayOfFilters.map((filterIndex, index) => {
        return (
          <div key={filterIndex} className="flex justify-between w-full py-2 mb-2 border-b-2 border-gray-100">
            <SingleFilter
              showBasics={showBasics}
              filterDetails={allFilters[index]}
              filterIndex={filterIndex}
              isCustomWidget={isCustomWidget}
              selectedElements={selectedElements}
            />
            <button
              className="hover:text-danger transition transition-colors"
              onClick={() => removeFilter(allFilters[index], filterIndex)}>
              <TrashIcon className="w-4 ms-2" />
            </button>
          </div>
        )
      })}
      <button
        className="flex items-center py-2 hover:text-primary text-start transition transition-colors"
        onClick={() => {
          const lastElement = last(allFilters) || [0, 0, 0]
          if (!last(allFilters) && arrayOfFilters.length > 0) {
            const lastIndex = last(arrayOfFilters)
            setArrayOfFilters([...arrayOfFilters, +lastIndex + 1])
          } else {
            setArrayOfFilters([...arrayOfFilters, +lastElement[2] + 1])
          }
        }}>
        <AddIcon className="w-5 md:w-6 me-1" />
        {t('addFilter')}
      </button>
    </SettingsDropdown>
  )
}

export default RecordFilters
