import React, { FC, KeyboardEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import Button, { ButtonVariant } from 'components/Buttons'
import { AddIcon } from 'components/Icons'
import Input from 'components/inputs/Input'
import { addRecordValidationSchema } from 'components/forms/validationSchemas'
import { withEditorRole } from 'hocs/withRole'

const AddCard: FC<{ addCard: (name: string) => void }> = ({ addCard, isCardAdding, setIsCardAdding }) => {
  // const [isCardAdding, setIsCardAdding] = useState<boolean>(false)
  const { t } = useTranslation('records')
  const inputRef = useRef<any>()

  const { errors, values, touched, setTouched, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => {
      handleAddCard(values.name)
      resetForm()
    },
    validationSchema: addRecordValidationSchema,
  })

  const handleAddCard = (value: string) => {
    addCard(value)
    setIsCardAdding(false)
  }

  const onBlur = () => {
    handleSubmit()
    setIsCardAdding(false)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key }: { key: string } = event
    if (key === 'Enter') handleSubmit()

    if (key === 'Escape') {
      resetForm()
      setIsCardAdding(false)
    }
  }

  return (
    <>
      {isCardAdding ? (
        <Input
          autoFocus
          error={errors.name}
          name="name"
          ref={inputRef}
          touched={touched.name}
          value={values.name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={() => setTouched({ name: true }, true)}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Button icon={AddIcon} small variant={ButtonVariant.Ghost} onClick={() => setIsCardAdding(true)}>
          {t('addCard')}
        </Button>
      )}
    </>
  )
}

export default withEditorRole(AddCard, false, null)
