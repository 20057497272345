import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { LinkButtonPropsType } from 'utils/types'
import { ButtonVariant, variants } from './Button'

const LinkButton: FC<LinkButtonPropsType> = ({ small, full, icon, className, variant, children, ...rest }) => {
  const baseLinkClasses = `flex items-center font-bold text-center transition-colors focus:outline-none focus-visible:shadow-focus ${
    small ? 'px-2 py-1.5 text-sm rounded-sm' : 'px-6 py-3 text-base rounded'
  } `
  return (
    <Link
      className={classnames(variants[variant || ButtonVariant.Primary], className, {
        [baseLinkClasses]: variant !== ButtonVariant.Icon,
        'w-full justify-center': full,
      })}
      {...rest}>
      {children}
    </Link>
  )
}

export default LinkButton
