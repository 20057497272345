import { createAsyncThunk } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import forEach from 'lodash/forEach'

import baseAPI, { customController } from 'utils/baseAPI'
import { ListType, ListStatusType, ListLabelType } from 'utils/types'
import { getElementLinkFromPath } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { newCustomField } from 'utils/constant/constant/customFields'
import { FormulaType } from '../../utils/types/FormulaType'
import { archiveElement, onMoveElement } from '../element'

export const fetchListById = createAsyncThunk(
  'list/fetchListById',
  async (listId: string | number, { rejectWithValue }) => {
    customController.abortAllControllersByType('fetchListById')
    const options = { requestType: 'fetchListById' }
    const response = await baseAPI(`api/lists/${listId}`, options)
    if (+response.status === 404) return rejectWithValue('cancelled')
    if (+response.status >= 400) return rejectWithValue(response.message)
    return response.body
  },
)

export const fetchListsByStationId = createAsyncThunk(
  'list/fetchListsByStationId',
  async (props: { stationId: number | string, archiveStatus: number }, { rejectWithValue }) => {
    const { stationId, archiveStatus } = props
    const response = await baseAPI(`api/lists/station/${stationId}?arc=${archiveStatus}`)
    if (+response.status >= 400) return rejectWithValue(404)
    return response.body
  },
)

export const addList = createAsyncThunk(
  'list/addList',
  async (
    {
      list,
      history,
    }: { list: { name: string, description?: string, stationId: string }, history: RouteComponentProps['history'] },
    { rejectWithValue },
  ) => {
    try {
      const response = await baseAPI('api/lists', { body: list, method: 'POST' })
      if (+response.status >= 400) throw response.message

      const url = getElementLinkFromPath(response.body.appElement.legacyPath, AppElementBaseTypes.ListAppElement)
      history.push(url)
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateList = createAsyncThunk(
  'list/updateList',
  async ({ list, changes }: { list: ListType, changes: Partial<ListType> }) => {
    const response = await baseAPI(`api/lists/${list.id}`, {
      body: { ...list, ...changes },
      method: 'PUT',
    })
    return { list: response.body, listDetails: list }
  },
)

export const removeListById = createAsyncThunk(
  'list/removeListById',
  async ({ listId }: { listId: number, initialList: ListType }) => {
    await baseAPI(`api/lists/${listId}`, {
      method: 'DELETE',
    })

    return listId
  },
)

export const createListStatus = createAsyncThunk(
  'list/createListStatus',
  async (status: { listId: number, name: string }) => {
    const response = await baseAPI(`api/list_statuses`, {
      body: status,
      method: 'POST',
    })
    return response.body
  },
)

export const removeListStatus = createAsyncThunk('list/removeListStatus', async (statusId: number) => {
  const response = await baseAPI(`api/list_statuses/${statusId}`, {
    method: 'DELETE',
  })
  return { body: response.body, statusId }
})

export const updateListStatus = createAsyncThunk(
  'list/updateListStatus',
  async ({ status, changes }: { status?: ListStatusType, changes: Partial<ListStatusType> }) => {
    if (status === undefined) return
    const response = await baseAPI(`api/list_statuses/${status.id}`, {
      body: { ...status, ...changes },
      method: 'PUT',
    })
    return response.body
  },
)

export const reorderListStatus = createAsyncThunk(
  'list/reorderListStatus',
  async ({ status, position }: { status?: ListStatusType, position: number }) => {
    if (status === undefined) return
    const response = await baseAPI(`api/list_statuses/${status.id}`, {
      body: { ...status, position },
      method: 'PUT',
    })
    return response.body
  },
)

export const createListLabel = createAsyncThunk(
  'list/createListLabel',
  async (label: { name: string, listId: number }) => {
    const response = await baseAPI(`api/list_labels`, {
      body: label,
      method: 'POST',
    })
    return response.body
  },
)

export const updateListLabel = createAsyncThunk(
  'list/updateListLabel',
  async ({ label, changes }: { label?: ListLabelType, changes: Partial<ListLabelType> }) => {
    if (label === undefined) return
    const response = await baseAPI(`api/list_labels/${label.id}`, {
      body: { ...label, ...changes },
      method: 'PUT',
    })
    return response.body
  },
)

export const removeListLabel = createAsyncThunk('list/removeListLabel', async (labelId: number) => {
  const response = await baseAPI(`api/list_labels/${labelId}`, {
    method: 'DELETE',
  })
  return { body: response.body, labelId }
})

export const importRecords = createAsyncThunk(
  'list/importRecords',
  async (newAttachment: { importFile: any, stationId: number }) => {
    const formData = new FormData()
    forEach(newAttachment, (value: any, fieldName: string) => {
      formData.append(fieldName, value)
    })
    const response = await baseAPI(`api/stations/${newAttachment.stationId}/import`, { body: formData, method: 'POST' })
    return { response: response.body }
  },
)

export const importExcelSheet = createAsyncThunk(
  'list/importExcelSheet',
  async (newExcelSheet: { importFile: any, listId: number }, { rejectWithValue }) => {
    const formData = new FormData()
    forEach(newExcelSheet, (value: any, fieldName: string) => {
      formData.append(fieldName, value)
    })
    const response = await baseAPI(`api/records/${newExcelSheet.listId}/excel-import`, {
      body: formData,
      method: 'POST',
    })
    if (response.status >= 400) return rejectWithValue(response.message)
    return { response: response.body }
  },
)

export const onAddCustomField = createAsyncThunk(
  'list/addCustomField',
  async (
    newField: {
      fieldName: string,
      fieldType: number,
      appElementId: number | null,
      optionsList: any[],
      fieldFormattingRules: any[],
      appliesOnListChildren: boolean,
      appliesOnStationChildren: boolean,
      appliesOnRecordChildren: boolean,
      elementBaseType: AppElementBaseTypes,
      customFormulas: FormulaType[],
      type: string,
      isVisible: boolean,
      isReplacement: boolean,
      intermediateAppElementId: number | string | undefined,
      replacementsAttributes: any,
    },
    { rejectWithValue },
  ) => {
    try {
      const {
        appElementId,
        fieldType,
        fieldName,
        intermediateAppElementId,
        optionsList,
        fieldFormattingRules,
        elementBaseType,
        customFormulas,
        appliesOnRecordChildren,
        appliesOnStationChildren,
        appliesOnListChildren,
        isVisible,
        isReplacement,
        replacementsAttributes,
      } = newField
      const customFieldBody = {
        ...newCustomField,
        ...replacementsAttributes,
        appElementId,
        appliesOnListChildren,
        appliesOnRecordChildren,
        // appliesOnListChildren: elementBaseType === AppElementBaseTypes.StationAppElement,
        // appliesOnRecordChildren: appliesOnRecordChildren || (appElementId !== null && elementBaseType === AppElementBaseTypes.ListAppElement),
        appliesOnStationChildren,
        // appliesOnStationChildren: elementBaseType === AppElementBaseTypes.WorkspaceAppElement,
        appliesOnWorkspaceChildren: !appElementId,
        baseType: +fieldType,
        customFormulas,
        fieldCaption: fieldName,
        fieldFormattingRules,
        fieldName,
        intermediateAppElementId,
        isGlobal: appElementId === null,
        isReplacement,
        isVisible,
        options: optionsList.map((option, i) => ({ ...option, optionOrder: i })),
      }
      const response = await baseAPI(`api/element/customfields`, {
        body: customFieldBody,
        method: 'POST',
      })

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const onAddAggregateField = createAsyncThunk(
  'list/onAddAggregateField',
  async (aggregateCustomField, { rejectWithValue }) => {
    try {
      const customFieldBody = {
        ...aggregateCustomField,
        targetAppElementBaseType: 3,
      }
      const response = await baseAPI(`api/element/customFieldAggregates`, {
        body: customFieldBody,
        method: 'POST',
      })

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onEditAggregateField = createAsyncThunk(
  'list/onEditAggregateField',
  async (aggregateCustomField, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/customFieldAggregates/${aggregateCustomField?.id}`, {
        body: aggregateCustomField,
        method: 'PUT',
      })

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onUpdateCustomField = createAsyncThunk(
  'list/updateCustomField',
  async (updatedField: any, { rejectWithValue }) => {
    try {
      const customFieldBody = {
        ...newCustomField,
        ...updatedField,
      }
      const response = await baseAPI(`api/element/customfields/${updatedField.id}`, {
        body: customFieldBody,
        method: 'PUT',
      })
      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onDeleteCustomField = createAsyncThunk(
  'list/deleteCustomField',
  async (customFieldId: number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/customfields/${customFieldId}`, {
        method: 'DELETE',
      })
      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchLinkedDocumentsByListId = createAsyncThunk(
  'list/fetchLinkedDocumentsByListId',
  async (listId: number | string, { rejectWithValue }) => {
    const response = await baseAPI(`api/lists/${listId}/documents`)
    if (+response.status >= 400) return rejectWithValue(404)
    return response.body
  },
)

export const archiveListById = createAsyncThunk(
  'list/archiveListById',
  async (element: { elementId: number, status: number, recursive: number }, { rejectWithValue }) => {
    const { elementId, status, recursive } = element
    return archiveElement(elementId, status, recursive, rejectWithValue)
  },
)

export const updateListCustomFields = createAsyncThunk(
  'element/updateListCustomFields',
  async ({ listId, body }: { listId: string | number, body: any }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/lists/${listId}/update`, { body, method: 'Put' })
      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onMoveList = createAsyncThunk('list/onMoveList', async (body: any, { rejectWithValue }) => {
  return onMoveElement(body, rejectWithValue)
})

export const fetchViewsByListElementId = createAsyncThunk(
  'listGrid/fetchViewsByListElementId',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/views/element/${id}`)

      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const removeListView = createAsyncThunk(
  'listGrid/removeListView',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/views/${id}`, { method: 'DELETE' })

      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const reorderListView = createAsyncThunk(
  'list/reorderListView',
  async (props: { id: number, oldOrder?: number, newOrder?: number }, { rejectWithValue }) => {
    const { id, oldOrder, newOrder } = props
    if (!oldOrder || !newOrder) return

    const response = await baseAPI(`api/element/views/reorder/${id}`, {
      body: { id, orderNumber: newOrder },
      method: 'PUT',
    })
    if (response.status >= 400) {
      return rejectWithValue(oldOrder)
    }
    return response.body
  },
)
