import React, { FC, useEffect, useState, useCallback, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useToggle } from 'react-use'

import { InfoIcon } from 'components/Icons'
import AddUserToWorkspaceForm from 'components/forms/AddUserToWorkspaceForm'
import UserListRow from 'components/tables/UserListRow'
import Button, { ButtonVariant } from 'components/Buttons'
import { ElementType } from 'components/inputs/TagInput'
import Loader from 'components/Loader'
import Table from 'components/tables/Table'
import TableBody from 'components/tables/TableBody'

import baseAPI from 'utils/baseAPI'
import { withAdminRole, withViewerRole } from 'hocs/withRole'
import UsersTablePropsType from 'utils/types/UsersTablePropsType'
import RolesEnum from 'utils/constant/enums'
import { useUserLoading } from 'features/user'
import { unwrapResult } from '@reduxjs/toolkit'

const AddUserButton = withAdminRole(Button)

type UsersListPageType = {
  users: UsersTablePropsType[],
  fetchUsers: () => Promise<any>,
  fetchResource: () => Promise<any>,
  inviteUrl: string,
  adminCount: number,
  updateUrl: string,
  deleteUrl: string,
  fieldToGetRoleBy: 'StationAccess' | 'WorkspaceMember' | 'ListAccess',
  elementType: 'workspace' | 'station' | 'list',
  isInPanel?: boolean,
  isArchived?: boolean,
}

const UsersListPage: FC<UsersListPageType> = ({
  users: initialUsers = [],
  fetchUsers,
  inviteUrl,
  adminCount,
  updateUrl,
  deleteUrl,
  fieldToGetRoleBy,
  elementType,
  isInPanel = false,
  isArchived = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loading = useUserLoading()
  const [showGlobalUsersTooltip, setGlobalUsersTooltip] = useToggle(false)
  const [showExplicitUsersTooltip, setExplicitUsersTooltip] = useToggle(false)
  const [users, setUsers] = useState(initialUsers)
  const usersWithExplicitRole =
    users && [...users].filter((user) => Number(user[fieldToGetRoleBy].accessGroupId) === RolesEnum.ExplicitAccess)

  const { t } = useTranslation('access')
  const [isFormVisible, setIsFormVisible] = useState(false)

  const TooltipBox = ({ children }: { children: ReactNode }) => (
    <div className="px-4 py-2 my-2 bg-gray-200 rounded text-small">{children}</div>
  )

  const renderGlobalUsersTooltip = () => {
    if (showGlobalUsersTooltip) {
      switch (elementType) {
        case 'workspace':
          return (
            <TooltipBox>
              <ul className="list-disc list-inside">
                <li>{t('globalWorkspaceUsersTooltip.accessToWholeWorkspace')}</li>
                <li className="mt-0.5">{t('globalWorkspaceUsersTooltip.changingRoleWillOverwrite')}</li>
                <li className="mt-0.5">{t('globalWorkspaceUsersTooltip.oneAdmin')}</li>
              </ul>
            </TooltipBox>
          )

        case 'station':
          return (
            <TooltipBox>
              <ul className="list-disc list-inside">
                <li>{t('globalStationUsersTooltip.accessToWholeStation')}</li>
                <li className="mt-0.5">{t('globalStationUsersTooltip.changingRoleWillOverwrite')}</li>
                <li className="mt-0.5">{t('globalStationUsersTooltip.oneAdmin')}</li>
              </ul>
            </TooltipBox>
          )
      }
    }
  }

  const renderExplicitUsersTooltip = () => {
    if (showExplicitUsersTooltip) {
      switch (elementType) {
        case 'workspace':
          return (
            <TooltipBox>
              <ul className="list-disc list-inside">
                <li>{t('explicitWorkspaceUsersTooltip.explicitExplanation')}</li>
                <li className="mt-0.5">{t('explicitWorkspaceUsersTooltip.changingRoleWillOverwrite')}</li>
              </ul>
            </TooltipBox>
          )
      }
    }
  }

  const fetchAndSaveUsers = useCallback(
    () =>
      fetchUsers()
        ?.then((res) => {
          return unwrapResult(res)
        })
        ?.then((res) => setUsers(res)),
    [fetchUsers, setUsers],
  )

  useEffect(() => {
    fetchAndSaveUsers()
  }, [fetchUsers])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const removeUserFromList = () => {
    fetchAndSaveUsers()
  }

  const hideForm = () => {
    setIsFormVisible(false)
  }

  const sendInvitations = (allEmails: ElementType[], role: string | number) => {
    if (allEmails) {
      setIsLoading(true)
      const emails = allEmails.map(({ element }) => element)
      baseAPI(inviteUrl, {
        body: {
          emails,
          accessGroupId: +role,
        },
        method: 'POST',
      }).then(() => {
        fetchAndSaveUsers()
      })
    }
    hideForm()
  }

  return (
    <div className={classnames('w-full p-6 md:p-8', { 'px-6': isInPanel })}>
      <div className={classnames('flex items-center', { 'mb-4': isInPanel })}>
        <h2 className="flex items-center text-lg font-bold text-gray-700 me-auto">
          {elementType === 'workspace' ? t('globalWorkspaceUsers') : t('globalStationUsers')}
          <Button
            className="text-gray-500"
            icon={InfoIcon}
            small
            variant={ButtonVariant.Icon}
            onClick={setGlobalUsersTooltip}
          />
        </h2>
        {!isArchived && (
          <div>
            <AddUserButton
              className="shadow-lg"
              small
              variant={ButtonVariant.Secondary}
              onClick={() => setIsFormVisible(true)}>
              {t('addUsers')}
            </AddUserButton>
          </div>
        )}
      </div>
      {renderGlobalUsersTooltip()}
      <Table key={adminCount} tableClasses="border-separate">
        <TableBody
          renderRow={(user) => (
            <UserListRow
              key={user.id}
              {...user}
              deleteUrl={`${deleteUrl}/${user.id}`}
              fetchUsers={fetchAndSaveUsers}
              fieldToGetRoleBy={fieldToGetRoleBy}
              hasMoreThanOneAdmin={adminCount > 1}
              updateUrl={updateUrl}
              isArchived={isArchived}
              onDelete={removeUserFromList}
            />
          )}
          rowData={
            users &&
            [...users]
              .filter((user) => Number(user[fieldToGetRoleBy].accessGroupId) !== RolesEnum.ExplicitAccess)
              .sort((userA, userB) => +userB[fieldToGetRoleBy].accessGroupId - +userA[fieldToGetRoleBy].accessGroupId)
          }
        />
      </Table>

      {usersWithExplicitRole && usersWithExplicitRole.length !== 0 && (
        <h2 className="flex items-center mt-8 text-lg font-bold text-gray-700 me-auto">
          {t('explicitWorkspaceUsersTitle')}
          <Button
            className="text-gray-500"
            icon={InfoIcon}
            small
            variant={ButtonVariant.Icon}
            onClick={setExplicitUsersTooltip}
          />
        </h2>
      )}
      {renderExplicitUsersTooltip()}
      <Table key={`${adminCount}-explicit`} tableClasses="border-separate">
        <TableBody
          renderRow={(user) => (
            <UserListRow
              key={user.id}
              {...user}
              deleteUrl={`${deleteUrl}/${user.id}`}
              fetchUsers={fetchAndSaveUsers}
              fieldToGetRoleBy={fieldToGetRoleBy}
              hasMoreThanOneAdmin={adminCount > 1}
              updateUrl={updateUrl}
              onDelete={removeUserFromList}
            />
          )}
          rowData={usersWithExplicitRole}
        />
      </Table>
      {isLoading && (
        <Loader
          loaderClasses={classnames('w-full flex items-center justify-center', { 'h-full': users.length === 0 })}
          svgClasses="w-12"
        />
      )}
      {isFormVisible && (
        <AddUserToWorkspaceForm closeModal={hideForm} isInPanel={isInPanel} sendInvitations={sendInvitations} />
      )}
    </div>
  )
}

export default withViewerRole(UsersListPage, true)
