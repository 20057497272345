import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { configValues } from 'utils/appConfig'
import { addTokensToLocalStorage } from 'utils/helpers/authHelpers'
import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'

// import baseAPI from 'utils/baseAPI'

const SSOLogin = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation('signIn')
  const [isSubmitting, setSubmitting] = useState(false)

  const loginWithSSO = () => {
    setSubmitting(true)

    fetch(`${configValues.REACT_APP_API_URL}/auth/login/sso`, {
      method: 'POST',
      credentials: 'include',
    })
      .then((res) => {
        return res.json()
      })
      .then((response) => {
        if (response.status === 400 || response.body === 'Invalid credentials') {
          setSubmitting(false)
        } else {
          addTokensToLocalStorage({ body: response?.body })
          history.replace(get(location, 'state.backTo', '/'))
          setSubmitting(false)
        }
      })
      .catch((error) => {
        setSubmitting(false)
      })
  }

  return (
    <div>
      <Button className="mt-4" disabled={isSubmitting} full variant={ButtonVariant.Outline} onClick={loginWithSSO}>
        {isSubmitting ? <Loader /> : t('loginWithSSO')}
      </Button>
    </div>
  )
}

export default SSOLogin
