const MENTION_REGEX = /@\[([^\]]+)\]\(userId:(\d+)\)/gm

export const formatTextWithMentions: (text: string, currentUserId: number) => string = (text, currentUserId) => {
  if (!text) return ''
  // if (text.indexOf('@') === -1) {
  //   const withoutCF = text.replace('cf_', '')
  //   const withoutDF = withoutCF.replace('df_', '')
  //   return `<span class='text-gray-600'>${withoutDF}</span>`
  // }
  return text.replace(MENTION_REGEX, (...match) => {
    const [, displayName, userId] = match
    return `<span class='py-0.5 px-1 rounded-sm ${
      currentUserId !== +userId ? 'bg-gray-200' : 'bg-primary text-white'
    }' >${displayName}</span>`
  })
}
