import * as yup from 'yup'
import i18n from 'i18n'
import { TestContext } from 'yup'
import { configValues } from '../../../utils/appConfig'

const defaultPasswordValidationRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(.{8,})$/

const validatePassword = (password: string | undefined, context: TestContext<Record<string, any>>) => {
  if (!password) return context.createError()
  if (!/^\S*$/.test(password)) return context.createError({ message: i18n.t('validation:errors.trimPasswordError') })

  const useCustomPasswordRegex =
    configValues.REACT_APP_CUSTOM_PASSWORD_REGEX && configValues.REACT_APP_CUSTOM_PASSWORD_REGEX !== ''

  const errorMessage = useCustomPasswordRegex
    ? 'validation:errors.customPasswordRegex'
    : 'validation:errors.passwordRegex'

  const passwordValidationRegex = useCustomPasswordRegex
    ? new RegExp(configValues.REACT_APP_CUSTOM_PASSWORD_REGEX ?? '')
    : defaultPasswordValidationRegex
  if (!passwordValidationRegex.test(password)) return context.createError({ message: i18n.t(errorMessage) })
  return true
}

const validateOnlySpaces = (value: string, context: TestContext<Record<string, any>>) => {
  if (/^(\s+$)/g.test(value)) return context.createError()
  return true
}

const birthdayValidationSchema = yup
  .date()
  .max(new Date(), i18n.t('validation:errors.birthdayCannotBeLaterThanToday'))
  .nullable()
  .required()

const passwordValidationSchema = yup
  .string()
  .strict()
  .trim()
  .test(
    'passwordTest',
    i18n.t('validation:errors.requiredField', { name: i18n.t(`validation:fieldNames.password`) }),
    (value, context) => validatePassword(value, context),
  )
  .required()

const emailValidationSchema = yup
  .string()
  .test(
    'Contains only spaces',
    i18n.t(`validation:errors.trimError`, {
      name: i18n.t('validation:fieldNames.email'),
    }),
    (value = '', context) => validateOnlySpaces(value, context),
  )
  .email(i18n.t('validation:errors.invalidEmail'))
  .max(129)
  .required()

const statusValidationSchema = yup.string().test(
  'Contains only spaces',
  i18n.t(`validation:errors.trimError`, {
    name: i18n.t('validation:fieldNames.email'),
  }),
  (value = '', context) => validateOnlySpaces(value, context),
)

const phoneNumberValidationSchema = yup.string().test(
  'Contains only spaces',
  i18n.t(`validation:errors.trimError`, {
    name: i18n.t('validation:fieldNames.phoneNumber'),
  }),
  (value = '', context) => validateOnlySpaces(value, context),
)

const completedInValidationSchema = yup
  .number()
  .typeError(i18n.t('validation:errors.shouldBeNumber', { name: i18n.t('validation:fieldNames.completedIn') }))
  .min(
    0,
    i18n.t(`validation:errors.valueShouldBeMoreThanValue`, {
      min: 0,
      name: i18n.t('validation:fieldNames.completedIn'),
    }),
  )
  .max(
    100,
    i18n.t('validation:errors.valueShouldBeLessThanValue', {
      max: 100,
      name: i18n.t('validation:fieldNames.completedIn'),
    }),
  )

const positiveNumberValidationSchema = yup
  .number()
  .typeError(i18n.t('validation:errors.shouldBeNumber', { name: i18n.t('validation:fieldNames.completedIn') }))
  .min(
    0,
    i18n.t(`validation:errors.valueShouldBeMoreThanValue`, {
      min: 0,
      name: i18n.t('validation:fieldNames.completedIn'),
    }),
  )

const requiredTextFieldValidationSchema: (name?: string, max?: number) => any = (name, max = 50) =>
  yup
    .string()
    .strict()
    .test(
      'Contains only spaces',
      i18n.t(`validation:errors.trimError`, {
        name: i18n.t(`validation:fieldNames.${name}`, i18n.t(`validation:fieldNames.field`)),
      }),
      (value = '', context) => validateOnlySpaces(value, context),
    )
    .max(max)
    .required()

const nullableRequiredTextFieldValidationSchema: (name?: string, max?: number) => any = (name, max = 50) =>
  yup
    .string()
    .strict()
    .test(
      'Contains only spaces',
      i18n.t(`validation:errors.trimError`, {
        name: i18n.t(`validation:fieldNames.${name}`, i18n.t(`validation:fieldNames.field`)),
      }),
      (value = '', context) => validateOnlySpaces(value, context),
    )
    .max(max)
    .required()
    .nullable()

const numberFieldValidationSchema: () => any = () =>
  yup.number().nullable(true).typeError(i18n.t('validation:errors.numberErrorMessage'))

const textFieldValidationSchema: (name?: string, max?: number) => any = (name, max = 50) =>
  yup
    .string()
    .strict()
    .test(
      'Contains only spaces',
      i18n.t(`validation:errors.trimError`, {
        name: i18n.t(`validation:fieldNames.${name}`, i18n.t(`validation:fieldNames.field`)),
      }),
      (value = '', context) => validateOnlySpaces(value, context),
    )
    .max(max)

const confirmPasswordValidationSchema: (referenceName: string) => any = (referenceName) =>
  yup
    .string()
    .strict()
    .trim()
    .oneOf(
      [yup.ref(referenceName), null],
      i18n.t('validation:errors.valueShouldMatch', { name: i18n.t(`validation:fieldNames.${referenceName}`) }),
    )
    .required()

const requiredNumberField: (name?: string) => any = (name) => yup.number().required().nullable()

const optionsListValidationSchema: (referenceName: any[]) => any = (referenceName) => yup.array().min(1)

export {
  emailValidationSchema,
  statusValidationSchema,
  passwordValidationSchema,
  requiredTextFieldValidationSchema,
  textFieldValidationSchema,
  numberFieldValidationSchema,
  phoneNumberValidationSchema,
  confirmPasswordValidationSchema,
  birthdayValidationSchema,
  positiveNumberValidationSchema,
  completedInValidationSchema,
  requiredNumberField,
  nullableRequiredTextFieldValidationSchema,
  optionsListValidationSchema,
}
