import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store'
import recordType from 'utils/types/RecordType'
import { getFormattedElementLinks } from 'utils/formatData/customFields'
import { getElementLinkFromPath } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'

import { onUpdateCustomFieldLinks } from 'features/record'
import { CrossSignIcon, PlusIcon } from 'components/Icons'
import classNames from 'classnames'
import LinkToRecordModal from './LinkToRecordModal'

interface LinkToRecordsPropTypes {
  customFieldInfo: any;
  currentRecord: recordType;
  isMulti: boolean;
  isEditingAllowed: boolean;
  isGridView?: boolean;
  className?: string;
}

const LinkToRecords: FC<LinkToRecordsPropTypes> = ({
  customFieldInfo,
  currentRecord,
  isMulti,
  isEditingAllowed,
  isGridView = false,
  className,
}) => {
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('customFields')
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  //to check if the current record parent = custom field elementId
  const isCurrentRecordElement = +customFieldInfo?.appElementId === +currentRecord?.appElements[0]?.parentId

  const linkedElements = useMemo(
    () => getFormattedElementLinks(currentRecord, customFieldInfo),
    [
      currentRecord?.appElements[0]?.appElementLinks,
      currentRecord?.appElements[0]?.linkedElementAppElementLinks,
      customFieldInfo.id,
    ],
  )

  const updateLinks = (record: { value: number, action: string }) => {
    //get the current record elementId
    const currentRecordElementId = currentRecord?.appElements[0]?.id

    let elementId, linkedElementId
    if (isCurrentRecordElement) {
      elementId = +currentRecordElementId
      linkedElementId = +record?.value
    } else {
      elementId = +record?.value
      linkedElementId = +currentRecordElementId
    }

    const updateLinkMessage = {
      action: record.action,
      customFieldId: +customFieldInfo?.id,
      elementId: elementId,
      force: isCurrentRecordElement,
      linkedElementId: linkedElementId,
    }

    return appDispatch(onUpdateCustomFieldLinks({ recordId: +currentRecord?.id, updateLinkMessage }))
  }

  const handleDelete = (recordId: number) => {
    updateLinks({ action: 'delete', value: recordId })
  }

  const renderLinkedRecords = () => {
    if (linkedElements.length === 0) {
      return <div className="text-sm text-gray-400">{t('noLinkedRecords')}</div>
    }
    return linkedElements.map((linkedRecord) => {
      return (
        <div
          key={linkedRecord.id}
          className="inline-flex items-center justify-between font-bold bg-gray-200 rounded-sm cursor-default cursor-pointer m-0.5 py-0.5 px-1.5 transition"
          style={{ maxWidth: '120px' }}>
          <a
            className="truncate"
            href={getElementLinkFromPath(linkedRecord.path, AppElementBaseTypes.RecordAppElement)}
            rel="noreferrer"
            target="_blank">
            {linkedRecord.label}
          </a>
          {isEditingAllowed && (
            <button
              className="flex rounded ms-1 hover:bg-warning hover:text-white"
              type="button"
              onClick={() => {
                handleDelete(linkedRecord.value)
              }}>
              <CrossSignIcon className="w-4" />
            </button>
          )}
        </div>
      )
    })
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-between text-xs w-full text-center border-2 border-transparent border-dashed rounded hover:bg-white focus:bg-white leading-6 min-h-12 focus:overflow-visible focus:whitespace-normal transition focus:outline-none focus:border-gray-400',
        className,
        {
          'hover:border-gray-300': isEditingAllowed && !isGridView,
        },
      )}>
      <div className="flex flex-wrap">{renderLinkedRecords()}</div>
      {isEditingAllowed && (
        <button
          className="shrink-0 w-5 bg-gray-300 rounded-sm hover:bg-primary hover:text-white"
          onClick={() => {
            setModalOpen(true)
          }}>
          <PlusIcon className="text-black stroke-2" />
        </button>
      )}
      {isModalOpen && (
        <LinkToRecordModal
          customFieldInfo={customFieldInfo}
          currentRecord={currentRecord}
          isMulti={isMulti}
          updateLinks={updateLinks}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  )
}

export default LinkToRecords
