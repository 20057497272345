import React, { FC } from 'react'

import { DropdownOptionType } from 'utils/types/inputs'
import { CrossSignIcon } from 'components/Icons'
import classnames from 'classnames'

const LabelComponent: FC<{
  data: DropdownOptionType,
  innerRef?: any,
  innerProps?: any,
  removeProps?: any,
  isDeletingAllowed?: boolean,
  isGridView: boolean,
}> = ({ data, innerRef, innerProps, removeProps, isDeletingAllowed = true, isGridView }) => {
  const { color, label, value } = data
  return (
    <p
      ref={innerRef}
      {...innerProps}
      className={classnames(
        'inline-flex items-center text-sm font-bold text-white rounded-full cursor-default ps-4 leading-6' +
          ' transition' +
          ' max-w-full ',
        isGridView ? 'mx-0.5 -mt-1 ' : 'py-1 m-0.5 ',
        isDeletingAllowed ? 'pe-0' : 'pe-4',
      )}
      style={{ backgroundColor: `${color}20`, color: color }}>
      <span className="truncate">{label}</span>
      {isDeletingAllowed && (
        <button className="flex w-8 -my-2 hover:opacity-50 transition" type="button" {...removeProps}>
          <CrossSignIcon className="w-6 m-1" />
        </button>
      )}
    </p>
  )
}

export default LabelComponent
