import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslations from 'locales/en'
import arTranslations from 'locales/ar'

const resources = {
  ar: arTranslations,
  en: enTranslations,
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      defaultNS: 'common',
      detection: {
        lookupLocalStorage: 'lang',
        order: ['localStorage'],
      },
      fallbackLng: 'ar',
      resources,
      supportedLngs: ['ar', 'en'],
    },
    () => {
      document.documentElement.setAttribute('dir', i18n.dir())
    },
  )

// This function will only change the language in the frontend
export const switchLanguage = (lang: 'ar' | 'en') => {
  changeLanguage(lang)
}

export const changeLanguage = (lang: 'ar' | 'en') => {
  i18n.changeLanguage(lang).then(() => {
    document.documentElement.setAttribute('dir', i18n.dir())
    localStorage.setItem('lang', i18n.language)
  })
}

export default i18n
