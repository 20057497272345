import { FC, useRef } from 'react'
import CreatableSelect, { Creatable } from 'react-select/creatable'
import { OptionTypeBase } from 'react-select'

import { RecordType } from 'utils/types'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { getCustomFieldsOptions } from 'utils/helpers/listHelpers'
import { useHasCurrentWorkspaceTeamsPlan } from '../../../features/workspace'
import CustomFieldSelectedType from './CustomFieldSelectedType'

const CustomFieldSelect: FC<{
  fullRecord: RecordType,
  isDisabled?: boolean,
  isFromAdmin?: boolean,
  selectedType: number,
  setFieldType: () => void,
  elementBaseType: AppElementBaseTypes,
}> = ({
  fullRecord,
  isDisabled,
  selectedType,
  setFieldType,
  elementBaseType = AppElementBaseTypes.ListAppElement,
  isFromAdmin = false,
}) => {
  const customRef = useRef<Creatable<OptionTypeBase, false>>(null)
  const fieldSelectedType = +selectedType === 5 ? 3 : +selectedType
  const isTeamsPlan = useHasCurrentWorkspaceTeamsPlan()
  const options = getCustomFieldsOptions(elementBaseType, isFromAdmin, isTeamsPlan)
  const currentValue = options?.find((i) => +i.value === +fieldSelectedType)

  return (
    <div className="w-full">
      <CreatableSelect
        className="-mt-2 -mb-2 -ms-2"
        classNamePrefix="custom-field-select"
        components={{
          Option: CustomFieldSelectedType,
        }}
        getOptionLabel={(e) => (
          <div className="flex items-center mt-2">
            <CustomFieldSelectedType {...e} isFromAdmin={isFromAdmin} />
          </div>
        )}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name="customFieldType"
        options={options}
        ref={customRef}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={currentValue}
        onChange={(values: any) => {
          setFieldType(values.value)
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Enter' || event.key === 'Escape') {
            customRef.current?.blur()
          }
        }}
      />
    </div>
  )
}

export default CustomFieldSelect
