import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BaseModal from '../Dashboard/BaseModal/BaseModal'
import { clearElementTree } from '../../features/element/elementSlice'
import Loader from '../Loader'
import LinkElements from '../LinkElements'
import Button, { ButtonVariant } from '../Buttons'
import { getElementTree, useCurrentElementTree, useCurrentElementTreeLoading } from '../../features/element'
import { useAppDispatch } from '../../store'
import ElementOperations from '../../utils/constant/enums/element'

interface MoveModalProps {
  isMoveModalOpen: boolean;
  elementBaseType: number;
  shouldRemoveElementAfterMove: boolean;
  setIsMoveModalOpen: (value: any) => void;
  onMoveItem: (value: any) => void;
  onRefetch: () => void;
  directParentId: string | number;
  currentParentId: string | number;
  currentElementId: string | number;
}

const MoveModal: FC<MoveModalProps> = ({
  isMoveModalOpen,
  elementBaseType,
  setIsMoveModalOpen,
  currentParentId,
  currentElementId,
  directParentId,
  onRefetch,
  shouldRemoveElementAfterMove,
  onMoveItem,
}) => {
  const { t } = useTranslation('dashboard')
  const [selectedElements, setSelectedElements] = useState<any[]>([])
  const [isMoving, setMoving] = useState<boolean>(false)
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const currentList = useCurrentElementTree()
  const currentListLoading = useCurrentElementTreeLoading()

  useEffect(() => {
    if (isMoveModalOpen) dispatch(getElementTree({ elementId: +currentParentId }))
    return () => {
      dispatch(clearElementTree())
    }
  }, [isMoveModalOpen])

  const onCancel = () => {
    setSelectedElements([])
    setIsMoveModalOpen(false)
  }

  const onMove = () => {
    setMoving(true)
    if (selectedElements.length > 0) {
      const body = {
        appElementId: +currentElementId,
        newParentId: +selectedElements[0]?.element?.id,
        operation: !shouldRemoveElementAfterMove ? ElementOperations.Duplicate : ElementOperations.MOVE,
      }
      appDispatch(onMoveItem(body))
        .then(() => {
          if (onRefetch) {
            onRefetch()
          }
        })
        .finally(() => {
          setMoving(false)
          onCancel()
        })
    } else {
      setMoving(false)
    }
  }
  const linkRecordsProps = {
    currentList: currentList.filter(({ element }) => +element?.id !== +directParentId),
    currentListLoading,
    elementBaseType,
    elementId: currentParentId,
    level: elementBaseType,
    lowestLevel: elementBaseType,
    options: {
      canAddRecord: false,
      isSingleSelect: true,
      shouldShowPreview: false,
    },
    selectedElements,
    setSelectedElements,
  }

  return (
    <BaseModal
      close={() => setIsMoveModalOpen(false)}
      content={
        <>
          {currentListLoading ? (
            <div className="flex items-center justify-center flex-1">
              <Loader />
            </div>
          ) : (
            <>
              <LinkElements {...linkRecordsProps} />
              <div className="flex justify-end mt-2">
                <Button className="me-1" variant={ButtonVariant.Outline} onClick={onCancel}>
                  {t('common:labels.cancel')}
                </Button>
                <Button
                  disabled={selectedElements.length === 0 || isMoving}
                  variant={ButtonVariant.Primary}
                  onClick={onMove}>
                  {isMoving ? <Loader /> : t('saveButton')}
                </Button>
              </div>
            </>
          )}
        </>
      }
      header={<h3 className="m-2 font-bold">{t('common:labels.selectElement')}</h3>}
      isModalOpen={isMoveModalOpen}
      type="md"
      withoutCloseBtn
    />
  )
}

export default MoveModal
