import React, { FC, useMemo, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
// import ReactDataGrid from '@inovua/reactdatagrid-community'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { TypeSortInfo, TypeEditInfo } from '@inovua/reactdatagrid-community/types'
// import '@inovua/reactdatagrid-community/index.css'
import '@inovua/reactdatagrid-enterprise/index.css'

import {
  updateRecordElementById,
  useCurrentRecordsCustomFields,
  useRecordLoading,
  useRecords,
  useRecordsChildren,
} from 'features/record'
import { configValues } from 'utils/appConfig'
import { RECORDS_PER_PAGE } from 'utils/helpers/recordHelpers'
import { PaginationPropsType, RecordType } from 'utils/types'
import { useActiveListView, useCurrentListDetails, useCurrentListId } from 'features/list'
import { useCurrentCustomGridColumns } from 'features/record'
import { useGridColumns, SortIndicator, Pagination, GridToolbar } from 'components/GridView'
import recordValidationSchema from 'components/forms/validationSchemas/addRecordValidationSchema'
import { format } from 'date-fns'
import { useRoles } from 'hooks'
import { tableTranslation } from 'utils/constant/GridView/customGridTableTranslation'
import { defaultFilterValue } from 'utils/constant/GridView/GridViewConstant'
import { hijriInputFormat } from 'utils/calendarUtils'
import { DATE_FORMAT } from 'utils/helpers/generalHelpers'
import customFields from 'utils/constant/enums/customFields'
import { customFieldsGideViewType } from 'utils/constant/constant/customFields'
import {
  customFieldList,
  getFieldReplacingInfo,
  getInheritedCustomFields,
  settingsList,
} from 'utils/helpers/customFields'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { useCurrentUser } from 'features/user'
import { useParams } from 'react-router-dom'

const defaultSortInfo: TypeSortInfo = { dir: 1, name: 'id' }

const GridView: FC<{ isArchived: boolean }> = memo(({ isArchived }) => {
  const [gridRef, setGridRef] = useState<any>(null)
  const { viewId } = useParams<{ viewId: string }>()
  const listId = useCurrentListId()
  const isLoading = useRecordLoading()
  const listDetails = useCurrentListDetails()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)
  const { i18n } = useTranslation('records')
  const records = useRecords()
  const dispatch = useDispatch()
  const language = i18n.language
  const user = useCurrentUser()
  const recordSubtasks = useRecordsChildren()
  const isHijri = user?.isHijri !== null ? user?.isHijri : false
  const [isDataProcessing, setIsDataProcessing] = useState(false)
  const view = useActiveListView()
  const fixedColumns = useGridColumns({
    defaultAttributeVisibility: view?.defaultAttributeVisibility,
    viewAttributes: view?.appElementViewAttributes,
  })
  const dynamicColumns = useCurrentCustomGridColumns()
  const isRtl = useMemo(() => i18n.dir() === 'rtl', [language])
  const renderSortTool = (direction: number) => <SortIndicator direction={direction} />
  const { isEditor } = useRoles()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()

  const cellDOMProps = (data: any) => {
    return {
      onClick: () => {
        gridRef?.current?.startEdit({ columnId: data?.cellProps?.name, rowIndex: data?.cellProps?.rowIndex })
      },
    }
  }
  const fixedList = isPremiumPlan && isEditor ? [...settingsList, ...customFieldList] : [...settingsList]

  const columns =
    dynamicColumns !== undefined ? [...fixedColumns, ...dynamicColumns, ...fixedList] : [...fixedColumns, ...fixedList]

  //Custom fields filter columns
  const dynamicFilterColumns = useMemo(
    () =>
      dynamicColumns
        .filter((c) => c.baseType !== customFields.Link && c.baseType !== customFields.SingleLink)
        .map((col) => ({
          name: col.name,
          operator:
            [customFields.Text, customFields.Date, customFields.Formula].indexOf(col.baseType) > -1 ? 'contains' : 'eq',
          type: customFieldsGideViewType[col.baseType],
          value: col.baseType === customFields.Dropdown ? null : '',
        })) || [],
    [dynamicColumns],
  )

  const totalDynamicFilters = useMemo(() => [...defaultFilterValue, ...dynamicFilterColumns], [dynamicFilterColumns])

  // Pagination toolbar
  const renderPaginationToolbar = useCallback(
    (props: PaginationPropsType) => <Pagination {...props} gridRef={gridRef?.current} />,
    [gridRef?.current],
  )
  const recordsLoading = isLoading || isDataProcessing || !listDetails?.appElement?.id

  //Edit function
  const onEditComplete = useCallback(
    ({ data: initialRecord, columnId, value, rowId, cellProps, ...rest }: TypeEditInfo & { data: RecordType }) => {
      const recordId = initialRecord.id.includes('/') ? initialRecord.id.split('/')[1] : initialRecord.id
      initialRecord.id = recordId
      if (initialRecord[columnId] !== value) {
        const changes = { [columnId]: value }
        recordValidationSchema
          .validate({ ...initialRecord, ...changes })
          .then(() => {
            if (columnId.indexOf('custom_') > -1) {
              const customFieldId = columnId.split('custom_')[1]
              const fieldValue = {
                customFieldId: customFieldId,
                customFieldOptionId: value,
                value: !value ? (cellProps.type === 'number' ? '' : value) : value,
              }
              const allCustoms = [...initialRecord.appElements[0].customFieldValues]
              const customIndex = allCustoms.findIndex((i: any) => +i.customFieldId === +customFieldId)
              if (customIndex > -1) {
                allCustoms[customIndex] = { ...allCustoms[customIndex], ...fieldValue }
              } else {
                allCustoms.push(fieldValue)
              }
              const fieldsList = {
                appElements: [
                  {
                    ...initialRecord?.appElements[0],
                    customFieldValues: allCustoms,
                  },
                ],
              }
              const updatedRecord = { ...initialRecord, ...fieldsList }
              // dispatch(updateRecordById({ changes, initialRecord: updatedRecord }))
              dispatch(
                updateRecordElementById({
                  changes,
                  elementId: updatedRecord?.appElements[0]?.id,
                  record: updatedRecord,
                  viewId,
                }),
              )
            } else {
              // dispatch(updateRecordById({ changes, initialRecord }))
              dispatch(
                updateRecordElementById({
                  changes,
                  elementId: initialRecord?.appElements[0]?.id,
                  record: initialRecord,
                  viewId,
                }),
              )
            }
          })
          .catch(({ errors }) => {
            errors.forEach((error) => toast.error(error, { icon: false }))
          })
      }
    },
    [dispatch, listId],
  )

  //Grid view custom style
  const gridStyle = useMemo(
    () => ({
      minHeight:
        records.length !== 0 ? `min(100%, ${Math.min(records.length, RECORDS_PER_PAGE) * 38 + 228}px)` : '250px',
    }),
    [records, dynamicColumns],
  )

  //Manage records data to by adding custom fields key and value
  const gridRecords = useMemo(() => {
    setIsDataProcessing(true)
    const newList = records.map((record, index) => {
      const formattedRecord = { key: record.id, ...record }
      fieldsList?.map((customColumn: any) => {
        const customFieldValue = record?.appElements
          ? record?.appElements[0]?.customFieldValues?.find((i: any) => +i.customFieldId === +customColumn.id)
          : undefined
        if (customFieldValue) {
          formattedRecord[`custom_${customColumn.id}`] =
            customColumn.baseType !== 2 ? customFieldValue.value : +customFieldValue.customFieldOptionId
        } else {
          formattedRecord[`custom_${customColumn.id}`] = null
        }
      })
      if (index === records.length - 1) {
        setIsDataProcessing(false)
      }
      return formattedRecord
    })
    setIsDataProcessing(false)
    return newList
  }, [records, dynamicColumns])

  //data structure new data
  const newGridRecords = useMemo(() => {
    setIsDataProcessing(true)
    const newList = records
      .filter((rec) => rec?.appElements && +rec?.appElements[0]?.parentId === +listDetails?.appElement?.id)
      .map((record, index) => {
        const recordNodes = recordSubtasks[record?.appElements[0]?.id] || undefined
        const formattedRecord = { key: record.id, ...record, folder: true, nodes: recordNodes }
        const isSubTask = Number(record?.appElements[0]?.elementLevel) > 4
        const isLeafNode = isSubTask || Number(record?.appElements[0]?.directChildrenCount) === 0
        fieldsList.map((customColumn: any) => {
          const { value: customFieldValue } = getFieldReplacingInfo({
            customFieldValues: record?.appElements[0]?.customFieldValues,
            field: customColumn,
            fieldsList,
            isLeafNode,
          })
          if (customFieldValue) {
            formattedRecord[`custom_${customColumn.id}`] =
              customColumn.baseType !== 2 ? customFieldValue.value : +customFieldValue.customFieldOptionId
          } else {
            formattedRecord[`custom_${customColumn.id}`] = null
          }
        })
        if (index === records.length - 1) {
          setIsDataProcessing(false)
        }
        return formattedRecord
      })
    setIsDataProcessing(false)
    return newList
  }, [records, dynamicColumns, listDetails?.appElement?.id, recordSubtasks])

  //Custom filter
  const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    completedIn: {
      emptyValue: null,
      name: 'completedIn',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value === +filterValue
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value !== +filterValue
          },
          name: 'neq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value > +filterValue
          },
          name: 'gt',
        },
        {
          fn: ({ value, filterValue }) => {
            return value === +filterValue || value > +filterValue
          },
          name: 'gte',
        },
        {
          fn: ({ value, filterValue }) => {
            return value < +filterValue
          },
          name: 'lt',
        },
        {
          fn: ({ value, filterValue }) => {
            return value < +filterValue || value === +filterValue
          },
          name: 'lte',
        },
      ],
    },
    customBoolean: {
      emptyValue: '',
      name: 'customBoolean',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value === filterValue
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value !== filterValue
          },
          name: 'neq',
        },
      ],
    },
    customDate: {
      emptyValue: '',
      name: 'customDate',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            const dateValue = value
              ? isHijri
                ? hijriInputFormat(value, i18n.language)
                : `${format(new Date(value), DATE_FORMAT)}`
              : ''
            return dateValue?.includes(filterValue)
          },
          name: 'contains',
        },
      ],
    },
    customFormula: {
      emptyValue: '',
      name: 'customFormula',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value?.includes(filterValue)
          },
          name: 'contains',
        },
        {
          fn: ({ value, filterValue }) => {
            return !value?.includes(filterValue)
          },
          name: 'notContains',
        },
        {
          fn: ({ value, filterValue }) => {
            return +value === +filterValue
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return +value !== +filterValue
          },
          name: 'neq',
        },
      ],
    },
    customLink: {
      emptyValue: null,
      name: 'customLink',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value?.toLowerCase().includes(filterValue.toLowerCase())
          },
          name: 'contains',
        },
        {
          fn: ({ value, filterValue }) => {
            return !value?.toLowerCase().includes(filterValue.toLowerCase())
          },
          name: 'notContains',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase() === filterValue.toLowerCase()
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase() !== +filterValue.toLowerCase()
          },
          name: 'neq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase().startsWith(filterValue.toLowerCase())
          },
          name: 'startsWith',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase().endsWith(filterValue.toLowerCase())
          },
          name: 'endsWith',
        },
      ],
    },
    customList: {
      emptyValue: null,
      name: 'customList',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return +value === +filterValue
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return +value !== +filterValue
          },
          name: 'neq',
        },
      ],
    },
    customNumber: {
      emptyValue: null || '',
      name: 'customNumber',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return +value === +filterValue
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return +value !== +filterValue
          },
          name: 'neq',
        },
      ],
    },
    customString: {
      emptyValue: '',
      name: 'customString',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value?.includes(filterValue)
          },
          name: 'contains',
        },
        {
          fn: ({ value, filterValue }) => {
            return !value?.includes(filterValue)
          },
          name: 'notContains',
        },
      ],
    },
    dateCustom: {
      emptyValue: null,
      name: 'dateCustom',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            const dateValue = value
              ? isHijri
                ? hijriInputFormat(value, i18n.language)
                : `${format(new Date(value), DATE_FORMAT)}`
              : ''
            return dateValue?.includes(filterValue)
          },
          name: 'contains',
        },
      ],
    },
    description: {
      emptyValue: '',
      name: 'description',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value?.includes(filterValue)
          },
          name: 'contains',
        },
      ],
    },
    labelsIds: {
      emptyValue: null,
      name: 'labelsIds',
      operators: [
        {
          fn: ({ value, filterValue, data }) => {
            return value?.includes(filterValue)
          },
          name: 'contains',
        },
        {
          fn: ({ value, filterValue, data }) => {
            return !value?.includes(filterValue)
          },
          name: 'notContains',
        },
      ],
    },
    name: {
      emptyValue: null,
      name: 'name',
      operators: [
        {
          fn: ({ value, filterValue }) => {
            return value?.toLowerCase().includes(filterValue.toLowerCase())
          },
          name: 'contains',
        },
        {
          fn: ({ value, filterValue }) => {
            return !value?.toLowerCase().includes(filterValue.toLowerCase())
          },
          name: 'notContains',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase() === filterValue.toLowerCase()
          },
          name: 'eq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase() !== +filterValue.toLowerCase()
          },
          name: 'neq',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase().startsWith(filterValue.toLowerCase())
          },
          name: 'startsWith',
        },
        {
          fn: ({ value, filterValue }) => {
            return value.toLowerCase().endsWith(filterValue.toLowerCase())
          },
          name: 'endsWith',
        },
      ],
    },
  })

  return (
    <>
      <GridToolbar isArchived={isArchived} />
      <ReactDataGrid
        key={listId}
        activateRowOnFocus={false}
        columns={columns.map((i: any) => {
          return { ...i, ...cellDOMProps }
        })}
        dataSource={newGridRecords}
        defaultFilterValue={!recordsLoading && newGridRecords.length > 0 && totalDynamicFilters}
        defaultHideFilteringRow={true}
        defaultSortInfo={defaultSortInfo}
        editStartEvent="click"
        enableKeyboardNavigation={false}
        enableSelection={false}
        filterTypes={filterTypes}
        i18n={tableTranslation[language]}
        idProperty="id"
        licenseKey={configValues.REACT_APP_GRID_LICENSE_KEY}
        limit={RECORDS_PER_PAGE}
        loading={recordsLoading}
        loadingText={<></>}
        nativeScroll
        pagination="local"
        renderPaginationToolbar={renderPaginationToolbar}
        renderSortTool={renderSortTool}
        reorderColumns={true}
        rowHeight={38}
        rtl={isRtl}
        scrollTopOnFilter={false}
        showColumnMenuGroupOptions={false}
        showColumnMenuLockOptions={false}
        showZebraRows={false}
        // showEmptyRows={true}
        sortInfo={[{ dir: 1, name: 'eventOrder' }]}
        style={gridStyle}
        theme="default-light"
        treeColumn="name"
        virtualizeColumns={false}
        onEditComplete={onEditComplete}
        onReady={setGridRef}
      />
    </>
  )
})

GridView.displayName = 'GridView'

export default GridView
