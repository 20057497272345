import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from '../Buttons'
import { ExportIcon } from '../Icons'
import { downloadBlob } from '../../utils/helpers/admin'
import Dropdown from '../inputs/Dropdown'

interface SearchHeaderPropsType {
  columns: any[];
  setDataSource?: () => void;
  setSearchText?: () => void;
  dataSource: [];
  gridRef: string | null;
  searchText: string | null;
  fileName: string | 'file.csv';
  dropdownOptions: any[];
  onChange: (value: any) => void;
  status: any;
  showDropdown?: boolean;
  title: string;
}

const SearchHeader: FC<SearchHeaderPropsType> = ({
  setDataSource,
  gridRef,
  searchText,
  setSearchText,
  dataSource,
  fileName,
  columns,
  dropdownOptions,
  onChange,
  status,
  showDropdown = false,
  title,
}) => {
  const { t } = useTranslation()
  const searchTextRef = useRef(searchText)
  searchTextRef.current = searchText

  const exportCSV = () => {
    const SEPARATOR = ','
    const header = columns?.map((c) => c.name).join(SEPARATOR)
    //to get all data we should replace "gridRef.current.data" with usersDataSource
    const rows = dataSource?.map((data) => columns?.map((c) => data[c.name]).join(SEPARATOR))

    const contents = [header].concat(rows).join('\n')
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' })
    downloadBlob(blob, fileName)
  }

  // const onSearchChange = ({ target: { value } }) => {
  //   const visibleColumns = gridRef?.current?.visibleColumns
  //
  //   const lowerSearchText = value && value.toLowerCase()
  //   const newData = dataSource?.filter((p) => {
  //     return visibleColumns?.reduce((acc, col) => {
  //       const v = (p[col.id] + '').toLowerCase() // get string value
  //       return acc || v.indexOf(lowerSearchText) != -1 // make the search case insensitive
  //     }, false)
  //   })
  //
  //   setSearchText(value)
  //   setDataSource(newData)
  // }

  return (
    <div className="flex justify-between w-full px-6 pb-6 bg-white">
      <div className="flex mt-6">
        <h5 className={`${showDropdown ? 'mt-2' : 'mt-2'} mb-4 font-bold`}>{title}</h5>
        {showDropdown && <Dropdown name={'users'} options={dropdownOptions} value={status.value} onChange={onChange} />}
      </div>
      <div className="mt-6">
        <Button icon={ExportIcon} variant={ButtonVariant.Outline} onClick={exportCSV}>
          {t('admin:exportCSV')}
        </Button>
      </div>
    </div>
  )
}

export default SearchHeader
