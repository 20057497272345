import React, { FC, useEffect } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button, { ButtonVariant } from 'components/Buttons'
import { CrossSignIcon } from 'components/Icons'
import { getNotificationsList, updateAllNotifications } from '../../features/element'
import NotificationsForm from './NotificationsForm'
import NotificationsSettings from './NotificationsSettings'

interface NotificationsModalProps {
  leftSpace?: boolean;
  isModalOpen: boolean;
  onClose: () => void;
}
const NotificationsModal: FC<NotificationsModalProps> = ({ isModalOpen, onClose, leftSpace = false }) => {
  const { t, i18n } = useTranslation('common')
  const dispatch = useDispatch()
  const isRtl = i18n.language === 'ar'

  useEffect(() => {
    if (isModalOpen) dispatch(getNotificationsList())
  }, [isModalOpen])

  const updateAllNotificationsStatus = () => {
    dispatch(updateAllNotifications({ status: 'readAll' }))
  }

  return (
    <Modal
      className={`fixed top-0 bottom-0 ${isRtl ? 'right-0' : 'left-0'} z-40 flex flex-col h-full p-4' +
          ' bg-white shadow-sm focus:outline-none rounded-ts-none transform w-full sm:w-auto`}
      isOpen={isModalOpen}
      overlayClassName="bg-gray-600/30 inset-0 fixed z-40"
      onRequestClose={onClose}>
      <div className="flex justify-between w-full p-4">
        <h3 className="font-bold">{t('notifications.notification')}</h3>
        <div className="flex gap-3">
          <p className="text-sm font-semibold cursor-pointer hover:text-primary" onClick={updateAllNotificationsStatus}>
            {t('readAll')}
          </p>
          <NotificationsSettings />
          <Button icon={CrossSignIcon} variant={ButtonVariant.Icon} onClick={onClose} style={{ padding: 0 }} />
        </div>
      </div>
      <div className="p-4 overflow-auto">
        <NotificationsForm />
      </div>
    </Modal>
  )
}

export default NotificationsModal
