import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import Loader from 'components/Loader'
import DashboardMain from '../../components/Dashboard/DashboardMain'
import {
  addWidgetToStationDashboard,
  getAvailableStationWidgets,
  getStationDashboard,
  saveStationDashboard,
  useCurrentStation,
  useStationDashboard,
  useStationAllDashboards,
  useStationDashboardAvailableWidgets,
  useStationAllDashboardsLoading,
  getAllDashboardForStation,
  useStationDashboardLoading,
  onCreateNewStationDashboard,
  onDeleteStationDashboard,
  useCurrentDashboardId,
} from '../../features/station'
import StationHeader from '../../components/StationHeader'
import {
  clearStationDashboardPage,
  removeWidgetFromStationDashboard,
  saveStationDashboardLayoutDragChanges,
  saveStationDashboardLayoutResizeChanges,
  updateStationWidgetImage,
  updateStationWidgetTitle,
  updateTextWidgetValue,
  onChangeCurrentDashboardId,
} from '../../features/station/stationSlice'
import { useCurrentWorkspace, useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'
import CustomScroll from '../../components/CustomScroll'
import { useAppDispatch } from '../../store'
import { updateDashboardWidgets } from '../../features/element/elementSlice'

const StationDashboardPage: FC = () => {
  const { t } = useTranslation('dashboard')
  const [isLoading, setIsLoading] = useState(true)
  const { stationId }: { stationId: string } = useParams()
  const workspace = useCurrentWorkspace()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const dashboard = useStationDashboard()
  const stationDetails = useCurrentStation()
  const dashboards = useStationAllDashboards()
  const currentDashboardId = useCurrentDashboardId()
  const dashboardLoading = useStationDashboardLoading()
  const availableWidgets = useStationDashboardAvailableWidgets()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const history = useHistory()
  const { name } = stationDetails || {}

  useEffect(() => {
    if (!isPremiumPlan && workspace !== undefined && stationDetails !== undefined) {
      history.push('/')
    }
  }, [workspace, stationDetails])

  useEffect(() => {
    if (stationId) {
      if (!dashboards) {
        setIsLoading(true)
        appDispatch(getAllDashboardForStation({ id: +stationId, scope: 's' })).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }, [dashboards, stationId])

  useEffect(() => {
    if (stationId) {
      if (currentDashboardId) {
        setIsLoading(true)
        appDispatch(getStationDashboard(currentDashboardId)).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }, [stationId, currentDashboardId])

  useEffect(() => {
    return () => {
      dispatch(clearStationDashboardPage())
    }
  }, [])

  const getAvailableWidgets = useCallback(() => {
    if (stationDetails) {
      dispatch(getAvailableStationWidgets({ elementId: stationDetails.id, elementType: 's' }))
    }
  }, [dispatch, getAvailableStationWidgets, stationDetails])

  const updateTextWidget = useCallback(
    (widgetId: number, description: string, imgBase64?: string) => {
      dispatch(updateTextWidgetValue({ description, widgetId }))
      dispatch(updateDashboardWidgets({ description, id: widgetId, imgBase64 }))
    },
    [dispatch, updateTextWidgetValue],
  )

  const updateDashboardWidgetTitle = useCallback(
    (widgetId: number | string, title: string) => {
      dispatch(updateStationWidgetTitle({ title, widgetId }))
    },
    [dispatch, updateStationWidgetTitle],
  )

  const onRemoveDashboard = (id: string | number) => {
    const dashboardsList = dashboards.filter((dash) => +dash.id !== +id)
    dispatch(onChangeCurrentDashboardId(dashboardsList[0].id))
    dispatch(onDeleteStationDashboard(+id))
  }

  return (
    <div className="flex flex-col min-h-screen -m-6 md:-m-10">
      <StationHeader isDashboardPage stationName={name} title={t('dashboardTitle')} />
      <CustomScroll maxHeight={'90%'}>
        <div>
          {isLoading ? (
            <Loader loaderClasses="w-full flex items-center justify-center h-full p-2" svgClasses="w-12" />
          ) : (
            <DashboardMain
              addWidgetToDashboard={addWidgetToStationDashboard}
              availableWidgets={availableWidgets}
              currentItem={stationDetails}
              currentStation={stationDetails}
              currentWorkspace={workspace}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              dashboards={dashboards}
              elementType={'s'}
              getAvailableWidgets={getAvailableWidgets}
              getCurrentItemDashboard={getStationDashboard}
              hideName={true}
              removeWidgetFromDashboard={removeWidgetFromStationDashboard}
              saveCurrentItemDashboard={saveStationDashboard}
              saveDashboardLayoutDragChanges={saveStationDashboardLayoutDragChanges}
              saveDashboardLayoutResizeChanges={saveStationDashboardLayoutResizeChanges}
              showBack={false}
              updateTextWidget={updateTextWidget}
              updateWidgetTitle={updateDashboardWidgetTitle}
              onChangeDashboardId={onChangeCurrentDashboardId}
              onCreateDashboard={onCreateNewStationDashboard}
              onRemoveDashboard={onRemoveDashboard}
              onUpdateWidgetImage={updateStationWidgetImage}
            />
          )}
        </div>
      </CustomScroll>
    </div>
  )
}

export default StationDashboardPage
