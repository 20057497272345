import { PayloadAction } from '@reduxjs/toolkit'

import { ProfilePropsType } from 'utils/types'
import { UserStateType } from 'utils/types/states'

export function updateStateUser(state: UserStateType, action: PayloadAction<ProfilePropsType>): void {
  if (action.payload) {
    const {
      firstName,
      lastName,
      email,
      avatar,
      city,
      link,
      phoneNumber,
      verified,
      dateOfBirth,
      id,
      gender,
      occupation,
      otherOccupation,
      companyName,
      companySize,
      country,
      isPasswordless,
      isHijri,
      isAdmin,
      language,
      emailNotificationPreference,
    } = action.payload

    state.user = {
      avatar: link || avatar,
      city,
      companyName: companyName || undefined,
      companySize,
      country,
      dateOfBirth,
      email,
      firstName,
      gender,
      id,
      isAdmin,
      isHijri,
      isPasswordless,
      lastName,
      occupation,
      otherOccupation,
      phoneNumber,
      verified,
      language,
      emailNotificationPreference,
    }
    state.loading = false
    state.loadingCurrentUser = false
  }
}

export const mapToSelectWithId = (data: { name: string, id: number | string }[]) =>
  data.map((el) => ({ label: el.name, value: `${el.id}` }))
