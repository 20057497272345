import React, { FC } from 'react'
import { useParams, Switch, Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AddListToStationForm from 'components/forms/AddListToStationForm'
import { CheckerIcon, CustomFieldListIcon, InfoIcon } from 'components/Icons'
import SettingsWithTabs from 'components/SettingsWithTabs'

import { withViewerRole } from 'hocs/withRole'
import { useCurrentListCreatedAt } from 'features/list'
import ListCustomFieldsForm from '../../components/CustomFields/ListCustomFieldsForm'
import { useCurrentUser } from '../../features/user'
import MakerCheckerSettingsPage from '../../components/MakerCheckerPolicy/MakerCheckerSettingsPage'
import { useHasCurrentWorkspaceTeamsPlan } from '../../features/workspace'
import { useRoles } from '../../hooks'

const ListSettingsPage: FC = () => {
  const { workspaceId, stationId, listId }: { workspaceId: string, stationId?: string, listId?: string } = useParams()
  const { t } = useTranslation('lists')
  const user = useCurrentUser()
  const { isEditor, isAdmin } = useRoles()
  const currentListCreatedAt = useCurrentListCreatedAt()
  const isTeamsPlan = useHasCurrentWorkspaceTeamsPlan()
  const isViewer = !isAdmin && !isEditor
  const links = [
    {
      description: t('listSettings:listDetailsDescription'),
      Icon: InfoIcon,
      title: t('listSettings:listDetails'),
      to: `/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/details`,
    },
    {
      description: t('customFields:customFieldsDescription'),
      Icon: CustomFieldListIcon,
      title: t('customFields:fields'),
      to: `/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/fields`,
    },
  ]
  const teamsPlan = [
    {
      description: t('listSettings:makerCheckerPolicyDescription'),
      Icon: CheckerIcon,
      title: t('listSettings:makerCheckerPolicy'),
      to: `/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/checkers`,
    },
  ]
  const linksList = isTeamsPlan ? [...links, ...teamsPlan] : links
  if (!workspaceId || !stationId || !listId) return null

  return (
    <div className="flex flex-1 w-full m-5">
      <>
        <div className="flex flex-col flex-1 bg-white rounded-ts-none rounded-2xl lg:flex-row">
          <SettingsWithTabs createdAt={currentListCreatedAt} links={linksList} />
          <Switch>
            <Route path="/workspace/:workspaceId/stations/:stationId/lists/:listId/details">
              <AddListToStationForm closePanel={() => console.log('close panel')} isViewer={isViewer} user={user} />
            </Route>
            <Route path="/workspace/:workspaceId/stations/:stationId/lists/:listId/fields">
              <ListCustomFieldsForm
                closePanel={() => console.log('close panel')}
                isViewer={isViewer}
                stationId={stationId}
                workspaceId={workspaceId}
              />
            </Route>
            {isTeamsPlan && (
              <Route path="/workspace/:workspaceId/stations/:stationId/lists/:listId/checkers">
                <MakerCheckerSettingsPage
                  closePanel={() => console.log('close panel')}
                  stationId={stationId}
                  workspaceId={workspaceId}
                />
              </Route>
            )}
            <Route>
              <Redirect to={`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/details`} />
            </Route>
          </Switch>
        </div>
      </>
    </div>
  )
}

export default withViewerRole(ListSettingsPage)
