import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, KeyboardEvent, FC, useState } from 'react'

import Button, { ButtonVariant } from 'components/Buttons'
import { Input } from 'components/inputs'
import { withEditorRole } from 'hocs/withRole'
import { AddIcon } from '../Icons'

const AddNewColumn: FC<{ isAllowed: boolean, addColumn: (value: string) => void }> = ({ isAllowed, addColumn }) => {
  const [isInputVisible, setInputVisibility] = useState<boolean>(false)
  const { t } = useTranslation('records')

  if (!isAllowed) return null

  const handleAddColumn = (value: string | null) => {
    if (value) {
      const trimmedValue = value.trim()
      addColumn(trimmedValue)
    }
    setInputVisibility(false)
  }

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    handleAddColumn(event.target.value)
  }

  const onPressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key, target }: { key: string, target: any } = event
    if (key === 'Enter') handleAddColumn(target.value)
  }

  return (
    <div className="flex-grow shrink-0 w-64 h-full border-gray-300 px-2.5">
      {isInputVisible ? (
        <Input autoFocus name="" onBlur={onBlur} onKeyDown={onPressEnter} />
      ) : (
        <Button icon={AddIcon} small variant={ButtonVariant.Ghost} onClick={() => setInputVisibility(true)}>
          {t('addNewColumns')}
        </Button>
      )}
    </div>
  )
}

export default withEditorRole(AddNewColumn, false, null)
