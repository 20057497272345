import React, { FC, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { statusColors } from 'utils/helpers/colorHelpers'
import { CustomFieldOptionType } from 'utils/types/customFieldsType'
import { OptionTypes } from 'utils/constant/constant/customFields'
import { FieldFormattingProperties } from '../../../utils/constant/enums/customFields'
import OptionRow from './OptionRow'

const OptionItem: FC<CustomFieldOptionType> = ({
  item,
  onDeleteOption,
  moveOption,
  index,
  optionsList,
  setOptionsList,
  showDescription,
  addText,
}) => {
  const ref = useRef(null)
  const color = item?.fieldFormattingStyles?.find(
    (f: any) => +f.styleAttributeType === FieldFormattingProperties.FONT_COLOR,
  )?.styleAttributeValue
  const name = item.optionCaption ? item.optionCaption : item?.value1 !== undefined ? item?.value1[0] : ''
  const [optionValue, setOptionValue] = useState<string>(name)
  const [optionDescription, setOptionDescription] = useState<string>(item.description)
  const [optionColor, setOptionColor] = useState<string>(item.color || `${color}` || statusColors.at(-1)?.value)
  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: {
      ...item,
      index,
      type: OptionTypes.OPTION,
    },
    type: OptionTypes.OPTION,
  })
  const [{ handlerId }, drop] = useDrop({
    accept: OptionTypes.OPTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.optionOrder
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref?.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveOption(item, hoverIndex, optionsList)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.optionOrder = hoverIndex
    },
  })
  drag(drop(ref))

  const onBlur = (key, value) => {
    const options = [...optionsList]
    if (value?.length > 0) {
      const updatedItem = { ...item, [key]: value }
      options[index] = updatedItem
      setOptionsList(options)
    } else {
      onDeleteOption(item, optionsList)
    }
  }
  const optionRowProps = {
    addText,
    colorOptions: statusColors,
    handlerId,
    isDragging,
    key: `${optionValue}_${optionColor}`,
    onBlur,
    onChangeColor: setOptionColor,
    onChangeDescription: setOptionDescription,
    onChangeName: setOptionValue,
    onDeleteOption: () => onDeleteOption(item, optionsList),
    optionColor,
    optionDescription,
    optionRef: ref,
    optionValue,
    showDescription,
  }

  return <OptionRow {...optionRowProps} />
}

export default OptionItem
