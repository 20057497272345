import React from 'react'
import i18n from 'i18n'

import ElementViewBaseTypes from 'utils/types/ElementViewBaseTypes'
import PlansEnum from 'utils/constant/enums/subscription'
import { recordAppElementDefaultAttributes } from 'utils/constant/enums/Aggregate'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { CalendarAgendaIcon, CalendarCardIcon, GridIcon, KanbanIcon, TimelineIcon } from 'components/Icons'

export const getViewIcon = (options: { viewBaseType: ElementViewBaseTypes }) => {
  const { viewBaseType } = options
  switch (viewBaseType) {
    case ElementViewBaseTypes.RecordsGrid:
      return GridIcon

    case ElementViewBaseTypes.RecordsAgenda:
      return CalendarAgendaIcon

    case ElementViewBaseTypes.RecordsCalendar:
      return CalendarCardIcon

    case ElementViewBaseTypes.RecordsTimeLine:
      return TimelineIcon

    default:
      return KanbanIcon
  }
}

export const getViewPlan = (options: { viewBaseType: ElementViewBaseTypes }) => {
  const { viewBaseType } = options
  switch (viewBaseType) {
    case ElementViewBaseTypes.RecordsKanban:
      return PlansEnum.Basic

    case ElementViewBaseTypes.RecordsGrid:
      return PlansEnum.Basic

    case ElementViewBaseTypes.RecordsAgenda:
      return PlansEnum.Basic

    case ElementViewBaseTypes.RecordsCalendar:
      return PlansEnum.Pro

    case ElementViewBaseTypes.RecordsTimeLine:
      return PlansEnum.Pro
  }
}

export const getViewTitleByBaseType = (options: { viewBaseType: ElementViewBaseTypes }) => {
  const { viewBaseType } = options
  switch (viewBaseType) {
    case ElementViewBaseTypes.RecordsKanban:
      return i18n.t('labels.kanban')

    case ElementViewBaseTypes.RecordsGrid:
      return i18n.t('labels.grid')

    case ElementViewBaseTypes.RecordsAgenda:
      return i18n.t('labels.agenda')

    case ElementViewBaseTypes.RecordsCalendar:
      return i18n.t('labels.calendar')

    case ElementViewBaseTypes.RecordsTimeLine:
      return i18n.t('labels.timeline')
  }
}

export const getWhichDateIsVisible = (options: {
  startDateVisibility: AppElementAttributeVisibility,
  endDateVisibility: AppElementAttributeVisibility,
}) => {
  const { startDateVisibility, endDateVisibility } = options
  let datesVisible:
    | recordAppElementDefaultAttributes.startDate
    | recordAppElementDefaultAttributes.endDate
    | 'both'
    | 'none' = 'none'
  if (
    startDateVisibility === AppElementAttributeVisibility.Hidden &&
    endDateVisibility === AppElementAttributeVisibility.Hidden
  ) {
    datesVisible = 'none'
  } else if (
    startDateVisibility !== AppElementAttributeVisibility.Hidden &&
    endDateVisibility === AppElementAttributeVisibility.Hidden
  ) {
    datesVisible = recordAppElementDefaultAttributes.startDate
  } else if (
    startDateVisibility === AppElementAttributeVisibility.Hidden &&
    endDateVisibility !== AppElementAttributeVisibility.Hidden
  ) {
    datesVisible = recordAppElementDefaultAttributes.endDate
  } else {
    datesVisible = 'both'
  }

  return datesVisible
}
