import React, { FC, useMemo, useState, useEffect } from 'react'
import { ProfilePropsType } from 'utils/types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

import { useAppDispatch } from 'store'
import { Datepicker, Dropdown, Input } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import StepsProgress from 'components/StepsProgress'
import { chooseWorkspaceTrailProPlan, fetchWorkspaceById, useCurrentWorkspace } from 'features/workspace'

import { UpdateUserType } from 'utils/types'
import { configValues } from 'utils/appConfig'
import { toBoolean } from 'utils/helpers/generalHelpers'
import baseAPI from 'utils/baseAPI'
import { withContentEditable } from 'hocs'

import {
  getCompanySizeOptions,
  getCountryOptions,
  getGenderOptions,
  getOccupationOptions,
  renderBirthDate,
  renderDropdownValue,
  renderOccupation,
} from 'utils/helpers/profileHelpers'
import { DropdownOptionType } from 'utils/types/inputs'
import {
  birthdayValidationSchema,
  requiredNumberField,
  nullableRequiredTextFieldValidationSchema,
} from '../validationSchemas/singleFieldsValidationSchemas'

type ProfileDetailsFormPropTypes = {
  user: ProfilePropsType,
  updateUser: (values: UpdateUserType) => void,
  isSequentialForm?: boolean,
}

const EditableInputWithButtons = withContentEditable(Input)
const EditableDatepickerWithButtons = withContentEditable(Datepicker)
const EditableDropdownWithButtons = withContentEditable(Dropdown)

const ProfileDetailsForm: FC<ProfileDetailsFormPropTypes> = ({ user, updateUser, isSequentialForm = false }) => {
  const history = useHistory()
  const appDispatch = useAppDispatch()
  const currentWorkspace = useCurrentWorkspace()
  const { t } = useTranslation(['common', 'user'])
  const { companyName, companySize, city, country, dateOfBirth, gender, occupation, otherOccupation } = user
  const [showOccupationInput, setShowOccupationInput] = useState(occupation === 8)
  const [step, setStep] = useState(0)
  const genderOptions = useMemo(() => getGenderOptions(), [])
  const occupationOptions = useMemo(() => getOccupationOptions(), [])
  const companySizeOptions = useMemo(() => getCompanySizeOptions(), [])
  const [countryOptions, setCountryOptions] = useState<DropdownOptionType[]>([])
  const alreadyApplied: boolean = toBoolean(localStorage.getItem('appliedForPro'))
  const [hasApplied, setApplied] = useState(alreadyApplied)

  const isMissingFirstPartField =
    country === null ||
    country === undefined ||
    city === null ||
    city === undefined ||
    city === '' ||
    dateOfBirth === null ||
    dateOfBirth === undefined ||
    gender === null ||
    gender === undefined

  const isMissingSecondPartField =
    companyName === null ||
    companyName === undefined ||
    companyName === '' ||
    companySize === null ||
    companySize === undefined ||
    occupation === null ||
    occupation === undefined

  const isMissingField = isMissingFirstPartField || isMissingSecondPartField

  useEffect(() => {
    getCountryOptions().then((res) => setCountryOptions(res))
  }, [])

  const commonProps = {
    isLabelVisible: true,
    onSubmit: updateUser,
  }

  const applyForTrial = () => {
    baseAPI('api/users/applyForTrial', {
      method: 'POST',
    })
  }

  const onApplyButtonClick = () => {
    if (isMissingField) {
      toast.error(t('validation:errors.pleaseFillOutTheWholeForm'))
    } else {
      localStorage.setItem('appliedForPro', 'true')
      setApplied(() => true)
      applyForTrial()
    }
  }

  const onNextButtonClick = () => {
    switch (step) {
      case 0:
        if (isMissingFirstPartField) {
          toast.error(t('validation:errors.pleaseFillOutTheWholeForm'))
        } else {
          setStep(() => step + 1)
        }
        break

      case 1:
        if (isMissingSecondPartField) {
          toast.error(t('validation:errors.pleaseFillOutTheWholeForm'))
        } else {
          if (currentWorkspace && currentWorkspace.id) {
            setStep(() => step + 1)
            appDispatch(chooseWorkspaceTrailProPlan(currentWorkspace.id)).then(() => {
              appDispatch(fetchWorkspaceById(currentWorkspace.id)).then(() => {
                history.push(`/workspace/${currentWorkspace.id}/plan`)
              })
            })
          } else {
            toast.error('Workspace not available')
          }
        }
        break
    }
  }

  if (isSequentialForm) {
    return (
      <div className="w-full p-8">
        <div className="sm:w-1/2 m-auto mb-10">
          <StepsProgress stepsContent={[1, 2, 3]} progress={step * 50} />
        </div>

        {step === 0 && (
          <>
            <div className="md:flex">
              <div className="md:w-1/2">
                <EditableDropdownWithButtons
                  {...commonProps}
                  label={t('labels.country')}
                  className="-mx-2 -mt-2"
                  name="country"
                  options={countryOptions}
                  renderNotEditingState={(value, props) =>
                    renderDropdownValue({
                      allOptions: countryOptions,
                      label: t('labels.country'),
                      props,
                      selectedOption: value,
                    })
                  }
                  value={country}
                  validationSchema={requiredNumberField('country')}
                  onSubmit={(values) => {
                    values.country = +values.country
                    updateUser(values)
                  }}
                />
              </div>
              <div className="md:w-1/2 md:ms-8">
                <EditableInputWithButtons
                  label={t('labels.city')}
                  className="-mx-2 -mt-2"
                  name="city"
                  notEditingClassName="mb-8 bg-gray-100"
                  style={{ borderColor: '#DDE5ED' }}
                  value={city}
                  validationSchema={nullableRequiredTextFieldValidationSchema('city')}
                  {...commonProps}
                />
              </div>
            </div>
            <div className="md:flex">
              <div className="md:w-1/2">
                <EditableDatepickerWithButtons
                  label={t('labels.dateOfBirth')}
                  className="-mx-2 -mt-2"
                  name="dateOfBirth"
                  renderNotEditingState={renderBirthDate}
                  validationSchema={birthdayValidationSchema}
                  value={dateOfBirth}
                  {...commonProps}
                />
              </div>
              <div className="md:w-1/2 md:ms-8">
                <EditableDropdownWithButtons
                  label={t('labels.gender')}
                  className="-mx-2 -mt-2"
                  name="gender"
                  options={genderOptions}
                  renderNotEditingState={(value, props) =>
                    renderDropdownValue({
                      allOptions: genderOptions,
                      label: t('labels.gender'),
                      props,
                      selectedOption: value,
                    })
                  }
                  value={gender}
                  validationSchema={requiredNumberField('gender')}
                  {...commonProps}
                  onSubmit={(values) => {
                    values.gender = +values.gender
                    updateUser(values)
                  }}
                />
              </div>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className="md:w-1/2 md:pe-4">
              <EditableDropdownWithButtons
                isLabelVisible
                label={t('labels.occupation')}
                className="-mx-2 -mt-2"
                name="occupation"
                options={occupationOptions}
                renderNotEditingState={(value, props) => renderOccupation(value, props, showOccupationInput)}
                value={occupation}
                validationSchema={requiredNumberField('occupation')}
                onChange={(event) => {
                  if (+event.target.value === 8) setShowOccupationInput(true)
                  else setShowOccupationInput(false)
                }}
                onSubmit={(values) => {
                  values.occupation = +values.occupation
                  updateUser(values)
                }}
              />
              {showOccupationInput && (
                <EditableInputWithButtons
                  label={t('user:enterOccupation')}
                  name="otherOccupation"
                  notEditingClassName="mb-8"
                  placeholder={t('user:enterOccupation')}
                  value={otherOccupation}
                  onSubmit={(values) => updateUser({ ...values, occupation: 8 })}
                />
              )}
            </div>
            <div className="md:flex">
              <div className="md:w-1/2">
                <EditableInputWithButtons
                  label={t('labels.companyName')}
                  className="-mx-2 -mt-2"
                  name="companyName"
                  notEditingClassName="mb-8 bg-gray-100"
                  style={{ borderColor: '#DDE5ED' }}
                  value={companyName}
                  validationSchema={nullableRequiredTextFieldValidationSchema('companyName')}
                  {...commonProps}
                />
              </div>
              <div className="md:w-1/2 md:ms-8">
                <EditableDropdownWithButtons
                  label={t('labels.companySize')}
                  className="-mx-2 -mt-2"
                  name="companySize"
                  options={companySizeOptions}
                  renderNotEditingState={(value, props) =>
                    renderDropdownValue({
                      allOptions: companySizeOptions,
                      label: t('labels.companySize'),
                      props,
                      selectedOption: value,
                    })
                  }
                  value={companySize}
                  validationSchema={requiredNumberField('companySize')}
                  {...commonProps}
                  onSubmit={(values) => {
                    values.companySize = +values.companySize
                    updateUser(values)
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="flex justify-end">
          {step !== 0 && (
            <Button variant={ButtonVariant.Ghost} onClick={() => setStep(step - 1)}>
              {t('previous')}
            </Button>
          )}
          <Button className="ms-2" variant={ButtonVariant.Primary} onClick={onNextButtonClick}>
            {step === 1 ? t('subscription:startFreeTrial') : t('next')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full p-8 overflow-auto">
      <h1 className="mb-6 text-lg">{t('common:labels.details')}</h1>
      <div className="md:flex">
        <div className="md:w-1/2">
          <EditableDropdownWithButtons
            {...commonProps}
            label={t('labels.country')}
            className="-mx-2 -mt-2"
            name="country"
            options={countryOptions}
            renderNotEditingState={(value, props) =>
              renderDropdownValue({
                allOptions: countryOptions,
                label: t('labels.country'),
                props,
                selectedOption: value,
              })
            }
            value={country}
            validationSchema={requiredNumberField('country')}
            onSubmit={(values) => {
              values.country = +values.country
              updateUser(values)
            }}
          />
        </div>
        <div className="md:w-1/2 md:ms-8">
          <EditableInputWithButtons
            label={t('labels.city')}
            className="-mx-2 -mt-2"
            name="city"
            notEditingClassName="mb-8 bg-gray-100"
            style={{ borderColor: '#DDE5ED' }}
            value={city}
            validationSchema={nullableRequiredTextFieldValidationSchema('city')}
            {...commonProps}
          />
        </div>
      </div>
      <div className="md:flex">
        <div className="md:w-1/2">
          <EditableDatepickerWithButtons
            label={t('labels.dateOfBirth')}
            className="-mx-2 -mt-2"
            name="dateOfBirth"
            renderNotEditingState={renderBirthDate}
            validationSchema={birthdayValidationSchema}
            value={dateOfBirth}
            {...commonProps}
          />
        </div>
        <div className="md:w-1/2 md:ms-8">
          <EditableDropdownWithButtons
            label={t('labels.gender')}
            className="-mx-2 -mt-2"
            name="gender"
            options={genderOptions}
            renderNotEditingState={(value, props) =>
              renderDropdownValue({
                allOptions: genderOptions,
                label: t('labels.gender'),
                props,
                selectedOption: value,
              })
            }
            value={gender}
            validationSchema={requiredNumberField('gender')}
            {...commonProps}
            onSubmit={(values) => {
              values.gender = +values.gender
              updateUser(values)
            }}
          />
        </div>
      </div>
      <EditableDropdownWithButtons
        isLabelVisible
        label={t('labels.occupation')}
        className="-mx-2 -mt-2"
        name="occupation"
        options={occupationOptions}
        renderNotEditingState={(value, props) => renderOccupation(value, props, showOccupationInput)}
        value={occupation}
        validationSchema={requiredNumberField('occupation')}
        onChange={(event) => {
          if (+event.target.value === 8) setShowOccupationInput(true)
          else setShowOccupationInput(false)
        }}
        onSubmit={(values) => {
          values.occupation = +values.occupation
          updateUser(values)
        }}
      />
      {showOccupationInput && (
        <EditableInputWithButtons
          label={t('user:enterOccupation')}
          name="otherOccupation"
          notEditingClassName="mb-8"
          placeholder={t('user:enterOccupation')}
          value={otherOccupation}
          onSubmit={(values) => updateUser({ ...values, occupation: 8 })}
        />
      )}
      <div className="md:flex">
        <div className="md:w-1/2">
          <EditableInputWithButtons
            label={t('labels.companyName')}
            className="-mx-2 -mt-2"
            name="companyName"
            notEditingClassName="mb-8 bg-gray-100"
            style={{ borderColor: '#DDE5ED' }}
            value={companyName}
            validationSchema={nullableRequiredTextFieldValidationSchema('companyName')}
            {...commonProps}
          />
        </div>
        <div className="md:w-1/2 md:ms-8">
          <EditableDropdownWithButtons
            label={t('labels.companySize')}
            className="-mx-2 -mt-2"
            name="companySize"
            options={companySizeOptions}
            renderNotEditingState={(value, props) =>
              renderDropdownValue({
                allOptions: companySizeOptions,
                label: t('labels.companySize'),
                props,
                selectedOption: value,
              })
            }
            value={companySize}
            validationSchema={requiredNumberField('companySize')}
            {...commonProps}
            onSubmit={(values) => {
              values.companySize = +values.companySize
              updateUser(values)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileDetailsForm
