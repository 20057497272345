import React, { FC, memo, useContext } from 'react'
import { useToggle } from 'react-use'

import { NavbarContextValueType } from 'utils/types'

const NavbarContext = React.createContext<NavbarContextValueType>({
  isMenuVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleMenu: (value) => {},
})

const NavbarProvider: FC<{ children: React.ReactNode }> = memo(({ children }) => {
  const [isMenuVisible, toggleMenu] = useToggle(false)

  return (
    <NavbarContext.Provider
      value={{
        isMenuVisible,
        toggleMenu,
      }}>
      {children}
    </NavbarContext.Provider>
  )
})

NavbarProvider.displayName = 'NavbarProvider'

const useNavbar = () => useContext(NavbarContext)

export { NavbarProvider, useNavbar }
