export enum AppElementViewUserAccess {
  //The user has access to own elements only
  OwnElementsOnly = 0,

  //to be added later
  Collaborator = 1,

  //The user has access to others elements along with his own
  OthersElements = 2,
}
