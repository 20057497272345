import { createAsyncThunk } from '@reduxjs/toolkit'
import { sidebarData } from 'features/station/docSidebarMock'

export const getPublicDocsSidebarData = createAsyncThunk('publicDocs/getSidebarData', async (id: string) => {
  return sidebarData
})

export const getPublicDocsOneItem = createAsyncThunk(
  'publicDocs/getOneItem',
  async ({ id, publicId }: { id: number, publicId: string }) => {
    return id
  },
)
