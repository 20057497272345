import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'
import { useCurrentUser } from '../../features/user'
import { UserType } from '../../utils/types'
import { useLoadElementSubscriber } from '../../features/element'
import Loader from '../Loader'

interface CustomFieldFormProps {
  isTippyInit?: boolean;
  onClose: (e: any) => void;
  customFieldItem?: any;
  onEdit?: (e: any) => void;
  isCurrentUserWatcher: boolean;
  isSubscribeInherited: boolean;
  onClick: (userId: string | number) => void;
  elementMembers: UserType[];
  watchersList: any[];
}

const WatchersForm: FC<CustomFieldFormProps> = ({
  watchersList,
  isCurrentUserWatcher,
  onClick,
  elementMembers,
  isSubscribeInherited,
}) => {
  const { t } = useTranslation('common')
  const isMobile = window.innerWidth <= 760
  const currentUser = useCurrentUser()
  const isStopLoading = useLoadElementSubscriber()

  const renderWatcher = (isTheAdmin: boolean, user: UserType, checked: boolean) => {
    const userName = !isTheAdmin ? user?.firstName + ' ' + user.lastName : t('notifications.mySelf')
    return (
      <div className="flex justify-between">
        <div className="flex">
          <UserAvatarStatus customSize={9} imgSrc={user?.link || user.avatar} userId={user?.id} />
          <div className="-mt-3 ms-2">
            <p className="text-sm font-semibold">{userName}</p>
            <p className="text-sm text-gray-500">{user?.email}</p>
          </div>
        </div>
        <div className="mt-0.5">
          <label className="switch">
            <input
              key={user?.id}
              checked={checked}
              type="checkbox"
              onClick={() => {
                onClick(user?.id)
              }}
            />
            <span className="slider round" />
          </label>
        </div>
      </div>
    )
  }

  const isCurrentUserWatcherTrue = watchersList?.find((watcher) => watcher.userId === currentUser.id) !== undefined

  return (
    <div className="relative w-full p-2 bg-white rounded shadow-md" style={{ minWidth: isMobile ? 270 : 300 }}>
      {!isStopLoading && (
        <div className="flex justify-center mt-5">
          <Loader />
        </div>
      )}
      {isStopLoading && (
        <div className="p-3">
          {renderWatcher(true, currentUser, isCurrentUserWatcherTrue)}
          <div className="w-full mt-2 bg-gray-200 h-0.5" />
          {elementMembers.length === 0 && (
            <div className="flex justify-center mt-8 text-sm opacity-50">{t('notifications.emptyMembers')}</div>
          )}
          <div className="mt-7">
            {elementMembers.map((item, index) => {
              const isUserWatcher = watchersList?.find((watcher) => watcher.userId === item.user.id) !== undefined
              return (
                <div key={index} className="mt-5">
                  {renderWatcher(false, item?.user, isUserWatcher)}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default WatchersForm
