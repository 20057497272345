import React, { useEffect, useRef, useState, useMemo, FC } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FullCalendar from '@fullcalendar/react'
import { EventDropArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import classnames from 'classnames'

import { DragDropContext } from 'react-beautiful-dnd'

import CreateRecordModal from 'components/TimelineEvent/CreateRecordModal'
import CalendarToolbar from 'components/CalendarToolbar'
import { AgendaView } from 'components/AgendaView'
import { Input } from 'components/inputs'

import {
  archiveRecordById,
  createRecord,
  fetchRecordsByParams,
  updateRecordElementById,
  useRecords,
} from 'features/record'
import { useRoles } from 'hooks'
import {
  ArabicMonths,
  calendarLocale,
  calendarLocales,
  getCalendarEvents,
  handleSelect,
  normalToHijri,
  toIndian,
} from 'utils/calendarUtils'
import { format } from 'date-fns'
import { useMedia } from 'react-use'
import { DATE_FORMAT } from '../../utils/helpers/generalHelpers'
import { useCurrentUser } from '../../features/user'
import DndTaskList from '../../components/TaskList/DndTaskList'
import TaskList from '../../components/TaskList/TaskList'
import { useCurrentList } from '../../features/list'
import { generateChangesObject } from '../../utils/helpers/timeLineHelpers'
import { RecordType } from '../../utils/types'
import Button, { ButtonVariant } from '../../components/Buttons'
import ConfirmationModal from '../../components/ConfirmationModal'
import EventContent from './EventContent'

interface GeneralCalendarPageType {
  isDefaultViewAgenda?: boolean;
  initialView: string;
  defaultCurrentView: string;
}

const GeneralCalendarPage: FC<GeneralCalendarPageType> = ({ isDefaultViewAgenda, initialView, defaultCurrentView }) => {
  const { t } = useTranslation()
  const { listId, viewId }: { listId: string, viewId: string } = useParams()
  const [currentView, setCurrentView] = useState<string>(defaultCurrentView)
  const [isCreatRecord, setIsCreatRecord] = useState<boolean>(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [currentItem, setCurrentItem] = useState<any>(null)
  const [newRecordData, setNewRecordData] = useState<{ endDate: string, startDate: string, ownerId: string } | null>(
    null,
  )
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [isTaskListVisible, setIsTaskListVisible] = useState<boolean>(false)
  const [selectedEndDate, setSelectedEndDate] = useState<Date>()
  const [selectedDateElement, setSelectedDateElement] = useState<Element>()
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false)
  const { i18n } = useTranslation('records')
  const dispatch = useDispatch()
  const records = useRecords()
  const history = useHistory()
  const location = useLocation()
  const { isEditor } = useRoles()
  const inputRef = useRef<HTMLInputElement>(null)
  const calendarRef = useRef<HTMLDivElement>(null)
  const user = useCurrentUser()
  const language = i18n.language
  const isMobile = useMedia('(max-width: 767px)')
  const currentList = useCurrentList()
  const selectedRecord = records.find(({ id }) => id === +currentItem?.publicId)
  const isArchived = selectedRecord && selectedRecord?.appElements[0]?.isArchived

  useEffect(() => {
    const handleClickOutSideMenu = () => setOpenMenu(false)
    window.addEventListener('click', handleClickOutSideMenu)
    return () => {
      setCurrentItem(null)
      setMenuPosition(null)
      window.removeEventListener('click', handleClickOutSideMenu)
    }
  }, [])

  const dayCellContentNormal = (info, create) => {
    const date = new Date(info.date)
    const hijriDate = normalToHijri(info.date)
    const day = date.getDate()
    const today = format(new Date(), DATE_FORMAT)
    const isToday = today === format(new Date(info.date), DATE_FORMAT)
    const hijriMonthName = isMobile
      ? language === 'en'
        ? ArabicMonths[hijriDate.month].enShort
        : ArabicMonths[hijriDate.month].ar
      : ArabicMonths[hijriDate.month][language]
    const element = create(
      'div',
      { style: { minWidth: '100%', width: '100%' } },
      <div className={`justify-between w-full flex`} dir={'ltr'}>
        <div className={isToday ? 'today-custom' : null}>{day}</div> {/*{!isToday ? (*/}
        <div className="text-xs mt-0.5">
          {' ( '} {hijriDate.day === 1 ? hijriMonthName : language === 'ar' ? toIndian(hijriDate.day) : hijriDate.day}{' '}
          {' ) '}
        </div>
      </div>,
    )
    return element
  }

  useEffect(() => {
    dispatch(fetchRecordsByParams({ archiveStatus: currentList?.appElement?.isArchived, listId, viewId }))
  }, [dispatch, listId, currentList, viewId])

  const calendarEvents = useMemo(
    () => getCalendarEvents(records, false, user?.isHijri !== null ? user?.isHijri : false),
    [records, user],
  )
  const handleEdit = (values: any) => {
    const selectedRecord = records.find((record) => +record.id === +values.id)
    if (selectedRecord) {
      history.push(`${location.pathname}?recordId=${selectedRecord.id}`)
    }
  }

  const updateElementById = ({
    idToUpdate,
    startDate,
    endDate,
  }: {
    idToUpdate: number,
    startDate: Date | null,
    endDate: Date | null,
  }) => {
    const updatedElement = records.find(({ id }) => +id === +idToUpdate)

    if (updatedElement) {
      dispatch(
        updateRecordElementById({
          changes: generateChangesObject(updatedElement, { endDate, startDate }),
          elementId: updatedElement?.appElements[0]?.id,
          record: updatedElement,
          viewId,
        }),
      )
    }
  }

  const onDrop = ({
    draggableId: eventId,
    destination,
  }: {
    draggableId: string,
    destination: { droppableId: string },
  }) => {
    const defaultDate = initialView === 'agendaView' ? calendarRef?.current?.getApi()?.getDate() : null
    const { droppableId: endDate } = destination || { droppableId: defaultDate }
    const initialRecord = records.find(({ id }) => id === +eventId)
    const newEndDate = destination ? new Date(JSON.parse(endDate)) : new Date(endDate)
    let newStartDate = null
    if (initialRecord?.startDate) {
      newStartDate = new Date(initialRecord?.startDate) > newEndDate ? newEndDate : initialRecord?.startDate
    }

    updateElementById({
      endDate: endDate ? newEndDate : null,
      idToUpdate: +eventId,
      startDate: newStartDate,
    })
  }

  const NewEventPortal = ({ children, parent }: { children: React.ReactNode, parent?: Element }) => {
    return parent ? ReactDOM.createPortal(children, parent) : <></>
  }

  const onSelect = (event: any) => {
    //TODO:Do as timeline
    event.dayEl.classList.add('calendar__date-clicked')
    setSelectedEndDate(event.date)
    setIsInputVisible(true)
    setSelectedDateElement(event.dayEl.firstChild)
    inputRef.current?.focus()
  }

  const createNewRecord = ({ name }: { name: string }) => {
    dispatch(createRecord({ endDate: selectedEndDate, listId: +listId, name, parentId: +currentList?.appElement?.id }))
    setIsInputVisible(false)
  }

  const onRightClickItem = (item: RecordType, position: { x: number, y: number }) => {
    setOpenMenu(!openMenu)
    setCurrentItem(item)
    setMenuPosition(position)
  }

  const onArchive = () => {
    const recursive = 0
    dispatch(
      archiveRecordById({ elementId: +selectedRecord?.appElements[0]?.id, recursive, status: Number(!isArchived) }),
    )
    setIsArchiveModalOpen(false)
    setOpenMenu(false)
  }

  const onCreateRecord = (event: any) => {
    const ownerId = +event.resource?._resource?.id || null
    setNewRecordData({
      endDate: new Date(event.date) || undefined,
      listId,
      ownerId,
      startDate: undefined,
    })
    setIsCreatRecord(true)
  }

  return (
    <>
      <CreateRecordModal isOpen={isCreatRecord} recordData={newRecordData} setIsCreatRecord={setIsCreatRecord} />

      <DragDropContext onDragEnd={onDrop}>
        <div className="flex flex-1 p-4 overflow-hidden">
          <CalendarToolbar
            calendarRef={calendarRef?.current}
            currentViewType={currentView}
            isDefaultViewAgenda={isDefaultViewAgenda}
            isTaskListVisible={isTaskListVisible}
            setCurrentViewType={setCurrentView}
            setIsTaskListVisible={setIsTaskListVisible}
          />
          {isInputVisible && (
            <NewEventPortal parent={selectedDateElement}>
              <Input
                classes={{ wrapper: 'mb-0' }}
                name="name"
                ref={inputRef}
                onBlur={(event) => {
                  createNewRecord({ name: event.target.value })
                }}
              />
            </NewEventPortal>
          )}
          <div className={classnames('w-full overflow-auto', { 'calendar-width': currentView !== 'agenda' })}>
            <FullCalendar
              dateClick={(event) => {
                // if (isEditor) onSelect(event)
                if (isEditor) onCreateRecord(event)
              }}
              dayCellClassNames="calendar__day-cell"
              dayCellContent={dayCellContentNormal}
              dayHeaderClassNames="calendar__day-header"
              dayHeaderFormat={{ weekday: 'long' }}
              dayMaxEventRows={currentView === 'month' ? 3 : Infinity}
              dayPopoverFormat={{ day: 'numeric' }}
              drop={(values: any) => {
                const updatedId = values.draggedEl.getAttribute('id')
                updateElementById({ endDate: values.date, idToUpdate: updatedId, startDate: null })
              }}
              droppable={isEditor}
              editable={isEditor}
              eventClassNames="calendar__event"
              eventClick={(event) => {
                handleEdit(event.event)
              }}
              eventContent={(props) => {
                return <EventContent {...props} isMobile={isMobile} language={language} />
              }}
              eventDidMount={(arg) => {
                arg.el.addEventListener('contextmenu', (e) => {
                  e.preventDefault()
                  onRightClickItem(arg.event._def, { x: e.pageX, y: e.pageY })
                })
              }}
              eventDrop={({ event }: EventDropArg) => {
                const initialRecord = records.find(({ id }) => id === +event.id)
                if (initialRecord) {
                  if (event.start && new Date(initialRecord.startDate) > event.start) {
                    updateElementById({ endDate: event.start, idToUpdate: +event.id, startDate: event.start })
                  } else {
                    updateElementById({
                      endDate: event.start,
                      idToUpdate: +event.id,
                      startDate: initialRecord.startDate && new Date(initialRecord.startDate),
                    })
                  }
                }
              }}
              eventOrder={'eventOrder'}
              eventReceive={({ revert }) => {
                revert()
              }}
              eventWillUnmount={(arg) => {
                arg.el.removeEventListener('contextmenu', (e) => {
                  e.preventDefault()
                  onRightClickItem(arg.event._def, { x: e.pageX, y: e.pageY })
                })
              }}
              events={calendarEvents}
              firstDay={0}
              fixedWeekCount={false}
              headerToolbar={{
                center: '',
                end: '',
                start: '',
              }}
              height="100%"
              initialView={initialView}
              locale={calendarLocale[i18n.language]}
              locales={calendarLocales}
              plugins={[dayGridPlugin, interactionPlugin, AgendaView]}
              ref={calendarRef}
              select={(event) => {
                const diff = event.end.valueOf() - event.start.valueOf()
                const isLessThanOneDay = diff / 1000 / 60 / 60 <= 24
                if (!isLessThanOneDay) {
                  handleSelect(event, history, location)
                }
              }}
              selectable={isEditor}
              style={{ backgroundColor: 'green' }}
              viewClassNames="calendar"
              views={{ agendaView: { duration: { months: 1 } } }}
              // onEventRender={(event: any) => {
              //   if (event.isSubtask) {
              //     event.element.style.background = '#000'
              //   }
              // }}
            />
          </div>
        </div>
        {isTaskListVisible && (currentView === 'agenda' ? <DndTaskList /> : <TaskList />)}
      </DragDropContext>
      {openMenu && (
        <div className="menuCard" style={{ left: menuPosition?.x, top: menuPosition?.y }}>
          <Button
            className="text-primary"
            small
            variant={ButtonVariant.Text}
            onClick={() => {
              const recordUrl = `${location.pathname}${
                location.search
                  ? `${location.search}&recordId=${currentItem.publicId}`
                  : `?recordId=${currentItem.publicId}`
              }`
              history.push(recordUrl)
            }}>
            {t('common:labels.open')}
          </Button>
          <Button
            className="text-danger"
            small
            variant={ButtonVariant.Text}
            onClick={() => setIsArchiveModalOpen(true)}>
            {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
          </Button>
        </div>
      )}
      <ConfirmationModal
        confirmMessage={isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
        confirmationMessage={t(isArchived ? 'records:confirmRecordUnarchive' : 'records:confirmRecordArchive', {
          interpolation: { escapeValue: false },
          name: currentItem?.title,
        })}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={onArchive}
      />
    </>
  )
}

export default GeneralCalendarPage
