import CommentList from 'components/CommentList'
import React, { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCommentForm } from 'components/forms'
import { SortAscendingIcon, SortDescendingIcon } from 'components/Icons'
import { useDispatch } from 'react-redux'
import { useShowLatest } from '../../../features/station'
import { toggleShowLatest } from '../../../features/station/stationSlice'

const DocumentationDocumentComments: FC<{ documentId: string }> = ({ documentId }) => {
  const showLatest = useShowLatest()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div>
      <div className="flex justify-between mb-6">
        <h2 className="font-semibold">{t('records:listing.comments')}</h2>

        <button
          className="flex items-center text-sm font-bold ms-auto transition-colors hover:text-primary"
          onClick={() => dispatch(toggleShowLatest())}>
          {showLatest ? (
            <>
              {t('records:listing.newest')}
              <SortDescendingIcon className="w-6 ms-1" />
            </>
          ) : (
            <>
              {t('records:listing.oldest')}
              <SortAscendingIcon className="w-6 ms-1" />
            </>
          )}
        </button>
      </div>
      <div className="flex flex-col">
        <AddCommentForm documentId={documentId} showLatest={showLatest} elementId={documentId} />
        <CommentList documentId={documentId} showLatest={showLatest} elementId={documentId} />
      </div>
    </div>
  )
}

export default memo(DocumentationDocumentComments)
