import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchWorkspaceById } from 'features/workspace'
import { fetchUsersByWorkspaceId } from 'features/user'
import { clearCurrentWorkspace } from 'features/workspace/workspaceSlice'
import WorkspaceDashboardPage from 'pages/workspace/WorkspaceDashboard'
import WorkspaceSettingsPage from 'pages/workspace/WorkspaceSettings'
import StationRouter from 'components/Layout/StationRouter'
import Loader from 'components/Loader'
import WorkspacePaymentPage from '../../pages/workspace/WorkspacePaymentPage'
import WorkspacePlansListPage from '../../pages/workspace/WorkspacePlansListPage'
import { useAppDispatch } from '../../store'

const WorkspaceRouter: FC = () => {
  const {
    path,
    params: { workspaceId },
  }: { path: string, params: { workspaceId: string } } = useRouteMatch()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const [localLoader, setLocalLoader] = useState(true)

  useEffect(() => {
    appDispatch(fetchWorkspaceById(workspaceId)).finally(() => setLocalLoader(false))
    dispatch(fetchUsersByWorkspaceId({ workspaceId }))
    return () => {
      dispatch(clearCurrentWorkspace())
    }
  }, [dispatch, workspaceId])

  if (localLoader) return <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />

  return (
    <Switch>
      <Route path={`${path}/stations`}>
        <StationRouter />
      </Route>
      <Route
        exact
        path={[
          `${path}/details`,
          `${path}/users`,
          `${path}/plan`,
          `${path}/billing`,
          `${path}/pro-trial`,
          `${path}/fields`,
        ]}>
        <WorkspaceSettingsPage />
      </Route>
      <Route path={`${path}/change-plane`}>
        <WorkspacePaymentPage />
      </Route>
      <Route path={`${path}/manage-billing`}>
        <WorkspacePaymentPage />
      </Route>
      <Route path={`${path}/plans-list`}>
        <WorkspacePlansListPage />
      </Route>
      <Route path={`${path}/dashboard`}>
        <WorkspaceDashboardPage />
      </Route>
      <Redirect to="/" />
    </Switch>
  )
}
export default WorkspaceRouter
