import React, { FC } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import classnames from 'classnames'

const Link: FC<LinkProps> = ({ children, to, className, ...rest }) => (
  <RouterLink
    className={classnames(
      'text-primary-dark transition transition-colors hover:text-primary focus:text-primary',
      className,
    )}
    to={to}
    {...rest}>
    {children}
  </RouterLink>
)
export default Link
