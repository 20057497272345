import react, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import { format } from 'date-fns'
import utf8 from 'utf8'

import { getAttachmentProperties } from 'utils/helpers/attachmentHelpers'
import { NewAttachmentType, SingleAttachmentPropsType } from 'utils/types'
import baseAPI from 'utils/baseAPI'
import { DATE_FORMAT } from 'utils/helpers/generalHelpers'
import { configValues } from 'utils/appConfig'

import { removeAttachment } from 'features/attachment'
import { useCurrentUser } from 'features/user'

import { TrashIcon } from 'components/Icons'
import Loader from 'components/Loader'

const SingleAttachment: FC<SingleAttachmentPropsType> = ({
  attachment,
  isEditor,
  assetId,
  isDocumentation = false,
  isRecordComment = false,
  onDeleteAttachment,
  detailsClasses = 'absolute bottom-0 w-full p-1 text-xs text-gray-700 bg-white/80 leading-3',
}) => {
  const [isDownloading, setDownloading] = useState(false)
  const dispatch = useDispatch()
  const currentUserId = useCurrentUser()?.id
  const { fileType, fileName, id, createdAt, publicUrl, fileExtension, fileSizeInMb, fileSizeInKb, creatorId } =
    getAttachmentProperties(attachment)
  const canDelete = isRecordComment ? creatorId == currentUserId : isEditor
  let decodedFileName = ''
  let nameWithoutExtension = ''
  try {
    decodedFileName = utf8.decode(fileName)
    nameWithoutExtension = decodedFileName.split('.').slice(0, -1)[0]
  } catch (e) {
    decodedFileName = fileName
    nameWithoutExtension = 'file'
  }

  const onDelete = (attachmentId: number) => () => {
    if (onDeleteAttachment) {
      onDeleteAttachment(assetId)
    } else {
      dispatch(removeAttachment(attachmentId))
    }
  }

  const downloadFile = async (attachmentId: number, fileName: string) => {
    let url = `api/attachments/download/${attachmentId}`
    if (isDocumentation || isRecordComment) {
      url = `api/element/assets/${attachmentId}/download`
    }
    setDownloading(true)
    baseAPI(url)
      .then((response: any) => {
        if (+response.status >= 400) throw response.message
        response.blob?.then((file: any) => {
          fileDownload(file, fileName)
        })
        setDownloading(false)
      })
      .catch(() => {
        setDownloading(false)
      })
  }

  const thumbnail = () => {
    if (isDownloading) {
      return (
        <Loader loaderClasses="flex items-center justify-center h-full w-full p-14 font-bold text-gray-600 uppercase group-hover:opacity-75 transition transition-color" />
      )
    } else {
      const type = attachment.fileMimeType || fileType
      return type.startsWith('image') && !configValues.REACT_APP_IS_SECURE_CLOUD ? (
        <img
          alt={decodedFileName}
          className="object-cover w-full h-full group-hover:opacity-75 transition transition-opacity"
          src={publicUrl}
        />
      ) : (
        <p className="flex items-center justify-center h-full pb-6 font-bold text-gray-600 uppercase group-hover:opacity-75 transition transition-color">
          {fileExtension || attachment?.fileExtension}
        </p>
      )
    }
  }

  return (
    <div key={id} className={`relative w-1/2 mb-2 pe-2 ${isDocumentation ? 'md:w-1/2' : 'md:w-1/4'} group`}>
      {canDelete && (
        <button
          className="absolute top-0 z-10 p-2 bg-white opacity-0 rounded-xs end-0 group-hover:opacity-100 hover:text-danger transition-opacity"
          type="button"
          onClick={onDelete(id)}>
          <TrashIcon className="w-4 transition-colors" />
        </button>
      )}
      {configValues.REACT_APP_IS_SECURE_CLOUD ? (
        <div className="cursor-pointer" onClick={() => downloadFile(attachment.id, decodedFileName)}>
          <div className="relative overflow-hidden bg-gray-200 rounded h-28">
            {thumbnail()}
            <div className={detailsClasses}>
              <div className="flex mb-1 font-bold">
                <span className="truncate">{nameWithoutExtension}</span>.{fileExtension}
              </div>
              <div className="flex justify-between">
                <span className="truncate" dir="ltr">
                  {format(new Date(createdAt), DATE_FORMAT)}
                </span>
                <span>{fileSizeInMb ? `${fileSizeInMb}MB` : `${fileSizeInKb}KB`}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <a download={decodedFileName} href={publicUrl} rel="noreferrer" target="_blank">
          <div className="relative overflow-hidden bg-gray-200 rounded h-28">
            {thumbnail()}
            <div className={detailsClasses}>
              <div className="flex mb-1 font-bold">
                <span className="truncate">{nameWithoutExtension}</span>.{fileExtension}
              </div>
              <div className="flex justify-between">
                <span className="truncate" dir="ltr">
                  {format(new Date(createdAt), DATE_FORMAT)}
                </span>
                <span>{fileSizeInMb ? `${fileSizeInMb}MB` : `${fileSizeInKb}KB`}</span>
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  )
}

const NewAttachment = ({
  attachment,
  detailsClasses = 'absolute bottom-0 w-full p-1 text-xs text-gray-700 bg-white/80 leading-3',
}: {
  attachment: NewAttachmentType,
  detailsClasses?: string,
}) => {
  const { fileExtension, fileSizeInKb, fileSizeInMb, fileName } = getAttachmentProperties(attachment)
  return (
    <div className={detailsClasses}>
      <div className="flex mb-1 font-bold">
        <span className="truncate">{fileName}</span>.{fileExtension}
      </div>
      <div className="flex justify-between">
        <span className="truncate" dir="ltr">
          {format(new Date(), DATE_FORMAT)}
        </span>
        <span>{fileSizeInMb ? `${fileSizeInMb}MB` : `${fileSizeInKb}KB`}</span>
      </div>
    </div>
  )
}

export { NewAttachment }
export default SingleAttachment
