import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DashboardStateWidgetType, { TeamMonitor } from 'utils/types/states/DashboardStateWidgetType'
import { useSize } from 'react-use'
import { mockGetResponse } from 'features/workspace/dashboardMock'
import CustomScroll from '../../../CustomScroll'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import TeamMonitorWidgetCard from './TeamMonitorWidgetCard'

const TeamMonitorWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isEdit: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const data: any = widget?.data ? widget.data : mockGetResponse.widgets[6].data
  const monitorWidgetData: TeamMonitor = data
  const { i18n } = useTranslation('dashboard')
  const isRtl = i18n.language === 'ar'
  const [chosenNavItem, setChosenNavItem] = useState(monitorWidgetData.values[0])
  const [sized, { width }] = useSize(({ width }) => <div />)
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  const gridConfig = useCallback(() => {
    if (width < 700) {
      return 'grid-cols-1 gap-4'
    }
    if (width > 1000) {
      return 'grid-cols-3 gap-4'
    }

    return 'grid-cols-2 gap-4'
  }, [width])

  return (
    <>
      {sized}
      {isDataLoading ? (
        <Loader />
      ) : (
        <CustomScroll>
          {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
            <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          )}
          <div className="flex items-center justify-start">
            {/* <Swiper
          dir="auto"
          slidesPerView="auto"
          spaceBetween={24}
          style={{ marginLeft: isRtl ? 'auto' : 0, marginRight: isRtl ? 0 : 'auto' }}>
          {monitorWidgetData.values.map((item: any, idx) => (
            <SwiperSlide key={item.id + idx} style={{ width: 160 }} onClick={() => setChosenNavItem(item)}>
              <div
                className="flex p-4 border rounded-lg border-gray-30"
                style={{
                  backgroundColor: chosenNavItem.id === item.id ? '#E7FCFB' : 'transparent',
                  direction: isRtl ? 'rtl' : 'ltr',
                }}>
                <div
                  className="flex items-center justify-center rounded-full w-14 h-14"
                  style={{ backgroundColor: chosenNavItem.id === item.id ? '#23C2BA' : '#DDE5ED' }}>
                  {item.icon ? (
                    <img alt="icon" src={item.icon} />
                  ) : (
                    <UsersFilledIcon color={chosenNavItem.id === item.id ? 'white' : 'black'} />
                  )}
                </div>
                <div className="ms-2">
                  <h1>{item.count}</h1>
                  <h3 className="text-gray-600">{item.title}</h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}
          </div>

          <div className={`mt-12 grid ${gridConfig()}`}>
            {chosenNavItem.items.map((card: any, idx: number) => (
              <TeamMonitorWidgetCard key={idx + card.username} card={card} />
            ))}
          </div>
        </CustomScroll>
      )}
    </>
  )
}

export default memo(TeamMonitorWidget)
