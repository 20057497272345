import React, { FC, useRef } from 'react'
import CreatableSelect, { Creatable } from 'react-select/creatable'
import Select from 'react-select'
import { OptionTypeBase } from 'react-select'

const MultiSelectDropDown: FC<{
  placeholder: string,
  name: string,
  label?: string,
  autoFocus?: boolean,
  isDisabled?: boolean,
  value: any,
  defaultValue?: any,
  options: any[],
  onBlur?: (value: any) => void,
  MultiSelectOptions: (value: any) => void,
  MultiSelectValue: (value: any) => void,
  isCreatable: boolean,
}> = ({
  options,
  MultiSelectOptions,
  MultiSelectValue,
  defaultValue,
  value,
  isDisabled = false,
  name,
  placeholder,
  setValue,
  onBlur,
  label,
  autoFocus = false,
  isCreatable = true,
}) => {
  const multiSelectRef = useRef<Creatable<OptionTypeBase, false>>(null)
  return (
    <div className="w-full">
      {label && <label className="block mb-2 text-sm font-bold">{label}</label>}
      {isCreatable ? (
        <CreatableSelect
          autoFocus={autoFocus}
          className="-mt-2 -mb-2 -ms-2"
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          components={{
            MultiValue: MultiSelectValue,
            Option: MultiSelectOptions,
          }}
          defaultValue={defaultValue || value}
          isDisabled={isDisabled}
          isMulti
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name={name}
          options={options}
          placeholder={placeholder}
          ref={multiSelectRef}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          value={value}
          onBlur={() => {
            if (onBlur) onBlur(value)
          }}
          onChange={(val) => {
            console.log(val, 'xxxsss')
          }}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              multiSelectRef.current?.blur()
            }
          }}
        />
      ) : (
        <Select
          autoFocus={autoFocus}
          className="-mt-2 -mb-2 -ms-2"
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          components={{
            MultiValue: MultiSelectValue,
            Option: MultiSelectOptions,
          }}
          defaultValue={defaultValue || value}
          isDisabled={isDisabled}
          isMulti
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name={name}
          options={options}
          placeholder={placeholder}
          ref={multiSelectRef}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          value={value}
          onBlur={() => {
            if (onBlur) onBlur(value)
          }}
          onChange={(val) => {
            console.log(val, 'xxxsss')
          }}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              multiSelectRef.current?.blur()
            }
          }}
        />
      )}
    </div>
  )
}

export default MultiSelectDropDown
