import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { useUpdateEffect } from 'react-use'

import GridView from 'components/GridView'

import { GridViewProvider } from 'contexts'
import { useCurrentListId, useFiltersByGridId } from 'features/listGrid'
import { addMultipleFilters, clearFilters } from 'features/record'
import { useTranslation } from 'react-i18next'
import ArchivePanel from '../../components/ArchivePanel/ArchivePanel'

const RecordsListingPage: FC<{ isArchived: boolean }> = ({ isArchived }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { viewTypeId, listId }: { workspaceId: string, stationId: string, listId: string, viewTypeId: string } =
    useParams()
  const gridListId = useCurrentListId()

  const defaultFilters = useFiltersByGridId(+viewTypeId)

  useUpdateEffect(() => {
    const { ownerFilters, filters, labelFilters } = defaultFilters
    const defaultFiltersArray: string[][] = []
    if (ownerFilters) {
      defaultFiltersArray.push(['owner', ownerFilters, `${defaultFiltersArray.length + 1}`])
    }
    if (filters) {
      Object.keys(filters).forEach((filterKey) => {
        defaultFiltersArray.push([filterKey, filters[filterKey], `${defaultFiltersArray.length + 1}`])
      })
    }
    if (labelFilters) {
      if (Array.isArray(labelFilters)) {
        labelFilters.forEach((labelId) => {
          defaultFiltersArray.push(['label', labelId, `${defaultFiltersArray.length + 1}`])
        })
      } else {
        defaultFiltersArray.push(['label', labelFilters, `${defaultFiltersArray.length + 1}`])
      }
    }
    if (!isEmpty(defaultFiltersArray)) dispatch(addMultipleFilters(defaultFiltersArray))
  }, [defaultFilters, dispatch])

  useEffect(() => {
    if (gridListId === +listId) {
      dispatch(clearFilters())
    }
  }, [viewTypeId, gridListId, listId, dispatch])

  return (
    <GridViewProvider>
      <div className="flex-1 overflow-auto grid" id={'portal-root'}>
        <div className="px-4 pb-4">
          {isArchived && (
            <div className="w-full">
              <ArchivePanel message={t('records:archiveRecordsMessage')} />
            </div>
          )}
          <GridView isArchived={isArchived} />
        </div>
      </div>
    </GridViewProvider>
  )
}

export default RecordsListingPage
