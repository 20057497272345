import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedActivityPath } from 'utils/helpers/generalHelpers'
import { ActivityLogType, UsersTablePropsType } from 'utils/types'
import { ActivityModules } from 'utils/types/ActivityLogType'

interface ActivityDeleted {
  activityUser: UsersTablePropsType | undefined;
  activity: ActivityLogType;
  isHijri?: boolean;
  isNotification?: boolean;
  isWidget?: boolean;
  language?: string;
  setSelectedRecordId?: () => void;
}

const Delete: FC<ActivityDeleted> = ({
  activityUser,
  activity,
  isNotification = false,
  isWidget = false,
  setSelectedRecordId,
}) => {
  const { t } = useTranslation('records')
  const userName =
    activity?.user !== undefined
      ? `${activity?.user?.firstName} ${activity?.user?.lastName}`
      : `${activity?.firstName} ${activity?.lastName}` || activityUser?.name

  const deletedRecord = activity?.relatedObjectType === ActivityModules.Record
  const isNotifOrWidget = isNotification || isWidget
  const shouldDisplayName = isNotifOrWidget && activity

  const renderName = () => {
    if (!activity) return
    switch (activity.relatedObjectType) {
      case ActivityModules.Record: {
        if (activity && activity.objectData && activity.objectData?.name) {
          return (
            <div
              className="cursor-default sm:tracking-normal sm:mt-1"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}
              title={`${activity.stationName} / ${activity.listName} / ${activity.recordName}`}>
              <span>{activity.objectData.name}</span>
            </div>
          )
        }
        break
      }

      case ActivityModules.Attachment: {
        return (
          <div>
            {shouldDisplayName &&
              (isNotification ? (
                <div className="mb-4">
                  <span className="text-sm me-1">{t('in')}</span>
                  <div
                    className="mt-1 cursor-pointer text-primary"
                    // href={`/workspace/${activity?.workspaceId}/stations/${activity?.stationId}/lists/${activity?.listId}?recordId=${activity?.record?.id}`}
                    // rel="noreferrer"
                    // target="_blank"
                    onClick={() => setSelectedRecordId(activity?.record?.id)}>
                    {activity?.recordName}
                  </div>
                  {getFormattedActivityPath(activity?.workspaceName, activity?.stationName, activity?.listName)}
                </div>
              ) : (
                <div
                  className="mb-2 text-xs cursor-pointer sm:tracking-normal sm:mt-1 sm:text-sm"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>
                  {t('in')} <span className="text-primary">{activity.recordName}</span>
                  {getFormattedActivityPath(null, activity?.stationName, activity?.listName)}
                </div>
              ))}
            {activity.objectData.name}
          </div>
        )
      }
    }
  }

  return (
    <div>
      <div className="flex items-baseline my-1 leading-4">
        <label className="text-sm font-bold me-1 leading-4">{userName}</label>
        <span className="text-sm leading-4">{deletedRecord ? t('recordDeleted') : t('deletedAttachment')}</span>
      </div>
      {renderName()}
    </div>
  )
}

export default Delete
