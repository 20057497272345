import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentListMembers } from '../../features/user'
import { formatValues } from '../../utils/constant/constant/common'
import MultiSelectDropDown from './MultiSelectDropDown'
import UserSelectOption from './UsersSelect/UserSelectOption'
import SelectedUserChip from './UsersSelect/SelectedUserChip'

type MultiSelectUsersWrapperPropsType = {
  value: any[],
  setValue: (value: any) => void,
  showActions: boolean,
  isDisabled: boolean,
  label: string,
  setShowActions: (value: any) => void,
}

const MultiSelectUsersWrapper: FC<MultiSelectUsersWrapperPropsType> = ({
  value,
  setValue,
  setShowActions,
  showActions,
  isDisabled,
  label,
}) => {
  const usersList = useCurrentListMembers()
  const { t } = useTranslation('lists')

  const usersDropdownOptions =
    useMemo(
      () => usersList?.map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.userId, ...u })),
      [usersList],
    ) || []

  const onSelectUser = (data) => {
    setValue([...value, data])
    if (!showActions) {
      setShowActions(true)
    }
  }

  const onRemoveOption = (id: string) => {
    const newList = value.filter((u) => +u?.value !== +id)
    setValue([...newList])
    if (!showActions) {
      setShowActions(true)
    }
  }

  const multiSelectDropdownProps = {
    isDisabled,
    label,
    MultiSelectOptions: (props: any) => <UserSelectOption {...props} isUserItem={true} onSelect={onSelectUser} />,
    MultiSelectValue: (props: any) => <SelectedUserChip {...props} isUserItem={true} onRemove={onRemoveOption} />,
    name: 'SelectUsers',
    options: usersDropdownOptions,
    placeholder: t('listSettings:placeholderSelectUser'),
    setValue,
    value: value.length > 0 && value[0]?.label === undefined ? formatValues(usersDropdownOptions, value) : value,
  }

  return (
    <div className="w-full">
      <MultiSelectDropDown {...multiSelectDropdownProps} />
    </div>
  )
}

export default MultiSelectUsersWrapper
