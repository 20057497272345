enum ElementOperations {
  /**Create activity */
  Create,

  /**Update activity */
  Update,

  /**Delete activity */
  Delete,

  /**Reset activity */
  Reset,

  /**Object listing activity */
  List,

  /**Object member update */
  MemberUpdated,

  /**Object inner role updated */
  RoleUpdated,

  /**Verify email */
  VerifyEmail,

  /**Invite to workspace */
  InviteToWorkspace,

  /**Invite to station */
  InviteToStation,

  /**Invite to list */
  InviteToList,

  /**Change avatar*/
  ChangeAvatar,

  /**Change password */
  ChangePassword,

  /**Invitation removed */
  InvitationRemoved,

  /**Change password requested */
  ChangePasswordRequested,

  /**Duplicate */
  Duplicate,

  /**Archive */
  Archive,

  /**Un archive */
  UnArchive,

  /**Recycle */
  Recycle,

  /**Un recycle */
  UnRecycle,

  /**Destructed */
  Destruct,
  RenewalSoonNotification,

  /**Subscription invoice issued */
  SubscriptionInvoiceIssued,

  /**Invoice payment succeed */
  InvoicePaymentSucceed,

  /**Invoice payment failed */
  InvoicePaymentFailed,

  /**Invoice payment max retries reached */
  InvoicePaymentMaxRetriesReached,
  PasswordExpiryNotification,
  ChangeRequestCreated,
  ChangeRequestApproved,
  ChangeRequestRejected,
  MOVE,
}
export default ElementOperations
