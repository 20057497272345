import { FC, memo, useMemo } from 'react'
import { Pie, Bar, Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  ChartOptions,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels,
  PointElement,
  LineElement,
)

const CustomChart: FC<{ labels?: string[]; type: any; datasets: any[]; options?: ChartOptions; plugins?: any }> = ({
  labels = [],
  type = 'pie',
  datasets = [],
  options = {},
  plugins = [],
}) => {
  // const chartContainer = useRef<null | Chart<any, any[], string> | any>(null)
  const data = useMemo(() => {
    return {
      datasets,
      labels,
      options,
    }
  }, [type, labels, datasets, options])

  if (type === 'pie') {
    // eslint-disable-next-line prettier/prettier
    const pieOptions = options as ChartOptions<'pie'>
    return (
      <Pie
        data={data}
        options={{ ...pieOptions, plugins: { ...pieOptions.plugins } }}
        plugins={plugins}
      />
    )
  }

  if (type === 'bar') {
    // eslint-disable-next-line prettier/prettier
    const barOptions = options as ChartOptions<'bar'>
    return <Bar data={data} dir="auto" options={{ ...barOptions, maintainAspectRatio: false }} plugins={plugins} />
  }

  if (type === 'line') {
    // eslint-disable-next-line prettier/prettier
    const lineOptions = options as ChartOptions<'line'>
    return <Line data={data} options={{ ...lineOptions, maintainAspectRatio: false }} plugins={plugins} />
  }
  return <></>
}

export default memo(CustomChart)
