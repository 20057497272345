import React, { FC } from 'react'
import classnames from 'classnames'

import { StationType, WorkspaceType } from 'utils/types'
import EntryInitials from 'components/EntryInitials'

export const commonStyles = 'relative overflow-hidden autocolor'
export const navbarStyles = 'rounded-full shadow w-10 h-10 mb-5 text-lg'
export const tileStyles = 'rounded-full shadow mt-9 -ms-16 w-16 h-16 text-xl me-4 flex-none'
export const editStyles = 'rounded-full shadow w-16 h-16 me-4 text-xl'
export const workspaceStyles = 'h-32 md:h-auto text-xl -m-4 md:me-4 mb-4 md:-mb-4 md:w-5/12 md:min-w-32'
export const stationStyles = 'rounded-full w-11 h-11 text-lg'
export const isMobileStyles = 'rounded-full w-8 h-8 text-sm'

const EntryAvatar: FC<{
  entry: WorkspaceType | StationType | any,
  isTileInitials?: boolean,
  isNavbar?: boolean,
  isEdit?: boolean,
  isWorkspace?: boolean,
  isStation?: boolean,
  isMobile?: boolean,
}> = ({
  entry: { color, name, link, useAvatar },
  isTileInitials,
  isNavbar,
  isEdit,
  isWorkspace,
  isStation,
  isMobile,
}) =>
  useAvatar && link ? (
    <div
      className={classnames(commonStyles, {
        [editStyles]: isEdit,
        [isMobileStyles]: isMobile,
        [navbarStyles]: isNavbar,
        [stationStyles]: isStation,
        [tileStyles]: isTileInitials,
        [workspaceStyles]: isWorkspace,
      })}>
      <img alt="workspace" className="absolute object-cover w-full h-full" src={link} />
    </div>
  ) : (
    <EntryInitials
      color={color}
      isEdit={isEdit}
      isMobile={isMobile}
      isNavbar={isNavbar}
      isStation={isStation}
      isTileInitials={isTileInitials}
      isWorkspace={isWorkspace}
      name={name}
    />
  )

export default EntryAvatar
