import React, { FC } from 'react'
import { EventContentArg, EventApi, Dictionary, EventDef } from '@fullcalendar/react'
import { differenceInDays } from 'date-fns'

import { getTimelinePercentageOfFilling } from 'utils/calendarUtils'
import { calendarTaskColors } from 'utils/helpers/timeLineHelpers'
import classnames from 'classnames'
import { RecordHealth } from 'features/record/recordSlice'
import { healthColors } from 'components/GridView/cells/displayCells/HealthCell'

interface ExtendendDictionary extends EventDef {
  extendedProps: Dictionary & { completedIn: number, health: RecordHealth };
}
interface ExtendedEventContentArg extends EventContentArg {
  event: EventApi & { _def: ExtendendDictionary };
}

const TimelineEvent: FC<ExtendedEventContentArg> = (props) => {
  const {
    event: {
      _def: {
        title,
        extendedProps: { completedIn, health, elementLevel },
      },
      _instance: { range },
    },
    view: { getCurrentData },
  } = props
  const {
    dateProfile: { currentRange },
  } = getCurrentData()
  const isSubtask = elementLevel > 4
  const hasMoreThanOneDay: boolean = differenceInDays(range.end, range.start) > 1
  const fillingPercentage: number = getTimelinePercentageOfFilling(completedIn, range, currentRange)

  const getStyles = () => {
    switch (true) {
      case !hasMoreThanOneDay:
        return { backgroundColor: healthColors[health], insetInlineEnd: 0 }
      case fillingPercentage < 20:
        return {
          backgroundColor: healthColors[health],
          insetInlineStart: `${fillingPercentage}%`,
        }
      default:
        return {
          backgroundColor: healthColors[health],
          insetInlineEnd: `${100 - fillingPercentage}%`,
        }
    }
  }
  return (
    <div className={`h-8 p-1 -m-1 group rounded ${calendarTaskColors(isSubtask)}`} onClick={(e) => e.stopPropagation()}>
      <span
        className={`absolute z-10 block w-full px-1 truncate rounded-sm pointer-events-none start-0 transition group-hover:w-auto ${
          isSubtask ? 'group-hover:bg-secondary' : 'group-hover:bg-tertiary'
        }`}>
        {title}
      </span>
      <div
        className="absolute bottom-0 z-0 h-1 rounded-sm pointer-events-none start-0"
        style={{ backgroundColor: healthColors[health], width: `${fillingPercentage}%` }}
      />

      {fillingPercentage > 0 && (
        <p
          className={classnames(
            `absolute z-10 flex py-1 text-xs rounded-sm opacity-0 pointer-events-none top-5 ms-1 bg-primary group-hover:opacity-100 px-1.5 leading-3 transform group-hover:translate-y-0.5 transition ${
              health === 'unavailable' ? 'text-gray-600' : 'text-white'
            }`,
          )}
          style={getStyles()}>
          {completedIn}
          <span>%</span>
        </p>
      )}
    </div>
  )
}

export default TimelineEvent
