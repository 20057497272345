import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { defaultWorkspaceFilterValue } from '../../../features/adminWorkspace/workspaceMock'
import { useWorkspacesList, useWorkspacesLoading } from '../../../features/workspace'
import useWorkspacesColumns from '../../../components/Admin/useWorkspacesColumns'
import SearchHeader from '../../../components/Admin/SearchHeader'
import GeneralGridTable from '../../../components/Admin/GridTable'

const WorkspacesPage: FC = () => {
  const { t } = useTranslation('admin')
  const columns = useWorkspacesColumns()
  const areWorkspacesLoading = useWorkspacesLoading()
  const workspacesData = useWorkspacesList()
  const [gridRef, setGridRef] = useState(null)
  const [searchText, setSearchText] = useState('')
  const searchTextRef = useRef(searchText)
  const workspaces = !workspacesData
    ? []
    : workspacesData?.map((col, i) => ({
        ...col,
        createdAt: format(new Date(col.createdAt), 'd/MM/yyyy') + '',
        id: col.id + '',
      }))

  searchTextRef.current = searchText

  const tableProps = {
    columns,
    dataSource: workspaces,
    defaultFilterValue: defaultWorkspaceFilterValue,
    handle: setGridRef,
    id: 'workspaces-table-to-xls',
    isLoading: areWorkspacesLoading || workspaces.length === 0,
  }
  const searchHeaderProps = {
    columns,
    dataSource: workspaces,
    fileName: 'workspace.csv',
    gridRef,
    searchText,
    setSearchText,
    title: t('admin:workspace:workspaceManagements'),
  }

  return (
    <>
      <SearchHeader {...searchHeaderProps} />
      {workspaces?.length === 0 ? (
        <div className="flex justify-center mt-40 text-lg opacity-50">{t('common:noUsersToVerify')}</div>
      ) : (
        <div className="p-6" dir={'auto'}>
          <GeneralGridTable {...tableProps} />
        </div>
      )}
    </>
  )
}

export default WorkspacesPage
