import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import CreatableSelect from 'react-select/creatable'
import Tippy from '@tippy.js/react'
import { useDispatch } from 'react-redux'
import DashboardWidgetType from 'utils/types/DashboardWidgetType'
import { useAppDispatch } from 'store'
import { customWidgetDefaultOptions } from 'utils/constant/constant/common'
import { StationType, WorkspaceType } from 'utils/types'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { customFieldBaseTypes } from 'utils/constant/enums/customFields'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import {
  getElementCustomFieldsByParentPath,
  getElementTree,
  useCurrentElementTree,
  useCurrentElementTreeLoading,
} from 'features/element'
import { clearElementTree } from 'features/element/elementSlice'
import Loader from '../../../../Loader'
import { DownIcon } from '../../../../Icons'
import LinkElements from '../../../../LinkElements'
import Button, { ButtonVariant } from '../../../../Buttons'
import RecordFilters from '../../../../RecordFilters'
import CreateGaugeForm from '../../GaugeWidget/CreateGaugeForm'
import SelectParentRow from './SelectParentRow'
import SelectRootElement from './SelectRootElement'

interface ConfiguringWidgetProps {
  parentId: string | number;
  level: number;
  currentLevel: number;
  isTimeline: boolean;
  isGaugeWidget: boolean;
  setCurrentLevel: (value: number) => void;
  setCurrentCustomValues: (value: boolean) => void;
  currentCustomValues: any;
  setSelectedElements: (value: any) => void;
  selectedElements: any[];
  currentStation: StationType;
  currentWorkspace: WorkspaceType;
  currentWidget: DashboardWidgetType;
  isStation?: boolean;
  isStartFromRootElement?: boolean;
  rootElement: WorkspaceType | StationType;
}

const ConfiguringWidget: FC<ConfiguringWidgetProps> = ({
  isStartFromRootElement,
  setCurrentCustomValues,
  currentCustomValues,
  setSelectedElements,
  selectedElements,
  currentStation,
  currentWorkspace,
  currentWidget,
  isGaugeWidget,
  rootElement,
  isTimeline,
  isStation,
}) => {
  const { t } = useTranslation('dashboard')
  const parentRef = useRef(null)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const dropDownOptions = customWidgetDefaultOptions
  const [selectedList, setSelectedList] = useState(null)
  const [showCurrentElement, setShowCurrentElement] = useState(isStartFromRootElement)
  const [selectedListsTitles, setSelectedListsTitles] = useState(null)
  const [options, setOptions] = useState([...dropDownOptions])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [toggleTippy, setToggleTippy] = useState<boolean>(false)
  const [activeFilter, setActiveFilter] = useState<boolean>(false)
  const currentList = useCurrentElementTree()
  const currentListLoading = useCurrentElementTreeLoading()
  const isMobile = window.innerWidth <= 760
  const notHasFilter =
    currentWidget?.baseType !== DashboardWidgetBaseTypes.CustomListOfRecords &&
    currentWidget?.baseType !== DashboardWidgetBaseTypes.BigNumbers

  const onGenerateTitle = () => {
    const listsTitles = selectedElements
      .filter((ele) => (isStartFromRootElement ? ele.isSelected : ele.basedType === 2))
      .map((list) => {
        return list.title || list.name
      })
      .join(' , ')
    setSelectedListsTitles(listsTitles)
  }

  useEffect(() => {
    const id = isStation ? +currentStation?.appElement?.id : +currentWorkspace?.appElement?.id
    dispatch(getElementTree({ elementId: id }))
    return () => {
      dispatch(clearElementTree())
    }
  }, [])

  useEffect(() => {
    if (selectedList && selectedList?.path && notHasFilter && !isTimeline) {
      setLoading(true)
      const listPath = selectedList?.element?.elementPath
      appDispatch(
        getElementCustomFieldsByParentPath({
          elementBaseType: AppElementBaseTypes.RecordAppElement,
          parentPath: listPath,
        }),
      )
        .then((res) => {
          const customFields = getInheritedCustomFields(res?.payload)
          const filteredFields =
            customFields
              ?.filter(
                (field) =>
                  +field.baseType === customFieldBaseTypes.DropDown || +field.baseType === customFieldBaseTypes.Number,
              )
              .map(({ id, fieldName }) => ({
                id: Number(id),
                key: `${id}`,
                label: fieldName,
                parentLevel: 2,
                sourceType: 4,
                value: Number(id),
              })) || []
          setOptions([...dropDownOptions, ...filteredFields])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [selectedList])

  const selectRowItemProps = {
    element: rootElement,
    isStartFromRootElement,
    selectedElements,
    setSelectedElements,
    setShowCurrentElement,
  }

  const linkRecordsProps = {
    currentList,
    currentListLoading,
    elementBaseType: 2,
    elementId: currentStation?.appElement?.id,
    isStartFromRootElement,
    level: isStation ? 2 : 1,
    lowestLevel: isStation ? 2 : 2,
    options: {
      canAddRecord: false,
      isSingleSelect: notHasFilter && !isTimeline,
      shouldShowPreview: !isStation,
    },
    selectedElements,
    setSelectedElements,
    setShowCurrentElement,
  }

  const renderElementsList = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1">
          <Loader />
        </div>
      )

    const dropdownValue = selectedElements?.find((item) => item?.sourceType === 3)

    const gaugeWidgetProps = {
      currentCustomValues,
      currentStation,
      currentWidget,
      currentWorkspace,
      isTimeline,
      selectedElements,
      setCurrentCustomValues,
      setSelectedElements,
    }

    return isGaugeWidget ? (
      <CreateGaugeForm {...gaugeWidgetProps} />
    ) : (
      <div className="flex flex-col flex-1 overflow-y-auto">
        <p className="mb-1 font-bold"> {t('buildYourPieChart')}</p>
        <div className="p-2 mb-2 bg-gray-200 rounded">
          <p className="text-sm">{t('buildYourPieChartDescription')}</p>
        </div>
        <div className="flex-col flex-1 min-h-0 gap-2 md:flex-row md:justify-between">
          <Tippy
            animation="fade"
            appendTo={() => document.body}
            arrow={false}
            className={'top-zIndex'}
            content={
              <div
                className={classNames('flex flex-col flex-1 min-h-0 p-3 bg-white rounded shadow-lg', {
                  'ms-8': isMobile,
                })}
                style={{ height: 450, width: 350 }}>
                {isStartFromRootElement && showCurrentElement && <SelectRootElement {...selectRowItemProps} />}
                {!showCurrentElement && <LinkElements {...linkRecordsProps} />}
                <div className="flex justify-end mt-2">
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                      setSelectedList(selectedElements[0])
                      onGenerateTitle()
                      setToggleTippy(false)
                    }}>
                    {t('saveButton')}
                  </Button>
                </div>
              </div>
            }
            interactive={true}
            isDestroyed={!toggleTippy}
            placement="top end"
            reference={parentRef}
            theme="calendar-tooltip"
            visible={toggleTippy}
            onHide={(data) => {
              setToggleTippy(false)
            }}>
            <div className="flex flex-col flex-1">
              <p className="mb-2">{t('selectElements')}</p>
              <div
                className={`flex items-center justify-between h-12 p-3 border-2 border-gray-300 rounded cursor-pointer hover:border-primary focus:border-primary`}
                ref={parentRef}
                onClick={() => {
                  setToggleTippy(!toggleTippy)
                }}>
                <p className="text-small">
                  {selectedListsTitles || <span className="opacity-30">{t('customFields:select')}</span>}
                </p>
                <DownIcon className="w-4 opacity-60" />
              </div>
            </div>
          </Tippy>
          <div className="flex-1 mt-3">
            {(notHasFilter || isTimeline) && (
              <>
                <p className={classNames('mb-2', { 'text-gray-400': !selectedList })}>{t('selectField')}</p>
                <CreatableSelect
                  className="border-none"
                  classNamePrefix="custom-field-select"
                  components={{
                    Option: SelectParentRow,
                  }}
                  isDisabled={!selectedList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  name="customFieldType"
                  options={options}
                  placeholder={t('customFields:select')}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  value={dropdownValue}
                  onBlur={() => {
                    setActiveFilter(true)
                  }}
                  onChange={(values: any) => {
                    const item = options.find((f) => +f.id === +values.value)
                    const list = [...selectedElements]
                    const index = selectedElements.findIndex((ele) => ele.sourceType === 3)
                    if (index !== -1) {
                      list[index] = item
                    } else {
                      list.push(item)
                    }
                    setCurrentCustomValues({
                      ...currentCustomValues,
                      childSourceType: item?.sourceType,
                      childValue: item?.id,
                    })
                    setSelectedElements([...list])
                  }}
                />
              </>
            )}
            {(!notHasFilter || isTimeline) && (
              <div className={`items-center border-gray-200 mt-2 pb-5`}>
                <p className={classNames('mb-2', { 'text-gray-400': !selectedList })}>{t('selectFilter')}</p>
                <RecordFilters
                  isCustomWidget={!!selectedList}
                  showBasics={isStartFromRootElement}
                  selectedElements={selectedElements}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return <>{!currentStation && !currentWorkspace ? <Loader /> : renderElementsList()}</>
}

export default ConfiguringWidget
