import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import recordType from '../../utils/types/RecordType'
import { useListRecordOptions } from '../../features/element'
import Dropdown from '../inputs/Dropdown'
import { addOrUpdateFilter } from '../../features/record'

interface CustomFieldFormProps {
  setSelectedElements: (e: any) => void;
  selectedElements: any[];
  elementId: number;
  level: number;
  addManyLists?: boolean;
  customFieldInfo: any;
  currentRecord: recordType;
  isReadOnly?: boolean;
}

const LinkFilter: FC<CustomFieldFormProps> = ({ listId, customFieldInfo, filterName, filterIndex, filterValue }) => {
  const { t } = useTranslation('records')
  const dispatch = useDispatch()
  const listsRecords = useListRecordOptions()
  const currentList = listsRecords[listId] || []
  const options = currentList.map((record) => ({
    label: record.title,
    value: record.id,
  }))
  const defaultOption = options.find(({ value }: { value: string }) => value?.toString() === filterValue)
  const [currentOption, setCurrentOption] = useState(defaultOption)

  const setNewFilter = (value: string) => {
    dispatch(addOrUpdateFilter([filterName, value, `${filterIndex}`]))
  }

  return (
    <>
      <span className="mx-2">{t('is')}</span>
      <Dropdown
        classes={{ wrapper: 'flex-1' }}
        name={filterName}
        options={options}
        value={currentOption?.value}
        onChange={({ target: { value } }: { target: { value: string } }) => {
          setCurrentOption({ label: `${value}`, value })
          setNewFilter(`${value}`)
        }}
      />
    </>
  )
}

export default LinkFilter
