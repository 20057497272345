import React, { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { useFormik } from 'formik'
import classnames from 'classnames'

import DashboardAvailableWidgets from 'utils/types/states/DashboardAvailableWidgets'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import {
  DashboardWidgetBaseTypes,
  dashboardWidgetTitles,
  getDashboardWidgetPreviewImages,
} from 'utils/constant/enums/DashboardWidgetBaseType'

import Button, { ButtonVariant } from 'components/Buttons'
import { Radio } from 'components/inputs'
import { useParams } from 'react-router-dom'
import { DashboardStateType } from '../../utils/types/states'
import elementType from '../../utils/types/ElementType'
import { StationType, WorkspaceType } from '../../utils/types'
import { DashboardParameterType } from '../../utils/types/states/DashboardGetWidgetDataType'
import { useFilters } from '../../features/record'
import ConfiguringWidget from './Widgets/CustomWidgets/ConfiguringWidget/ConfiguringWidget'
import BaseModal from './BaseModal/BaseModal'

enum WidgetTypes {
  General = 0,
  UserBased = 1,
  Custom = 2,
}

interface InitialValues {
  widget: DashboardAvailableWidgets | null;
}

const initialValues: InitialValues = {
  widget: null,
}

const AddWidgetModal: FC<{
  close: () => void,
  isStation: boolean,
  isModalOpen: boolean,
  currentStation: StationType,
  currentWorkspace: WorkspaceType,
  dashboard: DashboardStateType,
  availableWidgets: DashboardAvailableWidgets[] | null,
  addWidget: (widgets: DashboardAvailableWidgets, parameters?: DashboardParameterType[]) => void,
  dashboardWidgets: DashboardStateWidgetType[],
}> = ({
  close,
  availableWidgets,
  addWidget,
  dashboardWidgets,
  currentStation,
  currentWorkspace,
  isStation,
  isModalOpen,
}) => {
  const { workspaceId, stationId }: { workspaceId: string, stationId: string } = useParams()
  const [active, setActive] = useState<WidgetTypes>(WidgetTypes.General)
  const [currentCustomValues, setCurrentCustomValues] = useState({})
  const [isCustomSelected, setIsCustomSelected] = useState(false)
  const allFilters = useFilters()
  const parentId = stationId || workspaceId
  const level = !stationId ? 0 : 1
  const parent = level === 0 ? currentWorkspace : currentStation
  const [selectedElements, setSelectedElements] = useState<elementType[]>([parent])
  const [currentLevel, setCurrentLevel] = useState<number>(level)
  const [modelSize, setModelSize] = useState<string>('lg')
  const { t, i18n } = useTranslation('dashboard')
  const { width: windowWidth } = useWindowSize()

  const onClose = () => {
    close()
    setSelectedElements([parent])
    setIsCustomSelected(false)
    setModelSize('lg')
    setCurrentLevel(level)
    setActive(WidgetTypes.General)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const isTimelineWidget = formik.values.widget?.baseType === DashboardWidgetBaseTypes.CustomTimeLine
      const elementParent = selectedElements[selectedElements.length - 2]
      const elementField = selectedElements[selectedElements.length - 1]
      let parameters = []
      if (active === WidgetTypes.Custom) {
        //For new gauge widget
        if (formik.values.widget?.baseType === DashboardWidgetBaseTypes.GaugeWidget) {
          parameters = selectedElements.filter((element) => element !== undefined)
        } else {
          const isNotFilterWidget =
            formik.values.widget?.baseType !== DashboardWidgetBaseTypes.CustomListOfRecords &&
            formik.values.widget?.baseType !== DashboardWidgetBaseTypes.BigNumbers &&
            !isTimelineWidget
          if (isNotFilterWidget) {
            parameters = [
              {
                parameterId: 0,
                sourceType: 0,
                value: elementParent?.id,
              },
              {
                parameterId: 1,
                sourceType: elementField.sourceType,
                value: elementField.id,
              },
            ]
          } else {
            selectedElements
              // .filter((item) => item.basedType === 2)
              .filter((item) => [0, 1, 2].indexOf(item?.basedType || item?.appElement?.baseElementType) > -1)
              .map((item, index) => {
                const elementId = item?.appElement?.id || item.id
                parameters.push({ parameterId: index, sourceType: 0, value: elementId })
              })
            if (isTimelineWidget) {
              const selectedField = selectedElements.find((field) => field?.sourceType === 3)
              parameters.push({
                parameterId: 10001,
                sourceType: selectedField?.sourceType,
                value: selectedField?.id,
              })
            }
            let filterObject = {}
            allFilters.map((item, index) => {
              const key = item[0]
              let value = item[1]
              if (key === 'owner' || key === 'status_id' || key === 'creator') {
                value = filterObject[key] ? [...filterObject[key], item[1]] : [item[1]]
              }
              filterObject = { ...filterObject, [key]: value }
            })
            parameters.push({ parameterId: 10000, sourceType: 5, value: JSON.stringify(filterObject) })
            if (formik.values.widget?.baseType === DashboardWidgetBaseTypes.BigNumbers) {
              parameters.push({ parameterId: 10002, sourceType: 7, value: '1' })
            }
          }
        }
      }
      if (values.widget) {
        addWidget(values.widget, parameters)
        formik.setFieldValue('widget', null)
        onClose()
      }
    },
  })
  const isTimeline = formik?.values?.widget?.baseType === DashboardWidgetBaseTypes.CustomTimeLine
  const isGaugeWidget = formik?.values?.widget?.baseType === DashboardWidgetBaseTypes.GaugeWidget

  const handleChange = (widget: DashboardAvailableWidgets) => {
    if (active === WidgetTypes.Custom) {
      setIsCustomSelected(!isCustomSelected)
      setModelSize('md')
    } else {
      setSelectedElements([parent])
      setModelSize('lg')
    }
    if (!formik.values.widget) {
      formik.setFieldValue('widget', widget)
      return
    }
    if (formik.values.widget.baseType === widget.baseType) {
      formik.setFieldValue('widget', null)
    } else {
      formik.setFieldValue('widget', widget)
    }
  }

  if (!availableWidgets) {
    return <></>
  }

  const dashboardWidgetsBaseTypes = dashboardWidgets ? dashboardWidgets.map((widget) => widget.baseType) : []
  const sortedAvailableWidgets = availableWidgets.reduce(
    (
      acc: {
        [WidgetTypes.UserBased]: DashboardAvailableWidgets[],
        [WidgetTypes.General]: DashboardAvailableWidgets[],
        [WidgetTypes.Custom]: DashboardAvailableWidgets[],
      },
      curr,
    ) => {
      return {
        [WidgetTypes.General]:
          curr.widgetType === WidgetTypes.General ? [...acc[WidgetTypes.General], curr] : [...acc[WidgetTypes.General]],
        [WidgetTypes.UserBased]:
          curr.widgetType === WidgetTypes.UserBased
            ? [...acc[WidgetTypes.UserBased], curr]
            : [...acc[WidgetTypes.UserBased]],
        [WidgetTypes.Custom]:
          curr.widgetType === WidgetTypes.Custom ? [...acc[WidgetTypes.Custom], curr] : [...acc[WidgetTypes.Custom]],
      }
    },
    { [WidgetTypes.General]: [], [WidgetTypes.UserBased]: [], [WidgetTypes.Custom]: [] },
  )
  const filteredWidgets = availableWidgets
    ? sortedAvailableWidgets[active].filter(
        (available) => available.baseType !== DashboardWidgetBaseTypes.CustomListOfRecords,
      )
    : []
  const renderWidgets = (widget: DashboardAvailableWidgets, imageSrc: string, idx: number) => {
    return (
      <div key={widget.baseType + idx.toString()} className="flex flex-col justify-between w-full h-48 border">
        <img alt="previewImage" className="w-auto h-auto m-auto max-h-36" src={imageSrc} />
        <div className="flex items-center h-12 px-4 border-t">
          <Radio
            checked={Boolean(formik.values.widget && formik.values.widget.baseType === widget.baseType)}
            isFullRoundedBorder={true}
            name={'widget' + widget.baseType}
            onChange={() => handleChange(widget)}
          />
          {t(dashboardWidgetTitles[widget.baseType])}
        </div>
      </div>
    )
  }

  const isDisabled = isTimeline
    ? selectedElements.findIndex((ele) => ele.sourceType === 3) === -1
    : isGaugeWidget
    ? selectedElements.length < 5
    : active === WidgetTypes.Custom
    ? stationId === undefined || selectedElements.length < 2
    : !formik.values.widget
  const tabsList = Object.keys(sortedAvailableWidgets)

  const addWidgetModalContent = () => {
    return availableWidgets ? (
      <div className="flex flex-col flex-1 min-h-0">
        <nav className="flex mb-4 gap-6">
          {tabsList.map((key) => {
            const widgetTypeSection: WidgetTypes = Number(key)
            if (
              widgetTypeSection !== WidgetTypes.General &&
              widgetTypeSection !== WidgetTypes.UserBased &&
              widgetTypeSection !== WidgetTypes.Custom
            ) {
              return null
            }
            return (
              <div
                key={key}
                className="text-gray-800 cursor-pointer"
                onClick={() => {
                  setModelSize('lg')
                  setActive(widgetTypeSection)
                  setIsCustomSelected(false)
                  formik.setFieldValue('widget', null)
                }}>
                {widgetTypeSection === WidgetTypes.General
                  ? t('general')
                  : widgetTypeSection === WidgetTypes.UserBased
                  ? t('userBased')
                  : t('custom')}
                <div
                  className={classnames('w-full h-1 transition duration-300 ease-in-out mt-1 rounded-t', {
                    'bg-primary': active === widgetTypeSection,
                  })}
                />
              </div>
            )
          })}
        </nav>
        <form className="flex flex-col flex-1 min-h-0" onSubmit={formik.handleSubmit}>
          {isCustomSelected && active === WidgetTypes.Custom ? (
            <div className="flex flex-1 min-h-0 overflow-y-auto shadow-inner-bottom-xs">
              <ConfiguringWidget
                isStation={isStation}
                rootElement={isStation ? currentStation : currentWorkspace}
                isStartFromRootElement={!isStation && level === 0}
                currentCustomValues={currentCustomValues}
                currentLevel={currentLevel}
                currentStation={currentStation}
                currentWidget={formik.values.widget}
                currentWorkspace={currentWorkspace}
                isGaugeWidget={isGaugeWidget}
                isTimeline={isTimeline}
                level={level}
                parentId={parentId}
                selectedElements={selectedElements}
                setCurrentCustomValues={setCurrentCustomValues}
                setCurrentLevel={setCurrentLevel}
                setSelectedElements={setSelectedElements}
              />
            </div>
          ) : !filteredWidgets.length ? (
            <div className="flex items-center justify-center flex-1 text-center">
              <h3 className="mx-8 opacity-50"> {t('emptyModalText')}</h3>
            </div>
          ) : (
            <div className="flex-1 min-h-0 overflow-y-auto grid grid-cols-3 auto-rows-min gap-2 shadow-inner-bottom-xs">
              {filteredWidgets.map((widget, idx) => {
                const imageSrc = widget.availableTypes[0].image
                  ? widget.availableTypes[0].image
                  : getDashboardWidgetPreviewImages(widget.baseType, i18n.language)

                return renderWidgets(widget, imageSrc, idx)
              })}
            </div>
          )}
          <div className="flex justify-end mt-6">
            <Button className="bg-gray-200 me-4" variant={ButtonVariant.Ghost} onClick={onClose}>
              {t('common:labels.cancel')}
            </Button>
            <Button
              className={isDisabled ? 'opacity-50' : ''}
              disabled={isDisabled}
              type="submit"
              variant={ButtonVariant.Primary}>
              {t('addWidget')}
            </Button>
          </div>
        </form>
      </div>
    ) : null
  }
  return (
    <BaseModal
      close={onClose}
      content={addWidgetModalContent()}
      header={<h1 className="px-4 text-gray-800"> {t('addWidget')}</h1>}
      isModalOpen={isModalOpen}
      type={modelSize}
    />
  )
}

export default memo(AddWidgetModal)
