// eslint-disable-next-line prettier/prettier
enum ElementViewBaseTypes {
  //records kanban view
  RecordsKanban = 0,

  //Records grid view
  RecordsGrid,

  //Records agenda view
  RecordsAgenda,

  //Records calendar view
  RecordsCalendar,

  //Records timeline view
  RecordsTimeLine,
}

export default ElementViewBaseTypes
