import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { ButtonVariant } from 'components/Buttons'
import { ContentEditableInputRefType, ContentEditableInputType } from 'utils/types/inputs'
import { CheckmarkIcon, CrossSignIcon } from 'components/Icons'
import withContentEditable from './withContentEditable'

interface ContentEditableInputWithButtonsType extends ContentEditableInputType {
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  shouldSubmitOnBlur?: boolean;
  isRejectButton?: boolean;
  canSaveOnClickOutside?: boolean;
  isRichTextEditor?: boolean;
  isDocumentation?: boolean;
  needForApproval?: boolean;
  onCancel?: () => void;
  getIsFocused?: (focused: boolean) => void;
}

/* 
  HOC for inputs with buttons
  displays not editing data
  when clicked on, it renders editable input
  with action buttons
*/

const withButtonsAndContentEditable = (WrappedComponent: ReactNode) => {
  const EditableWrappedComponent = withContentEditable(WrappedComponent)
  const hocComponent = forwardRef<ContentEditableInputRefType, ContentEditableInputWithButtonsType>(
    (
      {
        submitButtonLabel,
        cancelButtonLabel,
        isRejectButton = false,
        shouldSubmitOnBlur = false,
        canSaveOnClickOutside,
        isDocumentation = false,
        needForApproval = false,
        onCancel,
        ...props
      },
      ref,
    ) => {
      const { t } = useTranslation()
      const inputRef = useRef<any>(null)
      const cancelButtonRef = useRef<HTMLButtonElement>(null)
      const confirmButtonRef = useRef<HTMLButtonElement>(null)
      const [isFocused, setIsFocused] = useState(false)
      const [errorMessage, setErrorMessage] = useState(undefined)

      useEffect(() => {
        if (props.getIsFocused) {
          props.getIsFocused(isFocused)
        }
      }, [isFocused])

      const submitField = () => {
        if (!inputRef?.current?.hasInputError) {
          console.log(errorMessage, isFocused, 'xxxcocoy2')
          if (errorMessage === null || errorMessage === undefined) {
            setIsFocused(false)
          }
          inputRef.current.submitField()
          if (needForApproval) {
            inputRef.current.resetForm()
          }
        } else {
          inputRef?.current?.shakeError()
        }
      }

      const onFocus = useCallback(() => setIsFocused(true), [])
      const isShowButtons = errorMessage !== undefined || isFocused

      useImperativeHandle(
        ref,
        () => ({
          blur: inputRef?.current?.blur,
          focus: inputRef?.current?.focus,
          hasInputError: inputRef?.current?.hasInputError,
          hideButtons: () => {
            inputRef?.current?.hideButtons()
            setIsFocused(false)
          },
          resetForm: inputRef?.current?.resetForm,
          shakeError: inputRef?.current?.shakeError,
          submitField: submitField,
        }),
        [inputRef],
      )

      if (!canSaveOnClickOutside) {
        return (
          <>
            <EditableWrappedComponent
              className="mb-0"
              isDocumentation={isDocumentation}
              notEditingClassName="mb-8 ms-1"
              ref={inputRef}
              shouldSubmitOnBlur={shouldSubmitOnBlur}
              onCancel={onCancel}
              onFocus={onFocus}
              setErrorMessage={setErrorMessage}
              {...props}
            />
            {isShowButtons && (
              <div className="flex mt-2 -mb-2">
                <Button
                  className="ms-auto"
                  small
                  variant={ButtonVariant.Ghost}
                  onClick={() => {
                    if (onCancel) {
                      onCancel()
                    }
                    setErrorMessage(undefined)
                    setIsFocused(false)
                    inputRef.current.blur()
                    inputRef.current.resetForm()
                  }}>
                  {cancelButtonLabel || t('labels.cancel')}
                </Button>
                <Button
                  className={`ms-2`}
                  data-testid={`${props['data-testid']}-save-button`}
                  small
                  variant={isRejectButton ? ButtonVariant.Danger : ButtonVariant.Primary}
                  onClick={submitField}>
                  {submitButtonLabel || t('labels.save')}
                </Button>
              </div>
            )}
          </>
        )
      }

      const handleClickOutsideField = (event: any) => {
        const { target } = event
        const isClickedInsideCancelButton = cancelButtonRef?.current ? cancelButtonRef?.current.contains(target) : false
        const isClickedInsideConfirmButton = confirmButtonRef?.current
          ? confirmButtonRef?.current.contains(target)
          : false
        const isClickedInsideInput = inputRef?.current ? inputRef?.current.contains(target) : false
        if (!isClickedInsideCancelButton && !isClickedInsideConfirmButton && !isClickedInsideInput) {
          submitField()
        }
      }

      return (
        <>
          <EditableWrappedComponent
            className="mb-0"
            notEditingClassName="mb-8"
            ref={inputRef}
            shouldSubmitOnBlur={shouldSubmitOnBlur}
            onFocus={onFocus}
            {...props}
            handleClickOutside={handleClickOutsideField}
          />
          {isFocused && (
            <div className="flex mt-2 -mb-2">
              <Button
                className="bg-gray-100 ms-auto hover:bg-gray-200 lg:p-1"
                icon={CrossSignIcon}
                ref={cancelButtonRef}
                variant={ButtonVariant.Icon}
                onClick={() => {
                  setIsFocused(false)
                  inputRef.current.blur()
                  inputRef.current.resetForm()
                }}
              />
              <Button
                className="bg-gray-100 ms-2 hover:bg-gray-200 lg:p-1"
                data-testid="checkmark"
                icon={CheckmarkIcon}
                ref={confirmButtonRef}
                small
                variant={ButtonVariant.Icon}
                onClick={submitField}
              />
            </div>
          )}
        </>
      )
    },
  )

  hocComponent.displayName = 'withButtonsAndContentEditable'

  return hocComponent
}

export default withButtonsAndContentEditable
