import React from 'react'
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import i18n from 'i18next'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { CustomFieldsListMessage, CustomFieldType } from 'utils/types/CustomFieldsType'
import { recordAppElementDefaultAttributes } from 'utils/constant/enums/Aggregate'
import { customFieldBaseTypes, FieldFormattingProperties } from 'utils/constant/enums/customFields'
import { AppElementViewAttribute } from 'utils/types/AppElementViewType'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { AppElementAttributeType } from 'utils/types/AppElementAttributeType'
import DropdownCustomFieldEditorCell from '../../components/CustomFields/DropdownCustomFieldEditorCell'
import CustomFieldDropdownCell from '../../components/CustomFields/CustomFieldDropdownCell'
import { DateCell, DateEditorCell, SettingsCell } from '../../components/GridView/cells'
import GridViewLinkColumnView from '../../components/CustomFields/LinkType/GridViewLinkColumnView'
import GridViewEditorCustomField from '../../components/CustomFields/LinkType/GridViewEditorCustomField'
import CreateCustomFieldButton from '../../components/CustomFields/CreateCustomFieldButton'
import RecordFormula from '../../components/CustomFields/Formula/RecordFormula'
import NumberCustomFieldEditable from '../../components/CustomFields/NumberCustomFieldEditable'

export const settingsList = [
  {
    className: '-settings',
    defaultWidth: 64,
    editable: false,
    header: '',
    name: 'settings',
    render: (props) => <SettingsCell {...props} />,
    showColumnMenuTool: false,
    sortable: false,
  },
]

export const customFieldList = [
  {
    className: 'add-custom-field',
    defaultWidth: 64,
    header: (
      <CreateCustomFieldButton
        elementBaseType={AppElementBaseTypes.ListAppElement}
        isGridView={true}
        showIcon={true}
        showText={false}
      />
    ),
    name: 'addCustom',
    showColumnMenuTool: false,
    sortable: false,
  },
]

const gridViewCustomField = (
  field: CustomFieldType,
  currentListElementId: string | number,
  isReplacedField: boolean,
  attributeVisibility: AppElementAttributeVisibility,
): TypeColumn[] => {
  const options = field?.customFieldOptions ? [...field.customFieldOptions] : []
  const isEditable =
    field.baseType !== customFieldBaseTypes.Aggregate && attributeVisibility !== AppElementAttributeVisibility.ReadOnly
  const isTargetList = +currentListElementId === +field?.intermediateAppElementId
  const listId = isTargetList ? field?.elementId : field?.intermediateAppElementId
  const sortedOptions = options
    .map((i: any) => ({ ...i, id: +i.id, label: i.optionCaption }))
    .sort((a: any, b: any) => a.optionOrder - b.optionOrder)

  const getIsEditable = (props: any) => {
    return isReplacedField ? props?.cellProps?.data?.appElements[0]?.activeDirectChildrenCount == 0 : isEditable
  }

  switch (field.baseType) {
    case customFieldBaseTypes.Number:
      return {
        baseType: field.baseType,
        className: `-end-align ${isEditable ? '-editable' : ''}`,
        editable: isEditable,
        header: field.fieldName,
        headerAlign: 'start',
        minWidth: 180,
        name: `custom_${field.id}`,
        render: (props) => {
          const value =
            props?.value ||
            props?.cellProps?.data?.appElements[0]?.customFieldValues?.find((f: any) => +f.customFieldId === +field.id)
              ?.value
          return <div>{value}</div>
        },
        renderEditor: (props) => (
          <NumberCustomFieldEditable {...props} field={field} isEditable={getIsEditable(props)} />
        ),
        sortable: true,
        textAlign: 'end',
        type: 'number',
      }
    case customFieldBaseTypes.DropDown:
      return {
        baseType: field.baseType,
        className: `-status ${isEditable ? '-editable' : ''}`,
        editable: isEditable,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: sortedOptions,
        },
        header: field.fieldName,
        minWidth: 180,
        name: `custom_${field.id}`,
        render: (props) => <CustomFieldDropdownCell {...props} field={field} options={sortedOptions} />,
        renderEditor: (props) => (
          <DropdownCustomFieldEditorCell {...props} isEditable={getIsEditable(props)} options={sortedOptions} />
        ),
        sortable: true,
      }
    case customFieldBaseTypes.Link:
      return {
        baseType: field.baseType,
        className: `w-full ${isEditable ? '-editable' : ''}`,
        editable: isEditable,
        enableFiltering: false,
        header: field.fieldName,
        minWidth: 350,
        name: `link_custom_${field.id}`,
        render: (props) => (
          <GridViewLinkColumnView
            {...props}
            currentRecord={props.cellProps.data}
            customFieldInfo={field}
            isEditable={getIsEditable(props)}
            isMulti={true}
            listId={listId}
          />
        ),
        renderEditor: (props) => (
          <GridViewEditorCustomField
            {...props}
            currentRecord={props.cellProps.data}
            customFieldInfo={field}
            isEditingAllowed={isEditable}
            isMulti={true}
            listId={listId}
          />
        ),
        sortable: false,
      }
    case customFieldBaseTypes.Date:
      return {
        baseType: field.baseType,
        className: `${isEditable ? '-editable' : ''}`,
        defaultFlex: 1,
        editable: isEditable,
        header: field.fieldName,
        minWidth: 180,
        name: `custom_${field.id}`,
        render: (props) => <DateCell i18n={i18n} user={undefined} {...props} field={field} />,
        renderEditor: (props) => (
          <DateEditorCell
            autoFocus={props.autoFocus}
            data={props.data}
            dateToCompare={props.cellProps.data.endDate}
            field={field}
            id={props.cellProps.data.id}
            isDisabled={getIsEditable(props)}
            isGridView={true}
            name={props.cellProps.id}
            value={props.value}
            onBlur={props.onComplete}
            onChange={props.onChange}
            {...props}
          />
        ),
        sortable: true,
      }
    case customFieldBaseTypes.SingleLink:
      return {
        baseType: field.baseType,
        className: `w-full ${isEditable ? '-editable' : ''}`,
        editable: isEditable,
        enableFiltering: false,
        header: field.fieldName,
        minWidth: 350,
        name: `link_custom_${field.id}`,
        render: (props) => (
          <GridViewLinkColumnView
            {...props}
            currentRecord={props.cellProps.data}
            customFieldInfo={field}
            isEditable={getIsEditable(props)}
            isMulti={false}
            listId={listId}
          />
        ),
        renderEditor: (props) => (
          <GridViewEditorCustomField
            {...props}
            currentRecord={props.cellProps.data}
            customFieldInfo={field}
            isEditable={getIsEditable(props)}
            isMulti={false}
            listId={listId}
          />
        ),
        sortable: false,
      }
    case customFieldBaseTypes.Formula:
      return {
        baseType: field.baseType,
        className: 'border-0',
        editable: false,
        enableFiltering: true,
        header: field.fieldName,
        minWidth: 250,
        name: `custom_${field.id}`,
        render: (props) => (
          <RecordFormula
            {...props}
            currentItem={props.cellProps.data}
            customFieldInfo={field}
            isEditingAllowed={getIsEditable(props)}
            isGridView={true}
            name="formula"
          />
        ),
        sortable: true,
      }
    default:
      return {
        baseType: field.baseType,
        className: '-editable -text',
        defaultFlex: 1,
        editable: isEditable,
        header: field.fieldName,
        minWidth: 180,
        name: `custom_${field.id}`,
        render: (props) => {
          const value = props?.data?.appElements[0]?.customFieldValues?.find(
            (f) => +f.customFieldId === +field.id,
          )?.value
          return <div>{value}</div>
        },
        sortable: true,
      }
  }
}

export const gridCustomList = (
  fieldsList: CustomFieldType[],
  currentListId: string | number,
  viewAttributes: AppElementViewAttribute[],
  defaultAttributeVisibility: AppElementAttributeVisibility,
) => {
  if (!viewAttributes || defaultAttributeVisibility === undefined) {
    return []
  }
  const gridCustomFieldColumns: TypeColumn[] = []
  fieldsList.forEach((field) => {
    const visibility = getFieldVisibility({
      attributeId: Number(field.id),
      attributeType: AppElementAttributeType.customField,
      viewAttributes,
      defaultAttributeVisibility,
      field,
      fieldsList,
      elementBaseType: AppElementBaseTypes.RecordAppElement,
    })

    if (visibility !== AppElementAttributeVisibility.Hidden) {
      const { shouldBeReplaced } = getFieldReplacingInfo({ field, fieldsList, isLeafNode: false })

      gridCustomFieldColumns.push(gridViewCustomField(field, currentListId, shouldBeReplaced, visibility))
    }
  })
  return gridCustomFieldColumns
}

export const gridCustomInitData = (list: any[]) => {
  const dataObj = {}
  list?.map((field: any) => {
    dataObj[field.id] = null
  })
  return dataObj
}

export const itemType = {
  list: 'listId',
  station: 'stationId',
  workspace: 'workspaceId',
}

export const getInheritedCustomFields = (
  customFieldsObject: CustomFieldsListMessage | Record<string, never>,
): CustomFieldType[] | [] => {
  if (customFieldsObject && Object.keys(customFieldsObject)?.length === 0) {
    return []
  } else {
    const aggregateFields = customFieldsObject?.native?.fields?.filter(
      (cf) => cf.baseType === customFieldBaseTypes.Aggregate,
    )
    return [
      ...aggregateFields,
      ...customFieldsObject?.inherited?.fromAncestors?.fields,
      ...customFieldsObject?.inherited?.fromParent?.fields,
    ]
  }
}

export const isFieldHidden = (
  field: CustomFieldType,
  fieldsList: CustomFieldType[],
  elementBaseType: AppElementBaseTypes,
) => {
  if (field.isReplacement) {
    const replacementElementIndex = findReplacementIndex({ field, fieldsList, elementBaseType })

    if (replacementElementIndex > -1) {
      return true
    } else if (field.isVisible) {
      return false
    }
    return true
  } else if (field.isVisible) {
    return false
  } else {
    return true
  }
}

export const getFieldReplacingInfo = (options: {
  field: CustomFieldType,
  fieldsList: CustomFieldType[],
  customFieldValues?: any,
  isLeafNode: boolean,
}): { shouldBeReplaced: boolean, value: any } => {
  const { field, fieldsList, customFieldValues, isLeafNode } = options
  // Get the aggregate value if there is a field that replaces current field
  const replacerField = fieldsList?.find(
    (f) => f.isReplacement && f.replacementAttributeId && +f.replacementAttributeId === +field.id,
  )
  //Check if the current field has replacement current record is not leave node
  const shouldBeReplaced = !isLeafNode && replacerField

  const value = !customFieldValues
    ? undefined
    : customFieldValues?.find((i: any) =>
        shouldBeReplaced ? +i.customFieldId === +replacerField.id : +i.customFieldId === +field.id,
      )
  return {
    shouldBeReplaced: Boolean(shouldBeReplaced),
    value,
  }
}

export const getFieldFormatting = (
  renderValue: string,
): { color: string, backgroundColor: string, raw: string } | null => {
  try {
    const parsedRenderValue = renderValue ? JSON.parse(renderValue) : null
    const raw = parsedRenderValue.raw
    const color = parsedRenderValue.styles.find(
      (f: any) => +f.styleAttributeType === FieldFormattingProperties.FONT_COLOR,
    )?.styleAttributeValue
    const backgroundColor = parsedRenderValue.styles.find(
      (f: any) => +f.styleAttributeType === FieldFormattingProperties.BACKGROUND_COLOR,
    )?.styleAttributeValue
    return { color, backgroundColor, raw }
  } catch (err) {
    return null
  }
}

export const getFieldVisibility = (options: {
  attributeId: number,
  attributeType: AppElementAttributeType,
  viewAttributes: AppElementViewAttribute[],
  defaultAttributeVisibility: AppElementAttributeVisibility,
  field?: CustomFieldType,
  fieldsList?: CustomFieldType[],
  elementBaseType?: AppElementBaseTypes,
}): AppElementAttributeVisibility => {
  const { attributeId, attributeType, viewAttributes, defaultAttributeVisibility, field, fieldsList, elementBaseType } =
    options

  // Get attribute
  const attribute = viewAttributes.find(
    (attribute) => attribute.attributeType === attributeType && attribute.attributeId == attributeId,
  )

  // If we found an attribute and these parameters are sent, it means that this is a custom field. We should check the replacement property
  if (field && fieldsList && elementBaseType) {
    // If field is not visible in its definition immediately hide it.
    if (field.isVisible === false) {
      return AppElementAttributeVisibility.Hidden
    }

    if (field.isReplacement) {
      const replacementElementIndex = findReplacementIndex({ field, fieldsList, elementBaseType })

      // If this field replaces an existing field then it should be hidden immediately
      if (replacementElementIndex > -1) {
        return AppElementAttributeVisibility.Hidden
      } else {
        return attribute ? attribute.attributeVisibility : defaultAttributeVisibility
      }
    } else {
      // If field is not a replacement then we should just return its visibility or the default
      return attribute ? attribute.attributeVisibility : defaultAttributeVisibility
    }
  }

  if (!attribute) {
    return defaultAttributeVisibility
  }
  return attribute.attributeVisibility
}

const findReplacementIndex = (options: {
  field: CustomFieldType,
  fieldsList: CustomFieldType[],
  elementBaseType: AppElementBaseTypes,
}): number => {
  const { field, fieldsList, elementBaseType } = options
  let replacementElementIndex = -1
  // Check if replacement exists in current element
  if (field.replacementAttributeId && field.replacementAttributeType) {
    if (field.replacementAttributeType === AppElementAttributeType.customField) {
      replacementElementIndex = fieldsList.findIndex((f) => +f.id === Number(field.replacementAttributeId))
      return replacementElementIndex
    } else if (field.replacementAttributeType === AppElementAttributeType.defaultAttribute) {
      switch (elementBaseType) {
        case AppElementBaseTypes.RecordAppElement: {
          replacementElementIndex = Object.values(recordAppElementDefaultAttributes).includes(
            field.replacementAttributeId,
          )
            ? 1
            : -1

          return replacementElementIndex
        }

        default:
          return -1
      }
    } else {
      return -1
    }
  }
  return -1
}
