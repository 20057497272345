import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import Button, { ButtonVariant } from 'components/Buttons'

type InfoModalPropsType = {
  onConfirm: () => void,
  confirmationMessage: string,
  confirmationDescription?: string,
  isModalOpen: boolean,
}

const InfoModal: FC<InfoModalPropsType> = ({
  onConfirm,
  confirmationMessage,
  confirmationDescription,
  isModalOpen,
}) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      className="fixed w-full max-h-screen p-6 overflow-auto bg-white rounded shadow top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 focus:outline-none sm:w-auto"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50">
      <h1>{confirmationMessage}</h1>
      {confirmationDescription && <div className="mt-2">{confirmationDescription}</div>}
      <div className="flex justify-end pt-6">
        <Button variant={ButtonVariant.Primary} onClick={onConfirm}>
          {t('labels.ok')}
        </Button>
      </div>
    </Modal>
  )
}

export default InfoModal
