import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { WorkspaceType } from 'utils/types'
import { configValues } from 'utils/appConfig'
import PlansEnum from 'utils/constant/enums/subscription'
import DisappearingText from 'components/DisappearingText'
import EntryAvatar from 'components/EntryAvatar'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'

type SingleWorkspaceTileProps = {
  workspace: WorkspaceType,
}

const SingleWorkspaceTile: FC<SingleWorkspaceTileProps> = ({ workspace }) => {
  const { t } = useTranslation('workspace')

  const UserAvatarStatusProps = {
    customSize: 7,
    hideUserStatus: true,
    imgAlt: `${workspace.owner?.firstName} ${workspace.owner?.lastName}`,
    imgSrc: workspace.owner?.link,
    userId: workspace?.owner?.id,
  }

  return (
    <div>
      <Link
        key={workspace.id}
        className="block p-4 overflow-hidden bg-white rounded-ts-none rounded-2xl transition-shadow hover:shadow shadow-sm md:flex md:h-40"
        to={`/workspace/${workspace.id}/stations`}>
        <EntryAvatar entry={workspace} isWorkspace />

        <div className="flex flex-col flex-1 h-32 min-w-0 md:h-auto">
          <div className="flex items-center mb-2">
            <UserAvatarStatus {...UserAvatarStatusProps} removeIconCustomSize={1} />
            <div className="text-sm text-gray-600 truncate ms-2">
              {workspace.owner?.firstName} {workspace.owner?.lastName}
            </div>
            {configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && (
              <div className="ms-auto">
                {+workspace?.plan?.plan === PlansEnum.Basic ? (
                  <div className="px-2 text-xs font-bold bg-gray-300 rounded leading-6">{t('basic')}</div>
                ) : (
                  <div className="px-2 text-xs font-bold rounded bg-caution leading-6">{t('premium')}</div>
                )}
              </div>
            )}
          </div>
          <div className="flex shrink-0 mb-1 text-lg font-bold text-gray-800 line-clamp-2">{workspace.name}</div>
          <DisappearingText text={workspace.description} />
          {/*<div className="flex h-1 mt-2 mb-1 overflow-hidden bg-gray-200 rounded-sm">*/}
          {/*  <div className="h-1 bg-primary" style={{ width: '79%' }} />*/}
          {/*  <div className="h-1 bg-white w-0.5" />*/}
          {/*</div>*/}
          {/*<div className="text-xs tracking-wide">*/}
          {/*  Used*/}
          {/*  <strong className="px-1 font-bold">1.58 GB</strong>*/}
          {/*  out of 2 GB.*/}
          {/*  <a className="font-bold ms-1 text-primary" href="/">*/}
          {/*    Upgrade?*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </Link>
    </div>
  )
}
export default SingleWorkspaceTile
