import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedActivityPath } from 'utils/helpers/generalHelpers'
import { ActivityLogType, UsersTablePropsType } from 'utils/types'
import { ActivityModules } from 'utils/types/ActivityLogType'

interface ActivityCreated {
  activityUser: UsersTablePropsType;
  activity?: ActivityLogType;
  isHijri?: boolean;
  language?: string;
  displayName?: boolean;
  isNotification?: boolean;
  setSelectedRecordId?: (value: any) => void;
  isWidget?: boolean;
}

const Create: FC<ActivityCreated> = ({
  activityUser,
  activity,
  isHijri,
  language,
  displayName = false,
  isNotification = false,
  isWidget = false,
  setSelectedRecordId,
}) => {
  const { t } = useTranslation('records')
  const userName =
    activity?.user !== undefined
      ? `${activity?.user?.firstName} ${activity?.user?.lastName}`
      : `${activity?.firstName} ${activity?.lastName}` || activityUser?.name

  const recordName = activity?.recordName || activity?.record?.name
  const recordId = activity?.recordId || activity?.record?.id
  const createdRecord = activity?.relatedObjectType === ActivityModules.Record
  const isNotifOrWidget = isNotification || isWidget
  const shouldDisplayName = isNotifOrWidget && activity

  const renderName = () => {
    if (!activity) return
    switch (activity.relatedObjectType) {
      case ActivityModules.Record: {
        if (displayName && activity) {
          return (
            <div
              className="mt-1 cursor-pointer text-primary"
              // href={`/workspace/${activity?.workspaceId}/stations/${activity?.stationId}/lists/${activity?.listId}?recordId=${recordId}`}
              // rel="noreferrer"
              // target="_blank"
              onClick={() => setSelectedRecordId(recordId)}>
              {recordName}
            </div>
          )
        }
        break
      }

      case ActivityModules.Attachment: {
        return (
          <div>
            {shouldDisplayName &&
              (isNotification ? (
                <div className="mb-4">
                  <span className="text-sm me-1">{t('in')}</span>
                  <a
                    className="mt-1 cursor-pointer text-primary"
                    href={`/workspace/${activity?.workspaceId}/stations/${activity?.stationId}/lists/${activity?.listId}?recordId=${activity?.record?.id}`}
                    rel="noreferrer"
                    target="_blank">
                    {activity?.recordName}
                  </a>
                  {getFormattedActivityPath(activity?.workspaceName, activity?.stationName, activity?.listName)}
                </div>
              ) : (
                <div
                  className="mb-2 text-xs cursor-pointer sm:tracking-normal sm:mt-1 sm:text-sm"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>
                  {t('in')} <span className="text-primary">{activity.recordName}</span>
                  {getFormattedActivityPath(null, activity?.stationName, activity?.listName)}
                </div>
              ))}
            {activity.objectData.name}
          </div>
        )
      }
    }
  }

  return (
    <div>
      <div className="flex items-baseline my-1 leading-4">
        <label className="text-sm font-bold me-1 leading-4">{userName}</label>
        <span className="text-sm leading-4">{createdRecord ? t('recordCreated') : t('attachedAFile')}</span>
      </div>
      {renderName()}
    </div>
  )
}

export default Create
