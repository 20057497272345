import React, { FC, memo, useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useSize } from 'react-use'
import { useParams } from 'react-router-dom'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import GaugeChartDataType, { FormattedGaugeChartDatasetType } from 'utils/types/GaugeChartDataType'
import { getFieldFormatting } from 'utils/helpers/customFields'
import { DashboardStateWidgetType } from 'utils/types/states'
import { getElementLinkFromPath } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import CustomScroll from '../../../CustomScroll'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import GaugeWidgetSlide from './GaugeWidgetSlide'

const setSlidesPerView = (width: number) => {
  if (width < 400) {
    return 1
  }

  if (width < 500) {
    return 2
  }

  if (width > 1500) {
    return 8
  }

  if (width > 1300) {
    return 6
  }

  if (width > 900) {
    return 5
  }

  if (width > 720) {
    return 4
  }

  return 3
}

const arrowStyle = {
  background: 'white',
  color: 'white',
  display: 'block',
  minWidth: 24,
  position: 'absolute',
  top: 'calc(50% - 10px)',
  width: 24,
}

const iconStyles: any = {
  color: 'black',
  marginTop: -20,
  position: 'relative',
  zIndex: 999,
}

const PrevArrow: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className="prev"
      style={{
        ...style,
        ...arrowStyle,
        left: '-10px',
      }}
      onClick={onClick}>
      <ChevronLeftIcon
        style={{
          ...iconStyles,
        }}
      />
    </div>
  )
}

const NextArrow: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      style={{
        ...style,
        ...arrowStyle,

        right: '-10px', // isRtl ? 0 : 'unset',
      }}
      onClick={onClick}>
      <ChevronRightIcon
        style={{
          ...iconStyles,
        }}
      />
    </div>
  )
}

const ListSwiper: FC<{
  rtl: boolean,
  isStepsEmpty: boolean,
  datasets: FormattedGaugeChartDatasetType[],
  parentWidth: number,
}> = ({ rtl, datasets, parentWidth, isStepsEmpty }) => {
  const { t } = useTranslation()
  const slickRef = useRef<any>(null)
  const { workspaceId, stationId } = useParams<{
    workspaceId: string,
    stationId: string,
  }>()

  useEffect(() => {
    if (slickRef && slickRef.current) {
      if (rtl) {
        slickRef.current.slickGoTo(datasets.length - 3)
      } else {
        slickRef.current.slickGoTo(0)
      }
    }
  }, [rtl])

  const plugins = (data: number) => {
    return [
      {
        beforeDraw: function (chart: any) {
          const width = chart.width
          const height = chart.height
          const ctx = chart.ctx
          ctx.restore()
          const fontSize = '20'
          ctx.font = '600 ' + fontSize + 'px Lato'
          ctx.textBaseline = 'top'
          const text = data
          const textX = Math.round((width - ctx.measureText(text).width) / 2)
          const textY = height / 2 - 14
          ctx.fillText(text, textX, textY)
          const fontCompletion = '14'
          const fontFaceCompletion = rtl ? 'Almarai' : 'Lato'
          ctx.font = fontCompletion + 'px ' + fontFaceCompletion
          const textCompletion = t('records:records')
          const textYCompletion = height / 2 + 14
          const textXCompletion = Math.round((width - ctx.measureText(textCompletion).width) / 2)
          ctx.fillText(textCompletion, textXCompletion, textYCompletion)
          ctx.save()
        },
      },
    ]
  }

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: false,
    slidesToScroll: 1,
    slidesToShow: setSlidesPerView(parentWidth),
    speed: 500,
  }

  return (
    <>
      <Slider className="w-full" ref={slickRef} {...settings}>
        {datasets?.map((dataset) => {
          const listLink = dataset.element.legacyPath
            ? getElementLinkFromPath(dataset.element.legacyPath, AppElementBaseTypes.ListAppElement)
            : '#'

          return (
            <GaugeWidgetSlide
              key={dataset.element.id}
              dataset={dataset}
              isStepsEmpty={isStepsEmpty}
              listLink={listLink}
              plugins={plugins(dataset?.totalRecord)}
            />
          )
        })}
      </Slider>
    </>
  )
}

const GaugeWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, onUpdateWidgetImage, isEdit, isPrinting }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const isRtl = i18n.language === 'ar'
  const ref = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const [elementWidthValue, setElementWidthValue] = useState(0)
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null
  const { workspaceId } = useParams<{ stationId: string, workspaceId: string }>()
  const showScores = workspaceId === '308'

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  useEffect(() => {
    const eleWidth = ref?.current?.offsetWidth
    if (ref.current && eleWidth !== elementWidthValue) {
      setElementWidthValue(eleWidth)
    }
  }, [ref?.current?.offsetWidth])

  if (isDataLoading) {
    return <Loader />
  }

  const gaugeData: any = widget ? widget.data : widgetInfo.data

  const datasets: FormattedGaugeChartDatasetType[] = gaugeData?.values?.map((item: GaugeChartDataType) => {
    const actual = Number(item?.actual?.value)
    const actualLabel = item?.actual?.fieldName
    const labelFormatting = getFieldFormatting(item.label.value)
    const label =
      labelFormatting === null ? (
        <p>{item.label.value}</p>
      ) : (
        <p style={{ background: `${labelFormatting.backgroundColor}`, color: `${labelFormatting.color}` }}>
          {!labelFormatting.raw || ['_/Err-001\\_', 'NaN'].indexOf(labelFormatting.raw) > -1
            ? t('invalid')
            : labelFormatting.raw}
        </p>
      )
    const element = item.element
    const orderedSteps = item.steps.map((step) => Number(step.value)).sort((a, b) => a - b)
    const breakPoints: number[] = []
    let sumOfBreakPoints = 0
    for (let i = 0; i < orderedSteps.length; i++) {
      if (i === 0) {
        breakPoints.push(orderedSteps[i])
        sumOfBreakPoints += orderedSteps[i]
      } else {
        const newBreakPoint = orderedSteps[i] - orderedSteps[i - 1]
        breakPoints.push(newBreakPoint)
        sumOfBreakPoints += newBreakPoint
      }
    }
    if (sumOfBreakPoints < 100) {
      breakPoints.push(100 - sumOfBreakPoints)
    }

    return {
      actual,
      actualLabel,
      label,
      element,
      steps: breakPoints,
      backgroundColors: ['#EB0045', '#ffc30f', '#00CE7C'],
    }
  })

  return (
    <>
      {sized}
      <div ref={ref} style={{ height: 'calc(100% - 40px)' }}>
        <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
          {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
            <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          )}

          <div className={`flex items-center justify-center `} style={{ height: '100%', width: '100%' }}>
            <ListSwiper
              key={isRtl + `${elementWidthValue}`}
              datasets={datasets}
              isStepsEmpty={widget?.data?.values[0]?.steps?.length === 0}
              parentWidth={!elementWidthValue ? 0 : elementWidthValue}
              rtl={isRtl}
            />
          </div>
        </CustomScroll>
      </div>
    </>
  )
}

export default memo(GaugeWidget)
