import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getStackByOptions } from 'utils/constant/constant/common'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { useActiveListView, useCurrentListDetails } from 'features/list'
import { useCurrentRecordsCustomFields } from 'features/record'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'

const StackByList: FC<{
  closeDropdown: () => void,
  setSelectedFilter: (value: any) => void,
}> = ({ closeDropdown, setSelectedFilter }) => {
  const isPro = useHasCurrentWorkspacePremiumPlan()
  const currentView = useActiveListView()
  const stackByOptions = getStackByOptions({ isPro, currentView })
  const { t } = useTranslation('records')
  const listDetails = useCurrentListDetails()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const allCustomFieldsList = getInheritedCustomFields(currentRecordsCustomFields)
  const customFields = !listDetails
    ? []
    : allCustomFieldsList
        ?.filter(
          (f: any) =>
            f.baseType === customFieldBaseTypes.DropDown ||
            (f.baseType === customFieldBaseTypes.SingleLink &&
              Number(listDetails?.appElement?.id) === +f?.appElementId),
        )
        ?.map((field: any) => ({ id: field.id, label: field.fieldName, value: field.id }))

  const stackList = [...stackByOptions, ...customFields]

  return (
    <>
      {stackList?.map((item: any, index) => {
        return (
          <div key={index} className="flex w-full py-2">
            <button
              className="flex hover:text-primary transition transition-colors"
              onClick={() => {
                setSelectedFilter(item)
                closeDropdown?.()
              }}>
              <p>{item.value ? item.label : t(`listing.${item.label}`)}</p>
            </button>
          </div>
        )
      })}
    </>
  )
}

export default StackByList
