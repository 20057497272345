import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { getRedirectFromLink } from 'utils/helpers/generalHelpers'

const LinkRouter = () => {
  const {
    location: { search },
  }: { location: { search: string | undefined } } = useHistory()
  const newPath = getRedirectFromLink(search)
  if (newPath === false) return <Redirect to="/" />

  return <Redirect to={newPath} />
}

export default LinkRouter
