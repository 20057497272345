import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchRecordById } from 'features/record'
import { addCommentValidationSchema } from '../../forms/validationSchemas'
import { withButtonsAndContentEditable } from '../../../hocs'
import RecordDescriptionRTEwithMentions from '../../RecordDescriptionRTEwithMentions'
import { fetchElementMakerCheckerPolicyRequests, updateRequestStatus } from '../../../features/element'
import { ChangeRequestStatus } from '../../../utils/constant/enums/common'
import { useCurrentUser } from '../../../features/user'
import { ListType, RecordType, StationType } from '../../../utils/types'
import { useAppDispatch } from '../../../store'

const EditableMultilineWithButtons = withButtonsAndContentEditable(RecordDescriptionRTEwithMentions)

type AddRequestCommentPropsType = {
  currentItem: RecordType | ListType | StationType,
  isAccept: boolean,
  setIsReject: (val: boolean) => void,
  setIsAccept: (val: boolean) => void,
  requestId: string | number,
  appElementId: string | number,
}

const AddRequestComment: FC<AddRequestCommentPropsType> = ({
  currentItem,
  isAccept,
  setIsReject,
  setIsAccept,
  requestId,
  appElementId,
}) => {
  const dispatch = useAppDispatch()
  const currentUser = useCurrentUser()
  const { t } = useTranslation('records')
  const [commentValue, setCommentValue] = useState('')
  const newCommentRef = useRef(null)

  useEffect(() => {
    newCommentRef?.current?.focus()
  }, [newCommentRef?.current])

  const handleAddComment = () => {
    const body = {
      ...currentItem,
      comment: {
        appElementId,
        text: commentValue,
      },
    }
    dispatch(
      updateRequestStatus({
        body,
        changeRequestId: requestId,
        status: isAccept ? ChangeRequestStatus.APPROVED : ChangeRequestStatus.REJECTED,
      }),
    ).finally(() => {
      dispatch(fetchRecordById({ id: currentItem.id }))
      dispatch(fetchElementMakerCheckerPolicyRequests(appElementId))
    })
  }

  const onCancel = () => {
    setIsReject(false)
    setIsAccept(false)
  }

  return (
    <div className="flex mx-8 mt-2">
      <img alt="user avatar" className="w-8 h-8 rounded-full me-4" loading="lazy" src={currentUser?.avatar} />
      <div>
        <EditableMultilineWithButtons
          autoFocus
          inputWrapperClassName="mb-0"
          isRejectButton={!isAccept}
          isRichTextEditor
          label={t('common:labels.description')}
          name="requestComment"
          ref={newCommentRef}
          submitButtonLabel={isAccept ? t('listing.addCommentAndAccept') : t('listing.addCommentAndReject')}
          validationSchema={undefined}
          value={commentValue}
          onCancel={onCancel}
          onChange={(e) => setCommentValue(e.target.value)}
          onSubmit={handleAddComment}
        />
      </div>
    </div>
  )
}
export default AddRequestComment
