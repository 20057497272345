import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import fileDownload from 'js-file-download'

import baseAPI from 'utils/baseAPI'
import { fetchRecordsByParams } from 'features/record'
import { useAppDispatch } from 'store'
import { fetchListById, importExcelSheet } from 'features/list'
// import ExcelEditableTable from './ExcelEditableTable'
import UploadExcelFile from './UploadExcelFile'

type InfoModalPropsType = {
  listId?: string | number,
  isModalOpen: boolean,
  onClose: () => void,
}

const ListImportExcelModal: FC<InfoModalPropsType> = ({ isModalOpen, onClose, listId }) => {
  const { t } = useTranslation('records')
  const [gridList, setGridList] = useState([])
  const [show, setShow] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)
  const appDispatch = useAppDispatch()

  const onSubmit = () => {
    appDispatch(importExcelSheet({ importFile: uploadedFile, listId: +listId })).then((res) => {
      if (res.meta.requestStatus === 'rejected') return
      appDispatch(fetchListById(+listId)).then(() => {
        appDispatch(fetchRecordsByParams({ listId: +listId })).then(() => {
          onClose()
        })
      })
    })
  }

  const downloadExcelTemplate = () => {
    baseAPI(`api/records/list/${listId}/download/template`).then((response: any) => {
      response.blob?.then((file: any) => {
        const fileName = 'records_template.xlsx'
        fileDownload(file, fileName)
      })
    })
  }

  return (
    <Modal
      className="fixed w-full max-h-screen p-6 overflow-auto bg-white rounded shadow top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 focus:outline-none sm:w-auto"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onClose}>
      <h1 className="mb-5">{t('import.header')}</h1>
      <div className="">
        {t('import.instructions')}
        {/* <span
          className="cursor-pointer text-primary text-sm"
          onClick={() => {
            setShow(!show)
          }}>
          {' '}
          Toggle instructions
        </span> */}
      </div>
      {/* {show && (
        <div className="mt-2 mb-1 text-sm ms-5">
          <ul>
            <li>1. Data are required for each column</li>
            <li>2. You can type in each column or copy base data from another excel sheet</li>
            <li>3. If you copy data from another file be sure the have the same column order</li>
            <li>4. Review data before upload it</li>
            <li>5. When click on upload table will be uploaded and add tasks as records</li>
          </ul>
        </div>
      )}
      <div className="my-2 overflow-auto">
        <ExcelEditableTable
          columns={columns}
          gridList={gridList}
          setGridList={setGridList}
          setUploadedFile={setUploadedFile}
          show={show}
          onConfirm={onConfirm}
        />
      </div> */}
      <div className="cursor-pointer text-primary" onClick={downloadExcelTemplate}>
        {t('import.downloadTemplate')}
      </div>
      <div className="flex justify-end pt-6">
        <UploadExcelFile setUploadedFile={setUploadedFile} onClose={onClose} onSubmit={onSubmit} />
      </div>
    </Modal>
  )
}

export default ListImportExcelModal
