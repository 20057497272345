import { AppElementBaseTypes } from './AppElementType'

interface ActivityLogType {
  id: number;
  action: number;
  creatorType: number;
  handledAt?: Date;
  activityDate?: Date;
  queuedAt?: Date;
  queueStatus: number;
  objectData: any;
  relatedObjectId: string;
  relatedObjectType: ActivityModules;
  userId: string;
  workspaceId?: number | null;
  workspaceName?: string | null;
  stationId?: number | null;
  stationName?: string | null;
  listId?: number | null;
  listName?: string | null;
  recordId?: number | null;
  recordName?: string | null;
  relatedAppElementBaseType?: AppElementBaseTypes;
  relatedAppElementName?: string;
}

/**Describes activity possible modules */
export enum ActivityModules {
  /**Workspace module */
  Workspace,

  /**Station module */
  Station,

  /**List module */
  List,

  /**Record module */
  Record,

  /**User module */
  User,

  /**Attachment */
  Attachment,

  /**Wiki */
  Wiki,

  /**Wiki page */
  WikiPage,

  /**App element */
  AppElement,
}

type ActivityLogKeys = ActivityLogType

export type { ActivityLogKeys }
export default ActivityLogType
