import { CSSProperties, ElementRef, FC } from 'react'
import classnames from 'classnames'

import { ControlProps, OptionsType } from 'react-select'
import { useTranslation } from 'react-i18next'

import { useRoles } from 'hooks'

import { Dropdown } from 'components/inputs'
import SettingsDropdown from 'components/SettingsDropdown'
import { DropdownOptionType } from 'utils/types/inputs'

export const styles = {
  control: (_: CSSProperties, state: ControlProps<DropdownOptionType, boolean>) => ({
    backgroundColor: state.isFocused ? '#EEEEEE' : '#FFFFFF',
    borderRadius: '4px',
    boxShadow: state.isFocused ? '0px 1px 2px #EEEEEE' : 'none',
    display: 'flex',
    padding: state.isFocused ? '' : '3px 0 4px',
  }),
  input: () => ({ position: 'absolute' }),
  menu: (base: CSSProperties) => ({
    ...base,
    display: 'flex',
    justifyContent: 'center',
  }),
}

const SingleColorForm: FC<{
  color?: string,
  colors: OptionsType<DropdownOptionType>,
  name: string,
  id?: number,
  isStatuses: boolean,
  updateColor: (newColor: string, name: string) => void,
  removeElement: (id: number | undefined, name: string) => void,
  tableCellClasses: string,
  tableRowClasses: string,
}> = ({ color, colors, name, isStatuses, id, updateColor, removeElement, tableCellClasses, tableRowClasses }) => {
  const { isEditor } = useRoles()
  const { t } = useTranslation()

  const Option = ({
    innerRef,
    innerProps,
    data: { value },
  }: {
    innerRef: ElementRef<any>,
    innerProps: any,
    data: { value: string },
  }) => (
    <div
      className="w-20 h-4 my-2 rounded cursor-pointer"
      ref={innerRef}
      style={{ backgroundColor: value }}
      {...innerProps}
    />
  )

  const SingleValue = ({ data: { value } }: { data: { value: string } }) => (
    <div className={classnames('w-36 h-4 my-1 rounded')} style={{ backgroundColor: value }} />
  )

  return (
    <div className={tableRowClasses}>
      {isStatuses ? (
        <div className={classnames('flex-1 p-2 group-hover:border-white', tableCellClasses)}>
          <p className="p-2 text-center rounded leading-4" style={{ backgroundColor: `${color}20`, color: color }}>
            {name}
          </p>
        </div>
      ) : (
        <div className={classnames('flex-1 p-3 group-hover:border-white', tableCellClasses)}>
          <button
            className="px-4 py-1 text-sm font-bold rounded-full cursor-default -my-0.5 transition text-start"
            style={{ backgroundColor: `${color}20`, color: color }}
            type="button">
            {name}
          </button>
        </div>
      )}
      <Dropdown
        classes={{
          input: 'react-select-small',
          wrapper: classnames(`w-40 group-hover:border-white `, tableCellClasses),
        }}
        components={{
          Option,
          SingleValue,
        }}
        isDisabled={!isEditor}
        isSearchable={false}
        name="status"
        options={colors}
        styles={styles}
        value={color}
        onChange={(event) => updateColor(event.target.value, name)}
      />
      <div className={classnames('p-3 group-hover:border-white ', tableCellClasses)}>
        <SettingsDropdown childClasses="w-44 end-0 rounded-te-none">
          <div className="flex flex-col items-start text-gray-600">
            <button className="text-danger hover:text-primary " type="button" onClick={() => removeElement(id, name)}>
              {t('common:labels.remove')}
            </button>
          </div>
        </SettingsDropdown>
      </div>
    </div>
  )
}

export default SingleColorForm
