import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { ActivityQueueStatuses } from 'utils/constant/enums/notifications'
import { updateNotificationStatus } from '../../features/element'
import ActivityItem from '../Activities/Activity/Item'

interface ActivityItemProps {
  notification: any;
  key: string;
}

const NotificationItem: FC<ActivityItemProps> = ({ key, notification }) => {
  const { id, status, activity } = notification
  const dispatch = useDispatch()
  const objectData = JSON.parse(activity.objectData)

  const onUpdateStatus = () => {
    const newStatus = status === ActivityQueueStatuses.Queued ? 'read' : 'unread'
    dispatch(updateNotificationStatus({ id, status: newStatus, notificationItem: notification }))
  }

  const newActivityData = { ...activity, objectData }

  return (
    <div className="border-b pb-5">
      <ActivityItem activity={newActivityData} isNotification={true} onUpdateStatus={onUpdateStatus} />
    </div>
  )
}

export default NotificationItem
