import { useEffect, useState } from 'react'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactInputVerificationCode from 'react-input-verification-code'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import baseAPI from 'utils/baseAPI'
import classnames from 'classnames'

import { handleAuthResponse } from 'utils/helpers/authHelpers'
import { SimpleAuthToken } from 'utils/types/AccessTokensType'
import Loader from 'components/Loader'
import Button, { ButtonVariant } from '../Buttons'

const ResendTimer = ({ token }: { token: string }) => {
  const { t } = useTranslation('auth')
  const [seconds, setSeconds] = useState(60)
  const [isLoading, setLoading] = useState(false)
  const resetTime = (waitTime = 60) => {
    setSeconds(waitTime)
  }

  const resendOtp = async () => {
    setLoading(true)
    const response = await baseAPI('auth/resendotp', {
      body: { token },
      method: 'POST',
    })

    if (response.body.waitTime) {
      resetTime(response.body.waitTime)
    }

    setLoading(false)
  }

  useEffect(() => {
    // Timer will decrement every second until it reaches 0
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          return 0
        }
        return prevSeconds - 1
      })
    }, 1000)
    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timer)
      resetTime()
    }
  }, [])
  return (
    <>
      <div className="mt-10 text-lg text-center">
        00:{seconds.toString().length === 1 && '0'}
        {seconds}
      </div>
      <div className="mt-2 text-sm text-center">
        <p>
          {t('smsConfirmation:resendMessage')}
          <button
            className={classnames('ms-1', {
              'text-gray-500 cursor-default': seconds > 0,
              'text-primary underline hover:text-primary-light': seconds == 0,
            })}
            disabled={isLoading || seconds > 0}
            onClick={resendOtp}>
            {t('smsConfirmation:resendCode')}
          </button>
        </p>
      </div>
    </>
  )
}

const SmsValidationInput = () => {
  const { t } = useTranslation('auth')
  const history = useHistory()
  const location = useLocation()
  const [value, setValue] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { token } = useParams<{ token: string }>()
  const decodedToken: JwtPayload & SimpleAuthToken = jwt_decode(token)

  const handleSubmit = async (code?: string) => {
    try {
      setLoading(true)
      const response = await baseAPI('auth/mfa', {
        body: {
          otp: code ?? value,
          token,
        },
        method: 'POST',
      })

      handleAuthResponse({ history, location, response, setLoading })
    } catch (err) {
      setLoading(false)
    }
  }

  const handleChange = (code: string) => {
    setValue(code)
    if (code.length === 6) {
      handleSubmit(code)
    }
  }

  return (
    <div className="text-center">
      <h2 className="font-bold">{t('smsConfirmation:SMSConformation')}</h2>
      <p className="mt-2 text-lg text-center">
        {t('smsConfirmation:SMSConformationDescription')}
        <p className="mt-2" dir="ltr">
          {decodedToken?.phoneNumber}
        </p>
      </p>

      <div className="flex justify-center mt-10 text-center custom-styles" dir="ltr">
        <ReactInputVerificationCode autoFocus length={6} placeholder="" value={value} onChange={handleChange} />
      </div>

      <div className="flex justify-center mt-10">
        <Button disabled={isLoading} variant={ButtonVariant.Primary} onClick={() => handleSubmit()}>
          {isLoading ? <Loader /> : t('common:next')}
        </Button>
      </div>

      <ResendTimer token={token} />

      <Button className="mt-5 text-sm" full variant={ButtonVariant.Ghost} onClick={() => history.push('/sign-in')}>
        {t('signIn:goBackToSignIn')}
      </Button>
    </div>
  )
}

export default SmsValidationInput
