import { StylesConfig } from 'react-select'
import { RecordType } from 'utils/types'

export const widgetsMobileHeight = {
  0: 2,
  1: 2,
  2: 3,
  3: 3,
  4: 2,
  5: 2.1,
  6: 4,
  7: 2,
  8: 2,
  9: 2,
  10: 2,
  11: 3,
  12: 2,
  13: 2,
  14: 2,
  15: 2,
  16: 2,
  17: 2,
  18: 2,
  19: 2,
  20: 1,
}

export const widgetSelectStyles: StylesConfig = {
  control: () => ({
    border: '1px solid #E8EBEE !important',
    borderRadius: 4,
    height: 34,
    maxHeight: 34,
    maxWidth: '100px !important',
    minHeight: '32px !important',
    overflowX: 'hidden',
    'text-overflow': 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90px !important',
  }),
  menuList: () => ({
    backgroundColor: '#fff !important',
    opacity: 1,
  }),
  singleValue: (provided, state) => {
    return { ...provided, fontSize: 14, padding: '6px 12px 6px 12px' }
  },
  valueContainer: () => ({
    alignItems: 'center',
    height: 32,
    maxHeight: 32,
    minHeight: 32,
  }),
}

export const manageDueSoonAndOverdueRecords = (records: RecordType[]) => {
  const dueSoonRecords = records?.filter((record) => record.recordDueStatus === 'dueSoon')

  const overdueRecords = records?.filter((record) => record.recordDueStatus === 'overdue')

  return { dueSoon: dueSoonRecords, overdue: overdueRecords, all: [...records] }
}
