import React, { FC, memo, useEffect, useState } from 'react'
import DashboardStateWidgetType, { OverallHealth } from 'utils/types/states/DashboardStateWidgetType'
import { mockGetResponse } from 'features/workspace/dashboardMock'
import { setColorByStatus } from 'utils/types/widgetUtils'
import { useSize } from 'react-use'
import CustomScroll from 'components/CustomScroll'
import { BudgetIcon, QualityIcon, ScheduleIcon } from '../../../Icons'
import OverviewWidgetCard from '../OverviewWidget/OverviewWidgetCard'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'

const HealthWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const data: any = widget?.data ? widget.data : mockGetResponse.widgets[5].data
  const healthWidgetData: OverallHealth = data
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const isMobile = elementWidth <= 600
  const middleWidth = elementWidth < 800 && !isMobile
  const { status, budgetHealth, qualityHealth } = healthWidgetData
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  const tableDataArr = [
    {
      icon: ScheduleIcon,
      title: 'schedule',
      value: status,
    },
    {
      icon: QualityIcon,
      title: 'quality',
      value: qualityHealth,
    },
    {
      icon: BudgetIcon,
      title: 'budget',
      value: budgetHealth,
    },
  ]

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }

  const renderHealthWidget = () => {
    return (
      <CustomScroll maxHeight={'100%'} hideScroll={isPrinting}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
        )}
        <div
          className={`${isMobile ? 'flex-col' : middleWidth ? 'grid grid-cols-3' : 'flex flex-1'} h-full lg:gap-4`}
          style={{ width: '100%' }}>
          {tableDataArr.map((item, index) => {
            const Icon = item.icon
            const statusObj = setColorByStatus(item.value, item.title === 'schedule')
            return (
              <OverviewWidgetCard
                key={item.title + index}
                Icon={Icon}
                customWidth={!middleWidth && !isMobile && elementWidth < 1075}
                iconBgColor={statusObj.bgColor}
                isMobile={isMobile}
                middleWidth={middleWidth}
                parentHeight={widgetInfo.dimensions.h}
                parentWidth={elementWidth}
                subTitle={statusObj}
                title={item.title}
              />
            )
          })}
        </div>
      </CustomScroll>
    )
  }

  return (
    <>
      {sized}
      <div style={{ height: 'calc(100% - 36px)' }}>{renderHealthWidget()}</div>
    </>
  )
}

export default memo(HealthWidget)
