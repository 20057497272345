import i18n from 'i18next'
import { toast } from 'react-toastify'
import { set } from 'lodash'

import { flattenObject } from 'utils/flattenObject'
import { configValues } from 'utils/appConfig'
import CustomAbortController from './helpers/fetchHelpers'
import { addTokensToLocalStorage, removeTokensFromLocalStorage } from './helpers/authHelpers'

export const customController = new CustomAbortController()

export const trimRequestBody = (body: Record<string, unknown>) => {
  const flattedBody = flattenObject(body)
  const trimmedBody = {}
  Object.keys(flattedBody).forEach((key) => {
    const value = typeof flattedBody[key] === 'string' ? flattedBody[key].trim() : flattedBody[key]
    set(trimmedBody, key, value)
  })
  return trimmedBody
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
let refreshingFunc: any = undefined

const isTokenRelatedMessage = (message: string | undefined): boolean => {
  return message === 'invalidToken' || message === 'jwt expired'
}

const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    if (!refreshToken) {
      removeTokensFromLocalStorage()
      window.location.replace(`/sign-in?error=tokenExpired`)
    }

    const queryUrl = `${configValues.REACT_APP_API_URL}/auth/refresh`
    const queryConfig: any = {
      headers: {
        'Arabic-Layout': i18n.language === 'ar',
        Authorization: refreshToken,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }

    return fetch(queryUrl, { ...queryConfig })
      .then((res) => res.json())
      .then((data) => {
        if (isTokenRelatedMessage(data.message)) {
          throw data.message
        }
        addTokensToLocalStorage({ body: data, resetUserId: false })
        return { jwtToken: data.accessTokenUserLogin.tokenData, refreshToken: data.refreshTokenUserLogin.tokenData }
      })
      .catch(() => {
        removeTokensFromLocalStorage()
        window.location.replace(`/sign-in?error=tokenExpired`)
      })
  } catch (error) {
    removeTokensFromLocalStorage()
    window.location.replace(`/sign-in?error=tokenExpired`)
  }
}

const baseAPI = async (url: string, options: any = {}, shouldDropAuthHeader = false, shouldDisplayToast = true) => {
  customController.createNewAbortController(options.requestType)
  const abortControllerId = customController.newestAbortControllerId
  const headersValue = shouldDropAuthHeader
    ? {
        'Arabic-Layout': i18n.language === 'ar',
        'Content-Type': 'application/json',
      }
    : {
        'Arabic-Layout': i18n.language === 'ar',
        Authorization: localStorage.getItem('jwtToken') || '',
        'Content-Type': 'application/json',
      }

  const basicQueryConfig: any = {
    headers: {
      ...headersValue,
    },
    method: 'GET',
    signal: customController.newestAbortController.signal,
  }

  const queryUrl = `${configValues.REACT_APP_API_URL}/${url}`
  const queryConfig = { ...basicQueryConfig }
  if (options.method && options.method !== 'GET') queryConfig.method = options.method

  if (options.body) {
    if (options.body.constructor.name === 'FormData') {
      queryConfig.body = options.body
      delete queryConfig.headers['Content-Type']
    } else queryConfig.body = JSON.stringify(trimRequestBody(options.body))
  }
  if (options.headers) queryConfig.headers = { ...queryConfig.headers, ...options.headers }

  try {
    const response = await fetch(queryUrl, queryConfig)
    customController.removeAbortControllerById({ id: abortControllerId, isSuccess: true })
    if (response.url.includes('download')) {
      return {
        blob: response.blob(),
        contentDisposition: response.headers.get('Content-Disposition'),
        status: response.status,
      }
    }
    if (response.url.includes('/assets/files/excels')) {
      return {
        blob: response.blob(),
        contentDisposition: response.headers.get('Content-Disposition'),
        status: response.status,
      }
    }

    const body: {
      toastType?: 'success' | 'error' | 'info' | 'warn' | 'none',
      message?: string,
      name?: string,
      result: any,
    } = await response.json()

    // Handle toast messages
    if (shouldDisplayToast && body.toastType !== 'none') {
      const toastFunction = body.toastType ? toast[body.toastType] : toast
      if (body.message && !isTokenRelatedMessage(body.message)) {
        if (body.name) {
          toastFunction(
            i18n.t(`validation:errors.${body.message}`, {
              name: i18n.t(`validation:fieldNames.${body.name}`),
            }),
            { icon: false },
          )
        } else if (body.message === 'passwordRegex') {
          toastFunction(i18n.t(`validation:errors.passwordRegex`), { icon: false })
        } else {
          toastFunction(i18n.t(`notifications:${body.message}`), { icon: false })
        }
      }
    }

    if (url !== 'auth/logout') {
      // Handle invalid tokens
      if (isTokenRelatedMessage(body?.message)) {
        if (!refreshingFunc) {
          refreshingFunc = refreshAccessToken()
        }
        const refreshResponse = await refreshingFunc

        options = { ...options, headers: { ...options.headers, Authorization: refreshResponse?.jwtToken } }

        return baseAPI(url, options)
      } else if (body?.message === 'noTokenProvided') {
        localStorage.setItem('lastVisitedPage', window.location.pathname)
        removeTokensFromLocalStorage()
        window.location.replace(`/sign-in?error=${body.message}`)
      }
    }

    return {
      body: body?.result || body,
      message: body.message,
      status: response.status,
    }
  } catch {
    const isCancelledFetchRecordsRequest =
      customController.newestAbortControllerId == 2 && options?.requestType == 'record'
    customController.removeAbortControllerById({ id: abortControllerId, isSuccess: false })
    return {
      status: isCancelledFetchRecordsRequest ? 429 : 404,
    }
  } finally {
    refreshingFunc = undefined
  }
}

export default baseAPI
