import { mockGetResponse } from 'features/workspace/dashboardMock'
import React, { FC, memo, useEffect, useState } from 'react'
import DashboardStateWidgetType, { DetailedRecords } from 'utils/types/states/DashboardStateWidgetType'
import CustomScroll from '../../../CustomScroll'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import RecursiveTree from './RecursiveTree'
import DetailedRecordsHeader from './DetailedRecordsHeader'

const columns = (headerRows: any[]) =>
  headerRows.map((row: any) => {
    if (!row) {
      return {
        header: '',
        minWidth: '40%',
        name: 'name',
        width: '60%',
      }
    }

    if (row.includes('Completion')) {
      return {
        header: '%',
        minWidth: '10%',
        name: 'completion',
        width: '20%',
      }
    }

    const nameWithoutSpaces = row.replace(/\s+/g, '')
    const preparedName = nameWithoutSpaces[0].toLowerCase() + nameWithoutSpaces.slice(1)

    return {
      header: row,
      minWidth: preparedName === 'dueDate' ? '25%' : '10%',
      name: preparedName,
      width: preparedName === 'dueDate' ? '25%' : '20%',
    }
  })

const DetailedRecordsWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, onUpdateWidgetImage, isEdit, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const data: any = widget?.data ? widget.data : mockGetResponse.widgets[7].data
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }

  const DiagramWidgetData: DetailedRecords = data
  const headerRows = columns(DiagramWidgetData.headerRows)

  return (
    <CustomScroll hideScroll={isPrinting}>
      {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
        <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
      )}
      <div style={{ minWidth: 400 }}>
        <div className="flex">
          {headerRows.map((row) => (
            <DetailedRecordsHeader key={row.name + row.header} row={row} />
          ))}
        </div>

        <RecursiveTree data={DiagramWidgetData?.data?.values} headerRows={headerRows} />
      </div>
    </CustomScroll>
  )
}

export default memo(DetailedRecordsWidget)
