import { FC } from 'react'

import { UserDropdown } from 'components/inputs'
import { useCurrentListMembers } from 'features/user'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'

const OwnerEditorCell: FC<CellEditorProps<RecordType['ownerId']>> = ({
  autoFocus,
  onChange,
  onComplete,
  onTabNavigation,
  value,
  isGridView,
}) => {
  useGridViewNavigation(
    () => onTabNavigation(true, -1),
    () => onTabNavigation(true, 1),
  )
  const currentListMembers = useCurrentListMembers()

  return (
    <div className="w-full">
      <UserDropdown
        autoFocus={autoFocus}
        isGridView={isGridView}
        menuPortalTarget={document.body}
        name="owner"
        openMenuOnFocus={autoFocus}
        options={currentListMembers}
        value={value}
        onChange={(e) => {
          onChange(e)
          setTimeout(() => onComplete(e), 0)
        }}
      />
    </div>
  )
}

export default OwnerEditorCell
