import { LocaleInput } from '@fullcalendar/common'
import { DateSelectArg } from '@fullcalendar/react'
import arLocale from '@fullcalendar/core/locales/ar-sa'
import enLocale from '@fullcalendar/core/locales/en-gb'
import { add, format, startOfWeek, endOfWeek, differenceInCalendarDays, lastDayOfMonth } from 'date-fns'
import { arSA, enGB } from 'date-fns/locale'
import { isNil, min } from 'lodash'

import { RecordType } from 'utils/types'
import { toGregorian, toHijri } from 'hijri-converter'

const TITLE_DATE_FORMAT = 'MMMM yyyy'
const SHORT_TITLE_DATE_FORMAT = 'MMM yyyy'
const DAY_FORMAT = 'MMMM d '
const PICKER_MONTH_FORMAT = 'MMMM'

const YEAR_FORMAT = 'yyyy'

export const ArabicMonths = {
  1: { ar: 'محرم', en: 'Muḥarram', enShort: 'Mhrm.' },
  2: { ar: 'صفر', en: 'Safar', enShort: 'Safr.' },
  3: { ar: 'ربيع الأول', en: 'Rabīʿ al-Awwal', enShort: 'Rab. I' },
  4: { ar: 'ربيع الثاني', en: 'Rabīʿ al-Thānī', enShort: 'Rab. II' },
  5: { ar: 'جمادي الأول', en: 'Jumādá al-Ūlá', enShort: 'Jmd. I' },
  6: { ar: 'جمادي الثاني', en: 'Jumādá al-Ākhirah', enShort: 'Jmd. II' },
  7: { ar: 'رجب', en: 'Rajab', enShort: 'Rajb.' },
  8: { ar: 'شعبان', en: 'Sha‘bān', enShort: 'Shbn.' },
  9: { ar: 'رمضان', en: 'Ramaḍān', enShort: 'Rmdn.' },
  10: { ar: 'شوال', en: 'Shawwāl', enShort: 'Shwl.' },
  11: { ar: 'ذو القعدة', en: 'Dhū al-Qa‘dah', enShort: 'Dhul-Q.' },
  12: { ar: 'ذو الحجة', en: 'Dhū al-Ḥijjah', enShort: 'Dhul-H.' },
}

export const hijriWeekFormat = (date1: Date, date2: Date, lang: string) => {
  const hijriDate = normalToHijri(date1)
  const hijriDate2 = normalToHijri(date2)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return `( ${lang === 'ar' ? toIndian(hijriDate.day) : hijriDate.day} ${ArabicMonths[hijriDate.month][lang]} ${
    hijriDate.year === hijriDate2.year ? '' : lang === 'ar' ? toIndian(hijriDate.year) : hijriDate.year
  } - ${lang === 'ar' ? toIndian(hijriDate2.day) : hijriDate2.day} ${ArabicMonths[hijriDate2.month][lang]} ${
    lang === 'ar' ? toIndian(hijriDate2.year) : hijriDate2.year
  } )`
}

export const hijriMonthFormat = (date: Date, lang: string, isPickerView: boolean | undefined) => {
  const dateMonth = new Date(date).getMonth() + 1
  const dateYear = new Date(date).getFullYear()
  const lastDayOnCurrentGregorian = lastDayOfMonth(new Date(date))
  const hijriDate1 = normalToHijri(new Date(`${dateYear}/${dateMonth}/1`))
  const hijriDate2 = normalToHijri(new Date(lastDayOnCurrentGregorian))
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dateFormat = `${ArabicMonths[hijriDate1.month][lang]} ${
    hijriDate1.year !== hijriDate2.year ? (lang === 'ar' ? toIndian(hijriDate1.year) : hijriDate1.year) : ''
  } - ${ArabicMonths[hijriDate2.month][lang]} ${lang === 'ar' ? toIndian(hijriDate2.year) : hijriDate2.year}`

  return isPickerView ? dateFormat : `( ${dateFormat} )`
}

export const hijriPickerMonthFormat = (date: Date, lang: string) => {
  const dateMonth = new Date(date).getMonth() + 1
  const dateYear = new Date(date).getFullYear()
  const lastDayOnCurrentGregorian = lastDayOfMonth(new Date(date))
  const hijriDate1 = normalToHijri(new Date(`${dateYear}/${dateMonth}/1`))
  const hijriDate2 = normalToHijri(new Date(lastDayOnCurrentGregorian))
  return `${ArabicMonths[hijriDate1.month][lang]} - ${ArabicMonths[hijriDate2.month][lang]}`
}

export const arabicDayFormat = (date: Date, lang: string) => {
  const hijriDate = normalToHijri(date)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return `${format(date, DAY_FORMAT, { locale: lang === 'ar' ? arSA : enGB })}    ( ${
    ArabicMonths[hijriDate.month][lang]
  } ${lang === 'ar' ? toIndian(hijriDate.day) : hijriDate.day} )`
}

export const getCalendarEvents = (
  records: RecordType[],
  isTimeline?: boolean,
  isHijri?: boolean,
  resource?: string,
  isResorceLink?: boolean,
  isDocumentsLinks?: boolean,
) => {
  return records
    .filter(({ endDate, startDate }) => {
      const recordsFilterForTimeline = isTimeline ? !isNil(endDate) && !isNil(startDate) : endDate !== undefined
      return recordsFilterForTimeline
    })
    .map((el) => {
      let resourceId = null
      const docLinkId =
        el?.appElements && el?.appElements[0]?.linkedElementAppElementLinks
          ? el.appElements[0]?.linkedElementAppElementLinks[0]?.elementId
          : null
      if (isResorceLink) {
        const elementLink =
          el?.appElements && el?.appElements[0]?.appElementLinks?.find((link) => link.customFieldId == resource)
        resourceId = elementLink ? elementLink.linkedElementId : null
      } else if (isDocumentsLinks) {
        resourceId = +docLinkId || null
      } else {
        resourceId = el[resource]
      }

      const {
        endDate: end,
        startDate: start,
        name: title,
        id,
        ownerId,
        completedIn,
        health,
        statusId,
        owner,
        priority,
        listId,
        appElements,
      } = el
      const endDate = end || el.endDate
      const startDate = start || el.startDate
      const recordTitle = title || el.name
      return {
        allDay: true,
        completedIn: completedIn || el?.completion,
        documents: +docLinkId,
        end: endDate && isTimeline ? add(new Date(endDate), { days: 1 }) : endDate,
        health,
        id: `${id}`,
        isHijri,
        owner,
        ownerId,
        priority,
        resourceId,
        start: isTimeline ? startDate : end,
        startDate: startDate,
        statusId,
        title: recordTitle,
        listId,
        isSubtask: appElements[0]?.elementLevel > 4,
        parentId: appElements[0]?.parentId,
        elementLevel: appElements[0]?.elementLevel,
      }
    })
}

export const calendarLocale: { [key: string]: LocaleInput } = {
  ar: arLocale,
  en: enLocale,
}

export const calendarLocales = [arLocale, enLocale]

export const handleSelect = (event: DateSelectArg, history: any, location: any) => {
  const { endStr: end, startStr: start } = event
  const endDate = new Date(end)
  const startDate = start ? new Date(start) : undefined
  const endDateInUTC = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1))
  const startDateInUTC = startDate
    ? new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))
    : undefined

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ownerId = +event.resource?._resource?.id || null

  history.push(`${location.pathname}?recordId=undefined`, {
    partialRecord: {
      endDate: endDateInUTC,
      ownerId,
      startDate: startDateInUTC,
    },
  })
}

export const getYearViewTitle = (currentDate: Date, locale: Locale) => format(currentDate, YEAR_FORMAT, { locale })

export const getMonthViewTitle = (
  currentDate: Date,
  locale: Locale,
  shortMonthFormat = false,
  type?: string,
  language?: string,
  isPickerView?: boolean,
) => {
  return type !== 'hijri'
    ? format(currentDate, shortMonthFormat ? SHORT_TITLE_DATE_FORMAT : TITLE_DATE_FORMAT, { locale })
    : hijriMonthFormat(currentDate, language, isPickerView)
}

export const getPickerMonthView = (currentDate: Date, type: string, locale: Locale) => {
  return type !== 'hijri'
    ? format(currentDate, PICKER_MONTH_FORMAT, { locale })
    : hijriPickerMonthFormat(currentDate, locale.code === 'ar-SA' ? 'ar' : 'en')
}

export const getWeekViewTitle = (currentDate: Date, locale: Locale, shortMonthFormat = false, type?: string) => {
  const lang = locale.code === 'ar-SA' ? 'ar' : 'en'
  const startOfCurrentWeek = startOfWeek(currentDate, { locale, weekStartsOn: 0 })
  const endOfCurrentWeek = endOfWeek(currentDate, { locale, weekStartsOn: 0 })

  const dateFormat = shortMonthFormat ? SHORT_TITLE_DATE_FORMAT : TITLE_DATE_FORMAT
  switch (true) {
    case type === 'hijri':
      return hijriWeekFormat(startOfCurrentWeek, endOfCurrentWeek, lang)
    case startOfCurrentWeek.getFullYear() !== endOfCurrentWeek.getFullYear():
      return `${format(startOfCurrentWeek, `d ${dateFormat}`, { locale })} - ${format(
        endOfCurrentWeek,
        `d ${dateFormat}`,
        {
          locale,
        },
      )}`
    default:
      return `${format(startOfCurrentWeek, `d ${shortMonthFormat ? 'MMM' : 'MMMM'}`, { locale })} - ${format(
        endOfCurrentWeek,
        `d ${dateFormat}`,
        {
          locale,
        },
      )}`
    // default:
    //   return `${format(startOfCurrentWeek, 'd', { locale })} - ${format(endOfCurrentWeek, `d ${dateFormat}`, {
    //     locale,
    //   })}`
  }
}

export const getCalendarTitle = (
  currentDate: Date,
  language: string,
  viewType: 'month' | 'week' | 'year' | 'agenda' | 'quarter',
  shortMonthFormat = false,
  type?: string,
  isPickerView?: boolean,
) => {
  const locale = language === 'ar' ? arSA : enGB
  switch (viewType) {
    case 'month':
    case 'quarter':
    case 'agenda':
      return getMonthViewTitle(currentDate, locale, shortMonthFormat, type, language, isPickerView)
    case 'week':
      return getWeekViewTitle(currentDate, locale, shortMonthFormat, type)
    case 'year':
      return getYearViewTitle(currentDate, locale)
  }
}

export const getTimelinePercentageOfFilling: (
  completedIn: number,
  eventRange: { start: Date, end: Date },
  visibleRange: { start: Date, end: Date },
) => number = (completedIn, { end: eventEnd, start: eventStart }, { end: rangeEnd, start: rangeStart }) => {
  if (completedIn === 0 || completedIn === 100) return completedIn

  const isEventStartedInCurrentRange = eventStart >= rangeStart
  const isEventEndedInCurrentRange = eventEnd < rangeEnd

  if (isEventStartedInCurrentRange && isEventEndedInCurrentRange) return completedIn

  const eventDurationInDays = differenceInCalendarDays(eventEnd, eventStart)

  const visiblePartOfEvent = isEventStartedInCurrentRange
    ? eventDurationInDays - differenceInCalendarDays(eventEnd, rangeEnd)
    : eventDurationInDays - differenceInCalendarDays(rangeStart, eventStart)

  const completionInDays = (eventDurationInDays * completedIn) / 100

  const fillingPercentage = isEventEndedInCurrentRange
    ? (completionInDays - (eventDurationInDays - visiblePartOfEvent)) / visiblePartOfEvent
    : completionInDays / visiblePartOfEvent

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return min([1, fillingPercentage]) * 100
}

export const normalToHijri = (date: Date) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth()
  const day = new Date(date).getDate()
  const hijri = toHijri(year, month + 1, day)
  return { day: hijri.hd, month: hijri.hm, year: hijri.hy }
}

export const hijriToGregorian = (date: string, lang: string) => {
  const datehijri = date.split('/')
  const gregorian = toGregorian(+datehijri[lang === 'ar' ? 0 : 2], +datehijri[1], +datehijri[lang === 'ar' ? 2 : 0])
  return { day: gregorian.gd, month: gregorian.gm, year: gregorian.gy }
}

export const hijriInputFormat = (date: Date, lang: string, isEditable: boolean) => {
  const hijri = normalToHijri(date)
  const year = isEditable ? hijri.year : toIndian(hijri.year)
  const month = isEditable ? hijri.month : toIndian(hijri.month)
  const day = isEditable ? hijri.day : toIndian(hijri.day)
  return lang === 'ar' ? `${year}/${month}/${day}` : `${hijri.day}/${hijri.month}/${hijri.year}`
}

export const hijriDateTimeFormat = (date: Date, lang: any, fullDate: Date) => {
  const newFullDate = new Date(fullDate)
  const timeText: { ar: { AM: string, PM: string }, en: { AM: string, PM: string } } = {
    ar: { AM: ' صباحا ', PM: ' مساء ' },
    en: { AM: ' AM ', PM: ' PM ' },
  }

  const dateDetails = {
    hr: newFullDate.getHours() % 12 === 0 ? 12 : newFullDate.getHours() % 12,
    min: forceDoubleDigitTime(newFullDate.getMinutes()),
    sec: forceDoubleDigitTime(newFullDate.getSeconds()),
  }

  const date1 = date.toString().split(' ')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const time = timeText[lang][date1[2]]
  return lang === 'ar'
    ? ` ${toIndian(dateDetails.sec)} : ${toIndian(dateDetails.min)} : ${toIndian(dateDetails.hr)}  ${time} `
    : ` ${dateDetails.hr} : ${dateDetails.min} : ${dateDetails.sec}  ${time} `
}

export const toIndian = (item: string | number) => {
  const indianNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

  return String(item)
    .split('')
    .map((char) => indianNumbers[Number(char)])
    .join('')
}

export const forceDoubleDigitTime = (time: string | number) => {
  const splitTime = String(time).split('')

  if (splitTime.length === 1) {
    splitTime.unshift('0')
  }

  return splitTime.join('')
}
