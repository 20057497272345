import React, { FC, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'
import { AddIcon } from 'components/Icons'
import ConfirmationModal from 'components/ConfirmationModal'

import { AttachmentListPropsType } from 'utils/types'
import { useRecordAttachments } from 'features/attachment'
import { fetchAttachmentsByRecordId } from 'features/attachment'
import { customController } from 'utils/baseAPI'
import { usePanel } from 'contexts/PanelContext'
import SingleAttachment, { NewAttachment } from './SingleAttachment'

const AttachmentList: FC<AttachmentListPropsType> = ({
  recordId,
  isEditor,
  inputProps,
  filesBeingUploaded,
  filesBeingUploadedRef,
}) => {
  const [shouldShowAll, setShouldShowAll] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const attachments = useRecordAttachments()
  const dispatch = useDispatch()
  const { t } = useTranslation('records')
  const { closePanel } = usePanel()
  const showModalRef = useRef(showModal)

  useEffect(() => {
    dispatch(fetchAttachmentsByRecordId(recordId))
  }, [dispatch, recordId])

  useEffect(() => {
    document.addEventListener('closePanel', preventClosingOnUpload)
    return () => {
      document.removeEventListener('closePanel', preventClosingOnUpload)
    }
  }, [])

  const preventClosingOnUpload = (event: any) => {
    if (!isEmpty(filesBeingUploadedRef.current) && !showModalRef.current) {
      event.preventDefault()
      updateShowModalState(true)
    }
  }

  const NO_SHOWN_ATTACHMENTS =
    Object.keys(filesBeingUploaded).length > 4 ? 0 : 4 - Object.keys(filesBeingUploaded).length

  const hasMoreThanThreeAttachments = attachments.length > NO_SHOWN_ATTACHMENTS

  const updateShowModalState = (newState: any) => {
    showModalRef.current = newState
    setShowModal(newState)
  }

  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <h5 className="block font-bold" id="attachments">
          {t('records:listing.attachments')}
        </h5>
        {isEditor && (
          <div>
            <input id="attachment" {...inputProps} />
            <label
              className="block p-3 cursor-pointer md:-mr-3 hover:text-primary transition transition-colors"
              htmlFor="attachment">
              <AddIcon className="w-6" />
            </label>
          </div>
        )}
      </div>
      <div className="mb-4">
        <div className="flex flex-wrap -me-2">
          {Object.keys(filesBeingUploaded).map((fileName: string) => {
            const file = filesBeingUploaded[+fileName]

            return (
              <div key={`${file?.name}-${file?.size}`} className="w-1/4 mb-2 pe-2">
                <div className="relative flex items-center justify-center bg-gray-200 rounded h-28">
                  <Loader loaderClasses="mb-8" />
                  <NewAttachment attachment={file} />
                </div>
              </div>
            )
          })}
          {attachments
            ? attachments?.map((attachment, index) => {
                if ((!shouldShowAll && index < NO_SHOWN_ATTACHMENTS) || shouldShowAll)
                  return <SingleAttachment key={attachment.id} attachment={attachment} isEditor={isEditor} />
              })
            : null}
        </div>
        {!shouldShowAll && hasMoreThanThreeAttachments && (
          <div className="flex justify-end w-full">
            <Button small variant={ButtonVariant.Outline} onClick={() => setShouldShowAll(true)}>
              {t('showAllAttachments')}
            </Button>
          </div>
        )}
      </div>
      <ConfirmationModal
        cancelMessage={t('no')}
        confirmMessage={t('yes')}
        confirmationMessage={t('confirmStoppingUploadingAttachment')}
        isModalOpen={showModal}
        onCancel={() => updateShowModalState(false)}
        onConfirm={() => {
          customController.abortAllControllers()
          closePanel()
        }}
      />
    </>
  )
}

export default AttachmentList
