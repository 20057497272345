import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'components/inputs'

import { removeFilterById, useCurrentRecordsCustomFields } from 'features/record'

import {
  fetchLinkedDocumentsByListId,
  useActiveListView,
  useCurrentListDetails,
  useCurrentListLabels,
  useCurrentListStatuses,
  useLinkedDocuments,
} from 'features/list'
import { useCurrentUser, useUsersOptionsByList } from 'features/user'
import { getFieldVisibility, getInheritedCustomFields } from 'utils/helpers/customFields'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'
import uniqBy from 'lodash/uniqBy'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { RecordAppElementAllAttributesConst } from 'utils/types/RecordAppElementAllAttributes'
import { getHealthOptions, getPriorityOptions } from '../../utils/helpers/recordHelpers'
import { ListType } from '../../utils/types'
import { useCustomListsFiltersOptions } from '../../features/station'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import NameFilter from './NameFilter'
import DropdownFilter from './DropdownFilter'
import CustomFieldFilter from './CustomTextFieldFilter'
import LinkFilter from './LinkFilter'

type SingleFilterType = {
  filterIndex: number,
  filterDetails: any,
  isCustomWidget: boolean,
  selectedElements?: ListType[],
  showBasics?: boolean,
}

const SingleFilter: FC<SingleFilterType> = ({
  filterIndex,
  filterDetails = [],
  isCustomWidget,
  selectedElements,
  showBasics,
}) => {
  const { t } = useTranslation('records')
  const listDetails = useCurrentListDetails()
  const currentListElementId = +listDetails?.appElement?.id
  const currentView = useActiveListView()
  const linkedDocumentsList = useLinkedDocuments()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const customFieldsList = getInheritedCustomFields(currentRecordsCustomFields)

  const linkedDocFormattedList = uniqBy(linkedDocumentsList, 'elementId').map((doc) => ({
    id: doc.element.id,
    label: doc.element.elementName,
    value: doc.element.id,
  }))

  useEffect(() => {
    if (listDetails?.id && !isCustomWidget) dispatch(fetchLinkedDocumentsByListId(+listDetails?.id))
  }, [listDetails, isCustomWidget])

  const customFields = selectedElements
    ? []
    : customFieldsList
        ?.filter((field: any) => field.baseType !== customFieldBaseTypes.Date)
        ?.map((field: any) => {
          const isTargetList = currentListElementId === +field?.intermediateAppElementId
          const optionsListId = isTargetList ? field?.elementId : field?.intermediateAppElementId
          const isLinkType =
            field.baseType === customFieldBaseTypes.Link || field.baseType === customFieldBaseTypes.SingleLink
          return {
            fieldType: field.baseType,
            id: field.id,
            label: field.fieldName,
            list:
              field.baseType === customFieldBaseTypes.DropDown
                ? field?.appElementCustomFieldOptions || field?.customFieldOptions
                : null,
            optionsListId: isLinkType ? optionsListId : null,
            value: field.id,
          }
        })

  const basicFilteringOptions = [
    {
      label: t('listing.name'),
      value: 'name',
    },
    {
      label: t('listing.priority'),
      value: 'priority',
    },
    {
      label: t('listing.health'),
      value: 'health',
    },
    {
      label: t('listing.owner'),
      value: 'owner',
    },
  ]

  const needListsOptions = [
    {
      label: t('listing.status'),
      value: 'status_id',
    },
    {
      label: t('listing.label'),
      value: 'label',
    },
  ]
  const defaultFilteringOptions = showBasics
    ? [...basicFilteringOptions]
    : [...basicFilteringOptions, ...needListsOptions]
  let basicFilterOptions: { label: string, value: string }[] = []
  if (!currentView) {
    basicFilterOptions = defaultFilteringOptions
  } else {
    defaultFilteringOptions.forEach((option) => {
      const { value } = option
      switch (value) {
        case 'name': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.name,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }

          break
        }
        case 'owner': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.ownerId,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }
          break
        }
        case 'status': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.statusId,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }
          break
        }
        case 'priority': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.priority,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }
          break
        }
        case 'label': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.labelsIds,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }
          break
        }
        case 'health': {
          const visibility = getFieldVisibility({
            attributeId: RecordAppElementAllAttributesConst.health,
            attributeType: AppElementAttributeType.defaultAttribute,
            defaultAttributeVisibility: currentView.defaultAttributeVisibility,
            viewAttributes: currentView.appElementViewAttributes,
          })

          if (visibility !== AppElementAttributeVisibility.Hidden) {
            basicFilterOptions.push(option)
          }
          break
        }
      }
    })
  }

  const filterOptions = [
    ...basicFilterOptions,
    ...customFields,
    {
      label: t('listing.creator'),
      value: 'creator',
    },
    {
      label: t('listing.linkedDocuments'),
      value: 'linkedDocumentId',
    },
  ]
  const [filterName, filterValue] = filterDetails
  const [selectedOption, setSelectedOption] = useState<string>(filterName || filterOptions[0].value)
  const dispatch = useDispatch()
  const statuses = useCurrentListStatuses()
  const labels = useCurrentListLabels()
  const ownerOptions = useUsersOptionsByList()
  const customListsFiltersOptions = useCustomListsFiltersOptions()
  const linkedDocOptions = linkedDocFormattedList
  const customOwners = uniqBy(customListsFiltersOptions.owners, 'value')
  const customStatuses = uniqBy(customListsFiltersOptions.statuses, 'id')
  const customLabels = uniqBy(customListsFiltersOptions.labels, 'id')
  const currentUser = useCurrentUser()
  const currentUserFilterOption = [{ label: `${currentUser.firstName} ${currentUser.lastName}`, value: currentUser.id }]
  const usersFilterOptions = isCustomWidget ? customOwners : showBasics ? currentUserFilterOption : ownerOptions

  const statusesOptions = useMemo(() => {
    const statusesList = isCustomWidget ? customStatuses : statuses
    const statusesOptions = statusesList.map(({ id, name }: { id: number, name: string }) => ({
      label: name,
      value: `${id}`,
    }))
    statusesOptions.push({ label: t('notAssigned'), value: 'null' })
    return statusesOptions
  }, [statuses, customStatuses, isCustomWidget, t])

  const labelsOptions = useMemo(() => {
    const labelsList = isCustomWidget ? customLabels : labels
    return labelsList.map(({ id, name }) => ({ label: name, value: `${id}` }))
  }, [labels, customLabels, isCustomWidget])

  const changeFilter = ({ target: { value } }: { target: { value: string } }) => {
    if (value) {
      setSelectedOption(value)
      dispatch(removeFilterById(filterIndex))
    }
  }

  const selectedCustomField = useMemo(
    () => customFields?.find((i: any) => i.value === selectedOption),
    [customFields, selectedOption],
  )

  return (
    <div className="flex items-center justify-start flex-1 min-w-0">
      <Dropdown
        classes={{ wrapper: 'flex-1 min-w-0' }}
        name="filter"
        options={showBasics ? basicFilterOptions : filterOptions}
        value={selectedOption}
        onChange={changeFilter}
      />
      {selectedOption === 'name' && <NameFilter filterIndex={filterIndex} filterValue={filterValue} />}
      {selectedOption === 'owner' && (
        <DropdownFilter
          dropdownOptions={usersFilterOptions}
          filterIndex={filterIndex}
          filterName="owner"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'creator' && (
        <DropdownFilter
          dropdownOptions={usersFilterOptions}
          filterIndex={filterIndex}
          filterName="creator"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'status_id' && (
        <DropdownFilter
          dropdownOptions={statusesOptions}
          filterIndex={filterIndex}
          filterName="status_id"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'priority' && (
        <DropdownFilter
          dropdownOptions={getPriorityOptions()}
          filterIndex={filterIndex}
          filterName="priority"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'label' && (
        <DropdownFilter
          dropdownOptions={labelsOptions}
          filterIndex={filterIndex}
          filterName="label"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'health' && (
        <DropdownFilter
          dropdownOptions={getHealthOptions()}
          filterIndex={filterIndex}
          filterName="health"
          filterValue={filterValue}
        />
      )}
      {selectedOption === 'linkedDocumentId' && (
        <DropdownFilter
          dropdownOptions={linkedDocOptions}
          filterIndex={filterIndex}
          filterName="linkedDocumentId"
          filterValue={filterValue}
        />
      )}
      {selectedCustomField &&
        (selectedCustomField?.list ? (
          <DropdownFilter
            dropdownOptions={selectedCustomField?.list.map((i: any) => ({ label: i.optionCaption, value: i.id }))}
            filterIndex={filterIndex}
            filterName={selectedCustomField?.id}
            filterValue={filterValue}
          />
        ) : selectedCustomField?.optionsListId ? (
          <LinkFilter
            customFieldInfo={selectedCustomField}
            filterIndex={filterIndex}
            filterName={selectedCustomField?.id}
            filterValue={filterValue}
            listId={selectedCustomField.optionsListId}
          />
        ) : (
          <CustomFieldFilter
            fieldType={selectedCustomField?.fieldType}
            filterIndex={filterIndex}
            filterName={selectedCustomField?.id}
            filterValue={filterValue}
          />
        ))}
    </div>
  )
}

export default SingleFilter
