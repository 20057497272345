import React, { forwardRef } from 'react'
import classnames from 'classnames'

import { ButtonPropsType } from 'utils/types'

export enum ButtonVariant {
  Primary,
  Secondary,
  Danger,
  Outline,
  Ghost,
  Icon,
  IconRoundedFilled,
  IconRoundedOutlined,
  Gray,
  Disabled,
  Text,
}

export const variants = {
  [ButtonVariant.Primary]: 'bg-primary text-white hover:bg-primary-dark shadow-primary',
  [ButtonVariant.Secondary]: 'bg-gray-600 text-white hover:bg-gray-700',
  [ButtonVariant.Danger]: 'bg-warning text-white hover:bg-danger',
  [ButtonVariant.Ghost]: 'text-gray-600 hover:bg-gray-300',
  [ButtonVariant.Outline]:
    'flex justify-center items-center text-gray-600 border-2 border-gray-400 border-solid shadow-sm hover:text-primary-dark hover:border-primary-dark',
  [ButtonVariant.Icon]:
    'flex items-center transition rounded p-3 hover:text-primary focus:outline-none focus-visible:shadow-focus',
  [ButtonVariant.IconRoundedFilled]: 'bg-gray-600 rounded-full w-11 h-11 flex justify-center items-center',
  [ButtonVariant.IconRoundedOutlined]:
    'border-2 border-gray-600 rounded-full w-11 h-11 flex justify-center items-center',
  [ButtonVariant.Gray]: 'bg-gray-200 hover:bg-gray-300',
  [ButtonVariant.Disabled]: 'bg-gray-200 text-gray-500 cursor-default',
  [ButtonVariant.Text]: 'font-normal',
}

const Button = forwardRef<HTMLButtonElement, ButtonPropsType>(
  ({ variant, type, children, small, icon: ButtonIcon, className, full, isActive, ...rest }, ref) => {
    const baseButtonClasses = `flex items-center font-bold text-center transition focus:outline-none focus-visible:shadow-focus ${
      small ? 'px-2 py-1.5 text-sm rounded-sm h-8' : 'px-6 py-3 text-base rounded'
    } ${isActive && 'bg-primary text-white hover:bg-gray-300'}`

    const notIcon =
      variant !== ButtonVariant.Icon &&
      variant !== ButtonVariant.IconRoundedFilled &&
      variant !== ButtonVariant.IconRoundedOutlined
    return (
      <button
        className={classnames(variants[variant || ButtonVariant.Primary], className, {
          [baseButtonClasses]: notIcon,
          'w-full justify-center': full,
        })}
        ref={ref}
        type={type || 'button'}
        {...rest}>
        {ButtonIcon && (
          <ButtonIcon
            className={classnames('md:w-6', {
              'me-1': notIcon,
              'w-5': variant !== ButtonVariant.IconRoundedFilled && variant !== ButtonVariant.IconRoundedOutlined,
            })}
          />
        )}{' '}
        {children}
      </button>
    )
  },
)
Button.displayName = 'Button'

export default Button
