import { createSlice } from '@reduxjs/toolkit'

import { AttachmentStateType } from 'utils/types/states'
import { createAttachment, fetchAttachmentsByRecordId, removeAttachment } from './actions'

const initialState: AttachmentStateType = { attachments: [], loading: true }

export const attachmentSlice = createSlice({
  extraReducers: (builder) => {
    //fetchAttachmentsByRecordId
    builder.addCase(fetchAttachmentsByRecordId.fulfilled, (state, action) => {
      state.attachments = action.payload
      state.loading = false
    })

    builder.addCase(fetchAttachmentsByRecordId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchAttachmentsByRecordId.rejected, (state) => {
      state.loading = false
    })

    //createAttachment
    builder.addCase(createAttachment.fulfilled, (state, action) => {
      state.attachments = [action.payload, ...state.attachments]
      state.loading = false
    })

    builder.addCase(createAttachment.pending, (state) => {
      state.loading = true
    })

    builder.addCase(createAttachment.rejected, (state) => {
      state.loading = false
    })

    //removeAttachment
    builder.addCase(removeAttachment.fulfilled, (state, action) => {
      const deletedAttachmentId = action.payload
      const deletedAttachmentIndex = state.attachments.findIndex(({ id }) => deletedAttachmentId === id)
      state.attachments.splice(deletedAttachmentIndex, 1)
      state.loading = false
    })

    builder.addCase(removeAttachment.pending, (state) => {
      state.loading = true
    })

    builder.addCase(removeAttachment.rejected, (state) => {
      state.loading = false
    })
  },
  initialState,
  name: 'attachment',
  reducers: {},
})

export default attachmentSlice.reducer
