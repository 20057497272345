import React, { FC } from 'react'
import Button, { ButtonVariant } from 'components/Buttons'
import { ArchiveIcon, ChevronLeftIcon, MenuIcon, TrashIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import HeaderPropsType from 'utils/types/HeaderPropsType'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { breakpoints } from 'utils/constant/constant/common'
import classNames from 'classnames'
import { withAdminRole, withEditorRole } from '../../hocs/withRole'
import { Dropdown } from '../inputs'
import { baseDropdownStyles } from '../inputs/Dropdown'

const AddStationButton = withEditorRole(Button)
const ReorderStationsButton = withAdminRole(Button)
const Header: FC<HeaderPropsType> = ({
  showBack,
  toggleMenu,
  currentItemName,
  onClick,
  onClick2,
  buttonTitle,
  buttonTitle2,
  isButtonActive,
  icon,
  icon2,
  title,
  needPermission,
  permission,
  hideName,
  isArchived,
  setIsArchived,
  options,
  onChangeDashboard,
  selectedOption,
  onRemoveDashboard,
  showArchived = false,
  showDeleteDashboard = false,
  isArchivedByWorkspace,
  exportPdfButton = null,
}) => {
  const { t } = useTranslation('station')
  const { goBack } = useHistory()
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < breakpoints.md

  return (
    <header className={`relative items-center mb-6 lg:flex lg:min-h-12`}>
      {toggleMenu && (
        <Button
          className="absolute -start-4 -top-2 lg:hidden"
          data-tut="mobile__sidebar"
          icon={MenuIcon}
          variant={ButtonVariant.Icon}
          onClick={() => toggleMenu(true)}
        />
      )}
      {showBack && (
        <button className="p-3 -ms-2 transition-colors hover:text-primary focus:text-primary" onClick={goBack}>
          <ChevronLeftIcon className="w-6 rtl-mirror" />
        </button>
      )}
      <h1 className="flex items-center mb-4 me-auto lg:mb-0 lg:ms-0 ms-6">
        {!hideName && (
          <>
            {!options ? (
              currentItemName
            ) : (
              <Dropdown
                isGridView={false}
                name={'dashboard'}
                options={options}
                style={baseDropdownStyles}
                value={selectedOption?.value}
                onChange={(e) => onChangeDashboard(e.target.value)}
              />
            )}
            <span className="font-normal ps-2">{title}</span>
          </>
        )}
      </h1>
      <div className="justify-start md:flex">
        {showArchived && (
          <AddStationButton
            className="shrink-0 sm:mb-1 me-1"
            data-tut="second__step"
            full={isMobile}
            icon={ArchiveIcon}
            isActive={isArchived}
            variant={ButtonVariant.Outline}
            onClick={() => setIsArchived(!isArchived)}>
            {isArchived ? t('common:labels.exitArchive') : t('station:archivedStations')}
          </AddStationButton>
        )}
        {!isArchivedByWorkspace && !isArchived && (
          <>
            <div className={`md:me-1 ${isMobile && 'my-1'}`}>
              {onClick2 &&
                (needPermission ? (
                  permission ? (
                    <ReorderStationsButton
                      className="shrink-0 sm:mb-1"
                      data-tut="second__step"
                      full={isMobile}
                      icon={icon2}
                      isActive={isButtonActive}
                      variant={ButtonVariant.Outline}
                      onClick={onClick2}>
                      {buttonTitle2}
                    </ReorderStationsButton>
                  ) : null
                ) : (
                  <ReorderStationsButton
                    className="shrink-0 sm:mb-1"
                    data-tut="second__step"
                    full={isMobile}
                    icon={icon2}
                    isActive={isButtonActive}
                    variant={ButtonVariant.Outline}
                    onClick={onClick2}>
                    {buttonTitle2}
                  </ReorderStationsButton>
                ))}
            </div>
            {onClick &&
              (needPermission ? (
                permission ? (
                  <>
                    <AddStationButton
                      className={classNames(`shrink-0 sm:mb-1`, { 'me-1': options })}
                      data-tut="second__step"
                      full={isMobile}
                      icon={icon}
                      variant={ButtonVariant.Outline}
                      onClick={onClick}>
                      {buttonTitle}
                    </AddStationButton>
                  </>
                ) : null
              ) : (
                <>
                  <AddStationButton
                    className={classNames(`shrink-0 sm:mb-1`, { 'me-1': options })}
                    data-tut="second__step"
                    full={isMobile}
                    icon={icon}
                    variant={ButtonVariant.Outline}
                    onClick={onClick}>
                    {buttonTitle}
                  </AddStationButton>
                </>
              ))}
          </>
        )}
        {showDeleteDashboard && (
          <Button
            className="shrink-0 sm:mb-1"
            icon={TrashIcon}
            variant={ButtonVariant.Outline}
            onClick={onRemoveDashboard}>
            {t('dashboard:deleteDashboard')}
          </Button>
        )}
        {exportPdfButton && exportPdfButton(true)}
      </div>
    </header>
  )
}

export default Header
