import Modal from 'react-modal'
import { FC, useEffect } from 'react'
import { CrossSignIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'

type BaseModalPropsType = {
  header: React.ReactNode,
  isModalOpen: boolean,
  content: React.ReactNode,
  close: () => void,
  withoutCloseBtn?: boolean,
  type?: 'confirmation' | 'md' | 'lg',
  shouldCloseOnOverlayClick?: boolean,
}

const baseModalClassnames = {
  confirmation:
    'fixed transition-all w-full max-h-screen bg-white rounded shadow top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 focus:outline-none lg:w-max md:w-max',
  sm: 'fixed transition-all z-50 flex w-full flex-col bg-white shadow-lg focus:outline-none bottom-0 top-0 lg:top-60 lg:bottom-60 rounded max-w-sm left-1/2 transform -translate-x-1/2',
  md: 'fixed transition-all z-50 flex w-full flex-col bg-white shadow-lg focus:outline-none bottom-0 top-0 lg:top-36 lg:bottom-36 rounded max-w-lg left-1/2 transform -translate-x-1/2',
  lg: 'fixed transition-all z-50 flex w-full flex-col bg-white shadow-lg focus:outline-none bottom-0 top-0 lg:top-20 lg:bottom-20 rounded max-w-5xl left-1/2 transform -translate-x-1/2',
}

const BaseModal: FC<BaseModalPropsType> = ({
  content,
  isModalOpen,
  header,
  close,
  withoutCloseBtn,
  type = 'md',
  shouldCloseOnOverlayClick = true,
}) => {
  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  return (
    <Modal
      className={baseModalClassnames[type]}
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={close}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
      <div className="flex items-center justify-between w-full py-4 px-2">
        <>{header}</>
        {!withoutCloseBtn && <Button icon={CrossSignIcon} variant={ButtonVariant.Icon} onClick={close} />}
      </div>
      <div className="flex flex-1 flex-col min-h-0 px-6 pb-6">{content}</div>
    </Modal>
  )
}

BaseModal.displayName = 'BaseModal'

export default BaseModal
