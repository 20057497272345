import React, { FC, useCallback, useMemo, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { configValues } from 'utils/appConfig'
import { RECORDS_PER_PAGE } from '../../utils/helpers/recordHelpers'
import { PaginationPropsType } from '../../utils/types'
import { Pagination } from '../GridView'

interface GeneralGridTableTypes {
  columns: any[];
  dataSource: any[];
  defaultFilterValue: any[];
  handle?: () => void;
  isLoading: boolean;
  loadingStatus?: boolean;
}

const GeneralGridTable: FC<GeneralGridTableTypes> = ({
  columns,
  dataSource,
  isLoading,
  defaultFilterValue,
  loadingStatus,
}) => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const pagePathName = pathname.split('/')
  const [gridRef, setGridRef] = useState<any>(null)
  const [tableColumns, setTableColumns] = useState<any>(columns)
  const gridStyle = { maxHeight: '90vh', minHeight: pagePathName.length > 3 ? '60vh' : '69vh', textAlign: 'center' }
  const isRtl = i18n.language === 'ar'

  const renderPaginationToolbar = useCallback(
    (props: PaginationPropsType) => <Pagination {...props} gridRef={gridRef?.current} />,
    [gridRef?.current],
  )
  return (
    <div dir={'auto'}>
      <ReactDataGrid
        activateRowOnFocus={false}
        columns={!loadingStatus ? tableColumns : []}
        dataSource={dataSource}
        defaultFilterValue={defaultFilterValue}
        editStartEvent="click"
        enableKeyboardNavigation={false}
        enableSelection={false}
        idProperty="id"
        licenseKey={configValues.REACT_APP_GRID_LICENSE_KEY}
        limit={RECORDS_PER_PAGE}
        loading={isLoading || loadingStatus}
        loadingText={<></>}
        nativeScroll
        pagination="local"
        renderPaginationToolbar={renderPaginationToolbar}
        reorderColumns={false}
        rowHeight={58}
        rtl={isRtl}
        showColumnMenuTool={false}
        showZebraRows={false}
        style={gridStyle}
        theme="default-light"
        virtualizeColumns={false}
        onReady={setGridRef}
      />
    </div>
  )
}
export default GeneralGridTable
