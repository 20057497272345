import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getHomePageDashboard, useHomeDashboard, useIsHomeDashboardLoading } from '../../features/workspace'
import Loader from '../Loader'
import HomeDashboardBaseWidget from './HomeDashboardBaseWidget'

const HomeDashboard: FC = () => {
  const { t } = useTranslation('dashboard')
  const dispatch = useDispatch()
  const dashboardData = useHomeDashboard()
  const isLoading = useIsHomeDashboardLoading()
  // const isMobile = window.innerWidth <= 1024

  useEffect(() => {
    dispatch(getHomePageDashboard())
  }, [])

  if (isLoading) {
    return <Loader loaderClasses="w-full h-full" svgClasses="w-10 h-10 m-auto" />
  }

  return (
    <>
      {dashboardData && (
        <div className="w-full lg:flex">
          <div className="w-full lg:pe-2 stations-widget">
            <HomeDashboardBaseWidget
              data={dashboardData?.stationListWidget}
              title={t('homeDashboard:workspacesStats')}
            />
          </div>
          {/*<div*/}
          {/*  className={`h-full ${isMobile && 'mt-3'}`}*/}
          {/*  style={{ maxHeight: '100vh', width: isMobile ? '100%' : '30%' }}>*/}
          {/*  <div className="tasks-widget">*/}
          {/*    <HomeDashboardBaseWidget data={dashboardData?.myDueRecordsWidget} title={t('homeDashboard:tasks')} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      )}
    </>
  )
}
export default HomeDashboard
