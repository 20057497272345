import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/inputs'
import { useParams } from 'react-router-dom'
import { PencilIcon } from '../Icons'
import { updateRecordElementById } from '../../features/record'
import { useAppDispatch } from '../../store'
import { RecordDatePicker } from '../inputs'
import { RecordType } from '../../utils/types'

type SubtaskItemPropTypes = {
  task: RecordType,
  isEditor: boolean,
  index: number,
  onTitleClick: () => void,
}

const SubtaskItem: FC<SubtaskItemPropTypes> = ({ task, onTitleClick, index, isEditor }) => {
  const { i18n } = useTranslation()
  const appDispatch = useAppDispatch()
  const { viewId } = useParams<{ viewId: string }>()
  const [isTitleEditing, setIsTitleEditing] = useState(false)
  const [isCompletedInEditing, setIsCompletedInEditing] = useState(false)
  const [editedCompletedIn, setEditedCompletedIn] = useState(task?.completedIn)
  const [editedTitle, setEditedTitle] = useState(task?.name)
  const isRtl = i18n.language === 'ar'

  const onEditTask = (values: any) => {
    appDispatch(
      updateRecordElementById({
        changes: { ...values },
        elementId: task?.appElements[0]?.id,
        viewId,
        record: task,
      }),
    )
  }

  const handleTitleClick = () => {
    setIsTitleEditing(true)
  }

  const handleCompletedInClick = () => {
    setIsCompletedInEditing(true)
  }

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value)
  }

  const handleTitleBlur = () => {
    setIsTitleEditing(false)
    if (task?.name !== editedTitle) onEditTask({ name: editedTitle })
  }

  const handleCompletedInChange = (e: any) => {
    const completedValue = e.target.value > 100 ? 100 : e.target.value < 0 || !e.target.value ? 0 : e.target.value
    setEditedCompletedIn(completedValue)
  }

  const handleCompletedInBlur = () => {
    setIsCompletedInEditing(false)
    if (task?.completedIn !== editedCompletedIn) onEditTask({ completedIn: editedCompletedIn })
  }

  return (
    <div
      className="flex items-center justify-between w-full px-2 mb-1 border-2 border-gray-100 rounded py-1.5 group"
      dir={isRtl ? 'rtl' : 'ltr'}>
      <div className="text-sm" style={{ width: 'calc(100% - 50%)' }}>
        {isTitleEditing ? (
          <Input
            autoFocus
            classes={{ wrapper: 'mb-1 mt-1 px-1 text-sm' }}
            disabled={!isEditor}
            name={'completedIn'}
            type="text"
            value={editedTitle}
            onBlur={handleTitleBlur}
            onChange={handleTitleChange}
          />
        ) : (
          <div className="flex items-center text-sm">
            {/* <span className="me-1">{index + 1}.</span> */}
            <div
              className="block truncate cursor-pointer max-w-50 hover:text-primary hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                onTitleClick()
              }}>
              {editedTitle}
            </div>
            {isEditor && (
              <div className="w-0 overflow-hidden ms-2 transition-all group-hover:w-4">
                <PencilIcon className="w-4 opacity-50 cursor-pointer" onClick={handleTitleClick} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="items-center text-sm lg:flex" style={{ width: 'calc(100% - 50%)' }}>
        <div className="text-gray-600">
          <div className="flex items-center px-2 py-1">
            {isCompletedInEditing ? (
              <Input
                autoFocus
                classes={{ wrapper: 'mb-1 mt-1 px-1 text-sm' }}
                disabled={!isEditor}
                max={100}
                min={0}
                type="number"
                value={editedCompletedIn}
                onBlur={handleCompletedInBlur}
                onChange={handleCompletedInChange}
              />
            ) : (
              <p onClick={handleCompletedInClick}>{editedCompletedIn}</p>
            )}
            <p className="ms-1 me-2">%</p>
          </div>
        </div>
        <div className="me-5">
          <RecordDatePicker
            currentItem={task}
            date={task?.startDate}
            dateToCompare={task?.endDate}
            handleRecordChange={onEditTask}
            isDisabled={!isEditor}
            name="startDate"
            shouldShowPlaceholder
          />
        </div>
        <div>
          <RecordDatePicker
            currentItem={task}
            date={task?.endDate}
            dateToCompare={task?.startDate}
            handleRecordChange={onEditTask}
            isDisabled={!isEditor}
            name="endDate"
            shouldShowPlaceholder
          />
        </div>
      </div>
    </div>
  )
}
export default SubtaskItem
