import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useParams } from 'react-router-dom'
import concat from 'lodash/concat'
import uniqBy from 'lodash/uniqBy'

import { UserType, ContactUsPropsType, ProfilePropsType, ElementMemberType } from 'utils/types'

import RolesEnum from 'utils/constant/enums'
import { getCurrentResourcePermission } from 'utils/helpers/permissionHelpers'
import UsersTablePropsType from 'utils/types/UsersTablePropsType'
import StateType from 'utils/types/states'
import { useCurrentListId } from 'features/list'

const selectCurrentUser: (state: StateType) => ProfilePropsType = (state) => state.user.user
const selectUsersByWorkspace = (state: StateType) => state.user.usersByWorkspace || []
const selectUsersByStation = (state: StateType) => state.user.usersByStation || []
const selectUsersByListWithRoles = (state: StateType) => state.user.usersByListWithRoles || []
const selectUsersByList = (state: StateType) => state.user.usersByList || []
const selectCurrentListMembers = (state: StateType) => state.list.currentListDetails?.listMembers || undefined
const selectLoading: (state: StateType) => boolean = (state) => state.user.loading
const selectUsersByNearestElement = (state: StateType) =>
  (state.user.usersByList && state.user.usersByList.length > 0 && state.user.usersByList) ||
  (state.user.usersByStation && state.user.usersByStation.length > 0 && state.user.usersByStation) ||
  (state.user.usersByWorkspace && state.user.usersByWorkspace.length > 0 && state.user.usersByWorkspace) ||
  []

const selectAllUsers = createSelector(
  [selectUsersByWorkspace, selectUsersByStation, selectUsersByListWithRoles],
  (workspaceUsers, stationUsers, listUsers) => {
    const allUsers = concat(workspaceUsers, stationUsers, listUsers)
    return uniqBy(allUsers, 'id')
  },
)
const selectUserById = createSelector([selectAllUsers, (_, id) => id], (users, userId) => {
  return users.find(({ id }) => id == userId)
})

const selectCurrentUserAvatar = createSelector(
  selectCurrentUser,
  (user) => user.avatar || '/assets/images/placeholder.svg',
)
const selectContactUsDetails = createSelector(selectCurrentUser, (user) =>
  user === undefined
    ? {
        name: '',
        supportEmail: 'info@mohimma.tech',
        userEmail: '',
      }
    : {
        name: `${user.firstName} ${user.lastName}`,
        supportEmail: 'ts@mohimma.tech',
        userEmail: user.email,
      },
)

export const useUsers = () => useSelector(selectAllUsers)
export const useUserById: (id: number | string | undefined) => UsersTablePropsType | undefined = (id) =>
  useSelector((state) => selectUserById(state, id))
export const useCurrentUser: () => ProfilePropsType = () => useSelector(selectCurrentUser)
export const useUserLoading: () => boolean = () => useSelector(selectLoading)
export const useUsersByWorkspace = (): UsersTablePropsType[] => useSelector(selectUsersByWorkspace)
export const useUsersByStation = (): UsersTablePropsType[] => useSelector(selectUsersByStation)
export const useUsersByListWithRole = (): UsersTablePropsType[] => useSelector(selectUsersByListWithRoles)
export const useUsersByList: () => UsersTablePropsType[] = () => useSelector(selectUsersByList)
export const useUsersByNearestElement: () => UsersTablePropsType[] = () => useSelector(selectUsersByNearestElement)
export const useCurrentListMembers: () => ElementMemberType[] | undefined = () => useSelector(selectCurrentListMembers)
export const useUserAvatar: () => string = () => useSelector(selectCurrentUserAvatar)
export const useContactUsDetails: () => ContactUsPropsType = () => useSelector(selectContactUsDetails)
export const useCurrentUserLoading: () => boolean = () =>
  useSelector((state: StateType) => state.user.loadingCurrentUser)

export const useUsersOptionsByList = () =>
  useSelector((state: StateType) => {
    const listUsers = state.user.usersByList || []
    return listUsers.map((user: UsersTablePropsType) => ({
      label: user.name,
      value: user.id,
    }))
  })

export const useCurrentUserPermissions: () => RolesEnum = () => {
  const { id: userId } = useCurrentUser()
  return useUserPermissions({ roleType: 'current', userId })
}

export const useUserPermissions: (params: { roleType: 'current' | 'previous', userId: number }) => RolesEnum = ({
  roleType,
  userId,
}) => {
  const params: { listId?: string, workspaceId?: string, stationId?: string } = useParams()
  return useSelector((state: StateType) => {
    switch (true) {
      case params.listId !== undefined: {
        if (roleType === 'current') {
          return getCurrentResourcePermission(state, 'list', params.listId, userId)
        }
        return getCurrentResourcePermission(state, 'station', params.stationId, userId)
      }
      case params.stationId !== undefined: {
        if (roleType === 'current') {
          return getCurrentResourcePermission(state, 'station', params.stationId, userId)
        }
        return getCurrentResourcePermission(state, 'workspace', params.workspaceId, userId)
      }
      case params.workspaceId !== undefined: {
        return getCurrentResourcePermission(state, 'workspace', params.workspaceId, userId)
      }
      default:
        return RolesEnum.Viewer
    }
  })
}
