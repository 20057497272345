import React, { FC } from 'react'
import classnames from 'classnames'

type StepsProgressPropsType = {
  stepsContent: Array<string | number>,
  progress: number,
}

const StepsProgress: FC<StepsProgressPropsType> = ({ stepsContent, progress }) => {
  const numberOfSteps = stepsContent.length

  const renderStep = (content: string | number, index: number) => {
    const isDone = progress >= (index / (numberOfSteps - 1)) * 100
    return (
      <div
        style={{ zIndex: 1 }}
        className={classnames(
          'h-5 w-5 rounded-full flex justify-center items-center text-white text-sm transition delay-300',
          {
            'bg-gray-300': !isDone,
            'bg-primary-dark': isDone,
          },
        )}>
        {content}
      </div>
    )
  }

  return (
    <div className="relative flex justify-between items-center rounded-full bg-gray-200 w-full h-2">
      {stepsContent.map((step, index) => renderStep(step, index))}
      <div
        className="absolute bg-primary right-0 left-0 top-0 bottom-0 rounded-full h-2"
        style={{ width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}></div>
    </div>
  )
}

export default StepsProgress
