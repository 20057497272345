export const defaultWorkspaceFilterValue = [
  { name: 'id', operator: 'startsWith', type: 'string', value: '' },
  { name: 'name', operator: 'startsWith', type: 'string', value: '' },
  { name: 'ownerId', operator: 'eq', type: 'select', value: null },
  { name: 'planId2222', operator: 'eq', type: 'select', value: null },
  { name: 'createdAt', operator: 'startsWith', type: 'string', value: '' },
]

export const workspaceDataSource = [
  {
    created: '1/1/2022',
    id: 1,
    type: 'basic',
    users: 5,
    name: 'test 11',
  },
  {
    created: '1/1/2022',
    id: 2,
    stations: 4,
    type: 'basic',
    users: 20,
    name: 'test 22',
  },
  {
    created: '1/1/2022',
    id: 3,
    stations: 5,
    type: 'pro',
    users: 50,
    name: 'test 55',
  },
  {
    created: '1/1/2022',
    id: 4,
    stations: 7,
    type: 'pro',
    users: 13,
    name: 'test 34',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
  {
    created: '1/1/2022',
    id: 5,
    stations: 1,
    type: 'basic',
    users: 3,
    name: 'test 12',
  },
]

export const defaultUsersFilterValue = [
  { name: 'id', operator: 'startsWith', type: 'string', value: '' },
  { name: 'firstName', operator: 'startsWith', type: 'string', value: '' },
  { name: 'lastName', operator: 'startsWith', type: 'string', value: '' },
  { name: 'email', operator: 'startsWith', type: 'string', value: '' },
  { name: 'type', operator: 'startsWith', type: 'string', value: '' },
]

export const usersDataSource = [
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 1',
    id: 1,
    lastName: 'test 11',
    type: 'admin',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 2',
    id: 2,
    lastName: 'test 22',
    type: 'viewer',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
  {
    email: 'tuytu@yuyu.ciii',
    firstName: 'test 3',
    id: 3,
    lastName: 'test 33',
    type: 'editor',
  },
]
