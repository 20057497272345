import React, { FC } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useTranslation } from 'react-i18next'

interface CustomScrollPropsType {
  children: React.ReactNode;
  maxHeight?: string;
  id?: string;
  ref?: any;
  hideScroll?: boolean;
  classes?: string;
}

const CustomScroll: FC<CustomScrollPropsType> = ({
  children,
  maxHeight = '100vh',
  id = 'scroll',
  ref,
  hideScroll = false,
  classes,
}) => {
  const { i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'

  if (hideScroll) {
    return <div className="overflow-hidden">{children}</div>
  }
  return (
    <SimpleBar
      ref={ref}
      autoHide={true}
      className={classes ? classes : 'w-full overflow-x-hidden'}
      data-simplebar-direction={isRtl ? 'rtl' : 'ltr'}
      id={id}
      style={{ height: maxHeight, maxHeight: maxHeight }}>
      {children}
    </SimpleBar>
  )
}

export default CustomScroll
