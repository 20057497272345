import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DateSelectArg } from '@fullcalendar/react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DropArg } from '@fullcalendar/interaction'
import { add } from 'date-fns'
import uniqBy from 'lodash/uniqBy'

import TimeLineComponent from 'components/TimeLineComponent/TimeLineComponent'
import { getCalendarEvents } from 'utils/calendarUtils'
import TimelineTaskList from 'components/TaskList'
import { useCurrentUser, useUsersByList } from 'features/user'
import { useListRecordOptions, useListRecordOptionsLoading } from 'features/element'
import {
  archiveRecordById,
  fetchRecordsByParams,
  onUpdateCustomFieldLinks,
  updateRecordElementById,
  useCurrentRecordsCustomFields,
  useRecords,
} from 'features/record'

import { getHealthOptions, getPriorityOptions } from 'utils/helpers/recordHelpers'
import {
  fetchLinkedDocumentsByListId,
  useActiveListView,
  useCurrentListDetails,
  useCurrentListStatuses,
  useLinkedDocuments,
} from 'features/list'
import CreateRecordModal from 'components/TimelineEvent/CreateRecordModal'
import {
  documentsResourceRender,
  generateChangesObject,
  healthResourceRender,
  priorityResourceRender,
  statusResourceRender,
  userResourceRender,
} from 'utils/helpers/timeLineHelpers'
import { getTimelineGroupByOptions } from 'utils/constant/constant/common'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import { RecordType } from '../../utils/types'
import Button, { ButtonVariant } from '../../components/Buttons'
import ConfirmationModal from '../../components/ConfirmationModal'
import { useRoles } from '../../hooks'

type DeltaType = {
  years: number,
  days: number,
  months: number,
  milliseconds: number,
}

const Timeline = () => {
  const { listId, viewId }: { listId: string, viewId: string } = useParams()
  const [isTaskListVisible, setIsTaskListVisible] = useState<boolean>(false)
  const [dropdownValue, setDropdownValue] = useState<string>('ownerId')
  const [isCreatRecord, setIsCreatRecord] = useState<boolean>(false)
  const [newRecordData, setNewRecordData] = useState<{ endDate: string, startDate: string, ownerId: string } | null>(
    null,
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const users = useUsersByList()
  const statuses = useCurrentListStatuses()
  const records = useRecords()
  const user = useCurrentUser()
  const isHijri = user.isHijri
  const linkedDocs = useLinkedDocuments()
  const listDetails = useCurrentListDetails()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const allCustomFieldsList = getInheritedCustomFields(currentRecordsCustomFields)
  const listRecordOptions = useListRecordOptions()
  const { isAdmin, isEditor } = useRoles()
  const listRecordOptionsLoading = useListRecordOptionsLoading()
  const currentView = useActiveListView()
  const basicOptions = getTimelineGroupByOptions({ currentView })
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState<any>(null)
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const selectedRecord = records.find(({ id }) => id === +currentItem?.publicId)
  const isArchived = selectedRecord && selectedRecord?.appElements[0]?.isArchived
  const isViewer = !isAdmin && !isEditor

  useEffect(() => {
    dispatch(fetchLinkedDocumentsByListId(+listId))
  }, [dispatch, listId])

  useEffect(() => {
    const handleClickOutSideMenu = () => setOpenMenu(false)
    window.addEventListener('click', handleClickOutSideMenu)
    return () => {
      window.removeEventListener('click', handleClickOutSideMenu)
    }
  }, [])

  const onRightClickItem = (item: RecordType, position: { x: number, y: number }) => {
    setOpenMenu(!openMenu)
    setCurrentItem(item)
    setMenuPosition(position)
  }

  const onArchive = () => {
    const recursive = 0 // 1 to unarchive element and all children force 0 to archive element and children but not
    dispatch(
      archiveRecordById({ elementId: +selectedRecord?.appElements[0]?.id, recursive, status: Number(!isArchived) }),
    )
    setIsArchiveModalOpen(false)
  }

  const customFields = allCustomFieldsList
    ?.filter((i: any) => i.baseType === customFieldBaseTypes.DropDown)
    ?.map((i: any) => ({ id: i.id, label: i.fieldName, list: i.customFieldOptions, value: i.id }))

  const linkFields =
    !listDetails || listRecordOptionsLoading
      ? []
      : allCustomFieldsList
          .filter(
            (field: any) =>
              field.baseType === customFieldBaseTypes.SingleLink && listDetails.appElement?.id == +field.appElementId,
          )
          .map((field: any) => {
            const options =
              listRecordOptions &&
              Object.keys(listRecordOptions).length > 0 &&
              listRecordOptions[field.intermediateAppElementId] &&
              listRecordOptions[field.intermediateAppElementId].length > 0
                ? listRecordOptions[field.intermediateAppElementId].map((record: any, index: number) => {
                    return {
                      customFieldId: field.id,
                      id: record.id,
                      isDefault: index === 0,
                      optionCaption: record.element.elementName,
                      optionOrder: index,
                    }
                  })
                : []
            return {
              id: field.id,
              label: field.fieldName,
              list: options,
              value: field.id,
            }
          })

  const options = [...basicOptions, ...customFields, ...linkFields]
  const timelineRef = useRef<HTMLDivElement>(null)
  const statusList = statuses.map((i) => ({ ...i, label: i.name, order: i.position, value: i.id.toString() }))
  const documentsList = uniqBy(linkedDocs, 'elementId').map((i, idx) => ({
    ...i,
    id: +i.elementId,
    label: i.element.elementName,
    order: idx,
    value: +i.elementId,
  }))
  const isResourceLink = linkFields.findIndex((field: any) => field.id == dropdownValue) !== -1
  const isDocumentsLinks = dropdownValue === 'documents'

  useEffect(() => {
    dispatch(fetchRecordsByParams({ listId, viewId }))
  }, [dispatch, listId, viewId])

  const calendarEvents = useMemo(() => {
    return getCalendarEvents(records, true, isHijri, dropdownValue, isResourceLink, isDocumentsLinks)
  }, [records, dropdownValue, linkedDocs])

  const groupByResources = {
    documents: { content: documentsResourceRender, list: documentsList },
    health: { content: healthResourceRender, list: getHealthOptions() },
    ownerId: { content: userResourceRender, list: users },
    priority: { content: priorityResourceRender, list: getPriorityOptions(false) },
    statusId: { content: statusResourceRender, list: statusList },
  }

  const handleEventClick = ({
    event: {
      _def: { publicId },
    },
  }: {
    event: { _def: { publicId: string } },
  }) => {
    history.push(`${location.pathname}?recordId=${publicId}`)
  }

  // TODO: handle dragging and dropping between single links
  const handleEventDrop = ({
    delta,
    event: {
      _def: { publicId },
    },
    newResource,
  }: {
    delta: DeltaType,
    newResource: any,
    event: { _def: { publicId: string } },
  }) => {
    const initialRecord = records.find(({ id }) => id === +publicId)
    if (initialRecord !== undefined) {
      const { endDate, startDate } = initialRecord
      if (endDate !== undefined && startDate !== undefined) {
        const newEndDate = add(new Date(endDate), delta)
        const newStartDate = add(new Date(startDate), delta)
        if (!isResourceLink) {
          let resourceId = initialRecord[dropdownValue]

          if (newResource !== null) resourceId = newResource._resource.id
          if (dropdownValue === 'priority' && resourceId == -1) resourceId = null

          const recordChangeItem = { [dropdownValue]: resourceId }

          let recordCustomFields = []
          if (!isNaN(dropdownValue)) {
            recordCustomFields = initialRecord?.appElements[0]?.customFieldValues.map((i: any) => {
              const currentItem = { ...i }
              if (+i.customFieldId === +dropdownValue) {
                currentItem.customFieldOptionId = resourceId
              }
              return currentItem
            })
          }
          const fieldsList = {
            appElements: [
              {
                ...initialRecord?.appElements[0],
                customFieldValues: recordCustomFields,
              },
            ],
          }
          const changedKey = Object.keys(recordChangeItem)[0]
          const updatedRecord = { ...initialRecord, ...fieldsList }
          let changes = generateChangesObject(initialRecord, { endDate: newEndDate, startDate: newStartDate })
          if (recordChangeItem[changedKey] !== updatedRecord[changedKey]) {
            changes = { ...recordChangeItem, ...changes }
          }
          dispatch(
            updateRecordElementById({
              changes,
              elementId: updatedRecord?.appElements[0]?.id,
              record: updatedRecord,
              viewId,
            }),
          )
        } else {
          if (newResource) {
            const updateLinkMessage = {
              action: 'add',
              customFieldId: +dropdownValue,
              elementId: +initialRecord.appElements[0].id,
              force: true,
              linkedElementId: newResource._resource.id,
            }

            dispatch(onUpdateCustomFieldLinks({ recordId: +initialRecord.id, updateLinkMessage }))
          }
          if (delta && (delta.years !== 0 || delta.days !== 0 || delta.months !== 0 || delta.milliseconds !== 0))
            dispatch(
              updateRecordElementById({
                changes: generateChangesObject(initialRecord, { endDate: newEndDate, startDate: newStartDate }),
                elementId: initialRecord?.appElements[0]?.id,
                record: initialRecord,
                viewId,
              }),
            )
        }
      }
    }
  }

  const handleEventDropFromOutside = ({ date, resource, draggedEl: { id: recordId } }: DropArg) => {
    const initialRecord = records.find(({ id }) => id === +recordId)
    if (initialRecord !== undefined) {
      dispatch(
        updateRecordElementById({
          changes: { [dropdownValue]: Number(resource?.id), endDate: date, startDate: date },
          elementId: initialRecord?.appElements[0]?.id,
          record: initialRecord,
          viewId,
        }),
      )
    }
  }

  const handleEventResize = ({
    endDelta,
    startDelta,
    event: {
      _def: { publicId },
    },
  }: {
    endDelta: DeltaType,
    startDelta: DeltaType,
    event: {
      _def: { publicId: string },
    },
  }) => {
    const initialRecord = records.find(({ id }) => id === +publicId)
    if (initialRecord !== undefined) {
      const { endDate, startDate } = initialRecord
      if (endDate !== undefined && startDate !== undefined) {
        const newEndDate = add(new Date(endDate), endDelta)
        const newStartDate = add(new Date(startDate), startDelta)
        dispatch(
          updateRecordElementById({
            changes: generateChangesObject(initialRecord, { endDate: newEndDate, startDate: newStartDate }),
            elementId: initialRecord.appElements[0].id,
            record: initialRecord,
            viewId,
          }),
        )
      }
    }
  }

  const onCreateRecord = (event: DateSelectArg) => {
    const { endStr: end, startStr: start } = event
    const endDate = new Date(end)
    const startDate = start ? new Date(start) : undefined
    const endDateInUTC = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1))
    const startDateInUTC = startDate
      ? new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))
      : undefined
    const ownerId = +event.resource?._resource?.id || null
    setNewRecordData({
      endDate: endDateInUTC,
      listId,
      ownerId,
      startDate: startDateInUTC,
    })
    setIsCreatRecord(true)
  }

  return (
    <>
      <div className="flex-1 m-4 overflow-auto">
        <TimeLineComponent
          calendarEvents={calendarEvents}
          customFields={customFields}
          dropdownValue={dropdownValue}
          groupByResources={groupByResources}
          handleEventClick={handleEventClick}
          handleEventDrop={handleEventDrop}
          handleEventDropFromOutside={handleEventDropFromOutside}
          handleEventResize={handleEventResize}
          isTaskListVisible={isTaskListVisible}
          isViewer={isViewer}
          linkFields={linkFields}
          options={options}
          setDropdownValue={setDropdownValue}
          setIsTaskListVisible={setIsTaskListVisible}
          timelineRef={timelineRef}
          toolBarId={'list-view-toolbar'}
          onCreateRecord={onCreateRecord}
          onRightClickItem={onRightClickItem}
        />
      </div>
      {isTaskListVisible && <TimelineTaskList />}
      <CreateRecordModal isOpen={isCreatRecord} recordData={newRecordData} setIsCreatRecord={setIsCreatRecord} />
      {openMenu && (
        <div className="menuCard" style={{ left: menuPosition?.x, top: menuPosition?.y }}>
          <Button
            className="text-primary"
            small
            variant={ButtonVariant.Text}
            onClick={() => {
              const recordUrl = `${location.pathname}${
                location.search
                  ? `${location.search}&recordId=${currentItem.publicId}`
                  : `?recordId=${currentItem.publicId}`
              }`
              history.push(recordUrl)
            }}>
            {t('common:labels.open')}
          </Button>
          <Button
            className="text-danger"
            small
            variant={ButtonVariant.Text}
            onClick={() => setIsArchiveModalOpen(true)}>
            {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
          </Button>
        </div>
      )}
      <ConfirmationModal
        confirmMessage={isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
        confirmationMessage={t(isArchived ? 'records:confirmRecordUnarchive' : 'records:confirmRecordArchive', {
          interpolation: { escapeValue: false },
          name: currentItem?.title,
        })}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={onArchive}
      />
    </>
  )
}

export default Timeline
