export enum ActivityQueueStatuses {
  /**Activity has no status yet */
  None,

  /**Activity is queued */
  Queued,

  /**Activity is completed with success */
  Succes,

  /**Activity has partially succeed (i.e. some users have not been notified) */
  PartialSuccess,

  /**Failed to notify all activity members*/
  Failed,
}

export enum ActivityActions {
  /**Create activity */
  Create,
  /**Update activity */
  Update,
  /**Delete activity */
  Delete,
  /**Reset activity */
  Reset,
  /**Object listing activity */
  List,
  /**Object member update */
  MemberUpdated,
  /**Object inner role updated */
  RoleUpdated,
  /**Verify email */
  VerifyEmail,
  /**Invite to workspace */
  InviteToWorkspace,
  /**Invite to station */
  InviteToStation,
  /**Invite to list */
  InviteToList,
  /**Change avatar*/
  ChangeAvatar,
  /**Change password */
  ChangePassword,
  /**Invitation removed */
  InvitationRemoved,
  /**Change password requested */
  ChangePasswordRequested,
  /**Duplicate */
  Duplicate,
  /**Archive */
  Archive,
  /**Un archive */
  UnArchive,
  /**Recycle */
  Recycle,
  /**Un recycle */
  UnRecycle,
  /**Destructed */
  Destruct,
  /**Renewal soon notification */
  RenewalSoonNotification,
  /**Subscription invoice issued */
  SubscriptionInvoiceIssued,
  /**Invoice payment succeed */
  InvoicePaymentSucceed,
  /**Invoice payment failed */
  InvoicePaymentFailed,

  /**Invoice payment max retries reached */
  InvoicePaymentMaxRetriesReached,
  PasswordExpiryNotification,
  ChangeRequestCreated,
  ChangeRequestApproved,
  ChangeRequestRejected,
}
