import React, { FC, Ref, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway, useToggle } from 'react-use'
import classnames from 'classnames'
import TetherComponent from 'react-tether'
import throttle from 'lodash/throttle'

import { CalendarAgendaIcon, CalendarMonthIcon, CalendarWeekIcon, DownIcon, YearViewIcon } from 'components/Icons'
import ViewTypeOption from 'components/CalendarToolbar/ViewTypeDropdown/ViewTypeOption'

type ViewTypeDropdownType = {
  isTimelineView: boolean,
  isCalendarView: boolean,
  currentViewType: 'week' | 'month' | 'year',
  setView: (view: any) => void,
}

const ViewTypeDropdown: FC<ViewTypeDropdownType> = ({ isTimelineView, isCalendarView, currentViewType, setView }) => {
  const [isDropdownVisible, toggleDropdown] = useToggle(false)
  const { i18n } = useTranslation()
  const menuWrapperRef = useRef<any>()
  const options = useMemo(() => {
    const options = [
      {
        icon: CalendarWeekIcon,
        label: 'labels.week',
        value: 'week',
        view: isTimelineView ? 'resourceTimelineWeek' : 'dayGridWeek',
      },
      {
        icon: CalendarMonthIcon,
        label: 'labels.month',
        value: 'month',
        view: isTimelineView ? 'resourceTimelineMonth' : 'dayGridMonth',
      },
    ]

    const timelineOptions = [
      {
        icon: CalendarWeekIcon,
        label: 'labels.quarter',
        value: 'quarter',
        view: 'resourceTimelineYear',
      },
      {
        icon: YearViewIcon,
        label: 'labels.year',
        value: 'year',
        view: 'resourceTimelineYear',
      },
    ]

    switch (true) {
      case isTimelineView:
        return [...options, ...timelineOptions]
      default:
        return options
    }
  }, [isTimelineView])

  const onViewChange = useCallback(
    (value) => {
      setView(options.find(({ value: optionValue }) => optionValue === value))
    },
    [options],
  )

  const throttledToggleDropdown = useCallback(
    throttle(
      (value?: boolean) => {
        toggleDropdown(value)
      },
      200,
      { trailing: false },
    ),
    [toggleDropdown],
  )

  const closeDropdown = () => throttledToggleDropdown(false)

  useClickAway(menuWrapperRef, closeDropdown)
  const attachmentSide = useMemo(() => (i18n.dir() === 'ltr' ? 'right' : 'left'), [i18n])

  const currentValue = useMemo(() => options.find(({ value }) => value === currentViewType), [currentViewType, options])

  return (
    <TetherComponent
      attachment={`top ${attachmentSide}`}
      constraints={[
        {
          attachment: 'together',
          pin: true,
          to: 'scrollParent',
        },
      ]}
      renderElement={(ref: any) =>
        isDropdownVisible && (
          <div
            className={classnames('z-30 text-sm bg-white cursor-default shadow-multi w-44 rounded-2xl px-2')}
            ref={(divRef: any) => {
              ref.current = divRef
              menuWrapperRef.current = divRef
            }}>
            {options.map((option) => (
              <ViewTypeOption
                key={option.value}
                data={option}
                onClick={() => {
                  onViewChange(option.value)
                  closeDropdown()
                }}
              />
            ))}
          </div>
        )
      }
      renderTarget={(ref: Ref<any>) => (
        <div
          className="flex items-center px-2 transition-shadow hover:text-primary focus:outline-none focus-visible:shadow-focus"
          ref={ref}
          onClick={() => throttledToggleDropdown()}>
          <ViewTypeOption
            className="flex items-center px-2 transition-shadow hover:text-primary focus:outline-none focus-visible:shadow-focus"
            data={currentValue}
            onClick={() => throttledToggleDropdown()}
          />
          <DownIcon className="w-3 cursor-pointer stroke-3" />
        </div>
      )}
      targetAttachment={`bottom ${attachmentSide}`}
    />
  )
}

export default ViewTypeDropdown
