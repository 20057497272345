import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from 'components/Buttons'
import Loader from '../../../Loader'
import {
  createElementLink,
  getElementLinkedRecords,
  getElementTree,
  useCurrentElementTree,
  useCurrentElementTreeLoading,
} from '../../../../features/element'
import { useAppDispatch } from '../../../../store'
import { clearElementTree } from '../../../../features/element/elementSlice'
import LinkElements from '../../../LinkElements'
import { RecordType } from '../../../../utils/types'

interface CustomFieldFormProps {
  onClose: (e: any) => void;
  parentElementId: number | string;
  onSubmit: (value: any[]) => void;
  elementBaseType: number;
  level: number;
  lowestLevel: number;
  options?: any;
  currentRecord?: RecordType;
  title?: string;
  confirmationTitle?: string;
  emptyPreviewMessage?: string;
  onRemoveParent?: () => void;
}

const AddLinkForm: FC<CustomFieldFormProps> = ({
  onClose,
  parentElementId,
  onSubmit,
  elementBaseType,
  level,
  lowestLevel,
  currentRecord,
  options,
  title,
  confirmationTitle,
  emptyPreviewMessage,
  onRemoveParent,
}) => {
  const { t } = useTranslation('documentation')
  const [selectedElements, setSelectedElements] = useState<any[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const recordsElements = selectedElements.filter((element) => element.basedType === 3)
  const dispatch = useAppDispatch()
  const currentList = useCurrentElementTree()
  const currentListLoading = useCurrentElementTreeLoading()

  useEffect(() => {
    dispatch(getElementTree({ elementId: +parentElementId }))
    return () => {
      dispatch(clearElementTree())
    }
  }, [])

  const handleSave = () => {
    setIsSubmitting(true)
    onSubmit(recordsElements)
    setIsSubmitting(false)
  }

  const linkRecordsProps = {
    currentList,
    currentListLoading,
    currentRecord,
    elementBaseType,
    elementId: parentElementId,
    emptyPreviewMessage,
    level,
    lowestLevel,
    options,
    selectedElements,
    setSelectedElements,
  }

  return (
    <div className="flex flex-col flex-1 w-full min-h-0 px-5 py-5 justify between">
      <div className="flex flex-col flex-1 min-h-0">
        <h2 className="pb-3 mb-3 font-bold border-b border-gray-200">{title || t('addLinkTitle')}</h2>
        <LinkElements {...linkRecordsProps} />
      </div>
      <div className="flex items-center justify-end mt-5">
        <Button className="me-1" small variant={ButtonVariant.Outline} onClick={onClose}>
          {t('common:labels.cancel')}
        </Button>
        {onRemoveParent && (
          <Button className="me-1" small variant={ButtonVariant.Outline} onClick={onRemoveParent}>
            {t('common:labels.removeParent')}
          </Button>
        )}
        <Button
          data-testid="save-button"
          disabled={recordsElements.length === 0}
          small
          type="submit"
          onClick={handleSave}>
          {isSubmitting ? <Loader /> : confirmationTitle || t('linkButton')}
        </Button>
      </div>
    </div>
  )
}

export default AddLinkForm
