import React, { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { getPriorityIconByValue } from 'utils/helpers/recordHelpers'
import { RecordType } from 'utils/types'

const PriorityCell: FC<CellRenderObject & { data: RecordType, value: RecordType['priority'] }> = ({ value }) => {
  if (value === null) return <div className="cursor-pointer" />
  const { Icon, color } = getPriorityIconByValue(value === null ? null : +value)
  return <Icon className="w-4 h-4 mx-auto cursor-pointer" style={{ color }} />
}

export default PriorityCell
