import { get, groupBy, uniq } from 'lodash'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

import { RecordType, RecordTypePropsKeys, UserType } from 'utils/types'
import StateType from 'utils/types/states'
import { groupByColumnId } from 'utils/helpers/recordHelpers'
import { CustomFieldsListMessage } from 'utils/types/CustomFieldsType'

const selectRecords = (state: StateType) => state.record.records || []
const selectRecordLoading = (state: StateType) => state.record.loading
const selectCurrentRecordId = (state: StateType) => state.record.currentRecordId
const selectCurrentSortColumn = (state: StateType) => state.record.currentSortColumn
const selectCurrentSortDirection = (state: StateType) => state.record.currentSortDirection
const selectSelectedFilters = (state: StateType) => state.record.selectedFilters
const selectRecordRejected = (state: StateType) => state.record.isRejected
const selectRecordsChildren = (state: StateType) => state.record.recordsChildren
const selectListStatusesGroups = (state: StateType) => state.record.listStatusesGroups
const selectCurrentRecordDetails = (state: StateType) => state.record.currentRecordDetails
const selectCurrentRecordDetailsLoading = (state: StateType) => state.record.currentRecordDetailsLoading
const selectCurrentRecordsCustomFields = (state: StateType): CustomFieldsListMessage | Record<string, never> =>
  state.record.currentRecordsCustomFields
const selectCurrentCustomGridColumns = (state: StateType) => state.record.currentCustomGridColumns

const selectCurrentRecord = createSelector([selectRecords, selectCurrentRecordId], (records, currentRecordId) =>
  records.find(({ id }) => id === currentRecordId),
)

const selectCurrentRecordReviewers = createSelector(
  [selectRecords, selectCurrentRecordId],
  (records, currentRecordId) => records.find(({ id }) => id === currentRecordId)?.reviewers,
)

const selectCurrentSortAttributes = createSelector(
  [selectCurrentSortColumn, selectCurrentSortDirection],
  (currentSortColumn, currentSortDirection) => ({ currentSortColumn, currentSortDirection }),
)
const selectRecordColumnNames = createSelector(
  [selectRecords, (_, fieldToSortBy: RecordTypePropsKeys) => fieldToSortBy],
  (records, fieldToSortBy) => uniq(records.map((singleRecord) => singleRecord[fieldToSortBy])),
)

const selectGroupedRecords = createSelector(
  [
    selectRecords,
    (_, args: { fieldToSortBy: RecordTypePropsKeys, groupName: string | null, baseType?: number, customId?: number }) =>
      args,
  ],
  (records, { fieldToSortBy, groupName, baseType, customId }) => {
    const groupedRecords = groupByColumnId(records, fieldToSortBy, groupName, baseType, customId)
    return get(groupedRecords, groupName || 'null')
  },
)

export const useRecordsSingleGroup = (
  fieldToSortBy: string,
  groupName: string | null,
  baseType?: number,
  customId?: number,
): RecordType[] =>
  useSelector((state: StateType) => selectGroupedRecords(state, { baseType, customId, fieldToSortBy, groupName }))

export const useRecordFetching: () => boolean = () =>
  useSelector((state: StateType) => state.record.isFetching || false)

const selectTimelineUnassignedRecords = createSelector([selectRecords], (records) =>
  records.filter(({ endDate, startDate }) => !endDate || !startDate),
)

const selectCalendarUnassignedRecords = createSelector([selectRecords], (records) =>
  records.filter(({ endDate }) => !endDate),
)

export const useRecords: () => RecordType[] = () => useSelector(selectRecords)
export const useRecordLoading: () => boolean = () => useSelector(selectRecordLoading)
export const useCurrentRecord: () => RecordType | undefined = () => useSelector(selectCurrentRecord)
export const useCurrentRecordReviewers: () => UserType[] = () => useSelector(selectCurrentRecordReviewers)
export const useCurrentSortAttributes = (): { currentSortDirection: string, currentSortColumn: string } =>
  useSelector(selectCurrentSortAttributes)
export const useFilters = (): string[][] => useSelector(selectSelectedFilters)

export const useRecordColumnNames = (fieldToSortBy: RecordTypePropsKeys): unknown[] =>
  useSelector((state: StateType) => selectRecordColumnNames(state, fieldToSortBy))

export const useIsRecordRejected = () => useSelector(selectRecordRejected)

export const useTimelineUnassignedRecords = (): RecordType[] => useSelector(selectTimelineUnassignedRecords)
export const useCalendarUnassignedRecords = (): RecordType[] => useSelector(selectCalendarUnassignedRecords)
export const useRecordsChildren = (): any => useSelector(selectRecordsChildren)
export const useListStatusesGroups = (): any => useSelector(selectListStatusesGroups)
export const useCurrentRecordDetails = (): RecordType => useSelector(selectCurrentRecordDetails)
export const useCurrentRecordDetailsLoading = (): boolean => useSelector(selectCurrentRecordDetailsLoading)
export const useCurrentRecordsCustomFields = (): CustomFieldsListMessage | Record<string, never> =>
  useSelector(selectCurrentRecordsCustomFields)
export const useCurrentCustomGridColumns = (): any => useSelector(selectCurrentCustomGridColumns)
