import React, { FC, useMemo, memo, useState, useEffect } from 'react'
import { Layout, Responsive } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { useAppDispatch } from 'store'
import { DashboardStateWidgetType } from 'utils/types/states'
import DashboardAvailableWidgets from 'utils/types/states/DashboardAvailableWidgets'
import DashboardStateType from 'utils/types/states/DashboardStateType'
import { widgetsMobileHeight } from 'utils/helpers/dashboardHelpers'
import { DashboardParameterType } from 'utils/types/states/DashboardGetWidgetDataType'

import { clearDashboardWidgets } from 'features/element/elementSlice'

import DashboardBaseWidget from './Widgets/DashboardBaseWidget'

const sortWidgetsByXandY = (widgets: DashboardStateWidgetType[] = [], isRtl: boolean) => {
  return [...widgets].sort((a, b) => {
    const xA = a.dimensions.x
    const xB = b.dimensions.x
    const yA = a.dimensions.y
    const yB = b.dimensions.y

    if (yA == yB) {
      if (isRtl) {
        return xA < xB ? 1 : xA > xB ? -1 : 0
      }
      return xA < xB ? -1 : xA > xB ? 1 : 0
    } else {
      return yA < yB ? -1 : 1
    }
  })
}

const DashboardPage: FC<{
  dashboardData: DashboardStateType,
  isEdit: boolean,
  closeEdit: () => void,
  removeWidget: (id: number) => void,
  saveLayoutResizeChanges: (layout: Layout[], oldItem: Layout, newItem: Layout, ...rest: any) => void,
  saveLayoutDragChanges: (layout: Layout[], oldItem: Layout, newItem: Layout, ...rest: any) => void,
  addWidget: (widget: DashboardAvailableWidgets, parameters: DashboardParameterType[]) => void,
  dashboardWidth: number,
  elementId: string | number,
  onUpdateWidgetImage: (value) => void,
  updateTextWidget: (widgetId: number, description: string, imgBase64?: string) => void,
  updateWidgetTitle: (widgetId: number, title: string) => void,
  isPrinting: boolean,
}> = ({
  dashboardData,
  isEdit,
  removeWidget,
  dashboardWidth,
  saveLayoutResizeChanges,
  saveLayoutDragChanges,
  updateTextWidget,
  updateWidgetTitle,
  elementId,
  onUpdateWidgetImage,
  isPrinting,
}) => {
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false)
  const { i18n, t } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearDashboardWidgets())
    }
  }, [])

  const layouts = useMemo(() => {
    return sortWidgetsByXandY(dashboardData?.widgets, i18n.language === 'ar').reduce(
      (prev: { lg: any[], md: any[], sm: any[] }, curr, i) => {
        const { dimensions, baseType, id, tempId } = curr
        const editProperties = isEdit
          ? {
              isDraggable: (isEditorOpen && baseType === 1) || dashboardWidth < 980 ? false : true,
              isResizable: dashboardWidth < 980 ? false : true,
            }
          : {
              isDraggable: false,
              isResizable: false,
            }
        const minH = dimensions.minH === -1 ? undefined : dimensions.minH
        const itemKey = `${id || tempId}`
        return {
          lg: [...prev.lg, { ...dimensions, i: itemKey, ...editProperties, minH }],
          md: [...prev.md, { h: 3, i: itemKey, w: 12, x: dimensions.x, y: i * 2, ...editProperties, minH }],
          sm: [
            ...prev.sm,
            {
              h: widgetsMobileHeight[baseType],
              i: itemKey,
              w: 12,
              x: dimensions.x,
              y: i,
              ...editProperties,
              minH,
            },
          ],
          xs: [
            ...prev.sm,
            {
              h: widgetsMobileHeight[baseType],
              i: itemKey,
              w: 12,
              x: dimensions.x,
              y: i,
              ...editProperties,
              minH,
            },
          ],
        }
      },
      { lg: [], md: [], sm: [] },
    )
  }, [dashboardData?.widgets, isEdit, dashboardWidth, isEditorOpen, isRtl])

  return dashboardData?.widgets?.length === 0 ? (
    <div className="flex items-center justify-center w-full mt-20 opacity-70">{t('dashboard:noAnyWidgetsAdded')}</div>
  ) : (
    <Responsive
      breakpoints={{
        lg: 1000,
        md: 900,
        sm: 668,
        xs: 480,
        xxs: 0,
      }}
      className="layout"
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      layouts={layouts}
      margin={[10, 10]}
      style={{
        direction: 'ltr',
      }}
      width={dashboardWidth ? dashboardWidth : 1440}
      onDragStop={saveLayoutDragChanges}
      onResizeStop={saveLayoutResizeChanges}>
      {dashboardData?.widgets?.map((item, idx) => {
        // Remove temp variables when backend will send real data
        const tempItem: any = item
        const TemporaryChange = { ...item, dimensions: item.dimensions ? item.dimensions : tempItem.dimentions }
        const itemKey = `${item?.id || item?.tempId}`
        return (
          <div key={itemKey} className={classnames({ 'border-1 border-gray-400 rounded overflow-hidden': isPrinting })}>
            <DashboardBaseWidget
              widget={item}
              elementId={elementId}
              isEdit={isEdit}
              removeWidget={removeWidget}
              setIsEditorOpen={setIsEditorOpen}
              updateTextWidget={updateTextWidget}
              updateWidgetTitle={updateWidgetTitle}
              onUpdateWidgetImage={onUpdateWidgetImage}
              isPrinting={isPrinting}
            />
          </div>
        )
      })}
    </Responsive>
  )
}
export default memo(DashboardPage)
