import React, { FC, useMemo } from 'react'
import { format } from 'date-fns'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { RecordType } from 'utils/types'
import { getPriorityIconByValue } from 'utils/helpers/recordHelpers'
import { DATE_FORMAT } from 'utils/helpers/generalHelpers'

import { CalendarCardIcon } from 'components/Icons'
import { healthColors } from 'components/GridView/cells/displayCells/HealthCell'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useActiveListView } from 'features/list'
import { RecordAppElementAllAttributesConst } from 'utils/types/RecordAppElementAllAttributes'
import { getFieldVisibility } from 'utils/helpers/customFields'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'
import { hijriInputFormat } from '../../utils/calendarUtils'
import { useCurrentUser } from '../../features/user'
import LabelsBarList from './LabelsBarList'

interface KanbanColumnItemPropType extends RecordType {
  onClick?: () => void;
  onRecordClick?: () => void;
  isParentInTheSameCol: boolean | undefined;
  hasSisters: boolean | undefined;
  order: number | undefined;
  formatSubtasks: boolean;
  onRightClickItem: (item: RecordType, position: { x: number, y: number }) => void;
}

const KanbanColumnItem: FC<KanbanColumnItemPropType> = (props) => {
  const {
    name,
    completedIn,
    priority,
    owner,
    endDate,
    id,
    onClick,
    health,
    labelsIds,
    onRecordClick,
    listId,
    appElements,
    isParentInTheSameCol,
    hasSisters = false,
    order,
    formatSubtasks = false,
    onRightClickItem,
  } = props
  const location = useLocation()
  const history = useHistory()
  const user = useCurrentUser()
  const isHijri = user.isHijri
  const { i18n } = useTranslation()
  const { workspaceId, stationId, viewId }: { workspaceId: string, stationId: string, viewId: string } = useParams()
  const viewInUrl = viewId ? `/${viewId}` : ''
  const currentView = useActiveListView()
  const { Icon: PriorityIcon, color } = getPriorityIconByValue(priority)
  const isSubTask = appElements && +appElements[0]?.elementLevel > 4
  const parent = appElements && appElements[0]?.parent
  const onItemClick = () => {
    if (onRecordClick) {
      onRecordClick()
    } else {
      onClick?.()
      const recordUrl = `${location.pathname}${
        location.search ? `${location.search}&recordId=${id}` : `?recordId=${id}`
      }`
      history.push(recordUrl)
    }
  }

  const attributeVisibilityMap: { [key: string]: boolean } | null = useMemo(() => {
    if (!currentView) return null
    const { defaultAttributeVisibility, appElementViewAttributes: viewAttributes } = currentView
    return {
      [RecordAppElementAllAttributesConst.completedIn]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.completedIn,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
      [RecordAppElementAllAttributesConst.labelsIds]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.labelsIds,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
      [RecordAppElementAllAttributesConst.priority]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.priority,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
      [RecordAppElementAllAttributesConst.endDate]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.endDate,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
      [RecordAppElementAllAttributesConst.ownerId]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.ownerId,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
      [RecordAppElementAllAttributesConst.health]:
        getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.health,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility,
          viewAttributes,
        }) !== AppElementAttributeVisibility.Hidden,
    }
  }, [currentView])

  const UserAvatarStatusProps = {
    customSize: 7,
    imgSrc: owner?.link,
    userId: owner?.id,
  }
  const displayParentName = !isParentInTheSameCol ? (hasSisters ? order === 1 : true) : false
  const percentage =
    !attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.completedIn]
      ? formatSubtasks && isSubTask
        ? `calc(${completedIn}% - 16px)`
        : `${completedIn}%`
      : 0

  return (
    <div
      className="relative flex mb-2 bg-gray-200 pb-0.5 shadow-sm transition transition-shadow hover:shadow group hover:bg-gray-300 z-1 overflow-hidden"
      onClick={onItemClick}
      onContextMenu={(e) => {
        e.preventDefault()
        onRightClickItem(props, { x: e.pageX, y: e.pageY })
      }}>
      <div className="z-10 w-full">
        {formatSubtasks && isSubTask && displayParentName && (
          <div
            className="z-20 px-2 text-xs text-gray-600 truncate bg-transparent bg-gray-200 cursor-pointer text-start hover:text-primary hover:underline"
            style={{ height: '18px' }}
            title={parent?.elementName}
            onClick={(e) => {
              const recordUrl = `/workspace/${workspaceId}/stations/${stationId}/lists/${listId}${viewInUrl}?recordId=${parent?.recordId}`
              e.stopPropagation()
              history.push(recordUrl)
            }}>
            {parent?.elementName}
          </div>
        )}
        <div
          className={classNames(`z-10 flex flex-col p-2 bg-white rounded-b-sm`, {
            'mx-2': formatSubtasks && isSubTask,
          })}>
          <LabelsBarList
            labelsIds={
              !attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.labelsIds]
                ? labelsIds
                : []
            }
          />
          <div title={name}>
            <p className="text-base text-gray-800 leading-5">{name}</p>
          </div>
          <div className="flex justify-between mt-2">
            <div className="flex align-middle">
              {(!attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.priority]) &&
                priority !== null && <PriorityIcon className="w-4 h-4 me-4" style={{ color }} />}
              {(!attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.endDate]) &&
                endDate && (
                  <div className="flex text-xs align-middle me-4">
                    <CalendarCardIcon className="w-3 h-4 me-2" />
                    <span dir="ltr">
                      {isHijri ? hijriInputFormat(endDate, i18n.language) : format(new Date(endDate), DATE_FORMAT)}
                    </span>
                  </div>
                )}
              {/*{isRecordWatch && <WatchIcon className="w-4 -mt-1" />}*/}
            </div>
            <div className="flex items-center justify-center">
              <UserAvatarStatus {...UserAvatarStatusProps} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(`absolute bottom-0 h-4 rounded-sm transform group-hover:translate-y-0.5 transition`, {
          'ms-2': formatSubtasks && isSubTask,
        })}
        style={{
          backgroundColor:
            healthColors[
              !attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.health]
                ? health
                : 'unavailable'
            ],
          width: percentage,
        }}
      />
      {completedIn > 0 && (
        <p
          className={`absolute bottom-0 z-10 flex py-1 text-xs rounded-sm opacity-0 pointer-events-none ms-1 group-hover:opacity-100 px-1.5 leading-3 transform group-hover:translate-y-0.5 transition ${
            !attributeVisibilityMap || attributeVisibilityMap[RecordAppElementAllAttributesConst.health]
              ? health === 'unavailable'
                ? 'text-gray-600'
                : 'text-white'
              : 'unavailable'
          }`}
          style={
            completedIn < 20
              ? { backgroundColor: healthColors[health], insetInlineStart: `${completedIn}%` }
              : { backgroundColor: healthColors[health], insetInlineEnd: `${100 - completedIn}%` }
          }>
          {completedIn}
          <span>%</span>
        </p>
      )}
    </div>
  )
}

export default KanbanColumnItem
