import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { fetchUser, useCurrentUser, useCurrentUserLoading } from 'features/user'
import { fetchWorkspaces } from 'features/workspace'
import { resetRejectedWorkspace } from 'features/workspace/workspaceSlice'
import { resetRejectedStation } from 'features/station/stationSlice'
import { useIsRejected } from 'features/common/selectors'
import { resetRejectedList } from 'features/list/listSlice'

import { ButtonVariant, LinkButton } from 'components/Buttons'
import { ChevronLeftIcon, EmptyErrorIcon } from 'components/Icons'
import EmptyStatePage from 'components/EmptyStatePage'
import InfoModal from 'components/InfoModal'

import Loader from 'components/Loader'
import { useNavbar } from 'contexts/NavbarContext'
import Panel, { SecondaryPanel } from '../Modal'
import CustomScroll from '../CustomScroll'
import { getNotificationsList } from '../../features/element'
import AdminNavbar from '../Admin/AdminLayout/AdminNavbar'
import AdminHeader from '../Admin/AdminLayout/AdminHeader'
import AdminRouter from '../Admin/AdminRouters/AdminRouter'
import Hero from './Hero'
import Navbar from './Navbar'
import Router from './Router'

const Layout: FC = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const user = useCurrentUser()
  const { t } = useTranslation('noAccess')
  const isRejected = useIsRejected()
  const isLoadingUser = useCurrentUserLoading()
  const pagePathName = pathname.split('/')
  const isAdminPage = pagePathName[1] && pagePathName[1] === 'admin'
  const isHomePage = pathname === '/' || pathname === '/dashboard'
  const [currentItem, setCurrentItem] = useState({ name: t('admin:users') })
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false)
  const { isMenuVisible, toggleMenu } = useNavbar()

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(getNotificationsList())
    dispatch(fetchWorkspaces())
  }, [dispatch])

  useEffect(() => {
    if (user && 'verified' in user) {
      setIsInfoModalOpen(!user.verified)
    }
  }, [user, user.verified])

  const onChangePage = (name: string) => {
    setCurrentItem({ name: t(`admin:${name}`) })
  }

  const NoAccessButton = () => (
    <LinkButton to="/" variant={ButtonVariant.Outline}>
      <ChevronLeftIcon className="w-6 rtl-mirror" />
      {t(`button`)}
    </LinkButton>
  )

  useEffect(() => {
    dispatch(resetRejectedWorkspace())
    dispatch(resetRejectedStation())
    dispatch(resetRejectedList())
  }, [pathname, dispatch])

  const renderAdminContent = () => {
    return (
      <div className={classnames('w-full h-screen grid lg:grid lg:grid-cols-layout lg:grid-rows-layout')}>
        <AdminNavbar isSideNavOpen={isMenuVisible} setSideNavOpen={toggleMenu} onChangePage={onChangePage} />
        <Panel />
        <AdminHeader
          parent={pagePathName[1]}
          subTitle={pagePathName.length > 3 ? pagePathName[3] : null}
          title={currentItem?.name}
        />
        <main className={classnames('flex flex-col flex-1 row-span-2 overflow-auto')}>
          {isLoadingUser ? (
            <Loader />
          ) : isRejected ? (
            <EmptyStatePage
              Button={NoAccessButton}
              Icon={EmptyErrorIcon}
              description={t(`description`)}
              header={t(`header`)}
            />
          ) : (
            <AdminRouter />
          )}
        </main>
      </div>
    )
  }

  const renderPageContent = () => {
    return (
      <div
        className={classnames('flex w-full h-screen', {
          'flex-col overflow-y-auto': isHomePage,
        })}>
        {isHomePage && user && isInfoModalOpen && (
          <InfoModal
            confirmationDescription={t('common:unverified:description')}
            confirmationMessage={t('common:unverified:message')}
            isModalOpen={isInfoModalOpen}
            onConfirm={() => setIsInfoModalOpen(false)}
          />
        )}
        {isHomePage && <Hero user={user} />}
        {!isHomePage && <Navbar isSideNavOpen={isMenuVisible} setSideNavOpen={toggleMenu} />}
        <Panel />
        <SecondaryPanel />
        <main
          className={classnames('flex relative flex-col flex-1 p-6 md:p-10', {
            'overflow-auto': !isHomePage,
          })}>
          {isLoadingUser ? (
            <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />
          ) : isRejected ? (
            <EmptyStatePage
              Button={NoAccessButton}
              Icon={EmptyErrorIcon}
              description={t('description')}
              header={t(`header`)}
            />
          ) : (
            <Router />
          )}
        </main>
      </div>
    )
  }

  return <>{isAdminPage ? renderAdminContent() : renderPageContent()}</>
}

export default Layout
