import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'
import DOMPurify from 'dompurify'

import { ArrowDownIcon, ArrowRightIcon } from 'components/Icons'
import { UsersTablePropsType } from 'utils/types'
import ActivityLogType from 'utils/types/ActivityLogType'
import { getFormattedActivityPath } from 'utils/helpers/generalHelpers'
import { getDateFormatInfo } from '../../../utils/constant/constant/common'
import { customFieldBaseTypes } from '../../../utils/constant/enums/customFields'

interface ActivityUpdated {
  activityUser: UsersTablePropsType | undefined;
  activity: ActivityLogType;
  isHijri?: boolean;
  isNotification?: boolean;
  language?: string;
  setSelectedRecordId?: (value: any) => void;
}

const Update: FC<ActivityUpdated> = ({
  activityUser,
  activity,
  isHijri,
  language,
  isNotification = false,
  setSelectedRecordId,
}) => {
  const { t } = useTranslation('records')
  const changeObject: { key: string, previous: string, current: string } = activity.objectData[0]
  const customFieldKey = changeObject?.key.includes('CF') ? changeObject?.key?.split(':') : null
  const customFieldName = customFieldKey ? customFieldKey[3]?.slice(1, -1) : ''
  const customFieldBaseType: customFieldBaseTypes | null = customFieldKey ? +customFieldKey[4] : null
  const isDate =
    changeObject?.key === 'startDate' ||
    changeObject?.key === 'endDate' ||
    customFieldBaseType === customFieldBaseTypes.Date

  const shouldDisplayRecordName =
    isNotification || (activity && activity.stationName && activity.listName && activity.recordName)

  const userName =
    activity?.user !== undefined
      ? `${activity?.user?.firstName} ${activity?.user?.lastName}`
      : `${activity?.firstName} ${activity?.lastName}` || activityUser?.name

  const formatedData =
    changeObject?.current && isDate && isValid(new Date(changeObject.current))
      ? getDateFormatInfo(changeObject.current, isHijri, language)
      : changeObject.current

  const formatedPreviousData =
    changeObject?.previous && isDate && isValid(new Date(changeObject.previous))
      ? getDateFormatInfo(changeObject.previous, isHijri, language)
      : changeObject.previous

  return (
    <>
      <div className="flex flex-wrap items-baseline mb-1 text-xs sm:tracking-normal sm:mt-1 sm:text-sm">
        <label className="font-bold me-1">{userName}</label>
        <span>
          {t('recordChanged', {
            key: customFieldKey ? customFieldName : t(`listing.${changeObject.key}`),
          })}
        </span>
      </div>
      {shouldDisplayRecordName &&
        (isNotification ? (
          <div className="mb-4">
            <span className="text-sm me-1">{t('in')}</span>
            <div
              className="mt-1 cursor-pointer text-primary"
              // href={`/workspace/${activity?.workspaceId}/stations/${activity?.stationId}/lists/${activity?.listId}?recordId=${activity?.record?.id}`}
              // rel="noreferrer"
              // target="_blank"
              onClick={() => setSelectedRecordId(activity?.record?.id)}>
              {activity?.record?.name}
            </div>
            {getFormattedActivityPath(activity?.workspace?.name, activity?.station?.name, activity?.list?.name)}
          </div>
        ) : (
          <div
            className="mb-4 text-xs cursor-pointer sm:tracking-normal sm:mt-1 sm:text-sm"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>
            {t('in')} <span className="text-primary">{activity.recordName}</span>
            {getFormattedActivityPath(null, activity.stationName, activity.listName)}
          </div>
        ))}

      <div className="my-1 text-sm sm:flex hyphenate sm:text-base" style={{ width: '130%' }}>
        {changeObject.previous !== null && changeObject.previous !== undefined && changeObject.previous !== '' ? (
          changeObject.key === 'description' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`${formatedPreviousData}`),
              }}
            />
          ) : (
            <div>{t(formatedPreviousData)}</div>
          )
        ) : (
          <span className="text-gray-400 break-normal">{t('emptyState')}</span>
        )}
        <ArrowRightIcon className="flex-none hidden w-4 h-4 mx-2 my-1 text-gray-400 rtl-mirror sm:block" />
        <ArrowDownIcon className="flex-none w-4 h-4 my-1 text-gray-400 sm:hidden" />
        {changeObject.current !== null && changeObject.current !== undefined && changeObject.current !== '' ? (
          changeObject.key === 'description' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(formatedData),
              }}
            />
          ) : (
            <div>{t(formatedData)}</div>
          )
        ) : (
          <span className="text-gray-400 break-normal">{t('emptyState')}</span>
        )}
      </div>
    </>
  )
}
export default Update
