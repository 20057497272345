import auth from './auth.json'
import common from './common.json'
import lists from './lists.json'
import navbarRoutes from './navbarRoutes.json'
import noAccess from './noAccess.json'
import notifications from './notifications.json'
import records from './records.json'
import station from './station.json'
import user from './user.json'
import validation from './validation.json'
import workspace from './workspace.json'
import tour from './tour.json'
import dashboard from './dashboard.json'
import documentation from './documentation.json'
import customFields from './customFields.json'
import admin from './admin.json'
import element from './element.json'

export default {
  ...auth,
  ...common,
  ...lists,
  ...navbarRoutes,
  ...notifications,
  ...noAccess,
  ...records,
  ...station,
  ...user,
  ...validation,
  ...workspace,
  ...tour,
  ...dashboard,
  ...documentation,
  ...customFields,
  ...admin,
  ...element,
}
