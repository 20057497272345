import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityLogType } from 'utils/types'
import Link from 'components/Link'
import { fetchActivityForResource, useActivities } from 'features/activity'
import { AppElementView } from 'utils/types/AppElementViewType'
import Loader from '../Loader'
import { useAppDispatch } from '../../store'
import ActivityItem from './Activity/Item'

interface ActivityFormProps {
  resourceId?: number;
  resourceType: number;
  showLatest: boolean;
  dataInsteadRecordId?: ActivityLogType[];
  currentView?: AppElementView;
  setSelectedRecordId?: (value: any) => void;
}

const ActivitiesList: FC<ActivityFormProps> = ({
  resourceId = 0,
  resourceType,
  showLatest,
  dataInsteadRecordId,
  currentView,
  setSelectedRecordId,
}) => {
  const activities = useActivities({
    defaultAttributeVisibility: currentView?.defaultAttributeVisibility,
    resourceId,
    resourceType,
    viewAttributes: currentView?.appElementViewAttributes,
  })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (resourceId) {
      setIsLoading(true)
      dispatch(fetchActivityForResource({ resourceId, resourceType, showLatest })).finally(() => setIsLoading(false))
    }
  }, [resourceId, resourceType, showLatest])

  const activitiesList = dataInsteadRecordId ? dataInsteadRecordId : activities

  if (isLoading) {
    return <Loader loaderClasses="w-full" svgClasses="w-8 m-auto" />
  }
  if (!isLoading && !activitiesList) {
    return <h2 className="mt-4 text-center opacity-50">{t('dashboard:notHaveData')}</h2>
  }

  return (
    <>
      {dataInsteadRecordId
        ? activitiesList.map((activity: ActivityLogType) => (
            <div
              key={activity.id}
              style={{ color: 'inherit' }}
              onClick={() => setSelectedRecordId(activity.id)}
              // to={`/workspace/${activity.workspaceId}/stations/${activity.stationId}/lists/${activity.listId}?recordId=${activity.recordId}`}
            >
              <ActivityItem activity={activity} />
            </div>
          ))
        : activitiesList.map((activity: ActivityLogType) => <ActivityItem key={activity.id} activity={activity} />)}
    </>
  )
}

export default ActivitiesList
