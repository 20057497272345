import { FC, useCallback, useMemo } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Dropdown } from 'components/inputs'
import DefaultNotEditingComponent from 'components/inputs/DefaultNotEditingComponent'

import { createListStatus, useCurrentListId, useCurrentListStatuses } from 'features/list'
import { DropdownOptionType } from 'utils/types/inputs'

import { withContentEditable, withKeyboardNavigation } from 'hocs'
import { useAppDispatch } from 'store'
import ColoredDropdownOption from 'components/inputs/LabelsSelect/ColoredDropdownOption'

const ContentEditableDropdown = withKeyboardNavigation(withContentEditable(Dropdown))

const StatusesDropdown: FC<{
  statusId?: number | null,
  handleRecordChange: (values: any) => void,
  isDisabled?: boolean,
  shouldShowPlaceholder?: boolean,
}> = ({ statusId, handleRecordChange, isDisabled, shouldShowPlaceholder = false }) => {
  const listId = useCurrentListId()
  const { t } = useTranslation('records')
  const appDispatch = useAppDispatch()
  const statuses = useCurrentListStatuses()
  const statusesOptions: DropdownOptionType[] = useMemo(
    () => [
      { label: t('notAssigned'), value: null },
      ...statuses
        .map(({ id, name, color, position }) => ({ color, label: name, position, value: `${id}` }))
        .sort((a, b) => a.position - b.position),
    ],
    [statuses, t],
  )

  const findStatusById = useCallback(
    (statusId: number | null) => statusesOptions.find(({ value }) => +value === statusId) || null,
    [statusesOptions],
  )

  const { color, label } = useMemo(() => (statusId && findStatusById(+statusId)) || {}, [statusId, statuses])

  const renderSelectedStatus = (selectedStatus: any, { showInput }: { showInput: () => void }) => (
    <DefaultNotEditingComponent
      className={classnames('', { 'text-sm': shouldShowPlaceholder })}
      disableHover
      name="statusId"
      placeholder={t('placeholderSelect')}
      shouldShowPlaceholder={shouldShowPlaceholder}
      showInput={showInput}
      style={{ backgroundColor: `${color}20`, color: color }}
      value={selectedStatus === null ? null : label}
    />
  )

  return (
    <ContentEditableDropdown
      className="-mb-2 -ms-2"
      components={{ Option: ColoredDropdownOption }}
      isClearable
      isOnClickDisabled={isDisabled}
      isSearchable={false}
      name="statusId"
      options={statusesOptions}
      renderNotEditingState={renderSelectedStatus}
      shouldShowPlaceholder={shouldShowPlaceholder}
      // styles={{
      //   clearIndicator: (base: any) => ({ ...base, color: color }),
      //   control: () => ({ backgroundColor: `${color}10` }),
      //   input: () => ({ color: color }),
      //   singleValue: () => ({ color: color }),
      // }}
      value={statusId}
      onCreateOption={async (inputValue: string) => {
        const wrappedResults = await appDispatch(createListStatus({ listId: +listId, name: inputValue }))
        const results = await unwrapResult(wrappedResults)
        return results.id
      }}
      onSubmit={(values) => {
        values.statusId = values.statusId === 'null' || values.statusId === null ? null : parseInt(values.statusId)
        handleRecordChange(values)
      }}
    />
  )
}

export default StatusesDropdown
