import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentRecordsCustomFields } from 'features/record'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import MultiSelectDropDown from '../MakerCheckerPolicy/MultiSelectDropDown'
import UserSelectOption from '../MakerCheckerPolicy/UsersSelect/UserSelectOption'
import SelectedUserChip from '../MakerCheckerPolicy/UsersSelect/SelectedUserChip'
import Button, { ButtonVariant } from '../Buttons'
import { getAttributeStatus, getDefaultExceptionsOptions } from '../../utils/constant/constant/common'
import { CloseIcon } from '../Icons'
import { Dropdown } from '../inputs'
import { AppElementAttributeVisibility } from '../../utils/types/AppElementAttributeVisibility'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'
import AppElementAttributeType from '../../utils/types/AppElementAttributeType'

type AddUserToViewFormPropsType = {
  setSelectedFields: (value: any[]) => void,
  selectedFields: any[],
  view?: any,
}

const AddFieldsToViewForm: FC<AddUserToViewFormPropsType> = ({ setSelectedFields, selectedFields, view }) => {
  const { t, i18n } = useTranslation('lists')
  const [showDropDown, setShowDropDown] = useState(false)
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)

  const defaultFields = useMemo(() => {
    return getDefaultExceptionsOptions()
  }, [i18n.language])

  const customFields =
    fieldsList?.map((cf) => ({
      ...cf,
      attributeType: AppElementAttributeType.customField,
      label: cf?.fieldName,
      status: AppElementAttributeVisibility.ReadOnly,
      value: cf?.id,
    })) || []

  const options = [...defaultFields, ...customFields]

  useEffect(() => {
    if (view) {
      const selectedAttributesIds = view.appElementViewAttributes.map((v: any) => v.attributeId)
      const newList = options
        .filter((option) => selectedAttributesIds.indexOf(option.value) > -1)
        .map((item, idx) => {
          const attributeData = view.appElementViewAttributes.find(
            (attribute: any) => +attribute.attributeId === +item.value,
          )
          return {
            ...item,
            status: attributeData?.attributeVisibility,
          }
        })
      setSelectedFields(newList)
    }
  }, [view])

  const onSelectFields = (data) => {
    setSelectedFields([...selectedFields, data])
  }

  const onRemoveOption = (id: string) => {
    const newList = selectedFields.filter((u) => +u?.value !== +id)
    setSelectedFields([...newList])
  }

  const fieldsAttributesOptions = useMemo(() => {
    return getAttributeStatus()
  }, [i18n.language])

  const renderFieldsList = () => {
    return (
      <div className="w-full px-4 py-2 rounded table-fixed border-1">
        <table className="w-full">
          <tbody className="w-full">
            {selectedFields.map((field, idx) => {
              return (
                <tr key={idx} className="items-center p-2">
                  <td className="text-sm">{field.label}</td>
                  <td className="w-1/3">
                    <Dropdown
                      components={{ Option: ColoredDropdownOption }}
                      isSearchable={false}
                      isSmall
                      menuPortalTarget={document.body}
                      name="FieldStatus"
                      options={fieldsAttributesOptions}
                      shouldShowPlaceholder
                      value={field?.status}
                      onChange={(e) => {
                        const currentField = selectedFields[idx]
                        currentField.status = e.target.value
                        const newList = [...selectedFields]
                        newList[idx] = currentField
                        setSelectedFields(newList)
                      }}
                    />
                  </td>
                  <td className="flex items-center justify-end">
                    <CloseIcon
                      className="w-3 mt-3 cursor-pointer text-danger"
                      onClick={() => onRemoveOption(field.id)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="w-full mt-2">
      {!showDropDown ? (
        <div className="flex flex-col">
          {renderFieldsList()}
          <Button
            className="self-end mt-2"
            small
            variant={ButtonVariant.Secondary}
            onClick={() => setShowDropDown(true)}>
            {t('views.addFields')}
          </Button>
        </div>
      ) : (
        <div className="mx-2 mt-4">
          <MultiSelectDropDown
            MultiSelectOptions={(props: any) => <UserSelectOption {...props} onSelect={onSelectFields} />}
            MultiSelectValue={(props: any) => <SelectedUserChip {...props} onRemove={onRemoveOption} />}
            autoFocus={true}
            defaultValue={selectedFields}
            name={'selectFields'}
            options={options}
            placeholder={t('views.selectFields')}
            value={selectedFields}
            onBlur={() => setShowDropDown(false)}
          />
        </div>
      )}
    </div>
  )
}

export default AddFieldsToViewForm
