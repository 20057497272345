import { ComponentType, CSSProperties, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import BasicSelect, { ControlProps, IndicatorProps, Styles } from 'react-select'

import { DropdownOptionType, DropdownInputType, InputRefType, ErrorMessageRefType } from 'utils/types/inputs'
import { InputErrorMessage, InputLabel } from 'components/inputs'
import { DownIcon } from '../Icons'

export const baseDropdownStyles: Partial<Styles<DropdownOptionType, false>> = {
  control: (_: CSSProperties, state: ControlProps<DropdownOptionType, boolean>) => ({
    backgroundColor: state.isFocused ? '#EEEEEE' : '#FFFFFF',
    borderRadius: '4px',
    boxShadow: state.isFocused ? '0px 1px 2px #EEEEEE' : 'none',
    display: 'flex',
    padding: state.isFocused ? '' : '3px 0 4px',
  }),
  dropdownIndicator: (base: CSSProperties, state: IndicatorProps<DropdownOptionType, boolean>) => ({
    ...base,
    display: state.isFocused ? 'block' : 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (base: CSSProperties) => ({
    ...base,
    backgroundColor: '#EEEEEE',
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    color: '#33647E',
    fontSize: 16,
  }),
}

const DatepickerDropdown = forwardRef<InputRefType, DropdownInputType>(
  ({ label, name, options, error, touched, classes, onChange, value, isCreatable, ...rest }, ref) => {
    const { t, i18n } = useTranslation('records')
    const [toggleMenu, setToggleMenu] = useState(false)
    const selectRef = useRef<any>()
    const isRtl = i18n.language === 'ar'

    const errorRef = useRef<ErrorMessageRefType>(null)

    useImperativeHandle(
      ref,
      () => ({
        blur: () => selectRef.current?.select?.blur(),
        focus: () => selectRef.current?.select?.focus(),
        shakeError: () => errorRef?.current?.shakeError(),
      }),
      [ref, selectRef.current?.select, errorRef?.current],
    )

    const handleChange = (selectedOption: DropdownOptionType) => {
      const { value } = selectedOption || { value: null }
      onChange?.({ target: { id: `input-${name}`, name, value: `${value}` } })
      selectRef.current?.select?.blur()
      if (name === 'picker-months' || name === 'picker-years') {
        selectRef.current?.blur()
      }
    }

    const onClick = () => {
      if (toggleMenu) {
        selectRef.current?.blur()
      } else {
        selectRef.current.focus()
      }
      setToggleMenu(!toggleMenu)
    }

    const onClose = () => {
      selectRef.current?.blur()
      setToggleMenu(false)
    }

    const Select: ComponentType<any> = BasicSelect
    const dropdownValue = useMemo(
      () => options?.find(({ value: optionValue }) => `${optionValue}` === `${value}`),
      [value],
    )

    return (
      <div className={classnames(classes.wrapper, 'relative text-lg')} onBlur={onClose} onClick={onClick}>
        <InputLabel className="font-bold" label={label} name={name} />
        <DownIcon
          className={`absolute z-10 w-4 opacity-50 cursor-pointer top-4 ${isRtl ? 'left-0' : 'right-0'}`}
          onClick={onClick}
        />
        <Select
          className={classnames('select', rest.className, classes.input)}
          classNamePrefix="react-select"
          id={`input-${name}`}
          menuIsOpen={toggleMenu}
          menuPlacement="auto"
          name={name}
          options={options}
          placeholder={t('placeholderSelect')}
          ref={selectRef}
          styles={baseDropdownStyles}
          value={dropdownValue}
          onChange={handleChange}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              selectRef.current?.blur()
            }
          }}
          {...rest}
        />
        <InputErrorMessage error={error} ref={errorRef} touched={touched} />
      </div>
    )
  },
)

DatepickerDropdown.displayName = 'DatepickerDropdown'

DatepickerDropdown.defaultProps = {
  classes: {},
}

export default DatepickerDropdown
