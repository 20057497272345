import React, { FC } from 'react'
import Modal from 'react-modal'

import { AppElementBaseTypes } from 'utils/types/AppElementType'
import CustomFieldForm from '../CustomFieldForm'
import { useAppDispatch } from '../../../store'
import { onUpdateCustomField } from '../../../features/list'
import { customFieldBaseTypes } from '../../../utils/constant/enums/customFields'

type EditCustomFieldModalPropsType = {
  onCancel: () => void,
  isModalOpen: boolean,
  isFromAdmin?: boolean,
  customFieldItem: any,
  currentItem: any,
  elementBaseType: AppElementBaseTypes,
  onRefetch: () => void,
}

const EditCustomFieldModal: FC<EditCustomFieldModalPropsType> = ({
  onCancel,
  isModalOpen,
  customFieldItem,
  currentItem,
  elementBaseType,
  onRefetch,
  isFromAdmin,
}) => {
  const appDispatch = useAppDispatch()

  const onEdit = async (newUpdate: any) => {
    const updatedField = { ...customFieldItem }
    updatedField.fieldName = newUpdate.fieldName
    updatedField.fieldCaption = newUpdate.fieldName
    if (newUpdate.changedAttributes && +customFieldItem.baseType === +customFieldBaseTypes.Formula) {
      updatedField.changedAttributes = await newUpdate.changedAttributes
    } else {
      //Options have different format comes from the backend
      const options = updatedField.customFieldOptions
      const updatedOptions = await options?.map((field: any) => {
        const itemIndex = newUpdate.optionsList.findIndex((i: any) => +i.id === +field.id)
        if (+itemIndex > -1) {
          return {
            ...field,
            changedAttributes: newUpdate?.changedAttributes,
            color: newUpdate.optionsList[itemIndex].color,
            description: newUpdate.optionsList[itemIndex].description,
            optionCaption: newUpdate.optionsList[itemIndex].optionCaption,
            optionOrder: newUpdate.optionsList[itemIndex].optionOrder,
          }
        } else {
          return {}
        }
      })
      const addedOptions = newUpdate?.optionsList?.filter((i: any) => i.id === undefined)
      const listWithoutDeletedOptions = updatedOptions?.filter((i: any) => Object.keys(i).length > 0 && i !== null)
      updatedField.customFieldOptions =
        addedOptions?.length > 0 ? [...listWithoutDeletedOptions, ...addedOptions] : [...listWithoutDeletedOptions]
    }
    appDispatch(onUpdateCustomField(updatedField)).then(() => {
      onRefetch()
      onCancel()
    })
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 z-50 flex flex-col w-full max-w-lg bg-white rounded shadow-lg focus:outline-none lg:top-36 lg:bottom-36 left-1/2 transform -translate-x-1/2"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onCancel}>
      <CustomFieldForm
        currentItem={currentItem}
        customFieldItem={customFieldItem}
        elementBaseType={elementBaseType}
        isFromAdmin={isFromAdmin}
        onClose={onCancel}
        onEdit={onEdit}
        onRefetch={onRefetch}
      />
    </Modal>
  )
}

export default EditCustomFieldModal
