import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addOrUpdateFilter, useFilters } from 'features/record'

const HealthFilter: FC = ({ closeDropdown }) => {
  const allFilters = useFilters()
  const dispatch = useDispatch()
  const { t } = useTranslation('records')

  const filterVeryLateRecords = useCallback(() => {
    const lastElement = allFilters[allFilters.length - 1] || [0, 0, 0]
    const healthVeryLateFilter = allFilters?.find(
      (singleFilter) => singleFilter[0] === 'health' && singleFilter[1] === 'veryLate',
    )
    if (!healthVeryLateFilter) {
      dispatch(addOrUpdateFilter(['health', `veryLate`, `${+lastElement[2] + 1}`]))
    }
    closeDropdown?.()
  }, [])

  const filterLateRecords = useCallback(() => {
    const lastElement = allFilters[allFilters.length - 1] || [0, 0, 0]
    const healthVeryLateFilter = allFilters?.find(
      (singleFilter) => singleFilter[0] === 'health' && singleFilter[1] === 'late',
    )
    if (!healthVeryLateFilter) {
      dispatch(addOrUpdateFilter(['health', `late`, `${+lastElement[2] + 1}`]))
    }
    closeDropdown?.()
  }, [])

  return (
    <div className="flex justify-between w-full border-b-2 border-gray-100">
      <button className="flex items-center py-2 hover:text-primary transition-colors" onClick={filterVeryLateRecords}>
        <div className="w-3 h-3 border-none rounded md:w-3 md:h-3 me-2.5 ms-1.5 bg-danger" />
        {t(`records:health:veryLate`)}
      </button>
      <div className="my-1 border-gray-200 border-e-2 opacity-60" />
      <button className="flex items-center py-2 hover:text-primary transition-colors" onClick={filterLateRecords}>
        <div className="w-3 h-3 border-none rounded md:w-3 md:h-3 me-2.5" style={{ background: '#FF671D' }} />
        {t(`records:health:late`)}
      </button>
    </div>
  )
}

export default HealthFilter
