import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '../../../inputs'
import { useAppDispatch } from '../../../../store'
import { createRecord } from '../../../../features/record'
import { formattedElement } from '../../../../utils/formatData/documentation'
import Loader from '../../../Loader'
import { useCurrentList } from '../../../../features/list'

interface AddNewRecordFormPropsType {
  listId: string | number;
  parentId: string | number;
  onAddNewRecord: (item: any, parent: any) => void;
  setHideBack?: (value: boolean) => void;
  hideBack?: boolean;
}
const AddNewRecordForm: FC<AddNewRecordFormPropsType> = ({
  listId,
  onAddNewRecord,
  parentId,
  setHideBack,
  hideBack,
}) => {
  const { t } = useTranslation('documentation')
  const currentList = useCurrentList()
  const appDispatch = useAppDispatch()
  const [name, setName] = useState('')
  const [showAdd, setShowAdd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const onCreateRecord = useCallback(async () => {
    const parentLagacyId = listId || currentList?.id
    if (parentLagacyId !== undefined && name.length > 0) {
      setIsLoading(true)
      await appDispatch(
        createRecord({ listId: +parentLagacyId, name, parentId: +parentId || +currentList?.appElement?.id }),
      ).then((data) => {
        const recordElement = formattedElement({ element: data?.payload?.appElements[0] })
        onAddNewRecord(recordElement)
        setName('')
        setIsLoading(false)
      })
    }
    setShowAdd(true)
  }, [listId, name])

  return (
    <div className="relative flex justify-end w-full cursor-pointer">
      {!showAdd ? (
        <div className="w-full -mt-3 ms-1">
          {isLoading ? (
            <Loader />
          ) : (
            <Input
              autoFocus={!showAdd}
              classes={{ wrapper: 'w-full' }}
              name={'recordName'}
              placeholder={t('documentation:recordName')}
              onBlur={() => {
                onCreateRecord()
                if (setHideBack && hideBack) setHideBack(false)
              }}
              onChange={(e) => {
                setName(e.target.value)
                if (showAdd) setShowAdd(false)
              }}
            />
          )}
        </div>
      ) : (
        <p
          className="text-sm font-bold text-primary"
          onClick={() => {
            setShowAdd(false)
            if (setHideBack && !hideBack) setHideBack(true)
          }}>
          {t('documentation:addNewRecord')}
        </p>
      )}
    </div>
  )
}

export default AddNewRecordForm
