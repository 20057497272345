import React, { FC } from 'react'
import recordType from '../../../utils/types/RecordType'
import LinkToRecords from './LinkToRecords'

interface CustomFieldFormProps {
  setSelectedElements: (e: any) => void;
  selectedElements: any[];
  elementId: number;
  level: number;
  addManyLists?: boolean;
  customFieldInfo: any;
  currentRecord: recordType;
  listId: number | string;
  isEditable: boolean;
  isMulti: boolean;
}

const GridViewLinkColumnView: FC<CustomFieldFormProps> = (props) => {
  const { customFieldInfo, listId, currentRecord, isMulti } = props

  return (
    <LinkToRecords
      key={`${customFieldInfo.id}_${currentRecord.id}`}
      {...props}
      currentRecord={currentRecord}
      customFieldInfo={customFieldInfo}
      isGridView
      isMulti={isMulti}
    />
  )
}

export default GridViewLinkColumnView
