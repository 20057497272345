import Chart from 'components/Dashboard/Chart/Chart'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const ListStatsWidgetSlide: FC<{ dataset: any, plugins: any, listLink: string }> = ({ dataset, plugins, listLink }) => {
  const { t } = useTranslation()

  return (
    <div style={{ height: '100%' }} className="flex flex-col items-center relative">
      <div style={{ height: 'calc(100% - 40px)' }}>
        <Chart
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              },
            },
          }}
          type="pie"
          datasets={[dataset]}
          plugins={plugins}
        />
      </div>

      <div
        title={dataset.label}
        style={{
          width: 180,
          minHeight: 24,
        }}
        className="mt-2 text-center">
        <div dir="auto">
          {t('dashboard:completed')}: {dataset.completion} %
        </div>
        <NavLink className="font-bold underline text-primary hover:text-primary-light line-clamp-2" to={listLink}>
          {dataset.label}
        </NavLink>
      </div>
    </div>
  )
}

export default memo(ListStatsWidgetSlide)
