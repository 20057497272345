import { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { useStatusById } from 'features/list'
import { RecordType } from 'utils/types'

const StatusCell: FC<CellRenderObject & { data: RecordType, value: RecordType['statusId'] }> = ({ value }) => {
  const status = useStatusById(value)

  if (status === undefined) return null

  return (
    <p
      className="w-full p-2 mx-2 text-sm font-bold tracking-wide text-center break-words whitespace-pre-line rounded leading-4"
      style={{ backgroundColor: `${status?.color}20`, color: status?.color }}>
      {status?.name}
    </p>
  )
}

export default StatusCell
