import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import PlanCheckout from '../../components/Subscription/PlanCheckout'
import PlanDownGrade from '../../components/Subscription/PlanDownGrad'
import { withAdminRole } from '../../hocs/withRole'
import { chooseWorkspaceTrailProPlan, useCurrentWorkspaceChosenPlan } from '../../features/workspace'
import PlansListPage from '../../components/Subscription/PlansListPage'
import { clearChosenPlan, setChosenPlan } from '../../features/workspace/workspaceSlice'

const WorkspacePlansListPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const chosenPlan = useCurrentWorkspaceChosenPlan()

  useEffect(() => {
    return () => {
      dispatch(clearChosenPlan())
    }
  }, [])

  const onChoosePlan = (plan: any) => {
    if (plan?.type === 'pro') {
      dispatch(chooseWorkspaceTrailProPlan(plan.workspaceId))
      history.push(`/workspace/${plan.workspaceId}/plan`)
    } else {
      dispatch(setChosenPlan(plan))
    }
  }

  return (
    <div className="w-full p-6 md:p-8">
      {!chosenPlan && <PlansListPage />}
      {chosenPlan && chosenPlan?.status === 'upgrade' && <PlanCheckout />}
      {chosenPlan && chosenPlan?.status !== 'upgrade' && <PlanDownGrade />}
    </div>
  )
}

export default withAdminRole(WorkspacePlansListPage, true)
