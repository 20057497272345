import React, { FC } from 'react'
import { useLabelsByIds } from '../../features/list'
import { hexToRgb } from '../../utils/helpers/colorHelpers'

const LabelsBarList: FC<[]> = ({ labelsIds }) => {
  const labels = useLabelsByIds(labelsIds)

  return (
    <div className="flex flex-wrap mb-0.5 -me-2">
      {labels?.map(({ color, name, id }) => {
        const backgroundColor = hexToRgb(color)

        const colorInRGB = {
          '--bg-b': backgroundColor?.blue,
          '--bg-g': backgroundColor?.green,
          '--bg-r': backgroundColor?.red,
        }
        return (
          <div key={id} className="h-2 rounded-sm mb-1.5 w-11 me-1.5 autocolor" data-title={name} style={colorInRGB} />
        )
      })}
    </div>
  )
}
export default LabelsBarList
