import * as yup from 'yup'
import {
  emailValidationSchema,
  requiredTextFieldValidationSchema,
  phoneNumberValidationSchema,
} from './singleFieldsValidationSchemas'

const validationSchema = yup.object().shape({
  dateOfBirth: yup.date(),
  email: emailValidationSchema,
  firstName: requiredTextFieldValidationSchema('firstName'),
  lastName: requiredTextFieldValidationSchema('lastName'),
  phoneNumber: phoneNumberValidationSchema,
})
export default validationSchema
