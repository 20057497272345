import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect, { Creatable } from 'react-select/creatable'
import Select from 'react-select'
import LabelComponent from 'components/inputs/LabelsSelect/LabelComponent'
import { createLabelDropdownValues } from 'utils/helpers/labelsHelpers'
import ColoredDropdownOption from 'components/inputs/LabelsSelect/ColoredDropdownOption'
import { OptionTypeBase } from 'react-select'
import { useUsersByStation } from 'features/user'
import { RecordDatePicker } from 'components/inputs'
import {
  createStationDocsOneDocLabel,
  getStationDocsAllLabels,
  getStationDocsOneDocInfo,
  updateStationDocsOneDocInfo,
  useStationDocumentationAllLabels,
  useStationDocumentationInfo,
} from 'features/station'
import { useAppDispatch } from 'store'
import Button, { ButtonVariant } from 'components/Buttons'
import { CrossSignIcon } from 'components/Icons'
import { useFormik } from 'formik'

const DocumentationDocumentInfo: FC<{ documentId: string, isDisabled?: boolean, stationId: string }> = ({
  documentId,
  stationId,
}) => {
  const { t } = useTranslation()
  const stationUsers = useUsersByStation()
  const data = useStationDocumentationInfo()
  const allLabels = useStationDocumentationAllLabels() || []
  const labelsRef = useRef<Creatable<OptionTypeBase, false>>(null)
  const labelsDropdownOption = useMemo(() => createLabelDropdownValues(allLabels), [allLabels])
  const value = useMemo(() => createLabelDropdownValues(data ? data.labels : []), [data])
  const appDispatch = useAppDispatch()
  const [hoveredCollab, setHoveredCollab] = useState(0)
  const formik = useFormik({
    initialValues: {
      date: data?.date || '',
      collaboratorsIds: data ? data.collaboratorsIds : [],
      labels: data ? data.labels : [], // You will need to delete labels and put only labelsIds when backend ready
      labelsIds: data ? data?.labels.map((l) => l.id) : [],
    },
    onSubmit: (values) => {
      appDispatch(updateStationDocsOneDocInfo(values))
    },
  })

  useEffect(() => {
    appDispatch(getStationDocsOneDocInfo(documentId))
    appDispatch(getStationDocsAllLabels(stationId))
  }, [])

  const availableUsersOptions = stationUsers
    .filter((user) => !formik.values.collaboratorsIds.includes(user.id))
    .map((user) => ({ label: user.name, value: user.id }))

  const onLabelCreate = async (inputValue: string) => {
    const labelCreateRes = await appDispatch(createStationDocsOneDocLabel({ documentId, name: inputValue }))
    // You will need to delete labels and put only labelsIds when backend ready
    const payload: any = labelCreateRes.payload
    await formik.setValues({
      ...formik.values,
      labels: [...formik.values.labels, payload],
      labelsIds: [...formik.values.labels.map((l) => l.id), payload.id],
    })
    formik.submitForm()
  }

  const onLabelChange = async (values: any) => {
    const labelsIds = values.map(({ value }: { value: number }) => +value)
    await formik.setValues({
      ...formik.values,
      labels: values.map((val: any) => ({ id: val.value, name: val.label, color: val.color })),
      labelsIds,
    })
    formik.submitForm()
  }

  const onCollaboratorsChange = async (values: any) => {
    const newCollabId = values.value
    await formik.setFieldValue('collaboratorsIds', [...formik.values.collaboratorsIds, newCollabId])
    formik.submitForm()
  }

  const onDateChange = async (val: any) => {
    await formik.setFieldValue('date', val.date)
    formik.submitForm()
  }

  const removeCollab = async (id: number) => {
    await formik.setFieldValue(
      'collaboratorsIds',
      formik.values.collaboratorsIds.map((c) => c !== id),
    )
    formik.submitForm()
  }

  // remove this when backend ready, use data.collaborators instead
  const collaborators = stationUsers.filter((user) => formik.values.collaboratorsIds.includes(user.id))

  return (
    <div>
      <h2 className="font-semibold mb-6">{t('records:listing.information')}</h2>
      <div className="mb-6">
        <div className="flex items-center gap-4">
          <p
            style={{
              minWidth: 110,
              width: 110,
            }}
            className="font-semibold">
            {t('documentation:collaborators')}
          </p>
          <Select
            className="-m-2 w-full"
            classNamePrefix="react-select"
            name="owners"
            value={null}
            options={availableUsersOptions}
            placeholder={t('records:placeholderSelect')}
            onChange={onCollaboratorsChange}
          />
        </div>
        <div>
          {collaborators.map((collaborator) => {
            const hovered = hoveredCollab === collaborator.id
            return (
              <div
                onMouseEnter={() => setHoveredCollab(collaborator.id)}
                onMouseLeave={() => setHoveredCollab(0)}
                className={`flex gap-1 items-center my-4 p-1 justify-between transition-all ${
                  hovered ? 'bg-gray-100 rounded' : ''
                }`}
                key={collaborator.id}>
                <div className="flex gap-4 items-center">
                  <div className="w-6 h-6">
                    <img
                      alt={collaborator.name}
                      className="rounded-full"
                      height={'100%'}
                      src={collaborator.avatar || '/assets/images/placeholder.svg'}
                      width={'100%'}
                    />
                  </div>
                  <p>{collaborator.name}</p>
                </div>
                {hovered && (
                  <Button
                    onClick={() => removeCollab(collaborator.id)}
                    style={{ padding: 0 }}
                    icon={CrossSignIcon}
                    variant={ButtonVariant.Icon}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex items-center gap-4 mb-6">
        <p
          style={{
            minWidth: 110,
            width: 110,
          }}
          className="font-semibold">
          {t('dashboard:date')}
        </p>
        <RecordDatePicker date={undefined} handleRecordChange={onDateChange} name="date" shouldShowPlaceholder />
      </div>

      <div className="flex items-center gap-4">
        <p
          style={{
            minWidth: 110,
            width: 110,
          }}
          className="font-semibold">
          {t('records:listing.labels')}
        </p>
        <CreatableSelect
          className="-m-2 w-full"
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          components={{
            MultiValue: LabelComponent,
            // eslint-disable-next-line react/display-name
            Option: (props) => <ColoredDropdownOption {...props} isLabelDropdown />,
          }}
          isClearable
          isMulti
          name="labels"
          options={labelsDropdownOption}
          placeholder={<div className="ms-1">{t('records:placeholderSelect')}</div>}
          ref={labelsRef}
          value={value}
          onChange={onLabelChange}
          onCreateOption={onLabelCreate}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              labelsRef.current?.blur()
            }
          }}
        />
      </div>
    </div>
  )
}

export default memo(DocumentationDocumentInfo)
