import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AvatarInput, Input, MultilineInputWithMentions } from 'components/inputs'
import StationSettings from 'components/StationSettings'
import EntryInitials from 'components/EntryInitials'
import {
  requiredTextFieldValidationSchema,
  textFieldValidationSchema,
} from 'components/forms/validationSchemas/singleFieldsValidationSchemas'

import {
  archiveStationById,
  fetchStationById,
  updateStation,
  uploadAvatar,
  useCurrentStation,
  useCurrentStationLoading,
  removeStationById,
} from 'features/station'
import { withButtonsAndContentEditable } from 'hocs'
import { StationType } from 'utils/types'
import { useRoles } from 'hooks'
import { useHistory, useParams } from 'react-router-dom'
import WatchButton from '../../components/Notifications/WatchButton'
import { useCurrentUser } from '../../features/user'
import ConfirmationModal from '../../components/ConfirmationModal'
import { useCurrentWorkspace } from '../../features/workspace'
import Loader from '../../components/Loader'
import { useAppDispatch } from '../../store'
import { removeListById } from '../../features/list'

const EditableInputWithButtons = withButtonsAndContentEditable(Input)
const EditableMultilineWithButtons = withButtonsAndContentEditable(MultilineInputWithMentions)

type StationDetailsPagePropsType = {
  createdAt: string,
}

const StationDetailsPage: FC<StationDetailsPagePropsType> = ({ createdAt, isViewer }) => {
  const dispatch = useAppDispatch()
  const { stationId } = useParams<{
    stationId: string,
  }>()
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { isAdmin, isEditor } = useRoles()
  const history = useHistory()
  const station = useCurrentStation()
  const loading = useCurrentStationLoading()
  const currentWorkspace = useCurrentWorkspace()
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [localLoader, setLocalLoader] = useState<boolean>(true)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const isArchived = station?.appElement?.isArchived
  const isEditable = isEditor && !station?.appElement?.isArchived

  useEffect(() => {
    if (stationId) dispatch(fetchStationById(stationId)).finally(() => setLocalLoader(false))
  }, [stationId])

  const handleAvatarUpload = (avatar: { filename: string, base64data: string }) =>
    dispatch(uploadAvatar({ avatar, stationId: station?.id }))

  const updateStationDetails = (values: any) => dispatch(updateStation({ ...station, ...values }))

  const commonProps = {
    isLabelVisible: true,
    onSubmit: updateStationDetails,
  }

  const onArchive = () => {
    setIsArchiveModalOpen(false)
    dispatch(
      archiveStationById({
        elementId: station?.appElement?.id,
        recursive: Number(isChecked),
        status: Number(!isArchived),
      }),
    )
    history.push(`/workspace/${currentWorkspace?.id}/stations`)
  }

  const onDelete = () => {
    setIsDeleteModalOpen(false)
    dispatch(removeStationById(station?.id))
    history.push(`/workspace/${currentWorkspace?.id}/stations`)
  }

  if (!station || loading || localLoader)
    return <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />

  return (
    <div className="m-4 mb-auto bg-white rounded-ts-none rounded-2xl">
      <div className="flex pt-8">
        <h1 className="mb-6 text-lg ps-8">{t('stationSettings:stationDetails')}</h1>
        <div className="-mt-2 ms-2">
          {!isArchived && station && <WatchButton elementId={station?.appElement?.id} userId={user?.id} />}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="px-8 pb-0 lg:px-8 lg:w-8/12">
          <EditableInputWithButtons
            isOnClickDisabled={!isEditable}
            label={t('labels.name')}
            name="name"
            validationSchema={requiredTextFieldValidationSchema('name', 100)}
            value={station?.name}
            {...commonProps}
          />
          <EditableMultilineWithButtons
            inputWrapperClassName="-m-2 mb-2"
            isOnClickDisabled={!isEditable}
            label={t('labels.description')}
            name="description"
            validationSchema={textFieldValidationSchema('description', 1000)}
            value={station?.appElement?.description}
            {...commonProps}
          />
          <AvatarInput
            avatar={station?.link}
            isViewer={isViewer}
            emptyAvatarPlaceholder={<EntryInitials color={station?.color} isEdit name={station?.name} />}
            isChangeDisabled={!isEditable}
            updateAvatar={handleAvatarUpload}
          />
          {!isViewer && (
            <div className="flex flex-col">
              <button
                className="py-2 text-danger text-start hover:text-primary"
                type="button"
                onClick={() => setIsArchiveModalOpen(true)}>
                {isArchived ? t('station:unarchiveStation') : t('station:archiveStation')}
              </button>
              {isArchived && isAdmin && (
                <button
                  className="py-2 text-danger text-start hover:text-primary"
                  type="button"
                  onClick={() => setIsDeleteModalOpen(true)}>
                  {t('station:removeStation')}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="p-8 pt-0 lg:p-8 lg:w-4/12">
          <StationSettings createdAt={createdAt} isArchived={isArchived} station={station} />
        </div>
      </div>
      <ConfirmationModal
        checkBoxMessage={isArchived ? t('common:archiveRecursiveMessage') : undefined}
        confirmMessage={!isArchived ? t('common:labels.archive') : t('common:labels.unarchive')}
        confirmationMessage={t(!isArchived ? 'station:confirmStationArchive' : 'station:confirmStationUnarchive', {
          interpolation: { escapeValue: false },
          name: station?.name,
        })}
        isChecked={isChecked}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onChangeCheckBox={() => setIsChecked(!isChecked)}
        onConfirm={onArchive}
      />
      <ConfirmationModal
        confirmationMessage={t('station:confirmStationDelete', {
          interpolation: { escapeValue: false },
          name: station.name,
        })}
        isModalOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
      />
    </div>
  )
}

export default StationDetailsPage
