import { createAsyncThunk } from '@reduxjs/toolkit'

import baseAPI from 'utils/baseAPI'

export const fetchGridViewsByListId = createAsyncThunk(
  'listGrid/fetchGridViewsByListId',
  async (listId: string | number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/list_grids/list/${listId}`)

      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
