import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'components/inputs'
import { addOrUpdateFilter } from 'features/record'
import { DropdownOptionType } from 'utils/types/inputs'

type DropdownFilterType = {
  filterIndex: number,
  filterValue: string | undefined,
  filterName: string,
  dropdownOptions: DropdownOptionType[],
}

const DropdownFilter: FC<DropdownFilterType> = ({ filterIndex, filterValue, filterName, dropdownOptions }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('records')
  const defaultOption = dropdownOptions.find(({ value }: { value: string }) => value?.toString() === filterValue)
  const [currentOption, setCurrentOption] = useState(defaultOption)

  const setNewFilter = (value: string) => {
    dispatch(addOrUpdateFilter([filterName, value, `${filterIndex}`]))
  }
  useEffect(() => {
    setCurrentOption(defaultOption)
  }, [defaultOption])

  return (
    <>
      <span className="mx-2">{t('is')}</span>
      <Dropdown
        classes={{ wrapper: 'flex-1 min-w-0' }}
        name={filterName}
        options={dropdownOptions}
        value={currentOption?.value}
        onChange={({ target: { value } }: { target: { value: string } }) => {
          setCurrentOption({ label: `${value}`, value })
          setNewFilter(`${value}`)
        }}
      />
    </>
  )
}

export default DropdownFilter
