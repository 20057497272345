import { FC } from 'react'

interface TableBodyProps {
  renderRow: (item: any, index: number) => void;
  header?: FC;
  rowData: any;
}

const TableBody: FC<TableBodyProps> = ({ renderRow, header: TableHeader, rowData }) => {
  return (
    <>
      {TableHeader && <TableHeader />}
      <tbody>{rowData?.map(renderRow)}</tbody>
    </>
  )
}

export default TableBody
