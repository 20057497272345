import React, { FC } from 'react'
import { ArchiveIcon } from '../Icons'

const ArchivePanel: FC<{ message: string }> = ({ message }) => {
  return (
    <div className="w-full flex items-center diagonal-pattern px-3 py-2 border-t-2 border-warning">
      <ArchiveIcon className="w-7 me-3 text-warning" />
      <p className="font-bold">{message}</p>
    </div>
  )
}

export default ArchivePanel
