import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  addWidgetToDashboard,
  getAvailableWorkspaceWidgets,
  getWorkspaceDashboard,
  saveWorkspaceDashboard,
  useCurrentWorkspace,
  useWorkspaceDashboard,
  useWorkspaceAllDashboards,
  useWorkspaceDashboardAvailableWidgets,
  getAllDashboardForWorkspace,
  useHasCurrentWorkspacePremiumPlan,
  useWorkspaceDashboardLoading,
  onCreateNewWorkspaceDashboard,
  onDeleteWorkspaceDashboard,
  useCurrentDashboardId,
} from 'features/workspace'
import { useDispatch } from 'react-redux'

import {
  clearWorkspaceDashboardPage,
  onChangeCurrentDashboardId,
  removeWidgetFromDashboard,
  saveDashboardLayoutDragChanges,
  saveDashboardLayoutResizeChanges,
  updateTextWidgetValue,
  updateWorkspaceWidgetImage,
  updateWorkspaceWidgetTitle,
} from 'features/workspace/workspaceSlice'
import Loader from 'components/Loader'
import { useHistory } from 'react-router-dom'
import CustomScroll from 'components/CustomScroll'
import DashboardMain from '../../components/Dashboard/DashboardMain'
import { useCurrentStation } from '../../features/station'
import { useAppDispatch } from '../../store'
import { clearDashboardWidgets, updateDashboardWidgets } from '../../features/element/elementSlice'

const WorkspaceDashboardPage: FC = () => {
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const workspace = useCurrentWorkspace()
  const station = useCurrentStation()
  const dashboard = useWorkspaceDashboard()
  const dashboards = useWorkspaceAllDashboards()
  const currentDashboardId = useCurrentDashboardId()
  const dashboardLoading = useWorkspaceDashboardLoading()
  const availableWidgets = useWorkspaceDashboardAvailableWidgets()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const history = useHistory()

  useEffect(() => {
    if (!isPremiumPlan && workspace !== undefined) {
      history.push('/')
    }
  }, [workspace])

  useEffect(() => {
    if (workspace) {
      if (!dashboards) {
        setIsLoading(true)
        appDispatch(getAllDashboardForWorkspace({ id: workspace.id, scope: 'w' })).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }, [dashboards, workspace])

  useEffect(() => {
    if (workspace) {
      if (currentDashboardId) {
        setIsLoading(true)
        appDispatch(getWorkspaceDashboard(currentDashboardId)).finally(() => {
          setIsLoading(false)
        })
      }
    }
  }, [workspace, currentDashboardId])

  useEffect(() => {
    return () => {
      dispatch(clearWorkspaceDashboardPage())
    }
  }, [])

  const getAvailableWidgets = useCallback(() => {
    if (workspace) {
      dispatch(getAvailableWorkspaceWidgets({ elementId: workspace.id, elementType: 'w' }))
    }
  }, [dispatch, getAvailableWorkspaceWidgets, workspace])

  const updateTextWidget = useCallback(
    (widgetId: number, description: string, imgBase64?: string) => {
      dispatch(updateTextWidgetValue({ description, imgBase64, widgetId }))
      dispatch(updateDashboardWidgets({ description, id: widgetId, imgBase64 }))
    },
    [dispatch, updateTextWidgetValue],
  )

  const updateDashboardWidgetTitle = useCallback(
    (widgetId: number | string, title: string) => {
      dispatch(updateWorkspaceWidgetTitle({ title, widgetId }))
    },
    [dispatch, updateWorkspaceWidgetTitle],
  )

  const onRemoveDashboard = (id: string | number) => {
    const dashboardsList = dashboards.filter((dash) => +dash.id !== +id)
    dispatch(onChangeCurrentDashboardId(dashboardsList[0].id))

    dispatch(onDeleteWorkspaceDashboard(+id))
  }

  return (
    <div className="overflow-hidden">
      <CustomScroll maxHeight={'100%'}>
        <>
          {isLoading ? (
            <div className="flex flex-col min-h-screen text-center">
              <div className="p-1">
                <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />
              </div>
            </div>
          ) : (
            <DashboardMain
              addWidgetToDashboard={addWidgetToDashboard}
              availableWidgets={availableWidgets}
              currentItem={workspace}
              currentStation={station}
              currentWorkspace={workspace}
              dashboard={dashboard}
              dashboardLoading={dashboardLoading}
              dashboards={dashboards}
              elementType={'w'}
              getAvailableWidgets={getAvailableWidgets}
              getCurrentItemDashboard={getWorkspaceDashboard}
              hideName={false}
              removeWidgetFromDashboard={removeWidgetFromDashboard}
              saveCurrentItemDashboard={saveWorkspaceDashboard}
              saveDashboardLayoutDragChanges={saveDashboardLayoutDragChanges}
              saveDashboardLayoutResizeChanges={saveDashboardLayoutResizeChanges}
              showBack={false}
              updateTextWidget={updateTextWidget}
              updateWidgetTitle={updateDashboardWidgetTitle}
              onChangeDashboardId={onChangeCurrentDashboardId}
              onCreateDashboard={onCreateNewWorkspaceDashboard}
              onRemoveDashboard={onRemoveDashboard}
              onUpdateWidgetImage={updateWorkspaceWidgetImage}
            />
          )}
        </>
      </CustomScroll>
    </div>
  )
}

export default WorkspaceDashboardPage
