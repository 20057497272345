import React, { FC, memo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway } from 'react-use'
import { DashboardWidgetBaseTypes, dashboardWidgetTitles } from 'utils/constant/enums/DashboardWidgetBaseType'
import DashboardWidgetType from '../../../utils/types/DashboardWidgetType'
import { SeeMoreIcon } from '../../Icons'
import AddImageModal from '../AddImageModal'

const WidgetTitle: FC<{
  baseType: DashboardWidgetBaseTypes,
  classes?: string,
  isEditMode: boolean,
  text: string,
  widgetId: number,
  elementId?: number | string,
  widget: DashboardWidgetType,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  updateWidgetTitle: (widgetId: number, title: string) => void,
}> = ({
  baseType,
  classes = '',
  isEditMode,
  text = '',
  updateWidgetTitle,
  widgetId,
  elementId,
  widget,
  onUpdateWidgetImage,
}) => {
  const { t } = useTranslation('dashboard')
  const [clicked, setClicked] = useState(false)
  const [inputVal, setInputVal] = useState(text)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const ref = useRef(null)

  const updateText = () => {
    setClicked(false)
    updateWidgetTitle(widgetId, inputVal.trim())
  }

  useClickAway(ref, () => {
    updateText()
  })

  return (
    <div className="flex flex-1 justify-between min-w-0">
      {isEditMode && clicked ? (
        <input
          className={`${classes} mb-3 border-2 border-gray-300 rounded w-full h-8`}
          ref={ref}
          type="text"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              updateText()
            }
          }}
        />
      ) : (
        <h3
          className={`font-bold text-md text-gray-800 ${classes} truncate`}
          title={text}
          onClick={() => {
            if (isEditMode) {
              setClicked(true)
            }
          }}>
          {text || t(dashboardWidgetTitles[baseType])}
        </h3>
      )}
      {isEditMode && (
        <div className="flex items-center">
          <SeeMoreIcon className="w-5 cursor-pointer" onClick={() => setIsModalOpen(true)} />
        </div>
      )}
      <AddImageModal
        elementId={elementId}
        isEditMode={isEditMode}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        widget={widget}
        onUpdateWidgetImage={onUpdateWidgetImage}
      />
    </div>
  )
}

export default memo(WidgetTitle)
