import ContextMenu from 'components/ContextMenu'
import { DownIcon, LeftIcon, RightIcon } from 'components/Icons'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from 'components/Buttons'
import { Input } from 'components/inputs'
import { useClickAway } from 'react-use'
// import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import {
  archiveStationById,
  createDocumentationNewPage,
  getStationDocSidebar,
  useStationDocumentationSidebarHidden,
} from '../../../features/station'
import { useAppDispatch } from '../../../store'
import { SetCurrentStationArchiveStatus } from '../../../features/station/stationSlice'
import ConfirmationModal from '../../ConfirmationModal'

const getBackgroundColor = (hovered: boolean, selected: boolean, isFake: boolean, isMouseHovered: boolean) => {
  let color = '#fff'

  if (!isFake) {
    if (selected || isMouseHovered || hovered) {
      color = '#e5f3f4'
    }
  } else {
    if (hovered) {
      color = '#008996'
    }
  }

  return color
}

// This callback uses moveCard callback to send new data to SidebarContent.tsx
const hoverItem = (
  item: DragItem,
  monitor: DropTargetMonitor,
  ref: any,
  index: any,
  order: any,
  isFake: any,
  level: any,
  id: any,
  parentId: number,
  moveCard: any,
) => {
  if (!ref.current) {
    return
  }
  const hoverIndex = index
  moveCard(item.order, order, isFake, level, id, parentId, item.id, item.index, item.parentId)
  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  item.index = hoverIndex
}

export const titleInputValidation = yup.object().shape({
  docTitle: yup.string().required('Title is required'),
})

export const ItemTypes = {
  CARD: 'card',
}

// export interface SidebarItemProps {
//   id: any;
//   text: string;
//   index: number;
//   moveCard: (dragIndex: number, hoverIndex: number, isFake: boolean, level: number, id: number, ref: any) => void;
//   isFake: boolean;
//   order: number;
//   hovered: number;
//   disableHover: () => void;
//   level: number;
//   isChildren: boolean;
//   isVisible: boolean;
//   updateTitle?: (id: number, val: string) => void;
//   selectedPage: number;
//   onOpen?: (item: any) => void;
//   delay: number;
// }

interface DragItem {
  index: number;
  id: string;
  type: string;
  order: number;
  parentId: number;
}

// Sidebar item where drag properties set
const SidebarItem = (props: any) => {
  const {
    item,
    moveCard,
    extraFakes,
    hovered,
    disableHover,
    onOpen,
    index,
    dragChildren,
    selectedPage,
    updateTitle,
    allowAccess,
    isPublic,
    hoveredParentId,
    shouldHaveExtraFake,
  } = props
  const { order, id, level, title, children, parentId } = item
  const isFake = false
  const isFirstItem = index === 0
  const isChildren = children && children.length ? true : false
  const [isArchiveOpen, setIsArchiveOpen] = useState(false)
  const [mouseHover, setMouseHovered] = useState(0)
  const [openTextInput, setOpenTextInput] = useState(false)
  const inputRef = useRef(null)
  const ref = useRef<HTMLDivElement>(null)
  const fakeRef = useRef<HTMLDivElement>(null)
  const extraFakeRef = useRef<HTMLDivElement>(null)
  const fakeFirstRef = useRef<HTMLDivElement>(null)
  const appDispatch = useAppDispatch()
  const hiddenElements = useStationDocumentationSidebarHidden()
  const isItemOpened = children.filter((i: any) => hiddenElements.includes(i)).length === 0
  const { workspaceId, stationId, docId, publicId } = useParams<{
    workspaceId: string,
    stationId: string,
    docId: string,
    publicId: string,
  }>()
  const history = useHistory()
  const { t, i18n } = useTranslation('documentation')
  const formik = useFormik({
    initialValues: { docTitle: title },
    onSubmit: ({ docTitle }) => {
      setOpenTextInput(false)
      // to do the post request just if the value changed
      if (updateTitle && docTitle.trim() !== title.trim()) {
        updateTitle(id, docTitle.trim())
      }
    },
    validateOnChange: true,
    validationSchema: titleInputValidation,
  })
  const isRtl = i18n.language === 'ar'

  const mouseOver = useCallback(
    (itemId: any) => {
      if (!hovered) {
        setMouseHovered(itemId)
      }
      if (hovered !== itemId) {
        setMouseHovered(itemId)
      }
    },
    [hovered, setMouseHovered],
  )

  const mouseLeave = useCallback(() => {
    setMouseHovered(0)
  }, [setMouseHovered])

  useClickAway(inputRef, async () => {
    await formik.submitForm()
  })

  const escFunction = useCallback(
    async (event) => {
      if (event.code === 'Escape' && openTextInput) {
        setOpenTextInput(false)
        formik.setFieldValue('docTitle', title)
      }
      if (event.code === 'Enter' && openTextInput) {
        await formik.submitForm()
      }
    },
    [openTextInput, setOpenTextInput, formik],
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction)

    return () => {
      document.removeEventListener('keydown', escFunction)
    }
  }, [escFunction])

  // Fake is the empty item AFTER real sidebar item
  const [fakeHandler, fakeDrop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      return hoverItem(item, monitor, ref, index + 0.5, order + 0.5, true, level, id, parentId, moveCard)
    },
  })

  // ExtraFake is the empty item. It is only shows when child sidebar items are visible. In that case extra fakes will be on TOP of the real item(ON THE BOTTOM WILL BE JUST FAKE ITEMS THAT I WROTE ABOVE)
  const [extraFakeHandler, extraFakeDrop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      return hoverItem(item, monitor, ref, index - 0.25, order - 0.25, true, level, id, parentId, moveCard)
    },
  })

  // Real item
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      return hoverItem(item, monitor, ref, index, order, isFake, level, id, parentId, moveCard)
    },
  })

  // THis is fake item that only show in the very beginning of the sidebar list
  const [fakeFirstHandler, fakeFirstDrop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      return hoverItem(item, monitor, ref, index - 0.5, order - 0.5, true, level, id, parentId, moveCard)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    canDrag: !isFake && allowAccess,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      setMouseHovered(0)
      disableHover()
    },
    item: () => {
      return { id, index, order, parentId }
    },
    type: ItemTypes.CARD,
  })
  const [fakeDragging, fakeDrag] = useDrag({
    canDrag: false,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      disableHover()
    },
    item: () => {
      return { id, index, order, parentId }
    },
    type: ItemTypes.CARD,
  })
  const [fakeFirstDragging, fakeFirstDrag] = useDrag({
    canDrag: false,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      disableHover()
    },
    item: () => {
      return { id, index, order, parentId }
    },
    type: ItemTypes.CARD,
  })
  const [extraFakeDragging, extraFakeDrag] = useDrag({
    canDrag: false,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      disableHover()
    },
    item: () => {
      return { id, index, order, parentId }
    },
    type: ItemTypes.CARD,
  })

  drag(drop(ref))
  fakeDrag(fakeDrop(fakeRef))
  extraFakeDrag(extraFakeDrop(extraFakeRef))
  fakeFirstDrag(fakeFirstDrop(fakeFirstRef))

  useEffect(() => {
    if (isDragging) {
      setMouseHovered(0)
    }
  }, [isDragging])
  const isHovered = hovered === order && hoveredParentId === parentId
  const isMouseHovered = mouseHover === id
  const height = isFake ? (isHovered ? 20 : 4) : 'auto'
  const padding = level === 1 ? 0 : level - 1
  const dispatch = useDispatch()
  const fakeHovered = fakeRef.current && fakeRef.current.id === hovered.toString() && hoveredParentId === parentId
  const extraFakeHovered =
    extraFakeRef.current && extraFakeRef.current.id === hovered.toString() && hoveredParentId === parentId
  const fakeFirtsHovered =
    fakeFirstRef.current && fakeFirstRef.current.id === hovered.toString() && hoveredParentId === parentId
  const linkToDocs = isPublic
    ? `/public-docs/${publicId}`
    : `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`
  const fakeHeight = 30

  const onArchive = () => {
    appDispatch(
      archiveStationById({
        elementId: +id,
        recursive: 1,
        status: 1,
      }),
    ).then(() => {
      setIsArchiveOpen(false)
      dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId }))
      history.push(`${linkToDocs}/archived`)
    })
  }
  console.log(dragChildren, id, 'xxxc')
  return (
    <div style={{ height: dragChildren.includes(id) ? 1 : 'auto' }}>
      <div
        id={(order - 0.5).toString()}
        ref={fakeFirstRef}
        style={{
          backgroundColor: fakeFirtsHovered ? '#39abec' : 'white',
          borderRadius: 3,
          display: dragChildren.includes(id) || !isFirstItem ? 'none' : 'block',
          fontSize: 10,
          height: fakeFirtsHovered ? fakeHeight : isFirstItem ? 6 : 0,
          transition: 'height .1s linear',
        }}></div>
      <div
        id={(order - 0.25).toString()}
        ref={extraFakeRef}
        style={{
          backgroundColor: extraFakeHovered ? '#39abec' : 'white',
          borderRadius: 3,
          display: dragChildren.includes(id) ? 'none' : 'flex',
          fontSize: 10,
          height: extraFakeHovered ? fakeHeight : shouldHaveExtraFake && !dragChildren.includes(id) ? 6 : 0,
          marginLeft: !isRtl ? padding * 12 : 0,
          marginRight: isRtl ? padding * 12 : 0,
          transition: 'height .1s linear',
        }}>
        {/* {extraFakes.includes(id) && !dragChildren.includes(id) ? 'extra fake ' + title : ''} */}
      </div>
      <div>
        {!openTextInput ? (
          <div
            className={`pt-1 pb-1 flex items-center rounded cursor-pointer relative`}
            data-handler-id={handlerId}
            id={id}
            ref={ref}
            style={{
              backgroundColor: getBackgroundColor(isHovered, +selectedPage === +id, isFake, isMouseHovered),
              display: dragChildren.includes(id) ? 'none' : 'flex',
              height: height,
              marginLeft: !isRtl && isFake ? padding * 12 : 0,
              marginRight: isRtl && isFake ? padding * 12 : 0,
              paddingLeft: !isRtl ? padding * 12 : 8,
              paddingRight: isRtl ? padding * 12 : 8,
              transition: 'height .1s linear',
            }}
            title={title}
            onMouseLeave={mouseLeave}
            onMouseOver={() => mouseOver(id)}>
            {!isFake ? (
              isChildren ? (
                <div
                  className="ms-1.5"
                  style={{ minWidth: 24, width: 24 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (onOpen) {
                      onOpen(item)
                    }
                  }}>
                  {!isItemOpened ? (
                    isRtl ? (
                      <LeftIcon className="w-3" />
                    ) : (
                      <RightIcon className="w-3" />
                    )
                  ) : (
                    <DownIcon className="w-3" />
                  )}
                </div>
              ) : (
                <div className="px-2" style={{ height: 24, width: 24 }}>
                  &#8226;
                </div>
              )
            ) : null}
            {/*<div className="px-2" style={{ height: 24, width: 24 }}>*/}
            {/*  &#8226;*/}
            {/*</div>*/}
            <Link className="flex flex-1 truncate" to={`${linkToDocs}/view/${id}`}>
              <div
                onClick={() => {
                  dispatch(SetCurrentStationArchiveStatus(item.isArchived))
                }}>
                {title}
              </div>
            </Link>
            {hovered === id ||
              (mouseHover === id && !isFake && allowAccess && (
                <>
                  <div
                    className="absolute flex ms-auto top-1.5 end-0"
                    style={{
                      backgroundColor: (hovered === id || mouseHover === id) && !isFake ? '#e5f3f4' : '#fff',
                    }}>
                    <div
                      className="ms-1 me-2"
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onMouseLeave={mouseLeave}
                      onMouseOver={() => mouseOver(id)}>
                      <ContextMenu
                        isCustomButton
                        render={() => {
                          return (
                            <div className="pb-2">
                              <Button variant={ButtonVariant.Icon} onClick={() => setOpenTextInput(true)}>
                                {t('documentation:rename')}
                              </Button>

                              <Link className="p-3" to={`${linkToDocs}/edit/${id}`}>
                                {t('common:labels.edit')}
                              </Link>
                              <Button variant={ButtonVariant.Icon} onClick={() => setIsArchiveOpen(true)}>
                                <span className="text-danger">{t('common:labels.archive')}</span>
                              </Button>
                            </div>
                          )
                        }}
                      />
                    </div>

                    <div
                      className="flex items-center justify-center mx-2 text-gray-600 hover:text-primary-light"
                      style={{ fontSize: 18, marginTop: -1 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        const createProps = {
                          parentId: +id,
                          title: t('documentation:defaultTitle'),
                        }
                        appDispatch(createDocumentationNewPage(createProps)).then((data) => {
                          history.push(`${linkToDocs}/edit/${+data.payload.id}?parent=${id}`)
                        })

                        // history.push(`${linkToDocs}/edit/0?parent=${id}`)
                      }}>
                      +
                    </div>
                  </div>
                </>
              ))}
          </div>
        ) : (
          allowAccess && (
            <form onSubmit={formik.handleSubmit}>
              <Input
                classes={{ wrapper: 'mb-0' }}
                customHeight="h-8"
                error={formik.errors.docTitle}
                name="docTitle"
                ref={inputRef}
                required={true}
                style={{ border: formik.errors.docTitle ? '2px solid red' : '2px solid #dde5ed' }}
                type="text"
                value={formik.values.docTitle}
                onChange={formik.handleChange}
              />
              {formik.errors.docTitle && <div className="text-sm text-danger">{formik.errors.docTitle}</div>}
            </form>
          )
        )}
      </div>
      <div
        id={order + 0.5}
        ref={fakeRef}
        style={{
          backgroundColor: fakeHovered ? '#39abec' : 'white',
          borderRadius: 3,
          display: dragChildren.includes(id) ? 'none' : 'flex',
          fontSize: 10,
          height: fakeHovered
            ? fakeHeight
            : (isChildren ? children.filter((i: number) => !dragChildren.includes(i)).length : false)
            ? 0
            : 6,
          marginLeft: !isRtl ? padding * 12 : 0,
          marginRight: isRtl ? padding * 12 : 0,
          transition: 'height .1s linear',
        }}>
        {/* {dragChildren.includes(id) ? '' : 'fake'} */}
      </div>
      <ConfirmationModal
        confirmMessage={t('common:labels.archive')}
        confirmationMessage={t('documentation:archiveDocConfirmationMessage', {
          interpolation: { escapeValue: false },
        })}
        isModalOpen={isArchiveOpen}
        onCancel={() => setIsArchiveOpen(false)}
        onConfirm={onArchive}
      />
    </div>
  )
}

export default SidebarItem
