import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import Tour from 'reactour'

import { addStation } from 'features/station'
import { Input, MultilineInputWithMentions } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import PanelFormWrapper from 'components//PanelFormWrapper'
import { TourContent } from 'components/Tour'

import { addStationValidationSchema } from './validationSchemas'

const AddStationToWorkspaceForm: FC<{
  workspaceId: number,
  closePanel?: () => void,
}> = ({ closePanel, workspaceId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('station')
  const thirdStepDone = localStorage.getItem('thirdStepDone') === 'true'

  const [isTourOpen, setTourOpen] = useState(!thirdStepDone)

  const { errors, handleChange, handleSubmit, handleBlur, touched, values } = useFormik({
    initialValues: {
      description: '',
      name: '',
    },
    onSubmit: (values) => {
      dispatch(addStation({ ...values, description: values.description?.trim(), workspaceId }))
      closePanel && closePanel()
    },
    validationSchema: addStationValidationSchema,
  })

  const buttons = (
    <>
      <Button className="me-4" variant={ButtonVariant.Ghost} onClick={closePanel}>
        {t('common:labels.cancel')}
      </Button>
      <Button type="submit">{t('createStation')}</Button>
    </>
  )

  const steps = [
    {
      action: (node: any) => {
        localStorage.setItem('thirdStepDone', 'true')
        node.focus()
      },
      content: function thirdStep() {
        return <TourContent closeTour={() => setTourOpen(false)} message={t('tour:thirdStepText')} />
      },
      selector: '[data-tut="third__step"]',
    },
  ]

  return (
    <div className="h-full flex flex-1" data-tut="third__step">
      <Tour
        disableFocusLock
        isOpen={isTourOpen}
        showButtons={false}
        showNavigation={false}
        showNumber={false}
        steps={steps}
        onRequestClose={() => setTourOpen(false)}
      />
      <PanelFormWrapper buttons={buttons} onSubmit={handleSubmit} hideWatchButton>
        <Input
          autoFocus={true}
          error={errors.name}
          label={t('common:labels.name')}
          name="name"
          placeholder={t('common:labels.name')}
          required
          touched={touched.name}
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <MultilineInputWithMentions
          error={errors.description}
          label={t('common:labels.description')}
          name="description"
          placeholder={t('common:labels.description')}
          rows={5}
          touched={touched.description}
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </PanelFormWrapper>
    </div>
  )
}

export default AddStationToWorkspaceForm
