import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Droppable, Draggable } from 'libs/react-beautiful-dnd'

import { useCalendarUnassignedRecords } from 'features/record'
import { DraggableProvided, DroppableProvided } from 'libs/react-beautiful-dnd/src'

const DndTaskList: FC = () => {
  const { t } = useTranslation()
  const unassignedRecords = useCalendarUnassignedRecords()

  return (
    <Droppable droppableId="unassigned" isDropDisabled>
      {(provided: DroppableProvided) => (
        <div
          className="z-10 w-64 px-3 pt-1 pb-3 overflow-auto text-sm bg-white border-t-2 shadow-sm"
          id="task-list"
          ref={provided.innerRef}>
          {unassignedRecords.map(({ name, id }, index) => (
            <Draggable key={id} draggableId={`${id}`} index={index}>
              {(provided: DraggableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="flex items-center w-full h-auto px-2 mt-2 text-sm font-bold text-gray-600 border-2 border-gray-400 border-solid rounded-sm py-2.5 shadow-sm hover:text-primary-dark hover:border-primary-dark text-start leading-3 transition focus:outline-none focus-visible:shadow-focus">
                  {name}
                </div>
              )}
            </Draggable>
          ))}
          {unassignedRecords.length === 0 && (
            <>
              <h2 className="mt-8 font-bold text-center text-gray-700">{t('records:taskListEmpty:header')}</h2>
              <p className="mt-2 mb-2 text-sm text-center">{t('records:taskListEmpty:description')}</p>
            </>
          )}
        </div>
      )}
    </Droppable>
  )
}

export default DndTaskList
