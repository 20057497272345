import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import findLastKey from 'lodash/findLastKey'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import mapKeys from 'lodash/mapKeys'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'

import { useCurrentUser, useUserById, useCurrentListMembers } from 'features/user'
import {
  clearCurrentRecord,
  clearRecords,
  createRecord,
  fetchRecordById,
  fetchRecordCustomFields,
  updateRecordElementById,
  useCurrentRecord,
  useCurrentRecordDetails,
  useCurrentRecordDetailsLoading,
  useCurrentRecordReviewers,
  useIsRecordRejected,
  useRecordLoading,
  useRecords,
  useRecordsChildren,
} from 'features/record'

import { UploadingFilesType, RecordType, ElementMemberType } from 'utils/types'
import { RecordAppElementAllAttributesConst } from 'utils/types/RecordAppElementAllAttributes'
import { useAppDispatch } from 'store'
import { withButtonsAndContentEditable, withContentEditable } from 'hocs'
import { recordAppElementDefaultAttributesValues } from 'utils/constant/constant/common'
import { getFieldVisibility } from 'utils/helpers/customFields'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'
import { AppElementView } from 'utils/types/AppElementViewType'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { getFormattedDateWithTime } from 'utils/helpers/generalHelpers'
import { getQueryParamValue } from 'utils/helpers/queryParamsHelper'
import { usePanel } from 'contexts/PanelContext'
import { createAttachment } from 'features/attachment'
import { useCurrentList, useCurrentListLoading } from 'features/list'

import AttachmentList from 'components/AttachmentList'
import Loader from 'components/Loader'
import EmptyStatePage from 'components/EmptyStatePage'
import { ArrowRightIcon, ChevronLeftIcon, DragDropIcon, EmptyErrorIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { RecordSettings } from 'components/SettingsDropdown'
import { getWhichDateIsVisible } from 'utils/helpers/viewHelpers'
import {
  PriorityDropdown,
  UserDropdown,
  StatusesDropdown,
  Input,
  LabelsSelect,
  RecordDatePicker,
} from 'components/inputs'
import { healthColors } from 'components/GridView/cells/displayCells/HealthCell'
import RecordDescriptionRTEwithMentions from 'components/RecordDescriptionRTEwithMentions'
import classnames from 'classnames'
import SelectedUser from 'components/inputs/UserDropdown/SelectedUser'
import { useDispatch } from 'react-redux'
import RecordCustomFieldsList from '../CustomFields/RecordCustomFieldsList'
import { useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'
import LinkedDocuments from '../LinkedDocuments/LinkedDocuments'
import { useCurrentStation } from '../../features/station'
import CreateCustomFieldButton from '../CustomFields/CreateCustomFieldButton'
import SwitchLists from '../SwitchLists/SwitchLists'
import RecordReviewer from '../MakerCheckerPolicy/RecordReviewer'
import ChartIconsList from '../ChartIconsList/ChartIconsList'
import { getElementLinkedRecords, useRefetchFunction } from '../../features/element'
import { clearRefetchAfterUpdate } from '../../features/element/elementSlice'
import ActivityForm from './ActivityForm'
import { completedInValidationSchema } from './validationSchemas/singleFieldsValidationSchemas'

const ContentEditableInput = withContentEditable(Input)
const ContentEditableUserDropdown = withContentEditable(UserDropdown)
const EditableMultilineWithButtons = withButtonsAndContentEditable(RecordDescriptionRTEwithMentions)

export const MAX_FILE_SIZE = 100 * 1024 * 1024

export const directoryValidator = (file: any) => {
  if (file.path.startsWith('/'))
    return {
      code: 'directory-upload',
      message: "You can't upload directory",
    }
  return null
}

const AddRecordToListForm: FC<{
  recordId: number,
  isEditor: boolean,
  isAdmin: boolean,
  listId: number,
  isDetailedRecord?: boolean,
  currentView: AppElementView | null,
  partialRecord?: Partial<RecordType>,
}> = memo(
  ({ recordId, listId, isEditor, partialRecord, isAdmin, currentView, isDetailedRecord = false }) => {
    const appDispatch = useAppDispatch()
    const record = useCurrentRecord()
    const currentRecordLoading = useCurrentRecordDetailsLoading()
    const currentRecordDetails = useCurrentRecordDetails()
    const currentRecord = isDetailedRecord ? currentRecordDetails : record
    let isRecordEditable = false

    if (
      ((isAdmin || isEditor) &&
        currentRecord &&
        currentRecord.appElements &&
        currentRecord.appElements[0] &&
        !currentRecord.appElements[0].isArchived) ||
      isDetailedRecord
    ) {
      isRecordEditable = true
    }
    const { t, i18n } = useTranslation(['records', 'noAccess'])

    useEffect(() => {
      return () => {
        if (isDetailedRecord) {
          dispatch(clearRecords())
          dispatch(clearCurrentRecord())
          dispatch(clearRefetchAfterUpdate())
        }
      }
    }, [])

    useEffect(() => {
      if (!isDetailedRecord) {
        dispatch(fetchRecordById({ id: currentRecord?.id, viewId: currentView?.id }))
      }
    }, [currentRecord?.id, currentView?.id])

    const uploadAttachment = useCallback(
      (filesToUpload, fileKey) => {
        appDispatch(createAttachment({ file: filesToUpload[+fileKey], recordId })).then(() => {
          const filesCopy = { ...filesBeingUploadedRef.current }
          delete filesCopy[+fileKey]
          updateFilesState(filesCopy)
        })
      },
      [appDispatch],
    )

    const onAttachmentDrop = useCallback(
      (files) => {
        if (recordId !== undefined) {
          const noCurrentlyUploadedFiles = findLastKey(filesBeingUploadedRef.current) || -1
          const filesToUpload = mapKeys(files, (file, key) => {
            return +key + +noCurrentlyUploadedFiles + 1
          })
          updateFilesState({ ...filesBeingUploadedRef.current, ...filesToUpload })
          Object.keys(filesToUpload).forEach((fileKey: string) => uploadAttachment(filesToUpload, fileKey))
        }
      },
      [recordId, uploadAttachment],
    )

    const onDropRejected = useCallback(
      (rejectedFiles: FileRejection[]) => {
        rejectedFiles.forEach(({ file, errors }) => {
          errors.forEach(({ code, message }) => {
            switch (code) {
              case ErrorCode.FileTooLarge:
                return toast.error(t('attachmentTooBig', { name: file.name }), { toastId: file.name })
              case 'directory-upload':
                return toast.error(t('folderUploadError'), { toastId: 'folderUploadError' })
              default:
                return toast.error(message)
            }
          })
        })
      },
      [t],
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      disabled: !isRecordEditable || isEmpty(currentRecord),
      maxSize: MAX_FILE_SIZE,
      multiple: true,
      noClick: true,
      onDrop: onAttachmentDrop,
      onDropRejected,
      validator: directoryValidator,
    })

    useEffect(() => {
      return closeModal
    }, [])

    useEffect(() => {
      if (currentRecord?.name) setPanelHeader(currentRecord.name)
    }, [currentRecord])

    const [filesBeingUploaded, setFilesBeingUploaded] = useState<UploadingFilesType>({})
    const currentStation = useCurrentStation()
    const user = useCurrentUser()
    const refetchFunction = useRefetchFunction()
    const language = i18n.language
    const isHijri = user.isHijri
    const { location, push } = useHistory()
    const dispatch = useDispatch()
    const { setPanelHeader, closePanel } = usePanel()
    const currentList = useCurrentList()
    const records = useRecords()
    const recordOwner = useUserById(currentRecord?.ownerId || partialRecord?.ownerId)
    const [completeInValue, setCompleteInValue] = useState(currentRecord?.completedIn || 0)
    const currentListMembers = useCurrentListMembers()
    const currentRecordReviewers = useCurrentRecordReviewers()
    const isLoading = useRecordLoading()
    const recordSubtasks = useRecordsChildren()
    const isRejected = useIsRecordRejected()
    const filesBeingUploadedRef = useRef(filesBeingUploaded)
    const completedInRef = useRef<any>(null)
    const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
    const isCurrentListLoading = useCurrentListLoading()
    const isViewer = !isEditor && !isAdmin
    const hasSubtasks = currentRecord?.appElements && !!recordSubtasks[currentRecord?.appElements[0]?.id]
    const isMakerCheckerApplied = currentRecord?.isMakerCheckerApplied
    const isCurrentUserReviewer = currentRecord?.reviewers?.find((u) => +u.userId === +user.id) !== undefined
    const needForApproval = !isAdmin && isMakerCheckerApplied && !isCurrentUserReviewer

    const onRefetch = () => {
      appDispatch(fetchRecordCustomFields(currentRecord?.appElements[0]?.id)).then(() => {
        dispatch(fetchRecordById({ id: currentRecord?.id, viewId: currentView?.id }))
      })
    }

    const closeModal = () => {
      let closeModalUrl = location.pathname
      if (location.pathname.includes('/grid')) {
        const currentPage = getQueryParamValue(location.search, 'page')
        closeModalUrl = `${closeModalUrl}?page=${currentPage}`
      }
      dispatch(clearCurrentRecord())
      push(closeModalUrl)
      closePanel()
    }

    const updateFilesState = (newState: any) => {
      filesBeingUploadedRef.current = newState
      setFilesBeingUploaded(newState)
    }

    const initialValues = {
      completedIn: currentRecord?.completedIn,
      description: currentRecord?.appElements ? currentRecord?.appElements[0]?.description || undefined : undefined,
      endDate: partialRecord?.endDate || (currentRecord?.endDate ? new Date(currentRecord?.endDate) : undefined),
      health: currentRecord?.health,
      labelsIds: currentRecord?.labelsIds,
      name: currentRecord?.name || '',
      ownerId: partialRecord?.ownerId || currentRecord?.ownerId || user.id,
      priority: currentRecord?.priority ?? undefined,
      startDate: partialRecord?.startDate || (currentRecord?.startDate && new Date(currentRecord?.startDate)),
      statusId: currentRecord?.statusId,
    }

    const handleUpdateRecord = async (values: Partial<RecordType>) => {
      if ((recordId || currentRecord?.appElements) && currentRecord) {
        // appDispatch(
        //   updateRecordById({
        //     changes: { ...values, ...values?.changes },
        //     initialRecord: currentRecord,
        //   }),
        // )
        appDispatch(
          updateRecordElementById({
            changes: { ...values, ...values?.changes },
            elementId: currentRecord?.appElements[0]?.id,
            record: currentRecord,
            viewId: currentView?.id,
          }),
        ).then(() => {
          if (isDetailedRecord) {
            dispatch(refetchFunction())
          }
        })
      } else {
        appDispatch(createRecord({ ...partialRecord, ...values, listId, parentId: +currentList?.appElement?.id }))
          .then(unwrapResult)
          .then(({ id }) => {
            const parsedSearch = JSON.parse(
              '{"' +
                decodeURI(location.search)
                  .replace(/"/g, '\\"')
                  .replace(/&/g, '","')
                  .replace(/\?/g, '')
                  .replace(/=/g, '":"') +
                '"}',
            )
            parsedSearch.recordId = id
            const queryParams = Object.keys(parsedSearch)
              .map((key) => `${key}=${parsedSearch[key]}`)
              .join('&')
            push({ pathname: location.pathname, search: queryParams })
          })
      }
    }

    const NoAccessButton = () => (
      <Button variant={ButtonVariant.Outline} onClick={closeModal}>
        <ChevronLeftIcon className="w-6 rtl-mirror" />
        {t(`noAccess:button`)}
      </Button>
    )

    const linkClasses =
      'me-2 sm:me-6 text-primary-dark transition transition-colors hover:text-primary focus:text-primary last:me-0'
    const isSubTask = currentRecord?.appElements && Number(currentRecord?.appElements[0]?.elementLevel) > 4
    const isLeafNode =
      isSubTask || (currentRecord?.appElements && Number(currentRecord?.appElements[0]?.directChildrenCount) === 0)
    const renderSelectedUser = (selectedUser: ElementMemberType, { showInput }: { showInput: () => void }) => {
      let user: ElementMemberType | undefined = selectedUser
      if (typeof selectedUser === 'string' || typeof selectedUser === 'number') {
        user = currentListMembers?.find((member) => member.userId == selectedUser)
      }

      return (
        <div className="-mt-2 -ms-2 -mb-2">
          <SelectedUser
            className="block w-full px-2 border-2 border-transparent border-dashed rounded cursor-pointer hover:bg-white focus:bg-white leading-6 min-h-12 focus:overflow-visible focus:whitespace-normal transition transition-colors hover:border-gray-300 focus:outline-none focus:border-gray-400"
            selectedUser={!user ? currentRecord?.owner : user}
            onClick={showInput}
          />
        </div>
      )
    }

    let attributeVisibilityMap: { [key: string]: AppElementAttributeVisibility } = {}
    Object.keys(recordAppElementDefaultAttributesValues).map((attribute) => {
      if (currentRecord?.resultAttributes !== undefined) {
        const recordAttribute = currentRecord?.resultAttributes?.find(
          (recordAttr) => Number(recordAttr.attributeId) === Number(attribute),
        )
        const attributeVisibility = recordAttribute?.attributeVisibility
        attributeVisibilityMap = {
          ...attributeVisibilityMap,
          [attribute]: attributeVisibility ?? AppElementAttributeVisibility.Hidden,
        }
      } else if (
        currentView &&
        (currentView.defaultAttributeVisibility || currentView.defaultAttributeVisibility === 0) &&
        currentView.appElementViewAttributes
      ) {
        const attributeVisibility = getFieldVisibility({
          attributeId: Number(attribute),
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility: currentView?.defaultAttributeVisibility,
          viewAttributes: currentView?.appElementViewAttributes,
        })
        attributeVisibilityMap = { ...attributeVisibilityMap, [attribute]: attributeVisibility }
      } else {
        attributeVisibilityMap = { ...attributeVisibilityMap, [attribute]: AppElementAttributeVisibility.AsPerRole }
      }
    })

    const datesVisible = getWhichDateIsVisible({
      endDateVisibility: attributeVisibilityMap[RecordAppElementAllAttributesConst.endDate],
      startDateVisibility: attributeVisibilityMap[RecordAppElementAllAttributesConst.startDate],
    })

    const renderArrow = () => (datesVisible === 'both' ? <ArrowRightIcon className="w-4 rtl-mirror" /> : null)
    const isCompletionDisabled =
      !isRecordEditable ||
      attributeVisibilityMap[RecordAppElementAllAttributesConst.completedIn] === AppElementAttributeVisibility.ReadOnly
    const isRecordLoading = isDetailedRecord
      ? currentRecordLoading
      : (!recordId && !isNaN(recordId)) || isLoading || isRejected || isCurrentListLoading
    return isRejected ? (
      <EmptyStatePage
        Button={NoAccessButton}
        Icon={EmptyErrorIcon}
        description={t(`noAccess:description`)}
        header={t(`noAccess:header`)}
      />
    ) : (
      <>
        {!isNil(recordId) && !isNil(currentRecord) && (
          <RecordSettings
            currentList={currentList}
            currentRecord={currentRecord}
            elementId={currentRecord?.appElements ? currentRecord?.appElements[0]?.id : null}
            handleUpdateRecord={handleUpdateRecord}
            hasSubtasks={hasSubtasks}
            id={recordId}
            isAdmin={isAdmin}
            isArchived={currentRecord?.appElements && currentRecord?.appElements[0]?.isArchived}
            isArchivedByParent={
              currentRecord?.appElements &&
              currentRecord?.appElements[0]?.archivedById &&
              +currentRecord?.appElements[0]?.archivedById !== +currentRecord?.appElements[0]?.id
            }
            isEditor={isEditor}
            name={currentRecord?.name}
            userId={user?.id}
          />
        )}
        {isDragActive && (
          <div className="absolute inset-0 z-10 p-4 bg-white/80 rounded pointer-events-none rounded-ts-none backdrop-filter backdrop-blur-sm">
            <div className="flex flex-col items-center justify-center flex-1 h-full border-2 border-dashed rounded rounded-ts-none border-primary">
              <DragDropIcon className="w-32 mb-6" />
              <h2 className="font-bold text-center text-gray-700">{t('records:drop.header')}</h2>
              <p className="mt-2 mb-6 text-sm text-center w-80">{t('records:drop.description')}</p>
            </div>
          </div>
        )}
        <div
          className="flex flex-col justify-between flex-grow h-full px-6 pb-6 overflow-auto"
          style={{ scrollBehavior: 'smooth', scrollPadding: '4rem' }}
          {...getRootProps()}
          role="none">
          {isRecordLoading ? (
            <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />
          ) : currentRecord === undefined ? (
            <EmptyStatePage
              Icon={EmptyErrorIcon}
              description={t(`noAccess:recordArchivedOrDeleted`)}
              header={t(`noAccess:header`)}
            />
          ) : (
            <>
              <div className="sticky top-0 z-10 flex py-4 text-sm bg-white md:hidden">
                <a className={linkClasses} href="#information">
                  {t('records:listing.information')}
                </a>
                <a className={linkClasses} href="#attachments">
                  {t('records:listing.attachments')}
                </a>
                <a className={linkClasses} href="#activity">
                  {t('records:listing.activity')}
                </a>
                <a className={linkClasses} href="#details">
                  {t('records:listing.settings')}
                </a>
              </div>
              <div className="md:flex overflow-x-hidden w-full">
                <div className="flex-1 min-w-0">
                  <h3 className="block mb-2 font-bold md:hidden" id="information">
                    {t('records:listing.information')}
                  </h3>
                  {attributeVisibilityMap[RecordAppElementAllAttributesConst.description] !==
                    AppElementAttributeVisibility.Hidden && (
                    <div className="mt-8">
                      <EditableMultilineWithButtons
                        currentRecord={currentRecord}
                        data-testid="description-input"
                        inputWrapperClassName="mb-0"
                        isLabelVisible
                        isOnClickDisabled={
                          !isRecordEditable ||
                          attributeVisibilityMap[RecordAppElementAllAttributesConst.description] ===
                            AppElementAttributeVisibility.ReadOnly
                        }
                        isRichTextEditor
                        label={t('common:labels.description')}
                        name="description"
                        needForApproval={needForApproval}
                        value={initialValues.description}
                        onSubmit={handleUpdateRecord}
                      />
                    </div>
                  )}
                  {!isSubTask && (
                    <SwitchLists
                      currentRecord={currentRecord}
                      elementId={currentRecord?.appElements ? currentRecord?.appElements[0]?.id : null}
                      isEditor={isRecordEditable}
                    />
                  )}
                  {currentRecord?.id && !isEmpty(currentRecord) && (
                    <>
                      <AttachmentList
                        filesBeingUploaded={filesBeingUploaded}
                        filesBeingUploadedRef={filesBeingUploadedRef}
                        inputProps={getInputProps()}
                        isEditor={isEditor}
                        recordId={recordId}
                      />
                      <ActivityForm
                        currentItem={currentRecord}
                        isRecordComment={true}
                        elementId={currentRecord.appElements ? currentRecord.appElements[0].id : null}
                        filesBeingUploaded={filesBeingUploaded}
                        filesBeingUploadedRef={filesBeingUploadedRef}
                        inputProps={getInputProps()}
                        isEditor={isRecordEditable}
                        isMakerCheckerApplied={isMakerCheckerApplied}
                        isRecord={true}
                        isRecordEditable={isRecordEditable}
                        needForApproval={needForApproval}
                        recordId={+currentRecord.id}
                      />
                    </>
                  )}
                </div>

                <div className="md:w-80 flex-0 md:ms-16">
                  <h3 className="block mt-12 mb-2 font-bold md:hidden" id="details">
                    {t('records:listing.settings')}
                  </h3>
                  <table
                    cellPadding="0"
                    className="w-full border-separate table-fixed"
                    style={{ borderSpacing: '0 1rem' }}>
                    <colgroup>
                      <col className="w-4/12" />
                      <col className="w-8/12" />
                    </colgroup>
                    <tbody className="overflow-y-auto">
                      {attributeVisibilityMap[RecordAppElementAllAttributesConst.ownerId] !==
                        AppElementAttributeVisibility.Hidden && (
                        <tr>
                          <td className="text-sm font-bold" id="owner">
                            {t('common:labels:owner')}
                          </td>
                          <td aria-labelledby="owner" data-testid="owner123">
                            <ContentEditableUserDropdown
                              autoFocus
                              className="-mt-2 -ms-2 -mb-2"
                              disabled={
                                !isRecordEditable ||
                                attributeVisibilityMap[RecordAppElementAllAttributesConst.ownerId] ===
                                  AppElementAttributeVisibility.ReadOnly
                              }
                              name="ownerId"
                              options={currentListMembers}
                              owner={recordOwner}
                              renderNotEditingState={renderSelectedUser}
                              value={initialValues.ownerId}
                              onSubmit={handleUpdateRecord}
                            />
                          </td>
                        </tr>
                      )}
                      {isMakerCheckerApplied && (
                        <tr>
                          <td className="text-sm font-bold" id="reviewer">
                            {t('common:labels:reviewers')}
                          </td>
                          <td aria-labelledby="reviewer" data-testid="reviewer">
                            <RecordReviewer
                              currentItem={currentRecord}
                              isDisabled={!isAdmin}
                              reviewers={currentRecordReviewers || []}
                            />
                          </td>
                        </tr>
                      )}

                      {datesVisible !== 'none' && (
                        <tr>
                          <td className="text-sm font-bold">
                            {datesVisible === 'both'
                              ? t('records:listing.dates')
                              : datesVisible === RecordAppElementAllAttributesConst.startDate
                              ? t('records:listing.startDate')
                              : t('records:listing.endDate')}
                          </td>
                          <td className="flex items-center justify-between flex-1 text-sm">
                            {attributeVisibilityMap[RecordAppElementAllAttributesConst.startDate] !==
                              AppElementAttributeVisibility.Hidden && (
                              <div className="w-5/12">
                                <RecordDatePicker
                                  currentItem={currentRecord}
                                  date={initialValues.startDate}
                                  dateToCompare={initialValues.endDate}
                                  handleRecordChange={handleUpdateRecord}
                                  isDisabled={
                                    !isRecordEditable ||
                                    attributeVisibilityMap[RecordAppElementAllAttributesConst.startDate] ===
                                      AppElementAttributeVisibility.ReadOnly
                                  }
                                  name="startDate"
                                  shouldShowPlaceholder
                                />
                              </div>
                            )}
                            {attributeVisibilityMap[RecordAppElementAllAttributesConst.endDate] !==
                              AppElementAttributeVisibility.Hidden && (
                              <>
                                {renderArrow()}
                                <div className="w-5/12">
                                  <RecordDatePicker
                                    currentItem={currentRecord}
                                    date={initialValues.endDate}
                                    dateToCompare={initialValues.startDate}
                                    handleRecordChange={handleUpdateRecord}
                                    isDisabled={
                                      !isRecordEditable ||
                                      attributeVisibilityMap[RecordAppElementAllAttributesConst.endDate] ===
                                        AppElementAttributeVisibility.ReadOnly
                                    }
                                    name="endDate"
                                    shouldShowPlaceholder
                                  />
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      )}

                      {attributeVisibilityMap[RecordAppElementAllAttributesConst.completedIn] !==
                        AppElementAttributeVisibility.Hidden && (
                        <tr>
                          <td className="text-sm font-bold">{t('common:labels:completedIn')}</td>
                          <td className="flex items-center">
                            <div className="relative flex items-center text-sm">
                              <ContentEditableInput
                                className="items-center w-full text-sm text-end pe-1"
                                defaultValue={0}
                                inputWrapperClassName="-ms-2 -mb-2"
                                isOnClickDisabled={isCompletionDisabled}
                                name="completedIn"
                                notEditingClassName="text-end pe-1"
                                ref={completedInRef}
                                shouldShowPlaceholder
                                type={'number'}
                                validationSchema={completedInValidationSchema}
                                value={initialValues.completedIn || completeInValue}
                                onKeyDown={(event: any) => {
                                  if (event.key === 'Enter') {
                                    completedInRef?.current?.submitField()
                                  }
                                }}
                                onSubmit={(values, actions) => {
                                  if (values.completedIn !== '') {
                                    // setCompleteInValue(values.completedIn)
                                    handleUpdateRecord({ completedIn: values.completedIn })
                                  } else {
                                    actions.resetForm()
                                  }
                                }}
                              />
                              <span className="text-sm pointer-events-none top-1 leading-4 ms-2">%</span>
                            </div>
                            {!isCompletionDisabled && (
                              <div className="ms-5">
                                <ChartIconsList
                                  onClick={(val: number) => {
                                    handleUpdateRecord({ completedIn: val })
                                  }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                      {currentRecord &&
                        attributeVisibilityMap[RecordAppElementAllAttributesConst.health] !==
                          AppElementAttributeVisibility.Hidden && (
                          <tr>
                            <td className="text-sm font-bold">{t('common:labels:health')}</td>
                            <td>
                              <div
                                className={classnames(
                                  `-ms-2 inline-flex px-2 py-1 text-sm rounded leading-6 ${
                                    currentRecord.health === 'unavailable' ? 'text-gray-600' : 'text-white'
                                  }`,
                                )}
                                style={{ backgroundColor: healthColors[currentRecord.health] }}>
                                {t(`records:health:${currentRecord?.health}`)}
                              </div>
                            </td>
                          </tr>
                        )}

                      {attributeVisibilityMap[RecordAppElementAllAttributesConst.statusId] !==
                        AppElementAttributeVisibility.Hidden && (
                        <tr>
                          <td className="text-sm font-bold">{t('records:listing.status')}</td>
                          <td>
                            <StatusesDropdown
                              handleRecordChange={handleUpdateRecord}
                              isDisabled={
                                !isRecordEditable ||
                                attributeVisibilityMap[RecordAppElementAllAttributesConst.statusId] ===
                                  AppElementAttributeVisibility.ReadOnly
                              }
                              shouldShowPlaceholder
                              statusId={currentRecord?.statusId}
                            />
                          </td>
                        </tr>
                      )}

                      {attributeVisibilityMap[RecordAppElementAllAttributesConst.priority] !==
                        AppElementAttributeVisibility.Hidden && (
                        <tr>
                          <td className="text-sm font-bold">{t('records:listing.priority')}</td>
                          <td>
                            <PriorityDropdown
                              handleRecordChange={handleUpdateRecord}
                              isDisabled={
                                !isRecordEditable ||
                                attributeVisibilityMap[RecordAppElementAllAttributesConst.priority] ===
                                  AppElementAttributeVisibility.ReadOnly
                              }
                              priority={initialValues.priority}
                              shouldShowPlaceholder
                              withNames
                            />
                          </td>
                        </tr>
                      )}

                      {attributeVisibilityMap[RecordAppElementAllAttributesConst.labelsIds] !==
                        AppElementAttributeVisibility.Hidden && (
                        <tr>
                          <td className="text-sm font-bold">{t('records:listing.labels')}</td>
                          <td>
                            {currentRecord && (
                              <LabelsSelect
                                fullRecord={currentRecord}
                                isDisabled={
                                  !isRecordEditable ||
                                  attributeVisibilityMap[RecordAppElementAllAttributesConst.labelsIds] ===
                                    AppElementAttributeVisibility.ReadOnly
                                }
                                shouldShowPlaceholder
                              />
                            )}
                          </td>
                        </tr>
                      )}

                      {currentStation?.wikiElementData && (
                        <tr>
                          <td className="text-sm font-bold">{t('records:listing:linkedDocuments')}</td>
                          <td>
                            <LinkedDocuments
                              currentRecord={currentRecord}
                              handleUpdateRecord={handleUpdateRecord}
                              isEditingAllowed={isRecordEditable}
                              isMulti={true}
                              isViewer={isViewer}
                            />
                          </td>
                        </tr>
                      )}

                      <RecordCustomFieldsList
                        currentRecord={currentRecord}
                        defaultAttributeVisibility={currentView?.defaultAttributeVisibility}
                        handleUpdateRecord={handleUpdateRecord}
                        isAdmin={isAdmin}
                        isEditor={isRecordEditable}
                        isLeafNode={isLeafNode}
                        viewAttributes={currentView?.appElementViewAttributes}
                      />

                      {isPremiumPlan && isRecordEditable && (
                        <tr className="w-full align-baseline">
                          <CreateCustomFieldButton
                            currentItem={currentList}
                            elementBaseType={AppElementBaseTypes.ListAppElement}
                            isInRecord={true}
                            showIcon={true}
                            showText={true}
                            onRefetch={onRefetch}
                          />
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="pt-3 mt-1 text-sm text-gray-400 border-t border-gray-200">
                    <div className="flex items-center mb-1">
                      <div className="me-2">{t('common:labels.creator')}:</div>
                      <div>
                        {currentRecord.reporter?.firstName} {currentRecord.reporter?.lastName}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <span className="me-2">{t('common:labels.createdAt')}:</span>
                      {currentRecord && (
                        <div dir="auto">{getFormattedDateWithTime(currentRecord.createdAt, isHijri, language)}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  },
  (prev, current) => {
    const { recordId, listId, currentView } = current
    const { recordId: prevRecordId, listId: prevListId, currentView: prevView } = prev
    return recordId !== prevRecordId && listId !== prevListId && currentView !== prevView
  },
)

AddRecordToListForm.displayName = 'AddRecordToListForm'

export default AddRecordToListForm
