import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import baseAPI from 'utils/baseAPI'
import { removeTokensFromLocalStorage } from 'utils/helpers/authHelpers'

const Logout = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const logout = () => {
    baseAPI(
      'auth/logout',
      {
        method: 'DELETE',
      },
      false,
      false,
    )
    removeTokensFromLocalStorage()
    history.push('/sign-in')
  }

  return (
    <button className="py-2 text-start hover:text-danger transition-colors" onClick={logout}>
      {t('labels.logout')}
    </button>
  )
}

export default Logout
