import React, { FC, useCallback } from 'react'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FormikHelpers, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import { SignUpFormValuesType } from 'utils/types'
import { Checkbox, Input, PasswordInput } from 'components/inputs'
import { signUpValidationSchema } from 'components/forms/validationSchemas'
import Button, { ButtonVariant, LinkButton } from 'components/Buttons'
import Loader from 'components/Loader'
import GoogleLogin from 'components/GoogleLogin'
import { configValues } from 'utils/appConfig'

type SignUpFormPropsType = {
  initialValues: SignUpFormValuesType,
  onSubmit: (values: SignUpFormValuesType, formikHelpers: FormikHelpers<SignUpFormValuesType>) => void | Promise<any>,
}

const SignUpForm: FC<SignUpFormPropsType> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation('signUp')
  const recordPriorities = i18n.t('signUp:termsAndConditions', { returnObjects: true })
  const { values, setFieldValue, handleSubmit, handleChange, isSubmitting, handleBlur, errors, touched } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: signUpValidationSchema,
  })

  const recaptchaCallback = useCallback((recaptchaToken: string | null) => {
    setFieldValue('recaptchaToken', recaptchaToken, false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      {configValues.IS_GOOGLE_RECAPTCHA_ENABLED && configValues.REACT_APP_GOOGLE_RECAPTCHA && (
        <GoogleReCaptchaProvider reCaptchaKey={configValues.REACT_APP_GOOGLE_RECAPTCHA}>
          <GoogleReCaptcha onVerify={recaptchaCallback} />
        </GoogleReCaptchaProvider>
      )}
      <div className="flex">
        <Input
          classes={{ wrapper: 'flex-1 me-6 mb-6' }}
          data-testid="first-name-input"
          error={errors.firstName}
          label={t('common:labels.firstName')}
          name="firstName"
          touched={touched.firstName}
          value={values.firstName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <Input
          classes={{ wrapper: 'flex-1 mb-6' }}
          data-testid="last-name-input"
          error={errors.lastName}
          label={t('common:labels.lastName')}
          name="lastName"
          touched={touched.lastName}
          value={values.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <Input
        data-testid="email-input"
        error={errors.email}
        label={t('common:labels.email')}
        name="email"
        touched={touched.email}
        type="email"
        value={values.email}
        onBlur={(event: any) => {
          setFieldValue('email', values.email.toLowerCase())
          handleBlur(event)
        }}
        onChange={handleChange}
      />
      <div className="flex">
        <PasswordInput
          classes={{ wrapper: 'flex-1 me-6 min-w-0 whitespace-pre-wrap' }}
          data-testid="password-input"
          error={errors.password}
          label={t('common:labels.password')}
          name="password"
          touched={touched.password}
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <PasswordInput
          classes={{ wrapper: 'flex-1 min-w-0' }}
          data-testid="confirm-password-input"
          error={errors.confirmPassword}
          label={t('common:labels.confirmPassword')}
          name="confirmPassword"
          touched={touched.confirmPassword}
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <Checkbox
        checked={values.acceptedTerms}
        classes={{ wrapper: 'mb-6' }}
        data-testid="accepted-terms-checkbox"
        error={errors.acceptedTerms}
        isLabelComponent
        label={`${recordPriorities[0]}<a class="text-primary-dark tranisiton-colors hover:text-primary ms-1" href="https://mohimma.tech/terms" rel="noreferrer" target="_blank">${recordPriorities[1]}</a>`}
        name="acceptedTerms"
        touched={touched.acceptedTerms}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Button data-testId="sign-up-button" disabled={isSubmitting} full type="submit">
        {!isSubmitting && t('signUp')}
        {isSubmitting && <Loader />}
      </Button>

      {configValues.IS_GOOGLE_LOGIN_ENABLED && <GoogleLogin isSignIn={false} />}

      <div className="mt-16 mb-4 font-bold text-gray-600">{t('alreadyHaveAccount')}</div>
      <LinkButton full to="/sign-in" variant={ButtonVariant.Outline}>
        {t('signIn:signIn')}
      </LinkButton>
    </form>
  )
}
export default SignUpForm
