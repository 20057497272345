import {
  ClockWiseIcon,
  CommentsIcon,
  DocumentFilledIcon,
  FileSearchEpmtyIcon,
  SearchNoDataIcon,
} from 'components/Icons'
import Loader from 'components/Loader'
import { useStationDocumentationSearchResults, useStationDocumentationSearchResultsLoading } from 'features/station'
import { emptyDocumentationSearchResults } from 'features/station/stationSlice'
import React, { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getFormattedDateWithLocale } from 'utils/helpers/generalHelpers'

interface SearchHighlight {
  wordIdx: number;
  strLength: number;
  isBlue: boolean;
}

const SearchWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        height: 'calc(100% - 60px)',
      }}
      className="flex flex-col items-center justify-center">
      {children}
    </div>
  )
}

const SearchMessage: FC<{
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>,
  header: string,
  text: string,
}> = ({ Icon, header, text }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        className="rounded-full flex items-center justify-center mb-6"
        style={{
          width: 120,
          height: 120,
          backgroundColor: 'rgba(0, 137, 150, 0.05)',
        }}>
        <Icon />
      </div>

      <h1 className="mb-2">{t(`documentation:${header}`)}</h1>
      <p>{t(`documentation:${text}`)}</p>
    </>
  )
}

const DocumentationSearch: FC = () => {
  const searchResults = useStationDocumentationSearchResults()
  const searchLoading = useStationDocumentationSearchResultsLoading()
  const { workspaceId, stationId } = useParams<{ stationId: string, workspaceId: string }>()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(emptyDocumentationSearchResults())
    }
  }, [])

  if (searchLoading) {
    return (
      <SearchWrapper>
        <Loader loaderClasses="w-20" />
      </SearchWrapper>
    )
  }

  if (!searchResults) {
    return (
      <SearchWrapper>
        <SearchMessage header="emptySearchHeader" text="emptySearchText" Icon={FileSearchEpmtyIcon} />
      </SearchWrapper>
    )
  }

  if (!searchResults.items || !searchResults.items.length) {
    return (
      <SearchWrapper>
        <SearchMessage header="searchNoDataHeader" text="searchNoDataText" Icon={SearchNoDataIcon} />
      </SearchWrapper>
    )
  }

  const linkTodocs = `/workspace/${workspaceId}/stations/${stationId}/documentation`

  return (
    <div className="m-10">
      {searchResults.items.map((i) => {
        return (
          <div
            onClick={() => {
              history.push(`${linkTodocs}/view/${i.id}`)
            }}
            style={{ minWidth: 200 }}
            className="p-5 bg-white h-52 rounded cursor-pointer mb-6"
            key={i.id}>
            <div className=" mb-3 flex items-center gap-4">
              <DocumentFilledIcon width={20} />
              <h2 className="font-semibold w-full truncate" style={{ color: '#000' }}>
                {i.title}
              </h2>
            </div>

            <div className="mb-6 flex items-center gap-4">
              <div className="flex gap-2 items-center">
                <div>
                  <ClockWiseIcon width={20} />
                </div>
                {i.updatedAt && <div className="text-sm">{getFormattedDateWithLocale(i.updatedAt, 'd/MM/yyyy ')}</div>}
              </div>
              <div className="flex gap-2 items-center">
                <div className="">
                  <CommentsIcon />
                </div>
                <div className="text-sm">{i.commentsCount}</div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="w-6 h-6">
                  <img
                    alt={i.author?.firstName + ' ' + i.author?.lastName}
                    className="rounded-full"
                    height={'100%'}
                    src={i.author?.link || '/assets/images/placeholder.svg'}
                    width={'100%'}
                  />
                </div>
                <p className="text-sm">{i.author?.firstName + ' ' + i.author?.lastName}</p>
              </div>
            </div>
            <div
              style={{
                overflowWrap: 'break-word',
                textOverflow: 'ellipsis',
                display: 'box',
                overflow: 'hidden',
                lineHeight: '20px',
                boxOrient: 'vertical',
                maxHeight: '56px',
                height: '42px',
                WebkitLineClamp: 3,
                lineClamp: 3,
              }}
              className="text-sm mb-4 border-t pt-6">
              {i.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: i.content,
                  }}></div>
              ) : (
                t('documentation:noDescription')
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(DocumentationSearch)
