import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TypeColumn } from '@inovua/reactdatagrid-community/types'
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'

import { useRoles } from 'hooks'
import {
  CheckboxCell,
  DateCell,
  DateEditorCell,
  DescriptionCell,
  DescriptionEditorCell,
  HealthCell,
  IdCell,
  LabelsCell,
  LabelsEditorCell,
  OwnerCell,
  OwnerEditorCell,
  PriorityCell,
  PriorityEditorCell,
  SettingsCell,
  StatusCell,
  StatusEditorCell,
} from 'components/GridView/cells'
import uniqBy from 'lodash/uniqBy'
import { useCurrentUser, useUsers, useUsersByList } from '../../features/user'
import { useCurrentListLabels, useCurrentListStatuses } from '../../features/list'
import { getHealthOptions, getPriorityOptions } from '../../utils/helpers/recordHelpers'
import Button from '../Buttons'
import { UserType, WorkspaceType } from '../../utils/types'
import { useWorkspacesList } from '../../features/workspace'

const useWorkspacesColumns = () => {
  const { isEditor } = useRoles()
  const { t } = useTranslation('records')
  const workspacesData = useWorkspacesList()
  const allUsers = workspacesData
    ?.map((w: WorkspaceType) => w.owner)
    ?.map((u: UserType) => ({ id: u.id, label: `${u.firstName} ${u.lastName}` }))
  // const statuses = useCurrentListStatuses().map((i) => ({ id: i.id, label: i.name }))
  // const priorities = getPriorityOptions().map((i) => ({ ...i, id: +i.value }))
  // const healthOptions = getHealthOptions().map((i) => ({ ...i, id: i.value }))
  // const labelsList = useCurrentListLabels().map((i) => ({ ...i, id: +i.id, label: i.name }))
  return useMemo<TypeColumn[]>(
    () => [
      { defaultFlex: 1, header: t('id'), headerAlign: 'center', name: 'id', type: 'string' },
      {
        defaultFlex: 2,
        header: 'Workspace title',
        headerAlign: 'center',
        name: 'name',
      },
      {
        defaultFlex: 1,
        editor: OwnerEditorCell,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: uniqBy(allUsers, 'id'),
          placeholder: t('records:listing:all'),
        },
        header: 'Owner',
        headerAlign: 'center',
        name: 'ownerId',
        render: (props) => <OwnerCell {...props} />,
        sortable: true,
      },
      {
        defaultFlex: 1,
        header: 'CreatedAt',
        headerAlign: 'center',
        name: 'createdAt',
      },
      // {
      //   defaultFlex: 2,
      //   filterEditor: SelectFilter,
      //   filterEditorProps: {
      //     dataSource: [
      //       { id: 0, label: t('subscription:basic') },
      //       { id: 1, label: t('subscription:pro') },
      //     ],
      //     placeholder: t('records:listing:all'),
      //   },
      //   header: 'Type',
      //   headerAlign: 'center',
      //   name: 'planId2222',
      //   render: (props) => <WorkspaceTypeIcon {...props} />,
      // },
      // {
      //   className: '-settings',
      //   defaultFlex: 1,
      //   editable: false,
      //   header: '',
      //   headerAlign: 'center',
      //   name: 'settings',
      //   render: ({ data }) => (
      //     <div className="flex justify-center w-full">
      //       <Button
      //         className={'w-1/2 flex justify-center'}
      //         small
      //         onClick={(e) => {
      //           onChangeWorkspacePlan(data)
      //         }}>
      //         {+data.planId2222 === 0 ? t('admin:workspace:upgrade') : t('admin:workspace:downgrade')}
      //       </Button>
      //     </div>
      //   ),
      // },

      // {
      //   defaultFlex: 1,
      //   header: 'Tree View',
      //   headerAlign: 'center',
      //   name: 'actions',
      //   render: () => (
      //     <div
      //       className="flex justify-center w-full"
      //       onClick={() => {
      //         history.push('/workspaces/1/tree-details')
      //       }}>
      //       {/*<TreeIcon className="w-4 cursor-pointer hover:text-primary" />*/}
      //     </div>
      //   ),
      // },
      // {
      //   defaultFlex: 1,
      //   header: '',
      //   headerAlign: 'center',
      //   name: 'actions',
      //   render: (props) => (
      //     <div className="flex justify-center w-full">
      //       <ManageMenu {...props} showUsers={true} />
      //     </div>
      //   ),
      // },
    ],
    [t, isEditor],
  )
}

export default useWorkspacesColumns
