import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import SelectParentRow from '../../Dashboard/Widgets/CustomWidgets/ConfiguringWidget/SelectParentRow'
import { customFieldAggregationOperation } from '../../../utils/constant/constant/customFields'
import { recordAppElementDefaultAttributes } from '../../../utils/constant/enums/Aggregate'
import { CustomFieldsListMessage, CustomFieldType } from '../../../utils/types/CustomFieldsType'
import { ListType, StationType, WorkspaceType } from '../../../utils/types'
import { customFieldBaseTypes } from '../../../utils/constant/enums/customFields'
import AppElementAttributeType from '../../../utils/types/AppElementAttributeType'

interface CustomFieldAggregationProps {
  aggregateCustomField: CustomFieldType;
  setAggregateCustomField: (value: number) => void;
  customFieldsObject: CustomFieldsListMessage;
  currentItem: WorkspaceType | StationType | ListType;
  isDisabled: boolean;
}

const CustomFieldAggregation: FC<CustomFieldAggregationProps> = ({
  setAggregateCustomField,
  aggregateCustomField,
  customFieldsObject,
  currentItem,
  isDisabled,
}) => {
  const { t } = useTranslation('customFields')
  const [field, setField] = useState<any>(null)
  const [operation, setOperation] = useState<any>(null)

  const customFieldsList = Object.keys(customFieldsObject).length === 0 ? [] : [...customFieldsObject?.native?.fields]

  const defaultOptions = [
    {
      label: t('validation:fieldNames.completedIn'),
      targetAttributeType: AppElementAttributeType.defaultAttribute,
      value: recordAppElementDefaultAttributes.completedIn,
    },
  ]
  const fieldsOptions = customFieldsList
    .filter((f) => f.baseType === customFieldBaseTypes.Number || f.baseType === customFieldBaseTypes.Formula)
    .map((item) => ({
      ...item,
      label: item.fieldName,
      targetAttributeType: AppElementAttributeType.customField,
      value: +item.id,
    }))

  const options = [...defaultOptions, ...fieldsOptions]

  useEffect(() => {
    if (isDisabled) {
      const selectedFieldOperation = customFieldAggregationOperation.find(
        (op) => op.value === aggregateCustomField?.aggregationMethod,
      )
      const selectedField = options.find((op) => +op.value === +aggregateCustomField.relatedAttributeId)
      setOperation(selectedFieldOperation)
      setField(selectedField)
    }
  }, [isDisabled])

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <p className="mt-3 text-sm font-bold">{t('selectField')}</p>
      <CreatableSelect
        className="border-none"
        classNamePrefix="custom-field-select"
        components={{
          Option: SelectParentRow,
        }}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name="customFieldType"
        options={options}
        placeholder={t('customFields:select')}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={field}
        onChange={(values: any) => {
          setField(values)
          setAggregateCustomField({
            ...aggregateCustomField,
            replacementAttributeId: +values.value,
            replacementAttributeType: values.targetAttributeType,
            targetAttributeId: +values.value,
            targetAttributeType: values.targetAttributeType,
          })
        }}
      />
      <p className="mt-3 text-sm font-bold">{t('selectOperation')}</p>
      <CreatableSelect
        className="mt-1 border-none"
        classNamePrefix="custom-field-select"
        components={{
          Option: SelectParentRow,
        }}
        isDisabled={!field || isDisabled}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name="customFieldType"
        options={customFieldAggregationOperation}
        placeholder={t('customFields:select')}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={operation}
        onChange={(values: any) => {
          setOperation(values)
          setAggregateCustomField({ ...aggregateCustomField, aggregationMethod: values.value })
        }}
      />
    </div>
  )
}

export default CustomFieldAggregation
