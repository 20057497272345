import React, { FC } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { createElementLink, getElementLinkedRecords } from '../../../../features/element'
import { useAppDispatch } from '../../../../store'
import AddLinkForm from './AddLinkForm'

type EditCustomFieldModalPropsType = {
  onCancel: () => void,
  isModalOpen: boolean,
  stationId: string | number,
  documentId: string | number,
  linkedRecords: any[],
}

const AddLinkModal: FC<EditCustomFieldModalPropsType> = ({ onCancel, isModalOpen, stationId, documentId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onSubmit = (recordsElements: any[]) => {
    const body = {
      description: 'linked item',
      elementId: +documentId,
      isLinkedAsChild: false,
    }
    recordsElements.map((record, index) => {
      body.linkedElementId = +record.id
      dispatch(createElementLink({ body, force: true })).then(() => {
        if (index === recordsElements.length - 1) {
          dispatch(getElementLinkedRecords(+documentId))
          onCancel()
        }
      })
    })
  }
  const addLinkProps = {
    elementBaseType: 3,
    level: 2,
    lowestLevel: 2,
    onClose: onCancel,
    onSubmit,
    parentElementId: +stationId,
  }
  return (
    <Modal
      className="fixed top-0 bottom-0 flex w-full bg-white border-white rounded shadow left-2/4 transform -translate-x-2/4 focus:outline-none md:max-w-lg md:top-20 md:bottom-20"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onCancel}>
      <AddLinkForm {...addLinkProps} />
    </Modal>
  )
}

export default AddLinkModal
