import { forEach, last } from 'lodash'
import { WorkspaceUserResponseType } from 'utils/types'
import UsersTablePropsType from 'utils/types/UsersTablePropsType'

export default class CustomAbortController {
  newestAbortController: AbortController
  newestAbortControllerId: number
  allAbortControllers: { [key: number]: { controller: AbortController, type?: string } }
  constructor() {
    this.newestAbortController = new AbortController()
    this.newestAbortControllerId = 0
    this.allAbortControllers = {}
  }

  createNewAbortController(typeOfRequest?: string): void {
    this.newestAbortController = new AbortController()
    const lastAbortControllerId = last(Object.keys(this.allAbortControllers)) || 0
    this.newestAbortControllerId = +lastAbortControllerId + 1
    this.addAbortControllerToList(typeOfRequest)
  }

  addAbortControllerToList(typeOfRequest?: string): void {
    this.allAbortControllers[this.newestAbortControllerId] = {
      controller: this.newestAbortController,
      type: typeOfRequest,
    }
  }

  removeAbortControllerById({ id, isSuccess }: { id: number, isSuccess: boolean }): void {
    if (!isSuccess) {
      this.allAbortControllers[id].controller.abort()
    }
    delete this.allAbortControllers[id]
  }
  abortAllControllers(): void {
    forEach(this.allAbortControllers, (singleController) => singleController.controller.abort())
  }

  abortAllControllersByType(typeOfRequest: string): void {
    forEach(this.allAbortControllers, ({ type, controller }) => {
      if (typeOfRequest === type) controller.abort()
    })
  }
}

export const mapResponseToCorrectDataFormat = (body: any[], ownerId: number | undefined): UsersTablePropsType[] =>
  body?.map(
    ({
      firstName,
      lastName,
      link,
      title = 'Developer',
      workspaceMember,
      id,
      email,
      stationMember,
      listMember,
    }: WorkspaceUserResponseType) => ({
      avatar: link,
      email,
      hasAccount: !!firstName,
      id,
      isOwner: id === ownerId,
      ListAccess: listMember,
      name: firstName ? `${firstName} ${lastName}` : '',
      StationAccess: stationMember,
      title,
      WorkspaceMember: workspaceMember,
    }),
  )
