import { isNil } from 'lodash'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { configValues } from 'utils/appConfig'
import { UserType, WorkspaceType } from 'utils/types'
import { DashboardHomeWidget } from 'utils/types/DashboardWidgetType'
import StateType, { DashboardAvailableWidgets, DashboardStateType } from 'utils/types/states'
import PlansEnum from 'utils/constant/enums/subscription'

const selectWorkspaces = (state: StateType) => state.workspace.workspaces
const selectCurrentWorkspaceId = (state: StateType) => state.workspace.currentWorkspaceId
const selectWorkspaceLoading = (state: StateType) => state.workspace.loading
const selectCurrentWorkspaceLoading = (state: StateType) => state.workspace.currentWorkspaceloading
const selectWorkspaceDashboard = (state: StateType) => state.workspace.dashboard
const selectHomeDashboard = (state: StateType) => state.workspace.homeDashboard
const selectIsHomeDashboardLoading = (state: StateType) => state.workspace.isHomeDashboardLoading
const selectMyTasks = (state: StateType) => state.workspace.myTasks
const selectIsMyTasksLoading = (state: StateType) => state.workspace.isMyTasksLoading
const selectWorkspaceDashboardAvailableWidgets = (state: StateType) => state.workspace.availableDashboardWidgets
const selectWorkspaceAllDashboards = (state: StateType) => state.workspace.dashboards
const selectWorkspaceAllDashboardsLoading = (state: StateType) => state.workspace.dashboardsLoading
const selectWorkspaceDashboardLoading = (state: StateType) => state.workspace.dashboardLoading
const selectCurrentWorkspaceChosenPlan = (state: StateType) => state.workspace.chosenPlan
const selectCurrentDashboardId = (state: StateType) => state.workspace.currentDashboardId
const selectCurrentWorkspaceChosenPlanIsBasic = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    const currentWorkspace = workspaces.find(({ id }) => id == currentWorkspaceId)
    return +currentWorkspace?.plan?.plan === PlansEnum.Basic
  },
)

const selectCurrentWorkspace = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)
  },
)

const selectCurrentWorkspaceName = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)?.name
  },
)
const selectCurrentWorkspaceIsArchived = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)?.appElement?.isArchived
  },
)
const selectCurrentWorkspaceAppElementId = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)?.appElement?.id
  },
)
const selectCurrentWorkspaceOwnerId = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)?.ownerId
  },
)
const selectCurrentWorkspaceCreatedAt = createSelector(
  [selectWorkspaces, selectCurrentWorkspaceId],
  (workspaces, currentWorkspaceId) => {
    return workspaces?.find(({ id }) => id == currentWorkspaceId)?.createdAt
  },
)
const selectCurrentWorkspaceMembers = createSelector(
  [selectCurrentWorkspace],
  (currentWorkspace) => currentWorkspace?.users || [],
)

const selectCurrentWorkspacePlan = createSelector([selectCurrentWorkspace], (currentWorkspace) => {
  if (!configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED) {
    return true
  }

  if (currentWorkspace === undefined || isNil(currentWorkspace?.plan?.plan)) return false

  return +currentWorkspace?.plan?.plan !== PlansEnum.Basic
})

const selectCurrentWorkspaceTeamsPlan = createSelector([selectCurrentWorkspace], (currentWorkspace) => {
  if (!configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED) {
    return true
  }

  if (currentWorkspace === undefined || isNil(currentWorkspace?.plan?.plan)) return false

  return +currentWorkspace?.plan?.plan === PlansEnum.Teams
})

export const useWorkspacesList: () => WorkspaceType[] = () => useSelector(selectWorkspaces)
export const useWorkspacesLoading: () => boolean = () => useSelector(selectWorkspaceLoading)
export const useCurrentWorkspaceLoading: () => boolean = () => useSelector(selectCurrentWorkspaceLoading)
export const useCurrentWorkspace: () => WorkspaceType | undefined = () => useSelector(selectCurrentWorkspace)
export const useCurrentWorkspaceIsArchived: () => boolean | undefined = () =>
  useSelector(selectCurrentWorkspaceIsArchived)
export const useCurrentWorkspaceName: () => string | undefined = () => useSelector(selectCurrentWorkspaceName)
export const useCurrentWorkspaceAppElementId: () => string | undefined = () =>
  useSelector(selectCurrentWorkspaceAppElementId)
export const useCurrentWorkspaceOwnerId: () => string | undefined = () => useSelector(selectCurrentWorkspaceOwnerId)
export const useCurrentWorkspaceCreatedAt: () => string | undefined = () => useSelector(selectCurrentWorkspaceCreatedAt)
export const useWorkspaceMembers: () => UserType[] = () => useSelector(selectCurrentWorkspaceMembers)
export const useHasCurrentWorkspacePremiumPlan = (): boolean => useSelector(selectCurrentWorkspacePlan)
export const useHasCurrentWorkspaceTeamsPlan = (): boolean => useSelector(selectCurrentWorkspaceTeamsPlan)
export const useWorkspaceDashboard: () => DashboardStateType | null = () => useSelector(selectWorkspaceDashboard)
export const useHomeDashboard: () => DashboardStateType | null = () => useSelector(selectHomeDashboard)
export const useMyTasksWidget: () => DashboardHomeWidget | null = () => useSelector(selectMyTasks)
export const useIsHomeDashboardLoading: () => boolean = () => useSelector(selectIsHomeDashboardLoading)
export const useIsMyTasksLoading: () => boolean = () => useSelector(selectIsMyTasksLoading)
export const useWorkspaceDashboardAvailableWidgets: () => DashboardAvailableWidgets[] | null = () =>
  useSelector(selectWorkspaceDashboardAvailableWidgets)
export const useWorkspaceAllDashboards: () => DashboardStateType[] | null = () =>
  useSelector(selectWorkspaceAllDashboards)
export const useWorkspaceAllDashboardsLoading: () => boolean = () => useSelector(selectWorkspaceAllDashboardsLoading)
export const useWorkspaceDashboardLoading: () => boolean = () => useSelector(selectWorkspaceDashboardLoading)
export const useCurrentWorkspaceChosenPlan: () => boolean = () => useSelector(selectCurrentWorkspaceChosenPlan)
export const useCurrentDashboardId: () => boolean = () => useSelector(selectCurrentDashboardId)
export const useCurrentWorkspaceChosenPlanIsBasic: () => boolean = () =>
  useSelector(selectCurrentWorkspaceChosenPlanIsBasic)
