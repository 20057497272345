import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { switchLanguage } from 'i18n'
import Logout from 'components/Logout'
import Button, { ButtonVariant } from 'components/Buttons'
import SettingsDropdown from 'components/SettingsDropdown'
import { MohimmatechLogo, CustomIcon, CrossSignIcon, TranslateIcon, UsersIcon } from 'components/Icons'

import { useUserAvatar } from 'features/user'
import { configValues } from 'utils/appConfig'
import { NavbarContextValueType } from 'utils/types'

type NavbarPropsType = {
  setSideNavOpen: NavbarContextValueType['toggleMenu'],
  isSideNavOpen: boolean,
  onChangePage?: () => void,
}

const AdminNavbar: FC<NavbarPropsType> = ({ setSideNavOpen, isSideNavOpen, onChangePage }) => {
  const avatar = useUserAvatar()
  const { t, i18n } = useTranslation('navbarRoutes')

  const UserAvatar = () => {
    return (
      <img
        alt="user avatar"
        className="w-10 h-10 border-2 border-gray-200 rounded-full transition transition-shadow hover:shadow"
        loading="lazy"
        src={avatar || '/assets/images/placeholder.svg'}
      />
    )
  }

  const DropdownContent = ({ closeDropdown }: { closeDropdown?: () => void }) => (
    <div className="flex flex-col text-gray-600">
      <button
        className="flex items-center py-2 text-start transition-colors hover:text-primary"
        onClick={() => {
          switchLanguage()
          if (closeDropdown) closeDropdown()
        }}>
        <TranslateIcon className="w-6 -my-1 rounded-sm me-2" />
        {i18n.language === 'ar' ? t('switchToEnglish') : t('switchToArabic')}
      </button>
      <hr className="my-2" />
      <NavLink
        className="py-2 transition-colors hover:text-primary"
        to="/profile"
        onClick={() => {
          onChangePage('')
          setSideNavOpen(false)
          if (closeDropdown) closeDropdown()
        }}>
        {t('editProfile')}
      </NavLink>
      <Logout />
    </div>
  )
  const buttonBox = 'flex justify-center p-1 text-sm border rounded h-8 w-8 hover:border-primary active:border-primary'
  return (
    <nav
      className={classNames(
        'bg-white flex flex-col fixed z-40 h-screen shadow-lg items-center px-4 pt-4 pb-6 row-span-2 lg:shadow-none lg:flex lg:static lg:border-e-2 lg:border-gray-200',
        {
          hidden: !isSideNavOpen,
        },
      )}
      data-tut="workspace__sidebar">
      <Button
        className="mb-6 lg:hidden"
        icon={CrossSignIcon}
        variant={ButtonVariant.Icon}
        onClick={() => setSideNavOpen(false)}
      />
      <NavLink className="w-10 mb-8" to="/" onClick={() => setSideNavOpen(false)}>
        {configValues.REACT_APP_IS_CUSTOM_BRANDING ? (
          <CustomIcon style={{ ...configValues.navbarIconClasses }} />
        ) : (
          <MohimmatechLogo className="text-primary" />
        )}
      </NavLink>
      <div className="flex flex-col items-center">
        <NavLink
          activeClassName="bg-tertiary-light/10 text-primary"
          className="flex items-center p-2 rounded transition-colors hover:text-primary"
          exact
          to={`/admin/users/view`}
          onClick={() => {
            onChangePage('users')
            setSideNavOpen(false)
          }}>
          <UsersIcon className="w-6" />
        </NavLink>
        <NavLink
          activeClassName="bg-tertiary-light/10 text-primary border-primary"
          className="flex items-center p-2 border-gray-300 rounded transition-colors hover:text-primary"
          exact
          to={`/admin/workspaces/view`}
          onClick={() => {
            onChangePage('workspaces')
            setSideNavOpen(false)
          }}>
          <div className={buttonBox}>WS</div>
        </NavLink>
        <NavLink
          activeClassName="bg-tertiary-light/10 text-primary border-primary"
          className="flex items-center p-2 border-gray-300 rounded transition-colors hover:text-primary"
          exact
          to={`/admin/customFields/settings`}
          onClick={() => {
            onChangePage('customFields')
            setSideNavOpen(false)
          }}>
          <div className={buttonBox}>CF</div>
        </NavLink>
      </div>

      <div className="flex flex-col items-center mt-auto">
        <SettingsDropdown ButtonChildren={UserAvatar} childClasses="w-44 rounded-bs-none" toTop wrapperClasses="flex">
          <DropdownContent />
        </SettingsDropdown>
      </div>
    </nav>
  )
}

export default AdminNavbar
