import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getElementTree, useCurrentElementTree, useCurrentElementTreeLoading } from 'features/element'
import { useAppDispatch } from 'store'
import { clearElementTree } from 'features/element/elementSlice'
import Loader from 'components/Loader'
import { Radio } from 'components/inputs'
import { useCurrentList } from 'features/list'
import { AppElementBaseTypes } from 'utils/types/AppElementType'

interface CustomFieldFormProps {
  stationId: number | string;
  selectedList?: any;
  setSelectedElements: (value: any) => void;
  selectedElements: any;
  elementBaseType: number;
}

const LinkCustomFieldForm: FC<CustomFieldFormProps> = ({
  stationId,
  selectedList,
  setSelectedElements,
  selectedElements,
  elementBaseType,
}) => {
  const { t, i18n } = useTranslation('customFields')
  const currentListElement = useCurrentList()
  const currentLists = useCurrentElementTree()
  const currentListLoading = useCurrentElementTreeLoading()
  const [togglePreview, setTogglePreview] = useState<boolean>(false)
  const [currentLevel, setCurrentLevel] = useState<number>(2)
  const dispatch = useAppDispatch()
  const isRtl = i18n.language === 'ar'

  useEffect(() => {
    if (stationId !== undefined) dispatch(getElementTree({ documentFlag: false, elementId: stationId }))
    return () => {
      dispatch(clearElementTree())
    }
  }, [stationId])

  useEffect(() => {
    const isListSelected = selectedElements.find((item: any) => +item.basedType === AppElementBaseTypes.ListAppElement)
    if (isListSelected) setTogglePreview(true)
  }, [selectedElements])

  const onClickItem = (item: any) => {
    setCurrentLevel(item.basedType + 1)
    setSelectedElements([...selectedElements, item])
  }

  const renderElementsList = () => {
    const lists = currentLists.filter((item: any) => item.basedType === AppElementBaseTypes.ListAppElement)

    return (
      <div className="flex flex-col flex-1 min-h-0 overflow-y-auto shadow-inner-bottom-xs">
        {currentListLoading && (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        )}
        <div>
          <div className="mb-2 text-sm font-bold">
            {currentLevel === 1 ? t('selectOneStation') : t('selectOneList')}
          </div>
          {lists.length === 0 && !currentListLoading && (
            <div className="flex items-center justify-center pt-20 text-sm opacity-50">
              {t('documentation:emptyChildren')}
            </div>
          )}
          <div>
            {lists
              ?.filter((list: any) => +list?.element?.id != currentListElement?.appElement?.id)
              .map((item: any, index: number) => {
                return (
                  <div
                    key={`${index}${isRtl}`}
                    className="flex items-center justify-between w-full p-2 rounded cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      onClickItem(item)
                    }}>
                    <div className="flex">
                      {item.basedType === 2 && (
                        <Radio
                          checked={selectedElements.indexOf(item) > -1}
                          name={`${item.id}-${item.title}`}
                          onChange={(e) => {
                            e.stopPropagation()
                            onClickItem(item)
                          }}
                        />
                      )}
                      <p className="text-sm">{item.title}</p>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }

  const renderPreview = () => {
    return (
      <div>
        <div className={`${selectedList && 'opacity-50'}`}>
          <p className="text-sm font-bold">{t('selectedList')}</p>
          <p className="mt-3">
            {selectedList?.elementName || selectedElements.find((item: any) => +item.basedType === 2)?.title}
          </p>
        </div>
        {!selectedList && (
          <div
            className="flex mt-1 text-sm cursor-pointer text-primary"
            onClick={() => {
              setSelectedElements(selectedElements.filter((el: any) => el.basedType !== 2))
              setTogglePreview(false)
            }}>
            {t('changeList')}
          </div>
        )}
      </div>
    )
  }
  return (
    <>
      {(togglePreview || selectedList) && renderPreview()}
      {!togglePreview && !selectedList && renderElementsList()}
    </>
  )
}

export default LinkCustomFieldForm
