import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../features/element'
import { ActivityActions } from '../../utils/constant/enums/notifications'
import { ChangeRequestActionsList } from '../../utils/constant/constant/common'
import NotificationItem from './NotificationItem'

const NotificationsForm: FC = () => {
  const { t } = useTranslation('common')
  const notifications = useNotifications()
  const isMobile = window.innerWidth <= 640
  const sortedNotifications = [...notifications]
    .filter((notification) => ChangeRequestActionsList.indexOf(notification.activity.action) === -1)
    .sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))

  return (
    <div className="relative w-full p-2" style={{ width: isMobile ? '' : 450 }}>
      {notifications?.length === 0 && (
        <div className="flex justify-center mt-32 opacity-50">{t('notifications.noUnreadNotifications')}</div>
      )}
      {sortedNotifications?.map((item) => (
        <NotificationItem key={item} notification={item} />
      ))}
    </div>
  )
}

export default NotificationsForm
