import React, { FC, memo, useEffect, useState } from 'react'
import { useSize } from 'react-use'
import { DashboardStateWidgetType } from 'utils/types/states'
import DashboardWidgetType from '../../../../../utils/types/DashboardWidgetType'
import WidgetImage from '../../../WidgetImage'
import CustomScroll from '../../../../CustomScroll'
import { useAppDispatch } from '../../../../../store'
import Loader from '../../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../../features/element'

const BigNumbersWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const { data } = widget || widgetInfo
  const [sized] = useSize(({ width }) => <div />)
  const [isLoading, setIsLoading] = useState(true)
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  const renderNumber = () => (
    <div className="flex items-center justify-center h-full p-2 text-center">
      <div>
        <p className="mb-2 font-extrabold" style={{ fontSize: 50 }}>
          {data?.values?.sum || 0}
        </p>
      </div>
    </div>
  )
  return (
    <>
      {sized}
      {isDataLoading ? (
        <Loader />
      ) : (
        <>
          {!widgetInfo?.background && widgetInfo?.appElementAsset?.id ? (
            <CustomScroll maxHeight={'100%'} hideScroll={isPrinting}>
              <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
              {renderNumber()}
            </CustomScroll>
          ) : (
            renderNumber()
          )}
        </>
      )}
    </>
  )
}

export default memo(BigNumbersWidget)
