import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash/groupBy'
import { NavLink } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'

import DashboardStateWidgetType, {
  MyTasksWidgetType,
  dueRecordsType,
} from 'utils/types/states/DashboardStateWidgetType'
import { getPriorityIconByValue } from 'utils/helpers/recordHelpers'
import ProgressBar from 'components/ProgressBar'
import { getDateFormat, getUserPermissionFromAccessGroup } from 'utils/helpers/generalHelpers'
import { useCurrentUser } from 'features/user'
import Select from 'react-select'
import CustomScroll from '../../../CustomScroll'
import { FilterIcon } from '../../../Icons'
import UserAvatarStatus from '../../../UserAvatar/UserAvatarStatus'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import WidgetImage from '../../WidgetImage'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import { fetchRecordById } from '../../../../features/record'
import { updateCurrentListDetails } from '../../../../features/list/listSlice'
import { AddRecordToListForm } from '../../../forms'
import { usePanel } from '../../../../contexts/PanelContext'
import userType from '../../../../utils/types/UserType'
import { manageDueSoonAndOverdueRecords } from '../../../../utils/helpers/dashboardHelpers'
import { refetchAfterUpdate } from '../../../../features/element/elementSlice'

const MyTasks: FC<{
  widgetInfo: DashboardStateWidgetType,
  isAdmin?: boolean,
  isEditor?: boolean,
  isSideBar?: boolean,
  isEdit: boolean,
  isCustomListOfRecords?: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  refetchFunction?: () => void,
  isPrinting: boolean,
  showAll?: boolean,
  setIsAllRecords?: (value: boolean) => void,
  isAllRecords: boolean,
  hasFilter: boolean,
}> = ({
  widgetInfo,
  isAdmin = false,
  isEditor = false,
  isSideBar = false,
  isEdit,
  onUpdateWidgetImage,
  isCustomListOfRecords,
  isPrinting,
  refetchFunction,
  showAll,
  setIsAllRecords,
  isAllRecords = false,
  hasFilter = false,
}) => {
  const { t, i18n } = useTranslation('dashboard')
  const dispatch = useAppDispatch()
  const isRtl = i18n.language === 'ar'
  const user = useCurrentUser()
  const { location, push } = useHistory()
  const { search } = useLocation()
  const shouldGroupByStation = location.pathname.includes('workspace')
  const isHijri = user?.isHijri !== null ? user?.isHijri : false
  const widget = useWidgetById(widgetInfo?.id)
  const { data }: any = widget || widgetInfo
  const [isLoading, setIsLoading] = useState(true)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [showAllRecords, setShowAllRecords] = useState(isAllRecords)
  const { setPanelContent, openPanel } = usePanel()
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  const adminWidgetFilter = [
    { label: t('homeDashboard:all'), value: 1 },
    { label: t('homeDashboard:myTasks'), value: 2 },
  ]
  const [showOverdue, setShowOverdue] = useState<boolean>(!isAllRecords)
  const [showDueSoon, setShowDueSoon] = useState<boolean>(false)
  const [adminFilter, setAdminFilter] = useState<any>(adminWidgetFilter[0])

  useEffect(() => {
    const recordInfo = location?.search?.split('Id=')
    if (recordInfo.length > 1) {
      setSelectedRecordId(recordInfo[1])
    }
    if (!isSideBar && widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  useEffect(() => {
    const urlRecordId = search.split('=')
    if (urlRecordId[1] && Number(selectedRecordId) !== Number(urlRecordId[1])) {
      setSelectedRecordId(+urlRecordId[1])
    }
  }, [search])

  useEffect(() => {
    if (selectedRecordId) {
      dispatch(fetchRecordById({ details: true, id: selectedRecordId })).then((res) => {
        if (res.payload) {
          dispatch(updateCurrentListDetails(res.payload.list))
          const currentRecord = res?.payload
          const currentUser = res?.payload?.list?.listMembers?.find(
            (userData: userType) => Number(userData.userId) === Number(user.id),
          )
          const isEditorUser = getUserPermissionFromAccessGroup(currentUser?.accessGroupId, 'Editor')
          const isAdminUser = getUserPermissionFromAccessGroup(currentUser?.accessGroupId, 'Admin')
          setPanelContent({
            content: (
              <AddRecordToListForm
                isAdmin={isAdminUser}
                isDetailedRecord={true}
                isEditor={isEditorUser}
                listId={+currentRecord?.listId}
                partialRecord={currentRecord}
                recordId={+currentRecord.id}
              />
            ),
            header: currentRecord.name,
            isBig: true,
            isRecordEditable:
              getUserPermissionFromAccessGroup(currentUser?.accessGroupId) &&
              !currentRecord?.appElements[0]?.isArchived,
            partialRecord: currentRecord,
          })
          openPanel()
          if (refetchFunction) {
            dispatch(refetchAfterUpdate(refetchFunction))
          } else {
            dispatch(refetchAfterUpdate(() => getWidgetData(+widgetInfo.id)))
          }
          setSelectedRecordId(null)
        }
      })
    }
  }, [selectedRecordId, location?.search?.includes('?recordId='), search])

  const tasks: MyTasksWidgetType = showAll ? widgetInfo : data?.values

  const myTasksData: MyTasksWidgetType = useMemo(() => {
    if (showAll || isAllRecords || hasFilter) {
      return manageDueSoonAndOverdueRecords(widgetInfo)
    }
    if (adminFilter.value === 2) {
      const overdueList = data?.overdue?.filter((task) => +task.ownerId === +user.id) || []
      const dueSoonList = data?.dueSoon?.filter((task) => +task.ownerId === +user.id) || []
      return { dueSoon: dueSoonList, overdue: overdueList, all: [] }
    } else {
      return data
    }
  }, [adminFilter, widget, tasks, isAllRecords, hasFilter])

  if (isDataLoading) {
    return <Loader />
  }

  const showingTasks = isCustomListOfRecords
    ? tasks
    : showAllRecords
    ? myTasksData?.all
    : showOverdue
    ? myTasksData?.overdue
    : myTasksData?.dueSoon

  const widgetContentHeight = isCustomListOfRecords ? 'calc(100% - 36px)' : 'calc(100% - 110px)'

  const getButtonClass = (isActive: boolean) => {
    const activeButtonClass = 'text-white bg-gray-600 me-2 hover:bg-gray-700'
    return `px-2 py-1 text-sm font-bold rounded-sm transition-colors ${
      isActive ? activeButtonClass : 'bg-gray-200 me-2 hover:bg-gray-300'
    }`
  }

  const prepareAndDisplayRecords = (records: dueRecordsType[]) => {
    if (records?.length === 0)
      return <div className="flex items-center justify-center w-full mt-5 opacity-50">{t('notHaveData')}</div>
    if (isSideBar || (isAdmin && !isCustomListOfRecords)) {
      if (shouldGroupByStation) {
        const groupedByStationId = groupBy(records, (record) => record.stationId)
        return Object.keys(groupedByStationId).map((stationId) => {
          const recordsToDisplay = groupedByStationId[stationId]

          return (
            <div key={stationId}>
              <NavLink
                className="sticky top-0 z-20 block pb-2 mb-2 text-lg font-bold text-gray-800 bg-white shadow-bottom-sm hover:text-primary"
                to={`/workspace/${groupedByStationId[stationId][0]?.workspaceId}/stations/${stationId}/dashboard`}>
                {groupedByStationId[stationId][0]?.stationName}
              </NavLink>
              {displayRecords(recordsToDisplay)}
            </div>
          )
        })
      } else {
        const groupedByWorkspaceId = groupBy(records, (record) => record.workspaceId)
        return Object.keys(groupedByWorkspaceId).map((workspaceId) => {
          const recordsToDisplay = groupedByWorkspaceId[workspaceId]
          return (
            <div key={workspaceId}>
              <NavLink
                className="sticky top-0 z-20 block pb-2 mb-2 text-lg font-bold text-gray-800 bg-white shadow-bottom-sm hover:text-primary"
                to={`/workspace/${workspaceId}/dashboard`}>
                {groupedByWorkspaceId[workspaceId][0]?.workspaceName}
              </NavLink>
              {displayRecords(recordsToDisplay)}
            </div>
          )
        })
      }
    } else {
      return displayRecords(records)
    }
  }

  const displayRecords = (records: dueRecordsType[]) => {
    return records.map((record) => {
      const { Icon: PriorityIcon, color } = getPriorityIconByValue(record.recordPriority)
      return (
        <div key={record.recordId || record?.id}>
          <div
            style={{ color: 'inherit' }}
            onClick={() => {
              push(`?recordId=${record.recordId}`)
              setSelectedRecordId(record.recordId)
            }}>
            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex items-center justify-center w-8 h-8 text-gray-600 bg-gray-300 rounded-full">
                <PriorityIcon className="w-5 h-5" style={{ color }} />
              </div>
              <div className="flex-1">
                <div className="text-gray-800 cursor-pointer">{record.recordName || record.name}</div>
                <div>
                  <ProgressBar
                    barClass="h-1"
                    completedIn={record.recordCompletion || record?.completion}
                    containerClass="w-1/2 my-1.5"
                    health={record.recordHealth || record.health}
                    id={record.recordId || record.id}
                    textClass="text-sm"
                  />
                </div>
              </div>
              <div className="relative">
                <div className="text-sm py-0.5">
                  {record.recordStartDate || record.startDate
                    ? getDateFormat(isRtl, record.recordStartDate || record.startDate, isHijri)
                    : t('noStartDate')}
                </div>
                <div className="text-sm py-0.5">
                  {record.recordEndDate || record.endDate
                    ? getDateFormat(isRtl, record.recordEndDate || record.endDate, isHijri)
                    : t('noStartDate')}
                </div>
                {isAdmin && (
                  <div className={`absolute ${isRtl ? 'left-0' : 'right-0'}`}>
                    <UserAvatarStatus
                      imgSrc={record?.ownerAvatar}
                      overrideCheckingUser
                      userId={record?.recordId || record.ownerId}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="p-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded ms-12 w-max">{`${record.stationName} / ${record.listName}`}</div>
          <hr className="mx-2 my-4" />
        </div>
      )
    })
  }
  return (
    <div style={{ height: widgetContentHeight }}>
      {!isCustomListOfRecords && (
        <div className="flex items-center justify-between mb-3">
          <div>
            {/* {!showAll && ( */}
            <>
              <button
                className={getButtonClass(showOverdue)}
                onClick={() => {
                  setShowOverdue(true)
                  setShowDueSoon(false)
                  setShowAllRecords(false)
                  // if (isAllRecords && setIsAllRecords) {
                  //   setIsAllRecords(false)
                  // }
                }}>
                <div className="flex items-center justify-center">
                  {t('overdue')}
                  <div className="flex items-center justify-center w-6 h-6 text-gray-600 bg-white rounded-full ms-1.5">
                    {myTasksData?.overdue?.length}
                  </div>
                </div>
              </button>
              <button
                className={getButtonClass(showDueSoon)}
                onClick={() => {
                  setShowOverdue(false)
                  setShowDueSoon(true)
                  setShowAllRecords(false)
                  // if (isAllRecords && setIsAllRecords) {
                  //   setIsAllRecords(false)
                  // }
                }}>
                <div className="flex items-center justify-center">
                  {t('dueSoon')}
                  <div className="flex items-center justify-center w-6 h-6 text-gray-600 bg-white rounded-full ms-1.5">
                    {myTasksData?.dueSoon?.length}
                  </div>
                </div>
              </button>
            </>
            {/* )} */}
            {isSideBar && (
              <button
                className={getButtonClass(showAllRecords)}
                onClick={() => {
                  setShowOverdue(false)
                  setShowDueSoon(false)
                  setShowAllRecords(true)
                  if (setIsAllRecords) {
                    setIsAllRecords(true)
                  }
                }}>
                <div className="flex items-center justify-center h-6">
                  {t('all')}
                  {myTasksData?.all?.length > 0 && (
                    <div
                      className={`flex items-center justify-center w-6 h-6 rounded-full text-gray-600 bg-white ms-1.5`}>
                      {myTasksData?.all?.length}
                    </div>
                  )}
                </div>
              </button>
            )}
          </div>

          {isAdmin && (
            <div className="flex items-center justify-end">
              <div style={{ height: 20, width: 20 }}>
                <FilterIcon />
              </div>
              <Select
                className="w-30"
                classNamePrefix="react-small-center-select"
                defaultValue={adminFilter}
                isRtl={isRtl}
                menuPortalTarget={document.body}
                name="sort-select"
                options={adminWidgetFilter}
                styles={{
                  menu: (base) => ({
                    ...base,
                    marginInlineStart: -10,
                  }),
                }}
                onChange={(e) => {
                  setAdminFilter(e)
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className={`h-full overflow-hidden`}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
        )}
        <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
          {prepareAndDisplayRecords(showingTasks)}
        </CustomScroll>
      </div>
    </div>
  )
}

export default memo(MyTasks)
