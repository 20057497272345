import React, { FC } from 'react'
import UserAvatarStatus from '../../UserAvatar/UserAvatarStatus'

type SelectedItemRowPropsType = {
  isUserItem: boolean,
  data: any,
}

const SelectedItemRow: FC<SelectedItemRowPropsType> = ({ isUserItem, data }) => {
  return (
    <>
      {isUserItem && (
        <div className="mt-0.5 pt-1">
          <UserAvatarStatus customSize={7} imgSrc={data.link} userId={data.value} />
        </div>
      )}
      <div className={`${isUserItem && 'ms-0.5 -mt-0.5'}`}>
        <p className="text-xs">{data.label}</p>
      </div>
    </>
  )
}
export default SelectedItemRow
