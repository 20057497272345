import React, { FC, useMemo } from 'react'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

import { createListLabel, useCurrentListId, useCurrentListLabels, useLabelsByIds } from 'features/list'
import LabelComponent from 'components/inputs/LabelsSelect/LabelComponent'
import ColoredDropdownOption from 'components/inputs/LabelsSelect/ColoredDropdownOption'
import { createLabelDropdownValues } from 'utils/helpers/labelsHelpers'
import { useAppDispatch } from 'store'
import { DropdownOptionType } from 'utils/types/inputs'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'

const LabelsEditorCell: FC<CellEditorProps<RecordType['labelsIds']>> = ({
  value,
  onChange,
  onComplete,
  autoFocus,
  gotoNext,
  gotoPrev,
  isGridView = false,
}) => {
  useGridViewNavigation(gotoPrev, gotoNext)
  const appDispatch = useAppDispatch()
  const listId = useCurrentListId()
  const { t } = useTranslation('records')
  const labels = useLabelsByIds(value)
  const listLabels = useCurrentListLabels()

  const labelsDropdownOption = useMemo(() => createLabelDropdownValues(listLabels), [listLabels])
  const selectedLabels = useMemo(() => createLabelDropdownValues(labels), [labels])

  const handleChange = (labels: MultiValue<DropdownOptionType | number> | number[]) => {
    const labelsIds = labels.map((label) => (typeof label === 'number' ? label : Number(label.value)))
    onChange({ target: { id: `input-labelsIds`, name: 'labelsIds', value: labelsIds } })
  }

  const onOptionCreate = async (inputValue: string) => {
    const results = await appDispatch(createListLabel({ listId: Number(listId), name: inputValue }))
    const newLabel: { id: number, name: string } = await unwrapResult(results)
    handleChange([...value, newLabel.id])
  }
  return (
    <div
      className="w-full"
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          e.preventDefault()
        }
      }}>
      <CreatableSelect
        autoFocus={autoFocus}
        classNamePrefix={`${isGridView ? 'react-select-grid-view' : 'react-select'}`}
        closeMenuOnSelect={false}
        components={{
          MultiValue: (props) => <LabelComponent {...props} isGridView={isGridView} />,
          // eslint-disable-next-line react/display-name
          Option: (props) => <ColoredDropdownOption {...props} isLabelDropdown />,
        }}
        isClearable
        isMulti
        menuPortalTarget={document.body}
        name="labels"
        openMenuOnFocus={autoFocus}
        options={labelsDropdownOption}
        placeholder={t('placeholderSelect')}
        value={selectedLabels}
        onBlur={onComplete}
        onChange={handleChange}
        onCreateOption={onOptionCreate}
      />
    </div>
  )
}

export default LabelsEditorCell
