import { useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import intersection from 'lodash/intersection'
import { DownIcon, LeftIcon, RightIcon } from 'components/Icons'
import { useAllArchivedDocumentsIds, useArchivedDocumentsHiddenElements } from '../../../features/station'
import { useCurrentUser } from '../../../features/user'
import { getDateFormat, parseIdsFromPath, pathGenerator } from '../../../utils/helpers/generalHelpers'
import UserAvatarStatus from '../../UserAvatar/UserAvatarStatus'
import ContextMenu from '../../ContextMenu'
import Button, { ButtonVariant } from '../../Buttons'

const SidebarItemContent = (props: any) => {
  const { item, onOpen, isPublic, onOpenArchive, index } = props
  const parentsIds = useAllArchivedDocumentsIds()
  const user = useCurrentUser()
  const { id, title, children, level, author, allChildrenCount, detailedPath, updatedAt, elementPath } = item
  const isChildren = !!(children && children.length)
  const isHijri = user.isHijri
  const ref = useRef<HTMLDivElement>(null)
  const hiddenElements = useArchivedDocumentsHiddenElements()
  const isItemOpened = children.filter((i: any) => hiddenElements.includes(i)).length === 0
  const { t, i18n } = useTranslation('documentation')
  const isRtl = i18n.language === 'ar'
  const { workspaceId, stationId, docId, publicId } = useParams<{
    workspaceId: string,
    stationId: string,
    docId: string,
    publicId: string,
  }>()
  const linkToDocs = isPublic
    ? `/public-docs/${publicId}`
    : `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`
  const ownerName = `${author?.firstName} ${author?.lastName}`
  const isSubChild = parentsIds.find((id: string) => +id === +item.parentId) !== undefined
  const levelInArchive = intersection(parentsIds, parseIdsFromPath(elementPath)).length
  const padding = levelInArchive === 1 ? 0 : levelInArchive - 1
  const documentPath = pathGenerator({ list: detailedPath, pathType: 'documents' })
  return (
    <div
      className={classNames('border-gray-100 items-center text-sm grid grid-cols-4 hover:bg-gray-100', {
        'border-t-2': !isSubChild && index !== 0,
      })}
      style={{ gridAutoRows: '3rem' }}>
      <div
        className="flex items-center rounded cursor-pointer relative"
        id={id}
        ref={ref}
        style={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: !isRtl ? padding * 12 : 8,
          paddingRight: isRtl ? padding * 12 : 8,
          transition: 'height .1s linear',
        }}
        title={title}>
        {isChildren ? (
          <div
            className="ms-1.5"
            style={{ minWidth: 24, width: 24 }}
            onClick={(e) => {
              e.stopPropagation()
              if (onOpen) {
                onOpen(item)
              }
            }}>
            {!isItemOpened ? (
              isRtl ? (
                <LeftIcon className="w-3" />
              ) : (
                <RightIcon className="w-3" />
              )
            ) : (
              <DownIcon className="w-3" />
            )}
          </div>
        ) : (
          <div className="px-2" style={{ height: 24, width: 24 }}>
            &#8226;
          </div>
        )}
        <Link className="truncate" to={`${linkToDocs}/view/${id}`}>
          <div className="truncate">{title}</div>
        </Link>
      </div>
      <div className="flex w-11/12">{documentPath}</div>
      <p className="">{getDateFormat(isRtl, updatedAt, isHijri)}</p>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start">
          <UserAvatarStatus userId={author?.id} />
          <p className="ms-0.5">{ownerName}</p>
        </div>
        {!isSubChild && (
          <div className="flex items-center">
            <ContextMenu
              isDefaultPopup
              render={() => {
                return (
                  <div className="pb-2">
                    <Button className="text-danger" variant={ButtonVariant.Icon} onClick={() => onOpenArchive(item)}>
                      {t('common:labels.unarchive')}
                    </Button>
                  </div>
                )
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default SidebarItemContent
