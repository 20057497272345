import * as yup from 'yup'
import { isBefore, startOfDay } from 'date-fns'
import i18n from 'i18next'

import { requiredTextFieldValidationSchema } from './singleFieldsValidationSchemas'

const validationSchema = yup.object().shape({
  completedIn: yup.number().min(0).max(100).nullable(),
  description: yup.string().max(2000).nullable(),
  endDate: yup
    .date()
    .test(
      "endDate can't be earlier than startDate",
      i18n.t('validation:errors.endDateCannotBeEarlierThanStartDate'),
      (value, context) => {
        const { startDate } = context.parent
        return startDate != null && value ? !isBefore(value, startOfDay(startDate)) : true
      },
    )
    .nullable(),
  name: requiredTextFieldValidationSchema('name', 150),
  priority: yup.number().nullable(),
  startDate: yup.date().nullable(),
})

export default validationSchema
