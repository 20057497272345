import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PhoneNumberForm } from 'components/forms'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'

const SetPhoneMfaPage: FC = () => {
  const { t } = useTranslation('signIn')
  return (
    <>
      <ChangeLanguageHeader headerText={t('setPhoneNumber')} />
      <div className="mb-10">{t('phoneUsage')}</div>
      <PhoneNumberForm />
    </>
  )
}

export default SetPhoneMfaPage
