import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useAppDispatch } from 'store'
import { withButtonsAndContentEditable } from 'hocs'
import { useRoles } from 'hooks'

import { configValues } from 'utils/appConfig'
import { getInheritedCustomFields } from 'utils/helpers/customFields'

import { useCurrentUser } from 'features/user'

import ConfirmationModal from 'components/ConfirmationModal'
import { AvatarInput, Input, MultilineInputWithMentions } from 'components/inputs'
import InfoModal from 'components/InfoModal'
import EntryInitials from 'components/EntryInitials'
import {
  requiredTextFieldValidationSchema,
  textFieldValidationSchema,
} from 'components/forms/validationSchemas/singleFieldsValidationSchemas'
import {
  updateWorkspaceById,
  deleteWorkspaceById,
  leaveWorkspace,
  uploadAvatar,
  archiveWorkspaceById,
  fetchWorkspaces,
  fetchWorkspaceById,
  updateWorkspaceCustomFields,
  useCurrentWorkspace,
} from 'features/workspace'
import WatchButton from 'components/Notifications/WatchButton'
import ElementCustomFields from 'components/CustomFields/ElementCustomFields'

const EditableInputWithButtons = withButtonsAndContentEditable(Input)
const EditableMultilineWithButtons = withButtonsAndContentEditable(MultilineInputWithMentions)

const WorkspaceDetailsPage: FC<{ workspaceId: string, isViewer?: boolean }> = ({ workspaceId, isViewer }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const workspace = useCurrentWorkspace()
  const customFieldsList = getInheritedCustomFields(workspace?.customFields)
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const user = useCurrentUser()
  const [isDeletingModalOpen, setIsDeletingModalOpen] = useState<boolean>(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isLeavingModalOpen, setIsLeavingModalOpen] = useState<boolean>(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const { isAdmin, isEditor } = useRoles()
  const isArchived = workspace?.appElement?.isArchived
  const isEditable = isEditor && !isArchived

  useEffect(() => {
    if (workspaceId) appDispatch(fetchWorkspaceById(+workspaceId))
  }, [workspaceId])

  const onDelete = () => {
    setIsDeletingModalOpen(false)
    dispatch(deleteWorkspaceById(workspace?.id))
    dispatch(fetchWorkspaces(Number(!isArchived)))
    history.replace('/')
  }

  const onArchive = () => {
    const recursive = Number(isChecked)
    appDispatch(
      archiveWorkspaceById({ elementId: workspace?.appElement?.id, recursive, status: Number(!isArchived) }),
    ).then(() => {
      setIsArchiveModalOpen(false)
      if (!isArchived) {
        appDispatch(fetchWorkspaces()).then(() => history.replace('/'))
      } else {
        dispatch(fetchWorkspaceById(+workspace.id))
      }
    })
  }

  const leaveCurrentWorkspace = async () => {
    const dispatchResponse: any = await appDispatch(leaveWorkspace(workspace?.id))
    if (dispatchResponse.payload.message === 'atLeastOneAdminMustBeInTheWorkspace') {
      setIsInfoModalOpen(true)
      setIsLeavingModalOpen(false)
    } else {
      history.replace('/')
    }
  }

  const updateNotificationsSettings = () => {
    dispatch(updateWorkspaceById({ ...workspace, enabledNotifications: !workspace?.enabledNotifications }))
  }

  const updateWorkspace = (values: any) => {
    const newWorkspaceValues = { elementName: values.name, ...workspace, ...values }
    dispatch(updateWorkspaceById(newWorkspaceValues))
  }

  const commonProps = {
    isLabelVisible: true,
    onSubmit: updateWorkspace,
  }

  const handleAvatarUpload = (avatar: { filename: string, base64data: string }) =>
    dispatch(uploadAvatar({ avatar, workspaceId: workspace?.id }))

  const onUpdateElement = (value: any) => {
    dispatch(updateWorkspaceCustomFields({ body: value, workspaceId: workspace?.id }))
  }

  return (
    <div className="flex w-full p-6 md:p-8">
      <div className="flex">
        <div>
          <div className="flex">
            <h1 className="mb-6 text-lg">{t('workspaceSettings:workspaceDetails')}</h1>{' '}
            {!isArchived && (
              <div className="-mt-1 ms-2">
                <WatchButton elementId={workspace?.appElement?.id} userId={user?.id} />
              </div>
            )}
          </div>
          <EditableInputWithButtons
            isOnClickDisabled={!isEditable}
            label={t('labels.name')}
            name="name"
            validationSchema={requiredTextFieldValidationSchema('name', 100)}
            value={workspace?.name}
            {...commonProps}
          />
          <EditableMultilineWithButtons
            isOnClickDisabled={!isEditable}
            label={t('labels.description')}
            name="description"
            validationSchema={textFieldValidationSchema('description', 1000)}
            value={workspace?.appElement?.description}
            {...commonProps}
          />

          <AvatarInput
            avatar={workspace?.link}
            emptyAvatarPlaceholder={<EntryInitials color={workspace?.color} isEdit name={workspace?.name} />}
            isChangeDisabled={!isEditable}
            isViewer={isViewer}
            updateAvatar={handleAvatarUpload}
          />
          {!isViewer && (
            <>
              <h1 className="mb-2 text-lg">{t('workspaceSettings:other')}</h1>
              <div className="flex flex-col items-start">
                {isAdmin && (
                  <>
                    <button
                      className="py-2 text-danger text-start hover:text-primary"
                      type="button"
                      onClick={() => setIsArchiveModalOpen(true)}>
                      {isArchived ? t('workspace:unArchiveWorkspace') : t('workspace:archiveWorkspace')}
                    </button>
                    {isArchived && (
                      <button
                        className="py-2 text-danger text-start hover:text-primary"
                        type="button"
                        onClick={() => setIsDeletingModalOpen(true)}>
                        {t('workspace:removeWorkspace')}
                      </button>
                    )}
                  </>
                )}
                {!isArchived && (
                  <button
                    className="py-2 text-danger text-start hover:text-primary"
                    type="button"
                    onClick={() => setIsLeavingModalOpen(true)}>
                    {t('workspace:leaveWorkspace')}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        {!configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && customFieldsList.length > 0 && (
          <div className="w-1/3">
            <div className="w-full">
              <label className="flex mb-2 text-lg font-bold">{t('customFields:customFields')}</label>
            </div>
            <table cellPadding="0" className="w-full max-h-full overflow-y-auto border-separate table-fixed">
              <colgroup>
                <col className="w-4/12" />
                <col className="w-8/12" />
              </colgroup>
              <tbody>
                <ElementCustomFields
                  currentElement={workspace}
                  customFieldsList={customFieldsList}
                  isEditor={isEditor}
                  updateElement={onUpdateElement}
                />
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ConfirmationModal
        confirmationMessage={t('workspace:confirmWorkspaceDelete', {
          interpolation: { escapeValue: false },
          name: workspace?.name,
        })}
        isModalOpen={isDeletingModalOpen}
        onCancel={() => setIsDeletingModalOpen(false)}
        onConfirm={onDelete}
      />
      <ConfirmationModal
        checkBoxMessage={isArchived ? t('common:archiveRecursiveMessage') : undefined}
        confirmMessage={isArchived ? t('common:labels.unarchive') : t('workspace:archive')}
        confirmationMessage={t(
          isArchived ? 'workspace:confirmWorkspaceUnarchive' : 'workspace:confirmWorkspaceArchive',
          {
            interpolation: { escapeValue: false },
            name: workspace?.name,
          },
        )}
        isChecked={isChecked}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onChangeCheckBox={() => setIsChecked(!isChecked)}
        onConfirm={onArchive}
      />
      <ConfirmationModal
        confirmMessage={t('workspace:leave')}
        confirmationMessage={t('workspace:confirmWorkspaceLeave', {
          interpolation: { escapeValue: false },
          name: workspace?.name,
        })}
        isModalOpen={isLeavingModalOpen}
        onCancel={() => setIsLeavingModalOpen(false)}
        onConfirm={leaveCurrentWorkspace}
      />

      <InfoModal
        confirmationMessage={t('notifications:atLeastOneAdminMustBeInTheWorkspace')}
        isModalOpen={isInfoModalOpen}
        onConfirm={() => setIsInfoModalOpen(false)}
      />
    </div>
  )
}

export default WorkspaceDetailsPage
