import { FC } from 'react'
import { ListType, RecordType, StationType } from '../../../utils/types'
import { CustomFieldType } from '../../../utils/types/CustomFieldsType'

interface CustomFieldAggregateRecordValueType {
  currentItem: RecordType | ListType | StationType;
  customFieldInfo: CustomFieldType;
  isGridView?: boolean;
}
const CustomFieldAggregateRecordValue: FC<CustomFieldAggregateRecordValueType> = ({ currentItem, customFieldInfo }) => {
  const fieldValue = currentItem?.appElements[0]?.customFieldValues?.find(
    (field) => +field.customFieldId === +customFieldInfo.id,
  )?.value

  const formattedValue = fieldValue && fieldValue !== 'undefined' ? Number(fieldValue).toFixed(2) : 0
  return <div className="text-sm">{formattedValue}</div>
}
export default CustomFieldAggregateRecordValue
