import React, { FC } from 'react'
import classnames from 'classnames'

interface TableProps {
  tableClasses?: string;
  style?: any;
  children?: React.ReactNode;
}

const Table: FC<TableProps> = ({ tableClasses, children, ...rest }) => {
  return (
    <table cellPadding="0" className={classnames('w-full', tableClasses)} style={{ borderSpacing: '0 8px' }} {...rest}>
      {children}
    </table>
  )
}

export default Table
