export const newDocument = {
  accessLevel: 0,
  baseElementType: 4,
  avatarType: 0,
  isArchived: false,
  keywords: 'document documentation wiki',
  tags: 'document documentation',
}
export const newDocumentPage = {
  accessLevel: 0,
  baseElementType: 5,
  avatarType: 0,
  isArchived: false,
  keywords: 'document page',
  tags: 'document page',
}
