import React, { forwardRef, useMemo } from 'react'

import { DropdownOptionType, UserDropdownInputType, InputRefType } from 'utils/types/inputs'
import Dropdown, { baseDropdownStyles } from 'components/inputs/Dropdown'

const UserDropdown = forwardRef<InputRefType, UserDropdownInputType>(
  ({ options: users = [], value, isGridView, ...dropdownProps }, ref) => {
    const options: DropdownOptionType[] = useMemo(
      () =>
        users.map(({ firstName, lastName, id }) => ({
          label: `${firstName} ${lastName}`,
          value: id.toString(),
        })),
      [users],
    )

    const dropdownValue = value?.id || value

    return (
      <Dropdown
        isCreatable={false}
        options={options}
        ref={ref}
        isGridView={isGridView}
        menuPortalTarget={document.body}
        // styles={baseDropdownStyles}
        value={dropdownValue}
        {...dropdownProps}
      />
    )
  },
)

UserDropdown.displayName = 'UserDropdown'

export default UserDropdown
