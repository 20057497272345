import React, { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListType, RecordType, StationType, WorkspaceType } from '../../../../../utils/types'
import SelectRowItem from './SelectRowItem'

type selectRowItemProps = {
  element: WorkspaceType | StationType | ListType | RecordType,
  selectedElements: any[],
  setSelectedElements: (val: any) => void,
  setShowCurrentElement: (val: boolean) => void,
}

const SelectRootElement: FC<selectRowItemProps> = ({
  selectedElements,
  setSelectedElements,
  setShowCurrentElement,
  element,
}) => {
  const { i18n, t } = useTranslation('documentation')
  const isRtl = i18n.language === 'ar'
  const itemIndex = [...selectedElements].findIndex(
    (ele) => Number(ele?.appElements[0]?.id) === Number(element?.appElements[0]?.id),
  )
  const item = selectedElements[itemIndex]
  const [isSelected, setIsSelected] = useState(item?.isSelected !== undefined ? item?.isSelected : false)

  const checkboxChangeHandle = (e: any) => {
    const newStatus = !isSelected
    const newItem = { ...item, isSelected: newStatus }
    e.stopPropagation()
    const oldList = [...selectedElements]
    if (itemIndex > -1) {
      oldList[itemIndex] = { ...newItem }
      setSelectedElements([...oldList])
    } else {
      setSelectedElements([...selectedElements, { ...newItem }])
    }
    setIsSelected(newStatus)
  }

  return (
    <>
      <div className="mt-5 mb-2 font-bold">{t('workspaces')}</div>
      <div className="flex-1 min-h-0 my-2">
        <SelectRowItem
          key={`${item.title}_${isRtl}`}
          checkboxChangeHandle={checkboxChangeHandle}
          setShowCurrentElement={setShowCurrentElement}
          isSelected={isSelected}
          isStartFromRootElement={false}
          item={item}
          name={`${item.id}-${item.title}`}
          options={{
            canAddRecord: false,
            isSingleSelect: false,
            shouldShowPreview: true,
          }}
          showNextIcon={true}
        />
        {/*<div className="flex items-center justify-between p-2 rounded hover:bg-gray-200">*/}
        {/*  <div*/}
        {/*    className={`flex`}*/}
        {/*    onClick={(e) => {*/}
        {/*      e.stopPropagation()*/}
        {/*    }}>*/}
        {/*    <Checkbox*/}
        {/*      checked={isSelected}*/}
        {/*      isFullRoundedBorder={true}*/}
        {/*      name={`workspace`}*/}
        {/*      onChange={(e) => checkboxChangeHandle(e)}*/}
        {/*    />*/}
        {/*    <p className="text-sm">{item?.title || item?.label || item?.name}</p>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    aria-disabled={isSelected}*/}
        {/*    className={`cursor-pointer ${isSelected && 'opacity-50'}`}*/}
        {/*    onClick={() => {*/}
        {/*      if (!isSelected) setShowCurrentElement(false)*/}
        {/*    }}>*/}
        {/*    {renderDirectionIcon(isRtl)}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  )
}
export default SelectRootElement
