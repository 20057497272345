import { useSelector } from 'react-redux'
import StateType from 'utils/types/states'
import { StationDocumentationSidebarType } from 'utils/types/states/StationDocumentationSidebarType'

const selectPublicDocsSidebar = (state: StateType) => state.publicDocs.publicDocsSidebar
const selectPublicDocsSidebarLoading = (state: StateType) => state.publicDocs.publicDocsSidebarLoading
const selectPublicDocsOneItem = (state: StateType) => state.publicDocs.publicDocsOneItem
const selectPublicDocsOneItemLoading = (state: StateType) => state.publicDocs.publicDocsOneItemLoading
const selectPublicDocsSidebarHiddenElements = (state: StateType) => state.publicDocs.publicDocSidebarHiddenElements

export const usePublicDocsSidebar: () => StationDocumentationSidebarType[] | null = () =>
  useSelector(selectPublicDocsSidebar)
export const usePublicDocsSidebarLoading: () => boolean = () => useSelector(selectPublicDocsSidebarLoading)
export const usePublicDocsOneItem: () => StationDocumentationSidebarType | null = () =>
  useSelector(selectPublicDocsOneItem)
export const usePublicDocsOneItemLoading: () => boolean = () => useSelector(selectPublicDocsOneItemLoading)
export const usePublicDocsSidebarHiddenElements: () => number[] = () =>
  useSelector(selectPublicDocsSidebarHiddenElements)
