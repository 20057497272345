import DocumentationDocumentEdit from 'components/Documentation/DocumetationsDocument/DocumentationDocumentEdit'
import React, { memo, useEffect, useState } from 'react'

const StationDocumentationSinglePageEdit = () => {
  const [hasChanges, setHasChanges] = useState(false)

  const handleWindowClose = (e: BeforeUnloadEvent) => {
    if (!hasChanges) return
    e.preventDefault()
    e.returnValue = 'message'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose)
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  })
  return (
    <div className="flex h-full min-h-0">
      <DocumentationDocumentEdit hasChanges={hasChanges} setHasChanges={setHasChanges} />
    </div>
  )
}

export default memo(StationDocumentationSinglePageEdit)
