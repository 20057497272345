import React, { FC, useRef, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'

import ContextMenu from 'components/ContextMenu'
import { Input } from 'components/inputs'

import { fetchListById, removeListStatus, updateListStatus } from 'features/list'
import { fetchRecordsByParams } from 'features/record'
import { RECORDS_PER_PAGE } from 'utils/helpers/recordHelpers'

import { withContentEditable } from 'hocs'
import { useAppDispatch } from 'store'
import { ListStatusType } from 'utils/types'
import { useDispatch } from 'react-redux'

const EditableInput = withContentEditable(Input)

type ColumnHeaderType = {
  title: string,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  columnValue: string | number | null,
  columnPosition: number,
  numberOfItemsInColumn: number,
  className?: string,
  showDragIcon?: boolean,
  isViewer: boolean,
  isArchivedList: boolean,
  columnsType: string,
}

const ColumnHeader: FC<ColumnHeaderType> = ({
  title,
  columnValue,
  columnPosition,
  dragHandleProps,
  numberOfItemsInColumn,
  className,
  isViewer,
  showDragIcon,
  columnsType,
  isArchivedList,
}) => {
  const { t, i18n } = useTranslation('lists')
  const isRtl = i18n.language === 'ar'
  const { listId, viewId }: { workspaceId: string, stationId: string, listId: string, viewId: string } = useParams()
  const [isFocus, setIsFocus] = useState(false)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const inputRef = useRef<any>()
  const shouldDisplayContextMenu = columnValue !== null && columnsType === 'statusId' && !isViewer
  const isNotAssignedColumn = columnValue === null

  const removeStatus = () => {
    if (columnValue)
      appDispatch(removeListStatus(+columnValue)).then(() => {
        dispatch(fetchListById(listId))
        dispatch(fetchRecordsByParams({ limit: RECORDS_PER_PAGE, listId, viewId }))
      })
  }
  const renameStatus = () => {
    inputRef?.current?.focus()
    setIsFocus(true)
  }

  const setColumnName = (value: any) => {
    if (columnValue && value.columnName !== title) {
      const status: ListStatusType = { id: +columnValue, listId: +listId, name: title, position: columnPosition }
      dispatch(updateListStatus({ changes: { name: value.columnName }, status }))
    }
    setIsFocus(false)
  }

  return (
    <div
      className={classnames(className && 'ps-3 flex items-center justify-between', {
        '-my-2': !isNotAssignedColumn && !isFocus && !isArchivedList,
        'column-drag-handle cursor-move': !isNotAssignedColumn && !isViewer && !isArchivedList,
        'my-1': isNotAssignedColumn || isArchivedList,
        'p-3': !shouldDisplayContextMenu && columnValue !== null && !isArchivedList,
      })}
      dir={isRtl ? 'rtl' : 'ltr'}
      {...dragHandleProps}>
      <EditableInput
        className="overflow-hidden text-xs font-bold uppercase text-ellipsis whitespace-nowrap"
        inputWrapperClassName="mb-0 flex-1"
        isOnClickDisabled
        name="columnName"
        notEditingClassName="overflow-hidden text-xs font-bold uppercase text-ellipsis whitespace-nowrap"
        ref={inputRef}
        truncated
        value={title}
        onKeyDown={(event) => {
          if (event.key === 'Escape') inputRef.current.resetForm()
          setIsFocus(false)
        }}
        onSubmit={setColumnName}
      />
      <div className="flex items-center">
        <div
          className={classnames({ 'me-3': columnValue === null }, 'text-xs bg-white p-1 rounded min-w-6 text-center')}>
          {numberOfItemsInColumn || 0}
        </div>
        {shouldDisplayContextMenu && !isArchivedList && !isViewer && (
          <ContextMenu
            render={({ closeMenu }) => (
              <>
                <button
                  className="block w-full py-2 hover:text-primary text-start"
                  onClick={() => {
                    renameStatus()
                    closeMenu()
                  }}>
                  {t('editName')}
                </button>
                <button
                  className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition transition-colors"
                  onClick={removeStatus}>
                  {t('common:labels.remove')}
                </button>
              </>
            )}
          />
        )}
      </div>
    </div>
  )
}

export default ColumnHeader
