import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ForgotPasswordForm } from 'components/forms'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'

const initialValues = {
  email: '',
}

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation('signIn')
  return (
    <>
      <ChangeLanguageHeader headerText={t('forgotPassword')} />
      <ForgotPasswordForm initialValues={initialValues} />
    </>
  )
}

export default ForgotPasswordPage
