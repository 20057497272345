import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-use'
import sortBy from 'lodash/sortBy'
import { DashboardStateWidgetType } from 'utils/types/states'
import Select, { StylesConfig } from 'react-select'
import { attributesStatuses, stationStates } from 'utils/constant/resources/stationResorces'
import { StationListWidgetType } from 'utils/types/states/DashboardStateWidgetType'
import { widgetSelectStyles } from 'utils/helpers/dashboardHelpers'
import { CustomFieldOptionItemType, CustomFieldType } from 'utils/types/CustomFieldsType'
import DashboardWidgetType from 'utils/types/DashboardWidgetType'
import { useCurrentWorkspace } from 'features/workspace'
import { StackIcon } from 'components/Icons'
import CustomScroll from '../../../CustomScroll'
import WidgetImage from '../../WidgetImage'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import { customFieldBaseTypes } from '../../../../utils/constant/enums/customFields'
import StationDetailsRow from './StationDetailsRow'

export const groupByOption = (arr: any[], option: { value: string, label: string, list?: CustomFieldType[] }) => {
  if (!option || !arr || !arr.length) {
    return { data: null, translatePath: null }
  }
  const sortedData = arr.slice().sort((a, b) => a.elementOrder - b.elementOrder)
  if (option.value === '__nullGrouping__') {
    return {
      data: { __nullGrouping__: sortedData },
      translatePath: null,
    }
  }
  const obj: Record<string, any> = {}

  for (let i = 0; i < sortedData.length; i++) {
    const el = sortedData[i]
    // Check if the custom field has a value inside this record
    const customFieldValue = el?.customFieldValues?.find((field: any) => +field.customFieldId === +option.value)
    let keyFromEl = el[option.value]

    if (keyFromEl === null || keyFromEl === undefined) {
      keyFromEl = 0
    } else {
      keyFromEl += 1
    }
    if (option.value !== 'manager' && customFieldValue === undefined) {
      if (option.value !== 'state') {
        keyFromEl = attributesStatuses[keyFromEl].label
      }

      if (option.value === 'state') {
        keyFromEl = stationStates[keyFromEl].label
      }
      if (obj[keyFromEl]) {
        obj[keyFromEl] = [...obj[keyFromEl], el]
      } else {
        obj[keyFromEl] = [el]
      }
    } else if (customFieldValue !== undefined) {
      // If record has custom field selected value then group it
      const optionObj = option?.list?.find(
        (op: CustomFieldOptionItemType) => +op.id === +customFieldValue?.customFieldOptionId,
      )
      const optionKey = optionObj?.optionCaption
      if (optionKey) {
        if (obj[optionKey]) {
          obj[optionKey] = [...obj[optionKey], el]
        } else {
          obj[optionKey] = [el]
        }
      }
    } else {
      const manager = el[option.value]
      const managerKey = manager?.firstName + ' ' + manager?.lastName

      if (obj[managerKey]) {
        obj[managerKey] = [...obj[managerKey], el]
      } else {
        obj[managerKey] = [el]
      }
    }
  }

  let translatePath = null

  if (option.value === 'manager') {
    translatePath = null
  } else if (option.value !== 'state') {
    translatePath = 'dashboard'
  } else if (option.value === 'state') {
    translatePath = 'station:stationStates'
  }

  return { data: obj, translatePath }
}

const StationListWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const { t, i18n } = useTranslation(['dashboard', 'station'])
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const selectBasicOptions: any = [
    { label: t('dashboard:groupByOptions.__nullGrouping__'), value: '__nullGrouping__' },
    { label: t('dashboard:groupByOptions.manager'), value: 'manager' },
    { label: t('dashboard:groupByOptions.state'), value: 'state' },
    { label: t('dashboard:groupByOptions.status'), value: 'status' },
    { label: t('dashboard:groupByOptions.budgetHealth'), value: 'budgetHealth' },
    { label: t('dashboard:groupByOptions.qualityHealth'), value: 'qualityHealth' },
  ]
  const currentWorkspace = useCurrentWorkspace()
  const customFieldsList = currentWorkspace?.customFields?.native?.fields
    ?.filter((field: CustomFieldType) => field.baseType === customFieldBaseTypes.DropDown)
    .map((f: CustomFieldType) => ({ label: f.fieldName, list: f.customFieldOptions, value: f.id }))
  const selectOptions = customFieldsList ? [...selectBasicOptions, ...customFieldsList] : [...selectBasicOptions]
  const [selectedOption, selectOption] = useState(selectOptions[0])
  const isRtl = i18n.language === 'ar'
  const { data }: any = widget || widgetInfo
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }
  const StationListWidgetData: StationListWidgetType = data
  const sortedStationListWidgetValues = sortBy(StationListWidgetData?.values, 'elementOrder')
  const groupedObj = groupByOption(sortedStationListWidgetValues, selectedOption)

  return (
    <>
      {sized}
      <div>
        {groupedObj.data ? (
          <div className="flex items-center mb-2">
            <div style={{ height: 20, width: 20 }}>
              <StackIcon />
            </div>
            <Select
              className="w-30"
              classNamePrefix="react-select"
              defaultValue={{ ...selectedOption, label: t(`dashboard:groupByOptions.${selectedOption.value}`) }}
              isRtl={isRtl}
              menuPortalTarget={document.body}
              name="sort-select"
              options={selectOptions}
              styles={widgetSelectStyles}
              onChange={(e) => {
                selectOption(e)
              }}
            />
          </div>
        ) : null}
      </div>
      {elementWidth < 700 ? (
        <div className="mt-2 overflow-x-hidden overflow-y-auto" style={{ maxHeight: 'calc(100% - 50px)' }}>
          {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
            <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          )}
          {groupedObj.data &&
            Object.keys(groupedObj.data).map((key, idx) => {
              let header: string | null = ''

              if (key === '__nullGrouping__') {
                header = null
              } else if (groupedObj.translatePath === 'dashboard') {
                header = t(key)
              } else if (!groupedObj.translatePath) {
                header = key
              } else {
                header = t(groupedObj.translatePath + '.' + key)
              }
              const mobileStationRowProps = {
                elementWidth,
                list: groupedObj.data[key],
              }

              return (
                <div key={key + idx} className="mt-1 mb-5">
                  {header !== null && <h2 className="mb-2.5">{header}</h2>}
                  <StationDetailsRow {...mobileStationRowProps} />
                  {/*{mobileVersion(groupedObj.data[key])}*/}
                </div>
              )
            })}
        </div>
      ) : (
        <div className="mt-2 overflow-hidden">
          <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
            {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
              <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
            )}
            <div>
              {groupedObj.data &&
                Object.keys(groupedObj.data).map((key, idx) => {
                  let header: string | null = ''

                  if (key === '__nullGrouping__') {
                    header = null
                  } else if (groupedObj.translatePath === 'dashboard') {
                    header = t(key)
                  } else if (!groupedObj.translatePath) {
                    header = key
                  } else {
                    header = t(groupedObj.translatePath + '.' + key)
                  }
                  const stationRowProps = {
                    elementWidth,
                    list: groupedObj.data[key],
                  }

                  return (
                    <div key={key + idx} className="mt-1 mb-5">
                      {header !== null && <h2 className="mb-2.5">{header}</h2>}
                      <StationDetailsRow {...stationRowProps} />
                      {/*{desktopVersion(groupedObj.data[key])}*/}
                    </div>
                  )
                })}
            </div>
          </CustomScroll>
        </div>
      )}
    </>
  )
}

export default memo(StationListWidget)
