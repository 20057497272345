import React, { useMemo } from 'react'

import KanbanColumnPlaceholder from './KanbanColumnPlaceholder'

const generatePlaceholderSeed = () => {
  const columnCount = Math.ceil(Math.random() * 4) + 1

  return Array.from(Array(columnCount)).map(() => Math.round(Math.random() * 5) + 1)
}

const KanbanPlaceholder = () => {
  const seed = useMemo(generatePlaceholderSeed, [])

  return (
    <div className="flex p-4">
      {seed.map((count, index) => (
        <KanbanColumnPlaceholder key={index} count={count} />
      ))}
    </div>
  )
}

export default KanbanPlaceholder
