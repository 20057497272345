import React, { FC } from 'react'
import classnames from 'classnames'

import { SortAscendingIcon } from 'components/Icons'

const SortIndicator: FC<{ direction: number }> = ({ direction }) => (
  <div className={classnames('transform', direction === 1 ? 'rotate-180' : 'rotate-0')}>
    <SortAscendingIcon className="w-6" />
  </div>
)

export default SortIndicator
