import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useAppDispatch } from 'store'
import recordType from 'utils/types/RecordType'
import { CloseIcon, DocColoredIcon, PlusIcon } from 'components/Icons'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCurrentStation } from '../../features/station'
import { useCurrentWorkspace } from '../../features/workspace'
import { deleteElementLink } from '../../features/element'
import { updateRecordElementById } from '../../features/record'
import LinkToDocumentModal from './LinkToDocumentModal'

interface LinkToRecordsPropTypes {
  currentRecord: recordType;
  isMulti: boolean;
  isEditingAllowed: boolean;
  isGridView?: boolean;
  isViewer?: boolean;
  className?: string;
}

const LinkedDocuments: FC<LinkToRecordsPropTypes> = ({ currentRecord, isEditingAllowed, className, isViewer }) => {
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('records')
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const { viewId } = useParams<{ viewId: string }>()
  const documentsLinks = currentRecord?.appElements[0]?.linkedElementAppElementLinks?.filter(
    (el) => el?.element?.baseElementTyp === 5,
  )
  const [linkedDocumentsList, setLinkedDocumentsList] = useState(documentsLinks)
  const isLinkedWithDoc = linkedDocumentsList?.length > 0
  const currentStation = useCurrentStation()
  const currentWorkspace = useCurrentWorkspace()

  const docSectionId = currentStation?.wikiElementData?.id

  const renderLinkedDocument = () => {
    const linkedDoc = linkedDocumentsList[0]
    const value = linkedDoc?.element?.id
    const recordUrl = `/workspace/${currentWorkspace?.id}/stations/${
      currentStation?.id
    }/documentation/${+docSectionId}/view/${value}`
    return (
      <div
        key={linkedDoc?.element?.id}
        className={classnames('flex bg-gray-200 p-2 items-center justify-between w-full font-bold rounded-sm group')}>
        <div className="flex items-center justify-start">
          <div className="shrink-0 w-5 h-5 me-2">
            <DocColoredIcon />
          </div>

          <a className="me-1" href={recordUrl} rel="noreferrer" target="_blank">
            <p className="text-sm line-clamp-2">{linkedDoc?.element?.elementName}</p>
          </a>
        </div>

        {isEditingAllowed && (
          <div className="flex">
            <button
              className="rounded end-0 hover:bg-warning hover:text-white p-0.5"
              type={'button'}
              onClick={() => {
                appDispatch(deleteElementLink(linkedDoc?.id)).then(() => {
                  const updatedList = linkedDocumentsList?.filter((item) => +item?.id !== +linkedDoc?.id)
                  setLinkedDocumentsList(updatedList)
                  const appElements = [{ ...currentRecord.appElements[0], linkedElementAppElementLinks: updatedList }]
                  // dispatch(
                  //   updateRecordElementById({
                  //     changes: { appElements: appElements },
                  //     elementId: currentRecord?.appElements[0]?.id,
                  //     viewId,
                  //   }),
                  // )
                })
              }}>
              <CloseIcon className="w-4" />
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div
        className={classnames(
          'flex items-center justify-between p-1 leading-6 min-h-12 -mt-2 -mb-2 -ms-2 transition border-2 border-transparent border-dashed rounded hover:border-dashed text-sm',
          {
            'hover:border-gray-300': isEditingAllowed,
          },
        )}>
        {isLinkedWithDoc ? (
          renderLinkedDocument()
        ) : (
          <p className="text-sm font-normal text-gray-400">{t('records:listing.linkToDocument')}</p>
        )}
        {!isLinkedWithDoc && !isViewer && (
          <div className="flex justify-end">
            <button
              className="shrink-0 w-5 bg-gray-300 rounded-sm hover:bg-primary hover:text-white"
              id={'addLinkDoc'}
              onClick={() => {
                setModalOpen(true)
              }}>
              <PlusIcon className="text-black stroke-2" />
            </button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <LinkToDocumentModal
          currentRecord={currentRecord}
          isModalOpen={isModalOpen}
          linkedDocumentsList={linkedDocumentsList}
          setLinkedDocumentsList={setLinkedDocumentsList}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  )
}

export default LinkedDocuments
