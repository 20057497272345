import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import SettingsDropdown from 'components/SettingsDropdown'
import ConfirmationModal from 'components/ConfirmationModal'
import { archiveRecordById, deleteRecordById, onMoveRecord } from 'features/record'
import { usePanel } from 'contexts/PanelContext'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { ExpandIcon, LinkIcon, SeeMoreIcon } from '../Icons'
import WatchButton from '../Notifications/WatchButton'
import { useCurrentStation } from '../../features/station'
import MoveModal from '../MoveModal'
import { useCurrentList, useLists } from '../../features/list'
import { useAppDispatch } from '../../store'
import ParentChange from '../ParentChange'
import { ListType, RecordType } from '../../utils/types'
import AddSubtaskParentModal from '../SubTasks/AddSubtaskParentModal'
import { useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'
import ElementOperations from '../../utils/constant/enums/element'

const RecordSettingsPortal = ({ children }: { children: any }) => {
  const toolbarParent = document.getElementById('modal-settings')
  return toolbarParent && ReactDOM.createPortal(children, toolbarParent)
}

type RecordSettingsType = {
  id: number,
  name: string,
  elementId: number | string,
  userId: number | string,
  isEditor: boolean,
  isAdmin: boolean,
  isArchived: boolean,
  isArchivedByParent: boolean,
  hasSubtasks: boolean,
  currentRecord: RecordType,
  currentList: ListType,
  handleUpdateRecord: (value: any) => void,
}

const RecordSettings: FC<RecordSettingsType> = ({
  id,
  name,
  elementId,
  userId,
  isEditor,
  isAdmin,
  isArchived,
  isArchivedByParent,
  currentRecord,
  handleUpdateRecord,
  hasSubtasks,
  currentList,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const lists = useLists()
  const history = useHistory()
  const { closePanel } = usePanel()
  const appDispatch = useAppDispatch()
  const { t, i18n } = useTranslation('records')
  const isRtl = i18n.language === 'ar'
  const currentSavedList = useCurrentList()
  const list = currentList || currentSavedList
  const currentStation = useCurrentStation()
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [shouldRemoveElementAfterMove, setShouldRemoveElementAfterMove] = useState<boolean>(true)

  const onRefetch = () => {
    closePanel()
    setShouldRemoveElementAfterMove(true)
  }
  const showParent = !hasSubtasks && +currentRecord?.appElements[0]?.parentId !== +list?.appElement?.id
  const isPro = useHasCurrentWorkspacePremiumPlan()
  const isSubTask = +currentRecord?.appElements[0]?.elementLevel > 4

  const onDelete = () => {
    dispatch(deleteRecordById(id))
    setIsConfirmationModalVisible(false)
    closePanel()
  }

  const onCopyLink = () => {
    if (window !== undefined) {
      navigator.clipboard.writeText(window.location.href)
      toast.success(t('copyNotification'))
    }
  }

  const getRecordLinkInList = () => {
    const legacyPath = currentRecord?.appElements[0]?.legacyPath
    const path = legacyPath?.split('.')
    return path ? `/workspace/${path[0]}/stations/${path[1]}/lists/${path[2]}?recordId=${currentRecord?.id}` : ''
  }

  const onArchiveRecord = () => {
    const recursive = 0 // 1 to unarchive element and all children force 0 to archive element and children but not
    // force that mean anyone archived individual will not unarchived
    dispatch(archiveRecordById({ elementId: +elementId, recursive, status: Number(!isArchived) }))
    closePanel()
  }
  const DropdownContent = ({ closeDropdown }: { closeDropdown?: () => void }) => (
    <div className="text-gray-600">
      {!isArchived && (
        <button
          className="block w-full py-2 text-gray-600 text-start hover:text-primary focus:text-primary transition"
          onClick={() => {
            closeDropdown?.()
            onDuplicateRecord()
          }}>
          {t('common:labels.duplicate')}
        </button>
      )}
      {!list?.appElement?.isArchived && (
        <>
          {lists?.length > 1 && (
            <>
              <button
                className="block w-full py-2 text-start hover:text-primary focus:text-primary transition"
                onClick={() => {
                  setIsMoveModalOpen(true)
                  closeDropdown?.()
                }}>
                {t('common:labels.move')}
              </button>
              <button
                className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                onClick={() => {
                  setShouldRemoveElementAfterMove(false)
                  setIsMoveModalOpen(true)
                  closeDropdown?.()
                }}>
                {t('common:labels.copyToOtherList')}
              </button>
            </>
          )}
        </>
      )}
      <div className="text-gray-600">
        {!isArchived && (
          <>
            {!hasSubtasks && isPro && (
              <button
                className="block w-full py-2 text-gray-600 text-start hover:text-primary focus:text-primary transition"
                onClick={() => {
                  setModalOpen(true)
                  closeDropdown?.()
                }}>
                {isSubTask ? t('common:labels.changeParent') : t('common:labels.convertToSubtask')}
              </button>
            )}
          </>
        )}
      </div>
      <button
        className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition"
        onClick={() => {
          setIsArchiveModalOpen(true)
          closeDropdown?.()
        }}>
        {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
      </button>
    </div>
  )

  const onDuplicateRecord = () => {
    const body = {
      appElementId: Number(elementId),
      newParentId: null,
      operation: ElementOperations.Duplicate,
    }
    appDispatch(onMoveRecord(body)).then(() => {
      onRefetch()
    })
  }

  return (
    <>
      <RecordSettingsPortal>
        <div className="flex items-center justify-center h-full">
          {!isArchived && (
            <>
              {showParent && (
                <ParentChange
                  currentRecord={currentRecord}
                  handleUpdateRecord={handleUpdateRecord}
                  isEditingAllowed={isEditor}
                  isModalOpen={isModalOpen}
                  isMulti={true}
                  setModalOpen={setModalOpen}
                />
              )}
              <WatchButton elementId={elementId} isAdmin={isAdmin} userId={userId} />
              <div>
                <button
                  className="flex items-center justify-center w-12 cursor-pointer hover:text-primary"
                  onClick={onCopyLink}>
                  <LinkIcon />
                </button>
              </div>
              {currentRecord?.list && (
                <div dir={'auto'}>
                  <a
                    className={`flex items-center justify-center w-12 cursor-pointer hover:text-primary`}
                    href={getRecordLinkInList()}
                    target="_blank"
                    rel="noreferrer">
                    <ExpandIcon className={`w-5`} style={{ transform: isRtl ? 'scaleX(-1)' : undefined }} />
                  </a>
                </div>
              )}
            </>
          )}
          {isEditor && !isArchivedByParent && (
            <SettingsDropdown
              childClasses="w-44 end-0 rounded-te-none"
              iconClasses="w-12 p-3 hover:text-primary"
              showDefaultIconWidth={false}
              Icon={SeeMoreIcon}>
              <DropdownContent />
            </SettingsDropdown>
          )}
        </div>
      </RecordSettingsPortal>
      <ConfirmationModal
        confirmationMessage={t('confirmRecordDelete', { interpolation: { escapeValue: false }, name })}
        isModalOpen={isConfirmationModalVisible}
        onCancel={() => setIsConfirmationModalVisible(false)}
        onConfirm={onDelete}
      />
      <MoveModal
        currentElementId={elementId}
        currentParentId={currentStation?.appElement?.id}
        directParentId={list?.appElement?.id}
        elementBaseType={2}
        isMoveModalOpen={isMoveModalOpen}
        setIsMoveModalOpen={setIsMoveModalOpen}
        shouldRemoveElementAfterMove={shouldRemoveElementAfterMove}
        onMoveItem={onMoveRecord}
        onRefetch={onRefetch}
      />
      <ConfirmationModal
        confirmMessage={isArchived ? t('workspace:unarchive') : t('workspace:archive')}
        confirmationMessage={t(isArchived ? 'confirmRecordUnarchive' : 'confirmRecordArchive', {
          interpolation: { escapeValue: false },
          name,
        })}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => {
          setIsArchiveModalOpen(false)
        }}
        onConfirm={onArchiveRecord}
      />
      <AddSubtaskParentModal
        currentRecord={currentRecord}
        isModalOpen={isModalOpen}
        listElementId={list?.appElement?.id}
        onCancel={() => setModalOpen(false)}
      />
    </>
  )
}

export default RecordSettings
