import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppElementView } from 'utils/types/AppElementViewType'
import { AppElementViewUserAccess } from 'utils/types/AppElementViewUserAccess'
import { Dropdown, Input, Radio } from '../inputs'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'
import Button, { ButtonVariant } from '../Buttons'
import { createNewView, updateView } from '../../features/element'
import { useAppDispatch } from '../../store'
import { fetchViewsByListElementId } from '../../features/list'
import { getViewTypes } from '../../utils/constant/constant/common'
import Loader from '../Loader'
import AddUserToViewForm from './AddUserToViewForm'
import AddFieldsToViewForm from './AddFieldsToViewForm'

type ManageViewFormPropsTypes = {
  appElementId: string,
  onCancel: () => void,
  view: AppElementView | null,
}

const ManageViewForm: FC<ManageViewFormPropsTypes> = ({ onCancel, appElementId, view }) => {
  const { t, i18n } = useTranslation('lists')
  const dispatch = useAppDispatch()
  const [name, setName] = useState<string>(view?.viewTitle || '')
  const [type, setType] = useState<number>(view ? view?.viewBaseType : 0)
  const isLimitUserAccess = view?.appElementViewUsers[0] && view?.appElementViewUsers[0]?.dataAccess
  const isForAllUsers = !(view && view?.defaultUserInteraction !== 3)
  const isForAllFields = !(view && view?.defaultAttributeVisibility !== 3)
  const [forAllUsers, setForAllUsers] = useState<boolean>(isForAllUsers)
  const [forAllFields, setForAllFields] = useState<boolean>(isForAllFields)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [limitUserAccess, setLimitUserAccess] = useState<boolean>(isLimitUserAccess || false)
  const [selectedUsers, setSelectedUsers] = useState(view?.appElementViewUsers || [])
  const [selectedFields, setSelectedFields] = useState(view?.appElementViewAttributes || [])
  const [viewOrder, setViewOrder] = useState(view?.orderNumber || 0)
  const isUpdate = !!view

  const viewTypesOptions = useMemo(() => {
    return getViewTypes()
  }, [i18n.language])

  const handleConfirm = () => {
    setIsSubmitting(true)
    const body = {
      appElementId,
      childBaseType: 3,
      defaultAttributeVisibility: forAllFields ? 3 : 0,
      defaultUserInteraction: forAllUsers ? 3 : 0,
      isDefault: false,
      orderNumber: viewOrder,
      viewAttributes: selectedFields.map((f) => ({
        attributeId: Number(f.id),
        attributeType: f.attributeType,
        attributeVisibility: Number(f.status),
        tag1: '',
        tag2: '',
      })),
      viewBaseType: Number(type),
      viewTitle: name,
      viewUsers: selectedUsers.map((u) => ({
        dataAccess: Number(u.dataAccess),
        userId: u.userId,
      })),
    }
    if (view) {
      body.id = +view.id
      body.isDefault = view.isDefault
      dispatch(updateView({ body, id: +view.id })).then(() => {
        dispatch(fetchViewsByListElementId(appElementId))
        setIsSubmitting(false)
        onCancel()
      })
    } else {
      body.isDefault = false
      dispatch(createNewView(body)).then(() => {
        dispatch(fetchViewsByListElementId(appElementId))
        setIsSubmitting(false)
        onCancel()
      })
    }
  }

  return (
    <div className="flex flex-col flex-1 w-full min-h-0 p-5">
      <h2 className="pb-3 mb-3 font-bold border-gray-200">{isUpdate ? t('updateView') : t('createNewView')}</h2>
      <div className="flex flex-col flex-1 w-full min-h-0 overflow-y-auto">
        <div className="flex justify-between">
          <div className="pe-4" style={{ flexGrow: 2 }}>
            <Input
              classes={{ wrapper: 'text-sm' }}
              label={t('views.viewName')}
              name="name"
              placeholder={t('views.viewName')}
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Dropdown
              components={{ Option: ColoredDropdownOption }}
              isDisabled={isUpdate}
              isSearchable={false}
              label={t('views.viewType')}
              menuPortalTarget={document.body}
              name="viewType"
              options={viewTypesOptions}
              shouldShowPlaceholder
              value={type}
              onChange={(e) => {
                setType(e.target.value)
              }}
            />
          </div>
        </div>

        <hr className="mx-2 my-4" />

        <div className="mb-3">
          <label className="mb-3 text-sm font-bold">{t('views.viewUsers')}</label>
          <p className="mb-3 text-sm">{t('views.viewUsersDescription')}</p>
          <div className="flex flex-col gap-1">
            <Radio
              checked={forAllUsers}
              className="custom-radio"
              classes={{ label: 'text-sm' }}
              label={t('views.allUsers')}
              name={'allUsers'}
              onChange={() => setForAllUsers(true)}
            />
            <Radio
              checked={!forAllUsers}
              className="custom-radio"
              classes={{ label: 'text-sm' }}
              label={t('views.justSelected')}
              name={'justSelected'}
              onChange={() => setForAllUsers(false)}
            />
          </div>
          {!forAllUsers && (
            <AddUserToViewForm
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              viewUsers={view?.appElementViewUsers}
            />
          )}
        </div>
        {/* <div>
          <p className="mb-1 text-sm font-bold">{t('views.controlUserAccess')}</p>
          <div className="flex items-center">
            <label className="text-sm me-4">{t('views.userCanAccessJustHisOwn')}</label>
            <label className="flex switch">
              <input
                checked={limitUserAccess}
                type="checkbox"
                onClick={() => {
                  setLimitUserAccess(!limitUserAccess)
                }}
              />
              <span className="slider round" />
            </label>
          </div>
        </div> */}

        <hr className="mx-2 my-4" />

        <div className="mb-2">
          <label className="mb-2 text-sm font-bold">{t('views.viewFields')}</label>
          <p className="mb-3 text-sm">{t('views.viewFieldsDescription')}</p>
          <div className="flex items-center">
            <p className="text-sm font-bold me-3">{t('views.defaultBehaviour')}</p>
            <Radio
              checked={forAllFields}
              className="text-sm custom-radio"
              classes={{ label: 'text-sm', wrapper: 'me-2' }}
              label={t('views.defaultShown')}
              name={'allFields'}
              onChange={() => setForAllFields(true)}
            />
            <Radio
              checked={!forAllFields}
              className="custom-radio"
              classes={{ label: 'text-sm' }}
              label={t('views.defaultHidden')}
              name={'justSelectedFields'}
              onChange={() => setForAllFields(false)}
            />
          </div>

          <p className="mt-4 mb-2 text-sm">{t('views.overwriteFields')}</p>
          <AddFieldsToViewForm selectedFields={selectedFields} setSelectedFields={setSelectedFields} view={view} />
        </div>
      </div>
      <div className="flex justify-end mt-8 gap-4">
        <Button variant={ButtonVariant.Outline} onClick={onCancel}>
          {t('common:labels.cancel')}
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} onClick={handleConfirm}>
          {isSubmitting ? <Loader /> : t('dashboard:saveButton')}
        </Button>
      </div>
    </div>
  )
}
export default ManageViewForm
