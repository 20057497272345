import { UsersIcon } from 'components/Icons'
import { FC, memo } from 'react'
import Chart from 'components/Dashboard/Chart/Chart'
import { useTranslation } from 'react-i18next'

const TeamMonitorWidgetCard: FC<{
  card: any,
}> = ({ card }) => {
  const { t, i18n } = useTranslation('dashboard')
  const isNotCardBaseType = card.completed === undefined

  const cardStats = isNotCardBaseType
    ? [
        {
          title: t('onTime'),
          value: card.onTime,
        },
        {
          title: t('slightlyLate'),
          value: card.slightlyLate,
        },
        {
          title: t('veryLate'),
          value: card.veryLate,
        },
      ]
    : [
        {
          title: t('onTime'),
          value: card.onTime,
        },
        {
          isChart: true,
          title: t('projectStats'),
          value: card.completed,
        },
      ]

  const isRtl = i18n.language === 'ar'

  return (
    <div className="p-4 border border-gray-300 rounded-lg h-44">
      <div className="flex items-center space-x-4">
        {card.userPicture ? (
          <img alt="user avatar" className="rounded-lg w-14 h-14" src={card.userPicture} />
        ) : (
          <div className="bg-gray-200 rounded-lg w-14 h-14">
            <UsersIcon />
          </div>
        )}
        <h3 className={`text-lg font-semibold text-gray-800 ${isRtl ? 'pr-4' : ''}`}>{card.username}</h3>
      </div>
      <div className="flex mt-4">
        {cardStats.map((stat, idx) => (
          <div key={stat.title + idx} className="me-12">
            {!stat.isChart ? (
              <>
                <p className="text-sm text-gray-600">{stat.title}</p>
                <h3 className="font-medium text-gray-800">{stat.value} task</h3>
              </>
            ) : (
              <div className="flex">
                <div style={{ height: 50, marginTop: -5, width: 50 }}>
                  <Chart
                    datasets={[
                      {
                        backgroundColor: ['#00B2A9', '#DDE5ED'],
                        borderColor: ['rgba(255, 99, 132, 0.0)', 'rgba(54, 162, 235, 0.0)'],
                        borderRadius: 0,
                        cutout: '80%',
                        data: [stat.value, 100 - stat.value],
                        fill: false,
                        label: stat.title,
                      },
                    ]}
                    options={{ plugins: { datalabels: { display: false } } }}
                    type="pie"
                  />
                </div>
                <div>
                  <p className="text-sm text-gray-600">{stat.title}</p>
                  <h3 className="font-medium text-gray-800">
                    {stat.value} % {t('station:stationStates.completed')}
                  </h3>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(TeamMonitorWidgetCard)
