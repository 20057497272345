import { createAsyncThunk } from '@reduxjs/toolkit'

import baseAPI from 'utils/baseAPI'
import { CommentResponseType } from 'utils/constant/enums/common'
import { CommentType } from 'utils/types'

export const fetchCommentsByRecordId = createAsyncThunk(
  'comment/fetchCommentsByRecordId',
  async ({ elementId, showLatest }: { elementId: number | string, showLatest: boolean }) => {
    const response = await baseAPI(`api/records/comments/${elementId}?asc=${showLatest ? 0 : 1}`)
    return response.body
  },
)

export const addComment = createAsyncThunk(
  'comment/addComment',
  async (comment: {
    text: string,
    appElementId: string | number,
    showLatest: boolean,
    inResponseTo: CommentResponseType | null,
    responseTargetId?: number,
  }) => {
    const response = await baseAPI('api/records/comments', { body: comment, method: 'POST' })
    return { ...response.body, showLatest: comment.showLatest }
  },
)

export const updateComment = createAsyncThunk('comment/updateComment', async (comment: Partial<CommentType>) => {
  const response = await baseAPI(`api/records/comments/${comment.id}`, {
    body: comment,
    method: 'PUT',
  })
  return { ...comment, ...response.body }
})

export const removeComment = createAsyncThunk(
  'comment/removeComment',
  async (commentId: number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/records/comments/${commentId}`, {
        method: 'DELETE',
      })

      if (response.status >= 400) throw response.message

      return response.body
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const onUploadAssetsToComment = createAsyncThunk(
  'comment/onUploadAssetsToComment',
  async (props: { commentId: number, appElementAssetId: number, elementId: string }) => {
    const { commentId, appElementAssetId, elementId } = props
    const body = {
      appElementAssetId,
      commentId,
    }
    const response = await baseAPI(`api/commentAsset`, { body, method: 'POST' })
    return response.body
  },
)
export const onDeleteAssetsFromComment = createAsyncThunk(
  'comment/onDeleteAssetsFromComment',
  async (commentAssetId: string, commentId) => {
    const response = await baseAPI(`api/commentAsset/${commentAssetId}`, { method: 'DELETE' })
    return response.body
  },
)
