import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Buttons'
import { useHistory } from 'react-router'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { withAdminRole } from '../../hocs/withRole'
import { DATE_FORMAT } from '../../utils/helpers/generalHelpers'
import PlansList from '../../components/Subscription/PlansList'
import { ProIcon } from '../../components/Icons'
import { fetchWorkspaceById, useCurrentWorkspace, useCurrentWorkspaceChosenPlanIsBasic } from '../../features/workspace'

interface WorkspacePlanPagePropsType {
  workspaceId: string;
}

const WorkspacePlanPage: FC<WorkspacePlanPagePropsType> = ({ workspaceId }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('workspace')
  const isBasic = useCurrentWorkspaceChosenPlanIsBasic()
  const workspace = useCurrentWorkspace()

  useEffect(() => {
    if (workspaceId) dispatch(fetchWorkspaceById(+workspaceId))
  }, [workspaceId])

  const currentPlanName = isBasic ? t('subscription:basic') : t('subscription:pro')
  return (
    <div className="w-full p-6 md:p-8" style={{ height: 'fit-content' }}>
      <h1 className="mb-6 text-lg">{t('workspaceSettings:workspacePlan')}</h1>
      <div className="overflow-y-auto max-h-4/5">
        <div className="border rounded">
          <div className="justify-between p-6 md:flex lg:flex" style={{ background: '#ECF4FC' }}>
            <div>
              <h1 className="flex">
                {currentPlanName}
                {!isBasic && <ProIcon className="ms-2 mt-0.5" />}
              </h1>
              <p className="mt-2">{t('subscription:currentPlan')}</p>
            </div>
            {/*<div className="flex">*/}
            {/*  <div style={{ height: 70, width: 70 }}>*/}
            {/*    <Chart*/}
            {/*      datasets={[*/}
            {/*        {*/}
            {/*          backgroundColor: ['#00B2A9', '#DDE5ED'],*/}
            {/*          borderColor: ['rgba(255, 99, 132, 0.0)', 'rgba(54, 162, 235, 0.0)'],*/}
            {/*          borderRadius: 9,*/}
            {/*          cutout: '80%',*/}
            {/*          data: [90, 10],*/}
            {/*          fill: false,*/}
            {/*          label: '',*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      options={{ plugins: { datalabels: { display: false } } }}*/}
            {/*      type="pie"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className="m-3">*/}
            {/*    <h2 className="font-semibold">{t('subscription:storage')}</h2>*/}
            {/*    <p>*/}
            {/*      {t('subscription:total')}*/}
            {/*      {isBasic ? ' : 500MB' : ' : 2GB'}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          {isBasic ? (
            <p className="m-5 text-gray-600">{t('subscription:plan.basicPlanDescription')}</p>
          ) : (
            <div>
              <p className="m-5 text-gray-600">
                {t('subscription:plan.proPlanDescription')}
                <a href={`https://mohimma.tech/${i18n.language}/getting-started`} rel="noreferrer" target={'_blank'}>
                  {t('subscription:howTo')}
                </a>
              </p>
              <div className="flex justify-between m-6">
                {workspace?.plan?.realExpiryDate && (
                  <p className="mt-3">
                    {t('subscription:plan.expiredPlanDate')}:
                    <span className="font-semibold ms-2">
                      {format(new Date(workspace?.plan?.realExpiryDate), DATE_FORMAT)}
                    </span>
                  </p>
                )}
                <Button
                  onClick={() => {
                    history.push(`/workspace/${workspace?.id}/plans-list`)
                  }}>
                  {t('subscription:changePlan')}
                </Button>
              </div>
            </div>
          )}
        </div>
        {isBasic && <PlansList />}
      </div>
    </div>
  )
}

export default withAdminRole(WorkspacePlanPage, true)
