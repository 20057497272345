import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button, { ButtonVariant } from 'components/Buttons'
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon, TaskIcon } from 'components/Icons'
import RecordFilters from 'components/RecordFilters'
import { getCalendarTitle } from 'utils/calendarUtils'
import ViewTypeDropdown from 'components/CalendarToolbar/ViewTypeDropdown'
import { useMedia } from 'react-use'

const CalendarToolbarPortal = ({
  children,
  toolBarId,
}: {
  children: React.ReactNode,
  toolBarId: string | undefined,
}) => {
  const toolbarId = toolBarId ? toolBarId : 'list-view-toolbar'
  const toolbarParent = document.getElementById(toolbarId)
  return toolbarParent && ReactDOM.createPortal(children, toolbarParent)
}

type CustomCalendarProps = {
  toolBarId?: string,
  widgetId?: string | number,
  toolBarClassName?: string,
  calendarRef: any,
  isTaskListVisible: boolean,
  setIsTaskListVisible: any,
  isDefaultViewAgenda?: boolean,
  setCurrentViewType?: (type: string) => void,
  currentViewType: 'month' | 'week' | 'year' | 'quarter',
}

const CalendarToolbar = ({
  calendarRef,
  isTaskListVisible,
  setIsTaskListVisible,
  setCurrentViewType,
  currentViewType,
  isDefaultViewAgenda,
  toolBarId,
  widgetId,
  toolBarClassName,
}: CustomCalendarProps): any => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const { t, i18n } = useTranslation()
  const calendarApi = calendarRef?.getApi()
  const currentMonth = new Date().getMonth()
  const calendarDate = new Date(currentDate)
  const currentCalendarMonth = calendarDate?.getMonth()
  const isDesktop = useMedia('(min-width:1024px)')
  const isWidgetBar = toolBarId === `timeline-widget-${widgetId}`

  useEffect(() => {
    if (calendarRef) {
      const date = calendarRef?.getApi()?.getDate()
      setCurrentDate(date)
    }
  }, [calendarRef])

  const setView = useCallback(
    ({ value, view }) => {
      calendarApi?.changeView(view)
      setCurrentViewType?.(value)
      const date = calendarApi?.getDate()
      setCurrentDate(date)
    },
    [calendarApi, setCurrentViewType],
  )
  return (
    <div key={widgetId || 'timeLine'} className="flex">
      <CalendarToolbarPortal toolBarId={toolBarId}>
        <div className={classnames('flex flex-wrap justify-between w-full', toolBarClassName)}>
          <div className="flex flex-wrap">
            <div
              className={`flex items-center order-last w-full sm:border-gray-200 ${
                !isWidgetBar && 'sm:border-s-2'
              } sm:ms-2 lg:ms-4 sm:ps-2 lg:ps-4 sm:order-none sm:w-auto`}>
              <button
                className={classnames(
                  'md:flex items-center p-2 me-2 rounded-sm transition hover:text-primary focus:outline-none focus-visible:shadow-focus hidden',
                  {
                    'bg-tertiary-light/5 text-tertiary-light': currentCalendarMonth === currentMonth,
                  },
                )}
                onClick={() => {
                  calendarApi.today()
                  const date = calendarApi?.getDate()
                  setCurrentDate(date)
                }}>
                <CalendarIcon className="w-5 md:w-6 me-1" />
                {t('labels.today')}
              </button>
              {isDefaultViewAgenda ? null : (
                <ViewTypeDropdown
                  currentViewType={currentViewType}
                  isCalendarView={calendarApi?.view.type.includes('dayGrid')}
                  isTimelineView={calendarApi?.view.type.includes('Timeline')}
                  setView={setView}
                />
              )}
              <Button
                className="-my-1 rtl-mirror"
                icon={ChevronLeftIcon}
                variant={ButtonVariant.Icon}
                onClick={() => {
                  calendarApi?.prev()
                  setCurrentDate(calendarApi?.getDate())
                }}
              />
              <div className="flex">
                <h5 key={calendarApi?.view.type} className="text-sm md:text-base">
                  {getCalendarTitle(currentDate, i18n.language, currentViewType, !isDesktop, 'gregorian', false)}
                </h5>
                <span className="mt-1 text-xs ms-2">
                  {getCalendarTitle(currentDate, i18n.language, currentViewType, !isDesktop, 'hijri', false)}
                </span>
              </div>
              <Button
                className="-my-1 rtl-mirror"
                icon={ChevronRightIcon}
                variant={ButtonVariant.Icon}
                onClick={() => {
                  calendarApi?.next()
                  if (calendarApi.getDate()) setCurrentDate(calendarApi.getDate())
                }}
              />
            </div>
            {!isWidgetBar && (
              <div className="flex items-center border-gray-200 ps-2 lg:ps-4 border-s-2 ms-2 lg:ms-2">
                <RecordFilters />
              </div>
            )}
            <div className="ms-auto" />
          </div>
          {!isWidgetBar && (
            <button
              className={classnames(
                'flex justify-end items-center p-2 rounded-sm transition hover:text-primary focus:outline-none' +
                  ' focus-visible:shadow-focus',
                {
                  'bg-tertiary-light/5 text-tertiary-light': isTaskListVisible,
                },
              )}
              onClick={() => setIsTaskListVisible(!isTaskListVisible)}>
              <TaskIcon className="w-5 md:w-6 me-1" /> {t('records:taskList')}
            </button>
          )}
        </div>
      </CalendarToolbarPortal>
    </div>
  )
}

export default CalendarToolbar
