import React, { FC, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CustomFieldListIcon } from 'components/Icons'
import SettingsWithTabs from 'components/SettingsWithTabs'
import { withViewerRole } from 'hocs/withRole'
import { useDispatch } from 'react-redux'
import { fetchGlobalCustomFields } from '../../../features/adminElement'
import CustomFieldsForm from './CustomFieldsForm'

const CustomFieldSettingsPage: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('admin')
  const links = [
    {
      description: t('customFields:customFieldsDescription'),
      Icon: CustomFieldListIcon,
      title: t('customFields:customFields'),
      to: `/customFields/settings`,
    },
  ]

  useEffect(() => {
    dispatch(fetchGlobalCustomFields())
  }, [])

  return (
    <div className="flex flex-1 m-2 p-2">
      <div className="flex flex-col flex-1 bg-white rounded-ts-none rounded-2xl lg:flex-row">
        <SettingsWithTabs links={links} isAdminPage />
        <Switch>
          <Route path="/admin/customFields/settings">
            <CustomFieldsForm closePanel={() => console.log('close panel')} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default withViewerRole(CustomFieldSettingsPage)
