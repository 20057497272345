import { createAsyncThunk } from '@reduxjs/toolkit'
import forEach from 'lodash/forEach'

import baseAPI from 'utils/baseAPI'

export const fetchAttachmentsByRecordId = createAsyncThunk(
  'attachment/fetchAttachmentsByRecordId',
  async (recordId: string | number) => {
    const response = await baseAPI(`api/attachments/record/${recordId}`)
    return response.body
  },
)

export const createAttachment = createAsyncThunk(
  'attachment/createAttachment',
  async (newAttachment: { file: any, recordId: number }, { rejectWithValue }) => {
    const formData = new FormData()
    forEach(newAttachment, (value: any, fieldName: string) => {
      formData.append(fieldName, value)
    })
    const response = await baseAPI('api/attachments', { body: formData, method: 'POST' })
    if (response.status === 404) return rejectWithValue(404)
    return response.body
  },
)

export const removeAttachment = createAsyncThunk(
  'attachment/removeAttachment',
  async (attachmentId: number, { rejectWithValue }) => {
    const response = await baseAPI(`api/attachments/${attachmentId}`, { method: 'DELETE' })
    if (response.message !== 'attachmentRemoved') {
      return rejectWithValue('Response failed')
    }
    return attachmentId
  },
)
