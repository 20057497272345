import React, { FC } from 'react'
import { UsersTablePropsType, UserType } from 'utils/types'
import { SimpleUserType } from 'utils/types/StationType'
import classnames from 'classnames'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'

type UsernameWithAvatarType = {
  classes?: {
    wrapper?: string,
    image?: string,
    label?: string,
  },
  user: UserType | SimpleUserType | UsersTablePropsType,
}

const UsernameWithAvatar: FC<UsernameWithAvatarType> = ({
  user: { firstName, lastName, name, link, avatar, id },
  hideUserStatus,
  classes = {},
}) => (
  <div className={classnames('flex items-center', classes.wrapper)}>
    <UserAvatarStatus hideUserStatus={hideUserStatus} imgSrc={link || avatar} userId={id} />
    <span className={classnames('overflow-hidden text-sm text-ellipsis ms-2', classes.label)}>
      {name || `${firstName} ${lastName}`}
    </span>
  </div>
)

export default UsernameWithAvatar
