import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import SettingsDropdown from 'components/SettingsDropdown'
import { StackIcon } from 'components/Icons'
import { useActiveListView } from 'features/list'
import { setKanbanViewColumnsType } from '../../features/element/elementSlice'
import { getStackByOptions } from '../../utils/constant/constant/common'
import StackByList from './StackByList'

const StackBy: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('records')
  const currentView = useActiveListView()
  const [selectedFilter, setSelectedFilter] = useState<{ id: string, label: string }>()

  useEffect(() => {
    const stackByOptions = getStackByOptions({ isPro: false, currentView })
    setSelectedFilter(stackByOptions[0])
  }, [currentView])

  useEffect(() => {
    dispatch(setKanbanViewColumnsType(selectedFilter?.id))
  }, [selectedFilter])

  const DownButton = () => {
    return (
      <div className={classnames('flex items-center p-2 hover:text-primary')}>
        <StackIcon className="w-4 me-1" />
        <span>{t('stackedBy')}: </span>
        <p className="ms-3">{selectedFilter?.value ? selectedFilter?.label : t(`listing.${selectedFilter?.label}`)}</p>
      </div>
    )
  }
  return (
    <SettingsDropdown ButtonChildren={DownButton} childClasses="w-32 rounded-ts-none" wrapperClasses="">
      <StackByList setSelectedFilter={setSelectedFilter} />
    </SettingsDropdown>
  )
}

export default StackBy
