import ActivityAR from 'assets/images/dashboard/ar/activity.jpg'
import MyTasksOverviewAR from 'assets/images/dashboard/ar/myTasksOverview.jpg'
import OverallStationHealthAR from 'assets/images/dashboard/ar/overallStationHealth.jpg'
import StationListAR from 'assets/images/dashboard/ar/stationList.jpg'
import StationListsOverviewAR from 'assets/images/dashboard/ar/stationListsOverview.jpg'
import StationOverviewAR from 'assets/images/dashboard/ar/stationOverview.jpg'
import TeamMonitorAR from 'assets/images/dashboard/ar/teamMonitor.jpg'
import TextAR from 'assets/images/dashboard/ar/text.jpg'
import WorkspaceStationsOverviewAR from 'assets/images/dashboard/ar/workspaceStationOverview.jpg'
import ListStatsAR from 'assets/images/dashboard/ar/listStats.jpg'
import ListOverviewAR from 'assets/images/dashboard/ar/listOverview.jpg'
import MyTasksAR from 'assets/images/dashboard/ar/myTasks.jpg'
import DescriptionAr from 'assets/images/dashboard/ar/description.jpg'
import AvatarAr from 'assets/images/dashboard/ar/avatar.jpg'
import StationKanbanAr from 'assets/images/dashboard/ar/stationKanban.jpg'
import CustomPieAr from 'assets/images/dashboard/ar/customPie.jpg'
import CustomBigNumberAr from 'assets/images/dashboard/ar/customBarChart.jpg'
import CustomListOfRecordsAr from 'assets/images/dashboard/ar/customListOfRecords.jpg'
import CustomBarAr from 'assets/images/dashboard/ar/customBarChart.jpg'
import TimeLineAr from 'assets/images/dashboard/ar/timeline.jpg'
import GaugeChartAr from 'assets/images/dashboard/ar/gaugeChart.jpg'

import ActivityEN from 'assets/images/dashboard/en/activity.jpg'
import MyTasksOverviewEN from 'assets/images/dashboard/en/myTasksOverview.jpg'
import OverallStationHealthEN from 'assets/images/dashboard/en/overallStationHealth.jpg'
import StationListEN from 'assets/images/dashboard/en/stationList.jpg'
import StationListsOverviewEN from 'assets/images/dashboard/en/stationListsOverview.jpg'
import StationOverviewEN from 'assets/images/dashboard/en/stationOverview.jpg'
import TeamMonitorEN from 'assets/images/dashboard/en/teamMonitor.jpg'
import TextEN from 'assets/images/dashboard/en/text.jpg'
import WorkspaceStationsOverviewEN from 'assets/images/dashboard/en/workspaceStationOverview.jpg'
import ListStatsEN from 'assets/images/dashboard/en/listStats.jpg'
import ListOverviewEN from 'assets/images/dashboard/en/listOverview.jpg'
import MyTasksEN from 'assets/images/dashboard/en/myTasks.jpg'
import DescriptionEN from 'assets/images/dashboard/en/description.jpg'
import AvatarEn from 'assets/images/dashboard/en/avatar.jpg'
import StationKanbanEn from 'assets/images/dashboard/en/stationKanban.jpg'
import CustomPieEn from 'assets/images/dashboard/en/customPie.jpg'
import CustomBarEn from 'assets/images/dashboard/en/customBarChart.jpg'
import CustomBigNumberEn from 'assets/images/dashboard/en/bigNumber.jpg'
import CustomListOfRecordsEn from 'assets/images/dashboard/en/customListOfRecords.jpg'
import TimeLineEn from 'assets/images/dashboard/en/timeline.jpg'
import GaugeChartEn from 'assets/images/dashboard/en/gaugeChart.jpg'

export enum DashboardWidgetBaseTypes {
  WorkspaceStationsOverview = 0,
  Text = 1,
  StationOverview = 2,
  Activity = 3,
  MyTasksOverview = 4,
  OverallStationHealth = 5,
  StationList = 6,
  TeamMonitor = 7,
  MyDetailedRecords = 8,
  StationListsOverview = 9,
  ListStats = 10,
  ListOverview = 11,
  MyTasks = 12,
  // RecordsHealth = 13,
  Avatar = 13,
  Description = 14,
  StationKanban = 15,
  CustomPieChart = 16,
  CustomBarChart = 17,
  CustomListOfRecords = 18,
  CustomTimeLine = 19,
  BigNumbers = 20,
  GaugeWidget = 21,
}

export const dashboardWidgetTitles: Record<DashboardWidgetBaseTypes, string> = {
  [DashboardWidgetBaseTypes.WorkspaceStationsOverview]: 'stationSummary',
  [DashboardWidgetBaseTypes.Text]: 'text',
  [DashboardWidgetBaseTypes.StationOverview]: 'stationOverview',
  [DashboardWidgetBaseTypes.Activity]: 'activity',
  [DashboardWidgetBaseTypes.MyTasksOverview]: 'tasksSummary',
  [DashboardWidgetBaseTypes.OverallStationHealth]: 'overallHealth',
  [DashboardWidgetBaseTypes.StationListsOverview]: 'stationListsOverview',
  [DashboardWidgetBaseTypes.TeamMonitor]: 'teamMonitor',
  [DashboardWidgetBaseTypes.MyDetailedRecords]: 'detailedRecords',
  [DashboardWidgetBaseTypes.StationList]: 'stationList',
  // [DashboardWidgetBaseTypes.RecordsHealth]: 'recordsHealth',
  [DashboardWidgetBaseTypes.ListOverview]: 'listOverview',
  [DashboardWidgetBaseTypes.ListStats]: 'listStats',
  [DashboardWidgetBaseTypes.MyTasks]: 'myTasks',
  [DashboardWidgetBaseTypes.Description]: 'description',
  [DashboardWidgetBaseTypes.Avatar]: 'avatar',
  [DashboardWidgetBaseTypes.StationKanban]: 'stationKanban',
  [DashboardWidgetBaseTypes.CustomPieChart]: 'customPieChart',
  [DashboardWidgetBaseTypes.CustomBarChart]: 'customBarChart',
  [DashboardWidgetBaseTypes.BigNumbers]: 'customBigNumbers',
  [DashboardWidgetBaseTypes.CustomBarChart]: 'customBarChart',
  [DashboardWidgetBaseTypes.CustomListOfRecords]: 'customListOfRecords',
  [DashboardWidgetBaseTypes.CustomTimeLine]: 'customTimeLine',
  [DashboardWidgetBaseTypes.GaugeWidget]: 'gaugeWidget',
}

export const getDashboardWidgetPreviewImages = (baseType: DashboardWidgetBaseTypes, locale: string) => {
  switch (baseType) {
    case DashboardWidgetBaseTypes.WorkspaceStationsOverview:
      return locale === 'ar' ? WorkspaceStationsOverviewAR : WorkspaceStationsOverviewEN

    case DashboardWidgetBaseTypes.Text:
      return locale === 'ar' ? TextAR : TextEN

    case DashboardWidgetBaseTypes.StationOverview:
      return locale === 'ar' ? StationOverviewAR : StationOverviewEN

    case DashboardWidgetBaseTypes.Activity:
      return locale === 'ar' ? ActivityAR : ActivityEN

    case DashboardWidgetBaseTypes.MyTasksOverview:
      return locale === 'ar' ? MyTasksOverviewAR : MyTasksOverviewEN

    case DashboardWidgetBaseTypes.OverallStationHealth:
      return locale === 'ar' ? OverallStationHealthAR : OverallStationHealthEN

    case DashboardWidgetBaseTypes.StationListsOverview:
      return locale === 'ar' ? StationListsOverviewAR : StationListsOverviewEN

    case DashboardWidgetBaseTypes.TeamMonitor:
      return locale === 'ar' ? TeamMonitorAR : TeamMonitorEN

    case DashboardWidgetBaseTypes.MyDetailedRecords:
      return locale === 'ar' ? '' : ''

    case DashboardWidgetBaseTypes.StationList:
      return locale === 'ar' ? StationListAR : StationListEN

    case DashboardWidgetBaseTypes.ListStats:
      return locale === 'ar' ? ListStatsAR : ListStatsEN

    case DashboardWidgetBaseTypes.ListOverview:
      return locale === 'ar' ? ListOverviewAR : ListOverviewEN

    case DashboardWidgetBaseTypes.MyTasks:
      return locale === 'ar' ? MyTasksAR : MyTasksEN

    case DashboardWidgetBaseTypes.Description:
      return locale === 'ar' ? DescriptionAr : DescriptionEN

    case DashboardWidgetBaseTypes.Avatar:
      return locale === 'ar' ? AvatarAr : AvatarEn

    case DashboardWidgetBaseTypes.StationKanban:
      return locale === 'ar' ? StationKanbanAr : StationKanbanEn

    case DashboardWidgetBaseTypes.CustomPieChart:
      return locale === 'ar' ? CustomPieAr : CustomPieEn

    case DashboardWidgetBaseTypes.CustomBarChart:
      return locale === 'ar' ? CustomBarAr : CustomBarEn

    case DashboardWidgetBaseTypes.CustomListOfRecords:
      return locale === 'ar' ? CustomListOfRecordsAr : CustomListOfRecordsEn

    case DashboardWidgetBaseTypes.BigNumbers:
      return locale === 'ar' ? CustomBigNumberEn : CustomBigNumberEn

    case DashboardWidgetBaseTypes.CustomTimeLine:
      return locale === 'ar' ? TimeLineAr : TimeLineEn

    case DashboardWidgetBaseTypes.GaugeWidget:
      return locale === 'ar' ? GaugeChartAr : GaugeChartEn
  }
}
