import React, { memo, useEffect, useState } from 'react'
import {
  createDocumentationNewPage,
  getStationDocOneItem,
  getStationDocSidebar,
  updateDocumentationDocument,
  useCurrentStation,
  useStationDocumentationOneItem,
} from 'features/station'
import useViewerPermission from 'hooks/useViewerPermission'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams, Route } from 'react-router-dom'
import { useLocation } from 'react-use'
import { useAppDispatch } from 'store'
import Loader from '../../Loader'
import DocumentEditor from './DocumentEditor'
import UnsavedWarningModal from './UnsavedWarningModal'

const DocumentationDocumentEdit = ({ hasChanges, setHasChanges }) => {
  const { i18n } = useTranslation()
  const [isSaveOpen, setSaveOpen] = useState(false)
  const appDispatch = useAppDispatch()
  const currentStation = useCurrentStation()
  const page = useStationDocumentationOneItem()
  const { workspaceId, stationId, docId, id } = useParams<{
    stationId: string,
    workspaceId: string,
    docId: string,
    id: string,
  }>()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  //TODO: fix parent
  const queryParams = new URLSearchParams(location.search)
  const parentId = queryParams.get('parent')
  const allowUserAccess = useViewerPermission()

  useEffect(() => {
    if (!allowUserAccess && currentStation !== undefined) {
      history.push(`/`)
    }
  }, [allowUserAccess, currentStation])

  useEffect(() => {
    if (!page) dispatch(getStationDocOneItem(+id))
  }, [page])

  const isRtl = i18n.language === 'ar'
  const linkTodocs = `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`

  const handleSave = async (content: string, title: string, removedFiles: string[]) => {
    const docPageProps = {
      docPage: { content, removedFiles, title },
      parentId: +docId,
    }
    docPageProps.id = +id

    const res = await appDispatch(updateDocumentationDocument(docPageProps))
    if (res.meta.requestStatus === 'rejected') {
      setSaveOpen(false)
      return
    }
    setHasChanges(false)
    appDispatch(getStationDocSidebar({ docId: +docId, archiveStatus: 0 })).then(() => {
      history.push(`${linkTodocs}/view/${+id}`)
    })
  }

  const title = +id === 0 ? '<h1></h1>' : `<h1 style="margin-bottom: 10px">${page?.title}</h1>`
  const content = +id !== 0 ? (page && page.content ? page.content : '') : ''

  if (!allowUserAccess) {
    return null
  }

  if (+id !== 0 && !page) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loader loaderClasses="w-20" />
      </div>
    )
  }

  return (
    <div className="w-full bg-white">
      <DocumentEditor
        key={isRtl + ''}
        data={title + content}
        documentId={+id}
        exitWithoutSave={() => {
          // dispatch(getStationDocSidebar(+docId))
          if (parseInt(id)) {
            history.push(`${linkTodocs}/view/${id}`)
          } else {
            history.push(`${linkTodocs}/home`)
          }
          setHasChanges(false)
        }}
        isRtl={isRtl}
        isSaveOpen={isSaveOpen}
        saveChanges={handleSave}
        setHasChanges={() => setHasChanges(true)}
        setSaveOpen={setSaveOpen}
      />
      <UnsavedWarningModal hasChanges={hasChanges} />
    </div>
  )
}

export default memo(DocumentationDocumentEdit)
