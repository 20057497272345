import { FC } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { PasswordInput } from 'components/inputs'
import Button from 'components/Buttons'
import Loader from 'components/Loader'
import baseAPI from 'utils/baseAPI'

import { profileResetPasswordValidationSchema } from '../validationSchemas'

const ProfileResetPasswordForm: FC = () => {
  const { t } = useTranslation()
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setSubmitting } = useFormik({
    initialValues: {
      confirmPassword: '',
      currentPassword: '',
      newPassword: '',
    },
    onSubmit: (values, actions) => {
      const userId = localStorage.getItem('userId')
      if (userId) {
        baseAPI(`api/users/passwordChange/${userId}`, {
          body: {
            password: values.newPassword,
            previousPassword: values.currentPassword,
          },
          method: 'PUT',
        }).then((res) => {
          setSubmitting(false)
          if (res.body === 'Current password invalid') {
            actions.setFieldError('currentPassword', "Password value doesn't match current password")
          } else {
            actions.resetForm()
          }
        })
      }
    },
    validationSchema: profileResetPasswordValidationSchema,
  })

  return (
    <div className="w-full p-8 overflow-auto">
      <h1 className="mb-6 text-lg">{t('user:password:header')}</h1>
      <form onSubmit={handleSubmit}>
        <PasswordInput
          error={errors.currentPassword}
          label={t('user:password:currentPassword')}
          name="currentPassword"
          touched={touched.currentPassword}
          value={values.currentPassword}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <PasswordInput
          error={errors.newPassword}
          label={t('user:password:newPassword')}
          name="newPassword"
          touched={touched.newPassword}
          value={values.newPassword}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <PasswordInput
          error={errors.confirmPassword}
          label={t('user:password:confirmPassword')}
          name="confirmPassword"
          touched={touched.confirmPassword}
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <Button disabled={isSubmitting} type="submit">
          {!isSubmitting && t('user:password:submit')}
          {isSubmitting && <Loader loaderClasses="flex justify-center" svgClasses="h-6" />}
        </Button>
      </form>
    </div>
  )
}

export default ProfileResetPasswordForm
