import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Button, { ButtonVariant } from 'components/Buttons'
import { AddIcon } from 'components/Icons'
import { useActiveListViewId, useCurrentList } from '../../features/list'
import { RecordType } from '../../utils/types'
import { fetchRecordById, onChangeRecordParent, setCurrentRecord, useRecordsChildren } from '../../features/record'
import { useAppDispatch } from '../../store'
import ElementOperations from '../../utils/constant/enums/element'
import SubtaskItem from './SubtaskItem'
import AddSubtaskModal from './AddSubtaskModal'

type UserAvatarPropTypes = {
  elementId: string | number,
  isEditor: boolean,
  currentRecord: RecordType,
}

const SubTasks: FC<UserAvatarPropTypes> = ({ elementId, isEditor, currentRecord }) => {
  const { t } = useTranslation('records')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const viewId = useActiveListViewId()
  const viewInUrl = viewId ? `/${viewId}` : ''
  const recordSubtasks = useRecordsChildren()
  const currentList = useCurrentList()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAddingSubtask, setIsAddingSubtask] = useState(false)
  const [selectedElements, setSelectedElements] = useState([])
  const subTasksList = recordSubtasks[elementId] || []
  const { pathname } = useLocation()

  const onTitleClick = (path: any[], task: RecordType) => {
    history.push(`${pathname}?recordId=${+task.id}`)
    setTimeout(() => {
      dispatch(setCurrentRecord(task.id))
    }, 1000)
  }

  const onSubmit = useCallback(() => {
    if (selectedElements?.length > 0) {
      setIsSubmitting(true)
      const elementId = +selectedElements[0]?.element?.id
      const body = {
        appElementId: Number(elementId),
        newParentId: +currentRecord?.appElements[0]?.id,
        oldParentId: selectedElements[0].parentId,
        operation: ElementOperations.MOVE,
      }
      dispatch(onChangeRecordParent(body)).finally(() => {
        setIsSubmitting(false)
        setIsAddingSubtask(false)
        if (currentRecord?.list !== undefined) {
          dispatch(fetchRecordById({ details: true, id: currentRecord?.id }))
        }
      })
    }
  }, [selectedElements])

  const addSubtasksModalProps = {
    currentListAppElementId: +currentList?.appElement?.id,
    currentRecord,
    isModalOpen: isAddingSubtask,
    isSubmitting,
    onCancel: () => setIsAddingSubtask(false),
    onSubmit,
    selectedElements,
    setSelectedElements,
  }

  return (
    <div>
      {subTasksList.length > 0 && (
        <div className="flex flex-1 min-h-0 overflow-y-auto max-h-36">
          <div className="w-full grid grid-cols-1">
            {subTasksList?.map((task: RecordType, idx: number) => {
              const legacyPath = task?.appElements[0]?.legacyPath?.split('.')
              return (
                <SubtaskItem
                  key={`${idx}.${task?.id}`}
                  index={idx}
                  isEditor={isEditor}
                  task={task}
                  onTitleClick={() => onTitleClick(legacyPath, task)}
                />
              )
            })}
          </div>
        </div>
      )}

      {isEditor && (
        <Button
          className="mt-1"
          icon={AddIcon}
          small
          variant={ButtonVariant.Ghost}
          onClick={() => {
            setIsAddingSubtask(true)
          }}>
          {t('addSubtask')}
        </Button>
      )}
      {isAddingSubtask && <AddSubtaskModal {...addSubtasksModalProps} />}
    </div>
  )
}

export default SubTasks
