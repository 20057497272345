import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { addCommentValidationSchema } from 'components/forms/validationSchemas'
import { MultilineInputWithMentions } from 'components/inputs'

import { useUserAvatar } from 'features/user'
import { addComment } from 'features/comment'
import { withButtonsAndContentEditable } from 'hocs'
import { addStationDocsOneComment } from 'features/station'
import RecordDescriptionRTEwithMentions from '../RecordDescriptionRTEwithMentions'
import { CommentResponseType } from '../../utils/constant/enums/common'

// For some reason, deleting this next line causes the Tests to give an error
const ContentEditableInputWithButtons = withButtonsAndContentEditable(MultilineInputWithMentions)
const EditableMultilineWithButtons = withButtonsAndContentEditable(RecordDescriptionRTEwithMentions)

const AddCommentForm: FC<{
  recordId?: string | number,
  documentId?: string | number,
  showLatest: boolean,
  elementId: string | number,
  autoFocus?: boolean,
  onCancel?: () => void,
  responseTargetId?: number,
}> = ({ recordId, documentId, showLatest, elementId, autoFocus = false, onCancel, responseTargetId }) => {
  const avatar = useUserAvatar()
  const { t } = useTranslation('records')
  const dispatch = useDispatch()
  const [commentValue, setCommentValue] = useState('')
  const newCommentRef = useRef<any>(null)

  useEffect(() => {
    if (autoFocus) newCommentRef.current.focus()
  }, [])

  const handleAddComment = useCallback(
    (values) => {
      if (documentId) {
        dispatch(
          addStationDocsOneComment({
            documentId,
            inResponseTo: autoFocus && responseTargetId ? CommentResponseType.COMMENT : null,
            responseTargetId,
            text: values.comment,
          }),
        )
      } else {
        dispatch(
          addComment({
            appElementId: +elementId || '',
            inResponseTo: autoFocus && responseTargetId ? CommentResponseType.COMMENT : null,
            responseTargetId,
            showLatest,
            text: values.comment,
          }),
        )
      }
      if (onCancel) onCancel()
      setCommentValue('')
      newCommentRef.current?.resetForm()
    },
    [recordId, dispatch, documentId, showLatest],
  )

  return (
    <div className="flex mt-4">
      <img alt="user avatar" className="w-8 h-8 rounded-full me-4" loading="lazy" src={avatar} />
      <div className="w-full min-w-0">
        <EditableMultilineWithButtons
          autoFocus={true}
          inputWrapperClassName="mb-0"
          isRichTextEditor
          label={t('common:labels.description')}
          name="comment"
          ref={newCommentRef}
          submitButtonLabel={t('common:labels.add')}
          validationSchema={addCommentValidationSchema}
          value={commentValue}
          onCancel={() => {
            if (onCancel) {
              onCancel()
            } else {
              setCommentValue('')
            }
          }}
          onChange={(e) => setCommentValue(e.target.value)}
          onSubmit={handleAddComment}
        />
      </div>
    </div>
  )
}

export default AddCommentForm
