import React, { FC, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-use'
import sortBy from 'lodash/sortBy'
import { DashboardStateWidgetType } from 'utils/types/states'
import Select, { StylesConfig } from 'react-select'
import { attributesStatuses, stationStates } from 'utils/constant/resources/stationResorces'
import { StationListWidgetType } from 'utils/types/states/DashboardStateWidgetType'
import { widgetSelectStyles } from 'utils/helpers/dashboardHelpers'
import { max, range } from 'lodash'
import classNames from 'classnames'
import CustomScroll from '../../../CustomScroll'
import { StackIcon } from '../../../Icons'
import StationType from '../../../../utils/types/StationType'
import { useCurrentWorkspace } from '../../../../features/workspace'
import { CustomFieldOptionItemType, CustomFieldType } from '../../../../utils/types/CustomFieldsType'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import Loader from '../../../Loader'
import { useAppDispatch } from '../../../../store'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import { customFieldBaseTypes } from '../../../../utils/constant/enums/customFields'
import SmallStationCard from './SmallStationCard'

const groupByOption = (arr: any[], option: { value: string, label: string, list?: CustomFieldType[] }) => {
  if (!option || !arr || !arr.length) {
    return { data: null, translatePath: null }
  }
  if (option.value === '__nullGrouping__') {
    return {
      data: { 'dashboard:groupByOptions.__nullGrouping__': arr },
      translatePath: null,
    }
  }
  const obj: Record<string, any> = {}

  for (let i = 0; i < arr.length; i++) {
    const el = arr[i]
    let keyFromEl = el[option.value]

    const customFieldValue = el?.customFieldValues?.find((field: any) => +field.customFieldId === +option.value)

    if (keyFromEl === null || keyFromEl === undefined) {
      keyFromEl = 0
    } else {
      keyFromEl += 1
    }
    if (option.value !== 'manager' && customFieldValue === undefined) {
      if (option.value !== 'state') {
        keyFromEl = attributesStatuses[keyFromEl].label
      }

      if (option.value === 'state') {
        keyFromEl = stationStates[keyFromEl].label
      }

      if (obj[keyFromEl]) {
        obj[keyFromEl] = [...obj[keyFromEl], el]
      } else {
        obj[keyFromEl] = [el]
      }
    } else if (customFieldValue !== undefined) {
      // If record has custom field selected value then group it
      const optionObj = option?.list?.find(
        (op: CustomFieldOptionItemType) => +op.id === +customFieldValue?.customFieldOptionId,
      )

      const optionKey = optionObj?.optionCaption
      if (optionKey) {
        if (obj[optionKey]) {
          obj[optionKey] = [...obj[optionKey], el]
        } else {
          obj[optionKey] = [el]
        }
      }
    } else {
      const manager = el[option.value]
      const managerKey = manager?.firstName + ' ' + manager?.lastName

      if (obj[managerKey]) {
        obj[managerKey] = [...obj[managerKey], el]
      } else {
        obj[managerKey] = [el]
      }
    }
  }

  let translatePath = null

  if (option.value === 'manager') {
    translatePath = null
  } else if (option.value !== 'state') {
    translatePath = 'dashboard'
  } else if (option.value === 'state') {
    translatePath = 'station:stationStates'
  }

  return { data: obj, translatePath }
}

const StationKanbanWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const { t, i18n } = useTranslation(['dashboard', 'station', 'stationSettings'])
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const selectBasicOptions: any = [
    { label: t('dashboard:groupByOptions.state'), value: 'state' },
    // { label: t('dashboard:groupByOptions.__nullGrouping__'), value: '__nullGrouping__' },
    { label: t('dashboard:groupByOptions.manager'), value: 'manager' },
    { label: t('dashboard:groupByOptions.status'), value: 'status' },
    { label: t('dashboard:groupByOptions.budgetHealth'), value: 'budgetHealth' },
    { label: t('dashboard:groupByOptions.qualityHealth'), value: 'qualityHealth' },
  ]
  const currentWorkspace = useCurrentWorkspace()
  const customFieldsList = currentWorkspace?.customFields?.native?.fields
    ?.filter((field: CustomFieldType) => field.baseType === customFieldBaseTypes.DropDown)
    .map((f: CustomFieldType) => ({ label: f.fieldName, list: f.customFieldOptions, value: f.id }))
  const selectOptions = customFieldsList ? [...selectBasicOptions, ...customFieldsList] : [...selectBasicOptions]
  const [selectedOption, selectOption] = useState(selectOptions[0])
  const isRtl = i18n.language === 'ar'
  const { data }: any = widget || widgetInfo
  const StationKanbanWidgetData: StationListWidgetType = data
  const sortedStationKanbanWidgetValues = sortBy(StationKanbanWidgetData?.values, 'elementOrder')
  const groupedObj = groupByOption(sortedStationKanbanWidgetValues, selectedOption)
  const listLength = groupedObj?.data ? Object.keys(groupedObj?.data)?.map((key) => groupedObj?.data[key]?.length) : 1
  const container = document.querySelector('#station-kanban .simplebar-content-wrapper')
  const cardNumber = range(1, max(listLength) + 1) // check what is the max list length
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  useEffect(() => {
    if (container) container.scrollTo({ behavior: 'smooth', top: 500 })
  }, [container])
  return (
    <>
      {sized}
      {isDataLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            {groupedObj.data ? (
              <div className="flex items-center mb-2 cursor-pointer">
                <div style={{ height: 20, width: 20 }}>
                  <StackIcon />
                </div>
                <Select
                  className="w-30"
                  classNamePrefix="react-select"
                  defaultValue={{ ...selectedOption, label: t(`dashboard:groupByOptions.${selectedOption.value}`) }}
                  isRtl={isRtl}
                  menuPortalTarget={document.body}
                  name="sort-select"
                  options={selectOptions}
                  styles={widgetSelectStyles}
                  onChange={(e) => {
                    selectOption(e)
                  }}
                />
              </div>
            ) : null}
          </div>
          {groupedObj.data && (
            <CustomScroll hideScroll={isPrinting} id={'station-kanban'} maxHeight={'80%'} classes="w-full">
              {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
                <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
              )}
              <div className="flex flex-1 min-h-0 mt-10">
                <div>
                  {cardNumber.reverse().map((num) => (
                    <div
                      key={num}
                      className={classNames(`flex items-center border-gray-400 pe-1`, {
                        'border-l-2': isRtl,
                        'border-r-2': !isRtl,
                      })}
                      style={{ minHeight: `84px` }}>
                      {num}
                    </div>
                  ))}
                </div>
                <div className="relative flex">
                  {groupedObj?.data &&
                    Object.keys(groupedObj?.data)?.map((option, index) => (
                      <div
                        key={index}
                        className="flex flex-col-reverse"
                        style={{
                          display: 'flex',
                          'flex-direction': 'column',
                          'justify-content': 'flex-end',
                        }}>
                        {groupedObj.data[option].reverse()?.map((station: StationType, idx: number) => {
                          return (
                            <div key={idx} className="p-1 w-36 station-small-card">
                              <SmallStationCard station={station} />
                            </div>
                          )
                        })}
                        <div className="w-full pt-1 text-center border-t-2 border-gray-400">
                          {groupedObj.translatePath ? t(`${groupedObj.translatePath}:${option}`) : t(option)}
                          <p className="text-xs text-gray-500">{groupedObj?.data[option].length}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </CustomScroll>
          )}
        </>
      )}
    </>
  )
}

export default memo(StationKanbanWidget)
