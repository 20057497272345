import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import classnames from 'classnames'

import SignInPage from 'pages/login/SignInPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage'
import ResetPasswordPage from 'pages/login/ResetPasswordPage'
import ConfirmationPage from 'pages/ConfirmationPage'
import AccountConfirmedPage from 'pages/login/AccountConfirmedPage'
import ResendEmailPage from 'pages/login/ResendEmailPage'
import SetPhoneMfaPage from 'pages/login/SetPhoneMfaPage'
import SmsValidationPage from 'pages/login/SmsValidationPage'
import { MohimmatechLogoWhite, MohimmatechLogoText } from 'components/Icons'
import { configValues } from 'utils/appConfig'

const LoginLayout: FC = () => {
  return (
    <main className="flex min-h-screen">
      <div
        className={classnames('flex-1 hidden lg:block', {
          'custom-login-bg': configValues.REACT_APP_IS_CUSTOM_BRANDING,
          'login-bg': !configValues.REACT_APP_IS_CUSTOM_BRANDING,
        })}
        style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.loginBgClasses } : {}}>
        <MohimmatechLogoWhite className="mt-8 w-52 ms-10" data-testid="mohimmatech-logo" />
      </div>
      <div className="flex flex-col items-center justify-center flex-1 bg-white">
        <MohimmatechLogoText className="lg:hidden w-60 mt-14" data-testid="mohimmatech-logo-text" />
        <div className="w-full max-w-xs py-20 mx-10 lg:max-w-md">
          <Switch>
            <Route path="/sign-in">
              <SignInPage />
            </Route>
            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route path="/reset-password/:token">
              <ResetPasswordPage />
            </Route>
            <Route path="/set-phone-mfa/:token">
              <SetPhoneMfaPage />
            </Route>
            <Route path="/confirmation-message">
              <ConfirmationPage />
            </Route>
            <Route path="/account-confirmed">
              <AccountConfirmedPage />
            </Route>
            <Route path="/resend-email">
              <ResendEmailPage />
            </Route>
            <Route path="/mfa/:token">
              <SmsValidationPage />
            </Route>
          </Switch>
        </div>
      </div>
    </main>
  )
}

export default LoginLayout
