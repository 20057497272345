import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ElementType from '../../utils/types/ElementType'
import SelectRowItem from '../Dashboard/Widgets/CustomWidgets/ConfiguringWidget/SelectRowItem'

interface ElementsListProps {
  setSelectedElements: (e: any) => void;
  setSelectedTimeLineItems?: (e: any) => void;
  selectedElements: any[];
  selectedTimeLineItems: any[];
  elementBaseType: number;
  renderDirectionIcon?: (value: boolean) => void;
  setRelinkedItem?: (e: any) => void;
  setVisible?: (e: any) => void;
  onClickItem: (item: ElementType) => void;
  list: any[];
  isStartFromRootElement?: boolean;
  options?: {
    canAddRecord: boolean,
    isSingleSelect: boolean,
    shouldShowPreview: boolean,
  };
}

const ElementsList: FC<ElementsListProps> = ({
  setSelectedElements,
  selectedElements,
  selectedTimeLineItems,
  elementBaseType,
  list,
  setVisible,
  onClickItem,
  setRelinkedItem,
  renderDirectionIcon,
  setSelectedTimeLineItems,
  isStartFromRootElement,
  options = {
    canAddRecord: true,
    isSingleSelect: false,
    shouldShowPreview: true,
  },
}) => {
  const { i18n } = useTranslation('documentation')
  const isRtl = i18n.language === 'ar'

  const onClickFirstDiv = (isItemSelected: boolean, item: any) => {
    if (isStartFromRootElement) {
      if (!isItemSelected) onClickItem(item)
    } else {
      onClickItem(item)
    }
  }

  const onClickSecondDiv = (item: any) => {
    if (item.hasLinkedDoc === true && setVisible) setVisible(true)
  }

  const radioChangeHandle = (e: any, isItemSelected: boolean, item: any) => {
    e.stopPropagation()
    if (!isItemSelected) {
      setSelectedElements([item])
    }
  }

  const checkboxChangeHandle = (e: any, isItemSelected: boolean, item: any) => {
    e.stopPropagation()
    // if it calls from timeline widget
    if (setSelectedTimeLineItems) {
      if (isItemSelected) {
        setSelectedTimeLineItems([...selectedTimeLineItems].filter((i: any) => +i.id !== +item.id))
      } else {
        const listItems = [...selectedTimeLineItems].filter((i) => +i.id !== +item.id)
        setSelectedTimeLineItems([...listItems, { ...item, isSelected: true }])
      }
    } else {
      // if it not calls from timeline widget
      if (isItemSelected) {
        setSelectedElements([...selectedElements].filter((i: any) => +i.id !== +item.id))
      } else if (item.hasDocument && setVisible && setRelinkedItem) {
        setVisible(true)
        setRelinkedItem(item)
      } else {
        const listItems = [...selectedElements].filter((i) => +i.id !== +item.id)
        setSelectedElements([...listItems, { ...item, isSelected: true }])
      }
    }
  }

  return (
    <>
      {list?.map((item: any, index: number) => {
        const resultList = setSelectedTimeLineItems ? selectedTimeLineItems : selectedElements
        const itemData = resultList?.find((i: any) => +i?.id === +item?.id)
        const isItemSelected = isStartFromRootElement ? itemData?.isSelected : itemData !== undefined
        return (
          <SelectRowItem
            key={`${index}${isRtl}`}
            checkboxChangeHandle={checkboxChangeHandle}
            elementBaseType={elementBaseType}
            isSelected={isItemSelected}
            isStartFromRootElement={isStartFromRootElement}
            item={item}
            name={`${item.id}-${item.title}`}
            options={options}
            radioChangeHandle={radioChangeHandle}
            showNextIcon={item.basedType !== elementBaseType}
            onClickFirstDiv={onClickFirstDiv}
            onClickSecondDiv={onClickSecondDiv}
          />
          // <div
          //   key={`${index}${isRtl}`}
          //   aria-disabled={isStartFromRootElement && isItemSelected}
          //   className="flex items-center justify-between w-full p-2 rounded cursor-pointer hover:bg-gray-200"
          //   onClick={() => onClickFirstDiv(isItemSelected, item)}>
          //   <div className={`flex ${item.hasDocument && 'opacity-50'}`} onClick={(e) => onClickSecondDiv(e, item)}>
          //     {(item.basedType === elementBaseType || isStartFromRootElement) &&
          //       (options.isSingleSelect ? (
          //         <Radio
          //           checked={isItemSelected}
          //           className="custom-radio"
          //           name="singleLink"
          //           value={+item.id}
          //           onChange={(e) => radioChangeHandle(e, isItemSelected, item)}
          //         />
          //       ) : (
          //         <Checkbox
          //           checked={isItemSelected}
          //           isFullRoundedBorder={true}
          //           name={`${item.id}-${item.title}`}
          //           onChange={(e) => checkboxChangeHandle(e, isItemSelected, item)}
          //         />
          //       ))}
          //     <p className="text-sm">{item.title || item.label || item.name}</p>
          //   </div>
          //   {item.basedType !== elementBaseType && (
          //     <div className={`${isItemSelected && 'opacity-50'}`}>{renderDirectionIcon(isRtl)}</div>
          //   )}
          // </div>
        )
      })}
    </>
  )
}

export default ElementsList
