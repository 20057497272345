import React, { FC, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'

import SingleComment from 'components/CommentList/SingleComment'
import { fetchCommentsByRecordId, useRecordComments } from 'features/comment'
import { CommentType } from 'utils/types'
import { getStationDocComments, useShowLatest, useStationDocumentationComments } from 'features/station'

const CommentList: FC<{
  recordId?: string | number,
  showLatest: boolean,
  isRecordEditable: boolean,
  dataInsteadRecordId?: CommentType[],
  documentId?: string,
  elementId?: string,
  isRecordComment?: boolean,
}> = ({ recordId = 0, showLatest, dataInsteadRecordId, documentId, isRecordEditable, elementId, isRecordComment }) => {
  const comments = useRecordComments(recordId)
  const docsComments = useStationDocumentationComments()
  const docShowLatest = useShowLatest()
  const dispatch = useDispatch()

  useEffect(() => {
    if (recordId && elementId) {
      dispatch(fetchCommentsByRecordId({ elementId, showLatest }))
    }

    if (documentId) {
      dispatch(getStationDocComments({ documentId, showLatest: !showLatest }))
    }
  }, [dispatch, recordId, showLatest, documentId, docShowLatest])

  const onScrollView = (id: string) => {
    const targetSection = document.getElementById(id)
    if (targetSection) targetSection.scrollIntoView()
  }

  const getCommentsList = () => {
    if (dataInsteadRecordId) {
      return dataInsteadRecordId
    }

    if (documentId) {
      return docsComments
    }

    return comments
  }

  const commentList = getCommentsList()

  if (isEmpty(commentList)) return null

  return (
    <>
      {commentList &&
        commentList?.map((comment) => (
          <SingleComment
            key={comment.id}
            comment={comment}
            documentId={documentId}
            elementId={elementId}
            isRecordComment={isRecordComment}
            isRecordEditable={isRecordEditable}
            isActivityWidget={Boolean(dataInsteadRecordId?.length > 0)}
            isDocumentation={Boolean(documentId)}
            isEdit={isRecordEditable !== undefined ? isRecordEditable : Boolean(!dataInsteadRecordId)}
            recordId={recordId}
            onScrollView={onScrollView}
            showLatest={showLatest}
          />
        ))}
    </>
  )
}

export default CommentList
