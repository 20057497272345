import React, { FC, memo, useRef, useState } from 'react'
import useElementSize from 'hooks/useElementSize'
import Button, { ButtonVariant } from 'components/Buttons'
import { AddBlueIcon, PencilMediumIcon, SaveIcon, SignOutIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import DashboardAvailableWidgets from 'utils/types/states/DashboardAvailableWidgets'
import ConfirmationModal from '../ConfirmationModal'
import dashboardStateType from '../../utils/types/states/DashboardStateType'
import { StationType, WorkspaceType } from '../../utils/types'
import AddWidgetModal from './AddWidgetModal'

interface ModalOptions {
  message?: string;
  onConfirm?: () => void;
  confirmMessage?: string;
}

const DashboardEditHeader: FC<{
  dashboard: dashboardStateType,
  closeEdit: () => void,
  saveChanges: () => void,
  addWidget: (widgets: DashboardAvailableWidgets) => void,
  getAvailableWidgets: () => void,
  availableWidgets: DashboardAvailableWidgets[] | null,
  dashboardWidgets: DashboardStateWidgetType[],
  elementType: 's' | 'w' | 'l',
  currentWorkspace: WorkspaceType,
  currentStation: StationType,
}> = ({
  closeEdit,
  saveChanges,
  availableWidgets,
  addWidget,
  getAvailableWidgets,
  dashboardWidgets,
  elementType,
  dashboard,
  currentWorkspace,
  currentStation,
}) => {
  const { t, i18n } = useTranslation('dashboard')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [confirmationModalOpenOptions, setConfirmationModalOpenOptions] = useState<ModalOptions>({})
  const [isModalOpen, setModalOpen] = useState(false)
  const ref = useRef(null)
  const { width: headerWidth } = useElementSize(ref)

  const toggleModal = () => {
    if (!isModalOpen) {
      getAvailableWidgets()
    }
    setModalOpen(!isModalOpen)
  }

  const onClick = (options: any) => {
    setConfirmationModalOpenOptions(options)
    setIsConfirmationModalOpen(true)
  }

  const showModal = (confirmationModalOpenOptions: ModalOptions) => {
    if (confirmationModalOpenOptions.message && confirmationModalOpenOptions.onConfirm) {
      return (
        <ConfirmationModal
          buttonVariant={confirmationModalOpenOptions.buttonVariant}
          confirmMessage={confirmationModalOpenOptions.confirmMessage}
          confirmationMessage={t(`${confirmationModalOpenOptions.message}`, {
            interpolation: { escapeValue: false },
            name: 'Confirm',
          })}
          isModalOpen={isConfirmationModalOpen}
          onCancel={confirmationModalOpenOptions.onCancel}
          onConfirm={confirmationModalOpenOptions.onConfirm}
        />
      )
    }
  }

  const onClose = () => {
    onClick({
      confirmMessage: t('exitButton'),
      message: t('exitConfirmationMessage'),
      onCancel: () => {
        setIsConfirmationModalOpen(false)
      },
      onConfirm: closeEdit,
    })
  }

  const onSave = () => {
    onClick({
      buttonVariant: ButtonVariant.Primary,
      confirmMessage: t('saveButton'),
      message: t('saveConfirmationMessage'),
      onCancel: () => {
        closeEdit()
        setIsConfirmationModalOpen(false)
      },
      onConfirm: saveChanges,
    })
  }

  const isStation = elementType === 's'
  const addWidgetModalProps = {
    addWidget,
    availableWidgets,
    close: toggleModal,
    currentStation,
    currentWorkspace,
    dashboard,
    dashboardWidgets,
    isStation,
    isModalOpen,
  }
  return (
    <header
      className={`${
        isStation ? 'py-2.5 px-5' : 'py-3 mr-1 mb-5 ml-1'
      } flex flex-wrap items-center justify-between bg-white`}
      ref={ref}>
      <div className={`flex items-center ${!isStation && 'px-5'}`}>
        <div className="flex items-center space-x-4">
          <div
            className={`flex items-center justify-center ${
              isStation ? 'w-9 h-9' : 'w-10 h-10'
            } bg-gray-300 rounded-full`}>
            <PencilMediumIcon />
          </div>
          <div className={`${i18n.language === 'ar' ? 'pl-3 pr-4' : ''}`}>
            {isStation ? (
              <>
                <h2 className="w-full text-gray-800 me-auto">{t('dashboardTitle')}</h2>
                <h4 className="text-sm text-gray-700">{t('editMode')}</h4>
              </>
            ) : (
              <>
                <h1 className="w-full mb-1 text-gray-800 me-auto xs:mb-0">{t('dashboardTitle')}</h1>
                <h3 className="text-gray-700">{t('editMode')}</h3>
              </>
            )}
          </div>
        </div>
        {headerWidth > 900 ? (
          <div className="items-center h-full ms-7 ps-5 border-s border-black-600 md:flex">
            <Button icon={AddBlueIcon} small={isStation} variant={ButtonVariant.Icon} onClick={toggleModal}>
              {t('addWidget')}
            </Button>
          </div>
        ) : null}
      </div>
      {headerWidth > 900 ? (
        <div className={`flex h-full ${!isStation && 'px-5'}`}>
          <div
            className={`${
              i18n.language === 'ar' ? 'ml-5 pl-7 border-l' : 'ml-7 pr-5 border-r'
            }  border-black-600 md:flex items-center`}>
            <Button icon={SaveIcon} small={isStation} variant={ButtonVariant.Primary} onClick={onSave}>
              {t('saveAndFinish')}
            </Button>
          </div>
          <div className="items-center md:flex">
            <Button
              icon={SignOutIcon}
              small={isStation}
              variant={isStation ? ButtonVariant.Ghost : ButtonVariant.Icon}
              onClick={onClose}>
              &nbsp; {t('exitWithoutSave')}
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`flex ${isStation ? 'm-0.5' : 'mx-4'} ${headerWidth < 396 && !isStation ? 'mt-4' : ''} space-x-2`}>
          <Button
            className={`${i18n.language === 'ar' ? 'ml-2' : ''}`}
            icon={AddBlueIcon}
            variant={ButtonVariant.IconRoundedFilled}
            onClick={toggleModal}
          />
          <Button icon={SaveIcon} small={isStation} variant={ButtonVariant.IconRoundedFilled} onClick={onSave} />
          <Button icon={SignOutIcon} variant={ButtonVariant.IconRoundedOutlined} onClick={onClose} />
        </div>
      )}
      <AddWidgetModal {...addWidgetModalProps} />
      {showModal(confirmationModalOpenOptions)}
    </header>
  )
}

export default memo(DashboardEditHeader)
