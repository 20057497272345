import React, { FC } from 'react'
import classnames from 'classnames'

import { useUserById } from 'features/user'
import { AgendaItemType } from 'utils/types'
import KanbanColumnItem from 'components/Kanban/KanbanColumnItem'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getPriorityIconByValue } from '../../utils/helpers/recordHelpers'
import LabelsBarList from '../Kanban/LabelsBarList'
import ProgressBar from '../ProgressBar'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'
import { useRecords } from '../../features/record'

const AgendaItem: FC<AgendaItemType> = (props) => {
  const {
    statusId,
    ownerId,
    innerRef,
    handleEdit,
    title,
    publicId,
    draggableProps,
    dragHandleProps,
    record,
    onRightClickItem,
    ...rest
  } = props
  const records = useRecords()
  const owner = useUserById(ownerId)
  const history = useHistory()
  const { pathname } = useLocation()
  const { Icon, color, label } = getPriorityIconByValue(record?.priority === null ? null : +record?.priority)
  const isMobile = window.innerWidth <= 760

  const renderWebView = () => {
    const UserAvatarStatusProps = {
      customSize: 10,
      imgAlt: `${owner?.name}`,
      imgSrc: owner?.avatar,
      minWidth: 40,
      removeIconCustomSize: 2.5,
      userId: owner?.id,
    }
    const hasParent = record?.appElements[0]?.elementLevel > 4
    const parent = hasParent ? records.find((rec) => +rec.appElements[0]?.id === +record?.appElements[0].parentId) : ''
    return (
      <div className="items-center justify-center p-1 mb-1 bg-white rounded lg:flex lg:items-stretch transition pe-2">
        <div
          className="relative flex-1 px-2 bg-white lg:py-2.5 sm:py-2"
          style={!isMobile ? { maxWidth: '50%', minWidth: '50%' } : null}>
          {hasParent && (
            <div
              className="block px-1 text-xs text-center text-gray-600 truncate bg-gray-200 rounded cursor-pointer max-w-50 w-min py-0.5 text-start hover:text-primary hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                history.push(`${pathname}?recordId=${parent?.id}`)
              }}>
              {parent?.name}
            </div>
          )}
          <LabelsBarList labelsIds={record?.labelsIds} />
          {title}
        </div>
        <div
          className="flex items-center justify-start px-2 py-1.5"
          style={!isMobile ? { maxWidth: '10%', minWidth: '10%' } : null}>
          {' '}
          <div>
            <Icon
              className={classnames('w-4 me-1', {
                '-ms-1': record?.priority === null,
              })}
              style={{ color }}
            />{' '}
          </div>
          <div className="text-sm">{label}</div>
        </div>
        <div
          className="flex items-center justify-center px-2 text-center py-1.5"
          style={!isMobile ? { maxWidth: '28%', minWidth: '28%' } : null}>
          <ProgressBar completedIn={record?.completedIn} health={record?.health} id={record?.id} />
        </div>
        <div className="flex items-center justify-center" style={!isMobile ? { maxWidth: '7%', minWidth: '7%' } : null}>
          <UserAvatarStatus {...UserAvatarStatusProps} />
        </div>
      </div>
    )
  }

  const renderMobileView = () => <KanbanColumnItem {...record} />

  return (
    <div
      key={publicId}
      ref={innerRef}
      onClick={() => handleEdit(+publicId)}
      onContextMenu={(e) => {
        e.preventDefault()
        onRightClickItem(props?.record, { x: e.pageX, y: e.pageY })
      }}
      {...draggableProps}
      {...dragHandleProps}
      {...rest}>
      {!isMobile ? renderWebView() : renderMobileView()}
    </div>
  )
}

export default AgendaItem
