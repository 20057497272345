import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import baseAPI from 'utils/baseAPI'

import { PasswordInput } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'
import { resetPasswordValidationSchema } from 'components/forms/validationSchemas'
import { handleAuthResponse } from 'utils/helpers/authHelpers'

const ResetPasswordForm = () => {
  const { t } = useTranslation('signIn')
  const history = useHistory()
  const location = useLocation()
  const { token } = useParams<{ token: string }>()
  const [showLoading, setShowLoading] = useState(false)
  const { values, handleChange, handleBlur, touched, handleSubmit, errors } = useFormik({
    initialValues: {
      confirmPassword: '',
      newPassword: '',
    },
    onSubmit: async (values) => {
      setShowLoading(true)
      const response = await baseAPI('auth/changePassword', {
        body: {
          password: values.newPassword,
          resetPasswordToken: token,
        },
        method: 'POST',
      })

      handleAuthResponse({ response, setLoading: setShowLoading, history, location })
    },
    validationSchema: resetPasswordValidationSchema,
  })

  return (
    <form onSubmit={handleSubmit}>
      <PasswordInput
        error={errors.newPassword}
        label={t('common:labels.newPassword')}
        name="newPassword"
        touched={touched.newPassword}
        value={values.newPassword}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Button disabled={showLoading} full type="submit">
        {t('common:labels.save')}
        {showLoading ? <Loader /> : null}
      </Button>

      <Button variant={ButtonVariant.Ghost} full className="mt-5 text-sm" onClick={() => history.push('/sign-in')}>
        {t('signIn:goBackToSignIn')}
      </Button>
    </form>
  )
}

export default ResetPasswordForm
