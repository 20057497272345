import { createSlice } from '@reduxjs/toolkit'
import ElementStateType from '../../utils/types/states/ElementStateType'
import { fetchGlobalCustomFields } from './actions'

const initialState: ElementStateType = {
  isRejected: false,
  loading: false,
  customFields: [],
}

export const adminElementSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalCustomFields.fulfilled, (state, action) => {
      state.customFields = action.payload
      state.loading = false
    })
    builder.addCase(fetchGlobalCustomFields.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchGlobalCustomFields.rejected, (state) => {
      state.isRejected = true
      state.loading = false
    })
  },
  initialState,
  name: 'adminElement',
  reducers: {
    clearCustomFields: (state) => {
      state.customFields = []
    },
  },
})

export const { clearCustomFields } = adminElementSlice.actions

export default adminElementSlice.reducer
