import { useState, useRef, useEffect, useMemo, FC } from 'react'
import Image from '@tiptap/extension-image'
import classnames from 'classnames'
import Mention from '@tiptap/extension-mention'
import Blockquote from '@tiptap/extension-blockquote'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import tippy from 'tippy.js'
import Underline from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import { Color } from '@tiptap/extension-color'
import { useEditor, EditorContent, ReactRenderer, ReactRendererOptions } from '@tiptap/react'

import { RTEPropType } from 'utils/types'
import { MentionList } from './MentionList'
import TextEditToolbar from './TextEditToolbar'

const RichTextEditorWithMentions: FC<RTEPropType> = ({
  content,
  editorWrapperClasses,
  onFocus,
  onChange,
  usersList,
  onBlur,
}) => {
  useEffect(() => {
    onFocus?.()
  }, [onFocus])

  const rteRef = useRef(null)
  const [isInverted, setIsInverted] = useState(false)

  // change editor position in grid view
  // if it is too low in the grid to display fully
  // position bottom of it on the bottom of the cell
  useEffect(() => {
    if (rteRef.current) {
      const rteRect = rteRef.current.getBoundingClientRect()
      const { top, height } = rteRect
      if (window.innerHeight - top - 200 < height) {
        setIsInverted(true)
      }
    }
  })

  const usersToMention = useMemo(() => usersList.map((user) => ({ id: user.id, label: user.name })), [usersList])

  const editor = useEditor({
    autofocus: 'end',
    content,
    extensions: [
      Blockquote,
      Color,
      StarterKit,
      Image,
      TextStyle,
      Underline,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      TextAlign.configure({
        alignments: ['start', 'center', 'end', 'justify'],
        types: ['heading', 'paragraph'],
      }),
      Mention.configure({
        HTMLAttributes: {
          class: `mention`,
        },
        suggestion: {
          items: ({ query }) => {
            return usersToMention
              .filter((item: any) => item.label.toLowerCase().startsWith(query.toLowerCase()))
              .slice(0, 9)
          },
          render: () => {
            let reactRenderer: ReactRendererOptions
            let popup: any[]

            return {
              onExit() {
                popup[0].destroy()
                reactRenderer.destroy()
              },
              onKeyDown(props) {
                return reactRenderer.ref?.onKeyDown(props)
              },
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  editor: props.editor,
                  props,
                })

                popup = tippy('body', {
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  getReferenceClientRect: props.clientRect,
                  interactive: true,
                  placement: 'bottom-start',
                  showOnCreate: true,
                  trigger: 'manual',
                })
              },
              onUpdate(props) {
                reactRenderer.updateProps(props)

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                })
              },
            }
          },
        },
      }),
    ],
    onUpdate: (data) => {
      onChange({ target: { name: 'description', value: data.editor.getHTML() } })
    },
  })

  const classNames = classnames(
    'w-full text-gray-600 bg-white border-2 border-gray-300 rounded focus-within:border-primary min-h-12 leading-6',
    editorWrapperClasses,
    {
      'bottom-0': isInverted,
      'top-0': !isInverted,
    },
  )

  return (
    <div onBlur={onBlur} className={classNames} ref={rteRef}>
      <TextEditToolbar editor={editor} />
      <EditorContent editor={editor} data-testid="description-input" />
    </div>
  )
}

export default RichTextEditorWithMentions
