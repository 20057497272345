import { FC, FunctionComponent, SVGAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { getFormattedDateWithTime } from 'utils/helpers/generalHelpers'
import { useCurrentUser } from '../../features/user'

type SettingsWithTabsType = {
  links: {
    description: string,
    Icon: FunctionComponent<SVGAttributes<SVGElement>>,
    title: string,
    to: string,
  }[],
  createdAt?: Date,
}

const SettingsWithTabs: FC<SettingsWithTabsType> = ({ links, createdAt }) => {
  const { t } = useTranslation('common')
  const { i18n } = useTranslation()
  const language = i18n.language
  const user = useCurrentUser()
  const isHijri = user.isHijri

  return (
    <div className="flex flex-col flex-none pt-4 bg-white border-b-2 border-gray-200 lg:w-72 lg:border-b-0 lg:border-e-2 solid">
      <ul>
        {links.map(
          ({ title, to, description, Icon }: { title: string, to: string, description: string, Icon?: any }) => (
            <li key={to}>
              <NavLink
                activeClassName="bg-tertiary-light/5 text-tertiary-light"
                className="flex items-center p-4 transition transition-colors hover:text-primary focus:text-primary"
                replace
                to={to}>
                {Icon != null && <Icon className="shrink-0 w-6 me-2" />}
                <div>
                  <p className="text-sm font-bold">{title}</p>
                  <small className="block text-xs text-gray-600">{description}</small>
                </div>
              </NavLink>
            </li>
          ),
        )}
      </ul>
      {createdAt && (
        <div className="p-4 pb-6 mt-auto text-sm text-gray-400">
          <span>{t('labels.createdAt')} </span>
          <span dir="auto">{getFormattedDateWithTime(createdAt, isHijri, language)}</span>
        </div>
      )}
    </div>
  )
}

export default SettingsWithTabs
