import React, { FC } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { DefaultNotEditingComponentType } from 'utils/types/inputs'
import { formatTextWithMentions } from 'utils/helpers/mentionHelper'
import { useCurrentUser } from 'features/user'
import DOMPurify from 'dompurify'
import { InputLabel } from '.'

const DefaultNotEditingComponent: FC<DefaultNotEditingComponentType> = ({
  name,
  className,
  disabled,
  truncated = false,
  placeholder,
  isOnClickDisabled,
  isRichTextEditor,
  tabIndex,
  value,
  showInput,
  shouldShowPlaceholder = true,
  style,
  required,
  labelClasses,
  label,
  disableHover,
  ...rest
}) => {
  const { id: currentUserId } = useCurrentUser()
  const { t } = useTranslation()

  const props = {
    'aria-labelledby': `contenteditable-${name}`,
    className: classnames(className, 'break-words', {
      'focus:bg-white leading-6 px-2 py-2.5 -mt-1 -ms-2 -mb-1 min-h-12 border-2 border-transparent hover:border-dashed rounded hover:border-gray-300 focus:outline-none focus:border-gray-400 cursor-pointer transition transition-colors':
        !disabled && !isOnClickDisabled,
      'hover:bg-white': !disableHover,
      'rte-output': isRichTextEditor,
      'whitespace-pre-line': !truncated,
    }),
    'data-testid': rest['data-testid'],
    onClick: () => {
      if (!isOnClickDisabled && !disabled) showInput()
    },
    role: 'textbox',
    style,
    tabIndex,
  }

  const valueSpanClasses = 'my-auto text-gray-400'

  return value != undefined && currentUserId !== undefined ? (
    <>
      <InputLabel className={labelClasses} label={label} name={name} required={required} />
      <p
        {...props}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            formatTextWithMentions(`${value}`, currentUserId) ||
              (shouldShowPlaceholder
                ? `<span class='${valueSpanClasses}'>${placeholder || t(`labels.${name}`)}</span>`
                : ''),
          ),
        }}></p>
    </>
  ) : (
    <>
      <InputLabel className={labelClasses} label={label} name={name} required={required} />
      <p {...props} {...rest}>
        {value != undefined
          ? value
          : shouldShowPlaceholder && <span className={valueSpanClasses}>{placeholder || t(`labels.${name}`)}</span>}
      </p>
    </>
  )
}

export default DefaultNotEditingComponent
