import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import StateType from 'utils/types/states'

const getListsRejected = (state: StateType) => state.list.isRejected
const getWorkspacesRejected = (state: StateType) => state.workspace.isRejected
const getStationRejected = (state: StateType) => state.station.isRejected

const isRejectedSelector = createSelector(
  [getListsRejected, getStationRejected, getWorkspacesRejected],
  (isListRejected, isStationRejected, isWorkspaceRejected) =>
    Boolean(isListRejected || isStationRejected || isWorkspaceRejected),
)

export const useIsRejected = (): boolean => useSelector(isRejectedSelector)
