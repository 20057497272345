import React, { FC } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../store'
import AddLinkForm from '../Documentation/DocumetationsDocument/LinkComponents/AddLinkForm'
import { RecordType } from '../../utils/types'
import { onChangeRecordParent } from '../../features/record'
import ElementOperations from '../../utils/constant/enums/element'

type EditCustomFieldModalPropsType = {
  onCancel: () => void,
  isModalOpen: boolean,
  listElementId: string | number,
  currentRecord: RecordType,
}

const AddSubtaskParentModal: FC<EditCustomFieldModalPropsType> = ({
  onCancel,
  isModalOpen,
  listElementId,
  currentRecord,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const changeParent = (parentId: number) => {
    const elementId = +currentRecord.appElements[0].id
    const body = {
      appElementId: +elementId,
      newParentId: +parentId,
      operation: ElementOperations.MOVE,
      oldParentId: currentRecord?.appElements[0]?.parentId,
    }
    dispatch(onChangeRecordParent(body))
    onCancel()
  }

  const onRemoveParent = () => {
    changeParent(+listElementId)
  }

  const onSubmit = (recordsElements: any[]) => {
    const newParentId = +recordsElements[0]?.element?.id
    changeParent(newParentId)
  }

  const addLinkProps = {
    confirmationTitle: t('common:labels.save'),
    currentRecord,
    elementBaseType: 3,
    emptyPreviewMessage: t('records:emptySubtasksPreviewMessage'),
    level: 3,
    lowestLevel: 3,
    onClose: onCancel,
    onRemoveParent,
    onSubmit,
    options: {
      canAddRecord: false,
      isSingleSelect: true,
      shouldShowPreview: false,
    },
    parentElementId: +listElementId,
    title: t('common:labels.changeParent'),
  }
  return (
    <Modal
      className="fixed top-0 bottom-0 flex w-full bg-white border-white rounded shadow left-2/4 transform -translate-x-2/4 focus:outline-none md:max-w-lg md:top-20 md:bottom-20"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onCancel}>
      <AddLinkForm {...addLinkProps} />
    </Modal>
  )
}

export default AddSubtaskParentModal
