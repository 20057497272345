import React, { FC, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import StationsByWorkspaceListPage from 'pages/workspace/StationsByWorkspaceListPage'
import { fetchStationsByWorkspaceId } from 'features/station'
import { clearCurrentStation } from 'features/station/stationSlice'
import { useCurrentWorkspace } from '../../features/workspace'
import SingleStationRouter from './SingleStationRouter'

const StationRouter: FC = () => {
  const {
    path,
    params: { workspaceId },
  }: { path: string, params: { workspaceId: string } } = useRouteMatch()
  const currentWorkspace = useCurrentWorkspace()
  const isArchivedByWorkspace = +currentWorkspace?.appElement?.archivedById === +currentWorkspace?.appElement?.id
  const dispatch = useDispatch()
  const [isArchived, setIsArchived] = useState(isArchivedByWorkspace)
  useEffect(() => {
    dispatch(fetchStationsByWorkspaceId({ isArchived, workspaceId: +workspaceId }))
    return () => {
      dispatch(clearCurrentStation())
    }
  }, [dispatch, workspaceId, isArchived])

  const stationsListProps = {
    isArchived,
    setIsArchived,
    isArchivedByWorkspace,
  }

  return (
    <Switch>
      {/* render single station or stations listing depending on the url */}
      <Route path={`${path}/:stationId`}>
        <SingleStationRouter />
      </Route>
      <Route path={path}>
        <StationsByWorkspaceListPage {...stationsListProps} />
      </Route>
    </Switch>
  )
}
export default StationRouter
