import { FC, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import CreatableSelect, { Creatable } from 'react-select/creatable'
import { OptionTypeBase } from 'react-select'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

import { createListLabel, useCurrentListLabels, useLabelsByIds } from 'features/list'
import { RecordType } from 'utils/types'
import { useAppDispatch } from 'store'
import { updateRecordById, updateRecordElementById } from 'features/record'
import LabelComponent from 'components/inputs/LabelsSelect/LabelComponent'
import ColoredDropdownOption from 'components/inputs/LabelsSelect/ColoredDropdownOption'
import { createLabelDropdownValues } from 'utils/helpers/labelsHelpers'

const LabelsSelect: FC<{
  fullRecord: RecordType,
  isDisabled?: boolean,
  shouldShowPlaceholder?: boolean,
}> = ({ fullRecord, isDisabled, shouldShowPlaceholder = false }) => {
  const { listId: paramsListId, viewId }: { listId: string, stationId: string, viewId: string } = useParams()
  const labelsRef = useRef<Creatable<OptionTypeBase, false>>(null)
  const listLabels = useCurrentListLabels()
  const recordLabels = useLabelsByIds(fullRecord?.labelsIds)
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('records')

  const labelsDropdownOption = useMemo(() => createLabelDropdownValues(listLabels), [listLabels])
  const value = useMemo(() => createLabelDropdownValues(recordLabels), [recordLabels])

  const onOptionCreate = async (inputValue: string) => {
    const results = await appDispatch(createListLabel({ listId: fullRecord.listId || +paramsListId, name: inputValue }))
    const newLabel: { id: number, name: string } = await unwrapResult(results)
    const existingLabels = fullRecord?.labelsIds || []
    // appDispatch(
    //   updateRecordById({
    //     changes: { labelsIds: [...existingLabels, newLabel.id] },
    //     initialRecord: fullRecord,
    //   }),
    // )
    appDispatch(
      updateRecordElementById({
        changes: { labelsIds: [...existingLabels, newLabel.id] },
        elementId: fullRecord?.appElements[0]?.id,
        viewId: viewId,
        record: fullRecord,
      }),
    )
  }

  return (
    <div className="w-full">
      <CreatableSelect
        className="-mt-2 -ms-2 -mb-2"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        components={{
          MultiValue: LabelComponent,
          // eslint-disable-next-line react/display-name
          Option: (props) => <ColoredDropdownOption {...props} isLabelDropdown />,
        }}
        isClearable
        isDisabled={isDisabled}
        isMulti
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name="labels"
        options={labelsDropdownOption}
        placeholder={shouldShowPlaceholder ? t('placeholderSelect') : ''}
        ref={labelsRef}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={value}
        onChange={(values: any) => {
          const labelsIds = values.map(({ value }: { value: number }) => +value)
          // appDispatch(updateRecordById({ changes: { labelsIds }, initialRecord: fullRecord }))
          appDispatch(
            updateRecordElementById({
              changes: { labelsIds },
              elementId: fullRecord?.appElements[0]?.id,
              viewId,
              record: fullRecord,
            }),
          )
        }}
        onCreateOption={onOptionCreate}
        onKeyDown={(event: KeyboardEvent) => {
          if (event.key === 'Enter' || event.key === 'Escape') {
            labelsRef.current?.blur()
          }
        }}
      />
    </div>
  )
}

export default LabelsSelect
