import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { findLastKey, mapKeys } from 'lodash'
import { addCommentValidationSchema } from 'components/forms/validationSchemas'
import ConfirmationModal from 'components/ConfirmationModal'
import {
  fetchCommentsByRecordId,
  onDeleteAssetsFromComment,
  onUploadAssetsToComment,
  removeComment,
  updateComment,
} from 'features/comment'
import { useCurrentUser, useUserById } from 'features/user'
import { withButtonsAndContentEditable } from 'hocs'
import { CommentType, UploadingFilesType, UsersTablePropsType } from 'utils/types'
import { StationDocumentationCommentsType } from 'utils/types/states/StationDocumentationComments'
import {
  getStationDocComments,
  removeStationDocsOneComment,
  removeStationDocsTextSelectionInDoc,
  updateStationDocsOneComment,
} from 'features/station'
import { useCurrentRecordsCustomFields } from 'features/record'
import { createAsset, deleteAsset } from 'features/element'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import Button, { ButtonVariant } from 'components/Buttons'
import { ArrowUturnRight, CrossSignIcon, VectorIcon } from 'components/Icons'
import classNames from 'classnames'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import head from 'lodash/head'
import { toast } from 'react-toastify'
import { CommentResponseType } from 'utils/constant/enums/common'
import RequestRaw from 'components/MakerCheckerPolicy/Request/RequestRaw'
import { getFormattedDateWithTime } from '../../utils/helpers/generalHelpers'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'
import RecordDescriptionRTEwithMentions from '../RecordDescriptionRTEwithMentions'
import { useAppDispatch } from '../../store'
import DashboardWidgetType from '../../utils/types/DashboardWidgetType'
import { directoryValidator, MAX_FILE_SIZE } from '../forms/AddRecordToListForm'
import { AppElementAssetTypes } from '../../utils/types/AssetsType'
import { configValues } from '../../utils/appConfig'
import { AddCommentForm } from '../forms'
import ExtendedComment from './ExtendedComment'

const EditableMultilineWithButtons = withButtonsAndContentEditable(RecordDescriptionRTEwithMentions)

type Comment = CommentType & StationDocumentationCommentsType

const SingleComment: FC<{
  comment: Comment,
  recordId?: string,
  documentId?: string,
  isEdit?: boolean,
  isActivityWidget?: boolean,
  isDocumentation?: boolean,
  isRecordEditable?: boolean,
  showLatest?: boolean,
  elementId: string,
  onScrollView?: (id: string) => void,
  isRecordComment?: boolean,
}> = ({
  comment,
  isEdit = true,
  isActivityWidget,
  isRecordEditable = false,
  isDocumentation = false,
  elementId,
  recordId,
  documentId,
  showLatest,
  onScrollView,
  isRecordComment,
}) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false)
  const { t } = useTranslation('records')
  const { i18n } = useTranslation()
  const { id } = useCurrentUser()
  const inputRef = useRef<any>()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const [isInputFocus, setInputFocus] = useState(false)
  const [commentValue, setCommentValue] = useState(comment.text)
  const [isReplayOpen, setIsReplayOpen] = useState(false)
  const [extendComment, setExtendComment] = useState(false)
  const [filesBeingUploaded, setFilesBeingUploaded] = useState<UploadingFilesType>({})
  const filesBeingUploadedRef = useRef(filesBeingUploaded)
  const [showExtend, setShowExtend] = useState(true)
  const responseObject = comment?.responseObject
  const user = useCurrentUser()
  const language = i18n.language
  const isHijri = user.isHijri
  const userById: UsersTablePropsType = useUserById(comment.creatorId)
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)

  const creator = useMemo(() => {
    if (!userById) {
      return comment.creator
    }
    return userById
  }, [comment.creatorId])

  const deleteComment = (commentId: any) => {
    if (!isDocumentation) {
      dispatch(removeComment(commentId))
    } else {
      dispatch(removeStationDocsOneComment(commentId))
    }
  }

  const editComment = (updatedCommentContent: string) => {
    const currentComment: any = comment
    if (!isDocumentation) {
      const recordComment: CommentType = currentComment
      const payload = { ...recordComment, appElementId: +recordComment.appElementId, text: updatedCommentContent }
      dispatch(updateComment(payload))
    } else {
      const docsComment: StationDocumentationCommentsType = currentComment
      const payload = { ...docsComment, text: updatedCommentContent }
      dispatch(updateStationDocsOneComment(payload))
    }
    setShowExtend(true)
    setInputFocus(false)
  }

  const UserAvatarStatusProps = {
    imgSrc: creator?.avatar,
    isRemovedUser: userById === undefined,
    userId: comment.creatorId,
  }

  const userName =
    creator !== undefined
      ? creator?.name || creator?.firstName + ' ' + creator?.lastName
      : comment?.firstName + ' ' + comment?.lastName

  const isEditMode = isEdit && !showExtend

  const onUploadAsset = () => {
    if (recordId) {
      dispatch(fetchCommentsByRecordId({ elementId, showLatest }))
    } else if (documentId) {
      dispatch(getStationDocComments({ documentId, showLatest: !showLatest }))
    }
  }

  const onDeleteAsset = (id: string) => {
    appDispatch(deleteAsset({ assetId: Number(id) })).then(() => {
      if (recordId) {
        dispatch(fetchCommentsByRecordId({ elementId, showLatest }))
      } else if (documentId) {
        dispatch(getStationDocComments({ documentId, showLatest: !showLatest }))
      }
    })
  }

  const uploadAttachment = useCallback(
    (filesToUpload, fileKey) => {
      const file = filesToUpload[+fileKey]
      const fileType = file?.type?.split('/')[0]
      const assetType = AppElementAssetTypes[fileType] || AppElementAssetTypes.generic
      appDispatch(createAsset({ upload: file, elementId: Number(elementId), assetType, commentId: comment.id })).then(
        () => {
          const filesCopy = { ...filesBeingUploadedRef.current }
          delete filesCopy[+fileKey]
          updateFilesState(filesCopy)
          onUploadAsset()
        },
      )
    },
    [appDispatch],
  )

  const onAttachmentDrop = useCallback(
    (files) => {
      if (comment !== undefined) {
        const noCurrentlyUploadedFiles = findLastKey(filesBeingUploadedRef.current) || -1
        const filesToUpload = mapKeys(files, (file, key) => {
          return +key + +noCurrentlyUploadedFiles + 1
        })
        updateFilesState({ ...filesBeingUploadedRef.current, ...filesToUpload })
        Object.keys(filesToUpload).forEach((fileKey: string) => uploadAttachment(filesToUpload, fileKey))
      }
    },
    [comment, uploadAttachment],
  )

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach(({ code, message }) => {
          switch (code) {
            case ErrorCode.FileTooLarge:
              return toast.error(t('attachmentTooBig', { name: file.name }), { toastId: file.name })
            case 'directory-upload':
              return toast.error(t('folderUploadError'), { toastId: 'folderUploadError' })
            default:
              return toast.error(message)
          }
        })
      })
    },
    [t],
  )

  const updateFilesState = (newState: any) => {
    filesBeingUploadedRef.current = newState
    setFilesBeingUploaded(newState)
  }

  const { getInputProps } = useDropzone({
    disabled: !isEdit,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    noClick: true,
    onDrop: onAttachmentDrop,
    onDropRejected,
    validator: directoryValidator,
  })

  const onEdit = () => {
    inputRef?.current?.focus()
    setShowExtend(false)
  }

  const onReplay = () => {
    setIsReplayOpen(!isReplayOpen)
  }

  const inputProps = getInputProps()
  const assetsList = comment?.commentAssets || comment?.appElementAssets

  const ResponseObjectBody = () => {
    if (comment.inResponseTo === CommentResponseType.COMMENT) {
      const responseComment = responseObject
      return (
        <>
          <label className="text-sm font-bold">{`${responseComment.creator.firstName} ${responseComment.creator.lastName}`}</label>
          <div className="truncate-comment" dangerouslySetInnerHTML={{ __html: responseComment.text }} />
        </>
      )
    } else if (comment.inResponseTo === CommentResponseType.CHANGE_REQUEST) {
      return <RequestRaw request={responseObject} isSimple={true} customFields={fieldsList} />
    } else return null
  }

  return (
    <div id={`${comment.id}`} className="mb-2">
      {responseObject && (
        <div className="flex flex-1 ms-4 justify-start items-center">
          <div className="shrink-0">
            <ArrowUturnRight className="w-5 rtl-mirror" />
          </div>
          <div
            className="w-full min-w-0 ms-2 px-3 rounded bg-gray-100 border-s-2 border-primary"
            onClick={() => {
              if (comment.inResponseTo === CommentResponseType.COMMENT && onScrollView)
                onScrollView(`${responseObject.id}`)
            }}>
            <ResponseObjectBody />
          </div>
        </div>
      )}
      <div className={`flex mb-6 group last:mb-0 ${responseObject && 'p-1'}`}>
        <UserAvatarStatus {...UserAvatarStatusProps} />
        <div className="w-full min-w-0 ms-2">
          <div className="flex items-baseline justify-between my-1">
            <label className="text-sm font-bold me-2">{userName}</label>
            {comment.createdAt && (
              <span className="my-1 text-xs">{getFormattedDateWithTime(comment.createdAt, isHijri, language)}</span>
            )}
          </div>
          {comment.selection && (
            <div className="flex mb-2">
              <div
                className="truncate"
                style={{
                  background: '#ffff0039',
                  borderBottom: '3px solid yellow',
                  width: 220,
                }}>
                {comment.selection.text}
              </div>
              {isInputFocus && (
                <Button
                  icon={CrossSignIcon}
                  small
                  variant={ButtonVariant.Ghost}
                  onClick={() => dispatch(removeStationDocsTextSelectionInDoc(comment.selection?.id))}
                />
              )}
            </div>
          )}
          <div className="flex justify-between">
            <div className="max-w-full" style={{ width: '-webkit-fill-available' }}>
              <>
                {!extendComment && (
                  <>
                    <EditableMultilineWithButtons
                      data-testid="description-input"
                      getIsFocused={(value) => {
                        if (value) {
                          onEdit()
                        }
                      }}
                      id={'comment_input'}
                      inputWrapperClassName="mb-0"
                      isOnClickDisabled={
                        isRecordEditable !== undefined && +id === +comment.creatorId
                          ? !isRecordEditable
                          : (+id !== +comment.creatorId && isEdit) || isActivityWidget || isRecordEditable
                      }
                      isRichTextEditor
                      name="comment"
                      notEditingClassName={`mb-0 cut-comment-text`}
                      ref={inputRef}
                      submitButtonLabel={t('common:labels.add')}
                      validationSchema={addCommentValidationSchema}
                      value={commentValue}
                      onCancel={() => {
                        setInputFocus(false)
                        setShowExtend(true)
                        setCommentValue(comment.text)
                      }}
                      onChange={(e) => {
                        setCommentValue(e.target.value)
                      }}
                      onSubmit={(values) => editComment(values.comment)}
                    />
                    {showExtend && (
                      <p className="mb-1 text-xs mt-1.5 opacity-60">
                        {' '}
                        {assetsList?.length || 0} {t('records:listing.attachments')}
                      </p>
                    )}
                    {isEditMode && (
                      <ExtendedComment
                        assetsList={assetsList}
                        comment={comment}
                        elementId={elementId}
                        filesBeingUploaded={filesBeingUploaded}
                        inputProps={inputProps}
                        isActivityWidget={isActivityWidget}
                        isDocumentation={isDocumentation}
                        isEdit={isEdit}
                        showExtend={showExtend}
                        onDeleteAsset={onDeleteAsset}
                        onDropRejected={onDropRejected}
                        onUploadAsset={onUploadAsset}
                        isRecordComment={isRecordComment}
                      />
                    )}
                  </>
                )}
                {extendComment && (
                  <ExtendedComment
                    assetsList={assetsList}
                    comment={comment}
                    elementId={elementId}
                    filesBeingUploaded={filesBeingUploaded}
                    inputProps={inputProps}
                    isActivityWidget={isActivityWidget}
                    isDocumentation={isDocumentation}
                    isEdit={isEdit}
                    showExtend={showExtend}
                    onDeleteAsset={onDeleteAsset}
                    onDropRejected={onDropRejected}
                    onUploadAsset={onUploadAsset}
                    isRecordComment={isRecordComment}
                  />
                )}

                <div
                  className={classNames(
                    'flex justify-between items-center transition-opacity duration-100 opacity-0 group-hover:opacity-100',
                    {
                      'mt-2': isDocumentation,
                    },
                  )}>
                  <div className="flex">
                    {id === +comment.creatorId && isEdit && showExtend && (
                      <>
                        {id === +comment.creatorId && (
                          <button
                            className="text-sm hover:text-primary transition-colors transition me-2"
                            type="button"
                            onClick={() => {
                              if (extendComment) {
                                setExtendComment(false)
                                setTimeout(() => {
                                  onEdit()
                                }, 500)
                              } else {
                                onEdit()
                              }
                            }}>
                            {t('common:labels.edit')}
                          </button>
                        )}
                        <input id={`${comment?.id}_commentAttachment`} {...inputProps} />
                        <label
                          className="flex cursor-pointer hover:text-primary transition transition-colors"
                          htmlFor={`${comment?.id}_commentAttachment`}>
                          <p className="text-sm me-2">{t('records:addAttachment')}</p>
                        </label>
                        <button
                          className="text-sm hover:text-danger transition-colors transition me-2"
                          type="button"
                          onClick={() => setIsConfirmationModalVisible(true)}>
                          {t('common:labels.delete')}
                        </button>
                        <ConfirmationModal
                          confirmationMessage={t('removeCommentConfirmMessage')}
                          isModalOpen={isConfirmationModalVisible}
                          onCancel={() => setIsConfirmationModalVisible(false)}
                          onConfirm={() => {
                            deleteComment(comment.id)
                            setIsConfirmationModalVisible(false)
                          }}
                        />
                      </>
                    )}
                    <button
                      className="text-sm hover:text-primary transition-colors transition"
                      type="button"
                      onClick={onReplay}>
                      {t('common:labels.reply')}
                    </button>
                  </div>
                  {showExtend && (
                    <div className="flex justify-end">
                      <VectorIcon
                        className={classNames(
                          'w-4 hover:text-primary cursor-pointer ms-3 opacity-30',
                          { 'text-primary opacity-100': extendComment },
                          { '-ms-32': isActivityWidget || isDocumentation },
                        )}
                        onClick={() => setExtendComment(!extendComment)}
                      />
                    </div>
                  )}
                </div>
              </>
              {isReplayOpen && (
                <AddCommentForm
                  autoFocus={true}
                  elementId={elementId}
                  documentId={documentId}
                  recordId={recordId}
                  responseTargetId={Number(comment.id)}
                  showLatest={showLatest}
                  onCancel={() => setIsReplayOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleComment
