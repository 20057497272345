import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store'
import { fetchStationById, useCurrentStation, useCurrentStationLoading } from 'features/station'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { useParams } from 'react-router-dom'
import Loader from '../Loader'
import CreateCustomFieldButton from './CreateCustomFieldButton'
import ListCustomFieldItem from './ListCustomFieldItem'

const StationCustomFieldsForm: FC<{ isViewer: boolean }> = ({ isViewer }) => {
  const { stationId }: { stationId: string } = useParams()

  const { t } = useTranslation('customFields')
  const appDispatch = useAppDispatch()
  const station = useCurrentStation()
  const isLoading = useCurrentStationLoading()
  const listCustomFields = station?.customFields

  const onRefetch = () => {
    appDispatch(fetchStationById(+station?.id))
  }

  useEffect(() => {
    appDispatch(fetchStationById(stationId))
  }, [stationId])

  if (isLoading) return <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />

  return (
    <div className="w-full p-8 m-4 bg-white rounded-2xl rounded-ts-none">
      <div className="flex items-center justify-between">
        <label className="block mb-2 text-lg font-bold">{t('customFields:customFields')}</label>
        {!isViewer && (
          <CreateCustomFieldButton
            currentItem={station}
            elementBaseType={AppElementBaseTypes.StationAppElement}
            showIcon={true}
            showText={false}
            onRefetch={onRefetch}
          />
        )}
      </div>
      <div className="overflow-y-auto" style={{ height: '90%', minHeight: '90%' }}>
        {listCustomFields?.native?.count > 0 ? (
          listCustomFields?.native?.fields?.map((field: CustomFieldType, index: number) => (
            <>
              <ListCustomFieldItem
                key={`${index} ${field.id}`}
                currentItem={station}
                customFieldItem={field}
                elementBaseType={AppElementBaseTypes.StationAppElement}
                isViewer={isViewer}
                item={station?.id}
                onRefetch={onRefetch}
              />
            </>
          ))
        ) : (
          <div className="flex items-center justify-center w-full h-full text-sm opacity-50">{t('noCustomFields')}</div>
        )}
      </div>
    </div>
  )
}

export default StationCustomFieldsForm
