import { FC, useMemo } from 'react'
import classnames from 'classnames'

import {
  commonStyles,
  editStyles,
  navbarStyles,
  tileStyles,
  workspaceStyles,
  isMobileStyles,
  stationStyles,
} from 'components/EntryAvatar/EntryAvatar'
import { hexToRgb } from 'utils/helpers/colorHelpers'
import { getInitialsFromName } from 'utils/helpers/generalHelpers'

const EntryInitials: FC<{
  name: string,
  color: string,
  isTileInitials?: boolean,
  isNavbar?: boolean,
  isEdit?: boolean,
  isWorkspace?: boolean,
  isStation?: boolean,
  isMobile?: boolean,
}> = ({ color, name, isTileInitials, isNavbar, isEdit, isWorkspace, isStation, isMobile }) => {
  const bgColorRgb = useMemo(() => hexToRgb(color), [color])

  const style = {
    '--bg-b': bgColorRgb?.blue,
    '--bg-g': bgColorRgb?.green,
    '--bg-r': bgColorRgb?.red,
  }

  return (
    <div
      className={classnames(
        commonStyles,
        'flex items-center justify-center flex-none font-bold tracking-widest autocolor',
        {
          [editStyles]: isEdit,
          [navbarStyles]: isNavbar,
          [stationStyles]: isStation,
          [tileStyles]: isTileInitials,
          [workspaceStyles]: isWorkspace,
          [isMobileStyles]: isMobile,
        },
      )}
      style={style}>
      {getInitialsFromName(name)}
    </div>
  )
}

export default EntryInitials
