import React from 'react'
import { format } from 'date-fns'
import { take } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import { ContentEditableEvent } from 'react-contenteditable'
import { useLocation } from 'react-router-dom'
import i18n from 'i18next'
import ReactDOM from 'react-dom'

import { DropdownOptionType } from 'utils/types/inputs'
import UsersTablePropsType from 'utils/types/UsersTablePropsType'
import tippy from 'tippy.js'
import 'tippy.js/animations/shift-away.css'
import RolesEnum from 'utils/constant/enums'
import { rolesDropdownType } from 'utils/types/AccessType'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { hijriDateTimeFormat, hijriInputFormat, toIndian } from '../calendarUtils'

export const AR_DATE_FORMAT = 'yyyy/M/d'
export const DATE_FORMAT = 'd/M/yyyy'
export const DATE_TIME_FORMAT = 'd/M/yyyy pp'
export const DATE_JUST_TIME_FORMAT = ' pp '
const isMobile = window.innerWidth <= 760

export const getFormattedDateWithTime = (date: string | Date, isHijri: boolean, language: string) => {
  const dateObject = typeof date === 'string' ? Date.parse(date) : date
  return isHijri
    ? hijriInputFormat(dateObject, language) +
        ' (' +
        hijriDateTimeFormat(format(dateObject, DATE_JUST_TIME_FORMAT), language, dateObject) +
        ')'
    : format(dateObject, DATE_TIME_FORMAT)
}

export const getFormattedDateWithLocale = (date: string | Date, formatTo: string, locale?: Locale) => {
  const dateObject = typeof date === 'string' ? Date.parse(date) : date
  if (locale) {
    return format(dateObject, formatTo || DATE_TIME_FORMAT, { locale })
  }
  return format(dateObject, formatTo || DATE_TIME_FORMAT)
}

export const findElementInStringDropdown = (
  list: DropdownOptionType[],
  value: string,
): DropdownOptionType | undefined => list.find((e: DropdownOptionType) => e.value === value)

export const findElementInNumberDropdown = (
  list: DropdownOptionType[],
  value: string | number,
): DropdownOptionType | undefined => list.find((e: DropdownOptionType) => +e.value === +value)

export const getInitialsFromName = (name: string): string =>
  take(name?.split(' '), 2)
    .map((name) => take(name, 1))
    .join('')
    .toUpperCase()

export const getValueFromEvent = (event: ContentEditableEvent): string => {
  const element = new DOMParser().parseFromString(event.target.value, 'text/html')
  return element.documentElement.textContent || ''
}

export const useQuery = () => new URLSearchParams(useLocation().search)
export const availableGroups = (shouldReturnExplicit = false): rolesDropdownType[] =>
  shouldReturnExplicit
    ? [
        {
          label: i18n.t('common:roles.admin'),
          value: `${RolesEnum.Admin}`,
        },
        {
          label: i18n.t('common:roles.editor'),
          value: `${RolesEnum.Editor}`,
        },
        {
          label: i18n.t('common:roles.viewer'),
          value: `${RolesEnum.Viewer}`,
        },
        {
          disabled: true,
          label: i18n.t('common:roles.explicit'),
          value: `${RolesEnum.ExplicitAccess}`,
        },
      ]
    : [
        {
          label: i18n.t('common:roles.admin'),
          value: `${RolesEnum.Admin}`,
        },
        {
          label: i18n.t('common:roles.editor'),
          value: `${RolesEnum.Editor}`,
        },
        {
          label: i18n.t('common:roles.viewer'),
          value: `${RolesEnum.Viewer}`,
        },
      ]

export const findNumberOfAdmins = (
  users: UsersTablePropsType[],
  fieldToSearchBy: 'WorkspaceMember' | 'StationAccess' | 'ListAccess',
): number =>
  users?.filter((user) => {
    const { accessGroupId } = user[fieldToSearchBy] || {}
    return accessGroupId >= RolesEnum.Admin
  }).length

export const toBoolean = (stringValue: string | undefined | null, defaultValue = false): boolean => {
  switch (stringValue?.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true
    case 'false':
    case 'no':
    case '0':
      return false
    default:
      return defaultValue
  }
}

export const getDateFormat = (isRtl, date, isHijri) =>
  isHijri
    ? hijriInputFormat(date, isRtl ? 'ar' : 'en')
    : getFormattedDateWithLocale(date, isRtl ? 'yyyy/MM/d' : 'd/MM/yyyy', isHijri)

export const saveCampaignToLocalStorage = (value: string) => {
  const currentCampaignValue = localStorage.getItem('campaign')
  if (!currentCampaignValue) {
    localStorage.setItem('campaign', value)
  }
}

export const Tooltip = (id: string, content: any, placement: string, theme: string, allowHTML?: boolean) => {
  !isMobile &&
    tippy(id, {
      allowHTML: allowHTML,
      animateFill: true,
      animation: 'shift-away',
      appendTo: () => document.body,
      arrow: false,
      content: content || '',
      delay: 0,
      placement: placement || 'right',
      role: 'tooltip',
      theme: theme || 'custom-primary',
      touch: true,
    })
}

export const GeneralPortal = ({ children, parentId }: { children: any, parentId?: string }) => {
  const toolbarParent = parentId ? document.getElementById(parentId) : document.body
  return toolbarParent && ReactDOM.createPortal(children, toolbarParent)
}

export const getFormattedCompletion = (percentage: number | string | null | undefined, decimalPoints = 2) =>
  percentage ? (+percentage === 100 ? 100 : (+percentage).toFixed(decimalPoints)) : 0

export const dateLanguageFormat = {
  ar: AR_DATE_FORMAT,
  en: DATE_FORMAT,
}

export const notEditableDateFormat = (date, isHijri, language, isEditable) => {
  if (isHijri) {
    return hijriInputFormat(new Date(date), language, isEditable)
  }
  return format(new Date(date), DATE_FORMAT)
}

export const range = (start, end) => {
  return new Array(end - start).fill().map((d, i) => i + start)
}

export const regex_date1 = /^\d{4}\/\d{1,2}\/\d{1,2}$/
export const regex_date2 = /^\d{1,2}\/\d{1,2}\/\d{4}$/

export const getElementLinkFromPath = (legacyPath: string, baseType: AppElementBaseTypes): string => {
  const pathList = legacyPath
    .split('.')
    .filter((e) => (e !== null ? e : null))
    .map((e) => +e)

  switch (baseType) {
    case AppElementBaseTypes.RecordAppElement: {
      return `/workspace/${pathList[0]}/stations/${pathList[1]}/lists/${pathList[2]}?recordId=${pathList[3]}`
    }

    case AppElementBaseTypes.ListAppElement: {
      return `/workspace/${pathList[0]}/stations/${pathList[1]}/lists/${pathList[2]}`
    }

    default:
      return '/404'
  }
}

export const getFormattedActivityPath = (
  workspaceName: string | null,
  stationName: string,
  listName: string,
  classes = 'flex gap-1 text-gray-500 text-sm',
) => {
  return (
    <p className={classes}>
      {workspaceName !== null && (
        <>
          <p>{workspaceName}</p>
          <p>/</p>
        </>
      )}
      <p>{stationName}</p>
      <p>/</p>
      <p>{listName}</p>
    </p>
  )
}

export const covertUrlToFile = async (url: string, type = 'image/jpeg') => {
  const response = await fetch(url)
  const data = await response.blob()
  const metadata = {
    type: type || 'image/jpeg',
  }
  return new File([data], 'newImage', metadata)
}

export const getRedirectFromLink = (queryParams: string | undefined): string | false => {
  if (!queryParams) return '/'
  const query = new URLSearchParams(queryParams)

  const baseType: AppElementBaseTypes | null = query.get('et') ? Number(query.get('et')) : null

  switch (baseType) {
    case AppElementBaseTypes.RecordAppElement: {
      const wId = query.get('w')
      const sId = query.get('s')
      const lId = query.get('l')
      const rId = query.get('r')
      if (wId === null || sId === null || lId === null || rId === null) return false
      return `/workspace/${wId}/stations/${sId}/lists/${lId}?recordId=${rId}`
    }

    case AppElementBaseTypes.WikiPageAppElement: {
      const wId = query.get('w')
      const sId = query.get('s')
      const wiId = query.get('wi')
      const wpId = query.get('wp')
      if (wId === null || sId === null || wiId === null || wpId === null) return false
      return `/workspace/${wId}/stations/${sId}/documentation/${wiId}/view/${wpId}`
    }

    default:
      return false
  }
}

export const pathGenerator = ({
  list,
  pathType = 'global',
  classes = 'flex gap-0.5 bg-gray-200 rounded px-2 py-1 truncate',
}: {
  list: any[],
  pathType?: 'global' | 'documents',
  classes?: string,
}) => {
  switch (pathType) {
    case 'documents':
      return (
        <p className={classes}>
          {list?.map((item, idx) => {
            if (item.elementType == AppElementBaseTypes.WikiPageAppElement) {
              if (idx !== list?.length - 1) {
                return (
                  <>
                    <p key={idx}>{item.name}</p>
                    <p>/</p>
                  </>
                )
              } else {
                return <p key={idx}>{item.name}</p>
              }
            }
          })}
        </p>
      )

    case 'global':
    default:
      return (
        <p className={classes}>
          {list?.map((item, idx) => {
            if (idx !== list?.length - 1) {
              return (
                <>
                  <p key={idx}>{item.name}</p>
                  <p>/</p>
                </>
              )
            } else {
              return <p key={idx}>{item.name}</p>
            }
          })}
        </p>
      )
  }
}

export const parseIdsFromPath = (elementPath: string): number[] =>
  elementPath
    .split('.')
    .filter((e) => (e !== null ? e : null))
    .map((e) => +e)

export const boolToNumber = (value: boolean) => {
  if (value === true) return 1
  if (value === false) return 0
}

export const isNumeric = (str: any) => {
  if (typeof str != 'string') return false
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const reorderItems = (options: {
  oldOrder: number,
  newOrder: number,
  originalItems: any[],
  orderPath: string,
  itemId: number | string,
}) => {
  const { oldOrder, newOrder, originalItems, orderPath, itemId } = options
  const items = cloneDeep(originalItems)
  let updatedOrder

  if (newOrder < 0) return items

  const orderChanged = oldOrder !== newOrder
  if (!orderChanged) return items

  const newOrderDirection =
    newOrder === undefined ? 'unknown' : newOrder > oldOrder ? 'away' : newOrder < oldOrder ? 'close' : 'unchanged'

  switch (newOrderDirection) {
    case 'away': {
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const currItemOrder = get(item, orderPath)

        if (currItemOrder > oldOrder && currItemOrder <= newOrder) {
          set(item, orderPath, currItemOrder - 1)
        }
      }

      updatedOrder = newOrder
      break
    }
    case 'close': {
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const currItemOrder = get(item, orderPath)

        if (currItemOrder < oldOrder && currItemOrder >= newOrder) {
          set(item, orderPath, currItemOrder + 1)
        }
      }

      updatedOrder = newOrder
      break
    }
    case 'unknown':
    case 'unchanged':
      break
  }

  const updatedItem = items.find((i) => i.id == itemId)
  set(updatedItem, orderPath, updatedOrder)

  return items
}

export const getUserPermissionFromAccessGroup = (accessGroupId: number, key: undefined | string) => {
  if (key) {
    return Number(accessGroupId) >= Number(RolesEnum[key])
  }
  const isAdmin = Number(accessGroupId) >= +RolesEnum.Admin
  const isEditor = Number(accessGroupId) >= +RolesEnum.Editor
  const isSuperAdmin = +RolesEnum.SuperAdmin === Number(accessGroupId)
  return isSuperAdmin || isAdmin || isEditor
}
