import { FC } from 'react'
import * as yup from 'yup'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'components/inputs'
import DefaultNotEditingComponent from 'components/inputs/DefaultNotEditingComponent'
import PriorityOption from 'components/inputs/PriorityDropdown/PriorityOption'

import { withContentEditable, withKeyboardNavigation } from 'hocs'
import { getPriorityIconByValue, getPriorityOptions } from 'utils/helpers/recordHelpers'
import { isNil } from 'lodash'

const ContentEditablePriorityDropdown = withKeyboardNavigation(withContentEditable(Dropdown))

const PriorityDropdown: FC<{
  priority?: number,
  handleRecordChange: (values: any) => void,
  isDisabled?: boolean,
  withNames?: boolean,
  shouldShowPlaceholder?: boolean,
}> = ({ priority, handleRecordChange, isDisabled, withNames, shouldShowPlaceholder = false, ...rest }) => {
  const { t } = useTranslation('records')
  const renderSelectedPriority = (value: any, { showInput }: { showInput: () => void }) => {
    const { Icon, color, label } = getPriorityIconByValue(value === null ? null : +value)

    if (!isNil(value)) {
      return (
        <div
          className={classnames({
            'flex items-center hover:bg-white focus:bg-white leading-6 px-2 py-2.5 -ms-2 -mt-2 -mb-2 min-h-12 rounded cursor-pointer transition transition-colors border-2 border-transparent border-dashed hover:border-gray-300 focus:outline-none focus:border-gray-400':
              !isDisabled,
            'justify-center': !withNames,
          })}
          onClick={showInput}>
          <Icon className="w-4 h-4 my-0" style={{ color }} />
          {withNames && <span className="flex items-center text-sm ms-1">{label}</span>}
        </div>
      )
    }
    return (
      <DefaultNotEditingComponent
        className={shouldShowPlaceholder ? 'text-sm' : ''}
        name="priority"
        placeholder={t('placeholderSelect')}
        shouldShowPlaceholder={shouldShowPlaceholder}
        showInput={showInput}
        value=""
      />
    )
  }

  return (
    <ContentEditablePriorityDropdown
      className="-ms-2 -mt-2 -mb-2"
      components={{ Option: PriorityOption, SingleValue: PriorityOption }}
      isLabelVisible={false}
      isOnClickDisabled={isDisabled}
      isSearchable={false}
      name="priority"
      options={getPriorityOptions()}
      renderNotEditingState={renderSelectedPriority}
      shouldShowPlaceholder={false}
      value={priority}
      onSubmit={(values: any) => {
        values.priority = values.priority === 'null' ? null : values.priority
        handleRecordChange(values)
      }}
      {...rest}
    />
  )
}

export default PriorityDropdown
