import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RemovedUser } from '../Icons'
import { useUserById, useUsers } from '../../features/user'

type UserAvatarPropTypes = {
  imgSrc: string,
  imgAlt?: string,
  userId: number,
  customSize?: number,
  removeIconCustomSize?: number,
  mobileInsetClass?: string,
  hideUserStatus?: boolean,
  minWidth?: number,
  overrideCheckingUser?: boolean,
}

const UserAvatarStatus: FC<UserAvatarPropTypes> = ({
  imgSrc,
  imgAlt,
  userId,
  customSize,
  removeIconCustomSize,
  mobileInsetClass,
  hideUserStatus,
  minWidth,
  overrideCheckingUser = false,
}) => {
  const { t } = useTranslation()
  const users = useUsers()
  const user = useUserById(userId)
  const isUserNotInList = user === undefined
  const isRemovedUser = !overrideCheckingUser && isUserNotInList
  const newImgAlt = imgAlt || 'user avatar'
  const size = customSize || 8
  const removeIconSize = removeIconCustomSize || 1

  return isRemovedUser && !hideUserStatus && users.length > 0 ? (
    <div className="relative" title={t('removedFromWorkspace')}>
      <img
        alt={newImgAlt}
        className={`w-${size} h-${size} opacity-40 rounded-full min-w-${size} z-1 ${
          customSize !== undefined ? (removeIconCustomSize ? null : '-mt-2') : null
        }`}
        loading="lazy"
        src={'/assets/images/placeholder.svg'}
        style={minWidth ? { minHeight: minWidth, minWidth: minWidth } : undefined}
      />
      <RemovedUser
        className={`absolute z-10 opacity-90 w-${size + removeIconSize} h-${size + removeIconSize} ${
          removeIconCustomSize
            ? mobileInsetClass
              ? mobileInsetClass
              : '-top-1 -left-1 -right-1'
            : '-top-0.5 -left-0.5'
        } text-gray-400 ${customSize !== undefined ? (removeIconCustomSize ? null : '-mt-2') : null}`}
        style={minWidth ? { minHeight: minWidth, minWidth: minWidth } : undefined}
      />
    </div>
  ) : (
    <img
      alt={newImgAlt}
      className={`w-${size} h-${size} border-2 rounded-full min-w-${size} z-1 ${
        customSize !== undefined ? (removeIconCustomSize ? null : '-mt-2') : null
      }`}
      loading="lazy"
      src={imgSrc || user?.avatar || '/assets/images/placeholder.svg'}
      style={minWidth ? { minHeight: minWidth, minWidth: minWidth } : undefined}
    />
  )
}

export default UserAvatarStatus
