import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigurationIcon } from 'components/Icons'
import { Checkbox } from '../inputs'

interface CustomFieldImplementLevelProps {
  itemBasedType: number;
  isDisabled: boolean;
  setApplyCustomFieldLevel: (e: any) => void;
  setFieldStatus?: (e: any) => void;
  applyCustomFieldLevel?: {
    appliesOnListChildren: boolean,
    appliesOnStationChildren: boolean,
    appliesOnRecordChildren: boolean,
  };
  fieldStatus?: {
    isReplacement: boolean,
    isVisible: boolean,
  };
  isAggregateField: boolean;
  isFormulaField: boolean;
  isApprovalPage: boolean;
}
const CustomFieldImplementLevel: FC<CustomFieldImplementLevelProps> = ({
  applyCustomFieldLevel,
  setApplyCustomFieldLevel,
  itemBasedType,
  isDisabled,
  setFieldStatus,
  fieldStatus,
  isAggregateField,
  isFormulaField,
  isApprovalPage = false,
}) => {
  const { t } = useTranslation('customFields')
  const [isOpen, setIsOpen] = useState(isApprovalPage)

  const isDisabledItem = (value: number) => {
    if (isDisabled) {
      return isDisabled
    }
    return itemBasedType > value
  }
  //Apply list
  const checkboxList = [
    {
      className: `${isDisabledItem(0) && 'opacity-50'}`,
      isChecked: applyCustomFieldLevel.appliesOnStationChildren,
      isDisabled: isDisabledItem(0),
      key: 'stations',
      label: 'appliesOnStations',
      onChange: () =>
        setApplyCustomFieldLevel({
          ...applyCustomFieldLevel,
          appliesOnStationChildren: !applyCustomFieldLevel.appliesOnStationChildren,
        }),
    },
    {
      className: `${isDisabledItem(1) && 'opacity-50'}`,
      isChecked: applyCustomFieldLevel.appliesOnListChildren,
      isDisabled: isDisabledItem(1),
      key: 'lists',
      label: 'appliesOnLists',
      onChange: () =>
        setApplyCustomFieldLevel({
          ...applyCustomFieldLevel,
          appliesOnListChildren: !applyCustomFieldLevel.appliesOnListChildren,
        }),
    },
    {
      className: `${isDisabledItem(2) && 'opacity-50'}`,
      isChecked: applyCustomFieldLevel.appliesOnRecordChildren,
      isDisabled: isDisabledItem(2),
      key: 'records',
      label: 'appliesOnRecords',
      onChange: () =>
        setApplyCustomFieldLevel({
          ...applyCustomFieldLevel,
          appliesOnRecordChildren: !applyCustomFieldLevel.appliesOnRecordChildren,
        }),
    },
  ]
  //settings list
  const settingsList = [
    {
      className: `${isDisabled && 'opacity-50'}`,
      isChecked: fieldStatus?.isVisible,
      isDisabled: isDisabled,
      key: 'isVisible',
      label: 'isVisible',
      onChange: () => {
        if (setFieldStatus) {
          setFieldStatus({
            ...fieldStatus,
            isVisible: !fieldStatus?.isVisible,
          })
        }
      },
    },
    {
      className: `${isDisabled && 'opacity-50'}`,
      isChecked: fieldStatus?.isReplacement,
      isDisabled: isDisabled,
      key: 'isAlternate',
      label: 'isAlternate',
      onChange: () => {
        if (setFieldStatus)
          setFieldStatus({
            ...fieldStatus,
            isReplacement: !fieldStatus?.isReplacement,
          })
      },
    },
  ]

  const renderCheckbox = (field: any) => {
    const { className, key, isChecked, isDisabled, label, onChange } = field
    return (
      <div key={key} className={`my-1 ${className}`}>
        <Checkbox
          checked={isChecked}
          classes={{ input: 'w-full' }}
          disabled={isDisabled}
          label={t(label)}
          name={label}
          tabIndex={-1}
          onChange={onChange}
        />{' '}
      </div>
    )
  }
  const renderAppliedSection = () => {
    const newSettingsList = !isAggregateField && !isFormulaField ? settingsList.splice(0, 1) : settingsList
    return (
      <div className="flex justify-between px-2 mt-1 overflow-hidden bg-gray-100 transition-max-height">
        <div>
          {checkboxList.map((field) => {
            return renderCheckbox(field)
          })}
        </div>
        {setFieldStatus && (
          <div>
            {newSettingsList.map((field) => {
              return renderCheckbox(field)
            })}
          </div>
        )}
      </div>
    )
  }

  return (
    <div aria-disabled={true} className="mt-4 -mx-1">
      <p className="flex items-center text-gray-500 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <ConfigurationIcon className="w-3" />
        <span className="text-sm ms-1">{t(`advancedSettings`)}</span>
      </p>
      {isOpen && renderAppliedSection()}
    </div>
  )
}
export default CustomFieldImplementLevel
