import React, { FC } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import {
  DashboardIcon,
  DocFileIcon,
  KanbanIcon,
  MenuIcon,
  SettingsIcon,
  MohimmatechLogo,
  CustomIcon,
} from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { useNavbar } from 'contexts/NavbarContext'
import { configValues } from 'utils/appConfig'
import { useDispatch } from 'react-redux'
import { useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'
import { Tooltip } from '../../utils/helpers/generalHelpers'
import { SetCurrentStationArchiveStatus } from '../../features/station/stationSlice'
import { useCurrentStation } from '../../features/station'

const StationHeader: FC<{
  stationName: string | undefined,
  isSettingPage?: boolean,
  isDashboardPage?: boolean,
  title?: string,
  isDocumentationPage?: boolean,
  isListsPage?: boolean,
}> = ({ stationName, isSettingPage, isDashboardPage, title, children, isDocumentationPage, isListsPage }) => {
  const { stationId, workspaceId }: { stationId: string, workspaceId: string } = useParams()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { toggleMenu } = useNavbar()
  const currentStation = useCurrentStation()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const isRtl = i18n.language === 'ar'
  const isMobileSidebar = window.innerWidth < 1024
  return (
    <header
      className={classnames('text-white ps-3 md:px-4', {
        'bg-tertiary-light': !configValues.REACT_APP_IS_CUSTOM_BRANDING,
      })}
      style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.stationHeaderClasses } : {}}>
      <div className="flex my-1">
        <Button
          className="px-2 py-2 -ms-3 lg:hidden"
          data-tut="mobile__sidebar"
          icon={MenuIcon}
          variant={ButtonVariant.Icon}
          onClick={() => toggleMenu(true)}
        />

        {!isMobileSidebar && (
          <NavLink className="flex items-center w-10 me-5" to="/" onMouseEnter={() => toggleMenu(true)}>
            {configValues.REACT_APP_IS_CUSTOM_BRANDING ? <CustomIcon /> : <MohimmatechLogo />}
          </NavLink>
        )}

        <h1 className="flex flex-wrap items-center min-w-0 text-lg font-normal text-white me-auto">
          <span className="font-bold truncate">{stationName} - </span>
          {isListsPage && <span className="ps-1.5">{t('common:labels.lists')}</span>}
          {isSettingPage && <span className="ps-1.5">{t('common:labels.settings')}</span>}
          {isDashboardPage && <span className="ps-1.5">{title}</span>}
          {isDocumentationPage && <span className="ps-1.5">{t('documentation:documentation')}</span>}
        </h1>
        {isPremiumPlan && (
          <NavLink
            activeClassName="bg-white rounded-sm text-tertiary-light transition hover:text-primary"
            className="flex p-2 md:p-3 transition hover:text-gray-400 focus:outline-none focus-visible:shadow-focus"
            to={`/workspace/${workspaceId}/stations/${stationId}/documentation`}
            onClick={() => {
              dispatch(SetCurrentStationArchiveStatus(currentStation?.appElement?.isArchived))
            }}>
            <div
              key={isRtl + ''}
              id={'stationDocumentation'}
              onMouseOver={() => {
                Tooltip('#stationDocumentation', t('toolTip:documentation'), 'bottom', 'custom-bottom')
              }}>
              <DocFileIcon className="w-5 h-5 md:w-6 md:h-6" />
            </div>
            <p className="hidden ms-2">{t('documetation.title')}</p>
          </NavLink>
        )}
        {isPremiumPlan && (
          <NavLink
            activeClassName="bg-white rounded-sm text-tertiary-light transition hover:text-primary"
            className="flex p-2 md:p-3 transition hover:text-gray-400 focus:outline-none focus-visible:shadow-focus"
            to={`/workspace/${workspaceId}/stations/${stationId}/dashboard`}>
            <div
              key={isRtl + ''}
              id={'stationDashboard'}
              onMouseOver={() => {
                Tooltip('#stationDashboard', t('toolTip:stationDashboard'), 'bottom', 'custom-bottom')
              }}>
              <DashboardIcon className="w-5 h-5 md:w-6 md:h-6" id={'stationDashboard'} />
            </div>
            <p className="hidden ms-2">{t('workspace:dashboard.dashboardTitle')}</p>
          </NavLink>
        )}
        <NavLink
          activeClassName="bg-white rounded-sm text-tertiary-light transition hover:text-primary"
          className="flex p-2 md:p-3 transition hover:text-gray-400 focus:outline-none focus-visible:shadow-focus"
          to={`/workspace/${workspaceId}/stations/${stationId}/lists`}>
          <div
            key={isRtl + ''}
            id={'stationKanban'}
            onMouseOver={() => {
              Tooltip('#stationKanban', t('toolTip:kanban'), 'bottom', 'custom-bottom')
            }}>
            <KanbanIcon className="w-5 h-5 md:w-6 md:h-6" />
          </div>
          <p className="hidden ms-2">{t('workspace:showStation')}</p>
        </NavLink>
        <NavLink
          activeClassName="bg-white rounded-sm text-tertiary-light transition hover:text-primary"
          className="flex p-2 md:p-3 transition hover:text-gray-400 focus:outline-none focus-visible:shadow-focus"
          to={`/workspace/${workspaceId}/stations/${stationId}/details`}>
          <div
            key={isRtl + ''}
            id={'stationSettings'}
            onMouseOver={() => {
              Tooltip('#stationSettings', t('toolTip:settings'), 'bottom', 'custom-bottom')
            }}>
            <SettingsIcon className="w-5 h-5 md:w-6 md:h-6" />
          </div>
          <p className="hidden ms-2">{t('workspace:stationDetails')}</p>
        </NavLink>
      </div>
      <div className="flex h-8 mt-1 md:mt-0 -ms-2 md:ms-0">{children}</div>
    </header>
  )
}

export default StationHeader
