import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'

const SidebarContentLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={185}
      height={200}
      viewBox="0 0 190 200"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" width="185" height="36" />
      <rect x="0" y="42" width="185" height="36" />
      <rect x="0" y="84" width="185" height="36" />
      <rect x="0" y="128" width="185" height="36" />
    </ContentLoader>
  )
}

export default memo(SidebarContentLoader)
