import React, {
    ChangeEvent,
    createRef,
    FocusEventHandler,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import {format, isDate} from 'date-fns'
import classnames from 'classnames'
import {arSA, enGB} from 'date-fns/locale'
import getYear from "date-fns/getYear";
import DatePicker from 'react-datepicker'
import InputErrorMessage from 'components/inputs/InputErrorMessage'
import {ErrorMessageRefType, InputRefType} from 'utils/types/inputs'
import InputLabel from 'components/inputs/InputLabel'
import {DATE_FORMAT, GeneralPortal, range} from 'utils/helpers/generalHelpers'
import { useTranslation } from 'react-i18next'
import {ArabicMonths, getPickerMonthView, normalToHijri, toIndian} from "../../utils/calendarUtils";
import { NextIcon, PrevIcon } from "../Icons";
import CustomDatePickerInput from "./CustomeDatePickerInput";
import DatepickerDropdown from "./DatepickerDropdown";


interface IDatepickerProps  {
    inputWrapperClassName?: string;
    className?: string;
    name: string;
    value?: string | null;
    defaultValue?: Date | Date[];
    elementRef?: any;
    isHijri?: boolean;
    onBlur: (e: FocusEventHandler) => void;
    id?: number;
    isGridView?: boolean;
}

const Datepicker = forwardRef<InputRefType, IDatepickerProps>(
    ({
         label,
         name,
         isInlineEditable,
         error,
         touched,
         inputWrapperClassName,
         onBlur,
         onChange,
         value,
         classes,
         elementRef,
         isHijri,
         id,
         isGridView,
         ...rest

     }, ref) => {
        const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(true)
        const [prevKey, setPrevKey] = useState<string>()
        const [positions, setPositions] = useState<any>({})
        const errorRef = createRef<ErrorMessageRefType>()
        const { t, i18n } = useTranslation()
        const isRtl = i18n.language === 'ar'
        const locale = isRtl ? arSA : enGB
        const isMobile = window.innerWidth <= 760
        const years = range(1938, getYear(new Date()) + 30)
        const months = range(1, 13)

        const pickerRef = useRef<DatePicker>()

        useEffect(() => {
            if (elementRef && elementRef?.current && elementRef?.current?.getBoundingClientRect())
                setPositions(elementRef?.current?.getBoundingClientRect())
        }, [elementRef?.current])

        useImperativeHandle(
            ref,
            () => ({
                blur: () => setIsCalendarOpen(false),
                focus: () => setIsCalendarOpen(true),
                shakeError: () => errorRef.current?.shakeError(),
            }),
            [errorRef],
        )

        const handleBlur = () => {
            // eslint-disable-next-line prettier/prettier
            const changeEvent = new Event('change') as unknown as ChangeEvent<HTMLInputElement>
            const fullChangeEvent = {
                ...changeEvent,
                target: {...changeEvent.target, id: `input-${name}`, name, value: new Date(value)}
            }
            const focusEvent = fullChangeEvent as unknown as FocusEventHandler<HTMLDivElement>
            onBlur(focusEvent)
        }

        const renderDayContents = (currentDay, info) => {
            const date = new Date(info)
            const hijriDate = normalToHijri(info)
            const day = date.getDate()
            const lang = i18n.language
            const cellDay = isHijri ? hijriDate.day : day
            const hijriMonthName = lang === 'ar'? ArabicMonths[hijriDate.month].ar : ArabicMonths[hijriDate.month].enShort

            return( <div className={`justify-center w-full flex`} dir={'auto'}>
                    <div className="text-sm xs:text-xs">{ isHijri && cellDay === 1?
                        hijriMonthName : lang=== 'ar' ? toIndian(cellDay) : cellDay}</div>
                </div>
            )
        }

        const onChangeValue = (date: Date) => {
                if (isDate(new Date(date)) || date === null) {
                    const changeEvent = new Event('change') as unknown as ChangeEvent<HTMLInputElement>
                    const fullChangeEvent = {
                        ...changeEvent,
                        target: {
                            ...changeEvent.target,
                            id: `input-${name}`,
                            name,
                            value: date && new Date(date)
                        },
                    }
                    onChange?.(fullChangeEvent)
                }
            }

        const renderCustomHeader =({
                                       date,
                                       changeYear,
                                       changeMonth,
                                       decreaseMonth,
                                       increaseMonth,
                                       prevMonthButtonDisabled,
                                       nextMonthButtonDisabled
                                   }) => {
            const mainWrapperStyle = {
                direction: 'revert',
                display: "flex",
                justifyContent: "center",
                maxWidth: isMobile? 355 : 400,
                minWidth: !isMobile? 350 : null,
        }
            const iconWrapper = `flex justify-center items-center`
            const iconClassName = `text-gray-700 w-5 h-5 cursor-pointer hover:text-primary`
            const iconStyle = {minWidth: '10%', width: '10%'}
            const dateDropdownsWrapper = {minWidth: '80%', width: '80%'}
            const dropdownClasses = classnames('me-3 text-lg hover:text-primary focus:text-primary')
            const monthOptions = months.map((label) => ({ label: getPickerMonthView(new Date(`${getYear(date)}/${label}/1`),isHijri ? 'hijri' : 'gregorian', locale), value: label }))
            const yearOptions = years.map((label) => {
                const dateLabel = isHijri ? normalToHijri(new Date(`${label}/1/1`)).year : label
                return { label: i18n.language === 'ar' ? toIndian(dateLabel) : dateLabel, value: label }})

            return(
                <div className="items-center px-2" style={mainWrapperStyle}>
                    <div className={iconWrapper} disabled={prevMonthButtonDisabled} style={iconStyle} onClick={decreaseMonth}>
                        {!isRtl? <PrevIcon className={iconClassName} /> : <NextIcon className={iconClassName} />}
                    </div>
                    <div className="flex items-center justify-center" style={dateDropdownsWrapper}>
                        <div style={{ maxWidth: '60%' }}>
                            <DatepickerDropdown
                              className="w-full font-extrabold lg:me-2"
                              classes={{ wrapper: dropdownClasses }}
                              name="picker-months"
                              options={monthOptions}
                              value={date ? new Date(date).getMonth() + 1 : undefined}
                              onChange={({ target: { value } }) =>{
                              changeMonth(value - 1)
                              }}
                            />
                        </div>
                    <DatepickerDropdown
                        className="w-full font-extrabold lg:me-2"
                        classes={{ wrapper: dropdownClasses }}
                        name="picker-years"
                        options={yearOptions}
                        value={date ? getYear(date) : undefined}
                        onChange={({ target: { value } }) => changeYear(value)}
                    />
                        <div className={'picker-today-button'}
                             style={{ border: '1px solid #C5D4E2' }}
                             onClick={() => {
                                 const d = new Date()
                                 const year = d.getFullYear()
                                 const month = d.getMonth() + 1
                                 const day = d.getDate()
                                 handleBlur()
                                 onChangeValue(new Date(`${year}/${month}/${day}`))
                                 pickerRef?.current?.setOpen(false)
                             }}>
                            {t('today')}
                        </div>
                    </div>
                    <div className={iconWrapper} disabled={nextMonthButtonDisabled} style={iconStyle} onClick={increaseMonth}>
                        {isRtl? <PrevIcon className={iconClassName} /> : <NextIcon className={iconClassName} />}
                    </div>
                </div>
            )}


        const renderContent = (moreClasses, style) => {
            const pickerDate = value ? new Date(value) : undefined
            const datePickerClassName = 'block w-full px-2 text-sm border-2 border-transparent border-dashed rounded' +
                ' cursor-pointer min-h-12 py-2.5 hover:border-gray-300 focus:outline-none text-start transition' +
                ' transition-colors focus:max-w-32'

            return (
            <div className={classnames(classes?.wrapper, moreClasses)}  style={ style? style : null }>
                <InputLabel label={label} name={name}/>
                <DatePicker calendarStartDay={0}
                            className={classnames(datePickerClassName, isRtl? 'rtlFont' : 'ltrFont')}
                            customInput={<CustomDatePickerInput closeCalendar={()=> pickerRef?.current?.setOpen(false)}
                                                                customId={id}
                                                                name={name}
                                                                isGridView={isGridView}
                                                                handleBlur={handleBlur}
                                                                pickerRef={pickerRef}
                                                                onChangeValue={onChangeValue}/>}
                            dayClassName={(date) => {
                                  const selectedValue = value ? format(new Date(value), DATE_FORMAT) : undefined
                                  const today = format(new Date(), DATE_FORMAT)
                                  const formattedDate = format(new Date(date), DATE_FORMAT)
                                  const isToday = today === formattedDate
                                  const isSelected = selectedValue === formattedDate
                                  return isSelected? "picker-selected-custom" : isToday? "picker-today-custom" : "picker-day-custom"
                            }}
                            isOpen={isCalendarOpen}
                            locale={locale}
                            name={name}
                            ref={pickerRef}
                            disabledKeyboardNavigation
                            renderCustomHeader={renderCustomHeader}
                            renderDayContents={renderDayContents}
                            selected={pickerDate}
                            onCalendarClose={handleBlur}
                            onChange={onChangeValue}
                            onKeyDown={(event: KeyboardEvent) => {
                                        if (event.key === 'Enter' || event.key === 'Escape' || (prevKey === 'Shift' && event.key === 'Tab')) {
                                            handleBlur()
                                         }
                                         setPrevKey(event.key)
                                       }}
                            {...rest}
                />
                <InputErrorMessage error={error} isInlineEditable={isInlineEditable} ref={errorRef} touched={touched} />
                </div>

            )
        }

        return elementRef ? (
            <GeneralPortal parentId={'portal-root'}>
                {renderContent('absolute bg-white', {
                    left: positions?.left,
                    top: positions?.top + 5,
                    width: positions.width
                })}
            </GeneralPortal>
        ) : (
            renderContent()
        )
    },
)

Datepicker.displayName = 'Datepicker'

export default Datepicker
