import { FC, useRef } from 'react'
import RecordDescriptionRTEwithMentions from 'components/RecordDescriptionRTEwithMentions'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'

const DescriptionEditorCell: FC<CellEditorProps<RecordType['description']>> = ({
  onTabNavigation,
  cellProps,
  ...props
}) => {
  const elementRef = useRef(null)
  useGridViewNavigation(
    () => onTabNavigation(true, -1),
    () => onTabNavigation(true, 1),
  )

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      props.onComplete(e)
    }
  }

  const value = cellProps?.data?.appElements[0]?.description || ''
  return (
    <div
      className="w-full"
      ref={elementRef}
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          e.preventDefault()
        }
        if (e.key === 'Escape') {
          props.onCancel()
        }
      }}>
      <RecordDescriptionRTEwithMentions
        classes={{ wrapper: 'grid-view-description-input' }}
        content={value}
        editorWrapperClasses="absolute"
        elementRef={elementRef}
        isGridView={true}
        tabIndex={1}
        onBlur={handleBlur}
        onChange={props.onChange}
      />
    </div>
  )
}

export default DescriptionEditorCell
