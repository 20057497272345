import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../Dashboard/BaseModal/BaseModal'
import Button, { ButtonVariant } from '../../Buttons'

const UnsavedWarningModal = ({ hasChanges }) => {
  const history = useHistory()
  const [isShowModal, changeModalVisibility] = React.useState<boolean>(false)
  const unblockHandle = useRef<any>()
  const { t } = useTranslation()

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      if (hasChanges) {
        handleShowModal()
        return false
      }
    })
  })

  function handleShowModal() {
    changeModalVisibility(true)
  }

  function onCancel() {
    changeModalVisibility(false)
  }

  function handleConfirm() {
    if (unblockHandle) {
      unblockHandle.current()
      history.goBack()
    }
  }

  return (
    <BaseModal
      close={onCancel}
      type="confirmation"
      content={
        <div>
          <h1>{t('documentation:unSavedChangesWarning')}</h1>
          <div className="flex justify-end my-6 gap-4">
            <Button variant={ButtonVariant.Outline} onClick={onCancel}>
              {t('common:labels.cancel')}
            </Button>
            <Button variant={ButtonVariant.Danger} onClick={handleConfirm}>
              {t('dashboard:exitButton')}
            </Button>
          </div>
        </div>
      }
      header={''}
      isModalOpen={isShowModal}
      withoutCloseBtn
    />
  )
}
export default UnsavedWarningModal
