enum dashboardSourceType {
  AppElement,
  User,
  Parent,
  DefaultField,
  CustomField,
  Filtering,
  Visibility,
  Customization,
}
export default dashboardSourceType
