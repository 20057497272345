import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckIcon, ChevronLeftIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { chooseWorkspaceBasicPlan, fetchWorkspaceById, useCurrentWorkspaceChosenPlan } from '../../features/workspace'
import { useAppDispatch } from '../../store'
import { clearChosenPlan } from '../../features/workspace/workspaceSlice'
import { allPlansList } from '../../utils/constant/constant/subscription'
import BackItem from './BackItem'

// interface PlanDownGradePropsType {}
const PlanDownGrade = () => {
  const { t, i18n } = useTranslation('workspace')
  const plan = useCurrentWorkspaceChosenPlan()
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const isRtl = i18n.language === 'ar'

  const onBack = () => {
    dispatch(clearChosenPlan())
  }

  const onDownGrade = () => {
    appDispatch(chooseWorkspaceBasicPlan(plan.workspaceId)).then((response) => {
      if (response?.toastType !== 'error') {
        dispatch(fetchWorkspaceById(plan.workspaceId))
      }
      history.push(`/workspace/${plan.workspaceId}/plan`)
    })
  }

  return (
    <>
      <BackItem isTheSameUrl />
      <div className="w-full p-6 bg-white md:p-8">
        <h1 className="mb-6 text-lg">{t('subscription:workspacePlanDownGrad')}</h1>
        <div
          className="p-5 rounded"
          style={{
            background: '#F8F4F4',
            borderLeft: !isRtl && '5px solid #FF671D',
            borderRight: isRtl && '5px solid #FF671D',
          }}>
          <li>{t('subscription:downgradePoint1')}</li>
          {/* <li>
            {t('subscription:downgradePoint2-1')} <span className="font-semibold">{plan.title}</span>{' '}
            {t('subscription:downgradePoint2-2')}
            <span className="font-semibold ms-1">
              {plan.realExpiryDate && format(new Date(plan.realExpiryDate), DATE_FORMAT)}
            </span>
          </li> */}
        </div>
        <div className="mt-10">
          <h3 className="my-5">{t('subscription:featureComparison')}</h3>
          <table className="w-full">
            <tr className="flex justify-between w-full p-2 mb-2 font-bold border-b">
              <td className="w-1/3">{t('subscription:feature')}</td>
              <td className="flex justify-center w-1/3">{t('subscription:basic')}</td>
              <td className="flex justify-center w-1/3">{t('subscription:pro')}</td>
            </tr>
            <div className="w-full overflow-y-auto max-h-96">
              {allPlansList.map((plan, index) => {
                return (
                  <tr key={index} className="flex justify-between w-full px-2 py-3 border-b">
                    <td className="w-1/3">{t(plan.title)}</td>
                    <td className="flex justify-center w-1/3">{plan.basic && <CheckIcon />}</td>
                    <td className="flex justify-center w-1/3">
                      <CheckIcon />
                    </td>
                  </tr>
                )
              })}
            </div>
          </table>
        </div>
        <div className="flex justify-end mt-5">
          <Button variant={ButtonVariant.Outline} onClick={onBack}>
            {t('subscription:stayWithCurrentPlan')}
          </Button>
          <Button className="ms-2" type="submit" onClick={onDownGrade}>
            {t('subscription:iUnderstandDowngradeMyWorkspace')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default PlanDownGrade
