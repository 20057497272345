import { createAsyncThunk } from '@reduxjs/toolkit'
import baseAPI from '../../utils/baseAPI'

export const fetchGlobalCustomFields = createAsyncThunk(
  'adminElement/fetchGlobalCustomFields',
  async (status: boolean, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/workspaces/customfields`)

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
