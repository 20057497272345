import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { ButtonVariant } from 'components/Buttons'

type TourContentPropsType = {
  goTo?: () => void,
  header?: string,
  message: string,
  isLast?: boolean,
  closeTour: () => void,
}

const skipTour = (closeTour: () => void) => {
  closeTour()
  localStorage.setItem('firstStepDone', 'true')
  localStorage.setItem('secondStepDone', 'true')
  localStorage.setItem('thirdStepDone', 'true')
  localStorage.setItem('fourthStepDone', 'true')
}

const TourContent: FC<TourContentPropsType> = ({ goTo, header, message, isLast, closeTour }) => {
  const { t } = useTranslation('tour')
  return (
    <>
      {header && <h2 className="mb-2 font-bold pe-4">{header}</h2>}
      <p>{message}</p>
      <div className="flex items-center justify-between mt-4">
        {isLast ? (
          <Button small onClick={() => skipTour(closeTour)}>
            {t('finishTour')}
          </Button>
        ) : (
          <Button small variant={ButtonVariant.Outline} onClick={() => skipTour(closeTour)}>
            {t('skipTour')}
          </Button>
        )}
        {goTo && (
          <Button small onClick={goTo}>
            {t('nextStep')}
          </Button>
        )}
      </div>
    </>
  )
}

export default TourContent
