import React, { FC } from 'react'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { getCustomFieldsOptions } from 'utils/helpers/listHelpers'
import { useHasCurrentWorkspaceTeamsPlan } from '../../../features/workspace'

type PriorityDropdownType = {
  innerRef?: any,
  innerProps?: any,
  value?: string,
  data: { value: string },
  isFocused?: boolean,
  isFromAdmin?: boolean,
  isSelected?: boolean,
  elementBaseType?: AppElementBaseTypes,
}

const CustomFieldSelectedType: FC<PriorityDropdownType> = ({
  innerRef,
  innerProps,
  value,
  isFocused,
  isSelected,
  isFromAdmin = false,
  elementBaseType = AppElementBaseTypes.ListAppElement,
}) => {
  const isTeamsPlan = useHasCurrentWorkspaceTeamsPlan()
  const options = getCustomFieldsOptions(elementBaseType, isFromAdmin, isTeamsPlan)
  const { Icon, label } = options.find((i) => +i.value === +value)
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`flex items-center mb-2 text-sm ${isFocused || isSelected ? 'text-primary' : 'text-gray-600'}`}>
      <Icon className={`w-4 me-2 ms-2 ${isFocused || isSelected ? 'text-primary' : ''}`} /> {label}
    </div>
  )
}

export default CustomFieldSelectedType
