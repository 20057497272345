export const allPlansList = [
  { basic: true, pro: true, title: 'subscription:features.kanbanView' },
  { basic: true, pro: true, title: 'subscription:features.gridView' },
  { basic: true, pro: true, title: 'subscription:features.agendaView' },
  { basic: true, pro: true, title: 'subscription:features.exportListToExcel' },
  { basic: true, pro: true, title: 'subscription:features.importListFromExcel' },
  { basic: false, pro: true, title: 'subscription:features.workspaceAccessControl' },
  { basic: false, pro: true, title: 'subscription:features.commentingOnRecords' },
  { basic: false, pro: true, title: 'subscription:features.stationAccessControl' },
  { basic: false, pro: true, title: 'subscription:features.calendarView' },
  { basic: false, pro: true, title: 'subscription:features.timelineView' },
  { basic: false, pro: true, title: 'subscription:features.dashboard' },
  { basic: false, pro: true, title: 'subscription:features.documentation' },
  { basic: false, pro: true, title: 'subscription:features.linkingTasksWithDocuments' },
  { basic: false, pro: true, title: 'subscription:features.customFields' },
]
export const basicFeatures = [
  'subscription:features.kanbanView',
  'subscription:features.gridView',
  'subscription:features.agendaView',
  'subscription:features.exportListToExcel',
  'subscription:features.importListFromExcel',
  'subscription:features.workspaceAccessControl',
  'subscription:features.commentingOnRecords',
]
export const proFeatures = [
  'subscription:features.stationAccessControl',
  'subscription:features.calendarView',
  'subscription:features.timelineView',
  'subscription:features.dashboard',
  'subscription:features.customFields',
  'subscription:features.documentation',
  'subscription:features.linkingTasksWithDocuments',
]

export const options = [
  { basic: '350$', label: 'Monthly', pro: '600$', value: 1 },
  { basic: '1000$', label: 'Yearly', pro: '1500$', value: 2 },
]

export const planDetails = [
  { title: 'Current monthly bill', value: 2500 },
  { title: 'Payment due date', value: '20/08/2022' },
  { title: 'Current Workspace plan name', value: 'test plan' },
  { title: 'Amount of storage used in the workspace and how much storage space is left ', value: '20/50 KB' },
  { title: 'Number of billable users', value: 250 },
]

export const billingList = [
  { title: 'Current monthly bill', value: 2500 },
  { title: 'Payment due date', value: '20/08/2022' },
]

export const invoicesList = [
  { date: '20/02/2022', number: 2500, price: '150$', status: 'Paid' },
  { date: '20/02/2022', number: 2500, price: '150$', status: 'Paid' },
  { date: '20/02/2022', number: 2500, price: '150$', status: 'Paid' },
  { date: '20/02/2022', number: 2500, price: '150$', status: 'Paid' },
]
