import React from 'react'
import GeneralCalendarPage from './GeneralCalendar'

const CalendarPage: any = () => {
  return (
    <>
      <GeneralCalendarPage defaultCurrentView={'month'} initialView={'dayGridMonth'} isDefaultViewAgenda={false} />
    </>
  )
}

export default CalendarPage
