import React, { FC, useState } from 'react'
import recordType from '../../utils/types/RecordType'
import { CustomFieldType } from '../../utils/types/CustomFieldsType'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'

const CustomFieldCheckBox: FC<{
  currentItem: recordType,
  customFieldInfo: CustomFieldType,
  isEditingAllowed: boolean,
  onUpdate: (value1: string, value2: string, value3: string | number | boolean) => void,
  name: string,
}> = ({ currentItem, customFieldInfo, isEditingAllowed, onUpdate, name }) => {
  const currentRecordCustomField = currentItem?.appElements[0]?.customFieldValues?.find(
    (field: any) => +field?.customFieldId === +customFieldInfo?.id,
  )
  const checkBoxValue = currentRecordCustomField !== undefined ? currentRecordCustomField?.value === 'true' : false
  const [isChecked, setIsChecked] = useState<boolean>(checkBoxValue)
  return (
    <label className="switch">
      <input
        key={currentItem?.id}
        checked={isChecked}
        type="checkbox"
        onClick={() => {
          onUpdate(customFieldInfo.id, customFieldBaseTypes.CheckBox, !isChecked)
          setIsChecked(!isChecked)
        }}
      />
      <span className="slider round" />
    </label>
  )
}

export default CustomFieldCheckBox
