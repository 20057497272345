import React, { FC, useCallback, useState } from 'react'
import { useParams, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { findNumberOfAdmins } from 'utils/helpers/generalHelpers'

import {
  useCurrentStationCreatedAt,
  useCurrentStationElementId,
  useCurrentStationName,
  useCurrentStationOwnerId,
} from 'features/station'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { fetchUsersByStationId, useCurrentUserPermissions, useUsersByStation } from 'features/user'

import { CustomFieldListIcon, InfoIcon, UsersIcon } from 'components/Icons'
import SettingsWithTabs from 'components/SettingsWithTabs'
import UsersListPage from 'pages/workspace/UsersListPage'
import { withExplicitRole } from 'hocs/withRole'
import StationHeader from 'components/StationHeader'
import StationCustomFieldsForm from '../../components/CustomFields/StationCustomFieldsForm'
import RolesEnum from '../../utils/constant/enums'
import StationDetailsPage from './StationDetailsPage'

const StationSettingsPage: FC = () => {
  const { t } = useTranslation(['station', 'workspace', 'workspaceSettings'])
  const { stationId, workspaceId }: { stationId: string, workspaceId: string } = useParams()
  const dispatch = useDispatch()
  const ownerId = useCurrentStationOwnerId()
  // const loading = useStationsLoading()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const users = useUsersByStation()
  // const isCurrentStationLoading = useCurrentStationLoading()
  const stationElementId = useCurrentStationElementId()
  const userRole = useCurrentUserPermissions()
  const isViewer = userRole === RolesEnum.Viewer

  // const stationDetails = useCurrentStation()
  const name = useCurrentStationName()
  const createdAt = useCurrentStationCreatedAt()
  const basicLinks = [
    {
      description: t('stationSettings:stationDetailsDescription'),
      Icon: InfoIcon,
      title: t('stationSettings:stationDetails'),
      to: `/workspace/${workspaceId}/stations/${stationId}/details`,
    },
    {
      description: t('workspaceSettings:workspaceUsersDescription'),
      Icon: UsersIcon,
      title: t('workspaceSettings:workspaceUsers'),
      to: `/workspace/${workspaceId}/stations/${stationId}/users`,
    },
  ]

  const adminLinks = [
    {
      description: t('customFields:customFieldsDescription'),
      Icon: CustomFieldListIcon,
      title: t('customFields:customFields'),
      to: `/workspace/${workspaceId}/stations/${stationId}/fields`,
    },
  ]
  const links = isPremiumPlan ? [...basicLinks, ...adminLinks] : [...basicLinks]

  const fetchUsers = useCallback(() => dispatch(fetchUsersByStationId({ ownerId, stationId })), [stationId, ownerId])

  // const fetchAllStations = useCallback(
  //   () => dispatch(fetchStationsByWorkspaceId(+workspaceId)),
  //   [dispatch, workspaceId],
  // )

  const adminCount = findNumberOfAdmins(users, 'StationAccess')

  return (
    <div className="flex flex-col min-h-screen -m-6 md:-m-10">
      <>
        <StationHeader isSettingPage stationName={name} />
        <div className="lg:flex lg:flex-1">
          {isPremiumPlan && createdAt && <SettingsWithTabs createdAt={createdAt} links={links} />}
          <Switch>
            <Route path="/workspace/:workspaceId/stations/:stationId/details">
              <StationDetailsPage createdAt={createdAt} isViewer={isViewer} />
            </Route>
            {isPremiumPlan && (
              <>
                <Route path="/workspace/:workspaceId/stations/:stationId/users">
                  <div className="flex flex-1 m-4 mb-auto bg-white rounded-ts-none rounded-2xl">
                    <UsersListPage
                      adminCount={adminCount}
                      deleteUrl={`api/stations/${stationId}/user`}
                      elementType="station"
                      fetchUsers={fetchUsers}
                      fieldToGetRoleBy="StationAccess"
                      inviteUrl={`api/stations/${stationId}/invite`}
                      updateUrl={`api/element/accessGroup/${stationElementId}`}
                      users={users}
                    />
                  </div>
                </Route>
                <Route path="/workspace/:workspaceId/stations/:stationId/fields">
                  <StationCustomFieldsForm isViewer={isViewer} />
                </Route>
              </>
            )}
          </Switch>
        </div>
      </>
    </div>
  )
}

export default withExplicitRole(StationSettingsPage)
