import React, { FC } from 'react'
import { healthColors } from '../../components/GridView/cells/displayCells/HealthCell'
import { dateLanguageFormat, notEditableDateFormat } from '../../utils/helpers/generalHelpers'
import { getPriorityIconByValue } from '../../utils/helpers/recordHelpers'
import { CalendarCardIcon } from '../../components/Icons'
import UserAvatarStatus from '../../components/UserAvatar/UserAvatarStatus'

const EventDidMount: FC = (props) => {
  const recordContent = props.event._def
  const { owner, health, completedIn, priority } = recordContent.extendedProps
  const { Icon, color } = getPriorityIconByValue(priority === null ? null : +priority)

  const dateFormat = () => {
    const date = recordContent.extendedProps.startDate
    const isHijri = recordContent.extendedProps.isHijri
    const language = props.language
    return notEditableDateFormat(date, isHijri, language)
  }

  const renderEventDetails = () => {
    const UserAvatarStatusProps = {
      customSize: 10,
      imgAlt: `${owner?.name}`,
      imgSrc: owner?.link,
      minWidth: 38,
      removeIconCustomSize: 2.5,
      userId: owner?.id,
    }

    const startDate = recordContent.extendedProps.startDate ? dateFormat() : null

    return (
      <div
        className="relative p-2 bg-white rounded drop-shadow-xl"
        style={{ boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)' }}>
        <>
          <div className="px-1 pt-1.5text-gray-600 cut-text">{recordContent.title}</div>
          <div className="flex justify-between">
            <div className="flex items-center text-gray-600">
              <div>{(priority || priority === 0) && <Icon className="w-6 me-5 ms-1" style={{ color }} />}</div>
              {startDate !== null && (
                <>
                  <CalendarCardIcon className="w-5 h-5 mx-2" />
                  <div className="text-sm" dir={'auto'}>
                    {startDate}
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center justify-center">
              <UserAvatarStatus {...UserAvatarStatusProps} />
            </div>
          </div>
        </>
        <div
          className="absolute bottom-0 rounded-sm z-1 -ms-2 h-0.5 group-hover:h-1.5 transform group-hover:translate-y-0.5 transition"
          style={{
            backgroundColor: healthColors[health],
            width: `${completedIn}%`,
          }}
        />
      </div>
    )
  }

  return renderEventDetails()
}
export default EventDidMount
