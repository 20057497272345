import get from 'lodash/get'
import { StationDocumentationCommentsType } from '../types/states/StationDocumentationComments'
import { AppElementBaseTypes } from '../types/AppElementType'
import { RecordType } from '../types'

const getChildren = (list: [], itemId: string | number) => {
  return list.filter((child) => {
    if (child.parentId === itemId) {
      return child.id
    }
  })
}

export const formatOneDocData = (item: any, index: number, list: []) => {
  const itemId = get(item, 'id', null)
  const itemParentId = get(item, 'parentId', null)
  const backendOrder = get(item, 'elementOrder', 0)
  return {
    allChildrenCount: get(item, 'allChildrenCount', 0),
    archivedById: get(item, 'archivedById', null),
    author: get(item, 'owner', {}),
    backendOrder,
    basedType: get(item, 'baseElementType', 0),
    children: getChildren(list, itemId).map((ch) => ch.id),
    commentsCount: get(item, 'commentsCount', 0),
    content: get(item, 'pageContent', ''),
    createdAt: get(item, 'createdAt', null),
    detailedPath: get(item, 'detailedPath', null),
    elementPath: get(item, 'elementPath', ''),
    id: itemId,
    isArchived: get(item, 'isArchived', false),
    isPinned: get(item, 'pinStatus', 0) === 1,
    level: get(item, 'elementLevel', 1) - 3,
    order: +backendOrder,
    //change to the new one
    parentId: itemParentId,

    renderedPath: get(item, 'renderedPath', ''),
    title: get(item, 'elementName', ''),
    updatedAt: get(item, 'updatedAt', null),
    visibility: false,
    ...item,
  }
}

export const formatDocSidebarData = (data: []) => {
  const newList = data.length > 0 ? (data[0]?.isArchived ? data : data.slice(1)) : []
  return newList.map((item, index) => {
    return formatOneDocData(item, index, newList)
  })
}

export const formatDetaildPathOneDocPageData = (list: [], id) => {
  const parents = [AppElementBaseTypes.ArenaAppElement, AppElementBaseTypes.TenancyAppElement]
  return list
    .filter((item) => item.elementType > 4 && item.elementId !== id && !parents.includes(+item.elementType))
    .map((item) => ({ id: item.elementId, title: item.name }))
}

export const formatOneDocPageData = (data: any) => {
  const pageData = get(data, 'element.appElementData', {})
  const currentPageId = get(data, 'element.appElementData.id', null)
  const ownerId = get(data, 'element.appElementData.ownerId', 0)
  pageData.author = data?.elementMembers?.find((member: any) => member.userId == ownerId)?.user
  pageData.pageContent = get(data, 'element.pageContent', null)
  pageData.pinStatus = get(data, 'element.pinStatus', 0)
  pageData.detailedPath = formatDetaildPathOneDocPageData(get(data, 'detailedPath', []), currentPageId)
  return formatOneDocData(pageData, 0, [])
}

export const formattedPinnedElements = (data: any) => {
  return data?.element
    ? data?.element?.pinnedElements?.map((item: any, index: number) =>
        formatOneDocData(item.pinnedAppElementData, index, []),
      )
    : []
}

export const formattedOneComment = (item: StationDocumentationCommentsType) => {
  const extraData = { documentId: get(item, 'appElementId', ''), text: get(item, 'text', '') }
  return { ...item, ...extraData }
}

export const formattedDocComments = (data: StationDocumentationCommentsType[]) => {
  return data.map((item) => formattedOneComment(item))
}

export const formattedOptionsList = (list: any[]) => {
  return list
    .sort((a: any, b: any) => a.optionOrder - b.optionOrder)
    .map((item: any) => ({
      ...item,
      color: item.color,
      label: item.optionCaption,
      value: item.id,
    }))
}

export const formattedElement = (item: any) => {
  const legacyList = get(item, 'element.legacyPath', '').split('.')
  const legacyId = legacyList[legacyList.length - 2]
  return {
    basedType: get(item, 'element.baseElementType', 0) || get(item, 'element.appElementData.baseElementType', 0),
    element: get(item, 'element', {}) || get(item, 'element.appElementData', {}),
    hasDocument: get(item, 'element.hasDocument', false),
    id: get(item, 'element.id', '') || get(item, 'element.appElementData.id', ''),
    isSelected: false,
    legacyId,
    parentId: get(item, 'element.parentId', '') || get(item, 'element.appElementData.parentId', ''),
    path: get(item, 'element.legacyPath', '') || get(item, 'element.appElementData.legacyPath', ''),
    title: get(item, 'element.elementName', '') || get(item, 'element.appElementData.elementName', ''),
  }
}

export const formattedElementTree = (list: any[]) => {
  return list.map((item: any) => formattedElement(item))
}

export const formattedLinkedRecords = (item: RecordType) => {
  return {
    created: item.createdAt,
    elementId: item.linkedElement.id,
    id: item.id,
    legacyPath: item.linkedElement.legacyPath,
    listId: item.linkedElement.listId,
    name: item.linkedElement.elementName,
    recordData: item.recordData,
    recordId: item.linkedElement.recordId,
    stationId: item.linkedElement.stationId,
    workspaceId: item.linkedElement.workspaceId,
  }
}
