import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isExists } from 'date-fns'
import { useCurrentUser } from '../../features/user'
import { notEditableDateFormat, regex_date1, regex_date2 } from '../../utils/helpers/generalHelpers'
import { CloseIcon } from '../Icons'
import { hijriToGregorian } from '../../utils/calendarUtils'

const CustomDatePickerInput = forwardRef(
  (
    {
      value,
      onClick,
      onChangeValue,
      name,
      closeCalendar,
      customId,
      pickerRef,
      handleBlur,
      setIsCalendarOpen,
      onBlur,
      isGridView = false,
      ...rest
    },
    ref,
  ) => {
    const { i18n } = useTranslation('validation')
    const user = useCurrentUser()
    const lang = i18n.language
    const isHijri = user?.isHijri !== null ? user?.isHijri : false
    const date = value && value.length > 0 ? notEditableDateFormat(value, isHijri, lang, true) : ''
    const [inputValue, setInputValue] = useState(date)

    useEffect(() => {
      onClick()
      if (value && value.length > 0) setInputValue(notEditableDateFormat(value, isHijri, i18n.language, true))
    }, [value])

    const onClearDate = (e) => {
      e.stopPropagation()
      document.activeElement.blur()
      closeCalendar()
      setInputValue('')
      onChangeValue(null)
    }
    const onChangeInput = (e) => {
      setInputValue(e.target.value)
    }

    const onBlurInput = () => {
      if (!inputValue || inputValue === '') return onChangeValue(null)
      if (isHijri) {
        if (inputValue && (regex_date1.test(inputValue) || regex_date2.test(inputValue))) {
          const gregorianDate = hijriToGregorian(inputValue, lang)
          const enteredDate = `${gregorianDate.year}/${gregorianDate.month}/${gregorianDate.day}`
          if (gregorianDate.year.toString().length === 4) {
            onChangeValue(new Date(enteredDate))
          }
        }
      } else if (regex_date1.test(inputValue) || regex_date2.test(inputValue)) {
        const separatedDate = inputValue.split('/')
        if (isExists(+separatedDate[2], +separatedDate[1] - 1, +separatedDate[0]))
          onChangeValue(new Date(`${separatedDate[2]}/${separatedDate[1]}/${separatedDate[0]}`))
      }
    }

    return (
      <div
        key={`input-${name}`}
        className={`flex justify-between items-center border-2 rounded border-primary ${
          isGridView ? 'px-2 py-1 -mt-1 w-2/3' : 'p-2 w-1/2'
        } `}>
        <div
          className={`flex justify-start ${isGridView ? 'w-2/3' : 'w-11/12'}`}
          onBlur={onBlurInput}
          onClick={onClick}>
          <input
            autoFocus
            id={`${customId}`}
            name={`input-${name}`}
            placeholder={lang === 'ar' ? 'yyyy/mm/dd' : 'dd/mm/yyyy'}
            ref={pickerRef}
            style={{ maxWidth: '100%' }}
            type={'text'}
            value={inputValue}
            onBlur={(e) => {
              e.stopPropagation()
              document.activeElement.blur()
            }}
            onChange={onChangeInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                onBlurInput()
                onBlur()
                handleBlur()
                closeCalendar()
                document.activeElement.blur()
              }
            }}
          />
        </div>
        {value && (
          <div className="w-1/12">
            <CloseIcon className="w-4 h-4 me-0.5 -ms-0.5 text-black cursor-pointer" onMouseDown={onClearDate} />
          </div>
        )}
      </div>
    )
  },
)
CustomDatePickerInput.displayName = 'CustomDatePickerInput'

export default CustomDatePickerInput
