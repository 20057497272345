import { appElementDefaultAttributes, recordAppElementDefaultAttributes } from 'utils/constant/enums/Aggregate'

type RecordAppElementAllAttributes = recordAppElementDefaultAttributes | appElementDefaultAttributes

export const RecordAppElementAllAttributesConst = {
  ...recordAppElementDefaultAttributes,
  ...appElementDefaultAttributes,
}

export default RecordAppElementAllAttributes
