import React, { FC } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ProfilePage from 'pages/ProfilePage'
import ContactUsPage from 'pages/ContactUsPage'
import WorkspaceListingPage from 'pages/workspace/WorkspaceListingPage'

import EmptyStatePage from 'components/EmptyStatePage'
import WorkspaceRouter from 'components/Layout/WorkspaceRouter'
import { EmptyErrorIcon } from 'components/Icons'
import LinkButton, { ButtonVariant } from 'components/Buttons'
import Button from 'components/Buttons'
import UsersPage from '../../pages/admin/user/UsersList/Users'
import AdminRouter from '../Admin/AdminRouters/AdminRouter'

const Router: FC = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  return (
    <Switch>
      {/*<Route path={'/admin'}>*/}
      {/*  <AdminRouter />*/}
      {/*</Route>*/}
      <Route path="/contact-us">
        <ContactUsPage />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/workspace/:workspaceId">
        <WorkspaceRouter />
      </Route>
      <Route exact path="/">
        <WorkspaceListingPage />
      </Route>
      <Route exact path="/dashboard">
        <WorkspaceListingPage />
      </Route>
      <Route>
        <EmptyStatePage
          Button={() => (
            <Button variant={ButtonVariant.Primary} onClick={() => push('/')}>
              {t('labels.goToHome')}
            </Button>
          )}
          Icon={EmptyErrorIcon}
          description={t('labels.notFound')}
          header="404"
        />
      </Route>
    </Switch>
  )
}

export default Router
