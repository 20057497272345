import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import CustomScroll from '../../../CustomScroll'
import { useCurrentWorkspace } from '../../../../features/workspace'
import { useCurrentStation } from '../../../../features/station'
import { getInitialsFromName } from '../../../../utils/helpers/generalHelpers'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import Loader from '../../../Loader'
import { useAppDispatch } from '../../../../store'
import { getWidgetData, useWidgetById } from '../../../../features/element'

const AvatarWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEditMode: boolean,
  setIsEditorOpen: () => void,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo, isEditMode, setIsEditorOpen, onUpdateWidgetImage }) => {
  const textData: string = !widgetInfo.description || widgetInfo.description === '<p></p>' ? '' : widgetInfo.description
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [opened, setOpened] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const currentWorkspace = useCurrentWorkspace()
  const currentStation = useCurrentStation()
  const { data } = widget || widgetInfo
  const avatarImage = data?.link || ''
  const currentItem = currentStation || currentWorkspace
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  const containerStyle = {
    border: textData ? 'none' : isEditMode ? '1px dashed #00b2a9' : 'none',
    borderRadius: 4,
    height: '100%',
  }

  const openEditor = useCallback(() => {
    if (isEditMode) {
      setOpened(!opened)
      setIsEditorOpen(!opened)
    }
  }, [isEditMode, setOpened, opened])

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  return isDataLoading ? (
    <Loader />
  ) : (
    <>
      {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
        <WidgetImage isEdit={isEditMode} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
      )}
      {avatarImage.length > 0 && currentItem?.useAvatar ? (
        <div className="h-full py-2">
          <div style={containerStyle} onClick={openEditor}>
            <img alt="workspace" className="object-contain w-full h-full" src={avatarImage} />
          </div>
        </div>
      ) : (
        <div className="h-full py-2">
          <div style={containerStyle} onClick={openEditor}>
            <div
              className="flex items-center justify-center w-full mb-2 text-xl font-bold text-white rounded"
              style={{ background: data?.color, height: 'inherit' }}>
              {getInitialsFromName(currentItem?.name)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(AvatarWidget)
