import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { useTranslation } from 'react-i18next'
import interactionPlugin from '@fullcalendar/interaction'
import { format } from 'date-fns'
import { arSA, enGB } from 'date-fns/locale'
import { useMedia } from 'react-use'
import { calendarLocales, calendarLocale, normalToHijri, toIndian } from 'utils/calendarUtils'
import { getTimelineOrderByProperty } from 'utils/helpers/timeLineHelpers'
import TimelineEvent from 'components/TimelineEvent'
import { configValues } from 'utils/appConfig'
import { timeLineDuration } from '../../utils/constant/resources/stationResorces'
import { Dropdown } from '../inputs'
import { CustomFieldType } from '../../utils/types/CustomFieldsType'
import CalendarToolbar from '../CalendarToolbar'
import Loader from '../Loader'
import { RecordType } from '../../utils/types'

type TimeLineProps = {
  widgetId?: string | number,
  dropdownValue: string,
  handleEventDropFromOutside: (date: any, resource: any, draggedEL: any) => void,
  handleEventClick: (event: any) => void,
  handleEventDrop: (delta: any, event: any, newResource: any) => void,
  handleEventResize: (endDelta: any, startDelta: any, event: any) => void,
  calendarEvents: any[],
  options: any[],
  customFields: CustomFieldType[],
  linkFields: any[],
  onCreateRecord: (event: any) => void,
  setDropdownValue: (event: any) => void,
  groupByResources: any,
  isTaskListVisible: boolean,
  setIsTaskListVisible: (value: boolean) => void,
  toolBarId: string,
  toolBarClassName?: string,
  timelineClassName?: string,
  isDisabled?: boolean,
  eventDurationEditable?: boolean,
  eventStartEditable?: boolean,
  eventResourceEditable?: boolean,
  timelineRef: any,
  isLoading?: boolean,
  onRightClickItem?: (item: RecordType, position: { x: number, y: number }) => void,
  isViewer?: boolean,
}
const TimeLineComponent: FC<TimeLineProps> = (props) => {
  const {
    widgetId,
    dropdownValue,
    handleEventDropFromOutside,
    handleEventClick,
    handleEventDrop,
    handleEventResize,
    calendarEvents,
    options,
    setDropdownValue,
    groupByResources,
    customFields,
    linkFields,
    onCreateRecord,
    isTaskListVisible,
    setIsTaskListVisible,
    toolBarId,
    toolBarClassName,
    timelineClassName,
    isDisabled = false,
    eventDurationEditable = true,
    eventStartEditable = true,
    eventResourceEditable = true,
    timelineRef,
    isLoading = false,
    onRightClickItem,
    isViewer,
  } = props
  const { t, i18n } = useTranslation()
  const [currentView, setCurrentView] = useState<string>('month')
  const isDesktop = useMedia('(min-width: 1024px)')
  const resourceAreaWidth = isDesktop ? '250px' : '65px'
  const currentTimeLineDuration = timeLineDuration[currentView] || timeLineDuration['month']
  const orderByProperty = getTimelineOrderByProperty(dropdownValue)

  const customResourceRender = ({
    resource: {
      _resource: { extendedProps: customProps },
    },
  }: {
    resource: {
      _resource: {
        extendedProps: { id: string, optionCaption: string },
      },
    },
  }) => {
    return (
      <div className="flex items-center justify-center px-2 -m-3 py-2.5 min-h-12">
        <div className="flex items-center justify-start w-full h-8 mx-2 rounded">
          <p className="text-sm">{customProps.optionCaption}</p>
        </div>
      </div>
    )
  }
  const renderDropdownTitle = () => {
    return (
      <div className="flex items-center justify-between px-1">
        <p className="text-sm font-semibold">{t('station:groupBy')}</p>
        <Dropdown
          className="w-32 ms-2"
          classes={{ wrapper: 'mx-2 text-lg' }}
          isDisabled={isDisabled}
          isSearchable={false}
          menuPortalTarget={document.body}
          name="customField"
          openMenuOnFocus={true}
          options={options}
          shouldShowPlaceholder
          value={dropdownValue}
          onChange={(e) => {
            setDropdownValue(e.target.value)
          }}
        />
      </div>
    )
  }

  const formatLabelContent = useCallback(
    ({ date }) => {
      const hijriDay = normalToHijri(date).day
      const translatedHijriDate = i18n.language === 'ar' ? toIndian(hijriDay) : hijriDay
      const locale = i18n.language === 'ar' ? arSA : enGB
      switch (currentView) {
        case 'week':
          return `${format(date, 'eee, d MMM ', { locale })}(${translatedHijriDate})`
        case 'year':
        case 'quarter':
          return format(date, 'MMMM', { locale })
        default:
          return `${format(date, 'd eeee ', { locale })}(${translatedHijriDate})`
      }
    },
    [i18n.language, currentView],
  )

  const resources = () => {
    if (groupByResources[dropdownValue]) return groupByResources[dropdownValue].list

    if (customFields.find((i: any) => +i.id === +dropdownValue))
      return customFields.find((i: any) => +i.id === +dropdownValue).list

    if (linkFields.find((field: any) => +field.id === +dropdownValue))
      return linkFields.find((field: any) => +field.id === +dropdownValue).list
  }

  const calendarRef = useMemo(() => {
    return timelineRef.current
  }, [timelineRef.current !== null])

  return (
    <>
      <CalendarToolbar
        widgetId={widgetId}
        calendarRef={calendarRef}
        currentViewType={currentView}
        isTaskListVisible={isTaskListVisible}
        setCurrentViewType={setCurrentView}
        setIsTaskListVisible={setIsTaskListVisible}
        toolBarClassName={toolBarClassName}
        toolBarId={toolBarId}
      />
      <div className={timelineClassName}>
        {isLoading && <Loader />}
        {!isLoading && (
          <FullCalendar
            defaultAllDay
            eventOrder={'eventOrder'}
            drop={dropdownValue !== 'health' ? handleEventDropFromOutside : undefined}
            droppable={dropdownValue !== 'health'}
            editable={!isViewer}
            eventClick={handleEventClick}
            eventContent={(props) => <TimelineEvent {...props} />}
            eventDrop={dropdownValue !== 'health' ? handleEventDrop : undefined}
            eventDurationEditable={eventDurationEditable}
            eventReceive={({ revert }) => {
              revert()
            }}
            eventDidMount={(arg) => {
              arg.el.addEventListener('contextmenu', (e) => {
                e.preventDefault()
                onRightClickItem(arg.event._def, { x: e.pageX, y: e.pageY })
              })
            }}
            eventWillUnmount={(arg) => {
              arg.el.removeEventListener('contextmenu', (e) => {
                e.preventDefault()
                onRightClickItem(arg.event._def, { x: e.pageX, y: e.pageY })
              })
            }}
            eventResize={handleEventResize}
            eventResourceEditable={eventResourceEditable && dropdownValue !== 'health' && dropdownValue !== 'documents'}
            eventStartEditable={eventStartEditable}
            events={calendarEvents}
            firstDay="0"
            headerToolbar={{
              center: '',
              end: '',
              start: '',
            }}
            height="auto"
            initialView="resourceTimelineMonth"
            direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            locale={i18n.language}
            locales={calendarLocales}
            nowIndicator
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            ref={timelineRef}
            resourceAreaHeaderContent={renderDropdownTitle}
            resourceAreaWidth={resourceAreaWidth}
            resourceLabelContent={
              !groupByResources[dropdownValue] ? customResourceRender : groupByResources[dropdownValue].content
            }
            resourceOrder={orderByProperty}
            resources={resources()}
            schedulerLicenseKey={configValues.REACT_APP_FULLCALENDAR_LICENCE_KEY}
            select={(event) => {
              // TODO: allow addition by grouped field
              if (dropdownValue === 'ownerId' && !isViewer) onCreateRecord(event)
              // handleSelect(event, history, location)
            }}
            selectable
            slotDuration={currentView === 'week' ? '24:00:00' : undefined}
            slotLabelContent={formatLabelContent}
            views={{
              resourceTimelineYear: {
                ...currentTimeLineDuration,
                slotDuration: currentView !== 'week' ? { days: 1 } : {},
                slotMinWidth: currentView !== 'week' ? 6 : undefined,
                type: 'resourceTimelineYear',
              },
            }}
          />
        )}
      </div>
    </>
  )
}
export default TimeLineComponent
