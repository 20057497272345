import React, { FC, useMemo } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { RecordType } from 'utils/types'
import { useLabelsByIds } from 'features/list'
import { createLabelDropdownValues } from 'utils/helpers/labelsHelpers'
import LabelComponent from 'components/inputs/LabelsSelect/LabelComponent'

const LabelsCell: FC<CellRenderObject & { data: RecordType, value: RecordType['labelsIds'], isGridView: boolean }> = ({
  value,
  isGridView,
}) => {
  const labels = useLabelsByIds(value)
  const formattedLabels = useMemo(() => createLabelDropdownValues(labels), [labels])
  return (
    <>
      {formattedLabels.map((label) => (
        <LabelComponent key={label.value} data={label} isDeletingAllowed={false} isGridView={isGridView} />
      ))}
    </>
  )
}

export default LabelsCell
