import { FC } from 'react'
import { format } from 'date-fns'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { DATE_FORMAT } from 'utils/helpers/generalHelpers'
import { RecordType } from 'utils/types'
import { hijriInputFormat } from '../../../../utils/calendarUtils'
import { useCurrentUser } from '../../../../features/user'

const DateCell: FC<CellRenderObject & { value: RecordType['startDate'] | RecordType['endDate'] }> = ({
  value,
  i18n,
  user,
  cellProps,
  field,
}) => {
  const currentUser = useCurrentUser()
  const userInfo = !user ? currentUser : user
  const isHijri = userInfo?.isHijri !== null ? userInfo?.isHijri : false
  const fieldValue =
    value || cellProps?.data?.appElements[0]?.customFieldValues?.find((f) => +f.customFieldId === +field?.id)?.value
  if (fieldValue === null || fieldValue === undefined) return ''
  return isHijri ? hijriInputFormat(fieldValue, i18n.language) : format(new Date(fieldValue), DATE_FORMAT)
}

export default DateCell
