import React from 'react'

export class MentionList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0,
    }
  }

  componentDidUpdate(oldProps): void {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0,
      })
    }
  }

  onKeyDown({ event }): boolean {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }

    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }

    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler(): void {
    this.setState({
      selectedIndex: (this.state.selectedIndex + this.props.items.length - 1) % this.props.items.length,
    })
  }

  downHandler(): void {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length,
    })
  }

  enterHandler(): void {
    this.selectItem(this.state.selectedIndex)
  }

  selectItem(index): void {
    const item = this.props.items[index]

    if (item) {
      this.props.command(item)
    }
  }

  render() {
    return (
      <div className="z-30 text-sm bg-white shadow rounded-ts-none rounded-lg py-1 overflow-hidden min-w-48 flex flex-col">
        {this.props.items.map((item, index: number) => (
          <button
            className={`px-4 py-1 font-normal hover:bg-primary/10 text-left ${
              index === this.state.selectedIndex ? 'bg-primary/10' : ''
            }`}
            key={index}
            onClick={() => this.selectItem(index)}>
            {item.label}
          </button>
        ))}
      </div>
    )
  }
}
