import { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { RecordType } from 'utils/types'

const CustomFieldDropdownCell: FC<
  CellRenderObject & { data: RecordType, value: RecordType['customDropdown'], options: any[] },
> = (props) => {
  const { value, options, data, field } = props
  const fieldValue = data?.appElements[0]?.customFieldValues?.find(
    (f) => +f.customFieldId === +field.id,
  )?.customFieldOptionId
  const customDropdown = options.find((option: any) => +option.id === +fieldValue)
  if (customDropdown === undefined) return null

  return (
    <p
      className="w-full p-2 mx-2 text-sm font-bold tracking-wide text-center break-words whitespace-pre-line rounded leading-4"
      style={{ backgroundColor: `${customDropdown?.color}20`, color: customDropdown?.color }}>
      {customDropdown?.optionCaption}
    </p>
  )
}

export default CustomFieldDropdownCell
