import { RecordType } from '../types'
import { CustomFieldType } from '../types/CustomFieldsType'
import { FieldFormattingProperties, comparisonRules } from '../constant/enums/customFields'

export const formatSelectedValues = (linkedElements: any[]) => {
  return linkedElements?.map((item: any) => {
    const formattedItem = item?.element ? item?.element : item?.linkedElement
    return {
      id: item.id,
      label: formattedItem.elementName,
      path: formattedItem.legacyPath,
      value: formattedItem.id,
    }
  })
}

export const getElementLinks = (currentRecord: RecordType, customFieldInfo: CustomFieldType) => {
  const baseLinkedElements =
    currentRecord?.appElements[0]?.appElementLinks?.filter(
      (item: any) => +item.customFieldId === +customFieldInfo?.id,
    ) || []
  const targetLinkedElements =
    currentRecord?.appElements[0]?.linkedElementAppElementLinks?.filter(
      (item: any) => +item.customFieldId === +customFieldInfo?.id,
    ) || []
  const isBase =
    baseLinkedElements.length > 0 &&
    +baseLinkedElements[0]?.linkedElement?.parentId !== +currentRecord?.appElements[0]?.parentId
  return isBase ? baseLinkedElements : targetLinkedElements
}

export const getFormattedElementLinks = (
  currentRecord: RecordType,
  customFieldInfo: CustomFieldType,
): { id: number, label: string, path: string, value: number }[] => {
  const baseLinkedElements =
    currentRecord?.appElements[0]?.appElementLinks?.filter(
      (item: any) => +item.customFieldId === +customFieldInfo?.id,
    ) || []
  const targetLinkedElements =
    currentRecord?.appElements[0]?.linkedElementAppElementLinks?.filter(
      (item: any) => +item.customFieldId === +customFieldInfo?.id,
    ) || []
  const isBase =
    baseLinkedElements.length > 0 &&
    +baseLinkedElements[0]?.linkedElement?.parentId !== +currentRecord?.appElements[0]?.parentId
  return isBase
    ? baseLinkedElements?.map((item: any) => {
        const formattedItem = item?.element ? item?.element : item?.linkedElement
        return {
          id: item.id,
          label: formattedItem.elementName,
          path: formattedItem.legacyPath,
          value: formattedItem.id,
        }
      })
    : targetLinkedElements?.map((item: any) => {
        const formattedItem = item?.element ? item?.element : item?.linkedElement
        return {
          id: +item.id,
          label: formattedItem.elementName,
          path: formattedItem.legacyPath,
          value: +formattedItem.id,
        }
      })
}

export const handleFormattingRules = (rulesList: any[]) => {
  if (rulesList.length > 0) {
    return (
      rulesList.map((rule, idx) => {
        const ruleValue1 = rule?.optionCaption || rule?.name || rule.value1[0]
        const color =
          rule?.color ||
          rule?.fieldFormattingStyles.find((f: any) => +f?.styleAttributeType === FieldFormattingProperties.FONT_COLOR)
            ?.styleAttributeValue

        return {
          ...rule,
          attributeType: 1,
          fieldFormattingStyles: [
            {
              styleAttributeType: FieldFormattingProperties.FONT_COLOR,
              styleAttributeValue: color,
            },
            {
              styleAttributeType: FieldFormattingProperties.BACKGROUND_COLOR,
              styleAttributeValue: `${color}20`,
            },
          ],
          id: rule?.id,
          isActive: true,
          rule: comparisonRules.EQUAL,
          ruleOrder: idx,
          value1: [ruleValue1],
          value2: [],
        }
      }) || []
    )
  }
}
