import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-use'
import { DashboardStateWidgetType } from 'utils/types/states'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { fetchGlobalCustomFields, useGlobalCustomFields } from 'features/adminElement'
import { StationListWidgetType } from 'utils/types/states/DashboardStateWidgetType'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { widgetSelectStyles } from 'utils/helpers/dashboardHelpers'

import CustomScroll from '../../../CustomScroll'
import StationDetailsRow from '../StationListWidget/StationDetailsRow'
import { StackIcon } from '../../../Icons'
import { groupByOption } from '../StationListWidget/StationListWidget'
import { customFieldBaseTypes } from '../../../../utils/constant/enums/customFields'

const WorkSpaceStationListWidget: FC<{
  widgetData: DashboardStateWidgetType,
  isPrinting: boolean,
}> = ({ widgetData, isPrinting }) => {
  const { t, i18n } = useTranslation('dashboard')
  const dispatch = useDispatch()
  const globalFields = useGlobalCustomFields()
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const { data }: any = widgetData
  const isSmallBreakpoint = elementWidth < 700
  const customFieldsList = globalFields
    ?.filter((field: CustomFieldType) => field.baseType === customFieldBaseTypes.DropDown)
    .map((f: CustomFieldType) => ({ label: f.fieldName, list: f.customFieldOptions, value: f.id }))
  const selectBasicOptions: any = [{ label: t('dashboard:groupByOptions.__nullGrouping__'), value: '__nullGrouping__' }]
  const selectOptions = customFieldsList ? [...selectBasicOptions, ...customFieldsList] : [...selectBasicOptions]
  const [selectedOption, setSelectedOption] = useState(selectOptions[0])
  const isRtl = i18n.language === 'ar'
  const StationListWidgetData: StationListWidgetType = data
  const groupedObj = groupByOption(StationListWidgetData?.workspaceInfo, selectedOption)

  useEffect(() => {
    dispatch(fetchGlobalCustomFields())
  }, [])

  const renderWorkspaceHeader = (workspace: any) => {
    const workspaceInfo = [
      { title: t('homeDashboard:stationsCounts'), value: workspace.numberOfStations },
      { title: t('homeDashboard:tasksCounts'), value: workspace.numberOfLateTasks },
      { title: t('completed'), value: (Math.round(workspace.completionPercentage * 100) / 100).toFixed(0) + '%' },
    ]
    return (
      <div
        className={`sticky ${
          selectedOption.value === '__nullGrouping__' ? 'top-0' : 'top-10'
        } z-20 justify-between pb-4 my-4 bg-white opacity-100' +
      ' shadow-bottom-sm lg:flex`}>
        <a
          className="text-lg font-bold cursor-pointer long-text hover:text-primary"
          href={`/workspace/${workspace.workspaceId}/dashboard`}>
          {workspace.workspaceName}
        </a>
        <div className="flex">
          {workspaceInfo.map((info, index) => (
            <p key={index} className={`me-3 w-32 flex items-center ${!isSmallBreakpoint && 'justify-end'}`}>
              <span className="text-sm me-1 opacity-70">{info.title}:</span>
              <span className="text-lg font-bold">{info.value}</span>
            </p>
          ))}
        </div>
      </div>
    )
  }
  const renderEmptyList = () => {
    return (
      <div className="flex items-center justify-center h-5 p-10 border-2 border-gray-200 opacity-50 rounder">
        {t('homeDashboard:emptyList')}
      </div>
    )
  }
  return (
    <>
      {sized}
      <div className="lg:absolute lg:end-6" style={isSmallBreakpoint ? {} : { top: 4.5 }}>
        {groupedObj.data ? (
          <div
            className="flex items-center mb-2 sm:justify-end"
            style={isSmallBreakpoint ? { justifyContent: 'end' } : {}}>
            <div style={{ height: 20, width: 20 }}>
              <StackIcon />
            </div>
            <Select
              className="w-30"
              classNamePrefix="react-small-center-select"
              defaultValue={{ ...selectedOption, label: t(`dashboard:groupByOptions.${selectedOption.value}`) }}
              isRtl={isRtl}
              menuPortalTarget={document.body}
              name="sort-select"
              options={selectOptions}
              styles={widgetSelectStyles}
              onChange={(e) => {
                setSelectedOption(e)
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="mt-6 overflow-hidden" style={{ height: 'calc(100% - 52px)' }}>
        <CustomScroll hideScroll={isPrinting} maxHeight={'90vh'}>
          <div>
            {groupedObj.data &&
              Object.keys(groupedObj.data).map((key, idx) => {
                let header: string | null = ''

                if (key === '__nullGrouping__') {
                  header = null
                } else if (groupedObj.translatePath === 'dashboard') {
                  header = t(key)
                } else if (!groupedObj.translatePath) {
                  header = key
                } else {
                  header = t(groupedObj.translatePath + '.' + key)
                }
                return (
                  <>
                    {header !== null && (
                      <div className="sticky top-0 z-30 justify-between pb-2 bg-white lg:my-2 shadow-bottom-sm lg:flex sm:overflow-hidden">
                        <h2 className="font-bold text-gray-800 mb-2.5">{header}</h2>
                      </div>
                    )}
                    {groupedObj.data[key] &&
                      groupedObj.data[key].map((workspace: any, idx: number) => {
                        const list = data.values.filter(
                          (station: any) => +station.workspaceId === +workspace.workspaceId,
                        )
                        const stationRowProps = {
                          elementWidth,
                          isHomeDashboard: true,
                          list,
                        }
                        return (
                          <div key={`${workspace.workspaceId}_${idx}`} className="mb-12 lg:mt-1">
                            {renderWorkspaceHeader(workspace)}
                            {stationRowProps.list.length === 0 && renderEmptyList()}
                            <StationDetailsRow {...stationRowProps} />
                          </div>
                        )
                      })}
                  </>
                )
              })}
          </div>
        </CustomScroll>
      </div>
    </>
  )
}

export default WorkSpaceStationListWidget
