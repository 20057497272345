export const defaultFilterValue = [
  { name: 'name', operator: 'startsWith', type: 'name', value: '' },
  { name: 'description', operator: 'contains', type: 'description', value: '' },
  { name: 'ownerId', operator: 'eq', type: 'select', value: null },
  { name: 'startDate', operator: 'contains', type: 'dateCustom', value: '' },
  { name: 'endDate', operator: 'contains', type: 'dateCustom', value: '' },
  { name: 'statusId', operator: 'eq', type: 'select', value: null },
  { name: 'priority', operator: 'eq', type: 'select', value: null },
  { name: 'completedIn', operator: 'eq', type: 'completedIn', value: null },
  { name: 'health', operator: 'eq', type: 'select', value: null },
  { name: 'labelsIds', operator: 'contains', type: 'labelsIds', value: null },
]
