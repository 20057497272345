import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useFormik } from 'formik'

import baseAPI from 'utils/baseAPI'
import { ContactUsPropsType } from 'utils/types'
import { Dropdown, Input } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'

import { useTranslation } from 'react-i18next'
import { getCountryOptions, renderDropdownValue } from '../../utils/helpers/profileHelpers'
import { DropdownOptionType } from '../../utils/types/inputs'
import { withContentEditable } from '../../hocs'
import { contactUsValidationSchema } from './validationSchemas'
import { requiredNumberField } from './validationSchemas/singleFieldsValidationSchemas'

const EditableDropdownWithButtons = withContentEditable(Dropdown)

type ContactUsFormPropsType = {
  initialValues: ContactUsPropsType,
  setMessage: Dispatch<SetStateAction<string>>,
}

const PaymentForm: FC<ContactUsFormPropsType> = ({ initialValues, setMessage, onCancel, onSave }) => {
  const { t } = useTranslation('notifications')
  const [countryOptions, setCountryOptions] = useState<DropdownOptionType[]>([])

  useEffect(() => {
    getCountryOptions().then((res) => setCountryOptions(res))
  }, [])

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      cardHolderName: '',
      cardNumber: '',
      city: '',
      country: '',
      cvv: '',
      expiryDate: '',
      postCode: '',
      streetName: '',
    },
    onSubmit: (values, actions) => {
      baseAPI('api/contact_form', {
        body: { ...values, question: values.question?.trim() },
        method: 'POST',
      }).then((res) => {
        if (res.status < 400) {
          actions.resetForm()
          setMessage(t('emailSentToSupport'))
        } else {
          setMessage(t('unexpectedErrorOccuredPleaseTryAgain'))
        }
      })
    },
    validationSchema: contactUsValidationSchema,
  })
  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex justify-between">
        <div className="w-1/2 pe-5">
          <Input
            error={errors.cardNumber}
            label="cardNumber"
            name="cardNumber"
            touched={touched.cardNumber}
            value={values.cardNumber}
            onBlur={(event: any) => {
              setFieldValue('cardNumber', values.cardNumber.toLowerCase())
              handleBlur(event)
            }}
            onChange={handleChange}
          />
          <Input
            error={errors.cardHolderName}
            label="cardHolderName"
            name="cardHolderName"
            touched={touched.cardHolderName}
            value={values.cardHolderName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className="flex justify-between">
            <Input
              error={errors.expiryDate}
              label="expiryDate"
              name="expiryDate"
              touched={touched.expiryDate}
              value={values.expiryDate}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              error={errors.cvv}
              label="CVV"
              name="cvv"
              touched={touched.cvv}
              value={values.cvv}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-1/2 ps-5">
          <Input
            error={errors.streetName}
            label="streetName"
            name="streetName"
            touched={touched.streetName}
            value={values.streetName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className="mx-2 mt-13">
            <EditableDropdownWithButtons
              label={t('labels.country')}
              name="country"
              options={countryOptions}
              renderNotEditingState={(value, props) =>
                renderDropdownValue({
                  allOptions: countryOptions,
                  className: 'mt-1 mb-6 bg-gray-100',
                  label: t('labels.country'),
                  props,
                  selectedOption: value,
                })
              }
              validationSchema={requiredNumberField('country')}
              value={values.country}
              onSubmit={(values) => {
                values.country = +values.country
                updateUser(values)
              }}
            />
          </div>
          <div className="flex justify-between">
            <Input
              error={errors.postCode}
              label="postCode"
              name="postCode"
              touched={touched.postCode}
              value={values.postCode}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              error={errors.city}
              label="City"
              name="city"
              touched={touched.city}
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <Button variant={ButtonVariant.Outline} onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ms-2" type="submit" onClick={onSave}>
          Save card
        </Button>
      </div>
    </form>
  )
}
export default PaymentForm
