import React, { FC } from 'react'
import classnames from 'classnames'

import { SimpleUserType } from 'utils/types/StationType'
import UserAvatar from 'components/UserAvatar'
import { ElementMemberType, UserType } from 'utils/types'

const SelectedUser: FC<{
  selectedUser?: SimpleUserType | ElementMemberType | UserType,
  onClick?: () => void,
  isDisabled?: boolean,
  className?: string,
}> = ({ selectedUser, onClick, isDisabled, className }) => (
  <button className={classnames(className, { 'cursor-default': isDisabled })} tabIndex={-1} onClick={onClick}>
    {selectedUser !== undefined && (
      <UserAvatar imgSrc={selectedUser.link} userId={selectedUser?.id}>
        <span className="overflow-hidden text-sm text-ellipsis text-start leading-4">
          {selectedUser?.firstName} {selectedUser?.lastName}
        </span>
      </UserAvatar>
    )}
  </button>
)

export default SelectedUser
