import React, { FC } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EmptyStatePage from 'components/EmptyStatePage'
import { EmptyErrorIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import UsersPage from '../../../pages/admin/user/UsersList/Users'
import CustomFieldSettingsPage from '../../../pages/admin/customFields/CustomFieldSettingsPage'
import WorkspacesPage from '../../../pages/admin/workspace/WorkspacesPage'

const AdminRouter: FC = () => {
  const { t } = useTranslation()
  const { push } = useHistory()

  return (
    <Switch>
      <Route exact path={`/admin/workspaces/view`}>
        <WorkspacesPage />
      </Route>
      <Route exact path={`/admin/customFields/settings`}>
        <CustomFieldSettingsPage />
      </Route>
      <Route exact path={`/admin/users/view`}>
        <UsersPage />
      </Route>
      <Route>
        <EmptyStatePage
          Button={() => (
            <Button variant={ButtonVariant.Primary} onClick={() => push('/')}>
              {t('labels.goToHome')}
            </Button>
          )}
          Icon={EmptyErrorIcon}
          description={t('labels.notFound')}
          header="404"
        />
      </Route>
    </Switch>
  )
}

export default AdminRouter
