import React, { createRef, FC, useEffect, useState } from 'react'
import classnames from 'classnames'

import { ErrorMessageRefType, MultilineInputWithMentionsPropsType } from 'utils/types/inputs'

import { InputErrorMessage } from 'components/inputs'
import { useUsersByNearestElement } from 'features/user'
import InputLabel from 'components/inputs/InputLabel'
import RichTextEditorWithMentions from 'components/inputs/RichTextEditorWithMentions/RichTextEditorWithMentions'
import { UsersTablePropsType } from 'utils/types'
import { GeneralPortal } from 'utils/helpers/generalHelpers'

const RecordDescriptionRTEwithMentions: FC<MultilineInputWithMentionsPropsType> = ({
  classes,
  editorWrapperClasses,
  error,
  handleClickOutside,
  isInlineEditable,
  label,
  name,
  onChange,
  touched,
  value,
  elementRef,
  setErrorMessage,
  ...rest
}) => {
  const errorRef = createRef<ErrorMessageRefType>()
  const usersInList: UsersTablePropsType[] = useUsersByNearestElement()
  const [positions, setPositions] = useState<any>({})

  useEffect(() => {
    if (elementRef?.current) setPositions(elementRef?.current?.getBoundingClientRect())
  }, [elementRef?.current])

  useEffect(() => {
    if (setErrorMessage) setErrorMessage(error)
  }, [error])

  const renderContent = () => {
    return (
      <>
        <InputLabel className={classnames('block text-sm font-bold', classes?.label)} label={label} name={name} />
        <RichTextEditorWithMentions
          content={value ? `${value}` : ''}
          editorWrapperClasses={editorWrapperClasses}
          usersList={usersInList}
          onChange={onChange}
          {...rest}
        />
        <InputErrorMessage error={error} isInlineEditable={isInlineEditable} ref={errorRef} touched={touched} />
      </>
    )
  }
  return elementRef ? (
    <GeneralPortal parentId={'portal-root'}>
      <div
        className={classnames(classes?.wrapper || 'mb-6', 'bg-white absolute')}
        style={{
          left: positions?.left - 5,
          top: positions?.top - 30,
          width: positions.width + 10,
        }}>
        {renderContent()}
      </div>
    </GeneralPortal>
  ) : (
    <div className={classnames(classes?.wrapper || 'mb-6')}>{renderContent()}</div>
  )
}

RecordDescriptionRTEwithMentions.displayName = 'RecordDescriptionRTEwithMentions'

export default RecordDescriptionRTEwithMentions
