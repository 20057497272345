import DocumentationPagesHeader from 'components/Documentation/DocumentationPagesComponents/DocumentationPagesHeader'
import DocumentationSearch from 'components/Documentation/DocumentationSearch/DocumentationSearch'
import DocumetationSidebar from 'components/Documentation/DocumentationSidebar/DocumetationSidebar'
import React, { FC } from 'react'

const StationDocumentationSearchPage: FC = () => {
  return (
    <div className="flex min-h-0 h-full">
      <DocumetationSidebar />
      <div className="w-full overflow-y-auto">
        <DocumentationPagesHeader type="search" />
        <DocumentationSearch />
      </div>
    </div>
  )
}

export default StationDocumentationSearchPage
