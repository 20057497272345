import React, { FC } from 'react'
import classnames from 'classnames'

import { DropdownOptionType } from 'utils/types/inputs'

const ColoredDropdownOption: FC<{
  data: DropdownOptionType,
  innerRef: any,
  innerProps: any,
  selectOption: any,
  isLabelDropdown?: boolean,
}> = ({ data: { color, label }, innerRef, innerProps, selectOption, isLabelDropdown }) => {
  return (
    <button
      ref={innerRef}
      {...selectOption}
      className={classnames(
        'block min-w-0 px-2 mb-2 text-sm cursor-pointer leading-4 last:mb-0 hover:opacity-80 transition',
        {
          'max-w-full text-start': isLabelDropdown,
          'w-full text-center': !isLabelDropdown,
        },
      )}>
      <p
        {...innerProps}
        className={classnames('truncate py-2 font-bold', {
          'p-2 rounded': !isLabelDropdown,
          'px-4 rounded-full': isLabelDropdown,
        })}
        style={{ backgroundColor: `${color}20`, color: color }}>
        {label}
      </p>
    </button>
  )
}

export default ColoredDropdownOption
