import { createSlice } from '@reduxjs/toolkit'
import ElementStateType from '../../utils/types/states/ElementStateType'
import { formattedLinkedRecords } from '../../utils/formatData/documentation'
import {
  deleteElementLink,
  fetchElementMakerCheckerPolicy,
  fetchElementMakerCheckerPolicyByElement,
  fetchElementMakerCheckerPolicyRequests,
  getElementCustomFields,
  getElementLinkedRecords,
  getElementSubscriber,
  getElementTree,
  getElementUsers,
  getListLinkOptions,
  getNotificationsList,
  getWidgetData,
  onAddAppElementSubscriber,
  onAddMakerChecker,
  onDeleteAppElementSubscriber,
  updateAllNotifications,
  updateNotificationStatus,
} from './actions'

const initialState: ElementStateType = {
  columnsType: 'statusId',
  dashboardWidgets: {},
  element: null,
  elementMakerCheckerPolicy: null,
  elementMakerCheckerPolicyRequests: null,
  elementCustomFields: [],
  elementMakerCheckerPolicyRequestsCF: null,
  elementSubscriber: [],
  elementTree: [],
  elementUsers: [],
  elementUsersIsLoading: true,
  formulaList: [],
  intermediateAppElementId: null,
  isElementCustomFieldsLoading: false,
  isElementMakerCheckerPolicyLoading: false,
  isElementMakerCheckerPolicyRequestsLoading: false,
  isRejected: false,
  linkedElements: [],
  linkedElementsIsRejected: false,
  linkedElementsLoading: false,
  linkType: 5,
  listRecordOptions: {},
  listRecordOptionsLoading: true,
  loadElementSubscriber: true,
  loading: false,
  notifications: [],
  notificationsIsLoading: true,
  refetchFunction: undefined,
}

export const elementSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getElementTree.fulfilled, (state, action) => {
      const elementIdsList = [...state.elementTree].map((item: any) => item.id)
      const newElements = action.payload.filter((item: any) => !elementIdsList.includes(item.id))
      state.elementTree = [...state.elementTree, ...newElements]
      state.loading = false
    })
    builder.addCase(getListLinkOptions.fulfilled, (state, action) => {
      const { listId, optionsList } = action.payload
      const copyListRecordOptions = { ...state.listRecordOptions }
      state.listRecordOptions = { ...copyListRecordOptions, [listId]: [...optionsList] }
      state.listRecordOptionsLoading = false
    })
    builder.addCase(getListLinkOptions.pending, (state, action) => {
      state.listRecordOptionsLoading = true
    })
    builder.addCase(getElementTree.pending, (state) => {
      state.loading = true
    })

    builder.addCase(getElementTree.rejected, (state) => {
      state.isRejected = true
      state.loading = false
    })
    builder.addCase(getElementLinkedRecords.fulfilled, (state, action) => {
      state.linkedElements = action.payload.map((item) => formattedLinkedRecords(item))
      state.linkedElementsLoading = false
    })
    builder.addCase(getElementLinkedRecords.pending, (state) => {
      state.linkedElementsLoading = true
    })

    builder.addCase(getElementLinkedRecords.rejected, (state) => {
      state.linkedElementsIsRejected = true
      state.linkedElementsLoading = false
    })

    builder.addCase(deleteElementLink.fulfilled, (state, action) => {
      state.linkedElements = [...state.linkedElements].filter((item) => +item.id !== +action.payload)
    })
    // builder.addCase(deleteElementLink.pending, (state) => {})
    builder.addCase(deleteElementLink.rejected, (state, action) => {
      state.linkedElementsLoading = false
    })
    //notification stuff
    builder.addCase(onAddAppElementSubscriber.fulfilled, (state, action) => {
      state.elementSubscriber = [action.payload]
    })
    builder.addCase(onDeleteAppElementSubscriber.fulfilled, (state, action) => {
      const { userId } = action.payload
      state.elementSubscriber = [...state.elementSubscriber].filter((el: any) => el.useId !== userId)
    })
    builder.addCase(getElementSubscriber.fulfilled, (state, action) => {
      state.elementSubscriber = action.payload
      state.loadElementSubscriber = true
    })
    builder.addCase(getElementSubscriber.rejected, (state, action) => {
      state.elementSubscriber = []
      state.loadElementSubscriber = true
    })
    builder.addCase(getElementSubscriber.pending, (state, action) => {
      state.loadElementSubscriber = false
    })
    builder.addCase(getElementUsers.fulfilled, (state, action) => {
      state.elementUsers = action.payload.elementMembers
      state.elementUsersIsLoading = true
    })
    builder.addCase(getElementUsers.rejected, (state, action) => {
      state.elementUsers = []
      state.elementUsersIsLoading = true
    })
    builder.addCase(getElementUsers.pending, (state, action) => {
      state.elementUsersIsLoading = false
    })
    builder.addCase(getNotificationsList.fulfilled, (state, action) => {
      state.notifications = action?.payload?.activityQueues
      state.notificationsIsLoading = true
    })
    builder.addCase(getNotificationsList.rejected, (state, action) => {
      state.notifications = []
      state.notificationsIsLoading = true
    })
    builder.addCase(getNotificationsList.pending, (state, action) => {
      state.notificationsIsLoading = false
    })
    builder.addCase(updateNotificationStatus.pending, (state, action) => {
      state.notifications = state.notifications.filter((notification) => +notification.id !== +action.meta.arg.id)
    })
    // builder.addCase(updateNotificationStatus.fulfilled, () => {})
    builder.addCase(updateNotificationStatus.rejected, (state, action) => {
      state.notifications = [...state.notifications, action.payload]
    })
    builder.addCase(updateAllNotifications.fulfilled, (state, action) => {
      state.notifications = []
    })
    // getWidgetData
    builder.addCase(getWidgetData.fulfilled, (state, action) => {
      state.dashboardWidgets[action.payload.id] = action.payload
    })
    builder.addCase(getWidgetData.rejected, (state, action) => {
      state.dashboardWidgets[action?.payload?.id] = null
    })
    // getElementCustomFields
    builder.addCase(getElementCustomFields.fulfilled, (state, action) => {
      state.elementCustomFields = action.payload
      state.isElementCustomFieldsLoading = false
    })
    builder.addCase(getElementCustomFields.pending, (state, action) => {
      state.isElementCustomFieldsLoading = true
    })
    builder.addCase(getElementCustomFields.rejected, (state, action) => {
      state.isElementCustomFieldsLoading = false
    })
    // fetchElementMakerCheckerPolicy
    builder.addCase(fetchElementMakerCheckerPolicy.fulfilled, (state, action) => {
      state.elementMakerCheckerPolicy = action.payload.makerCheckerPolicy
      state.isElementMakerCheckerPolicyLoading = false
    })
    builder.addCase(fetchElementMakerCheckerPolicy.pending, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = true
    })
    builder.addCase(fetchElementMakerCheckerPolicy.rejected, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = false
    })
    // onAddMakerChecker
    builder.addCase(onAddMakerChecker.fulfilled, (state, action) => {
      state.elementMakerCheckerPolicy = action.payload
      state.isElementMakerCheckerPolicyLoading = false
    })
    builder.addCase(onAddMakerChecker.pending, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = true
    })
    builder.addCase(onAddMakerChecker.rejected, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = false
    })
    // fetchElementMakerCheckerPolicyByElement
    builder.addCase(fetchElementMakerCheckerPolicyByElement.fulfilled, (state, action) => {
      state.elementMakerCheckerPolicy = action.payload.makerCheckerPolicy
      state.isElementMakerCheckerPolicyLoading = false
    })
    builder.addCase(fetchElementMakerCheckerPolicyByElement.pending, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = true
    })
    builder.addCase(fetchElementMakerCheckerPolicyByElement.rejected, (state, action) => {
      state.isElementMakerCheckerPolicyLoading = false
    })
    // fetchElementMakerCheckerPolicyRequests
    builder.addCase(fetchElementMakerCheckerPolicyRequests.fulfilled, (state, action) => {
      state.elementMakerCheckerPolicyRequests = action.payload.changeRequests
      state.elementMakerCheckerPolicyRequestsCF = action.payload.customFields
      state.isElementMakerCheckerPolicyRequestsLoading = false
    })
    builder.addCase(fetchElementMakerCheckerPolicyRequests.pending, (state, action) => {
      state.isElementMakerCheckerPolicyRequestsLoading = true
    })
    builder.addCase(fetchElementMakerCheckerPolicyRequests.rejected, (state, action) => {
      state.isElementMakerCheckerPolicyRequestsLoading = false
    })
  },
  initialState,
  name: 'element',
  reducers: {
    cleanElementSubscriber: (state, action) => {
      state.elementSubscriber = []
    },
    cleanElementUsers: (state, action) => {
      state.elementUsers = []
    },
    clearCurrentIntermediateElementId: (state) => {
      state.intermediateAppElementId = null
    },
    clearDashboardWidgets: (state) => {
      state.dashboardWidgets = {}
    },
    clearDashboardWidgets: (state) => {
      state.dashboardWidgets = {}
    },
    clearElementMakerCheckerPolicy: (state) => {
      state.elementMakerCheckerPolicy = null
      state.isElementMakerCheckerPolicyLoading = true
    },
    clearElementTree: (state) => {
      state.elementTree = []
    },
    resetFormulaList: (state, action) => {
      state.formulaList = []
    },
    setCurrentIntermediateElementId: (state, action) => {
      state.intermediateAppElementId = action.payload
    },
    setFormulaList: (state, action) => {
      state.formulaList = action.payload
    },
    setKanbanViewColumnsType: (state, action) => {
      state.columnsType = action.payload
    },
    setLinkType: (state, action) => {
      state.linkType = action.payload
    },
    updateDashboardWidgets: (state, action) => {
      const widgetData = state.dashboardWidgets[action.payload.id]
      state.dashboardWidgets[action.payload.id] = { ...widgetData, ...action.payload }
    },
    refetchAfterUpdate: (state, action) => {
      state.refetchFunction = action.payload
    },
    clearRefetchAfterUpdate: (state) => {
      state.refetchFunction = undefined
    },
  },
})

export const {
  clearElementTree,
  setKanbanViewColumnsType,
  setCurrentIntermediateElementId,
  clearCurrentIntermediateElementId,
  setLinkType,
  cleanElementSubscriber,
  cleanElementUsers,
  setFormulaList,
  resetFormulaList,
  clearDashboardWidgets,
  updateDashboardWidgets,
  clearElementMakerCheckerPolicy,
  refetchAfterUpdate,
  clearRefetchAfterUpdate,
} = elementSlice.actions

export default elementSlice.reducer
