import React, { FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from 'components/Buttons'
import { TrashIcon } from '../Icons'

const UploadExcelFile: FC<{ setUploadedFile: any, onClose: () => void, onSubmit: () => void }> = ({
  setUploadedFile,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('records')
  const [hideFiles, setHideFiles] = useState(false)
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.xlsx,.csv',
    noClick: true,
    noKeyboard: true,
    onDrop,
  })
  const files = acceptedFiles.map((file) => {
    setUploadedFile(file)
    return (
      <p key={file.path}>
        {file.path} - {file.size} bytes
      </p>
    )
  })

  const afterUploadFile = useCallback(
    (data) => {
      setUploadedFile(data)
    },
    [files],
  )

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="flex justify-end">
        <Button variant={ButtonVariant.Outline} onClick={onClose}>
          {t('import.cancel')}
        </Button>
        {hideFiles || files.length === 0 ? (
          <Button
            className="ms-1"
            variant={ButtonVariant.Primary}
            onClick={() => {
              open()
              setHideFiles(false)
            }}>
            {t('import.uploadFile')}
          </Button>
        ) : (
          <Button className="ms-1" variant={ButtonVariant.Primary} onClick={onSubmit}>
            {t('import.confirmUpload')}
          </Button>
        )}
      </div>
      <div className={`flex mt-2 ${hideFiles || files.length === 0 ? 'hidden' : 'visible'}`}>
        <div>{files}</div>
        <div
          className="w-5 h-5 mt-1 ms-2"
          onClick={() => {
            setHideFiles(true)
          }}>
          <TrashIcon className="text-danger" />
        </div>
      </div>
    </div>
  )
}

export default UploadExcelFile
