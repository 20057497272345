import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { useRoles } from 'hooks'
import { withButtonsAndContentEditable } from 'hocs'

import { getInheritedCustomFields } from 'utils/helpers/customFields'
import { ListType } from 'utils/types'
import userType from 'utils/types/UserType'

import { ElementType, Input, MultilineInputWithMentions } from 'components/inputs'
import PanelFormWrapper from 'components/PanelFormWrapper'

import {
  archiveListById,
  fetchListById,
  fetchListsByStationId,
  updateList,
  useCurrentListDetails,
  useCurrentListLoading,
  useListsIds,
  onMoveList,
} from 'features/list'
import { updateListCustomFields } from 'features/element'
import { useCurrentWorkspace } from '../../features/workspace'
import { useCurrentStation, useStationsList } from '../../features/station'
import MoveModal from '../MoveModal'
import ConfirmationModal from '../ConfirmationModal'
import ElementCustomFields from '../CustomFields/ElementCustomFields'
import Loader from '../Loader'
import { useAppDispatch } from '../../store'
import ElementOperations from '../../utils/constant/enums/element'
import { addListValidationSchema } from './validationSchemas'
import {
  requiredTextFieldValidationSchema,
  textFieldValidationSchema,
} from './validationSchemas/singleFieldsValidationSchemas'

const EditableInputWithButtons = withButtonsAndContentEditable(Input)
const EditableMultilineWithButtons = withButtonsAndContentEditable(MultilineInputWithMentions)

type AddListToStationFormPropsType = {
  list: ListType,
  closePanel: () => void,
  user: userType,
  isViewer?: boolean,
}

export interface ElementTypeWithColor extends ElementType {
  color?: string;
  position?: number;
}

const AddListToStationForm: FC<AddListToStationFormPropsType> = ({ list, closePanel, user, isViewer }) => {
  const { workspaceId, stationId, listId }: { workspaceId: string, stationId: string, listId: string } = useParams()
  const { t } = useTranslation('workspace')
  const listsIds = useListsIds()
  const stations = useStationsList()
  const currentList = useCurrentListDetails()
  const currentListLoading = useCurrentListLoading()
  const listData = { ...currentList, stationId }
  const dispatch = useAppDispatch()
  const history = useHistory()
  const currentWorkspace = useCurrentWorkspace()
  const currentStation = useCurrentStation()
  const { isEditor } = useRoles()
  const customFieldsList = currentList?.customFields ? getInheritedCustomFields(currentList?.customFields) : []
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [localLoader, setLocalLoader] = useState<boolean>(true)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [shouldRemoveElementAfterMove, setShouldRemoveElementAfterMove] = useState<boolean>(true)
  const isEditable = isEditor && !listData?.appElement?.isArchived

  const { errors, handleChange, handleSubmit, handleBlur, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: currentList?.appElement?.description,
      name: currentList?.name,
    },
    onSubmit: (values) => {
      const body: Partial<ListType> = {
        ...values,
        description: values.description?.trim(),
        id: currentList?.id,
        labels: currentList?.labels,
        stationId: +listData?.stationId,
        statuses: currentList?.statuses,
      }
      dispatch(updateList({ changes: body, list }))
      closePanel()
    },
    validationSchema: addListValidationSchema,
  })

  useEffect(() => {
    if (listId) dispatch(fetchListById(+listId)).then(() => setLocalLoader(false))
  }, [listId])

  const onUpdateElement = (value: any) => {
    dispatch(updateListCustomFields({ body: value, listId: currentList?.id }))
  }

  const onArchive = () => {
    setIsArchiveModalOpen(false)
    const status = currentList?.appElement?.isArchived
    dispatch(
      archiveListById({
        elementId: currentList?.appElement?.id,
        recursive: Number(isChecked),
        status: Number(!status),
      }),
    )
    if (stationId) {
      if (!listsIds[0]) {
        dispatch(fetchListsByStationId({ archiveStatus: !status, stationId }))
      }
      setTimeout(() => {
        const listToRedirectTo = +listsIds[0] === +listData?.id ? listsIds[1] : listsIds[0]
        dispatch(fetchListById(listToRedirectTo))
        history.replace(`/workspace/${workspaceId}/stations/${stationId}/lists/${listToRedirectTo}`)
      }, 1000)
    }
  }

  const showArchive = (listsIds?.length > 1 || listData?.appElement?.isArchived) && !isViewer
  const commonProps = {
    isLabelVisible: true,
    onChange: handleChange,
    onSubmit: handleSubmit,
  }
  const onRefetch = () => {
    const listToRedirectTo = +listsIds[0] === +listData?.id ? +listsIds[1] : +listsIds[0]
    history.replace(`/workspace/${workspaceId}/stations/${stationId}/lists/${listToRedirectTo}/kanban`)
    setShouldRemoveElementAfterMove(true)
  }

  return currentListLoading || localLoader ? (
    <Loader loaderClasses="flex flex-1 w-full h-full justify-center items-center" svgClasses="w-10 h-10" />
  ) : (
    <PanelFormWrapper
      buttons={null}
      elementId={listData?.appElement?.id}
      list={listData}
      user={user}
      onSubmit={handleSubmit}>
      <div className="flex flex-col p-2 lg:flex-row">
        <div className="pb-0 lg:pe-3 lg:w-8/12">
          <EditableInputWithButtons
            isOnClickDisabled={!isEditable}
            label={t('common:labels.name')}
            name="name"
            notEditingClassName="me-1 mt-2 mb-10"
            validationSchema={requiredTextFieldValidationSchema('name', 100)}
            value={values.name}
            {...commonProps}
          />
          <EditableMultilineWithButtons
            inputWrapperClassName="my-2"
            isOnClickDisabled={!isEditable}
            label={t('common:labels.description')}
            name="description"
            notEditingClassName="me-1 my-2"
            validationSchema={textFieldValidationSchema('description', 1000)}
            value={values.description}
            {...commonProps}
          />
          {!listData?.appElement?.isArchived && (
            <>
              <button
                className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  const body = {
                    appElementId: +listData?.appElement?.id,
                    newParentId: null,
                    operation: ElementOperations.Duplicate,
                  }
                  dispatch(onMoveList(body))
                }}>
                {t('common:labels.duplicate')}
              </button>
              {stations?.length > 1 && (
                <>
                  {listsIds.length > 1 && (
                    <button
                      className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsMoveModalOpen(true)
                      }}>
                      {t('common:labels.move')}
                    </button>
                  )}
                  <button
                    className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setShouldRemoveElementAfterMove(false)
                      setIsMoveModalOpen(true)
                    }}>
                    {t('common:labels.copyToOtherStation')}
                  </button>
                </>
              )}
            </>
          )}
          {showArchive && (
            <button
              className="py-2 text-danger text-start hover:text-primary"
              type="button"
              onClick={() => setIsArchiveModalOpen(true)}>
              {listData?.appElement?.isArchived ? t('lists:unArchiveList') : t('lists:archiveList')}
            </button>
          )}
        </div>
        {customFieldsList?.length > 0 && (
          <div className="pt-0 lg:w-4/12">
            <label className="block mb-4 text-sm font-bold">{t('customFields:customFields')}</label>
            <table cellPadding="0" className="w-full max-h-full overflow-y-auto border-separate table-fixed">
              <tbody className="w-full">
                <ElementCustomFields
                  currentElement={currentList}
                  customFieldsList={customFieldsList}
                  isEditor={isEditor}
                  updateElement={onUpdateElement}
                />
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ConfirmationModal
        checkBoxMessage={listData?.appElement?.isArchived ? t('common:archiveRecursiveMessage') : undefined}
        confirmMessage={listData?.appElement?.isArchived ? t('workspace:unarchive') : t('workspace:archive')}
        confirmationMessage={t(
          listData.appElement?.isArchived ? 'lists:confirmListUnarchive' : 'lists:confirmListArchive',
          {
            interpolation: { escapeValue: false },
            name: listData.name,
          },
        )}
        isChecked={isChecked}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onChangeCheckBox={() => setIsChecked(!isChecked)}
        onConfirm={onArchive}
      />
      <MoveModal
        currentElementId={+listData?.appElement?.id}
        currentParentId={currentWorkspace?.appElement?.id}
        directParentId={currentStation?.appElement?.id}
        elementBaseType={1}
        isMoveModalOpen={isMoveModalOpen}
        setIsMoveModalOpen={setIsMoveModalOpen}
        shouldRemoveElementAfterMove={shouldRemoveElementAfterMove}
        onMoveItem={onMoveList}
        onRefetch={onRefetch}
      />
    </PanelFormWrapper>
  )
}

export default AddListToStationForm
