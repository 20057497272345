import React, { FC, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'

import {
  fetchListsByStationId,
  // useLists,
  useListLoading,
  // useCurrentList,
  fetchListById,
  // useCurrentListDetails,
  useListsIds,
  useCurrentListId,
} from 'features/list'
import { useCurrentStation } from 'features/station'

import ListDetailsPage from 'pages/list/ListDetailsPage'

import ListNavigation from 'components/ListNavigation'
import StationHeader from 'components/StationHeader'
import Loader from 'components/Loader'
import { useTranslation } from 'react-i18next'
import { clearCurrentList, clearLists } from '../../features/list/listSlice'
import { clearCurrentDocumentation } from '../../features/station/stationSlice'
import ArchivePanel from '../../components/ArchivePanel/ArchivePanel'

const StationMainPage: FC = () => {
  const { workspaceId, stationId, listId } = useParams<{ workspaceId: string, stationId: string, listId: string }>()
  const {
    location: { search },
  }: { location: { search: string | undefined } } = useHistory()
  const { t } = useTranslation('lists')
  const areListsLoading = useListLoading()
  const currentStation = useCurrentStation()
  // const currentStationDetails = useCurrentListDetails()
  const listsIds = useListsIds()
  // const currentListId = useCurrentListId()
  const [archiveStatus, setArchiveStatus] = useState(currentStation ? currentStation?.appElement?.isArchived : false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    // to return all station list by default when component loaded for the first time
    return () => {
      dispatch(clearLists())
      dispatch(clearCurrentList())
      dispatch(clearCurrentDocumentation())
    }
  }, [])

  useEffect(() => {
    dispatch(fetchListsByStationId({ archiveStatus: Number(currentStation?.appElement?.isArchived), stationId }))
  }, [stationId])

  useEffect(() => {
    if (listsIds.length > 0 && listId === undefined && !areListsLoading) {
      const firstListId = get(listsIds, '[0]')
      if (firstListId !== undefined)
        history.replace(`/workspace/${workspaceId}/stations/${stationId}/lists/${firstListId}`)
    }
  }, [listsIds, history, stationId, workspaceId, listId, areListsLoading])

  if (areListsLoading)
    return <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />

  return (
    <>
      {archiveStatus && (
        <div className="relative flex -m-6 md:-m-10" style={{ width: '100vw' }}>
          <ArchivePanel message={t('archiveListsMessage')} />
        </div>
      )}
      <div className="flex flex-col min-h-screen -m-6 md:-m-10" style={archiveStatus ? { marginTop: 40 } : {}}>
        <StationHeader isListsPage stationName={currentStation?.name}>
          <ListNavigation archiveStatus={archiveStatus} setArchiveStatus={setArchiveStatus} />
        </StationHeader>
        <ListDetailsPage />
      </div>
    </>
  )
}

export default StationMainPage
