import { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { ExtendIcon } from 'components/Icons'

import { useGridViewMethods } from 'contexts/GridViewContext'
import { RecordType } from 'utils/types'

const IdCell: FC<CellRenderObject & { data: RecordType }> = ({ remoteRowIndex, data: { id } }) => {
  const { onEdit } = useGridViewMethods()

  return (
    <div className="relative flex justify-end w-full leading-6 whitespace-nowrap">
      <button
        className="absolute inset-0 w-full bg-gray-200 opacity-0 transition transition-opacity hover:opacity-100"
        onClick={() => {
          const recordId = id.includes('/') ? id.split('/')[1] : id
          onEdit(recordId)
        }}>
        <ExtendIcon className="w-6 mx-auto my-0 text-gray-600" />
      </button>
      {remoteRowIndex + 1}.
    </div>
  )
}

export default IdCell
