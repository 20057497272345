import React, { FC } from 'react'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import baseAPI from 'utils/baseAPI'
import { Input, PasswordInput } from 'components/inputs'
import Loader from 'components/Loader'
import Button, { LinkButton, ButtonVariant } from 'components/Buttons'
import Link from 'components/Link'
import GoogleLogin from 'components/GoogleLogin'
import SSOLogin from 'components/SSOLogin'
import { configValues } from 'utils/appConfig'
import { handleAuthResponse } from 'utils/helpers/authHelpers'

import { loginValidationSchema } from '../validationSchemas'

const LoginForm: FC = () => {
  const { t } = useTranslation(['signIn', 'signUp'])
  const history = useHistory()
  const location = useLocation()

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      baseAPI('auth/login', {
        body: values,
        headers: {
          Authorization: 'auth_token', //TODO: setup correct token
        },
        method: 'POST',
      }).then((response) => {
        handleAuthResponse({ response, setLoading: setSubmitting, history, location })
      })
    },
    validationSchema: loginValidationSchema,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Input
        classes={{ wrapper: 'mb-6' }}
        data-testid="email-input"
        error={errors.email}
        label={t('common:labels.email')}
        name="email"
        touched={touched.email}
        type="email"
        value={values.email}
        onBlur={(event: any) => {
          setFieldValue('email', values.email.toLowerCase())
          handleBlur(event)
        }}
        onChange={handleChange}
      />
      <PasswordInput
        classes={{ wrapper: 'mb-6' }}
        data-testid="password-input"
        error={errors.password}
        id="password"
        label={t('common:labels.password')}
        name="password"
        touched={touched.password}
        value={values.password}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <div className="mt-10">
        <Button data-testid="sign-in-button" disabled={isSubmitting} full type="submit">
          {isSubmitting ? <Loader /> : t('signIn')}
        </Button>

        {configValues.IS_GOOGLE_LOGIN_ENABLED && <GoogleLogin isSignIn />}
        {configValues.IS_SSO_ENABLED && <SSOLogin />}
      </div>

      <div className="mt-16 mb-4 font-bold text-gray-600">{t('dontHaveAnAccount')}</div>
      <LinkButton full to="/sign-up" variant={ButtonVariant.Outline}>
        {t('signUp:signUp')}
      </LinkButton>
      <div className="flex flex-col mt-16 mb-4">
        <Link className="py-1 text-sm" to="/forgot-password">
          {t('forgotPassword')}
        </Link>
        <Link className="py-1 text-sm" to="/resend-email">
          {t('didntReceiveConfirmationEmail')}
        </Link>
      </div>
    </form>
  )
}

export default LoginForm
