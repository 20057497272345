import {
  archiveStationById,
  getStationArchivedDocuments,
  getStationDocSidebar,
  useAllArchivedDocuments,
  useArchivedDocuments,
  useArchivedDocumentsHiddenElements,
  useArchivedDocumentsLoading,
} from 'features/station'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loader from '../../Loader'
import { useAppDispatch } from '../../../store'
import ConfirmationModal from '../../ConfirmationModal'
import SidebarItemContent from '../DocumentationSidebar/SidebarItemContent'
import { findAllChildren } from '../DocumentationSidebar/functions/findRelatives'
import {
  updateDocArchivedHiddenElements,
  updateDocumentationArchivedElement,
} from '../../../features/station/stationSlice'

const DocumentationArchivePage = () => {
  const { t } = useTranslation()
  const allCards = useAllArchivedDocuments()
  const cards = useArchivedDocuments()
  const isLoading = useArchivedDocumentsLoading()
  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<any>(null)
  const hiddenElements = useArchivedDocumentsHiddenElements()
  const { docId } = useParams<{
    docId: string,
  }>()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()

  const onCallArchiveRequest = (pageId) => {
    appDispatch(getStationArchivedDocuments(+pageId)).then((res) => {
      const parentsIds = res.payload.filter(({ allChildrenCount }) => +allChildrenCount > 0).map(({ id }) => id)
      const allChildrenIds = res.payload.filter(({ parentId }) => parentsIds.includes(parentId)).map(({ id }) => id)
      dispatch(updateDocArchivedHiddenElements([...hiddenElements, ...allChildrenIds]))
    })
  }

  const onArchive = () => {
    appDispatch(
      archiveStationById({
        elementId: +currentPage?.id,
        recursive: 1,
        status: 0,
      }),
    ).then(() => {
      setCurrentPage(null)
      setIsArchiveOpen(false)
      onCallArchiveRequest(docId)
      dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId }))
    })
  }
  useEffect(() => {
    onCallArchiveRequest(docId)
  }, [])

  useEffect(() => {
    const list = cards.filter(({ id }) => !hiddenElements.includes(id))
    dispatch(updateDocumentationArchivedElement(list))
  }, [hiddenElements])

  const onOpenArchive = (page: any) => {
    setIsArchiveOpen(true)
    setCurrentPage(page)
  }

  const onOpenDocFolder = useCallback(
    (item: any) => {
      if (item && item.children) {
        if (allCards && cards) {
          const allChildrenIds = findAllChildren(cards, item.children || [])
          const allChildrenList = allCards.filter((i: any) => +i.parentId === +item.id)
          const itemIndex = cards.findIndex((i) => +i.id === +item.id)
          const newList = cards.filter((i) => !allChildrenIds.includes(i.id))
          newList.splice(itemIndex + 1, 0, ...allChildrenList)
          dispatch(updateDocumentationArchivedElement(newList))
          if (hiddenElements.filter((i) => item.children.includes(i)).length) {
            dispatch(updateDocArchivedHiddenElements(hiddenElements.filter((i) => !item.children.includes(i))))
          } else {
            dispatch(updateDocArchivedHiddenElements([...hiddenElements, ...allChildrenIds]))
          }
        }
      }
    },
    [hiddenElements, cards, allCards],
  )

  return (
    <div className="flex flex-col min-h-0 pt-14 px-14">
      <h1 className="mb-10">{t('documentation:archivedPages')}</h1>
      {cards?.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full mt-10 opacity-50">
          {isLoading ? <Loader /> : t('documentation:noArchivedPages')}
        </div>
      ) : (
        <>
          <div className="w-full p-3 border-b-2 border-gray-100 grid grid-cols-4">
            <p>{t('documentation:title')}</p>
            <p>{t('documentation:path')}</p>
            <p>{t('documentation:dateArchived')}</p>
            <p>{t('records:listing:owner')}</p>
          </div>
          <div className="flex flex-col flex-1 min-h-0 overflow-y-auto">
            {cards.map((cardItem, index) => {
              return (
                <SidebarItemContent
                  key={cardItem.id}
                  dragChildren={cardItem.children}
                  index={index}
                  isArchived={cardItem?.isArchived}
                  item={cardItem}
                  onOpen={onOpenDocFolder}
                  onOpenArchive={onOpenArchive}
                />
              )
            })}
          </div>
        </>
      )}
      <ConfirmationModal
        confirmMessage={t('common:labels.unarchive')}
        confirmationMessage={t('documentation:unarchiveDocConfirmationMessage', {
          interpolation: { escapeValue: false },
        })}
        isModalOpen={isArchiveOpen}
        onCancel={() => setIsArchiveOpen(false)}
        onConfirm={onArchive}
      />
    </div>
  )
}

export default memo(DocumentationArchivePage)
