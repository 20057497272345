import React, { FC, SVGAttributes } from 'react'

type EmptyStatePagePropsType = {
  Icon: FC<SVGAttributes<SVGElement>>,
  header: string,
  description: string,
  Button?: FC,
  classes?: string,
}

const EmptyStatePage: FC<EmptyStatePagePropsType> = ({
  Icon,
  header,
  description,
  Button,
  classes = 'flex flex-col items-center justify-center flex-1 ms-10',
}) => (
  <div className={classes}>
    <Icon className="w-32 mb-6" />
    <h2 className="font-bold text-center text-gray-700">{header}</h2>
    {<p className="mt-2 mb-6 text-sm text-center w-80">{description}</p>}
    {Button && <Button />}
  </div>
)

export default EmptyStatePage
