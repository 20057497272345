import { createSlice } from '@reduxjs/toolkit'

import { StationStateType } from 'utils/types/states'

import { Layout } from 'react-grid-layout'
import { StationType } from 'utils/types'
import { minWidgetHeight, widgetHeight } from '../../utils/types/widgetUtils'
import { normalToHijri } from '../../utils/calendarUtils'
import {
  addStation,
  addWidgetToStationDashboard,
  fetchStationById,
  fetchStations,
  fetchStationsByWorkspaceId,
  getAvailableStationWidgets,
  getStationDashboard,
  saveStationDashboard,
  updateStation,
  uploadAvatar,
  getAllDashboardForStation,
  getStationDocSidebar,
  getStationDocOneItem,
  getStationDocSearchResults,
  getStationDocComments,
  updateStationDocsOneComment,
  removeStationDocsOneComment,
  addStationDocsOneComment,
  addStationDocsTextSelectionInDoc,
  removeStationDocsTextSelectionInDoc,
  createStationDocsOneDocAttachment,
  getStationDocsOneDocAttachment,
  deleteStationDocsOneDocAttachment,
  createOrDeleteStationDocPublicLink,
  getStationDocPublicLink,
  getStationDocsOneDocInfo,
  updateStationDocsOneDocInfo,
  getStationDocsAllLabels,
  getCurrentStationDocumentation,
  getCurrentDocumentationDetails,
  createDocumentationNewPage,
  createNewDocumentation,
  pinDocumentationPage,
  updateDocumentationDocument,
  updateStationCustomFields,
  archiveStationById,
  getStationArchivedDocuments,
  onCreateNewStationDashboard,
  onDeleteStationDashboard,
  removeStationById,
} from './actions'

const initialState: StationStateType = {
  allArchivedDocuments: [],
  allArchivedDocumentsIds: [],
  archivedDocuments: [],
  archivedDocumentsHiddenElements: [],
  archivedDocumentsLoading: false,
  availableStationDashboardWidgets: null,
  currentDashboardId: null,
  currentStation: {},
  currentStationDocumentation: null,
  currentStationId: undefined,
  currentStationLoading: true,
  customDashboard: null,
  customListsFiltersOptions: {
    labels: [],
    owners: [],
    statuses: [],
  },
  dashboard: null,
  dashboardLoading: false,
  dashboards: null,
  dashboardsLoading: false,

  docSidebarHiddenElements: [],
  //change it to null when backend will be ready
  docSidebarLoading: true,
  documentationAllLabels: null,
  documentationDetails: [],
  documentationItemAttachments: null,
  documentationItemAttachmentsLoading: true,
  documentationItemAttachmentsOpen: false,
  documentationItemComments: null,
  documentationItemCommentsOpen: false,
  documentationItemInfo: null,
  documentationItemInfoOpen: false,
  documentationItemLinks: null,
  documentationItemLinksOpen: false,
  documentationOneItem: null,
  documentationOneItemLoading: true,
  documentationPublicLink: '',
  documentationSearchResults: null,
  documentationSearchResultsLoading: false,
  documentationSidebar: null,
  documentationSidebarAllItems: null,
  isArchivedDocActive: false,
  isRejected: false,
  isStationArchived: false,
  loading: true,
  openSidebarItems: [],
  showLatest: true,
  stations: [],
  updateDocumentLoading: false,
}

export const stationSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchStations.fulfilled, (state, action) => {
      state.stations = action.payload
      state.loading = false
    })

    builder.addCase(fetchStations.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchStations.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(fetchStationById.fulfilled, (state, action) => {
      const station = action.payload
      const fetchedStationIndex = state.stations.findIndex(({ id: stationId }) => station.id === stationId)
      if (fetchedStationIndex > -1)
        state.stations[fetchedStationIndex] = { ...state.stations[fetchedStationIndex], ...station }
      else state.stations = [...state.stations, action.payload]
      state.currentStationId = action.payload.id
      state.currentStation = action.payload
      state.currentStationLoading = false
    })

    builder.addCase(updateStationCustomFields.fulfilled, (state, action) => {
      const station = action.payload
      const fetchedStationIndex = state.stations.findIndex(({ id: stationId }) => station.id === stationId)
      if (fetchedStationIndex > -1)
        state.stations[fetchedStationIndex] = { ...state.stations[fetchedStationIndex], ...station }
      else state.stations = [...state.stations, action.payload]
      state.currentStationId = action.payload.id
      state.currentStation = state.stations[fetchedStationIndex]
      state.currentStationLoading = false
    })

    builder.addCase(fetchStationById.pending, (state, action) => {
      state.currentStationId = action.meta.arg
      state.currentStationLoading = true
    })

    builder.addCase(fetchStationById.rejected, (state) => {
      state.currentStationLoading = false
    })

    builder.addCase(fetchStationsByWorkspaceId.fulfilled, (state, action) => {
      state.stations = action.payload.map((station: StationType) => {
        if (station.id === state.currentStationId) {
          return { ...station, ...state.currentStation }
        } else {
          return { ...station }
        }
      })

      state.loading = false
    })

    builder.addCase(fetchStationsByWorkspaceId.pending, (state) => {
      state.loading = true
      if (state.isRejected) state.isRejected = false
    })

    builder.addCase(fetchStationsByWorkspaceId.rejected, (state) => {
      state.isRejected = true
      state.loading = false
    })

    builder.addCase(addStation.fulfilled, (state, action) => {
      state.stations = [...state.stations, action.payload]
      state.loading = false
    })

    builder.addCase(getAvailableStationWidgets.fulfilled, (state, action) => {
      state.availableStationDashboardWidgets = action.payload
    })

    builder.addCase(addStation.pending, (state) => {
      state.loading = true
    })

    builder.addCase(addStation.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(updateStation.fulfilled, (state, action) => {
      const updatedStation = action.payload
      const updatedStationIndex = state.stations.findIndex(({ id: stationId }) => updatedStation.id === stationId)
      state.stations[updatedStationIndex] = updatedStation
      state.loading = false
    })

    builder.addCase(updateStation.pending, (state) => {
      state.loading = true
    })

    builder.addCase(updateStation.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(uploadAvatar.fulfilled, (state, action) => {
      const { link: avatarLink, id } = action.payload
      if (avatarLink !== undefined) {
        const updatedStationIndex = state.stations.findIndex(({ id: stationId }) => id === stationId)
        state.stations[updatedStationIndex].link = avatarLink
      }
      state.loading = false
    })

    builder.addCase(uploadAvatar.pending, (state) => {
      state.loading = true
    })

    builder.addCase(uploadAvatar.rejected, (state) => {
      state.loading = false
    })
    //archive station by id
    builder.addCase(archiveStationById.fulfilled, (state, action) => {
      const stationElementId = +action.payload
      const archivedStationIndex = state.stations.findIndex((station) => {
        const element = station.appElements ? station?.appElements[0] : station.appElement
        return +stationElementId === +element?.id
      })
      if (archivedStationIndex > -1) {
        state.stations.splice(archivedStationIndex, 1)
      }
      state.loading = false
    })

    builder.addCase(archiveStationById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(archiveStationById.rejected, (state) => {
      state.loading = false
    })
    //remove station by id
    builder.addCase(removeStationById.fulfilled, (state, action) => {
      const stationId = +action.payload
      const removedStationIndex = state.stations.findIndex((station) => {
        return +station.id === stationId
      })
      if (removedStationIndex > -1) {
        const archivedById = state.stations[removedStationIndex]?.appElement?.archivedById
        const elementId = state.stations[removedStationIndex]?.appElement?.id
        if (+archivedById === +elementId) state.stations.splice(removedStationIndex, 1)
      }
      state.loading = false
    })

    builder.addCase(removeStationById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(removeStationById.rejected, (state) => {
      state.loading = false
    })

    // Dashboard
    builder.addCase(addWidgetToStationDashboard.fulfilled, (state, action) => {
      if (state.dashboard) {
        const dashboardWidgets = state.dashboard.widgets ? state.dashboard.widgets : []
        const arrOfY = dashboardWidgets.map((w) => w.dimensions.y)
        const yMax = arrOfY.length ? Math.max.apply(null, arrOfY) : 0
        let newWidgets = [...dashboardWidgets]
        if (!action.payload.widget) {
          const widgetsIds = newWidgets.map(({ id, tempId }) => id || tempId)
          action.payload.dimensions.y = action.payload.dimensions.y === -1 ? yMax + 4 : action.payload.dimensions.y
          action.payload.dimensions.x = action.payload.dimensions.x === -1 ? 0 : action.payload.dimensions.x
          action.payload.tempId = widgetsIds.length ? Math.max(...widgetsIds) + 1 : 1
          newWidgets = [...newWidgets, action.payload]
        } else {
          newWidgets = [...newWidgets, { ...action.payload.widget, tempId: `${newWidgets.length + 1}_id` }]
        }
        state.dashboard = { ...state.dashboard, widgets: newWidgets }
      } else {
        state.dashboard = action.payload
      }
    })

    builder.addCase(getAllDashboardForStation.fulfilled, (state, action) => {
      state.dashboards = action.payload
      state.currentDashboardId = action.payload[0]?.id
      state.dashboardsLoading = false
    })
    builder.addCase(getAllDashboardForStation.pending, (state) => {
      state.dashboardsLoading = true
    })
    builder.addCase(getAllDashboardForStation.rejected, (state) => {
      state.dashboards = null
      state.currentDashboardId = null
      state.dashboardsLoading = false
    })

    builder.addCase(getStationDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload
      state.currentDashboardId = action.payload.id
      state.dashboardLoading = false
    })
    builder.addCase(getStationDashboard.pending, (state, action) => {
      state.dashboardLoading = true
    })
    builder.addCase(getStationDashboard.rejected, (state, action) => {
      state.dashboardLoading = false
    })

    builder.addCase(saveStationDashboard.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveStationDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload
      state.loading = false
    })
    builder.addCase(saveStationDashboard.rejected, (state, action) => {
      if (action.payload?.body?.errors?.length > 0) {
        state.dashboard = action.payload.body.resultDb
        state.dashboardcurrentDashboardId = action.payload.body.resultDb?.id
      }
      state.loading = false
    })

    // Documentation
    builder.addCase(getStationDocSidebar.fulfilled, (state, action) => {
      state.documentationSidebar = action.payload
      state.documentationSidebarAllItems = action.payload
      const hiddenElements = action.payload ? action.payload.filter((i) => i.level !== 1).map((i) => i.id) : []
      state.docSidebarHiddenElements = hiddenElements
      state.docSidebarLoading = false
    })
    builder.addCase(getStationDocSidebar.pending, (state, action) => {
      state.docSidebarLoading = true
    })

    // Get Single Document
    builder.addCase(getStationDocOneItem.fulfilled, (state, action) => {
      // the old code
      // state.documentationOneItem = sidebarData.filter((i) => i.id === 1)[0]
      // state.documentationOneItem = action.payload
      state.documentationOneItem = action.payload
      state.documentationOneItemLoading = false
    })
    builder.addCase(getStationDocOneItem.pending, (state, action) => {
      state.documentationOneItem = null
      state.documentationOneItemLoading = true
    })

    builder.addCase(createNewDocumentation.fulfilled, (state, action) => {
      state.currentStationDocumentation = action.payload?.appElementData
      state.currentStation.wikiElementData = action.payload?.appElementData
    })
    builder.addCase(createDocumentationNewPage.fulfilled, (state, action) => {
      state.documentationOneItem = action.payload
    })
    builder.addCase(getCurrentDocumentationDetails.fulfilled, (state, action) => {
      state.documentationDetails = action.payload
    })
    builder.addCase(pinDocumentationPage.fulfilled, (state, action) => {
      state.documentationDetails = state.documentationDetails.filter(
        (element: any) => +element.id !== +action.payload.id,
      )
    })
    builder.addCase(getStationDocSearchResults.fulfilled, (state, action) => {
      state.documentationSearchResultsLoading = false
      state.documentationSearchResults = action.payload
    })
    builder.addCase(getStationDocSearchResults.pending, (state, action) => {
      state.documentationSearchResultsLoading = true
    })
    builder.addCase(getStationDocSearchResults.rejected, (state, action) => {
      state.documentationSearchResultsLoading = false
    })
    builder.addCase(getStationDocComments.fulfilled, (state, action) => {
      state.documentationItemComments = action.payload
    })
    builder.addCase(updateStationDocsOneComment.fulfilled, (state, action) => {
      //rewrite logic when backend ready
      state.documentationItemComments = state.documentationItemComments
        ? state.documentationItemComments.map((i) => {
            if (i.id === action.payload.id) {
              i = action.payload
            }

            return i
          })
        : null
    })
    builder.addCase(removeStationDocsOneComment.fulfilled, (state, action) => {
      state.documentationItemComments = state.documentationItemComments
        ? state.documentationItemComments.filter((i) => i.id !== action.payload)
        : null
    })
    builder.addCase(addStationDocsOneComment.fulfilled, (state, action) => {
      if (state.documentationItemComments) {
        state.documentationItemComments = state.showLatest
          ? [action.payload, ...state.documentationItemComments]
          : [...state.documentationItemComments, action.payload]
      } else {
        state.documentationItemComments = [action.payload]
      }
    })
    builder.addCase(addStationDocsTextSelectionInDoc.fulfilled, (state, action) => {
      if (state.documentationOneItem && action.payload.documentId === state.documentationOneItem.id.toString()) {
        state.documentationOneItem.selections = state.documentationOneItem.selections
          ? [...state.documentationOneItem.selections, action.payload]
          : [action.payload]
      }
    })
    builder.addCase(removeStationDocsTextSelectionInDoc.fulfilled, (state, action) => {
      if (state.documentationOneItem && state.documentationOneItem.selections) {
        state.documentationOneItem.selections = state.documentationOneItem.selections.filter(
          (sel) => sel.id !== action.payload,
        )
      }

      if (state.documentationItemComments) {
        state.documentationItemComments = state.documentationItemComments.map((com) => {
          if (com.selection && com.selection.id === action.payload) {
            com.selection = undefined
          }

          return com
        })
      }
    })
    builder.addCase(createStationDocsOneDocAttachment.fulfilled, (state, action) => {
      if (state.documentationItemAttachments) {
        state.documentationItemAttachments = [...action.payload, ...state.documentationItemAttachments]
      } else {
        state.documentationItemAttachments = action.payload
      }
      state.documentationItemAttachmentsLoading = false
    })
    builder.addCase(createStationDocsOneDocAttachment.pending, (state, action) => {
      state.documentationItemAttachmentsLoading = true
    })
    builder.addCase(createStationDocsOneDocAttachment.rejected, (state, action) => {
      state.documentationItemAttachmentsLoading = false
    })
    builder.addCase(getStationDocsOneDocAttachment.fulfilled, (state, action) => {
      state.documentationItemAttachments = action.payload
      state.documentationItemAttachmentsLoading = false
    })
    builder.addCase(getStationDocsOneDocAttachment.pending, (state, action) => {
      state.documentationItemAttachmentsLoading = true
    })
    builder.addCase(getStationDocsOneDocAttachment.rejected, (state, action) => {
      state.documentationItemAttachmentsLoading = false
    })
    builder.addCase(deleteStationDocsOneDocAttachment.fulfilled, (state, action) => {
      if (state.documentationItemAttachments) {
        const filteredAttachments = state.documentationItemAttachments.filter((a: any) => a.id !== action.payload)

        state.documentationItemAttachments = filteredAttachments.length ? filteredAttachments : null
      }
    })
    builder.addCase(createOrDeleteStationDocPublicLink.fulfilled, (state, action) => {
      state.documentationPublicLink = action.payload
    })
    builder.addCase(getStationDocPublicLink.fulfilled, (state, action) => {
      state.documentationPublicLink = action.payload
    })
    builder.addCase(getStationDocsOneDocInfo.fulfilled, (state, action) => {
      state.documentationItemInfo = null
    })
    builder.addCase(updateStationDocsOneDocInfo.fulfilled, (state, action) => {
      state.documentationItemInfo = action.payload
    })
    builder.addCase(getStationDocsAllLabels.fulfilled, (state, action) => {
      state.documentationAllLabels = null
    })
    builder.addCase(getCurrentStationDocumentation.fulfilled, (state, action) => {
      state.currentStationDocumentation = action.payload.wikiElementData
    })

    // updateDocumentationDocument
    builder.addCase(updateDocumentationDocument.pending, (state) => {
      state.updateDocumentLoading = true
    })
    builder.addCase(updateDocumentationDocument.fulfilled, (state) => {
      state.updateDocumentLoading = false
    })
    builder.addCase(updateDocumentationDocument.rejected, (state) => {
      state.updateDocumentLoading = false
    })
    // getArchivedDocuments
    builder.addCase(getStationArchivedDocuments.pending, (state) => {
      state.archivedDocumentsLoading = true
    })
    builder.addCase(getStationArchivedDocuments.fulfilled, (state, action) => {
      const parentIds = action.payload.map((page: any) => +page.id)
      state.allArchivedDocumentsIds = parentIds
      state.allArchivedDocuments = action.payload
      state.archivedDocuments = action.payload.filter((p: any) => !parentIds.includes(p.parentId))
      state.archivedDocumentsHiddenElements = action.payload
        .filter((p: any) => parentIds.includes(p.parentId))
        .map(({ id }) => id)
      state.archivedDocumentsLoading = false
    })
    builder.addCase(getStationArchivedDocuments.rejected, (state) => {
      state.archivedDocumentsLoading = false
    })
    builder.addCase(onCreateNewStationDashboard.fulfilled, (state, action) => {
      state.dashboards = [...state.dashboards, action.payload]
      state.currentDashboardId = action.payload?.id
      state.dashboardsLoading = false
    })

    builder.addCase(onCreateNewStationDashboard.pending, (state, action) => {
      state.dashboardsLoading = true
    })

    builder.addCase(onCreateNewStationDashboard.rejected, (state, action) => {
      state.dashboardsLoading = false
    })

    builder.addCase(onDeleteStationDashboard.fulfilled, (state, action) => {
      const newList = [...state.dashboards].filter((dash) => +dash.id !== +action.payload.elementId)
      state.dashboards = [...newList]
      state.currentDashboardId = newList[0]?.id
      state.dashboardsLoading = false
    })

    builder.addCase(onDeleteStationDashboard.pending, (state, action) => {
      state.dashboardsLoading = true
    })

    builder.addCase(onDeleteStationDashboard.rejected, (state, action) => {
      state.dashboardsLoading = false
    })
  },
  initialState,
  name: 'station',
  reducers: {
    clearCurrentDocumentation: (state) => {
      state.currentStationDocumentation = null
      state.documentationSidebarAllItems = null
      // state.documentationOneItem = null
      state.documentationDetails = []
      state.documentationSidebar = null
      state.docSidebarHiddenElements = []
      state.docSidebarLoading = false
    },
    clearCurrentStation: (state) => {
      state.currentStationId = undefined
      state.currentStation = {}
      state.currentStationLoading = true
    },
    clearCustomDashboard: (state, payload) => {
      state.customDashboard = null
    },
    clearCustomListsFiltersOptions: (state, { payload }) => {
      state.customListsFiltersOptions = {
        labels: [],
        owners: [],
        statuses: [],
      }
    },
    clearStationDashboardPage: (state) => {
      state.dashboard = null
      state.dashboards = null
      state.currentDashboardId = null
    },

    createCustomDashboard: (state, action) => {
      state.customDashboard = action.payload.das
      if (state.dashboard) {
        const dashboardWidgets = state.dashboard.widgets ? state.dashboard.widgets : []
        const newWidgets = [...dashboardWidgets, action.payload]
        state.dashboard = { ...state.dashboard, widgets: newWidgets }
      } else {
        const dashboard = action.payload.dashboard
        state.dashboard = { ...dashboard, widgets: [action.payload.customWidget] }
      }
    },

    currentOpenSidebarItems: (state, { payload }) => {
      state.openSidebarItems = payload
    },

    emptyDocumentationSearchResults: (state) => {
      state.documentationSearchResults = null
    },

    onChangeCurrentDashboardId: (state, action) => {
      state.currentDashboardId = +action.payload
    },

    onCreateNewCustomWidget: (state, action) => {
      state.dashboard = action.payload
    },

    onCreateNewCustomWidget: (state, action) => {
      state.dashboard = action.payload
    },

    openDocumentationComments: (state) => {
      state.documentationItemCommentsOpen = true
      state.documentationItemAttachmentsOpen = false
    },

    pinDocumentationDocument: (state, { payload }) => {
      const newItem = payload.currentPage
      state.documentationOneItem = { ...newItem, isPinned: !newItem.isPinned }
      // state.documentationSidebar = state.documentationSidebar
      // ? state.documentationSidebar?.map((i) => {
      // if (i.id === payload.cußrrentPage.id) {
      //   if (i.filters && i.filters.includes('pinned')) {
      //     i.filters = i.filters.filter((f) => f !== 'pinned')
      //   } else if (i.filters && !i.filters.includes('pinned')) {
      //     i.filters.push('pinned')
      //   } else if (!i.filters) {
      //     i.filters = ['pinned']
      //       newItem = i
      //     }
      //
      //     return i
      //   })
      // : null

      // state.documentationOneItem = newItem
    },

    removeDocumentationSidebarItems: (state) => {
      state.documentationSidebar = null
    },

    removeStationPublicDocLinkLocally: (state) => {
      state.documentationPublicLink = ''
    },

    removeWidgetFromStationDashboard: (state, { payload }: { type: string, payload: number }) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.filter((w) => w.id !== payload && w?.tempId !== payload)
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },

    resetRejectedStation: (state) => {
      state.isRejected = false
    },

    // Dashboard
    saveStationDashboardLayoutDragChanges: (
      state,
      {
        payload,
      }: {
        type: string,
        payload: {
          layout: Layout[],
          newItem: Layout,
        },
      },
    ) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.map((w, idx) => {
          return {
            ...w,
            dimensions: {
              ...w.dimensions,
              x: payload.layout[idx].x,
              y: payload.layout[idx].y,
            },
          }
        })

        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },

    saveStationDashboardLayoutResizeChanges: (state, { payload }: { type: string, payload: Layout }) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard.widgets.map((s) => {
          const itemKey = `${s?.id || s?.tempId}`
          if (itemKey === payload?.i) {
            return {
              ...s,
              dimensions: {
                ...s.dimensions,
                h: widgetHeight(s, payload),
                minH: minWidgetHeight(s, payload),
                minW: payload.minW,
                w: payload.w,
                x: payload.x,
                y: payload.y,
              },
            }
          }
          return s
        })
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
    SetCurrentStationArchiveStatus: (state, { payload }) => {
      state.isStationArchived = payload
    },

    toggleDocumentationAttachments: (state) => {
      state.documentationItemAttachmentsOpen = !state.documentationItemAttachmentsOpen
      state.documentationItemCommentsOpen = false
      state.documentationItemInfoOpen = false
      state.documentationItemLinksOpen = false
    },
    toggleDocumentationComments: (state) => {
      state.documentationItemCommentsOpen = !state.documentationItemCommentsOpen
      state.documentationItemAttachmentsOpen = false
      state.documentationItemInfoOpen = false
      state.documentationItemLinksOpen = false
    },

    toggleDocumentationInfo: (state) => {
      state.documentationItemInfoOpen = !state.documentationItemInfoOpen
      state.documentationItemAttachmentsOpen = false
      state.documentationItemCommentsOpen = false
      state.documentationItemLinksOpen = false
    },

    toggleDocumentationLinks: (state) => {
      state.documentationItemLinksOpen = !state.documentationItemLinksOpen
      state.documentationItemAttachmentsOpen = false
      state.documentationItemCommentsOpen = false
      state.documentationItemInfoOpen = false
    },

    toggleShowLatest: (state) => {
      state.showLatest = !state.showLatest
    },

    updateCustomListsFiltersOptions: (state, { payload }) => {
      const tempOptions = { ...state.customListsFiltersOptions }
      state.customListsFiltersOptions = {
        labels: [...tempOptions.labels, ...payload.labels],
        owners: [...tempOptions.owners, ...payload.owners],
        statuses: [...tempOptions.statuses, ...payload.statuses],
      }
    },
    updateDocArchivedHiddenElements: (state, { payload }) => {
      state.archivedDocumentsHiddenElements = payload
    },

    updateDocSidebarHiddenElements: (state, { payload }) => {
      state.docSidebarHiddenElements = payload
    },

    // Documentation
    updateDocumentationArchivedDocActive: (state, { payload }) => {
      state.isArchivedDocActive = payload
    },

    updateDocumentationArchivedElement: (state, { payload }) => {
      state.archivedDocuments = payload
    },
    // updateDocumentationDocumentContent: (state, { payload }) => {
    //   if (payload.parentId === null) {
    //     state.documentationSidebar = state.documentationSidebar
    //       ? state.documentationSidebar.map((i) => {
    //           if (+i.id === +payload.id) {
    //             i.content = payload.content
    //             i.title = payload.title
    //             i.filters = i.filters
    //               ? [...i.filters, 'recently_viewed', 'recently_updated']
    //               : ['recently_viewed', 'recently_updated']
    //           }
    //           return i
    //         })
    //       : [
    //           {
    //             children: null,
    //             content: payload.content,
    //             id: payload.id,
    //             level: 1,
    //             order: 0,
    //             parentId: null,
    //             title: payload.title,
    //             visibility: true,
    //           },
    //         ]
    //     state.documentationOneItem = null
    //   } else {
    //     const parent = state.documentationSidebar?.filter((i) => i.id === parseInt(payload.parentId))[0]
    //     if (!parent) {
    //       const newItem = {
    //         children: null,
    //         content: payload.content,
    //         id: payload.id,
    //         level: 1,
    //         order: state.documentationSidebar ? state.documentationSidebar.length : 0,
    //         parentId: null,
    //         title: payload.title,
    //         visibility: true,
    //       }
    //       const newSidebar = state.documentationSidebar ? [...state.documentationSidebar] : []
    //       newSidebar.push(newItem)
    //       state.documentationSidebar = newSidebar
    //       state.documentationOneItem = newItem
    //     } else {
    //       const newItem = {
    //         children: null,
    //         content: payload.content,
    //         id: payload.id,
    //         level: parent.level + 1,
    //         order: parent.order,
    //         parentId: parent.id,
    //         title: payload.title,
    //         visibility: true,
    //       }
    //       const newSidebar = state.documentationSidebar ? [...state.documentationSidebar] : []
    //       newSidebar.splice(parent.order + 1, 0, newItem)
    //       state.documentationSidebar = newSidebar.map((item, idx) => {
    //         item.order = idx
    //         if (item.id === parent.id) {
    //           item.children = item.children ? [...item.children, payload.id] : [payload.id]
    //         }
    //         return item
    //       })
    //       state.documentationOneItem = state.documentationSidebar.filter((i) => i.id === payload.id)[0]
    //     }
    //   }
    // },
    updateDocumentationDocumentTitle: (state, { payload }) => {
      state.documentationSidebar = state.documentationSidebar
        ? state.documentationSidebar.map((i) => {
            if (i.id === payload.id) {
              i.title = payload.title
            }

            return i
          })
        : null

      const newOneItem = state.documentationOneItem ? state.documentationOneItem : null

      if (newOneItem) {
        newOneItem.title = payload.title
      }

      state.documentationOneItem = newOneItem
    },
    updateDocumentationSidebar: (state, { payload }) => {
      state.documentationSidebar = payload
    },
    updateDocumentationVisitedDoc: (state, { payload }) => {
      state.documentationSidebar = state.documentationSidebar
        ? state.documentationSidebar?.map((i) => {
            if (i.id === payload.id) {
              i.filters = i.filters ? Array.from(new Set([...i.filters, 'recently_viewed'])) : ['recently_viewed']
            }

            return i
          })
        : null
    },
    updateStationWidgetImage: (state, action) => {
      const dashboardWidgets = [...state.dashboard.widgets]
      const dashboardWidgetIndex = dashboardWidgets.findIndex((w) => +w.baseType === +action.payload.baseType)
      if (dashboardWidgetIndex > -1) {
        //add widget image data
        dashboardWidgets[dashboardWidgetIndex] = action.payload
        state.dashboard = { ...state.dashboard, widgets: dashboardWidgets }
      }
    },
    updateStationWidgetTitle: (state, { payload }: { payload: { widgetId: number | string, title: string } }) => {
      const newWidgets = state.dashboard?.widgets.map((w) => {
        if (w.id === payload.widgetId || w.tempId === payload.widgetId) {
          const data: any = {
            title: payload.title,
          }
          const updatedWidget = { ...w, ...data }
          return updatedWidget
        }
        return w
      })

      state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
    },
    updateTextWidgetValue: (
      state,
      { payload }: { payload: { description: string, widgetId: number, imgBase64?: string } },
    ) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.map((w) => {
          if (w.id === payload.widgetId || w.tempId === payload.widgetId) {
            const data: any = {
              description: payload.description,
              image: payload.imgBase64 !== undefined && payload.imgBase64 !== null ? payload.imgBase64 : w.image,
            }

            const updatedTextWidget = { ...w, ...data }

            return updatedTextWidget
          }
          return w
        })
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
  },
})

export const {
  resetRejectedStation,
  clearCurrentStation,
  removeWidgetFromStationDashboard,
  saveStationDashboardLayoutDragChanges,
  saveStationDashboardLayoutResizeChanges,
  updateTextWidgetValue,
  clearStationDashboardPage,
  updateStationWidgetTitle,
  updateDocumentationSidebar,
  updateDocumentationArchivedElement,
  updateDocSidebarHiddenElements,
  updateDocArchivedHiddenElements,
  // updateDocumentationDocumentContent,
  updateDocumentationVisitedDoc,
  pinDocumentationDocument,
  removeDocumentationSidebarItems,
  toggleDocumentationComments,
  toggleDocumentationLinks,
  updateDocumentationDocumentTitle,
  emptyDocumentationSearchResults,
  toggleDocumentationAttachments,
  openDocumentationComments,
  removeStationPublicDocLinkLocally,
  toggleDocumentationInfo,
  clearCurrentDocumentation,
  currentOpenSidebarItems,
  toggleShowLatest,
  SetCurrentStationArchiveStatus,
  createCustomDashboard,
  clearCustomDashboard,
  onCreateNewCustomWidget,
  updateStationWidgetImage,
  updateCustomListsFiltersOptions,
  clearCustomListsFiltersOptions,
  onChangeCurrentDashboardId,
} = stationSlice.actions

export default stationSlice.reducer
