import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import classnames from 'classnames'

import { ErrorMessageType, ErrorMessageRefType } from 'utils/types/inputs'

const InputErrorMessage = forwardRef<ErrorMessageRefType, ErrorMessageType>(
  ({ error, touched, isInlineEditable, ...rest }, ref) => {
    const [isErrorShaking, setIsErrorShaking] = useState<boolean>(false)

    const shakeError = useCallback(() => setIsErrorShaking(true), [])

    useImperativeHandle(
      ref,
      () => ({
        shakeError,
      }),
      [ref],
    )

    if (!error || !touched) return null

    return (
      <p
        className={classnames(
          'text-sm text-danger',
          { 'animate-wiggle': isErrorShaking },
          isInlineEditable ? '-mb-7 ml-2' : 'mt-1',
        )}
        onAnimationEnd={() => {
          setIsErrorShaking(false)
        }}
        {...rest}>
        {error}
      </p>
    )
  },
)

InputErrorMessage.displayName = 'InputErrorMessage'

export default InputErrorMessage
