import React, { FC, useCallback, useRef } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { useClickAway, useKey, useToggle } from 'react-use'
import throttle from 'lodash/throttle'
import { useFormik } from 'formik'

import { AddIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { Input } from 'components/inputs'
import { addRecordValidationSchema } from 'components/forms/validationSchemas'
import { createRecord } from 'features/record'
import { useCurrentList, useCurrentListId } from 'features/list'
import { InputRefType } from 'utils/types/inputs'
import { useAppDispatch } from 'store'

const AddRecordButton: FC<{ calculateNewRecordPage: (recordId: number) => void }> = ({ calculateNewRecordPage }) => {
  const [isInputVisible, toggleInput] = useToggle(false)

  const { t } = useTranslation('records')
  const currentList = useCurrentList()
  const listId = currentList?.id
  const appDispatch = useAppDispatch()
  const inputRef = useRef<InputRefType>(null)

  const addRecord = useCallback(
    (name: string) => {
      if (listId !== undefined)
        appDispatch(createRecord({ listId, name, parentId: currentList?.appElement?.id }))
          .then(unwrapResult)
          .then((newRecord) => {
            calculateNewRecordPage(newRecord)
          })
    },
    [listId, calculateNewRecordPage],
  )

  const { errors, values, touched, setTouched, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: { name: '' },
    onSubmit: (values, ...rest) => {
      inputRef?.current?.focus()
      addRecord(values.name)
      resetForm()
    },
    validationSchema: addRecordValidationSchema,
  })
  const onBlur = useCallback(
    throttle(
      (shouldKeepInput) => {
        handleSubmit()
        if (shouldKeepInput === true) {
          inputRef?.current?.focus()
        } else toggleInput(false)
      },
      200,
      { trailing: false },
    ),
    [],
  )

  useKey('Enter', ({ shiftKey }) => {
    onBlur(shiftKey)
  })
  useClickAway(inputRef, () => {
    onBlur(false)
  })

  return isInputVisible ? (
    <Input
      autoFocus
      error={errors.name}
      name="name"
      ref={inputRef}
      touched={touched.name}
      value={values.name}
      onChange={handleChange}
      onFocus={() => setTouched({ name: true }, false)}
    />
  ) : (
    <Button icon={AddIcon} small variant={ButtonVariant.Ghost} onClick={toggleInput}>
      {t('addRow')}
    </Button>
  )
}

export default AddRecordButton
