import React, { FC, useEffect } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { clearElementTree } from 'features/element/elementSlice'
import { useAppDispatch } from '../../store'
import { RecordType } from '../../utils/types'
import { getElementTree, useCurrentElementTree, useCurrentElementTreeLoading } from '../../features/element'
import LinkElements from '../LinkElements'
import Button, { ButtonVariant } from '../Buttons'
import Loader from '../Loader'

type AddSubtaskModalPropsType = {
  onCancel: () => void,
  isModalOpen: boolean,
  listElementId: string | number,
  currentRecord: RecordType,
  currentListAppElementId: number,
  selectedElements: any[],
  setSelectedElements: (value: RecordType) => void,
  onSubmit: () => void,
}

const AddSubtaskModal: FC<AddSubtaskModalPropsType> = ({
  onCancel,
  isModalOpen,
  currentRecord,
  currentListAppElementId,
  selectedElements,
  setSelectedElements,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const recordsList = useCurrentElementTree()
  const recordsListLoading = useCurrentElementTreeLoading()

  useEffect(() => {
    dispatch(getElementTree({ documentFlag: false, elementId: +currentListAppElementId }))
    return () => {
      dispatch(clearElementTree())
    }
  }, [currentListAppElementId, isModalOpen])

  const records = recordsList?.filter((element) => element?.element?.allChildrenCount === '0')
  return (
    <Modal
      className="fixed top-0 bottom-0 flex w-full bg-white border-white rounded shadow left-2/4 transform -translate-x-2/4 focus:outline-none md:max-w-lg md:top-20 md:bottom-20"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onCancel}>
      <div className="flex flex-col flex-1 w-full min-h-0 px-5 py-5 justify between">
        <div className="flex flex-col flex-1 min-h-0">
          <h2 className="pb-3 mb-3 font-bold border-b border-gray-200">{t('records:addSubtask')}</h2>
          <LinkElements
            checkBasedType={false}
            currentList={records}
            currentListLoading={recordsListLoading}
            currentRecord={currentRecord}
            elementBaseType={3}
            elementId={currentRecord?.appElements[0]?.id}
            level={3}
            lowestLevel={3}
            options={{
              addManyLists: false,
              canAddRecord: true,
              isSingleSelect: true,
              shouldShowPreview: false,
            }}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        </div>
        <div className="flex items-center justify-end mt-5">
          <Button className="me-1" small variant={ButtonVariant.Outline} onClick={onCancel}>
            {t('common:labels.cancel')}
          </Button>
          <Button data-testid="save-button" disabled={recordsList.length === 0} small type="submit" onClick={onSubmit}>
            {isSubmitting ? <Loader /> : t('common:labels.save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddSubtaskModal
