import DocumentationPagesHeader from 'components/Documentation/DocumentationPagesComponents/DocumentationPagesHeader'
import DocumetationSidebar from 'components/Documentation/DocumentationSidebar/DocumetationSidebar'
import React, { FC, memo } from 'react'
import DocumentationArchivePage from '../../../components/Documentation/DocumentationsArchive/DocumentationArchivePage'

const StationDocumentationArchivePage: FC<{ isPublic?: boolean }> = ({ isPublic }) => {
  return (
    <div className="flex h-full min-h-0">
      <DocumetationSidebar isPublic={isPublic} />
      <div className="flex flex-col w-full bg-white">
        <DocumentationPagesHeader isPublic={isPublic} type="archived" />
        <DocumentationArchivePage isPublic={isPublic} />
      </div>
    </div>
  )
}

export default memo(StationDocumentationArchivePage)
