import React, { FC, memo, useRef } from 'react'
import { CrossSignIcon } from 'components/Icons'
import DashboardStateWidgetType, { ActivityWidgetWithSortType } from 'utils/types/states/DashboardStateWidgetType'
import { useTranslation } from 'react-i18next'
import { DashboardWidgetBaseTypes } from '../../../utils/constant/enums/DashboardWidgetBaseType'
import { useRoles } from '../../../hooks'
import CopyWidget from '../CopyWidget/CopyWidget'
import OverviewWidget from './OverviewWidget/OverviewWidget'
import DiagramOverviewWidget from './DiagramOverviewWidget/DiagramOverviewWidget'
import HealthWidget from './HealthWidget/HealthWidget'
import TeamMonitorWidget from './TeamMonitorWidget/TeamMonitorWidget'
import DetailedRecordsWidget from './DetailedRecordsWidget/DetailedRecordsWidget'
import TextWidget from './TextWidget/TextWidget'
import ActivityWidgetWithSortVersion from './ActivityWidget/ActivityWidgetWithSortVersion'
import StationListWidget from './StationListWidget/StationListWidget'
import WidgetTitle from './WidgetTitle'
import RecordsHealthWidget from './RecordsHealthWidget/RecordsHealthWidget'
import ListOverviewWidget from './ListOverviewWidget/ListOverviewWidget'
import ListsStatsWidget from './ListsStatsWidget/ListsStatsWidget'
import MyTasks from './MyTasks/MyTasks'
import AvatarWidget from './AvatarWidget/AvatarWidget'
import StationKanbanWidget from './StationKanbanWidget/StationKanbanWidget'
import CustomWidget from './CustomWidgets/CustomWidget/CustomWidget'
import BigNumbersWidget from './CustomWidgets/BigNumbersWidget/BigNumbersWidget'
import TimeLineWidget from './CustomWidgets/TimeLineWidget/TimeLineWidget'
import GaugeWidget from './GaugeWidget/GaugeWidget'

const DashboardBaseWidget: FC<{
  widget: DashboardStateWidgetType,
  isEdit: boolean,
  elementId: string | number,
  removeWidget: (id: number) => void,
  updateTextWidget: (widgetId: number, description: string, imgBase64?: string) => void,
  updateWidgetTitle: (widgetId: number | string, title: string) => void,
  setIsEditorOpen: () => void,
  onUpdateWidgetImage: (value) => void,
  isPrinting: boolean,
}> = ({
  widget,
  isEdit,
  removeWidget,
  updateTextWidget,
  updateWidgetTitle,
  setIsEditorOpen,
  elementId,
  onUpdateWidgetImage,
  isPrinting,
}) => {
  const { t, i18n } = useTranslation('dashboard')
  const widgetData: any = widget?.data
  const { isAdmin, isEditor } = useRoles()
  const htmlElementRef = useRef(null)
  const hasAccess = widget?.isVisible
  // let noData = Boolean(!widgetData)
  if (!hasAccess) {
    return (
      <div
        style={{
          backgroundColor: '#fafafa',
          direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          height: '100%',
          width: '100%',
        }}>
        <div className="absolute z-10 flex items-center justify-center w-full h-full text-lg">
          {t('noAccessToWidget')}
        </div>
        <div style={{ filter: 'blur(10px)', height: '100%', width: '100%' }}>
          {isEdit ? (
            <div
              className={`absolute z-50 w-5 h-5 rounded-full cursor-pointer bg-danger -top-2 ${
                i18n.language === 'ar' ? '-left-2' : '-right-2'
              }`}
              onClick={() => {
                const id = widget.id || widget.tempId
                removeWidget(id)
              }}>
              <CrossSignIcon color="white" />
            </div>
          ) : (
            ''
          )}
          <div className={`py-4 bg-white h-full rounded pr-6 pl-6`}>
            <h3 className="mb-2 font-bold text-gray-800 truncate text-md" title={'No Access'}>
              {t('noAccess')}
            </h3>
            <h2 className="mt-4 text-center opacity-50">{t('notHaveData')}</h2>
          </div>
        </div>
      </div>
    )
  }

  // if (
  //   data.baseType === DashboardWidgetBaseTypes.StationList ||
  //   data.baseType === DashboardWidgetBaseTypes.StationKanban ||
  //   data.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview ||
  //   data.baseType === DashboardWidgetBaseTypes.StationListsOverview ||
  //   data.baseType === DashboardWidgetBaseTypes.RecordsHealth ||
  //   data.baseType === DashboardWidgetBaseTypes.ListStats ||
  //   data.baseType === DashboardWidgetBaseTypes.ListOverview
  // ) {
  //   noData = !widgetData || !widgetData.values || !widgetData.values.length
  // } else if (data.baseType === DashboardWidgetBaseTypes.Activity) {
  //   const activityWidgetData: ActivityWidgetWithSortType = {
  //     ...widgetData,
  //     activities: widgetData.activities?.filter((item: any) => item.relatedObjectType === 3),
  //   }
  //   const emptyComments = !activityWidgetData.comments || !activityWidgetData.comments.length
  //   const emptyActivities = !activityWidgetData.activities || !activityWidgetData.activities.length
  //
  //   if (!activityWidgetData || (emptyComments && emptyActivities)) {
  //     noData = true
  //   } else {
  //     noData = false
  //   }
  // } else if (data.baseType === DashboardWidgetBaseTypes.MyTasks) {
  //   noData = !widgetData || (widgetData?.dueSoon?.length === 0 && widgetData?.overdue?.length === 0)
  // }
  //
  // if (noData && data.baseType !== DashboardWidgetBaseTypes.Text) {
  //   return (
  //     <div
  //       style={{
  //         // backgroundColor: '#fafafa',
  //         direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
  //         height: '100%',
  //         width: '100%',
  //       }}>
  //       {isEdit ? (
  //         <div
  //           className={`absolute z-50 w-5 h-5 rounded-full cursor-pointer bg-danger -top-2 ${
  //             i18n.language === 'ar' ? '-left-2' : '-right-2'
  //           }`}
  //           onClick={() => removeWidget(data.id)}>
  //           <CrossSignIcon color="white" />
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //       <div className={`py-4 bg-white h-full rounded pr-6 pl-6`}>
  //         <WidgetTitle
  //           baseType={data.baseType}
  //           elementId={elementId}
  //           isEditMode={isEdit}
  //           text={data.title || ''}
  //           updateWidgetTitle={updateWidgetTitle}
  //           widget={data}
  //           widgetId={data.id}
  //           onUpdateWidgetImage={onUpdateWidgetImage}
  //         />
  //         <h2 className="mt-4 text-center opacity-50">{t('notHaveData')}</h2>
  //       </div>
  //     </div>
  //   )
  // }
  // if (
  //   widget.baseType === DashboardWidgetBaseTypes.StationList ||
  //   widget.baseType === DashboardWidgetBaseTypes.StationKanban ||
  //   widget.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview ||
  //   widget.baseType === DashboardWidgetBaseTypes.StationListsOverview ||
  //   widget.baseType === DashboardWidgetBaseTypes.RecordsHealth ||
  //   widget.baseType === DashboardWidgetBaseTypes.ListStats ||
  //   widget.baseType === DashboardWidgetBaseTypes.ListOverview
  // ) {
  //   noData = !widgetData || !widgetData.values || !widgetData.values.length
  // } else if (widget.baseType === DashboardWidgetBaseTypes.Activity) {
  //   const activityWidgetData: ActivityWidgetWithSortType = {
  //     ...widgetData,
  //     activities: widgetData?.activities?.filter((item: any) => item?.relatedObjectType === 3),
  //   }
  //   const emptyComments = !activityWidgetData.comments || !activityWidgetData.comments.length
  //   const emptyActivities = !activityWidgetData.activities || !activityWidgetData.activities.length
  //
  //   if (!activityWidgetData || (emptyComments && emptyActivities)) {
  //     noData = true
  //   } else {
  //     noData = false
  //   }
  // } else if (widget.baseType === DashboardWidgetBaseTypes.MyTasks) {
  //   noData = !widgetData || (widgetData?.dueSoon?.length === 0 && widgetData?.overdue?.length === 0)
  // }
  //
  // if (noData && widget.baseType !== DashboardWidgetBaseTypes.Text) {
  //   return (
  //     <div
  //       style={{
  //         // backgroundColor: '#fafafa',
  //         direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
  //         height: '100%',
  //         width: '100%',
  //       }}>
  //       {isEdit ? (
  //         <div
  //           className={`absolute z-50 w-5 h-5 rounded-full cursor-pointer bg-danger -top-2 ${
  //             i18n.language === 'ar' ? '-left-2' : '-right-2'
  //           }`}
  //           onClick={() => {
  //             const id = widget.id || widget.tempId
  //             removeWidget(id)
  //           }}>
  //           <CrossSignIcon color="white" />
  //         </div>
  //       ) : (
  //         ''
  //       )}
  //       <div className={`py-4 bg-white h-full rounded pr-6 pl-6`}>
  //         <WidgetTitle
  //           baseType={widget.baseType}
  //           elementId={elementId}
  //           isEditMode={isEdit}
  //           text={widget.title || ''}
  //           updateWidgetTitle={updateWidgetTitle}
  //           widget={widget}
  //           widgetId={widget.id || widget.tempId}
  //           onUpdateWidgetImage={onUpdateWidgetImage}
  //         />
  //         <h2 className="mt-4 text-center opacity-50">{t('notHaveData')}</h2>
  //       </div>
  //     </div>
  //   )
  // }

  const backgroundImage = {
    backgroundImage: `url(${widget?.appElementAsset?.publicUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  const isCustomListOfRecords = widget.baseType === DashboardWidgetBaseTypes.CustomListOfRecords
  const widgetGeneralProp = {
    isEdit,
    isPrinting,
    onUpdateWidgetImage,
    widgetInfo: widget,
  }

  return (
    <div
      style={{
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
        height: '100%',
        width: '100%',
      }}>
      {isEdit ? (
        <div
          className={`absolute z-50 w-5 h-5 rounded-full cursor-pointer bg-danger -top-2 ${
            i18n.language === 'ar' ? '-left-2' : '-right-2'
          }`}
          onClick={() => {
            const id = widget.id || widget.tempId
            removeWidget(id)
          }}>
          <CrossSignIcon color="white" />
        </div>
      ) : (
        ''
      )}
      <div
        className="custom-chart flex flex-col z-10 bg-white h-full rounded py-2 px-3 group"
        ref={htmlElementRef}
        style={widget?.appElementAsset?.publicUrl && widget?.background ? backgroundImage : {}}>
        <div className="flex justify-end items-center mb-2">
          {widget.baseType !== DashboardWidgetBaseTypes.Description &&
            widget.baseType !== DashboardWidgetBaseTypes.Avatar && (
              <WidgetTitle
                baseType={widget.baseType}
                elementId={elementId}
                isEditMode={isEdit}
                text={widget.title || ''}
                updateWidgetTitle={updateWidgetTitle}
                widget={widget}
                widgetId={widget.id || widget.tempId}
                onUpdateWidgetImage={onUpdateWidgetImage}
              />
            )}
          <div className="hidden group-hover:block">
            <CopyWidget htmlElementRef={htmlElementRef} />
          </div>
        </div>
        {(widget.baseType === DashboardWidgetBaseTypes.StationOverview ||
          widget.baseType === DashboardWidgetBaseTypes.MyTasksOverview) && <OverviewWidget {...widgetGeneralProp} />}
        {(widget.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview ||
          widget.baseType === DashboardWidgetBaseTypes.StationListsOverview) && (
          <DiagramOverviewWidget {...widgetGeneralProp} />
        )}
        {widget.baseType === DashboardWidgetBaseTypes.Activity && (
          <ActivityWidgetWithSortVersion
            elementId={elementId}
            isAdmin={isAdmin}
            isEditor={isEditor}
            {...widgetGeneralProp}
          />
        )}
        {widget.baseType === DashboardWidgetBaseTypes.OverallStationHealth && <HealthWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.TeamMonitor && <TeamMonitorWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.MyDetailedRecords && (
          <DetailedRecordsWidget {...widgetGeneralProp} />
        )}
        {(widget.baseType === DashboardWidgetBaseTypes.Text ||
          widget.baseType === DashboardWidgetBaseTypes.Description) && (
          <TextWidget
            elementId={elementId}
            setIsEditorOpen={setIsEditorOpen}
            updateTextWidget={updateTextWidget}
            {...widgetGeneralProp}
          />
        )}
        {widget.baseType === DashboardWidgetBaseTypes.StationList && <StationListWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.StationKanban && <StationKanbanWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.RecordsHealth && <RecordsHealthWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.ListOverview && <ListOverviewWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.ListStats && <ListsStatsWidget {...widgetGeneralProp} />}
        {/*{widget.baseType === DashboardWidgetBaseTypes.ListStats && <CircleGauge {...widgetGeneralProp} />}*/}
        {(widget.baseType === DashboardWidgetBaseTypes.MyTasks || isCustomListOfRecords) && (
          <MyTasks
            {...widgetGeneralProp}
            isAdmin={isAdmin}
            isCustomListOfRecords={isCustomListOfRecords}
            isEditor={isEditor}
          />
        )}
        {widget.baseType === DashboardWidgetBaseTypes.Avatar && <AvatarWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.CustomTimeLine && <TimeLineWidget {...widgetGeneralProp} />}
        {(widget.baseType === DashboardWidgetBaseTypes.CustomPieChart ||
          widget.baseType === DashboardWidgetBaseTypes.CustomBarChart) && <CustomWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.BigNumbers && <BigNumbersWidget {...widgetGeneralProp} />}
        {widget.baseType === DashboardWidgetBaseTypes.GaugeWidget && <GaugeWidget {...widgetGeneralProp} />}
      </div>
    </div>
  )
}

export default memo(DashboardBaseWidget)
