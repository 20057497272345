import {
  AddIcon,
  ChevronDownIcon,
  CommentsIcon,
  FolderOpenIcon,
  InfoIcon,
  LinkIcon,
  PencilOutlineIcon,
  RecordLinkIcon,
  SearchIcon,
  StarIcon,
} from 'components/Icons'
import { useFormik } from 'formik'
import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { StylesConfig } from 'react-select'
import Button, { ButtonVariant } from 'components/Buttons'
import { Link, useHistory, useParams } from 'react-router-dom'
import ContextMenu from 'components/ContextMenu'
import {
  getStationDocSearchResults,
  createOrDeleteStationDocPublicLink,
  getStationDocPublicLink,
  useStationDocumentationOneItem,
  useStationDocumentationOneItemLoading,
  useStationDocumentationPublicLink,
  useStationDocumentationSidebar,
  onDeleteDocumentPage,
  getStationDocSidebar,
  useCurrentStationDocumentation,
  pinDocumentationPage,
  createDocumentationNewPage,
  useStationDocumentationSidebarHidden,
  archiveStationById,
  useCurrentStation,
  useCurrentStationArchiveStatus,
} from 'features/station'
import ListToolbar from 'components/ListToolbar'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import {
  pinDocumentationDocument,
  toggleDocumentationAttachments,
  removeStationPublicDocLinkLocally,
  toggleDocumentationComments,
  toggleDocumentationInfo,
  clearDocumentationNewCreatedPageId,
  updateDocSidebarHiddenElements,
  toggleDocumentationLinks,
} from 'features/station/stationSlice'
import useViewerPermission from 'hooks/useViewerPermission'
import * as yup from 'yup'
import { usePublicDocsOneItem, usePublicDocsOneItemLoading, usePublicDocsSidebar } from 'features/publicDocs/selectors'
import classnames from 'classnames'
import { findAllParentsAndSiblings } from '../DocumentationSidebar/functions/findRelatives'
import { Tooltip } from '../../../utils/helpers/generalHelpers'
import BaseModal from '../../Dashboard/BaseModal/BaseModal'
import DocumentHeaderLoader from '../DocumetationsDocument/DocumentHeaderLoader'
import { useAppDispatch } from '../../../store'
import ConfirmationModal from '../../ConfirmationModal'
import ArchivePanel from '../../ArchivePanel/ArchivePanel'

const selectStyles: StylesConfig = {
  control: () => ({
    border: '1px solid #DDE5ED !important',
    height: '36px !important',
    maxHeight: '36px !important',
    minHeight: '36px !important',
    width: 180,
  }),
  menu: () => ({
    left: 0,
    position: 'absolute',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, fontSize: 16, opacity, transition }
  },
}

export const searchInputValidation = yup.object().shape({
  docSearch: yup.string().min(2, 'Search must be at least 2 characthers').required('This field is required'),
})

// This is universal header for documentation. If in the future you will have new pages add new string in type prop
const DocumentationPagesHeader: FC<{ type?: 'home' | 'view' | 'search' | 'edit' | 'archived', isPublic?: boolean }> = ({
  type = 'home',
  isPublic,
}) => {
  // Since I don't know exact options these will be without translation until we determine which options we need
  const selectSortOptions = [
    { label: 'Newest', value: 'newest' },
    { label: 'Oldest', value: 'oldest' },
  ]

  const { workspaceId, stationId, docId, id, publicId } = useParams<{
    stationId: string,
    workspaceId: string,
    docId: string,
    id: string,
    publicId: string,
  }>()
  const allowUserAccess = useViewerPermission()
  const formik = useFormik({
    initialValues: {
      docSearch: '',
      docSort: selectSortOptions[0],
    },
    onSubmit: ({ docSearch, docSort }) => {
      dispatch(getStationDocSearchResults({ searchSort: docSort.value, searchStr: docSearch }))
    },
    validationSchema: searchInputValidation,
  })
  const { t, i18n } = useTranslation()
  const currentStation = useCurrentStation()
  const history = useHistory()
  const cards = useStationDocumentationSidebar()
  const publicCards = usePublicDocsSidebar()
  const [parents, setParents] = useState<null | any>(null)
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false)
  const page = useStationDocumentationOneItem()
  const pageLoading = useStationDocumentationOneItemLoading()
  const publicPage = usePublicDocsOneItem()
  const publicLoading = usePublicDocsOneItemLoading()
  const hiddenElements = useStationDocumentationSidebarHidden()
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const [focusedSearch, setFocused] = React.useState(false)
  const isArchivedActive = useCurrentStationArchiveStatus()
  const isRtl = i18n.language === 'ar'
  const isArchived = page?.isArchived !== undefined ? page?.isArchived : false
  const docPublicLink = useStationDocumentationPublicLink()
  const linkToDoc = isPublic
    ? `/public-docs/${publicId}`
    : `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`

  useEffect(() => {
    if (cards && !isPublic && page) {
      // const allParentsIds = findAllParentsAndSiblings(cards, parseInt(id), [], true)
      // const allParentsItems = cards.filter((i) => allParentsIds.includes(i.id))
      // setParents(allParentsItems.length ? allParentsItems.sort((a, b) => a.order - b.order) : null)
      setParents(page?.detailedPath)
    }

    if (publicCards && isPublic) {
      const allParentsIds = findAllParentsAndSiblings(publicCards, parseInt(id), [], true)
      const allParentsItems = publicCards.filter((i) => allParentsIds.includes(i.id))
      setParents(allParentsItems.length ? allParentsItems.sort((a, b) => a.order - b.order) : null)
    }
  }, [id, cards, publicCards])

  useEffect(() => {
    if (page) setParents(page.detailedPath)
    // const pathIds = page?.detailedPath.map((item) => item.id)
    // const newHiddenList = hiddenElements.filter((i) => !pathIds?.includes(i))
    // dispatch(updateDocSidebarHiddenElements(newHiddenList))
  }, [page])

  useEffect(() => {
    if (!isPublic) {
      dispatch(getStationDocPublicLink(stationId))
    }

    return () => {
      if (!isPublic) {
        dispatch(removeStationPublicDocLinkLocally())
      }
    }
  }, [])

  const createNewPage = () => {
    const createProps = {
      parentId: +docId,
      title: t('documentation:defaultTitle'),
    }
    appDispatch(createDocumentationNewPage(createProps)).then((data) => {
      history.push(`${linkToDoc}/edit/${+data.payload.id}`)
    })
  }

  if (type === 'view') {
    if ((!isPublic && pageLoading) || (isPublic && publicLoading)) {
      return <DocumentHeaderLoader />
    }

    const currentPage = isPublic ? publicPage : page
    // const isPinned = currentPage?.filters && currentPage?.filters.includes('pinned')
    const isPinned = currentPage?.isPinned

    const onOpenDelete = () => {
      setIsDeleteOpen(true)
    }
    const onOpenArchive = () => {
      setIsArchiveOpen(true)
    }

    const onConfirmDelete = () => {
      dispatch(onDeleteDocumentPage(+currentPage?.id))
      setTimeout(() => dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId })), 1000)
      setIsDeleteOpen(false)
      history.push(`${linkToDoc}/home`)
    }

    const onArchive = () => {
      appDispatch(
        archiveStationById({
          elementId: +currentPage?.id,
          recursive: 1,
          status: Number(!currentPage?.isArchived),
        }),
      ).then(() => {
        history.push(`${linkToDoc}/home`)
        dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId }))
        setIsArchiveOpen(false)
      })
    }
    const isArchivedByParent = currentStation?.archivedById && +currentStation?.archivedById !== +currentStation?.id

    return currentPage ? (
      <>
        {(isArchivedActive || isArchived) && !window.location.href.includes('/home') && (
          <div className="relative flex w-full">
            <ArchivePanel message={t('documentation:archiveDocPageMessage')} />
          </div>
        )}
        <ListToolbar>
          <div className={`flex items-center justify-between w-full ${isArchivedActive && 'mt-8'}`}>
            <div className="flex items-center cursor-pointer gap-2 ms-7" style={{ fontSize: 15 }}>
              <Link to={`${linkToDoc}/home`}>{t('documentation:documentation')}</Link>
              <div>/</div>
              {parents &&
                parents.map((i: any) => (
                  <div key={i.id} className="flex items-center gap-2">
                    <Link to={`${linkToDoc}/view/${i.id}`}>{i.title}</Link>
                    <div>/</div>
                  </div>
                ))}
              <div className="flex items-center gap-2">
                <Link className="font-semibold underline" to={`${linkToDoc}/view/${currentPage.id}`}>
                  {currentPage.title}
                </Link>
              </div>
            </div>
            <div
              className={classnames(`flex items-center justify-end bg-white px-7 ms-0.5`, {
                'py-2': currentPage.isArchived,
              })}>
              {!currentPage.isArchived && (
                <>
                  {allowUserAccess && (
                    <Link to={`${linkToDoc}/edit/${id}`}>
                      <Button
                        key={isRtl + 'edit'}
                        className="py-2"
                        icon={PencilOutlineIcon}
                        id={'doc-edit'}
                        variant={ButtonVariant.Icon}
                        onMouseOver={() => {
                          Tooltip('#doc-edit', t('common:labels.edit'), 'bottom', 'custom-bottom')
                        }}
                      />
                    </Link>
                  )}
                  <Button
                    key={isRtl + 'comments'}
                    className="py-2"
                    icon={CommentsIcon}
                    id={'doc-comments'}
                    variant={ButtonVariant.Icon}
                    onClick={() => dispatch(toggleDocumentationComments())}
                    onMouseOver={() => {
                      Tooltip('#doc-comments', t('records:listing.comments'), 'bottom', 'custom-bottom')
                    }}
                  />
                  {/*<Button*/}
                  {/*  key={isRtl + 'attachments'}*/}
                  {/*  className="py-2"*/}
                  {/*  icon={FolderOpenIcon}*/}
                  {/*  id={'doc-attachments'}*/}
                  {/*  variant={ButtonVariant.Icon}*/}
                  {/*  onClick={() => dispatch(toggleDocumentationAttachments())}*/}
                  {/*  onMouseOver={() => {*/}
                  {/*    Tooltip('#doc-attachments', t('records:listing.attachments'), 'bottom', 'custom-bottom')*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<Button*/}
                  {/*  key={isRtl + 'information'}*/}
                  {/*  className="py-2"*/}
                  {/*  icon={InfoIcon}*/}
                  {/*  id={'doc-information'}*/}
                  {/*  variant={ButtonVariant.Icon}*/}
                  {/*  onClick={() => dispatch(toggleDocumentationInfo())}*/}
                  {/*  onMouseOver={() => {*/}
                  {/*    Tooltip('#doc-information', t('records:listing.information'), 'bottom', 'custom-bottom')*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Button
                    key={isRtl + isPinned + 'pin'}
                    className="py-2"
                    id={'doc-pin'}
                    variant={ButtonVariant.Icon}
                    onClick={() => {
                      dispatch(
                        pinDocumentationPage({
                          id: +currentPage.id,
                          parentId: +docId,
                          status: Number(!isPinned),
                        }),
                      )
                      dispatch(pinDocumentationDocument({ currentPage: currentPage }))
                    }}
                    onMouseOver={() => {
                      const title = isPinned ? t('common:labels.unpin') : t('common:labels.pin')
                      Tooltip('#doc-pin', title, 'bottom', 'custom-bottom')
                    }}>
                    <StarIcon color={isPinned ? '#008996' : 'currentColor'} fill={isPinned ? '#008996' : 'white'} />
                  </Button>

                  <Button
                    key={isRtl + 'copy-link'}
                    className="py-2"
                    icon={LinkIcon}
                    id={'doc-link'}
                    variant={ButtonVariant.Icon}
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href)
                      toast.success('Link copied!', {
                        icon: false,
                      })
                    }}
                    onMouseOver={() => {
                      Tooltip('#doc-link', t('documentation:copyLink'), 'bottom', 'custom-bottom')
                    }}
                  />
                  {allowUserAccess && (
                    <Button
                      key={isRtl + 'link'}
                      className="py-2"
                      icon={RecordLinkIcon}
                      id={'doc-link-record'}
                      variant={ButtonVariant.Icon}
                      onClick={() => dispatch(toggleDocumentationLinks())}
                      onMouseOver={() => {
                        Tooltip('#doc-link-record', t('documentation:recordLink'), 'bottom', 'custom-bottom')
                      }}
                    />
                  )}
                </>
              )}
              {allowUserAccess && !isArchivedByParent && (
                <div className="ms-4">
                  <ContextMenu
                    isCustomButton
                    isDefaultPopup
                    render={() => {
                      return (
                        <div className="pb-2">
                          <Button className="text-danger" variant={ButtonVariant.Icon} onClick={onOpenArchive}>
                            {currentPage.isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
                          </Button>
                        </div>
                      )
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </ListToolbar>
        <ConfirmationModal
          confirmMessage={!isArchived ? t('workspace:archive') : t('common:labels.unarchive')}
          confirmationMessage={
            !isArchived
              ? t('documentation:archiveDocConfirmationMessage')
              : t('documentation:unarchiveDocConfirmationMessage')
          }
          isModalOpen={isArchiveOpen}
          onCancel={() => setIsArchiveOpen(false)}
          onConfirm={onArchive}
        />
        <BaseModal
          close={() => setIsDeleteOpen(false)}
          content={
            <div>
              <h1>{t('documentation:deleteDocConfirmationMessage')}</h1>
              <div className="flex justify-end my-6 gap-4">
                <Button variant={ButtonVariant.Outline} onClick={() => setIsDeleteOpen(false)}>
                  {t('common:labels.cancel')}
                </Button>
                <Button variant={ButtonVariant.Danger} onClick={onConfirmDelete}>
                  {t('documentation:deleteButton')}
                </Button>
              </div>
            </div>
          }
          header={''}
          isModalOpen={isDeleteOpen}
          type="confirmation"
          withoutCloseBtn
        />
      </>
    ) : null
  }

  if (type === 'archived') {
    return (
      <ListToolbar>
        <div className={`flex items-center justify-between w-full ${isArchivedActive && 'mt-8'}`}>
          <div className="flex items-center cursor-pointer gap-2 ms-7" style={{ fontSize: 15 }}>
            <Link to={`${linkToDoc}/home`}>{t('documentation:documentation')}</Link>
            <div>/</div>
            {parents &&
              parents.map((i: any) => (
                <div key={i.id} className="flex items-center gap-2">
                  <Link to={`${linkToDoc}/view/${i.id}`}>{i.title}</Link>
                  <div>/</div>
                </div>
              ))}
            <div className="flex items-center gap-2 py-2.5">
              <Link className="font-semibold underline" to={`${linkToDoc}/archived`}>
                {t('documentation:archivedPages')}
              </Link>
            </div>
          </div>
        </div>
      </ListToolbar>
    )
  }

  if (type === 'search') {
    const isSearchError = Boolean(formik.errors.docSearch) || !formik.values.docSearch

    return (
      <ListToolbar>
        <form className="flex items-center justify-between w-full px-7" onSubmit={formik.handleSubmit}>
          <div className="flex items-center gap-4">
            <div className="relative">
              <div
                className={`flex items-center gap-2 h-10 border-2 rounded px-2 ${
                  focusedSearch ? 'border-primary' : ''
                }`}
                style={{
                  width: 500,
                }}>
                <SearchIcon />

                <input
                  className="w-full"
                  name="docSearch"
                  placeholder={t('documentation:searchDocument')}
                  onBlur={() => setFocused(false)}
                  onChange={formik.handleChange}
                  onFocus={() => setFocused(true)}
                />
              </div>
              {/* {formik.errors.docSearch && (
                <p className="text-sm text-danger absolute -bottom-8">{formik.errors.docSearch}</p>
              )} */}
            </div>

            <Button
              className={`${isSearchError ? 'hover:bg-primary opacity-50' : ''}`}
              style={{ height: 40 }}
              type="submit"
              variant={ButtonVariant.Primary}>
              {t('documentation:search')}
            </Button>
          </div>
          <Select
            className="w-30"
            classNamePrefix="react-select"
            components={{
              IndicatorSeparator: () => <ChevronDownIcon className="me-2" width={20} />,
            }}
            defaultValue={formik.values.docSort}
            isRtl={isRtl}
            name="docSort"
            options={selectSortOptions}
            styles={selectStyles}
            onChange={(e) => {
              formik.setFieldValue('docSort', e).then(() => {
                formik.submitForm()
              })
            }}
          />
        </form>
      </ListToolbar>
    )
  }

  return allowUserAccess ? (
    <>
      <ListToolbar>
        <div className="flex items-center justify-end w-full bg-white gap-2 px-7 ml-0.5">
          {docPublicLink && (
            <Button
              icon={LinkIcon}
              style={{ height: 40 }}
              variant={ButtonVariant.Ghost}
              onClick={() => {
                navigator.clipboard.writeText(docPublicLink)
                toast.success('Link copied!', {
                  icon: false,
                })
              }}>
              {t('documentation:copyLink')}
            </Button>
          )}
          {/*<Button*/}
          {/*  style={{ height: 40 }}*/}
          {/*  variant={ButtonVariant.Outline}*/}
          {/*  onClick={() => {*/}
          {/*    dispatch(createOrDeleteStationDocPublicLink({ isRemove: Boolean(docPublicLink), stationId }))*/}
          {/*  }}>*/}
          {/*  {docPublicLink ? t('documentation:makeDocsPrivate') : t('documentation:makeDocsPublic')}*/}
          {/*</Button>*/}
          <Button icon={AddIcon} style={{ height: 40 }} variant={ButtonVariant.Primary} onClick={createNewPage}>
            {t('documentation:newDocument')}
          </Button>
        </div>
      </ListToolbar>
    </>
  ) : null
}

export default memo(DocumentationPagesHeader)
