import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import StateType from '../../utils/types/states'

const selectCurrentElementTree = (state: StateType) => state.element.elementTree
const selectCurrentElementTreeLoading = (state: StateType) => state.element.loading
const selectLinkedElements = (state: StateType) => state.element.linkedElements
const selectRefetchFunction = (state: StateType) => state.element.refetchFunction
const selectLinkedElementsLoading = (state: StateType) => state.element.linkedElementsLoading
const selectIntermediateElementId = (state: StateType) => state.element.intermediateAppElementId
const selectCurrentListColumnsType = (state: StateType) => state.element.columnsType
const selectListRecordOptions = (state: StateType) => state.element.listRecordOptions
const selectListRecordOptionsLoading = (state: StateType) => state.element.listRecordOptionsLoading
const selectLinkType = (state: StateType) => state.element.linkType
const selectElementSubscriber = (state: StateType) => state.element.elementSubscriber
const selectLoadElementSubscriber = (state: StateType) => state.element.loadElementSubscriber
const selectElementUsers = (state: StateType) => state.element.elementUsers
const selectNotifications = (state: StateType) => state.element.notifications
const selectNotificationsIsLoading = (state: StateType) => state.element.notificationsIsLoading
const selectElementUsersIsLoading = (state: StateType) => state.element.elementUsersIsLoading
const selectFormulaList = (state: StateType) => state.element.formulaList
const selectDashboardWidgets = (state: StateType) => state.element.dashboardWidgets
const selectElementCustomFields = (state: StateType) => state.element.elementCustomFields
const selectElementMakerCheckerPolicy = (state: StateType) => state.element.elementMakerCheckerPolicy
const selectElementMakerCheckerPolicyRequests = (state: StateType) => state.element.elementMakerCheckerPolicyRequests
const selectElementMakerCheckerPolicyRequestsCF = (state: StateType) =>
  state.element.elementMakerCheckerPolicyRequestsCF
const selectElementMakerCheckerPolicyRequestsLoading = (state: StateType) =>
  state.element.elementMakerCheckerPolicyRequestsLoading
const selectElementCustomFieldsLoading = (state: StateType) => state.element.isElementCustomFieldsLoading
const selectIsElementMakerCheckerPolicyLoading = (state: StateType) => state.element.isElementMakerCheckerPolicyLoading
const selectWidgetById = createSelector(
  [selectDashboardWidgets, (_, id) => id],
  (dashboardWidgets, widgetId) => dashboardWidgets[widgetId],
)

export const useCurrentElementTree: () => any | null = () => useSelector(selectCurrentElementTree)
export const useCurrentElementTreeLoading: () => any | null = () => useSelector(selectCurrentElementTreeLoading)
export const useLinkedElements: () => any | null = () => useSelector(selectLinkedElements)
export const useLinkedElementsLoading: () => boolean = () => useSelector(selectLinkedElementsLoading)
export const useIntermediateElementId: () => string | number | null = () => useSelector(selectIntermediateElementId)
export const useCurrentListColumnsType: () => string = () => useSelector(selectCurrentListColumnsType)
export const useListRecordOptions: () => [any] = () => useSelector(selectListRecordOptions)
export const useListRecordOptionsLoading: () => boolean = () => useSelector(selectListRecordOptionsLoading)
export const useLinkType: () => number = () => useSelector(selectLinkType)
export const useElementSubscriber: () => string = () => useSelector(selectElementSubscriber)
export const useLoadElementSubscriber: () => string = () => useSelector(selectLoadElementSubscriber)
export const useElementUsers: () => any[] = () => useSelector(selectElementUsers)
export const useNotifications: () => any[] = () => useSelector(selectNotifications)
export const useNotificationsIsLoading: () => boolean = () => useSelector(selectNotificationsIsLoading)
export const useElementUsersIsLoading: () => boolean = () => useSelector(selectElementUsersIsLoading)
export const useFormulaList: () => boolean = () => useSelector(selectFormulaList)
export const useDashboardWidgets: () => any = () => useSelector(selectDashboardWidgets)
export const useWidgetById: (id: number) => any = (id) => useSelector((state: StateType) => selectWidgetById(state, id))
export const useElementCustomFields: () => any[] = () => useSelector(selectElementCustomFields)
export const useElementCustomFieldsLoading: () => boolean = () => useSelector(selectElementCustomFieldsLoading)
export const useElementMakerCheckerPolicy: () => any = () => useSelector(selectElementMakerCheckerPolicy)
export const useElementMakerCheckerPolicyLoading: () => boolean = () =>
  useSelector(selectIsElementMakerCheckerPolicyLoading)
export const useElementMakerCheckerPolicyRequests: () => any[] = () =>
  useSelector(selectElementMakerCheckerPolicyRequests)
export const useElementMakerCheckerPolicyRequestsCF: () => any[] = () =>
  useSelector(selectElementMakerCheckerPolicyRequestsCF)
export const useElementMakerCheckerPolicyRequestsLoading: () => boolean = () =>
  useSelector(selectElementMakerCheckerPolicyRequestsLoading)
export const useRefetchFunction: () => any = () => useSelector(selectRefetchFunction)
