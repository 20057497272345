import * as React from 'react'
import { useSize } from 'react-use'
import { memo, useEffect, useRef, useState } from 'react'
import { FormattedGaugeChartDatasetType } from 'utils/types/GaugeChartDataType'
import Chart from '../../Chart/Chart'

const CircleGauge = ({ datasets, plugins }: { datasets: FormattedGaugeChartDatasetType, plugins: any }) => {
  const ref = useRef(null)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const [elementWidthValue, setElementWidthValue] = useState(0)

  useEffect(() => {
    const eleWidth = ref?.current?.offsetWidth
    if (ref.current && eleWidth !== elementWidthValue) {
      setElementWidthValue(eleWidth)
    }
  }, [ref?.current?.offsetWidth])

  const gaugeNeedle = {
    afterDatasetsDraw(chart, args: args) {
      const { ctx, data } = chart
      ctx.save()
      const needleValue = data.datasets[0].needleValue
      const x = chart?.getDatasetMeta(0)?.data[0]?.x
      const y = chart?.getDatasetMeta(0)?.data[0]?.y
      const outerRadius = chart?.getDatasetMeta(0)?.data[0]?.outerRadius - 20
      const angle = Math.PI
      const value1 = chart?.getDatasetMeta(0)?.data[0]?.circumference / angle / data.datasets[0].data[0]
      const circumference = (isNaN(value1) ? 0 : value1) * needleValue
      const needleAngle = circumference + 1.5
      //needle
      ctx.translate(x, y)
      ctx.rotate(angle * needleAngle)
      ctx.beginPath()
      ctx.strokeStyle = 'lightgray'
      ctx.fillStyle = 'lightgray'
      ctx.moveTo(0 - 3, 0)
      ctx.lineTo(0, -outerRadius)
      ctx.lineTo(0 + 3, 0)
      ctx.stroke()
      ctx.fill()
      //dot
      ctx.beginPath()
      ctx.arc(0, -3, 5, angle * 0, angle * 2, false)
      ctx.fill()
      ctx.restore()
    },
    id: 'gaugeNeedle',
  }

  return (
    <>
      {sized}
      <Chart
        datasets={[
          {
            backgroundColor: datasets.backgroundColors,
            borderColor: datasets.backgroundColors,
            borderRadius: 0,
            circumference: 180,
            cutout: '81%',
            data: datasets.steps,
            fill: false,
            fontSize: 8,
            needleValue: datasets?.actual ? datasets.actual : 0,
            rotation: 270,
          },
        ]}
        options={{
          maintainAspectRatio: false,
          plugins: { datalabels: { display: false }, legend: { display: false }, tooltip: { enabled: false } },
        }}
        plugins={[gaugeNeedle]}
        type={'pie'}
      />
    </>
  )
}
export default memo(CircleGauge)
