import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateRecordElementById } from 'features/record'
import { useActiveListView } from 'features/list'
import { fetchElementMakerCheckerPolicy } from '../../features/element'
import { formatValues } from '../../utils/constant/constant/common'
import { useCurrentListMembers } from '../../features/user'
import Loader from '../Loader'
import { RecordType } from '../../utils/types'
import AppElementAttributeType from '../../utils/types/AppElementAttributeType'
import MultiSelectDropDown from './MultiSelectDropDown'
import UserSelectOption from './UsersSelect/UserSelectOption'
import SelectedUserChip from './UsersSelect/SelectedUserChip'

const RecordReviewer: FC<{
  currentItem: RecordType,
  reviewers: any[],
  isDisabled: boolean,
}> = ({ currentItem, reviewers, isDisabled }) => {
  const dispatch = useDispatch()
  const usersList = useCurrentListMembers()
  const { t } = useTranslation('lists')
  const recordReviewers = reviewers.length > 0 ? reviewers : currentItem?.reviewers
  const currentView = useActiveListView()

  const reviewersList = useMemo(() => {
    return recordReviewers?.map((reviewer: any) => ({
      ...reviewer,
      value: reviewer.userId,
    }))
  }, [recordReviewers])
  const [value, setValue] = useState(reviewersList)

  useEffect(() => {
    setValue(reviewersList)
  }, [reviewersList])

  useEffect(() => {
    if (currentItem?.appElements[0]?.id) dispatch(fetchElementMakerCheckerPolicy(currentItem?.appElements[0]?.id))
  }, [currentItem?.appElements[0]?.id])

  const usersDropdownOptions =
    useMemo(
      () => usersList?.map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.userId, ...u })),
      [usersList],
    ) || []

  const onSelectUser = (data) => {
    setValue([...value, data])
  }

  const onRemoveOption = (id: string) => {
    const newList = value.filter((u) => +u?.value !== +id)
    setValue([...newList])
  }

  const onSubmit = ({ changes }) => {
    if (currentItem && currentView)
      dispatch(
        updateRecordElementById({
          changes,
          elementId: currentItem?.appElements[0]?.id,
          record: currentItem,
          viewId: currentView.id,
          needsFormatting: false,
        }),
      )
  }

  const multiSelectDropdownProps = {
    isDisabled: isDisabled,
    MultiSelectOptions: (props: any) => <UserSelectOption {...props} isUserItem={true} onSelect={onSelectUser} />,
    MultiSelectValue: (props: any) => <SelectedUserChip {...props} isUserItem={true} onRemove={onRemoveOption} />,
    name: 'SelectUsers',
    onBlur: (val: any) => {
      const attributeId = currentItem?.makerCheckerPolicyId
      if (attributeId) {
        const changedAttributes = [
          {
            attributeData: {
              attributeId,
              attributeType: AppElementAttributeType.reviewer,
            },
            Value: val.map((user) => user.userId),
          },
        ]
        onSubmit({ changes: changedAttributes })
      }
    },
    options: usersDropdownOptions,
    placeholder: t('listSettings:placeholderSelectUser'),
    setValue,
    value: value.length > 0 && value[0]?.label === undefined ? formatValues(usersDropdownOptions, value) : value,
  }
  return <div>{!value ? <Loader /> : <MultiSelectDropDown {...multiSelectDropdownProps} />}</div>
}
export default RecordReviewer
