import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { useAppDispatch } from 'store'
import recordType from 'utils/types/RecordType'
import { getElementTree, useCurrentElementTree, useCurrentElementTreeLoading } from 'features/element'
import { clearElementTree } from 'features/element/elementSlice'

import LinkElements from 'components/LinkElements'
import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'

interface LinkToRecordModalPropTypes {
  customFieldInfo: any;
  currentRecord: recordType;
  isMulti: boolean;
  updateLinks: (record: { value: number, action: string }) => void;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

const LinkToRecordModal: FC<LinkToRecordModalPropTypes> = ({
  customFieldInfo,
  currentRecord,
  isMulti,
  updateLinks,
  isModalOpen,
  setModalOpen,
}) => {
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('customFields')
  const currentList = useCurrentElementTree()
  const currentListLoading = useCurrentElementTreeLoading()
  const [selectedElements, setSelectedElements] = useState<any[]>([])
  const recordsElements = selectedElements.filter((element) => element.basedType === 3)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isCurrentRecordElement = +customFieldInfo?.appElementId === +currentRecord?.appElements[0]?.parentId
  const elementIdToLookIn = isCurrentRecordElement
    ? +customFieldInfo?.intermediateAppElementId
    : +customFieldInfo?.appElementId

  useEffect(() => {
    if (elementIdToLookIn) appDispatch(getElementTree({ documentFlag: false, elementId: +elementIdToLookIn }))
    return () => {
      appDispatch(clearElementTree())
    }
  }, [elementIdToLookIn])

  const handleSave = () => {
    setIsSubmitting(true)
    selectedElements.map((element) => {
      updateLinks({ action: 'add', value: +element.id })
    })
    setSelectedElements([])
    setIsSubmitting(false)
    setModalOpen(false)
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 z-50 flex flex-col w-full max-w-lg bg-white rounded shadow-lg focus:outline-none lg:top-28 lg:bottom-28 left-1/2 transform -translate-x-1/2"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/30 inset-0 fixed z-50"
      onRequestClose={() => {
        setModalOpen(false)
      }}>
      <div className="flex flex-col flex-1 w-full min-h-0 px-5 py-5 justify between">
        <div className="flex flex-col flex-1 min-h-0">
          <h2 className="pb-3 mb-3 font-bold border-b border-gray-200">{t('addLinkTitle')}</h2>
          <LinkElements
            currentList={currentList}
            currentListLoading={currentListLoading}
            elementBaseType={3}
            elementId={elementIdToLookIn}
            level={3}
            lowestLevel={3}
            options={{
              addManyLists: false,
              canAddRecord: false,
              isSingleSelect: !isMulti && isCurrentRecordElement,
              shouldShowPreview: false,
            }}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        </div>
        <div className="flex items-center justify-end mt-5">
          <Button
            className="me-1"
            small
            variant={ButtonVariant.Outline}
            onClick={() => {
              setSelectedElements([])
              setModalOpen(false)
            }}>
            {t('common:labels.cancel')}
          </Button>
          <Button
            data-testid="save-button"
            disabled={recordsElements.length === 0}
            small
            type="submit"
            onClick={handleSave}>
            {isSubmitting ? <Loader /> : t('linkButton')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LinkToRecordModal
