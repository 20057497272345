import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useAppDispatch } from 'store'
import recordType from 'utils/types/RecordType'
import { fetchLinkedDocumentsByListId, useCurrentList } from 'features/list'
import LinkElements from 'components/LinkElements'
import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getStationDocSidebar,
  useCurrentStation,
  useDocumentationSidebarAllItems,
  useStationDocumentationSidebarLoading,
} from '../../features/station'
import { createElementLink, getElementById } from '../../features/element'
import { fetchRecordById, updateRecordElementById } from '../../features/record'

interface LinkToDocumentModalPropTypes {
  currentRecord: recordType;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  setLinkedDocumentsList: any;
  linkedDocumentsList: any[];
}

const LinkToDocumentModal: FC<LinkToDocumentModalPropTypes> = ({
  currentRecord,
  isModalOpen,
  setModalOpen,
  linkedDocumentsList,
  setLinkedDocumentsList,
}) => {
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('records')
  const docList = useDocumentationSidebarAllItems()
  const docListLoading = useStationDocumentationSidebarLoading()
  const { viewId } = useParams<{ viewId: string }>()
  const [selectedElements, setSelectedElements] = useState<any[]>([])
  const recordsElements = selectedElements.filter((element) => element.basedType === 5)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const currentStation = useCurrentStation()
  const currentList = useCurrentList()

  const docSectionId = currentStation?.wikiElementData?.id

  useEffect(() => {
    dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docSectionId }))
  }, [docSectionId])

  const handleSave = () => {
    const body = {
      description: 'linked item',
      elementId: -1,
      isLinkedAsChild: false,
      linkedElementId: -1,
    }
    setIsSubmitting(true)
    selectedElements.map((element) => {
      body.elementId = +element.id
      body.linkedElementId = +currentRecord?.appElements[0]?.id
      appDispatch(createElementLink({ body })).then((res) => {
        appDispatch(getElementById(+res?.payload?.elementId)).then((response) => {
          const linkedDoc = response?.payload?.element?.appElementData
          const updatedLinkedDoc = [...linkedDocumentsList, { element: linkedDoc, id: +res.payload.id }]
          const recordAppElements = currentRecord?.appElements[0]
          const appElements = [{ ...recordAppElements, linkedElementAppElementLinks: updatedLinkedDoc }]
          appDispatch(
            fetchRecordById({
              id: Number(currentRecord?.id),
              viewId,
            }),
          ).then(() => {
            if (currentList && currentList.id) appDispatch(fetchLinkedDocumentsByListId(currentList?.id))
            setLinkedDocumentsList(updatedLinkedDoc)
          })
        })
      })
    })
    setSelectedElements([])
    setIsSubmitting(false)
    setModalOpen(false)
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 z-50 flex flex-col w-full max-w-lg bg-white rounded shadow-lg focus:outline-none lg:top-28 lg:bottom-28 left-1/2 transform -translate-x-1/2"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/30 inset-0 fixed z-50"
      onRequestClose={() => {
        setModalOpen(false)
      }}>
      <div className="flex flex-col flex-1 w-full min-h-0 px-5 py-5 justify between">
        <div className="flex flex-col flex-1 min-h-0">
          <h2 className="pb-3 mb-3 font-bold border-b border-gray-200">{t('addDocumentLinkTitle')}</h2>
          <LinkElements
            checkBasedType={false}
            currentList={docList}
            currentListLoading={docListLoading}
            elementBaseType={5}
            elementId={docSectionId}
            emptyPreviewMessage={t('addDocumentLinkEmptyPreview')}
            level={5}
            lowestLevel={5}
            options={{
              addManyLists: false,
              canAddRecord: false,
              isSingleSelect: true,
              shouldShowPreview: false,
            }}
            previewTitle={t('addDocumentLinkPreviewTitle')}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        </div>
        <div className="flex items-center justify-end mt-5">
          <Button
            className="me-1"
            small
            variant={ButtonVariant.Outline}
            onClick={() => {
              setSelectedElements([])
              setModalOpen(false)
            }}>
            {t('common:labels.cancel')}
          </Button>
          <Button
            data-testid="save-button"
            disabled={recordsElements.length === 0}
            small
            type="submit"
            onClick={handleSave}>
            {isSubmitting ? <Loader /> : t('customFields:linkButton')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LinkToDocumentModal
