import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import { useTranslation } from 'react-i18next'
import { RichTextEditorWithMentions } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import CustomScroll from '../../../CustomScroll'
import { DashboardWidgetBaseTypes } from '../../../../utils/constant/enums/DashboardWidgetBaseType'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { SeeMoreIcon } from '../../../Icons'
import AddImageModal from '../../AddImageModal'
import Loader from '../../../Loader'
import { useAppDispatch } from '../../../../store'
import { getWidgetData, useWidgetById } from '../../../../features/element'
// import head from 'lodash/head'
// import { toast } from 'react-toastify'
// import { CrossSignIcon } from 'components/Icons'

// const SUPPORTED_FILE_FORMATS = ['image/jpeg', 'image/png']

// const file2Base64 = (file: File): Promise<string> => {
//   return new Promise<string>((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result?.toString() || '')
//     reader.onerror = (error) => reject(error)
//   })
// }

const TextWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  elementId: string,
  setIsEditorOpen: () => void,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
  updateTextWidget: (widgetId: number, description: string, imgBase64?: string) => void,
}> = ({ widgetInfo, isEdit, updateTextWidget, setIsEditorOpen, onUpdateWidgetImage, elementId, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(false)
  const isDescriptionWidget = widgetInfo.baseType === DashboardWidgetBaseTypes.Description
  const { description, data } = widget || widgetInfo
  const textWidgetData: string = !description || description === '<p></p>' ? '' : description
  const descriptionWidgetData: string = !data?.description || data?.description === '<p></p>' ? '' : data?.description
  const textData = isDescriptionWidget ? descriptionWidgetData : textWidgetData
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [opened, setOpened] = useState(false)
  const { t } = useTranslation('dashboard')
  const [values, setValues] = useState({
    description: textData ? textData : '',
    widgetId: widgetInfo.id || widgetInfo.tempId,
  })

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  const handleChange = useCallback(
    (e: any) => {
      const description = e.target.value
      const regexP = /<p><\/p>/g
      const regexOl = /<ol>/g
      const descriptionwithSpaces = description.replace(regexP, '<p><br></p>')
      const descriptionwithSpacesAndMarker = descriptionwithSpaces.replace(
        regexOl,
        '<ol style=" list-style-type: decimal;">',
      )
      setValues({
        ...values,
        description: descriptionwithSpacesAndMarker,
        widgetId: widgetInfo.id || widgetInfo.tempId,
      })
    },
    [widgetInfo, setValues],
  )

  const openEditor = useCallback(() => {
    if (isEdit) {
      setOpened(!opened)
      setIsEditorOpen(!opened)
    }
  }, [isEdit, setOpened, opened])

  const cancelEdit = useCallback(() => {
    setOpened(false)
    setIsEditorOpen(false)
    setValues({ ...values, description: textData, widgetId: widgetInfo.id || widgetInfo.tempId })
  }, [setOpened, setValues])

  const submitChanges = useCallback(() => {
    updateTextWidget(values.widgetId, values.description)
    setOpened(false)
    setIsEditorOpen(false)
  }, [values, setOpened, setValues, updateTextWidget])

  // const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const file: any = head(event.target.files)
  //   if (!SUPPORTED_FILE_FORMATS.includes(file.type)) return toast(t('validation:errors.unsupportedImageFileFormat'))

  //   const base64Img = await file2Base64(file)

  //   updateTextWidget(widgetInfo.id, textData, base64Img)
  //   event.target.value = ''
  // }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isEdit && widgetInfo.baseType !== DashboardWidgetBaseTypes.Text && (
        <div className="flex items-center justify-end">
          <SeeMoreIcon className="w-5 cursor-pointer" onClick={() => setIsModalOpen(true)} />
        </div>
      )}
      <CustomScroll hideScroll={isPrinting} maxHeight={'90%'}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
        )}
        <div className={`${isEdit && opened ? '-mt-2' : 'mt-2'}`}>
          {isEdit && opened && !isDescriptionWidget ? (
            <>
              <RichTextEditorWithMentions
                content={textData}
                setIsEditorOpen={setIsEditorOpen}
                usersList={[]}
                onChange={handleChange}
              />
              <div className="flex justify-end mt-2">
                <Button className="me-2" small variant={ButtonVariant.Ghost} onClick={cancelEdit}>
                  {t('common:labels.cancel')}
                </Button>
                <Button small type="submit" variant={ButtonVariant.Primary} onClick={submitChanges}>
                  {t('common:labels.save')}
                </Button>
              </div>
            </>
          ) : (
            <div
              className="whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html:
                  !textData || textData === '<p><br></p>'
                    ? isDescriptionWidget
                      ? t('noDescription')
                      : t('Add your text')
                    : textData,
              }}
              style={{
                border: textData ? 'none' : isEdit && !isDescriptionWidget ? '1px dashed #00b2a9' : 'none',
                borderRadius: 4,
                height: textData ? 'auto' : '40px',
                opacity: !textData ? 0.5 : 1,
              }}
              onClick={openEditor}
            />
          )}
        </div>
      </CustomScroll>
      <AddImageModal
        elementId={elementId}
        isEditMode={isEdit}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        widget={widgetInfo}
        onUpdateWidgetImage={onUpdateWidgetImage}
      />
      {/* {isEditMode && !widgetInfo.image && (
        <div className="flex items-center justify-center">
          <input
            accept={SUPPORTED_FILE_FORMATS.join(',')}
            id="textImage"
            name="textImage"
            type="file"
            onChange={onFileChange}
          />
          <label
            className={
              'mt-6 items-center px-2 text-sm font-bold text-center text-gray-600 border-2 border-gray-400 border-solid rounded py-2 shadow-sm transition-colors'
            }
            htmlFor="textImage">
            {t('user:details:uploadImage')}
          </label>
        </div>
      )} */}
      {/* {widgetInfo.image && (
        <div className="relative m-auto mt-6" style={{ width: '80%' }}>
          {isEditMode && (
            <div
              className={`absolute z-50 w-5 h-5 rounded-full cursor-pointer bg-danger -top-2 ${
                i18n.language === 'ar' ? '-left-2' : '-right-2'
              }`}
              onClick={() => updateTextWidget(widgetInfo.id, textData, '')}>
              <CrossSignIcon color="white" />
            </div>
          )}
          <img width="100%" height="auto" src={widgetInfo.image} alt="img" />
        </div>
      )} */}
    </>
  )
}

export default memo(TextWidget)
