import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { useAppDispatch } from 'store'

import { updatedRecordTarget } from 'utils/types/KanbanPropsType'
import { KanbanColumnTypeType } from 'utils/types'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import {
  fetchLinkedDocumentsByListId,
  useCurrentListStatuses,
  useCurrentListDetails,
  useLinkedDocuments,
} from 'features/list'
import { getHealthOptions, getPriorityOptions } from 'utils/helpers/recordHelpers'

import {
  fetchRecordsByParams,
  updateRecordById,
  updateRecordElementById,
  useCurrentRecordsCustomFields,
  useRecords,
} from 'features/record'
import { useUsersByList } from 'features/user'
import { getListLinkOptions, useCurrentListColumnsType } from 'features/element'

import KanbanComplex from 'components/KanbanComplex'
import ArchivePanel from 'components/ArchivePanel/ArchivePanel'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'

type KanbanColumnType = { type: KanbanColumnTypeType, position: number }

const KanbanViewPage: FC<{ isArchived: boolean }> = ({ isArchived }) => {
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const statuses = useCurrentListStatuses()
  const { listId, viewId }: { listId: string, viewId: string } = useParams()
  const columnsType = useCurrentListColumnsType()
  const { t, i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const priorities = getPriorityOptions()
  const healths = getHealthOptions()
  const owners = useUsersByList()
  const listDetails = useCurrentListDetails()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)
  const linkedDocs = useLinkedDocuments()
  const records = useRecords()
  const uniqueList = uniqBy(linkedDocs, 'elementId')
  const isArchivedList = listDetails?.appElement?.isArchived
  const customFields = fieldsList
    ?.filter((i: any) => i.baseType === customFieldBaseTypes.DropDown)
    ?.map((i: any) => ({ id: i.id, label: i.fieldName, list: i.customFieldOptions, value: i.id }))

  useEffect(() => {
    dispatch(fetchLinkedDocumentsByListId(+listId))
    const listElementId = listDetails?.appElement?.id
    const statuses = listDetails?.statuses
    dispatch(fetchRecordsByParams({ archiveStatus: Number(isArchived), listElementId, listId, statuses, viewId }))
  }, [listId, isArchived, viewId])

  const updateRecord = async (recordId: number, type: string, targetValue: updatedRecordTarget, newOrder?: number) => {
    const recordToUpdate = find(records, ({ id }) => id === recordId)
    if (recordToUpdate === undefined) return
    let updatedRecord = { ...recordToUpdate }
    const customFieldIndex = customFields.findIndex((field: any) => +field.id === +type)

    if (customFieldIndex > -1) {
      const fieldValue = {
        customFieldId: type,
        customFieldOptionId: targetValue.value,
        value: targetValue.value,
      }
      const allCustoms = [...recordToUpdate.appElements[0].customFieldValues]
      const customIndex = allCustoms.findIndex((i: any) => +i.customFieldId === +type)
      allCustoms[customIndex] = { ...allCustoms[customIndex], ...fieldValue }
      const fieldsList = {
        appElements: [
          {
            ...recordToUpdate?.appElements[0],
            customFieldValues: allCustoms,
          },
        ],
      }
      updatedRecord = { ...recordToUpdate, ...fieldsList }
    }
    const listElementId = listDetails?.appElement?.id
    appDispatch(
      updateRecordElementById({
        changes: { [type]: targetValue.value },
        elementId: updatedRecord?.appElements[0]?.id,
        newOrder,
        oldOrder: updatedRecord?.appElements[0]?.elementOrder,
        record: updatedRecord,
        viewId,
        statuses,
        listElementId,
      }),
    )
  }
  const notAssignedType = { label: t('notAssigned'), value: null }

  //status columns
  const statusesList = useMemo(() => {
    const columns: KanbanColumnType[] = []
    columns?.push({ position: 0, type: notAssignedType })
    sortBy(statuses, 'position').forEach((status, index) => {
      columns.push({
        id: status?.id?.toString() || `${index}`,
        position: status?.position,
        type: {
          label: status?.name,
          value: status?.id?.toString() || `${index}`,
        },
      })
    })
    return columns
  }, [isRtl, statuses])

  //priorities columns
  const priorityList = useMemo(() => {
    const columns: KanbanColumnType[] = []
    priorities.reverse().forEach((item, index) => {
      if (item?.id === -1) {
        columns.unshift({
          id: item?.id,
          position: 0,
          type: item,
        })
      } else {
        columns.push({
          id: item?.id,
          position: index,
          type: item,
        })
      }
    })
    return columns
  }, [isRtl, priorities])

  //healths columns
  const healthList = useMemo(() => {
    const columns: KanbanColumnType[] = []
    healths.forEach((item, index) => {
      columns.push({
        id: item?.id,
        position: index,
        type: item,
      })
    })
    return columns
  }, [isRtl, healths])

  //owners columns
  const ownersList = useMemo(() => {
    const columns: KanbanColumnType[] = []
    owners.forEach((item, index) => {
      columns.push({
        id: item?.id,
        position: index,
        type: {
          label: item.name,
          value: item.id,
          ...item,
        },
      })
    })
    return columns
  }, [isRtl, owners])

  //custom Fields select type columns
  const customColumnsObject = useMemo(() => {
    let columns: KanbanColumnType[] = []
    const customColumns = {}
    customFields?.map((column) => {
      column?.list?.forEach((item) => {
        columns.push({
          id: item?.id,
          position: item.optionOrder,
          type: {
            label: item.optionCaption,
            value: item.id,
            ...item,
          },
        })
      })
      customColumns[column.id] = { baseType: column.baseType, formatted: columns, origin: column.list }
      columns = []
    })
    return customColumns
  }, [isRtl, listDetails, customFields])

  //link custom Fields type columns
  const linkFields = useMemo(() => {
    const customLinkedFields = {}
    fieldsList
      .filter((field: CustomFieldType) => field.baseType === customFieldBaseTypes.SingleLink)
      .filter((f: CustomFieldType) => +f.appElementId === +listDetails?.appElement?.id)
      .map((field: any) => {
        return appDispatch(getListLinkOptions(+field.intermediateAppElementId)).then((res) => {
          const options =
            res?.payload?.optionsList?.length > 0
              ? res?.payload?.optionsList?.map((record: any, index: number) => {
                  return {
                    id: record.id,
                    position: index,
                    type: {
                      label: record.element.elementName,
                      value: record.id,
                      ...record,
                    },
                  }
                })
              : []
          customLinkedFields[field.id] = {
            baseType: field.baseType,
            customId: field.id,
            formatted: options,
            id: field.id,
            origin: options,
          }
        })
      })
    return customLinkedFields
  }, [isRtl, listId])

  //linked documents columns
  const linkedDocumentsList = useMemo(() => {
    const columns: KanbanColumnType[] = []
    uniqueList.forEach((item, index) => {
      columns.push({
        id: item?.element?.id,
        position: index,
        type: {
          label: item?.element.elementName,
          value: item?.element?.id,
          ...item,
        },
      })
    })
    return columns
  }, [isRtl, listDetails, uniqueList])

  const columnTypeList = {
    documents: { formatted: linkedDocumentsList, origin: uniqueList },
    health: { formatted: healthList, origin: healths },
    ownerId: { formatted: ownersList, origin: owners },
    priority: { formatted: priorityList, origin: priorities },
    statusId: { formatted: statusesList, origin: statuses },
    ...customColumnsObject,
    ...linkFields,
  }

  return (
    <div className="flex-1 h-full overflow-y-hidden">
      {isArchived && (
        <div className="flex w-full">
          <ArchivePanel message={t('records:archiveRecordsMessage')} />
        </div>
      )}
      <KanbanComplex
        baseType={columnTypeList[columnsType]?.baseType}
        columnsType={columnsType}
        customId={columnTypeList[columnsType]?.customId}
        isArchived={isArchived}
        list={columnTypeList[columnsType]?.formatted}
        originList={columnTypeList[columnsType]?.origin}
        statuses={statuses}
        updateRecord={updateRecord}
      />
    </div>
  )
}

export default KanbanViewPage
