import { createSlice } from '@reduxjs/toolkit'
import orderBy from 'lodash/orderBy'
import { reorderItems } from 'utils/helpers/generalHelpers'

import { ListType } from 'utils/types'
import { AppElementView } from 'utils/types/AppElementViewType'
import { ListStateType } from 'utils/types/states'

import { gridCustomInitData } from '../../utils/helpers/customFields'
import ElementOperations from '../../utils/constant/enums/element'
import {
  addList,
  fetchListById,
  fetchListsByStationId,
  updateList,
  createListStatus,
  removeListStatus,
  updateListStatus,
  removeListById,
  reorderListStatus,
  createListLabel,
  removeListLabel,
  importRecords,
  updateListLabel,
  importExcelSheet,
  fetchLinkedDocumentsByListId,
  updateListCustomFields,
  archiveListById,
  onMoveList,
  fetchViewsByListElementId,
  removeListView,
  reorderListView,
} from './actions'

const initialState: ListStateType = {
  currentListDetails: undefined,
  currentListId: undefined,
  activeListViewId: null,
  currentListLoading: true,
  currentListViews: [],
  customGridData: {},
  isLinkedDocumentsLoading: true,
  isLoadingListViews: true,
  isRejected: false,
  linkedDocuments: [],
  lists: [],
  listsIds: [],
  loading: true,
}

export const listSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchListById.fulfilled, (state, action) => {
      state.currentListId = action?.payload?.id
      const updatedList = action.payload
      const updatedListIndex = state?.lists?.findIndex((list) => updatedList?.id === list.id)
      state.lists[updatedListIndex] = updatedList
      state.currentListDetails = action.payload
      state.customGridData = gridCustomInitData(action?.payload?.customFields?.native?.fields)
      state.currentListLoading = false
      if (state.isRejected) state.isRejected = false
    })
    builder.addCase(updateListCustomFields.fulfilled, (state, action) => {
      const updatedList = action.payload
      const updatedListIndex = state?.lists?.findIndex((list) => updatedList?.id === list?.id)
      if (updatedListIndex > -1) state.lists[updatedListIndex] = { ...state.lists[updatedListIndex], ...updatedList }
      else state.lists = [...state.lists, updatedList]
      state.currentListId = action?.payload?.id
      state.currentListDetails = state.lists[updatedListIndex]
      state.currentListLoading = false

      if (state.isRejected) state.isRejected = false
    })

    builder.addCase(fetchListById.pending, (state) => {
      state.currentListLoading = true
    })

    builder.addCase(fetchListById.rejected, (state) => {
      state.customGridData = {}
      state.currentListDetails = undefined
      state.currentListLoading = false
    })

    builder.addCase(fetchListsByStationId.fulfilled, (state, action) => {
      state.lists = action.payload
      state.listsIds = action?.payload?.map((list: ListType) => +list.id)
      state.loading = false
    })

    builder.addCase(fetchListsByStationId.pending, (state) => {
      state.loading = true
      if (state.isRejected) state.isRejected = false
    })

    builder.addCase(fetchListsByStationId.rejected, (state) => {
      state.isRejected = true
      state.loading = false
    })

    builder.addCase(addList.fulfilled, (state, action) => {
      state.lists = [...state.lists, action.payload]
      state.listsIds = [...state.listsIds, +action.payload.id]
      state.loading = false
    })

    builder.addCase(addList.pending, (state) => {
      state.loading = true
    })

    builder.addCase(addList.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(updateList.fulfilled, (state, action) => {
      const { list, listDetails } = action.payload
      const updatedList = list
      const updatedListIndex = state?.lists?.findIndex((item) => +updatedList?.id === +item?.id)
      state.lists[updatedListIndex] = updatedList
      state.currentListDetails = { ...list, ...listDetails }
      state.loading = false
    })

    builder.addCase(updateList.pending, (state, action) => {
      const { list, changes } = action.meta.arg
      const updatedListIndex = state.lists.findIndex(({ id: listId }) => list.id === listId)
      state.lists[updatedListIndex] = { ...list, ...changes }
    })

    builder.addCase(updateList.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(removeListById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(removeListById.fulfilled, (state, action) => {
      // const { listId } = action.meta.arg
      // const deletedRecordIndex = state.lists.findIndex(({ id }) => listId === id)
      // state.lists.splice(deletedRecordIndex, 1)
      // state.listsIds.splice(deletedRecordIndex, 1)
      state.loading = false
    })

    builder.addCase(removeListById.rejected, (state, action) => {
      const { initialList } = action.meta.arg
      state.lists.push(initialList)
      state.listsIds.push(+initialList.id)
      state.loading = false
    })
    //archive list by id
    // builder.addCase(archiveListById.fulfilled, (state) => {})

    builder.addCase(archiveListById.pending, (state, action) => {
      const listElementId = +action?.payload
      const archivedRecordIndex = state.lists.findIndex(({ appElement }) => +listElementId === +appElement.id)
      state.lists.splice(archivedRecordIndex, 1)
      state.listsIds.splice(archivedRecordIndex, 1)
      state.loading = true
      state.currentListViews = []
      state.isLoadingListViews = true
      state.activeListViewId = null
    })

    builder.addCase(archiveListById.rejected, (state, action) => {
      const { initialList } = action.meta.arg
      state.lists.push(initialList)
      state.listsIds.push(+initialList.id)
      state.loading = false
    })

    builder.addCase(createListStatus.fulfilled, (state, action) => {
      state.loading = false
      if (state.currentListId !== undefined) {
        const listToUpdate = state.lists.find(({ id: listId }) => state.currentListId === listId)
        if (listToUpdate !== undefined) {
          listToUpdate.statuses = [...listToUpdate.statuses, action.payload]
        }
      }
      state.currentListDetails = {
        ...state.currentListDetails,
        statuses: [...state.currentListDetails.statuses, action.payload],
      }
    })

    builder.addCase(removeListStatus.pending, (state) => {
      state.loading = true
    })

    builder.addCase(removeListStatus.fulfilled, (state, action) => {
      const { statusId } = action.payload
      const updatedListIndex = state?.lists?.findIndex((list) => state.currentListId === list.id)
      const newStatuses = state?.lists[updatedListIndex]?.statuses.filter((status) => statusId !== status?.id)
      state.lists[updatedListIndex] = { ...state.lists[updatedListIndex], statuses: newStatuses }
      state.loading = false
    })

    builder.addCase(removeListStatus.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(updateListStatus.fulfilled, (state, action) => {
      const updatedStatus = action.payload
      if (state.currentListId !== undefined) {
        const listToEditIndex = state.lists.findIndex((list: ListType) => list.id === state.currentListId)
        if (listToEditIndex > -1) {
          const listToEdit = state.lists[listToEditIndex]
          const indexToEdit = listToEdit.statuses.findIndex((status) => updatedStatus?.id === status?.id)
          state.lists[listToEditIndex].statuses[indexToEdit] = updatedStatus
          const listStatuses = state.lists[listToEditIndex].statuses
          listStatuses[indexToEdit] = { ...updatedStatus }
          state.currentListDetails = { ...state.currentListDetails, statuses: listStatuses }
        }
      }
      state.loading = false
    })

    builder.addCase(updateListStatus.pending, (state, action) => {
      const { status: initialStatus, changes } = action.meta.arg
      if (state.currentListId !== undefined && initialStatus) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const statusToUpdate = state.lists[currentListIndex].statuses.findIndex(
          ({ id: statusId }: { id: number }) => initialStatus?.id === statusId,
        )
        if (statusToUpdate > -1) {
          state.lists[currentListIndex].statuses[statusToUpdate] = { ...initialStatus, ...changes }

          const indexToEditInLists = state.lists[currentListIndex].statuses.findIndex(
            ({ id: statusId }: { id: number }) => initialStatus?.id === statusId,
          )
          state.lists[currentListIndex].statuses[indexToEditInLists] = { ...initialStatus, ...changes }
        }
      }
    })

    builder.addCase(updateListStatus.rejected, (state, action) => {
      const { status } = action.meta.arg
      if (state.currentListId) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const statusToUpdate = state.lists[currentListIndex].statuses.findIndex(
          ({ id: statusId }: { id: number }) => status?.id === statusId,
        )
        if (status !== undefined) state.lists[currentListIndex].statuses[statusToUpdate] = status
      }
      state.loading = false
    })

    builder.addCase(reorderListStatus.fulfilled, (state, action) => {
      const updatedStatus = action.payload
      const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
      const updatedStatusIndex =
        state?.lists?.[currentListIndex]?.statuses?.findIndex(
          ({ id: statusId }: { id: number }) => statusId === updatedStatus.id,
        ) || -1
      if (updatedStatusIndex > -1 && state?.lists?.[currentListIndex]?.statuses?.[updatedStatusIndex])
        state.lists[currentListIndex].statuses[updatedStatusIndex] = updatedStatus
      state.currentListDetails = state.lists[currentListIndex]
    })
    builder.addCase(reorderListStatus.pending, (state, action) => {
      const { position, status } = action.meta.arg
      if (state.currentListId && status) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const reorderedStatuses = orderBy(
          [...state.lists?.[currentListIndex].statuses.filter(({ id }: { id: number }) => id !== status.id)],
          'position',
        )
        reorderedStatuses.splice(position - 1, 0, { ...status, position })
        state.lists[currentListIndex].statuses = reorderedStatuses.map((status, index) => ({
          ...status,
          position: index + 1,
        }))
      }
    })
    builder.addCase(reorderListStatus.rejected, (state, action) => {
      const { status } = action.meta.arg
      if (state.currentListId && status) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const reorderedStatuses = orderBy(
          [...state.lists[currentListIndex].statuses.filter(({ id }: { id: number }) => id !== status.id)],
          'position',
        )
        reorderedStatuses.splice(status.position, 0, status)
        state.lists[currentListIndex].statuses = reorderedStatuses.map((status, index) => ({
          ...status,
          position: index + 1,
        }))
      }
    })

    //createListLabel
    builder.addCase(createListLabel.fulfilled, (state, action) => {
      state.loading = false
      if (state.currentListId) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const newLabels = [...state.lists[currentListIndex].labels, action.payload]
        state.lists[currentListIndex].labels = newLabels
        state.currentListDetails = { ...state.currentListDetails, labels: newLabels }
      }
    })

    //updateListLabel
    builder.addCase(updateListLabel.fulfilled, (state, action) => {
      const updatedLabel = action.payload
      if (state.currentListId) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const indexToEdit = state.lists[currentListIndex]?.labels.findIndex(
          ({ id: labelId }: { id: number }) => updatedLabel?.id === labelId,
        )
        state.lists[currentListIndex].labels[indexToEdit] = updatedLabel
      }
      state.loading = false
    })

    builder.addCase(updateListLabel.pending, (state, action) => {
      const { label: initialLabel, changes } = action.meta.arg
      if (state.currentListId && initialLabel) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const labelToUpdate = state.lists[currentListIndex].labels.findIndex(
          ({ id: labelId }: { id: number }) => initialLabel?.id === labelId,
        )
        if (labelToUpdate > -1) {
          state.lists[currentListIndex].labels[labelToUpdate] = { ...initialLabel, ...changes }
        }
      }
    })

    builder.addCase(updateListLabel.rejected, (state, action) => {
      const { label } = action.meta.arg
      if (state.currentListId) {
        const currentListIndex = state.lists.findIndex(({ id }: { id: number }) => id === state.currentListId)
        const labelToUpdate = state.lists[currentListIndex].labels.findIndex(
          ({ id: labelId }: { id: number }) => label?.id === labelId,
        )
        if (label !== undefined) state.lists[currentListIndex].labels[labelToUpdate] = label
      }
      state.loading = false
    })

    //removeListLabel
    builder.addCase(removeListLabel.fulfilled, (state, action) => {
      const { labelId } = action.payload
      const updatedListIndex = state.lists.findIndex(({ id: listId }) => Number(state.currentListId) === +listId)
      if (updatedListIndex > -1) {
        const newLabels = state?.lists[updatedListIndex]?.labels.filter(({ id }) => labelId !== id)
        state.lists[updatedListIndex].labels = newLabels
        state.loading = false
      }
    })

    builder.addCase(removeListLabel.pending, (state) => {
      state.loading = true
    })

    builder.addCase(removeListLabel.rejected, (state) => {
      state.loading = false
    })

    // import records
    builder.addCase(importRecords.fulfilled, (state, action) => {
      state.loading = false
      if (state.lists) {
        if (action.payload.response) {
          const newLists = [...state.lists, action.payload.response]
          state.lists = newLists
          state.listsIds = newLists.map((list: ListType) => +list.id)
        }
      }
    })

    builder.addCase(importRecords.pending, (state) => {
      state.loading = true
    })

    builder.addCase(importRecords.rejected, (state) => {
      state.loading = false
    })

    // import excelSheet
    builder.addCase(importExcelSheet.fulfilled, (state) => {
      state.loading = false
    })

    builder.addCase(importExcelSheet.pending, (state) => {
      state.loading = true
    })

    builder.addCase(importExcelSheet.rejected, (state, action) => {
      state.loading = false
    })
    // linked documents
    builder.addCase(fetchLinkedDocumentsByListId.fulfilled, (state, action) => {
      state.linkedDocuments = action.payload
      state.isLinkedDocumentsLoading = true
    })

    builder.addCase(fetchLinkedDocumentsByListId.pending, (state) => {
      state.isLinkedDocumentsLoading = false
    })

    builder.addCase(fetchLinkedDocumentsByListId.rejected, (state, action) => {
      state.isLinkedDocumentsLoading = true
    })
    //onMoveList
    builder.addCase(onMoveList.fulfilled, (state, action) => {
      const movedListId = action.payload.appElementId
      const movedListIndex = state.lists.findIndex(({ appElement }) => +movedListId === +appElement?.id)
      if (action.payload.newParentId && Number(action.payload.operation) === ElementOperations.MOVE) {
        state.lists.splice(movedListIndex, 1)
      } else if (!action.payload.newParentId) {
        state.lists.push(action.payload.response)
      }
      state.loading = false
    })

    // fetch views by list element id
    builder.addCase(fetchViewsByListElementId.pending, (state, action) => {
      state.isLoadingListViews = true
    })
    builder.addCase(fetchViewsByListElementId.fulfilled, (state, action) => {
      const listViews: AppElementView[] = action.payload
      const activeListView = listViews.find((view) => view.isDefault) || listViews.at(0)
      state.currentListViews = listViews
      state.activeListViewId = activeListView?.id || null
      state.isLoadingListViews = false
    })
    builder.addCase(fetchViewsByListElementId.rejected, (state, action) => {
      state.isLoadingListViews = false
    })
    // remove view
    // builder.addCase(removeListView.pending, (state, action) => {})
    builder.addCase(removeListView.fulfilled, (state, action) => {
      const viewId = action.meta.arg
      const activeListView = state.currentListViews.find((view) => view.isDefault) || state.currentListViews.at(0)
      state.currentListViews = state.currentListViews.filter((view) => +view.id !== +viewId)
      state.activeListViewId = activeListView?.id || null
      state.isLoadingListViews = false
    })
    // builder.addCase(removeListView.rejected, (state, action) => {})

    // Reorder View
    builder.addCase(reorderListView.pending, (state, action) => {
      const viewId = action.meta.arg.id
      const newOrder = action.meta.arg.newOrder
      const oldOrder = state.currentListViews.find((view) => view.id == viewId)?.orderNumber
      if (oldOrder && newOrder) {
        const reorderedViews = reorderItems({
          itemId: viewId,
          newOrder,
          oldOrder,
          orderPath: 'orderNumber',
          originalItems: state.currentListViews,
        })

        state.currentListViews = reorderedViews
      }
    })
    builder.addCase(reorderListView.fulfilled, (state, action) => {
      const newDefaultViewId = action.payload.defaultViewId
      const updatedView = action.payload.view

      for (let i = 0; i < state.currentListViews.length; i++) {
        const view = state.currentListViews[i]
        // If the new default view is the updated view then there wont be a problem because we are updating here
        if (view.id == updatedView.id) {
          state.currentListViews[i] = updatedView
        } else if (view.id == newDefaultViewId) {
          state.currentListViews[i].isDefault = true
        } else {
          state.currentListViews[i].isDefault = false
        }
      }
    })
    builder.addCase(reorderListView.rejected, (state, action) => {
      const viewId = action.meta.arg.id
      const newOrder = Number(action.payload)
      const oldOrder = action.meta.arg.newOrder
      if (oldOrder && newOrder) {
        const reorderedViews = reorderItems({
          itemId: viewId,
          newOrder,
          oldOrder,
          orderPath: 'orderNumber',
          originalItems: state.currentListViews,
        })

        state.currentListViews = reorderedViews
      }
    })
  },
  initialState,
  name: 'list',
  reducers: {
    clearCurrentList: (state) => {
      state.currentListId = undefined
      state.currentListDetails = undefined
      state.currentListLoading = true
      state.isLinkedDocumentsLoading = true
      state.currentListViews = []
      state.isLoadingListViews = true
      state.activeListViewId = null
    },
    clearLists: (state) => {
      state.lists = []
      state.listsIds = []
      state.loading = true
      state.currentListViews = []
      state.isLoadingListViews = true
      state.activeListViewId = null
    },
    resetRejectedList: (state) => {
      state.isRejected = false
    },
    setActiveListViewId: (state, action) => {
      state.activeListViewId = action.payload
    },
    updateCurrentListDetails: (state, action) => {
      state.currentListDetails = action.payload
      state.currentListId = action?.payload?.id
      state.lists = [...state.lists, { ...action.payload }]
    },
  },
})

export const { resetRejectedList, clearLists, clearCurrentList, setActiveListViewId, updateCurrentListDetails } =
  listSlice.actions

export default listSlice.reducer
