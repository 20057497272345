import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  numberFieldValidationSchema,
  textFieldValidationSchema,
} from '../forms/validationSchemas/singleFieldsValidationSchemas'
import { withContentEditable, withKeyboardNavigation } from '../../hocs'
import { Dropdown, Input, RecordDatePicker } from '../inputs'
import { formattedOptionsList } from '../../utils/formatData/documentation'
import { ListType, RecordType, StationType } from '../../utils/types'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'
import DefaultNotEditingComponent from '../inputs/DefaultNotEditingComponent'
import { CustomFieldType } from '../../utils/types/CustomFieldsType'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import LinkToRecords from './LinkType/LinkToRecords'
import RecordFormula from './Formula/RecordFormula'
import CustomFieldCheckBox from './CustomFieldCheckBox'
import CustomFieldAggregateRecordValue from './CustomFieldAggregation/CustomFieldAggregateRecordValue'

const ContentEditableInput = withContentEditable(Input)
const ContentEditableDropdown = withKeyboardNavigation(withContentEditable(Dropdown))

interface RecordCustomFieldsEditableType {
  customFieldInfo: CustomFieldType;
  customFieldValue: string | number;
  isEditor: boolean;
  currentItem: RecordType | ListType | StationType;
  onUpdate: (key: string, type: number, value: string | number | null | undefined) => void;
}

const RecordCustomFieldsEditable: FC<RecordCustomFieldsEditableType> = ({
  customFieldInfo,
  customFieldValue,
  onUpdate,
  isEditor,
  currentItem,
}) => {
  const { t, i18n } = useTranslation('customFields')
  const isRtl = i18n.language === 'ar'
  const nameRef = useRef<any>(null)

  const fieldCurrentValue =
    customFieldInfo.baseType === customFieldBaseTypes.Date && !customFieldValue
      ? undefined
      : customFieldValue === undefined || (!customFieldValue && customFieldInfo.baseType !== customFieldBaseTypes.Date)
      ? null
      : customFieldInfo.baseType === customFieldBaseTypes.DropDown
      ? customFieldValue?.customFieldOptionId
      : customFieldInfo.baseType === customFieldBaseTypes.Date
      ? new Date(customFieldValue?.value)
      : customFieldValue?.value
  const [fieldValue, setFieldValue] = useState<any>(fieldCurrentValue)

  useEffect(() => {
    if (fieldValue !== fieldCurrentValue && customFieldInfo.baseType !== customFieldBaseTypes.Date)
      setFieldValue(fieldCurrentValue)
  }, [fieldCurrentValue])
  const isTextFieldUpdated = !customFieldValue || fieldValue !== customFieldValue?.value
  const isNumberFieldUpdated = !customFieldValue || +fieldValue !== +customFieldValue?.value
  const customFieldOptions = customFieldInfo?.customFieldOptions
    ? [{ label: '', value: null }, ...customFieldInfo?.customFieldOptions]
    : [{ label: '', value: null }]

  return (
    <>
      {customFieldInfo.baseType === customFieldBaseTypes.Text && (
        <ContentEditableInput
          key={`${customFieldInfo}_${isRtl}`}
          className="text-sm"
          defaultValue={''}
          inputWrapperClassName="-ms-2 -mb-2 text-sm"
          isOnClickDisabled={!isEditor}
          name={`fieldName${customFieldInfo.id}`}
          notEditingClassName="text-start text-sm"
          placeholder={t('labels.customFieldTextPlaceholder')}
          ref={nameRef}
          shouldShowPlaceholder
          validationSchema={textFieldValidationSchema('name', 100)}
          value={fieldValue || ''}
          onChange={(e) => {
            setFieldValue(e.target.value)
          }}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter' && isTextFieldUpdated) {
              nameRef?.current?.submitField()
              onUpdate(customFieldInfo.id, customFieldBaseTypes.Text, fieldValue)
            }
          }}
          onSubmit={(values, actions) => {
            if (isTextFieldUpdated) {
              setFieldValue(fieldValue)
              onUpdate(customFieldInfo.id, customFieldBaseTypes.Text, fieldValue)
            } else {
              actions.resetForm()
            }
          }}
        />
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.Number && (
        <ContentEditableInput
          key={`${customFieldInfo}_${isRtl}`}
          className="text-sm"
          defaultValue={''}
          inputWrapperClassName="-ms-2 -mb-2 text-sm"
          isOnClickDisabled={!isEditor}
          name={`fieldName${customFieldInfo.id}`}
          notEditingClassName="text-start text-sm"
          placeholder={t('labels.customFieldNumberPlaceholder')}
          ref={nameRef}
          shouldShowPlaceholder
          type={'number'}
          validationSchema={numberFieldValidationSchema()}
          value={fieldValue}
          onChange={(e) => {
            setFieldValue(e.target.value)
          }}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter' && isNumberFieldUpdated) {
              nameRef?.current?.submitField()
              onUpdate(customFieldInfo.id, customFieldBaseTypes.Number, fieldValue)
            }
          }}
          onSubmit={(values, actions) => {
            if (isNumberFieldUpdated) {
              setFieldValue(fieldValue)
              onUpdate(customFieldInfo.id, customFieldBaseTypes.Number, fieldValue)
            } else {
              actions.resetForm()
            }
          }}
        />
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.DropDown && (
        <ContentEditableDropdown
          key={`${customFieldInfo}_${isRtl}`}
          className="-ms-2 -mb-2 text-sm"
          components={{ Option: ColoredDropdownOption }}
          isClearable
          isOnClickDisabled={!isEditor}
          isSearchable={false}
          name={`select`}
          options={formattedOptionsList(customFieldOptions)}
          renderNotEditingState={(value: any, { showInput }: { showInput: () => void }) => {
            const option = formattedOptionsList(customFieldOptions).find((op) => op.id === value)
            return (
              <DefaultNotEditingComponent
                className={'text-sm'}
                disableHover
                name="customDropDown"
                placeholder={t('labels.customFieldSelectPlaceholder')}
                shouldShowPlaceholder={true}
                showInput={showInput}
                style={option ? { backgroundColor: `${option.color}20`, color: option.color } : {}}
                value={value === null ? null : option?.label}
              />
            )
          }}
          shouldShowPlaceholder={true}
          value={fieldValue || null}
          onSubmit={(values: any) => {
            const itemValue = values.select === 'null' || values.select === 'undefined' ? null : values.select
            setFieldValue(itemValue)
            onUpdate(customFieldInfo.id, customFieldBaseTypes.DropDown, itemValue)
          }}
        />
      )}
      {(+customFieldInfo.baseType === +customFieldBaseTypes.Link ||
        +customFieldInfo.baseType === +customFieldBaseTypes.SingleLink) && (
        <LinkToRecords
          key={`${customFieldInfo}_${isRtl}`}
          className="px-2 -ms-2 -mb-2"
          currentRecord={currentItem}
          customFieldInfo={customFieldInfo}
          isEditingAllowed={isEditor}
          isMulti={customFieldInfo.baseType === customFieldBaseTypes.Link}
        />
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.Date && (
        <div className="text-sm">
          <RecordDatePicker
            currentItem={currentItem}
            date={fieldValue || undefined}
            dateToCompare={undefined}
            handleRecordChange={(fieldValue) => {
              if (fieldValue.startDate !== undefined) {
                setFieldValue(fieldValue.startDate)
                onUpdate(customFieldInfo.id, customFieldBaseTypes.Date, fieldValue?.startDate)
              }
            }}
            isDisabled={!isEditor}
            name="startDate"
            shouldShowPlaceholder
          />
        </div>
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.Formula && (
        <div className="text-sm">
          <RecordFormula
            currentItem={currentItem}
            customFieldInfo={customFieldInfo}
            isEditingAllowed={isEditor}
            name="formula"
            onUpdate={(formulaOptionId: string) => {
              setFieldValue(formulaOptionId)
              onUpdate(customFieldInfo.id, customFieldBaseTypes.Formula, formulaOptionId)
            }}
          />
        </div>
        // <DateCustomField
        //   key={`${customFieldInfo}_${isRtl}`}
        //   className="-m-2 text-sm"
        //   currentRecord={currentRecord}
        //   customFieldInfo={customFieldInfo}
        //   isClearable
        //   isOnClickDisabled={!isEditor}
        //   listId={isTargetList ? customFieldInfo?.elementId : customFieldInfo?.intermediateElementId}
        //   value={fieldValue || null}
        //   onSubmit={(linkedData: any[]) => {
        //     onUpdate(customFieldInfo.id, 3, linkedData)
        //   }}
        // />
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.CheckBox && (
        <CustomFieldCheckBox
          currentItem={currentItem}
          customFieldInfo={customFieldInfo}
          isEditingAllowed={isEditor}
          name="checkbox"
          onUpdate={onUpdate}
        />
      )}
      {customFieldInfo.baseType === customFieldBaseTypes.Aggregate && (
        <CustomFieldAggregateRecordValue currentItem={currentItem} customFieldInfo={customFieldInfo} />
      )}
    </>
  )
}

export default RecordCustomFieldsEditable
