import React, { FC, useCallback } from 'react'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import withScrolling from 'react-dnd-scrolling'
import { CustomFieldOptionsType, CustomFieldOptionType } from '../../../utils/types/CustomFieldsType'
import OptionItem from './OptionItem'

const ScrollingComponent = withScrolling('div')

const OptionsList: FC<CustomFieldOptionsType> = ({
  optionsList,
  setOptionsList,
  onDeleteOption,
  showDescription,
  addText,
}) => {
  const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints
  const newOptionsList = [...optionsList]

  const moveOption = useCallback(
    (item, hoverIndex, optionsList) => {
      const updatedList = [...optionsList].filter((i: any) => i.optionOrder !== item.optionOrder)
      updatedList.splice(hoverIndex, 0, item)
      const newUpdatedList = updatedList.map((i, index) => ({ ...i, optionOrder: index }))
      setOptionsList(newUpdatedList)
    },
    [optionsList],
  )

  const renderOption = useCallback((option: any, index: number, optionsList: CustomFieldOptionType[]) => {
    return (
      <OptionItem
        key={`${index}_${option.optionCaption}_${option.id}`}
        index={index}
        item={option}
        addText={addText}
        moveOption={moveOption}
        optionsList={optionsList}
        setOptionsList={setOptionsList}
        showDescription={showDescription}
        onDeleteOption={onDeleteOption}
      />
    )
  }, [])

  return (
    <DndProvider backend={supportsTouch ? TouchBackend : HTML5Backend}>
      <ScrollingComponent className="min-h-0 overflow-y-auto">
        {newOptionsList?.map((option, index) => {
          return renderOption(option, index, optionsList)
        })}
      </ScrollingComponent>
    </DndProvider>
  )
}

export default OptionsList
