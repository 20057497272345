import i18n, { switchLanguage } from 'i18n'
import { format } from 'date-fns'
import classnames from 'classnames'

import store from 'store'
import { DropdownOptionType } from 'utils/types/inputs'
import { DATE_FORMAT } from 'utils/helpers/generalHelpers'
import { updateUserLanguage } from 'features/user/actions'
import DefaultNotEditingComponent from 'components/inputs/DefaultNotEditingComponent'
import baseAPI from '../baseAPI'
import ColoredDropdownOption from '../../components/inputs/LabelsSelect/ColoredDropdownOption'

const getCountryOptions = async (): Promise<DropdownOptionType[]> => {
  const results = await baseAPI('api/countries', {
    method: 'GET',
  })
  const countries = results.body?.data
  return countries.map(({ name, numericCode }: { name: string, numericCode: number }) => ({
    label: name,
    value: numericCode,
  }))
}

const getGenderOptions = (): DropdownOptionType[] => [
  { label: i18n.t('user:gender.male'), value: 0 },
  { label: i18n.t('user:gender.female'), value: 1 },
]

const getOccupationOptions = (): DropdownOptionType[] => [
  { label: i18n.t('user:occupation.freelancer'), value: 0 },
  { label: i18n.t('user:occupation.professional'), value: 1 },
  { label: i18n.t('user:occupation.supervisor'), value: 2 },
  { label: i18n.t('user:occupation.manager'), value: 3 },
  { label: i18n.t('user:occupation.seniorManager'), value: 4 },
  { label: i18n.t('user:occupation.consultant'), value: 5 },
  { label: i18n.t('user:occupation.student'), value: 6 },
  { label: i18n.t('user:occupation.trainee'), value: 7 },
  { label: i18n.t('user:occupation.other'), value: 8 },
]

const getCompanySizeOptions = (): DropdownOptionType[] => [
  { label: i18n.t('user:companySize.selfEmployed'), value: 0 },
  { label: i18n.t('user:companySize.1-5Employees'), value: 1 },
  { label: i18n.t('user:companySize.6-49Employees'), value: 2 },
  { label: i18n.t('user:companySize.50-249Employees'), value: 3 },
  { label: i18n.t('user:companySize.250-1000Employees'), value: 4 },
  { label: i18n.t('user:companySize.1000+Employees'), value: 5 },
  { label: i18n.t('user:companySize.other'), value: 6 },
]
const getCalendarOptions = (): DropdownOptionType[] => [
  { label: i18n.t('user:calendarType.gregorian'), value: 0 },
  { label: i18n.t('user:calendarType.hijri'), value: 1 },
]

const renderBirthDate = (selectedDate: any, { showInput, name }: { showInput: () => void, name: string }) => {
  return (
    <DefaultNotEditingComponent
      className="mb-8 bg-gray-100"
      label={i18n.t('labels.dateOfBirth')}
      name={name}
      shouldShowPlaceholder={false}
      showInput={showInput}
      style={{ borderColor: '#DDE5ED' }}
      value={selectedDate && `${format(new Date(selectedDate), DATE_FORMAT)}`}
    />
  )
}

const renderDropdownValue = ({
  selectedOption,
  props: { showInput, name },
  label,
  color,
  className,
  allOptions,
  placeholder,
}: {
  selectedOption: any | null,
  props: { showInput: () => void, name: string },
  label: string,
  color: string,
  className: any,
  allOptions: DropdownOptionType[],
  placeholder: string,
}) => {
  const option = allOptions.find(({ value }) => value === selectedOption)
  return (
    <DefaultNotEditingComponent
      className={className ? className : 'mb-8 bg-gray-100'}
      components={{ Option: ColoredDropdownOption }}
      isCustomField={true}
      label={label}
      name={name}
      placeholder={placeholder}
      shouldShowPlaceholder={true}
      showInput={showInput}
      style={
        !className
          ? { borderColor: '#DDE5ED' }
          : option && option?.color
          ? { backgroundColor: `${color}20`, color: color }
          : {}
      }
      value={option === undefined ? null : option.label}
    />
  )
}

const renderOccupation = (
  selectedOccupation: any,
  { showInput, name }: { showInput: () => void, name: string },
  showOccupationInput: boolean,
) => {
  const occupation = getOccupationOptions().find(({ value }) => value === selectedOccupation)?.label
  return (
    <DefaultNotEditingComponent
      className={classnames('bg-gray-100', { 'mb-2': showOccupationInput, 'mb-8': !showOccupationInput })}
      label={i18n.t('labels.occupation')}
      name={name}
      shouldShowPlaceholder={true}
      showInput={showInput}
      style={{ borderColor: '#DDE5ED' }}
      value={occupation}
    />
  )
}

const changeUserLanguage = () => {
  const newLanguage = i18n.language === 'ar' ? 'en' : 'ar'
  switchLanguage(newLanguage)
  store.dispatch(updateUserLanguage(newLanguage))
}

export {
  getGenderOptions,
  getOccupationOptions,
  renderBirthDate,
  renderDropdownValue,
  getCountryOptions,
  renderOccupation,
  getCompanySizeOptions,
  getCalendarOptions,
  changeUserLanguage,
}
