import React, { FC, useState, useRef, useCallback, useEffect, CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import ReactToPrint from 'react-to-print'
import { AddIcon, ExportIcon, PencilIcon, TrashIcon } from 'components/Icons'
import { useCurrentUserPermissions } from 'features/user'
import RolesEnum from 'utils/constant/enums'
import DashboardEditHeader from 'components/Dashboard/DashboardEditHeader'
import { useDispatch } from 'react-redux'
import useElementSize from 'hooks/useElementSize'
import { Layout } from 'react-grid-layout'
import DashboardAvailableWidgets from 'utils/types/states/DashboardAvailableWidgets'
import { DashboardGetWidgetDataType, DashboardParameterType } from 'utils/types/states/DashboardGetWidgetDataType'
import DashboardMainPropsType from 'utils/types/states/DashboardMainPropsType'
import Button, { ButtonVariant } from 'components/Buttons'
import ListToolbar from 'components/ListToolbar'
import { ControlProps, IndicatorProps, Styles } from 'react-select'
import Header from '../Header'
import { useNavbar } from '../../contexts/NavbarContext'
import { clearStationDashboardPage } from '../../features/station/stationSlice'
import Loader from '../Loader'
import { Dropdown } from '../inputs'
import { baseDropdownStyles } from '../inputs/Dropdown'
import ConfirmationModal from '../ConfirmationModal'
import { DropdownOptionType } from '../../utils/types/inputs'
import Dashboard from './Dashboard'
import CreateDashboardModal from './CreateDashboardModal'

export const smallBaseDropdownStyles: Partial<Styles<DropdownOptionType, false>> = {
  control: (_: CSSProperties, state: ControlProps<DropdownOptionType, boolean>) => ({
    backgroundColor: state.isFocused ? '#EEEEEE' : '#FFFFFF',
    borderRadius: '4px',
    boxShadow: state.isFocused ? '0px 1px 2px #EEEEEE' : 'none',
    display: 'flex',
  }),
  dropdownIndicator: (base: CSSProperties, state: IndicatorProps<DropdownOptionType, boolean>) => ({
    ...base,
    display: state.isFocused ? 'block' : 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (base: CSSProperties) => ({
    ...base,
    backgroundColor: '#EEEEEE',
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    color: '#33647E',
  }),
}

const DashboardMain: FC<DashboardMainPropsType> = ({
  showBack,
  currentItem,
  dashboard,
  availableWidgets,
  getAvailableWidgets,
  saveCurrentItemDashboard,
  removeWidgetFromDashboard,
  addWidgetToDashboard,
  getCurrentItemDashboard,
  hideName,
  dashboards,
  updateTextWidget,
  elementType,
  saveDashboardLayoutResizeChanges,
  saveDashboardLayoutDragChanges,
  updateWidgetTitle,
  currentStation,
  currentWorkspace,
  dashboardLoading,
  onUpdateWidgetImage,
  onCreateDashboard,
  onRemoveDashboard,
  onChangeDashboardId,
}) => {
  const { t } = useTranslation('dashboard')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isDeletingModalOpen, setIsDeletingModalOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const permission = useCurrentUserPermissions()
  const [isEdit, setIsEdit] = useState(false)
  const ref = useRef(null)
  const componentRef = useRef(null)
  const { width: dashboardWidth } = useElementSize(ref)
  const { toggleMenu } = useNavbar()
  const [isPrinting, setPrinting] = useState(false)
  const options = dashboards?.map((d) => ({ label: d.title || t('defaultDashboard'), value: +d.id }))
  const selectedOption = options?.find((d) => +d?.value === +dashboard?.id)

  const onOpenCreateDashboard = () => {
    setIsModalOpen(true)
  }

  const addWidget = useCallback(
    (widget: DashboardAvailableWidgets, parameters: DashboardParameterType[]) => {
      if (currentItem) {
        const params: DashboardGetWidgetDataType = {
          baseType: widget.baseType,
          elementId: currentItem.id,
          elementType: elementType,
          parameters,
        }
        dispatch(addWidgetToDashboard(params))
      }
    },
    [dispatch, addWidgetToDashboard, currentItem],
  )

  const editAndRefresh = useCallback(() => {
    if (isEdit) {
      if (dashboards) {
        dispatch(getCurrentItemDashboard(dashboards[0].id))
      }
    }
    setIsEdit(!isEdit)
  }, [isEdit, dashboards])

  const removeWidget = useCallback(
    (id: number) => {
      dispatch(removeWidgetFromDashboard(id))
    },
    [dispatch, removeWidgetFromDashboard],
  )

  const saveChanges = () => {
    if (dashboard) {
      dispatch(saveCurrentItemDashboard(dashboard))
      setIsEdit(!isEdit)
    }
  }

  const saveLayoutResizeChanges = useCallback(
    (layout: Layout[], oldItem: Layout, newItem: Layout, ...rest: any) => {
      dispatch(saveDashboardLayoutResizeChanges(newItem))
    },
    [dispatch, saveDashboardLayoutResizeChanges],
  )

  const saveLayoutDragChanges = useCallback(
    (layout: Layout[], oldItem: Layout, newItem: Layout, ...rest: any) => {
      dispatch(saveDashboardLayoutDragChanges({ layout, newItem }))
    },
    [dispatch, saveDashboardLayoutDragChanges],
  )

  const onChangeDashboard = (id: string | number) => {
    dispatch(onChangeDashboardId(id))
  }

  const handleOnBeforeGetContent = () => {
    return new Promise((resolve) => {
      setPrinting(true)
      setTimeout(() => {
        resolve(undefined)
      }, 1000)
    })
  }

  const handleOnAfterPrint = () => {
    setPrinting(false)
  }

  const exportPdfButton = (isWorkspace = false) => {
    const buttonContent = isPrinting ? <Loader /> : t('exportToPdf')
    if (isWorkspace) {
      return (
        <ReactToPrint
          trigger={() => (
            <Button className="shrink-0 sm:mb-1" icon={ExportIcon} variant={ButtonVariant.Outline}>
              {buttonContent}
            </Button>
          )}
          content={() => componentRef.current}
          onBeforeGetContent={handleOnBeforeGetContent}
          onAfterPrint={handleOnAfterPrint}
          documentTitle={selectedOption?.label}
        />
      )
    }
    return (
      <ReactToPrint
        trigger={() => (
          <Button className="ms-1" icon={ExportIcon} small variant={ButtonVariant.Outline}>
            {buttonContent}
          </Button>
        )}
        content={() => componentRef.current}
        onBeforeGetContent={handleOnBeforeGetContent}
        onAfterPrint={handleOnAfterPrint}
        documentTitle={selectedOption?.label}
      />
    )
  }

  const renderToolbarContent = (isAdmin: boolean) => (
    <div className="flex items-center justify-between w-full">
      <div>
        <Dropdown
          isSmall={true}
          name={'dashboard'}
          options={options}
          styles={smallBaseDropdownStyles}
          value={selectedOption?.value}
          onChange={(e) => onChangeDashboard(e.target.value)}
        />
      </div>
      <div className="flex">
        {isAdmin && (
          <>
            <Button icon={AddIcon} small variant={ButtonVariant.Outline} onClick={onOpenCreateDashboard}>
              {t('createDashboard')}
            </Button>
            <Button className="mx-1" icon={PencilIcon} small variant={ButtonVariant.Outline} onClick={editAndRefresh}>
              {t('editWidget')}
            </Button>
            {options?.length > 1 && (
              <Button
                icon={TrashIcon}
                small
                variant={ButtonVariant.Outline}
                onClick={() => setIsDeletingModalOpen(true)}>
                {t('deleteDashboard')}
              </Button>
            )}
          </>
        )}
        {exportPdfButton(false)}
      </div>
    </div>
  )

  return (
    <>
      {!isEdit ? (
        elementType === 's' ? (
          <ListToolbar>{renderToolbarContent(permission >= RolesEnum.Admin)}</ListToolbar>
        ) : (
          <div className="lg:px-2">
            <Header
              buttonTitle={t('editWidget')}
              buttonTitle2={t('createDashboard')}
              currentItemName={currentItem?.name}
              hideName={hideName}
              icon={PencilIcon}
              icon2={AddIcon}
              needPermission={true}
              options={options}
              selectedOption={selectedOption}
              permission={permission >= RolesEnum.Admin}
              showBack={showBack}
              showDeleteDashboard={permission >= RolesEnum.Admin && dashboards?.length > 1 && onRemoveDashboard}
              title={t('dashboardTitle')}
              toggleMenu={toggleMenu}
              onChangeDashboard={onChangeDashboard}
              onClick={editAndRefresh}
              onClick2={onOpenCreateDashboard}
              onRemoveDashboard={() => setIsDeletingModalOpen(true)}
              exportPdfButton={exportPdfButton}
            />
          </div>
        )
      ) : (
        dashboard && (
          <DashboardEditHeader
            addWidget={addWidget}
            availableWidgets={availableWidgets}
            closeEdit={editAndRefresh}
            currentStation={currentStation}
            currentWorkspace={currentWorkspace}
            dashboard={dashboard}
            dashboardWidgets={dashboard.widgets}
            elementType={elementType}
            getAvailableWidgets={getAvailableWidgets}
            saveChanges={saveChanges}
          />
        )
      )}
      <div className={classnames('min-h-fit')} ref={ref}>
        {/*{dashboardLoading ? (*/}
        {/*  <Loader loaderClasses="w-full flex items-center justify-center h-full p-2" svgClasses="w-12" />*/}
        {/*) : (*/}
        <div id={'dashboard-container'} ref={componentRef}>
          <Dashboard
            addWidget={addWidget}
            closeEdit={editAndRefresh}
            dashboardData={dashboard}
            dashboardWidth={isPrinting ? 1122 : dashboardWidth}
            elementId={currentItem?.appElement?.id}
            isEdit={isEdit}
            removeWidget={removeWidget}
            saveLayoutDragChanges={saveLayoutDragChanges}
            saveLayoutResizeChanges={saveLayoutResizeChanges}
            updateTextWidget={updateTextWidget}
            updateWidgetTitle={updateWidgetTitle}
            onUpdateWidgetImage={onUpdateWidgetImage}
            isPrinting={isPrinting}
          />
        </div>
        {/*)}*/}
      </div>
      <CreateDashboardModal
        appElementId={+currentItem?.appElement?.id}
        isModalOpen={isModalOpen}
        scope={elementType}
        onCancel={() => setIsModalOpen(false)}
        onCreateDashboard={onCreateDashboard}
      />
      <ConfirmationModal
        confirmationMessage={t('confirmDeleteDashboard', {
          name: selectedOption?.label,
        })}
        isModalOpen={isDeletingModalOpen}
        onCancel={() => setIsDeletingModalOpen(false)}
        onConfirm={() => {
          onRemoveDashboard(selectedOption?.value)
          setIsDeletingModalOpen(false)
        }}
      />
    </>
  )
}

export default DashboardMain
