import React, { createRef, forwardRef, useImperativeHandle, useRef } from 'react'
import classnames from 'classnames'

import { InputRefType, ErrorMessageRefType, ErrorMessageType, InputPropsType } from 'utils/types/inputs'

import InputErrorMessage from 'components/inputs/InputErrorMessage'

const Checkbox = forwardRef<InputRefType, InputPropsType & ErrorMessageType & { isLabelComponent?: boolean }>(
  (
    {
      isFullRoundedBorder,
      name,
      label,
      onChange,
      classes,
      error,
      touched,
      isInlineEditable,
      isLabelComponent,
      ...rest
    },
    ref,
  ) => {
    const errorRef = createRef<ErrorMessageRefType>()
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        blur: () => inputRef.current?.blur(),
        focus: () => inputRef.current?.focus(),
        shakeError: () => errorRef.current?.shakeError(),
      }),
      [errorRef, inputRef],
    )

    return (
      <div className={classnames(classes.wrapper)}>
        <input
          className={classnames('checkbox', classes.input)}
          id={`input-${name}`}
          name={name}
          ref={inputRef}
          onChange={onChange}
          {...rest}
          type="checkbox"
        />
        {label && isLabelComponent ? (
          <label
            className={classnames('checkbox__label', classes.label)}
            dangerouslySetInnerHTML={{ __html: label }}
            htmlFor={`input-${name}`}
          />
        ) : (
          <label className={classnames('checkbox__label', classes.label)} htmlFor={`input-${name}`}>
            {label}
          </label>
        )}
        <InputErrorMessage
          data-testId={`${rest['data-testid'] || name}-error`}
          error={error}
          isInlineEditable={isInlineEditable}
          ref={errorRef}
          touched={touched}
        />
      </div>
    )
  },
)
Checkbox.displayName = 'Checkbox'

Checkbox.defaultProps = {
  classes: {},
}

export default Checkbox
