import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

import KanbanColumnItemPlaceholder from 'components/Kanban/KanbanPlaceholder/KanbanColumnItemPlaceholder'
import placeholderConfig from './placeholderConfig.json'

const KanbanColumnPlaceholder: FC<{ count: number }> = ({ count }) => (
  <div className="flex flex-col shrink-0 h-full px-3 bg-gray-100 rounded-b-lg w-72 me-3 py-1.5 rounded-te-lg rtl-mirror">
    <ContentLoader {...placeholderConfig} className="mb-1.5" viewBox="0 0 260 24">
      <rect height="9" width="87" y="8" />
      <rect height="24" rx="4" width="24" x="204" />
      <rect height="2" width="2" x="246" y="12" />
      <rect height="2" width="2" x="252" y="12" />
      <rect height="2" width="2" x="258" y="12" />
    </ContentLoader>
    {Array.from(Array(count)).map((_, index) => (
      <KanbanColumnItemPlaceholder key={index} />
    ))}
    <ContentLoader {...placeholderConfig} viewBox="0 0 220 18">
      <path
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm14 2H2v14h14V2Z"
        fillRule="evenodd"
      />
      <path clipRule="evenodd" d="M10 4H8v4H4v2h4v4h2v-4h4V8h-4V4Z" fillRule="evenodd" />
      <path d="M29 4h54v10H29z" />
    </ContentLoader>
  </div>
)

export default KanbanColumnPlaceholder
