import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from 'components/Buttons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { CheckIcon, InfoIcon, ProIcon } from '../../components/Icons'
import { basicFeatures, options, proFeatures } from '../../utils/constant/constant/subscription'
import {
  chooseWorkspaceTrailProPlan,
  fetchWorkspaceById,
  useCurrentWorkspace,
  useCurrentWorkspaceChosenPlanIsBasic,
} from '../../features/workspace'
import { setChosenPlan } from '../../features/workspace/workspaceSlice'
import { useAppDispatch } from '../../store'
import { Tooltip } from '../../utils/helpers/generalHelpers'

// interface PlansListPropsType {}

const PlansList = () => {
  const { t, i18n } = useTranslation('workspace')
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const workspace = useCurrentWorkspace()
  const isBasicPlan = useCurrentWorkspaceChosenPlanIsBasic()
  const [dropdownValue, setDropdownValue] = useState(1)
  const currentOption = options.find((op) => +op.value === +dropdownValue)
  // const isUserUsedTrail = workspace?.owner?.usedTrial === true
  const isUserUsedTrail = false
  const isRtl = i18n.language === 'ar'

  const onChoosePlan = (plan: any) => {
    if (plan?.type === 'pro') {
      history.push(`/workspace/${plan.workspaceId}/pro-trial`)
    } else {
      dispatch(setChosenPlan(plan))
    }
  }

  const getPlanButtonContent = (type: string) => {
    switch (type) {
      case 'basic':
        if (isBasicPlan) return t('subscription:currentPlan')
        return t('subscription:choosePlan')

      case 'pro':
        if (isUserUsedTrail) {
          if (isBasicPlan) return t('subscription:trialAlreadyUsed')
          return t('subscription:currentPlan')
        } else {
          return t('subscription:startFreeTrial')
        }
    }
  }

  const renderPlansList = (list: string[], type: string) => {
    const isDisabled = type === 'basic' ? isBasicPlan : !isBasicPlan
    return (
      <div className="w-full pt-6 ">
        <div className="h-72 ps-6">
          {type === 'pro' && (
            <div className="flex py-1.5">
              <CheckIcon className={'w-100 me-2'} />
              <p className="flex font-semibold">{t('subscription:features.allFreeFeaturesAndMore')}</p>
            </div>
          )}
          {list.map((title, i) => {
            return (
              <div key={i} className="flex py-1.5">
                <CheckIcon className={'w-100'} />
                <p className="ms-2">{t(title)}</p>
              </div>
            )
          })}
        </div>
        <div className="flex justify-center w-full mt-10">
          <div className={`${isBasicPlan && type === 'pro' ? 'w-11/12 xs:mt-6' : 'w-full'}`}>
            <Button
              disabled={isDisabled}
              full
              variant={isDisabled ? ButtonVariant.Disabled : ButtonVariant.Primary}
              onClick={() => {
                const currentPlanDetails = workspace?.plan
                const currentPlan = {
                  ...currentPlanDetails,
                  status: isBasicPlan ? 'upgrade' : 'downgrade',
                  title: isBasicPlan ? t('subscription:basic') : t('subscription:pro'),
                  type: type,
                  workspaceId: workspace?.id,
                }
                onChoosePlan(currentPlan)
              }}>
              {getPlanButtonContent(type)}
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const plansListData = [
    { features: basicFeatures, storage: '200MB', title: t('subscription:basic'), type: 'basic' },
    { features: proFeatures, storage: '2GB', title: t('subscription:pro'), type: 'pro' },
  ]
  return (
    <>
      <p className="mt-6 mb-3 font-extrabold">{t('subscription:chooseAPlan')}</p>
      <div className="justify-between lg:flex md:flex">
        {plansListData.map((plan, index) => {
          return (
            <div key={index} className="mt-3 md:w-1/2 lg:w-1/2">
              <div className={`p-5 border rounded ${index !== 0 ? 'ms-5' : ''}`}>
                <div className="pb-5 mx-6 mb-3 border-b">
                  <h3 className="flex font-semibold">
                    {plan.title}
                    {plan.type === 'pro' && <ProIcon className="ms-2" />}
                  </h3>
                  {/*<p className="my-3 text-lg font-bold">{plan.storage}</p>*/}
                </div>
                {renderPlansList(plan.features, plan.type)}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PlansList
