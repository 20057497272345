import React, { FC, memo, useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useSize } from 'react-use'
import { useParams } from 'react-router-dom'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { DashboardStateWidgetType } from 'utils/types/states'
import { setColorByStatus } from 'utils/types/widgetUtils'
import { stationStatuses } from 'utils/constant/resources/stationResorces'
import { getElementLinkFromPath, getFormattedCompletion } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import CustomScroll from '../../../CustomScroll'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import ListStatsWidgetSlide from './ListStatsWidgetSlide'

const setSlidesPerView = (width: number) => {
  if (width < 400) {
    return 1
  }

  if (width < 500) {
    return 2
  }

  if (width > 1500) {
    return 8
  }

  if (width > 1300) {
    return 6
  }

  if (width > 900) {
    return 5
  }

  if (width > 720) {
    return 4
  }

  return 3
}

const arrowStyle = {
  background: 'white',
  color: 'white',
  display: 'block',
  minWidth: 24,
  position: 'absolute',
  top: 'calc(50% - 10px)',
  width: 24,
}

const iconStyles: any = {
  color: 'black',
  marginTop: -20,
  position: 'relative',
  zIndex: 999,
}

const PrevArrow: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className="prev"
      style={{
        ...style,
        ...arrowStyle,
        left: '-10px',
      }}
      onClick={onClick}>
      <ChevronLeftIcon
        style={{
          ...iconStyles,
        }}
      />
    </div>
  )
}

const NextArrow: FC<any> = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      style={{
        ...style,
        ...arrowStyle,

        right: '-10px', // isRtl ? 0 : 'unset',
      }}
      onClick={onClick}>
      <ChevronRightIcon
        style={{
          ...iconStyles,
        }}
      />
    </div>
  )
}

const ListSwiper: FC<{ rtl: boolean, datasets: any, parentWidth: number }> = ({ rtl, datasets, parentWidth }) => {
  const { t } = useTranslation()
  const slickRef = useRef<any>(null)
  const { workspaceId, stationId } = useParams<{
    workspaceId: string,
    stationId: string,
  }>()

  useEffect(() => {
    if (slickRef && slickRef.current) {
      if (rtl && datasets) {
        slickRef.current.slickGoTo(datasets?.length - 3)
      } else {
        slickRef.current.slickGoTo(0)
      }
    }
  }, [rtl, datasets])

  const plugins = (data: number) => {
    return [
      {
        beforeDraw: function (chart: any) {
          const width = chart.width
          const height = chart.height
          const ctx = chart.ctx
          ctx.restore()
          const fontSize = '20'
          ctx.font = '600 ' + fontSize + 'px Lato'
          ctx.textBaseline = 'top'
          const text = data
          const textX = Math.round((width - ctx.measureText(text).width) / 2)
          const textY = height / 2 - 14
          ctx.fillText(text, textX, textY)
          const fontCompletion = '14'
          const fontFaceCompletion = rtl ? 'Almarai' : 'Lato'
          ctx.font = fontCompletion + 'px ' + fontFaceCompletion
          const textCompletion = t('records:records')
          const textYCompletion = height / 2 + 14
          const textXCompletion = Math.round((width - ctx.measureText(textCompletion).width) / 2)
          ctx.fillText(textCompletion, textXCompletion, textYCompletion)
          ctx.save()
        },
      },
    ]
  }

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: false,
    slidesToScroll: 1,
    slidesToShow: setSlidesPerView(parentWidth),
    speed: 500,
  }

  return (
    <>
      <Slider className="w-full" ref={slickRef} {...settings}>
        {datasets?.map((dataset: any, idx: number) => {
          const path = `${workspaceId}.${stationId}.${dataset.listId}.`
          const listLink = getElementLinkFromPath(path, AppElementBaseTypes.ListAppElement)

          return (
            <ListStatsWidgetSlide
              key={dataset.label + idx.toString() + dataset.completion.toString()}
              dataset={dataset}
              listLink={listLink}
              plugins={plugins(dataset.totalRecord)}
            />
          )
        })}
      </Slider>
    </>
  )
}

const ListsStatsWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  isPrinting: boolean,
}> = ({ widgetInfo, onUpdateWidgetImage, isEdit, isPrinting }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const isRtl = i18n.language === 'ar'
  const ref = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const [elementWidthValue, setElementWidthValue] = useState(0)
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  useEffect(() => {
    const eleWidth = ref?.current?.offsetWidth
    if (ref.current && eleWidth !== elementWidthValue) {
      setElementWidthValue(eleWidth)
    }
  }, [ref?.current?.offsetWidth])

  if (isDataLoading) {
    return <Loader />
  }

  const listStatsData: any = widget ? widget.data : widgetInfo.data

  const datasets = listStatsData?.values?.map((item: any) => {
    const items = item?.statuses?.reduce(
      (prev: any, curr: any) => ({
        ...prev,
        backgroundColor: [...prev.backgroundColor, setColorByStatus(curr.status, true).color],
        data: [...prev.data, curr.data],
      }),
      {
        backgroundColor: [],
        data: [],
      },
    )

    return {
      backgroundColor: items.backgroundColor,
      borderRadius: 20,
      completion: getFormattedCompletion(item.completion),
      cutout: '88%',
      data: items.data,
      label: item.label,
      listId: item.listId,
      totalRecord: item.totalRecord,
    }
  })

  return (
    <>
      {sized}
      <div ref={ref} style={{ height: 'calc(100% - 40px)' }}>
        <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
          {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
            <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          )}
          <div className="flex gap-5">
            {stationStatuses.map((s) => (
              <div key={s.value} className="flex items-center gap-2">
                <div
                  style={{
                    backgroundColor: setColorByStatus(s.value ? parseInt(s.value) : -1, true).color,
                    borderRadius: '100%',
                    height: 8,
                    minWidth: 8,
                    width: 8,
                  }}
                />
                <div>{t(`dashboard:${setColorByStatus(s.value ? parseInt(s.value) : -1, true).title}`)}</div>
              </div>
            ))}
          </div>

          <div className={`flex items-center justify-center `} style={{ height: '100%', width: '100%' }}>
            <ListSwiper
              key={isRtl + `${elementWidthValue}`}
              datasets={datasets}
              parentWidth={!elementWidthValue ? 0 : elementWidthValue}
              rtl={isRtl}
            />
          </div>
        </CustomScroll>
      </div>
    </>
  )
}

export default memo(ListsStatsWidget)
