import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Dashboard/BaseModal/BaseModal'
import Button, { ButtonVariant } from '../Buttons'
import { Input } from '../inputs'
import { createRecord } from '../../features/record'
import { useAppDispatch } from '../../store'
import { useCurrentList } from '../../features/list'

interface RecordDataProps {
  endDate: string;
  startDate: string;
  ownerId: string;
}

const CreateRecordModal: FC<{
  isOpen: boolean,
  recordData: RecordDataProps,
  setIsCreatRecord: (value: boolean) => void,
}> = ({ isOpen, recordData, setIsCreatRecord }) => {
  const appDispatch = useAppDispatch()
  const currentList = useCurrentList()
  const [name, setName] = useState<string>('')
  const { t } = useTranslation()

  const onCancel = () => {
    setIsCreatRecord(false)
  }

  function handleConfirm() {
    if (name.length > 0)
      appDispatch(createRecord({ ...recordData, name, parentId: +currentList?.appElement?.id })).then(() => {
        setName('')
        onCancel()
      })
  }

  return (
    <BaseModal
      close={onCancel}
      content={
        <>
          <h1 className="pb-3">{t('records:addRecord')}</h1>
          <Input
            autoFocus
            classes={{ wrapper: 'px-1 text-sm w-full lg:w-96' }}
            name="name"
            placeholder={t('records:listing.name')}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          <div className="flex justify-end mt-8 gap-4">
            <Button variant={ButtonVariant.Outline} onClick={onCancel}>
              {t('common:labels.cancel')}
            </Button>
            <Button variant={ButtonVariant.Primary} onClick={handleConfirm}>
              {t('dashboard:saveButton')}
            </Button>
          </div>
        </>
      }
      header={''}
      isModalOpen={isOpen}
      type="confirmation"
      withoutCloseBtn
    />
  )
}
export default CreateRecordModal
