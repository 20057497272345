import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronLeftIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { useDispatch } from 'react-redux'
import {
  chooseWorkspaceTrailProPlan,
  fetchWorkspaceById,
  useCurrentWorkspace,
  useCurrentWorkspaceChosenPlan,
} from '../../features/workspace'
import PaymentForm from '../forms/PaymentForm'
import { useAppDispatch } from '../../store'
import { clearChosenPlan } from '../../features/workspace/workspaceSlice'
import BillingCardInfo from './BillingCardInfo'

// interface PlanCheckoutPropsType {}
//TODO: check if plan has the same structure
//TODO: add translation
const PlanCheckout = () => {
  const { t } = useTranslation('access')
  const [showBillingForm, setShowBillingForm] = useState(false)
  const currentWorkspace = useCurrentWorkspace()
  const plan = useCurrentWorkspaceChosenPlan()
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()

  const onToggleBillingForm = (value: boolean) => {
    setShowBillingForm(value)
  }

  const onBack = () => {
    dispatch(clearChosenPlan())
  }

  const onCheckOut = () => {
    appDispatch(chooseWorkspaceTrailProPlan(plan.workspaceId)).then((response) => {
      if (response?.payload?.success) {
        dispatch(fetchWorkspaceById(plan.workspaceId))
      }
      onBack()
    })
  }

  return (
    <>
      <div className="flex justify-start mb-5 cursor-pointer" onClick={onBack}>
        <ChevronLeftIcon className="w-10 -mt-2" />
        <h2>{t('back')}</h2>
      </div>
      <div className="w-full p-6 bg-white md:p-8">
        <h1 className="mb-6 text-lg">{t('workspaceSettings:workspacePlanCheckout')}</h1>
        <li>
          You have selected the <span className="font-semibold">{plan.title}</span> plan for the{' '}
          <span className="font-semibold">{currentWorkspace?.title}</span> Workspace, billed{' '}
          <span className="font-semibold">{plan.type}</span> at <span className="font-semibold">{plan.price} </span> per
          user per <span className="font-semibold">{plan.billingCycle}</span>
        </li>
        <li>
          You will pay <span className="font-semibold">{plan.price}</span> per{' '}
          <span className="font-semibold">{plan.billingCycle}</span> as you have{' '}
          <span className="font-semibold">{currentWorkspace?.users?.length}</span> users in the Workspace
        </li>
        {/*{!showBillingForm && (*/}
        {/*  <div className="mx-3 my-5">*/}
        {/*    <BillingCardInfo />*/}
        {/*    <Button*/}
        {/*      variant={ButtonVariant.Outline}*/}
        {/*      onClick={() => {*/}
        {/*        onToggleBillingForm(true)*/}
        {/*      }}>*/}
        {/*      Change billing method*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{showBillingForm && (*/}
        {/*  <div className="mx-3 my-5">*/}
        {/*    <h3 className="my-5 font-semibold">Enter card information</h3>*/}
        {/*    <div className="mx-5 my-2">*/}
        {/*      <PaymentForm*/}
        {/*        onCancel={() => {*/}
        {/*          onToggleBillingForm(false)*/}
        {/*        }}*/}
        {/*        onSave={() => {*/}
        {/*          onToggleBillingForm(false)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="flex justify-end mt-5">
          <Button variant={ButtonVariant.Outline} onClick={onBack}>
            {t('cancel')}
          </Button>
          <Button className="ms-2" type="submit" onClick={onCheckOut}>
            {t('checkout')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default PlanCheckout
