import React, { FC } from 'react'
import UserAvatarStatus from './UserAvatarStatus'

type UserAvatarPropTypes = {
  imgSrc: string,
  imgAlt?: string,
  children?: React.ReactNode,
  userId: number,
}

const UserAvatar: FC<UserAvatarPropTypes> = ({ imgSrc, imgAlt, children, userId }) => {
  const UserAvatarStatusProps = {
    imgAlt,
    imgSrc,
    userId,
  }
  return (
    <div className="items-center p-2 -m-2 grid grid-cols-auto gap-x-2">
      <UserAvatarStatus {...UserAvatarStatusProps} />
      {children}
    </div>
  )
}

export default UserAvatar
