import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useParams } from 'react-router-dom'

import baseAPI from 'utils/baseAPI'
import { availableGroups } from 'utils/helpers/generalHelpers'

import UsersTablePropsType from 'utils/types/UsersTablePropsType'

import { Dropdown } from 'components/inputs'
import UserAvatar from 'components/UserAvatar'
import SettingsDropdown from 'components/SettingsDropdown'
import ConfirmationModal from 'components/ConfirmationModal'

import { useCurrentUser } from 'features/user'
import { withAdminRole } from 'hocs/withRole'
import { useRoles } from 'hooks'
import RolesEnum from 'utils/constant/enums'

interface UserListRowProps extends UsersTablePropsType {
  isOwner: boolean;
  onDelete: () => void;
  stationAccess: number[];
  hasMoreThanOneAdmin?: boolean;
  fetchUsers?: () => void;
  deleteUrl: string;
  updateUrl: string;
  fieldToGetRoleBy: 'StationAccess' | 'WorkspaceMember' | 'ListAccess';
  isArchived?: boolean;
}

const UserSettings = withAdminRole(SettingsDropdown)

const translationsPerSpace = {
  ListAccess: 'confirmDeletingUserFromList',
  StationAccess: 'confirmDeletingUserFromStation',
  WorkspaceMember: 'confirmDeletingUserFromWorkspace',
}

const UserListRow: FC<UserListRowProps> = ({
  avatar,
  name,
  email,
  id,
  onDelete,
  isOwner,
  hasAccount,
  fetchUsers,
  hasMoreThanOneAdmin,
  deleteUrl,
  updateUrl,
  fieldToGetRoleBy,
  isArchived,
  ...restOfUser
}) => {
  const accessGroupId =
    restOfUser[fieldToGetRoleBy]?.accessGroupId === RolesEnum.SuperAdmin
      ? RolesEnum.Admin
      : restOfUser[fieldToGetRoleBy]?.accessGroupId
  const { t } = useTranslation('userList')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { workspaceId, stationId, listId }: { workspaceId: string, stationId?: string, listId?: string } = useParams()
  const { isAdmin } = useRoles()
  const roles = availableGroups(Number(accessGroupId) === RolesEnum.ExplicitAccess)
  const { id: currentUserId } = useCurrentUser()

  const onConfirm = () => {
    const path = hasAccount ? deleteUrl : `api/invitedUsers/${id}`
    baseAPI(path, {
      method: 'DELETE',
    }).then(() => {
      onCancel()
      onDelete()
    })
  }

  const updateRole = async (accessGroupId: number, userId: number) => {
    await baseAPI(updateUrl, {
      body: {
        listId: listId && +listId,
        accessGroupId,
        stationId: stationId && +stationId,
        userId,
        workspaceId: +workspaceId,
      },
      method: 'PUT',
    })
    if (currentUserId === userId) window.location?.reload()
    fetchUsers?.()
  }

  const displayedName = name || email
  const onCancel = () => setIsModalOpen(false)

  return (
    <>
      <tr
        className={classnames('flex flex-wrap items-center border-b-2 mt-2 sm:border-b-0 sm:mt-0 sm:table-row', {
          'text-gray-400': !hasAccount,
        })}>
        <td className="w-full sm:w-8/12">
          <UserAvatar imgAlt={name} imgSrc={avatar} userId={id}>
            <div className="flex flex-col text-sm">
              {hasAccount && <span className="font-bold">{name}</span>}
              <span>{email}</span>
            </div>
          </UserAvatar>
        </td>
        {/* <td className="w-full sm:w-3/12">
          <span className="text-sm">{title}</span>
        </td> */}
        <td className="w-10/12 sm:w-3/12">
          <Dropdown
            isDisabled={!isAdmin || (!hasMoreThanOneAdmin && accessGroupId >= RolesEnum.Admin) || isArchived}
            name="role"
            options={roles}
            value={accessGroupId}
            onChange={({ target: { value } }: { target: { value: string } }) => {
              updateRole(+value, id)
            }}
          />
        </td>
        {!isArchived && (
          <td className="w-6 ms-auto sm:w-1/12">
            <UserSettings childClasses="w-44 rounded-te-none sm:right-3/4" wrapperClasses="w-6 h-6 sm:mx-auto">
              <button
                className="text-danger transition transition-colors hover:text-gray-600"
                onClick={() => setIsModalOpen(true)}>
                {t('deleteButtonText')}
              </button>
            </UserSettings>
          </td>
        )}
      </tr>
      <ConfirmationModal
        confirmationMessage={t(translationsPerSpace[fieldToGetRoleBy], {
          interpolation: { escapeValue: false },
          name: displayedName,
        })}
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  )
}

export default UserListRow
