import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { ListStatusType, RecordType } from 'utils/types'
import baseAPI, { customController } from 'utils/baseAPI'
import { AppElementView } from 'utils/types/AppElementViewType'
import { getChangeObject } from 'utils/helpers/timeLineHelpers'
import { SimpleChangeObject } from 'utils/types/ChangeTypes'

import { setActiveListViewId } from 'features/list/listSlice'
import { archiveElement, onMoveElement } from '../element'
import { appElementDefaultAttributes, recordAppElementDefaultAttributes } from '../../utils/constant/enums/Aggregate'

export const fetchRecordsByParams = createAsyncThunk(
  'record/fetchRecordsByParams',
  async (
    {
      listId,
      viewTypeId,
      queryParams,
      customGridData,
      archiveStatus,
      viewId,
      listElementId,
      statuses,
    }: {
      listId?: string | number,
      listElementId?: string | number,
      viewTypeId?: string | number,
      queryParams?: string[][],
      customGridData?: any,
      archiveStatus?: number,
      viewId?: number | string,
      statuses?: ListStatusType[],
    },
    thunkApi: any,
  ) => {
    try {
      const currentSortColumn = 'id'
      const currentSortDirection = 'asc'
      const archive = !archiveStatus ? 0 : archiveStatus

      const state = thunkApi.getState()
      const filters = get(state, 'record.selectedFilters')

      const currentViewId = viewId || get(state, 'list.activeListViewId')
      const currentListViews: AppElementView[] = get(state, 'list.currentListViews')
      const currentView = currentListViews.find(({ id }) => currentViewId == id)

      const customFields = []
      const queryString = filters.reduce((currentQueryString: string, filter: string[]) => {
        if (isNaN(filter[0])) {
          currentQueryString = currentQueryString + '&' + filter[0] + '=' + filter[1]
        } else {
          customFields.push({ id: filter[0], value: filter[1] })
        }
        return currentQueryString
      }, `?arc=${archive}&sort=${currentSortColumn}&direction=${currentSortDirection}&page=${1}&limit=1000000`)

      customController.abortAllControllersByType('fetchRecordsByParams')
      let response
      customController.abortAllControllersByType('record')
      const queryNewParams =
        customFields.length > 0 ? `${queryString}&customFields=${JSON.stringify(customFields)}` : queryString

      const finalQueryParams = currentViewId ? `${queryNewParams}&viewId=${currentViewId}` : queryNewParams
      if (viewTypeId && listId === undefined) {
        response = await baseAPI(`api/records/grid/${viewTypeId}${queryNewParams}`, { requestType: 'record' })
        if (+response.status >= 400) throw response
      } else {
        const options = { requestType: 'record' }
        response = await baseAPI(`api/records/list/${listId}${finalQueryParams}`, options)
        if (+response.status >= 400) throw response
      }

      thunkApi.dispatch(setActiveListViewId(currentViewId))
      return {
        currentSortColumn,
        currentSortDirection,
        currentView,
        customFields: response.body.customFields,
        customGridData,
        listElementId,
        listId,
        records: response.body.records,
        statuses,
        totalPages: response.body.totalPages,
        totalRecords: response.body.total,
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const createRecord = createAsyncThunk('record/createRecord', async (record: Partial<RecordType>) => {
  if (record.name !== '') {
    // const newValues = omitBy(record, isNil)
    const recordAdditionalData = {
      accessLevel: 1,
      avatar: '',
      avatarBackgroundColor: '#000',
      avatarForegroundColor: '#000',
      avatarType: 0,
      baseElementType: 1,
      color: '#fff',
      description: '',
      elementName: record.name,
      listId: record.listId,
      parentId: record.parentId,
      tags: 'record',
      ...record,
    }
    const response = await baseAPI('api/records', {
      body: recordAdditionalData,
      method: 'POST',
    })
    return response.body
  }
})

export const fetchRecordById = createAsyncThunk(
  'record/fetchRecordById',
  async (
    options: { id: number | string, viewId?: number | string, details?: boolean, fetchOnly?: boolean },
    { rejectWithValue, getState },
  ) => {
    try {
      const { id, viewId, details, fetchOnly = false } = options
      const state = getState()
      const currentViewId = viewId || get(state, 'list.activeListViewId')
      const queryParams = currentViewId ? `?viewId=${currentViewId}` : details ? '?details=true' : ''
      const response = await baseAPI(`api/records/${id}${queryParams}`)

      if (+response.status >= 400) throw response.message

      return { ...response.body, fetchOnly }
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateRecordById = createAsyncThunk(
  'record/updateRecordById',
  async (
    { initialRecord, changes }: { initialRecord: RecordType, changes: Partial<RecordType> },
    { rejectWithValue, dispatch },
  ) => {
    const newValues = omitBy({ ...initialRecord, ...changes }, isNil)
    delete newValues.status_id
    delete newValues.status
    newValues.startDate = initialRecord.startDate
    newValues.endDate = initialRecord.endDate
    newValues.description = changes.description ? changes.description : initialRecord.appElements[0]?.description
    delete newValues.labels
    if (changes.statusId === null) newValues.statusId = changes.statusId
    if (changes.priority === null) newValues.priority = changes.priority
    if (!isNil(changes.endDate) || changes.endDate === null) newValues.endDate = changes.endDate
    if (!isNil(changes.startDate) || changes.startDate === null) newValues.startDate = changes.startDate

    const fieldKeyName = changes.changes ? Object.keys(changes.changes)[0] : Object.keys(changes)[0]
    const defaultFieldId = appElementDefaultAttributes[fieldKeyName] || recordAppElementDefaultAttributes[fieldKeyName]
    const isDefaultField = defaultFieldId !== undefined
    const isGridViewCustomField = fieldKeyName && fieldKeyName.indexOf('custom_') > -1
    if (changes.changedAttributes) {
      newValues.changedAttributes = changes.changedAttributes
    } else {
      newValues.changedAttributes = [
        {
          attributeData: {
            attributeId: isDefaultField
              ? defaultFieldId
              : isGridViewCustomField
              ? fieldKeyName.replace('custom_', '')
              : fieldKeyName,
            attributeType: isDefaultField ? 0 : 1,
          },
          Value: changes[fieldKeyName],
        },
      ]
    }

    customController.abortAllControllersByType('updateRecordById')
    const response = await baseAPI(`api/records/${initialRecord.id}`, {
      body: newValues,
      method: 'PUT',
      requestType: 'updateRecordById',
    })
    if (response.status >= 400 || response.message === 'YourChangeSendToApproval') {
      return rejectWithValue(400)
    }
    // TODO: remove this next couple of lines when we have webSockets
    if (initialRecord.appElements[0]?.parent?.recordId && initialRecord.appElements[0].elementLevel === 5) {
      dispatch(fetchRecordById({ id: initialRecord.appElements[0].parent.recordId, fetchOnly: true }))
    }
    return response.body
  },
)

export const updateRecordElementById = createAsyncThunk(
  'record/updateRecordElementById',
  async (
    {
      elementId,
      viewId,
      changes,
      record,
      newOrder,
      oldOrder,
      needsFormatting = true,
      listElementId,
      statuses,
    }: {
      elementId: string | number,
      viewId: string | number,
      changes: any,
      record: RecordType,
      newOrder?: number,
      oldOrder?: number,
      needsFormatting?: boolean,
      listElementId?: string,
      statuses?: ListStatusType[],
    },
    { rejectWithValue, dispatch },
  ) => {
    let changedAttributes = []
    if (!needsFormatting) {
      changedAttributes = changes
    } else {
      const finalChanges = changes?.changes || changes
      const changedKeys = Object.keys(finalChanges)
      const oldValues: SimpleChangeObject = {}
      changedKeys.map((key) => {
        const index = record.appElements[0].customFieldValues.findIndex((field) => field.customFieldId == key)
        if (index > -1) {
          oldValues[key] = record.appElements[0].customFieldValues[index].value
        } else {
          oldValues[key] = record[key]
        }
      })

      const newChangeObject = getChangeObject({ changes: finalChanges, oldValues })
      if (Object.keys(newChangeObject).length === 0) {
        if (newOrder !== 0 && !newOrder) {
          return
        }
      }

      const finalChangedKeys = Object.keys(newChangeObject)

      finalChangedKeys.map((fieldKeyName) => {
        const defaultFieldId =
          appElementDefaultAttributes[fieldKeyName] || recordAppElementDefaultAttributes[fieldKeyName]
        const isDefaultField = isNaN(Number(fieldKeyName)) && defaultFieldId !== undefined
        const isGridViewCustomField = fieldKeyName && fieldKeyName.indexOf('custom_') > -1

        changedAttributes.push({
          attributeData: {
            attributeId: isDefaultField
              ? Number(defaultFieldId)
              : isGridViewCustomField
              ? Number(fieldKeyName.replace('custom_', ''))
              : Number(fieldKeyName),
            attributeType: isDefaultField ? 0 : 1,
          },
          Value: newChangeObject[fieldKeyName],
        })
      })
    }
    if (newOrder || newOrder === 0) {
      changedAttributes = [
        ...changedAttributes,
        {
          attributeData: {
            attributeId: 6,
            attributeType: 0,
          },
          Value: newOrder,
        },
      ]
    }
    const body = {
      appElementId: elementId,
      changedAttributes,
      viewId,
    }
    const response = await baseAPI(`api/records/${elementId}`, {
      body,
      method: 'PUT',
    })
    if (response.status >= 400) {
      return rejectWithValue(400)
    }
    // Remove this part and the "record" param when we have webSockets
    if (response?.body?.appElements[0]?.parent?.recordId && response?.body?.appElements[0].elementLevel === 5) {
      dispatch(fetchRecordById({ id: response.body.appElements[0].parent.recordId, fetchOnly: true }))
    }
    return { ...response.body, listElementId, newOrder, oldOrder, statuses }
  },
)

export const onUpdateCustomFieldLinks = createAsyncThunk(
  'record/onUpdateCustomFieldLinks',
  async ({ updateLinkMessage, recordId }: { updateLinkMessage: any, recordId: number | string }) => {
    const response = await baseAPI(`api/records/${recordId}/updateCustomLink`, {
      body: updateLinkMessage,
      method: 'PUT',
      requestType: 'onUpdateCustomFieldLinks',
    })
    return response.body
  },
)

export const deleteRecordById = createAsyncThunk('record/deleteRecordById', async (recordId: number) => {
  await baseAPI(`api/records/${recordId}`, { method: 'DELETE' })
  return recordId
})

export const archiveBulkRecord = createAsyncThunk(
  'record/archiveBulkRecord',
  async (props: { recordsIds: string[], isArchived: boolean }) => {
    const { recordsIds, isArchived } = props
    await baseAPI(`api/element/archiveElements/${isArchived ? 0 : 1}/0`, {
      body: { appElementIds: recordsIds },
      method: 'PUT',
    })
    return recordsIds
  },
)

export const duplicateRecordById = createAsyncThunk('record/duplicateRecordById', async (recordId: number) => {
  const response = await baseAPI(`api/records/${recordId}/duplicate`, { method: 'POST' })
  return response.body
})

export const archiveRecordById = createAsyncThunk(
  'record/archiveRecordById',
  async (element: { elementId: number, status: number, recursive: number }, { rejectWithValue }) => {
    const { elementId, status, recursive } = element
    return archiveElement(elementId, status, recursive, rejectWithValue)
  },
)
export const onMoveRecord = createAsyncThunk('record/onMoveRecord', async (body: any, { rejectWithValue }) => {
  return onMoveElement(body, rejectWithValue)
})

export const onChangeRecordParent = createAsyncThunk(
  'record/onChangeRecordParent',
  async (body: any, { rejectWithValue }) => {
    return onMoveElement(body, rejectWithValue)
  },
)
export const fetchRecordCustomFields = createAsyncThunk(
  'record/fetchRecordCustomFields',
  async (recordElementId: number) => {
    const response = await baseAPI(`api/element/customfields/element/${recordElementId}`)
    return response.body
  },
)

export const addMultipleFilters = createAction<string[][]>('record/addMultipleFilters')
export const clearFilters = createAction<void>('record/clearFilters')

export const addOrUpdateFilter = createAction<string[]>('record/addOrUpdateFilter')

export const removeFilterById = createAction<number>('record/removeFilterById')

export const removeAllFilters = createAction('record/removeAllFilters')

export const clearCurrentRecord = createAction('record/clearCurrentRecord')

export const clearRecords = createAction('record/clearRecords')

export const setCurrentRecord = createAction<string | number | null>('record/setCurrentRecord')
