import { useState } from 'react'

import ContactUsForm from 'components/forms/ContactUsForm'
import { useContactUsDetails, useUserLoading } from 'features/user'

const ContactUsPage = () => {
  const contactUsDetails = useContactUsDetails()
  const [message, setMessage] = useState('')
  const loading: any = useUserLoading()

  return (
    <div>
      {message && <p>{message}</p>}
      {!loading && <ContactUsForm initialValues={contactUsDetails} setMessage={setMessage} />}
    </div>
  )
}

export default ContactUsPage
