import { FC } from 'react'
import classnames from 'classnames'

import { RTEClasses, ActiveTextEditClasses } from 'components/inputs/RTEClassNames'
import {
  WysiwygBoldIcon,
  WysiwygItalicIcon,
  WysiwygUnderlineIcon,
  WysiwygAlignCenterIcon,
  WysiwygAlignJustifyIcon,
  WysiwygAlignLeftIcon,
  WysiwygAlignRightIcon,
  WysiwygBlockqouteIcon,
  WysiwygOrderedListIcon,
} from 'components/Icons'
import ColorPalette from './ColorPalette'

const TextEditToolbar: FC = ({ editor }) => {
  const focusEditor = () => editor.chain().focus()
  const ua = navigator.userAgent.toLowerCase()
  const isSafari = ua.indexOf('safari') !== -1 ? ua.indexOf('chrome') <= -1 : false

  return (
    <div className="flex flex-wrap items-center mt-1 ms-0.5">
      <button
        className={classnames(RTEClasses, editor?.isActive('heading', { level: 1 }) && ActiveTextEditClasses)}
        style={{ lineHeight: '1px' }}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleHeading({ level: 1 }).run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleHeading({ level: 1 }).run()
            e.preventDefault()
          }
        }}>
        H1
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive('heading', { level: 2 }) && ActiveTextEditClasses)}
        style={{ lineHeight: '1px' }}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleHeading({ level: 2 }).run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleHeading({ level: 2 }).run()
            e.preventDefault()
          }
        }}>
        H2
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive('heading', { level: 3 }) && ActiveTextEditClasses)}
        style={{ lineHeight: '1px' }}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleHeading({ level: 3 }).run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleHeading({ level: 3 }).run()
            e.preventDefault()
          }
        }}>
        H3
      </button>
      <div className="h-5 mx-1 border-gray-300 border-e" />
      <button
        className={classnames(RTEClasses, editor?.isActive('bold') && ActiveTextEditClasses)}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleBold().run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleBold().run()
            e.preventDefault()
          }
        }}>
        <WysiwygBoldIcon className="h-5" />
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive('underline') && ActiveTextEditClasses)}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleUnderline().run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleUnderline().run()
            e.preventDefault()
          }
        }}>
        <WysiwygUnderlineIcon className="h-5" />
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive('italic') && ActiveTextEditClasses)}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleItalic().run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleItalic().run()
            e.preventDefault()
          }
        }}>
        <WysiwygItalicIcon className="h-5" />
      </button>
      <div className="h-5 mx-1 border-gray-300 border-e" />
      <ColorPalette editor={editor} isSafari={isSafari} />
      <div className="h-5 mx-1 border-gray-300 border-e" />
      <button
        className={classnames(
          RTEClasses,
          'rtl-mirror',
          editor?.isActive({ textAlign: 'start' }) && ActiveTextEditClasses,
        )}
        onClick={() => focusEditor().setTextAlign('start').run()}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().setTextAlign('start').run()
            e.preventDefault()
          }
        }}>
        <WysiwygAlignLeftIcon className="h-5" />
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive({ textAlign: 'center' }) && ActiveTextEditClasses)}
        onClick={() => editor?.chain().focus().setTextAlign('center').run()}
        onMouseDown={(e) => {
          if (isSafari) {
            editor?.chain().focus().setTextAlign('center').run()
            e.preventDefault()
          }
        }}>
        <WysiwygAlignCenterIcon className="h-5" />
      </button>
      <button
        className={classnames(
          RTEClasses,
          'rtl-mirror',
          editor?.isActive({ textAlign: 'end' }) && ActiveTextEditClasses,
        )}
        onClick={() => focusEditor().setTextAlign('end').run()}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().setTextAlign('end').run()
            e.preventDefault()
          }
        }}>
        <WysiwygAlignRightIcon className="h-5" />
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive({ textAlign: 'justify' }) && ActiveTextEditClasses)}
        onClick={() => focusEditor().setTextAlign('justify').run()}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().setTextAlign('justify').run()
            e.preventDefault()
          }
        }}>
        <WysiwygAlignJustifyIcon className="h-5" />
      </button>
      <div className="h-5 mx-1 border-gray-300 border-e" />
      <button
        className={classnames(RTEClasses, editor?.isActive('blockquote') && ActiveTextEditClasses)}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleBlockquote().run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleBlockquote().run()
            e.preventDefault()
          }
        }}>
        <WysiwygBlockqouteIcon className="h-5" />
      </button>
      <button
        className={classnames(RTEClasses, editor?.isActive('orderedList') && ActiveTextEditClasses)}
        onClick={() => {
          if (!isSafari) {
            focusEditor().toggleOrderedList().run()
          }
        }}
        onMouseDown={(e) => {
          if (isSafari) {
            focusEditor().toggleOrderedList().run()
            e.preventDefault()
          }
        }}>
        <WysiwygOrderedListIcon className="h-5" />
      </button>
    </div>
  )
}

export default TextEditToolbar
