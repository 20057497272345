import React from 'react'
import PlansList from './PlansList'
import BackItem from './BackItem'

const PlansListPage = () => {
  return (
    <>
      <BackItem />
      <div className="w-full py-10 bg-white rounded lg:px-36 md:px-36">
        <PlansList />
      </div>
    </>
  )
}

export default PlansListPage
