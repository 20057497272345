/**Defines app element default attributes */
export enum appElementDefaultAttributes {
    // id
    // eslint-disable-next-line prettier/prettier
    id = 0,

    // name
    name,

    //	creation date
    creationDate,

    // update date
    updateDate,

    // owner
    owner,

    // Description
    description
}

/**Defines record app element default attributes */
export enum recordAppElementDefaultAttributes {
    // completed in
    completedIn = 4000,
    health,
    priority,
    ownerId,
    statusId,
    startDate,
    endDate,
    labelsIds
}

/**Defines list app element default attributes*/
export enum listAppElementDefaultAttributes {
    // name
    stationId = 3000
}

export enum aggregationMethods {
    // sum
    sum,

    // average
    average,

    // minimum
    minimum,

    // maximum
    maximum,

    // count
    count
}
