import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import CreatableSelect from 'react-select/creatable'
import { useAppDispatch } from 'store'
import DashboardWidgetType from 'utils/types/DashboardWidgetType'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { getElementCustomFieldsByParentPath } from 'features/element'
import { customFieldBaseTypes } from 'utils/constant/enums/customFields'
import Loader from '../../../Loader'
import { StationType, WorkspaceType } from '../../../../utils/types'
import { Radio } from '../../../inputs'
import { CustomFieldType } from '../../../../utils/types/CustomFieldsType'
import { getInheritedCustomFields } from '../../../../utils/helpers/customFields'
import { CustomWidgetFieldValues } from '../../../../utils/constant/enums/widgets'
import DashboardSourceType from '../../../../utils/constant/enums/dashboardSourceType'

interface ConfiguringWidgetProps {
  parentId: string | number;
  level: number;
  currentLevel: number;
  isTimeline: boolean;
  setCurrentLevel: (value: number) => void;
  setCurrentCustomValues: (value: boolean) => void;
  currentCustomValues: any;
  setSelectedElements: (value: any) => void;
  selectedElements: any[];
  currentStation: StationType;
  currentWorkspace: WorkspaceType;
  currentWidget: DashboardWidgetType;
}

const CreateGaugeForm: FC<ConfiguringWidgetProps> = ({
  setSelectedElements,
  selectedElements,
  currentStation,
  currentWorkspace,
}) => {
  const { t } = useTranslation('dashboard')
  const appDispatch = useAppDispatch()
  const [requiredFields, setRequiredFields] = useState({
    ActualValue: null,
    LabelValue: null,
    MinValue: null,
    MaxValue: null,
  })
  const [applyOnChildren, setApplyOnChildren] = useState(true)
  const [customFields, setCustomFields] = useState<CustomFieldType[]>([])

  const baseTypes = [customFieldBaseTypes.Number, customFieldBaseTypes.Formula, customFieldBaseTypes.Aggregate]
  const formattedCustomFields = customFields
    ?.filter((field: CustomFieldType) => baseTypes.includes(field.baseType))
    ?.map((cf: CustomFieldType) => ({
      ...cf,
      label: cf.fieldName,
      sourceType: DashboardSourceType.CustomField,
      value: cf.id,
    }))
  const allCustomFields = customFields?.map((cf: CustomFieldType) => ({
    ...cf,
    label: cf.fieldName,
    sourceType: DashboardSourceType.CustomField,
    value: cf.id,
  }))
  const options = [
    // {
    //   id: recordAppElementDefaultAttributes.completedIn,
    //   label: t('validation:fieldNames.completedIn'),
    //   sourceType: DashboardSourceType.DefaultField,
    //   value: recordAppElementDefaultAttributes.completedIn,
    // },
    ...formattedCustomFields,
  ]

  useEffect(() => {
    if (currentStation?.appElements[0]?.elementPath) {
      if (!applyOnChildren) {
        appDispatch(
          getElementCustomFieldsByParentPath({
            elementBaseType: AppElementBaseTypes.StationAppElement,
            parentPath: currentStation?.appElements[0]?.elementPath,
          }),
        ).then((res) => {
          const customFieldsData = res.payload
          const mergedFields = getInheritedCustomFields(customFieldsData)
          setCustomFields(mergedFields)
        })
      } else {
        appDispatch(
          getElementCustomFieldsByParentPath({
            elementBaseType: AppElementBaseTypes.ListAppElement,
            parentPath: currentStation?.appElements[0]?.elementPath,
          }),
        ).then((res) => {
          const customFieldsData = res.payload
          const mergedFields = getInheritedCustomFields(customFieldsData)
          setCustomFields(mergedFields)
        })
      }
    }
  }, [currentStation?.appElements[0]?.elementPath, applyOnChildren])

  useEffect(() => {
    const list = [...selectedElements]
    list[0] = {
      parameterId: 1,
      sourceType: DashboardSourceType.Customization,
      value: 'true',
    }
    setSelectedElements(list)
  }, [])

  const renderDropdown = (fieldKey: string, index: number) => {
    return (
      <div className="flex-1 mt-3">
        <p className={classNames('mb-2', { 'text-gray-400': !selectedElements })}>{t(fieldKey)}</p>
        <CreatableSelect
          className="border-none"
          classNamePrefix="custom-field-select"
          isDisabled={!selectedElements}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name="customFieldType"
          options={fieldKey === 'LabelValue' ? allCustomFields : options}
          placeholder={t('customFields:select')}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          value={requiredFields[fieldKey]}
          onChange={(values: any) => {
            setRequiredFields({ ...requiredFields, [fieldKey]: values })
            const list = [...selectedElements]
            list[index] = {
              parameterId: CustomWidgetFieldValues[fieldKey],
              sourceType: values.sourceType,
              value: values.value,
            }
            setSelectedElements(list)
          }}
        />
      </div>
    )
  }

  return (
    <>
      {!currentStation && !currentWorkspace ? (
        <Loader />
      ) : (
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="flex">
            <Radio
              checked={!applyOnChildren}
              className="custom-radio"
              name={`isApplyOnStation`}
              onChange={() => {
                const list = [...selectedElements]
                list[0] = {
                  parameterId: 1,
                  sourceType: DashboardSourceType.Customization,
                  value: 'false',
                }
                setSelectedElements(list)
                setApplyOnChildren(false)
              }}
            />
            <p className="text-sm">{t('applyOnStation')}</p>
          </div>
          <div className="flex">
            <Radio
              checked={applyOnChildren}
              className="custom-radio"
              name={`isApplyOnLists`}
              onChange={() => {
                const list = [...selectedElements]
                list[0] = {
                  parameterId: 1,
                  sourceType: DashboardSourceType.Customization,
                  value: 'true',
                }
                setSelectedElements(list)
                setApplyOnChildren(true)
              }}
            />
            <p className="text-sm">{t('applyOnStationLists')}</p>
          </div>
          {options?.length === 0 && <p className="text-danger text-sm my-1">{t('noFieldsErrorMessage')}</p>}
          <div className="flex-col flex-1 min-h-0 gap-2 md:flex-row md:justify-between">
            {Object.keys(requiredFields).map((key, idx) => {
              return renderDropdown(key, idx + 1)
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default CreateGaugeForm
