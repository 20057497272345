import React, { FC } from 'react'
import Tippy from '@tippy.js/react'
import { calendarTaskColors } from 'utils/helpers/timeLineHelpers'
import { healthColors } from '../../components/GridView/cells/displayCells/HealthCell'
import UserAvatarStatus from '../../components/UserAvatar/UserAvatarStatus'
import EventDidMount from './EventDidMount'

const EventContent: FC = (props) => {
  const recordContent = props.event._def
  const { language, isMobile } = props
  const { owner, health, completedIn, elementLevel } = recordContent.extendedProps

  const UserAvatarStatusProps = {
    customSize: 6,
    imgAlt: `${owner?.name}`,
    imgSrc: owner?.link,
    mobileInsetClass: '-inset-0.5',
    removeIconCustomSize: 1,
    userId: owner?.id,
  }
  const isSubtask = elementLevel > 4

  if (!recordContent) {
    return true
  }

  const renderContent = () => {
    return (
      <div className={`p-0.5 rounded ${calendarTaskColors(isSubtask)}`}>
        <div
          className={`relative shadow-sm transition transparent hover:shadow group ${calendarTaskColors(isSubtask)}`}>
          <div className="flex items-center justify-start w-full overflow-x-hidden rounded-b-sm z-1 transparent">
            <UserAvatarStatus {...UserAvatarStatusProps} />
            <span className="ms-1">{recordContent.title}</span>
          </div>
          <div
            className="absolute rounded-sm z-1 -bottom-0.5 h-0.5 group-hover:h-1.5 transform group-hover:translate-y-0.5 transition"
            style={{
              backgroundColor: healthColors[health],
              width: `${completedIn}%`,
            }}
          />
          {completedIn > 0 && (
            <p
              className={`absolute -bottom-1 z-10 flex py-1 text-xs rounded-sm opacity-0 pointer-events-none ms-1 group-hover:opacity-100 px-1.5 leading-3 transform group-hover:translate-y-0.5 transition ${
                health === 'unavailable' ? 'text-gray-600' : 'text-white'
              }`}
              style={
                completedIn < 20
                  ? { backgroundColor: healthColors[health], insetInlineStart: `${completedIn}%` }
                  : { backgroundColor: healthColors[health], insetInlineEnd: `${100 - completedIn}%` }
              }>
              {completedIn}
              <span>%</span>
            </p>
          )}
        </div>
      </div>
    )
  }

  return isMobile ? (
    <>{renderContent()}</>
  ) : (
    <Tippy
      animation="fade"
      appendTo={() => document.body}
      arrow={false}
      className={'top-zIndex'}
      content={<EventDidMount {...props} />}
      interactive={true}
      placement={language === 'ar' ? 'left-start' : 'right-start'}
      theme={'calendar-tooltip'}>
      {renderContent()}
    </Tippy>
  )
}
export default EventContent
