import React, { FC, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import Tour from 'reactour'
import { sortBy } from 'lodash'
import { useSize } from 'react-use'

import { ArchiveIcon, CircledPlusIcon, EmptyWorkspacesIcon } from 'components/Icons'
import {
  fetchWorkspaces,
  getFilteredTasksWidget,
  getMyTasksWidget,
  useMyTasksWidget,
  useIsMyTasksLoading,
  useWorkspacesList,
  useWorkspacesLoading,
} from 'features/workspace'
import { useCurrentUser } from 'features/user'
import SingleWorkspaceTile from 'components/SingleWorkspaceTile'
import Button, { ButtonVariant } from 'components/Buttons'
import { usePanel } from 'contexts/PanelContext'
import { AddWorkspaceForm } from 'components/forms'

import Loader from 'components/Loader'
import EmptyStatePage from 'components/EmptyStatePage'
import { TourContent } from 'components/Tour'
import { useHistory, useLocation } from 'react-router-dom'
import { useMedia } from 'react-use'
import classNames from 'classnames'
import { useFilters } from '../../features/record'
import HomeDashboard from '../../components/HomeDashboard/HomeDashboard'
import { configValues } from '../../utils/appConfig'
import { useAppDispatch } from '../../store'
import ArchivePanel from '../../components/ArchivePanel/ArchivePanel'
import SideBarMyTasksWidget from '../../components/Dashboard/Widgets/SideBarMyTasksWidget/SideBarMyTasksWidget'
import { useRoles } from '../../hooks'

const WorkspaceListingPage: FC = () => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const { isAdmin } = useRoles()
  const appDispatch = useAppDispatch()
  const areWorkspacesLoading = useWorkspacesLoading()
  const workspaces = useWorkspacesList()
  const { openPanel, setPanelContent } = usePanel()
  const { firstName } = useCurrentUser()
  const allFilters = useFilters()
  const { pathname, search } = useLocation()
  const isDashboard = pathname === '/dashboard'
  const firstStepDone = localStorage.getItem('firstStepDone') === 'true'
  const [isTourOpen, setTourOpen] = useState(!firstStepDone)
  const [isArchived, setIsArchived] = useState(workspaces ? workspaces[0]?.appElements[0]?.isArchived : false)
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isAllRecords, setIsAllRecords] = useState(false)
  const [currentWidgetData, setCurrentWidgetData] = useState('myTasks')
  const [selectedElements, setSelectedElements] = useState([])
  const [requestSearch, setRequestSearch] = useState('')
  const dashboardData = useMyTasksWidget()
  const isDashboardDataLoading = useIsMyTasksLoading()
  const isLgBreakpoint = useMedia('(min-width: 1024px)')
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div className="w-full h-0" />)
  const recordsList =
    currentWidgetData === 'myTasks' && allFilters.length === 0 && !isAllRecords
      ? dashboardData?.myDueRecordsWidget
      : dashboardData?.records

  useEffect(() => {
    const recordInfo = search?.split('Id=')
    if (configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED) {
      if (recordInfo.length > 1) {
        setIsSideBarOpen(true)
        history.push(`${search}`)
      } else {
        history.push('/')
      }
    }
  }, [])

  const fetchSideBarWidget = () => {
    if (currentWidgetData === 'watched') {
      appDispatch(getFilteredTasksWidget('watched=true'))
    } else {
      appDispatch(getMyTasksWidget(0))
    }
  }

  // const refetchFunction = () => {
  //   if (currentWidgetData === 'watched') {
  //     return getFilteredTasksWidget('watched=true')
  //   }
  //   return getMyTasksWidget(0)
  // }
  useEffect(() => {
    if (!isFirstLoad) {
      let filterUpdate = requestSearch
      if (currentWidgetData === 'watched' && filterUpdate.indexOf('watched') === -1) {
        filterUpdate += filterUpdate.length > 0 ? `&watched=true` : `watched=true`
      }
      appDispatch(getFilteredTasksWidget(filterUpdate))
    }
  }, [currentWidgetData, requestSearch, isAllRecords])

  useEffect(() => {
    let filterUpdate = ''
    if (allFilters?.length > 0) {
      allFilters.map((f) => {
        filterUpdate += filterUpdate.length > 0 ? `&${f[0]}=${f[1]}` : `${f[0]}=${f[1]}`
      })
    }
    setRequestSearch((prev) => (currentWidgetData === 'watched' ? `watched=true&${filterUpdate}` : filterUpdate))
  }, [allFilters.length])

  useEffect(() => {
    if (!isDashboard && currentWidgetData === 'myTasks' && !isAllRecords) {
      appDispatch(getMyTasksWidget(0))
      setIsFirstLoad(false)
    }
  }, [isDashboard, isArchived, currentWidgetData, isAllRecords])

  useEffect(() => {
    setPanelContent({
      content: <AddWorkspaceForm />,
      header: t('addNewWorkspace'),
    })
  }, [t])

  const steps = [
    {
      content: function introStep({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            goTo={() => goTo(1)}
            header={t('tour:introHeader', { name: firstName })}
            message={t('tour:introText')}
          />
        )
      },
      selector: '',
    },
    {
      action: () => localStorage.setItem('firstStepDone', 'true'),
      content: function firstStep() {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            header={t('tour:firstStepHeader')}
            message={t('tour:firstStepText')}
          />
        )
      },
      selector: '[data-tut="first__step"]',
    },
  ]
  const renderWorkspaces = () => {
    return (
      <div className="flex flex-col-reverse w-full lg:flex-row">
        <div
          className="flex flex-col"
          style={{ width: isLgBreakpoint ? (isSideBarOpen ? '66%' : 'calc(100% - 42px)') : '100%' }}>
          {sized}
          {areWorkspacesLoading && (
            <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />
          )}
          {isEmpty(workspaces) && !areWorkspacesLoading && (
            <EmptyStatePage
              Button={
                !isArchived
                  ? () => (
                      <Button icon={CircledPlusIcon} variant={ButtonVariant.Outline} onClick={openPanel}>
                        {t('addWorkspace')}
                      </Button>
                    )
                  : null
              }
              Icon={EmptyWorkspacesIcon}
              classes="flex flex-col h-full items-center justify-start flex-1 ms-10"
              description={t('noWorkspacesDescription')}
              header={t('noWorkspacesHeader')}
            />
          )}
          {!isEmpty(workspaces) && (
            <>
              <Tour
                isOpen={isTourOpen}
                showButtons={false}
                showNavigation={false}
                showNumber={false}
                steps={steps}
                onRequestClose={() => setTourOpen(false)}
              />

              <div
                className={classNames('grid grid-cols-1 w-full gap-6 lg:pe-4', {
                  'md:grid-cols-2 2xl:grid-cols-3': isSideBarOpen,
                  'md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4': !isSideBarOpen,
                })}
                data-tut="first__step">
                {sortBy(workspaces, ['updatedAt'])?.map((workspace) => (
                  <SingleWorkspaceTile key={workspace.id} workspace={workspace} />
                ))}
              </div>
            </>
          )}
        </div>
        {!isArchived && (
          <SideBarMyTasksWidget
            currentWidgetData={currentWidgetData}
            dashboardWidget={recordsList}
            isAdmin={isAdmin}
            isHomePage={true}
            setRequestSearch={setRequestSearch}
            setSelectedItems={setSelectedElements}
            selectedElements={selectedElements}
            isSideBarOpen={isSideBarOpen}
            isAllRecords={isAllRecords}
            hasFilter={allFilters?.length}
            setIsFirstLoad={setIsFirstLoad}
            isDashboardDataLoading={isDashboardDataLoading}
            setIsAllRecords={setIsAllRecords}
            refetchFunction={() => getMyTasksWidget(0)}
            setCurrentWidgetData={setCurrentWidgetData}
            setIsSideBarOpen={setIsSideBarOpen}
          />
        )}
      </div>
    )
  }

  const renderDashboard = () => {
    return (
      <div className="relative h-screen max-h-screen">
        <HomeDashboard />
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="mb-2">
        <header className="items-center justify-between mb-10 md:flex lg:h-12">
          <div className="lg:flex">
            <h1
              className={`mb-4 me-4 md:mb-0 text-xl ${
                !configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && 'cursor-pointer'
              } ${isDashboard && 'opacity-50'}`}
              onClick={() => {
                if (!configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED) history.push('/')
              }}>
              {t('workspaces')}
            </h1>
            {!configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && (
              <h1
                className={`mb-4 me-auto md:mb-0 text-xl cursor-pointer ${!isDashboard && 'opacity-50'}`}
                onClick={() => {
                  history.push('/dashboard')
                }}>
                {t(`dashboard`)}
              </h1>
            )}
          </div>
          {!isDashboard && (
            <div className="flex">
              {!isArchived && (
                <Button className="me-1" icon={CircledPlusIcon} variant={ButtonVariant.Outline} onClick={openPanel}>
                  {t('addWorkspace')}
                </Button>
              )}
              <Button
                icon={ArchiveIcon}
                isActive={isArchived}
                variant={ButtonVariant.Outline}
                onClick={() => {
                  appDispatch(fetchWorkspaces(Number(!isArchived))).then(() => setIsArchived(!isArchived))
                }}>
                {isArchived ? t('common:labels.exitArchive') : t('workspace:archivedWorkspaces')}
              </Button>
            </div>
          )}
        </header>
        {isArchived && (
          <div className="flex w-full mb-8">
            <ArchivePanel message={t('archiveWorkspaceMessage')} />
          </div>
        )}
        {!isDashboard && renderWorkspaces()}
        {isDashboard && !configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && renderDashboard()}
      </div>
    </div>
  )
}

export default WorkspaceListingPage
