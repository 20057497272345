import React, { FC } from 'react'
import { getFieldReplacingInfo, isFieldHidden } from 'utils/helpers/customFields'
import { ElementType, RecordType } from 'utils/types'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import RecordCustomFieldsEditable from './RecordCustomFieldEditable'

interface ElementCustomFieldsPropsType {
  isEditor: boolean;
  currentElement: ElementType;
  customFieldsList: any[];
  updateElement: (values: Partial<RecordType>) => void;
}

const ElementCustomFields: FC<ElementCustomFieldsPropsType> = ({
  currentElement,
  updateElement,
  isEditor,
  customFieldsList,
}) => {
  const fieldsList = customFieldsList ? [...customFieldsList] : []

  const onUpdate = async (key: string, type: customFieldBaseTypes, value: any[] | string | null | undefined) => {
    const fieldsList = {}
    const fieldValue = {
      customFieldId: key,
      description: 'custom linked item',
      elementId: currentElement?.appElements[0]?.id,
      isLinkedAsChild: false,
    }
    if (type === customFieldBaseTypes.DropDown) {
      fieldValue.customFieldOptionId = value
      fieldValue.value = null
    } else {
      fieldValue.value = value
    }
    const allFields = currentElement?.appElements[0]?.customFieldValues
      ? [...currentElement?.appElements[0]?.customFieldValues]
      : []
    const updatedFieldIndex = allFields.findIndex((i: any) => +i.customFieldId === +key)
    if (updatedFieldIndex > -1) {
      allFields[updatedFieldIndex] = { ...allFields[updatedFieldIndex], ...fieldValue }
    } else {
      if (type !== customFieldBaseTypes.Link) allFields.push(fieldValue)
    }

    fieldsList.appElements = [
      {
        ...currentElement.appElements[0],
        customFieldValues: allFields,
      },
    ]
    updateElement({ customFieldValues: allFields })
  }

  return (
    <>
      {fieldsList
        ?.sort((a, b) => a.baseType - b.baseType)
        ?.map((field: CustomFieldType, index: number) => {
          if (isFieldHidden(field, fieldsList, currentElement?.appElements[0]?.baseElementType)) return null

          const { shouldBeReplaced, value: customFieldValue } = getFieldReplacingInfo({
            customFieldValues: currentElement?.appElements[0]?.customFieldValues,
            field,
            fieldsList,
            isLeafNode: currentElement?.appElements[0]?.directChildrenCount == 0,
          })

          const isCalculatedField =
            field.baseType === customFieldBaseTypes.Formula ||
            field.baseType === customFieldBaseTypes.Aggregate ||
            shouldBeReplaced

          return (
            <tr key={field.id}>
              <td className={`text-sm font-bold ${isCalculatedField && 'italic font-extrabold'}`} id={`${index}`}>
                {field.fieldName}
              </td>
              <td className="p-2">
                <RecordCustomFieldsEditable
                  currentItem={currentElement}
                  customFieldInfo={field}
                  customFieldValue={customFieldValue}
                  isEditor={isCalculatedField ? !isCalculatedField : isEditor}
                  onUpdate={onUpdate}
                />
              </td>
            </tr>
          )
        })}
    </>
  )
}

export default ElementCustomFields
