import { Datepicker } from 'components/inputs'
import DefaultNotEditingComponent from 'components/inputs/DefaultNotEditingComponent'
import { format, isAfter, isBefore } from 'date-fns'
import { withContentEditable } from 'hocs'
import React, { FC, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentEditableInputRefType } from 'utils/types/inputs'
import { DATE_FORMAT } from 'utils/helpers/generalHelpers'
import * as yup from 'yup'
import recordType from '../../utils/types/RecordType'
import { hijriInputFormat } from '../../utils/calendarUtils'
import { useCurrentUser } from '../../features/user'

const ContentEditableDatepicker = withContentEditable(Datepicker)

const RecordDatePicker: FC<{
  currentItem: recordType,
  name: string,
  date?: Date,
  handleRecordChange: (values: any) => void,
  dateToCompare?: Date,
  isDisabled?: boolean,
  shouldShowPlaceholder?: boolean,
}> = forwardRef(
  ({ currentItem, name, date, handleRecordChange, dateToCompare, isDisabled, shouldShowPlaceholder = false }, ref) => {
    const { t, i18n } = useTranslation('validation')
    const user = useCurrentUser()
    const inputRef = useRef()
    const isHijri = user?.isHijri !== null ? user?.isHijri : false
    const datepickerRef = useRef<ContentEditableInputRefType>(null)

    useImperativeHandle(
      ref,
      () => ({
        blur: () => datepickerRef?.current?.blur(),
        focus: () => datepickerRef?.current?.focus(),
        shakeError: () => datepickerRef?.current?.shakeError(),
      }),
      [datepickerRef?.current],
    )

    const renderSelectedDate = (selectedDate: any, { showInput, name }: { showInput: () => void, name: string }) => {
      const dateValue = isHijri
        ? hijriInputFormat(selectedDate, i18n.language)
        : selectedDate
        ? `${format(new Date(selectedDate), DATE_FORMAT)}`
        : undefined
      return (
        <DefaultNotEditingComponent
          name={name}
          placeholder={i18n.language === 'ar' && isHijri ? 'yyyy/mm/dd' : 'dd/mm/yyyy'}
          ref={inputRef}
          shouldShowPlaceholder={shouldShowPlaceholder}
          showInput={showInput}
          value={selectedDate && dateValue}
        />
      )
    }

    const validationSchema =
      name === 'endDate'
        ? yup
            .date()
            .test(
              "endDate can't be earlier than startDate",
              t('errors.endDateCannotBeEarlierThanStartDate'),
              (value) => {
                return dateToCompare != null && value ? !isBefore(value, new Date(dateToCompare)) : true
              },
            )
            .nullable()
        : yup
            .date()
            .test("startDate can't be later than endDate", t('errors.startDateCannotBeLaterThanEndDate'), (value) => {
              return dateToCompare != null && value ? !isAfter(value, new Date(dateToCompare)) : true
            })
            .nullable()

    const datePickerProps = {
      className: '-ms-2',
      currentItem,
      inputRef,
      inputWrapperClassName: '-ms-2',
      isHijri,
      isOnClickDisabled: isDisabled,
      name,
      onSubmit: handleRecordChange,
      ref: datepickerRef,
      renderNotEditingState: renderSelectedDate,
      shouldShowPlaceholder,
      tabIndex: 0,
      validationSchema: validationSchema,
      value: date,
    }

    return <ContentEditableDatepicker {...datePickerProps} />
  },
)

RecordDatePicker.displayName = 'RecordDatePicker'

export default RecordDatePicker
