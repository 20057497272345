import React, { FC, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchStationById } from 'features/station'
import { fetchUsersByStationId } from 'features/user'

import StationSettingsPage from 'pages/station/StationSettingsPage'
import StationMainPage from 'pages/station/StationMainPage'
import StationDocumentationPage from 'pages/station/StationDocumentation/StationDocumentationPage'
import StationDashboardPage from 'pages/station/StationDashboardPage'
import Loader from 'components/Loader'
import { useAppDispatch } from '../../store'

const SingleStationRouter: FC = () => {
  const {
    path,
    url,
    params: { stationId },
  }: { path: string, url: string, params: { stationId: string } } = useRouteMatch()
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const [localLoader, setLocalLoader] = useState<boolean>(true)

  useEffect(() => {
    appDispatch(fetchStationById(stationId)).finally(() => setLocalLoader(false))
    dispatch(fetchUsersByStationId({ stationId }))
  }, [dispatch, stationId])

  if (localLoader) return <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />

  return (
    <Switch>
      <Route path={[`${path}/details`, `${path}/users`, `${path}/fields`]}>
        <StationSettingsPage />
      </Route>
      <Route path={`${path}/documentation`}>
        <StationDocumentationPage />
      </Route>
      <Route path={`${path}/dashboard`}>
        <StationDashboardPage />
      </Route>
      <Route path={`${path}/lists/:listId?/:viewId?`}>
        <StationMainPage />
      </Route>
      <Redirect to={`${url}/lists`} />
    </Switch>
  )
}

export default SingleStationRouter
