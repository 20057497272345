import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedActivityPath } from 'utils/helpers/generalHelpers'
import { ActivityLogType, UsersTablePropsType } from 'utils/types'
import { AppElementBaseTypes } from 'utils/types/AppElementType'

interface ActivityArchived {
  activityUser: UsersTablePropsType | undefined;
  activity?: ActivityLogType;
  isHijri?: boolean;
  isNotification?: boolean;
  language?: string;
  setSelectedRecordId?: (value: string) => void;
}

const Archive: FC<ActivityArchived> = ({ activityUser, activity, isNotification, setSelectedRecordId }) => {
  const { t } = useTranslation('notifications')
  const shouldDisplayRecordName =
    isNotification || (activity && activity.stationName && activity.listName && activity.recordName)

  const elementBaseType = activity?.relatedAppElementBaseType || AppElementBaseTypes.RecordAppElement
  const elementName = activity?.relatedAppElementName || ''
  const userName =
    activity?.user !== undefined
      ? `${activity?.user?.firstName} ${activity?.user?.lastName}`
      : `${activity?.firstName} ${activity?.lastName}` || activityUser?.name

  return (
    <div>
      <div className="flex items-baseline my-1 leading-4">
        <label className="text-sm font-bold me-1 leading-4">{userName}</label>
        <span className="text-sm leading-4">
          {t('elementArchived', { elementType: t(`common:baseTypeTranslations.${elementBaseType}`) })}
        </span>
      </div>
      {shouldDisplayRecordName &&
        (isNotification ? (
          <div className="mb-4">
            <div
              className="mt-1 cursor-pointer text-primary"
              // href={`/workspace/${activity?.workspaceId}/stations/${activity?.stationId}/lists/${activity?.listId}?recordId=${activity?.record?.id}`}
              // rel="noreferrer"
              // target="_blank"
              onClick={() => setSelectedRecordId(activity?.record?.id)}>
              {elementName}
            </div>
            {getFormattedActivityPath(activity?.workspace?.name, activity?.station?.name, activity?.list?.name)}
          </div>
        ) : (
          <div
            className="mb-4 text-xs cursor-pointer sm:tracking-normal sm:mt-1 sm:text-sm"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>
            <span className="text-primary">{activity.recordName}</span>
            {getFormattedActivityPath(null, activity.stationName, activity.listName)}
          </div>
        ))}
    </div>
  )
}

export default Archive
