import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'
import { getElementCustomFields, useElementCustomFields, useFormulaList } from '../../../features/element'
import { setFormulaList } from '../../../features/element/elementSlice'
import { ListType, StationType, WorkspaceType } from '../../../utils/types'
import SelectParentRow from '../../Dashboard/Widgets/CustomWidgets/ConfiguringWidget/SelectParentRow'
import { recordAppElementDefaultAttributes } from '../../../utils/constant/enums/Aggregate'
import { customFieldBaseTypes } from '../../../utils/constant/enums/customFields'
import { CustomFieldsListMessage, CustomFieldType } from '../../../utils/types/CustomFieldsType'
import FormulasList from './FormulasList'

interface CustomFieldFormulaType {
  setShowFormulaError: (item: any) => void;
  showFormulaError: boolean;
  setIsDescriptionChange: (item: any) => void;
  isDescriptionChange: boolean;
  isDisabled: boolean;
  aggregateCustomField: CustomFieldType;
  setAggregateCustomField: (value: number) => void;
  customFieldsObject: CustomFieldsListMessage;
  currentItem: WorkspaceType | StationType | ListType;
  fieldStatus: any;
}

const CustomFieldFormulaOptions: FC<CustomFieldFormulaType> = ({
  isDisabled,
  setIsDescriptionChange,
  isDescriptionChange,
  showFormulaError,
  currentItem,
  customFieldsObject,
  aggregateCustomField,
  setAggregateCustomField,
  fieldStatus,
}) => {
  const { t } = useTranslation('customFields')
  const [field, setField] = useState<any>(null)
  const dispatch = useDispatch()
  const formulaList = useFormulaList()
  const customFields = useElementCustomFields()
  const customFieldsList = Object.keys(customFieldsObject).length === 0 ? [] : [...customFieldsObject?.native?.fields]

  const defaultOptions = [
    {
      label: t('validation:fieldNames.completedIn'),
      targetAttributeType: AppElementAttributeType.defaultAttribute,
      value: recordAppElementDefaultAttributes.completedIn,
    },
  ]
  const fieldsOptions = customFieldsList
    .filter((f) => f.baseType === customFieldBaseTypes.Number || f.baseType === customFieldBaseTypes.Formula)
    .map((item) => ({
      ...item,
      label: item.fieldName,
      targetAttributeType: AppElementAttributeType.customField,
      value: +item.id,
    }))

  const options = [...defaultOptions, ...fieldsOptions]

  useEffect(() => {
    dispatch(getElementCustomFields(+currentItem?.appElements[0]?.id))
  }, [])

  useEffect(() => {
    if (isDisabled && aggregateCustomField?.replacementAttributeId) {
      const selectedField = options.find((op) => +op.value === +aggregateCustomField?.replacementAttributeId)
      setField(selectedField)
    }
  }, [isDisabled])

  return (
    <div className={classnames('w-full overflow-y-auto', { 'disabled-container': isDisabled })}>
      {fieldStatus.isReplacement && (
        <>
          <p className="mt-3 text-sm font-bold">{t('selectReplacementField')}</p>
          <CreatableSelect
            className="border-none mb-4"
            classNamePrefix="custom-field-select"
            components={{
              Option: SelectParentRow,
            }}
            isDisabled={isDisabled}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            name="customFieldType"
            options={options}
            placeholder={t('customFields:select')}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={field}
            onChange={(values: any) => {
              setField(values)
              setAggregateCustomField({
                ...aggregateCustomField,
                replacementAttributeId: +values.value,
                replacementAttributeType: values.targetAttributeType,
                targetAttributeId: +values.value,
                targetAttributeType: values.targetAttributeType,
              })
            }}
          />
        </>
      )}
      {formulaList?.length > 0 && (
        <FormulasList
          customFields={customFields}
          formulaList={formulaList}
          isDescriptionChange={isDescriptionChange}
          setIsDescriptionChange={setIsDescriptionChange}
        />
      )}
      {showFormulaError && <p className="text-sm text-danger">{t('emptyFormulasErrorMessage')}</p>}
      {!isDisabled && !isDescriptionChange && (
        <div
          className="mb-2 font-semibold cursor-pointer hover:text-primary"
          onClick={(e) => {
            e.stopPropagation()
            const defaultFormula = {
              formula: '',
              isDefault: formulaList?.length === 0,
              name: 'Default Formula',
            }
            dispatch(setFormulaList([...formulaList, defaultFormula]))
          }}>
          +<span className="m-1 text-sm">{t('addFormula')}</span>
        </div>
      )}
    </div>
  )
}

export default CustomFieldFormulaOptions
