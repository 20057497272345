import {
  CalendarCheckIcon,
  CalendarFilledBlueIcon,
  CalendarWithNumberIcon,
  CalendarWrongIcon,
  ClockWiseIcon,
  FileTextIcon,
  WarningIcon,
} from 'components/Icons'
import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-use'
import { arSA } from 'date-fns/locale'
import DashboardStateWidgetType, { StationSummary, TasksSummary } from 'utils/types/states/DashboardStateWidgetType'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { getFormattedCompletion, getFormattedDateWithLocale } from 'utils/helpers/generalHelpers'
import CustomScroll from 'components/CustomScroll'
import WidgetImage from '../../WidgetImage'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'
import OverviewWidgetCard from './OverviewWidgetCard'

const createOverviewWidgetArr = (widgetData: DashboardStateWidgetType, t: any, isRtl: boolean) => {
  const data: any = widgetData?.data

  if (!data) {
    return []
  }
  const isSummary = widgetData?.baseType === DashboardWidgetBaseTypes?.StationOverview

  // for some reason 'const summaryWidget = widgetData.data as StationSummary' doesn't work. Prettier error raised
  // I tried to fix in prettier and eslint config but nothing worked.That's why I use this workaround
  const summaryWidget: StationSummary = data
  const tasksWidget: TasksSummary = data

  return [
    {
      Icon: isSummary ? CalendarFilledBlueIcon : FileTextIcon,
      iconBgColor: '#DDE5ED',
      isTask: !isSummary,
      subtitle: isSummary
        ? summaryWidget.startDate
          ? getFormattedDateWithLocale(summaryWidget.startDate, 'd/MM/yyyy', isRtl ? arSA : undefined)
          : t('notAvailable')
        : tasksWidget.myTasks,
      title: isSummary ? t('startDate') : t('myTasks'),
    },
    {
      Icon: isSummary ? CalendarCheckIcon : WarningIcon,
      iconBgColor: isSummary ? '#DDE5ED' : '#FFE7EE',
      isTask: !isSummary,
      subtitle: isSummary
        ? summaryWidget.endDate
          ? getFormattedDateWithLocale(summaryWidget.endDate, 'd/MM/yyyy', isRtl ? arSA : undefined)
          : t('notAvailable')
        : tasksWidget.overdue,
      title: isSummary ? t('endDate') : t('overdue'),
    },
    {
      Icon: isSummary ? ClockWiseIcon : CalendarWithNumberIcon,
      iconBgColor: '#DDE5ED',
      isTask: !isSummary,
      subtitle: isSummary ? summaryWidget.duration || t('notAvailable') : tasksWidget.dueSoon,
      title: isSummary ? t('duration') : t('dueSoon'),
    },
    {
      Icon: CalendarWrongIcon,
      iconBgColor: isSummary ? '#DDE5ED' : '#DDE5ED',
      isChart: isSummary,
      isTask: !isSummary,
      subtitle: isSummary ? getFormattedCompletion(summaryWidget.completed) : tasksWidget.noDueDate,
      title: isSummary ? t('completed') : t('noDueDate'),
    },
  ]
}
// Since we have two types of widgets that similar I've created universal component
const OverviewWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  isPrinting: boolean,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage, isPrinting }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const { t, i18n } = useTranslation('dashboard')
  const [isLoading, setIsLoading] = useState(true)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const isRtl = i18n.language === 'ar'
  const isMobile = elementWidth <= 500
  const middleWidth = elementWidth < 800 && !isMobile

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id, widget])
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null
  return (
    <>
      {sized}
      {isDataLoading ? (
        <Loader />
      ) : (
        <div style={{ height: 'calc(100% - 36px)' }}>
          <CustomScroll maxHeight={'100%'} hideScroll={isPrinting}>
            {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
              <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
            )}
            <div
              className={`${isMobile ? 'flex-col' : middleWidth ? 'grid grid-cols-3' : 'flex flex-1'} h-full lg:gap-4`}
              style={{ width: '100%' }}>
              {createOverviewWidgetArr(widget || widgetInfo, t, isRtl).map((s, idx) => (
                <OverviewWidgetCard
                  key={s.title + idx}
                  Icon={s.Icon}
                  customWidth={!middleWidth && !isMobile && elementWidth < 1075}
                  iconBgColor={s.iconBgColor}
                  isChart={s.isChart}
                  isMobile={isMobile}
                  isTask={s.isTask}
                  middleWidth={middleWidth}
                  parentHeight={widgetInfo.dimensions.h}
                  parentWidth={elementWidth}
                  subTitle={{ color: '#1b1b1b', iconBgColor: s.iconBgColor, title: s.subtitle }}
                  title={s.title}
                />
              ))}
            </div>
          </CustomScroll>
        </div>
      )}
    </>
  )
}

export default memo(OverviewWidget)
