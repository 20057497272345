import { setLocale } from 'yup'
import i18n from 'i18next'

setLocale({
  mixed: {
    required: (error: { path: string }) =>
      i18n.t(`validation:errors.requiredField`, {
        name: i18n.t(`validation:fieldNames.${error.path}`, i18n.t(`validation:fieldNames.field`)),
      }),
  },
  string: {
    matches: (error: { path: string }) => i18n.t(`validation:errors.${error.path}Regex`),
    max: (error: { path: string, max: number }) =>
      i18n.t(`validation:errors.maxLength`, {
        max: error.max,
        name: i18n.t(`validation:fieldNames.${error.path}`, i18n.t(`validation:fieldNames.field`)),
      }),
    trim: (error: { path: string }) => {
      if (error.path === 'password') {
        return i18n.t('validation:errors.trimPasswordError')
      }
      return i18n.t(`validation:errors.trimError`, {
        name: i18n.t(`validation:fieldNames.${error.path}`, i18n.t(`validation:fieldNames.field`)),
      })
    },
  },
})
