import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ActivityLogType from 'utils/types/ActivityLogType'
import { getFormattedDateWithTime } from 'utils/helpers/generalHelpers'
import { ActivityActions } from 'utils/constant/enums/notifications'
import { useCurrentUser, useUserById } from 'features/user'

import UserAvatarStatus from '../../UserAvatar/UserAvatarStatus'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import Archive from './Archive'

interface ActivityItemProps {
  activity: ActivityLogType;
  isNotification?: boolean;
  isWidget?: boolean;
  onUpdateStatus?: (data: any) => void;
  setSelectedRecordId?: (value: any) => void;
}

const ActivityItem: FC<ActivityItemProps> = ({
  activity: originalActivity,
  isNotification = false,
  isWidget = false,
  onUpdateStatus,
  setSelectedRecordId,
}) => {
  const { t, i18n } = useTranslation()
  const language = i18n.language
  const activity =
    originalActivity?.objectData?.length > 0
      ? {
          ...originalActivity,
          objectData: originalActivity.objectData.filter((action: any) => action.key !== 'elementOrder'),
        }
      : originalActivity
  const activityUser = useUserById(activity.userId)
  const user = useCurrentUser()
  const isHijri = user.isHijri

  const selectActivityComponentProps = {
    activity,
    activityUser,
    isHijri,
    isNotification,
    isWidget,
    language,
  }

  const activityList = () => {
    return activity.objectData.map((item: any) => {
      const props = { ...selectActivityComponentProps }
      props.activity = { ...props.activity, objectData: [item] }
      // eslint-disable-next-line react/jsx-key
      return <Update {...props} setSelectedRecordId={setSelectedRecordId} />
    })
  }

  const selectActivityComponent = (key: ActivityActions) => {
    switch (key) {
      case ActivityActions.Create:
        return (
          <Create
            {...selectActivityComponentProps}
            displayName={isNotification || isWidget}
            setSelectedRecordId={setSelectedRecordId}
          />
        )
      case ActivityActions.Update:
        if (activity.objectData[0]?.key) return activityList()
        else return <></>
      case ActivityActions.Delete:
        return <Delete {...selectActivityComponentProps} setSelectedRecordId={setSelectedRecordId} />
      case ActivityActions.Archive:
        return <Archive {...selectActivityComponentProps} setSelectedRecordId={setSelectedRecordId} />
      default:
        break
    }
  }

  return activity?.objectData?.length > 0 ||
    activity?.action === ActivityActions.Create ||
    activity?.action === ActivityActions.Archive ||
    activity?.action === ActivityActions.Delete ? (
    <div key={activity.id} className="flex mt-4 mb-6 last:mb-0">
      {isNotification && <div className="mt-3 rounded-full w-1.5 h-1.5 bg-primary me-2" />}
      <UserAvatarStatus
        imgSrc={activityUser?.avatar || activity?.user?.link}
        overrideCheckingUser
        userId={activityUser?.id || activity?.user?.id}
      />
      <div className="flex-1 ms-1">{selectActivityComponent(activity.action)}</div>
      <div className="flex flex-col items-end justify-between my-2">
        {activity.activityDate && (
          <span className="text-xs">{getFormattedDateWithTime(activity.activityDate, isHijri, language)}</span>
        )}
        {isNotification && (
          <div className=" text-xs cursor-pointer text-primary" onClick={onUpdateStatus}>
            {t('markAsRead')}
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default ActivityItem
