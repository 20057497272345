import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { getScoreColor } from 'utils/helpers/colorHelpers'
import AppElementType from 'utils/types/AppElementType'
import { FormattedGaugeChartDatasetType } from 'utils/types/GaugeChartDataType'
import CircleGauge from './CircleGauge'

const GaugeWidgetSlide: FC<{
  dataset: FormattedGaugeChartDatasetType,
  plugins: any,
  listLink: string,
  isStepsEmpty: boolean,
}> = ({ dataset, plugins, listLink, isStepsEmpty }) => {
  const { t } = useTranslation('dashboard')

  return (
    <div className="relative flex flex-col items-center justify-center" style={{ height: '100%', width: '85%' }}>
      {isStepsEmpty && (
        <div className="absolute z-40 items-center w-full h-full text-center bg-gray-200 rounded opacity-50 text-danger mt-1/2">
          {t('gaugeError')}
        </div>
      )}

      <div style={{ height: 'calc(100% - 40px)', width: '100%' }}>
        <CircleGauge datasets={dataset} plugins={plugins} />
      </div>

      <div
        className="mt-1 text-center"
        style={{
          minHeight: 24,
          width: 180,
        }}
        title={dataset.element.elementName}>
        <div dir="auto">
          <div className="flex items-center justify-center text-sm gap-4">
            <div className="flex flex-col">
              <p>{dataset?.actualLabel || t('completionPercentage')}</p>
              <p>{dataset.actual && dataset.actual !== 0 ? dataset.actual.toFixed(2) : 0}%</p>
            </div>
          </div>
          <div className="mb-2 text-sm rounded-sm">{dataset.label}</div>
        </div>

        <NavLink className="font-bold underline text-primary hover:text-primary-light line-clamp-2" to={listLink}>
          {dataset.element.elementName}
        </NavLink>
      </div>
    </div>
  )
}

export default memo(GaugeWidgetSlide)
