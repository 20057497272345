import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { CustomFieldType } from 'utils/types/customFieldsType'
import {
  fetchListById,
  removeListLabel,
  removeListStatus,
  updateList,
  updateListLabel,
  updateListStatus,
  useCurrentListDetails,
} from 'features/list'
import { useAppDispatch } from '../../store'
import ListColorsForm from '../forms/ListColorsForm'
import { labelColors, statusColors } from '../../utils/helpers/colorHelpers'
import { useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'
import { ElementTypeWithColor } from '../forms/AddListToStationForm'
import { ListLabelType, ListStatusType, ListType } from '../../utils/types'
import { getElementsSorted } from '../../utils/helpers/listHelpers'
import { fetchRecordsByParams } from '../../features/record'
import { RECORDS_PER_PAGE } from '../../utils/helpers/recordHelpers'
import { addListValidationSchema } from '../forms/validationSchemas'
import { ListIcon, MultiSelectIcon } from '../Icons'
import ListCustomFieldItem from './ListCustomFieldItem'
import CreateCustomFieldButton from './CreateCustomFieldButton'

const createStatuses = (statuses: ListStatusType[] | ListLabelType[]): ElementTypeWithColor[] =>
  statuses?.map(({ color, name, id, ...rest }: ListStatusType | ListLabelType) => ({
    color: color,
    element: name,
    id: id,
    isValid: true,
    position: 'position' in rest ? rest.position : undefined,
  }))

const ListCustomFieldsForm: FC = ({ closePanel, isViewer }) => {
  const { stationId, listId }: { stationId?: string, listId?: string } = useParams()
  const { t } = useTranslation('customFields')
  const listDetails = useCurrentListDetails()
  const list = { ...listDetails, stationId }
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const listCustomFields = listDetails?.customFields
  const [labels, setLabels] = useState<ElementTypeWithColor[]>(createStatuses(listDetails?.labels || []))
  const [statuses, setStatuses] = useState<ElementTypeWithColor[]>(createStatuses(listDetails?.statuses || []))
  const { errors, handleChange, handleSubmit, handleBlur, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: list.description,
      name: list.name,
      statuses: list.statuses,
    },
    onSubmit: (values) => {
      const {
        newElements: newStatuses,
        removedElements: removedStatuses,
        unchangedElements: unchangedStatuses,
        updatedElements: updatedStatuses,
      } = getElementsSorted({ allPreviousElements: list.statuses, allSelectedElements: statuses })

      const {
        newElements: newLabels,
        removedElements: removedLabels,
        unchangedElements: unchangedLabels,
        updatedElements: updatedLabels,
      } = getElementsSorted({ allPreviousElements: list.labels, allSelectedElements: labels })

      const body: Partial<ListType> = {
        ...values,
        description: values.description?.trim(),
        id: list.id,
        labels: [...unchangedLabels, ...updatedLabels, ...newLabels],
        stationId: +list.stationId,
        statuses: [...unchangedStatuses, ...updatedStatuses, ...newStatuses],
      }
      updatedStatuses.map(({ id, color }) => {
        const previousStatus = list.statuses.find(({ id: previousId }) => id === previousId)
        dispatch(updateListStatus({ changes: { color }, status: previousStatus }))
      })
      updatedLabels.map(({ id, color }) => {
        const previousLabel = list.labels.find(({ id: previousId }) => id === previousId)
        dispatch(updateListLabel({ changes: { color }, label: previousLabel }))
      })

      removedStatuses.map(({ id }, index) =>
        appDispatch(removeListStatus(id)).then(() => {
          if (index === removedStatuses.length - 1) {
            dispatch(
              fetchRecordsByParams({
                archiveStatus: list?.appElement?.isArchived,
                limit: RECORDS_PER_PAGE,
                listId: list.id,
              }),
            )
          }
        }),
      )
      removedLabels.map(({ id }) => dispatch(removeListLabel(id)))
      const updatedList: ListType = {
        ...listDetails,
        customFields: listCustomFields,
        labels: body.labels,
        statuses: body.statuses,
      }
      dispatch(updateList({ changes: body, list: updatedList }))
      closePanel()
    },
    validationSchema: addListValidationSchema,
  })

  const onRefetch = () => {
    if (listDetails) appDispatch(fetchListById(+listDetails.id))
  }

  useEffect(() => {
    if (listId)
      appDispatch(fetchListById(+listId)).then((res) => {
        setLabels(createStatuses(res.payload?.labels || []))
        setStatuses(createStatuses(res.payload?.statuses || []))
      })
  }, [listId])

  return (
    <div className="w-full h-full p-8 overflow-hidden">
      <div className="overflow-y-auto h-5/6">
        <div className="flex items-center justify-between">
          <label className="block mb-5 text-lg font-bold">{t('customFields:fields')}</label>
          {isPremiumPlan && !isViewer && (
            <CreateCustomFieldButton
              currentItem={listDetails}
              elementBaseType={AppElementBaseTypes.ListAppElement}
              showIcon={true}
              showText={false}
              onRefetch={onRefetch}
            />
          )}
        </div>
        <ListColorsForm
          colors={statusColors}
          isStatuses
          label={t('records:listOfStatuses')}
          localValues={statuses}
          options={{ Icon: ListIcon, label: t('lists:selection(default)') }}
          placeholder={t('lists:addNewStatus')}
          setLocalValues={setStatuses}
          onSubmit={handleSubmit}
          isViewer={isViewer}
        />
        <ListColorsForm
          colors={labelColors}
          isStatuses={false}
          label={t('records:listOfLabels')}
          localValues={labels}
          options={{ Icon: MultiSelectIcon, label: t('lists:multiSelection(default)') }}
          placeholder={t('lists:addNewLabel')}
          setLocalValues={setLabels}
          onSubmit={handleSubmit}
          isViewer={isViewer}
        />
        {isPremiumPlan && (
          <>
            {listCustomFields?.native?.count > 0 && (
              <div className="overflow-y-auto lg:max-h-3/6">
                {listCustomFields?.native?.fields?.map((field: CustomFieldType, index: number) => (
                  <>
                    <ListCustomFieldItem
                      key={`${index} ${field.id}`}
                      currentItem={listDetails}
                      customFieldItem={field}
                      elementBaseType={AppElementBaseTypes.ListAppElement}
                      item={listDetails?.id}
                      onRefetch={onRefetch}
                      isViewer={isViewer}
                    />
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ListCustomFieldsForm
