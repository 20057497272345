import DOMPurify from 'dompurify'
import { CalendarCardIcon, ClockWiseIcon } from 'components/Icons'
import { getPublicDocsOneItem } from 'features/publicDocs/actions'
import { updatePublicDocsVisitedDoc } from 'features/publicDocs/publicDocsSlice'
import { usePublicDocsOneItem, usePublicDocsOneItemLoading } from 'features/publicDocs/selectors'
import {
  getStationDocOneItem,
  getStationDocSidebar,
  useStationDocumentationAttachmentsOpen,
  useStationDocumentationCommentsOpen,
  useStationDocumentationInfoOpen,
  useStationDocumentationLinksOpen,
  useStationDocumentationOneItem,
  useStationDocumentationOneItemLoading,
  useStationDocumentationSidebar,
} from 'features/station'
import {
  currentOpenSidebarItems,
  toggleDocumentationLinks,
  updateDocumentationVisitedDoc,
} from 'features/station/stationSlice'
import React, { FC, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getDateFormat } from 'utils/helpers/generalHelpers'
import { DocumentationDocumentTextSelectionType } from 'utils/types/states'
import { useAppDispatch } from '../../../store'
import DocumentationDocumentPropertiesPanel from './DocumentationDocumentPropertiesPanel'
import DocumentationDocumentComments from './DocumentationDocumentComments'
import DocumentationDocumentAttachments from './DocumentationDocumentAttachments'
import DocumentationDocumentInfo from './DocumentationDocumentInfo'
import DocumentViewLoader from './DocumentViewLoader'
import DocumentationDocumentLinks from './DocumentationDocumentLinks'

const DocumentationDocumentView: FC<{ isPublic?: boolean }> = ({ isPublic }) => {
  const { stationId, id, publicId } = useParams<{
    stationId: string,
    workspaceId: string,
    docId: string,
    id: string,
    publicId: string,
  }>()
  const page = useStationDocumentationOneItem()
  const documents = useStationDocumentationSidebar()
  const pageLoading = useStationDocumentationOneItemLoading()
  const publicPage = usePublicDocsOneItem()
  const publicLoading = usePublicDocsOneItemLoading()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { location } = useHistory()
  const openComments = useStationDocumentationCommentsOpen()
  const isRTL = i18n.language === 'ar'
  const ref = useRef<any>(null)
  const appDispatch = useAppDispatch()
  const [selected, setSelected] = useState<DocumentationDocumentTextSelectionType | null>(null)
  const openAttachments = useStationDocumentationAttachmentsOpen()
  const [showSelections, setShowSelections] = useState(true)
  const openInfo = useStationDocumentationInfoOpen()
  const openLinks = useStationDocumentationLinksOpen()
  const renderedPath = page?.renderedPath?.split('.')
  const stationPart = renderedPath?.find((e) => e.indexOf('1:') > -1)
  const stationElementId = stationPart?.split(':')[1]
  const isMobile = window.innerWidth <= 760

  const openSideBar = () => {
    if (documents && documents?.length > 0) {
      const parentsPath = documents
        ?.find((i: any) => +i.id === +id)
        ?.elementPath?.split('.')
        ?.slice(3, -2)
      if (parentsPath) {
        const parentsItems = documents.filter((item: any) => parentsPath.includes(item.id))
        appDispatch(currentOpenSidebarItems(parentsItems))
      }
    }
  }

  useEffect(() => {
    const recordInfo = location?.search?.split('Id=')
    if (recordInfo.length > 1) {
      dispatch(toggleDocumentationLinks())
    }
    if (isPublic) {
      dispatch(getPublicDocsOneItem({ id: +id, publicId }))
      dispatch(updatePublicDocsVisitedDoc({ id: +id }))
    } else {
      dispatch(getStationDocOneItem(+id))
      dispatch(updateDocumentationVisitedDoc({ id: +id }))
      openSideBar()
    }
  }, [id])

  if ((!isPublic && pageLoading) || (isPublic && publicLoading)) {
    return <DocumentViewLoader />
  }

  const currentPage = isPublic ? publicPage : page

  // text highligt set
  /* const handleMouseUp = () => {
    const selObj = window.getSelection()

    if (selObj && selObj.rangeCount !== 0) {
      const range = selObj.getRangeAt(0)
      const cloned = range?.cloneContents().querySelectorAll('*')
      const elemArr = Array.from(cloned)
      const clonedIds = elemArr.map((el) => el.id).filter(Boolean)

      if (clonedIds.length) {
        const text = selObj.toString()
        const firstEl = clonedIds.slice(0)[0]

        const lastWord = text.split(' ').slice(-1)[0]
        const lasEl = clonedIds.slice(-1)[0]

        const endLength = elemArr
          .map((el: any) => {
            if (el.id === lasEl) {
              return el.innerText.lastIndexOf(lastWord) + lastWord.length
            }
          })
          .filter(Boolean)[0]

        const allChildNodes = Array.from(range.commonAncestorContainer.childNodes)
        const firstChild = allChildNodes
          .map((el: any) => {
            if (el.id === firstEl) {
              return el
            }
          })
          .filter(Boolean)[0]

        const startLength = elemArr
          .map((el: any) => {
            if (el.id === firstEl) {
              const wholeText = firstChild.innerText || ''
              const selectedText = el.innerText
              const indexOfSelected = wholeText.indexOf(selectedText)

              return indexOfSelected
            }
          })
          .filter((el) => Number.isInteger(el))[0]
        const { x, y } = firstChild.getBoundingClientRect()
        setSelected({
          elementsBetween: clonedIds,
          end: endLength,
          popupX: x,
          popupY: y,
          start: startLength,
          text: selObj.toString(),
        })
      } else {
        if (range.startContainer.parentElement) {
          if (range.startOffset === range.endOffset) {
            return
          }

          const { x, y } = range.startContainer.parentElement.getBoundingClientRect()

          setSelected({
            elementsBetween: [range.startContainer.parentElement.id],
            end: range.endOffset,
            popupX: x,
            popupY: y,
            start: range.startOffset,
            text: selObj.toString(),
          })
        }
      }
    }
  } */

  // add text highlight into render
  const addSpan = (content: any) => {
    const placeholder = document.createElement('span')
    placeholder.insertAdjacentHTML('afterbegin', content)
    const children = Array.from(placeholder.children)
    let newChildren = [...children]

    if (currentPage && currentPage.selections && showSelections) {
      const wereInPrev: Record<string, { id: string, start: number, end: number }> = {}
      currentPage.selections.map((selection) => {
        newChildren = children.map((el: any) => {
          if (selection.elementsBetween.includes(el.id)) {
            const start = wereInPrev[el.id] ? wereInPrev[el.id].start : selection.start
            const end = wereInPrev[el.id] ? wereInPrev[el.id].end : selection.end

            if (selection.elementsBetween.length !== 1) {
              if (selection.elementsBetween.slice(-1)[0] === el.id) {
                const substrToHighlight = el.innerText.slice(0, end)
                const otherSubStr = el.innerText.slice(end)
                const addSpanTohighlighted = `<span id="${el.id}" style="border-bottom: 3px solid yellow; background: #ffffc6">${substrToHighlight}</span>`
                el.innerHTML = addSpanTohighlighted + otherSubStr
              } else if (selection.elementsBetween.slice(0, 1)[0] === el.id) {
                const substrToHighlight = el.innerText.slice(start)
                const otherSubStr = el.innerText.slice(0, start)
                el.innerHTML = `${otherSubStr}<span id="${el.id}" style="border-bottom: 3px solid yellow; background: #ffffc6">${substrToHighlight}</span>`
              } else {
                el.innerHTML = `<span id="${el.id}" style="border-bottom: 3px solid yellow; background: #ffffc6">${el.innerHTML}</span>`
              }
            } else {
              const substrToHighlight = el.innerText.slice(start, end)
              const beforeHighlight = el.innerText.slice(0, start)
              const afterHighlight = el.innerText.slice(end)
              el.innerHTML = `${beforeHighlight}<span id="${el.id}" style="border-bottom: 3px solid yellow; background: #ffffc6">${substrToHighlight}</span>${afterHighlight}`
            }

            if (!wereInPrev[el.id]) {
              wereInPrev[el.id] = { end: selection.end, id: el.id, start: selection.start }
            } else {
              if (wereInPrev[el.id].start > selection.start) {
                wereInPrev[el.id].start = selection.start
              }
              if (wereInPrev[el.id].end < selection.end) {
                wereInPrev[el.id].end = selection.end
              }
            }
          }

          return el
        })
      })
    }

    return newChildren
      .map((el) => {
        return el.outerHTML
      })
      .join('')
  }

  const renderHtml = (content: any) => {
    return {
      __html: DOMPurify.sanitize(addSpan(content), {
        ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        ADD_TAGS: ['iframe'],
        FORCE_BODY: true,
        SAFE_FOR_TEMPLATES: true,
      }),
    }
  }

  return currentPage ? (
    <div className="w-full h-full overflow-y-auto border-none">
      <div className="relative flex justify-center h-full bg-white mt-0.5">
        <div className="relative z-0 justify-center w-full pt-16 px-2.5" style={{ width: isMobile ? '90%' : '720px' }}>
          <div className="flex items-center justify-between">
            <h1 className="my-6 text-gray-800">{currentPage.title}</h1>
            {/*{currentPage.selections && (*/}
            {/*  <Button small variant={ButtonVariant.Secondary} onClick={() => setShowSelections(!showSelections)}>*/}
            {/*    {showSelections ? t('documentation:hideHighlights') : t('documentation:showHighlights')}*/}
            {/*  </Button>*/}
            {/*)}*/}
          </div>

          {currentPage.author && (
            <div className="flex items-center mb-6 text-sm gap-6">
              <div className="flex items-center gap-2">
                <div className="w-6 h-6">
                  <img
                    alt={currentPage.author.firstName + currentPage.author.lastName}
                    className="rounded-full"
                    height={'100%'}
                    src={currentPage.author.link || '/assets/images/placeholder.svg'}
                    width={'100%'}
                  />
                </div>
                <p>{currentPage.author.firstName + ' ' + currentPage.author.lastName}</p>
              </div>
              <div className="flex items-center gap-2">
                <CalendarCardIcon width={20} />
                {currentPage.createdAt && (
                  <p>{t('common:labels.createdAt') + ' ' + getDateFormat(isRTL, currentPage.createdAt || '')}</p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <ClockWiseIcon width={20} />
                {currentPage.updatedAt && (
                  <p>{t('common:labels.updatedAt') + ' ' + getDateFormat(isRTL, currentPage.updatedAt || '')}</p>
                )}
              </div>
            </div>
          )}
          <div
            className={`ck-view text-gray-700 leading-relaxed pb-32 ${selected ? 'select-none' : ''}`}
            dangerouslySetInnerHTML={renderHtml(currentPage.content || '')}
            ref={ref}
            /* onClick={(e: any) => {
            if (e.target.tagName === 'SPAN' && currentPage.selections) {
              if (e.target.id) {
                dispatch(openDocumentationComments())
              }
            }
          }}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseUp} */
          />
          {/*{selected && <TextSelectPopup clearSelected={() => setSelected(null)} documentId={id} selected={selected} />}*/}
        </div>
        <div className={`fixed ${!isRTL ? 'right-0' : 'left-0'} lg:z-10 h-full bg-white`}>
          {openComments && (
            <DocumentationDocumentPropertiesPanel>
              <DocumentationDocumentComments documentId={id} />
            </DocumentationDocumentPropertiesPanel>
          )}
          {openAttachments && (
            <DocumentationDocumentPropertiesPanel>
              <DocumentationDocumentAttachments documentId={id} />
            </DocumentationDocumentPropertiesPanel>
          )}
          {openInfo && (
            <DocumentationDocumentPropertiesPanel>
              <DocumentationDocumentInfo documentId={id} stationId={stationId} />
            </DocumentationDocumentPropertiesPanel>
          )}
          {openLinks && (
            <DocumentationDocumentPropertiesPanel>
              <DocumentationDocumentLinks documentId={id} stationId={stationElementId} />
            </DocumentationDocumentPropertiesPanel>
          )}
        </div>
      </div>
    </div>
  ) : (
    <DocumentViewLoader />
  )
}

export default memo(DocumentationDocumentView)
