import React, { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import SingleComment from 'components/CommentList/SingleComment'
import ActivityItem from 'components/Activities/Activity/Item'
import ActivitiesList from 'components/Activities/ActivitiesList'
import CommentList from 'components/CommentList'
import { SortAscendingIcon, SortDescendingIcon } from 'components/Icons'
import { ActivityWidgetWithSortType } from 'utils/types/states/DashboardStateWidgetType'
import { AddCommentForm, AddRecordToListForm } from 'components/forms'
import { breakpoints } from 'utils/constant/constant/common'
import { useActiveListView } from 'features/list'
import { useHistory } from 'react-router-dom'
import CustomScroll from '../CustomScroll'
import AttachmentList from '../AttachmentList'
import { ListType, RecordType, StationType, UploadingFilesType } from '../../utils/types'
import RequestsList from '../MakerCheckerPolicy/Request/RequestsList'
import { fetchRecordById } from '../../features/record'
import { updateCurrentListDetails } from '../../features/list/listSlice'
import { useAppDispatch } from '../../store'
import { usePanel } from '../../contexts/PanelContext'
import userType from '../../utils/types/UserType'
import { useCurrentUser } from '../../features/user'
import { getUserPermissionFromAccessGroup } from '../../utils/helpers/generalHelpers'
import { refetchAfterUpdate } from '../../features/element/elementSlice'

const sortByDate = (arr: any[], showLatest: boolean) => {
  if (!arr) {
    return undefined
  }
  return arr.slice().sort((a, b) => {
    const aDate = a.updatedAt ? new Date(a.updatedAt) : new Date(a.activityDate)
    const bDate = b.updatedAt ? new Date(b.updatedAt) : new Date(b.activityDate)

    if (showLatest) {
      return bDate.getTime() - aDate.getTime()
    }

    return aDate.getTime() - bDate.getTime()
  })
}

interface ActivityFormProps {
  recordId?: number;
  data?: ActivityWidgetWithSortType;
  isHomeDashboard?: boolean;
  isRecordEditable?: boolean;
  isWidget?: boolean;
  elementId: string;
  isPrinting?: boolean;
  filesBeingUploaded: UploadingFilesType;
  filesBeingUploadedRef: any;
  inputProps: () => void;
  isEditor: boolean;
  isRecord: boolean;
  needForApproval?: boolean;
  isMakerCheckerApplied?: boolean;
  currentItem: RecordType | ListType | StationType;
  refetchFunction: () => void;
  isRecordComment?: boolean;
}

export const getButtonClass = (isActive: boolean) => {
  const activeButtonClass = 'text-white bg-gray-600 me-2 hover:bg-gray-700'
  return `px-2 py-1 text-sm font-bold rounded-sm transition-colors ${
    isActive ? activeButtonClass : 'bg-gray-200 me-2 hover:bg-gray-300'
  }`
}
const ActivityForm: FC<ActivityFormProps> = ({
  recordId,
  data,
  isHomeDashboard,
  isRecordEditable,
  isWidget = false,
  elementId,
  isPrinting = false,
  filesBeingUploaded,
  filesBeingUploadedRef,
  inputProps,
  isEditor,
  isRecord = false,
  needForApproval = false,
  currentItem,
  isMakerCheckerApplied,
  refetchFunction,
  isRecordComment,
}) => {
  const dispatch = useAppDispatch()
  const user = useCurrentUser()
  const { push } = useHistory()
  const [showLatest, setShowLatest] = useState<boolean>(true)
  const [showComments, setShowComments] = useState<boolean>(!data)
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [showٍRequests, setShowٍRequests] = useState<boolean>(false)
  const [showAll, setShowAll] = useState<boolean>(!!data)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const { setPanelContent, openPanel } = usePanel()
  const [showAttachments, setShowAttachments] = useState<boolean>(false)
  const { t } = useTranslation(['records', 'noAccess', 'dashboard'])
  const isMobile = window.innerWidth < breakpoints.md
  let currentView = useActiveListView()
  if (!isRecord) currentView = undefined

  useEffect(() => {
    if (selectedRecordId) {
      push(`?recordId=${selectedRecordId}`)
      dispatch(fetchRecordById({ details: true, id: selectedRecordId })).then((res) => {
        if (res.payload) {
          dispatch(updateCurrentListDetails(res.payload.list))
          const currentRecord = res?.payload
          const currentUser = res?.payload?.list?.listMembers.find(
            (userData: userType) => Number(userData.userId) === Number(user.id),
          )
          const isEditorUser = getUserPermissionFromAccessGroup(currentUser.accessGroupId, 'Editor')
          const isAdminUser = getUserPermissionFromAccessGroup(currentUser.accessGroupId, 'Admin')
          setPanelContent({
            content: (
              <AddRecordToListForm
                isAdmin={isAdminUser}
                isDetailedRecord={true}
                isEditor={isEditorUser}
                listId={+currentRecord?.listId}
                partialRecord={currentRecord}
                recordId={+currentRecord.id}
              />
            ),
            header: currentRecord.name,
            isBig: true,
            isRecordEditable:
              getUserPermissionFromAccessGroup(currentUser?.accessGroupId) &&
              !currentRecord?.appElements[0]?.isArchived,
            partialRecord: currentRecord,
          })
          openPanel()
          dispatch(refetchAfterUpdate(() => refetchFunction()))
          setSelectedRecordId(null)
        }
      })
    }
  }, [selectedRecordId])

  const showData = () => {
    const activities = data?.activities ? data.activities : []
    if (showAll) {
      if (data) {
        const comments = data.comments ? data.comments : []
        const allData = [...comments, ...activities]
        const sortedAll = sortByDate(allData, showLatest)

        return (
          <div>
            {sortedAll?.length === 0 ? (
              <h2 className="mt-6 text-center opacity-50">{t('dashboard:notHaveData')}</h2>
            ) : (
              <>
                {sortedAll &&
                  sortedAll.map((item) => {
                    if (item.updatedAt && item.creatorId) {
                      return (
                        <SingleComment
                          key={item.id + item.updatedAt}
                          comment={item}
                          elementId={elementId}
                          isActivityWidget={true}
                          isEdit={false}
                          isRecordComment={isRecordComment}
                          recordId={recordId}
                          showLatest={showLatest}
                        />
                      )
                    }

                    if (item.recordId === null) {
                      return (
                        <div key={item.id} style={{ color: 'inherit' }}>
                          <ActivityItem activity={item} isWidget={isWidget} setSelectedRecordId={setSelectedRecordId} />
                        </div>
                      )
                    }

                    return (
                      <div
                        key={item.id}
                        style={{ color: 'inherit' }}
                        onClick={() => setSelectedRecordId(item.recordId)}
                        // to={`/workspace/${item.workspaceId}/stations/${item.stationId}/lists/${item.listId}?recordId=${item.recordId}`}
                      >
                        <ActivityItem activity={item} isWidget={isWidget} setSelectedRecordId={setSelectedRecordId} />
                      </div>
                    )
                  })}
              </>
            )}
          </div>
        )
      }
      return <></>
    }
    if (showComments) {
      return recordId ? (
        <>
          {isRecord && <AddCommentForm elementId={elementId} recordId={recordId} showLatest={showLatest} />}
          <CommentList
            elementId={elementId}
            isRecordEditable={isRecordEditable}
            recordId={recordId}
            showLatest={showLatest}
            isRecordComment={isRecordComment}
          />{' '}
        </>
      ) : (
        <div className="mt-6">
          {!data?.comments ? (
            <h2 className="mt-4 text-center opacity-50">{t('dashboard:notHaveData')}</h2>
          ) : (
            <CommentList
              dataInsteadRecordId={data ? sortByDate(data.comments, showLatest) : undefined}
              elementId={elementId}
              isRecordEditable={isRecordEditable}
              showLatest={showLatest}
            />
          )}
        </div>
      )
    }
    if (showٍRequests && isMakerCheckerApplied) {
      return (
        <>
          <RequestsList
            currentItem={currentItem}
            elementId={elementId}
            needForApproval={needForApproval}
            showLatest={showLatest}
          />
        </>
      )
    }
    if (showAttachments && isRecord) {
      return (
        <AttachmentList
          filesBeingUploaded={filesBeingUploaded}
          filesBeingUploadedRef={filesBeingUploadedRef}
          inputProps={inputProps}
          isEditor={isEditor}
          recordId={recordId}
        />
      )
    }
    return (
      <div className="mt-6">
        <ActivitiesList
          currentView={currentView}
          dataInsteadRecordId={data ? sortByDate(activities, showLatest) : undefined}
          resourceId={recordId}
          resourceType={3}
          setSelectedRecordId={setSelectedRecordId}
          showLatest={showLatest}
        />
      </div>
    )
  }

  return (
    <>
      {!data && (
        <h5 className="block mt-12 mb-2 font-bold md:mt-8" id="activity">
          {t('records:listing.activity')}
        </h5>
      )}
      <div className="flex">
        {data && (
          <button
            className={getButtonClass(showAll)}
            onClick={() => {
              setShowAll(true)
              setShowComments(false)
              setShowAttachments(false)
              setShowٍRequests(false)
              setShowHistory(false)
            }}>
            {t('records:listing.all')}
          </button>
        )}
        <button
          className={getButtonClass(showComments)}
          onClick={() => {
            setShowAll(false)
            setShowHistory(false)
            setShowAttachments(false)
            setShowٍRequests(false)
            setShowComments(true)
          }}>
          {t('records:listing.comments')}
        </button>
        <button
          className={getButtonClass(showHistory)}
          onClick={() => {
            setShowAll(false)
            setShowComments(false)
            setShowAttachments(false)
            setShowٍRequests(false)
            setShowHistory(true)
          }}>
          {t('records:listing.history')}
        </button>
        {isMakerCheckerApplied && (
          <button
            className={getButtonClass(showٍRequests)}
            onClick={() => {
              setShowAll(false)
              setShowHistory(false)
              setShowAttachments(false)
              setShowComments(false)
              setShowٍRequests(true)
            }}>
            {t('records:listing.requests')}
          </button>
        )}
        {/* <button
          className={getButtonClass(showAttachments)}
          onClick={() => {
            setShowAll(false)
            setShowComments(false)
            setShowHistory(false)
            setShowAttachments(true)
          }}>
          {t('records:listing.attachments')}
        </button> */}
        <button
          className="flex items-center py-1 text-sm font-bold ms-auto transition-colors hover:text-primary"
          onClick={() => setShowLatest((prev) => !prev)}>
          {showLatest ? (
            <>
              {!isMobile && t('records:listing.newest')}
              <SortDescendingIcon className="w-6 ms-1" />
            </>
          ) : (
            <>
              {!isMobile && t('records:listing.oldest')}
              <SortAscendingIcon className="w-6 ms-1" />
            </>
          )}
        </button>
      </div>
      {data ? (
        <div className="h-full mt-6 overflow-hidden">
          <CustomScroll hideScroll={isPrinting} maxHeight={isHomeDashboard ? '35vh' : '90%'}>
            {showData()}
          </CustomScroll>
        </div>
      ) : (
        showData()
      )}
    </>
  )
}

export default ActivityForm
