import React, { FC, useMemo } from 'react'
import { ProfilePropsType } from 'utils/types'
import { useTranslation } from 'react-i18next'

import { AvatarInput, Dropdown, Input } from 'components/inputs'

import { UpdateUserType } from 'utils/types'
import { withButtonsAndContentEditable, withContentEditable } from 'hocs'
import { getCalendarOptions, renderDropdownValue } from 'utils/helpers/profileHelpers'

import {
  emailValidationSchema,
  phoneNumberValidationSchema,
  requiredTextFieldValidationSchema,
} from '../validationSchemas/singleFieldsValidationSchemas'

type PersonalProfileFormPropTypes = {
  user: ProfilePropsType,
  updateUser: (values: UpdateUserType) => void,
  updateAvatar: (avatar: { filename: string, base64data: string }) => void,
}

const EditableInputWithButtons = withButtonsAndContentEditable(Input)
const EditableDropdownWithButtons = withContentEditable(Dropdown)

const PersonalProfileForm: FC<PersonalProfileFormPropTypes> = ({ user, updateUser, updateAvatar }) => {
  const { t } = useTranslation()
  const { firstName, lastName, email, avatar, phoneNumber, isHijri } = user
  const calendarOptions = useMemo(() => getCalendarOptions(), [])

  const commonProps = {
    isLabelVisible: true,
    onSubmit: updateUser,
  }

  return (
    <div className="w-full p-8 overflow-auto">
      <h1 className="mb-6 text-lg">{t('user:details:header')}</h1>
      <AvatarInput avatar={avatar} updateAvatar={updateAvatar} />
      <div className="md:flex">
        <div className="md:w-1/2">
          <EditableInputWithButtons
            label={t('labels.firstName')}
            notEditingClassName="mb-8 bg-gray-100"
            style={{ borderColor: '#DDE5ED' }}
            name="firstName"
            validationSchema={requiredTextFieldValidationSchema('firstName')}
            value={firstName}
            {...commonProps}
          />
        </div>
        <div className="md:w-1/2 md:ms-8">
          <EditableInputWithButtons
            label={t('labels.lastName')}
            notEditingClassName="mb-8 bg-gray-100"
            style={{ borderColor: '#DDE5ED' }}
            name="lastName"
            validationSchema={requiredTextFieldValidationSchema('lastName')}
            value={lastName}
            {...commonProps}
          />
        </div>
      </div>
      <div className="md:flex">
        <div className="md:w-1/2">
          <EditableInputWithButtons
            label={t('labels.phoneNumber')}
            notEditingClassName="mb-8 bg-gray-100"
            style={{ borderColor: '#DDE5ED' }}
            name="phoneNumber"
            validationSchema={phoneNumberValidationSchema}
            value={phoneNumber}
            {...commonProps}
          />
        </div>
        <div className="md:w-1/2 md:ms-8">
          <EditableInputWithButtons
            disabled
            label={t('labels.email')}
            name="email"
            validationSchema={emailValidationSchema}
            value={email}
            {...commonProps}
          />
        </div>
      </div>
      <EditableDropdownWithButtons
        label={t('labels.calendarType')}
        name="isHijri"
        options={calendarOptions}
        renderNotEditingState={(value, props) =>
          renderDropdownValue({
            allOptions: calendarOptions,
            label: t('labels.calendarType'),
            props,
            selectedOption: value,
          })
        }
        value={+isHijri}
        {...commonProps}
        onSubmit={(values) => {
          values.isHijri = values.isHijri === '1'
          updateUser(values)
        }}
      />
    </div>
  )
}

export default PersonalProfileForm
