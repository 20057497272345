import React, { FC } from 'react'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'
import { useCurrentListDetails } from '../../../features/list'
import LinkToRecords from './LinkToRecords'

const GridViewEditorCustomField: FC<CellEditorProps<RecordType['customDropdown']>> = ({
  onTabNavigation,
  onComplete,
  currentRecord,
  customFieldInfo,
  cellProps,
  isMulti,
  isEditingAllowed,
  // listId,
}) => {
  const currentListInfo = useCurrentListDetails()
  //to check if the current list is base or target
  const currentListElementId = +currentListInfo?.appElement?.id

  useGridViewNavigation(
    () => onTabNavigation(true, -1),
    () => onTabNavigation(true, 1),
  )
  const isTargetList = currentListElementId === +customFieldInfo?.intermediateAppElementId

  return (
    <div
      className="w-full px-2"
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          e.preventDefault()
        }
      }}>
      <LinkToRecords
        isMulti={isMulti}
        autoFocus={true}
        currentRecord={currentRecord}
        customFieldInfo={customFieldInfo}
        menuPortalTarget={document.body}
        name={`${cellProps.id}`}
        tabIndex={1}
        onComplete={onComplete}
        isEditingAllowed={isEditingAllowed}
        isGridView
      />
    </div>
  )
}

export default GridViewEditorCustomField
