import { FC, useEffect, useRef, useState } from 'react'
import ReactContentEditable from 'react-contenteditable'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import head from 'lodash/head'
import classnames from 'classnames'

import { AddIcon } from 'components/Icons'
import { textFieldValidationSchema } from 'components/forms/validationSchemas/singleFieldsValidationSchemas'
import SettingsDropdown from 'components/SettingsDropdown'
import { addList, importRecords } from 'features/list'
import { getValueFromEvent } from 'utils/helpers/generalHelpers'
import { withEditorRole } from 'hocs/withRole'
import { useAppDispatch } from 'store'
import { unwrapResult } from '@reduxjs/toolkit'

type AddNewListTabPropsType = {
  listsLength: number,
  setActiveList: (listId: number) => void,
  isAddListVisible: boolean,
  setIsAddListVisible: (value?: any) => void,
}

const AddNewListTab: FC<AddNewListTabPropsType> = ({
  listsLength,
  setActiveList,
  isAddListVisible,
  setIsAddListVisible,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [newName, setNewName] = useState(`List ${listsLength + 1}`)
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const { stationId } = useParams<{ stationId: string }>()
  const inputRef = useRef<HTMLElement | null>()
  const { t, i18n } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (isAddListVisible) {
      inputRef.current?.focus()
    }
  }, [isAddListVisible])

  const addNewList = () => {
    textFieldValidationSchema('name', 100)
      .isValid(newName)
      .then((isValid: boolean) => {
        if (isValid) {
          if (newName !== '') {
            appDispatch(addList({ list: { description: '', name: newName, stationId }, history }))
              .then(unwrapResult)
              .then((result) => {
                setActiveList(result.id)
              })
          }
          setIsAddListVisible(false)
        } else {
          toast.error(
            i18n.t(`validation:errors.maxLength`, {
              max: 100,
              name: i18n.t('validation:fieldNames.name'),
            }),
          )
          inputRef.current?.focus()
        }
      })
  }

  const handleTrelloUpload = (event: any) => {
    const file = head(event?.target?.files)
    dispatch(importRecords({ importFile: file, stationId: +stationId }))
    event.target.value = ''
  }

  const DropdownOptions = ({ closeDropdown }: { closeDropdown?: () => void }) => {
    useEffect(() => {
      setIsDropdownVisible(true)
      return () => setIsDropdownVisible(false)
    }, [])

    const onClose = () => {
      closeDropdown?.()
      setIsDropdownVisible(false)
    }

    return (
      <div className="text-gray-600">
        <button
          className="py-2 hover:text-primary text-start"
          onClick={() => {
            setIsAddListVisible(true)
            onClose()
          }}>
          {t('lists:createList')}
        </button>
        <div>
          <input
            accept=".json"
            id="json-import"
            type="file"
            onChange={(event) => {
              handleTrelloUpload(event)
              onClose()
            }}
          />
          <label className="py-2 hover:text-primary text-start" htmlFor="json-import">
            {t('lists:import')}
          </label>
        </div>
      </div>
    )
  }

  return (
    <>
      {isAddListVisible && (
        <ReactContentEditable
          className="order-last px-4 mx-1 text-sm rounded-t-sm py-1.5 bg-tertiary"
          html={newName}
          innerRef={(ref: HTMLElement) => (inputRef.current = ref)}
          tagName="span"
          onBlur={addNewList}
          onChange={(event) => {
            setNewName(getValueFromEvent(event))
          }}
          onFocus={() => document.execCommand('selectAll', false, undefined)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') addNewList()
          }}
        />
      )}
      <SettingsDropdown
        Icon={AddIcon}
        iconClasses="m-1"
        wrapperClasses={classnames('order-last', {
          'text-sm bg-white rounded-t-sm text-tertiary-light': isDropdownVisible,
        })}>
        <DropdownOptions />
      </SettingsDropdown>
    </>
  )
}

export default withEditorRole(AddNewListTab, false, null)
