import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../inputs'
import Button, { ButtonVariant } from '../Buttons'
import Loader from '../Loader'
import CustomFieldImplementLevel from '../CustomFields/CustomFieldImplementLevel'
import { ListType } from '../../utils/types'
import { onAddMakerChecker, updateElementMakerCheckerPolicy } from '../../features/element'
import { useAppDispatch } from '../../store'
import { AppElementBaseTypes } from '../../utils/types/AppElementType'
import { recordAppElementDefaultAttributes } from '../../utils/constant/enums/Aggregate'
import MakerCheckerType from '../../utils/types/MakerCheckerType'
import MultiSelectUsersWrapper from './MultiSelectUsersWrapper'
import MultiSelectFieldsWrapper from './MultiSelectFieldsWrapper'

type MakerCheckerFormPropsType = {
  isCreatingNew: boolean,
  isDisabled: boolean,
  setIsCreatingNew: (val: boolean) => void,
  setShowAddPolicyForm: (val: boolean) => void,
  currentItem: ListType,
  makerCheckerPolicy: MakerCheckerType,
}

const MakerCheckerForm: FC<MakerCheckerFormPropsType> = ({
  setShowAddPolicyForm,
  isDisabled,
  currentItem,
  makerCheckerPolicy,
  setIsCreatingNew,
  isCreatingNew,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('element')
  const [isOwnerChecker, setIsOwnerChecker] = useState(
    makerCheckerPolicy?.isTargetElementOwnerReviewer !== undefined
      ? makerCheckerPolicy?.isTargetElementOwnerReviewer
      : true,
  )
  const [areAdminsCheckers, setAreAdminsCheckers] = useState(
    makerCheckerPolicy?.isAdminReviewer !== undefined ? makerCheckerPolicy?.isAdminReviewer : true,
  )
  const users = makerCheckerPolicy?.reviewers?.map((u) => ({ value: u.userId, ...u }))
  const fields = makerCheckerPolicy?.exceptions?.map((e) => ({ value: e.targetId, ...e }))
  const [reviewers, setReviewers] = useState(users || [])
  const [exceptions, setExceptions] = useState(fields || [])
  const [isSaving, setIsSaving] = useState(false)
  const [showActions, setShowActions] = useState(isCreatingNew)
  const itemBaseType = currentItem?.appElement?.baseElementType
  const [applyCustomFieldLevel, setApplyCustomFieldLevel] = useState({
    appliesOnListChildren: makerCheckerPolicy ? makerCheckerPolicy.appliesOnListChildren : false,
    appliesOnRecordChildren: makerCheckerPolicy ? makerCheckerPolicy.appliesOnRecordChildren : true,
    appliesOnStationChildren: makerCheckerPolicy ? makerCheckerPolicy.appliesOnStationChildren : false,
  })

  useEffect(() => {
    if (!makerCheckerPolicy) {
      setShowActions(true)
    }
  }, [makerCheckerPolicy])

  const customFieldImplementLevelProps = {
    applyCustomFieldLevel,
    isAggregateField: false,
    isApprovalPage: true,
    isDisabled: false,
    itemBaseType,
    setApplyCustomFieldLevel,
    setShowActions,
    showActions,
  }

  const onSubmit = () => {
    setIsSaving(true)
    if (!makerCheckerPolicy?.id) {
      //create new policy
      const body = {
        appElementId: +currentItem?.appElements[0]?.id,
        appliesOnListChildren: applyCustomFieldLevel.appliesOnListChildren,
        appliesOnRecordChildren: applyCustomFieldLevel.appliesOnRecordChildren,
        appliesOnStationChildren: applyCustomFieldLevel.appliesOnStationChildren,
        exceptions: exceptions.map((field) => ({
          elementBaseType: AppElementBaseTypes.RecordAppElement,
          operationId: 1,
          targetId: field.value,
          targetType: field.fieldName !== undefined ? 1 : 0,
        })),
        isAdminReviewer: areAdminsCheckers,
        isTargetElementOwnerReviewer: isOwnerChecker,
        reviewers: reviewers.map((user) => ({ elementId: null, userId: user.value })),
      }
      dispatch(onAddMakerChecker(body)).finally(() => {
        setIsSaving(false)
        setIsCreatingNew(false)
        setShowActions(false)
      })
    } else {
      //edit old policy
      const bodyData = {
        ...makerCheckerPolicy,
        appElementId: +makerCheckerPolicy.appElementId,
        appliesOnListChildren: applyCustomFieldLevel.appliesOnListChildren,
        appliesOnRecordChildren: applyCustomFieldLevel.appliesOnRecordChildren,
        appliesOnStationChildren: applyCustomFieldLevel.appliesOnStationChildren,
        exceptions: exceptions.map((field) => ({
          elementBaseType: AppElementBaseTypes.RecordAppElement,
          operationId: 1,
          targetId: field.value,
          targetType: field.fieldName !== undefined ? 1 : 0,
        })),
        isAdminReviewer: areAdminsCheckers,
        isTargetElementOwnerReviewer: isOwnerChecker,
        reviewers: reviewers.map((user) => ({ elementId: null, userId: user.value })),
      }
      dispatch(updateElementMakerCheckerPolicy({ bodyData, makerCheckerPolicyId: makerCheckerPolicy?.id })).finally(
        () => {
          setIsSaving(false)
          setShowActions(false)
        },
      )
    }
  }

  return (
    <div className="w-full h-full p-5">
      <div>
        <p className="font-bold">{t('makerChecker.reviewers')}</p>
        <p className="text-sm mt-1 mb-6">{t('makerChecker.reviewersExplanation')}</p>
        <Checkbox
          key={'areAdminsCheckers'}
          checked={areAdminsCheckers}
          classes={{ wrapper: 'mb-3' }}
          disabled={isDisabled}
          isLabelComponent
          label={t('makerChecker.isAdminReviewer')}
          name="areAdminsCheckers"
          onChange={() => {
            setAreAdminsCheckers(!areAdminsCheckers)
            if (!showActions) {
              setShowActions(true)
            }
          }}
        />
        <Checkbox
          key={'isOwnerChecker'}
          checked={isOwnerChecker}
          classes={{ wrapper: 'mb-3' }}
          disabled={isDisabled}
          isLabelComponent
          label={t('makerChecker.isOwnerReviewer')}
          name="isOwnerChecker"
          onChange={() => {
            setIsOwnerChecker(!isOwnerChecker)
            if (!showActions) {
              setShowActions(true)
            }
          }}
        />
        <p className="text-sm mb-2">{t('makerChecker.additionalReviewers')}</p>
        <MultiSelectUsersWrapper
          isDisabled={isDisabled}
          setShowActions={setShowActions}
          setValue={setReviewers}
          showActions={showActions}
          value={reviewers}
          label={t('listSettings:reviewers')}
        />
      </div>

      <hr className="my-5" />

      <p className="font-bold">{t('makerChecker.excludedFields')}</p>
      <p className="text-sm mt-1 mb-4">{t('makerChecker.excludedFieldsExplanation')}</p>
      <MultiSelectFieldsWrapper
        isDisabled={isDisabled}
        setShowActions={setShowActions}
        setValue={setExceptions}
        showActions={showActions}
        value={exceptions}
        label={t('listSettings:exceptions')}
      />

      {/* <hr className="my-5" /> */}

      {/* <CustomFieldImplementLevel {...customFieldImplementLevelProps} /> */}

      {showActions && (
        <div className="flex justify-end mt-8 mb-3 gap-4">
          <Button variant={ButtonVariant.Outline} onClick={() => setShowActions(false)}>
            {t('common:labels.cancel')}
          </Button>
          <Button onClick={onSubmit}>{isSaving ? <Loader /> : t('dashboard:saveButton')}</Button>
        </div>
      )}
    </div>
  )
}
export default MakerCheckerForm
