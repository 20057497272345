import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Container, Draggable } from '@edorivai/react-smooth-dnd'
import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useRoles } from 'hooks'
import { useDispatch } from 'react-redux'
import { isNumber } from 'lodash'

import { AddRecordToListForm } from 'components/forms'
import RecordFilters from 'components/RecordFilters'
import { AddIcon, ArchiveIcon, SettingsIcon, ViewsIcon } from 'components/Icons'

import ListToolbar from 'components/ListToolbar'

import {
  fetchListById,
  fetchViewsByListElementId,
  removeListView,
  reorderListView,
  useCurrentListArchivedById,
  useCurrentListElementId,
  useCurrentListIsArchived,
  useCurrentListViews,
  useIsLoadingListViews,
} from 'features/list'
import { fetchUsersByListId } from 'features/user'
import { clearRecords, fetchRecordsByParams, setCurrentRecord } from 'features/record'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'

import { useAppDispatch } from 'store'
import ListSettingsPage from 'pages/list/ListSettingsPage'

import { getQueryParamValue } from 'utils/helpers/queryParamsHelper'
import { usePanel } from 'contexts/PanelContext'
import { getViewIcon, getViewPlan, getViewTitleByBaseType } from 'utils/helpers/viewHelpers'
import PlansEnum from 'utils/constant/enums/subscription'
import ElementViewBaseTypes from 'utils/types/ElementViewBaseTypes'
import { AppElementView } from 'utils/types/AppElementViewType'
import { ListViewsLoader } from 'components/List'

import StackBy from '../../components/StackBy/StackBy'
import { getListLinkOptions, updateView } from '../../features/element'
import { RECORDS_PER_PAGE } from '../../utils/helpers/recordHelpers'
import { clearCurrentList } from '../../features/list/listSlice'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import ContextMenu from '../../components/ContextMenu'
import ConfirmationModal from '../../components/ConfirmationModal'
import Button, { ButtonVariant } from '../../components/Buttons'
import ManageViewModal from '../../components/Views/ManageViewModal'
import ListView from './ListView'

type ViewTabType = {
  Icon: FC<React.SVGAttributes<SVGElement>>,
  text: string,
  requirePremium: boolean,
  id: number,
  isDefault: boolean,
}

const ListDetailsPage: FC = () => {
  const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(true)
  const appDispatch = useAppDispatch()
  const { workspaceId, stationId, listId, viewId } = useParams<{
    workspaceId: string,
    stationId: string,
    listId: string,
    viewId: string,
  }>()
  const {
    location: { search, state },
  }: { location: { search: string | undefined, state: any } } = useHistory()
  const recordIdFromUrl = useMemo(() => getQueryParamValue(search, 'recordId'), [search])
  const { t, i18n } = useTranslation()
  const { push } = useHistory()
  const currentListViews = useCurrentListViews()
  const currentListIsArchived = useCurrentListIsArchived()
  const currentListArchivedById = useCurrentListArchivedById()
  const currentListElementId = useCurrentListElementId()
  const dispatch = useDispatch()
  const isRtl = i18n.language === 'ar'
  const { isEditor, isAdmin } = useRoles()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const [isArchived, setIsArchived] = useState(false)
  const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false)
  const [selectedView, setSelectedView] = useState<any | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { setPanelContent, openPanel, isPanelOpen } = usePanel()
  const isLoadingListViews = useIsLoadingListViews()

  const currentView: AppElementView | null = useMemo(() => {
    if (currentListViews.length === 0) return null
    let currentView = currentListViews.find((view) => view.id == Number(viewId))
    if (!currentView) currentView = currentListViews.find((view) => view.isDefault)
    if (!currentView) currentView = currentListViews[0]
    return currentView
  }, [currentListViews, viewId])

  useEffect(() => {
    if (currentListIsArchived !== undefined) setIsArchived(currentListArchivedById !== null || currentListIsArchived)
  }, [currentListIsArchived])

  useEffect(() => {
    return () => {
      dispatch(clearRecords())
    }
  }, [listId])

  useEffect(() => {
    if (listId !== undefined && isNumber(+listId)) {
      appDispatch(fetchListById(listId)).then((res) => {
        const listElementId = res?.payload?.appElement?.id
        const linkCustomFields = res?.payload?.customFields?.native?.fields.filter(
          (i) => +i.baseType === customFieldBaseTypes.SingleLink || +i.baseType === customFieldBaseTypes.Link,
        )
        linkCustomFields?.map((customField) => {
          const isBaseList = +customField.appElementId === +listElementId
          const optionsListListId = isBaseList ? customField.intermediateAppElementId : customField.appElementId
          dispatch(getListLinkOptions(+optionsListListId))
        })
      })
      appDispatch(fetchUsersByListId({ listId: listId }))
    }
    return () => {
      dispatch(clearCurrentList())
    }
  }, [listId])

  useEffect(() => {
    if (currentListElementId) {
      appDispatch(fetchViewsByListElementId(currentListElementId))
    }
  }, [appDispatch, currentListElementId])

  useEffect(() => {
    if (currentView !== null) {
      if (recordIdFromUrl) {
        dispatch(setCurrentRecord(recordIdFromUrl))
        setPanelContent({
          content: (
            <AddRecordToListForm
              currentView={currentView}
              isAdmin={isAdmin && !currentListIsArchived}
              isEditor={isEditor && !currentListIsArchived}
              listId={+listId}
              partialRecord={state?.partialRecord}
              recordId={+recordIdFromUrl}
            />
          ),
          header: recordIdFromUrl === 'undefined' && `${t('common:labels.createRecord')}`,
          isBig: true,
          isRecordEditable: isEditor && !state?.partialRecord?.appElements[0]?.isArchived,
          partialRecord: state?.partialRecord,
        })
        openPanel()
      } else if (isPanelOpen && isEditor)
        setPanelContent({
          content: (
            <AddRecordToListForm
              currentView={currentView}
              isAdmin={isAdmin && !currentListIsArchived}
              isEditor={isEditor && !currentListIsArchived}
              listId={+listId}
              partialRecord={state?.partialRecord}
              recordId={+recordIdFromUrl}
            />
          ),
          header: recordIdFromUrl === 'undefined' && `${t('common:labels.createRecord')}`,
          isBig: true,
          isRecordEditable: isEditor && !state?.partialRecord?.appElements[0]?.isArchived,
          partialRecord: state?.partialRecord,
        })
    }
  }, [recordIdFromUrl, isEditor, currentView])

  const getArchivedRecords = (status?: boolean) => {
    const archiveStatus = status !== undefined ? status : isArchived
    appDispatch(
      fetchRecordsByParams({
        archiveStatus: Number(archiveStatus),
        limit: RECORDS_PER_PAGE,
        listElementId: currentListElementId,
        listId,
        viewId,
      }),
    ).then((res) => {
      if (res.payload[0]) {
        const currentListId = res?.payload[0]?.id
        dispatch(fetchListById(currentListId))
      }
    })
  }

  const onRemoveView = (view: any) => {
    const defaultView = views.find((v) => v.isDefault)
    const newViewId = defaultView && view.id !== defaultView.id ? `/${defaultView?.id}` : ''
    push(`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}${newViewId}`)
    appDispatch(removeListView(view.id)).then((res) => {
      setSelectedView(null)
      setIsDeletingModalOpen(false)
    })
  }

  const views: ViewTabType[] = useMemo(() => {
    return currentListViews
      .map((view) => ({
        ...view,
        appElementId: Number(view.appElementId),
        Icon: getViewIcon({ viewBaseType: view.viewBaseType }),
        id: Number(view.id),
        isDefault: view.isDefault,
        requirePremium: getViewPlan({ viewBaseType: view.viewBaseType }) === PlansEnum.Pro,
        text: view.viewTitle ? view.viewTitle : getViewTitleByBaseType({ viewBaseType: view.viewBaseType }),
        viewAttributes: view?.viewAttributes || [],
        viewTitle: view.viewTitle || '',
        viewUsers: view?.viewUsers || [],
      }))
      .sort((a, b) => a.orderNumber - b.orderNumber)
  }, [currentListViews, t])

  const currentViewTab = useMemo(() => views.find(({ id }) => id === Number(currentView?.id)), [views, currentView])

  const canBeFiltered =
    currentView?.viewBaseType === ElementViewBaseTypes.RecordsGrid ||
    currentView?.viewBaseType === ElementViewBaseTypes.RecordsKanban
  const isKanbanView = currentView?.viewBaseType === ElementViewBaseTypes.RecordsKanban

  const reorderList = (dropResult: any) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (addedIndex === removedIndex) {
      return
    }
    const replacedItem = views[addedIndex]

    appDispatch(
      reorderListView({ id: +payload.id, newOrder: replacedItem?.orderNumber, oldOrder: payload?.orderNumber }),
    )
  }

  return (
    <>
      <ListToolbar>
        <div className="flex flex-wrap items-center justify-between flex-1">
          <div className="flex flex-1" id="list-view-toolbar">
            <button
              className={classnames(
                'flex shrink-0 items-center p-2 rounded-sm transition hover:text-primary focus:outline-none focus-visible:shadow-focus',
                {
                  'bg-tertiary-light/5 text-tertiary-light': isSidePanelVisible,
                },
              )}
              style={{ maxWidth: 132 }}
              onClick={() => setIsSidePanelVisible(!isSidePanelVisible)}>
              <ViewsIcon className="shrink-0 w-5 md:w-6 me-1" />
              <div className="block truncate">{currentViewTab ? currentViewTab.text : ''}</div>
            </button>
            {isKanbanView && (
              <div className="flex items-center ms-2 md:ms-4">
                <StackBy />
              </div>
            )}
            {canBeFiltered && !isArchived && (
              <div className="flex items-center border-gray-200 ms-2 md:ms-4 border-s-2 ps-2 md:ps-4">
                <RecordFilters />
              </div>
            )}
          </div>
          <div className="flex justify-end" id={'archive-icon'}>
            <button
              className={classnames(
                'ms-2 md:ms-4 flex items-center p-2 rounded-sm transition hover:text-primary focus:outline-none focus-visible:shadow-focus',
                {
                  'bg-tertiary-light/5 text-tertiary-light': isArchived,
                },
              )}
              onClick={() => {
                setIsArchived(!isArchived)
                getArchivedRecords(!isArchived)
              }}>
              <ArchiveIcon className="w-6 md:w-6 me-1" /> {t('records:archivedRecords')}
            </button>
          </div>
        </div>
      </ListToolbar>
      <div className="flex flex-1 min-h-0">
        {isSidePanelVisible && (
          <div
            className="z-10 flex flex-col justify-between shrink-0 w-40 h-full text-sm bg-white border-t-2 px-2.5 py-1.5 shadow-sm"
            data-tut="sixth__step">
            <div className="flex flex-col flex-1 min-h-0 overflow-y-auto">
              {isLoadingListViews ? (
                <ListViewsLoader />
              ) : (
                <Container
                  autoScrollEnabled={true}
                  dragHandleSelector=".view-drag-handle"
                  getChildPayload={(i) => views[i]}
                  orientation={'vertical'}
                  onDrop={reorderList}>
                  {views.map((view) => {
                    const { Icon, text, id, requirePremium, isDefault } = view
                    const isActive = Number(view.id) === Number(currentView.id)
                    return (isPremiumPlan && requirePremium) || !requirePremium ? (
                      <Draggable key={view.id}>
                        <div
                          key={`${text} - ${id} - ${isRtl}`}
                          className={classnames(
                            'view-drag-handle flex items-center p-2 my-2 rounded-sm bg-opacity-5 transition cursor-pointer' +
                              ' hover:text-primary focus:outline-none focus-visible:shadow-focus',
                            {
                              'bg-tertiary-light text-tertiary-light': (!viewId && isDefault) || isActive,
                            },
                          )}
                          dir={isRtl ? 'rtl' : 'ltr'}
                          onClick={() => {
                            push(`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/${id}`)
                          }}>
                          <div className="flex justify-between flex-1 min-w-0">
                            <div className="flex min-w-0">
                              <Icon className="shrink-0 w-5 md:w-6 me-1" />
                              <div className="block line-clamp-2" title={text}>
                                {text}
                              </div>
                            </div>
                          </div>
                          {isAdmin && (
                            <ContextMenu
                              isCustomButton
                              render={({ closeMenu }) => {
                                return (
                                  <div className="pb-2">
                                    <button
                                      className="block w-full py-2 hover:text-primary text-start"
                                      onClick={() => {
                                        setSelectedView(view)
                                        setIsModalOpen(true)
                                        closeMenu()
                                      }}>
                                      {t('common:labels.edit')}
                                    </button>
                                    {views.length > 1 && (
                                      <button
                                        className="block w-full py-2 hover:text-danger text-start"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          setSelectedView(view)
                                          setIsDeletingModalOpen(true)
                                          closeMenu()
                                        }}>
                                        {t('common:labels.remove')}
                                      </button>
                                    )}
                                  </div>
                                )
                              }}
                            />
                          )}
                        </div>
                      </Draggable>
                    ) : null
                  })}
                </Container>
              )}
            </div>
            {isAdmin && (
              <div className="flex justify-end w-max hover:bg-gray-200">
                <Button full icon={AddIcon} small variant={ButtonVariant.Ghost} onClick={() => setIsModalOpen(true)}>
                  {t('lists:createView')}
                </Button>
              </div>
            )}
            <div>
              <NavLink
                activeClassName="bg-tertiary-light text-tertiary-light"
                className="flex items-center p-2 mt-2 mb-4 rounded-sm bg-opacity-5 transition hover:text-primary focus:outline-none focus-visible:shadow-focus"
                to={`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/details`}>
                <SettingsIcon className="w-5 md:w-6 me-1" /> {t('labels.listSettings')}
              </NavLink>
            </div>
          </div>
        )}
        <Switch key={listId}>
          <Route
            path={[
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/details',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/users',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/fields',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/checkers',
            ]}>
            <ListSettingsPage />
          </Route>
          <Route
            path={[
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/kanban',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/grid',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/agenda',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/calendar',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/timeline',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId/:viewId',
              '/workspace/:workspaceId/stations/:stationId/lists/:listId',
            ]}>
            {isLoadingListViews ? null : (
              <ListView appElementId={currentListElementId} isArchived={isArchived} view={currentView} />
            )}
          </Route>
        </Switch>
      </div>
      <ManageViewModal
        appElementId={currentListElementId}
        isModalOpen={isModalOpen}
        view={selectedView}
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedView(null)
        }}
      />
      <ConfirmationModal
        confirmationMessage={t('lists:removeListViewMessage', {
          name: selectedView?.text,
        })}
        isModalOpen={isDeletingModalOpen}
        onCancel={() => {
          setIsDeletingModalOpen(false)
          setSelectedView(null)
        }}
        onConfirm={() => {
          onRemoveView(selectedView)
        }}
      />
    </>
  )
}

export default memo(ListDetailsPage)
