import React, { FC, useState, ChangeEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import head from 'lodash/head'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import Cropper from 'react-cropper'
import Loader from 'components/Loader'
import DashboardWidgetType from '../../utils/types/DashboardWidgetType'
import { SUPPORTED_FILE_FORMATS } from '../inputs/AvatarInput'
import { configValues } from '../../utils/appConfig'
import Checkbox from '../inputs/Checkbox'
import { Input } from '../inputs'
import Button, { ButtonVariant } from '../Buttons'
import { AddIcon, ReplaceIcon, TrashIcon } from '../Icons'
import BaseModal from './BaseModal/BaseModal'

const AddImageModal: FC<{
  isEditMode: boolean,
  isModalOpen: boolean,
  widgetId: number,
  elementId?: number | string,
  widget: DashboardWidgetType,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  setIsModalOpen: (value: boolean) => void,
}> = ({ isEditMode, elementId, widget, onUpdateWidgetImage, setIsModalOpen, isModalOpen }) => {
  const { t } = useTranslation('dashboard')
  const dispatch = useDispatch()
  const cropperRef = useRef(null)
  const [isUploading, setUploading] = useState(false)
  const [imagePath, setImagePath] = useState('')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [asBackground, setAsBackground] = useState<boolean>(false)
  const [imageWidth, setImageWidth] = useState<number>(50)
  const [imageHeight, setImageHeight] = useState<number>(50)

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = head(event.target.files)
    if (!SUPPORTED_FILE_FORMATS.includes(file.type)) return toast(t('validation:errors.unsupportedImageFileFormat'))
    setImagePath(URL.createObjectURL(file))
    setFile(file)
    setFileName(file.name ?? 'image.png')
    setIsModalOpen(true)
    event.target.value = ''
  }

  const onUploadImage = () => {
    setUploading(true)
    if (file) {
      const imageElement: any = cropperRef?.current
      const canvas = imageElement?.cropper?.getCroppedCanvas()
      canvas.toBlob(function (blob) {
        const formData = new FormData()
        formData.append('upload', blob, fileName)
        const basicQueryConfig: any = {
          body: formData,
          headers: {
            Authorization: localStorage.getItem('jwtToken') || '',
          },
          method: 'POST',
        }
        const queryUrl = `${configValues.REACT_APP_API_URL}/api/element/assets/element/${elementId}?assetType=0&sysTag=image&isPublic=true`
        return fetch(queryUrl, basicQueryConfig)
          .then((res) => res.json())
          .then((res) => {
            const w = { ...widget }
            w.appElementAssetId = res.dataValues.id
            w.appElementAsset = {
              elementId,
              id: res.dataValues.id,
              publicUrl: res.dataValues.publicUrl,
            }
            if (asBackground) {
              w.background = 'true'
            } else {
              w.background = null
              w.imageHeight = imageHeight
              w.imageWidth = imageWidth
              w.imagePosition = 'top'
            }
            dispatch(onUpdateWidgetImage(w))
            setUploading(false)
            setIsModalOpen(false)
            setImagePath('')
          })
      })
    }
  }

  const onRemoveImage = () => {
    const w = { ...widget }
    w.background = null
    w.imageHeight = null
    w.imageWidth = null
    w.imagePosition = null
    w.appElementAssetId = null
    w.appElementAsset = {}
    dispatch(onUpdateWidgetImage(w))
    setIsModalOpen(false)
    setImagePath('')
  }

  const onCancel = () => {
    setIsModalOpen(false)
    setImagePath('')
  }

  const uploadImageIcon = (Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>, text: string) => {
    return (
      <>
        <input accept={SUPPORTED_FILE_FORMATS.join(',')} id="image" name="image" type="file" onChange={onFileChange} />
        <div
          className={classnames(
            'text-gray-600 transition-colors cursor-pointer hover:text-primary-dark flex items-center',
          )}>
          <label className="flex items-center cursor-pointer hover:text-primary-dark" htmlFor="image">
            <Icon className="w-5 me-1" /> {text}
          </label>
        </div>
      </>
    )
  }

  const renderImageSettings = () => {
    const imageName: string =
      widget?.appElementAsset?.attachmentName === '_' || !widget?.appElementAsset?.attachmentName
        ? widget?.appElementAsset?.fileName === '_' || !widget?.appElementAsset?.fileName
          ? 'image.png'
          : widget?.appElementAsset?.fileName
        : widget?.appElementAsset?.attachmentName
    return (
      <div className="text-sm">
        {isEditMode && elementId && !widget?.appElementAsset?.id && (
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold">{t('dashboard:image')}</p>
            {uploadImageIcon(AddIcon, t('dashboard:uploadImage'))}
          </div>
        )}
        {widget?.appElementAssetId && (
          <>
            <p className="mb-3 text-lg font-bold">{t('dashboard:image')}</p>
            <div className="flex items-center h-16">
              <div className="flex flex-2 h-full items-center shrink-0" style={{ maxWidth: '30%', minWidth: '10%' }}>
                <img className="rounded max-h-full max-w-full" src={widget?.appElementAsset?.publicUrl} />
              </div>
              <div className="flex-1 ms-3 shrink-0 min-w-0">
                <p className="line-clamp-2">{imageName}</p>
                <div className="flex mt-3 gap-3">
                  {/*<button*/}
                  {/*  className="flex mt-2 text-gray-600 cursor-pointer transition-colors hover:font-bold"*/}
                  {/*  onClick={() => {*/}
                  {/*    const newFile = covertUrlToFile(widget?.appElementAsset?.publicUrl)*/}
                  {/*    onFileChange({ target: { files: [newFile] } })*/}
                  {/*  }}>*/}
                  {/*  <PencilOutlineIcon className="w-5 me-1" />*/}
                  {/*  {t('dashboard:updateImage')}*/}
                  {/*</button>*/}
                  <div>{uploadImageIcon(ReplaceIcon, t('dashboard:replaceImage'))}</div>
                  {/*<button*/}
                  {/*  className="flex mx-3 mt-2 text-gray-600 cursor-pointer transition-colors hover:font-bold"*/}
                  {/*  onClick={onRemoveImage}>*/}
                  {/*  <ReplaceIcon className="w-4 me-1" />*/}
                  {/*  {t('dashboard:replaceImage')}*/}
                  {/*</button>*/}
                  <button
                    className="flex cursor-pointer text-danger transition-colors hover:font-bold"
                    onClick={onRemoveImage}>
                    <TrashIcon className="w-5 me-1" />
                    {t('dashboard:removeImage')}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {/*<div className="flex justify-end">*/}
        {/*  <Button className="me-4" variant={ButtonVariant.Ghost} onClick={onCancel}>*/}
        {/*    {t('common:labels.cancel')}*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    )
  }

  const renderImagePreview = () => {
    return (
      <>
        <div className="flex flex-col flex-1 min-h-0 p-1 rounded">
          <div className="flex flex-col flex-1 min-h-0">
            <label className="block mb-3 text-sm font-bold">{t('user:details:cropArea')}</label>
            <Cropper
              autoCropArea={1}
              background={false}
              checkOrientation={false}
              className="self-center flex-1 min-h-0"
              guides={true}
              initialAspectRatio={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              preview=".image-preview"
              ref={cropperRef}
              responsive={true}
              src={imagePath}
              viewMode={1}
              zoomable={false}
            />
          </div>
          <div className="flex flex-col flex-1">
            <label className="block mb-3 text-sm font-bold">{t('user:details:cropPreview')}</label>
            <div className="h-full w-full self-center overflow-hidden image-preview" style={{ direction: 'ltr' }} />
          </div>
        </div>
        <div className="px-6 py-3 mt-0 -m-6 rounded">
          <div className="flex items-center justify-between mb-5">
            <Checkbox
              checked={asBackground}
              label={<span className="text-sm font-bold">{t('setAsBackground')}</span>}
              onChange={() => {
                setAsBackground(!asBackground)
              }}
            />
            {!asBackground && (
              <>
                <div className="flex items-center">
                  <div className="text-sm font-bold">{t('imageWidth')}:</div>
                  <div className="flex items-center flex-1">
                    <Input
                      classes={{ wrapper: 'px-1 text-sm w-20' }}
                      defaultValue={imageWidth}
                      id="imageSize"
                      max={100}
                      min={10}
                      name="imageSize"
                      placeholder={'ImageSize'}
                      type="number"
                      onChange={(e) => {
                        setImageWidth(e.target.value)
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-end pt-3 border-t border-gray-200">
            <Button className="me-4" variant={ButtonVariant.Ghost} onClick={onCancel}>
              {t('common:labels.cancel')}
            </Button>
            <Button onClick={onUploadImage} disabled={isUploading}>
              {isUploading ? <Loader /> : t('common:labels:save')}
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <BaseModal
      close={onCancel}
      content={!imagePath ? renderImageSettings() : renderImagePreview()}
      header={<h1 className="px-4 text-gray-800">{imagePath ? t('imageSettings') : t('widgetSettings')}</h1>}
      isModalOpen={isModalOpen}
      shouldCloseOnOverlayClick={false}
      type="md"
    />
  )
}

export default AddImageModal
