import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppElementViewUser } from 'utils/types/AppElementViewType'

import { useCurrentListMembers } from '../../features/user'
import MultiSelectDropDown from '../MakerCheckerPolicy/MultiSelectDropDown'
import UserSelectOption from '../MakerCheckerPolicy/UsersSelect/UserSelectOption'
import SelectedUserChip from '../MakerCheckerPolicy/UsersSelect/SelectedUserChip'
import Button, { ButtonVariant } from '../Buttons'
import UserAvatarStatus from '../UserAvatar/UserAvatarStatus'
import { CloseIcon } from '../Icons'
import { Dropdown } from '../inputs'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'
import { AppElementViewUserAccess } from '../../utils/types/AppElementViewUserAccess'
import { userDataAccessOptions } from '../../utils/constant/constant/common'

type AddUserToViewFormPropsType = {
  setSelectedUsers: (value: any[]) => void,
  selectedUsers: any[],
  viewUsers?: AppElementViewUser[],
}

const AddUserToViewForm: FC<AddUserToViewFormPropsType> = ({ setSelectedUsers, selectedUsers, viewUsers }) => {
  const { t } = useTranslation('lists')
  const usersList = useCurrentListMembers()
  const [showDropDown, setShowDropDown] = useState(false)
  const formattedUsers = usersList?.map((user) => ({
    ...user,
    dataAccess: AppElementViewUserAccess.OthersElements,
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }))

  useEffect(() => {
    if (viewUsers && formattedUsers) {
      const usersIds = viewUsers.map((vUser) => Number(vUser.userId))
      const users = formattedUsers
        .filter((u) => usersIds?.indexOf(Number(u.userId)) > -1)
        .map((u) => {
          const userDataAccess = viewUsers.find((user) => Number(user.userId) === Number(u.value))?.dataAccess
          return { ...u, dataAccess: userDataAccess }
        })
      setSelectedUsers(users)
    }
  }, [viewUsers])

  const onSelectFields = (value: any) => {
    setSelectedUsers([...selectedUsers, value])
  }

  const onRemoveOption = (value: number | string) => {
    const newList = selectedUsers.filter((user) => +user.id !== +value)
    setSelectedUsers(newList)
  }

  const renderUsersList = () => {
    return (
      <div className="flex flex-col px-4 py-2 rounded gap-2 border-1">
        {selectedUsers.map((selectedUser, idx) => {
          const user = usersList?.find((u) => u.userId === selectedUser.userId)
          return (
            <div key={idx} className="flex justify-between">
              <div className="flex items-center">
                <UserAvatarStatus customSize={7} imgSrc={user?.link} removeIconCustomSize={true} userId={user?.id} />
                <span className="text-sm ms-1">
                  {user?.firstName} {user?.lastName}
                </span>
              </div>
              <div className="flex">
                <Dropdown
                  components={{ Option: ColoredDropdownOption }}
                  isSearchable={false}
                  isSmall
                  menuPortalTarget={document.body}
                  name="FieldStatus"
                  options={userDataAccessOptions}
                  shouldShowPlaceholder
                  value={selectedUser?.dataAccess}
                  onChange={(e) => {
                    const currentUser = selectedUsers[idx]
                    currentUser.dataAccess = e.target.value
                    const newList = [...selectedUsers]
                    newList[idx] = currentUser
                    setSelectedUsers(newList)
                  }}
                />
                <CloseIcon
                  className="w-3 cursor-pointer text-danger ms-3"
                  onClick={() => onRemoveOption(selectedUser.id)}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="w-full mt-2">
      {!showDropDown ? (
        <div className="flex flex-col">
          {renderUsersList()}
          <Button
            className="self-end mt-2"
            small
            variant={ButtonVariant.Secondary}
            onClick={() => setShowDropDown(true)}>
            {t('views.addUsers')}
          </Button>
        </div>
      ) : (
        <div className="mx-2">
          <MultiSelectDropDown
            MultiSelectOptions={(props: any) => (
              <UserSelectOption {...props} isUserItem={true} onSelect={onSelectFields} />
            )}
            MultiSelectValue={(props: any) => (
              <SelectedUserChip {...props} isUserItem={true} onRemove={onRemoveOption} />
            )}
            autoFocus={true}
            defaultValue={selectedUsers}
            isCreatable={false}
            name={'selectUsers'}
            options={formattedUsers}
            placeholder={t('views.selectUsers')}
            value={selectedUsers}
            onBlur={() => setShowDropDown(false)}
          />
        </div>
      )}
    </div>
  )
}

export default AddUserToViewForm
