import React, { FC } from 'react'
import { DropdownOptionType } from 'utils/types/inputs'
import SelectedItemRow from './SelectedItemRow'

const UserSelectOption: FC<{
  data: DropdownOptionType,
  innerRef: any,
  innerProps: any,
  selectOption: any,
  isLabelDropdown?: boolean,
  onSelect: (user: any) => void,
  isUserItem: boolean,
}> = ({ data, onSelect, isUserItem = false }) => {
  return (
    <div className="flex p-2 my-1 items-center cursor-pointer hover:bg-gray-100" onClick={() => onSelect(data)}>
      <SelectedItemRow data={data} isUserItem={isUserItem} />
    </div>
  )
}

export default UserSelectOption
