import { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'
import { RecordType } from 'utils/types'

const TitleCell: FC<CellRenderObject & { data: RecordType, value: RecordType['name'] }> = (props) => {
  const value = props?.data?.name || ''
  return (
    <div className="truncate" title={value}>
      {value}
    </div>
  )
}
export default TitleCell
