import React, { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppElementView } from 'utils/types/AppElementViewType'
import ElementViewBaseTypes from 'utils/types/ElementViewBaseTypes'

import Calendar from 'pages/record/Calendar'
import CalendarAgendaView from 'pages/record/CalendarAgendaView'
import KanbanViewPage from 'pages/record/KanbanView'
import RecordsListingPage from 'pages/record/RecordsListingPage'
import Timeline from 'pages/record/Timeline'

import EmptyStatePage from 'components/EmptyStatePage'
import { EmptyStationsIcon } from 'components/Icons'
import Button from 'components/Buttons'
import ManageViewModal from '../../components/Views/ManageViewModal'

interface ListViewPropTypes {
  view: AppElementView | null;
  isArchived: boolean;
  appElementId: number;
}

const ListView: FC<ListViewPropTypes> = ({ view, isArchived, appElementId }) => {
  const { t } = useTranslation('lists')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const CreateView = () => {
    return (
      <>
        <EmptyStatePage
          Button={() => <Button onClick={() => setIsModalOpen(true)}>{t('createView')}</Button>}
          Icon={EmptyStationsIcon}
          description={t('noViewsDescription')}
          header={t('noViewsHeader')}
        />
        <ManageViewModal
          appElementId={appElementId}
          isModalOpen={isModalOpen}
          view={null}
          onCancel={() => {
            setIsModalOpen(false)
          }}
        />
      </>
    )
  }

  if (view === null) return <CreateView />

  switch (view.viewBaseType) {
    case ElementViewBaseTypes.RecordsKanban:
      return <KanbanViewPage isArchived={isArchived} />

    case ElementViewBaseTypes.RecordsGrid:
      return <RecordsListingPage isArchived={isArchived} />

    case ElementViewBaseTypes.RecordsAgenda:
      return <CalendarAgendaView />

    case ElementViewBaseTypes.RecordsCalendar:
      return <Calendar />

    case ElementViewBaseTypes.RecordsTimeLine:
      return <Timeline />

    default:
      return <div>Create a new view</div>
  }
}

export default memo(ListView)
