import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '../../../../components/Buttons'
import { updateVerifiedUser, useLoadingUsers, useUsersList } from '../../../../features/adminUser'
import GeneralGridTable from '../../../../components/Admin/GeneralGrid'
import { defaultAdminUsersFilterValue } from '../../../../utils/constant/GridView/adminUsers'
import SearchHeader from '../../../../components/Admin/SearchHeader'

const UsersPage: FC<{
  status: any,
  dropdownOptions: any[],
  onChange: () => void,
  loadingStatus: boolean,
  setLoadingStatus: (value: boolean) => void,
}> = ({ status, dropdownOptions, onChange, loadingStatus, setLoadingStatus }) => {
  const { t } = useTranslation()
  const defaultColumns = [
    { defaultFlex: 1, header: t('admin:user:id'), headerAlign: 'center', name: 'id' },
    {
      defaultFlex: 2,
      header: t('admin:user:firstName'),
      headerAlign: 'center',
      name: 'firstName',
    },
    {
      defaultFlex: 2,
      header: t('admin:user:lastName'),
      headerAlign: 'center',
      name: 'lastName',
    },
    {
      defaultFlex: 3,
      header: t('admin:user:email'),
      headerAlign: 'center',
      name: 'email',
    },
    {
      defaultFlex: 1,
      header: t('admin:user:created'),
      headerAlign: 'center',
      name: 'createdAt',
    },
    {
      className: '-settings',
      defaultFlex: 1,
      header: '',
      headerAlign: 'center',
      name: 'settings',
      render: ({ data }) => (
        <div className="flex justify-center w-full">
          <Button
            small
            onClick={(e) => {
              onVerifiedUser(data)
            }}>
            {t(`admin:user:${status.buttonLabel}`)}
          </Button>
        </div>
      ),
    },
  ]
  const [gridRef, setGridRef] = useState(null)
  const [actionUser, setActionUser] = useState(null)
  const [columns, setColumns] = useState(defaultColumns)
  const dataSource = useUsersList()
  const dispatch = useDispatch()
  const isLoadingUsers = useLoadingUsers()

  useEffect(() => {
    const updatedCol = [...columns]
    updatedCol[updatedCol.length - 1].render = ({ data }) => (
      <div className="flex justify-center w-full">
        <Button
          small
          onClick={(e) => {
            onVerifiedUser(data)
          }}>
          {t(`admin:user:${status.buttonLabel}`)}
        </Button>
      </div>
    )
    setColumns(updatedCol)
    setLoadingStatus(false)
  }, [status])

  useEffect(() => {
    if (actionUser) dispatch(updateVerifiedUser({ status: !status.value, userId: actionUser.id }))
  }, [actionUser])

  const onVerifiedUser = (user: any) => {
    setActionUser(user)
    // dispatch(updateVerifiedUser({ status: !status.value, userId: user.id }))
  }

  const tableProps = {
    columns,
    dataSource,
    defaultFilterValue: defaultAdminUsersFilterValue,
    handle: setGridRef,
    id: 'users-table-to-xls',
    isLoading: isLoadingUsers,
    loadingStatus,
  }

  const searchHeaderProps = {
    columns,
    dataSource,
    dropdownOptions,
    handle: setGridRef,
    id: 'users-table-to-xls',
    isLoading: isLoadingUsers,
    onChange,
    showDropdown: true,
    status,
    title: t('admin:user:userManagements'),
  }

  return (
    <>
      <SearchHeader {...searchHeaderProps} />
      {dataSource.length === 0 && !isLoadingUsers ? (
        <div className="flex justify-center mt-40 text-lg opacity-50">{t('common:noUsersToVerify')}</div>
      ) : (
        <div className="p-6" dir={'auto'}>
          <GeneralGridTable {...tableProps} />
        </div>
      )}
    </>
  )
}

export default UsersPage
