import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import CollapseIcon from '../Dashboard/CollapseIcon/CollapseIcon'
import { CloseIcon } from '../Icons'

type PreviewProps = {
  elementId: string,
  previewTitle: string,
  isCollapsed: boolean,
  setIsCollapsed: (val: boolean) => void,
  recordsElements: any[],
  selectedElements: any[],
  setSelectedElements: (val: any) => void,
  emptyPreviewMessage?: string,
}

const Preview: FC<PreviewProps> = ({
  elementId,
  isCollapsed,
  previewTitle,
  setIsCollapsed,
  recordsElements,
  selectedElements,
  setSelectedElements,
  emptyPreviewMessage,
}) => {
  const { t } = useTranslation('documentation')
  return (
    <div className="flex flex-col flex-initial min-h-0 p-1 overflow-y-auto border-t max-h-36">
      <div className="flex items-center justify-between">
        <p className="my-2 text-sm font-bold">{previewTitle || t('preview')}</p>
        <div className="flex items-center">
          <div className="px-1 text-sm bg-gray-200 rounded min-w-5"> {recordsElements.length}</div>
          <CollapseIcon
            collapsedItems={selectedElements}
            currentId={+elementId}
            isCollapsed={isCollapsed}
            setCollapsedItems={setSelectedElements}
            onCollapse={() => setIsCollapsed((prev) => !prev)}
          />
        </div>
      </div>
      <div
        className={`mb-2 overflow-y-auto transition-all duration-300 ease-in-out ${
          isCollapsed ? 'h-0 overflow-hidden opacity-0' : 'block opacity-100'
        }`}>
        {recordsElements.length === 0 && (
          <div className="w-full p-6 text-sm text-center bg-gray-200 rounded">
            {emptyPreviewMessage || t('emptyPreview')}
          </div>
        )}
        {recordsElements.map((item, index) => (
          <div key={index} className="flex justify-between my-1 bg-gray-200 rounded p-1.5">
            <p className="text-sm">{item.title}</p>
            <CloseIcon
              className="w-3 cursor-pointer"
              onClick={() => {
                const list = selectedElements.filter((i: any) => i.id !== item.id)
                setSelectedElements([...list])
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default Preview
