import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import recordType from 'utils/types/RecordType'
import { DownIcon } from 'components/Icons'
import { useCurrentStation } from '../../features/station'
import { useCurrentWorkspace } from '../../features/workspace'
import { useRecords, useRecordsChildren } from '../../features/record'
import { useActiveListViewId, useCurrentList } from '../../features/list'

interface LinkToRecordsPropTypes {
  currentRecord: recordType;
  className?: string;
  setModalOpen: (value: boolean) => void;
}

const ParentChange: FC<LinkToRecordsPropTypes> = ({ currentRecord, setModalOpen }) => {
  const currentList = useCurrentList()
  const viewId = useActiveListViewId()
  const viewInUrl = viewId ? `/${viewId}` : ''
  const records = useRecords()
  const isSubtask = currentRecord?.appElements[0]?.elementLevel > 4
  const currentStation = useCurrentStation()
  const currentWorkspace = useCurrentWorkspace()
  const parent = isSubtask
    ? records?.find((record) => record.appElements[0]?.id === currentRecord?.appElements[0]?.parentId)
    : currentList

  const recordUrl = `/workspace/${currentWorkspace?.id}/stations/${currentStation?.id}/lists/${currentList?.id}${viewInUrl}?recordId=${parent?.id}`
  return (
    <>
      <button
        className="flex justify-between items-center max-w-50 text-xs text-gray-600 bg-gray-200 rounded cursor-pointer px-2 mx-2 truncate text-start"
        style={{ paddingTop: '6.5px', paddingBottom: '6.5px', maxWidth: 150 }}
        id={'addLinkDoc'}>
        <Link className="me-1 truncate hover:text-primary hover:underline" to={recordUrl}>
          <div className="truncate" title={parent?.name}>
            {parent?.name}
          </div>
        </Link>
        <div className="rounded-full p-0.5 hover:bg-gray-300 hover:text-black">
          <DownIcon
            className="w-4 cursor-pointer opacity-60 stroke-3"
            onClick={() => {
              setModalOpen(true)
            }}
          />
        </div>
      </button>
    </>
  )
}

export default ParentChange
