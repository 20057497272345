import { useEffect, useRef } from 'react'

const useEventListener = (eventName: string, handler: (event: Event) => void, element = window): void => {
  const savedHandler = useRef<(event: Event) => void | null>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: Event) => {
      if (savedHandler.current) savedHandler.current(event)
    }

    if (savedHandler.current) {
      element.addEventListener(eventName, eventListener)
    }

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export default useEventListener
