import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LoginForm } from 'components/forms'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { getQueryParamValue } from '../../utils/helpers/queryParamsHelper'

const SignInPage: FC = () => {
  const { t } = useTranslation('signIn')
  const history = useHistory()

  useEffect(() => {
    const errorMessage = getQueryParamValue(history?.location?.search, 'error')
    if (errorMessage !== '') {
      toast(i18n.t(`notifications:${errorMessage}`), { icon: false, type: 'info' })
      history.replace('/sign-in')
    }
  }, [])

  return (
    <>
      <ChangeLanguageHeader headerText={t('signIn')} />
      <LoginForm />
    </>
  )
}

export default SignInPage
