import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import ReactContentEditable from 'react-contenteditable'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import fileDownload from 'js-file-download'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'

import SettingsDropdown from 'components/SettingsDropdown'
import { ChevronDownIcon } from 'components/Icons'
import ConfirmationModal from 'components/ConfirmationModal'

import {
  archiveListById,
  fetchListById,
  fetchListsByStationId,
  onMoveList,
  removeListById,
  updateList,
} from 'features/list'

import ListType from 'utils/types/ListType'
import baseAPI from 'utils/baseAPI'
import { withAdminRole, withEditorRole } from 'hocs/withRole'
import { useAppDispatch } from 'store'
import { useRoles } from 'hooks'
import ListImportExcelModal from '../ListImportExcel/ListImportExcelModal'
import MoveModal from '../MoveModal'
import { useCurrentWorkspace } from '../../features/workspace'
import { useCurrentStation, useStationsList } from '../../features/station'
import ElementOperations from '../../utils/constant/enums/element'

type SelectedListTabPropsType = {
  list: ListType,
  index: number,
  listsLength: number,
  firstListId: number,
  allLists: ListType[],
  setArchiveStatus: (value: boolean) => void,
}

const EditorButton = withEditorRole(({ children, ...props }: { children: ReactNode }) => (
  <button {...props}>{children}</button>
))

const AdminButton = withAdminRole(({ children, ...props }: { children: ReactNode }) => (
  <button {...props}>{children}</button>
))

const SelectedListTab: FC<SelectedListTabPropsType> = ({
  list,
  listsLength,
  firstListId,
  allLists,
  index,
  setArchiveStatus,
}) => {
  const { workspaceId, stationId, listId }: { workspaceId: string, stationId: string, listId: string } = useParams()
  const { t, i18n } = useTranslation('lists')
  const isRtl = i18n.language === 'ar'
  const [canEditCurrentTab, setCanEditCurrentTab] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [shouldRemoveElementAfterMove, setShouldRemoveElementAfterMove] = useState<boolean>(true)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const nameRef = useRef<HTMLElement | null>()
  const dispatch = useDispatch()
  const { isAdmin } = useRoles()
  const stations = useStationsList()
  const appDispatch = useAppDispatch()
  const currentWorkspace = useCurrentWorkspace()
  const currentStation = useCurrentStation()
  const history = useHistory()
  const { isEditor } = useRoles()
  const isArchivedList = list?.appElement?.isArchived
  const showArchive = isArchivedList ? true : listsLength > 1
  const isArchivedByParent = list.appElement.archivedById && +list.appElement.archivedById !== list.appElement.id

  useEffect(() => {
    if (canEditCurrentTab) {
      nameRef.current?.focus()
    }
  }, [canEditCurrentTab])

  const onUpdate = (e: any) => {
    const name = e.target.innerText
    if (list?.name !== name) dispatch(updateList({ changes: { name }, list }))
    setCanEditCurrentTab(false)
  }

  const onDelete = () => {
    if (stationId) {
      appDispatch(fetchListsByStationId({ archiveStatus: 0, stationId })).then((res) => {
        if (res?.payload[0]) {
          const currentListId = res?.payload[0]?.id
          history.push(`/workspace/${workspaceId}/stations/${stationId}/lists/${currentListId}`)
          appDispatch(removeListById({ initialList: list, listId: +list.id })).then(() => {
            setArchiveStatus(false)
            setIsModalOpen(false)
          })
        }
      })
    }
  }

  const onArchive = () => {
    appDispatch(
      archiveListById({
        elementId: list?.appElement?.id,
        recursive: Number(isChecked),
        status: Number(!isArchivedList),
      }),
    ).then(() => {
      setIsArchiveModalOpen(false)
      if (stationId) {
        appDispatch(fetchListsByStationId({ archiveStatus: 0, stationId })).then((res) => {
          if (res?.payload[0]) {
            const currentListId = res?.payload[0]?.id
            dispatch(fetchListById(currentListId))
            history.push(`/workspace/${workspaceId}/stations/${stationId}/lists/${currentListId}`)
            setArchiveStatus(false)
          }
        })
      }
    })
  }
  const onRefetch = () => {
    let lists = [...allLists]
    if (shouldRemoveElementAfterMove) {
      lists = allLists.filter(({ appElement }) => +appElement.id !== +list?.appElement?.id)
    }
    history.push(`/workspace/${workspaceId}/stations/${stationId}/lists/${lists[0].id}/kanban`)
    setShouldRemoveElementAfterMove(true)
  }

  const downloadExcelFile = () => {
    baseAPI(`api/records/list/${list.id}/download?sort=id&direction=asc&limit=2500&page=1`).then((response) => {
      const fileName = response?.contentDisposition?.split('filename=')[1].split(';')[0] || `${list.name}.xls`
      response.blob?.then((file) => {
        fileDownload(file, fileName)
      })
    })
  }

  const DropdownContent = (props: any) => {
    return (
      <div className="flex flex-col order-none text-gray-600">
        {!isArchivedList && (
          <>
            <EditorButton
              className="py-2 hover:text-primary text-start"
              onClick={() => {
                setCanEditCurrentTab(true)
                props.closeDropdown()
              }}>
              {t('editName')}
            </EditorButton>
            <button
              className="py-2 hover:text-primary text-start"
              onClick={() => {
                history.push(`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}/details`)
                props.closeDropdown()
              }}>
              {t('editDetails')}
            </button>
            <button
              className="py-2 hover:text-primary text-start"
              onClick={() => {
                downloadExcelFile()
                props.closeDropdown()
              }}>
              {t('export')}
            </button>
            <button
              className="py-2 hover:text-primary text-start"
              onClick={() => {
                setIsExcelModalOpen(true)
                props.closeDropdown()
              }}>
              {t('importExcel')}
            </button>
          </>
        )}
        {!isArchivedList && (
          <>
            <AdminButton
              className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
              onClick={() => {
                const body = {
                  appElementId: +list?.appElement?.id,
                  newParentId: null,
                  operation: ElementOperations.Duplicate,
                }
                appDispatch(onMoveList(body))
                props.closeDropdown()
              }}>
              {t('common:labels.duplicate')}
            </AdminButton>
            {stations?.length > 1 && (
              <>
                {listsLength > 1 && (
                  <AdminButton
                    className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                    onClick={() => {
                      setShouldRemoveElementAfterMove(true)
                      setIsMoveModalOpen(true)
                      props.closeDropdown()
                    }}>
                    {t('common:labels.move')}
                  </AdminButton>
                )}
                <AdminButton
                  className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                  onClick={() => {
                    setShouldRemoveElementAfterMove(false)
                    setIsMoveModalOpen(true)
                    props.closeDropdown()
                  }}>
                  {t('common:labels.copyToOtherStation')}
                </AdminButton>
              </>
            )}
          </>
        )}
        {showArchive && (
          <AdminButton
            className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition transition-colors"
            onClick={() => setIsArchiveModalOpen(true)}>
            {isArchivedList ? t('common:labels.unarchive') : t('common:labels.archive')}
          </AdminButton>
        )}

        {isAdmin && isArchivedList && (
          <AdminButton
            className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition transition-colors"
            onClick={() => setIsModalOpen(true)}>
            {t('common:labels.remove')}
          </AdminButton>
        )}
      </div>
    )
  }

  return (
    <>
      <div
        dir={isRtl ? 'rtl' : 'ltr'}
        className={classnames(
          'flex items-center min-w-0 py-1 mx-1 text-sm bg-white rounded-t-sm ps-4 pe-2 text-tertiary-light',
          {
            'flex-none': canEditCurrentTab,
          },
        )}>
        <ReactContentEditable
          className={classnames('me-1', {
            truncate: !canEditCurrentTab,
          })}
          disabled={!canEditCurrentTab}
          html={list.name}
          innerRef={(ref: HTMLElement) => (nameRef.current = ref)}
          onBlur={onUpdate}
          onFocus={() => document.execCommand('selectAll', false, undefined)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onUpdate(event)
          }}
        />
        {isEditor && !isArchivedByParent && (
          <SettingsDropdown Icon={ChevronDownIcon}>
            <DropdownContent />
          </SettingsDropdown>
        )}
      </div>
      <ConfirmationModal
        confirmationMessage={t('confirmListDelete', { interpolation: { escapeValue: false }, name: list.name })}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={onDelete}
      />
      <ConfirmationModal
        checkBoxMessage={isArchivedList ? t('common:archiveRecursiveMessage') : undefined}
        confirmMessage={isArchivedList ? t('common:labels.unarchive') : t('common:labels.archive')}
        confirmationMessage={t(isArchivedList ? 'lists:confirmListUnarchive' : 'lists:confirmListArchive', {
          interpolation: { escapeValue: false },
          name: list.name,
        })}
        isChecked={isChecked}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onChangeCheckBox={() => setIsChecked(!isChecked)}
        onConfirm={onArchive}
      />
      <ListImportExcelModal isModalOpen={isExcelModalOpen} listId={listId} onClose={() => setIsExcelModalOpen(false)} />
      <MoveModal
        currentElementId={+list?.appElement?.id}
        currentParentId={currentWorkspace?.appElement?.id}
        directParentId={currentStation?.appElement?.id}
        elementBaseType={1}
        isMoveModalOpen={isMoveModalOpen}
        setIsMoveModalOpen={setIsMoveModalOpen}
        shouldRemoveElementAfterMove={shouldRemoveElementAfterMove}
        onMoveItem={onMoveList}
        onRefetch={onRefetch}
      />
    </>
  )
}

export default SelectedListTab
