import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'
import DOMPurify from 'dompurify'

import { ArrowDownIcon, ArrowRightIcon } from 'components/Icons'
import {
  getChangeObjectData,
  getDateFormatInfo,
  recordAppElementDefaultAttributesValues,
} from '../../../utils/constant/constant/common'
import { recordAppElementDefaultAttributes } from '../../../utils/constant/enums/Aggregate'
import RequestType from '../../../utils/types/RequestType'
import UserAvatarStatus from '../../UserAvatar/UserAvatarStatus'

import { ListType, RecordType, StationType } from '../../../utils/types'
import { ChangeRequestStatus } from '../../../utils/constant/enums/common'
import { CustomFieldType } from '../../../utils/types/CustomFieldsType'
import { getFormattedDateWithTime } from '../../../utils/helpers/generalHelpers'
import AppElementAttributeType from '../../../utils/types/AppElementAttributeType'
import AddRequestComment from './AddRequestComment'
import RequestDoneStatus from './RequestDoneStatus'
import LabelRequestList from './LabelRequestList'

interface RequestRowType {
  request: RequestType;
  isHijri: boolean;
  customFields: CustomFieldType[];
  elementId?: string | number;
  needForApproval?: boolean;
  currentItem?: RecordType | ListType | StationType;
  isSimple?: boolean;
}

const RequestRow: FC<RequestRowType> = ({
  request,
  isHijri,
  elementId,
  currentItem,
  customFields,
  needForApproval,
  isSimple = false,
}) => {
  const { t, i18n } = useTranslation('records')
  const [isAccept, setIsAccept] = useState(false)
  const [isReject, setIsReject] = useState(false)
  const language = i18n.language
  const user = request?.createdByUser
  const userName = user !== undefined ? `${user?.firstName} ${user?.lastName}` : undefined
  const isCustomFieldKey = request.targetType === AppElementAttributeType.customField
  const isLabelRequest = +request.targetId === recordAppElementDefaultAttributes.labelsIds
  const isRequestDone =
    request.status === ChangeRequestStatus.APPROVED || request.status === ChangeRequestStatus.REJECTED
  const isDate =
    request &&
    (recordAppElementDefaultAttributesValues[request.targetId] === 'startDate' ||
      recordAppElementDefaultAttributesValues[request.targetId] === 'endDate')
  const changeObject: { key: string, previous: string, current: string } = getChangeObjectData(
    request,
    customFields,
    isLabelRequest,
  )

  const formatedData =
    changeObject?.current && isDate && isValid(new Date(changeObject.current))
      ? getDateFormatInfo(changeObject.current, isHijri, language)
      : changeObject.current

  const formatedPreviousData =
    changeObject?.previous && isDate && isValid(new Date(changeObject.previous))
      ? getDateFormatInfo(changeObject.previous, isHijri, language)
      : changeObject.previous

  if (isSimple) {
    return (
      <div className="flex flex-col flex-1 ms-1">
        <div className="flex flex-wrap justify-between items-baseline text-sm sm:tracking-normal">
          <div>
            <label className="font-bold me-1">{userName}</label>
            <span>
              {t('recordRequestChanged', {
                key: isCustomFieldKey ? changeObject.key : t(`listing.${changeObject.key}`),
              })}
            </span>
          </div>
          {isRequestDone && <RequestDoneStatus isHijri={isHijri} request={request} isSimple={isSimple} />}
        </div>
        <div className="mb-1 text-sm sm:flex hyphenate sm:text-base" style={{ width: '100%' }}>
          {changeObject.previous !== null && changeObject.previous !== undefined && changeObject.previous !== '' ? (
            changeObject.key === 'description' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(`${formatedPreviousData}`),
                }}
              />
            ) : (
              <div>
                {' '}
                {isLabelRequest ? (
                  <LabelRequestList labels={changeObject.previous} request={request} />
                ) : (
                  t(formatedPreviousData)
                )}
              </div>
            )
          ) : (
            <span className="text-gray-400 break-normal">{t('emptyState')}</span>
          )}
          <ArrowRightIcon className="flex-none hidden w-4 h-4 mx-2 my-1 text-gray-400 rtl-mirror sm:block" />
          <ArrowDownIcon className="flex-none w-4 h-4 my-1 text-gray-400 sm:hidden" />
          {changeObject.current !== null && changeObject.current !== undefined && changeObject.current !== '' ? (
            changeObject.key === 'description' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(formatedData),
                }}
              />
            ) : (
              <div>
                {isLabelRequest ? (
                  <LabelRequestList labels={changeObject.current} request={request} />
                ) : (
                  t(formatedData)
                )}
              </div>
            )
          ) : (
            <span className="text-gray-400 break-normal">{t('emptyState')}</span>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="mt-4 mb-2 last:mb-0">
      <div className="flex">
        <UserAvatarStatus imgSrc={user?.link} overrideCheckingUser userId={user?.id} />
        <div className="flex-1 ms-1">
          <div className="flex flex-wrap items-baseline mb-1 text-xs sm:tracking-normal sm:mt-1 sm:text-sm">
            <label className="font-bold me-1">{userName}</label>
            <span>
              {t('recordRequestChanged', {
                key: isCustomFieldKey ? changeObject.key : t(`listing.${changeObject.key}`),
              })}
            </span>
          </div>
          <div className="my-1 text-sm sm:flex hyphenate sm:text-base" style={{ width: '100%' }}>
            {changeObject.previous !== null && changeObject.previous !== undefined && changeObject.previous !== '' ? (
              changeObject.key === 'description' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`${formatedPreviousData}`),
                  }}
                />
              ) : (
                <div>
                  {' '}
                  {isLabelRequest ? (
                    <LabelRequestList labels={changeObject.previous} request={request} />
                  ) : (
                    t(formatedPreviousData)
                  )}
                </div>
              )
            ) : (
              <span className="text-gray-400 break-normal">{t('emptyState')}</span>
            )}
            <ArrowRightIcon className="flex-none hidden w-4 h-4 mx-2 my-1 text-gray-400 rtl-mirror sm:block" />
            <ArrowDownIcon className="flex-none w-4 h-4 my-1 text-gray-400 sm:hidden" />
            {changeObject.current !== null && changeObject.current !== undefined && changeObject.current !== '' ? (
              changeObject.key === 'description' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formatedData),
                  }}
                />
              ) : (
                <div>
                  {isLabelRequest ? (
                    <LabelRequestList labels={changeObject.current} request={request} />
                  ) : (
                    t(formatedData)
                  )}
                </div>
              )
            ) : (
              <span className="text-gray-400 break-normal">{t('emptyState')}</span>
            )}
          </div>
        </div>
        <div>
          <span className="text-xs">{getFormattedDateWithTime(request.createdAt, isHijri, language)}</span>
        </div>
      </div>
      {isRequestDone ? (
        <RequestDoneStatus isHijri={isHijri} request={request} />
      ) : isAccept || isReject ? (
        <AddRequestComment
          appElementId={elementId}
          currentItem={currentItem}
          isAccept={isAccept}
          requestId={request?.id}
          setIsAccept={setIsAccept}
          setIsReject={setIsReject}
        />
      ) : (
        !needForApproval && (
          <div className="flex justify-end">
            <button className="text-sm cursor-pointer text-primary me-4" onClick={() => setIsAccept(true)}>
              {t('listing.accept')}
            </button>
            <button className="text-sm cursor-pointer text-danger" onClick={() => setIsReject(true)}>
              {t('listing.reject')}
            </button>
          </div>
        )
      )}
    </div>
  )
}
export default RequestRow
