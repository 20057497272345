import Chart from 'components/Dashboard/Chart/Chart'
import React from 'react'
import ChartIcon from '../ChartIcon/ChartIcon'

const ChartIconsList = ({ onClick }) => {
  const iconsList = [25, 50, 75, 100]
  return (
    <div className="flex mt-1">
      {iconsList.map((iconVal, idx) => {
        return <ChartIcon key={idx} dataValue={iconVal} onClick={() => onClick(iconVal)} />
      })}
    </div>
  )
}
export default ChartIconsList
