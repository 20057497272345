import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import classnames from 'classnames'

import { ErrorMessageRefType, MultilineInputWithMentionsPropsType, InputRefType } from 'utils/types/inputs'
import { UsersTablePropsType } from 'utils/types'

import { InputErrorMessage } from 'components/inputs'
import { useUsersByList, useUsersByStation } from 'features/user'
import InputLabel from 'components/inputs/InputLabel'
import { useEventListener } from 'hooks'

const MultilineInputWithMentions = forwardRef<InputRefType, MultilineInputWithMentionsPropsType>(
  (
    {
      name,
      label,
      error,
      touched,
      classes,
      isInlineEditable,
      value,
      onChange,
      rows,
      handleClickOutside,
      isDocumentation,
      ...rest
    },
    ref,
  ) => {
    const errorRef = createRef<ErrorMessageRefType>()
    const usersInList: UsersTablePropsType[] = useUsersByList()
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const usersInStation: UsersTablePropsType[] = useUsersByStation()

    useEffect(() => {
      const end = value?.length || 0
      inputRef.current?.setSelectionRange(end, end)
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        blur: () => inputRef.current?.blur(),
        contains: (target: Node) => inputRef.current?.contains(target),
        focus: () => inputRef.current?.focus(),
        shakeError: () => errorRef.current?.shakeError(),
      }),
      [errorRef, inputRef],
    )

    useEventListener('mousedown', (event) => handleClickOutside?.(event))

    const usersToDisplay = isDocumentation
      ? usersInStation.map(({ id, name }) => ({ display: name, id }))
      : usersInList.map(({ id, name }) => ({ display: name, id }))

    return (
      <div className={classnames(classes?.wrapper || 'mb-6')}>
        <InputLabel className={classnames('block text-sm font-bold', classes?.label)} label={label} name={name} />
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <MentionsInput
          a11ySuggestionsListLabel={'Suggested mentions'}
          className={classnames({ mentions: !rows, 'mentions -rows': rows })}
          id={`input-${name}`}
          inputRef={inputRef}
          name={name}
          placeholder={label}
          rows={rows}
          style={{ '--rows': rows }}
          value={value ? `${value}` : ''}
          onChange={(e: any) => onChange?.({ ...e, target: { ...e.target, id: `input-${name}`, name } })}
          {...rest}>
          <Mention
            data={usersToDisplay}
            markup="@[__display__](userId:__id__)"
            renderSuggestion={(highlightedDisplay, focused) => (
              <div className={classnames('user', { focused: focused })}>{highlightedDisplay.display}</div>
            )}
            trigger="@"
          />
        </MentionsInput>
        <InputErrorMessage error={error} isInlineEditable={isInlineEditable} ref={errorRef} touched={touched} />
      </div>
    )
  },
)

MultilineInputWithMentions.displayName = 'MultilineInputWithMentions'

export default MultilineInputWithMentions
