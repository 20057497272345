import { toBoolean } from '../../helpers/generalHelpers'
import appConfigType from '../appConfigType'

export const configValues: appConfigType = {
  IS_GOOGLE_LOGIN_ENABLED: true,
  IS_GOOGLE_RECAPTCHA_ENABLED: true,
  IS_SENTRY_ENABLED: toBoolean(process.env.IS_SENTRY_ENABLED) ?? true,
  IS_SSO_ENABLED: false,

  REACT_APP_API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  REACT_APP_FULLCALENDAR_LICENCE_KEY: process.env.REACT_APP_FULLCALENDAR_LICENCE_KEY || '',
  REACT_APP_GRID_LICENSE_KEY: process.env.REACT_APP_GRID_LICENSE_KEY || '',
  REACT_APP_GOOGLE_LOGIN_CLIENT_ID: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || '',
  REACT_APP_GOOGLE_RECAPTCHA: process.env.REACT_APP_GOOGLE_RECAPTCHA || '',
  REACT_APP_IS_CUSTOM_BRANDING: toBoolean(process.env.REACT_APP_IS_CUSTOM_BRANDING, false),
  REACT_APP_SENTRY_DOMAIN: process.env.REACT_APP_SENTRY_DOMAIN || '',
  REACT_APP_SENTRY_TOKEN: process.env.REACT_APP_SENTRY_TOKEN || '',
  REACT_APP_IS_SECURE_CLOUD: toBoolean(process.env.REACT_APP_IS_SECURE_CLOUD, false),
  REACT_APP_IS_SUBSCRIPTION_ENABLED: toBoolean(process.env.REACT_APP_IS_SUBSCRIPTION_ENABLED, true),
  REACT_APP_CUSTOM_PASSWORD_REGEX: '',
}
