import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useCurrentUser, updateUser } from 'features/user'
import { ProfilePropsType } from 'utils/types'
import ProfileDetailsForm from 'components/forms/profile/ProfileDetailsForm'

const ApplyForTrial = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('user')
  const user: ProfilePropsType = useCurrentUser()

  return (
    <div className="w-full p-6 md:p-8 overflow-auto">
      <h1 className="mb-2 text-lg">{t('workspaceSettings:profileDetailsForm')}</h1>
      <p className="mb-8">{t('workspaceSettings:pleaseFillTheForm')}</p>
      <ProfileDetailsForm updateUser={(values: any) => dispatch(updateUser(values))} user={user} isSequentialForm />
    </div>
  )
}

export default ApplyForTrial
