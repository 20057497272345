import React, { FC, useState } from 'react'
import { statusColors } from 'utils/helpers/colorHelpers'
import { CustomFieldOptionType } from 'utils/types/customFieldsType'
import OptionRow from '../CustomFields/Options/OptionRow'

const SingleFieldItem: FC<CustomFieldOptionType> = ({
  item,
  colorOptions,
  onDeleteOption,
  optionsList,
  onBlurElement,
}) => {
  const [optionValue, setOptionValue] = useState<string>(item.element)
  const [optionColor, setOptionColor] = useState<string>(item.color || statusColors.at(-1)?.value)

  const onBlur = (key: string, value: string) => {
    onBlurElement(key, value)
  }
  const optionRowProps = {
    colorOptions,
    isDragging: false,
    onBlur,
    onChangeColor: (value) => {
      setOptionColor(value)
    },
    onChangeName: (value) => {
      setOptionValue(value)
    },
    onDeleteOption: () => onDeleteOption(item, optionsList),
    optionColor,
    optionValue,
    showDescription: false,
  }

  return <OptionRow {...optionRowProps} />
}

export default SingleFieldItem
