import React, { FC, useMemo } from 'react'

import { Dropdown } from 'components/inputs'
import PriorityOption from 'components/inputs/PriorityDropdown/PriorityOption'
import { getPriorityOptions } from 'utils/helpers/recordHelpers'
import { RecordType, CellEditorProps } from 'utils/types'
import { useGridViewNavigation } from 'hooks'

const PriorityEditorCell: FC<CellEditorProps<RecordType['priority']>> = ({
  autoFocus,
  cellProps,
  value,
  onComplete,
  gotoNext,
  gotoPrev,
  onChange,
  ...rest
}) => {
  useGridViewNavigation(gotoPrev, gotoNext)

  const options = useMemo(getPriorityOptions, [])

  return (
    <div
      className="w-full"
      onKeyDown={(e) => {
        e.preventDefault()
      }}>
      <Dropdown
        autoFocus={autoFocus}
        components={{ Option: PriorityOption, SingleValue: PriorityOption }}
        isLabelVisible={false}
        isSearchable={false}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        name={`${cellProps.id}`}
        openMenuOnFocus={autoFocus}
        options={options}
        shouldShowPlaceholder={false}
        tabIndex={1}
        value={value}
        onChange={(e) => {
          const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
          onChange(newEvent)
          setTimeout(() => onComplete(newEvent), 0)
        }}
        {...rest}
      />
    </div>
  )
}

export default PriorityEditorCell
