import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import i18n from 'i18next'
import { toast } from 'react-toastify'

import baseAPI from 'utils/baseAPI'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { StationDocumentationCommentsType } from 'utils/types/states/StationDocumentationComments'
import { StationDocumentationInfoUpdateParams } from 'utils/types/states/StationDocumentationInfoType'
import { CommentResponseType } from 'utils/constant/enums/common'
import { DashboardStateType, DocumentationDocumentTextSelectionType } from '../../utils/types/states'
import {
  formatDocSidebarData,
  formatOneDocPageData,
  formattedDocComments,
  formattedOneComment,
  formattedPinnedElements,
} from '../../utils/formatData/documentation'
import { newDocument, newDocumentPage } from '../../utils/constant/constant/documentationConstant'
import { archiveElement, onCreateElementDashboard, onDeleteElementDashboard } from '../element'
import { DashboardGetWidgetDataType } from '../../utils/types/states/DashboardGetWidgetDataType'
import { sidebarData } from './docSidebarMock'

export const fetchStations = createAsyncThunk('station/fetchStations', async () => {
  const response = await baseAPI('api/stations')
  return response.body
})

export const fetchStationById = createAsyncThunk('user/fetchStationById', async (stationId: number | string) => {
  const response = await baseAPI(`api/stations/${stationId}`)
  return response.body
})

export const fetchStationsByWorkspaceId = createAsyncThunk(
  'station/fetchStationsByWorkspaceId',
  async (props: { workspaceId: number, isArchived: boolean }, { rejectWithValue }) => {
    const { workspaceId, isArchived } = props
    const archiveStatus = isArchived ? 1 : 0
    const response = await baseAPI(`api/stations/workspace/${workspaceId}?arc=${archiveStatus}`)
    if (+response.status >= 400) return rejectWithValue(404)
    return response.body
  },
)

export const addStation = createAsyncThunk(
  'station/addStation',
  async (station: { name: string, description?: string, workspaceId: number }) => {
    const response = await baseAPI('api/stations', { body: station, method: 'POST' })
    return response.body
  },
)

export const updateStation = createAsyncThunk('station/updateStation', async (station: any) => {
  const response = await baseAPI(`api/stations/${station.id}`, {
    body: station,
    method: 'PUT',
  })
  return { ...station, ...response.body }
})

export const uploadAvatar = createAsyncThunk(
  'station/uploadAvatar',
  async (params: { stationId: number, avatar: { filename: string, base64data: string } }) => {
    const { stationId, avatar } = params
    if (stationId) {
      const payload = { ...avatar }
      const response = await baseAPI(`api/stations/upload/${stationId}`, {
        body: payload,
        method: 'PUT',
      })
      return response.body
    }
  },
)
export const getAllDashboardForStation = createAsyncThunk(
  'station/allDashboards',
  async (params: { scope: 'w' | 's' | 'l', id: number }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/dashboards/element?scope=${params.scope}&id=${params.id}`)

      if (response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getStationDashboard = createAsyncThunk('station/dashboard', async (dashboardId: number) => {
  const response = await baseAPI(`api/dashboards?id=${dashboardId}&data=false`)
  return response.body
})

export const addWidgetToStationDashboard = createAsyncThunk(
  'station/addWidgetToStationDashboard',
  async (params: {
    elementType: 'w' | 's' | 'l',
    elementId: number,
    baseType: DashboardWidgetBaseTypes,
    parameters?: DashboardGetWidgetDataType,
  }) => {
    const body = { parameters: params.parameters } || {}
    const response = await baseAPI(
      `api/widgets?scope=${params.elementType}&elementId=${params.elementId}&baseType=${params.baseType}`,
      { body, method: 'POST' },
    )
    const result = response.body
    return { ...result }
  },
)

export const getAvailableStationWidgets = createAsyncThunk(
  'station/getAvailableWidgets',
  async (params: { elementType: 'w' | 's' | 'l', elementId: number }) => {
    const response = await baseAPI(`api/widgets/available?scope=${params.elementType}&elementId=${params.elementId}`)
    return response.body
  },
)

export const saveStationDashboard = createAsyncThunk(
  'station/saveStationDashboard',
  async (dashboard: DashboardStateType, { getState, rejectWithValue }) => {
    try {
      const dashboardWithData = cloneDeep(dashboard)
      const state = getState()
      const dashboardWidgets = state.element.dashboardWidgets

      dashboardWithData.widgets = dashboard.widgets.map((w) => {
        return {
          ...w,
          ...dashboardWidgets[w.id],
          dimensions: {
            ...w.dimensions,
          },
          title: w.title,
        }
      })

      const response = await baseAPI(`api/dashboards`, { body: dashboardWithData, method: 'PUT' })

      if (+response.status >= 400) throw response
      return response.body
    } catch (e) {
      if (e?.body?.errors?.length > 0) {
        toast(i18n.t('notifications:errorSavingWidget'), { icon: false, type: 'error' })
      }
      return rejectWithValue(e)
    }
  },
)

export const getCurrentStationDocumentation = createAsyncThunk('station/getStationDocs', async (stationId: number) => {
  const response = await baseAPI(`api/stations/${stationId}`)
  return response.body
})

export const createNewDocumentation = createAsyncThunk('station/createNewDoc', async (stationId: number | string) => {
  const docBody = {
    ...newDocument,
    elementName: `station ${stationId} new documentation`,
    parentId: stationId,
  }
  const response = await baseAPI('api/wiki', { body: { ...docBody }, method: 'POST' })
  return response.body
})

export const createDocumentationNewPage = createAsyncThunk(
  'station/createNewDocPage',
  async ({ parentId, title }: { parentId: number, title: string }) => {
    const newPage = {
      ...newDocumentPage,
      elementName: title,
      pageContent: '',
      parentId: parentId,
    }

    const response = await baseAPI('api/wikiPage', { body: newPage, method: 'POST' })
    return formatOneDocPageData(response.body)
  },
)
export const updateDocumentationDocument = createAsyncThunk(
  'station/updateDocPage',
  async (
    { docPage, id }: { docPage: { content: string, title: string, removedFiles: string[] }, id: number },
    { rejectWithValue },
  ) => {
    try {
      const newPage = {
        ...newDocumentPage,
        elementName: docPage.title,
        id,
        removedFiles: docPage.removedFiles,
      }
      if (docPage.content) {
        newPage.pageContent = docPage.content
      }

      const response = await baseAPI(`api/wikiPage/${id}`, { body: newPage, method: 'PUT' })

      if (response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onDeleteDocumentPage = createAsyncThunk('station/deleteDocPage', async (docId: number) => {
  const response = await baseAPI(`api/wikiPage/${docId}`, {
    method: 'DELETE',
  })
  return response.body
})

export const getStationDocSidebar = createAsyncThunk(
  'station/getDocSidebar',
  async (props: { docId: number, archiveStatus: number }) => {
    const { docId, archiveStatus } = props
    const response = await baseAPI(`api/element/map/${docId}?arc=${archiveStatus}&include=children&sort=order`)
    const formattedData = formatDocSidebarData(response.body.map)
    return formattedData
  },
)

export const getStationArchivedDocuments = createAsyncThunk(
  'station/getStationArchivedDocuments',
  async (docId: number) => {
    const response = await baseAPI(`api/element/map/${docId}?arc=1&include=children&sort=order`)
    return formatDocSidebarData(response.body.map)
  },
)

export const getCurrentDocumentationDetails = createAsyncThunk('station/getDocDetails', async (docId: number) => {
  const response = await baseAPI(`api/wiki/${docId}`)
  return formattedPinnedElements(response.body)
})

export const getStationDocOneItem = createAsyncThunk('station/getDocOneItem', async (documentId: number) => {
  const response = await baseAPI(`api/wikiPage/${documentId}?im=1`)
  return formatOneDocPageData(response.body)
  // return documentId //sidebarData.filter((i) => i.id === documentId)[0]
})

export const pinDocumentationPage = createAsyncThunk(
  'station/setPinDocPage',
  async ({ parentId, id, status }: { parentId: number, id: number, status: number }) => {
    const response = await baseAPI(`api/wikiPage/${id}/setpin?pin=${status}&container=${parentId}`, {
      method: 'PUT',
    })
    return { id, result: response.body }
  },
)
export const updateDocumentationPageOrder = createAsyncThunk(
  'station/updateDocPageOrder',
  async ({ id, newOrderData }: { id: number, newOrderData: any }) => {
    const response = await baseAPI(`api/wikiPage/${id}/move`, {
      body: newOrderData,
      method: 'PUT',
    })
    return response.body
  },
)

const getSearchResultsAsync = async (data: any) => {
  const promise = new Promise((resolve) => {
    setTimeout(resolve, 1000)
  })

  await promise

  return data
}

export const getStationDocComments = createAsyncThunk(
  'station/getStationDocComments',
  async ({ documentId, showLatest }: { documentId: number | string, showLatest: boolean }) => {
    const response = await baseAPI(`api/wikiPage/comments/${+documentId}?asc=${Number(showLatest)}`, {
      method: 'GET',
    })
    return formattedDocComments(response.body)
  },
)

export const updateStationDocsOneComment = createAsyncThunk(
  'station/updateDocsOneComment',
  async (comment: StationDocumentationCommentsType) => {
    const response = await baseAPI(`api/wikiPage/comments/${+comment.id}`, {
      body: { ...comment, appElementId: +comment.appElementId },
      method: 'PUT',
    })
    return comment
  },
)

export const removeStationDocsOneComment = createAsyncThunk('station/removeDocsOneComment', async (id: number) => {
  const response = await baseAPI(`api/wikiPage/comments/${id}`, {
    method: 'DELETE',
  })
  return id
})

export const addStationDocsOneComment = createAsyncThunk(
  'station/addStationDocsOneComment',
  async ({
    text,
    documentId,
    inResponseTo,
    responseTargetId,
  }: {
    text: string,
    documentId: string | number,
    creatorId?: number,
    selection?: DocumentationDocumentTextSelectionType,
    inResponseTo: CommentResponseType | null,
    responseTargetId?: number,
  }) => {
    const commentBody = {
      appElementId: +documentId,
      inResponseTo,
      responseTargetId,
      text,
    }
    const response = await baseAPI(`api/wikiPage/comments`, {
      body: commentBody,
      method: 'POST',
    })
    const result = response.body
    return formattedOneComment(result)
  },
)

export const addStationDocsTextSelectionInDoc = createAsyncThunk(
  'station/addTextSelectionInDoc',
  async (payload: DocumentationDocumentTextSelectionType) => {
    return payload
  },
)

export const removeStationDocsTextSelectionInDoc = createAsyncThunk(
  'station/removeTextSelectionInDoc',
  async (selectionId: number | string | undefined) => {
    return selectionId
  },
)

const getattachMockPreview = async (data: any) => {
  const promise = new Promise((resolve) => {
    setTimeout(resolve, 1000)
  })

  await promise

  return data
}

export const getStationDocSearchResults = createAsyncThunk(
  'station/getDocSearchResults',
  async (payload: { searchStr: string, searchSort: string }) => {
    return await getSearchResultsAsync({
      items: sidebarData.filter((i) => i.title.toLocaleLowerCase().includes(payload.searchStr.toLocaleLowerCase())),
      searchStr: payload.searchStr,
    })
  },
)
export const createStationDocsOneDocAttachment = createAsyncThunk(
  'attachment/createOneDocAttachment',
  async (newAttachment: { files: File[], documentId: string }, { rejectWithValue }) => {
    const mockPreview = await getattachMockPreview(newAttachment.files) // newAttachment.files.map((file: any) => ({ ...file, publicUrl: URL.createObjectURL(file) }))
    // const formData = new FormData()
    // forEach(newAttachment, (value: any, fieldName: string) => {
    //   formData.append(fieldName, value)
    // })
    // const response = await baseAPI('api/attachments', { body: formData, method: 'POST' })
    // if (response.status === 404) return rejectWithValue(404)

    return mockPreview.map((file: File) => {
      return {
        createdAt: '2022-02-28T11:07:58.698Z',
        creatorId: 53,
        description: '',
        documentId: newAttachment.documentId,
        fileSize: file.size,
        fileType: file.type,
        id: Math.floor(Math.random() * (10000 - 500) + 500),
        name: file.name || 'pds',
        publicUrl: URL.createObjectURL(file),
      }
    })
  },
)

export const getStationDocsOneDocAttachment = createAsyncThunk(
  'station/getOneDocAttachment',
  async (documentId: string) => {
    return null
  },
)

export const deleteStationDocsOneDocAttachment = createAsyncThunk(
  'station/deleteOneDocAttachment',
  async (payload: { documentId: string, id: number }) => {
    return payload.id
  },
)
export const createOrDeleteStationDocPublicLink = createAsyncThunk(
  'station/createOrDelete',
  async ({ stationId, isRemove }: { stationId: string, isRemove: boolean }) => {
    if (!isRemove) {
      const sampleId = '123e4567-e89b-12d3-a456-426614174000'

      return `${window.origin}/public-docs/${sampleId}&stationId=${stationId}`
    }

    return ''
  },
)

export const getStationDocPublicLink = createAsyncThunk('station/getDocPublicLink', async (stationId: string) => {
  const sampleId = '123e4567-e89b-12d3-a456-426614174000'

  return `${window.origin}/public-docs/${sampleId}`
})

export const createStationDocsOneDocLabel = createAsyncThunk(
  'station/createOneDocLabel',
  async ({ documentId, name }: { documentId: string | number, name: string }) => {
    const rgb = () => Math.floor(Math.random() * (255 - 0) + 0)
    return {
      color: `rgb(${rgb()}, ${rgb()}, ${rgb()})`,
      id: Math.floor(Math.random() * (10000 - 500) + 500),
      name,
    }
  },
)

export const getStationDocsAllLabels = createAsyncThunk('station/createOneDocLabel', async (stationId: string) => {
  return null
})

export const getStationDocsOneDocInfo = createAsyncThunk('station/getOneDocInfo', async (documentId: string) => {
  return null
})

// Delete this type after backend is ready. It is only used for mock
type ExtraPayload = { labels: any[] }

export const updateStationDocsOneDocInfo = createAsyncThunk(
  'station/updateOneDocInfo',
  async (payload: StationDocumentationInfoUpdateParams & ExtraPayload) => {
    return {
      collaboratorsIds: payload.collaboratorsIds,
      date: payload.date,
      labels: payload.labels,
    }
  },
)

export const updateStationCustomFields = createAsyncThunk(
  'element/updateStationCustomFields',
  async ({ stationId, body }: { stationId: string | number, body: any }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/stations/${stationId}/update`, { body, method: 'Put' })
      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const archiveStationById = createAsyncThunk(
  'station/archiveStationById',
  async (element: { elementId: number, status: number, recursive: number }, { rejectWithValue }) => {
    const { elementId, status, recursive } = element
    return archiveElement(elementId, status, recursive, rejectWithValue)
  },
)

export const onCreateNewStationDashboard = createAsyncThunk(
  'station/onCreateNewStationDashboard',
  async (body: { scope: 'w' | 's' | 'l', appElementId: number, title: string }, { rejectWithValue }) => {
    return onCreateElementDashboard({ ...body, footer: '', header: '' }, rejectWithValue)
  },
)

export const onDeleteStationDashboard = createAsyncThunk(
  'station/onDeleteStationDashboard',
  async (elementId: number, { rejectWithValue }) => {
    return onDeleteElementDashboard(elementId, rejectWithValue)
  },
)
export const removeStationById = createAsyncThunk(
  'station/removeStationById',
  async (stationId: number, { rejectWithValue }) => {
    const response = await baseAPI(`api/stations/${stationId}`, {
      method: 'DELETE',
    })
    return stationId
  },
)
