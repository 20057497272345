import Chart from 'components/Dashboard/Chart/Chart'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { hijriInputFormat } from '../../../../utils/calendarUtils'
import { useCurrentUser } from '../../../../features/user'

const OverviewWidgetCard: FC<{
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>,
  title: string,
  subtitle: any,
  isTask?: boolean,
  isChart?: boolean,
  iconBgColor: string,
  parentWidth: number,
  parentHeight: number,
  customWidth: boolean,
}> = ({
  Icon,
  title,
  subtitle = 0,
  // isTask = false,
  isChart = false,
  subTitle,
  key,
  isMobile,
  customWidth,
}) => {
  const { t, i18n } = useTranslation('dashboard')
  const user = useCurrentUser()
  const isHijri = user.isHijri

  const subTitleContent = (content) => {
    if (content?.toString().indexOf('/') !== -1) {
      const newDate = content.toString().split('/').reverse().join('/')
      return isHijri ? hijriInputFormat(new Date(newDate), i18n.language) : content
    }
    return t(content)
  }

  return (
    <>
      <div
        key={key}
        className={`flex p-2 ${isMobile && 'mb-3'} items-center justify-start w-full rounded-lg border last:mb-0`}
        style={{ maxHeight: 66, height: 66 }}>
        {isChart ? (
          <div className="ms-2 me-1" style={{ height: isMobile ? 50 : 58, marginTop: -10, width: isMobile ? 50 : 58 }}>
            {subtitle !== null && (
              <Chart
                datasets={[
                  {
                    backgroundColor: ['#00B2A9', '#DDE5ED'],
                    borderColor: ['rgba(255, 99, 132, 0.0)', 'rgba(54, 162, 235, 0.0)'],
                    borderRadius: 0,
                    cutout: '80%',
                    data: [subTitle.title, 100 - subTitle.title],
                    fill: false,
                    label: title,
                  },
                ]}
                options={{ plugins: { datalabels: { display: false } } }}
                type="pie"
              />
            )}
          </div>
        ) : (
          <div
            className={` w-12 h-12 p-2.5 shrink-0 ${
              isMobile ? 'mr-3 ml-3' : customWidth ? 'ms-3 me-3' : 'ms-9 me-3'
            } rounded-full flex justify-center items-center bg-gray-300`}
            style={{ minWidth: 30 }}>
            <Icon className="w-7 h-7" />
          </div>
        )}
        <div className="flex flex-col w-30">
          <h3 className="text-gray-700">{t(title)}</h3>
          <h4 className="text-base" style={{ color: subTitle.color }}>
            {subTitle.title !== null ? subTitleContent(subTitle.title) + (isChart ? '% ' : '') : t('notAvailable')}
          </h4>
        </div>
      </div>
    </>
  )
}

export default memo(OverviewWidgetCard)
