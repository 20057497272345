import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'

import userReducer from 'features/user/userSlice'
import workspaceReducer from 'features/workspace/workspaceSlice'
import stationReducer from 'features/station/stationSlice'
import listReducer from 'features/list/listSlice'
import listGridReducer from 'features/listGrid/listGridSlice'
import recordReducer from 'features/record/recordSlice'
import commentReducer from 'features/comment/commentSlice'
import attachmentReducer from 'features/attachment/attachmentSlice'
import activityReducer from 'features/activity/activitySlice'
import publicDocsReducer from 'features/publicDocs/publicDocsSlice'
import elementReducer from 'features/element/elementSlice'
import adminReducer from 'features/adminUser/adminUserSlice'
import adminElementReducer from 'features/adminElement/adminElementSlice'
import { configValues } from 'utils/appConfig'

const sentryReduxEnhancer = configValues.IS_SENTRY_ENABLED ? Sentry.createReduxEnhancer({}) : undefined

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: (defaultEnhancers) =>
    configValues.IS_SENTRY_ENABLED ? [sentryReduxEnhancer, ...defaultEnhancers] : [...defaultEnhancers],
  middleware: getDefaultMiddleware(),
  reducer: {
    activity: activityReducer,
    attachment: attachmentReducer,
    comments: commentReducer,
    list: listReducer,
    listGrid: listGridReducer,
    record: recordReducer,
    station: stationReducer,
    user: userReducer,
    workspace: workspaceReducer,
    publicDocs: publicDocsReducer,
    element: elementReducer,
    admin: adminReducer,
    adminElement: adminElementReducer,
  },
})

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
