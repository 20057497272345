import { useState, ChangeEvent, Dispatch, SetStateAction, FC } from 'react'
import endsWith from 'lodash/endsWith'
import classnames from 'classnames'

import { CrossSignIcon } from 'components/Icons'

export interface ElementType {
  element: string;
  isValid: boolean;
  id?: number;
}

type TagInputProps = {
  delimiter?: string,
  validationSchema: any,
  selectedElements: ElementType[],
  setSelectedElements: Dispatch<SetStateAction<ElementType[]>> | ((elements: ElementType[]) => void),
  setIsValid: Dispatch<SetStateAction<boolean>>,
  placeholder: string,
  className?: string,
}

const TagInput: FC<TagInputProps> = ({
  delimiter = ';',
  validationSchema,
  selectedElements,
  setSelectedElements,
  setIsValid,
  placeholder,
  className,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [noInvalid, setNoInvalid] = useState(0)

  const validateElement = (element: string) =>
    validationSchema.isValid(element).then((isValid: boolean) => {
      setSelectedElements([...selectedElements, { element, isValid }])
      setInputValue('')
      if (!isValid) {
        setNoInvalid(noInvalid + 1)
        setIsValid(false)
      }
    })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event?.currentTarget.value
    const endsWithDelimiter = endsWith(currentValue, delimiter)
    const newElement = currentValue.slice(0, -1)
    if (endsWithDelimiter && newElement) {
      validateElement(newElement)
    } else {
      setInputValue(currentValue)
    }
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event?.currentTarget.value
    if (currentValue) {
      validateElement(currentValue)
    }
  }

  const removeElement = (elementToRemove: ElementType) => {
    if (!elementToRemove.isValid) {
      if (noInvalid === 1) {
        setIsValid(true)
      }
      setNoInvalid(noInvalid - 1)
    }
    const newElements = selectedElements.filter(({ element }) => element !== elementToRemove.element)
    setSelectedElements(newElements)
  }

  return (
    <div
      className={classnames(
        'flex flex-wrap items-center pb-0 border-2 border-gray-300 rounded-sm p-1.5 ps-0 focus-within:border-primary transition-colors',
        className,
      )}>
      {selectedElements?.map((singleElement: ElementType) => (
        <div
          key={singleElement.id}
          className={`bg-${
            singleElement.isValid ? 'gray-200' : 'danger'
          } p-1 ps-3 mb-1.5 ms-1.5 rounded-2xl flex items-center ${!singleElement.isValid && 'text-gray-200'}`}>
          <span>{singleElement.element}</span>
          <button type="button" onClick={() => removeElement(singleElement)}>
            <CrossSignIcon className="w-6 ms-1" />
          </button>
        </div>
      ))}
      <div className="flex-grow">
        <input
          autoFocus
          className="w-full h-8 placeholder-gray-400 rounded-sm mb-1.5 ps-1.5"
          placeholder={placeholder}
          value={inputValue}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default TagInput
