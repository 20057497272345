import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import { useDispatch } from 'react-redux'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { withContentEditable } from '../../hocs'
import { requiredTextFieldValidationSchema } from '../forms/validationSchemas/singleFieldsValidationSchemas'
import { onAddAggregateField, onAddCustomField, onEditAggregateField } from '../../features/list'
import { useAppDispatch } from '../../store'
import Loader from '../Loader'
import { getListLinkOptions, useFormulaList, useLinkType } from '../../features/element'
import {
  resetFormulaList,
  setCurrentIntermediateElementId,
  setFormulaList,
  setLinkType,
} from '../../features/element/elementSlice'
import { fetchRecordsByParams } from '../../features/record'
import { RECORDS_PER_PAGE } from '../../utils/helpers/recordHelpers'
import { handleFormattingRules } from '../../utils/formatData/customFields'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import { useHasCurrentWorkspaceTeamsPlan } from '../../features/workspace'
import CustomFieldOptions from './Options/CustomFieldOptions'
import CustomFieldSelect from './Select/CustomFieldTypesSelect'
import LinkCustomFieldForm from './LinkCustomFieldForm'
import CustomFieldFormulaOptions from './Formula/CustomFieldFormulaOptions'
import CustomFieldAggregation from './CustomFieldAggregation'
import CustomFieldImplementLevel from './CustomFieldImplementLevel'

const ContentEditableInput = withContentEditable(Input)

interface CustomFieldFormProps {
  isTippyInit?: boolean;
  isInRecord?: boolean;
  onClose: (e: any) => void;
  customFieldItem?: any;
  onEdit?: (e: any) => void;
  onRefetch?: (e: any) => void;
  elementBaseType: AppElementBaseTypes;
  currentItem: any;
  isFromAdmin?: boolean;
  stationId: number | string;
}

const CustomFieldForm: FC<CustomFieldFormProps> = ({
  onClose,
  isTippyInit,
  onEdit,
  customFieldItem,
  stationId,
  elementBaseType,
  currentItem,
  onRefetch,
  isInRecord,
  isFromAdmin = false,
}) => {
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const linkType = useLinkType()
  const { t } = useTranslation('customFields')
  const nameRef = useRef<any>(null)
  const formulaList = useFormulaList()
  const isTeamsPlan = useHasCurrentWorkspaceTeamsPlan()
  const [aggregateCustomField, setAggregateCustomField] = useState<any>(
    customFieldItem
      ? customFieldItem?.customFieldAggregate || {
          replacementAttributeId: customFieldItem?.replacementAttributeId,
          replacementAttributeType: customFieldItem?.replacementAttributeType,
        }
      : {},
  )
  const itemBaseType = currentItem?.appElements[0]?.baseElementType
  const [applyCustomFieldLevel, setApplyCustomFieldLevel] = useState({
    appliesOnListChildren:
      customFieldItem !== undefined
        ? customFieldItem.appliesOnListChildren
        : itemBaseType === AppElementBaseTypes.StationAppElement,
    appliesOnRecordChildren:
      customFieldItem !== undefined
        ? customFieldItem.appliesOnRecordChildren
        : itemBaseType === AppElementBaseTypes.ListAppElement,
    appliesOnStationChildren:
      customFieldItem !== undefined
        ? customFieldItem.appliesOnStationChildren
        : itemBaseType === AppElementBaseTypes.WorkspaceAppElement,
  })
  const [fieldStatus, setFieldStatus] = useState<any>({
    isReplacement: customFieldItem ? customFieldItem.isReplacement : false,
    isVisible: customFieldItem ? customFieldItem.isVisible : true,
  })
  const [fieldName, setFieldName] = useState<string | undefined>(customFieldItem ? customFieldItem.fieldName : '')
  const [fieldType, setFieldType] = useState<AppElementBaseTypes>(
    customFieldItem ? customFieldItem.baseType : AppElementBaseTypes.WorkspaceAppElement,
  )
  const [selectedElements, setSelectedElements] = useState<any[]>([])
  const [showListError, setShowListError] = useState<boolean>(false)
  const [showFormulaError, setShowFormulaError] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isDescriptionChange, setIsDescriptionChange] = useState<boolean>(false)
  const [optionsList, setOptionsList] = useState<any[]>(customFieldItem ? customFieldItem?.customFieldOptions : [])
  const [fieldFormattingRule, setFieldFormattingRule] = useState<any[]>(
    customFieldItem ? customFieldItem?.fieldFormattingRules : [],
  )

  useEffect(() => {
    if (customFieldItem) {
      dispatch(setCurrentIntermediateElementId(customFieldItem.intermediateAppElementId))
      dispatch(setFormulaList(customFieldItem.customFieldFormulas))
    }
    return () => {
      dispatch(resetFormulaList())
    }
  }, [customFieldItem])

  const resetFields = () => {
    nameRef.current = null
    setFieldName(undefined)
    setFieldType(0)
    setOptionsList([])
    setShowListError(false)
    setShowFormulaError(false)
    setIsSubmitting(false)
    setAggregateCustomField({})
    setFieldFormattingRule([])
  }

  const onCloseModal = (e: any) => {
    resetFields()
    onClose(e)
  }

  const handleSaveAggregateField = (e: any) => {
    if (onEdit) {
      appDispatch(
        onEditAggregateField({
          ...customFieldItem,
          aggregationMethod: customFieldItem.customFieldAggregate.aggregationMethod,
          fieldCaption: fieldName,
          fieldHelp: '',
          fieldName,
          targetAppElementBaseType: 3,
          targetAttributeId: +customFieldItem.customFieldAggregate.relatedAttributeId,
          targetAttributeType: customFieldItem.customFieldAggregate.relatedAttributeType,
        }),
      ).then(() => {
        setIsSubmitting(false)
        if (onRefetch) onRefetch()
      })
    } else {
      appDispatch(
        onAddAggregateField({
          ...aggregateCustomField,
          ...applyCustomFieldLevel,
          ...fieldStatus,
          appElementId: +currentItem?.appElement?.id,
          fieldCaption: fieldName,
          fieldName,
        }),
      ).then(() => {
        setIsSubmitting(false)
        if (onRefetch) onRefetch()
      })
    }
    onCloseModal(e)
  }
  const dataFormattingEditMode = (list: any[]) => {
    const allRules = [...list]
    customFieldItem?.fieldFormattingRules.map((rule: any) => {
      const isRuleDeletedExist = allRules.findIndex((r) => r.id === rule.id) === -1
      if (isRuleDeletedExist) {
        allRules.push({ ...rule, shouldDelete: true })
      }
    })
    return handleFormattingRules(allRules)
  }

  const handleSave = useCallback(
    (e: any) => {
      setIsSubmitting(true)
      if (+fieldType === customFieldBaseTypes.Aggregate) {
        handleSaveAggregateField(e)
      } else {
        const newField = {
          customFormulas: formulaList,
          elementBaseType,
          fieldFormattingRules: handleFormattingRules(fieldFormattingRule),
          fieldName,
          fieldType,
          optionsList,
          ...applyCustomFieldLevel,
          ...fieldStatus,
          replacementsAttributes: aggregateCustomField,
        }
        if (!fieldName || fieldName === '') {
          nameRef?.current?.focus()
          nameRef?.current?.submitField()
          setIsSubmitting(false)
        }
        if (+fieldType === customFieldBaseTypes.DropDown && optionsList.length === 0) {
          setShowListError(true)
          setIsSubmitting(false)
        } else if (+fieldType === customFieldBaseTypes.Formula && formulaList?.length === 0) {
          setShowFormulaError(true)
          setIsSubmitting(false)
        } else if (fieldName && (currentItem?.appElement || currentItem?.appElements || isFromAdmin)) {
          const elementId = !isFromAdmin ? currentItem?.appElement?.id || currentItem?.appElements[0]?.id : null
          newField.appElementId = isFromAdmin ? null : +elementId
          if (+fieldType === customFieldBaseTypes.Link) {
            newField.fieldType = customFieldBaseTypes.SingleLink
            newField.intermediateAppElementId = +selectedElements[0]?.id
          }
          if (onEdit) {
            newField.changedAttributes = {
              fieldFormattingRules: dataFormattingEditMode(fieldFormattingRule),
            }
            onEdit(newField)
            onCloseModal(e)
            setIsSubmitting(false)
          } else {
            appDispatch(onAddCustomField(newField)).then((res) => {
              if (res.meta.requestStatus === 'rejected') {
                setIsSubmitting(false)
                return
              } else {
                if (+fieldType === +customFieldBaseTypes.Link || +fieldType === +customFieldBaseTypes.SingleLink) {
                  onRefetch()?.then(() => {
                    dispatch(getListLinkOptions(+selectedElements[0]?.id))
                  })
                } else {
                  onRefetch()
                  if (isInRecord && +fieldType === +customFieldBaseTypes.Formula) {
                    dispatch(fetchRecordsByParams({ limit: RECORDS_PER_PAGE, listId: +currentItem?.id }))
                  }
                }
                setIsSubmitting(false)
                onCloseModal(e)
                setSelectedElements([])
                dispatch(setLinkType(5))
              }
            })
          }
        }
      }
    },
    [
      fieldName,
      fieldType,
      optionsList,
      nameRef,
      selectedElements,
      linkType,
      elementBaseType,
      formulaList,
      aggregateCustomField,
      applyCustomFieldLevel,
      fieldFormattingRule,
    ],
  )
  const isDisabled = customFieldItem !== undefined && onEdit !== undefined
  const CustomFieldSelectProps = {
    elementBaseType,
    isDisabled,
    isFromAdmin,
    selectedType: fieldType,
    setFieldType,
    shouldShowPlaceholder: true,
  }
  const CustomFieldAggregationProps = {
    aggregateCustomField,
    currentItem,
    customFieldsObject: currentItem?.customFields,
    isDisabled,
    setAggregateCustomField,
  }

  const linkCustomFieldProps = {
    elementBaseType: +customFieldItem?.baseType,
    selectedElements,
    selectedList: customFieldItem?.intermediateAppElement,
    setSelectedElements,
    stationId,
  }

  const customFieldDropdownOptionsProps = {
    optionsList: optionsList ? [...optionsList]?.sort((a, b) => a.optionOrder - b.optionOrder) : [],
    setOptionsList,
    setShowListError,
    showListError,
  }
  //todo: send element to customfieldformulaoptions and that component should call an endpoint from the backend to get all custom fields that...
  //todo: are applicable to its direct children and send those fields to the components it calls
  const customFieldFormulaProps = {
    aggregateCustomField,
    currentItem,
    customFields: currentItem?.customFields,
    customFieldsObject: currentItem?.customFields,
    fieldStatus,
    isDescriptionChange,
    isDisabled,
    setAggregateCustomField,
    setIsDescriptionChange,
    setShowFormulaError,
    showFormulaError,
  }

  const customFieldImplementLevelProps = {
    applyCustomFieldLevel,
    fieldStatus,
    isAggregateField: +fieldType === customFieldBaseTypes.Aggregate,
    isDisabled: customFieldItem !== undefined && onEdit !== undefined,
    isFormulaField: +fieldType === customFieldBaseTypes.Formula,
    itemBaseType,
    setApplyCustomFieldLevel,
    setFieldStatus,
  }

  const customFieldFormulaOptionsProps = {
    addButtonTitle: t('addRule'),
    addText: true,
    isEditMode: customFieldItem !== undefined && onEdit !== undefined,
    optionsList: fieldFormattingRule,
    setOptionsList: setFieldFormattingRule,
    showDescription: false,
    title: t('formattingRules'),
  }

  const hasSeparator =
    +fieldType === customFieldBaseTypes.Formula ||
    +fieldType === customFieldBaseTypes.DropDown ||
    +fieldType === customFieldBaseTypes.Link ||
    +fieldType === customFieldBaseTypes.SingleLink ||
    +fieldType === customFieldBaseTypes.Aggregate
  const isLinkType = +fieldType === customFieldBaseTypes.Link || +fieldType === customFieldBaseTypes.SingleLink

  return (
    <div className="flex flex-col flex-1 w-full min-h-0 px-5 py-5 justify between">
      <div className="flex flex-col flex-1 min-h-0">
        <h2 className="pb-3 mb-3 font-bold border-b border-gray-200">{t('customFieldModalTitle')}</h2>
        <div className="p-1 mt-2 mb-5">
          <ContentEditableInput
            className="-mx-2 text-sm"
            inputWrapperClassName="text-sm -my-2"
            isOnClickDisabled={false}
            isTippyInit={isTippyInit}
            name="fieldName"
            notEditingClassName="text-start custom-field__input"
            placeholder={t('labels.customFieldNamePlaceholder')}
            ref={nameRef}
            shouldShowPlaceholder
            validationSchema={requiredTextFieldValidationSchema('fieldName', 100)}
            value={fieldName}
            onChange={(e) => {
              setFieldName(e.target.value)
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                nameRef?.current?.submitField()
              }
            }}
            onSubmit={(values, actions) => {
              if (values.fieldName !== '') {
                setFieldName(values.fieldName)
              } else {
                actions.resetForm()
              }
            }}
          />
        </div>
        <div
          className={classnames('p-1 mt-1', {
            'border-b border-gray-200 pb-3': hasSeparator,
          })}>
          <CustomFieldSelect {...CustomFieldSelectProps} />
          {!isFromAdmin && <CustomFieldImplementLevel {...customFieldImplementLevelProps} />}
        </div>
        <div className="min-h-0 mt-2 overflow-y-auto">
          <div className="flex flex-col flex-1">
            {+fieldType === customFieldBaseTypes.DropDown && (
              <CustomFieldOptions {...customFieldDropdownOptionsProps} />
            )}
            {isLinkType && <LinkCustomFieldForm {...linkCustomFieldProps} />}
            {+fieldType === customFieldBaseTypes.Formula && <CustomFieldFormulaOptions {...customFieldFormulaProps} />}
            {+fieldType === customFieldBaseTypes.Aggregate && !isFromAdmin && isTeamsPlan && (
              <CustomFieldAggregation {...CustomFieldAggregationProps} />
            )}
          </div>
          {+fieldType === customFieldBaseTypes.Formula && (
            <div className={classnames('bottom-0 p-1 border-t border-gray-200 pb-6')}>
              <CustomFieldOptions {...customFieldFormulaOptionsProps} />
            </div>
          )}
        </div>
      </div>

      {!isDescriptionChange && (
        <div className="flex items-center justify-end mt-5">
          <Button className="me-1" small variant={ButtonVariant.Outline} onClick={onCloseModal}>
            {t('common:labels.cancel')}
          </Button>
          <Button
            data-testid="save-button"
            disabled={isSubmitting || !fieldName || fieldName?.length === 0}
            small
            type="submit"
            onClick={handleSave}>
            {isSubmitting ? <Loader /> : t('common:labels.save')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CustomFieldForm
