import ContextMenu from 'components/ContextMenu'
import { ClockWiseIcon, CommentsIcon, DocumentFilledIcon } from 'components/Icons'
import {
  getCurrentDocumentationDetails,
  pinDocumentationPage,
  useDocumentationDetails,
  useStationDocumentationSidebar,
  useStationDocumentationSidebarLoading,
} from 'features/station'
import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMeasure } from 'react-use'
import { getFormattedDateWithLocale } from 'utils/helpers/generalHelpers'
import Button, { ButtonVariant } from 'components/Buttons'
import { useDispatch } from 'react-redux'
import { usePublicDocsSidebar, usePublicDocsSidebarLoading } from 'features/publicDocs/selectors'
import SidebarContentLoader from '../DocumentationSidebar/SidebarContentLoader'
import CustomScroll from '../../CustomScroll'

// Documentation home page
const DocumentationPagesContent: FC<{ isPublic?: boolean }> = ({ isPublic = false }) => {
  const { t } = useTranslation()
  const [selectedFilter, setFilter] = useState('recently_viewed')
  const cards = useStationDocumentationSidebar()
  const publicCards = usePublicDocsSidebar()
  const publicCardsLoading = usePublicDocsSidebarLoading()
  const loading = useStationDocumentationSidebarLoading()
  const documentationDetails = useDocumentationDetails()
  const [ref, { width: elementWidth }] = useMeasure()
  const history = useHistory()
  const { workspaceId, stationId, docId, id } = useParams<{
    stationId: string,
    workspaceId: string,
    docId: string,
    id: string,
  }>()
  const dispatch = useDispatch()
  // TODO: check why this is called twice when opening documentation home page
  useEffect(() => {
    if (docId) dispatch(getCurrentDocumentationDetails(+docId))
  }, [])

  const commonFilters = [
    {
      emptyTitle: t('documentation:noRecentlyViewed'),
      title: t('documentation:recentlyViewed'),
      value: 'recently_viewed',
    },
    {
      emptyTitle: t('documentation:noRecentlyUpdated'),
      title: t('documentation:recentlyUpdated'),
      value: 'recently_updated',
    },
  ]

  const filters = isPublic
    ? commonFilters
    : [
        ...commonFilters,
        { emptyTitle: t('documentation:noCreatedByMe'), title: t('documentation:createdByMe'), value: 'created_by_me' },
        { emptyTitle: t('documentation:noMyDrafts'), title: t('documentation:myDrafts'), value: 'my_drafts' },
      ]

  if ((!isPublic && loading) || (isPublic && publicCardsLoading)) {
    return <SidebarContentLoader />
  }

  const gridCols = () => {
    if (elementWidth < 600) {
      return 1
    }

    if (elementWidth < 1000) {
      return 2
    }

    if (elementWidth < 1400) {
      return 3
    }

    return 4
  }
  const currentCards = isPublic ? publicCards : cards
  // const pinnedCards = currentCards ? currentCards.filter((i) => i.filters && i.filters.includes('pinned')) : []
  const filteredCards = currentCards ? currentCards.filter((i) => i.filters && i.filters.includes(selectedFilter)) : []
  const linkTodocs = isPublic
    ? `/public-docs/${id}`
    : `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`

  return (
    <CustomScroll maxHeight={'90vh'}>
      <div className="m-7" ref={ref}>
        {/*{sized}*/}
        <h1 className="mb-6">{t('station:pinned')}</h1>

        <div className={`grid grid-cols-${gridCols()} gap-4 mb-20`}>
          {documentationDetails?.map((i: any) => {
            const card = i
            return (
              <div
                key={card.id}
                className="p-6 bg-white rounded cursor-pointer h-52"
                style={{ minWidth: 200 }}
                onClick={() => {
                  history.push(`${linkTodocs}/view/${card.id}`)
                }}>
                <div className="flex items-center justify-between">
                  <DocumentFilledIcon width={20} />
                  {!isPublic && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                      }}>
                      <ContextMenu
                        isCustomButton
                        render={() => {
                          return (
                            <div>
                              <Button
                                variant={ButtonVariant.Icon}
                                onClick={() => {
                                  dispatch(
                                    pinDocumentationPage({
                                      id: +card.id,
                                      parentId: +docId,
                                      status: 0,
                                    }),
                                  )
                                  // dispatch(pinDocumentationDocument({ currentPage: card }))
                                }}>
                                {t('documentation:unpin')}
                              </Button>
                            </div>
                          )
                        }}
                      />
                    </div>
                  )}
                </div>

                <h2 className="w-full my-3 font-semibold truncate" style={{ color: '#000' }}>
                  {card?.title}
                </h2>
                <div
                  className="mb-4 text-sm"
                  style={{
                    boxOrient: 'vertical',
                    display: 'box',
                    height: '40px',
                    lineClamp: 3,
                    lineHeight: '20px',
                    maxHeight: '56px',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 3,
                  }}>
                  {/*{card.content ? (*/}
                  {/*  <div*/}
                  {/*    dangerouslySetInnerHTML={{*/}
                  {/*      __html: card.content,*/}
                  {/*    }}></div>*/}
                  {/*) : (*/}
                  {/*  t('documentation:noDescription')*/}
                  {/*)}*/}
                </div>
                <div className="flex items-center justify-between pt-3 border-t">
                  <div className="flex items-center gap-2">
                    <div>
                      <ClockWiseIcon width={20} />
                    </div>
                    {card.updatedAt && (
                      <div className="text-sm">{getFormattedDateWithLocale(card.updatedAt, 'd/MM/yyyy ')}</div>
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <div className="">
                        <CommentsIcon />
                      </div>
                      <div className="text-sm">{card.commentsCount}</div>
                    </div>
                    <div className="w-6 h-6" title={card.author?.firstName + ' ' + i.author?.lastName}>
                      <img
                        alt={card.author?.firstName + ' ' + card.author?.lastName}
                        className="rounded-full"
                        height={'100%'}
                        src={card.author?.link || '/assets/images/placeholder.svg'}
                        width={'100%'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {!documentationDetails?.length && <div>{t('documentation:noPinnedDocument')}</div>}
        </div>

        {/*<h1 className="mb-6 mt-7">{t('documentation:documents')}</h1>*/}
        {/*<div className="p-6 bg-white rounded">*/}
        {/*  <div className="flex border-b gap-4">*/}
        {/*    {filters.map((filter) => (*/}
        {/*      <div*/}
        {/*        key={filter.value}*/}
        {/*        className={`${selectedFilter === filter.value ? 'border-b-2 border-primary' : ''} pb-2 cursor-pointer`}*/}
        {/*        onClick={() => setFilter(filter.value)}>*/}
        {/*        {filter.title}*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    {filteredCards.map((item) => (*/}
        {/*      <div*/}
        {/*        key={item.id}*/}
        {/*        className="flex items-center justify-between py-4 border-b cursor-pointer"*/}
        {/*        onClick={() => history.push(`${linkTodocs}/view/${item.id}`)}>*/}
        {/*        <div className="flex gap-2 ">*/}
        {/*          <div style={{ width: 16 }}>*/}
        {/*            <DocumentFilledIcon />*/}
        {/*          </div>*/}
        {/*          <div className="-mt-0.5">{item.title}</div>*/}
        {/*        </div>*/}
        {/*        <div className="flex items-center gap-4">*/}
        {/*          <div className="flex items-center gap-2">*/}
        {/*            <div className="-mt-1">*/}
        {/*              <CommentsIcon />*/}
        {/*            </div>*/}
        {/*            <div className="text-sm">{item.commentsCount}</div>*/}
        {/*          </div>*/}
        {/*          <div className="w-6 h-6" title={item.author?.firstName + ' ' + item.author?.lastName}>*/}
        {/*            <img*/}
        {/*              alt={item.author?.firstName + ' ' + item.author?.lastName}*/}
        {/*              className="rounded-full"*/}
        {/*              height={'100%'}*/}
        {/*              src={item.author?.link || '/assets/images/placeholder.svg'}*/}
        {/*              width={'100%'}*/}
        {/*            />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*    {!filteredCards.length && (*/}
        {/*      <div className="mt-4">{filters.filter((f) => f.value === selectedFilter)[0].emptyTitle}</div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </CustomScroll>
  )
}

export default memo(DocumentationPagesContent)
