import React, { FC, useCallback, useMemo } from 'react'
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo'
import findIndex from 'lodash/findIndex'
import orderBy from 'lodash/orderBy'

import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from 'components/Icons'
import { withEditorRole } from 'hocs/withRole'
import { RecordType, PaginationPropsType } from 'utils/types'
import PageButton from './PageButton'
import AddRecordButton from './AddRecordButton'

const AddRow = withEditorRole(AddRecordButton)

const Pagination: FC<PaginationPropsType> = ({
  gotoNextPage,
  gotoPrevPage,
  hasNextPage,
  hasPrevPage,
  count,
  limit,
  skip,
  onSkipChange,
  gridRef,
}) => {
  const currentPage = useMemo(() => skip / limit + 1, [skip, limit])
  const pageCount = useMemo(() => Math.ceil(count / limit), [count, limit])
  const pagesNumbers = useMemo(() => Array.from({ length: pageCount }, (_, i) => i + 1), [pageCount])

  const goToSpecificPage = useCallback(
    (page: number) => {
      onSkipChange((page - 1) * limit)
    },
    [limit],
  )

  const calculatePageNumberWithNewRecord: (
    newRecord: RecordType,
    dataSource: RecordType[],
    sort: TypeSingleSortInfo,
  ) => number = useCallback(
    (newRecord, dataSource, { name: sortProperty, dir: sortDirection }) => {
      const sortedDataSource = orderBy([...dataSource, newRecord], sortProperty, sortDirection === 1 ? 'asc' : 'desc')
      const indexOfNewRecord = findIndex(sortedDataSource, ({ id }) => id === newRecord.id)
      return Math.ceil(indexOfNewRecord / limit)
    },
    [limit],
  )

  const calculateNewRecordPage = useCallback(
    (newRecord: RecordType) => {
      const { onNextRender, scrollToId, computedSortInfo, dataSource } = gridRef
      if (count <= limit)
        return onNextRender(() => {
          scrollToId(newRecord.id)
        })
      return onNextRender(() => {
        const pageWithNewRecord = calculatePageNumberWithNewRecord(newRecord, dataSource, computedSortInfo)
        goToSpecificPage(pageWithNewRecord)
        scrollToId(newRecord.id)
      })
    },
    [limit, count, currentPage, gridRef],
  )

  return (
    <div className="flex items-center justify-between w-full py-1 px-4 -mb-5 text-sm bg-white rounded-b-2xl">
      <AddRow calculateNewRecordPage={calculateNewRecordPage} />
      <div className="flex ms-auto">
        {hasPrevPage() && (
          <button onClick={() => gotoPrevPage()}>
            <DoubleArrowLeftIcon className="w-6 rtl-mirror" />
          </button>
        )}
        {pagesNumbers.map((pageNumber) => (
          <PageButton
            key={pageNumber}
            currentPage={currentPage}
            goToSpecificPage={goToSpecificPage}
            pageCount={pageCount}
            pageNumber={pageNumber}
          />
        ))}
        {hasNextPage() && (
          <button onClick={() => gotoNextPage()}>
            <DoubleArrowRightIcon className="w-6 rtl-mirror" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Pagination
