import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DashboardWidgetType from '../../utils/types/DashboardWidgetType'
import { CrossSignIcon } from '../Icons'

const WidgetImage: FC<{
  widgetInfo: DashboardWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo }) => {
  const { appElementAsset, imageWidth } = widgetInfo
  const imageSrc = appElementAsset.publicUrl || null

  return (
    imageSrc && (
      <div className="flex justify-center p-3 w-full, h-1/2 max-h-1/2">
        <img className="max-w-full" src={appElementAsset.publicUrl} width={`${imageWidth}%`} />
      </div>
    )
  )
}

export default WidgetImage
