import { createSlice } from '@reduxjs/toolkit'

import { CommentsStateType } from 'utils/types/states'
import { addComment, fetchCommentsByRecordId, onUploadAssetsToComment, removeComment, updateComment } from './actions'

const initialState: CommentsStateType = { comments: [], loading: true }

export const commentsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchCommentsByRecordId.fulfilled, (state, action) => {
      state.comments = action.payload
      state.loading = false
    })

    builder.addCase(fetchCommentsByRecordId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchCommentsByRecordId.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments = action.payload.showLatest
        ? [action.payload, ...state.comments]
        : [...state.comments, action.payload]
      state.loading = false
    })

    builder.addCase(addComment.pending, (state) => {
      state.loading = true
    })

    builder.addCase(addComment.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(updateComment.fulfilled, (state, action) => {
      const updatedComment = action.payload
      const updatedCommentIndex = state.comments.findIndex(({ id: stationId }) => updatedComment.id === stationId)
      state.comments[updatedCommentIndex] = updatedComment
      state.loading = false
    })

    builder.addCase(updateComment.pending, (state) => {
      state.loading = true
    })

    builder.addCase(updateComment.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(removeComment.pending, (state, action) => {
      state.loading = true
    })

    builder.addCase(removeComment.fulfilled, (state, action) => {
      state.comments = state.comments.filter(({ id }) => id !== action.meta.arg)
      state.loading = false
    })

    builder.addCase(removeComment.rejected, (state) => {
      state.loading = false
    })
    //Add Assets To Comment
    builder.addCase(onUploadAssetsToComment.fulfilled, (state, action) => {
      // const updatedCommentIndex = state.comments.findIndex((comment) => +action.payload.id === +comment.id)
      // const commentAssets = [...state.comments[updatedCommentIndex].commentAssets]
      // commentAssets.push(action.payload)
      // state.comments[updatedCommentIndex] = { ...state.comments[updatedCommentIndex], commentAssets }
      state.loading = false
    })

    builder.addCase(onUploadAssetsToComment.pending, (state) => {
      state.loading = true
    })

    builder.addCase(onUploadAssetsToComment.rejected, (state) => {
      state.loading = false
    })
  },
  initialState,
  name: 'list',
  reducers: {},
})

export default commentsSlice.reducer
