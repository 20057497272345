import React, { FC, useState } from 'react'
import i18n from 'i18next'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import EditorBuild from 'ckeditor5-custom-build/build/ckeditor'
import 'ckeditor5-custom-build/build/translations/ar.js'
import 'ckeditor5-custom-build/build/translations/en-gb.js'
import Button, { ButtonVariant } from 'components/Buttons'
import { CrossSignIcon, InfoIcon } from 'components/Icons'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/Dashboard/BaseModal/BaseModal'
import { nanoid } from 'nanoid'
import { configValues } from 'utils/appConfig'
import { useUpdateDocumentLoading } from 'features/station'
import Loader from 'components/Loader'
import { Tooltip } from '../../../utils/helpers/generalHelpers'

const getTitleAndBody = (editorData: string) => {
  const data = {
    body: '',
    title: '',
  }
  const parser = new DOMParser()
  const doc = parser.parseFromString(editorData, 'text/html')
  const title = doc.getElementsByTagName('h1')[0]
  data.title = title ? title.innerText : ''
  if (title) {
    doc.body.removeChild(title)
  }

  doc.body.childNodes.forEach((node: any) => {
    if (node.className === 'table') {
      if (!node.style.float) {
        if (node.className !== 'documentation-figure-center') {
          node.className = 'documentation-figure-center'
        }
      } else {
        node.className = ''
      }
      if (node.children[0].className !== 'documentation-table-default') {
        node.children[0].className = 'documentation-table-default'
      }
    }
  })

  data.body = doc.body.innerHTML

  return data
}

const DocumentEditor: FC<{
  documentId: number,
  isRtl: boolean,
  data: string,
  saveChanges: (body: string, title: string, removedFiles: string[]) => void,
  exitWithoutSave: () => void,
  isSaveOpen: boolean,
  setSaveOpen: React.Dispatch<React.SetStateAction<any>>,
  setHasChanges: () => void,
}> = ({ isRtl, data, saveChanges, exitWithoutSave, documentId, isSaveOpen, setSaveOpen, setHasChanges }) => {
  const [editedData, setEditedData] = React.useState(data)
  const ref = React.useRef<any>(null)
  const { width: windowWidth } = useWindowSize()
  const { t } = useTranslation()
  const [isExitOpen, setExitOpen] = useState(false)
  const [removedFiles, setRemovedFiles] = useState<string[]>([])
  const [isCharactersWarningOpen, setIsCharactersWarningOpen] = useState<boolean>(false)
  const isUpdatingDocument = useUpdateDocumentLoading()
  const isImagesPublic = configValues.REACT_APP_IS_SECURE_CLOUD

  const additionalBtnWidth = windowWidth < 1001 ? 130 : 180

  const checkRemovedFiles = (event: any) => {
    const elementTypes = ['image', 'imageBlock', 'inlineImage', 'imageInline']

    const differ = event.source.differ

    // if no difference
    if (differ.isEmpty) {
      return
    }

    const changes = differ.getChanges({
      includeChangesInGraveyard: true,
    })

    if (changes.length === 0) {
      return
    }

    let hasNoImageRemoved = true

    // check any image remove or not
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i]
      // if image remove exists
      if (change && change.type === 'remove' && elementTypes.includes(change.name)) {
        hasNoImageRemoved = false
        break
      }
    }

    // if not image remove stop execution
    if (hasNoImageRemoved) {
      return
    }

    // get removed nodes
    const removedNodes = changes.filter((change: any) => change.type === 'insert' && elementTypes.includes(change.name))

    // removed images src
    const removedImagesSrc: any[] = []
    // removed image nodes
    const removedImageNodes = []

    removedNodes.forEach((node: any) => {
      const removedNode = node.position.nodeAfter
      removedImageNodes.push(removedNode)
      removedImagesSrc.push(removedNode.getAttribute('src'))
    })
    const updatedRemovedFiles = [...removedFiles, ...removedImagesSrc]
    setRemovedFiles(updatedRemovedFiles)
  }

  // MARK: Editor Config
  const defaultConfig = {
    width: '100%',
    overflowY: 'auto',
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          attributes: {
            target: '_blank',
          },
        },
      ],
      defaultProtocol: 'https://',
    },
    mediaEmbed: { previewsInData: true },
    overflowY: 'auto',
    placeholder: t('documentation:editorPlaceholder'),
    simpleUpload: {
      // Enable the XMLHttpRequest.withCredentials property.
      // withCredentials: true,
      // Headers sent along with the XMLHttpRequest to the upload server.
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Arabic-Layout': i18n.language === 'ar',
        Authorization: localStorage.getItem('jwtToken') || '',
        // 'Content-Type': 'application/json',
      },

      // The URL that the images are uploaded to.
      uploadUrl: `${configValues.REACT_APP_API_URL}/api/wikipage/assets/element/${documentId}?assetType=0&sysTag=image&isPublic=${isImagesPublic}`,
    },
    tabSpaces: 4,
    width: '100%',
  }

  const handleChange = (event: any, editor: any) => {
    checkRemovedFiles(event)
    setHasChanges()

    const uniqueId = nanoid()
    editor.conversion.for('downcast').add((dispatcher: any) => {
      dispatcher.on('insert:paragraph', (evt: any, data: any, conversionApi: any) => {
        const viewWriter = conversionApi.writer

        viewWriter.setAttribute('id', 'doc-' + uniqueId, conversionApi.mapper.toViewElement(data.item))
      })
    })
    const contentWithoutTitle = editor.getData().split('</h1>')
    const content = contentWithoutTitle[1]
    if (content?.length > 10000) {
      setIsCharactersWarningOpen(true)
    } else {
      setIsCharactersWarningOpen(false)
    }
    setEditedData(editor.getData().replace('\r\n', '<br />'))
    setEditedData(editor.getData().replace('&nbsp;', ' '))
  }

  const handleOnReady = (editor: any) => {
    editor?.ui
      .getEditableElement()
      .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement())

    ref.current = editor
  }

  const handleOnError = ({ willEditorRestart }: any) => {
    if (willEditorRestart) {
      ref.current.ui.view.toolbar.element.remove()
    }
  }
  // MARK: END Editor config

  return (
    <div className="relative flex w-full h-full border">
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          // width: `calc(100% - ${additionalBtnWidth}px)`,
          width: `100%`,
        }}>
        {isRtl ? (
          <CKEditor
            className="editor"
            config={{
              language: 'ar',
              ...defaultConfig,
            }}
            data={data}
            editor={EditorBuild.Editor}
            ref={ref}
            onChange={handleChange}
            onError={handleOnError}
            onReady={handleOnReady}
          />
        ) : (
          <CKEditor
            className="editor"
            config={{
              language: 'en-gb',
              ...defaultConfig,
            }}
            data={data}
            editor={EditorBuild.Editor}
            ref={ref}
            onChange={handleChange}
            onError={handleOnError}
            onReady={handleOnReady}
          />
        )}
      </div>
      <div
        className={`absolute flex items-center lg:gap-3 lg:px-4 ${isRtl ? 'left-0' : 'right-0'} justify-end`}
        style={{
          borderBottom: '3px solid #eeeeee',
          borderLeft: !isRtl ? '1px solid #c4c4c4' : 'none',
          borderRight: isRtl ? '1px solid #c4c4c4' : 'none',
          fontSize: 14,
          height: 61,
          width: additionalBtnWidth,
        }}>
        {/* <div>
          <Button icon={CommentsIcon} variant={ButtonVariant.Icon} small />
        </div> */}
        {isCharactersWarningOpen && (
          <div
            key={isRtl + ''}
            id={'characters-warning'}
            onMouseOver={() => {
              Tooltip('#characters-warning', t('documentation:charactersErrorMessage'), 'bottom', 'custom-warning')
            }}>
            <Button className="text-danger hover:text-danger" icon={InfoIcon} small variant={ButtonVariant.Icon} />
          </div>
        )}
        <div>
          <Button
            className={getTitleAndBody(editedData).title !== '' ? '' : 'opacity-30 cursor-not-allowed'}
            small
            onClick={() => {
              if (getTitleAndBody(editedData).title !== '') {
                setSaveOpen(true)
              }
            }}>
            {t('dashboard:saveButton')}
          </Button>
        </div>
        <div>
          <Button small variant={ButtonVariant.Icon} onClick={() => setExitOpen(true)}>
            {windowWidth < 1001 ? <CrossSignIcon width={22} /> : t('dashboard:exitButton')}
          </Button>
        </div>
      </div>
      <BaseModal
        close={() => setSaveOpen(false)}
        type="confirmation"
        content={
          <div>
            <h1>{t('dashboard:saveConfirmationMessage')}</h1>
            <div className="flex justify-end my-6 gap-4">
              <Button variant={ButtonVariant.Outline} onClick={() => setSaveOpen(false)}>
                {t('common:labels.cancel')}
              </Button>
              <Button
                disabled={isUpdatingDocument}
                onClick={() => {
                  const saveData = getTitleAndBody(editedData)
                  saveChanges(saveData.body, saveData.title, removedFiles)
                }}>
                {isUpdatingDocument ? <Loader /> : t('dashboard:saveButton')}
              </Button>
            </div>
          </div>
        }
        header={''}
        isModalOpen={isSaveOpen}
        withoutCloseBtn
      />
      <BaseModal
        close={() => setExitOpen(false)}
        type="confirmation"
        content={
          <div>
            <h1>{t('dashboard:exitConfirmationMessage')}</h1>
            <div className="flex justify-end my-6 gap-4">
              <Button variant={ButtonVariant.Outline} onClick={() => setExitOpen(false)}>
                {t('common:labels.cancel')}
              </Button>
              <Button variant={ButtonVariant.Danger} onClick={exitWithoutSave}>
                {t('dashboard:exitButton')}
              </Button>
            </div>
          </div>
        }
        header={''}
        isModalOpen={isExitOpen}
        withoutCloseBtn
      />
    </div>
  )
}

export default DocumentEditor
