import { FC, useEffect } from 'react'
import omit from 'lodash/omit'
import { FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { SignUpFormValuesType } from 'utils/types'
import baseAPI from 'utils/baseAPI'
import { addTokensToLocalStorage } from 'utils/helpers/authHelpers'
import { useQuery, saveCampaignToLocalStorage } from 'utils/helpers/generalHelpers'
import { SignUpForm } from 'components/forms'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'
import { configValues } from 'utils/appConfig'
import { toast } from 'react-toastify'

const initialValues = configValues.IS_GOOGLE_RECAPTCHA_ENABLED
  ? {
      acceptedTerms: false,
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      recaptchaToken: '',
    }
  : {
      acceptedTerms: false,
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    }

const SignUpPage: FC = () => {
  const { t } = useTranslation('signUp')
  const query = useQuery()
  initialValues.email = query.get('email')?.replace(' ', '+') || ''
  const campaign = localStorage.getItem('campaign') || query.get('from') || 'organic'
  saveCampaignToLocalStorage(campaign)

  const history = useHistory()

  // useEffect(
  //   () =>
  //     configValues.IS_GOOGLE_RECAPTCHA_ENABLED
  //       ? asyncScriptOnLoad(configValues.REACT_APP_GOOGLE_RECAPTCHA ?? '')
  //       : undefined,
  //   [],
  // )

  const onSubmit = (values: SignUpFormValuesType, formikHelpers: FormikHelpers<SignUpFormValuesType>) => {
    const payload = omit(
      {
        ...values,
        adCampaign: campaign,
      },
      ['confirmPassword'],
    )
    baseAPI('api/users', { body: payload, method: 'POST' }).then((res) => {
      formikHelpers.setSubmitting(false)
      if (res.body.message === 'userWithThisEmailAlreadyExists') {
        formikHelpers.setFieldError('email', t('emailAlreadyUsed'))
      } else if (res.status !== 400) {
        if (!res.body.accessTokenUserLogin) {
          toast(t('adminShouldVerify'), { icon: false })
          history.push('/sign-in')
        } else {
          localStorage.removeItem('campaign')
          addTokensToLocalStorage({ body: res?.body })
          history.push('/')
        }
      }
    })
  }

  return (
    <div className="h-screen signup-bg">
      <Modal
        className="fixed top-0 bottom-0 z-40 flex flex-col w-full max-w-2xl overflow-auto bg-white rounded shadow-lg focus:outline-none p-9 lg:top-10 lg:bottom-10 rounded-ts-none left-1/2 transform -translate-x-1/2"
        isOpen
        overlayClassName="bg-gray-600/30 inset-0 fixed z-40">
        <ChangeLanguageHeader headerText={t('createAccount')} />
        <SignUpForm initialValues={initialValues} onSubmit={onSubmit} />
      </Modal>
    </div>
  )
}

export default SignUpPage
