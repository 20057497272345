import { useCurrentUserPermissions } from 'features/user'
import RolesEnum from 'utils/constant/enums'
import { useMemo } from 'react'

const useRoles: () => { isAdmin: boolean, isEditor: boolean } = () => {
  const role = useCurrentUserPermissions()
  // roleupdate
  return useMemo(
    () => ({
      isAdmin: role >= RolesEnum.Admin,
      isEditor: role == RolesEnum.Editor || role >= RolesEnum.Admin,
    }),
    [role],
  )
}

export default useRoles
