import React from 'react'
import ContentLoader from 'react-content-loader'

import placeholderConfig from './placeholderConfig.json'

const KanbanColumnItemPlaceholder = () => (
  <div className="z-10 flex flex-col p-2 mb-2 bg-white rounded-b-sm">
    <ContentLoader {...placeholderConfig} viewBox="0 0 247 65">
      <rect height="8" rx="4" width="44" />
      <rect height="15" width="204" x="1" y="20" />
      <rect height="10" width="59" x="52" y="47" />
      <rect height="10" rx="2" width="10" x="33" y="47" />
      <rect height="10" rx="2" width="10" x="3" y="47" />
      <circle cx="236" cy="54" r="11" />
    </ContentLoader>
  </div>
)

export default KanbanColumnItemPlaceholder
