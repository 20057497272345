import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ContentEditableInputType, ContentEditableInputRefType, InputRefType } from 'utils/types/inputs'

import DefaultNotEditingComponent from 'components/inputs/DefaultNotEditingComponent'

/*
  HOC for input components
  displays not editing data
  when clicked on, it renders editable input
*/

const withContentEditable = (WrappedComponent: any) => {
  const hocComponent = forwardRef<ContentEditableInputRefType, ContentEditableInputType>(
    (
      {
        onSubmit,
        isOnClickDisabled,
        renderNotEditingState,
        validationSchema,
        name,
        value,
        disabled,
        isLabelVisible,
        notEditingClassName,
        className = 'mb-8',
        truncated,
        inputWrapperClassName,
        shouldShowPlaceholder,
        labelClasses,
        shouldSubmitOnBlur = true,
        isDocumentation = false,
        currentRecord,
        isTippyInit,
        ...rest
      },
      ref,
    ) => {
      const [isInputVisible, setIsInputVisible] = useState<boolean>()
      const inputRef = useRef<InputRefType>(null)

      const { handleSubmit, errors, touched, values, handleBlur, handleChange, resetForm, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: { [name]: value },
        onSubmit: (values, actions) => {
          let trimmedValue
          const initialValue = values[name]

          if (typeof initialValue === 'string') {
            trimmedValue = initialValue?.trim()
          } else {
            trimmedValue = values[name]
          }

          onSubmit({ [name]: trimmedValue }, actions)
          setIsInputVisible(false)
        },
        validateOnBlur: false,
        validateOnChange: true,
        validationSchema: yup.object().shape({
          [name]: validationSchema,
        }),
      })

      useEffect(() => {
        if (isTippyInit) {
          resetForm()
        }
      }, [isTippyInit])

      useImperativeHandle(
        ref,
        () => ({
          blur: () => {
            inputRef?.current?.blur()
            setIsInputVisible(false)
          },
          contains: (target: Node) => inputRef?.current?.contains?.(target),
          focus: () => {
            setIsInputVisible(true)
            inputRef?.current?.focus()
          },
          hasInputError: Boolean(errors[name] && touched[name]),
          hideButtons: () => setIsInputVisible(false),
          resetForm,
          shakeError: () => inputRef?.current?.shakeError(),
          submitField: handleSubmit,
        }),
        [inputRef, errors, touched, handleSubmit, name, resetForm],
      )

      const inputProps = {
        disabled,
        name,
        ...rest,
        autoFocus: true,
        classes: {
          input: className,
          label: labelClasses,
          wrapper: inputWrapperClassName,
        },
        'data-testid': rest['data-testid'],
        defaultMenuIsOpen: true,
        error: errors[name],
        label: isLabelVisible ? rest.label : undefined,
        onBlur: (e: any) => {
          handleBlur(e)
          if (shouldSubmitOnBlur) setTimeout(() => handleSubmit())
        },
        onChange: (event: any) => {
          handleChange(event)
          rest?.onChange?.(event)
        },
        onCreateOption:
          rest.onCreateOption !== undefined
            ? (...args: any[]) => {
                rest?.onCreateOption?.(...args).then((result: any) => {
                  setFieldValue(name, result)
                  handleSubmit()
                })
              }
            : null,
        touched: touched[name],
        value: values[name],
      }

      if (!isInputVisible || disabled)
        return renderNotEditingState !== undefined ? (
          renderNotEditingState(inputProps.value, {
            name,
            showInput: () => {
              if (!disabled && !isOnClickDisabled) {
                setIsInputVisible(true)
                inputRef.current?.focus()
              }
            },
          })
        ) : (
          <DefaultNotEditingComponent
            className={notEditingClassName}
            data-testid={rest['data-testid']}
            disabled={disabled}
            isOnClickDisabled={isOnClickDisabled}
            isRichTextEditor={inputProps.isRichTextEditor}
            label={inputProps.label}
            labelClasses={labelClasses}
            name={name}
            placeholder={rest.placeholder}
            required={inputProps.required}
            shouldShowPlaceholder={shouldShowPlaceholder}
            showInput={() => {
              setIsInputVisible(true)
              inputRef.current?.focus()
            }}
            style={rest.style}
            truncated={truncated}
            value={inputProps.value}
          />
        )

      return <WrappedComponent isDocumentation={isDocumentation} {...inputProps} ref={inputRef} />
    },
  )

  hocComponent.displayName = 'withContentEditable'

  return hocComponent
}

export default withContentEditable
