export enum CustomFieldEnum {
  Text,
  Number,
  Dropdown,
  Link,
  Date,
  SingleLink,
  Formula,
}
export default CustomFieldEnum

// eslint-disable-next-line prettier/prettier
export enum FieldFormattingProperties {
  FONT_COLOR,
  BACKGROUND_COLOR,
  ICON,
}

export enum comparisonRules {
  EQUAL,
  LESS_THAN,
  LESS_THAN_EQUAL,
  GREATER_THAN,
  GREATER_THAN_EQUAL,
  NOT_EQUAL,
  BETWEEN,
}

export enum customFieldBaseTypes {
  Text,
  Number,
  DropDown,
  Link,
  Date,
  SingleLink,
  Formula,
  CheckBox,
  Aggregate,
}
