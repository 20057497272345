import React, { FC, useRef, useState } from 'react'
import Tippy from '@tippy.js/react'
import { SeeMoreIcon } from 'components/Icons'
import userType from '../../utils/types/UserType'
import WatchersForm from './WatchersForm'

interface CustomFieldPopoverProps {
  isSubscribeInherited: boolean;
  isCurrentUserWatcher: boolean;
  watchersList: any[] | null;
  elementMembers: userType[];
  elementId: string | number;
  onClick: (userId: string | number) => void;
}
const WatchersPopover: FC<CustomFieldPopoverProps> = ({
  isCurrentUserWatcher,
  watchersList,
  onClick,
  elementMembers,
  isSubscribeInherited,
  setVisible,
  visible,
  setIsTippyInit,
  isTippyInit,
}) => {
  const ref = useRef()

  const onClose = (e?: any) => {
    if (e) {
      e?.stopPropagation()
    }
    setVisible(false)
    setIsTippyInit(false)
  }

  return (
    <Tippy
      animation="fade"
      appendTo={() => document.body}
      arrow={false}
      className={'top-zIndex'}
      content={
        <WatchersForm
          elementMembers={elementMembers}
          isCurrentUserWatcher={isCurrentUserWatcher}
          isSubscribeInherited={isSubscribeInherited}
          isTippyInit={isTippyInit}
          watchersList={watchersList}
          onClick={onClick}
          onClose={onClose}
        />
      }
      interactive={true}
      isDestroyed={!visible}
      placement={'bottom'}
      reference={ref}
      theme={'calendar-tooltip'}
      visible={visible}
      onHide={(data) => {
        onClose()
      }}>
      <div
        className="text-sm font-extrabold cursor-pointer hover:text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setVisible(!visible)
          setIsTippyInit(!isTippyInit)
        }}>
        <div ref={ref} className="ms-1">
          <SeeMoreIcon className="w-5 h-5" />
        </div>
      </div>
    </Tippy>
  )
}

export default WatchersPopover
