import { useState, useRef, FC } from 'react'
import classnames from 'classnames'
import { useClickAway } from 'react-use'

import { RTEClasses } from 'components/inputs/RTEClassNames'
import { WysiwygColorIcon } from 'components/Icons'

const paletteColors = ['#33647E', '#008996', '#00B2A9', '#EB0045', '#FF671D', '#00CE7C']

const ColorPalette: FC = ({ editor, isSafari }) => {
  const [isPaletteOpen, setIsPaletteOpen] = useState(false)
  const paletteRef = useRef()

  useClickAway(paletteRef, () => {
    setIsPaletteOpen(false)
  })

  const onPaletteClick = () => {
    setIsPaletteOpen(!isPaletteOpen)
  }

  const onColorClick = (color: string) => {
    editor.chain().focus().setColor(color).run()
    setIsPaletteOpen(false)
  }

  const renderColorsButtons = () => {
    return paletteColors.map((color) => {
      const classes = 'w-8 h-6 m-1 rounded '

      const colorClassnames = (color: string) => {
        return classnames(classes, editor?.isActive('textStyle', { color }) && 'border-2 border-gray-800')
      }

      return (
        <button
          key={color}
          className={colorClassnames(color)}
          style={{ backgroundColor: color }}
          onClick={() => onColorClick(color)}
          onMouseDown={(e) => {
            if (isSafari) {
              onColorClick(color)
              e.preventDefault()
            }
          }}
        />
      )
    })
  }

  return (
    <div className="relative" ref={paletteRef}>
      <button className={RTEClasses}>
        <WysiwygColorIcon
          className="h-5"
          onClick={() => {
            if (!isSafari) {
              onPaletteClick()
            }
          }}
          onMouseDown={(e) => {
            if (isSafari) {
              onPaletteClick()
              e.preventDefault()
            }
          }}
        />
      </button>
      <div className="absolute z-30 flex flex-row items-center py-1 overflow-hidden text-sm bg-white rounded-lg shadow rounded-ts-none min-w-24">
        {isPaletteOpen && renderColorsButtons()}
      </div>
    </div>
  )
}

export default ColorPalette
