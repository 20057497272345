interface AppElementAttributes {
  id: number;
  elementName?: string;
  isArchived: boolean;
  tags?: string;
  accessLevel: number;
  elementOrder: number;
  uuid?: string;
  baseElementType: number;
  elementLevel: number;
  parentId?: number;
  parent?: {
    elementName: string,
    id: number | string,
    recordId: number | string,
  };
  parentLevel?: number;
  workspaceId?: number;
  stationId?: number;
  listId?: number;
  recordId?: number;
  elementPath: string;
  legacyPath?: string;
  isLegacyNumber: boolean;
  createdAt: Date;
  updatedAt: Date;
  commentsCount: number;
  directChildrenCount: number;
  allChildrenCount: number;
  search?: any;
  ownerId?: number;
  avatarType: number;
  avatar?: string;
  avatarBackgroundColor?: string;
  avatarForegroundColor?: string;
  isDeleted: boolean;
  appElementLinks: any[];
  customFieldValues: any[];
  linkedElementAppElementLinks: any[];
  description: string;
}

/**Describes application elements base types */
export enum AppElementBaseTypes {
  /**Workspace element */
  WorkspaceAppElement,

  /**Station element */
  StationAppElement,

  /**List element */
  ListAppElement,

  /**Record element */
  RecordAppElement,

  /**Wiki section (AKA Documentation) */
  WikiAppElement,

  /**Wiki page (AKA Page) */
  WikiPageAppElement,

  /**Arena Element*/
  ArenaAppElement,

  /**Tenancy Element*/
  TenancyAppElement,
}

type AppElementType = AppElementAttributes

export default AppElementType
