import * as yup from 'yup'
import { emailValidationSchema } from './singleFieldsValidationSchemas'

const validationSchema = yup.object({
  name: yup.string().trim().required(),
  question: yup.string().trim().required(),
  userEmail: emailValidationSchema,
})

export default validationSchema
