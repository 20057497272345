import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Radio } from '../../../../inputs'
import { renderDirectionIcon } from '../../../../LinkElements/LinkElements'

type selectRowItemProps = {
  key: string,
  item: any,
  name: string,
  onClickFirstDiv?: (isSelected: boolean, item: any) => void,
  onClickSecondDiv?: (item: any) => void,
  radioChangeHandle?: (e: any, isSelected: boolean, item: any) => void,
  checkboxChangeHandle?: (e: any, isSelected: boolean, item: any) => void,
  setShowCurrentElement?: (val: boolean) => void,
  showNextIcon: boolean,
  isSelected: boolean,
  isStartFromRootElement: boolean,
  elementBaseType?: string | number,
  options?: {
    canAddRecord: boolean,
    isSingleSelect: boolean,
    shouldShowPreview: boolean,
  },
}

const SelectRowItem: FC<selectRowItemProps> = ({
  key,
  item,
  name,
  onClickFirstDiv,
  onClickSecondDiv,
  radioChangeHandle,
  elementBaseType,
  isStartFromRootElement,
  checkboxChangeHandle,
  showNextIcon,
  setShowCurrentElement,
  isSelected,
  options,
}) => {
  const { i18n } = useTranslation('documentation')
  const isRtl = i18n.language === 'ar'

  return (
    <div
      key={key}
      className="flex items-center justify-between p-2 rounded hover:bg-gray-200"
      onClick={(e) => {
        e.stopPropagation()
        if (onClickFirstDiv) onClickFirstDiv(isSelected, item)
      }}>
      <div
        className={`flex ${item?.hasDocument && 'opacity-50'}`}
        onClick={(e) => {
          e.stopPropagation()
          if (onClickSecondDiv) onClickSecondDiv(item)
        }}>
        {(item?.basedType === elementBaseType || isStartFromRootElement) &&
          (options?.isSingleSelect ? (
            <Radio
              checked={isSelected}
              className="custom-radio"
              name={name}
              value={+item?.id}
              onChange={(e) => {
                if (radioChangeHandle) radioChangeHandle(e, isSelected, item)
              }}
            />
          ) : (
            <Checkbox
              checked={isSelected}
              isFullRoundedBorder={true}
              name={name}
              onChange={(e) => {
                e.stopPropagation()
                if (checkboxChangeHandle) checkboxChangeHandle(e, isSelected, item)
              }}
            />
          ))}
        <p className="text-sm">{item?.title || item?.label || item?.name}</p>
      </div>
      {showNextIcon && (
        <div
          aria-disabled={isSelected}
          className={`cursor-pointer ${isSelected && 'opacity-50'}`}
          onClick={() => {
            if (!isSelected && setShowCurrentElement) setShowCurrentElement(false)
          }}>
          {renderDirectionIcon(isRtl)}
        </div>
      )}
    </div>
  )
}
export default SelectRowItem
