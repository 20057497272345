import { Layout } from 'react-grid-layout'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { generateOpacityBackgroundColor } from '../helpers/colorHelpers'
import { DashboardStateWidgetType } from './states'

export const minWidgetHeight = (oldItem: DashboardStateWidgetType, newItem: Layout) => {
  if (
    (oldItem.baseType === DashboardWidgetBaseTypes.MyTasksOverview && newItem.w <= 7) ||
    (oldItem.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview && newItem.w <= 7)
  ) {
    return 2
  }

  if (
    (oldItem.baseType === DashboardWidgetBaseTypes.MyTasksOverview && newItem.w > 7) ||
    (oldItem.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview && newItem.w > 7)
  ) {
    return 1
  }

  if (!oldItem.dimensions) {
    return 1
  }

  return oldItem.minH
}

export const widgetHeight = (oldItem: DashboardStateWidgetType, newItem: Layout) => {
  if (
    (oldItem.baseType === DashboardWidgetBaseTypes.MyTasksOverview ||
      oldItem.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview) &&
    newItem.h === 1 &&
    newItem.w < 8
  ) {
    return 2
  }

  return newItem.h
}

enum Statuses {
  HighDeviation = 2,
  LowDeviation = 1,
  OnTrack = 0,
}

export enum States {
  Draft,
  Proposed,
  Approved,
  Started,
  OnHold,
  Completed,
  Closed,
  Cancelled,
}

export const setColorByStatus = (status: string | number, isSchedule?: boolean) => {
  if (typeof status === 'string') {
    status = parseInt(status)
  }
  if (status === Statuses.HighDeviation) {
    return {
      bgColor: generateOpacityBackgroundColor('#EB0045'),
      color: '#EB0045',
      title: isSchedule ? 'veryLate' : 'highDeviation',
    }
  }

  if (status === Statuses.LowDeviation) {
    return {
      bgColor: generateOpacityBackgroundColor('#FFC30F'),
      color: '#FFC30F',
      title: isSchedule ? 'late' : 'lowDeviation',
    }
  }

  if (status === Statuses.OnTrack) {
    return {
      bgColor: generateOpacityBackgroundColor('#00CE7C'),
      color: '#00CE7C',
      title: 'onTrack',
    }
  }

  return {
    bgColor: generateOpacityBackgroundColor('#C5D4E2'),
    color: '#C5D4E2',
    title: 'notAvailable',
  }
}

export const setColorByStates = (status: string | number) => {
  if (typeof status === 'string') {
    status = parseInt(status)
  }

  if (status === States.Closed) {
    return {
      bgColor: generateOpacityBackgroundColor('#00263A'),
      color: '#00263A',
      title: 'closed',
    }
  }

  if (status === States.Cancelled) {
    return {
      bgColor: generateOpacityBackgroundColor('#EB0045'),
      color: '#EB0045',
      title: 'cancelled',
    }
  }
  if (status === States.Approved) {
    return {
      bgColor: generateOpacityBackgroundColor('#cdd317'),
      color: '#cdd317',
      title: 'approved',
    }
  }

  if (status === States.OnHold) {
    return {
      bgColor: generateOpacityBackgroundColor('#FF671D'),
      color: '#FF671D',
      title: 'onHold',
    }
  }

  if (status === States.Completed) {
    return {
      bgColor: generateOpacityBackgroundColor('#00CE7C'),
      color: '#00CE7C',
      title: 'completed',
    }
  }

  if (status === States.Draft) {
    return {
      bgColor: generateOpacityBackgroundColor('#5c9bbc'),
      color: '#5c9bbc',
      title: 'draft',
    }
  }

  if (status === States.Proposed) {
    return {
      bgColor: generateOpacityBackgroundColor('#ffc30f'),
      color: '#ffc30f',
      title: 'proposed',
    }
  }

  if (status === States.Started) {
    return {
      bgColor: generateOpacityBackgroundColor('#6BDAD5'),
      color: '#6BDAD5',
      title: 'started',
    }
  }
  return {
    bgColor: generateOpacityBackgroundColor('#C5D4E2'),
    color: '#C5D4E2',
    title: 'notAvailable',
  }
}
