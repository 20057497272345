import { FC } from 'react'
import DashboardStateWidgetType, { ActivityWidgetWithSortType } from 'utils/types/states/DashboardStateWidgetType'
import { useTranslation } from 'react-i18next'
import { DashboardWidgetBaseTypes } from '../../utils/constant/enums/DashboardWidgetBaseType'
import WidgetTitle from '../Dashboard/Widgets/WidgetTitle'
import MyTasks from '../Dashboard/Widgets/MyTasks/MyTasks'
import WorkSpaceStationListWidget from '../Dashboard/Widgets/WorkspaceStationsListWidget/WorkspaceStationsListWidget'

const HomeDashboardBaseWidget: FC<{ data: DashboardStateWidgetType, title: string }> = ({ data, title }) => {
  const { t, i18n } = useTranslation('dashboard')
  const widgetData: any = data.data
  const hasAccess = data.isVisible
  let noData = Boolean(!widgetData)

  if (!hasAccess) {
    return (
      <div
        style={{
          backgroundColor: '#fafafa',
          direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          maxHeight: '100%',
          width: '100%',
        }}>
        <div className="absolute z-10 flex items-center justify-center w-full h-full text-lg">
          {t('noAccessToWidget')}
        </div>
        <div style={{ filter: 'blur(10px)', height: '100%', width: '100%' }}>
          <div className={`py-4 bg-white h-full rounded pr-6 pl-6`}>
            <h3 className={`font-bold text-md text-gray-800 mb-2 truncate`} title={'No Access'}>
              {t('noAccess')}
            </h3>
            <h2 className="mt-4 text-center opacity-50">{t('notHaveData')}</h2>
          </div>
        </div>
      </div>
    )
  }

  if (data.baseType === DashboardWidgetBaseTypes.StationList) {
    noData = !widgetData || !widgetData.values || !widgetData.values.length
  } else if (data.baseType === DashboardWidgetBaseTypes.Activity) {
    const activityWidgetData: ActivityWidgetWithSortType = {
      ...widgetData,
      activities: widgetData?.activities?.filter((item: any) => item.relatedObjectType === 3),
    }
    const emptyComments = !activityWidgetData.comments || !activityWidgetData.comments.length
    const emptyActivities = !activityWidgetData.activities || !activityWidgetData.activities.length

    if (!activityWidgetData || (emptyComments && emptyActivities)) {
      noData = true
    } else {
      noData = false
    }
  } else if (data.baseType === DashboardWidgetBaseTypes.MyTasks) {
    noData = !widgetData || (widgetData?.dueSoon?.length === 0 && widgetData?.overdue?.length === 0)
  }
  const isStationsList = data.baseType === DashboardWidgetBaseTypes.StationList

  if (noData) {
    return (
      <div
        style={{
          backgroundColor: '#fafafa',
          direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          height: '100vh',
          maxHeight: '100vh',
          minHeight: '100vh',
          width: '100%',
        }}>
        <div className={`py-4 bg-white h-full rounded pr-6 pl-6`}>
          <WidgetTitle
            baseType={data.baseType}
            isEditMode={false}
            text={title}
            updateWidgetTitle={undefined}
            widgetId={data.id || data.tempId}
          />
          <h2 className="mt-4 text-center opacity-50">{t('notHaveData')}</h2>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        backgroundColor: '#fafafa',
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
        height: '100%',
        width: '100%',
      }}>
      <div className="h-full py-4 pl-6 pr-6 bg-white rounded">
        <WidgetTitle
          baseType={data.baseType}
          isEditMode={false}
          text={title}
          updateWidgetTitle={undefined}
          widgetId={data.id || data.tempId}
        />
        {data?.baseType === DashboardWidgetBaseTypes.StationList && <WorkSpaceStationListWidget widgetData={data} />}
        {/* {data.baseType === DashboardWidgetBaseTypes.MyTasks && <MyTasks isAdmin={true} widgetInfo={data} />} */}
      </div>
    </div>
  )
}

export default HomeDashboardBaseWidget
