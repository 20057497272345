import React, { FC, useEffect } from 'react'
import { useMedia } from 'react-use'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useIsMyTasksLoading } from 'features/workspace'
import { clearFilters } from 'features/record'
import { clearMyTasks } from 'features/workspace/workspaceSlice'
import { useAppDispatch } from 'store'
import { CloseSideBarIcon, DashboardIcon, OpenSideBarIcon, WatchIcon } from '../../../Icons'
import WidgetTitle from '../WidgetTitle'
import MyTasks from '../MyTasks/MyTasks'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import Loader from '../../../Loader'
import WidgetSettings from './WidgetSettings'

interface SideBarMyTasksWidgetProps {
  isAdmin: boolean;
  isHomePage?: boolean;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (value: boolean) => void;
  refetchFunction?: () => void;
  dashboardWidget: DashboardWidgetType;
  isStopLoading: boolean;
  classes?: string;
  isDocHome?: boolean;
  setCurrentWidgetData: (value: boolean) => void;
  currentWidgetData: string;
  setSelectedElements: (value: any[]) => void;
  selectedElements: any[];
  setIsAllRecords?: (value: boolean) => void;
  isAllRecords?: boolean;
  setRequestSearch?: (value: string) => void;
  setIsFirstLoad: (value: string) => void;
  hasFilter?: boolean;
  isDashboardDataLoading: boolean;
}
const SideBarMyTasksWidget: FC<SideBarMyTasksWidgetProps> = ({
  isSideBarOpen,
  setIsSideBarOpen,
  dashboardWidget,
  classes = '',
  refetchFunction,
  isDocHome,
  setCurrentWidgetData,
  currentWidgetData,
  setSelectedElements,
  selectedElements,
  setIsAllRecords,
  isAllRecords,
  setRequestSearch,
  hasFilter,
  isDashboardDataLoading,
  setIsFirstLoad,
}) => {
  const { t, i18n } = useTranslation()
  const isLgBreakpoint = useMedia('(min-width: 1024px)')
  const isRtl = i18n.language === 'ar'
  const toggleIconClassName = `w-6 h-6 opacity-50 transform ${
    isRtl ? 'rotate-90' : '-rotate-90'
  } lg:rotate-0 hover:text-primary-light hover:opacity-100`
  const isLoading = useIsMyTasksLoading()
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(clearMyTasks())
      dispatch(clearFilters())
    }
  }, [])

  const onToggleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen)
  }
  return (
    <div
      className={classnames(
        `flex flex-col flex-none min-h-0 max-h-96 bg-white rounded transition-transform cursor-pointer lg:flex-row lg:max-h-screen lg:sticky lg:top-0 lg:h-screen ${classes}`,
        { 'w-1/3': isSideBarOpen && isLgBreakpoint, 'w-full overflow-y-auto mb-5': !isLgBreakpoint },
      )}>
      <div
        className={classnames('flex flex-1 justify-between px-2 py-3 lg:py-5 lg:flex-col', {
          'border-b-2 lg:border-b-0 lg:border-e-2': isSideBarOpen,
        })}
        onClick={onToggleSideBar}>
        <div className="flex justify-start items-center lg:flex-col gap-5">
          <DashboardIcon
            className={classnames('w-6 h-6 opacity-50 hover:text-primary-light hover:opacity-100', {
              'text-primary opacity-100': currentWidgetData === 'myTasks' && isSideBarOpen,
            })}
            onClick={(e) => {
              e.stopPropagation()
              setIsSideBarOpen(true)
              setIsFirstLoad(true)
              setCurrentWidgetData('myTasks')
              dispatch(clearFilters())
              if (setIsAllRecords) setIsAllRecords(false)
              if (setRequestSearch) setRequestSearch('')
            }}
          />
          <WatchIcon
            className={classnames('w-7 h-7 opacity-50 hover:text-primary-light hover:opacity-100', {
              'text-primary opacity-100': currentWidgetData === 'watched' && isSideBarOpen,
            })}
            onClick={(e) => {
              e.stopPropagation()
              setIsSideBarOpen(true)
              dispatch(clearFilters())
              setCurrentWidgetData('watched')
            }}
          />
        </div>

        {isSideBarOpen ? (
          isRtl ? (
            <OpenSideBarIcon className={toggleIconClassName} onClick={onToggleSideBar} />
          ) : (
            <CloseSideBarIcon className={toggleIconClassName} onClick={onToggleSideBar} />
          )
        ) : isRtl ? (
          <CloseSideBarIcon className={toggleIconClassName} onClick={onToggleSideBar} />
        ) : (
          <OpenSideBarIcon className={toggleIconClassName} onClick={onToggleSideBar} />
        )}
      </div>
      {isSideBarOpen &&
        (isLoading || isDashboardDataLoading ? (
          <Loader loaderClasses="w-full h-full overflow-y-auto p-4" svgClasses="w-8 h-8 m-auto" />
        ) : (
          <div className="w-full h-full p-4 overflow-y-auto">
            {currentWidgetData ? (
              <>
                <div className="flex justify-between container-relative">
                  <WidgetTitle
                    baseType={dashboardWidget?.baseType}
                    isEditMode={false}
                    text={currentWidgetData === 'myTasks' ? t('dashboard:myTasks') : t('dashboard:watchedRecords')}
                    updateWidgetTitle={undefined}
                    widgetId={dashboardWidget?.id}
                  />
                  <WidgetSettings />
                </div>
                {dashboardWidget ? (
                  <MyTasks
                    hasFilter={hasFilter}
                    isSideBar={true}
                    refetchFunction={refetchFunction}
                    showAll={currentWidgetData !== 'myTasks'}
                    widgetInfo={dashboardWidget}
                    isDocHome={isDocHome}
                    setIsAllRecords={setIsAllRecords}
                    isAllRecords={isAllRecords}
                  />
                ) : (
                  <h2 className="mx-8 text-center opacity-50">{t('dashboard:notHaveData')}</h2>
                )}
              </>
            ) : (
              <div className="flex justify-center w-full mt-52">{t(`common:notAvailable`)}</div>
            )}
          </div>
        ))}
    </div>
  )
}
export default SideBarMyTasksWidget
