import React, { FC } from 'react'

const DocumentationDocumentPropertiesPanel: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      className="border-s px-4 py-6 overflow-y-auto shadow-sm"
      style={{
        width: 340,
        minWidth: 340,
        height: 'calc(100% - 148px)',
      }}>
      {children}
    </div>
  )
}

export default DocumentationDocumentPropertiesPanel
