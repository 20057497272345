import StationHeader from 'components/StationHeader'
import { useCurrentStationObj } from 'features/station'
import React, { FC, useEffect, useRef } from 'react'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearCurrentDocumentation } from '../../../features/station/stationSlice'
import { useHasCurrentWorkspacePremiumPlan } from '../../../features/workspace'
import StationDocumentationHomePage from './StationDocumentationHomePage'
import StationDocumentationSearchPage from './StationDocumentationSearchPage'
import StationDocumentationSinglePageEdit from './StationDocumentationSinglePageEdit'
import StationDocumentationSinglePageView from './StationDocumentationSinglePageView'
import StationDocumentationNewDocPage from './StationDocumentationNewDocPage'
import StationDocumentationArchivePage from './StationDocumentationArchivePage'

const StationDocumentationPage: FC = () => {
  const currentStation = useCurrentStationObj()
  const { path, url } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()

  useEffect(() => {
    if (!isPremiumPlan && path.includes('documentation') && currentStation.id) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearCurrentDocumentation())
    }
  }, [currentStation])

  useEffect(() => {
    if (isPremiumPlan) {
      if (location.pathname.slice(-13) === 'documentation' || location.pathname.slice(-14) === 'documentation/') {
        const stationDoc = currentStation?.wikiElementData
        if (stationDoc) {
          history.replace(
            location.pathname +
              (location.pathname.slice(-14) === 'documentation/' ? `${stationDoc.id}/home` : `/${stationDoc.id}/home`),
          )
        } else {
          history.replace(location.pathname + '/create')
        }
      }
    }
  }, [location, currentStation])

  return (
    <div className="flex flex-col min-h-screen -m-6 overflow-hidden md:-m-10">
      <StationHeader isDocumentationPage stationName={currentStation?.name}></StationHeader>
      <Switch>
        <Route path={`${path}/create`}>
          <StationDocumentationNewDocPage />
        </Route>
        <Route path={`${path}/:docId/home`}>
          <StationDocumentationHomePage />
        </Route>
        <Route path={`${path}/:docId/view/:id`}>
          <StationDocumentationSinglePageView />
        </Route>
        <Route path={`${path}/:docId/edit/:id`}>
          <StationDocumentationSinglePageEdit />
        </Route>
        <Route path={`${path}/:docId/search`}>
          <StationDocumentationSearchPage />
        </Route>
        <Route path={`${path}/:docId/archived`}>
          <StationDocumentationArchivePage />
        </Route>
      </Switch>
    </div>
  )
}

export default StationDocumentationPage
