import Chart from 'components/Dashboard/Chart/Chart'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import DashboardStateWidgetType, { WorkspaceStationsOrListOverview } from 'utils/types/states/DashboardStateWidgetType'
import { useTranslation } from 'react-i18next'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { States } from '../../../../utils/types/widgetUtils'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'

// Since we have two types of widgets that similar I've created universal component
const DiagramOverviewWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage }) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const { t, i18n } = useTranslation('dashboard, station')
  const [isLoading, setIsLoading] = useState(true)
  const diagramWidgetData: WorkspaceStationsOrListOverview = widget ? widget.data : widgetInfo ? widgetInfo.data : null
  const isWorkspaceStationsOverview = widgetInfo.baseType === DashboardWidgetBaseTypes.WorkspaceStationsOverview
  const isRtl = i18n.language === 'ar'
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  const getDataset: any = useCallback(
    (stationOverview: boolean) => {
      if (stationOverview) {
        return diagramWidgetData?.values.reduce(
          (
            acc: {
              data: number[],
              labels: string[],
              label: string,
              barPercentage: number,
              categoryPercentage: number,
              backgroundColor: string,
            },
            curr,
          ) => {
            return {
              ...acc,
              data: [...acc.data, curr.data],
              labels: [
                ...acc.labels,
                curr.label !== null && typeof curr.label === 'number'
                  ? t(`station:stationStates.${States[curr.label].toLowerCase()}`)
                  : curr.label || t('dashboard:notAvailable'),
              ],
            }
          },
          {
            backgroundColor: '#dbf3f3',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            data: [],
            label: '',
            labels: [],
          },
        )
      } else {
        return diagramWidgetData?.values.map((val) => {
          return {
            ...val,
            data: typeof val.data === 'string' ? [parseInt(val.data)] : [val.data],
            label:
              val.label !== null && typeof val.label === 'number'
                ? t(`station:stationStates.${States[val.label].toLowerCase()}`)
                : val.label || t('dashboard:notAvailable'),
          }
        })
      }
    },
    [diagramWidgetData?.values, widgetInfo.baseType],
  )

  if (isDataLoading) {
    return <Loader />
  }

  return (
    <div style={{ height: 'calc(100% - 40px)', overflowY: 'auto' }}>
      {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
        <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
      )}
      <Chart
        datasets={
          isRtl
            ? isWorkspaceStationsOverview
              ? [getDataset(isWorkspaceStationsOverview)].reverse()
              : getDataset(false).reverse()
            : isWorkspaceStationsOverview
            ? [getDataset(isWorkspaceStationsOverview)]
            : getDataset(isWorkspaceStationsOverview)
        }
        labels={
          isWorkspaceStationsOverview
            ? getDataset(isWorkspaceStationsOverview).labels
            : diagramWidgetData?.labels || ['']
        }
        options={{
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: isWorkspaceStationsOverview ? false : true,
              labels: isWorkspaceStationsOverview
                ? null
                : {
                    boxHeight: 10,
                    boxWidth: 10,
                    padding: 20,
                    usePointStyle: true,
                  },
              position: 'bottom',
            },
          },
          responsive: true,
          scales: {
            xAxes: {
              barPercentage: 1.0,
              categoryPercentage: 1.0,
            },
            yAxes: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
        type="bar"
      />
    </div>
  )
}

export default memo(DiagramOverviewWidget)
