import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { GridViewType } from 'utils/types'
import { GridFilterType, GridSortType } from 'utils/types/GridViewType'
import StateType from 'utils/types/states'

const selectGridViews = (state: StateType) => state.listGrid.gridViews
const selectCurrentListId = (state: StateType) => state.listGrid.gridViews[0]?.listId

const selectGridFiltersById = createSelector(
  [selectGridViews, (_, id) => id],
  (gridViews, gridViewId) => gridViews.find(({ id }) => id === gridViewId)?.filter || {},
)
const selectSortByGridId = createSelector(
  [selectGridViews, (_, id) => id],
  (gridViews, gridViewId) =>
    gridViews.find(({ id }) => id === gridViewId) || {
      direction: 'asc',
      sort: 'id',
    },
)

export const useGridViews = (): GridViewType[] => useSelector(selectGridViews)
export const useFiltersByGridId = (gridId: number): GridFilterType =>
  useSelector((state: StateType) => selectGridFiltersById(state, gridId))
export const useSortByGridId = (gridId: number): GridSortType =>
  useSelector((state: StateType) => selectSortByGridId(state, gridId))

export const useCurrentListId = (): number => useSelector(selectCurrentListId)
