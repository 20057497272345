import * as yup from 'yup'
import i18n from 'i18n'
import {
  confirmPasswordValidationSchema,
  emailValidationSchema,
  passwordValidationSchema,
  requiredTextFieldValidationSchema,
} from './singleFieldsValidationSchemas'

const validationSchema = yup.object().shape({
  acceptedTerms: yup
    .bool()
    .test('consent', i18n.t('validation:errors.termsAndConditionsMustBeAccepted'), (value) => value === true)
    .required(),
  confirmPassword: confirmPasswordValidationSchema('password'),
  email: emailValidationSchema,
  firstName: requiredTextFieldValidationSchema('firstName'),
  lastName: requiredTextFieldValidationSchema('lastName'),
  password: passwordValidationSchema,
})
export default validationSchema
