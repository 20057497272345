import React, { FC } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'

import { RecordType } from 'utils/types'

import KanbanColumnItem from './KanbanColumnItem'

const KanbanItemCopy: FC<{ provided: DraggableProvided, item: RecordType }> = ({ provided, item }) => (
  <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
    <KanbanColumnItem {...item} />
  </div>
)

export default KanbanItemCopy
