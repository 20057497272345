import React, { useRef, useState } from 'react'
import { useEffect } from 'react'

const withKeyboardNavigation = (WrappedComponent: any) => {
  const HocComponent = ({ ...props }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isFirstEnter, setIsFirstEnter] = useState<boolean>(true)

    const inputRef = useRef<any>()

    useEffect(() => {
      if (!isFocused) {
        inputRef.current.blur()
      }
    }, [isFocused])

    const onKeyDown = (e: any) => {
      if (isFocused && e.key === 'Enter' && isFirstEnter && inputRef?.current) {
        inputRef.current.focus()
        e.preventDefault()
        setIsFirstEnter(false)
      }
      if (!isFirstEnter) {
        e.stopPropagation()
      }
    }

    return (
      <div
        className="w-full"
        tabIndex={0}
        onBlur={() => {
          setIsFirstEnter(true)
        }}
        onFocus={() => setIsFocused(true)}
        onKeyDown={onKeyDown}>
        <WrappedComponent
          {...props}
          handleRecordChange={(event: any) => {
            props?.handleRecordChange?.(event)
            setIsFocused(false)
          }}
          ref={inputRef}
          tabIndex={-1}
        />
      </div>
    )
  }
  return HocComponent
}
export default withKeyboardNavigation
