import React, { ElementRef, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { CustomFieldOptionType } from 'utils/types/customFieldsType'
import { CloseIcon, DragIcon } from '../../Icons'
import { Dropdown, Input } from '../../inputs'
import { styles } from '../../forms/SingleColorForm'

const style = {
  cursor: 'move',
}
const OptionRow: FC<CustomFieldOptionType> = ({
  optionRef,
  onDeleteOption,
  colorOptions,
  optionValue,
  optionColor,
  optionDescription,
  onChangeColor,
  onChangeDescription,
  onBlur,
  onChangeName,
  showDescription = true,
  handlerId,
  isDragging,
  addText,
}) => {
  const { t } = useTranslation('customFields')
  const [title, setTitle] = useState<string>(optionValue || '')
  const [isTitleEditMode, setIsTitleEditMode] = useState<boolean>(false)
  const [isDescriptionEditMode, setIsDescriptionEditMode] = useState<boolean>(false)

  const Option = ({
    innerRef,
    innerProps,
    data: { value },
  }: {
    innerRef: ElementRef<any>,
    innerProps: any,
    data: { value: string },
  }) => (
    <div
      className="w-20 h-4 my-2 rounded cursor-pointer"
      ref={innerRef}
      style={{ backgroundColor: value }}
      {...innerProps}
    />
  )

  const SingleValue = ({ data: { value } }: { data: { value: string } }) => (
    <div className={classnames('w-36 h-4 rounded')} style={{ backgroundColor: value }} />
  )
  const tableCellClasses = 'text-sm font-bold leading-6 transition'
  return (
    <div
      key={`${optionValue}_${optionColor}`}
      className={`w-full p-1 mt-0.5 ${isDragging ? 'opacity-50' : 'opacity-100'}`}
      data-handler-id={handlerId}
      ref={optionRef}
      style={style}>
      <tr className="flex items-center justify-between">
        {showDescription && (
          <td>
            <DragIcon className="w-5 h-5 me-1" />
          </td>
        )}
        {addText && (
          <td className="flex font-bold text-small">
            <span>
              {t('ifTheValue')} <span className="ms-5">=</span>
            </span>
          </td>
        )}
        <td
          style={{ width: addText ? 'auto' : '-webkit-fill-available' }}
          // onBlur={() => setIsTitleEditMode(false)}
          onClick={() => setIsTitleEditMode(true)}>
          <Input
            autoFocus={isTitleEditMode}
            classes={{ wrapper: 'px-1 text-sm w-full' }}
            name="name"
            placeholder={t('labels.customFieldOptionName')}
            type="text"
            value={title}
            onBlur={() => {
              onChangeName(title)
              onBlur('optionCaption', title)
              setIsTitleEditMode(false)
            }}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </td>
        {showDescription && (
          <td onBlur={() => setIsDescriptionEditMode(false)} onClick={() => setIsDescriptionEditMode(true)}>
            <Input
              autoFocus={isDescriptionEditMode}
              classes={{ wrapper: 'px-1 text-sm w-full' }}
              name="name"
              placeholder={t('labels.customFieldDescription')}
              type="text"
              value={optionDescription}
              onBlur={() => onBlur('description', optionDescription)}
              onChange={(e) => onChangeDescription(e.target.value)}
            />
          </td>
        )}
        <td>
          <div>
            <Dropdown
              classes={{
                input: 'react-select',
                wrapper: classnames(`w-${showDescription ? '9' : '20'}`, tableCellClasses),
              }}
              components={{
                Option,
                SingleValue,
              }}
              name="customOption"
              options={colorOptions}
              styles={{ ...styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={optionColor}
              onChange={(e) => {
                onChangeColor(e.target.value)
                onBlur('color', e.target.value)
              }}
            />
          </div>
        </td>
        <td>
          <div className="rounded-sm cursor-pointer p-0.5 hover:bg-warning hover:text-white">
            <CloseIcon
              className="w-4 h-4"
              onClick={(e) => {
                e.stopPropagation()
                onDeleteOption()
              }}
            />
          </div>
        </td>
      </tr>
    </div>
  )
}

export default OptionRow
