import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { RecordType } from '../../utils/types'
import { getButtonClass } from '../forms/ActivityForm'
import SubTasks from '../SubTasks'
import InputLabel from '../inputs/InputLabel'
import { useHasCurrentWorkspacePremiumPlan } from '../../features/workspace'

type SwitchListsPropTypes = {
  elementId: string | number,
  isEditor: boolean,
  currentRecord: RecordType,
}

const SwitchLists: FC<SwitchListsPropTypes> = (props) => {
  const { t, i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const { pathname } = useLocation()
  const isHomePage = pathname === '/' || pathname === '/dashboard'
  const [showCheckList, setShowCheckList] = useState(false)
  const [showSubtasks, setShowSubtasks] = useState(true)
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const showComponent = isPremiumPlan || isHomePage

  return (
    showComponent && (
      <>
        <InputLabel className={classnames('block text-sm font-bold')} label={t('records:subtasks')} name={'subtasks'} />
        {showCheckList && (
          <div className="flex items-center justify-between my-3 opacity-50">
            <h5 className="block font-bold" id="subtasks">
              {t('documentation:emptyChildren')}
            </h5>
          </div>
        )}
        {showSubtasks && <SubTasks {...props} />}
      </>
    )
  )
}
export default SwitchLists
