import { ElementTypeWithColor } from 'components/forms/AddListToStationForm'
import { groupBy } from 'lodash'
import { ListLabelType, ListStatusType, RecordType } from 'utils/types'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { DropdownOptionType } from '../types/inputs'
import i18n from '../../i18n'
import {
  AggregateIcon,
  CalendarIcon,
  CheckBoxIcon,
  FormulaIcon,
  ListIcon,
  NumberIcon,
  RecordLinkIcon,
  TextIcon,
} from '../../components/Icons'
import { customFieldBaseTypes } from '../constant/enums/customFields'

const getElementsSorted = ({
  allPreviousElements,
  allSelectedElements,
}: {
  allPreviousElements: ListStatusType[] | ListLabelType[],
  allSelectedElements: ElementTypeWithColor[],
}): {
  newElements: { color: string | undefined, name: string }[],
  removedElements: ListStatusType[] | ListLabelType[],
  unchangedElements: { color: string | undefined, id: number | undefined, name: string }[],
  updatedElements: { color: string | undefined, id: number | undefined, name: string }[],
} => {
  const allSelectedElementsIds = allSelectedElements.map(({ id }) => id)
  const { selectedElements = [], removedElements = [] } = groupBy(allPreviousElements, ({ id }) => {
    if (allSelectedElementsIds.indexOf(id) > -1) {
      return 'selectedElements'
    }
    return 'removedElements'
  })
  const selectedElementsIds = selectedElements.map(({ id }) => id)
  const {
    newElements = [],
    updatedElements = [],
    unchangedElements = [],
  } = groupBy(allSelectedElements, ({ id = 0, color }) => {
    if (selectedElementsIds.indexOf(id) < 0) return 'newElements'
    const previousElement = allPreviousElements.find(({ id: previousElementId }) => id === previousElementId)
    if (previousElement?.color !== color) return 'updatedElements'
    return 'unchangedElements'
  })
  return {
    newElements: newElements.map(({ element, color }) => ({ color, name: element })),
    removedElements,
    unchangedElements: unchangedElements.map(({ element, id, color, position }) => ({
      color,
      id,
      name: element,
      position,
    })),
    updatedElements: updatedElements.map(({ element, id, color, position }) => ({
      color,
      id,
      name: element,
      position,
    })),
  }
}

export { getElementsSorted }

export const getCustomFieldsOptions = (
  elementBaseType: AppElementBaseTypes,
  isFromAdmin?: boolean,
  isTeamsPlan?: boolean,
): DropdownOptionType[] => {
  const basicFields = [
    { Icon: TextIcon, label: i18n.t('customFields:fieldType.text'), value: customFieldBaseTypes.Text },
    { Icon: NumberIcon, label: i18n.t('customFields:fieldType.number'), value: customFieldBaseTypes.Number },
    { Icon: ListIcon, label: i18n.t('customFields:fieldType.dropdown'), value: customFieldBaseTypes.DropDown },
    { Icon: CalendarIcon, label: i18n.t('customFields:fieldType.date'), value: customFieldBaseTypes.Date },
    { Icon: CheckBoxIcon, label: i18n.t('customFields:fieldType.checkBox'), value: customFieldBaseTypes.CheckBox },
  ]

  const teamsFields = [
    { Icon: AggregateIcon, label: i18n.t('customFields:fieldType.aggregate'), value: customFieldBaseTypes.Aggregate },
    { Icon: FormulaIcon, label: i18n.t('customFields:fieldType.formula'), value: customFieldBaseTypes.Formula },
  ]

  const listSpecificFields = [
    { Icon: RecordLinkIcon, label: i18n.t('customFields:fieldType.link'), value: customFieldBaseTypes.Link },
  ]

  if (isFromAdmin) {
    return basicFields
  } else if (elementBaseType === AppElementBaseTypes.ListAppElement) {
    return isTeamsPlan
      ? [...basicFields, ...teamsFields, ...listSpecificFields]
      : [...basicFields, ...listSpecificFields]
  } else return isTeamsPlan ? [...basicFields, ...teamsFields] : basicFields
}

export const getRecordOrder = ({
  statuses,
  allRecords,
  recordsInGroups,
  currentStatus,
  recordIndex,
  recordId,
}: {
  statuses: ListStatusType[],
  allRecords: RecordType[],
  recordsInGroups: any,
  currentStatus: string,
  recordIndex: number,
  recordId: number,
}) => {
  const currentStatusRecords = recordsInGroups[`${currentStatus}`]
  const targetPositionRecord = currentStatusRecords
    ? recordIndex === currentStatusRecords.length
      ? currentStatusRecords[recordIndex - 1]
      : currentStatusRecords[recordIndex]
    : undefined

  if (targetPositionRecord) {
    const addedValue = recordIndex === currentStatusRecords.length ? 1 : 0
    return targetPositionRecord?.appElements[0]?.elementOrder + addedValue
  } else if ((currentStatusRecords === undefined || currentStatusRecords?.length === 0) && allRecords.length > 1) {
    const orderedStatuses = [{ id: null, position: 0 }, ...statuses]?.sort((a, b) => a.position - b.position)
    const currentStatusIndex = orderedStatuses?.findIndex((status) => `${status.id}` === currentStatus)
    let i = currentStatusIndex
    // to get the last record order from previous status records
    while (i >= 1) {
      const prevStatusId = orderedStatuses[i - 1].id
      const prevList = recordsInGroups[`${prevStatusId}`]?.filter(
        (recElement: RecordType) => recElement.id !== recordId,
      )
      if (prevList && prevList.length > 0) {
        return prevList[prevList.length - 1].appElements[0]?.elementOrder + 1
      } else i = i - 1
    }
    // otherwise look for the first record order from next lists
    if (i === 0) {
      i = currentStatusIndex
      while (i < orderedStatuses.length) {
        const nextStatusId = orderedStatuses[i + 1].id
        const nextList = recordsInGroups[`${nextStatusId}`]
        if (nextList && nextList.length > 0) {
          return nextList[0].appElements[0]?.elementOrder - 1
        } else i = i + 1
      }
    }
  } else {
    return 0
  }
}
