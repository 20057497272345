import React, { FC, memo, useEffect, useState } from 'react'
import { DashboardStateWidgetType } from 'utils/types/states'
import Chart from 'components/Dashboard/Chart/Chart'
import { useTranslation } from 'react-i18next'
import { setColorByStatus } from 'utils/types/widgetUtils'
import { useSize } from 'react-use'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'

const RecordsHealthWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div style={{ width: '100%' }} />)
  const recordHealthData: any = widget ? widget.data : widgetInfo.data
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }

  const pieChartDataset = recordHealthData.values.map((item: any) => {
    return {
      backgroundColor: [setColorByStatus(item.status, true).color, '#f0f6fc'],
      borderRadius: 20,
      borderWidth: 7,
      cutout: '30%',
      data: [item.data, 100 - item.data],
    }
  })

  return (
    <div className="flex flex-col items-center" style={{ height: 'calc(100% - 60px)', width: '100%' }}>
      {sized}
      {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
        <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
      )}
      <div style={{ height: elementWidth < 271 ? '64%' : '80%', marginBottom: 10, width: '100%' }}>
        <Chart
          datasets={pieChartDataset}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              },
            },
          }}
          type="pie"
        />
      </div>
      <div className="grid grid-cols-2 gap-x-1 gap-y-1">
        {recordHealthData.values.map((item: any, idx: number) => (
          <div
            key={item.label + idx.toString()}
            className="flex items-center gap-1"
            style={{
              fontSize: elementWidth < 271 ? 12 : 14,
            }}>
            <div
              style={{
                backgroundColor: setColorByStatus(item.status, true).color,
                borderRadius: '100%',
                height: 8,
                minWidth: 8,
                width: 8,
              }}
            />
            <div>{t(`dashboard:${setColorByStatus(item.status, true).title}`)}</div>
            <div>&#8213;</div>
            <div>{item.data}%</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(RecordsHealthWidget)
