import React, { FC, ReactNode, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { StationType } from 'utils/types'

import { archiveStationById, removeStationById, updateStation } from 'features/station'

import { DashboardIcon, DocFileIcon, LateIcon, OnTrackIcon, VeryLateIcon } from 'components/Icons'
import SettingsDropdown from 'components/SettingsDropdown'
import ConfirmationModal from 'components/ConfirmationModal'
import DisappearingText from 'components/DisappearingText'

import { getStateName } from 'utils/helpers/stationHelpers'
import { withAdminRole } from 'hocs/withRole'
import EntryAvatar from 'components/EntryAvatar'
import { clearStationDashboardPage, SetCurrentStationArchiveStatus } from '../../features/station/stationSlice'
import { useCurrentWorkspace } from '../../features/workspace'
import { useRoles } from '../../hooks'

interface SingleStationTilePropsType {
  workspaceId: string | undefined;
  isLastInRow: boolean;
  station: StationType;
  isMovingStation: boolean;
  isReorderMode: boolean;
  isProWorkspace: boolean;
  isArchived: boolean;
  isSideBarOpen?: boolean;
}

const statusIcons = [OnTrackIcon, LateIcon, VeryLateIcon]

// props.closeDropdown comes from SettingsDropdown component
// React.children.map then React.cloneElement was used
// to add props.closeDropdown
const RemoveButton = ({ children, onClick, ...props }: { children: ReactNode }) => (
  <button
    {...props}
    onClick={(e) => {
      onClick?.(e)
      props.closeDropdown?.()
    }}>
    {children}
  </button>
)

const RemoveStationButton = withAdminRole(RemoveButton)

const SingleStationTile: FC<SingleStationTilePropsType> = ({
  station,
  workspaceId,
  isLastInRow,
  isMovingStation,
  isReorderMode,
  isProWorkspace,
  isArchived,
  isSideBarOpen = false,
}) => {
  const { id, name, state, owner, isPinned, appElement } = station
  const { t } = useTranslation('station')
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLDivElement | null>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const stateName = getStateName(state)
  const { isAdmin } = useRoles()
  const currentWorkspace = useCurrentWorkspace()
  const isMobile = window.innerWidth <= 760
  const isDraftOrNotAvailable = stateName === 'draft' || stateName === 'notAvailable'
  const isArchivedByWorkspace =
    currentWorkspace?.appElement.archivedById &&
    +currentWorkspace?.appElement.archivedById === +currentWorkspace?.appElement.id

  const onDelete = () => {
    setIsModalOpen(false)
    dispatch(removeStationById(id))
  }

  const renderCardContent = () => {
    return (
      <>
        <EntryAvatar entry={station} isDraft={isDraftOrNotAvailable} isTileInitials />
        <div className="flex flex-col w-full min-w-0">
          <div className="flex items-center">
            <p className="text-xs font-bold uppercase me-auto">{t(`stationStates.${stateName}`)}</p>

            <div className="flex items-center justify-end gap-1" ref={(ref) => (inputRef.current = ref)}>
              {isProWorkspace && (
                <>
                  <Link className="hover:text-primary" to={`/workspace/${workspaceId}/stations/${id}/documentation`}>
                    <DocFileIcon className="w-6 h-6" />
                  </Link>
                  <Link className="hover:text-primary" to={`/workspace/${workspaceId}/stations/${id}/dashboard`}>
                    <DashboardIcon
                      className="w-6 h-6"
                      onClick={() => {
                        dispatch(clearStationDashboardPage())
                      }}
                    />
                  </Link>
                </>
              )}

              {!isArchivedByWorkspace && (
                <SettingsDropdown
                  childClasses={classnames('w-44 flex flex-col text-gray-600', {
                    'end-0 rounded-te-none': isLastInRow,
                    'rounded-ts-none': !isLastInRow,
                  })}
                  isClickDisabled={isMovingStation}>
                  {!isArchived && (
                    <>
                      <Link
                        className="py-2 font-normal hover:text-primary"
                        to={`/workspace/${workspaceId}/stations/${id}/details`}>
                        {t('common:labels.details')}
                      </Link>
                      <button
                        className="py-2 text-start hover:text-primary"
                        onClick={(e) => {
                          if (workspaceId) {
                            dispatch(updateStation({ id, isPinned: !isPinned, name, owner, workspaceId: +workspaceId }))
                          }
                          e.stopPropagation()
                        }}>
                        {isPinned ? t('common:labels.unpin') : t('common:labels.pin')}
                      </button>
                    </>
                  )}
                  <RemoveStationButton
                    className="py-2 text-danger text-start hover:text-primary"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsArchiveModalOpen(true)
                    }}>
                    {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
                  </RemoveStationButton>
                  {isArchived && isAdmin && (
                    <RemoveStationButton
                      className="py-2 text-danger text-start hover:text-primary"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsModalOpen(true)
                      }}>
                      {t('common:labels.remove')}
                    </RemoveStationButton>
                  )}
                </SettingsDropdown>
              )}
            </div>
            {/* <div
              className={classnames('p-1.5 bg-gray-200 rounded-s-lg border-2 border-e-0 -my-2 -me-6 ms-2', {
                'border-gray-400 relative -end-0.5': isDraftOrNotAvailable,
              })}>
              <StatusIcon className="w-6 h-6" />
            </div> */}
          </div>
          <div className="flex justify-between mt-2 text-lg font-bold text-gray-800">
            <span className="line-clamp-2">{name}</span>
          </div>
          <DisappearingText
            classNames={{
              gradient: isDraftOrNotAvailable ? 'from-gray-200' : '',
              wrapper: 'mt-1',
            }}
            text={appElement?.description}
          />
          {/*<div className="flex items-center mt-3">*/}
          {/*  <div className="flex items-center min-w-0">*/}
          {/*    <img*/}
          {/*      alt={`${owner?.firstName} ${owner?.lastName}`}*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full me-3"*/}
          {/*      src={owner?.link || '/assets/images/placeholder.svg'}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="flex flex-row-reverse items-center min-w-0">*/}
          {/*    <div className="flex-none h-6 px-1 text-sm font-bold text-center bg-gray-200 rounded-full min-w-6 leading-6">*/}
          {/*      +12*/}
          {/*    </div>*/}
          {/*    <img*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full -me-1"*/}
          {/*      src="https://randomuser.me/api/portraits/men/92.jpg"*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full -me-1"*/}
          {/*      src="https://randomuser.me/api/portraits/women/49.jpg"*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full -me-1"*/}
          {/*      src="https://randomuser.me/api/portraits/women/9.jpg"*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full -me-1"*/}
          {/*      src="https://storage.googleapis.com/super-avatars-test/386adda2-65c9-4907-aa35-801dde279403.jpeg"*/}
          {/*    />*/}
          {/*    <img*/}
          {/*      className="flex-none w-6 h-6 bg-gray-200 rounded-full -me-1"*/}
          {/*      src="https://randomuser.me/api/portraits/men/20.jpg"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="items-center mt-4 md:flex">*/}
          {/*  <div className="flex items-center text-sm me-auto">*/}
          {/*    <svg className="w-5 text-gray-600" fill="none" viewBox="0 0 24 24">*/}
          {/*      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">*/}
          {/*        <path d="M13 14.78h4" />*/}
          {/*        <path*/}
          {/*          clipRule="evenodd"*/}
          {/*          d="M19 3.5H5a2 2 0 00-2 2v13c0 1.1.9 2 2 2h14a2 2 0 002-2v-13a2 2 0 00-2-2z"*/}
          {/*        />*/}
          {/*        <path d="M7 14.5l.92.83 1.85-1.66M13 9.78h4M7 9.5l.92.83 1.85-1.66" />*/}
          {/*      </g>*/}
          {/*    </svg>*/}
          {/*    <strong className="font-bold ms-1">63</strong>*/}
          {/*    <span className="text-xs">/125</span>*/}
          {/*  </div>*/}
          {/*  <div className="px-3 py-0 text-sm text-white bg-gray-600 rounded-full ms-2">23 updates</div>*/}
          {/*  <div className="px-3 py-0 text-sm font-bold text-white rounded-full bg-primary ms-2">2 mentions</div>*/}
          {/*</div>*/}
        </div>
      </>
    )
  }

  const classNameStyle = classnames(
    'flex h-48 p-6 mb-6 md:mb-10 rounded-ts-none rounded-2xl transition-shadow shadow-sm hover:shadow',
    {
      'bg-white': !isDraftOrNotAvailable,
      'border-2 border-gray-400 bg-gray-200': isDraftOrNotAvailable,
    },
  )

  const onClickCard = (event: any) => {
    const { target } = event
    dispatch(SetCurrentStationArchiveStatus(station?.appElement?.isArchived))
    const isClickedInside = inputRef?.current ? inputRef?.current.contains(target) : false
    if (isClickedInside) {
      event.preventDefault()
    }
  }

  const onArchive = () => {
    setIsArchiveModalOpen(false)
    const elementId = station.appElements ? station.appElements[0].id : station.appElement.id
    dispatch(archiveStationById({ elementId, recursive: Number(isChecked), status: Number(!isArchived) }))
    // history.push(`/workspace/${workspaceId}/stations`)
  }

  return (
    <>
      <div
        className={`w-full ps-10 pe-6 md:pe-10 flex-0 ${
          !isReorderMode ? (isSideBarOpen ? 'md:w-1/2 xl:w-1/3' : 'md:w-6/12 lg:w-4/12 2xl:w-3/12') : 'max-w-36'
        } ${isMovingStation && 'opacity-50'}`}
        style={isReorderMode && !isMobile ? { width: '300px' } : {}}>
        {!isReorderMode ? (
          <Link className={classNameStyle} to={`/workspace/${workspaceId}/stations/${id}`} onClick={onClickCard}>
            {renderCardContent()}
          </Link>
        ) : (
          <div className={classNameStyle} onClick={onClickCard}>
            {renderCardContent()}
          </div>
        )}
      </div>
      <ConfirmationModal
        confirmationMessage={t('station:confirmStationDelete', { interpolation: { escapeValue: false }, name })}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={onDelete}
      />
      <ConfirmationModal
        checkBoxMessage={isArchived ? t('common:archiveRecursiveMessage') : undefined}
        confirmMessage={!isArchived ? t('common:labels.archive') : t('common:labels.unarchive')}
        confirmationMessage={t(!isArchived ? 'station:confirmStationArchive' : 'station:confirmStationUnarchive', {
          interpolation: { escapeValue: false },
          name: station.name,
        })}
        isChecked={isChecked}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onChangeCheckBox={() => setIsChecked(!isChecked)}
        onConfirm={onArchive}
      />
    </>
  )
}

export default SingleStationTile
