import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import ElementStateType from '../../utils/types/states/ElementStateType'
import { UserType } from '../../utils/types'
import { getVerifiedAndNotVerifiedUsers, updateVerifiedUser } from './actions'

const initialState: ElementStateType = {
  isRejected: false,
  loading: false,
  users: [],
}

export const adminUserSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getVerifiedAndNotVerifiedUsers.fulfilled, (state, action) => {
      state.users = action.payload.map((i: UserType) => ({
        ...i,
        createdAt: format(new Date(i.createdAt), 'd/MM/yyyy') + '',
      }))
      state.loading = false
    })
    builder.addCase(getVerifiedAndNotVerifiedUsers.pending, (state) => {
      state.loading = true
    })

    builder.addCase(getVerifiedAndNotVerifiedUsers.rejected, (state) => {
      state.isRejected = true
      state.loading = false
    })
    builder.addCase(updateVerifiedUser.fulfilled, (state, action) => {
      const updatedUsers = state.users.filter((i: UserType) => +i.id !== +action.payload)
      state.users = updatedUsers
    })
  },
  initialState,
  name: 'admin',
  reducers: {
    clearUsers: (state) => {
      state.users = []
    },
  },
})

export const { clearUsers } = adminUserSlice.actions

export default adminUserSlice.reducer
