import i18n from '../../../i18n'
import { aggregationMethods } from '../enums/Aggregate'

export const newCustomField = {
  appliesOnElement: false,
  appliesOnWikiChildren: false,
  appliesOnWikiPageChildren: false,
  appliesOnWorkspaceChildren: false,
  fieldHelp: '',
  inheritanceLevel: 0,
  regexValidationRule: '',
}

export const OptionTypes = {
  OPTION: 'option',
}

export const customFieldsGideViewType = {
  0: 'customString',
  1: 'customNumber',
  2: 'customList',
  4: 'customDate',
  5: 'customLink',
  6: 'customFormula',
  7: 'customBoolean',
  8: 'customNumber',
  3: 'customLink',
}

export const customFieldAggregationOperation = [
  { id: aggregationMethods.sum, label: i18n.t('customFields:aggregation.sum'), value: aggregationMethods.sum },
  { id: aggregationMethods.count, label: i18n.t('customFields:aggregation.count'), value: aggregationMethods.count },
  {
    id: aggregationMethods.average,
    label: i18n.t('customFields:aggregation.average'),
    value: aggregationMethods.average,
  },
  {
    id: aggregationMethods.maximum,
    label: i18n.t('customFields:aggregation.maximum'),
    value: aggregationMethods.maximum,
  },
  {
    id: aggregationMethods.minimum,
    label: i18n.t('customFields:aggregation.minimum'),
    value: aggregationMethods.minimum,
  },
]
