import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { numberFieldValidationSchema } from '../forms/validationSchemas/singleFieldsValidationSchemas'
import { Input } from '../inputs'

const NumberCustomFieldEditable = (props) => {
  const { t, i18n } = useTranslation('customfields')
  const isRtl = i18n.language === 'ar'
  const { isEditable, field, cellProps, onComplete, onChange } = props
  const data = cellProps.data
  const value = data?.appElements[0]?.customFieldValues?.find((f) => +f.customFieldId === +field.id)?.value
  const [fieldValue, setFieldValue] = useState(value)

  if (!isEditable) {
    return null
  }

  return (
    <Input
      key={`${field.id}_${isRtl}`}
      autoFocus
      className="w-full h-full text-sm"
      classes={{ input: 'w-full h-full', wrapper: 'm-0 w-full h-full' }}
      defaultValue={''}
      isOnClickDisabled={!isEditable}
      name={`fieldName${field.id}`}
      type={'number'}
      validationSchema={numberFieldValidationSchema()}
      value={fieldValue}
      onBlur={(e: any) => {
        const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
        setFieldValue(e.target.value)
        onChange(newEvent)
        setTimeout(() => onComplete(newEvent), 0)
      }}
      onSubmit={(e: any) => {
        const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
        setFieldValue(e.target.value)
        onChange(newEvent)
        onComplete(newEvent)
      }}
      onChange={(e) => {
        setFieldValue(e.target.value)
        const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
        onChange(newEvent)
      }}
      onKeyDown={(e) => {
        const newEvent = { ...e, target: { ...e.target, value: fieldValue } }
        onChange(newEvent)
        setFieldValue(fieldValue)
        onComplete(newEvent)
        // setTimeout(() => onComplete(newEvent), 0)
      }}
    />
  )
}

export default NumberCustomFieldEditable
