import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { addOrUpdateFilter, useFilters } from 'features/record'
import { VeryHighPriorityIcon } from '../Icons'

const PriorityFilter: FC = ({ closeDropdown }) => {
  const allFilters = useFilters()
  const dispatch = useDispatch()
  const { t } = useTranslation('records')

  const filterRecords = useCallback(() => {
    const lastElement = allFilters[allFilters.length - 1] || [0, 0, 0]
    const ownerFilter = allFilters?.find((singleFilter) => singleFilter[0] === 'priority' && singleFilter[1] === '4')
    if (!ownerFilter) {
      dispatch(addOrUpdateFilter(['priority', '4', `${+lastElement[2] + 1}`]))
    }
    closeDropdown?.()
  }, [])

  return (
    <div className="flex justify-between w-full border-b-2 border-gray-100">
      <button className="flex items-center py-2 hover:text-primary transition-colors" onClick={filterRecords}>
        <div className="w-5 h-5 rounded-full md:w-4 md:h-4 me-2 ms-1">
          <VeryHighPriorityIcon className="text-danger" />
        </div>
        {t('records:veryImportant')}
      </button>
    </div>
  )
}

export default PriorityFilter
