import React, { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import UserAvatar from 'components/UserAvatar'
import { useUserById } from 'features/user'
import { RecordType } from 'utils/types'

const OwnerCell: FC<CellRenderObject & { data: RecordType, value: RecordType['ownerId'] }> = ({ data, value }) => {
  const user = useUserById(+value)
  const owner = data.owner
  const avatar = user?.avatar || owner?.avatar
  const name = user?.name || `${owner?.firstName} ${owner?.lastName}`

  return (
    <UserAvatar imgSrc={avatar} userId={owner?.id}>
      <span className="overflow-hidden text-sm text-ellipsis text-start leading-4">{name}</span>
    </UserAvatar>
  )
}

export default OwnerCell
