import React, { FC, useMemo } from 'react'
import classnames from 'classnames'
import { SeeMoreIcon } from 'components/Icons'

const PageButton: FC<{
  currentPage: number,
  pageCount: number,
  pageNumber: number,
  goToSpecificPage: (page: number) => void,
}> = ({ currentPage, pageCount, pageNumber, goToSpecificPage }) => {
  const isFirstOrLast = useMemo(() => pageNumber === 1 || pageNumber === pageCount, [pageNumber, pageCount])
  const isSelectedInFirstThree = useMemo(() => pageNumber <= 3 && currentPage <= 3, [pageNumber, currentPage])
  const isSelectedInLastThree = useMemo(
    () => pageNumber > pageCount - 3 && currentPage > pageCount - 3,
    [pageNumber, currentPage],
  )
  const isInSurroundingThree = useMemo(
    () => pageNumber >= +currentPage - 1 && pageNumber <= +currentPage + 1,
    [pageNumber, currentPage],
  )

  const shouldRenderButton =
    isInSurroundingThree || isSelectedInFirstThree || isSelectedInLastThree || isFirstOrLast || pageCount < 6

  return shouldRenderButton ? (
    <button
      key={pageNumber}
      className={classnames('w-10 h-10 rounded-sm', {
        'bg-tertiary-light/5 text-tertiary-light': pageNumber === currentPage,
      })}
      onClick={() => goToSpecificPage(pageNumber)}>
      {pageNumber}
    </button>
  ) : (
    <SeeMoreIcon className="w-6" />
  )
}

export default PageButton
