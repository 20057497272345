import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TypeColumn } from '@inovua/reactdatagrid-community/types'
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'

import { useRoles } from 'hooks'
import { recordAppElementDefaultAttributesNameToId } from 'utils/constant/constant/common'
import { getFieldVisibility } from 'utils/helpers/customFields'
import { AppElementAttributeType } from 'utils/types/AppElementAttributeType'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { AppElementViewAttribute } from 'utils/types/AppElementViewType'
import {
  CheckboxCell,
  DateCell,
  DateEditorCell,
  DescriptionCell,
  DescriptionEditorCell,
  HealthCell,
  IdCell,
  LabelsCell,
  LabelsEditorCell,
  OwnerCell,
  OwnerEditorCell,
  PriorityCell,
  PriorityEditorCell,
  SettingsCell,
  StatusCell,
  StatusEditorCell,
  TitleCell,
} from 'components/GridView/cells'
import { useCurrentUser, useUsersByList } from '../../features/user'
import { useCurrentListLabels, useCurrentListStatuses } from '../../features/list'
import { getHealthOptions, getPriorityOptions } from '../../utils/helpers/recordHelpers'

const useGridColumns = (options: {
  viewAttributes?: AppElementViewAttribute[],
  defaultAttributeVisibility?: AppElementAttributeVisibility,
}) => {
  const { isEditor } = useRoles()
  const { t, i18n } = useTranslation('records')
  const { viewAttributes, defaultAttributeVisibility } = options
  const user = useCurrentUser()
  const allUsers = useUsersByList().map((i) => ({ id: i.id, label: i.name }))
  const statuses = useCurrentListStatuses().map((i) => ({ id: i.id, label: i.name }))
  const priorities = getPriorityOptions().map((i) => ({ ...i, id: i.id }))
  const healthOptions = getHealthOptions().map((i) => ({ ...i, id: i.value }))
  const labelsList = useCurrentListLabels().map((i) => ({ ...i, id: +i.id, label: i.name }))

  return useMemo<TypeColumn[]>(() => {
    const columns = [
      {
        defaultWidth: 52,
        header: '#',
        headerAlign: 'center',
        name: 'id',
        render: (props) => <IdCell {...props} />,
        resizable: false,
        showColumnMenuTool: false,
        sortable: true,
        tabIndex: null,
        textAlign: 'center',
        type: 'number',
      },
      {
        defaultWidth: 48,
        editable: false,
        header: '',
        name: 'checkBox',
        render: (props) => <CheckboxCell {...props} />,
        resizable: false,
        sortable: false,
        tabIndex: null,
        textAlign: 'center',
        // visible: isEditor,
      },
      {
        className: '-editable -text',
        colspan: ({ data, columns }) => {
          if (data?.id !== Infinity) return 1
          return columns.length - 2
        },
        defaultFlex: 1,
        editable: isEditor,
        header: t('listing.name'),
        minWidth: 240,
        name: 'name',
        render: TitleCell,
        sortable: true,
      },
      {
        className: '-editable -text',
        defaultFlex: 2,
        editable: isEditor,
        editor: DescriptionEditorCell,
        header: t('listing.description'),
        minWidth: 360,
        name: 'description',
        render: DescriptionCell,
        sortable: false,
      },
      {
        className: '-editable',
        defaultWidth: 192,
        editable: isEditor,
        renderEditor: (props) => <OwnerEditorCell isGridView={true} {...props} />,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: allUsers,
          placeholder: t('records:listing:all'),
        },
        header: t('listing.collaborator'),
        name: 'ownerId',
        render: (props) => <OwnerCell {...props} />,
        sortable: true,
      },
      {
        className: '-editable',
        editable: isEditor,
        header: t('listing.startDate'),
        name: 'startDate',
        render: (props) => <DateCell i18n={i18n} user={user} {...props} />,
        renderEditor: (props) => (
          <DateEditorCell
            autoFocus={props.autoFocus}
            dateToCompare={props.cellProps.data.endDate}
            id={props.cellProps.data.id}
            isGridView={true}
            name={props.cellProps.id}
            value={props.value}
            onBlur={props.onComplete}
            onChange={props.onChange}
            {...props}
          />
        ),
        sortable: true,
      },
      {
        className: '-editable',
        editable: isEditor,
        header: t('listing.endDate'),
        name: 'endDate',
        render: (props) => <DateCell i18n={i18n} user={user} {...props} />,
        renderEditor: (props) => (
          <DateEditorCell
            autoFocus={props.autoFocus}
            dateToCompare={props.cellProps.data.startDate}
            id={props.cellProps.data.id}
            isGridView={true}
            name={props.cellProps.id}
            value={props.value}
            onBlur={props.onComplete}
            onChange={props.onChange}
            {...props}
          />
        ),
        sortable: true,
      },
      {
        className: '-editable -status',
        editable: isEditor,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: statuses,
          placeholder: t('records:listing:all'),
        },
        header: t('listing.status'),
        name: 'statusId',
        render: (props) => <StatusCell {...props} isGridView={true} />,
        renderEditor: (props) => <StatusEditorCell isGridView={true} {...props} />,
        sortable: true,
      },
      {
        className: '-editable',
        defaultLocked: false,
        defaultWidth: 120,
        editable: isEditor,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: priorities,
          placeholder: t('records:listing:all'),
        },
        header: t('listing.priority'),
        headerAlign: 'start',
        name: 'priority',
        render: PriorityCell,
        renderEditor: (props) => <PriorityEditorCell isGridView={true} {...props} />,
        sort: (one, tow) => {
          let firstItem = one
          let secondItem = tow
          if (firstItem === null) firstItem = -1
          if (secondItem === null) secondItem = -1
          return Number(firstItem) - Number(secondItem)
        },
        sortable: true,
        textAlign: 'center',
      },
      {
        className: '-editable -end-align',
        defaultWidth: 130,
        editable: isEditor,
        editorProps: {
          maxValue: 100,
          minValue: 0,
        },
        header: t('listing.completion'),
        headerAlign: 'start',
        name: 'completedIn',
        render: ({ value }) => `${+value} %`,
        sortable: true,
        textAlign: 'end',
        type: 'number',
      },
      {
        defaultWidth: 160,
        editable: false,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: healthOptions,
          placeholder: t('records:listing:all'),
        },
        header: t('listing.health'),
        name: 'health',
        render: (props) => <HealthCell {...props} />,
        sortable: true,
      },
      {
        className: '-editable -labels',
        defaultWidth: 360,
        editable: isEditor,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: labelsList,
          placeholder: t('records:listing:all'),
        },
        header: t('listing.labels'),
        name: 'labelsIds',
        render: (props) => <LabelsCell {...props} isGridView={true} />,
        renderEditor: (props) => <LabelsEditorCell isGridView={true} {...props} />,
        sortable: false,
      },
      // {
      //   className: '-editable -labels',
      //   defaultWidth: 360,
      //   editable: isEditor,
      //   enableFiltering: false,
      //   header: t('common:labels:linkedDocument'),
      //   minWidth: 350,
      //   name: `link_doc`,
      //   render: (props) => (
      //     <GridViewLinkDocument
      //       {...props}
      //       currentRecord={props.cellProps.data}
      //       isEditingAllowed={isEditor}
      //       isMulti={false}
      //     />
      //   ),
      //   renderEditor: (props) => (
      //     <GridViewLinkedDocumentEditor
      //       {...props}
      //       currentRecord={props.cellProps.data}
      //       isEditingAllowed={isEditor}
      //       isMulti={false}
      //     />
      //   ),
      //   sortable: false,
      // },
    ]

    let filteredColumns: TypeColumn[] = []

    if (defaultAttributeVisibility !== undefined && viewAttributes) {
      filteredColumns = columns.filter((col) => {
        if (col.name === 'id' || col.name === 'checkBox') {
          return true
        }
        const attributeId = recordAppElementDefaultAttributesNameToId[col.name]
        const fieldVisibility = getFieldVisibility({
          attributeId,
          attributeType: AppElementAttributeType.defaultAttribute,
          viewAttributes,
          defaultAttributeVisibility,
        })
        col.editable = fieldVisibility === AppElementAttributeVisibility.ReadOnly ? false : isEditor
        return fieldVisibility !== AppElementAttributeVisibility.Hidden
      })
    } else {
      filteredColumns = columns
    }

    return filteredColumns
  }, [t, isEditor, defaultAttributeVisibility, viewAttributes])
}

export default useGridColumns
