import { createSlice } from '@reduxjs/toolkit'

import { ListGridStateType } from 'utils/types/states'
import { fetchGridViewsByListId } from './actions'

const initialState: ListGridStateType = { gridViews: [], loading: true }

export const listGridSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchGridViewsByListId.fulfilled, (state, action) => {
      state.gridViews = action.payload
      state.loading = false
    })

    builder.addCase(fetchGridViewsByListId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchGridViewsByListId.rejected, (state) => {
      state.loading = false
      state.gridViews = []
    })
  },
  initialState,
  name: 'listGrid',
  reducers: {
    clearGridView: (state) => {
      state.gridViews = []
    },
  },
})
export const { clearGridView } = listGridSlice.actions

export default listGridSlice.reducer
