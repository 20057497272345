import { createSlice } from '@reduxjs/toolkit'

import { ActivityStateType } from 'utils/types/states'
import { fetchActivityForResource } from './actions'

const initialState: ActivityStateType = { activities: null, loading: false }

export const activitySlice = createSlice({
  extraReducers: (builder) => {
    //fetchActivityForResource
    builder.addCase(fetchActivityForResource.fulfilled, (state, action) => {
      state.activities = action.payload
      state.loading = false
    })

    builder.addCase(fetchActivityForResource.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchActivityForResource.rejected, (state) => {
      state.loading = false
    })
  },
  initialState,
  name: 'activity',
  reducers: {},
})

export default activitySlice.reducer
