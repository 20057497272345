import { useCurrentUserPermissions } from 'features/user'
import RolesEnum from 'utils/constant/enums'
import { useMemo } from 'react'

const useViewerPermission = () => {
  const role = useCurrentUserPermissions()

  return role !== undefined && role !== null ? role !== RolesEnum.Viewer && role !== RolesEnum.NoAccess : false
}

export default useViewerPermission
