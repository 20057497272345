import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import Button, { ButtonVariant } from 'components/Buttons'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import SidebarContent from './SidebarContent'

const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints

// This is sidebar wrapper. Here located logic and view of resizing/hiding sidebar. Also SidebarContent attached here
const DocumetationSidebar: FC<{ isPublic?: boolean }> = ({ isPublic = false }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [sidebarHovered, setSidebarHovered] = useState(false)
  const [active, setActive] = useState(false)
  const [sidebarWidth, setSidebarWidth] = useState(200)
  const resizeBtnRef = useRef(null)
  const sidebarRef = useRef<any>(null)
  const [hoveredResizeBtn, setHoveredResizeBtn] = useState(false)
  const { i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const ua = navigator.userAgent.toLowerCase()
  const isSafari = ua.indexOf('safari') !== -1 ? ua.indexOf('chrome') <= -1 : false

  const pointMove = useCallback(
    (e) => {
      if (active && sidebarRef && sidebarRef.current) {
        const pos = e.movementX * 1.1 //< 0 ? -5 : 5
        const mousePosWithRtlOrTouch = isRtl || supportsTouch ? -pos : pos
        const sidebarWidth = sidebarRef.current.style.width
        const indexOfPX = sidebarWidth.indexOf(sidebarWidth.slice(-2))
        const parsedWidth = parseInt(sidebarWidth.slice(0, indexOfPX))
        const width = parsedWidth + mousePosWithRtlOrTouch
        let sidebarNewWidth = width

        if (width < 100) {
          sidebarNewWidth = 24
          setCollapsed(true)
        }
        if (width > 1000) {
          setActive(false)
          sidebarNewWidth = 1000
        }

        setSidebarWidth(sidebarNewWidth)
      }
    },
    [active],
  )

  const pointDown = useCallback(
    (e) => {
      if (e.target.id === 'resize-sidebar-button') {
        setActive(true)
      }
    },
    [setActive],
  )

  const pointUp = useCallback(
    (e) => {
      setActive(false)
    },
    [setActive],
  )

  useEffect(() => {
    if (isSafari) {
      document.addEventListener('mousemove', pointMove)
    } else {
      document.addEventListener('pointermove', pointMove)
    }

    return () => {
      if (isSafari) {
        document.removeEventListener('mousemove', pointMove)
      } else {
        document.removeEventListener('pointermove', pointMove)
      }
    }
  }, [pointMove])

  useEffect(() => {
    if (isSafari) {
      document.addEventListener('mousedown', pointDown)
    } else {
      document.addEventListener('pointerdown', pointDown)
    }

    return () => {
      if (isSafari) {
        document.removeEventListener('mousedown', pointDown)
      } else {
        document.removeEventListener('pointerdown', pointDown)
      }
    }
  }, [pointDown])

  useEffect(() => {
    if (isSafari) {
      document.addEventListener('mouseup', pointUp)
    } else {
      document.addEventListener('pointerup', pointUp)
    }

    return () => {
      if (isSafari) {
        document.removeEventListener('mouseup', pointUp)
      } else {
        document.removeEventListener('pointerup', pointUp)
      }
    }
  }, [pointUp])

  useEffect(() => {
    return () => {
      setCollapsed(true)
    }
  }, [])

  useClickAway(resizeBtnRef, () => {
    setActive(false)
  })

  const onMouseOverSidebar = useCallback(
    (e: any) => {
      if (!sidebarHovered) {
        setSidebarHovered(true)
      }
    },
    [sidebarHovered, setSidebarHovered],
  )

  const onMouseLeaveSidebar = useCallback(
    (e: any) => {
      if (sidebarHovered) {
        setSidebarHovered(false)
      }
    },
    [sidebarHovered, setSidebarHovered],
  )

  const mouseOverResize = useCallback(() => {
    setHoveredResizeBtn(true)
  }, [setHoveredResizeBtn])

  const mouseLeaveResize = useCallback(() => {
    if (!active) {
      setHoveredResizeBtn(false)
    }
  }, [setHoveredResizeBtn, active])

  return (
    <div
      className={`z-10 pl-2 pr-1.5 py-1.5 bg-white h-full text-sm relative me-0.5`}
      ref={sidebarRef}
      style={{
        borderLeft: !collapsed && hoveredResizeBtn && isRtl ? '2px solid #21cada' : '2px solid transparent',
        borderRight: !collapsed && hoveredResizeBtn && !isRtl ? '2px solid #21cada' : '2px solid transparent',
        minWidth: collapsed ? 24 : sidebarWidth,
        width: collapsed ? 24 : sidebarWidth,
      }}
      onMouseLeave={onMouseLeaveSidebar}
      onMouseOver={onMouseOverSidebar}>
      <Button
        className="bg-white border shadow-md hover:bg-gray-300 ease-in duration-100"
        icon={collapsed ? (isRtl ? ChevronLeftIcon : ChevronRightIcon) : isRtl ? ChevronRightIcon : ChevronLeftIcon}
        style={{
          // backgroundColor: '#fff',
          display: sidebarHovered ? 'initial' : collapsed ? 'initial' : 'none',

          height: 26,

          left: isRtl ? -13 : 'unset',

          position: 'absolute',

          right: isRtl ? 'unset' : -13,

          top: 90,

          width: 26,
          zIndex: 999,
        }}
        variant={ButtonVariant.IconRoundedFilled}
        onClick={() => {
          if (collapsed) {
            setSidebarWidth(200)
          }
          setCollapsed(!collapsed)
        }}
      />
      {!collapsed && (
        <Button
          id="resize-sidebar-button"
          ref={resizeBtnRef}
          style={{
            borderRadius: 0,
            cursor: 'ew-resize',
            height: '100%',
            left: isRtl ? -22 : 'unset',
            position: 'absolute',
            right: isRtl ? 'unset' : -22,
            top: 0,
            width: 18,
          }}
          variant={ButtonVariant.Icon}
          onMouseLeave={mouseLeaveResize}
          onMouseOver={mouseOverResize}
        />
      )}

      {!collapsed && <SidebarContent isPublic={isPublic} />}
    </div>
  )
}

export default memo(DocumetationSidebar)
