import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SmsValidationInput from '../../components/SmsValidaionInput'

const SmsValidationPage: FC = () => {
  return (
    <div className="items-center justify-center w-full h-full">
      <SmsValidationInput />
    </div>
  )
}

export default SmsValidationPage
