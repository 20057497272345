import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'components/inputs'
import { DropdownOptionType } from 'utils/types/inputs'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'

const DropdownCustomFieldEditorCell: FC<CellEditorProps<RecordType['customDropdown']>> = ({
  autoFocus,
  onChange,
  onComplete,
  onTabNavigation,
  value,
  options,
  isEditable,
}) => {
  useGridViewNavigation(
    () => onTabNavigation(true, -1),
    () => onTabNavigation(true, 1),
  )
  const { t } = useTranslation('customFields')
  const customOptions: DropdownOptionType[] = useMemo(
    () => [...options.map((op) => ({ ...op, label: op.optionCaption, value: op.id }))],
    [options, t],
  )

  if (!isEditable) return null

  return (
    <div
      className="w-full"
      onKeyDown={(e) => {
        e.preventDefault()
      }}>
      <Dropdown
        autoFocus={autoFocus}
        className="-m-2"
        classes={{ wrapper: 'mx-2' }}
        components={{ Option: ColoredDropdownOption }}
        isClearable
        isCreatable
        isSearchable={false}
        menuPortalTarget={document.body}
        name="customField"
        openMenuOnFocus={autoFocus}
        options={customOptions}
        shouldShowPlaceholder
        value={value}
        onChange={(e) => {
          const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
          onChange(newEvent)
          setTimeout(() => onComplete(newEvent), 0)
        }}
      />
    </div>
  )
}

export default DropdownCustomFieldEditorCell
