import ListToolbar from 'components/ListToolbar'
import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'

const DocumentHeaderLoader = () => {
  return (
    <ListToolbar>
      <div className="flex items-center justify-between w-full">
        <ContentLoader
          speed={2}
          width={185}
          height={40}
          viewBox="0 0 190 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" width="185" height="36" rx="5" />
        </ContentLoader>
        <div className="flex items-center justify-end bg-white px-7 ms-0.5">
          <ContentLoader
            speed={2}
            width={40}
            height={30}
            viewBox="0 0 50 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" width="30" height="30" rx="5" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={40}
            height={30}
            viewBox="0 0 50 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" width="30" height="30" rx="5" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={40}
            height={30}
            viewBox="0 0 50 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" width="30" height="30" rx="5" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={40}
            height={30}
            viewBox="0 0 50 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb">
            <rect x="0" y="0" width="30" height="30" rx="5" />
          </ContentLoader>
        </div>
      </div>
    </ListToolbar>
  )
}

export default memo(DocumentHeaderLoader)
