import React, { FC } from 'react'
import DocumetationSidebar from 'components/Documentation/DocumentationSidebar/DocumetationSidebar'
import DocumentationPagesHeader from 'components/Documentation/DocumentationPagesComponents/DocumentationPagesHeader'
import DocumentationPagesContent from 'components/Documentation/DocumentationsHome/DocumentationPagesContent'

const StationDocumentationHomePage: FC<{ isPublic?: boolean }> = ({ isPublic = false }) => {
  return (
    <div className="flex h-full min-h-0">
      <DocumetationSidebar isPublic={isPublic} />
      <div className="w-full">
        <DocumentationPagesHeader isPublic={isPublic} />
        <DocumentationPagesContent isPublic={isPublic} />
      </div>
    </div>
  )
}

export default StationDocumentationHomePage
