import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'
import CustomScroll from '../../../../CustomScroll'
import WidgetImage from '../../../WidgetImage'
import DashboardWidgetType from '../../../../../utils/types/DashboardWidgetType'
import TimeLineComponent from '../../../../TimeLineComponent/TimeLineComponent'
import { getCalendarEvents } from '../../../../../utils/calendarUtils'
import { useCurrentUser } from '../../../../../features/user'
import {
  healthResourceRender,
  priorityResourceRender,
  statusResourceRender,
  userResourceRender,
} from '../../../../../utils/helpers/timeLineHelpers'
import { getHealthOptions, getPriorityOptions } from '../../../../../utils/helpers/recordHelpers'
import {
  basicOptions,
  customWidgetDefaultOptions,
  parametersValues,
} from '../../../../../utils/constant/constant/common'
import { useAppDispatch } from '../../../../../store'
import { getWidgetData, useWidgetById } from '../../../../../features/element'
import { fetchRecordById } from '../../../../../features/record'
import { updateCurrentListDetails } from '../../../../../features/list/listSlice'
import userType from '../../../../../utils/types/UserType'
import { getUserPermissionFromAccessGroup } from '../../../../../utils/helpers/generalHelpers'
import { AddRecordToListForm } from '../../../../forms'
import { refetchAfterUpdate } from '../../../../../features/element/elementSlice'
import { usePanel } from '../../../../../contexts/PanelContext'

const TimeLineWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEditMode: boolean,
  elementId: string,
  setIsEditorOpen: () => void,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
  updateTextWidget: (widgetId: number, description: string, imgBase64?: string) => void,
  getWidgets: (widgetId: number) => void,
  refetchFunction?: () => void,
  isPrinting: boolean,
}> = ({
  widgetInfo,
  isEditMode,
  updateTextWidget,
  setIsEditorOpen,
  onUpdateWidgetImage,
  elementId,
  getWidgets,
  isPrinting,
  refetchFunction,
}) => {
  const { t } = useTranslation('dashboard')
  const dispatch = useAppDispatch()
  const timelineRef = useRef<HTMLDivElement>(null)
  const widget = useWidgetById(widgetInfo.id)
  const widgetId = widgetInfo.id || widgetInfo.tempId
  const user = useCurrentUser()
  const location = useLocation()
  const search = location.search
  const isHijri = user.isHijri
  const { setPanelContent, openPanel } = usePanel()
  const [isTaskListVisible, setIsTaskListVisible] = useState<boolean>(false)
  const [selectedRecordId, setSelectedRecordId] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const isMobile = window.innerWidth <= 760
  const { parameters, data } = widget || widgetInfo
  const dropdownOptions = [...basicOptions, { label: t('records:listing.creator'), value: 'creator' }]
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    const recordInfo = location?.search?.split('Id=')
    if (recordInfo.length > 1) {
      setSelectedRecordId(recordInfo[1])
    }
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  const records = data?.values || []
  const users = data?.owners.map((u, idx) => ({ ...u.user }))
  const statusList = data?.listStatuses?.map((i) => ({
    ...i,
    label: i?.name,
    order: i?.position,
    value: i?.id?.toString(),
  }))

  const dropdown = parameters ? parameters?.find(({ parameterId }) => parameterId === 10001)?.value : 2

  const dropdownValue = parametersValues[customWidgetDefaultOptions?.find((item) => +item.id === +dropdown)?.key]

  const calendarEvents = useMemo(() => {
    const resource = dropdownValue === 'creator' ? 'reporterId' : dropdownValue
    return getCalendarEvents(records, true, isHijri, resource, false, false)
  }, [records, statusList, users, widget])

  const groupByResources = {
    creator: { content: userResourceRender, list: users },
    health: { content: healthResourceRender, list: getHealthOptions() },
    ownerId: { content: userResourceRender, list: users },
    priority: { content: priorityResourceRender, list: getPriorityOptions(false) },
    statusId: { content: statusResourceRender, list: statusList },
  }

  useEffect(() => {
    if (selectedRecordId) {
      dispatch(fetchRecordById({ details: true, id: selectedRecordId })).then((res) => {
        if (res.payload) {
          dispatch(updateCurrentListDetails(res.payload.list))
          const currentRecord = res.payload
          const currentUser = res.payload.list.listMembers.find(
            (userData: userType) => Number(userData.userId) === Number(user.id),
          )
          const isEditorUser = getUserPermissionFromAccessGroup(currentUser.accessGroupId, 'Editor')
          const isAdminUser = getUserPermissionFromAccessGroup(currentUser.accessGroupId, 'Admin')
          setPanelContent({
            content: (
              <AddRecordToListForm
                isAdmin={isAdminUser}
                isDetailedRecord={true}
                isEditor={isEditorUser}
                listId={+currentRecord?.listId}
                partialRecord={currentRecord}
                recordId={+currentRecord.id}
              />
            ),
            header: currentRecord.name,
            isBig: true,
            isRecordEditable:
              getUserPermissionFromAccessGroup(currentUser.accessGroupId) && !currentRecord?.appElements[0]?.isArchived,
            partialRecord: currentRecord,
          })
          openPanel()
          if (refetchFunction) {
            dispatch(refetchAfterUpdate(refetchFunction))
          } else {
            dispatch(refetchAfterUpdate(() => getWidgetData(+widgetInfo.id)))
          }
          setSelectedRecordId(null)
        }
      })
    }
  }, [selectedRecordId, location?.search?.includes('?recordId='), search])

  useEffect(() => {
    const urlRecordId = search.split('=')
    if (urlRecordId[1] && Number(selectedRecordId) !== Number(urlRecordId[1])) {
      setSelectedRecordId(+urlRecordId[1])
    }
  }, [search])

  const onRightClickItem = (props) => {
    return null
  }

  const handleEventClick = ({
    event: {
      _def: { publicId },
    },
  }: {
    event: { _def: { publicId: string } },
  }) => {
    setSelectedRecordId(publicId)
  }

  const handleEventDrop = () => {
    return null
  }

  const handleEventDropFromOutside = () => {
    return null
  }

  const handleEventResize = () => {
    return null
  }

  const onCreateRecord = () => {
    return null
  }

  return (
    <>
      {sized}
      <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <div className="mt-14">
            <WidgetImage isEdit={isEditMode} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          </div>
        )}
        <div className="flex-1 m-4 overflow-auto" id={`timeline-widget-${widgetId}`}>
          <TimeLineComponent
            calendarEvents={calendarEvents}
            customFields={[]}
            dropdownValue={dropdownValue}
            eventDurationEditable={false}
            eventResourceEditable={false}
            eventStartEditable={false}
            groupByResources={groupByResources}
            handleEventClick={handleEventClick}
            handleEventDrop={handleEventDrop}
            handleEventDropFromOutside={handleEventDropFromOutside}
            handleEventResize={handleEventResize}
            isDisabled={true}
            isLoading={isDataLoading}
            isTaskListVisible={isTaskListVisible}
            linkFields={[]}
            options={dropdownOptions}
            setDropdownValue={() => console.log('change')}
            setIsTaskListVisible={setIsTaskListVisible} // need to send current list custom fields
            timelineClassName={`mt-10 ${isMobile && 'mt-20'}`}
            timelineRef={timelineRef}
            toolBarClassName={`absolute top-3 -ms-8 ${isMobile && 'ms-0'}`}
            toolBarId={`timeline-widget-${widgetId}`}
            widgetId={widgetId}
            onCreateRecord={onCreateRecord}
            onRightClickItem={onRightClickItem}
          />
        </div>
      </CustomScroll>
    </>
  )
}

export default memo(TimeLineWidget)
