import { forwardRef, useImperativeHandle, useRef } from 'react'
import classnames from 'classnames'

import { ErrorMessageRefType, InputRefType, InputPropsType, ErrorMessageType } from 'utils/types/inputs'

import InputLabel from 'components/inputs/InputLabel'
import { inputClassNames } from 'components/inputs/inputClassNames'
import InputErrorMessage from 'components/inputs/InputErrorMessage'
import { useEventListener } from 'hooks'

const Input = forwardRef<InputRefType, InputPropsType & ErrorMessageType>(
  (
    { customHeight, name, label, error, touched, classes, required, isInlineEditable, handleClickOutside, ...rest },
    ref,
  ) => {
    const errorRef = useRef<ErrorMessageRefType>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        blur: () => inputRef?.current?.blur(),
        contains: (target: Node) => inputRef.current?.contains(target),
        focus: () => inputRef?.current?.focus(),
        shakeError: () => errorRef?.current?.shakeError(),
      }),
      [errorRef.current, inputRef.current],
    )

    useEventListener('mousedown', (event) => handleClickOutside?.(event))

    return (
      <div className={classnames(classes.wrapper || 'mb-6')}>
        <InputLabel
          className={classnames('block text-sm font-bold', classes.label)}
          label={label}
          name={name}
          required={required}
        />
        <input
          className={classnames(inputClassNames(customHeight || ''), classes.input)}
          id={`input-${name}`}
          name={name}
          placeholder={label}
          ref={inputRef}
          required={required}
          style={rest.style}
          {...rest}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              inputRef.current?.blur()
            }
          }}
        />
        <InputErrorMessage
          data-testId={`${rest['data-testid'] || name}-error`}
          error={error}
          isInlineEditable={isInlineEditable}
          ref={errorRef}
          touched={touched}
        />
      </div>
    )
  },
)

Input.displayName = 'Input'
Input.defaultProps = {
  classes: {},
}

export default Input
