import React, { FC } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { configValues } from 'utils/appConfig'

interface GeneralGridTableTypes {
  columns: any[];
  dataSource: any[];
  defaultFilterValue: any[];
  handle?: () => void;
}

const GeneralGridTable: FC<GeneralGridTableTypes> = ({ columns, dataSource, defaultFilterValue, handle }) => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const pagePathName = pathname.split('/')
  const gridStyle = { maxHeight: '90vh', minHeight: pagePathName.length > 3 ? '60vh' : '69vh', textAlign: 'center' }
  const isRtl = i18n.language === 'ar'

  const tableProps = {
    activateRowOnFocus: false,
    borders: { color: '#000' },
    columns,
    dataSource,
    defaultFilterValue,
    defaultLimit: pagePathName.length > 3 ? 10 : 20,
    enableKeyboardNavigation: false,
    enableSelection: false,
    handle,
    idProperty: 'id',
    licenseKey: configValues.REACT_APP_GRID_LICENSE_KEY,
    pagination: true,
    rtl: isRtl,
    showColumnMenuTool: true,
    showZebraRows: false,
    sortable: true,
    style: gridStyle,
    theme: 'default-light',
    toggleRowSelectOnClick: false,
  }

  return (
    <div dir={'auto'}>
      <ReactDataGrid {...tableProps} />
    </div>
  )
}
export default GeneralGridTable
