import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { setColorByStatus } from 'utils/types/widgetUtils'
import { getDateFormat, getFormattedCompletion } from 'utils/helpers/generalHelpers'
import { useCurrentUser } from '../../../../features/user'
import StationType from '../../../../utils/types/StationType'

const ItemWithColoredDiv: FC<{
  item: any,
  customWidth: string | null,
  itemKey: string,
  text: string,
}> = ({ item, customWidth, itemKey, text }) => {
  const color = setColorByStatus(item[itemKey], false).color
  return (
    <div className="flex items-center justify-between text-center">
      <div
        className="border-2 rounded my-1"
        style={{
          backgroundColor: color,
          borderColor: color,
          width: customWidth ? customWidth : '4.5rem',
        }}
        title={text}
      />
    </div>
  )
}

const SmallStationCard: FC<{ station: StationType }> = ({ station }) => {
  const { t, i18n } = useTranslation(['dashboard', 'station'])
  const user = useCurrentUser()
  const isHijri = user?.isHijri !== null ? user?.isHijri : false
  const isRtl = i18n.language === 'ar'
  return (
    <div key={station.id} className="p-1 bg-gray-100 border border-2 rounded" style={{ width: '100%' }}>
      <a
        className="flex justify-start text-sm font-semibold truncate cursor-pointer hover:text-primary"
        href={`/workspace/${station?.workspaceId}/stations/${station?.id}/dashboard`}
        title={station.name}>
        {station.name}
      </a>
      <div>
        <div className="flex items-center justify-between text-xs my-0.5">
          <div>{station.startDate ? getDateFormat(isRtl, station.startDate, isHijri) : t('dashboard:noStartDate')}</div>
          {' - '}
          <div>{station.endDate ? getDateFormat(isRtl, station.endDate, isHijri) : t('dashboard:noEndDate')}</div>
        </div>
        <div className="flex items-center justify-between text-xs my-0.5">
          <div className="flex">
            <div>{t('lateTasks')}:</div>
            <div className="font-bold text-danger ms-1">
              <p>{station.numberOfLateTasksInStation || 0}</p>
            </div>
          </div>
          <div className="flex font-semibold justify-end">{getFormattedCompletion(station.avg, 0)}%</div>
        </div>
        <div className="flex items-center justify-between">
          <ItemWithColoredDiv
            customWidth={'2.2rem'}
            item={station}
            itemKey="status"
            text={t('dashboard:groupByOptions.status')}
          />
          <ItemWithColoredDiv
            customWidth={'2.2rem'}
            item={station}
            itemKey="budgetHealth"
            text={t('dashboard:groupByOptions.budgetHealth')}
          />
          <ItemWithColoredDiv
            customWidth={'2.2rem'}
            item={station}
            itemKey="qualityHealth"
            text={t('dashboard:groupByOptions.qualityHealth')}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(SmallStationCard)
