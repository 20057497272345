import React, { FC } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '../../Icons'

type CollapseIconProps = {
  currentId: string,
  collapsedItems: string[],
  setCollapsedItems: (value: any) => void,
  onCollapse?: () => void,
  isCollapsed: boolean,
}

const CollapseIcon: FC<CollapseIconProps> = ({
  currentId,
  setCollapsedItems,
  isCollapsed,
  collapsedItems,
  onCollapse,
}) => {
  const generateArrowClassName = (isActive: boolean) => {
    return `w-5 hover:text-primary cursor-pointer ${isActive ? 'text-primary' : 'text-gray-600'}`
  }

  const toggleCollapse = (itemId: string) => {
    let newList = [...collapsedItems]
    const itemIndex = newList.findIndex((id) => id === itemId)
    if (itemIndex > -1) {
      newList = newList.filter((id) => id !== itemId)
    } else {
      newList.push(itemId)
    }

    setCollapsedItems([...newList])
  }
  return (
    <button
      className={`bg-blue-500 hover:bg-blue-700 text-primary font-bold py-2 px-2 rounded justify-end`}
      onClick={() => (onCollapse ? onCollapse() : toggleCollapse(currentId))}>
      {!isCollapsed ? (
        <ChevronDownIcon className={generateArrowClassName(!isCollapsed)} />
      ) : (
        <ChevronUpIcon className={generateArrowClassName(false)} />
      )}
    </button>
  )
}
export default CollapseIcon
