import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader'
import { Input } from '../inputs'
import Button, { ButtonVariant } from '../Buttons'
import { useAppDispatch } from '../../store'
import BaseModal from './BaseModal/BaseModal'

const CreateDashboardModal: FC<{
  isModalOpen: boolean,
  appElementId: number,
  scope: string,
  onCancel: () => void,
  onCreateDashboard: (value: any) => void,
}> = ({ onCreateDashboard, onCancel, appElementId, scope, isModalOpen }) => {
  const { t } = useTranslation('dashboard')
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onClose = () => {
    onCancel()
    setTitle('')
  }
  const onCreateNewDashboard = () => {
    if (title.trim().length > 0) {
      setIsLoading(true)
      const dashboard = {
        appElementId,
        scope,
        title,
      }
      dispatch(onCreateDashboard(dashboard)).then(() => {
        setIsLoading(false)
        onCancel()
      })
    }
  }

  const renderContent = () => {
    return (
      <div className="flex flex-col flex-1 min-h-0 p-1 rounded">
        <div className="flex flex-col flex-1">
          <Input
            autoFocus
            classes={{ wrapper: 'mb-6 mt-3 px-1 text-sm' }}
            name="name"
            placeholder={t('addNewDashboardTitle')}
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </div>
        <div className="flex justify-end pt-3 border-t border-gray-200">
          <Button className="me-4" variant={ButtonVariant.Ghost} onClick={onClose}>
            {t('common:labels.cancel')}
          </Button>
          <Button disabled={isLoading} onClick={onCreateNewDashboard}>
            {isLoading ? <Loader /> : t('common:labels:save')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <BaseModal
      close={onCancel}
      content={renderContent()}
      header={<h1 className="px-4 text-gray-800">{t('createNewDashboard')}</h1>}
      isModalOpen={isModalOpen}
      shouldCloseOnOverlayClick={false}
      type="md"
    />
  )
}

export default CreateDashboardModal
