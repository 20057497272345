import { AttachmentType, NewAttachmentType } from 'utils/types'
import { last, round } from 'lodash'

export const getAttachmentProperties: (attachment: NewAttachmentType | AttachmentType) => any = (
  attachment: NewAttachmentType | AttachmentType,
) => {
  const fileName = attachment.name || attachment.attachmentName || ''
  const fileSize = 'size' in attachment ? attachment.size : attachment.fileSize
  const fileType = 'type' in attachment ? attachment.type : attachment.fileType || attachment.fileMimeType
  const fileExtension = last(fileName.split('.')) || attachment.fileExtension
  const fileSizeInKb = round(+fileSize / 1024)
  const fileSizeInMb = fileSizeInKb > 1024 ? round(+fileSizeInKb / 1024) : undefined
  const remoteName = 'remoteName' in attachment ? attachment.remoteName : null
  return { ...attachment, fileExtension, fileName, fileSize, fileSizeInKb, fileSizeInMb, fileType }
}
