export enum ChangeRequestStatus {
  PENDING,
  REVIEW_STARTED,
  APPROVED,
  REJECTED,
}

export enum CommentResponseType {
  COMMENT,
  CHANGE_REQUEST,
}
