import React from 'react'
import { SimpleChangeObject } from 'utils/types/ChangeTypes'
import { isValid } from 'date-fns'
import { RecordType, UserType } from '../types'
import UsernameWithAvatar from '../../components/UsernameWithAvatar'
import PriorityItem from '../../components/inputs/PriorityDropdown/PriorityItem'
import { healthColors } from '../../components/GridView/cells/displayCells/HealthCell'
import { isNumeric } from './generalHelpers'

export const userResourceRender = ({
  resource: {
    _resource: { extendedProps: userProps },
  },
}: {
  resource: {
    _resource: {
      extendedProps: UserType,
    },
  },
}) => <UsernameWithAvatar classes={{ label: 'hidden lg:block' }} hideUserStatus={true} user={userProps} />

export const priorityResourceRender = ({
  resource: {
    _resource: { extendedProps: priorityProps },
  },
}: {
  resource: {
    _resource: {
      extendedProps: { value: string, label: string },
    },
  },
}) => (
  <PriorityItem
    isDisabled={false}
    value={priorityProps?.value === null ? null : +priorityProps?.value}
    withNames={true}
  />
)

export const healthResourceRender = ({
  resource: {
    _resource: { extendedProps: healthProps },
  },
}: {
  resource: {
    _resource: {
      extendedProps: { value: string, label: string, color: string },
    },
  },
}) => {
  return (
    <div className="flex items-center justify-center px-1 -m-3 py-2.5 min-h-12">
      <div className="flex items-center justify-start w-full h-8 mx-2 rounded">
        <div className="w-4 h-4 rounded" style={{ backgroundColor: healthColors[healthProps?.value] }} />
        <p className={`text-sm text-gray-700 ms-2`}>{healthProps.label}</p>
      </div>
    </div>
  )
}

export const statusResourceRender = ({
  resource: {
    _resource: { extendedProps: statusProps },
  },
}: {
  resource: {
    _resource: {
      extendedProps: { value: string, label: string, color: string },
    },
  },
}) => {
  return (
    <div className="flex items-center justify-center px-1 -m-3 py-2.5 min-h-12">
      <div className="flex items-center justify-start w-full h-8 mx-2 rounded">
        <div className="w-4 h-4 rounded" style={{ backgroundColor: statusProps.color }} />
        <p className="text-sm text-gray-700 ms-2">{statusProps.label}</p>
      </div>
    </div>
  )
}
export const documentsResourceRender = ({
  resource: {
    _resource: { extendedProps: statusProps },
  },
}: {
  resource: {
    _resource: {
      extendedProps: { value: string, label: string, color: string },
    },
  },
}) => {
  return (
    <div className="flex items-center justify-center px-1 -m-3 py-2.5 min-h-12">
      <div className="flex items-center justify-start w-full h-8 mx-2 rounded">
        <p className="text-sm text-gray-700 ms-2">{statusProps.label}</p>
      </div>
    </div>
  )
}

export const getTimelineOrderByProperty = (fieldName: string) => {
  if (isNumeric(fieldName)) {
    return 'optionOrder'
  } else if (fieldName === 'ownerId') {
    return 'name'
  } else {
    return 'order'
  }
}

export const calendarTaskColors = (isSubtask: boolean): string => {
  if (isSubtask) {
    return 'bg-secondary-dark hover:bg-secondary'
  } else {
    return 'bg-tertiary-light hover:bg-tertiary'
  }
}

export const formatDateFromGMTToUTC = (date: string) => {
  return new Date(date).toISOString()
}

export const getChangeObject = (options: {
  changes: SimpleChangeObject,
  oldValues: SimpleChangeObject,
}): SimpleChangeObject => {
  const { changes, oldValues } = options
  const changedKeys = Object.keys(changes)
  const newChangesObject: SimpleChangeObject = {}
  changedKeys.map((key) => {
    let formattedData = changes[key]
    let oldValue = oldValues[key]
    if (formattedData !== undefined && formattedData !== null && isValid(formattedData)) {
      formattedData = formatDateFromGMTToUTC(changes[key])
    }
    if (oldValue !== undefined && oldValue !== null && isValid(oldValue)) {
      oldValue = formatDateFromGMTToUTC(oldValues[key])
    }

    if (typeof oldValue === 'number' && formattedData !== null && formattedData !== undefined) {
      formattedData = Number(formattedData)
    }

    if ((formattedData === null && oldValue === undefined) || (formattedData === undefined && oldValue === null))
      return {}

    const isChanged = formattedData !== undefined && formattedData !== oldValue

    if (isChanged) {
      newChangesObject[key] = changes[key]
    }
  })
  return newChangesObject
}

export const generateChangesObject = (record: RecordType, changesElements: any) => {
  const changes = {}
  const oldStartDate = formatDateFromGMTToUTC(record?.startDate)
  const newStartDate = formatDateFromGMTToUTC(changesElements.startDate)
  const oldEndDate = formatDateFromGMTToUTC(record?.endDate)
  const newEndDate = formatDateFromGMTToUTC(changesElements.endDate)
  if (newStartDate !== oldStartDate) {
    changes.startDate = newStartDate
  }
  if (newEndDate !== oldEndDate) {
    changes.endDate = newEndDate
  }
  return changes
}
