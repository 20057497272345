import { CalendarCardIcon, ClockWiseIcon } from 'components/Icons'
import ListToolbar from 'components/ListToolbar'
import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'

const DocumentViewLoader = () => {
  return (
    <div className="flex overflow-y-auto bg-white mt-0.5" style={{ height: 'calc(100% - 62px)' }}>
      <div className="relative w-full px-24 pt-16">
        <div className="flex items-center justify-between">
          <h1 className="my-6 text-gray-800">
            <ContentLoader
              speed={2}
              width={185}
              height={40}
              viewBox="0 0 190 40"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb">
              <rect x="0" y="0" width="185" height="36" rx="5" />
            </ContentLoader>
          </h1>
        </div>

        <div className="flex items-center mb-6 text-sm gap-6">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6">
              <ContentLoader
                speed={2}
                width={30}
                height={30}
                viewBox="0 0 30 30"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <circle cx="15" cy="15" r="12" />
              </ContentLoader>
            </div>
            <p>
              <ContentLoader
                speed={2}
                width={185}
                height={40}
                viewBox="0 0 190 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <rect x="0" y="0" width="185" height="36" rx="5" />
              </ContentLoader>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <CalendarCardIcon width={20} />
            <p>
              <ContentLoader
                speed={2}
                width={185}
                height={40}
                viewBox="0 0 190 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <rect x="0" y="0" width="185" height="36" rx="5" />
              </ContentLoader>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <ClockWiseIcon width={20} />
            <p>
              <ContentLoader
                speed={2}
                width={185}
                height={40}
                viewBox="0 0 190 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <rect x="0" y="0" width="185" height="36" rx="5" />
              </ContentLoader>
            </p>
          </div>
        </div>

        <ContentLoader
          speed={2}
          width={300}
          height={40}
          viewBox="0 0 300 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" width="300" height="36" rx="5" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={180}
          height={40}
          viewBox="0 0 180 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" width="180" height="36" rx="5" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={215}
          height={40}
          viewBox="0 0 215 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" width="215" height="36" rx="5" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={150}
          height={40}
          viewBox="0 0 150 40"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" width="150" height="36" rx="5" />
        </ContentLoader>
      </div>
    </div>
  )
}

export default memo(DocumentViewLoader)
