import React, { FC } from 'react'
import Modal from 'react-modal'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { useCurrentStation } from 'features/station'
import { useCurrentWorkspace } from 'features/workspace'
import CustomFieldForm from './CustomFieldForm'

interface CustomFieldPopoverProps {
  isModalOpen: boolean;
  isInRecord: boolean;
  isFromAdmin: boolean;
  onCancel: () => void;
  elementBaseType?: AppElementBaseTypes;
  currentItem: any;
  onRefetch: () => void;
}
const CustomFieldPopover: FC<CustomFieldPopoverProps> = ({
  isModalOpen,
  onCancel,
  elementBaseType = AppElementBaseTypes.ListAppElement,
  currentItem,
  onRefetch,
  isInRecord,
  isFromAdmin,
}) => {
  const currentWorkspace = useCurrentWorkspace()
  const currentStation = useCurrentStation()

  const onClose = (e?: any) => {
    if (e) {
      e?.stopPropagation()
    }
    onCancel()
  }

  return (
    <Modal
      className="fixed top-0 bottom-0 z-50 flex flex-col w-full max-w-lg bg-white rounded shadow-lg focus:outline-none lg:top-36 lg:bottom-36 left-1/2 transform -translate-x-1/2"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/30 inset-0 fixed z-50"
      onRequestClose={onCancel}>
      <CustomFieldForm
        currentItem={currentItem}
        elementBaseType={elementBaseType}
        isFromAdmin={isFromAdmin}
        isInRecord={isInRecord}
        isTippyInit={true}
        stationId={currentStation?.appElement?.id}
        workspaceId={currentWorkspace?.appElement?.id}
        onClose={onClose}
        onRefetch={onRefetch}
      />
    </Modal>
  )
}

export default CustomFieldPopover
