import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { clearChosenPlan } from '../../features/workspace/workspaceSlice'

type BackItemProps = {
  isTheSameUrl?: boolean,
}
const BackItem: FC<BackItemProps> = ({ isTheSameUrl = false }) => {
  const { t, i18n } = useTranslation('workspace')
  const dispatch = useDispatch()
  const history = useHistory()
  const isRtl = i18n.language === 'ar'

  const onBack = () => {
    if (isTheSameUrl) {
      dispatch(clearChosenPlan())
    } else {
      history.goBack()
    }
  }

  return (
    <div className="flex justify-start mb-5 cursor-pointer" onClick={onBack}>
      {isRtl ? <ChevronRightIcon className="w-10 -mt-2" /> : <ChevronLeftIcon className="w-10 -mt-2" />}
      <h2>{t('subscription:back')}</h2>
    </div>
  )
}

export default BackItem
