import { FC } from 'react'
import classnames from 'classnames'
import { getPriorityIconByValue } from 'utils/helpers/recordHelpers'

const PriorityItem: FC<{
  isDisabled?: boolean,
  withNames?: boolean,
  showInput: () => void,
  value: number | null,
}> = ({ isDisabled, withNames, value, showInput }) => {
  const { Icon, color, label } = getPriorityIconByValue(value)
  return (
    <div
      className={classnames({
        'flex items-center leading-6 px-2 py-2.5 -m-2 min-h-12 rounded': !isDisabled,
        'justify-center': !withNames,
      })}
      onClick={showInput}>
      <Icon className="w-4 h-4 my-0" style={{ color }} />
      {withNames && <span className="flex items-center text-sm ms-1">{label}</span>}
    </div>
  )
}

export default PriorityItem
