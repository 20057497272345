import { createSlice } from '@reduxjs/toolkit'

import { UserStateType } from 'utils/types/states'
import {
  fetchUser,
  fetchUsersByListId,
  fetchUsersByStationId,
  fetchUsersByWorkspaceId,
  updateUser,
  uploadAvatar,
} from './actions'
import { updateStateUser } from './helpers'

const initialState: UserStateType = {
  loading: true,
  loadingCurrentUser: true,
  user: {},
  usersByList: [],
  usersByListWithRoles: [],
  usersByStation: [],
  usersByWorkspace: [],
}

export const userSlice = createSlice({
  extraReducers: (builder) => {
    // fetchUser
    builder.addCase(fetchUser.fulfilled, updateStateUser)

    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true
      state.loadingCurrentUser = true
    })

    builder.addCase(fetchUser.rejected, (state) => {
      state.loading = false
      state.loadingCurrentUser = false
    })

    // fetchUserByList
    builder.addCase(fetchUsersByListId.fulfilled, (state, action) => {
      state.usersByList = action.payload
      state.loading = false
    })

    builder.addCase(fetchUsersByListId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchUsersByListId.rejected, (state) => {
      state.loading = false
    })

    // fetchUsersByWorkspaceId
    builder.addCase(fetchUsersByWorkspaceId.fulfilled, (state, action) => {
      state.usersByWorkspace = action.payload
      state.loading = false
    })

    builder.addCase(fetchUsersByWorkspaceId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchUsersByWorkspaceId.rejected, (state) => {
      state.loading = false
    })

    // fetchUsersByStationId
    builder.addCase(fetchUsersByStationId.fulfilled, (state, action) => {
      state.usersByStation = action.payload
      state.loading = false
    })

    builder.addCase(fetchUsersByStationId.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchUsersByStationId.rejected, (state) => {
      state.loading = false
    })

    // uploadAvatar
    builder.addCase(uploadAvatar.fulfilled, updateStateUser)

    builder.addCase(uploadAvatar.pending, (state) => {
      state.loading = true
    })

    builder.addCase(uploadAvatar.rejected, (state) => {
      state.loading = false
    })

    // updateUser
    builder.addCase(updateUser.fulfilled, updateStateUser)

    builder.addCase(updateUser.pending, (state) => {
      state.loading = true
    })

    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false
    })
  },
  initialState,
  name: 'user',
  reducers: {},
})

export default userSlice.reducer
