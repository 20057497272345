import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../Loader'
import { useAppDispatch } from '../../../store'
import {
  fetchElementMakerCheckerPolicyRequests,
  useElementMakerCheckerPolicyRequests,
  useElementMakerCheckerPolicyRequestsCF,
} from '../../../features/element'
import RequestType from '../../../utils/types/RequestType'
import { useCurrentUser } from '../../../features/user'
import { ListType, RecordType, StationType } from '../../../utils/types'
import RequestRow from './RequestRaw'

interface RequestsListProps {
  elementId: number | string;
  needForApproval?: boolean;
  showLatest?: boolean;
  currentItem: RecordType | ListType | StationType;
}

const RequestsList: FC<RequestsListProps> = ({ elementId, currentItem, needForApproval, showLatest }) => {
  const requestsList = useElementMakerCheckerPolicyRequests()
  const customFields = useElementMakerCheckerPolicyRequestsCF()
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const dispatch = useAppDispatch()
  const currentUser = useCurrentUser()
  const { t } = useTranslation()

  useEffect(() => {
    if (elementId) {
      setIsLoading(true)
      dispatch(fetchElementMakerCheckerPolicyRequests(elementId)).finally(() => setIsLoading(false))
    }
  }, [elementId])

  useEffect(() => {
    if (requestsList?.length > 0) {
      const sortedList = [...requestsList]
      if (showLatest) {
        sortedList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      } else {
        sortedList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      }
      setList(sortedList)
    }
  }, [showLatest, requestsList])

  if (isLoading) {
    return <Loader loaderClasses="w-full" svgClasses="w-8 m-auto" />
  }

  if (requestsList?.length === 0) {
    return <div className="flex justify-center mt-10 opacity-50">{t('records:noRequestsAddedYet')}</div>
  }

  return (
    <>
      {list.map((request: RequestType) => (
        <RequestRow
          key={request.id}
          currentItem={currentItem}
          customFields={customFields}
          elementId={elementId}
          isHijri={currentUser.isHijri}
          needForApproval={needForApproval}
          request={request}
        />
      ))}
    </>
  )
}

export default RequestsList
