import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getVerifiedAndNotVerifiedUsers } from '../../../../features/adminUser'
import UsersTable from './UsersTable'

const UsersPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dropdownOptions = [
    { label: t('admin:user:verified'), value: 1, buttonLabel: 'unVerify' },
    { label: t('admin:user:unVerified'), value: 0, buttonLabel: 'verify' },
  ]
  const [status, setStatus] = useState(dropdownOptions[0])
  const [loadingStatus, setLoadingStatus] = useState(false)

  const onChangeStatus = (e: any) => {
    setLoadingStatus(true)
    const selectedVal = dropdownOptions.find((i) => +i.value === +e.target.value)
    setStatus(selectedVal)
  }

  useEffect(() => {
    dispatch(getVerifiedAndNotVerifiedUsers(Boolean(status.value)))
  }, [status])

  const tableProps = {
    dropdownOptions,
    onChange: onChangeStatus,
    status,
    loadingStatus,
    setLoadingStatus,
  }

  return (
    <div className="flex flex-col min-h-screen">
      <UsersTable {...tableProps} />
    </div>
  )
}

export default UsersPage
