import { FC, memo } from 'react'

const DetailedRecordsHeader: FC<{ row: { header: string, width: string, minWidth: string } }> = ({ row }) => {
  return (
    <div
      className={`text-center  pt-3 pb-3`}
      style={{ backgroundColor: '#F1F5FF', minWidth: row.minWidth, width: row.width }}>
      {row.header}
    </div>
  )
}

export default memo(DetailedRecordsHeader)
