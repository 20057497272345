import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { RECORDS_PER_PAGE } from '../../utils/helpers/recordHelpers'
import { fetchRecordsByParams } from '../../features/record'

import Button, { ButtonVariant } from '../Buttons'
import { AddIcon } from '../Icons'
import { fetchListById, useCurrentList } from '../../features/list'
import { useAppDispatch } from '../../store'
import CustomFieldPopover from './CustomFieldPopover'

const CreateCustomFieldButton: FC<{
  showIcon?: boolean,
  showText?: boolean,
  isGridView?: boolean,
  isFromAdmin?: boolean,
  elementBaseType: AppElementBaseTypes,
  currentItem: any,
  isInRecord?: boolean,
  onRefetch?: () => void,
}> = ({
  showIcon = false,
  showText = true,
  isGridView = false,
  elementBaseType,
  currentItem,
  onRefetch,
  isInRecord = false,
  isFromAdmin,
}) => {
  const { t } = useTranslation('customFields')
  const appDispatch = useAppDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentList = useCurrentList()

  const customOnRefetch = () => {
    if (!onRefetch && elementBaseType === AppElementBaseTypes.ListAppElement) {
      return isGridView
        ? appDispatch(
            fetchRecordsByParams({
              archiveStatus: 0,
              limit: RECORDS_PER_PAGE,
              listId: +currentList?.id,
            }),
          )
        : appDispatch(fetchListById(+currentList?.id))
    } else return onRefetch()
  }
  const item = isFromAdmin
    ? null
    : elementBaseType === AppElementBaseTypes.ListAppElement && currentItem === undefined
    ? currentList
    : currentItem

  return (
    <div className="w-max">
      <Button
        full
        icon={showIcon ? AddIcon : null}
        small
        variant={ButtonVariant.Ghost}
        onClick={() => setIsModalOpen(true)}>
        {showText ? t('addCustomField') : null}
      </Button>
      <CustomFieldPopover
        currentItem={item}
        elementBaseType={elementBaseType}
        isFromAdmin={isFromAdmin}
        isInRecord={isInRecord}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onRefetch={customOnRefetch}
      />
    </div>
  )
}

export default CreateCustomFieldButton
