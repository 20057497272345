import React, { FC, memo, useEffect, useState } from 'react'
import DashboardStateWidgetType from 'utils/types/states/DashboardStateWidgetType'
import { useSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { getChartColor } from 'utils/helpers/colorHelpers'
import { InfoIcon } from 'components/Icons'
import Chart from '../../../Chart/Chart'
import { DashboardWidgetBaseTypes } from '../../../../../utils/constant/enums/DashboardWidgetBaseType'
import WidgetImage from '../../../WidgetImage'
import DashboardWidgetType from '../../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../../store'
import Loader from '../../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../../features/element'

const CustomWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEditMode: boolean,
  setIsEditorOpen: () => void,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo, isEditMode, onUpdateWidgetImage }) => {
  const { t, i18n } = useTranslation('dashboard')
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const { data } = widget || widgetInfo
  const [isLoading, setIsLoading] = useState(true)
  const isRtl = i18n.language === 'ar'
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  const addTranslation = (text: string) => {
    const translatedText = t(`dashboard:priority:${text}`)
    if (translatedText.includes('priority')) {
      return text
    }
    return translatedText
  }

  const customChartTypes = {
    16: 'pie',
    17: 'bar',
  }
  const [sized] = useSize(({ width }) => <div />)
  const dataCount: number[] = []
  const labels: string[] = []
  const backgrounds: string[] = []

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }
  data?.values?.map((item: any, index: number) => {
    dataCount.push(item.count)
    backgrounds.push(getChartColor(index))
    labels.push(t(addTranslation(item.key)))
  })

  const datasets = [
    {
      backgroundColor: backgrounds,
      data: dataCount,
      hoverOffset: 4,
      label: '',
    },
  ]

  const scales =
    widgetInfo.baseType === DashboardWidgetBaseTypes.CustomBarChart
      ? {
          scales: {
            y: {
              ticks: {
                precision: 0,
              },
            },
          },
        }
      : {}

  return (
    <>
      {sized}
      <div className="flex flex-col flex-1 min-h-0 overflow-y-auto">
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <WidgetImage isEdit={isEditMode} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
        )}
        {widget?.data?.errors?.length > 0 ? (
          <div className="flex flex-col items-center justify-center w-full mt-10">
            <InfoIcon className="w-8 h-8 mb-2 text-danger" />
            <p className="text-center text-danger">{t(`notifications:${widget?.data.errors[0].key}`)}</p>
          </div>
        ) : (
          <Chart
            datasets={datasets}
            labels={labels}
            options={{
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  color: '#fff',
                  formatter: (value, ctx) => {
                    let sum = 0
                    const dataArr = ctx.chart.data.datasets[0].data
                    dataArr.map((data) => {
                      sum = Number(data) + sum
                    })
                    const percentage = ((value * 100) / sum).toFixed(0) + '%'
                    return percentage
                  },
                  textAlign: 'center',
                },
                legend: {
                  align: isRtl ? 'end' : 'start',
                  display: widgetInfo.baseType !== DashboardWidgetBaseTypes.CustomBarChart,
                  labels: {
                    usePointStyle: true,
                  },
                },
              },
              responsive: true,
              ...scales,
            }}
            type={customChartTypes[widgetInfo.baseType]}
          />
        )}
      </div>
    </>
  )
}

export default memo(CustomWidget)
