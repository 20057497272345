import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getFieldFormatting } from 'utils/helpers/customFields'
import { useParams } from 'react-router-dom'
import { RecordType } from '../../../utils/types'
import { ChosenChoiseIcon, FormulaIcon } from '../../Icons'
import SettingsDropdown from '../../SettingsDropdown'
import { useCurrentList } from '../../../features/list'
import { CustomFieldType } from '../../../utils/types/CustomFieldsType'
import { updateRecordElementById } from '../../../features/record'

interface CustomFieldFormulaType {
  currentItem: RecordType;
  customFieldInfo: CustomFieldType;
  onUpdate?: (item: string) => void;
  isEditingAllowed: boolean;
  isGridView?: boolean;
}

const RecordFormula: FC<CustomFieldFormulaType> = ({
  currentItem,
  onUpdate,
  isEditingAllowed,
  customFieldInfo,
  isGridView = false,
}) => {
  const { viewId } = useParams<{ viewId: string }>()
  const { t } = useTranslation('customFields')
  const dispatch = useDispatch()
  const currentList = useCurrentList()
  const currentFormula = currentItem?.appElements[0]?.customFieldValues?.find(
    (field) => +field.customFieldId === +customFieldInfo.id,
  )

  let color = ''
  let backgroundColor = ''
  const formattingStyles = getFieldFormatting(currentFormula?.renderValue)
  if (formattingStyles !== null) {
    color = formattingStyles.color
    backgroundColor = formattingStyles.backgroundColor
  }
  let formulaValue = currentFormula?.value

  const customFieldFormulas = currentList?.customFields?.native?.fields.find(
    (field: CustomFieldType) => +field.id === +customFieldInfo.id,
  )
  if (formulaValue?.indexOf('_/Err-') > -1 || formulaValue?.length === 0) {
    formulaValue = 'notAvailable'
  }

  const onUpdateFormulaOption = (optionId: string | number) => {
    const fieldValue = {
      customFieldFormulaId: optionId,
      customFieldId: customFieldInfo.id,
      value: '',
    }
    const changes = { [`custom_${customFieldInfo.id}`]: optionId }
    const allCustoms = [...currentItem?.appElements[0]?.customFieldValues]
    const customIndex = allCustoms.findIndex((i: any) => +i.customFieldId === +customFieldInfo.id)
    if (customIndex > -1) {
      allCustoms[customIndex] = { ...allCustoms[customIndex], ...fieldValue }
    } else {
      allCustoms.push(fieldValue)
    }
    const fieldsList = {
      appElements: [
        {
          ...currentItem?.appElements[0],
          customFieldValues: allCustoms,
        },
      ],
    }
    const updatedRecord = { ...currentItem, ...fieldsList }

    dispatch(
      updateRecordElementById({
        changes,
        elementId: updatedRecord?.appElements[0]?.id,
        viewId: viewId,
        record: updatedRecord,
      }),
    )
    // dispatch(updateRecordById({ changes, initialRecord: updatedRecord }))
  }

  return (
    <div
      className={`flex items-center justify-between p-1 leading-6 min-h-12 -ms-2 -my-1.5 transition border-transparent border-dashed rounded text-sm hover:border-dashed hover:border-gray-300 ${
        !isGridView && 'border-2'
      }`}
      style={{ background: `${backgroundColor}`, color: `${color}` }}>
      <div className="text-sm">{t(`${formulaValue}`)}</div>
      {isEditingAllowed && (
        <div className="flex border-gray-300">
          <SettingsDropdown
            Icon={FormulaIcon}
            childClasses="w-60 end-0 rounded-te-none"
            iconClasses="h-5 w-5 p-0.5 shrink-0 bg-gray-300 rounded-sm hover:bg-primary hover:text-white text-gray-600"
            showDefaultIconWidth={false}
            toTop={false}>
            <div className="text-gray-600">
              <p className="font-bold">{t('formulasOptionsTitle')}</p>
              {customFieldFormulas?.customFieldFormulas?.map((formulaInfo, idx) => {
                const isDefaultOption = +formulaInfo?.id === +currentFormula?.customFieldFormulaId
                return (
                  <button
                    key={idx}
                    className="block w-full py-2 hover:text-primary text-start"
                    onClick={() => {
                      if (onUpdate) {
                        onUpdate(formulaInfo.id)
                      } else {
                        onUpdateFormulaOption(formulaInfo.id)
                      }
                    }}>
                    <div className="leading-6">
                      <div className="flex items-center justify-between">
                        <p className={`${isDefaultOption ? 'text-primary' : 'text-gray-600'}`}>{formulaInfo.name}</p>
                        {isDefaultOption && <ChosenChoiseIcon style={{ stroke: '#00B2A9' }} />}
                      </div>
                      <div className="text-gray-600 truncate opacity-60">{formulaInfo.formula}</div>
                    </div>
                  </button>
                )
              })}
            </div>
          </SettingsDropdown>
        </div>
      )}
    </div>
  )
}

export default RecordFormula
