import React, { FC } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import 'i18n'
import 'react-toastify/dist/ReactToastify.css'
import 'components/forms/validationSchemas/validationLocales'
import store from 'store'

import 'styles/App.sass'
import { NavbarProvider, PanelProvider, SecondaryPanelProvider } from 'contexts'
import Layout from 'components/Layout'
import LoginLayout from 'components/Layout/LoginLayout'
import ProtectedRoute from 'components/ProtectedRoute'
import SignUpPage from 'pages/login/SignUpPage'
import history from 'utils/history'
import { configValues } from 'utils/appConfig'
import PublicDocumentation from 'pages/PublicDocumentationPage'
import { useTranslation } from 'react-i18next'
import { LinkRouter } from 'components/Layout'

const LocalHostWrapper: FC<{ children: React.ReactNode }> = ({ children }) =>
  process.env.NODE_ENV === 'production' ? <>{children}</> : <div className="localhost">{children}</div>

const App: FC = () => {
  const { i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const toastPosition = isRtl ? 'top-left' : 'top-right'
  if (configValues.IS_SENTRY_ENABLED && !configValues.IS_GOOGLE_LOGIN_ENABLED) {
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <LocalHostWrapper>
            <Router history={history}>
              <Switch>
                <Route path="/link">
                  <LinkRouter />
                </Route>
                <Route
                  path={[
                    '/sign-in',
                    '/reset-password',
                    '/set-phone-mfa',
                    '/forgot-password',
                    '/confirmation-message',
                    '/account-confirmed',
                    '/resend-email',
                    '/mfa',
                  ]}>
                  <LoginLayout />
                </Route>
                <Route path="/sign-up">
                  <SignUpPage />
                </Route>
                <Route path="/public-docs/:publicId">
                  <PublicDocumentation />
                </Route>
                <ProtectedRoute>
                  <NavbarProvider>
                    <PanelProvider>
                      <SecondaryPanelProvider>
                        <Layout />
                      </SecondaryPanelProvider>
                    </PanelProvider>
                  </NavbarProvider>
                </ProtectedRoute>
              </Switch>
            </Router>
            <ToastContainer hideProgressBar pauseOnHover={false} position={toastPosition} rtl={isRtl} />
          </LocalHostWrapper>
        </Provider>
      </Sentry.ErrorBoundary>
    )
  } else if (configValues.IS_SENTRY_ENABLED && configValues.IS_GOOGLE_LOGIN_ENABLED) {
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <LocalHostWrapper>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ''}>
              <Router history={history}>
                <Switch>
                  <Route
                    path={[
                      '/sign-in',
                      '/reset-password',
                      '/forgot-password',
                      '/confirmation-message',
                      '/account-confirmed',
                      '/resend-email',
                    ]}>
                    <LoginLayout />
                  </Route>
                  <Route path="/sign-up">
                    <SignUpPage />
                  </Route>
                  <Route path="/public-docs/:publicId">
                    <PublicDocumentation />
                  </Route>
                  <ProtectedRoute>
                    <NavbarProvider>
                      <PanelProvider>
                        <SecondaryPanelProvider>
                          <Layout />
                        </SecondaryPanelProvider>
                      </PanelProvider>
                    </NavbarProvider>
                  </ProtectedRoute>
                </Switch>
              </Router>
              <ToastContainer hideProgressBar pauseOnHover={false} position={toastPosition} rtl={isRtl} />
            </GoogleOAuthProvider>
          </LocalHostWrapper>
        </Provider>
      </Sentry.ErrorBoundary>
    )
  } else if (!configValues.IS_SENTRY_ENABLED && configValues.IS_GOOGLE_LOGIN_ENABLED) {
    return (
      <Provider store={store}>
        <LocalHostWrapper>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ''}>
            <Router history={history}>
              <Switch>
                <Route
                  path={[
                    '/sign-in',
                    '/reset-password',
                    '/forgot-password',
                    '/confirmation-message',
                    '/account-confirmed',
                    '/resend-email',
                  ]}>
                  <LoginLayout />
                </Route>
                <Route path="/sign-up">
                  <SignUpPage />
                </Route>
                <Route path="/public-docs/:publicId">
                  <PublicDocumentation />
                </Route>
                <ProtectedRoute>
                  <NavbarProvider>
                    <PanelProvider>
                      <SecondaryPanelProvider>
                        <Layout />
                      </SecondaryPanelProvider>
                    </PanelProvider>
                  </NavbarProvider>
                </ProtectedRoute>
              </Switch>
            </Router>
            <ToastContainer hideProgressBar pauseOnHover={false} position={toastPosition} rtl={isRtl} />
          </GoogleOAuthProvider>
        </LocalHostWrapper>
      </Provider>
    )
  }

  return (
    <Provider store={store}>
      <LocalHostWrapper>
        <Router history={history}>
          <Switch>
            <Route path="/link">
              <LinkRouter />
            </Route>
            <Route
              path={[
                '/sign-in',
                '/reset-password',
                '/set-phone-mfa',
                '/forgot-password',
                '/confirmation-message',
                '/account-confirmed',
                '/resend-email',
                '/mfa',
              ]}>
              <LoginLayout />
            </Route>
            <Route path="/sign-up">
              <SignUpPage />
            </Route>
            <ProtectedRoute>
              <NavbarProvider>
                <PanelProvider>
                  <SecondaryPanelProvider>
                    <Layout />
                  </SecondaryPanelProvider>
                </PanelProvider>
              </NavbarProvider>
            </ProtectedRoute>
          </Switch>
        </Router>
        <ToastContainer hideProgressBar pauseOnHover={false} position={toastPosition} rtl={isRtl} />
      </LocalHostWrapper>
    </Provider>
  )
}

export default App
