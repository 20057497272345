import {
  VeryLowPriorityIcon,
  VeryHighPriorityIcon,
  LowPriorityIcon,
  HighPriorityIcon,
  MediumPriorityIcon,
  DashIcon,
} from 'components/Icons'
import { FC, SVGAttributes } from 'react'
import i18n from 'i18next'
import { RecordType } from 'utils/types'
import { groupBy } from 'lodash'

export const RECORDS_PER_PAGE = 200

const _hashCode = (str: any) => {
  if (Array.isArray(str)) {
    str = str[0]
  }
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash ** 2 - hash
}

const _intToARGB = (i: number) => {
  return ((i >> 24) & 0xff).toString(16) + ((i >> 16) & 0xff).toString(16) + ((i >> 8) & 0xff).toString(16)
}

export function generateColor(name: string | Array<any>): string {
  if (Array.isArray(name) && name[0] === undefined) {
    return 'ffffff'
  }
  const color = _intToARGB(_hashCode(name))
  return `${color.length === 6 ? `${color}` : `${color}6`}`
}

export const iconsByStatusName = (
  getNotAssigned = true,
): {
  color: string,
  Icon: FC<SVGAttributes<SVGElement>>,
  label: string,
  value: number | null,
  id: number,
}[] => {
  const recordPriorities = i18n.t('records:priorities', { returnObjects: true })
  const notAssignedPriority = {
    color: '#7C9CB0',
    Icon: DashIcon,
    id: -1,
    label: i18n.t('records:notAssigned'),
    value: null,
  }

  const priorities = [
    {
      color: '#EB0045',
      Icon: VeryHighPriorityIcon,
      id: 4,
      label: recordPriorities[4],
      value: 4,
    },
    {
      color: '#FF671D',
      Icon: HighPriorityIcon,
      id: 3,
      label: recordPriorities[3],
      value: 3,
    },
    {
      color: '#FFD040',
      Icon: MediumPriorityIcon,
      id: 2,
      label: recordPriorities[2],
      value: 2,
    },
    {
      color: '#00CE7C',
      Icon: LowPriorityIcon,
      id: 1,
      label: recordPriorities[1],
      value: 1,
    },
    {
      color: '#00CE7C',
      Icon: VeryLowPriorityIcon,
      id: 0,
      label: recordPriorities[0],
      value: 0,
    },
  ]

  return getNotAssigned ? [notAssignedPriority, ...priorities] : priorities
}

export const healthStatusName = (): {
  color: string,
  label: string,
  value: string,
  id: string,
  order: number,
}[] => {
  const recordHeath = i18n.t('records:health', { returnObjects: true })
  return [
    {
      color: '#C5D4E2',
      id: 'unavailable',
      label: recordHeath['unavailable'],
      order: 0,
      value: 'unavailable',
    },
    {
      color: '#00CE7C',
      id: 'onTime',
      label: recordHeath['onTime'],
      order: 1,
      value: 'onTime',
    },
    {
      color: '#FF671D',
      id: 'late',
      label: recordHeath['late'],
      order: 2,
      value: 'late',
    },
    {
      color: '#EB0045',
      id: 'veryLate',
      label: recordHeath['veryLate'],
      order: 3,
      value: 'veryLate',
    },
  ]
}

export const getPriorityOptions = (getNotAssigned = true): { label: string, value: string | null, id: number }[] =>
  iconsByStatusName(getNotAssigned).map(({ label, value, id }) => ({
    id,
    label,
    value: value === null ? null : value.toString(),
  }))

export const getHealthOptions = (): { label: string, value: string }[] =>
  healthStatusName().map(({ label, value, id, order }) => ({ id, label, order, value: value.toString() }))

export const getPriorityIconByValue = (
  priorityValue: number | null,
): { Icon: FC<SVGAttributes<SVGElement>>, color: string, label: string } =>
  iconsByStatusName().find(({ value }) => priorityValue === value) || { color: '', Icon: () => null, label: '' }

export const getPriorityIconByText = (searchedText: string): { Icon: FC<SVGAttributes<SVGElement>>, color: string } => {
  return iconsByStatusName().find(({ label }) => searchedText === label) || { color: '', Icon: () => null }
}

export const getItemSize = ({
  currentRecord,
  recordsLength,
  containerHeight,
  rowHeight,
  singleLineHeight,
}: {
  currentRecord: RecordType,
  recordsLength: number,
  containerHeight: number,
  rowHeight: number,
  singleLineHeight: number,
}): number => {
  if (recordsLength) {
    const maxOfLabelsInRow = 5
    const outer = document.createElement('div')
    const element = document.createElement('p')
    outer.appendChild(element)

    element.innerText = currentRecord.name
    element.classList.add('text-base')
    element.classList.add('leading-5')
    element.style.width = '248px'

    document.body.append(element)

    const hasScroll = element.scrollHeight > element.clientHeight

    const height = element.clientHeight - singleLineHeight
    element.remove()
    const isSubTask = currentRecord.appElements && +currentRecord.appElements[0]?.elementLevel > 4
    const parentTitleHeight = currentRecord?.isParentInTheSameCol
      ? 0
      : isSubTask
      ? currentRecord.hasSisters && currentRecord.order === 1
        ? 20
        : 0
      : 0
    const labelRowHeight = 14
    const numberOfLabelsRows = (currentRecord.labels?.length || 0) / maxOfLabelsInRow
    const labelsRows =
      Number.isInteger(numberOfLabelsRows) && numberOfLabelsRows && hasScroll
        ? numberOfLabelsRows
        : Math.ceil(numberOfLabelsRows)
    const labelsHeight = labelRowHeight * labelsRows
    return rowHeight + height + labelsHeight + parentTitleHeight
  }
  return rowHeight
}
export const groupByColumnId = (
  records: any[],
  fieldToSortBy: string,
  groupName: string,
  baseType?: number,
  customId?: number,
) => {
  if (fieldToSortBy === 'documents') {
    return {
      [groupName]: records.filter((rec) => {
        return rec.appElements[0].linkedElementAppElementLinks.findIndex((el: any) => +el.elementId === +groupName) > -1
      }),
    }
  } else if (baseType === 5) {
    return {
      [groupName]: records.filter((rec) => {
        return (
          rec.appElements[0].appElementLinks.findIndex(
            (el: any) => +el.linkedElementId === +groupName && +el.customFieldId === +customId,
          ) > -1
        )
      }),
    }
  } else {
    return groupBy(records, fieldToSortBy)
  }
}

export const formattingRecordsInStack = (recordsList: RecordType[], listElementId: number | string) => {
  const recordsCopy = [...recordsList].map((item) => ({ ...item, isParentInTheSameCol: false }))
  let newListIds: number[] = [] // to simplify testing
  let newList: any[] = [] // collect ordered records
  recordsList?.map((rec) => {
    if (newListIds.indexOf(+rec.id) === -1) {
      //check if the current record is not a subtask
      if (+rec?.appElements[0]?.parentId === +listElementId) {
        newList.push(rec)
        newListIds.push(+rec.id)
        const recordChildren = [...recordsCopy]
          .filter((item) => {
            return +item.appElements[0].parentId === +rec.appElements[0].id
          })
          .map((item) => ({ ...item, isParentInTheSameCol: true }))
        newList = [...newList, ...recordChildren]
        newListIds = [...newListIds, ...recordChildren.map((item) => +item.id)]
      } else {
        const isCurrentRecordParenExist =
          recordsList.findIndex((r) => r?.appElements[0]?.id === rec?.appElements[0]?.parentId) > -1
        // collect rest records in ordered way
        if (!isCurrentRecordParenExist) {
          const recordParent = rec.appElements[0].parentId
          newList.push({ ...rec, hasSisters: true, isParentInTheSameCol: false, order: 1 })
          newListIds.push(+rec.id)
          const childrenForSameParent = [...recordsCopy]
            .filter((item) => {
              return +item.appElements[0].parentId === +recordParent && +item.id !== +rec.id
            })
            .map((item) => ({ ...item, hasSisters: true, isParentInTheSameCol: false }))
          newList = [...newList, ...childrenForSameParent]
          newListIds = [...newListIds, ...childrenForSameParent.map((item) => +item.id)]
        }
      }
    }
  })
  return [...newList]
}
