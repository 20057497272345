import React, { ChangeEvent, useState, FC, useEffect, useRef, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import head from 'lodash/head'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { toast } from 'react-toastify'

import { updateStation } from 'features/station'
import { useCurrentStation } from 'features/station'
import { useCurrentWorkspace, updateWorkspaceById } from 'features/workspace'

import Button, { AvatarColorButton, ButtonVariant } from 'components/Buttons'
import { avatarColors } from 'utils/helpers/colorHelpers'
import { CheckmarkIcon } from 'components/Icons'
import { useDispatch } from 'react-redux'

export const SUPPORTED_FILE_FORMATS = ['image/jpeg', 'image/png']

const AvatarInput: FC<{
  avatar: string | undefined,
  updateAvatar: (avatar: { filename: string, base64data: string }) => void,
  emptyAvatarPlaceholder?: ReactNode,
  isChangeDisabled?: boolean,
  isViewer?: boolean,
}> = ({ avatar, updateAvatar, emptyAvatarPlaceholder, isChangeDisabled, isViewer }) => {
  const { t } = useTranslation(['user', 'validation'])
  const location = useLocation()
  const dispatch = useDispatch()
  const cropperRef = useRef<HTMLImageElement>(null)

  const workspace = useCurrentWorkspace()
  const station = useCurrentStation()
  const currentResource = location.pathname.includes('stations') ? station : workspace
  const [avatarColorActive, setAvatarColorActive] = useState<string | undefined>(currentResource?.color)
  const hasAvatar = currentResource?.useAvatar && avatar
  const [isDisplayColorCheckMark, setIsDisplayButtonCheckMark] = useState<boolean>(!hasAvatar)
  const [fileName, setFileName] = useState<string>('')
  const [avatarPath, setAvatarPath] = useState<any>(avatar || '')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isProfile = location.pathname.includes('profile')

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  useEffect(() => {
    if (avatar) setAvatarPath(avatar)
  }, [avatar])

  useEffect(() => {
    if (currentResource) {
      setAvatarColorActive(currentResource?.color)
    }
  }, [currentResource, currentResource?.color])

  useEffect(() => {
    if (avatarPath === '') return
    if (currentResource?.useAvatar) {
      setAvatarColorActive('')
      setIsDisplayButtonCheckMark(false)
    }
  }, [avatarPath, currentResource?.useAvatar])

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = head(event.target.files)
    if (!SUPPORTED_FILE_FORMATS.includes(file.type)) return toast(t('validation:errors.unsupportedImageFileFormat'))
    setAvatarPath(URL.createObjectURL(file))
    setFileName(file.name)
    setIsModalOpen(true)
    event.target.value = ''
  }

  const onCrop = () => {
    const imageElement: any = cropperRef?.current
    if (imageElement) {
      const base64data = imageElement?.cropper?.getCroppedCanvas()?.toDataURL()?.split(',')?.slice(1)?.join(',')
      updateAvatar({
        base64data,
        filename: fileName,
      })
      setIsModalOpen(false)
      handleOnImageClick()
    }
  }
  const onCancel = () => {
    setIsModalOpen(false)
    setFileName('')
  }

  const handleOnImageClick = () => {
    if (isDisplayColorCheckMark) {
      const newWorkspaceValues = {
        ...currentResource,
        color: avatarColorActive,
        elementName: currentResource?.name,
        useAvatar: true,
      }
      if (currentResource && currentResource.stationMembers) dispatch(updateStation(newWorkspaceValues))
      else dispatch(updateWorkspaceById(newWorkspaceValues))
    }
    setAvatarColorActive('')
    setIsDisplayButtonCheckMark(false)
  }

  return (
    <div className="mb-10">
      <h1 className="mb-6 text-lg">{t('user:details:avatar')}</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="mb-4 md:me-4 md:mb-0">
          <label className="block mb-2 text-sm font-bold">{t('user:details:preview')}</label>
          {isDisplayColorCheckMark && emptyAvatarPlaceholder !== undefined ? (
            emptyAvatarPlaceholder
          ) : (
            <div className="w-16 h-16 mb-2 overflow-hidden rounded-full me-4">
              <img alt="User avatar" src={avatar || '/assets/images/placeholder.svg'} />
            </div>
          )}
        </div>
        {!isProfile && (
          <div className="mb-4 md:me-6 md:mb-0">
            <label className="block text-sm font-bold">{t('user:details:background')}</label>
            <div className="flex flex-wrap md:w-72">
              {avatarColors.map((color) => (
                <AvatarColorButton
                  key={color}
                  disabled={isViewer}
                  avatarColorActive={avatarColorActive}
                  color={color}
                  data={currentResource}
                  isDisplayColorCheckMark={isDisplayColorCheckMark}
                  isStation={currentResource}
                  setIsDisplayButtonCheckMark={setIsDisplayButtonCheckMark}
                />
              ))}
              {avatarPath && (
                <button
                  className="relative w-10 h-10 mt-2 overflow-hidden rounded-sm me-2"
                  disabled={isViewer}
                  onClick={handleOnImageClick}>
                  {!isDisplayColorCheckMark && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800/20">
                      <CheckmarkIcon className="w-6 h-6 text-white stroke-4 filter drop-shadow-active" />
                    </div>
                  )}
                  <img alt="User avatar" src={avatar || '/assets/images/placeholder.svg'} />
                </button>
              )}
            </div>
          </div>
        )}
        <div>
          <label className="block mb-2 text-sm font-bold">{t('user:details:image')}</label>
          <div className="flex items-center">
            <input
              accept={SUPPORTED_FILE_FORMATS.join(',')}
              disabled={isChangeDisabled}
              id="avatar"
              name="avatar"
              type="file"
              onChange={onFileChange}
            />
            <label
              className={classnames(
                'items-center px-2 text-sm font-bold text-center text-gray-600 border-2 border-gray-400 border-solid rounded py-2 shadow-sm transition-colors',
                {
                  'cursor-pointer hover:text-primary-dark hover:border-primary-dark': !isChangeDisabled,
                },
              )}
              htmlFor="avatar">
              {t('user:details:uploadImage')}
            </label>
          </div>
        </div>
      </div>
      <Modal
        className="fixed max-h-screen p-6 overflow-auto bg-white rounded shadow top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 focus:outline-none"
        isOpen={!!(isModalOpen && avatarPath)}
        overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
        onRequestClose={onCancel}>
        <h1 className="mb-6">{t('user:details:cropModalTitle')}</h1>
        <div className="lg:flex">
          <div>
            <label className="block mb-3 text-sm font-medium font-bold">{t('user:details:cropArea')}</label>
            <Cropper
              aspectRatio={1}
              autoCropArea={1}
              className="w-3/4 max-w-full max-h-screen mb-6 mr-16"
              guides={false}
              preview=".avatar-preview"
              ref={cropperRef}
              responsive={true}
              src={avatarPath}
              viewMode={1}
              zoomable={false}
            />
          </div>
          <div>
            <label className="block mb-3 text-sm font-medium font-bold">{t('user:details:cropPreview')}</label>
            <div className="w-48 h-48 mb-6 overflow-hidden avatar-preview" style={{ direction: 'ltr' }} />
          </div>
        </div>
        <div className="flex justify-end p-6 mt-0 -m-6 bg-gray-200">
          <Button className="me-4" variant={ButtonVariant.Ghost} onClick={onCancel}>
            {t('common:labels.cancel')}
          </Button>
          <Button onClick={onCrop}>{t('user:details:cropAndSave')}</Button>
        </div>
      </Modal>
    </div>
  )
}

export default AvatarInput
