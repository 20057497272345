import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Container } from '@edorivai/react-smooth-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Tour from 'reactour'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { EmptyStationsIcon } from 'components/Icons'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import { useHistory, useLocation } from 'react-router-dom'
import { createListStatus, reorderListStatus, useCurrentList } from '../../features/list'
import AddNewColumn from '../Kanban/AddNewColumn'
import { KanbanProps, RecordType } from '../../utils/types'
import {
  archiveRecordById,
  createRecord,
  onUpdateCustomFieldLinks,
  updateRecordElementById,
  useCurrentRecordsCustomFields,
  useListStatusesGroups,
  useRecordFetching,
  useRecords,
} from '../../features/record'
import { useRoles } from '../../hooks'
import KanbanPlaceholder from '../Kanban/KanbanPlaceholder'
import { TourContent } from '../Tour'
import RolesEnum from '../../utils/constant/enums'
import { useCurrentUserPermissions } from '../../features/user'
import { useAppDispatch } from '../../store'
import { createElementLink, getElementById } from '../../features/element'
import recordType from '../../utils/types/RecordType'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import { getRecordOrder } from '../../utils/helpers/listHelpers'
import Button, { ButtonVariant } from '../Buttons'
import ConfirmationModal from '../ConfirmationModal'
import KanbanColumn from './KanbanColumn'

export const generateItems = (count: number, creator: any) => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(creator(i))
  }
  return result
}

// type KanbanColumnType = { type: KanbanColumnTypeType, position: number }

const KanbanComplex: FC<KanbanProps> = ({
  updateRecord,
  columnsType,
  list,
  originList,
  baseType,
  customId,
  isArchived,
  statuses,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { isEditor, isAdmin } = useRoles()
  const { t, i18n } = useTranslation()
  const appDispatch = useAppDispatch()
  const currentList = useCurrentList()
  const allRecords = useRecords()
  const recordsInGroups = useListStatusesGroups()
  const isViewer = !isEditor && !isAdmin
  const isRecordsLoading = useRecordFetching()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState<any>(null)
  const [kanbanContainerHeight, setKanbanContainerHeight] = useState<number>()
  const fourthStepDone = localStorage.getItem('fourthStepDone') === 'true'
  const [isTourOpen, setTourOpen] = useState(!fourthStepDone)
  const kanbanContainerRef = useRef<HTMLDivElement>(null)
  const { listId, viewId }: { listId: string, viewId: string } = useParams()
  const isArchivedList = currentList?.appElement?.isArchived
  const isColumnReorderingDisabled = isArchivedList ? true : !isEditor
  const isMobile = window.innerWidth <= 1024
  const isRtl = i18n.language === 'ar'
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)
  const customFields = fieldsList
    ?.filter((i: any) => i.baseType === customFieldBaseTypes.DropDown)
    ?.map((i: any) => ({ id: i.id, label: i.fieldName, list: i?.customFieldOptions, value: i.id }))
  // const columnsType = 'statusId'
  // const fieldToSortBy = 'statusId'
  // const notAssignedType = { label: t('notAssigned'), value: null }
  // const list = useMemo(() => {
  //   const columns: KanbanColumnType[] = []
  //   priorities.forEach((priority, index) => {
  //     columns.push({
  //       position: index,
  //       type: priority,
  //     })
  //   })
  //   return columns
  // }, [isRtl, priorities])

  // const list = useMemo(() => {
  //   const columns: KanbanColumnType[] = []
  //   columns.push({ position: 0, type: notAssignedType })
  //   sortBy(statuses, 'position').forEach((status, index) => {
  //     columns.push({
  //       position: status.position,
  //       type: { label: status.name, value: status?.id?.toString() || `${index}` },
  //     })
  //   })
  //   return columns
  // }, [isRtl, statuses])

  useEffect(() => {
    const handleClickOutSideMenu = () => setOpenMenu(false)
    window.addEventListener('click', handleClickOutSideMenu)
    return () => window.removeEventListener('click', handleClickOutSideMenu)
  }, [])

  useLayoutEffect(() => {
    setKanbanContainerHeight(kanbanContainerRef.current?.clientHeight)
  }, [isRecordsLoading])

  const addColumn = (value: string) => {
    switch (columnsType) {
      case 'statusId': {
        dispatch(createListStatus({ listId: +listId, name: value }))
      }
    }
  }

  const columnsOrderUpdate = (payload: {
    columnType: string,
    columnValue: string,
    targetPosition: number | undefined,
  }) => {
    const { columnType, columnValue, targetPosition = 1 } = payload
    const statusToEdit = originList.find((item: any) => item.id.toString() === columnValue)
    switch (columnType) {
      case 'statusId': {
        return dispatch(
          reorderListStatus({
            position: targetPosition,
            status: statusToEdit,
          }),
        )
      }
      default:
        return
    }
  }

  const onColumnDrop = (dropResult: any) => {
    const { addedIndex, payload } = dropResult
    const columnType = columnsType
    const columnValue = payload.type.value
    const targetPosition = isRtl ? columnsList.length - 1 - addedIndex : addedIndex
    if ((!isRtl && addedIndex !== 0) || (isRtl && addedIndex !== columnsList.length - 1)) {
      columnsOrderUpdate({ columnType, columnValue, targetPosition })
    }
  }

  const onCreateCardOnCustomField = (status: string | null, updatedRecord: recordType) => {
    return dispatch(
      updateRecordElementById({
        changes: { [columnsType]: status },
        elementId: updatedRecord?.appElements[0]?.id,
        record: updatedRecord,
        viewId,
      }),
    )
  }

  const onCreateDocumentLink = (body: any, record: recordType) => {
    return appDispatch(createElementLink({ body, shouldDisplayToast: false })).then((res) => {
      appDispatch(getElementById(+res.payload.elementId)).then((response) => {
        const linkedDoc = response?.payload?.element?.appElementData
        const updatedLinkedDoc = [{ element: linkedDoc, id: +res.payload.id }]
        const recordAppElements = record?.appElements[0]
        const appElements = [{ ...recordAppElements, linkedElementAppElementLinks: updatedLinkedDoc }]
        dispatch(
          updateRecordElementById({
            changes: { appElements: appElements },
            elementId: record?.appElements[0]?.id,
            record,
            viewId,
          }),
        )
      })
    })
  }

  const addCard = (name: string, status: string | null) => {
    const cardData: any = {
      listId: +listId,
      name,
      parentId: +currentList?.appElement?.id,
    }

    if (status) cardData[columnsType] = +status
    appDispatch(createRecord(cardData)).then((res) => {
      //if columns are custom fields
      const recordToUpdate = res.payload
      const customFieldIndex = customFields.findIndex((field: any) => +field.id === +columnsType)
      if (baseType === 5) {
        const updateLinkMessage = {
          action: 'add',
          customFieldId: +columnsType,
          elementId: recordToUpdate?.appElements[0]?.id,
          force: true,
          linkedElementId: +status,
        }
        return appDispatch(onUpdateCustomFieldLinks({ recordId: +recordToUpdate?.id, updateLinkMessage }))
      } else if (customFieldIndex > -1) {
        const fieldValue = {
          customFieldId: columnsType,
          customFieldOptionId: status,
          value: status,
        }
        const fieldsList = {
          appElements: [
            {
              ...recordToUpdate?.appElements[0],
              customFieldValues: [fieldValue],
            },
          ],
        }
        const updatedRecord = { ...recordToUpdate, ...fieldsList }
        onCreateCardOnCustomField(status, updatedRecord)
      } else if (columnsType === 'documents') {
        //if columns are documents
        const body = {
          description: 'linked item',
          elementId: +status,
          isLinkedAsChild: false,
          linkedElementId: +recordToUpdate?.appElements[0]?.id,
        }
        onCreateDocumentLink(body, recordToUpdate)
      }
    })
  }

  const shouldAnimateDrop = (sourceContainerOptions, payload) => {
    if (payload.position === 0 || isViewer) {
      return false
    }
    return undefined
  }
  //list is reversed when change the language to keep columns showing in the same order user know
  const columnsList = isRtl
    ? list?.sort((a, b) => b?.position - a?.position)
    : list?.sort((a, b) => a?.position - b?.position)

  const steps = [
    {
      content: function listIntro({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            goTo={() => goTo(1)}
            header={t('tour:listIntroHeader')}
            message={t('tour:listIntroText')}
          />
        )
      },
      selector: '',
    },
    {
      content: function fourthStep({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent closeTour={() => setTourOpen(false)} goTo={() => goTo(2)} message={t('tour:fourthStepText')} />
        )
      },
      selector: isMobile ? '' : '[data-tut="fourth__step"]',
    },
    {
      content: function fifthStep({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent closeTour={() => setTourOpen(false)} goTo={() => goTo(3)} message={t('tour:fifthStepText')} />
        )
      },
      selector: isMobile ? '' : '[data-tut="fifth__step"]',
    },
    {
      content: function workspaceSidebar({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            goTo={() => goTo(4)}
            message={t('tour:navigateWorkspace')}
          />
        )
      },
      selector: isMobile ? '[data-tut="mobile__sidebar"]' : '[data-tut="workspace__sidebar"]',
    },
    {
      content: function sixthStep({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent closeTour={() => setTourOpen(false)} goTo={() => goTo(5)} message={t('tour:sixthStepText')} />
        )
      },
      selector: '[data-tut="sixth__step"]',
    },
    {
      action: () => localStorage.setItem('fourthStepDone', 'true'),
      content: function outro() {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            header={t('tour:outroHeader')}
            isLast={true}
            message={t('tour:outroText')}
          />
        )
      },
      selector: '',
    },
  ]

  const onDragEnd = async (dropResult: DropResult) => {
    const { draggableId, destination, type } = dropResult
    if (type === 'column') {
      const [columnType, columnValue] = draggableId.split('-').slice(1)
      return columnsOrderUpdate({ columnType, columnValue, targetPosition: destination?.index })
    }
    const { droppableId: value, index: position } = destination || {}
    const [valueToChange, entityId, , recordElement, , baseType] = draggableId.split('-').slice(1)
    if (baseType && +baseType === 5 && value) {
      const updateLinkMessage = {
        action: 'add',
        customFieldId: +valueToChange,
        elementId: recordElement,
        force: true,
        linkedElementId: +value,
      }
      return appDispatch(onUpdateCustomFieldLinks({ recordId: +entityId, updateLinkMessage }))
    }
    if (entityId !== undefined && position !== undefined && value !== undefined && valueToChange !== 'documents') {
      const newOrder = getRecordOrder({
        currentStatus: destination?.droppableId,
        recordIndex: destination?.index,
        allRecords,
        recordsInGroups,
        statuses,
        recordId: +entityId,
      })

      return updateRecord(
        +entityId,
        valueToChange,
        {
          position,
          value: value === 'null' ? null : +value,
        },
        newOrder,
      )
    }
  }

  if (!isRecordsLoading && columnsList?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 h-full m-auto">
        <EmptyStationsIcon className="w-48" />
        <div className="mt-5">{t('noDataAvailable')}</div>
      </div>
    )
  }

  const onRightClickItem = (item: RecordType, position: { x: number, y: number }) => {
    setOpenMenu(!openMenu)
    setCurrentItem(item)
    setMenuPosition(position)
  }

  const onArchive = () => {
    const recursive = 0 // 1 to unarchive element and all children force 0 to archive element and children but not
    dispatch(archiveRecordById({ elementId: +currentItem?.appElements[0]?.id, recursive, status: Number(!isArchived) }))
    setIsArchiveModalOpen(false)
  }

  return (
    <>
      <Tour
        disableInteraction={true}
        isOpen={isTourOpen}
        showButtons={false}
        showNavigation={false}
        showNumber={false}
        steps={steps}
        onRequestClose={() => setTourOpen(false)}
      />
      {isRecordsLoading ? (
        <KanbanPlaceholder />
      ) : (
        <div
          className="flex p-4 overflow-x-auto overflow-y-hidden kanban-container"
          data-tut="fifth__step"
          id="kanban-container"
          ref={kanbanContainerRef}
          style={{ height: isArchived ? 'calc(100% - 46px)' : '100%' }}>
          <Container
            autoScrollEnabled={true}
            behaviour={'move'}
            dragHandleSelector=".column-drag-handle"
            dropPlaceholder={{
              animationDuration: 150,
              className: 'cards-drop-preview',
              showOnTop: true,
            }}
            getChildPayload={(i: any) => list[i]}
            orientation="horizontal"
            shouldAnimateDrop={shouldAnimateDrop}
            onDrop={onColumnDrop}>
            <DragDropContext onDragEnd={onDragEnd}>
              {columnsList?.map((column: any) => {
                return (
                  <KanbanColumn
                    key={`column_${column.type.value}`}
                    onRightClickItem={onRightClickItem}
                    {...column}
                    addCard={addCard}
                    baseType={baseType}
                    columnsType={columnsType}
                    customId={customId}
                    fieldToSortBy={columnsType}
                    index={column.position}
                    isArchivedList={isArchivedList}
                    isDragDisabled={isColumnReorderingDisabled}
                    isViewer={isViewer || isArchivedList}
                    kanbanContainerHeight={kanbanContainerHeight - 100}
                    updateRecord={updateRecord}
                  />
                )
              })}
            </DragDropContext>
          </Container>
          {columnsType === 'statusId' && !isArchivedList && <AddNewColumn addColumn={addColumn} isAllowed />}
          {openMenu && (
            <div className="menuCard" style={{ left: menuPosition.x, top: menuPosition.y }}>
              <Button
                className="text-primary"
                small
                variant={ButtonVariant.Text}
                onClick={() => {
                  const recordUrl = `${location.pathname}${
                    location.search ? `${location.search}&recordId=${currentItem.id}` : `?recordId=${currentItem.id}`
                  }`
                  history.push(recordUrl)
                }}>
                {t('common:labels.open')}
              </Button>
              <Button
                className="text-danger"
                variant={ButtonVariant.Text}
                small
                onClick={() => setIsArchiveModalOpen(true)}>
                {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
              </Button>
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        confirmMessage={isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
        confirmationMessage={t(isArchived ? 'records:confirmRecordUnarchive' : 'records:confirmRecordArchive', {
          interpolation: { escapeValue: false },
          name: currentItem?.name,
        })}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={onArchive}
      />
    </>
  )
}
export default KanbanComplex
