import { CaretDownIcon } from 'components/Icons'
import { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const RecursiveTree: FC<{
  data: any,
  headerRows: { header: string, width: string, minWidth: string, name: string }[],
  padding?: number,
}> = ({ data, headerRows, padding = 20 }) => {
  const [opened, setOpened] = useState<string[]>([])
  const { i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'

  if (!data) {
    return null
  }

  const onOpen = (item: any) => {
    if (item.folder) {
      if (opened.includes(item.id + item.name)) {
        setOpened(opened.filter((o) => !o.includes(item.id + item.name)))
      } else {
        setOpened([...opened, item.id + item.name])
      }
    }
  }

  return data.map((item: any) => (
    <div key={item.name + item.id} className="w-full">
      <div
        className={`flex ${item.folder ? 'cursor-pointer' : ''} pt-3 pb-3 border-b border-l border-r w-full`}
        onClick={() => onOpen(item)}>
        {headerRows.map((row, idx) => {
          const isName = row.name === 'name'

          return (
            <div
              key={row.name + idx}
              className={`${!isName ? '' : `flex items-center`} text-center`}
              style={{
                minWidth: row.minWidth,
                paddingLeft: isName && !isRtl ? padding : 0,
                paddingRight: isName && isRtl ? padding : 0,
                width: row.width,
              }}>
              {item.folder && isName ? (
                <div className="me-2">
                  <CaretDownIcon />
                </div>
              ) : null}

              <p>{item[row.name]}</p>
            </div>
          )
        })}
      </div>
      {opened.includes(item.id + item.name) ? (
        <RecursiveTree data={item.nodes} headerRows={headerRows} padding={padding + 22} />
      ) : null}
    </div>
  ))
}

export default memo(RecursiveTree)
