import React, { FC } from 'react'
import classnames from 'classnames'

type DisappearingTextPropType = {
  text?: string | undefined,
  classNames?: { wrapper?: string, text?: string, gradient?: string },
}

const DisappearingText: FC<DisappearingTextPropType> = ({ text, classNames }) => (
  <div className={classnames('flex-1 relative overflow-hidden text-sm', classNames?.wrapper)}>
    <div
      className={classnames(
        'absolute inset-x-0 bottom-0 top-2/3 bg-gradient-to-t',
        classNames?.gradient || 'from-white',
      )}
    />
    <p className={classnames('whitespace-pre-line', classNames?.text)}>{text}</p>
  </div>
)

export default DisappearingText
