import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import EntryAvatar from 'components/EntryAvatar'
import ContextMenu from 'components/ContextMenu'
import { setColorByStates, setColorByStatus } from 'utils/types/widgetUtils'
import { getDateFormat, getFormattedCompletion } from 'utils/helpers/generalHelpers'
import { configValues } from 'utils/appConfig'
import Link from 'components/Link'
import { useHistory } from 'react-router-dom'
import Chart from '../../Chart/Chart'
import { getStateName } from '../../../../utils/helpers/stationHelpers'
import UserAvatarStatus from '../../../UserAvatar/UserAvatarStatus'
import { useCurrentUser } from '../../../../features/user'

const ItemWithColoredDiv: FC<{
  item: any,
  customWidth: string | null,
  itemKey: string,
  text: string,
}> = ({ item, customWidth, itemKey, text }) => {
  const color = setColorByStatus(item[itemKey], false).color
  return (
    <div className="flex flex-col items-center justify-between text-center h-11">
      <div
        className="border-2 rounded mt-2.5"
        style={{
          backgroundColor: color,
          borderColor: color,
          width: customWidth ? customWidth : '4.5rem',
        }}
      />
      <div className="text-sm">{text}</div>
    </div>
  )
}

const StationDetailsRow: FC<{
  list: any[],
  elementWidth: number,
  isHomeDashboard?: boolean,
}> = ({ elementWidth, list, isHomeDashboard = false }) => {
  const { t, i18n } = useTranslation(['dashboard', 'station'])
  const history = useHistory()
  const user = useCurrentUser()
  const isHijri = user?.isHijri !== null ? user?.isHijri : false

  const isRtl = i18n.language === 'ar'
  const isLessThanFirstBreakpoint = elementWidth < 1124

  const completionChart = (completed: number, isMobileVersion = false) => {
    return (
      <Chart
        datasets={[
          {
            backgroundColor: ['#00B2A9', '#DDE5ED'],
            borderColor: ['rgba(255, 99, 132, 0.0)', 'rgba(54, 162, 235, 0.0)'],
            borderRadius: 0,
            cutout: isMobileVersion ? '70%' : '82%',
            data: [completed, 100 - completed],
            fill: false,
            label: '',
          },
        ]}
        options={{ plugins: { datalabels: { display: false } } }}
        type="pie"
      />
    )
  }

  const desktopVersion = (arr: any) =>
    arr?.map((item: any) => {
      const UserAvatarStatusProps = {
        customSize: 11,
        imgAlt: `${item?.manager?.firstName} ${item?.manager?.lastName}`,
        imgSrc: item?.manager?.link,
        minWidth: 44,
        overrideCheckingUser: isHomeDashboard,
        removeIconCustomSize: 2.5,
        userId: item?.manager?.id,
      }

      return (
        <div
          key={item.id}
          className="flex justify-between px-5 py-5 mb-2 text-sm border rounded"
          style={{ width: '100%' }}>
          <div className={`items-center justify-between flex flex-1 ${isLessThanFirstBreakpoint ? 'gap-2' : 'gap-6'}`}>
            <div className="flex items-center gap-1" style={{ width: '30%' }}>
              <div className="shrink-0">
                <EntryAvatar entry={item} isStation />
              </div>
              <Link
                className="text-base cursor-pointer line-clamp-2 ms-1 hover:text-primary"
                to={`/workspace/${item?.workspaceId}/stations/${item?.id}/dashboard`}
                title={item.name}>
                {item.name}
              </Link>
            </div>

            <div
              className="flex justify-center"
              style={{ width: '6%' }}
              title={item?.manager?.firstName + ' ' + item?.manager?.lastName}>
              <UserAvatarStatus {...UserAvatarStatusProps} />
            </div>

            <div
              className="flex items-center justify-center px-2 py-1 text-xs font-bold text-center text-white rounded"
              style={{
                backgroundColor: setColorByStates(item.state).bgColor,
                color: setColorByStates(item?.state).color,
                width: '10%',
              }}>
              {t(`station:stationStates.${getStateName(item.state)}`)}
            </div>

            <div className="flex flex-col items-center justify-between text-sm h-11" style={{ width: '7%' }}>
              <div className="absolute" style={{ height: '55px', marginTop: '-11px', width: '55px' }}>
                {completionChart(item.avg)}
              </div>
              <div className="flex items-center justify-center flex-1">{getFormattedCompletion(item.avg, 0)}</div>
            </div>
            <div className="flex flex-col justify-between text-center h-11" style={{ width: '8%' }}>
              <div className="font-bold text-danger">{item.numberOfLateTasksInStation ?? 0}</div>
              <div className="text-sm">{t('lateTasks')}</div>
            </div>

            <div
              className={`flex items-center ${isLessThanFirstBreakpoint ? 'gap-2' : 'gap-6'} justify-around`}
              style={{ width: '25%' }}>
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '2.5rem' : null}
                item={item}
                itemKey="status"
                text={t('dashboard:groupByOptions.status')}
              />
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '2.5rem' : null}
                item={item}
                itemKey="budgetHealth"
                text={t('dashboard:groupByOptions.budgetHealth')}
              />
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '2.5rem' : null}
                item={item}
                itemKey="qualityHealth"
                text={t('dashboard:groupByOptions.qualityHealth')}
              />
            </div>
            <div className="content-center py-1 text-sm text-center" style={{ width: '12%' }}>
              {item.startDate ? getDateFormat(isRtl, item.startDate, isHijri) : t('dashboard:noStartDate')}
              <br />
              {item.endDate ? getDateFormat(isRtl, item.endDate, isHijri) : t('dashboard:noEndDate')}
            </div>

            <div className="flex justify-center" style={{ width: '2%' }}>
              <ContextMenu
                render={(close: any) => {
                  return (
                    <Link to={`/workspace/${item.workspaceId}/stations/${item.id}/details`}>{t('dashboard:Edit')}</Link>
                  )
                }}
              />
            </div>
          </div>
        </div>
      )
    })

  const mobileVersion = (arr: any) =>
    arr?.map((item: any) => {
      const UserAvatarStatusProps = {
        customSize: 9,
        imgAlt: `${item?.manager?.firstName} ${item?.manager?.lastName}`,
        imgSrc: item?.manager?.link,
        mobileInsetClass: '-inset-0.5',
        overrideCheckingUser: isHomeDashboard,
        removeIconCustomSize: 1,
        userId: item?.manager?.id,
      }
      return (
        <div key={item.id} className="px-1 mb-2 border rounded" style={{ width: '100%' }}>
          <div>
            <div className="flex justify-between">
              <div className="flex items-center justify-between py-2">
                <div className="flex items-center gap-2">
                  <EntryAvatar entry={item} isMobile />
                  <UserAvatarStatus {...UserAvatarStatusProps} />
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className="flex items-center justify-center px-4 py-1 text-xs font-bold text-center rounded"
                  style={{
                    backgroundColor: setColorByStates(item.state).bgColor,
                    color: setColorByStates(item.state).color,
                  }}>
                  {t(`station:stationStates.${getStateName(item.state)}`)}
                </div>
                <div className="flex justify-center -me-2">
                  <ContextMenu
                    render={() => {
                      return (
                        <Link to={`/workspace/${item.workspaceId}/stations/${item.id}/details`}>
                          {t('dashboard:Edit')}
                        </Link>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <Link
              className="w-full px-1 py-2 font-semibold truncate cursor-pointer hover:text-primary"
              to={`/workspace/${item?.workspaceId}/stations/${item?.id}/dashboard`}
              title={item.name}>
              {item.name}
            </Link>
            <div className="flex justify-between px-1 py-1">
              <div className="flex items-center text-sm text-center">
                {item.startDate ? getDateFormat(isRtl, item.startDate, isHijri) : t('dashboard:noStartDate')}
                {' - '}
                {item.endDate ? getDateFormat(isRtl, item.endDate, isHijri) : t('dashboard:noEndDate')}
              </div>

              <div className="flex">
                <div className="flex items-center justify-end w-20 text-sm">
                  <div>{t('lateTasks')}: </div>
                  <div className="flex justify-end font-bold text-danger ms-1">
                    <p>{item.numberOfLateTasksInStation}</p>
                  </div>
                </div>

                <div className="flex items-center justify-end w-20 text-xs">
                  <div className="flex justify-start -mt-2 ms-0" style={{ height: 33, width: 33 }}>
                    {completionChart(item.avg, true)}
                  </div>
                  <div className="flex justify-end w-13">
                    <p>{getFormattedCompletion(item.avg, 0)}%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between px-1 py-3">
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '3.5rem' : null}
                item={item}
                itemKey="status"
                text={t('dashboard:groupByOptions.status')}
              />
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '3.5rem' : null}
                item={item}
                itemKey="budgetHealth"
                text={t('dashboard:groupByOptions.budgetHealth')}
              />
              <ItemWithColoredDiv
                customWidth={isLessThanFirstBreakpoint ? '3.5rem' : null}
                item={item}
                itemKey="qualityHealth"
                text={t('dashboard:groupByOptions.qualityHealth')}
              />
            </div>
          </div>
        </div>
      )
    })

  // tempforkpi
  // const kpiVersion = (arr: any) =>
  //   arr?.map((item: any) => {
  //     const scoreValue = item?.customFieldValues?.find((f: any) => +f.customFieldId === 331)?.value
  //     const actualValue = item?.customFieldValues?.find((f: any) => +f.customFieldId === 330)?.value
  //     const maxValue = item?.customFieldValues?.find((f: any) => +f.customFieldId === 327)?.value
  //     const scoreColor = getScoreColor(scoreValue)
  //     return (
  //       <div
  //         key={item.id}
  //         className="flex justify-between px-5 py-5 mb-2 text-sm border rounded"
  //         style={{ width: '100%' }}>
  //         <div className={`items-center justify-between flex flex-1 ${isLessThanFirstBreakpoint ? 'gap-2' : 'gap-6'}`}>
  //           <div className="flex items-center gap-1" style={{ width: '66%' }}>
  //             <div className="shrink-0">
  //               <EntryAvatar entry={item} isStation />
  //             </div>
  //             <Link
  //               className="text-base cursor-pointer line-clamp-2 ms-1 hover:text-primary"
  //               to={`/workspace/${item?.workspaceId}/stations/${item?.id}/dashboard`}
  //               title={item.name}>
  //               {item.name}
  //             </Link>
  //           </div>

  //           <div className="flex flex-col justify-between text-center h-11" style={{ width: '8%' }}>
  //             <div className="text-sm">Actual</div>
  //             <div className="font-extrabold">
  //               {!actualValue || ['_/Err-001\\_', 'NaN'].indexOf(actualValue) > -1 ? 0 : Number(actualValue).toFixed(2)}
  //               %
  //             </div>
  //           </div>
  //           <div className="flex flex-col justify-between text-center h-11" style={{ width: '8%' }}>
  //             <div className="text-sm">Target</div>
  //             <div className="font-extrabold">{!maxValue ? 0 : Number(maxValue).toFixed(2)}%</div>
  //           </div>
  //           <div className="flex flex-col justify-between text-center h-11" style={{ width: '18%' }}>
  //             <div className="text-sm">Score</div>
  //             <div className="font-bold mx-2 rounded-sm" style={{ backgroundColor: scoreColor, color: 'white' }}>
  //               {!scoreValue || ['_/Err-001\\_', 'NaN'].indexOf(scoreValue) > -1 ? t('invalid') : scoreValue}
  //             </div>
  //           </div>

  //           <div className="flex justify-center" style={{ width: '4%' }}>
  //             <ContextMenu
  //               render={(close: any) => {
  //                 return (
  //                   <Link to={`/workspace/${item.workspaceId}/stations/${item.id}/details`}>{t('dashboard:Edit')}</Link>
  //                 )
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   })

  return <>{elementWidth < 700 ? <>{mobileVersion(list)}</> : <>{desktopVersion(list)}</>}</>
}

export default memo(StationDetailsRow)
