import { ComponentType, CSSProperties, forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import BasicSelect, { ControlProps, IndicatorProps, Styles } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { DropdownOptionType, DropdownInputType, InputRefType, ErrorMessageRefType } from 'utils/types/inputs'
import { InputErrorMessage, InputLabel } from 'components/inputs'

export const baseDropdownStyles: Partial<Styles<DropdownOptionType, false>> = {
  control: (_: CSSProperties, state: ControlProps<DropdownOptionType, boolean>) => ({
    backgroundColor: state.isFocused ? '#EEEEEE' : '#FFFFFF',
    borderRadius: '4px',
    boxShadow: state.isFocused ? '0px 1px 2px #EEEEEE' : 'none',
    display: 'flex',
    padding: state.isFocused ? '' : '3px 0 4px',
  }),
  dropdownIndicator: (base: CSSProperties, state: IndicatorProps<DropdownOptionType, boolean>) => ({
    ...base,
    display: state.isFocused ? 'block' : 'none',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (base: CSSProperties) => ({
    ...base,
    backgroundColor: '#EEEEEE',
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    color: '#33647E',
  }),
}

const Dropdown = forwardRef<InputRefType, DropdownInputType>(
  (
    {
      label,
      name,
      options,
      error,
      touched,
      classes,
      onChange,
      value,
      isCreatable,
      isGridView = false,
      isSmall,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation(['records', 'customFields'])
    const selectRef = useRef<any>()
    // const isGridView = window.location.pathname.indexOf('grid') > -1
    const errorRef = useRef<ErrorMessageRefType>(null)
    const dropdownStyle = rest.styles || baseDropdownStyles

    useImperativeHandle(
      ref,
      () => ({
        blur: () => selectRef.current?.select?.blur(),
        focus: () => selectRef.current?.select?.focus(),
        shakeError: () => errorRef?.current?.shakeError(),
      }),
      [ref, selectRef.current?.select, errorRef?.current],
    )

    const handleChange = (selectedOption: DropdownOptionType) => {
      const { value } = selectedOption || { value: null }
      onChange?.({ target: { id: `input-${name}`, name, value: `${value}` } })
      selectRef.current?.select?.blur()
      selectRef.current?.blur()
    }

    const Select: ComponentType<any> = isCreatable ? CreatableSelect : BasicSelect
    const dropdownValue = useMemo(
      () => options?.find(({ value: optionValue }) => `${optionValue}` === `${value}`),
      [value],
    )

    return (
      <div className={classnames(classes.wrapper)}>
        <InputLabel className="font-bold" label={label} name={name} />
        <Select
          className={classnames('select', rest.className, classes.input)}
          classNamePrefix={`${isGridView ? 'react-select-grid-view' : isSmall ? 'small-react-select' : 'react-select'}`}
          id={`input-${name}`}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          name={name}
          options={options}
          placeholder={t('placeholderSelect')}
          ref={selectRef}
          styles={{ ...dropdownStyle, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          value={dropdownValue}
          onChange={handleChange}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              selectRef.current?.blur()
            }
          }}
          {...rest}
        />
        <InputErrorMessage error={error} ref={errorRef} touched={touched} />
      </div>
    )
  },
)

Dropdown.displayName = 'Dropdown'

Dropdown.defaultProps = {
  classes: {},
}

export default Dropdown
