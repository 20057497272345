import { History } from 'history'
import get from 'lodash/get'
import { AuthNextStepEnum } from 'utils/constant/enums/auth'
import BaseApiResponseType from 'utils/types/BaseApiResponseType'
import { AccessTokenData, RefreshTokenData } from 'utils/types/TokenTypes'

export const handleAuthResponse = ({
  response,
  setLoading,
  history,
  location,
  isSettingNewPhone = false,
}: {
  response: BaseApiResponseType,
  setLoading: (isLoading: boolean) => void,
  history: History,
  location: any,
  isSettingNewPhone?: boolean,
}) => {
  if (response.status >= 400 || response.body === 'Invalid credentials') {
    setLoading(false)
  } else if (response.body.nextStep) {
    if (response.body.nextStep.id === AuthNextStepEnum.RESET_PASSWORD) {
      const resetToken = response.body.nextStep.extraValues.token
      history.push(`/reset-password/${resetToken}`)
    } else if (response.body.nextStep.id === AuthNextStepEnum.ADD_SMS_MFA) {
      const setMfaToken = response.body.nextStep.extraValues.token
      history.push(`/set-phone-mfa/${setMfaToken}`)
    } else if (response.body.nextStep.id === AuthNextStepEnum.MFA) {
      const mfaToken = response.body.nextStep.extraValues.token
      // Todo: do we need setnewphone?
      const newLocation = isSettingNewPhone ? `/mfa/${mfaToken}?setnewphone=true` : `/mfa/${mfaToken}`
      history.push(newLocation)
    }
  } else {
    if (response.body?.accessTokenUserLogin && response.body?.refreshTokenUserLogin) {
      setLoading(false)
      addTokensToLocalStorage({ body: response?.body })
      const lastVisitedPage = localStorage.getItem('lastVisitedPage')
      if (lastVisitedPage) {
        history.replace(get(location, 'state.backTo', lastVisitedPage))
        localStorage.removeItem('lastVisitedPage')
      } else {
        history.replace(get(location, 'state.backTo', '/'))
      }
    } else {
      setLoading(false)
      history.push('/sign-in')
    }
  }
}

export const removeTokensFromLocalStorage = () => {
  localStorage.removeItem('jwtToken')
  localStorage.removeItem('userId')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('tokenExpiresAt')
  localStorage.removeItem('refreshTokenExpiresAt')
}

export const addTokensToLocalStorage = (options: {
  body: {
    accessTokenUserLogin: RefreshTokenData,
    refreshTokenUserLogin: RefreshTokenData,
    accessToken: AccessTokenData,
    success: boolean,
  },
  resetUserId?: boolean,
}) => {
  const { body, resetUserId = true } = options
  localStorage.setItem('jwtToken', `${body?.accessTokenUserLogin?.tokenData}`)
  localStorage.setItem('refreshToken', `${body?.refreshTokenUserLogin?.tokenData}`)
  localStorage.setItem('tokenExpiresAt', `${body?.accessTokenUserLogin?.tokenExpiresAt}`)
  localStorage.setItem('refreshTokenExpiresAt', `${body?.refreshTokenUserLogin?.tokenExpiresAt}`)
  if (resetUserId) localStorage.setItem('userId', `${body?.accessToken?.id}`)
}
