import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tippy from '@tippy.js/react'
import NotificationSendEmailsMenu from './NotificationSendEmailsMenu'

type WatchButtonType = { onClick: () => void, onShowWatchers: () => void }

const NotificationsSettings: FC<WatchButtonType> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [isTippyInit, setIsTippyInit] = useState<boolean>(true)
  const { t } = useTranslation('common')
  const ref = useRef()

  const onClose = (e?: any) => {
    if (e) {
      e?.stopPropagation()
    }
    setVisible(false)
    setIsTippyInit(false)
  }

  return (
    <Tippy
      animation="fade"
      appendTo={() => document.body}
      arrow={false}
      className={'top-zIndex'}
      content={<NotificationSendEmailsMenu onClose={onClose} />}
      interactive={true}
      isDestroyed={!visible}
      placement={'bottom-end'}
      reference={ref}
      theme={'calendar-tooltip'}
      visible={visible}
      onHide={(data) => {
        onClose()
      }}>
      <div
        className="text-sm font-extrabold cursor-pointer hover:text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setVisible(!visible)
          setIsTippyInit(!isTippyInit)
        }}>
        <div ref={ref}>
          <p className="text-sm font-semibold cursor-pointer ms-2">{t('settings')}</p>
        </div>
      </div>
    </Tippy>
  )
}

export default NotificationsSettings
