import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ButtonVariant, LinkButton } from 'components/Buttons'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'

type ConfirmationPagePropsType = {
  message: string,
  buttonLink?: string,
  buttonText?: string,
}

const ConfirmationPage: FC = () => {
  const {
    location: { state },
  }: { location: { state: ConfirmationPagePropsType } } = useHistory()
  const { message } = state || {}
  const { t } = useTranslation('signIn')

  return (
    <>
      <ChangeLanguageHeader headerText={message} />
      <LinkButton full to="/sign-in" variant={ButtonVariant.Outline}>
        {t('signIn:backToLogin')}
      </LinkButton>
    </>
  )
}

export default ConfirmationPage
