import React, { FC, Ref, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TetherComponent from 'react-tether'
import { useClickAway } from 'react-use'
import classnames from 'classnames'

import ConfirmationModal from 'components/ConfirmationModal'
import { SeeMoreIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'

import { useGridViewMethods } from 'contexts/GridViewContext'
import { archiveRecordById, deleteRecordById, duplicateRecordById, onMoveRecord } from 'features/record'
import { useRoles } from 'hooks'
import { useAppDispatch } from 'store'
import { RecordType } from 'utils/types'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'
import { useDispatch } from 'react-redux'
import MoveModal from '../../../MoveModal'
import { useCurrentStation } from '../../../../features/station'
import { useCurrentList, useLists } from '../../../../features/list'
import ElementOperations from '../../../../utils/constant/enums/element'

interface ModalOptions {
  message?: string;
  onConfirm?: () => void;
  confirmMessage?: string;
  changePageIfNeeded?: () => void;
}

const SettingsCell: FC<CellRenderObject & { data: RecordType }> = ({ data, changePageIfNeeded }) => {
  const { t, i18n } = useTranslation('records')
  const { isAdmin, isEditor } = useRoles()
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const lists = useLists()
  const { onEdit } = useGridViewMethods()
  const currentStation = useCurrentStation()
  const currentList = useCurrentList()
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalOptions, setModalOptions] = useState<ModalOptions>({})

  const isArchivedByParent =
    data?.appElements[0]?.archivedById && +data?.appElements[0]?.archivedById !== +data?.appElements[0]?.id
  const isArchived = data?.appElements[0]?.isArchived
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [shouldRemoveElementAfterMove, setShouldRemoveElementAfterMove] = useState<boolean>(true)
  const menuWrapperRef = useRef<any>()
  const recordId = data.id.includes('/') ? data.id.split('/')[1] : data.id
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  useClickAway(menuWrapperRef, () => {
    setIsMenuOpen(false)
  })

  const onRefetch = () => {
    setShouldRemoveElementAfterMove(true)
  }

  const duplicateRecord = () => {
    dispatch(duplicateRecordById(+recordId))
    setIsModalOpen(false)
  }

  const onDelete = () => {
    appDispatch(deleteRecordById(+recordId)).then(() => {
      if (changePageIfNeeded !== undefined) changePageIfNeeded()
    })
    setIsModalOpen(false)
  }
  const onArchive = () => {
    const recursive = 0 // 1 to unarchive element and all children force 0 to archive element and children but not
    dispatch(archiveRecordById({ elementId: +data?.appElements[0]?.id, recursive, status: Number(!isArchived) }))
    setIsModalOpen(false)
  }

  const showModal = (modalOptions: ModalOptions) => {
    if (modalOptions.message && modalOptions.onConfirm) {
      return (
        <ConfirmationModal
          confirmMessage={modalOptions.confirmMessage}
          confirmationMessage={t(`${modalOptions.message}`, {
            interpolation: { escapeValue: false },
            name: data.name,
          })}
          isModalOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={modalOptions.onConfirm}
        />
      )
    }
  }
  const attachmentSide = useMemo(() => (i18n.dir() === 'ltr' ? 'right' : 'left'), [i18n])

  return (
    <>
      <TetherComponent
        attachment={`top ${attachmentSide}`}
        constraints={[
          {
            attachment: 'together',
            to: 'scrollParent',
          },
        ]}
        renderElement={(ref: any) =>
          isMenuOpen && (
            <div
              className={classnames(
                'z-30 px-6 py-2 text-sm bg-white cursor-default shadow-multi w-44 rounded-2xl',
                i18n.dir() === 'ltr' ? 'rounded-te-none' : 'rounded-ts-none',
              )}
              ref={(divRef: any) => {
                ref.current = divRef
                menuWrapperRef.current = divRef
              }}>
              <div className="text-gray-600">
                <button
                  className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                  onClick={() => {
                    onEdit(+recordId)
                    setIsMenuOpen(false)
                  }}>
                  {t('common:labels.edit')}
                </button>
                {isEditor && (
                  <>
                    {!isArchived && (
                      <button
                        className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                        onClick={() => {
                          const body = {
                            appElementId: Number(data?.appElements[0]?.id),
                            newParentId: null,
                            operation: ElementOperations.Duplicate,
                          }
                          appDispatch(onMoveRecord(body)).then(() => onRefetch())
                          setIsModalOpen(true)
                          setIsMenuOpen(false)
                        }}>
                        {t('records:duplicate')}
                      </button>
                    )}
                    {lists?.length > 1 && !currentList?.appElement?.isArchived && (
                      <>
                        <button
                          className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                          onClick={() => {
                            setIsMoveModalOpen(true)
                          }}>
                          {t('common:labels.move')}
                        </button>
                        <button
                          className="block w-full py-2 text-start hover:text-primary focus:text-primary transition transition-colors"
                          onClick={() => {
                            setIsMoveModalOpen(true)
                            setShouldRemoveElementAfterMove(false)
                          }}>
                          {t('common:labels.copyToOtherList')}
                        </button>
                      </>
                    )}
                    {!isArchivedByParent && (
                      <>
                        <button
                          className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition transition-colors"
                          onClick={() => {
                            setModalOptions({
                              confirmMessage: isArchived
                                ? `${t('common:labels.unarchive')}`
                                : `${t('common:labels.archive')}`,
                              message: isArchived ? 'confirmRecordUnarchive' : 'confirmRecordArchive',
                              onConfirm: onArchive,
                            })
                            setIsModalOpen(true)
                            setIsMenuOpen(false)
                          }}>
                          {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
                        </button>
                        {isAdmin && isArchived && (
                          <button
                            className="block w-full py-2 text-danger text-start hover:text-primary focus:text-primary transition transition-colors"
                            onClick={() => {
                              setModalOptions({
                                message: 'confirmRecordDelete',
                                onConfirm: onDelete,
                              })
                              setIsModalOpen(true)
                              setIsMenuOpen(false)
                            }}>
                            {t('common:labels.remove')}
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )
        }
        renderTarget={(ref: Ref<any>) => (
          <Button icon={SeeMoreIcon} ref={ref} tabIndex={-1} variant={ButtonVariant.Icon} onClick={toggleMenu} />
        )}
        targetAttachment={`bottom ${attachmentSide}`}
      />
      {showModal(modalOptions)}
      <MoveModal
        currentElementId={data?.appElements[0]?.id}
        currentParentId={currentStation?.appElement?.id}
        directParentId={currentList?.appElement?.id}
        directParentLegacyId={currentList?.id}
        elementBaseType={2}
        isMoveModalOpen={isMoveModalOpen}
        setIsMoveModalOpen={setIsMoveModalOpen}
        shouldRemoveElementAfterMove={shouldRemoveElementAfterMove}
        onMoveItem={onMoveRecord}
        onRefetch={onRefetch}
      />
    </>
  )
}

export default SettingsCell
