/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, memo, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { GridViewContextValueType, RecordType } from 'utils/types'
import { fetchRecordsByParams, updateRecordById, updateRecordElementById } from 'features/record'

const GridViewContext = React.createContext<GridViewContextValueType>({
  clearSelectedRecords: () => {},
  handleClickOutsideField: () => () => {},
  handleRecordChange: () => {},
  isClickedOnExtendButton: false,
  onEdit: () => {},
  selectedRecords: [],
  selectRecord: () => {},
})

const GridViewProvider: FC<{ children: React.ReactNode }> = memo(({ children }) => {
  const [isClickedOnExtendButton, setIsClickedOnExtendButton] = useState<boolean>(false)
  const [selectedRecords, setSelectedRecords] = useState<number[]>([])
  const { viewId, listId }: { listId: string, viewId: string } = useParams()

  const { location, push } = useHistory()
  const dispatch = useDispatch()

  const onEdit = useCallback(
    (recordId?: number) => {
      const recordUrl = `${location.pathname}${
        location.search ? `${location.search}&recordId=${recordId}` : `?recordId=${recordId}`
      }`
      push(recordUrl)
    },
    [location.pathname, location.search, push],
  )
  const selectRecord = useCallback(
    (recordId: number) => {
      setSelectedRecords(
        selectedRecords.includes(recordId)
          ? selectedRecords.filter((id) => id !== recordId)
          : [...selectedRecords, recordId],
      )
    },
    [selectedRecords],
  )
  const clearSelectedRecords = useCallback(() => {
    setSelectedRecords([])
  }, [])

  const handleRecordChange = useCallback(
    ({ changes, fullRecord }: { changes: Partial<RecordType>, fullRecord: RecordType }) => {
      const changesKeys = Object.keys(changes)
      const initialValues = pick(fullRecord, changesKeys)
      if (!isEqual(changes, initialValues)) {
        dispatch(
          updateRecordElementById({
            changes,
            elementId: fullRecord?.appElements[0]?.id,
            viewId,
            record: fullRecord,
          }),
        )
      }
    },
    [],
  )

  const handleClickOutsideField = useCallback(
    (extendButtonRef: React.MutableRefObject<HTMLElement | null | undefined>) => (event: any) => {
      const { target } = event
      const isClickedInside = extendButtonRef?.current ? extendButtonRef?.current.contains(target) : false

      if (isClickedInside && !isClickedOnExtendButton) {
        setIsClickedOnExtendButton(true)
      }
      if (!isClickedInside && isClickedOnExtendButton) {
        setIsClickedOnExtendButton(false)
      }
    },
    [isClickedOnExtendButton],
  )

  useEffect(() => {
    dispatch(
      fetchRecordsByParams({
        listId,
        viewId,
      }),
    )
  }, [dispatch, viewId, listId])

  return (
    <GridViewContext.Provider
      value={{
        clearSelectedRecords,
        handleClickOutsideField,
        handleRecordChange,
        isClickedOnExtendButton,
        onEdit,
        selectedRecords,
        selectRecord,
      }}>
      {children}
    </GridViewContext.Provider>
  )
})

GridViewProvider.displayName = 'GridView context'

const useGridViewMethods = () => useContext(GridViewContext)

export { GridViewProvider, useGridViewMethods }
