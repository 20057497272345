import React, { FC, memo, useEffect } from 'react'
import Modal from 'react-modal'
import isEmpty from 'lodash/isEmpty'

import { useSecondaryPanel } from 'contexts/SecondaryPanelContext'
import Button, { ButtonVariant } from 'components/Buttons'
import { CrossSignIcon } from 'components/Icons'
import { useIsRejected } from 'features/common/selectors'

const SecondaryPanel: FC = memo(() => {
  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  const { isPanelOpen, closePanel, panelContent } = useSecondaryPanel()
  const isRejected = useIsRejected()

  useEffect(() => {
    if (isRejected) closePanel()
  }, [isRejected])

  return (
    <Modal
      className="fixed z-50 flex w-full flex-col bg-white shadow-lg focus:outline-none bottom-0 top-0 lg:top-28 lg:bottom-28 rounded max-w-lg left-1/2 transform -translate-x-1/2"
      isOpen={isPanelOpen}
      overlayClassName="bg-gray-800/30 inset-0 fixed z-50"
      onRequestClose={closePanel}>
      <header className="flex items-start justify-between p-6 pb-0">
        {!isEmpty(panelContent) && panelContent?.header && <h1 className="me-auto">{panelContent?.header}</h1>}
        <div className="flex -mt-2 -me-6 sm:-me-4">
          <div id="modal-settings" />
          <Button icon={CrossSignIcon} variant={ButtonVariant.Icon} onClick={closePanel} />
        </div>
      </header>
      {!isEmpty(panelContent) && panelContent?.content && React.cloneElement(panelContent.content, { closePanel })}
    </Modal>
  )
})

SecondaryPanel.displayName = 'Secondary Panel'

export default SecondaryPanel
