import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/inputs'
import { CustomFieldOptionItemType } from '../../../utils/types/CustomFieldsType'
import OptionsList from './OptionsList'

interface CustomFieldOptionsType {
  title?: string;
  addButtonTitle?: string;
  addText?: boolean;
  optionsList: any[];
  setOptionsList: (item: any) => void;
  isSettingsList?: boolean;
  showListError?: boolean;
  showDescription?: boolean;
  isEditMode?: boolean;
  setShowListError?: (item: boolean) => void;
}
const CustomFieldOptions: FC<CustomFieldOptionsType> = ({
  setOptionsList,
  optionsList,
  showListError,
  setShowListError,
  showDescription,
  addButtonTitle,
  title,
  addText,
  isEditMode,
}) => {
  const { t } = useTranslation('customFields')
  const [optionName, setOptionName] = useState<string>('')
  const [showAddInput, setShowAddInput] = useState<boolean>(false)
  const onDeleteOption = (item: any, list: CustomFieldOptionItemType[]) => {
    const newList = list?.filter((i) => i !== item)
    setOptionsList(newList)
  }

  return (
    <>
      {showListError && <div className="mt-2 text-sm ps-2 text-danger">{t('customFields:optionsErrorMessage')}</div>}
      <>
        <div className="flex items-center justify-between my-1">
          <span className="text-sm italic font-extrabold" id="reporter">
            {title || t('labels.customFieldOptions')}
            <span className="ms-1">:</span>
          </span>
        </div>
        {optionsList?.length > 0 && (
          <OptionsList
            addText={addText}
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            showDescription={showDescription}
            onDeleteOption={onDeleteOption}
            isEditMode={isEditMode}
          />
        )}
      </>

      {!showAddInput && (
        <div
          className="mt-2 cursor-pointer text-gray-500 hover:text-primary"
          onClick={() => {
            if (setShowListError) setShowListError(false)
            setShowAddInput(true)
            setOptionName('')
          }}>
          +<span className="m-1 text-sm">{addButtonTitle || t('addOption')}</span>
        </div>
      )}
      {showAddInput && (
        <Input
          autoFocus
          classes={{ wrapper: 'mb-6 mt-3 px-1 text-sm' }}
          name="name"
          placeholder={t('labels.customFieldOptionName')}
          type="text"
          value={optionName}
          onBlur={(event: any) => {
            const optionCaption = optionName
            if (optionCaption.length > 0)
              setOptionsList([
                ...optionsList,
                {
                  isDefault: optionsList.length === 0,
                  optionCaption,
                  optionOrder: optionsList.length,
                },
              ])
            setShowAddInput(false)
          }}
          onChange={(e) => {
            setOptionName(e.target.value)
          }}
        />
      )}
    </>
  )
}

export default CustomFieldOptions
