import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createNewDocumentation, useCurrentStationDocumentation, useCurrentStation } from '../../../features/station'
import Button, { ButtonVariant } from '../../../components/Buttons'
import EmptyStatePage from '../../../components/EmptyStatePage'
import { EmptyStationsIcon, CircledPlusIcon } from '../../../components/Icons'
import { withEditorRole } from '../../../hocs/withRole'

const AddStationButton = withEditorRole(Button)

const StationDocumentationNewDocPage: FC = () => {
  const currentStation = useCurrentStation()
  const currentDoc = useCurrentStationDocumentation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation('documentation')

  const onCreate = () => {
    dispatch(createNewDocumentation(+currentStation?.appElement?.id))
  }

  useEffect(() => {
    if (currentDoc) {
      const newPath = location.pathname.replace('create', `${currentDoc.id}/home`)
      history.push(newPath)
    }
  }, [currentDoc])

  return (
    <div className="flex items-center justify-center h-full min-h-0">
      <EmptyStatePage
        Button={() => (
          <AddStationButton
            data-tut="second__step"
            icon={CircledPlusIcon}
            variant={ButtonVariant.Outline}
            onClick={onCreate}>
            {t(`createDocument`)}
          </AddStationButton>
        )}
        Icon={EmptyStationsIcon}
        description={t('noDocumentDescription')}
        header={t('noDocumentHeader', { interpolation: { escapeValue: false }, name: currentStation?.name })}
      />
    </div>
  )
}

export default StationDocumentationNewDocPage
