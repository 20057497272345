import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedDateWithTime } from 'utils/helpers/generalHelpers'
import { useCurrentListMembers } from '../../../features/user'
import RequestType from '../../../utils/types/RequestType'
import UserAvatarStatus from '../../UserAvatar/UserAvatarStatus'

type RequestDoneStatusPropsType = {
  request: RequestType,
  isHijri: boolean,
  isSimple?: boolean,
}

const RequestDoneStatus: FC<RequestDoneStatusPropsType> = ({ request, isHijri, isSimple = false }) => {
  const { t, i18n } = useTranslation('records')
  const users = useCurrentListMembers()
  const reviewer = users?.find((u) => +u?.id === +request?.reviewedBy)
  const reviewerName = `${reviewer?.firstName} ${reviewer?.lastName}`
  const comments = request?.appElement?.record?.appElements[0]?.comments
  const comment = comments?.find((c) => c?.inResponseTo == 1 && c?.responseTargetId == request.id)
  const date = getFormattedDateWithTime(request.reviewedAt, isHijri, i18n.language)
  const requestComment = comment && comment?.text?.length > 0 ? comment.text : t('noCommentAdded')

  if (isSimple) {
    return (
      <div className="p-2 text-sm bg-gray-100 rounded">
        <div className="flex items-center justify-between">
          <div>
            <span className="font-bold me-1">{t('listing.status')}:</span>{' '}
            <span className={`${request.status === 2 ? 'text-primary' : 'text-danger'}`}>
              {request.status === 2 ? t('accepted') : t('rejected')}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="p-2 text-sm bg-gray-100 rounded">
      <div className="flex items-center justify-between">
        <div>
          <span className="font-bold me-1">{t('listing.status')}:</span>{' '}
          <span className={`${request.status === 2 ? 'text-primary' : 'text-danger'}`}>
            {request.status === 2 ? t('accepted') : t('rejected')}
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-bold me-1">{t('listing.by')}:</span>{' '}
          <div className="mt-1">
            <UserAvatarStatus customSize={7} imgSrc={reviewer.link} userId={reviewer.userId} />
          </div>
          <span className="ms-1">{reviewerName}</span>
        </div>
        <div className="text-xs">{date}</div>
      </div>
      <div className="flex mt-1">
        <span className="font-bold me-1">{t('listing.comment')}:</span>{' '}
        <div dangerouslySetInnerHTML={{ __html: requestComment }} />
      </div>
    </div>
  )
}
export default RequestDoneStatus
