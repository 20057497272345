import { useSelector } from 'react-redux'
import { getAttributeFromActivity } from 'utils/constant/constant/common'
import { getFieldVisibility } from 'utils/helpers/customFields'

import { ActivityLogType } from 'utils/types'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { AppElementViewAttribute } from 'utils/types/AppElementViewType'
import StateType from 'utils/types/states'

export const useActivities = (options: {
  resourceType: number,
  resourceId: number,
  viewAttributes?: AppElementViewAttribute[],
  defaultAttributeVisibility?: AppElementAttributeVisibility,
}): [] | ActivityLogType[] | undefined => {
  const { resourceType, resourceId, viewAttributes, defaultAttributeVisibility } = options
  return useSelector((state: StateType) =>
    state.activity?.activities?.filter((activity: ActivityLogType) => {
      let isVisible = true
      if (
        activity?.objectData &&
        activity.objectData[0]?.key &&
        viewAttributes &&
        defaultAttributeVisibility !== undefined
      ) {
        const attributeInfo = getAttributeFromActivity({ activity })
        if (attributeInfo?.attributeId !== undefined && attributeInfo?.attributeType !== undefined) {
          const visibility = getFieldVisibility({
            attributeId: attributeInfo.attributeId,
            attributeType: attributeInfo.attributeType,
            viewAttributes,
            defaultAttributeVisibility,
          })

          isVisible = visibility > AppElementAttributeVisibility.Hidden
        }
      }
      return (
        ((+activity.relatedObjectId === resourceId && resourceType === +activity.relatedObjectType) ||
          +activity.relatedObjectType === 5) &&
        isVisible
      )
    }),
  )
}

const selectActivitiesLoading = (state: StateType) => state.activity.loading
export const useActivitiesLoading: () => boolean = () => useSelector(selectActivitiesLoading)
