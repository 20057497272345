import DocumentationPagesHeader from 'components/Documentation/DocumentationPagesComponents/DocumentationPagesHeader'
import DocumetationSidebar from 'components/Documentation/DocumentationSidebar/DocumetationSidebar'
import DocumentationDocumentView from 'components/Documentation/DocumetationsDocument/DocumentationDocumentView'
import React, { FC, memo } from 'react'

const StationDocumentationSinglePageView: FC<{ isPublic?: boolean }> = ({ isPublic }) => {
  return (
    <div className="flex min-h-0 h-full">
      <DocumetationSidebar isPublic={isPublic} />
      <div className="w-full bg-white">
        <DocumentationPagesHeader isPublic={isPublic} type="view" />
        <DocumentationDocumentView isPublic={isPublic} />
      </div>
    </div>
  )
}

export default memo(StationDocumentationSinglePageView)
