import { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'
import DOMPurify from 'dompurify'
import { RecordType } from 'utils/types'

const DescriptionCell: FC<CellRenderObject & { data: RecordType, value: RecordType['description'] }> = (props) => {
  const value = props?.data?.appElements[0].description || ''
  return <div className="absolute rte-output" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
}
export default DescriptionCell
