import * as yup from 'yup'
import cloneDeep from 'lodash/cloneDeep'

const FormulaParser = require('hot-formula-parser').Parser

const parser = new FormulaParser()

const validateFormula = (text: string): boolean => {
  try {
    const result: any = parser.parse(text)
    return result.error === null
  } catch (e) {
    return false
  }
}
const reBrackets = /{([^}]+)}/g

export const formulaValidationSchema = (customFormula: string) => {
  let formulaString = cloneDeep(customFormula)
  const customFieldList = []
  let found
  while ((found = reBrackets.exec(formulaString))) {
    customFieldList.push(found[1])
  }
  customFieldList.forEach((text) => {
    formulaString = formulaString.replace('{' + text + '}', '1')
  })

  return validateFormula(formulaString)
}

export const formulaNameValidationSchema = yup.string().trim().max(250).required()
