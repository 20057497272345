import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SeeMoreIcon } from 'components/Icons'
import RecordFilters from 'components/RecordFilters'

const WidgetSettings: FC = () => {
  const { i18n } = useTranslation()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const isRtl = i18n.language === 'ar'

  return (
    <div>
      <SeeMoreIcon className="w-5 h-5" onClick={() => setShowFilter(!showFilter)} />
      {showFilter && (
        <div
          className={`absolute ${
            isRtl ? 'left-10' : 'right-10'
          } top-5 z-30 px-6 py-2 -ml-12 text-sm bg-white cursor-default -me-12 shadow-multi w-44 rounded-ts-none rounded-2xl`}>
          <RecordFilters isCustomWidget={false} showBasics={true} onClose={() => setShowFilter(false)} />
        </div>
      )}
    </div>
  )
}

export default WidgetSettings
