import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { TagInput, ElementType, Dropdown } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'

import { availableGroups } from 'utils/helpers/generalHelpers'
import { toast } from 'react-toastify'
import RolesEnum from 'utils/constant/enums'
import { emailValidationSchema } from './validationSchemas/singleFieldsValidationSchemas'

type AddUserToWorkspaceFormPropsType = {
  sendInvitations: (allEmails: ElementType[], role: number) => void,
  closeModal: () => void,
  isInPanel?: boolean,
}

const AddUserToWorkspaceForm: FC<AddUserToWorkspaceFormPropsType> = ({ sendInvitations, closeModal, isInPanel }) => {
  const [allEmails, setAllEmails] = useState<ElementType[]>([])
  const [isValid, setIsValid] = useState<boolean>(true)
  const [role, setRole] = useState<number>(RolesEnum.Admin)
  const { t } = useTranslation('workspace')
  const allRoles = availableGroups()
  const currentRole = useMemo(() => allRoles.find(({ value }) => +value === role)?.value, [role, allRoles])

  const saveEmailInCorrectFormat = (emailList: ElementType[]) => {
    const [newestEmail] = emailList.splice(-1, 1)
    if (newestEmail !== undefined) {
      setAllEmails([...emailList, { element: newestEmail?.element?.toLowerCase(), isValid: newestEmail?.isValid }])
    } else {
      setAllEmails([])
    }
  }

  return (
    <div className="w-full mt-5 text-sm">
      <div className="mb-2">
        <h3 className="font-bold leading-md">{t('addUsers')}</h3>
        <p className="text-xs">{t('emailsInputInstruction')}</p>
      </div>
      <div className={classnames('lg:flex', { 'flex-col': isInPanel })}>
        <TagInput
          className="flex-1 mb-2 lg:me-2 lg:mb-0"
          delimiter=","
          placeholder={t('emailsInputPlaceholder')}
          selectedElements={allEmails}
          setIsValid={setIsValid}
          setSelectedElements={saveEmailInCorrectFormat}
          validationSchema={emailValidationSchema}
        />
        <Dropdown
          className={classnames({ 'lg:w-36': !isInPanel, 'w-full lg:me-2': isInPanel })}
          classes={{ wrapper: classnames({ 'me-2 mt-2': isInPanel }) }}
          name="role"
          options={allRoles}
          value={currentRole}
          onChange={(event: { target: { value: string } }) => setRole(+event.target.value)}
        />
      </div>
      <div className="flex justify-end mt-4">
        <Button small variant={ButtonVariant.Ghost} onClick={closeModal}>
          {t('common:labels.cancel')}
        </Button>
        <Button
          className="ms-4"
          small
          onClick={() => {
            if (!isValid) {
              return toast.error(t('validation:errors.invalidEmail'), { icon: false })
            } else {
              sendInvitations(allEmails, role)
            }
          }}>
          {t('sendInvites')}
        </Button>
      </div>
    </div>
  )
}

export default AddUserToWorkspaceForm
