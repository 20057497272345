import { AddWithoutBorderIcon, ArchiveIcon, HouseIcon, MohimmatechLogoText, TranslateIcon } from 'components/Icons'
import {
  createDocumentationNewPage,
  getStationDocSidebar,
  updateDocumentationDocument,
  updateDocumentationPageOrder,
  useCurrentOpenItems,
  useCurrentStation,
  useCurrentStationArchiveStatus,
  useDocumentationSidebarAllItems,
  useStationDocumentationSidebar,
  useStationDocumentationSidebarHidden,
  useStationDocumentationSidebarLoading,
} from 'features/station'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  currentOpenSidebarItems,
  SetCurrentStationArchiveStatus,
  updateDocSidebarHiddenElements,
  updateDocumentationDocumentTitle,
  updateDocumentationSidebar,
} from 'features/station/stationSlice'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useDispatch } from 'react-redux'
import { useDebounce, useRafState } from 'react-use'
import { Virtuoso as List } from 'react-virtuoso'
import { useTranslation } from 'react-i18next'
import useViewerPermission from 'hooks/useViewerPermission'
import {
  usePublicDocsSidebar,
  usePublicDocsSidebarHiddenElements,
  usePublicDocsSidebarLoading,
} from 'features/publicDocs/selectors'
import { changeUserLanguage } from 'utils/helpers/profileHelpers'
import { getPublicDocsSidebarData } from 'features/publicDocs/actions'
import { updatePublicDocSidebarHiddenElements } from 'features/publicDocs/publicDocsSlice'
import { useAppDispatch } from 'store'
import classNames from 'classnames'
import { findAllChildren, findAllParentsAndSiblings } from './functions/findRelatives'
import SidebarContentLoader from './SidebarContentLoader'
import SidebarItem from './SidebarItem'

let isMobile = false
// device detection
if (
  //eslint-disable-next-line
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    navigator.userAgent,
  ) ||
  //eslint-disable-next-line
  /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
    navigator.userAgent.substr(0, 4),
  )
) {
  isMobile = true
}

// This is the container of sidebar items
const SidebarContent: FC<{ isPublic?: boolean }> = ({ isPublic = false }) => {
  const [selectedPage, setSelectedPage] = useRafState(-1)
  const cards = useStationDocumentationSidebar()
  const publicCards = usePublicDocsSidebar()
  const allCards = useDocumentationSidebarAllItems()
  const loading = useStationDocumentationSidebarLoading()
  const publicCardsLoading = usePublicDocsSidebarLoading()
  const hiddenElements = useStationDocumentationSidebarHidden()
  const openSidebarItems = useCurrentOpenItems()
  const [hovered, setHovered] = useRafState(-2)
  const [hoveredParentId, setHoveredParentId] = useRafState(-2)
  const [extraFakes, setExtraFakes] = useRafState<number[]>([])
  const currentStation = useCurrentStation()
  const [orders, setOrder] = useRafState<any>(null)
  const { workspaceId, stationId, docId, id, publicId } = useParams<{
    workspaceId: string,
    stationId: string,
    docId: string,
    id: string,
    publicId: string,
  }>()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const { t } = useTranslation()
  const allowUserAccess = useViewerPermission()
  const publicHiddenElements = usePublicDocsSidebarHiddenElements()
  const history = useHistory()
  const [isMovingDocuments, setMovingDocuments] = useState(false)
  const firstChildrenIds: number[] = []
  const isArchivedPage = window.location.pathname.includes('archived')
  const linkToDoc = isPublic
    ? `/public-docs/${publicId}`
    : `/workspace/${workspaceId}/stations/${stationId}/documentation/${docId}`

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (openSidebarItems.length > 0 && cards) {
      const children: any[] = []
      let newList = [...cards]
      openSidebarItems.map((item: any) => {
        const allChildrenList = allCards.filter((i: any) => +i.parentId === +item.id)
        children.push(...item.children)
        const itemIndex = newList.findIndex((i) => +i.id === +item.id)
        newList = newList.filter((i) => !item.children.includes(i.id))
        newList.splice(itemIndex + 1, 0, ...allChildrenList)
      })
      dispatch(updateDocumentationSidebar(newList))
      dispatch(updateDocSidebarHiddenElements(hiddenElements.filter((i) => !children.includes(i))))
    }
  }, [openSidebarItems])

  // get sidebar data
  useEffect(() => {
    if (!cards && !isPublic && docId) {
      dispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId }))
    }

    if (isPublic && !publicCards) {
      dispatch(getPublicDocsSidebarData(publicId))
    }
  }, [cards, publicCards, isPublic, docId, id])

  // set selected page(current page that opened now) and determine items to hide in sidebar
  useEffect(() => {
    if (cards && parseInt(id) !== selectedPage && !isPublic) {
      setSelectedPage(parseInt(id))
      const allParentsAndSiblings = findAllParentsAndSiblings(cards, parseInt(id))
      dispatch(updateDocSidebarHiddenElements(hiddenElements.filter((i) => !allParentsAndSiblings.includes(i))))
    }

    if (isPublic && publicCards) {
      setSelectedPage(parseInt(id))
      const allParentsAndSiblings = findAllParentsAndSiblings(publicCards, parseInt(id))
      dispatch(
        updatePublicDocSidebarHiddenElements(publicHiddenElements.filter((i) => !allParentsAndSiblings.includes(i))),
      )
    }
  }, [id, cards, isPublic, publicCards])

  const setHoveredItem = (hoverIndex?: number, parentId?: number) => {
    if (!hoverIndex || !parentId) {
      setHovered(-2)
      setHoveredParentId(-2)
    } else {
      setHovered(() => hoverIndex)
      setHoveredParentId(() => parentId)
    }
  }

  const [, cancel] = useDebounce(
    () => {
      if (cards && hovered >= 0) {
        const hoverCard = cards[hovered]
        if (hoverCard) {
          dispatch(updateDocSidebarHiddenElements(hiddenElements.filter((i) => !hoverCard.children?.includes(i))))
        }
      }
    },
    500,
    [hovered, cards],
  )

  // This callback fires when you move sidebar item
  const moveCard = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      isFake: any,
      hoverLevel: any,
      hoverId: any,
      parentId: number,
      itemId: number | string,
      itemIndex: number,
      hoverParentId: number,
    ) => {
      setHoveredItem(hoverIndex, parentId)
      // You will probably will need to rewrite logic here according to new requirements
      if (dragIndex === hoverIndex) {
        setOrder(() => null)
        return
      } else {
        setOrder(() => ({
          dragOrder: dragIndex,
          hoverId,
          hoverOrder: hoverIndex,
          hoverParentId,
          id: itemId,
          itemIndex,
          parentId,
        }))
      }
    },
    [cards, hiddenElements],
  )

  const updateTitle = useCallback((id: number, val: string) => {
    dispatch(updateDocumentationDocument({ docPage: { title: val }, id: +id }))
    dispatch(updateDocumentationDocumentTitle({ id, title: val }))
  }, [])

  const onOpenDocFolder = useCallback(
    (item: any) => {
      if (item && item.children) {
        if (cards && !isPublic) {
          const allChildrenIds = findAllChildren(cards, item.children || [])
          const allChildrenList = allCards.filter((i: any) => +i.parentId === +item.id)
          const itemIndex = cards.findIndex((i) => +i.id === +item.id)
          const newList = cards.filter((i) => !allChildrenIds.includes(i.id))
          newList.splice(itemIndex + 1, 0, ...allChildrenList)
          dispatch(updateDocumentationSidebar(newList))
          const nextCard = cards[item.order + allChildrenIds.length + 1]
          // const firstHoverChild = item.children.includes(cards[item.order + 1].id) ? cards[item.order + 1].id : null
          const firstHoverChild = item.children[0]

          if (hiddenElements.filter((i) => item.children.includes(i)).length) {
            if (nextCard) {
              if (firstHoverChild) {
                setExtraFakes([...extraFakes, nextCard.id, firstHoverChild])
              }
            } else {
              if (firstHoverChild) {
                setExtraFakes([...extraFakes, firstHoverChild])
              }
            }
            dispatch(updateDocSidebarHiddenElements(hiddenElements.filter((i) => !item.children.includes(i))))
          } else {
            if (nextCard) {
              setExtraFakes(extraFakes.filter((i) => nextCard.id !== i))
            }
            dispatch(updateDocSidebarHiddenElements([...hiddenElements, ...allChildrenIds]))
          }
        }

        if (isPublic && publicCards) {
          const allChildren = findAllChildren(publicCards, item.children || [])
          if (publicHiddenElements.filter((i) => item.children.includes(i)).length) {
            dispatch(
              updatePublicDocSidebarHiddenElements(publicHiddenElements.filter((i) => !item.children.includes(i))),
            )
          } else {
            dispatch(updatePublicDocSidebarHiddenElements([...publicHiddenElements, ...allChildren]))
          }
        }
      }
    },
    [hiddenElements, cards, isPublic, publicHiddenElements, openSidebarItems],
  )

  // This is drop callback. One of the main functions, this is will need to be rewritten mostly according to new requirements
  const handleDrop = async () => {
    setMovingDocuments(() => true)
    setHoveredItem()
    if (!orders) {
      setMovingDocuments(() => false)
      return
    }
    const newParentId = Math.floor(orders.hoverOrder) === orders.hoverOrder ? +orders.hoverId : +orders.parentId
    const newOrderData = {
      newParentId,
    }
    const newPosition =
      orders.hoverOrder < orders.dragOrder || orders.hoverParentId !== orders.parentId
        ? Math.ceil(orders.hoverOrder)
        : Math.floor(orders.hoverOrder)
    if (Math.floor(orders.hoverOrder) !== orders.hoverOrder) {
      newOrderData.newPosition = newPosition
    }
    const newData = await appDispatch(updateDocumentationPageOrder({ id: +orders.id, newOrderData }))
    const parentsPath = newData?.payload?.appElementData?.elementPath?.split('.').slice(3, -2)
    const newStationDocData = await appDispatch(getStationDocSidebar({ archiveStatus: 0, docId: +docId }))
    if (parentsPath) {
      const parentsItems = newStationDocData.payload.filter((item: any) => parentsPath.includes(item.id))
      dispatch(currentOpenSidebarItems(parentsItems))
    }
    setOrder(() => null)
    setMovingDocuments(() => false)
  }

  if ((!isPublic && loading && !isMovingDocuments) || (isPublic && publicCardsLoading && !isMovingDocuments)) {
    return <SidebarContentLoader />
  }
  const cardsOrPublic = isPublic ? publicCards : cards
  const publicOrPrivateHiddenElements = isPublic ? publicHiddenElements : hiddenElements

  const createNewPage = () => {
    const createProps = {
      parentId: +docId,
      title: t('documentation:defaultTitle'),
    }
    appDispatch(createDocumentationNewPage(createProps)).then((data) => {
      history.push(`${linkToDoc}/edit/${+data.payload.id}`)
    })
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col pb-5 mt-2 mb-5 border-b gap-2">
        {isMovingDocuments && (
          <div
            className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-white opacity-50 width-full height-full"
            onClick={() => null}
          />
        )}
        {isPublic && (
          <>
            <Link className="mx-2" style={{ maxWidth: 170 }} to="/">
              <MohimmatechLogoText />
            </Link>
            <button
              className="flex items-center px-2 pt-3 pb-5 mb-3 border-b transition transition-colors hover:text-primary"
              onClick={changeUserLanguage}>
              <TranslateIcon className="w-6 -my-1 rounded-sm me-2" />
              {t('labels.switchLanguage')}
            </button>
          </>
        )}

        <Link
          className="flex items-center px-2 py-1 text-gray-600 rounded gap-2 hover:bg-primary-extraLight"
          to={`${linkToDoc}/home`}>
          <div
            onClick={() => {
              history.push(`${linkToDoc}/home`)
            }}>
            <span className="inline-block">
              <HouseIcon className="w-4 mx-1" />
            </span>
            <span className="inline-block mt-1 ms-2">{t('documentation:home')}</span>
          </div>
        </Link>
        {/*<Link*/}
        {/*  className="flex items-center px-2 py-1 text-gray-600 rounded hover:bg-primary-extraLight gap-2"*/}
        {/*  to={`${linkToDoc}/search`}>*/}
        {/*  <span className="inline-block">*/}
        {/*    <SearchIcon />*/}
        {/*  </span>*/}
        {/*  <span className="inline-block mt-1">{t('documentation:search')}</span>*/}
        {/*</Link>*/}
        {allowUserAccess && (
          <div
            className="flex items-center px-2 text-gray-600 rounded cursor-pointer hover:bg-primary-extraLight gap-1 py-1.5 -mt-0.5"
            onClick={createNewPage}>
            <span className="inline-block ms-0.5" style={{ width: 18 }}>
              <AddWithoutBorderIcon />
            </span>
            <span className="inline-block ms-1">{t('documentation:newDocument')}</span>
          </div>
        )}
        <div
          className={classNames(
            `flex items-center px-2 text-gray-600 rounded cursor-pointer hover:bg-primary-extraLight gap-1 py-1.5 -mt-0.5`,
            { 'bg-tertiary-light/5 text-tertiary-light': isArchivedPage },
          )}
          onClick={() => history.push(`${linkToDoc}/archived`)}>
          <span className="inline-block ms-0.5" style={{ width: 18 }}>
            <ArchiveIcon />
          </span>
          <span className="inline-block ms-1">{t('documentation:archivedPages')}</span>
        </div>
      </div>

      {cardsOrPublic && (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <div className="h-full" onDrop={handleDrop} onTouchEnd={handleDrop}>
            <List
              data={cardsOrPublic}
              itemContent={(index, card) => {
                if (card.children && card.children.length > 0) {
                  firstChildrenIds.push(card.children[0])
                }
                return (
                  <SidebarItem
                    key={card.id}
                    allowAccess={isPublic ? false : allowUserAccess}
                    disableHover={() => setHoveredItem()}
                    dragChildren={publicOrPrivateHiddenElements}
                    extraFakes={extraFakes}
                    hovered={hovered}
                    hoveredParentId={hoveredParentId}
                    index={index}
                    isPublic={isPublic}
                    item={card}
                    moveCard={moveCard}
                    selectedPage={selectedPage}
                    shouldHaveExtraFake={firstChildrenIds.includes(card.id)}
                    updateTitle={updateTitle}
                    onOpen={onOpenDocFolder}
                  />
                )
              }}
              style={{ height: '100%' }}
            />
          </div>
        </DndProvider>
      )}
    </div>
  )
}

export default memo(SidebarContent)
