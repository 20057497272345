import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ResetPasswordForm } from 'components/forms'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation('signIn')
  return (
    <>
      <ChangeLanguageHeader headerText={t('resetPassword')} />
      <ResetPasswordForm />
    </>
  )
}

export default ResetPasswordPage
