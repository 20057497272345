import React, { FC } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addWorkspace } from 'features/workspace'
import { Input, MultilineInputWithMentions } from 'components/inputs'
import Button, { ButtonVariant } from 'components/Buttons'
import PanelFormWrapper from 'components/PanelFormWrapper'

import { addWorkspaceValidationSchema } from './validationSchemas'

type AddWorkspaceFormPropsType = { closePanel?: () => void }

const AddWorkspaceForm: FC<AddWorkspaceFormPropsType> = ({ closePanel }) => {
  const { t } = useTranslation('workspace')
  const dispatch = useDispatch()
  const { errors, handleBlur, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues: {
      description: '',
      name: '',
    },
    onSubmit: (values) => {
      const body = {
        ...values,
        accessLevel: 1,
        baseElementType: 2,
        avatar: 'minim',
        // avatarBackgroundColor: '#eaeaea',
        // avatarForegroundColor: '#000000',
        avatarType: 0,
        color: '#00B2A9',
        elementName: values.name,
        enabledNotifications: true,
        tags: 'et irure pariatur',
        useAvatar: false,
      }
      dispatch(addWorkspace(body))
      closePanel && closePanel()
    },
    validationSchema: addWorkspaceValidationSchema,
  })

  const buttons = (
    <>
      <Button
        className="me-4"
        type="button"
        variant={ButtonVariant.Ghost}
        onClick={() => {
          closePanel && closePanel()
        }}>
        {t('common:labels.cancel')}
      </Button>
      <Button type="submit">{t('addWorkspace')}</Button>
    </>
  )

  return (
    <PanelFormWrapper buttons={buttons} hideWatchButton onSubmit={handleSubmit}>
      <Input
        autoFocus
        error={errors.name}
        label={t('common:labels.name')}
        name="name"
        required
        touched={touched.name}
        value={values.name}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <MultilineInputWithMentions
        error={errors.description}
        label={t('common:labels.description')}
        name="description"
        rows={5}
        touched={touched.description}
        value={values.description}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </PanelFormWrapper>
  )
}

export default AddWorkspaceForm
