import React, { FC, memo, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { usePanel } from 'contexts/PanelContext'
import { appElementDefaultAttributes } from 'utils/constant/enums/Aggregate'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import Button, { ButtonVariant } from 'components/Buttons'
import { CrossSignIcon } from 'components/Icons'
import { useIsRejected } from 'features/common/selectors'
import { matchPath, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { requiredTextFieldValidationSchema } from '../forms/validationSchemas/singleFieldsValidationSchemas'
import { withContentEditable } from '../../hocs'
import { Input } from '../inputs'
import { RecordType } from '../../utils/types'
import { updateRecordElementById, useCurrentRecord, useCurrentRecordDetails } from '../../features/record'
import { useAppDispatch } from '../../store'
import { useActiveListView, useCurrentListViews } from '../../features/list'
import { useRefetchFunction } from '../../features/element'

const EditableInputWithButtons = withContentEditable(Input)

const Panel: FC = memo(() => {
  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])
  const { setPanelHeader, isPanelOpen, closePanel, panelContent } = usePanel()
  const isRejected = useIsRejected()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const detailedRecord = useCurrentRecordDetails()
  const refetchFunction = useRefetchFunction()
  const record = useCurrentRecord()
  const listViews = useCurrentListViews()
  const view = useActiveListView()
  const nameRef = useRef(null)
  const history = useHistory()
  const currentRecord = record || detailedRecord
  const [title, setTitle] = useState(currentRecord?.name)
  const match = matchPath(history.location.pathname, {
    path: '/workspace/:workspaceId/stations/:stationId/lists/:listId/:viewId',
  })
  const viewId = match?.params?.viewId
  const nameAttribute = detailedRecord?.resultAttributes?.find(
    (recordAttr) => recordAttr.attributeId === Number(appElementDefaultAttributes.name),
  )
  const currentView = view ? view : listViews.find((v) => +v.id === +viewId)
  const attributeVisibility =
    detailedRecord && nameAttribute
      ? nameAttribute.attributeVisibility
      : currentView?.appElementViewAttributes?.find(
          (attribute) => attribute.attributeId == appElementDefaultAttributes.name,
        )?.attributeVisibility

  const handleUpdateRecord = (values: Partial<RecordType>) => {
    if (currentRecord?.id && currentRecord.name !== values.name) {
      appDispatch(
        updateRecordElementById({
          changes: { ...values, ...values?.changes },
          elementId: currentRecord?.appElements[0]?.id,
          record: currentRecord,
          viewId: viewId,
        }),
      ).then(() => {
        console.log(refetchFunction, detailedRecord, 'xxxdone')
        if (detailedRecord?.list) {
          dispatch(refetchFunction())
        }
      })
    }
  }

  useEffect(() => {
    if (currentRecord && currentRecord?.name) setTitle(currentRecord.name)
  }, [currentRecord])

  useEffect(() => {
    if (isRejected) closePanel()
  }, [isRejected])

  return (
    <Modal
      className={classnames('fixed z-40 flex w-full flex-col bg-white shadow-lg focus:outline-none', {
        'bottom-0 top-0 lg:top-20 lg:bottom-20 rounded rounded-ts-none max-w-5xl left-1/2 transform -translate-x-1/2':
          panelContent?.isBig,
        'max-w-md top-0 h-full end-0': !panelContent?.isBig,
      })}
      isOpen={isPanelOpen}
      overlayClassName="bg-gray-600/10 inset-0 fixed z-40"
      onRequestClose={closePanel}>
      <header className="flex flex-wrap justify-between w-full p-6 pb-0 sm:flex-nowrap">
        <div className="flex items-start w-full">
          {!isEmpty(panelContent) && !isEmpty(currentRecord) && panelContent?.header && (
            <div className="flex flex-col justify-between w-full me-2">
              <EditableInputWithButtons
                canSaveOnClickOutside
                data-testid="name-input"
                disabled={
                  attributeVisibility !== undefined &&
                  Number(attributeVisibility) === AppElementAttributeVisibility.ReadOnly
                }
                inputWrapperClassName="me-1 w-full"
                isLabelVisible
                isOnClickDisabled={!panelContent.isRecordEditable}
                name="name"
                notEditingClassName="font-bold text-xl"
                ref={nameRef}
                shouldSubmitOnBlur
                validationSchema={requiredTextFieldValidationSchema('name', 150)}
                value={title}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    nameRef?.current?.submitField()
                  }
                }}
                onSubmit={(values) => {
                  if (values.name) setPanelHeader(`${values.name}`)
                  handleUpdateRecord(values)
                  setTitle(values.name)
                  nameRef?.current?.hideButtons()
                }}
              />
            </div>
          )}
          <Button
            className="block -mt-2 sm:hidden"
            icon={CrossSignIcon}
            variant={ButtonVariant.Icon}
            onClick={closePanel}
          />
        </div>
        <div className="flex -me-6 sm:-me-4 sm:-mt-2">
          <div id="modal-settings" />
          <Button className="hidden sm:block" icon={CrossSignIcon} variant={ButtonVariant.Icon} onClick={closePanel} />
        </div>
      </header>
      {!isEmpty(panelContent) && panelContent?.content && React.cloneElement(panelContent.content, { closePanel })}
    </Modal>
  )
})

Panel.displayName = 'Panel'

export default Panel
