export enum AppElementAttributeType {
    // default attribute
    // eslint-disable-next-line prettier/prettier
    defaultAttribute = 0,

    // custom field
    customField,

    reviewer,
    // module (e.g. dashboard module)
    module,

    // module part (e.g. widget of a dashboard module)
    modulePart
}
export default AppElementAttributeType
