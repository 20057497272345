import React, { FC } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import ReactCropperElement from 'react-cropper'
import ContextMenu from '../ContextMenu'

interface CustomFieldItemInfoType {
  field: CustomFieldType;
  options: { label: string, Icon: ReactCropperElement };
  setIsEdit: (value: boolean) => void;
  setIsDelete: (value: boolean) => void;
  showDelete?: boolean;
  isViewer?: boolean;
}

const CustomFieldRow: FC<CustomFieldItemInfoType> = ({
  field,
  options,
  setIsEdit,
  setIsDelete,
  showDelete = true,
  isViewer,
}) => {
  const { t } = useTranslation('customFields')
  const tableRowClasses =
    'flex justify-between border-b border-gray-200 items-center w-full group transition transition-colors hover:bg-gray-200'
  const tableCellClasses = 'text-sm font-bold leading-6 transition transition-colors'
  const { label, Icon } = options

  return (
    <>
      <div className={tableRowClasses}>
        <div className={classnames('p-2 w-1/2', tableCellClasses)}>
          <p className="flex items-center p-2 rounded text-start leading-4">{field?.fieldName}</p>
        </div>
        <div className={classnames(`flex w-2/5 items-center leading-4 px-3 py-4 text-start text-sm`, tableCellClasses)}>
          <Icon className={`w-4 me-2 ms-2`} />
          {label}
        </div>
        {!isViewer && (
          <div className={classnames('px-3 py-4 end-0 rounded-te-none', tableCellClasses)}>
            <ContextMenu
              isCustomButton
              isDefaultPopup
              isSettingsList
              render={({ closeMenu }) => (
                <div className="flex flex-col items-start p-2 text-gray-600">
                  <button
                    className="mb-2 hover:text-primary"
                    type="button"
                    onClick={() => {
                      setIsEdit(true)
                      closeMenu()
                    }}>
                    {t('common:labels.edit')}
                  </button>
                  {showDelete && (
                    <button
                      className="text-danger hover:text-primary "
                      type="button"
                      onClick={() => {
                        setIsDelete(true)
                        closeMenu()
                      }}>
                      {t('common:labels.remove')}
                    </button>
                  )}
                </div>
              )}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CustomFieldRow
