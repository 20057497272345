import React, { FC, Ref, useCallback, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useClickAway, useToggle } from 'react-use'
import { useTranslation } from 'react-i18next'
import TetherComponent from 'react-tether'

import { SeeMoreIcon } from 'components/Icons'
import throttle from 'lodash/throttle'

type SettingsDropdownPropsType = {
  iconClasses?: string,
  childClasses?: string,
  ButtonChildren?: FC,
  Icon?: FC<React.SVGAttributes<SVGElement>>,
  children?: React.ReactChildren | React.ReactChild,
  wrapperClasses?: string,
  toTop?: boolean,
  isCustomWidget?: boolean,
  isClickDisabled?: boolean,
  showDefaultIconWidth?: boolean,
  onClose?: () => void,
}

const SettingsDropdown: FC<SettingsDropdownPropsType> = ({
  children,
  childClasses = 'w-44 rounded-ts-none',
  Icon = SeeMoreIcon,
  ButtonChildren,
  iconClasses = 'hover:text-primary',
  showDefaultIconWidth = true,
  wrapperClasses = '',
  isClickDisabled = false,
  toTop = false,
  isCustomWidget,
  onClose,
}) => {
  const [areSettingsVisible, toggleSettings] = useToggle(false)
  const { i18n } = useTranslation()
  const menuWrapperRef = useRef<any>()
  const isMobile = window.innerWidth <= 760

  const throttledToggleSettings = useCallback(
    throttle(
      (value?: boolean) => {
        toggleSettings(value)
        if (onClose && value === false) {
          onClose()
        }
      },
      200,
      { trailing: false },
    ),
    [toggleSettings, onClose],
  )

  const closeDropdown = () => throttledToggleSettings(false)

  useClickAway(menuWrapperRef, closeDropdown)
  const attachmentSide = useMemo(() => (i18n.dir() === 'ltr' ? 'left' : 'right'), [i18n])
  const iconClassName = classnames('transition-colors', iconClasses, { 'w-6': showDefaultIconWidth })
  return (
    <TetherComponent
      attachment={`top ${attachmentSide}`}
      className={isCustomWidget && isMobile ? 'left-9' : ''}
      constraints={[
        {
          attachment: 'together',
          pin: true,
          to: 'scrollParent',
        },
      ]}
      renderElement={(ref: any) =>
        areSettingsVisible && (
          <div
            className={classnames(
              'z-30 text-sm bg-white cursor-default shadow-multi w-44 rounded-2xl py-2 px-6 left-20 max-h-96 overflow-y-auto overflow-x-hidden',
              toTop ? 'bottom-full' : 'top-full',
              childClasses,
            )}
            ref={(divRef: any) => {
              ref.current = divRef
              menuWrapperRef.current = divRef
            }}>
            {children &&
              React.Children.map(children, (child, i) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, { closeDropdown })
                }
              })}
          </div>
        )
      }
      renderTarget={(ref: Ref<any>) => (
        <div className={wrapperClasses} ref={ref}>
          <button
            className="block transition-shadow hover:text-gray-400 focus:outline-none focus-visible:shadow-focus"
            tabIndex={-1}
            type="button"
            onClick={() => {
              if (isClickDisabled) return null
              throttledToggleSettings()
            }}>
            {ButtonChildren ? <ButtonChildren /> : <Icon className={iconClassName} />}
          </button>
        </div>
      )}
      targetAttachment={`bottom ${attachmentSide}`}
    />
  )
}

export default SettingsDropdown
