import { createSlice } from '@reduxjs/toolkit'
import { Layout } from 'react-grid-layout'

import { WorkspaceStateType } from 'utils/types/states'
import { minWidgetHeight, widgetHeight } from 'utils/types/widgetUtils'

import {
  addWorkspace,
  fetchWorkspaces,
  fetchWorkspaceById,
  updateWorkspaceById,
  deleteWorkspaceById,
  leaveWorkspace,
  uploadAvatar,
  getWorkspaceDashboard,
  saveWorkspaceDashboard,
  getAvailableWorkspaceWidgets,
  addWidgetToDashboard,
  getAllDashboardForWorkspace,
  getMyTasksWidget,
  archiveWorkspaceById,
  updateWorkspaceCustomFields,
  getHomePageDashboard,
  onCreateNewWorkspaceDashboard,
  onDeleteWorkspaceDashboard,
  getFilteredTasksWidget,
} from './actions'

const initialState: WorkspaceStateType = {
  availableDashboardWidgets: null,
  chosenPlan: null,
  currentDashboardId: null,
  currentWorkspaceId: undefined,
  currentWorkspaceloading: true,
  dashboard: null,
  dashboardLoading: false,
  dashboards: null,
  dashboardsLoading: false,
  homeDashboard: null,
  isHomeDashboardLoading: false,
  isMyTasksLoading: false,
  isRejected: false,
  loading: true,
  myTasks: null,
  role: 3,
  workspaces: [],
}

export const workspaceSlice = createSlice({
  extraReducers: (builder) => {
    // fetchWorkspaces
    builder.addCase(fetchWorkspaces.fulfilled, (state, action) => {
      state.workspaces = action.payload
      state.loading = false
    })

    builder.addCase(fetchWorkspaces.pending, (state) => {
      state.loading = true
      state.isRejected = false
    })

    builder.addCase(fetchWorkspaces.rejected, (state) => {
      state.loading = false
      state.isRejected = true
    })

    // addWorkspace
    builder.addCase(addWorkspace.fulfilled, (state, action) => {
      state.workspaces = [...state.workspaces, action.payload]
      state.loading = false
    })

    builder.addCase(addWorkspace.pending, (state) => {
      state.loading = true
    })

    builder.addCase(addWorkspace.rejected, (state) => {
      state.loading = false
    })

    // updateWorkspaceById
    builder.addCase(updateWorkspaceById.fulfilled, (state, action) => {
      const updatedWorkspace = action.payload
      const updatedWorkspaceIndex = state.workspaces.findIndex(
        ({ id: workspaceId }) => updatedWorkspace.id === workspaceId,
      )
      state.workspaces[updatedWorkspaceIndex] = updatedWorkspace
      state.loading = false
    })

    builder.addCase(updateWorkspaceById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(updateWorkspaceById.rejected, (state) => {
      state.loading = false
    })

    // fetchWorkspaceById
    builder.addCase(fetchWorkspaceById.fulfilled, (state, action) => {
      const fetchedWorkspace = action.payload
      const workspaceToUpdateIndex = state.workspaces.findIndex(({ id }) => id === fetchedWorkspace.id)
      if (workspaceToUpdateIndex > -1) {
        state.workspaces[workspaceToUpdateIndex] = { ...state.workspaces[workspaceToUpdateIndex], ...fetchedWorkspace }
      } else {
        state.workspaces = [...state.workspaces, fetchedWorkspace]
      }
      state.currentWorkspaceId = fetchedWorkspace.id
      state.currentWorkspaceloading = false
    })

    builder.addCase(fetchWorkspaceById.pending, (state) => {
      if (state.isRejected) state.isRejected = false
      state.currentWorkspaceloading = true
    })

    builder.addCase(fetchWorkspaceById.rejected, (state) => {
      state.isRejected = true
      state.currentWorkspaceloading = false
    })

    builder.addCase(getWorkspaceDashboard.fulfilled, (state, action) => {
      if (typeof action.payload === 'object' && action.payload !== null) {
        state.dashboard = action.payload
        state.currentDashboardId = action.payload.id
        state.dashboardLoading = false
      }
    })

    builder.addCase(getWorkspaceDashboard.rejected, (state, action) => {
      state.dashboard = null
      state.dashboardLoading = false
    })
    builder.addCase(getWorkspaceDashboard.pending, (state, action) => {
      state.dashboardLoading = true
    })

    builder.addCase(getHomePageDashboard.fulfilled, (state, action) => {
      state.homeDashboard = action?.payload
      state.isHomeDashboardLoading = false
    })

    builder.addCase(getHomePageDashboard.rejected, (state, action) => {
      state.isHomeDashboardLoading = false
    })

    builder.addCase(getHomePageDashboard.pending, (state, action) => {
      state.isHomeDashboardLoading = true
    })
    builder.addCase(getMyTasksWidget.fulfilled, (state, action) => {
      state.myTasks = action?.payload
      state.isMyTasksLoading = false
    })

    builder.addCase(getMyTasksWidget.rejected, (state, action) => {
      state.isMyTasksLoading = false
    })

    builder.addCase(getMyTasksWidget.pending, (state, action) => {
      state.isMyTasksLoading = true
    })
    builder.addCase(getFilteredTasksWidget.fulfilled, (state, action) => {
      state.myTasks = action?.payload
      state.isMyTasksLoading = false
    })

    builder.addCase(getFilteredTasksWidget.rejected, (state, action) => {
      state.isMyTasksLoading = false
    })

    builder.addCase(getFilteredTasksWidget.pending, (state, action) => {
      state.isMyTasksLoading = true
    })

    builder.addCase(saveWorkspaceDashboard.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(saveWorkspaceDashboard.rejected, (state, action) => {
      if (action.payload?.body?.errors?.length > 0) {
        state.dashboard = action.payload.body.resultDb
        state.currentDashboardId = action.payload.body.resultDb.id
      }
      state.loading = false
    })
    builder.addCase(saveWorkspaceDashboard.fulfilled, (state, action) => {
      state.dashboard = action.payload
      state.currentDashboardId = action.payload.id
      state.loading = false
    })

    builder.addCase(getAvailableWorkspaceWidgets.fulfilled, (state, action) => {
      state.availableDashboardWidgets = action.payload
    })

    builder.addCase(addWidgetToDashboard.fulfilled, (state, action) => {
      if (state.dashboard) {
        const dashboardWidgets = state.dashboard.widgets ? state.dashboard.widgets : []
        const arrOfY = dashboardWidgets.map((w) => w.dimensions.y)
        const yMax = arrOfY.length ? Math.max.apply(null, arrOfY) : 0
        let newWidgets = [...dashboardWidgets]
        if (!action.payload.widget) {
          const widgetsIds = newWidgets.map(({ id, tempId }) => id || tempId)
          action.payload.dimensions.y = action.payload.dimensions.y === -1 ? yMax + 4 : action.payload.dimensions.y
          action.payload.dimensions.x = action.payload.dimensions.x === -1 ? 0 : action.payload.dimensions.x
          action.payload.tempId = widgetsIds.length ? Math.max(...widgetsIds) + 1 : 1
          newWidgets = [...newWidgets, action.payload]
        } else {
          newWidgets = [...newWidgets, { ...action.payload.widget, tempId: `${newWidgets.length + 1}_id` }]
        }
        state.dashboard = { ...state.dashboard, widgets: newWidgets }
      } else {
        state.dashboard = action.payload
      }
    })

    builder.addCase(getAllDashboardForWorkspace.fulfilled, (state, action) => {
      state.dashboards = action.payload
      state.currentDashboardId = action.payload[0]?.id
      state.dashboardsLoading = false
    })
    builder.addCase(getAllDashboardForWorkspace.pending, (state) => {
      state.dashboardsLoading = true
    })
    builder.addCase(getAllDashboardForWorkspace.rejected, (state) => {
      state.dashboards = null
      state.currentDashboardId = null
      state.dashboardsLoading = false
    })

    builder.addCase(onCreateNewWorkspaceDashboard.fulfilled, (state, action) => {
      state.dashboards = [...state.dashboards, action.payload]
      state.currentDashboardId = action.payload.id
      state.dashboardsLoading = false
    })

    builder.addCase(onCreateNewWorkspaceDashboard.pending, (state, action) => {
      state.dashboardsLoading = true
    })

    builder.addCase(onCreateNewWorkspaceDashboard.rejected, (state, action) => {
      state.dashboardsLoading = false
    })

    builder.addCase(onDeleteWorkspaceDashboard.fulfilled, (state, action) => {
      const newList = [...state.dashboards].filter((dash) => +dash.id !== +action.payload.elementId)
      state.dashboards = [...newList]
      state.currentDashboardId = newList[0].id
      state.dashboardsLoading = false
    })

    builder.addCase(onDeleteWorkspaceDashboard.pending, (state, action) => {
      state.dashboardsLoading = true
    })

    builder.addCase(onDeleteWorkspaceDashboard.rejected, (state, action) => {
      state.dashboardsLoading = false
    })

    // deleteWorkspaceById
    builder.addCase(deleteWorkspaceById.fulfilled, (state, action) => {
      state.loading = false
    })

    builder.addCase(deleteWorkspaceById.pending, (state, action) => {
      state.workspaces = state.workspaces.filter((workspace) => workspace.id !== action.meta.arg)
    })

    builder.addCase(deleteWorkspaceById.rejected, (state) => {
      state.loading = false
    })
    // archiveWorkspaceById
    builder.addCase(archiveWorkspaceById.fulfilled, (state, action) => {
      state.loading = false
    })

    builder.addCase(archiveWorkspaceById.pending, (state, action) => {
      const archivedIndex = state.workspaces.findIndex((workspace) => +workspace.appElements[0].id === +action.payload)
      state.workspaces.splice(archivedIndex, 1)
    })

    builder.addCase(archiveWorkspaceById.rejected, (state, action) => {
      // state.workspaces = state.workspaces.filter((workspace) => +workspace.appElement.id !== +action.payload)
      state.loading = false
    })

    // leaveWorkspace
    builder.addCase(leaveWorkspace.fulfilled, (state, action) => {
      if (action.payload.message === 'userRemovedFromWorkspace') {
        state.workspaces = state.workspaces.filter((workspace) => workspace.id !== action.meta.arg)
      }
      state.loading = false
    })

    builder.addCase(leaveWorkspace.pending, (state, action) => {
      state.loading = false
    })

    builder.addCase(leaveWorkspace.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(uploadAvatar.fulfilled, (state, action) => {
      const { link: avatarLink, id } = action.payload
      if (avatarLink !== undefined) {
        const updatedWorkspaceIndex = state.workspaces.findIndex(({ id: workspaceId }) => id === workspaceId)
        state.workspaces[updatedWorkspaceIndex].link = avatarLink
      }
      state.loading = false
    })

    builder.addCase(uploadAvatar.pending, (state) => {
      state.loading = true
    })

    builder.addCase(uploadAvatar.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(updateWorkspaceCustomFields.fulfilled, (state, action) => {
      const workspace = action.payload
      const fetchedWorkspaceIndex = state.workspaces.findIndex(({ id: workspaceId }) => workspace.id === workspaceId)
      if (fetchedWorkspaceIndex > -1)
        state.workspaces[fetchedWorkspaceIndex] = { ...state.workspaces[fetchedWorkspaceIndex], ...workspace }
      else state.workspaces = [...state.workspaces, action.payload]
      state.currentWorkspaceId = action.payload.id
      state.currentWorkspaceloading = false
    })
  },
  initialState,
  name: 'workspace',
  reducers: {
    clearChosenPlan: (state) => {
      state.chosenPlan = null
    },
    clearCurrentWorkspace: (state) => {
      state.currentWorkspaceId = undefined
      state.currentWorkspaceloading = true
    },
    clearMyTasks: (state) => {
      state.myTasks = null
    },
    clearWorkspaceDashboardPage: (state) => {
      state.dashboard = null
      state.dashboards = null
      state.currentDashboardId = null
    },
    onChangeCurrentDashboardId: (state, action) => {
      state.currentDashboardId = +action.payload
    },
    removeWidgetFromDashboard: (state, { payload }: { type: string, payload: number }) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.filter((w) => w.id !== payload && w?.tempId !== payload)
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
    resetRejectedWorkspace: (state) => {
      state.isRejected = false
    },
    saveDashboardLayoutDragChanges: (
      state,
      {
        payload,
      }: {
        type: string,
        payload: {
          layout: Layout[],
          newItem: Layout,
        },
      },
    ) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.map((w, idx) => {
          return {
            ...w,
            dimensions: {
              ...w.dimensions,
              x: payload.layout[idx].x,
              y: payload.layout[idx].y,
            },
          }
        })

        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
    saveDashboardLayoutResizeChanges: (state, { payload }: { type: string, payload: Layout }) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard.widgets.map((w) => {
          const itemKey = `${w?.id || w?.tempId}`
          if (itemKey === payload?.i) {
            return {
              ...w,
              dimensions: {
                ...w.dimensions,
                h: widgetHeight(w, payload),
                minH: minWidgetHeight(w, payload),
                minW: payload.minW,
                w: payload.w,
                x: payload.x,
                y: payload.y,
              },
            }
          }

          return w
        })
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
    setChosenPlan: (state, { payload }) => {
      state.chosenPlan = payload
    },
    setRejectedWorkspace: (state) => {
      state.isRejected = true
    },
    updateTextWidgetValue: (
      state,
      { payload }: { payload: { description: string, widgetId: number, imgBase64?: string } },
    ) => {
      if (state.dashboard) {
        const newWidgets = state.dashboard?.widgets.map((w) => {
          if (w.id === payload.widgetId || w.tempId === payload.widgetId) {
            const data: any = {
              description: payload.description,
              image: payload.imgBase64 !== undefined && payload.imgBase64 !== null ? payload.imgBase64 : w.image,
            }
            const updatedTextWidget = { ...w, ...data }
            return updatedTextWidget
          }
          return w
        })
        state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
      }
    },
    updateWorkspaceWidgetImage: (state, action) => {
      const dashboardWidgets = [...state.dashboard.widgets]
      const dashboardWidgetIndex = dashboardWidgets.findIndex((w) => +w.baseType === +action.payload.baseType)
      if (dashboardWidgetIndex > -1) {
        //add widget image data
        dashboardWidgets[dashboardWidgetIndex] = action.payload
        state.dashboard = { ...state.dashboard, widgets: dashboardWidgets }
      }
    },
    updateWorkspaceWidgetTitle: (state, { payload }: { payload: { widgetId: number | string, title: string } }) => {
      const newWidgets = state.dashboard?.widgets.map((w) => {
        if (w.id === payload.widgetId || w.tempId === payload.widgetId) {
          const data: any = {
            title: payload.title,
          }
          const updatedWidget = { ...w, ...data }
          return updatedWidget
        }
        return w
      })

      state.dashboard = { ...state.dashboard, widgets: newWidgets ? newWidgets : [] }
    },
  },
})

export const {
  resetRejectedWorkspace,
  setRejectedWorkspace,
  clearCurrentWorkspace,
  saveDashboardLayoutResizeChanges,
  saveDashboardLayoutDragChanges,
  removeWidgetFromDashboard,
  updateTextWidgetValue,
  clearWorkspaceDashboardPage,
  updateWorkspaceWidgetTitle,
  setChosenPlan,
  clearChosenPlan,
  updateWorkspaceWidgetImage,
  clearMyTasks,
  onChangeCurrentDashboardId,
} = workspaceSlice.actions
export default workspaceSlice.reducer
