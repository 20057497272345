// Finds all descendant sidebar items
export const findAllChildren: (arr: any, children: number[]) => number[] = (arr, children) => {
  if (!children || !children.length || !arr || !arr.length) {
    return children
  }
  const newChildren = arr
    .filter((i: any) => children.includes(i.id))
    .map((i: any) => i.children)
    .flat()
    .filter(Boolean)
  const newArr = arr.filter((i: any) => !children.includes(i.id))

  if (newArr.length === arr.length) {
    return [...newChildren, ...children]
  }

  return findAllChildren(newArr, [...newChildren, ...children])
}

// Finds all ancestors and sibling sidebar items
export const findAllParentsAndSiblings = (arr: any, item: any, parents?: number[], noSiblings?: boolean): number[] => {
  const prevParents = parents || []
  if (!arr || !arr.length || !item) {
    return prevParents
  }
  const filteredItem = arr.filter((i: any) => i.id === item)[0]
  const newParent = filteredItem && filteredItem.parentId ? filteredItem.parentId : 0
  const newArr = arr.filter((i: any) => i.id !== item)
  const siblings = noSiblings
    ? []
    : arr.filter((i: any) => filteredItem?.level === i?.level && newParent === i?.parentId).map((m: any) => m.id)
  const newParentsAndSiblings = [newParent, ...prevParents, ...siblings]

  if (newArr.length === arr.length) {
    return newParentsAndSiblings
  }

  return findAllParentsAndSiblings(newArr, newParent, newParentsAndSiblings, noSiblings)
}
