import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { fetchWorkspaceById, useCurrentWorkspace } from 'features/workspace'
import CreateCustomFieldButton from './CreateCustomFieldButton'
import ListCustomFieldItem from './ListCustomFieldItem'

const WorkspaceCustomFieldsForm: FC<{ workspaceId: string, isViewer?: boolean }> = ({ workspaceId, isViewer }) => {
  const { t } = useTranslation('customFields')
  const appDispatch = useAppDispatch()
  const workspace = useCurrentWorkspace()
  const listCustomFields = workspace?.customFields

  useEffect(() => {
    if (workspaceId) appDispatch(fetchWorkspaceById(+workspaceId))
  }, [workspaceId])

  const onRefetch = () => {
    appDispatch(fetchWorkspaceById(Number(workspace?.id)))
  }

  return (
    <div className="w-full p-8">
      <div className="flex items-center justify-between">
        <label className="block mb-2 text-lg font-bold">{t('customFields:customFields')}</label>
        {!isViewer && (
          <CreateCustomFieldButton
            currentItem={workspace}
            elementBaseType={AppElementBaseTypes.WorkspaceAppElement}
            showIcon={true}
            showText={false}
            onRefetch={onRefetch}
          />
        )}
      </div>
      <div className="overflow-y-auto" style={{ height: '90%', minHeight: '90%' }}>
        {listCustomFields?.native?.count > 0 ? (
          listCustomFields?.native?.fields?.map((field: CustomFieldType, index: number) => (
            <>
              {workspace && (
                <ListCustomFieldItem
                  key={`${index} ${field.id}`}
                  currentItem={workspace}
                  customFieldItem={field}
                  elementBaseType={AppElementBaseTypes.WorkspaceAppElement}
                  item={workspace?.id}
                  onRefetch={onRefetch}
                  isViewer={isViewer}
                />
              )}
            </>
          ))
        ) : (
          <div className="flex items-center justify-center w-full h-full text-sm opacity-50">{t('noCustomFields')}</div>
        )}
      </div>
    </div>
  )
}

export default WorkspaceCustomFieldsForm
