import React, { useRef, useState, useEffect, useMemo, MouseEvent, FocusEvent } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { sliceEvents, createPlugin, Duration, DateProfile, ViewProps } from '@fullcalendar/react'
import { format } from 'date-fns'
import { arSA, enGB } from 'date-fns/locale'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Droppable, Draggable } from 'react-beautiful-dnd'

import { archiveRecordById, createRecord, useRecords } from 'features/record'
import { Input } from 'components/inputs'
import { EmptyStationsIcon } from 'components/Icons'
import AgendaItem from 'components/AgendaView/AgendaItem'
import { arabicDayFormat } from '../../utils/calendarUtils'
import { useCurrentList } from '../../features/list'
import { RecordType } from '../../utils/types'
import Button, { ButtonVariant } from '../Buttons'
import ConfirmationModal from '../ConfirmationModal'
import { useRoles } from '../../hooks'

const AgendaView = (
  props: ViewProps & {
    dateProfile: DateProfile,
    nextDayThreshold: Duration,
  },
) => {
  const [newEventParent, setNewEventParent] = useState<string | null>(null)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState<any>(null)
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const { listId }: { listId: string } = useParams()
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const records = useRecords()
  const currentList = useCurrentList()
  const { t, i18n } = useTranslation('records')
  const { isEditor, isAdmin } = useRoles()
  const isViewer = !isEditor && !isAdmin
  const currentLocale = useMemo(() => (i18n.language === 'ar' ? arSA : enGB), [i18n.language])

  useEffect(() => {
    if (newEventParent && inputRef) {
      inputRef.current?.focus()
    }
  }, [inputRef, newEventParent])

  useEffect(() => {
    const handleClickOutSideMenu = () => setOpenMenu(false)
    window.addEventListener('click', handleClickOutSideMenu)
    return () => {
      window.removeEventListener('click', handleClickOutSideMenu)
    }
  }, [])

  const { groupedByDay } = useMemo(() => {
    const segments = sliceEvents({ ...props }, true)
    const sorted = sortBy(segments, ({ range: { start } }) => start)

    return {
      groupedByDay: groupBy(
        sorted,
        ({ range: { start } }) =>
          // currentLocale === arSA
          arabicDayFormat(start, i18n.language),
        // : format(start, 'MMMM d', { locale: currentLocale }),
      ),
    }
  }, [props, currentLocale])

  const handleEdit = (id: number) => {
    const selectedRecord = records.find((record) => +record.id === id)
    if (selectedRecord) {
      history.push(`${location.pathname}?recordId=${selectedRecord.id}`)
    }
  }

  const onDayCellClick = (eventDate: Date) => (event: MouseEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) {
      return
    }
    setNewEventParent(JSON.stringify(eventDate))
  }

  const onNewEventBlur = (eventDate: Date) => (event: FocusEvent<HTMLInputElement>) => {
    dispatch(
      createRecord({
        endDate: new Date(eventDate),
        listId: +listId,
        name: event.target.value,
        parentId: +currentList?.appElement?.id,
      }),
    )
    setNewEventParent(null)
  }

  const getFormattedDateClassName = (formattedDate: string) =>
    classnames('mb-2 mt-4', {
      'text-primary font-bold': formattedDate === format(new Date(), 'd MMMM', { locale: currentLocale }),
    })

  const onRightClickItem = (item: RecordType, position: { x: number, y: number }) => {
    setOpenMenu(!openMenu)
    setCurrentItem(item)
    setMenuPosition(position)
  }

  const onArchive = () => {
    const recursive = 0 // 1 to unarchive element and all children force 0 to archive element and children but not
    dispatch(archiveRecordById({ elementId: +currentItem?.appElements[0]?.id, recursive, status: Number(!isArchived) }))
    setIsArchiveModalOpen(false)
  }

  const isArchived = currentItem && currentItem?.appElements[0]?.isArchived

  return (
    <div className="h-full md:pt-0 md:p-4">
      {Object.keys(groupedByDay).length === 0 && (
        <div className="flex flex-col items-center justify-center flex-1 h-full ms-10">
          <EmptyStationsIcon className="w-32 mb-6" />
          <h2 className="font-bold text-center text-gray-700">{t('noRecordsThisMonth')}</h2>
        </div>
      )}
      {Object.keys(groupedByDay).map((formattedDate) => {
        const eventDate = groupedByDay[formattedDate][0].range.start
        const dateTexts = formattedDate.split('(')
        return (
          <div key={formattedDate}>
            <div className={getFormattedDateClassName(formattedDate)}>
              <button
                className="py-2 text-sm font-bold uppercase hover:text-primary transition"
                onClick={onDayCellClick(eventDate)}>
                {dateTexts[0]}
                <span className="text-xs ms-2">({dateTexts[1]}</span>
              </button>
            </div>
            <Droppable droppableId={JSON.stringify(eventDate)}>
              {(provided) => (
                <div
                  className="pb-2 mb-2 border-b-2 border-gray-400 rounded last:border-b-0 last:mb-0"
                  ref={provided.innerRef}>
                  {groupedByDay[formattedDate].map(
                    (
                      {
                        def: {
                          title,
                          publicId,
                          extendedProps: { statusId, ownerId },
                          ...rest
                        },
                      },
                      index,
                    ) => {
                      const record = records.find((item) => item.id === +publicId)
                      return (
                        <Draggable key={publicId} draggableId={publicId} index={index} isDragDisabled={isViewer}>
                          {(provided) => (
                            <AgendaItem
                              dragHandleProps={provided.dragHandleProps}
                              draggableProps={provided.draggableProps}
                              handleEdit={handleEdit}
                              innerRef={provided.innerRef}
                              ownerId={ownerId}
                              publicId={publicId}
                              record={record}
                              statusId={statusId}
                              title={title}
                              onRightClickItem={onRightClickItem}
                            />
                          )}
                        </Draggable>
                      )
                    },
                  )}
                  {newEventParent === JSON.stringify(eventDate) && (
                    <Input
                      classes={{ wrapper: 'mb-0' }}
                      name="name"
                      ref={inputRef}
                      onBlur={onNewEventBlur(eventDate)}
                    />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )
      })}
      {openMenu && (
        <div className="menuCard" style={{ left: menuPosition?.x - 160, top: menuPosition?.y - 150 }}>
          <Button
            className="text-primary"
            small
            variant={ButtonVariant.Text}
            onClick={() => {
              const recordUrl = `${location.pathname}${
                location.search ? `${location.search}&recordId=${currentItem.id}` : `?recordId=${currentItem.id}`
              }`
              history.push(recordUrl)
            }}>
            {t('common:labels.open')}
          </Button>
          <Button
            className="text-danger"
            small
            variant={ButtonVariant.Text}
            onClick={() => setIsArchiveModalOpen(true)}>
            {isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
          </Button>
        </div>
      )}
      <ConfirmationModal
        confirmMessage={isArchived ? t('common:labels.unarchive') : t('common:labels.archive')}
        confirmationMessage={t(isArchived ? 'records:confirmRecordUnarchive' : 'records:confirmRecordArchive', {
          interpolation: { escapeValue: false },
          name: currentItem?.name,
        })}
        isModalOpen={isArchiveModalOpen}
        onCancel={() => setIsArchiveModalOpen(false)}
        onConfirm={onArchive}
      />
    </div>
  )
}

export default createPlugin({
  views: {
    agendaView: AgendaView,
  },
})
