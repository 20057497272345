import { createAsyncThunk } from '@reduxjs/toolkit'

import baseAPI from 'utils/baseAPI'

export const fetchActivityForResource = createAsyncThunk(
  'activity/fetchActivityForResource',
  async ({
    resourceId,
    resourceType,
    showLatest,
  }: {
    resourceId: number,
    resourceType: number,
    showLatest: boolean,
  }) => {
    return baseAPI(`api/activity/${resourceType}/${resourceId}?asc=${showLatest ? 0 : 1}`, {}).then((response: any) => {
      return response.body.data
    })
  },
)
