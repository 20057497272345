import React from 'react'
import { useTranslation } from 'react-i18next'
import Item from './Item'

const DraggableItem = (props: any) => {
  const {
    data: { records, columnId, columnsType, setClickedRecordIndex, baseType, onRightClickItem },
    index,
    style,
  } = props
  const { i18n } = useTranslation()
  if (!records?.length) {
    return <div style={{ ...style }} />
  }
  const record = records[index]
  return (
    <div key={record.id} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} style={style}>
      <Item
        key={record.id}
        {...record}
        columnId={columnId}
        columnsType={columnsType}
        index={index}
        baseType={baseType}
        onRightClickItem={onRightClickItem}
        onClick={() => setClickedRecordIndex(index)}
      />
    </div>
  )
}
export default DraggableItem
