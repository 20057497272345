import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { ListStatusType, ListType, ListLabelType } from 'utils/types'
import StateType from 'utils/types/states'
import { AppElementViewAttribute, AppElementView } from 'utils/types/AppElementViewType'

const selectCurrentListId = (state: StateType) => state.list.currentListId
const selectCurrentListDetails = (state: StateType) => state.list.currentListDetails
const selectListLoading = (state: StateType) => state.list.loading
const selectCurrentListLoading = (state: StateType) => state.list.currentListLoading
const selectLists = (state: StateType) => state.list.lists
const selectListsIds = (state: StateType) => state.list.listsIds
const selectCustomGridData = (state: StateType) => state.list.customGridData
const selectLinkedDocuments = (state: StateType) => state.list.linkedDocuments
const selectCurrentListViews = (state: StateType) => state.list.currentListViews
const selectIsLoadingListViews = (state: StateType) => state.list.isLoadingListViews
const selectActiveListViewId = (state: StateType) => state.list.activeListViewId

const selectCurrentList = createSelector([selectLists, selectCurrentListId], (lists, currentListId) =>
  lists.find(({ id }) => id === currentListId),
)

const selectCurrentListCreatedAt = createSelector(
  [selectLists, selectCurrentListId],
  (lists, currentListId) => lists.find(({ id }) => id === currentListId)?.createdAt,
)
const selectCurrentListIsArchived = createSelector(
  [selectLists, selectCurrentListId],
  (lists, currentListId) => lists.find(({ id }) => id === currentListId)?.appElement?.isArchived,
)

const selectCurrentListElementId = createSelector(
  [selectLists, selectCurrentListId],
  (lists, currentListId) => lists.find(({ id }) => id === currentListId)?.appElement?.id,
)

const selectCurrentListArchivedById = createSelector(
  [selectLists, selectCurrentListId],
  (lists, currentListId) => lists.find(({ id }) => id === currentListId)?.appElement?.archivedById,
)
const selectCurrentListStatuses = createSelector(selectCurrentList, (currentList) => currentList?.statuses || [])
const selectCurrentListLabels = createSelector(selectCurrentList, (currentList) => currentList?.labels || [])
const selectStatusById = createSelector([selectCurrentListStatuses, (_, id) => id], (statuses, id) =>
  statuses.find(({ id: statusId }) => statusId === +id),
)

const selectLabelsByIds = createSelector([selectCurrentListLabels, (_, ids: number[]) => ids], (labels = [], ids) =>
  labels.filter(({ id }) => ids.includes(id)),
)

const selectActiveListView = createSelector(
  [selectCurrentListViews, selectActiveListViewId],
  (listViews, activeListViewId) => listViews?.find((view) => view.id == activeListViewId),
)

const selectActiveListViewFields = createSelector(
  [selectCurrentListViews, selectActiveListViewId],
  (listViews, activeListViewId) => {
    const view = listViews.find((view) => view.id == activeListViewId)
    if (!view) return []
    return view?.appElementViewAttributes
  },
)

export const useLists: () => ListType[] = () => useSelector(selectLists)
export const useListsIds: () => ListType[] = () => useSelector(selectListsIds)
export const useCurrentList: () => ListType | undefined = () => useSelector(selectCurrentList)
export const useCurrentListDetails: () => ListType | undefined = () => useSelector(selectCurrentListDetails)
export const useCurrentListId: () => number | undefined = () => useSelector(selectCurrentListId)
export const useCurrentListElementId: () => number | undefined = () => useSelector(selectCurrentListElementId)
export const useCurrentListIsArchived: () => number | undefined = () => useSelector(selectCurrentListIsArchived)
export const useCurrentListArchivedById: () => number | string | null = () => useSelector(selectCurrentListArchivedById)
export const useCurrentListCreatedAt: () => number | string | null = () => useSelector(selectCurrentListCreatedAt)
export const useCurrentListStatuses: () => ListStatusType[] = () => useSelector(selectCurrentListStatuses)
export const useCurrentListLabels: () => ListLabelType[] = () => useSelector(selectCurrentListLabels)
export const useListLoading: () => boolean = () => useSelector(selectListLoading)
export const useCurrentListLoading: () => boolean = () => useSelector(selectCurrentListLoading)
export const useStatusById: (id: number | string) => ListStatusType | undefined = (id) =>
  useSelector((state: StateType) => selectStatusById(state, id))
export const useLabelsByIds: (ids: number[] | undefined) => ListLabelType[] = (ids = []) =>
  useSelector((state) => selectLabelsByIds(state, ids))
export const useCustomGridData: () => any[] = () => useSelector(selectCustomGridData)
export const useLinkedDocuments: () => any[] = () => useSelector(selectLinkedDocuments)
export const useCurrentListViews: () => AppElementView[] = () => useSelector(selectCurrentListViews)
export const useIsLoadingListViews: () => boolean = () => useSelector(selectIsLoadingListViews)
export const useActiveListView: () => AppElementView | undefined = () => useSelector(selectActiveListView)
export const useActiveListViewFields: () => AppElementViewAttribute[] = () => useSelector(selectActiveListViewFields)
export const useActiveListViewId: () => number | null = () => useSelector(selectActiveListViewId)
