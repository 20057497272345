import { get, isEmpty } from 'lodash'

import RolesEnum from 'utils/constant/enums'
import { AccessType } from 'utils/types'
import { ListStateType, StationStateType, WorkspaceStateType } from 'utils/types/states'

type Resource = 'station' | 'workspace' | 'list'
type currentResource = Record<Resource, AccessType> & { id: number, ownerId: number }

export const getCurrentResourcePermission = (
  state: { list: ListStateType, station: StationStateType, workspace: WorkspaceStateType },
  resource: 'station' | 'workspace' | 'list',
  resourceId: string | number | undefined,
  userId: number,
) => {
  if (resourceId === undefined) return RolesEnum.Viewer

  const currentResources: currentResource[] = get(state, `${resource}.${resource}s`)
  if (isEmpty(currentResources)) return RolesEnum.NoAccess
  const currentResource = currentResources.find(({ id }) => +id === +resourceId)
  const currentResourceAccesses: AccessType[] | undefined = get(currentResource, `${resource}Members`)
  if (currentResourceAccesses === undefined) return RolesEnum.NoAccess
  const userAccess = currentResourceAccesses.find(({ userId: uId }) => {
    return uId == userId
  })
  if (userAccess !== undefined) return userAccess.accessGroupId
  return RolesEnum.Viewer
}

export const hasProperRole = (userRole: RolesEnum, requiredRole: RolesEnum) => userRole >= requiredRole
