import classnames from 'classnames'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { healthColors } from '../GridView/cells/displayCells/HealthCell'

type ProgressBarPropsType = {
  id: number,
  health?: string | null,
  completedIn?: number | null,
  containerClass?: string,
  barClass?: string,
  textClass?: string,
}

const ProgressBar: FC<ProgressBarPropsType> = ({ id, health, completedIn, containerClass, barClass, textClass }) => {
  const { t } = useTranslation()
  const parsedHealth = health || 'unavailable'
  const parsedCompletedIn = completedIn || 0

  return (
    <div className={classnames('flex justify-start items-center', containerClass, { 'w-full': !containerClass })}>
      <div className="relative w-10/12 bg-gray-200 rounded">
        <div
          key={id}
          className={classnames('absolute rounded-lg', barClass, { 'h-1.5': !barClass })}
          data-title={t(`records:health:${parsedHealth}`)}
          style={{ backgroundColor: healthColors[parsedHealth], width: `${parsedCompletedIn}%` }}
        />
      </div>
      <div className={classnames('ms-3', textClass)}>{completedIn}%</div>
    </div>
  )
}
export default ProgressBar
