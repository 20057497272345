import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { StationType, WorkspaceType } from 'utils/types'

import { updateStation } from 'features/station'
import { updateWorkspaceById } from 'features/workspace'

import { CheckmarkIcon } from 'components/Icons'
import { hexToRgb } from 'utils/helpers/colorHelpers'

const AvatarColorButton: FC<{
  avatarColorActive: string | undefined,
  color: string,
  data: WorkspaceType | StationType | undefined,
  isDisplayColorCheckMark: boolean,
  disabled?: boolean,
  isStation: WorkspaceType | StationType | undefined,
  setIsDisplayButtonCheckMark: Dispatch<SetStateAction<boolean>>,
}> = ({
  avatarColorActive,
  color,
  data,
  isDisplayColorCheckMark,
  isStation,
  setIsDisplayButtonCheckMark,
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const bgColorRgb = useMemo(() => hexToRgb(color), [color])

  const style = {
    '--bg-b': bgColorRgb.blue,
    '--bg-g': bgColorRgb.green,
    '--bg-r': bgColorRgb.red,
  }

  const updateAvatar = () => {
    setIsDisplayButtonCheckMark(true)
    if (data) {
      const newWorkspaceValues = { ...data, color: color, elementName: data.name, useAvatar: false }
      if (isStation && isStation.stationMembers) dispatch(updateStation(newWorkspaceValues))
      else dispatch(updateWorkspaceById(newWorkspaceValues))
    }
  }

  return (
    <button
      className="relative w-10 h-10 mt-2 rounded-sm transition hover:opacity-70 me-2 autocolor"
      disabled={disabled}
      style={style}
      onClick={updateAvatar}>
      {avatarColorActive === color && isDisplayColorCheckMark && (
        <div className="absolute inset-0 flex items-center justify-center">
          <CheckmarkIcon className="w-6 h-6 stroke-3 filter drop-shadow-active" />
        </div>
      )}
    </button>
  )
}

export default AvatarColorButton
