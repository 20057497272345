import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { WatchedIcon, WatchIcon } from '../Icons'
import {
  getElementSubscriber,
  getElementUsers,
  onAddAppElementSubscriber,
  onDeleteAppElementSubscriber,
  useElementSubscriber,
  useElementUsers,
} from '../../features/element'
import { Tooltip } from '../../utils/helpers/generalHelpers'
import { useRoles } from '../../hooks'
import { getElementSubscriberForUser } from '../../utils/helpers/notificationHelpers'
import { UserType } from '../../utils/types'
import { useAppDispatch } from '../../store'
import { cleanElementSubscriber, cleanElementUsers } from '../../features/element/elementSlice'
import WatchersPopover from './WatcharsPopover'

type WatchButtonType = {
  elementId: number | string,
  userId: number | string,
  isAdmin?: boolean,
}

const WatchButton: FC<WatchButtonType> = ({ elementId, userId, isAdmin = false }) => {
  const { t, i18n } = useTranslation('common')
  const [visible, setVisible] = useState<boolean>(false)
  const [isTippyInit, setIsTippyInit] = useState<boolean>(true)
  const userRole = useRoles()
  const isRtl = i18n.language === 'ar'
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const elementSubscriberList = useElementSubscriber()
  const elementAllUsers = useElementUsers()
  const elementMembers = elementAllUsers.filter((user: UserType) => +user.userId !== +userId)
  const element = getElementSubscriberForUser(elementSubscriberList, userId)
  const isSubscribed = elementSubscriberList?.length > 0
  const isSubscribeInherited = isSubscribed ? elementSubscriberList[0]?.inherit?.inherited : false
  const isCurrentUserWatcher = element !== null && element !== undefined
  const isCurrentUserAnAdmin = userRole?.isAdmin || isAdmin

  useEffect(() => {
    dispatch(getElementSubscriber(elementId))
    if (isCurrentUserAnAdmin) dispatch(getElementUsers(+elementId))
    return () => {
      dispatch(cleanElementSubscriber())
      if (isCurrentUserAnAdmin) dispatch(cleanElementUsers())
    }
  }, [])

  const onChangeWatchStatus = (currentUserId: string | number) => {
    const elementItem = getElementSubscriberForUser(elementSubscriberList, currentUserId)

    if (elementId && userId) {
      if (elementItem) {
        appDispatch(onDeleteAppElementSubscriber({ elementId: +elementId, userId: currentUserId })).then(() =>
          dispatch(getElementSubscriber(elementId)),
        )
      } else {
        dispatch(onAddAppElementSubscriber({ elementId: +elementId, userId: currentUserId })).then(() =>
          dispatch(getElementSubscriber(elementId)),
        )
      }
    }
  }

  return (
    <div
      key={`elementWatch-${isRtl}`}
      className={classNames('flex items-center justify-center px-2 py-1 rounded cursor-pointer hover:bg-gray-200', {
        'bg-gray-200': isCurrentUserWatcher && isSubscribeInherited,
        'bg-primary/10': isCurrentUserWatcher && !isSubscribeInherited,
      })}
      id={'elementWatch'}
      onMouseOver={() => {
        if (isSubscribeInherited)
          Tooltip(
            '#elementWatch',
            t('notifications.inheritedMessage', {
              parentName:
                elementSubscriberList.length > 0 ? elementSubscriberList[0]?.inherit?.element?.elementName : 'Parent',
            }),
            'bottom',
            'custom-bottom',
          )
      }}>
      <button
        className="flex items-center justify-center"
        disabled={isSubscribeInherited}
        type="button"
        onClick={() => {
          onChangeWatchStatus(userId)
        }}>
        {isCurrentUserWatcher ? (
          <WatchedIcon
            className="me-1 text-primary hover:text-gray-600"
            onClick={(e: any) => {
              if (!isSubscribeInherited) {
                e.stopPropagation()
                onChangeWatchStatus(userId)
              }
            }}
          />
        ) : (
          <WatchIcon
            className="w-6 me-1 hover:text-primary"
            onClick={(e) => {
              e.stopPropagation()
              onChangeWatchStatus(userId)
            }}
          />
        )}
        {t('notifications.watch')}
        {isCurrentUserAnAdmin && <span className="ms-1">({elementSubscriberList?.length || 0})</span>}
      </button>
      {isCurrentUserAnAdmin && (
        <WatchersPopover
          elementId={elementId}
          elementMembers={elementMembers}
          hidText={true}
          isCurrentUserWatcher={isCurrentUserWatcher}
          isSubscribeInherited={isSubscribeInherited}
          isTippyInit={isTippyInit}
          setIsTippyInit={setIsTippyInit}
          setVisible={setVisible}
          visible={visible}
          watchersList={elementSubscriberList}
          onClick={onChangeWatchStatus}
        />
      )}
    </div>
  )
}

export default WatchButton
