import { createAsyncThunk } from '@reduxjs/toolkit'
import forEach from 'lodash/forEach'
import { boolToNumber } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { AppElementAssetTypes } from 'utils/types/AssetsType'
import baseAPI from '../../utils/baseAPI'
import { formattedElementTree } from '../../utils/formatData/documentation'

export const getElementTree = createAsyncThunk(
  'element/getElementTree',
  async (props: { elementId: number, documentFlag?: boolean, getArchived?: boolean }) => {
    const { elementId, documentFlag = false, getArchived = false } = props
    const arc = boolToNumber(getArchived)
    const response = await baseAPI(`api/element/parent/${elementId}?documentFlag=${documentFlag}&arc=${arc}`)
    const formattedData = formattedElementTree(response.body?.elements)
    return formattedData
  },
)

export const getElementUsers = createAsyncThunk(
  'element/getElementUsers',
  async (elementId: number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/${elementId}/users`)

      if (response.status >= 400) throw response.message

      return response.body
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getListLinkOptions = createAsyncThunk('element/getListLinkOptions', async (elementId: number) => {
  const response = await baseAPI(`api/element/parent/${elementId}`)
  const formattedData = formattedElementTree(response.body?.elements)
  return { listId: elementId, optionsList: formattedData }
})

export const createElementLink = createAsyncThunk(
  'element/createElementLink',
  async (props: { body: any, force?: boolean, shouldDisplayToast?: boolean }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(
        `api/element/links?force=${props.force || false}`,
        {
          body: props.body,
          method: 'POST',
        },
        false,
        props.shouldDisplayToast === undefined ? true : props.shouldDisplayToast,
      )

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteElementLink = createAsyncThunk(
  'element/deleteElementLink',
  async (elementId: number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/element/links/${elementId}`, { method: 'DELETE' })

      if (+response.status >= 400) throw response.message

      return elementId
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getElementLinkedRecords = createAsyncThunk(
  'element/getElementLinkedRecords',
  async (elementId: number) => {
    const response = await baseAPI(`api/element/links/${elementId}`)
    return response.body
  },
)
//Notification Actions
export const onAddAppElementSubscriber = createAsyncThunk('element/onAddAppElementSubscriber', async (body: any) => {
  const response = await baseAPI(`api/appElementSubscriber`, { body: body, method: 'POST' })
  return response.body
})

export const onDeleteAppElementSubscriber = createAsyncThunk(
  'element/onDeleteAppElementSubscriber',
  async (body: any) => {
    const response = await baseAPI(`api/appElementSubscriber/${body.userId}/${body.elementId}`, {
      body: body,
      method: 'DELETE',
    })
    return { result: response.body, userId: body.userId }
  },
)

export const getElementSubscriber = createAsyncThunk(
  'element/getElementSubscriber',
  async (elementId: string | number) => {
    const response = await baseAPI(`api/appElementSubscriber/${elementId}`)
    return response.body
  },
)

export const getElementById = createAsyncThunk('element/getElementById', async (elementId: string | number) => {
  const response = await baseAPI(`api/element/${elementId}`)
  return response.body
})

export const getNotificationsList = createAsyncThunk('element/getNotificationsList', async () => {
  const response = await baseAPI(`api/activityQueue`)
  return response.body
})

export const updateNotificationStatus = createAsyncThunk(
  'element/updateNotificationStatus',
  async (notification: { id: string | number, status: string, notificationItem: any }, { rejectWithValue }) => {
    try {
      const body = {
        status: notification.status,
      }
      const response = await baseAPI(`api/activityQueue/${notification.id}`, { body, method: 'PUT' })

      if (+response.status >= 400) throw response.message

      return { body: response.body, id: notification.id }
    } catch (err: any) {
      return rejectWithValue(notification.notificationItem)
    }
  },
)

export const updateAllNotifications = createAsyncThunk(
  'element/updateAllNotifications',
  async (notification: { status: string }) => {
    const response = await baseAPI(`api/activityQueue/change/${notification.status}`, { method: 'Put' })
    return response.body
  },
)

export const updateElementOrder = createAsyncThunk(
  'element/updateElementOrder',
  async (element: { id: string | number, newPosition: number }) => {
    const { id, newPosition } = element
    const body = {
      newPosition,
    }
    const response = await baseAPI(`api/element/${id}/move`, { body, method: 'PUT' })
    return response.body
  },
)

export const updateStationCustomFields = createAsyncThunk(
  'element/updateStationCustomFields',
  async ({ stationId, body }: { stationId: string | number, body: any }) => {
    const response = await baseAPI(`api/stations/${stationId}/update`, { body, method: 'Put' })
    return response.body
  },
)
export const updateListCustomFields = createAsyncThunk(
  'element/updateListCustomFields',
  async ({ listId, body }: { listId: string | number, body: any }) => {
    const response = await baseAPI(`api/lists/${listId}/update`, { body, method: 'Put' })
    return response.body
  },
)

export const archiveElement = async (
  elementId: number,
  status: number,
  recursive: number,
  rejectWithValue: (value: number) => void,
) => {
  const response = await baseAPI(`api/element/archive/${status}/${recursive}/${elementId}`, { method: 'PUT' })
  if (response.status >= 400) {
    return rejectWithValue(400)
  }
  return elementId
}

export const onMoveElement = async (body: any, rejectWithValue: (value: number) => void) => {
  const response = await baseAPI(`api/element/copy`, { body: body, method: 'POST' })
  if (response.status >= 400) {
    return rejectWithValue(400)
  }
  return { ...body, response: response.body }
}

export const getWidgetData = createAsyncThunk(
  'element/getWidgetData',
  async (widgetId: number, { rejectWithValue }) => {
    const response = await baseAPI(`api/widgets/${+widgetId}`)
    if (response.status >= 400) {
      return rejectWithValue(400)
    }
    return { id: widgetId, ...response.body }
  },
)
export const onCreateElementDashboard = async (body: any, rejectWithValue: (value: number) => void) => {
  const response = await baseAPI(`api/dashboards`, { body, method: 'POST' })
  if (response.status >= 400) {
    return rejectWithValue(400)
  }
  return response.body
}

export const onDeleteElementDashboard = async (elementId: number, rejectWithValue: (value: number) => void) => {
  const response = await baseAPI(`api/dashboards/${elementId}`, { method: 'DELETE' })
  if (response.status >= 400) {
    return rejectWithValue(400)
  }
  return { ...response.body, elementId }
}

// export const onMoveElement = async (body: any) => {
//   const { newParentId, appElementId } = body
//   // const response = await baseAPI(`api/element/${appElementId}/move`, { body, method: 'Put' })
//   const response = await baseAPI(`api/element/duplicate`, { body, method: 'POST' })
//   return { ...response.body, newParentId }
// }

export const getElementCustomFields = createAsyncThunk(
  'element/getElementCustomFields',
  async (appElementId: number, { rejectWithValue }) => {
    const response = await baseAPI(`api/element/${appElementId}/customfields/appliesOnChildren`)
    if (response.status >= 400) {
      return rejectWithValue(400)
    }
    return response.body
  },
)

//Maker & Checker feature
export const onAddMakerChecker = createAsyncThunk('element/onAddMakerChecker', async (bodyData: any) => {
  const body = {
    ...bodyData,
    appliesOnArenaElement: false,
    appliesOnElement: false,
    appliesOnTenancyElement: false,
    appliesOnWikiChildren: false,
    appliesOnWikiPageChildren: false,
    appliesOnWorkspaceChildren: false,
  }
  const response = await baseAPI(`api/element/makerchecker/policies`, { body, method: 'POST' })
  return response.body
})

export const fetchElementMakerCheckerPolicy = createAsyncThunk(
  'element/fetchElementMakerCheckerPolicy',
  async (elementId: string) => {
    const response = await baseAPI(
      `api/element/makerchecker/policies/appliedOnElement/element/${elementId}`,
      {},
      false,
      false,
    )
    return response.body
  },
)

export const fetchElementMakerCheckerPolicyByElement = createAsyncThunk(
  'element/fetchElementMakerCheckerPolicyByElement',
  async (elementId: string) => {
    const response = await baseAPI(`api/element/makerchecker/policies/byElement/element/${elementId}`, {}, false, false)
    return response.body
  },
)

export const fetchElementMakerCheckerPolicyRequests = createAsyncThunk(
  'element/fetchElementMakerCheckerPolicyRequests',
  async (elementId: string | number) => {
    const response = await baseAPI(`api/element/makerchecker/changerequests/element/${elementId}`, {}, false, false)
    return response.body
  },
)

export const updateElementMakerCheckerPolicy = createAsyncThunk(
  'element/updateElementMakerCheckerPolicy',
  async (props: { makerCheckerPolicyId: string, bodyData: any }) => {
    const { makerCheckerPolicyId, bodyData } = props
    const body = {
      ...bodyData,
      appliesOnArenaElement: false,
      appliesOnElement: false,
      appliesOnTenancyElement: false,
      appliesOnWikiChildren: false,
      appliesOnWikiPageChildren: false,
      appliesOnWorkspaceChildren: false,
    }
    const response = await baseAPI(`api/element/makerchecker/policies/${makerCheckerPolicyId}`, { body, method: 'PUT' })
    return response.body
  },
)

export const removeElementMakerCheckerPolicy = createAsyncThunk(
  'element/removeElementMakerCheckerPolicy',
  async (makerCheckerPolicyId: number) => {
    const response = await baseAPI(`api/element/makerchecker/policies/${makerCheckerPolicyId}`, { method: 'DELETE' })
    return response.body
  },
)

export const updateRequestStatus = createAsyncThunk(
  'element/updateRequestStatus',
  async (props: { body: any, changeRequestId: string, status: number }, { rejectWithValue }) => {
    try {
      const { body, changeRequestId, status } = props
      const response = await baseAPI(`api/element/makerchecker/changerequests/${changeRequestId}/${status}`, {
        body,
        method: 'PUT',
      })

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const createNewView = createAsyncThunk('element/createNewView', async (body: any, { rejectWithValue }) => {
  const response = await baseAPI(`api/element/views`, { body, method: 'POST' })
  if (response.status >= 400) {
    return rejectWithValue(400)
  }
  return response.body
})

export const updateView = createAsyncThunk(
  'element/updateView',
  async (props: { id: number, body: any }, { rejectWithValue }) => {
    const { id, body } = props
    const response = await baseAPI(`api/element/views/${id}`, { body, method: 'PUT' })
    if (response.status >= 400) {
      return rejectWithValue(400)
    }
    return response.body
  },
)

export const getElementCustomFieldsByParentPath = createAsyncThunk(
  'element/getElementCustomFieldsByParentPath',
  async (options: { parentPath: number, elementBaseType: AppElementBaseTypes }, { rejectWithValue }) => {
    try {
      const { parentPath, elementBaseType } = options
      const response = await baseAPI(`api/element/customfields/byBasetypeAndPath/${elementBaseType}?path=${parentPath}`)

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const createAsset = createAsyncThunk(
  'element/createAsset',
  async (
    newAttachment: {
      file: any,
      elementId: number,
      assetType: AppElementAssetTypes,
      commentId?: number,
      isPublic?: boolean,
    },
    { rejectWithValue },
  ) => {
    try {
      const { elementId, assetType, commentId, isPublic = false } = newAttachment
      const formData = new FormData()
      forEach(newAttachment, (value: any, fieldName: string) => {
        formData.append(fieldName, value)
      })
      const response = await baseAPI(
        `api/element/assets/element/${elementId}?assetType=${assetType}&isPublic=${isPublic}${
          commentId ? `&commentId=${commentId}` : ''
        }`,
        {
          body: formData,
          method: 'POST',
        },
      )
      if (response.status === 404) return rejectWithValue(404)
      return response.body
    } catch (err) {
      return err
    }
  },
)

export const deleteAsset = createAsyncThunk(
  'element/deleteAsset',
  async (options: { assetId: number }, { rejectWithValue }) => {
    try {
      const { assetId } = options

      const response = await baseAPI(`api/element/assets/${assetId}`, { method: 'DELETE' })

      if (response.status === 404) return rejectWithValue(404)
      return response.body
    } catch (err) {
      return err
    }
  },
)
