import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import Tour from 'reactour'
import { useMedia } from 'react-use'

import { useStationsList, useStationsLoading } from 'features/station'
import {
  getFilteredTasksWidget,
  getMyTasksWidget,
  useCurrentWorkspace,
  useMyTasksWidget,
  useIsMyTasksLoading,
  useWorkspaceDashboardLoading,
} from 'features/workspace'

import Loader from 'components/Loader'
import Button, { ButtonVariant } from 'components/Buttons'
import { EmptyStationsIcon, CircledPlusIcon, OrderIcon } from 'components/Icons'
import { usePanel } from 'contexts/PanelContext'
import { AddStationToWorkspaceForm } from 'components/forms'
import EmptyStatePage from 'components/EmptyStatePage'
import { TourContent } from 'components/Tour'
import { withEditorRole } from 'hocs/withRole'
import StationTileListing from 'components/StationTileListing'
import { useNavbar } from 'contexts/NavbarContext'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { useFilters } from '../../features/record'
import Header from '../../components/Header'
import ArchivePanel from '../../components/ArchivePanel/ArchivePanel'
import SideBarMyTasksWidget from '../../components/Dashboard/Widgets/SideBarMyTasksWidget/SideBarMyTasksWidget'

const AddStationButton = withEditorRole(Button)

const StationsByWorkspaceListPage: FC<{
  setIsArchived: (value: boolean) => void,
  isArchived: boolean,
  isArchivedByWorkspace: boolean,
}> = ({ setIsArchived, isArchived, isArchivedByWorkspace }) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const { t } = useTranslation('station')
  const dispatch = useDispatch()
  const { location } = useHistory()
  const allFilters = useFilters()
  const currentWorkspace = useCurrentWorkspace()
  const secondStepDone = localStorage.getItem('secondStepDone') === 'true'
  const stations = useStationsList()
  const areStationsLoading = useStationsLoading()
  const [isAllRecords, setIsAllRecords] = useState(false)
  const [requestSearch, setRequestSearch] = useState('')
  const [isReorderMode, setIsReorderMode] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const dashboardData = useMyTasksWidget()
  const isLoading = useWorkspaceDashboardLoading()
  const isDashboardDataLoading = useIsMyTasksLoading()
  const [currentWidgetData, setCurrentWidgetData] = useState('myTasks')
  const { openPanel, setPanelContent } = usePanel()
  const { toggleMenu } = useNavbar()
  const isLoadForFirstTime = currentWidgetData === 'myTasks' && allFilters?.length === 0 && !isAllRecords
  const recordsList = isLoadForFirstTime ? dashboardData?.myDueRecordsWidget : dashboardData?.records

  const hideAddButton = isArchived || currentWorkspace?.appElement?.archivedById !== null
  const isLgBreakpoint = useMedia('(min-width: 1024px)')
  const [isTourOpen, setTourOpen] = useState(!secondStepDone)

  const isWorkspaceEmpty = isEmpty(stations)

  const addStationClick = () => {
    setTourOpen(false)
    openPanel()
  }
  // const fetchSideBarWidget = () => {
  //   if (currentWidgetData === 'watched') {
  //     dispatch(getFilteredTasksWidget('watched=true'))
  //   } else {
  //     dispatch(getMyTasksWidget(currentWorkspace?.appElement?.id))
  //   }
  // }
  useEffect(() => {
    if (!isFirstLoad) {
      let filterUpdate = requestSearch
      if (currentWidgetData === 'watched' && filterUpdate.indexOf('watched') === -1) {
        filterUpdate += filterUpdate.length > 0 ? `&watched=true` : `watched=true`
      }
      // if (filterUpdate.length > 0 || isAllRecords)
      dispatch(getFilteredTasksWidget(`appElementId=${currentWorkspace?.appElement?.id}&${filterUpdate}`))
    }
  }, [currentWidgetData, requestSearch, isAllRecords])

  useEffect(() => {
    let filterUpdate = ''
    if (allFilters?.length > 0) {
      allFilters.map((f) => {
        filterUpdate += filterUpdate.length > 0 ? `&${f[0]}=${f[1]}` : `${f[0]}=${f[1]}`
      })
    }
    setRequestSearch((prev) => (currentWidgetData === 'watched' ? `watched=true&${filterUpdate}` : filterUpdate))
  }, [allFilters.length])

  const refetchFunction = () => {
    if (currentWidgetData === 'watched') {
      return getFilteredTasksWidget(`appElementId=${currentWorkspace?.appElement?.id}&watched=true`)
    }
    return getMyTasksWidget(currentWorkspace?.appElement?.id)
  }
  useEffect(() => {
    if (workspaceId && currentWidgetData === 'myTasks' && !isAllRecords) {
      dispatch(getMyTasksWidget(currentWorkspace?.appElement?.id))
      setIsFirstLoad(false)
    }
  }, [workspaceId, isArchived, currentWidgetData, isAllRecords])

  useEffect(() => {
    const recordInfo = location?.search?.split('Id=')
    if (recordInfo.length > 1) {
      setIsSideBarOpen(true)
    }
    setPanelContent({
      content: <AddStationToWorkspaceForm workspaceId={+workspaceId} />,
      header: t('createStation'),
    })
  }, [])

  const { pinned = [], unpinned = [] } = groupBy(stations, ({ isPinned }) => (isPinned ? 'pinned' : 'unpinned'))
  const showArchiveButton = +currentWorkspace?.appElement?.id !== +currentWorkspace?.appElement?.archivedById
  const steps = [
    {
      content: function stationIntro({ goTo }: { goTo: (step: number) => void }) {
        return (
          <TourContent
            closeTour={() => setTourOpen(false)}
            goTo={() => goTo(1)}
            header={t('tour:stationIntroHeader')}
            message={t('tour:stationIntroText')}
          />
        )
      },
      selector: '',
    },
    {
      action: () => localStorage.setItem('secondStepDone', 'true'),
      content: function secondStep() {
        return <TourContent closeTour={() => setTourOpen(false)} message={t('tour:secondStepText')} />
      },
      selector: '[data-tut="second__step"]',
    },
  ]

  return (
    <div className="w-full h-full">
      {isArchived && (
        <div className="flex w-full">
          <ArchivePanel message={t('archiveStationsMessage')} />
        </div>
      )}
      <Tour
        isOpen={isTourOpen}
        showButtons={false}
        showNavigation={false}
        showNumber={false}
        steps={steps}
        onRequestClose={() => setTourOpen(false)}
      />
      <div className={`${isArchived && 'mt-8'} w-full`}>
        <Header
          buttonTitle={t('addStation')}
          buttonTitle2={t('reorderStations')}
          currentItemName={currentWorkspace?.name}
          icon={CircledPlusIcon}
          icon2={OrderIcon}
          isArchived={isArchived}
          isArchivedByWorkspace={isArchivedByWorkspace}
          isButtonActive={isReorderMode}
          setIsArchived={setIsArchived}
          showArchived={showArchiveButton}
          showBack={false}
          title={t('stations')}
          toggleMenu={toggleMenu}
          onClick={isWorkspaceEmpty ? undefined : addStationClick}
          onClick2={isWorkspaceEmpty ? undefined : () => setIsReorderMode(!isReorderMode)}
        />
      </div>
      {!isWorkspaceEmpty && (
        <>
          {/*<header className="relative items-center mb-6 ms-10 lg:flex lg:min-h-12">*/}
          {/*  <Button*/}
          {/*    className="absolute -start-12 -top-2 lg:hidden"*/}
          {/*    data-tut="mobile__sidebar"*/}
          {/*    icon={MenuIcon}*/}
          {/*    variant={ButtonVariant.Icon}*/}
          {/*    onClick={() => toggleMenu(true)}*/}
          {/*  />*/}
          {/*  <h1 className="mb-4 me-auto lg:mb-0">*/}
          {/*    {currentWorkspace?.name} <span className="font-normal ps-2">{t('stations')}</span>*/}
          {/*  </h1>*/}
          {/*  <AddStationButton*/}
          {/*    className="shrink-0"*/}
          {/*    data-tut="second__step"*/}
          {/*    icon={CircledPlusIcon}*/}
          {/*    variant={ButtonVariant.Outline}*/}
          {/*    onClick={addStationClick}>*/}
          {/*    {t(`addStation`)}*/}
          {/*  </AddStationButton>*/}
          {/*</header>*/}
        </>
      )}
      {areStationsLoading && (
        <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />
      )}

      {/* return pinned and unpinned statons after successful loading */}
      {isWorkspaceEmpty && !areStationsLoading && (
        <EmptyStatePage
          Button={
            !hideAddButton
              ? () => (
                  <AddStationButton
                    data-tut="second__step"
                    icon={CircledPlusIcon}
                    variant={ButtonVariant.Outline}
                    onClick={addStationClick}>
                    {t(`addStation`)}
                  </AddStationButton>
                )
              : null
          }
          Icon={EmptyStationsIcon}
          description={t('noStationsDescription')}
          header={t('noStationsHeader', { interpolation: { escapeValue: false }, name: currentWorkspace?.name })}
        />
      )}
      <div className="flex flex-col-reverse w-full lg:flex-row">
        {!areStationsLoading && (
          <div
            className={classNames('flex flex-wrap w-full')}
            style={{ width: isLgBreakpoint ? (isSideBarOpen ? '66%' : 'calc(100% - 42px)') : '100%' }}>
            <div className="w-full">
              <StationTileListing
                header={t('pinned')}
                isArchived={isArchived}
                isReorderMode={isReorderMode}
                isSideBarOpen={isSideBarOpen}
                setIsReorderMode={setIsReorderMode}
                stations={pinned}
                workspaceId={workspaceId}
              />
              <StationTileListing
                header={t('recent')}
                isArchived={isArchived}
                isReorderMode={isReorderMode}
                isSideBarOpen={isSideBarOpen}
                setIsReorderMode={setIsReorderMode}
                stations={unpinned}
                workspaceId={workspaceId}
              />
            </div>
          </div>
        )}
        {!isArchived && !isReorderMode && !isWorkspaceEmpty && (
          <SideBarMyTasksWidget
            classes="lg:-top-10"
            currentWidgetData={currentWidgetData}
            dashboardWidget={recordsList}
            isAdmin={false}
            isSideBarOpen={isSideBarOpen}
            isStopLoading={!isLoading}
            isAllRecords={isAllRecords}
            setIsFirstLoad={setIsFirstLoad}
            hasFilter={allFilters?.length}
            setRequestSearch={setRequestSearch}
            setIsAllRecords={setIsAllRecords}
            isDashboardDataLoading={isDashboardDataLoading}
            refetchFunction={() => refetchFunction()}
            setCurrentWidgetData={setCurrentWidgetData}
            setIsSideBarOpen={setIsSideBarOpen}
          />
        )}
      </div>
    </div>
  )
}

export default StationsByWorkspaceListPage
