import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { last } from 'lodash'
import { useDispatch } from 'react-redux'

import { useCurrentUser, useUserAvatar } from 'features/user'
import { addOrUpdateFilter, useFilters } from 'features/record'

const MyRecordsFilter: FC = ({ closeDropdown }) => {
  const avatar = useUserAvatar()
  const allFilters = useFilters()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { t } = useTranslation('records')

  const filterRecords = useCallback(() => {
    const lastElement = last(allFilters) || [0, 0, 0]
    const ownerFilter = allFilters?.find(
      (singleFilter) => singleFilter[0] === 'owner' && +singleFilter[1] === currentUser.id,
    )
    if (!ownerFilter) {
      dispatch(addOrUpdateFilter(['owner', `${currentUser.id}`, `${+lastElement[2] + 1}`]))
    }
    closeDropdown?.()
  }, [])

  return (
    <div className="flex justify-between w-full border-b-2 border-gray-100">
      <button className="flex items-center py-2 hover:text-primary transition-colors" onClick={filterRecords}>
        <img
          alt="user avatar"
          className="w-6 h-6 border-2 border-gray-200 rounded-full md:w-6 md:h-6 me-1"
          loading="lazy"
          src={avatar || '/assets/images/placeholder.svg'}
        />
        {t('myRecords')}
      </button>
    </div>
  )
}

export default MyRecordsFilter
