import { createAsyncThunk } from '@reduxjs/toolkit'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import baseAPI from '../../utils/baseAPI'

export const getVerifiedAndNotVerifiedUsers = createAsyncThunk(
  'admin/getVerifiedAndNotVerifiedUsers',
  async (status: boolean, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/users/byVerified/${status}`)

      if (+response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateVerifiedUser = createAsyncThunk(
  'admin/updateVerifiedUser',
  async (data: { userId: string | number, status: boolean }) => {
    const response = await baseAPI(`api/users/verified/${data.userId}`, {
      body: { verified: data.status },
      method: 'PUT',
    })
    if (response.status === 200) {
      const resMessage = data.status ? i18n.t(`notifications:userVerified`) : i18n.t(`notifications:userUnVerified`)
      toast(resMessage, { icon: false })
    }
    return data.userId
  },
)
