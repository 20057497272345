import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/inputs'

import { addOrUpdateFilter } from 'features/record'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'

type NameFilterType = {
  filterIndex: number,
  filterValue: string | undefined,
  filterName: string,
  fieldType: number,
}

const CustomFieldFilter: FC<NameFilterType> = ({ filterIndex, filterValue = '', filterName, fieldType }) => {
  const [currentValue, setCurrentValue] = useState<string>(filterValue)
  const dispatch = useDispatch()
  const { t } = useTranslation('records')

  const setNewFilter = (value: string) => {
    dispatch(addOrUpdateFilter([filterName, value, `${filterIndex}`]))
  }

  const labelContent = () => {
    switch (fieldType) {
      case customFieldBaseTypes.Text:
        return t('contains')

      case customFieldBaseTypes.Number:
        return t('is')

      default:
        return t('is')
    }
  }

  return (
    <>
      <span className="mx-2">{labelContent()}</span>
      <Input
        classes={{ input: 'hover:border-primary', wrapper: 'flex-1 -mb-0' }}
        error=""
        name="filter"
        touched={false}
        value={currentValue}
        onBlur={(event) => {
          setNewFilter(event.target.value)
        }}
        onChange={(event) => {
          setCurrentValue(event.target.value)
        }}
      />
    </>
  )
}

export default CustomFieldFilter
