import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { configValues } from 'utils/appConfig'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production' && configValues.IS_SENTRY_ENABLED)
  Sentry.init({
    dsn: `https://${configValues.REACT_APP_SENTRY_DOMAIN}.ingest.sentry.io/${configValues.REACT_APP_SENTRY_TOKEN}`,
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  })
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
