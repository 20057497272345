import React, { useCallback, useState, FC, useEffect } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { useGridViewMethods } from 'contexts/GridViewContext'
import { archiveBulkRecord, deleteRecordById } from 'features/record'
import ConfirmationModal from 'components/ConfirmationModal'
import { ArchiveIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import { useAppDispatch } from 'store'
import { useRoles } from '../../hooks'

const GridToolbar: FC<{ isArchived: boolean }> = ({ isArchived }) => {
  const toolbarParent = document.getElementById('list-view-toolbar')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const appDispatch = useAppDispatch()
  const { isEditor, isAdmin } = useRoles()

  const { selectedRecords, clearSelectedRecords } = useGridViewMethods()
  const { t } = useTranslation('records')

  useEffect(() => {
    clearSelectedRecords()
  }, [isArchived])

  const deleteSelected = useCallback(() => {
    const recordsToDelete = selectedRecords.map((recordId) =>
      appDispatch(deleteRecordById(recordId)).then(unwrapResult),
    )
    Promise.all(recordsToDelete).then(() => {
      clearSelectedRecords()
      setIsModalVisible(false)
    })
  }, [selectedRecords])

  const archiveSelected = useCallback(() => {
    appDispatch(archiveBulkRecord({ isArchived, recordsIds: selectedRecords })).then(unwrapResult)
    Promise.all(selectedRecords).then(() => {
      clearSelectedRecords()
      setIsModalVisible(false)
    })
  }, [selectedRecords])

  const content =
    !isEmpty(selectedRecords) && (isAdmin || isEditor) ? (
      <>
        <Button
          className="self-center ms-2 md:ms-4"
          icon={ArchiveIcon}
          small
          variant={ButtonVariant.Danger}
          onClick={() => {
            setIsModalVisible(true)
          }}>
          {t(isArchived ? 'common:labels.unArchiveSelectedRecords' : 'common:labels.archiveSelectedRecords', {
            count: selectedRecords.length,
          })}
        </Button>
        <ConfirmationModal
          buttonVariant={ButtonVariant.Primary}
          cancelMessage={t('no')}
          confirmMessage={t('yes')}
          confirmationMessage={t(!isArchived ? 'confirmArchivingSelectedRecords' : 'confirmUnArchivingSelectedRecords')}
          isModalOpen={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onConfirm={archiveSelected}
        />
        <ConfirmationModal
          buttonVariant={ButtonVariant.Danger}
          cancelMessage={t('common:labels.cancel')}
          confirmMessage={t('common:labels.archive')}
          confirmationMessage={t(!isArchived ? 'confirmArchivingSelectedRecords' : 'confirmUnArchivingSelectedRecords')}
          isModalOpen={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onConfirm={archiveSelected}
        />
      </>
    ) : null

  return toolbarParent && ReactDOM.createPortal(content, toolbarParent)
}

export default GridToolbar
