import Chart from 'components/Dashboard/Chart/Chart'
import React from 'react'

const ChartIcon = ({ dataValue, onClick }) => {
  const data = {
    datasets: [
      {
        backgroundColor: ['#00B2A9', '#DDE5ED'],
        data: [dataValue, 100 - dataValue],
      },
    ],
    labels: ['', ''],
  }
  return (
    <div className="w-6 cursor-pointer" onClick={onClick}>
      <Chart
        datasets={data.datasets}
        options={{
          animation: false,
          hover: { mode: null },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
        type="pie"
      />
    </div>
  )
}
export default ChartIcon
