import React, { FC, useCallback, useMemo } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store'
import { createListStatus, useCurrentListStatuses } from 'features/list'
import ColoredDropdownOption from 'components/inputs/LabelsSelect/ColoredDropdownOption'
import { Dropdown } from 'components/inputs'
import { DropdownOptionType } from 'utils/types/inputs'
import { CellEditorProps, RecordType } from 'utils/types'
import { useGridViewNavigation } from 'hooks'

const StatusEditorCell: FC<CellEditorProps<RecordType['statusId']>> = ({
  autoFocus,
  onChange,
  onComplete,
  onTabNavigation,
  value,
  isGridView,
}) => {
  useGridViewNavigation(
    () => onTabNavigation(true, -1),
    () => onTabNavigation(true, 1),
  )
  const { t } = useTranslation('records')
  const appDispatch = useAppDispatch()
  const statuses = useCurrentListStatuses()
  const statusesOptions: DropdownOptionType[] = useMemo(
    () => [
      { label: t('notAssigned'), value: null },
      ...statuses
        .map(({ id, name, color, position }) => ({ color, label: name, position, value: `${id}` }))
        .sort((a, b) => a.position - b.position),
    ],
    [statuses, t],
  )
  const findStatusById = useCallback(
    (statusId: number | null) => statusesOptions.find(({ value }) => +value === statusId) || null,
    [statusesOptions],
  )
  const { color } = useMemo(() => (value && findStatusById(+value)) || {}, [value, statuses])

  return (
    <div
      className="w-full"
      onKeyDown={(e) => {
        e.preventDefault()
      }}>
      <Dropdown
        autoFocus={autoFocus}
        className="-m-2"
        classes={{ wrapper: 'mx-2' }}
        components={{ Option: ColoredDropdownOption }}
        isClearable
        isCreatable
        isGridView={isGridView}
        isSearchable={false}
        menuPortalTarget={document.body}
        name="statusId"
        openMenuOnFocus={autoFocus}
        options={statusesOptions}
        shouldShowPlaceholder
        styles={{
          clearIndicator: (base: any) => ({ ...base, color: color }),
          control: () => ({ backgroundColor: `${color}10` }),
          input: () => ({ color: color }),
          singleValue: () => ({ color: color }),
        }}
        value={value}
        onChange={(e) => {
          const newEvent = { ...e, target: { ...e.target, value: e.target.value === 'null' ? null : e.target.value } }
          onChange(newEvent)
          setTimeout(() => onComplete(newEvent), 0)
        }}
        onCreateOption={async (inputValue: string) => {
          const wrappedResults = await appDispatch(createListStatus({ listId: +listId, name: inputValue }))
          const results = await unwrapResult(wrappedResults)
          return results.id
        }}
      />
    </div>
  )
}

export default StatusEditorCell
