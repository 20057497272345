import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useUserAvatar } from 'features/user'
import { ProfilePropsType } from 'utils/types'

import { changeUserLanguage } from 'utils/helpers/profileHelpers'
import {
  CustomLogoText,
  MohimmatechLogoText,
  NoNotificationsIcon,
  NotificationsIcon,
  TranslateIcon,
} from 'components/Icons'
import { configValues } from 'utils/appConfig'
import Logout from '../Logout'
import Button, { ButtonVariant } from '../Buttons'
import NotificationsModal from '../Notifications/NotificationsModal'
import { useNotifications } from '../../features/element'

interface HeroProps {
  user: ProfilePropsType;
}

const Hero: FC<HeroProps> = ({ user }) => {
  const avatar = useUserAvatar()
  const { firstName, lastName, email, isAdmin } = user
  const [isOpenNotifications, setIsOpenNotifications] = useState(false)
  const { t } = useTranslation()
  const notifications = useNotifications()
  const hasNotifications = notifications && notifications.length > 0

  return (
    <div
      className={classnames('flex flex-col items-start w-full mb-8 col-span-2', {
        'custom-hero': configValues.REACT_APP_IS_CUSTOM_BRANDING,
        hero: !configValues.REACT_APP_IS_CUSTOM_BRANDING,
      })}
      style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.heroBgClasses } : {}}>
      <nav
        className="flex flex-col w-full px-6 py-6 md:px-10 bg-gradient-to-b/80 from-white sm:flex-row md:items-center backdrop-filter backdrop-blur-sm"
        style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.homeNavClasses } : {}}>
        <Link className="flex mb-4 me-auto sm:mb-0" to="/">
          {configValues.REACT_APP_IS_CUSTOM_BRANDING ? (
            <CustomLogoText
              style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.customLogoTextClasses } : {}}
            />
          ) : (
            <MohimmatechLogoText className="h-10 md:h-12" />
          )}
        </Link>
        <div className="flex flex-col items-start sm:items-end md:flex-row md:gap-6 md:ms-5 md:items-center lg:gap-10">
          {/*<Button*/}
          {/*  className="flex items-center py-2 transition transition-colors hover:text-primary focus:text-primary md:me-10"*/}
          {/*  icon={NotificationsIcon}*/}
          {/*  variant={ButtonVariant.Icon}*/}
          {/*/>*/}
          <button
            className="flex items-center py-2 transition hover:text-primary focus:text-primary"
            onClick={changeUserLanguage}>
            <TranslateIcon className="w-6 -my-1 rounded-sm me-2" />
            {t('labels.switchLanguage')}
          </button>
          <Link className="py-2 transition hover:text-primary focus:text-primary" to="/profile">
            {t('labels.profileSettings')}
          </Link>
          {isAdmin && (
            <Link className="py-2 transition hover:text-primary focus:text-primary" to="/admin/users/view">
              {t('navbarRoutes:management')}
            </Link>
          )}
          <a
            className="py-2 transition hover:text-primary focus:text-primary"
            href="https://mohimma.tech/getting-started"
            rel="noreferrer"
            target="_blank">
            {t('labels.howToPage')}
          </a>
          <Button
            className="flex items-center py-2 transition hover:text-primary focus:text-primary"
            icon={hasNotifications ? NotificationsIcon : NoNotificationsIcon}
            variant={ButtonVariant.Icon}
            onClick={() => setIsOpenNotifications(true)}
          />
          <div>
            <Logout />
          </div>
        </div>
      </nav>
      <div className="w-full ps-6 md:ps-10">
        <div className="w-full -mb-8 md:mt-8 pe-6 md:pe-10 flex-0 md:w-6/12 xl:w-4/12 2xl:w-3/12">
          <div className="flex flex-col items-start p-6 rounded backdrop-filter backdrop-blur rounded-ts-none bg-gradient-to-t from-white">
            <Link className="mb-4" to="/profile">
              <img
                alt="user avatar"
                className="w-20 h-20 rounded transition-shadow hover:shadow shadow-sm rounded-ts-none"
                src={avatar || '/assets/images/placeholder.svg'}
              />
            </Link>
            <h1>
              {firstName} {lastName}
            </h1>
            <h2>{email}</h2>
          </div>
        </div>
      </div>
      <NotificationsModal isModalOpen={isOpenNotifications} onClose={() => setIsOpenNotifications(false)} />
    </div>
  )
}

export default Hero
