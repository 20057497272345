import DashboardStateType from 'utils/types/states/DashboardStateType'

export const mockGetResponse: DashboardStateType = {
  id: 1,
  widgets: [
    {
      availableScopes: ['station'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'custom',
        },
      ],
      baseType: 0,
      chartType: 0,
      data: {
        completed: 15,
        duration: 228,
        endDate: '03 May 2021',
        id: 1,
        startDate: '03 May 2021',
      },
      dimensions: {
        h: 2,
        minH: 0,
        minW: 6,
        w: 8,
        x: 0,
        y: 0,
      },
      id: 1,
      isVisible: true,
      scope: 'station',
      widgetType: 'general',
    },
    {
      availableScopes: ['workspace', 'station', 'list'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'custom',
        },
      ],
      baseType: 1,
      chartType: 0,
      data: {
        dueSoon: 228,
        id: 1,
        myTasks: 148,
        noDueDate: 15,
        overdue: 228,
      },
      dimensions: {
        h: 2,
        minH: 0,
        minW: 5,
        w: 8,
        x: 0,
        y: 0,
      },
      id: 2,
      isVisible: true,
      scope: 'workspace',
      widgetType: 'user-based',
    },
    {
      availableScopes: ['workspace'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'bar',
        },
        {
          typeImage: '',
          typeName: 'pie',
        },
      ],
      baseType: 2,
      chartType: 2,
      data: {
        id: 1,
        labels: ['Lists'],
        values: [
          {
            backgroundColor: '#00837B',
            data: 9,
            label: 'Development',
          },
          {
            backgroundColor: '#00B2A9',
            data: 15,
            label: 'Marketing',
          },
          {
            backgroundColor: '#33647E',
            data: 5,
            label: 'Financial',
          },
          {
            backgroundColor: '#CDDE00',
            data: 12,
            label: 'Legal',
          },
          {
            backgroundColor: '#00CE7C',
            data: 19,
            label: 'Managerial',
          },
        ],
      },
      dimensions: {
        h: 2,
        minH: 2,
        minW: 3,
        w: 5,
        x: 0,
        y: 0,
      },
      id: 4,
      isVisible: true,
      scope: 'workspace',
      widgetType: 'general',
    },
    {
      availableScopes: ['station'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'pie',
        },
        {
          typeImage: '',
          typeName: 'bar',
        },
      ],
      baseType: 3,
      chartType: 2,
      data: {
        id: 1,
        labels: ['lists'],
        values: [
          {
            backgroundColor: '#00837B',
            data: 12,
            label: 'Mobile UI Design',
          },
          {
            backgroundColor: '#00B2A9',
            data: 19,
            label: 'Backend Development',
          },
        ],
      },
      dimensions: {
        h: 2,
        minH: 2,
        minW: 3,
        w: 5,
        x: 0,
        y: 0,
      },
      id: 5,
      isVisible: true,
      scope: 'station',
      widgetType: 'general',
    },

    {
      availableScopes: ['workspace'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'pie',
        },
        {
          typeImage: '',
          typeName: 'bar',
        },
      ],
      baseType: 4,
      chartType: 2,
      data: {
        id: 1,
        values: [
          {
            bgColor: 'red',
            id: 1,
            items: [
              {
                action: 1,
                creatorType: 1,
                activityDate: '01/01/2021',
                id: 1,
                handledAt: '01/01/2021',
                objectData: '',
                queuedAt: '01/01/2021',
                queueStatus: 1,
                relatedObjectId: '1',
                relatedObjectType: 1,
                userId: '1',
              },
            ],
            title: 'تطوير الواجهة',
          },
          {
            bgColor: 'blue',
            id: 1,
            items: [
              {
                action: 1,
                creatorType: 1,
                activityDate: '01/01/2021',
                id: 1,
                handledAt: '01/01/2021',
                objectData: '',
                queuedAt: '01/01/2021',
                queueStatus: 1,
                relatedObjectId: '1',
                relatedObjectType: 1,
                userId: '1',
              },
            ],
            title: 'التسويق',
          },
          {
            bgColor: '#00b2a9',
            id: 1,
            items: [
              {
                action: 1,
                creatorType: 1,
                activityDate: '01/01/2021',
                id: 1,
                handledAt: '01/01/2021',
                objectData: '',
                queuedAt: '01/01/2021',
                queueStatus: 1,
                relatedObjectId: '1',
                relatedObjectType: 1,
                userId: '1',
              },
            ],
            title: 'المالية',
          },
        ],
      },
      dimensions: {
        h: 2,
        w: 4,
        x: 5,
        y: 4,
      },
      id: 6,
      isVisible: true,
      scope: 'Workspace',
      widgetType: 'user-based',
    },
    {
      availableScopes: ['station'],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'custom',
        },
      ],
      baseType: 5,
      chartType: 0,
      data: {
        description: '',
        id: 1,
        title: '',
        status: 0,
        budgetHealth: 1,
        qualityHealth: null,
      },
      dimensions: {
        h: 2,
        minH: 2,
        minW: 4,
        w: 4,
        x: 5,
        y: 4,
      },
      id: 7,
      isVisible: true,
      scope: 'station',
      widgetType: 'general',
    },
    {
      availableScopes: [''],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'custom',
        },
      ],
      baseType: 'teamMonitor',
      chartType: 0,
      data: {
        id: 1,
        values: [
          {
            count: 20,
            icon: '',
            id: 1,
            items: [
              {
                onTime: 10,
                username: 'Mohammad',
                slightlyLate: 21,
                userPicture: '',
                veryLate: 1,
              },
              {
                onTime: 10,
                username: 'Khalid',
                slightlyLate: 21,
                userPicture: '',
                veryLate: 1,
              },
              {
                onTime: 10,
                username: 'Sara',
                slightlyLate: 21,
                userPicture: '',
                veryLate: 1,
              },
            ],
            title: 'All',
          },
          {
            count: 10,
            icon: '',
            id: 2,
            items: [
              {
                onTime: 13,
                completed: 20,
                username: 'John Doe',
                userPicture: '',
              },
            ],
            title: 'On track',
          },
          {
            count: 5,
            icon: '',
            id: 3,
            items: [
              {
                onTime: 10,
                completed: 20,
                username: 'John Doe',
                userPicture: '',
              },
            ],
            title: 'Late',
          },
          {
            count: 2,
            icon: '',
            id: 4,
            items: [
              {
                onTime: 10,
                completed: 20,
                username: 'John Doe',
                userPicture: '',
              },
            ],
            title: 'Very late',
          },
        ],
      },
      dimensions: {
        h: 2,
        w: 4,
        x: 5,
        y: 4,
      },
      id: 8,
      isVisible: true,
      scope: 'Workspace',

      widgetType: 'user-based',
    },
    {
      availableScopes: [''],
      availableTypes: [
        {
          typeImage: '',
          typeName: 'custom',
        },
      ],
      baseType: 'detailedRecords',
      chartType: 0,
      data: {
        headerRows: ['', 'Due Date', '% of Completion', 'Status', 'Health'],
        id: 1,
        values: [
          {
            folder: true,
            id: 1,
            name: 'Applications',
            nodes: [
              {
                dueDate: '11/11/2021',
                id: 1,
                '% of Completion': 12,
                name: 'Front end',
                Health: '100',
                nodes: null,
                Status: 'Done',
              },
            ],
          },
        ],
      },
      dimensions: {
        h: 2,
        w: 4,
        x: 5,
        y: 4,
      },
      id: 9,
      isVisible: true,
      scope: 'Workspace',
      widgetType: 'user-based',
    },
    {
      id: 11,
      availableTypes: [
        {
          typeName: 'custom',
          typeImage: '',
        },
      ],
      chartType: 0,
      baseType: 'text',
      isVisible: true,
      availableScopes: ['workspace'],
      scope: 'workspace',
      widgetType: 'general',
      description: '<p>Text Sample</p>',
      dimensions: {
        x: 0,
        y: 0,
        w: 4,
        h: 2,
        minW: 2,
        minH: 1,
      },
    },
  ],
}
