import { FC } from 'react'

const Loader: FC<{ svgClasses?: string, loaderClasses?: string }> = ({ svgClasses, loaderClasses }) => (
  <div className={`w-6 ${loaderClasses}`}>
    <svg className={`animate-spin ${svgClasses}`} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
        stroke="currentColor"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M12 3C16.9706 3 21 7.02944 21 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  </div>
)

export default Loader
