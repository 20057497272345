import { FC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { GoogleLogin as GLogin, CredentialResponse } from '@react-oauth/google'
import { toast } from 'react-toastify'
import baseAPI from 'utils/baseAPI'
import { useQuery, saveCampaignToLocalStorage } from 'utils/helpers/generalHelpers'
import { addTokensToLocalStorage } from 'utils/helpers/authHelpers'

type GoogleLoginPropsType = {
  isSignIn: boolean,
}

const GoogleLogin: FC<GoogleLoginPropsType> = ({ isSignIn }) => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const { t, i18n } = useTranslation('common')
  const campaign = localStorage.getItem('campaign') || query.get('from') || 'organic'
  saveCampaignToLocalStorage(campaign)
  const buttonText = isSignIn ? 'signin_with' : 'continue_with'

  const handleSuccess = async (response: CredentialResponse) => {
    const res = await baseAPI('auth/login/gc', {
      body: {
        token: response.credential,
        adCampaign: campaign,
      },
      headers: {
        Authorization: 'auth_token',
      },
      method: 'POST',
    })

    if (res.status === 200) {
      addTokensToLocalStorage({ body: res?.body })
      history.replace(get(location, 'state.backTo', '/'))
    }
  }

  return (
    <div className="flex mt-4 justify-center">
      <GLogin
        onSuccess={handleSuccess}
        onError={() => {
          toast.error(t('unexpectedError'))
        }}
        useOneTap
        size="large"
        shape="rectangular"
        locale={i18n.language === 'ar' ? 'ar' : 'en'}
        text={buttonText}
      />
    </div>
  )
}

export default GoogleLogin
