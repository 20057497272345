import React, { FC, memo, useEffect, useState } from 'react'
import DashboardStateWidgetType, { ActivityWidgetWithSortType } from 'utils/types/states/DashboardStateWidgetType'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { ActivityModules } from 'utils/types/ActivityLogType'
import { ActivityActions } from 'utils/constant/enums/notifications'
import ActivityForm from 'components/forms/ActivityForm'
import CustomScroll from '../../../CustomScroll'
import WidgetImage from '../../WidgetImage'
import DashboardWidgetType from '../../../../utils/types/DashboardWidgetType'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'

const ActivityWidgetWithSortVersion: FC<{
  widgetInfo: DashboardStateWidgetType,
  isHomeDashboard: boolean,
  isEdit: boolean,
  elementId: string,
  isPrinting: boolean,
  isAdmin?: boolean,
  isEditor?: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({
  widgetInfo,
  isHomeDashboard = false,
  isEdit,
  onUpdateWidgetImage,
  elementId,
  isPrinting,
  isAdmin,
  isEditor,
}) => {
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const { data }: any = widget || widgetInfo
  const [isLoading, setIsLoading] = useState(true)
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).finally(() => {
        setIsLoading(false)
      })
    }
  }, [widgetInfo.id])

  if (isDataLoading) {
    return <Loader />
  }

  const activityWidgetData: ActivityWidgetWithSortType = {
    ...data,
    activities: data?.activities?.filter(
      (item: any) =>
        (item.relatedObjectType === AppElementBaseTypes.RecordAppElement ||
          item.relatedObjectType === ActivityModules.AppElement ||
          item.relatedObjectType === ActivityModules.Attachment) &&
        item.action !== ActivityActions.ChangeRequestCreated &&
        item.action !== ActivityActions.ChangeRequestApproved &&
        item.action !== ActivityActions.ChangeRequestRejected,
    ),
  }
  return (
    <div style={{ height: 'calc(100% - 40px)' }}>
      <CustomScroll hideScroll={isPrinting} maxHeight={'100%'}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
        )}
        <ActivityForm
          data={activityWidgetData}
          elementId={elementId}
          isHomeDashboard={isHomeDashboard}
          isPrinting={isPrinting}
          isWidget={true}
          isAdmin={isAdmin}
          isEditor={isEditor}
          refetchFunction={() => getWidgetData(+widgetInfo.id)}
        />
      </CustomScroll>
    </div>
  )
}

export default memo(ActivityWidgetWithSortVersion)
