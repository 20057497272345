import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentList } from '../../features/list'
import { formatValues, getDefaultExceptionsOptions } from '../../utils/constant/constant/common'
import MultiSelectDropDown from './MultiSelectDropDown'
import UserSelectOption from './UsersSelect/UserSelectOption'
import SelectedUserChip from './UsersSelect/SelectedUserChip'

type MultiSelectFieldsWrapperPropsType = {
  value: any[],
  setValue: (value: any) => void,
  showActions: boolean,
  isDisabled: boolean,
  label?: string,
  setShowActions: (value: boolean) => void,
}

const MultiSelectFieldsWrapper: FC<MultiSelectFieldsWrapperPropsType> = ({
  value,
  setValue,
  setShowActions,
  showActions,
  isDisabled,
  label,
}) => {
  const list = useCurrentList()
  const { t } = useTranslation('lists')
  const defaultFields = getDefaultExceptionsOptions()

  const customFields =
    list?.customFields?.native?.fields.map((cf) => ({
      ...cf,
      label: cf?.fieldName,
      value: cf?.id,
    })) || []

  const onSelectFields = (data) => {
    setValue([...value, data])
    if (!showActions) {
      setShowActions(true)
    }
  }

  const onRemoveOption = (id: string) => {
    const newList = value.filter((u) => +u?.value !== +id)
    setValue([...newList])
    if (!showActions) {
      setShowActions(true)
    }
  }
  const options = [...defaultFields, ...customFields]

  const multiSelectDropdownProps = {
    isDisabled,
    label,
    MultiSelectOptions: (props: any) => <UserSelectOption {...props} onSelect={onSelectFields} />,
    MultiSelectValue: (props: any) => <SelectedUserChip {...props} onRemove={onRemoveOption} />,
    name: 'SelectFields',
    options,
    placeholder: t('listSettings:placeholderSelectFields'),
    setValue,
    value: value.length > 0 && value[0]?.label === undefined ? formatValues(options, value) : value,
  }

  return (
    <div className="w-full mt-4">
      <MultiSelectDropDown {...multiSelectDropdownProps} />
    </div>
  )
}

export default MultiSelectFieldsWrapper
