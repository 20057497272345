import React, { useState } from 'react'
import * as htmlToImage from 'html-to-image'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader'
import { CopyIcon } from '../../Icons'

const BROKEN_IMAGE_PLACEHOLDER =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij4KICA8cGF0aCBmaWxsPSIjZjdmN2Y3IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz4KICA8cGF0aCBmaWxsPSIjQzVENEUyIiBkPSJNNDcuNCA5MS45cy00LjQgNi01LjkgNy4xYy0xLjMgMS4yLTI4LjEgMTAuMy0zMy4yIDE3LjEtNi41IDguNy01LjIgMTEuOS01LjIgMTEuOWgxMjIuMnMxLjItMy4yLTUuMy0xMmMtNS02LjctMzEuOC0xNS44LTMzLjItMTctMS4zLTEuMi01LjgtNy4xLTUuOC03LjFoLS4zdi02LjZjMS43LTIuMSAzLjEtNC40IDQuMy02LjkuNy0xLjMgMS4zLTQuMSAxLjctNy43IDUuMi0yLjQgNS44LTEwLjggNi0xNS42IDAtMi4zLTEuMS0yLjQtMi41LTEuOGwuMi0xLjVMOTQuMiAzN2MuOC0zLjkgMS41LTgtLjctMTEuMmwyLjIuMkEyNC42IDI0LjYgMCAwMDc5IDEzYTQ5IDQ5IDAgMDAtMjIuMS42Yy0zLjYuOC03LjUgMi0xMCA0LjgtMy4zIDAtNi42LjUtOS43IDEuNWwzLjUgMS41QTI1LjQgMjUuNCAwIDAwMzcgNTFsLjggMmMtMS4zLS4zLTIgMC0yIDIuMi4yIDQuNi44IDEzIDYuMSAxNS41LjQgMy41IDEgNi4zIDEuNiA3LjYgMS4yIDIuNCAyLjYgNC42IDQuMiA2LjZWOTJoLS41Ii8+Cjwvc3ZnPgo='

const CopyWidget = ({ htmlElementRef }: { htmlElementRef: React.MutableRefObject<null> }) => {
  const [isCopying, setCopying] = useState(false)
  const { t } = useTranslation('dashboard')

  const convertToImage = () => {
    if (htmlElementRef.current) {
      setCopying(true)
      htmlToImage
        .toPng(htmlElementRef.current, {
          cacheBust: true,
          imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER,
        })
        .then(async (dataUrl) => {
          const copiedImage = await fetch(dataUrl)
          const blobData = await copiedImage.blob()
          const clipboardItemInput = new ClipboardItem({ 'image/png': blobData })
          navigator.clipboard.write([clipboardItemInput])
          toast.success(t('widgetCopiedSuccesfully'), { icon: false })
        })
        .catch(() => {
          toast.error(t('copyWidgetError'))
        })
        .finally(() => {
          setCopying(false)
        })
    }
  }
  return isCopying ? (
    <Loader loaderClasses="w-5 ms-2" />
  ) : (
    <CopyIcon className="w-5 cursor-pointer ms-2" onClick={convertToImage} />
  )
}
export default CopyWidget
