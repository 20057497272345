import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import classnames from 'classnames'
import { ErrorMessageRefType, MultilineInputWithMentionsPropsType, InputRefType } from 'utils/types/inputs'
import { InputErrorMessage } from 'components/inputs'
import { useEventListener } from 'hooks'
import { getStackByOptions } from 'utils/constant/constant/common'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { useCurrentListDetails } from 'features/list'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { configValues } from 'utils/appConfig'
import { customFieldBaseTypes } from '../../../utils/constant/enums/customFields'

const FormulaInput = forwardRef<InputRefType, MultilineInputWithMentionsPropsType>(
  (
    {
      name,
      elementRef,
      label,
      error,
      touched,
      classes,
      index,
      isInlineEditable,
      value,
      onChange,
      onSubmit,
      rows,
      isDescriptionChange,
      setIsDescriptionChange,
      handleClickOutside,
      isDocumentation,
      customFields,
      ...rest
    },
    ref,
  ) => {
    const errorRef = createRef<ErrorMessageRefType>()
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const listDetails = useCurrentListDetails()
    const isPro = useHasCurrentWorkspacePremiumPlan()
    const stackByOptions = getStackByOptions(isPro)
    const [positions, setPositions] = useState<any>({})
    const defaultFields = stackByOptions.slice(0, -1).concat([
      { id: 'startDate', label: 'startDate' },
      { id: 'endDate', label: 'endDate' },
    ])
    const listCustomFields = configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED
      ? customFields
          ?.filter((f: CustomFieldType) => f.baseType !== customFieldBaseTypes.Formula)
          .map((i: any) => ({
            display: i.fieldName,
            id: 'cf_' + i.id,
          }))
      : customFields?.map((i: any) => ({
          display: i.fieldName,
          id: 'cf_' + i.id,
        }))
    const normalFields = defaultFields.map((i) => ({ display: i.label, id: 'df_' + i.id }))

    useEffect(() => {
      if (elementRef?.current) setPositions(elementRef?.current?.getBoundingClientRect())
    }, [elementRef?.current])

    useEffect(() => {
      const end = value?.length || 0
      setIsDescriptionChange(true)
      inputRef.current?.setSelectionRange(end, end)
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        blur: () => {
          setIsDescriptionChange(true)
          inputRef.current?.blur()
        },
        contains: (target: Node) => inputRef.current?.contains(target),
        focus: () => {
          setIsDescriptionChange(true)
          inputRef.current?.focus()
        },
        shakeError: () => errorRef.current?.shakeError(),
      }),
      [errorRef, inputRef],
    )

    useEventListener('mousedown', (event) => handleClickOutside?.(event))

    const fieldsDisplay = [...normalFields, { display: 'completedIn', id: 'df_completedIn' }, ...listCustomFields]

    return (
      <>
        <MentionsInput
          a11ySuggestionsListLabel={'Suggested mentions'}
          allowSuggestionsAboveCursor={true}
          className={classnames({ mentions: !rows, 'mentions -rows': rows })}
          id={`input-${name}`}
          inputRef={inputRef}
          menuPosition="absolute"
          name={name}
          placeholder={label}
          rows={rows}
          style={{ '--rows': rows, zIndex: 9999 }}
          value={value ? `${value}` : ''}
          onChange={(e: any) => onChange?.({ ...e, target: { ...e.target, id: `input-${name}`, name } })}
          {...rest}>
          <Mention
            data={fieldsDisplay}
            displayTransform={(id) => fieldsDisplay.find((field) => field.id === id)?.display || id}
            markup="{__id__}"
            renderSuggestion={(highlightedDisplay, focused) => (
              <div className={classnames('user', { focused: focused })}>{highlightedDisplay.display}</div>
            )}
            trigger="{"
          />
        </MentionsInput>
        <InputErrorMessage error={error} isInlineEditable={isInlineEditable} ref={errorRef} touched={touched} />
      </>
    )
  },
)

FormulaInput.displayName = 'FormulaInput'

export default FormulaInput
