import { Datepicker } from 'components/inputs'
import { FC, forwardRef, useRef, useImperativeHandle } from 'react'
import { ContentEditableInputRefType } from 'utils/types/inputs'
import { useGridViewNavigation } from 'hooks'
import { useCurrentUser } from '../../../../features/user'

const DateEditorCell: FC<{
  name: string,
  date?: Date,
  field?: any,
  handleRecordChange: (values: any) => void,
  dateToCompare?: Date,
  isDisabled?: boolean,
  shouldShowPlaceholder?: boolean,
  id?: number,
  isGridView: boolean,
}> = forwardRef(
  (
    {
      name,
      value,
      data,
      onBlur,
      onChange,
      field,
      dateToCompare,
      isDisabled,
      shouldShowPlaceholder = false,
      gotoPrev,
      gotoNext,
      id,
      isGridView,
      ...rest
    },
    ref,
  ) => {
    const user = useCurrentUser()
    const isHijri = user?.isHijri !== null ? user?.isHijri : false
    useGridViewNavigation(gotoPrev, gotoNext)
    const datepickerRef = useRef<ContentEditableInputRefType>(null)
    const elementRef = useRef(null)
    const fieldValue =
      value ||
      rest?.cellProps?.data?.appElements[0]?.customFieldValues?.find((f) => +f.customFieldId === +field?.id)?.value

    useImperativeHandle(
      ref,
      () => ({
        blur: () => datepickerRef?.current?.blur(),
        focus: () => datepickerRef?.current?.focus(),
        shakeError: () => datepickerRef?.current?.shakeError(),
      }),
      [datepickerRef.current],
    )

    return (
      <div
        className="absolute top-0 left-0 z-50 w-full"
        ref={elementRef}
        onKeyDown={(e) => {
          if (e.key === 'Tab') e.preventDefault()
        }}>
        <Datepicker
          autoFocus
          elementRef={elementRef}
          id={id}
          inputWrapperClassName="-m-2"
          isGridView={isGridView}
          isHijri={isHijri}
          isOnClickDisabled={isDisabled}
          name={name}
          ref={datepickerRef}
          shouldShowPlaceholder={shouldShowPlaceholder}
          value={fieldValue}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
    )
  },
)

DateEditorCell.displayName = 'lukas'

export default DateEditorCell
