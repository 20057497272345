import React, { FC } from 'react'

import { CrossSignIcon } from 'components/Icons'
import classnames from 'classnames'
import RequestType from '../../../utils/types/RequestType'

const LabelRequestList: FC<{
  request: RequestType,
  labels: any[],
  isDeletingAllowed?: boolean,
  isGridView: boolean,
}> = ({ labels, isDeletingAllowed = false, isGridView, request }) => {
  const labelsList = request?.appElement?.record?.labels
  return (
    <div className="flex flex-wrap">
      {labels.map((label, idx) => {
        const { name, id } = label
        const currentColor = labelsList.find((lab: any) => +lab.id === +id)?.color || '#003E51'
        return (
          <p
            key={idx}
            className={classnames(
              'inline-flex items-center text-sm font-bold text-white rounded-full cursor-default ps-4 leading-6' +
                ' transition' +
                ' max-w-full ',
              isGridView ? 'mx-0.5 -mt-1 ' : 'py-1 m-0.5 ',
              isDeletingAllowed ? 'pe-0' : 'pe-4',
            )}
            style={{ backgroundColor: `${currentColor}20`, color: currentColor }}>
            <span className="truncate">{name}</span>
            {isDeletingAllowed && (
              <button className="flex w-8 -my-2 hover:opacity-50 transition" type="button" {...removeProps}>
                <CrossSignIcon className="w-6 m-1" />
              </button>
            )}
          </p>
        )
      })}
    </div>
  )
}

export default LabelRequestList
