import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Draggable } from '@fullcalendar/interaction'

import Button, { ButtonVariant } from 'components/Buttons'
import { useCalendarUnassignedRecords } from 'features/record'

const TaskList: FC = () => {
  const { t } = useTranslation()
  const unassignedRecords = useCalendarUnassignedRecords()

  useEffect(() => {
    const containerEl = document.getElementById('task-list')
    if (containerEl) {
      new Draggable(containerEl, {
        eventData: function (eventEl) {
          return {
            title: eventEl.innerText,
          }
        },
        itemSelector: '.fc-event',
      })
    }
  }, [])

  return (
    <div
      className="z-10 w-64 px-3 pt-1 pb-3 overflow-auto text-sm bg-white border-t-2 shadow-sm max-w-50"
      id="task-list">
      {unassignedRecords.map(({ name, id }) => (
        <div key={id} className="fc-event" id={`${id}`}>
          <Button className="w-full h-auto mt-2 text-start leading-3" small variant={ButtonVariant.Outline}>
            {name}
          </Button>
        </div>
      ))}
      {unassignedRecords.length === 0 && (
        <>
          <h2 className="mt-8 font-bold text-center text-gray-700">{t('records:taskListEmpty:header')}</h2>
          <p className="mt-2 mb-2 text-sm text-center">{t('records:taskListEmpty:description')}</p>
        </>
      )}
    </div>
  )
}

export default TaskList
