import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ContextMenu from '../ContextMenu'
import { ChosenChoiseIcon, NextIcon, PrevIcon } from '../Icons'
import { updateUser, useCurrentUser } from '../../features/user'
import { emailFrequency } from '../../utils/constant/constant/common'

interface NotificationsModalProps {
  leftSpace?: boolean;
  isModalOpen: boolean;
  onClose: () => void;
}
const NotificationSendEmailsMenu: FC<NotificationsModalProps> = ({ isModalOpen, onClose, leftSpace = false }) => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const [currentEmailFrequency, setCurrentEmailFrequency] = useState(currentUser.emailNotificationPreference)
  const { t, i18n } = useTranslation('common')
  const isRtl = i18n.language === 'ar'

  const onChangeMenu = (emailTypeValue: string | number) => {
    setCurrentEmailFrequency(emailTypeValue)
    const updatedUserData = { ...currentUser, emailNotificationPreference: +emailTypeValue }
    dispatch(updateUser(updatedUserData))
  }

  return (
    <div className="flex items-center p-1 -mt-3 bg-white border-gray-300 rounded shadow-md z-1">
      <ContextMenu
        render={({ closeMenu }) => (
          <>
            {emailFrequency.map((emailType, idx) => (
              <button
                key={idx}
                className="block w-full py-2 hover:text-primary text-start"
                onClick={() => {
                  onChangeMenu(emailType.value)
                  onClose()
                  closeMenu()
                }}>
                <div className="flex justify-between">
                  <p>{t(emailType.title)}</p>
                  {+currentEmailFrequency === +emailType.value && <ChosenChoiseIcon style={{ stroke: '#00B2A9' }} />}
                </div>
              </button>
            ))}
          </>
        )}
        text={
          <div className="flex">
            <p className="text-sm font-semibold cursor-pointer ms-2">{t('emailNotificationsFrequency')}</p>
            {isRtl ? <PrevIcon className="w-4 ms-1 mt-0.5" /> : <NextIcon className="w-4 ms-1 mt-0.5" />}
          </div>
        }
      />
    </div>
  )
}

export default NotificationSendEmailsMenu
