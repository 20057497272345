import React, { createRef, forwardRef, useImperativeHandle, useRef } from 'react'
import classnames from 'classnames'

import { InputRefType, ErrorMessageRefType, ErrorMessageType, InputPropsType } from 'utils/types/inputs'

import InputErrorMessage from 'components/inputs/InputErrorMessage'

const Radio = forwardRef<InputRefType, InputPropsType & ErrorMessageType & { isLabelComponent?: boolean }>(
  ({ name, label, onChange, classes, error, touched, isLabelComponent, ...rest }, ref) => {
    const errorRef = createRef<ErrorMessageRefType>()
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        blur: () => inputRef.current?.blur(),
        focus: () => inputRef.current?.focus(),
        shakeError: () => errorRef.current?.shakeError(),
      }),
      [errorRef, inputRef],
    )

    return (
      <div className={classnames('flex items-center', classes.wrapper)}>
        <input
          className={classnames('me-2', classes.input)}
          id={`input-${name}`}
          name={name}
          ref={inputRef}
          onChange={onChange}
          {...rest}
          type="radio"
        />
        {label && isLabelComponent ? (
          <label
            className={classnames('', classes.label)}
            dangerouslySetInnerHTML={{ __html: label }}
            htmlFor={`input-${name}`}
          />
        ) : (
          <label className={classnames('', classes.label)} htmlFor={`input-${name}`}>
            {label}
          </label>
        )}
        <InputErrorMessage
          data-testId={`${rest['data-testid'] || name}-error`}
          error={error}
          ref={errorRef}
          touched={touched}
        />
      </div>
    )
  },
)
Radio.displayName = 'Radio'

Radio.defaultProps = {
  classes: {},
}

export default Radio
