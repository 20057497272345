import { createSlice } from '@reduxjs/toolkit'
import { PublicDocsType } from 'utils/types/states'
import { StationDocumentationSidebarType } from 'utils/types/states/StationDocumentationSidebarType'
import { getPublicDocsSidebarData, getPublicDocsOneItem } from './actions'

const initialState: PublicDocsType = {
  publicDocsSidebar: null,
  publicDocsSidebarLoading: true,
  publicDocsOneItem: null,
  publicDocsOneItemLoading: true,
  publicDocSidebarHiddenElements: [],
}

export const publicDocsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getPublicDocsSidebarData.fulfilled, (state, action) => {
      state.publicDocsSidebar = action.payload
      const hiddenElements = action.payload ? action.payload.filter((i) => i.level !== 1).map((i) => i.id) : []
      state.publicDocSidebarHiddenElements = hiddenElements
      state.publicDocsSidebarLoading = false
    })

    builder.addCase(getPublicDocsOneItem.fulfilled, (state, action) => {
      state.publicDocsOneItem = state.publicDocsSidebar
        ? state.publicDocsSidebar.filter((i) => i.id === action.payload)[0]
        : null
      state.publicDocsOneItemLoading = false
    })
  },
  initialState,
  name: 'publicDocs',
  reducers: {
    updatePublicDocsVisitedDoc: (state, { payload }) => {
      state.publicDocsSidebar = state.publicDocsSidebar
        ? state.publicDocsSidebar?.map((i) => {
            if (i.id === payload.id) {
              i.filters = i.filters ? Array.from(new Set([...i.filters, 'recently_viewed'])) : ['recently_viewed']
            }

            return i
          })
        : null
    },
    updatePublicDocSidebarHiddenElements: (state, { payload }) => {
      state.publicDocSidebarHiddenElements = payload
    },
  },
})

export const { updatePublicDocsVisitedDoc, updatePublicDocSidebarHiddenElements } = publicDocsSlice.actions

export default publicDocsSlice.reducer
