import i18n from 'i18n'
import { format } from 'date-fns'
import { AppElementView } from 'utils/types/AppElementViewType'
import { RecordAppElementAllAttributesConst } from 'utils/types/RecordAppElementAllAttributes'
import { getFieldVisibility } from 'utils/helpers/customFields'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'
import { ActivityLogType } from 'utils/types'
import RequestType from '../../types/RequestType'
import { ActivityActions } from '../enums/notifications'
import { hijriInputFormat } from '../../calendarUtils'
import { AppElementAttributeVisibility } from '../../types/AppElementAttributeVisibility'
import ElementViewBaseTypes from '../../types/ElementViewBaseTypes'
import { AppElementViewUserAccess } from '../../types/AppElementViewUserAccess'

export const getStackByOptions = (options: { isPro: boolean, currentView: AppElementView | undefined }) => {
  const { isPro, currentView } = options
  const basicOptions = [
    { id: 'statusId', label: 'status' },
    { id: 'ownerId', label: 'owner' },
    { id: 'health', label: 'health' },
    { id: 'priority', label: 'priority' },
  ]

  const proOptions = [{ id: 'documents', label: 'linkedDocuments' }]

  const allOptions = isPro ? [...basicOptions, ...proOptions] : basicOptions

  const finalOptions: { id: string, label: string }[] = []

  if (!currentView) {
    return allOptions
  }
  allOptions.forEach((option) => {
    const { id } = option

    switch (id) {
      case 'statusId': {
        const visibility = getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.statusId,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility: currentView.defaultAttributeVisibility,
          viewAttributes: currentView.appElementViewAttributes,
        })

        if (visibility !== AppElementAttributeVisibility.Hidden) {
          finalOptions.push(option)
        }
        break
      }
      case 'ownerId': {
        const visibility = getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.ownerId,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility: currentView.defaultAttributeVisibility,
          viewAttributes: currentView.appElementViewAttributes,
        })

        if (visibility !== AppElementAttributeVisibility.Hidden) {
          finalOptions.push(option)
        }
        break
      }
      case 'priority': {
        const visibility = getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.priority,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility: currentView.defaultAttributeVisibility,
          viewAttributes: currentView.appElementViewAttributes,
        })

        if (visibility !== AppElementAttributeVisibility.Hidden) {
          finalOptions.push(option)
        }
        break
      }
      case 'health': {
        const visibility = getFieldVisibility({
          attributeId: RecordAppElementAllAttributesConst.health,
          attributeType: AppElementAttributeType.defaultAttribute,
          defaultAttributeVisibility: currentView.defaultAttributeVisibility,
          viewAttributes: currentView.appElementViewAttributes,
        })

        if (visibility !== AppElementAttributeVisibility.Hidden) {
          finalOptions.push(option)
        }
        break
      }

      default: {
        finalOptions.push(option)
      }
    }
  })

  return finalOptions
}
export const getDefaultExceptionsOptions = () => {
  const defaultAttributeStatus = AppElementAttributeVisibility.ReadOnly
  const attributeType = AppElementAttributeType.defaultAttribute
  return [
    { attributeType, id: '1', label: i18n.t('records:listing.name'), status: defaultAttributeStatus, value: '1' },
    {
      attributeType,
      id: '5',
      label: i18n.t('records:listing.description'),
      status: defaultAttributeStatus,
      value: '5',
    },
    {
      attributeType,
      id: '4003',
      label: i18n.t('records:listing.owner'),
      status: defaultAttributeStatus,
      value: '4003',
    },
    {
      attributeType,
      id: '4004',
      label: i18n.t('records:listing.status'),
      status: defaultAttributeStatus,
      value: '4004',
    },
    {
      attributeType,
      id: '4000',
      label: i18n.t('validation:fieldNames.completedIn'),
      status: defaultAttributeStatus,
      value: '4000',
    },
    {
      attributeType,
      id: '4002',
      label: i18n.t('records:listing.priority'),
      status: defaultAttributeStatus,
      value: '4002',
    },
    {
      attributeType,
      id: '4005',
      label: i18n.t('records:listing.startDate'),
      status: defaultAttributeStatus,
      value: '4005',
    },
    {
      attributeType,
      id: '4006',
      label: i18n.t('records:listing.endDate'),
      status: defaultAttributeStatus,
      value: '4006',
    },
    {
      attributeType,
      id: '4007',
      label: i18n.t('records:listing.labelsIds'),
      status: defaultAttributeStatus,
      value: '4007',
    },
  ]
}
export const customWidgetDefaultOptions = [
  { id: 4, key: 'status_id', label: i18n.t('records:listing.status'), sourceType: 3, value: 4 },
  { id: 0, key: 'completedIn', label: i18n.t('validation:fieldNames.completedIn'), sourceType: 3, value: 0 },
  { id: 2, key: 'owner', label: i18n.t('records:listing.owner'), sourceType: 3, value: 2 },
  { id: 1, key: 'priority', label: i18n.t('records:listing.priority'), sourceType: 3, value: 1 },
  { id: 3, key: 'creator', label: i18n.t('records:listing.creator'), sourceType: 3, value: 3 },
  { id: 5, key: 'health', label: i18n.t('records:listing.health'), sourceType: 3, value: 5 },
]
export const imagePositionOptions = [
  { id: 0, label: i18n.t('dashboard:positions:top'), value: 'top' },
  { id: 1, label: i18n.t('dashboard:positions:left'), value: 'left' },
  { id: 2, label: i18n.t('dashboard:positions:right'), value: 'right' },
  { id: 3, label: i18n.t('dashboard:positions:bottom'), value: 'bottom' },
]

export const staticGroupByList = [
  { label: 'statuses', staticLabel: 'statuses', value: 1 },
  { label: 'owners', staticLabel: 'owners', value: 2 },
  { label: 'priorities', staticLabel: 'priorities', value: 3 },
  { label: 'creators', staticLabel: 'creators', value: 4 },
]

export const emailFrequency = [
  { title: 'never', value: 0 },
  { title: 'onceAHour', value: 1 },
  { title: 'immediately', value: 2 },
]

export const customPieWidget = {
  availableScopes: [1],
  availableTypes: [
    {
      image: '',
      typeName: 1,
    },
  ],
  baseType: 16,
  chartType: 1,
  data: { values: [] },
  dimensions: {
    h: 2,
    minH: 1,
    minW: 2,
    w: 4,
    x: 5,
    y: 1,
  },
  isVisible: true,
  scope: 1,
  widgetType: 0,
}
export const parametersValues = {
  creator: 'creator',
  health: 'health',
  owner: 'ownerId',
  priority: 'priority',
  status_id: 'statusId',
}
export const basicOptions = [
  { label: i18n.t('records:listing.owner'), value: 'ownerId' },
  { label: i18n.t('records:listing.priority'), value: 'priority' },
  { label: i18n.t('records:listing.health'), value: 'health' },
  { label: i18n.t('records:listing.status'), value: 'statusId' },
  { label: i18n.t('records:listing.linkedDocuments'), value: 'documents' },
]

export const getTimelineGroupByOptions = (options: { currentView: AppElementView | undefined }) => {
  const { currentView } = options
  const allOptions = [
    { label: i18n.t('records:listing.owner'), value: 'ownerId' },
    { label: i18n.t('records:listing.priority'), value: 'priority' },
    { label: i18n.t('records:listing.health'), value: 'health' },
    { label: i18n.t('records:listing.status'), value: 'statusId' },
    { label: i18n.t('records:listing.linkedDocuments'), value: 'documents' },
  ]

  const finalOptions: { value: string, label: string }[] = []

  allOptions.forEach((option) => {
    const { value } = option

    switch (value) {
      case 'statusId': {
        const attribute = currentView?.appElementViewAttributes.find(
          ({ attributeId }) => Number(attributeId) === RecordAppElementAllAttributesConst.statusId,
        )
        if (attribute) {
          if (attribute.attributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        } else {
          if (currentView?.defaultAttributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        }
        break
      }
      case 'ownerId': {
        const attribute = currentView?.appElementViewAttributes.find(
          ({ attributeId }) => Number(attributeId) === RecordAppElementAllAttributesConst.ownerId,
        )
        if (attribute) {
          if (attribute.attributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        } else {
          if (currentView?.defaultAttributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        }
        break
      }
      case 'priority': {
        const attribute = currentView?.appElementViewAttributes.find(
          ({ attributeId }) => Number(attributeId) === RecordAppElementAllAttributesConst.priority,
        )
        if (attribute) {
          if (attribute.attributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        } else {
          if (currentView?.defaultAttributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        }
        break
      }
      case 'health': {
        const attribute = currentView?.appElementViewAttributes.find(
          ({ attributeId }) => Number(attributeId) === RecordAppElementAllAttributesConst.health,
        )
        if (attribute) {
          if (attribute.attributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        } else {
          if (currentView?.defaultAttributeVisibility !== AppElementAttributeVisibility.Hidden) {
            finalOptions.push(option)
          }
        }
        break
      }

      default: {
        finalOptions.push(option)
      }
    }
  })

  return finalOptions
}

export const breakpoints = {
  '2xl': 1536,
  lg: 1024,
  md: 768,
  sm: 640,
  xl: 1280,
}
export const recordAppElementDefaultAttributesValues = {
  // completed in
  '0': 'id',
  '1': 'name',
  '2': 'creationDate',
  '3': 'updateDate',
  '4': 'owner',
  '5': 'description',
  '4000': 'completedIn',
  '4001': 'health',
  '4002': 'priority',
  '4003': 'ownerId',
  '4004': 'status',
  '4005': 'startDate',
  '4006': 'endDate',
  '4007': 'labelsIds',
}
export const recordAppElementDefaultAttributesNameToId = {
  completedIn: '4000',

  creationDate: '2',

  description: '5',

  endDate: '4006',

  health: '4001',
  // completed in
  id: '0',
  labelsIds: '4007',
  name: '1',
  owner: '4',
  ownerId: '4003',
  priority: '4002',
  startDate: '4005',
  status: '4004',
  updateDate: '3',
}

export const activityKeysToAttributeId = {
  completedIn: '4000',
  description: '5',
  endDate: '4006',
  health: '4001',
  name: '1',
  owner: '4003',
  priority: '4002',
  startDate: '4005',
  status: '4004',
}

export const getAttributeFromActivity = (options: {
  activity: ActivityLogType,
}): { attributeId: number, attributeType: AppElementAttributeType } => {
  const { activity } = options
  const changeObject: { key: string, previous: string, current: string } = activity?.objectData[0]
  const customFieldKey = changeObject?.key.includes('CF') ? changeObject?.key?.split(':') : null
  const isCF = !!customFieldKey
  const attributeType = isCF ? AppElementAttributeType.customField : AppElementAttributeType.defaultAttribute
  const attributeId = isCF ? customFieldKey[2] : activityKeysToAttributeId[changeObject.key]
  return {
    attributeId,
    attributeType,
  }
}

export const formatValues = (originList: any[], list: any[]) => {
  return list.map((item) => {
    const option = originList?.find((ele) => `${ele.value}` === `${item.value}`)
    return { ...item, label: option?.label, value: option?.value, ...option }
  })
}

export const getRequestFieldName = (request: RequestType, list?: any) => {
  switch (request?.targetType) {
    case AppElementAttributeType.defaultAttribute:
      return recordAppElementDefaultAttributesValues[request.targetId]
    case AppElementAttributeType.customField:
      return list?.find((f) => +f.id === +request.targetId)?.fieldName
    default:
      return request.targetId
  }
}

export const getChangeObjectData = (request: RequestType, customFields: any) => {
  return {
    current: !request?.changeValue || !request?.changeValue?.renderedValue ? null : request?.changeValue?.renderedValue,
    key: getRequestFieldName(request, customFields),
    previous: !request?.oldValue || !request?.oldValue?.renderedValue ? null : request?.oldValue?.renderedValue,
  }
}

export const ChangeRequestActionsList = [
  ActivityActions.ChangeRequestCreated,
  ActivityActions.ChangeRequestApproved,
  ActivityActions.ChangeRequestRejected,
]

export const getDateFormatInfo = (date: Date, isHijri: boolean, language: string) => {
  return isHijri ? hijriInputFormat(date, language) : format(new Date(date), 'd/MM/yyyy')
}

export const getAttributeStatus = () => [
  {
    id: AppElementAttributeVisibility.Hidden,
    label: i18n.t('lists:views.hidden'),
    value: AppElementAttributeVisibility.Hidden,
  },
  {
    id: AppElementAttributeVisibility.ReadOnly,
    label: i18n.t('lists:views.readOnly'),
    value: AppElementAttributeVisibility.ReadOnly,
  },
  // {
  //   id: AppElementAttributeVisibility.Edit,
  //   label: i18n.t('lists:views.edit'),
  //   value: AppElementAttributeVisibility.Edit,
  // },
  {
    id: AppElementAttributeVisibility.AsPerRole,
    label: i18n.t('lists:views.perAttribute'),
    value: AppElementAttributeVisibility.AsPerRole,
  },
]

export const getViewTypes = () => [
  {
    id: ElementViewBaseTypes.RecordsKanban,
    label: i18n.t('common:labels.kanban'),
    value: ElementViewBaseTypes.RecordsKanban,
  },
  {
    id: ElementViewBaseTypes.RecordsGrid,
    label: i18n.t('common:labels.grid'),
    value: ElementViewBaseTypes.RecordsGrid,
  },
  {
    id: ElementViewBaseTypes.RecordsAgenda,
    label: i18n.t('common:labels.agenda'),
    value: ElementViewBaseTypes.RecordsAgenda,
  },
  {
    id: ElementViewBaseTypes.RecordsCalendar,
    label: i18n.t('common:labels.calendar'),
    value: ElementViewBaseTypes.RecordsCalendar,
  },
  {
    id: ElementViewBaseTypes.RecordsTimeLine,
    label: i18n.t('common:labels.timeline'),
    value: ElementViewBaseTypes.RecordsTimeLine,
  },
]

export const userDataAccessOptions = [
  { label: i18n.t('lists:views.ownRecords'), value: AppElementViewUserAccess.OwnElementsOnly },
  // { label: 'allRecords', value: AppElementViewUserAccess.Collaborator },
  { label: i18n.t('lists:views.allRecords'), value: AppElementViewUserAccess.OthersElements },
]
