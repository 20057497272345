import React, { FC, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import StationDocumentationHomePage from './station/StationDocumentation/StationDocumentationHomePage'
import StationDocumentationSinglePageView from './station/StationDocumentation/StationDocumentationSinglePageView'

const PublicDocumentation: FC = () => {
  const { path, url } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const params = useParams<{ publicId: string }>()

  useEffect(() => {
    if (location.pathname.lastIndexOf(params.publicId) + params.publicId.length === location.pathname.length) {
      history.replace(location.pathname + '/home')
    }
    // if (location.pathname.slice(-13) === 'documentation' || location.pathname.slice(-14) === 'documentation/') {
    //   history.replace(location.pathname + (location.pathname.slice(-14) === 'documentation/' ? 'home' : '/home'))
    // }
  }, [location])
  return (
    <div className="min-h-screen h-screen">
      <Switch>
        <Route path={`${path}/home`}>
          <StationDocumentationHomePage isPublic />
        </Route>
        <Route path={`${path}/view/:id`}>
          <StationDocumentationSinglePageView isPublic />
        </Route>
      </Switch>
    </div>
  )
}

export default PublicDocumentation
