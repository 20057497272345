import { Dispatch, FC, SetStateAction } from 'react'
import { useFormik } from 'formik'

import baseAPI from 'utils/baseAPI'
import { ContactUsPropsType } from 'utils/types'
import { Input, MultilineInputWithMentions } from 'components/inputs'
import Button from 'components/Buttons'

import { useTranslation } from 'react-i18next'
import { contactUsValidationSchema } from './validationSchemas'

type ContactUsFormPropsType = {
  initialValues: ContactUsPropsType,
  setMessage: Dispatch<SetStateAction<string>>,
}

const ContactUsForm: FC<ContactUsFormPropsType> = ({ initialValues, setMessage }) => {
  const { t } = useTranslation('notifications')
  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      question: '',
    },
    onSubmit: (values, actions) => {
      baseAPI('api/contact_form', {
        body: { ...values, question: values.question?.trim() },
        method: 'POST',
      }).then((res) => {
        if (res.status < 400) {
          actions.resetForm()
          setMessage(t('emailSentToSupport'))
        } else {
          setMessage(t('unexpectedErrorOccuredPleaseTryAgain'))
        }
      })
    },
    validationSchema: contactUsValidationSchema,
  })
  return (
    <form onSubmit={handleSubmit}>
      <Input
        error={errors.name}
        label="Name"
        name="name"
        touched={touched.name}
        value={values.name}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Input
        error={errors.userEmail}
        label="Email"
        name="userEmail"
        touched={touched.userEmail}
        value={values.userEmail}
        onBlur={(event: any) => {
          setFieldValue('userEmail', values.userEmail.toLowerCase())
          handleBlur(event)
        }}
        onChange={handleChange}
      />
      <MultilineInputWithMentions
        error={errors.question}
        label="Question"
        name="question"
        rows={5}
        touched={touched.question}
        value={values.question}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Button type="submit">Send</Button>
    </form>
  )
}
export default ContactUsForm
