import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import WatchButton from '../Notifications/WatchButton'
import { ListType, UserType } from '../../utils/types'

type PanelFormWrapperProps = {
  buttons?: ReactNode,
  children: ReactNode,
  onSubmit: () => void,
  elementId?: string | undefined,
  user?: UserType,
  hideWatchButton?: boolean,
}

const PanelFormWrapper: FC<PanelFormWrapperProps> = ({
  buttons,
  children,
  onSubmit,
  elementId,
  user,
  hideWatchButton = false,
}) => {
  const { t } = useTranslation()
  return (
    <form
      className="flex flex-col justify-between flex-grow p-6 overflow-y-auto"
      style={{ scrollBehavior: 'smooth', scrollPadding: '4rem' }}
      onSubmit={(e, data) => {
        e.preventDefault()
        onSubmit(data)
      }}>
      <div className="flex">
        <label className="block mb-2 text-lg font-bold">{t('listSettings:listDetails')}</label>
        <div className="flex items-center justify-center -mt-2 ms-2">
          {!hideWatchButton && elementId && user?.id && <WatchButton elementId={Number(elementId)} userId={user?.id} />}
        </div>
      </div>
      <div className="h-full mt-3 overflow-auto">{children}</div>
      {buttons && <div className="flex justify-end p-6 mt-auto -m-6">{buttons}</div>}
    </form>
  )
}

export default PanelFormWrapper
