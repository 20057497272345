import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store'
import { ListType, StationType, WorkspaceType } from 'utils/types'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { getCustomFieldsOptions } from 'utils/helpers/listHelpers'
import { onDeleteCustomField } from 'features/list'
import ConfirmationModal from '../ConfirmationModal'
import { useHasCurrentWorkspaceTeamsPlan } from '../../features/workspace'
import EditCustomFieldModal from './Modal/EditCustomFieldModal'
import CustomFieldRow from './CustomFieldRow'

interface CustomFieldItemInfoType {
  customFieldItem: CustomFieldType;
  isFromAdmin: boolean;
  isViewer?: boolean;
  item: any;
  elementBaseType: AppElementBaseTypes;
  currentItem: WorkspaceType | StationType | ListType;
  onRefetch: () => void;
}

const ListCustomFieldItem: FC<CustomFieldItemInfoType> = ({
  customFieldItem,
  item,
  currentItem,
  onRefetch,
  elementBaseType,
  isFromAdmin = false,
  isViewer,
}) => {
  const { t } = useTranslation('customFields')
  const appDispatch = useAppDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const isTeamsPlan = useHasCurrentWorkspaceTeamsPlan()
  const fieldSelectedType = +customFieldItem?.baseType === 5 ? 3 : +customFieldItem?.baseType
  const options = getCustomFieldsOptions(elementBaseType, isFromAdmin, isTeamsPlan)

  const option = options.find((field) => +field.value === fieldSelectedType) || {
    Icon: 'div',
    label: '',
  }

  const onDelete = () => {
    appDispatch(onDeleteCustomField(+customFieldItem.id)).then(() => {
      onRefetch()
      setIsDelete(false)
    })
  }
  const customFieldRowProp = {
    field: customFieldItem,
    options: option,
    setIsDelete,
    setIsEdit,
    isViewer,
  }

  return (
    <>
      <CustomFieldRow {...customFieldRowProp} />
      <EditCustomFieldModal
        currentItem={currentItem}
        customFieldItem={customFieldItem}
        isFromAdmin={isFromAdmin}
        isModalOpen={isEdit}
        onCancel={() => setIsEdit(false)}
        onRefetch={onRefetch}
      />
      <ConfirmationModal
        cancelMessage={t('common:labels.cancel')}
        confirmMessage={t('common:labels.remove')}
        confirmationMessage={t('customFields:deleteCustomFieldMessage')}
        isModalOpen={isDelete}
        onCancel={() => setIsDelete(false)}
        onConfirm={onDelete}
      />
    </>
  )
}

export default ListCustomFieldItem
