import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useSize } from 'react-use'
import { useParams } from 'react-router-dom'
import { DashboardStateWidgetType } from 'utils/types/states'
import Chart from 'components/Dashboard/Chart/Chart'
import { useTranslation } from 'react-i18next'
import { setColorByStatus } from 'utils/types/widgetUtils'
import Select, { StylesConfig } from 'react-select'
import { ChevronDownIcon, StackIcon, KanbanIcon } from 'components/Icons'
import { stationStatuses } from 'utils/constant/resources/stationResorces'
import { widgetSelectStyles } from 'utils/helpers/dashboardHelpers'
import { staticGroupByList } from 'utils/constant/constant/common'
import DashboardWidgetType from 'utils/types/DashboardWidgetType'
import WidgetImage from '../../WidgetImage'
import { useAppDispatch } from '../../../../store'
import Loader from '../../../Loader'
import { getWidgetData, useWidgetById } from '../../../../features/element'

const ListOverviewWidget: FC<{
  widgetInfo: DashboardStateWidgetType,
  isEdit: boolean,
  onUpdateWidgetImage: (value: DashboardWidgetType) => void,
}> = ({ widgetInfo, isEdit, onUpdateWidgetImage }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const widget = useWidgetById(widgetInfo.id)
  const [isLoading, setIsLoading] = useState(true)
  const { data } = widget || widgetInfo
  const [sized, { width: elementWidth }] = useSize(({ width }) => <div />)
  const isRtl = i18n.language === 'ar'
  const hideText = elementWidth < 980
  const isDataLoading = isLoading && widget === undefined && widgetInfo.data === null

  useEffect(() => {
    if (widgetInfo.id && widget === undefined) {
      setIsLoading(true)
      dispatch(getWidgetData(+widgetInfo.id)).then((res) => {
        setIsLoading(false)
        const lists = res?.payload?.data?.values?.map((i: any) => {
          return {
            label: i.listName,
            value: i.id,
          }
        })
        if (lists && lists[0]) selectOption(lists[0])
      })
    }
  }, [widgetInfo.id])

  const selectOptions = useMemo(() => {
    return (
      data?.values?.map((i: any, idxI: number) => {
        return {
          label: i.listName,
          value: i.id,
        }
      }) || []
    )
  }, [widget])

  const [selectedOption, selectOption] = useState(selectOptions[0])
  const selectedItem = data?.values?.filter((i: any) => i?.id === selectedOption?.value)[0] || {}

  const groupByList = useMemo(() => {
    return selectedItem
      ? Object.keys(selectedItem)
          .filter((i: string) => i !== 'listName' && i !== 'id')
          .map((key, index) => {
            const translatedLabel = t(`listOverview:${key}`)
            return {
              label: translatedLabel.includes('listOverview') ? key : translatedLabel,
              staticLabel: key,
              value: index + 1,
            }
          })
      : []
  }, [selectedItem, selectedOption, selectOptions, widget])

  const [groupBy, setGroupBy] = useState(groupByList[0] || staticGroupByList[0])

  const dataset = useMemo(() => {
    return selectedItem
      ? selectedItem[groupBy?.staticLabel]?.map((i: any, idx: number) => {
          return i?.healths
            ? i?.healths?.map((s: any) => {
                return {
                  backgroundColor: setColorByStatus(s.health).color,
                  data: [...new Array(idx), s.data],
                  label: i.labelName,
                }
              })
            : []
        })
      : []
  }, [selectedItem, groupBy, widget])

  if (isDataLoading) {
    return <Loader />
  }

  const labels = selectedItem
    ? selectedItem[groupBy?.staticLabel]?.map((i: any) =>
        i.labelName === 'notAssigned'
          ? t('records:notAssigned')
          : i.labelName === 'No Reference'
          ? t('dashboard:noReferences')
          : groupBy?.staticLabel === 'priorities'
          ? t(`dashboard:priority.${i.labelName}`)
          : i.labelName,
      )
    : [t('records:notAssigned')]

  return (
    <>
      {sized}
      <div>
        <div className="flex items-center mb-2">
          <div className="flex items-center me-3">
            <StackIcon className="w-4 stroke-1 me-1" />
            {!hideText && <div className="me-1">{t('groupBy')}:</div>}
            <Select
              className="min-h-0 bg-white w-30"
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: () => <ChevronDownIcon className="me-2" height={16} width={20} />,
              }}
              isRtl={isRtl}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              name="sort-select"
              options={groupByList}
              styles={widgetSelectStyles}
              value={groupBy}
              onChange={(e: any) => {
                setGroupBy(e)
              }}
            />
          </div>
          <div className="flex items-center me-1.5">
            <KanbanIcon className="w-5 stroke-2 me-1" />
            {!hideText && <div className="me-1">{t('list')}:</div>}
            <Select
              className="min-h-0 bg-white w-30"
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: () => <ChevronDownIcon className="me-2" height={16} width={20} />,
              }}
              defaultValue={selectedOption}
              isRtl={isRtl}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              name="sort-select"
              options={selectOptions}
              styles={widgetSelectStyles}
              value={selectedOption}
              onChange={(e) => {
                selectOption(e)
                setGroupBy(groupByList[0])
              }}
            />
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: 'calc(100% - 40px)', overflowY: 'auto' }}>
        {!widgetInfo?.background && widgetInfo?.appElementAsset?.id && (
          <div className="my-2">
            <WidgetImage isEdit={isEdit} widgetInfo={widgetInfo} onUpdateWidgetImage={onUpdateWidgetImage} />
          </div>
        )}
        <div style={{ height: 'calc(100% - 36px)' }}>
          <Chart
            datasets={dataset?.flat()}
            labels={labels}
            options={{
              indexAxis: 'y',
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  color: 'white',
                  formatter: function (value: number, context: any) {
                    return value === 0 ? '' : value
                  },
                  marginTop: 10,
                },
                legend: {
                  display: false,
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    stepSize: 1,
                  },
                },
                y: {
                  stacked: true,
                },
              },
            }}
            type="bar"
          />
        </div>
        <div className="flex justify-end mt-4 gap-4">
          {stationStatuses.map((s) => (
            <div key={s.value} className="flex items-center text-sm gap-2">
              <div
                style={{
                  backgroundColor: setColorByStatus(s.value ? parseInt(s.value) : -1, true).color,
                  borderRadius: '100%',
                  height: 8,
                  minWidth: 8,
                  width: 8,
                }}
              />
              <div>{t(`dashboard:${setColorByStatus(s.value ? parseInt(s.value) : -1, true).title}`)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(ListOverviewWidget)
