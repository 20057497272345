import React, { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddIcon } from '../../Icons'
import AddLinkModal from './LinkComponents/AddLinkModal'
import LinkedRecordsList from './LinkComponents/LinkedRecordsList'

const DocumentationDocumentLinks: FC<{ documentId: string, isDisabled?: boolean, stationId: string }> = ({
  documentId,
  stationId,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h2 className="font-semibold">{t('documentation:linkedRecords')}</h2>
        <AddIcon className="w-6 cursor-pointer" onClick={() => setIsModalOpen(true)} />
      </div>
      <LinkedRecordsList />
      <AddLinkModal
        documentId={documentId}
        isModalOpen={isModalOpen}
        stationId={stationId}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export default memo(DocumentationDocumentLinks)
