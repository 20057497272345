import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { useMedia, useToggle } from 'react-use'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { without, take, sortBy } from 'lodash'

import { useLists, useListLoading, fetchListsByStationId, fetchListById } from 'features/list'
import { clearCurrentList } from 'features/list/listSlice'

import SettingsDropdown from 'components/SettingsDropdown'
import { SwitchListsIcon } from 'components/Icons'
import SelectedListTab from 'components/SelectedListTab'
import AddNewListTab from 'components/AddNewListTab'
import { ListType } from 'utils/types'
import { configValues } from 'utils/appConfig'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Tooltip } from '../../utils/helpers/generalHelpers'
import { updateElementOrder } from '../../features/element'
import { useAppDispatch } from '../../store'
import { useCurrentStation } from '../../features/station'
import { useRoles } from '../../hooks'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver, itemsLength) => ({
  width: '100%',
})

const ListNavigation = ({ setArchiveStatus, archiveStatus }) => {
  const appDispatch = useAppDispatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const lists = useLists()
  const currentStation = useCurrentStation()
  const { t, i18n } = useTranslation()
  const { isAdmin, isEditor } = useRoles()
  const isViewer = !isAdmin && !isEditor
  const areListsLoading = useListLoading()
  const { workspaceId, stationId, listId } = useParams<{ workspaceId: string, stationId: string, listId: string }>()
  const isMobile = useMedia('(max-width: 767px)')
  const isTablet = useMedia('(min-width: 768px) and (max-width:1023px)')
  const basicListsOrder = [...lists]
    ?.sort((list1, list2) => list1?.appElement?.elementOrder - list2?.appElement?.elementOrder)
    .map(({ id }) => +id)
  const [listOrder = [], setListOrder] = useState(basicListsOrder || [])
  const [isAddListVisible, toggleIsAddListVisible] = useToggle(false)
  const [orderedLists, setOrderedLists] = useState([])
  const isRtl = i18n.language === 'ar'

  const takeListsToDisplay = useCallback((lists: any[]) => take(lists, isMobile ? 2 : 4), [isMobile, lists])

  const updateListOrder = useCallback(
    (listId: number) => {
      if (listId !== listOrder[0]) {
        const listOrderWithoutCurrent = listOrder?.filter((id) => id != listId) || []
        setListOrder(takeListsToDisplay([listId, ...listOrderWithoutCurrent]))
      }
    },
    [listOrder],
  )
  const fetchCurrentList = (result) => {
    if (result.payload[0]) {
      const currentListId = result?.payload[0]?.id
      dispatch(fetchListById(currentListId))
      history.push(`/workspace/${workspaceId}/stations/${stationId}/lists/${currentListId}`)
    }
  }

  const DropdownContent = (props: any) => (
    <ul className="text-gray-600">
      {sortBy(lists, 'name').map(({ name, id }) => (
        <li key={id}>
          <Link
            className={classnames(
              'block py-2 font-normal hover:text-primary focus:text-primary transition transition-colors',
              { 'text-tertiary-light font-bold': id === +listId },
            )}
            to={`/workspace/${workspaceId}/stations/${stationId}/lists/${id}`}
            onClick={() => {
              updateListOrder(id)
              props.closeDropdown()
            }}>
            {name}
          </Link>
        </li>
      ))}
      <li
        className={classnames(
          `block py-2 font-normal hover:text-primary focus:text-primary transition transition-colors cursor-pointer`,
          { 'text-primary': archiveStatus },
        )}
        onClick={() => {
          setArchiveStatus(!archiveStatus)
          appDispatch(fetchListsByStationId({ archiveStatus: Number(!archiveStatus), stationId })).then((res) => {
            if (res.payload[0]) {
              fetchCurrentList(res)
            } else {
              appDispatch(fetchListsByStationId({ archiveStatus: Number(archiveStatus), stationId })).then((res) => {
                fetchCurrentList(res)
              })
            }
          })
        }}>
        {archiveStatus ? t('common:labels.exitArchive') : t('lists:archivedLists')}
      </li>
    </ul>
  )

  useEffect(() => {
    if (listOrder?.length === lists.length) return
    if (lists.length > listOrder.length) {
      const missingIds = without(
        lists.map(({ id }) => id),
        ...listOrder,
      )
      return setListOrder([...listOrder, ...missingIds])
    }
    if (lists.length < listOrder.length) {
      const idsToRemove = without(listOrder, ...lists.map(({ id }) => id))

      return setListOrder([...listOrder.filter((id) => !idsToRemove.includes(id))])
    }
    if (lists.findIndex(({ id }) => +id === listOrder[0]) === -1) {
      return setListOrder([...lists.map(({ id }) => +id)])
    }
  }, [lists, listOrder])

  const listToRender = useMemo<ListType[]>(() => {
    if ((!isMobile && !isTablet) || lists.length === 0) return lists
    const listsToRender = takeListsToDisplay(listOrder).map((listId) => lists.find(({ id }) => id === +listId))
    const includesCurrentList = listsToRender.find(({ id }) => id === +listId) !== undefined
    if (includesCurrentList) return listsToRender
    const updatedList = [...listsToRender.slice(0, -1), lists.find(({ id }) => id === +listId)].map((item) => ({
      ...item,
      order: item?.appElement?.elementOrder,
    }))
    return sortBy(updatedList, ['order'])
  }, [isMobile, isTablet, listOrder, lists])

  useEffect(() => {
    if (orderedLists !== listToRender) setOrderedLists(listToRender)
  }, [listToRender])

  // const orderedLists = listToRender
  // TODO: add an icon for dragging (it should have classname drag-handle to be able to drag) watch out for:
  // lists with long names, only showing for admins
  const renderTab = (list: ListType, index: number) => {
    const { name, id, appElement } = list
    const isSelected = list ? +listId === +id : false

    return (
      <div className="cursor-pointer" dir={'auto'} title={isSelected ? undefined : name}>
        {list === undefined ? null : isSelected ? (
          <SelectedListTab
            key={id}
            allLists={lists}
            firstListId={+lists[0].id}
            index={index}
            list={list}
            listsLength={lists.length}
            setArchiveStatus={setArchiveStatus}
          />
        ) : (
          <Link
            key={id}
            className={classnames('items-center px-4 mx-1 text-sm truncate block rounded-t-sm py-1.5', {
              'bg-tertiary': !configValues.REACT_APP_IS_CUSTOM_BRANDING,
              hidden: (isMobile || isTablet) && isAddListVisible && listToRender.length === index + 1,
            })}
            style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.listNavigationClasses } : {}}
            to={`/workspace/${workspaceId}/stations/${stationId}/lists/${id}`}
            onClick={() => {
              dispatch(clearCurrentList())
            }}>
            {name}
          </Link>
        )}
      </div>
    )
  }

  const isArchive = archiveStatus || currentStation?.appElement?.isArchived
  const itemsList = isRtl ? [...orderedLists]?.reverse() : orderedLists
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const items = reorder(itemsList, result.source.index, result.destination.index)
    const newOrder = itemsList[result.destination.index]?.appElement?.elementOrder
    appDispatch(updateElementOrder({ id: Number(result.draggableId), newPosition: Number(newOrder) })).then(() => {
      dispatch(fetchListsByStationId({ archiveStatus: Number(isArchive), stationId }))
    })
    setOrderedLists(items)
  }

  return (
    <>
      <nav className="flex max-w-full">
        <div
          id={'allLists'}
          onMouseOver={() => {
            Tooltip('#allLists', t('toolTip:allLists'), 'top', 'custom-top')
          }}>
          <SettingsDropdown Icon={SwitchListsIcon} iconClasses="hover:text-gray-400 m-1 order-first">
            <DropdownContent />
          </SettingsDropdown>
        </div>
        <div className="flex flex-wrap flex-1 w-fit" dir={'auto'} style={{ maxWidth: 'calc(100% - 64px)' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction="horizontal" droppableId="droppableLists">
              {(provided, snapshot) => (
                <div
                  className="flex"
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver, orderedLists.length)}
                  {...provided.droppableProps}>
                  {/*<div className="flex" style={{ maxWidth: 'calc(100% - 64px)' }}>*/}
                  {itemsList
                    // .sort((a, b) => a.appElement?.elementOrder - b.appElement?.elementOrder)
                    .map((list, index) => {
                      const isSelected = list ? +listId === +list.id : false
                      return (
                        <Draggable
                          key={`${list.appElementId}`}
                          draggableId={`${list.appElementId}`}
                          index={index}
                          isDragDisabled={isViewer || isArchive}>
                          {(provided, snapshot) => (
                            <div
                              className={classnames({ 'overflow-hidden': !isSelected })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                              {renderTab(list, index)}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {!areListsLoading && !isArchive && (
          <div
            key={isRtl + ''}
            id={'newList'}
            onMouseOver={() => {
              Tooltip('#newList', t('toolTip:newList'), 'top', 'custom-top')
            }}>
            <AddNewListTab
              isAddListVisible={isAddListVisible}
              listsLength={lists.length}
              setActiveList={updateListOrder}
              setIsAddListVisible={toggleIsAddListVisible}
            />
          </div>
        )}
      </nav>
    </>
  )
}

export default ListNavigation
