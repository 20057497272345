import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import { Draggable } from '@edorivai/react-smooth-dnd'
import { VariableSizeList as List } from 'react-window'
import { useTranslation } from 'react-i18next'
import { KanbanColumnTypeType, RecordType, RecordTypePropsKeys } from 'utils/types'

import ColumnHeader from 'components/Kanban/ColumnHeader'
import { useRecordsSingleGroup } from 'features/record'
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd'
import AddCard from '../Kanban/AddCard'
import { formattingRecordsInStack, getItemSize } from '../../utils/helpers/recordHelpers'
import KanbanItemCopy from '../Kanban/KanbanItemCopy'
import { useCurrentList } from '../../features/list'
import DraggableItem from './dndItems/DraggableItem'

const KanbanColumn: FC<{
  type: KanbanColumnTypeType,
  index: number,
  isViewer: boolean,
  isArchivedList: boolean,
  columnsType: string,
  baseType?: number,
  customId?: number,
  isDragDisabled?: boolean,
  fieldToSortBy: RecordTypePropsKeys,
  addCard: (name: string, value: string | null) => void,
  kanbanContainerHeight: number,
  onCardDrop: (columnId: string | number, dropResult: any) => void,
  updateRecord: (id: string | number, type: string, data: any) => void,
  onRightClickItem: (item: RecordType, position: { x: number, y: number }) => void,
}> = ({
  type,
  index,
  updateRecord,
  addCard,
  columnsType,
  fieldToSortBy,
  kanbanContainerHeight,
  isViewer,
  isArchivedList,
  baseType,
  customId,
  onRightClickItem,
}) => {
  const list = useCurrentList()
  const { i18n, t } = useTranslation('records')
  const isRtl = i18n.language === 'ar'
  const notOrderedRecords = useRecordsSingleGroup(fieldToSortBy, type.value, baseType, customId)
  const recordsList = notOrderedRecords?.sort(
    (a, b) => a?.appElements[0]?.elementOrder - b?.appElements[0]?.elementOrder,
  )
  const [clickedRecordIndex, setClickedRecordIndex] = useState<number>()
  const [useExtendedWidth, setUseExtendedWidth] = useState<boolean>(false)
  const [isCardAdding, setIsCardAdding] = useState<boolean>(false)
  const el = document.querySelector(`.column-${type.value}`)
  const singleLineHeight = 20
  const listRef = useRef(null)

  const records = useMemo(() => {
    if (recordsList === undefined) {
      return undefined
    }
    const listElementId = list?.appElement?.id
    return formattingRecordsInStack(recordsList, listElementId)
    // const recordsCopy = [...recordsList].map((item) => ({ ...item, isParentInTheSameCol: false }))
    // let newListIds: number[] = [] // to simplify testing
    // let newList: any[] = [] // collect ordered records
    // recordsList?.map((rec) => {
    //   if (newListIds.indexOf(+rec.id) === -1) {
    //     //check if the current record is not a subtask
    //     if (+rec?.appElements[0]?.parentId === +list?.appElement?.id) {
    //       newList.push(rec)
    //       newListIds.push(+rec.id)
    //       const recordChildren = [...recordsCopy]
    //         .filter((item) => {
    //           return +item.appElements[0].parentId === +rec.appElements[0].id
    //         })
    //         .map((item) => ({ ...item, isParentInTheSameCol: true }))
    //       newList = [...newList, ...recordChildren]
    //       newListIds = [...newListIds, ...recordChildren.map((item) => +item.id)]
    //     } else {
    //       const isCurrentRecordParenExist =
    //         recordsList.findIndex((r) => r?.appElements[0]?.id === rec?.appElements[0]?.parentId) > -1
    //       // collect rest records in ordered way
    //       if (!isCurrentRecordParenExist) {
    //         const recordParent = rec.appElements[0].parentId
    //         newList.push({ ...rec, hasSisters: true, isParentInTheSameCol: false, order: 1 })
    //         newListIds.push(+rec.id)
    //         const childrenForSameParent = [...recordsCopy]
    //           .filter((item) => {
    //             return +item.appElements[0].parentId === +recordParent && +item.id !== +rec.id
    //           })
    //           .map((item) => ({ ...item, hasSisters: true, isParentInTheSameCol: false }))
    //         newList = [...newList, ...childrenForSameParent]
    //         newListIds = [...newListIds, ...childrenForSameParent.map((item) => +item.id)]
    //       }
    //     }
    //   }
    // })
    // return [...newList]
  }, [recordsList])

  const recordsLength = records?.length
  const rowHeight = useMemo(() => (recordsLength ? 74 : 40), [recordsLength])

  useEffect(() => {
    if (clickedRecordIndex) {
      listRef?.current?.resetAfterIndex(clickedRecordIndex)
      setClickedRecordIndex(undefined)
    } else {
      listRef?.current?.resetAfterIndex(0)
    }
  }, [clickedRecordIndex, records])

  useEffect(() => {
    const { userAgent } = navigator
    const linuxOrWindows = userAgent.indexOf('Win') !== -1 || userAgent.indexOf('Linux') !== -1
    if (el && el?.scrollHeight > el?.clientHeight && linuxOrWindows) {
      setUseExtendedWidth(true)
    } else {
      setUseExtendedWidth(false)
    }
  }, [el, el?.clientHeight])

  const handleAddCard = (name: string) => {
    addCard(name, type.value)
  }
  let tutValue = ''
  if (type.value === null) {
    tutValue = 'fourth__step'
  }

  const visibleItemsSize = useMemo(() => {
    const allSizes =
      records?.slice(0, 20).map((_, index) => {
        return getItemSize({
          containerHeight: kanbanContainerHeight,
          currentRecord: records?.[index],
          recordsLength: records?.length || 0,
          rowHeight,
          singleLineHeight,
        })
      }) || []
    return Math.max(
      allSizes.reduce((first, second) => first + second, 0),
      rowHeight,
    )
  }, [records, rowHeight, kanbanContainerHeight, singleLineHeight])

  let height = Math.min(kanbanContainerHeight, visibleItemsSize)
  const isFullSizeHeight = kanbanContainerHeight === visibleItemsSize
  if (isFullSizeHeight) height = height - 100
  const renderContent = () => (
    <Draggable key={`column-${columnsType}-${type.value}`}>
      <div className="flex flex-col flex-grow shrink-0 max-h-full bg-gray-100 rounded-b-lg w-72 me-3 py-1.5 rounded-te-lg">
        <ColumnHeader
          className={'column-drag-handle'}
          columnPosition={index}
          columnValue={type.value}
          columnsType={columnsType}
          dragHandleProps={undefined}
          isArchivedList={isArchivedList}
          isViewer={isViewer}
          numberOfItemsInColumn={recordsLength}
          showDragIcon
          title={t(type.label)}
        />
        <Droppable
          droppableId={type.value || JSON.stringify(type.value)}
          isCombineEnabled={false}
          mode="virtual"
          renderClone={(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
            <React.Fragment>
              <KanbanItemCopy item={records[rubric.source.index]} provided={provided} />
            </React.Fragment>
          )}
          type="item">
          {(provided, { isDraggingOver, isUsingPlaceholder, draggingFromThisWith, draggingOverWith }) => {
            const columnId = type.value
            const hasMaxHeight = height === kanbanContainerHeight
            const isDraggingOverColumn = isUsingPlaceholder && isDraggingOver
            const isOverInitialColumn = draggingFromThisWith === draggingOverWith
            const correctHeight =
              isDraggingOverColumn && !isOverInitialColumn && !hasMaxHeight ? height + rowHeight : height
            return (
              <>
                <List
                  className={classnames('mx-3 overflow-x-hidden', `column-${type.value}`, {
                    'mb-4 border-2 border-gray-400 border-dashed  rounded min-h-12': !recordsLength,
                  })}
                  height={isCardAdding && hasMaxHeight ? correctHeight - rowHeight : correctHeight}
                  itemCount={recordsLength || 1}
                  itemData={{ baseType, columnId, columnsType, records, setClickedRecordIndex, onRightClickItem }}
                  itemSize={(index) => {
                    return getItemSize({
                      containerHeight: kanbanContainerHeight,
                      currentRecord: records?.[index],
                      recordsLength: records?.length || 0,
                      rowHeight,
                      singleLineHeight,
                    })
                  }}
                  outerRef={provided.innerRef}
                  ref={listRef}
                  width={useExtendedWidth ? 276 : 264}>
                  {DraggableItem}
                </List>
                {columnsType !== 'health' && (
                  <div className="px-3" data-tut={tutValue} style={{ direction: isRtl ? 'rtl' : 'ltr' }}>
                    {!isArchivedList && (
                      <AddCard addCard={handleAddCard} isCardAdding={isCardAdding} setIsCardAdding={setIsCardAdding} />
                    )}
                  </div>
                )}
              </>
            )
          }}
        </Droppable>
      </div>
    </Draggable>
  )

  return baseType === 5 || columnsType === 'documents' ? recordsLength > 0 && renderContent() : renderContent()
}

export default KanbanColumn
