import React, { FC } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { InputLabelType } from 'utils/types/inputs'

const InputLabel: FC<InputLabelType> = ({ label, required, className, name }) => {
  const { t } = useTranslation()

  if (label === undefined) return null

  return (
    <label
      className={classnames('flex items-baseline text-sm mb-2 font-bold', className)}
      htmlFor={`input-${name}`}
      id={`contenteditable-${name}`}>
      {label}
      {required && <span className="text-xs font-bold text-gray-800 uppercase ps-2">{t('labels.required')}</span>}
    </label>
  )
}

export default InputLabel
