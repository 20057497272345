import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

const ViewTypeOption = ({ data: { label, icon: Icon }, ...rest }) => {
  const { t } = useTranslation()
  return (
    <button className={classnames('react-select__option')} {...rest}>
      <Icon className="w-5 md:w-6 me-1" />
      {t(label)}
    </button>
  )
}

export default ViewTypeOption
