import React, { FC } from 'react'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { NextIcon } from '../../../../Icons'

type PriorityDropdownType = {
  innerRef?: any,
  innerProps?: any,
  value?: string,
  data: { value: string },
  isFocused?: boolean,
  isSelected?: boolean,
  elementBaseType?: AppElementBaseTypes,
}

const SelectParentRow: FC<PriorityDropdownType> = ({
  innerRef,
  innerProps,
  value,
  options,
  isFocused,
  isSelected,
  elementBaseType = AppElementBaseTypes.ListAppElement,
}) => {
  const { label, parentLevel } = options?.find((i) => +i.value === +value)
  return (
    <div ref={innerRef} {...innerProps} className={`flex justify-between p-3 hover:bg-primary/20`}>
      <div>{label}</div>
      {parentLevel <= 1 && <NextIcon className={`w-4 opacity-70`} />}
    </div>
  )
}

export default SelectParentRow
