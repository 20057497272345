import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { ProfilePropsType } from 'utils/types'
import { useCurrentUser, updateUser, uploadAvatar } from 'features/user'
import { PersonalProfileForm, ProfileResetPasswordForm } from 'components/forms'
import { ChevronLeftIcon, InfoIcon, PasswordIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import baseAPI from 'utils/baseAPI'
import ProfileDetailsForm from 'components/forms/profile/ProfileDetailsForm'

const ProfilePage: FC = () => {
  const user: ProfilePropsType = useCurrentUser()
  const dispatch = useDispatch()
  const { t } = useTranslation('user')
  const { goBack } = useHistory()

  const links = [
    {
      description: t('personalInformationDescription'),
      Icon: InfoIcon,
      title: t('common:labels.personalInformation'),
      to: `/profile/personal-information`,
    },
    {
      description: t('passwordDescription'),
      Icon: PasswordIcon,
      title: t('common:labels.password'),
      to: `/profile/reset-password`,
    },
    {
      description: t('detailsDescription'),
      Icon: InfoIcon,
      title: t('common:labels.details'),
      to: `/profile/details`,
    },
  ]

  const resendEmail = () => {
    baseAPI('api/users/resend/email', {
      body: { email: user.email },
      method: 'POST',
    })
  }

  return (
    <>
      <header className="flex items-center mb-4 md:mb-8 min-h-12 ms-10">
        <button className="p-3 -ms-12 transition-colors hover:text-primary focus:text-primary" onClick={goBack}>
          <ChevronLeftIcon className="w-6 rtl-mirror" />
        </button>
        <h1 className="flex flex-wrap items-center">
          {t('profile')} <span className="font-normal ps-2">{t('common:labels.settings')}</span>
        </h1>
      </header>
      <div
        className="bg-white max-w-screen-2xl rounded-ts-none rounded-2xl lg:flex lg:flex-1 lg:mx-10 h-full overflow-auto"
        style={{
          minHeight: '50%',
        }}>
        <ul className="flex-none pt-4 border-b-2 border-gray-200 solid lg:w-72 lg:border-b-0 lg:border-e-2">
          {links.map(
            ({ title, to, description, Icon }: { title: string, to: string, description: string, Icon?: any }) => (
              <li key={to}>
                <NavLink
                  activeClassName="bg-tertiary-light/5 text-tertiary-light"
                  className="flex items-center py-4 ps-8 pe-4 transition transition-colors hover:text-primary focus:text-primary"
                  replace
                  to={to}>
                  {Icon != null && <Icon className="shrink-0 w-6 me-2" />}
                  <div>
                    <h5 className="text-sm font-bold">{title}</h5>
                    <small className="block text-xs text-gray-600">{description}</small>
                  </div>
                </NavLink>
              </li>
            ),
          )}
        </ul>
        <Switch>
          <Route exact path="/profile/personal-information">
            <div className="flex flex-col w-full">
              {!isEmpty(user) && !user?.verified && (
                <div className="px-6 py-4 m-8 mb-0 rounded bg-caution">
                  <div className="mb-2 min-width-0">
                    {t('accountNotConfirmed', { returnObjects: true })[0]}
                    <br />
                    {t('accountNotConfirmed', { returnObjects: true })[1]}
                  </div>
                  <Button small variant={ButtonVariant.Secondary} onClick={resendEmail}>
                    {t('resendConfirmationEmail')}
                  </Button>
                </div>
              )}
              <PersonalProfileForm
                updateAvatar={(avatar: { filename: string, base64data: string }) => dispatch(uploadAvatar(avatar))}
                updateUser={(values: any) => dispatch(updateUser(values))}
                user={user}
              />
            </div>
          </Route>
          <Route path="/profile/reset-password">
            <div className="flex flex-col w-full">
              {!isEmpty(user) && user.isPasswordless && (
                <div className="px-6 py-4 m-8 mb-0 rounded bg-caution">
                  <div className="mb-2 min-width-0">{t('yourAccountIsPasswordless')}</div>
                </div>
              )}
              <ProfileResetPasswordForm />
            </div>
          </Route>
          <Route path="/profile/details">
            <ProfileDetailsForm updateUser={(values: any) => dispatch(updateUser(values))} user={user} />
          </Route>
          <Route>
            <Redirect to="/profile/personal-information" />
          </Route>
        </Switch>
      </div>
    </>
  )
}

export default ProfilePage
