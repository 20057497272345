import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import i18n from 'i18n'
import { toast } from 'react-toastify'
import { WorkspaceType } from 'utils/types'
import baseAPI from 'utils/baseAPI'
import { DashboardWidgetBaseTypes } from 'utils/constant/enums/DashboardWidgetBaseType'
import { DashboardStateType } from '../../utils/types/states'
import { archiveElement, onCreateElementDashboard, onDeleteElementDashboard } from '../element'
import { DashboardGetWidgetDataType } from '../../utils/types/states/DashboardGetWidgetDataType'

export const fetchWorkspaces = createAsyncThunk(
  'workspace/fetchWorkspaces',
  async (archive: number, { rejectWithValue }) => {
    const archiveStatus = !archive ? 0 : 1
    const response = await baseAPI(`api/workspaces?arc=${archiveStatus}`)
    if (+response.status >= 400) return rejectWithValue(response)
    return response.body
  },
)

export const fetchWorkspaceById = createAsyncThunk(
  'workspace/fetchWorkspaceById',
  async (workspaceId: string | number, { rejectWithValue }) => {
    const response = await baseAPI(`api/workspaces/${workspaceId}`)
    if (+response.status >= 400) return rejectWithValue(404)
    return response.body
  },
)

export const addWorkspace = createAsyncThunk(
  'workspace/addWorkspace',
  async (workspace: {
    accessLevel: number,
    baseElementType: number,
    elementName: string,
    description?: string,
    avatarType: number,
    avatarBackgroundColor?: string,
    avatar?: string,
    avatarForegroundColor?: string,
    color?: string,
    enabledNotifications?: boolean,
    tags?: string,
    useAvatar?: boolean,
  }) => {
    const response = await baseAPI('api/workspaces', { body: workspace, method: 'POST' })
    return response.body
  },
)

export const getAllDashboardForWorkspace = createAsyncThunk(
  'workspace/getAllDashboardForWorkspace',
  async (params: { scope: 'w' | 's' | 'l', id: number }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/dashboards/element?scope=${params.scope}&id=${params.id}`)

      if (response.status >= 400) throw response.message

      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateWorkspaceById = createAsyncThunk(
  'workspace/updateWorkspaceById',
  async (workspace: WorkspaceType) => {
    const response = await baseAPI(`api/workspaces/${workspace?.id}`, {
      body: workspace,
      method: 'PUT',
    })

    return { ...workspace, ...response.body }
  },
)

export const deleteWorkspaceById = createAsyncThunk('workspace/deleteWorkspaceById', async (workspaceId: number) => {
  const response = await baseAPI(`api/workspaces/${workspaceId}`, {
    method: 'DELETE',
  })
  return response.body
})

export const leaveWorkspace = createAsyncThunk('workspace/leaveWorkspace', async (workspaceId: number) => {
  const response = await baseAPI(`api/workspaces/${workspaceId}/leave`, {
    method: 'DELETE',
  })
  return response.body
})

export const uploadAvatar = createAsyncThunk(
  'workspace/uploadAvatar',
  async (params: { workspaceId: number, avatar: { filename: string, base64data: string } }) => {
    const { workspaceId, avatar } = params
    if (workspaceId) {
      const payload = { ...avatar }
      const response = await baseAPI(`api/workspaces/upload/${workspaceId}`, {
        body: payload,
        method: 'PUT',
      })
      return response.body
    }
  },
)
export const getWorkspaceDashboard = createAsyncThunk('workspace/dashboard', async (dashboardId: number) => {
  const response = await baseAPI(`api/dashboards?id=${dashboardId}`)

  return response.body
})

export const saveWorkspaceDashboard = createAsyncThunk(
  'workspace/saveDashboard',
  async (dashboard: DashboardStateType, { getState, rejectWithValue }) => {
    try {
      const dashboardWithData = cloneDeep(dashboard)
      const state = getState()
      const dashboardWidgets = state.element.dashboardWidgets

      dashboardWithData.widgets = dashboard.widgets.map((w) => {
        return {
          ...w,
          ...dashboardWidgets[w.id],
          dimensions: {
            ...w.dimensions,
          },
          title: w.title,
        }
      })

      const response = await baseAPI(`api/dashboards`, { body: dashboardWithData, method: 'PUT' })

      if (+response.status >= 400) throw response
      return response.body
    } catch (e) {
      if (e?.body?.errors?.length > 0) {
        toast(i18n.t('notifications:errorSavingWidget'), { icon: false, type: 'error' })
      }
      return rejectWithValue(e)
    }
  },
)

export const getAvailableWorkspaceWidgets = createAsyncThunk(
  'workspace/getAvailableWidgets',
  async (params: { elementType: 'w' | 's' | 'l', elementId: number }) => {
    const response = await baseAPI(`api/widgets/available?scope=${params.elementType}&elementId=${params.elementId}`)
    return response.body
  },
)

export const addWidgetToDashboard = createAsyncThunk(
  'workspace/addWidgetToDashboard',
  async (params: {
    elementType: 'w' | 's' | 'l',
    elementId: number,
    baseType: DashboardWidgetBaseTypes,
    parameters?: DashboardGetWidgetDataType,
  }) => {
    const body = { parameters: params.parameters } || {}
    const response = await baseAPI(
      `api/widgets?scope=${params.elementType}&elementId=${params.elementId}&baseType=${params.baseType}`,
      { body, method: 'POST' },
    )
    return response.body
  },
)

//workspace plan stuff

export const chooseWorkspaceTrailProPlan = createAsyncThunk(
  'workspace/chooseWorkspaceTrailProPlan',
  async (workspaceId: string | number, { rejectWithValue }) => {
    const body = {
      price: '',
      trialMode: true,
      workspaceId,
    }
    try {
      const response = await baseAPI('api/subscriptions/subscribe', { body, method: 'POST' })
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const chooseWorkspaceBasicPlan = createAsyncThunk(
  'workspace/chooseWorkspaceBasicPlan',
  async (workspaceId: string | number, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/subscriptions/optOut/${workspaceId}`, { method: 'POST' })
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const getHomePageDashboard = createAsyncThunk('workspace/getHomePageDashboard', async () => {
  const response = await baseAPI(`api/dashboards/home`)
  return response.body
})

export const getMyTasksWidget = createAsyncThunk('workspace/getMyTasksWidget', async (appElementId: number) => {
  const response = await baseAPI(`api/dashboards/myDueRecords?appElementId=${appElementId}`)
  return response.body
})

export const getFilteredTasksWidget = createAsyncThunk('workspace/getFilteredTasksWidget', async (search?: string) => {
  const searchData = `${search}&`
  const response = await baseAPI(`api/dashboards/records?${searchData}arc=0&sort=id&direction=asc&page=1&limit=1000000`)
  return response.body
})

export const archiveWorkspaceById = createAsyncThunk(
  'workspace/archiveWorkspaceById',
  async (element: { elementId: number, status: number, recursive: number }, { rejectWithValue }) => {
    const { elementId, status, recursive } = element
    return archiveElement(elementId, status, recursive, rejectWithValue)
  },
)
export const updateWorkspaceCustomFields = createAsyncThunk(
  'workspace/updateWorkspaceCustomFields',
  async ({ workspaceId, body }: { workspaceId: string | number, body: any }, { rejectWithValue }) => {
    try {
      const response = await baseAPI(`api/workspaces/${workspaceId}/update`, { body, method: 'Put' })
      if (+response.status >= 400) throw response.message
      return response.body
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onCreateNewWorkspaceDashboard = createAsyncThunk(
  'workspace/onCreateNewWorkspaceDashboard',
  async (body: { scope: 'w' | 's' | 'l', appElementId: number, title: string }, { rejectWithValue }) => {
    return onCreateElementDashboard({ ...body, footer: '', header: '' }, rejectWithValue)
  },
)

export const onDeleteWorkspaceDashboard = createAsyncThunk(
  'workspace/onDeleteWorkspaceDashboard',
  async (elementId: number, { rejectWithValue }) => {
    return onDeleteElementDashboard(elementId, rejectWithValue)
  },
)
