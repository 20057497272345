import React, { FC } from 'react'
import { getPriorityIconByValue } from 'utils/helpers/recordHelpers'
import classnames from 'classnames'

type PriorityDropdownType = {
  innerRef?: any,
  innerProps?: any,
  value?: string,
  data: { value: string },
  isFocused?: boolean,
  isSelected?: boolean,
}

const PriorityDropdown: FC<PriorityDropdownType> = ({ innerRef, innerProps, value, data, isFocused, isSelected }) => {
  const { Icon, color, label } = getPriorityIconByValue(value === null ? null : +(value || data?.value))
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classnames('-my-1 react-select__option', {
        '-small-padding': value === undefined,
        'react-select__option--is-focused': isFocused,
        'react-select__option--is-selected': isSelected,
      })}>
      <Icon className="w-4 me-1 -ms-1" style={{ color }} /> {label}
    </div>
  )
}

export default PriorityDropdown
