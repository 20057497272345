import React, { FC } from 'react'
import { CommentType } from 'utils/types'
import { StationDocumentationCommentsType } from 'utils/types/states/StationDocumentationComments'
import DOMPurify from 'dompurify'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SingleAttachment, { NewAttachment } from '../AttachmentList/SingleAttachment'
import { AddIcon } from '../Icons'
import Loader from '../Loader'

type Comment = CommentType & StationDocumentationCommentsType

const ExtendedComment: FC<{
  comment: Comment,
  isEdit?: boolean,
  isActivityWidget?: boolean,
  isDocumentation?: boolean,
  isRecordEditable?: boolean,
  showExtend?: boolean,
  elementId: string,
  inputProps: any,
  filesBeingUploaded: any[],
  assetsList: any[],
  onDeleteAsset: (value: string) => void,
  onUploadAsset: (value: string) => void,
  onDropRejected: (value: string) => void,
  isRecordComment?: boolean,
}> = ({
  comment,
  isEdit,
  isActivityWidget,
  onDeleteAsset,
  showExtend,
  isDocumentation,
  inputProps,
  filesBeingUploaded,
  onDropRejected,
  assetsList,
  isRecordComment,
}) => {
  const { t, i18n } = useTranslation('records')
  const isRtl = i18n.language === 'ar'

  return (
    <div
      className={classNames(`relative p-1 rounded bg-opacity-50`, {
        'me-4': isActivityWidget,
      })}>
      {showExtend ? (
        <div
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(comment.text),
          }}
        />
      ) : (
        !isActivityWidget && (
          <div className={`absolute -top-8 -ms-${isRtl ? '1' : '4'}`}>
            <input id="commentAttachment" {...inputProps} />
            <label
              className="flex p-3 cursor-pointer md:-mr-3 hover:text-primary transition transition-colors"
              htmlFor="commentAttachment">
              <AddIcon className="w-5" />
              <p className="text-sm ms-1">{t('addAttachment')}</p>
            </label>
          </div>
        )
      )}
      <div className="flex flex-wrap mt-3 -me-2">
        {Object.keys(filesBeingUploaded).map((fileName: string) => {
          const file = filesBeingUploaded[+fileName]

          return (
            <div key={`${file?.name}-${file?.size}`} className="w-1/4 mb-2 pe-2">
              <div className="relative flex items-center justify-center bg-gray-200 rounded h-28">
                <Loader loaderClasses="mb-8" />
                <NewAttachment attachment={file} />
              </div>
            </div>
          )
        })}
        {assetsList?.map((asset, idx) => {
          return (
            <SingleAttachment
              key={idx}
              assetId={asset.appElementAssetId}
              attachment={asset.appElementAsset}
              isDocumentation={isDocumentation}
              isEditor={isEdit}
              isRecordComment={isRecordComment}
              onDeleteAttachment={onDeleteAsset}
              onDropRejected={onDropRejected}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ExtendedComment
