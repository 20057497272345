import { DropdownOptionType } from 'utils/types/inputs'
import { RgbColorType } from 'utils/types'

type ColorType = {
  label: string,
  value: string,
}

export const labelColors: DropdownOptionType[] = [
  {
    label: 0,
    value: '#6BDAD5',
  },
  {
    label: 1,
    value: '#00CE7C',
  },
  {
    label: 2,
    value: '#cdd317',
  },
  {
    label: 3,
    value: '#ffc30f',
  },
  {
    label: 4,
    value: '#FF671D',
  },
  {
    label: 5,
    value: '#EB0045',
  },
  {
    label: 6,
    value: '#5c9bbc',
  },
  {
    label: 7,
    value: '#00263A',
  },
]

export const statusColors: DropdownOptionType[] = [
  {
    label: 0,
    value: '#6BDAD5',
  },
  {
    label: 1,
    value: '#00CE7C',
  },
  {
    label: 2,
    value: '#cdd317',
  },
  {
    label: 3,
    value: '#ffc30f',
  },
  {
    label: 4,
    value: '#FF671D',
  },
  {
    label: 5,
    value: '#EB0045',
  },
  {
    label: 6,
    value: '#5c9bbc',
  },
  {
    label: 7,
    value: '#00263A',
  },
  {
    label: 8,
    value: '#cccccc',
  },
]

export const chartColors: ColorType[] = [
  {
    label: 'Emerald',
    value: '#00CE7C',
  },
  {
    label: 'Mikado yellow',
    value: '#FFC30F',
  },
  {
    label: 'Light sea green',
    value: '#00B2A9',
  },
  {
    label: 'Red',
    value: '#EB0045',
  },
  {
    label: 'Pear',
    value: '#E3E935',
  },
  {
    label: 'Light gray blue',
    value: '#5C9BBC',
  },
  {
    label: 'Orange',
    value: '#FF671D',
  },
  {
    label: 'Dark blue',
    value: '#00263A',
  },
]

export const avatarColors = [
  '#00B2A9',
  '#E3E935',
  '#FFD040',
  '#EB0045',
  '#00CE7C',
  '#FF671D',
  '#EEEEEE',
  '#C5D4E2',
  '#33647E',
  '#00263A',
]

interface IColors {
  [index: number]: string;
}

const colorHelpers: IColors = {
  0: 'red',
  1: 'green',
  2: 'blue',
}

export const hexToRgb: (hex: string | undefined) => RgbColorType = (hex) => {
  if (hex === undefined) return { blue: 0, green: 0, red: 0 }

  return hex
    ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => '#' + r + r + g + g + b + b)
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.reduce((acc: any, color, index) => {
      acc[colorHelpers[index]] = parseInt(color, 16)
      return acc
    }, {})
}

export const generateOpacityBackgroundColor = (color: string) => {
  return `${color}20`
}

export const getChartColor = (index: number): string => {
  return chartColors[index % labelColors.length].value
}
