import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/inputs'

import { addOrUpdateFilter } from 'features/record'

type NameFilterType = {
  filterIndex: number,
  filterValue: string | undefined,
}

const NameFilter: FC<NameFilterType> = ({ filterIndex, filterValue = '' }) => {
  const [currentValue, setCurrentValue] = useState<string>(filterValue)
  const dispatch = useDispatch()
  const { t } = useTranslation('records')

  const setNewFilter = (value: string) => {
    dispatch(addOrUpdateFilter(['name', value, `${filterIndex}`]))
  }

  return (
    <>
      <span className="mx-2">{t('contains')}</span>
      <Input
        classes={{ input: 'hover:border-primary', wrapper: 'flex-1 -mb-0' }}
        error=""
        name="filter"
        touched={false}
        value={currentValue}
        onBlur={(event) => {
          setNewFilter(event.target.value)
        }}
        onChange={(event) => {
          setCurrentValue(event.target.value)
        }}
      />
    </>
  )
}

export default NameFilter
