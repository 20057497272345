import React, { CSSProperties, FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ControlProps, IndicatorProps, Styles } from 'react-select'
import { useRoles } from 'hooks'
import { withContentEditable } from 'hocs'

import { Dropdown, RecordDatePicker, UserDropdown } from 'components/inputs'
import UsernameWithAvatar from 'components/UsernameWithAvatar'
import SelectedUser from 'components/inputs/UserDropdown/SelectedUser'

import { ElementMemberType, RecordType, StationType } from 'utils/types'
import { getFormattedDateWithTime } from 'utils/helpers/generalHelpers'
import { getInheritedCustomFields } from 'utils/helpers/customFields'
import { setColorByStates, setColorByStatus } from 'utils/types/widgetUtils'

import { updateStation, updateStationCustomFields, useCurrentStationMembers } from 'features/station'
import { useCurrentUser } from 'features/user'

import { attributesStatuses, stationStates, stationStatuses } from 'utils/constant/resources/stationResorces'
import ColoredDropdownOption from '../inputs/LabelsSelect/ColoredDropdownOption'
import ElementCustomFields from '../CustomFields/ElementCustomFields'

type StationSettingsPropsType = {
  station: StationType,
}

const ContentEditableUserDropdown = withContentEditable(UserDropdown)

export const dropdownStyles: Partial<Styles<{ label: string, value: string }, false>> = (menuItem) => {
  return {
    control: (_: CSSProperties, state: ControlProps<{ label: string, value: string }, boolean>) => {
      return {
        backgroundColor: state.isFocused ? '#EEEEEE' : `${menuItem.bgColor}!important`,
        borderRadius: '4px',
        boxShadow: state.isFocused ? '0px 1px 2px #EEEEEE' : 'none',
        color: menuItem.color,
        display: 'flex',
        fontSize: 13,
        justifyContent: 'center',
        padding: state.isFocused ? '' : '3px 0 4px',
        width: '100%',
      }
    },
    dropdownIndicator: (base: CSSProperties, state: IndicatorProps<{ label: string, value: string }, boolean>) => {
      return {
        ...base,
        display: state.isFocused ? 'block' : 'none',
      }
    },
    indicatorSeparator: () => {
      return { display: 'none' }
    },
    menu: (base: CSSProperties) => {
      return {
        ...base,
        backgroundColor: '#EEEEEE',
      }
    },
    singleValue: (base: CSSProperties, state: ControlProps<{ label: string, value: string }, boolean>) => {
      return {
        ...base,
        color: '#C5D4E2',
      }
    },
  }
}

const StationSettings: FC<StationSettingsPropsType> = ({ station, createdAt, isArchived }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation(['stationSettings', 'station'])
  const { isEditor } = useRoles()
  const stationMembers = useCurrentStationMembers()
  const customFieldsList = station?.customFields ? getInheritedCustomFields(station?.customFields) : []
  const language = i18n.language
  const user = useCurrentUser()
  const isHijri = user.isHijri
  const isEditable = isEditor && !isArchived

  const handleUpdateStationDate = async (values: Partial<RecordType>) => {
    dispatch(updateStation({ ...station, ...values }))
  }

  const translatedStates = useMemo(
    () =>
      stationStates.map((state) => ({
        ...state,
        color: setColorByStates(state.value).color,
        label: t(`station:stationStates.${state.label}`),
      })),
    [t, stationStates],
  )

  const translatedStatuses = useMemo(
    () =>
      stationStatuses.map((status) => ({
        ...status,
        color: setColorByStatus(status.value).color,
        label: t(`station:stationStatuses.${status.label}`),
      })),
    [t, stationStatuses],
  )

  const translatedAttributesStatuses = useMemo(
    () =>
      attributesStatuses.map((status) => ({
        ...status,
        color: setColorByStatus(status.value).color,
        label: t(`station:stationStatuses.${status.label}`),
      })),
    [t, attributesStatuses],
  )

  const dropDownsList = [
    {
      currentItemColor: setColorByStatus(station?.status),
      key: 'status',
      name: 'scheduleStatus',
      options: translatedStatuses,
      value: station?.status,
    },
    {
      currentItemColor: setColorByStatus(station?.qualityHealth),
      key: 'qualityHealth',
      name: 'qualityHealth',
      options: translatedAttributesStatuses,
      value: station?.qualityHealth,
    },
    {
      currentItemColor: setColorByStatus(station?.budgetHealth),
      key: 'budgetHealth',
      name: 'budgetHealth',
      options: translatedAttributesStatuses,
      value: station?.budgetHealth,
    },
    {
      currentItemColor: setColorByStates(station?.state),
      key: 'state',
      name: 'state',
      options: translatedStates,
      value: station?.state,
    },
  ]

  const onUpdateElement = (value: any) => {
    dispatch(updateStationCustomFields({ body: value, stationId: station?.id }))
  }

  const renderSelectedUser = (selectedUser: ElementMemberType, { showInput }: { showInput: () => void }) => {
    let user: ElementMemberType | undefined = selectedUser
    if (typeof selectedUser === 'string' || typeof selectedUser === 'number') {
      user = stationMembers?.find((member) => member.userId == selectedUser)
    }

    return (
      <div className="-m-2">
        <SelectedUser
          className="block w-full px-2 border-2 border-transparent border-dashed rounded cursor-pointer hover:bg-white focus:bg-white leading-6 min-h-12 focus:overflow-visible focus:whitespace-normal transition transition-colors hover:border-gray-300 focus:outline-none focus:border-gray-400"
          selectedUser={!user ? station?.manager : user}
          onClick={showInput}
        />
      </div>
    )
  }

  return (
    <>
      <table cellPadding="0" className="w-full max-h-full overflow-y-auto border-separate table-fixed">
        <colgroup>
          <col className="w-4/12" />
          <col className="w-8/12" />
        </colgroup>
        <tbody>
          <tr>
            <td className="text-sm font-bold">{t('owner')}</td>
            <td>
              {station?.owner && <UsernameWithAvatar classes={{ wrapper: 'px-0.5 my-2.5' }} user={station?.owner} />}
            </td>
          </tr>
          <tr>
            <td className="text-sm font-bold">{t('manager')}</td>
            <td>
              <ContentEditableUserDropdown
                className="m-0"
                isOnClickDisabled={!isEditable}
                name="managerId"
                options={stationMembers}
                renderNotEditingState={renderSelectedUser}
                value={station?.managerId}
                onSubmit={({ managerId: newManagerId }: { managerId: string }) => {
                  if (+newManagerId !== station?.managerId)
                    dispatch(updateStation({ ...station, managerId: newManagerId }))
                }}
              />
            </td>
          </tr>
          <div className="p-1" />
          <tr>
            <td className="text-sm font-bold">{t('records:listing.startDate')}</td>
            <td className="text-sm">
              <RecordDatePicker
                date={station?.startDate}
                dateToCompare={station?.endDate}
                handleRecordChange={handleUpdateStationDate}
                isDisabled={!isEditable}
                name="startDate"
                shouldShowPlaceholder
              />
            </td>
          </tr>
          <div className="p-1" />
          <tr>
            <td className="text-sm font-bold">{t('records:listing.endDate')}</td>
            <td className="text-sm">
              <RecordDatePicker
                date={station?.endDate}
                dateToCompare={station?.startDate}
                handleRecordChange={handleUpdateStationDate}
                isDisabled={!isEditable}
                name="endDate"
                shouldShowPlaceholder
              />
            </td>
          </tr>
          <div className="p-0.5" />
          {dropDownsList.map((item, index) => {
            return (
              <tr key={index}>
                <td className="text-sm font-bold">{t(item.name)}</td>
                <td>
                  <Dropdown
                    components={{ Option: ColoredDropdownOption }}
                    isDisabled={!isEditable}
                    name={item.name}
                    options={item.options}
                    styles={dropdownStyles(item.currentItemColor)}
                    value={item.value}
                    onChange={({ target: { value } }: { target: { value: string } }) => {
                      dispatch(updateStation({ ...station, [item.key]: parseInt(value) }))
                    }}
                  />
                </td>
              </tr>
            )
          })}
          <div className="p-1" />
          <ElementCustomFields
            currentElement={station}
            customFieldsList={customFieldsList}
            isEditor={isEditor}
            updateElement={onUpdateElement}
          />
        </tbody>
      </table>

      <div className="pt-3 mt-1 text-sm text-gray-400 border-t border-gray-200">
        <span>{t('common:labels.createdAt')} </span>
        <span dir="auto">{createdAt && getFormattedDateWithTime(createdAt, isHijri, language)}</span>
      </div>
    </>
  )
}

export default StationSettings
