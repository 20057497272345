import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ children, ...routeProps }: { routeProps?: any, children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const isAuth = !!localStorage.getItem('userId')

  return (
    <Route {...routeProps}>
      {isAuth ? children : <Redirect to={{ pathname: '/sign-in', state: { backTo: pathname } }} />}
    </Route>
  )
}

export default ProtectedRoute
