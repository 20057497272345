import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { clearElementMakerCheckerPolicy } from 'features/element/elementSlice'
import { useCurrentList } from 'features/list'
import {
  fetchElementMakerCheckerPolicyByElement,
  removeElementMakerCheckerPolicy,
  useElementMakerCheckerPolicy,
  useElementMakerCheckerPolicyLoading,
} from 'features/element'
import Button, { ButtonVariant } from '../Buttons'
import Loader from '../Loader'
import { useAppDispatch } from '../../store'
import ContextMenu from '../ContextMenu'
import ConfirmationModal from '../ConfirmationModal'
import { useRoles } from '../../hooks'
import MakerCheckerForm from './MakerCheckerForm'

const MakerCheckerSettingsPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const currentList = useCurrentList()
  const { isAdmin } = useRoles()
  const elementMakerCheckerPolicy = useElementMakerCheckerPolicy()
  const isLoading = useElementMakerCheckerPolicyLoading()
  const isNoPolicy = elementMakerCheckerPolicy === null || elementMakerCheckerPolicy === undefined
  const [showAddPolicyForm, setShowAddPolicyForm] = useState(isNoPolicy || false)
  const [isCreatingNew, setIsCreatingNew] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const isEditMode = !!elementMakerCheckerPolicy?.id
  const isDisabled = !isAdmin

  useEffect(() => {
    if (currentList?.appElement?.id)
      dispatch(fetchElementMakerCheckerPolicyByElement(currentList?.appElement?.id)).then((e) => {
        if (e?.payload?.message === 'makerCheckerPolicyNotFound') {
          setShowAddPolicyForm(false)
        } else {
          setShowAddPolicyForm(true)
        }
      })
    return () => {
      dispatch(clearElementMakerCheckerPolicy())
      setShowAddPolicyForm(false)
    }
  }, [currentList])

  const handleRemovePolicy = () => {
    if (elementMakerCheckerPolicy.id)
      dispatch(removeElementMakerCheckerPolicy(+elementMakerCheckerPolicy?.id)).then((e) => {
        setShowAddPolicyForm(false)
        setIsDelete(false)
      })
  }

  const formProps = {
    currentItem: currentList,
    isCreatingNew,
    isDisabled,
    isEditMode,
    makerCheckerPolicy: elementMakerCheckerPolicy,
    setIsCreatingNew,
    setShowAddPolicyForm,
  }

  return (
    <div className="w-full h-full p-8 overflow-hidden">
      <div className="flex items-center justify-between">
        <label className="block mb-5 text-lg font-bold">{t('listSettings:makerCheckerPolicy')}</label>
        {elementMakerCheckerPolicy?.id && !isDisabled && (
          <ContextMenu
            isCustomButton
            isDefaultPopup
            isSettingsList
            render={({ closeMenu }) => (
              <div className="flex flex-col items-start p-2 text-gray-600">
                <button
                  className="text-danger hover:text-primary "
                  type="button"
                  onClick={() => {
                    setIsDelete(true)
                    closeMenu()
                  }}>
                  {t('common:labels.remove')}
                </button>
              </div>
            )}
          />
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-y-auto h-5/6">
          <div className="flex items-center justify-center w-full h-full">
            {!showAddPolicyForm ? (
              <div className="flex flex-col justify-center items-center text-center">
                <h3 className="font-bold">{t('listSettings:addNewPolicyHeader')}</h3>
                <p className="mt-2">{t('listSettings:addNewPolicyText')}</p>
                {!isDisabled && (
                  <Button
                    className="mt-5"
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                      setIsCreatingNew(true)
                      setShowAddPolicyForm(true)
                    }}>
                    {t('listSettings:addNewPolicy')}
                  </Button>
                )}
              </div>
            ) : (
              <MakerCheckerForm {...formProps} />
            )}
          </div>
        </div>
      )}
      <ConfirmationModal
        cancelMessage={t('common:labels.cancel')}
        confirmMessage={t('common:labels.remove')}
        confirmationMessage={t('customFields:deleteCustomFieldMessage')}
        isModalOpen={isDelete}
        onCancel={() => setIsDelete(false)}
        onConfirm={handleRemovePolicy}
      />
    </div>
  )
}
export default MakerCheckerSettingsPage
