import React, { FC, useCallback, useEffect } from 'react'
import { useParams, Switch, Route, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  // fetchWorkspaces,
  useCurrentWorkspaceAppElementId,
  useCurrentWorkspaceCreatedAt,
  useCurrentWorkspaceIsArchived,
  useCurrentWorkspaceName,
  useCurrentWorkspaceOwnerId,
} from 'features/workspace'
import { fetchUsersByWorkspaceId, useCurrentUserPermissions, useUsersByWorkspace } from 'features/user'
import { withExplicitRole } from 'hocs/withRole'
import { findNumberOfAdmins } from 'utils/helpers/generalHelpers'
import { configValues } from 'utils/appConfig'

import { BoxIcon, CustomFieldListIcon, InfoIcon, UsersIcon } from 'components/Icons'
import SettingsWithTabs from 'components/SettingsWithTabs'
import ApplyForTrial from 'components/Subscription/ApplyForTrial'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import Header from '../../components/Header'
import { useNavbar } from '../../contexts/NavbarContext'
import RolesEnum from '../../utils/constant/enums'
import WorkspaceCustomFieldsForm from '../../components/CustomFields/WorkspaceCustomFieldsForm'
import { useRoles } from '../../hooks'
import WorkspaceDetailsPage from './WorkspaceDetailsPage'
import UsersListPage from './UsersListPage'
import WorkspacePlanPage from './WorkspacePlanPage'

const WorkspaceSettingsPage: FC = () => {
  const { workspaceId }: { workspaceId: string } = useParams()
  const { t } = useTranslation(['workspace', 'workspaceSettings'])
  const currentWorkspaceName = useCurrentWorkspaceName()
  const currentWorkspaceIsArchived = useCurrentWorkspaceIsArchived()
  const currentWorkspaceAppElementId = useCurrentWorkspaceAppElementId()
  const currentWorkspaceOwnerId = useCurrentWorkspaceOwnerId()
  const currentWorkspaceCreatedAt = useCurrentWorkspaceCreatedAt()
  const userRole = useCurrentUserPermissions()
  const { toggleMenu } = useNavbar()
  const users = useUsersByWorkspace()
  const dispatch = useDispatch()
  const { isAdmin, isEditor } = useRoles()
  const isAdminInCurrentWorkspace = userRole >= RolesEnum.Admin
  const isViewer = !isEditor && !isAdmin
  const isArchived = currentWorkspaceIsArchived
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()

  const basicLinks = [
    {
      description: t('workspaceSettings:workspaceDetailsDescription'),
      Icon: InfoIcon,
      title: t('workspaceSettings:workspaceDetails'),
      to: `/workspace/${workspaceId}/details`,
    },
    {
      description: t('workspaceSettings:workspaceUsersDescription'),
      Icon: UsersIcon,
      title: t('workspaceSettings:workspaceUsers'),
      to: `/workspace/${workspaceId}/users`,
    },
  ]
  const proLinks = [
    {
      description: t('customFields:customFieldsDescription'),
      Icon: CustomFieldListIcon,
      title: t('customFields:customFields'),
      to: `/workspace/${workspaceId}/fields`,
    },
  ]
  const wsConfigLinks = isPremiumPlan ? [...basicLinks, ...proLinks] : [...basicLinks]
  const billingLinks = [
    {
      description: t('workspaceSettings:workspacePlanDescription'),
      Icon: BoxIcon,
      title: t('workspaceSettings:workspacePlan'),
      to: `/workspace/${workspaceId}/plan`,
    },
    // {
    //   description: t('workspaceSettings:workspaceBillingDescription'),
    //   Icon: ReceiptIcon,
    //   title: t('workspaceSettings:workspaceBilling'),
    //   to: `/workspace/${workspaceId}/billing`,
    // },
  ]

  const links = isAdminInCurrentWorkspace
    ? configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && !isArchived
      ? [...wsConfigLinks, ...billingLinks]
      : [...wsConfigLinks]
    : wsConfigLinks

  const fetchUsers = useCallback(() => {
    if (currentWorkspaceOwnerId) {
      return dispatch(fetchUsersByWorkspaceId({ ownerId: Number(currentWorkspaceOwnerId), workspaceId }))
    }
  }, [workspaceId, currentWorkspaceOwnerId])

  // const fetchAllWorkspaces = useCallback(() => dispatch(fetchWorkspaces()), [dispatch])

  const adminCount = findNumberOfAdmins(users, 'WorkspaceMember')

  return (
    <div className="max-h-1/4">
      {/*{!currentWorkspace ? (*/}
      {/*  <Loader loaderClasses="w-full flex items-center justify-center h-full" svgClasses="w-12" />*/}
      {/*) : (*/}
      <>
        <Header
          currentItemName={currentWorkspaceName}
          showBack={false}
          title={t('common:labels.settings')}
          toggleMenu={toggleMenu}
        />
        <div
          className="bg-white rounded-ts-none rounded-2xl lg:flex lg:flex-1"
          style={{
            minHeight: '50%',
          }}>
          {currentWorkspaceCreatedAt && <SettingsWithTabs createdAt={currentWorkspaceCreatedAt} links={links} />}
          {/*{!isEmpty(currentWorkspace) && !isUndefined(currentWorkspace) && (*/}
          <Switch>
            <Route path="/workspace/:workspaceId/users">
              <UsersListPage
                adminCount={adminCount}
                deleteUrl={`api/workspaces/${workspaceId}/user`}
                elementType="workspace"
                fetchUsers={fetchUsers}
                fieldToGetRoleBy="WorkspaceMember"
                inviteUrl={`api/workspaces/${workspaceId}/invite`}
                isArchived={isArchived}
                updateUrl={`api/element/accessGroup/${currentWorkspaceAppElementId}`}
                users={users}
              />
            </Route>
            <Route path="/workspace/:workspaceId/details">
              <WorkspaceDetailsPage isViewer={isViewer} workspaceId={workspaceId} />
            </Route>
            <Route path="/workspace/:workspaceId/fields">
              <WorkspaceCustomFieldsForm isViewer={isViewer} workspaceId={workspaceId} />
            </Route>
            {configValues.REACT_APP_IS_SUBSCRIPTION_ENABLED && !isArchived && (
              <>
                <Route path="/workspace/:workspaceId/plan">
                  <WorkspacePlanPage workspaceId={workspaceId} />
                </Route>
                {/*<Route path="/workspace/:workspaceId/billing">*/}
                {/*  <WorkspaceBillingPage workspace={currentWorkspace} />*/}
                {/*</Route>*/}
                <Route path="/workspace/:workspaceId/pro-trial">
                  <ApplyForTrial />
                </Route>
              </>
            )}
            <Route>
              <Redirect to={`/workspace/${workspaceId}/stations`} />
            </Route>
          </Switch>
          {/*)}*/}
        </div>
      </>
      {/*)}*/}
    </div>
  )
}

export default withExplicitRole(WorkspaceSettingsPage)
