import React, { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Logout from 'components/Logout'
import Button, { ButtonVariant } from 'components/Buttons'
import SettingsDropdown from 'components/SettingsDropdown'
import {
  MohimmatechLogo,
  CustomIcon,
  WorkspacesIcon,
  SettingsIcon,
  CrossSignIcon,
  TranslateIcon,
  HelpIcon,
  UsersIcon,
  NotificationsIcon,
  NoNotificationsIcon,
} from 'components/Icons'
import EntryAvatar from 'components/EntryAvatar'

import { useCurrentUser, useUserAvatar } from 'features/user'
import { useCurrentWorkspace, useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { useIsRejected } from 'features/common/selectors'
import { configValues } from 'utils/appConfig'
import { Tooltip } from 'utils/helpers/generalHelpers'
import { NavbarContextValueType } from 'utils/types'
import { changeUserLanguage } from 'utils/helpers/profileHelpers'
import NotificationsModal from '../Notifications/NotificationsModal'
import { useNotifications } from '../../features/element'

type NavbarPropsType = {
  setSideNavOpen: NavbarContextValueType['toggleMenu'],
  isSideNavOpen: boolean,
}

const Navbar: FC<NavbarPropsType> = ({ setSideNavOpen, isSideNavOpen }) => {
  const avatar = useUserAvatar()
  const { t, i18n } = useTranslation('navbarRoutes')
  const isRejected = useIsRejected()
  const workspace = useCurrentWorkspace()
  const notifications = useNotifications()
  const hasNotifications = notifications && notifications.length > 0
  const isPremiumPlan = useHasCurrentWorkspacePremiumPlan()
  const [showNotifications, setShowNotifications] = useState(false)
  const user = useCurrentUser()
  const isRtl = i18n.language === 'ar'
  const isAdminPage = window.location.pathname.includes('admin')
  const isStationPage = window.location.pathname.includes('stations/')
  const isMobileSidebar = window.innerWidth < 1024
  const isOpen = isStationPage || isMobileSidebar ? isSideNavOpen : true

  const UserAvatar = () => {
    return (
      <img
        alt="user avatar"
        className="w-10 h-10 border-2 border-gray-200 rounded-full transition transition-shadow hover:shadow"
        loading="lazy"
        src={avatar || '/assets/images/placeholder.svg'}
      />
    )
  }

  const DropdownContent = ({ closeDropdown }: { closeDropdown?: () => void }) => (
    <div className="flex flex-col text-gray-600">
      <button
        className="flex items-center py-2 text-start transition-colors hover:text-primary"
        onClick={() => {
          changeUserLanguage()
          if (closeDropdown) closeDropdown()
        }}>
        <TranslateIcon className="w-6 -my-1 rounded-sm me-2" />
        {t('common:labels.switchLanguage')}
      </button>
      <hr className="my-2" />
      <NavLink
        className="py-2 transition-colors hover:text-primary"
        to="/profile"
        onClick={() => {
          setSideNavOpen(false)
          if (closeDropdown) closeDropdown()
        }}>
        {t('editProfile')}
      </NavLink>
      {user?.isAdmin && (
        <NavLink
          className="py-2 transition-colors hover:text-primary"
          to="/admin/users/view"
          onClick={() => {
            setSideNavOpen(false)
            if (closeDropdown) closeDropdown()
          }}>
          {t('management')}
        </NavLink>
      )}
      <Logout />
    </div>
  )

  const HelpDropdownContent = ({ closeDropdown }: { closeDropdown?: () => void }) => (
    <div
      className="flex flex-col text-gray-600"
      onClick={() => {
        if (closeDropdown) closeDropdown()
      }}>
      <a
        className="w-full py-2 transition transition-colors hover:text-primary focus:text-primary md:me-12"
        href="https://mohimma.tech/getting-started"
        rel="noreferrer"
        target="_blank">
        {t('common:labels.howToPage')}
      </a>
    </div>
  )

  return (
    <>
      <nav
        className={classNames('flex flex-col z-40 fixed h-screen shadow-lg items-center transition-all', {
          'bg-white px-4 pt-4 pb-6': isOpen,
          'lg:shadow-none lg:static lg:border-gray-200': !isStationPage,
          'w-1 overflow-hidden p-0': !isOpen,
        })}
        data-tut="workspace__sidebar"
        onMouseLeave={() => setSideNavOpen(false)}
        onMouseOver={() => setSideNavOpen(true)}>
        {isOpen && (
          <>
            <Button
              className="mb-6 lg:hidden"
              icon={CrossSignIcon}
              variant={ButtonVariant.Icon}
              onClick={() => setSideNavOpen(false)}
            />
            <NavLink className="w-10 mb-8" to="/" onClick={() => setSideNavOpen(false)}>
              <div
                key={isRtl + ''}
                id={'home'}
                onMouseOver={() => {
                  Tooltip('#home', t('home'))
                }}>
                {configValues.REACT_APP_IS_CUSTOM_BRANDING ? (
                  <CustomIcon style={{ ...configValues.navbarIconClasses }} />
                ) : (
                  <MohimmatechLogo className="text-primary" />
                )}
              </div>
            </NavLink>
            {isAdminPage && (
              <NavLink
                activeClassName="bg-tertiary-light/10 text-primary"
                className="flex items-center p-2 mb-5 rounded transition-colors hover:text-primary"
                exact
                to={`/admin/users/view`}
                onClick={() => setSideNavOpen(false)}>
                <div
                  key={isRtl + ''}
                  id={'managements'}
                  onMouseOver={() => {
                    Tooltip('#managements', t('users'))
                  }}>
                  <UsersIcon className="w-6" />
                </div>
              </NavLink>
            )}
            {!isRejected && workspace !== undefined && (
              <div className="flex flex-col items-center">
                <NavLink
                  to={`/workspace/${workspace.id}/${isPremiumPlan ? 'dashboard' : 'stations'}`}
                  onClick={() => setSideNavOpen(false)}>
                  <div
                    key={isRtl + ''}
                    id={'entryAvatar'}
                    onMouseOver={() => {
                      const title = isPremiumPlan ? t('dashboard') : t('stations')
                      Tooltip('#entryAvatar', title)
                    }}>
                    <EntryAvatar entry={workspace} isNavbar />
                  </div>
                </NavLink>
                <NavLink
                  activeClassName="bg-tertiary-light/10 text-primary"
                  className="flex items-center p-2 mb-5 rounded transition-colors hover:text-primary"
                  exact
                  to={`/workspace/${workspace.id}/stations`}
                  onClick={() => setSideNavOpen(false)}>
                  <div
                    key={isRtl + ''}
                    id={'workspaceStations'}
                    onMouseOver={() => {
                      Tooltip('#workspaceStations', t('stations'))
                    }}>
                    <WorkspacesIcon className="w-6" />
                  </div>
                </NavLink>
                <NavLink
                  activeClassName="bg-tertiary-light/10 text-primary"
                  className="flex items-center p-2 rounded transition-colors hover:text-primary"
                  exact
                  to={`/workspace/${workspace.id}/details`}
                  onClick={() => setSideNavOpen(false)}>
                  <div
                    key={isRtl + ''}
                    id={'workspaceSettings'}
                    onMouseOver={() => {
                      Tooltip('#workspaceSettings', t('settings'))
                    }}>
                    <SettingsIcon key={isRtl + ''} className="w-6" />
                  </div>
                </NavLink>
              </div>
            )}
            <div
              key={isRtl + '' + 'notifications'}
              className="flex flex-col items-center mt-auto cursor-pointer"
              id={`notifications`}
              onMouseOver={() => {
                Tooltip('#notifications', t('notifications'))
              }}>
              {hasNotifications ? (
                <NotificationsIcon onClick={() => setShowNotifications(!showNotifications)} className="w-6 h-6" />
              ) : (
                <NoNotificationsIcon onClick={() => setShowNotifications(!showNotifications)} className="w-6 h-6" />
              )}
            </div>

            <div
              key={isRtl + '' + 'help'}
              className="flex flex-col items-center mt-6"
              id={`help`}
              onMouseOver={() => {
                Tooltip('#help', t('help'))
              }}>
              <SettingsDropdown
                ButtonChildren={HelpIcon}
                childClasses="w-64 rounded-bs-none"
                toTop
                wrapperClasses="flex">
                <HelpDropdownContent />
              </SettingsDropdown>
            </div>
            <div
              key={isRtl + ''}
              className="flex flex-col items-center mt-5"
              id={`profile`}
              onMouseOver={() => {
                Tooltip('#profile', t('profile'))
              }}>
              <SettingsDropdown
                ButtonChildren={UserAvatar}
                childClasses="w-44 rounded-bs-none"
                toTop
                wrapperClasses="flex">
                <DropdownContent />
              </SettingsDropdown>
            </div>
          </>
        )}
      </nav>
      <NotificationsModal isModalOpen={showNotifications} leftSpace onClose={() => setShowNotifications(false)} />
    </>
  )
}

export default Navbar
