import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getElementTree, useLinkedElements } from '../../features/element'
import { useAppDispatch } from '../../store'
import { NextIcon, PrevIcon } from '../Icons'
import { Input } from '../inputs'
import Loader from '../Loader'
import AddNewRecordForm from '../Documentation/DocumetationsDocument/LinkComponents/AddNewRecordForm'
import ConfirmationModal from '../ConfirmationModal'
import ElementType from '../../utils/types/ElementType'
import { RecordType } from '../../utils/types'
import ElementsList from './ElementsList'
import Preview from './Preview'

interface CustomFieldFormProps {
  setSelectedElements: (e: any) => void;
  setShowCurrentElement?: (e: boolean) => void;
  selectedElements: any[];
  elementId: number;
  level: number;
  lowestLevel: number;
  elementBaseType: number;
  currentList: any[];
  currentListLoading: boolean;
  previewTitle?: string;
  emptyPreviewMessage?: string;
  isStartFromRootElement?: boolean;
  checkBasedType: boolean;
  currentRecord?: RecordType;
  options?: {
    canAddRecord: boolean,
    isSingleSelect: boolean,
    shouldShowPreview: boolean,
  };
}

export const renderDirectionIcon = (condition: boolean) => (
  <div className="w-4">{condition ? <PrevIcon /> : <NextIcon />}</div>
)

const LinkElements: FC<CustomFieldFormProps> = ({
  setSelectedElements,
  selectedElements,
  elementId,
  level,
  lowestLevel,
  elementBaseType,
  currentList,
  currentListLoading,
  previewTitle,
  emptyPreviewMessage,
  checkBasedType = true,
  currentRecord,
  isStartFromRootElement = false,
  setShowCurrentElement,
  options = {
    canAddRecord: true,
    isSingleSelect: false,
    shouldShowPreview: true,
  },
}) => {
  const { t, i18n } = useTranslation('documentation')
  const dispatch = useAppDispatch()
  const isRtl = i18n.language === 'ar'
  const linkedRecords = useLinkedElements()
  const [currentParent, setCurrentParent] = useState<any>({ id: elementId })
  const [isCollapsed, setIsCollapsed] = useState<any>(false)
  const [hideBack, setHideBack] = useState<boolean>(false)
  const [searchRecord, setSearchRecord] = useState<string>('')
  const [currentLevel, setCurrentLevel] = useState<number>(level)
  const [visible, setVisible] = useState<boolean>(false)
  const [relinkedItem, setRelinkedItem] = useState<any>(null)
  const linkedRecordsIds = linkedRecords.map((i: any) => i.elementId)
  // this component display the children of the current item so when isStartFromRootElement = true that mean the
  // rootElement level = level - 1
  const recordsElements = isStartFromRootElement
    ? selectedElements.filter((ele) => !!ele.isSelected && ele?.appElement?.baseElementType !== level - 1)
    : selectedElements.filter((element) => element?.basedType === elementBaseType)

  const onClickItem = useCallback(
    (item) => {
      if (item.basedType !== elementBaseType) {
        setCurrentLevel(item.basedType + 1)
        setSelectedElements([...selectedElements, item])
        dispatch(getElementTree({ documentFlag: true, elementId: +item.id }))
      }
    },
    [selectedElements],
  )

  const onAddNewRecord = (record: any) => {
    setSelectedElements([...selectedElements, record])
  }

  const showBack = (currentLevel > lowestLevel && !hideBack) || isStartFromRootElement
  const isRecordsList = currentLevel === elementBaseType
  const renderSearchRecord = () => (
    <div>
      <Input
        classes={{ input: 'text-sm', wrapper: 'my-1' }}
        name={'search'}
        placeholder={t('search')}
        onChange={(e) => {
          setSearchRecord(e.target.value)
        }}
      />
    </div>
  )

  const listGenerator = () => {
    if (isStartFromRootElement) {
      return currentList
        ?.filter((i: any) => +i.basedType === currentLevel)
        .filter((j) => {
          if (currentParent.id) {
            return +currentParent?.id === +j.parentId
          } else {
            return j
          }
        })
        .filter((i: any) => i.title.toLowerCase().indexOf(searchRecord.toLowerCase()) > -1)
    } else if (checkBasedType || isStartFromRootElement) {
      return currentList
        ?.filter((i: any) => checkBasedType && +i.basedType === currentLevel && +currentParent?.id === +i.parentId)
        .filter((i: any) => {
          if (currentLevel === elementBaseType) {
            return linkedRecordsIds.indexOf(i.id) === -1
          } else {
            return i
          }
        })
        .filter((i: any) => i?.title?.toLowerCase().indexOf(searchRecord.toLowerCase()) > -1)
    }
    return currentList
      ?.filter((i: any) => {
        if (currentLevel === elementBaseType) {
          return linkedRecordsIds.indexOf(i.id) === -1
        } else {
          return i
        }
      })
      .filter((i: any) => i.title.toLowerCase().indexOf(searchRecord.toLowerCase()) > -1)
  }

  const list = currentRecord
    ? listGenerator().filter((record) => +currentRecord?.appElements[0]?.id !== +record.element?.id)
    : listGenerator()

  const previewProps = {
    elementId,
    emptyPreviewMessage,
    isCollapsed,
    previewTitle,
    recordsElements,
    selectedElements,
    setIsCollapsed,
    setSelectedElements,
  }

  return (
    <div className="flex flex-col flex-1 min-h-0">
      <div className="flex items-center justify-between mt-2 mb-4 cursor-pointer -ms-1">
        {showBack && (
          <div
            className="flex"
            onClick={() => {
              if (isStartFromRootElement && setShowCurrentElement) {
                if (currentLevel === 1) {
                  setShowCurrentElement(true)
                } else {
                  setCurrentParent({ id: elementId })
                  setCurrentLevel(currentLevel - 1)
                }
              } else {
                const newLevel = currentLevel - 1
                const parent =
                  level === newLevel
                    ? { id: +elementId }
                    : currentList.find((i: any) => +i.id === +currentParent.parentId)
                setCurrentParent(parent)
                setCurrentLevel(newLevel)
                setSelectedElements(selectedElements.filter((el) => el.basedType !== elementBaseType))
              }
            }}>
            {renderDirectionIcon(!isRtl)}
            <p className="text-sm font-bold ps-1 -mt-0.5">{t('back')}</p>
          </div>
        )}
        {options.canAddRecord && isRecordsList && (
          <div className={`flex justify-end ${!showBack && 'w-full'}`}>
            <AddNewRecordForm
              hideBack={hideBack}
              listId={currentParent.legacyId}
              parentId={currentParent.id}
              setHideBack={setHideBack}
              onAddNewRecord={onAddNewRecord}
            />
          </div>
        )}
      </div>
      {currentListLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col flex-1 min-h-0">
          <div>
            <div className="mb-2 font-bold">
              {currentLevel === 1
                ? t('stations')
                : currentLevel === 2
                ? t('lists')
                : currentLevel === 3
                ? t('records')
                : currentLevel === 5
                ? t('documents')
                : null}
            </div>
            {currentLevel === elementBaseType && renderSearchRecord()}
          </div>
          <div className="flex flex-col justify-between flex-1 min-h-0">
            {currentListLoading && <Loader />}
            {!currentListLoading && (
              <div className="flex flex-col flex-1 shrink-0 min-h-0 overflow-y-auto shadow-inner-bottom-xs">
                {list?.length === 0 && !currentListLoading ? (
                  <div className="flex items-center justify-center flex-1 text-sm opacity-50">
                    {t('documentation:emptyChildren')}
                  </div>
                ) : (
                  <ElementsList
                    elementBaseType={elementBaseType}
                    isStartFromRootElement={isStartFromRootElement}
                    list={list}
                    options={options}
                    renderDirectionIcon={renderDirectionIcon}
                    selectedElements={selectedElements}
                    setRelinkedItem={setRelinkedItem}
                    setSelectedElements={setSelectedElements}
                    setVisible={setVisible}
                    onClickItem={(item: ElementType) => {
                      onClickItem(item)
                      if (item.basedType !== elementBaseType) setCurrentParent(item)
                    }}
                  />
                )}
              </div>
            )}

            {options.shouldShowPreview && <Preview {...previewProps} />}
          </div>
        </div>
      )}
      <ConfirmationModal
        cancelMessage={t('common:labels.cancel')}
        confirmMessage={t('common:labels.remove')}
        confirmationMessage={t('documentation:confirmRelinkDocMessage')}
        isModalOpen={visible}
        onCancel={() => setVisible(false)}
        onConfirm={() => {
          setSelectedElements([...selectedElements, relinkedItem])
          setVisible(false)
        }}
      />{' '}
    </div>
  )
}

export default LinkElements
