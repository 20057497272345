import React, { FC } from 'react'
import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'

import { RecordType } from 'utils/types'
import { useGridViewMethods } from 'contexts/GridViewContext'
import { Checkbox } from 'components/inputs'

const CheckboxCell: FC<CellRenderObject & { data: RecordType }> = ({ data: { id, appElements } }) => {
  const { selectedRecords, selectRecord } = useGridViewMethods()

  const isChecked = selectedRecords.includes(appElements[0].id)
  return (
    <Checkbox
      checked={isChecked}
      classes={{ input: 'w-full' }}
      name={`selected-${id}`}
      tabIndex={-1}
      onChange={() => {
        selectRecord(appElements[0].id)
      }}
    />
  )
}

export default CheckboxCell
