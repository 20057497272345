export const stationStatuses = ['notAvailable', 'onTrack', 'late', 'veryLate'].map((el, index) => ({
  label: el,
  value: index === 0 ? null : `${index - 1}`,
}))

export const attributesStatuses = ['notAvailable', 'onTrack', 'lowDeviation', 'highDeviation'].map((el, index) => ({
  label: el,
  value: index === 0 ? null : `${index - 1}`,
}))

export const stationStates = [
  'notAvailable',
  'draft',
  'proposed',
  'approved',
  'started',
  'onHold',
  'completed',
  'closed',
  'cancelled',
].map((el, index) => ({ label: el, value: index === 0 ? null : `${index - 1}` }))

export const timeLineDuration = {
  month: { duration: { years: 1 }, slotDuration: { months: 1 } },
  quarter: { duration: { month: 3 }, slotDuration: { months: 1 } },
}
