import React, { FC, ReactElement, Ref, useCallback, useRef } from 'react'
import TetherComponent from 'react-tether'
import { useClickAway, useToggle } from 'react-use'

import { SeeMoreIcon } from 'components/Icons'
import Button, { ButtonVariant } from 'components/Buttons'
import throttle from 'lodash/throttle'

const ContextMenu: FC<{
  render: (closeMenu: any) => ReactElement,
  text?: string,
  isCustomButton?: boolean,
  isDefaultPopup?: boolean,
}> = ({ render, text = '', isCustomButton = false, isDefaultPopup = false }) => {
  const [areSettingsVisible, toggleSettings] = useToggle(false)
  const throttledToggleSettings = useCallback(
    throttle(
      (value?: boolean) => {
        toggleSettings(value)
      },
      200,
      { trailing: false },
    ),
    [toggleSettings],
  )
  const menuWrapperRef = useRef<any>()

  const closeDropdown = () => throttledToggleSettings(false)
  useClickAway(menuWrapperRef, closeDropdown)

  return (
    <TetherComponent
      attachment="top left"
      constraints={[
        {
          attachment: 'together',
          to: 'scrollParent',
        },
      ]}
      renderElement={(ref: any) =>
        areSettingsVisible && (
          <div
            className={`z-30 px-6 py-2 -ml-12 text-sm bg-white cursor-default ${
              isDefaultPopup ? '-me-12' : '-me-44'
            } shadow-multi w-44 rounded-ts-none rounded-2xl `}
            ref={(divRef: any) => {
              ref.current = divRef
              menuWrapperRef.current = divRef
            }}>
            {render({
              closeMenu: closeDropdown,
            })}
          </div>
        )
      }
      renderTarget={(ref: Ref<any>) =>
        text ? (
          <Button ref={ref} variant={ButtonVariant.Icon} onClick={() => throttledToggleSettings()}>
            {text}
          </Button>
        ) : isCustomButton ? (
          <button
            className="flex items-center hover:text-primary-light"
            ref={ref}
            style={{ height: 18, width: 18 }}
            onClick={() => throttledToggleSettings()}>
            <SeeMoreIcon className="w-5 h-5" />
          </button>
        ) : (
          <Button icon={SeeMoreIcon} ref={ref} variant={ButtonVariant.Icon} onClick={() => throttledToggleSettings()} />
        )
      }
      targetAttachment="bottom right"
    />
  )
}

export default ContextMenu
