import React from 'react'
import ContentLoader from 'react-content-loader'

const ListViewsLoader = () => {
  return (
    <div className="flex flex-col gap-1">
      <ContentLoader
        speed={2}
        width={140}
        height={36}
        viewBox="0 0 140 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" width="140" height="36" rx="3" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={140}
        height={36}
        viewBox="0 0 140 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" width="140" height="36" rx="3" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={140}
        height={36}
        viewBox="0 0 140 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" width="140" height="36" rx="3" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={140}
        height={36}
        viewBox="0 0 140 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" width="140" height="36" rx="3" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={140}
        height={36}
        viewBox="0 0 140 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" width="140" height="36" rx="3" />
      </ContentLoader>
    </div>
  )
}

export default ListViewsLoader
