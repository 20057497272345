import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import { switchLanguage } from 'i18n'

import baseAPI from 'utils/baseAPI'
import { UpdateUserType } from 'utils/types'
import { mapResponseToCorrectDataFormat } from 'utils/helpers/fetchHelpers'

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { rejectWithValue }) => {
  try {
    const userId = localStorage.getItem('userId')
    if (userId) {
      const response = await baseAPI(`api/users/${userId}`)

      if (+response.status >= 400) throw response.message

      switchLanguage(response.body ? response.body.language : 'ar')
      return response.body
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchUsersByWorkspaceId = createAsyncThunk(
  'user/fetchUsersByWorkspaceId',
  async ({ workspaceId, ownerId }: { workspaceId: string | number, ownerId?: number }) => {
    const response = await baseAPI(`api/workspaces/${workspaceId}/users`)

    return mapResponseToCorrectDataFormat(response?.body, ownerId)
  },
)

export const fetchUsersByStationId = createAsyncThunk(
  'user/fetchUsersByStationId',
  async ({ stationId, ownerId }: { stationId: string | number, ownerId?: number }) => {
    const response = await baseAPI(`api/stations/${stationId}/users`)

    return mapResponseToCorrectDataFormat(response?.body, ownerId)
  },
)

export const fetchUsersByListId = createAsyncThunk(
  'user/fetchUsersByListId',
  async ({ listId, ownerId }: { listId: string | number, ownerId?: number }) => {
    const response = await baseAPI(`api/lists/${listId}/users`)

    return mapResponseToCorrectDataFormat(response?.body, ownerId)
  },
)

export const uploadAvatar = createAsyncThunk(
  'user/uploadAvatar',
  async (avatar: { filename: string, base64data: string }) => {
    const userId = localStorage.getItem('userId')
    if (userId) {
      const payload = { ...avatar }
      const response = await baseAPI(`api/users/upload/${userId}`, {
        body: payload,
        method: 'PUT',
      })
      return response.body
    }
  },
)

export const updateUser = createAsyncThunk('user/updateUser', async (updatedUser: UpdateUserType, thunkApi: any) => {
  const user = get(thunkApi.getState(), 'user.user')

  if (!isEmpty(user) && !isEqual(pick(user, Object.keys(updatedUser || {})), updatedUser)) {
    const userId = localStorage.getItem('userId')
    if (userId) {
      let payload: any = omitBy({ ...user, ...updatedUser }, isNil)
      const response = await baseAPI(`api/users/${userId}`, {
        body: payload,
        method: 'PUT',
      })
      if (response.status !== 200) payload = { ...user }
      if (!isEmpty(payload.dateOfBirth)) {
        payload = { ...payload, dateOfBirth: new Date(payload.dateOfBirth) }
      }
      return payload
    }
  }
})

export const updateUserLanguage = createAsyncThunk(
  'user/updateUserLanguage',
  async (newLanguage: 'ar' | 'en', thunkApi: any) => {
    const user = get(thunkApi.getState(), 'user.user')

    if (!isEmpty(user)) {
      const userId = localStorage.getItem('userId')
      if (userId) {
        let payload: any = omitBy({ ...user, language: newLanguage }, isNil)
        const response = await baseAPI(`api/users/${userId}`, {
          body: payload,
          method: 'PUT',
        })
        if (response.status !== 200) payload = { ...user }
        if (!isEmpty(payload.dateOfBirth)) {
          payload = { ...payload, dateOfBirth: new Date(payload.dateOfBirth) }
        }
        return payload
      }
    }
  },
)
