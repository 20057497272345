import React, { FC } from 'react'

import { DropdownOptionType } from 'utils/types/inputs'
import { CrossSignIcon } from 'components/Icons'
import SelectedItemRow from './SelectedItemRow'

const SelectedUserChip: FC<{
  data: DropdownOptionType,
  innerRef?: any,
  innerProps?: any,
  onRemove: (id: string) => void,
  isDeletingAllowed?: boolean,
  isGridView: boolean,
  isUserItem: boolean,
}> = ({ data, isUserItem = false, onRemove }) => {
  if (!data.value) return null

  return (
    <div
      className={`flex items-center py-0.5 mx-1 my-1 bg-gray-100 rounded-full cursor-pointer ps-2 pe-2 ${
        data?.isDefaultUser && 'pe-2'
      }`}>
      <SelectedItemRow data={data} isUserItem={isUserItem} />
      {!data?.isDefaultReviewer && (
        <button
          className="flex w-8 -my-2 hover:opacity-50 transition"
          type="button"
          onClick={() => onRemove(data.value)}>
          <CrossSignIcon className="w-6 m-1" />
        </button>
      )}
    </div>
  )
}

export default SelectedUserChip
