import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LinkButton } from 'components/Buttons'
import ChangeLanguageHeader from 'components/ChangeLanguageHeader'

const AccountConfirmedPage: FC = () => {
  const { t } = useTranslation('confirmation')
  const signIn = useTranslation('signIn')
  return (
    <>
      <ChangeLanguageHeader headerText={t('emailVerifiedMessage')} />
      <LinkButton full to="/sign-in">
        {signIn.t('signIn')}
      </LinkButton>
    </>
  )
}

export default AccountConfirmedPage
