import React, { FC, Dispatch, useState, KeyboardEvent, SetStateAction, useCallback } from 'react'
import classnames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'

import { DropdownOptionType } from 'utils/types/inputs'
import { useTranslation } from 'react-i18next'
import ReactCropperElement from 'react-cropper'
import CustomFieldRow from '../CustomFields/CustomFieldRow'
import ConfirmationModal from '../ConfirmationModal'
import Button, { ButtonVariant } from '../Buttons'
import BaseModal from '../Dashboard/BaseModal/BaseModal'
import SingleFieldItem from '../SingleFieldItem/SingleFieldItem'
import CustomScroll from '../CustomScroll'
import { ElementTypeWithColor } from './AddListToStationForm'

const ListColorsForm: FC<{
  onSubmit: () => void,
  colors: DropdownOptionType[],
  isStatuses: boolean,
  isViewer: boolean,
  label: string,
  localValues: ElementTypeWithColor[],
  placeholder: string,
  options: { label: string, Icon: ReactCropperElement },
  setLocalValues: Dispatch<SetStateAction<ElementTypeWithColor[]>>,
}> = ({ colors, isStatuses, label, placeholder, setLocalValues, localValues, options, onSubmit, isViewer }) => {
  const { t } = useTranslation()
  const [newRecord, setNewRecord] = useState<string>('')
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const tableRowClasses = 'flex items-center w-full group transition transition-colors hover:bg-gray-200'
  const tableCellClasses =
    'text-sm font-bold leading-6 border-b border-gray-200 border-e transition transition-colors last:border-e-0'

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const randomColor = colors[Math.floor(Math.random() * (colors.length - 1))].value || undefined
      const newValue: ElementTypeWithColor = {
        color: randomColor,
        element: newRecord,
        isValid: true,
      }
      setLocalValues([...localValues, newValue])
      setNewRecord('')
    }
  }

  const handleElementBlur = (key: string, value: string, item: any) => {
    const newKey = key === 'optionCaption' ? 'element' : key
    const itemIndex = localValues.findIndex(({ id, element }) =>
      item.id ? +id === +item.id : element === item.element,
    )
    const newList = [...localValues]
    newList[itemIndex] = { ...newList[itemIndex], [newKey]: value }
    setLocalValues(newList)
  }

  const handleElementRemove = (item: any, list) => {
    const localValuesCopy = [...list]
    const indexToRemove = localValuesCopy.findIndex(({ id, element }) =>
      item.id ? +item.id === +id : item.element === element,
    )
    localValuesCopy.splice(indexToRemove, 1)
    setLocalValues(localValuesCopy)
  }

  const onEdit = () => {
    return localValues
      .sort((a, b) => a.position - b.position)
      .map((item, index) => {
        const optionProps = {
          colorOptions: colors,
          index,
          item,
          onBlurElement: (key: string, value: string) => {
            handleElementBlur(key, value, item)
          },
          onDeleteOption: (item: any, list: any[]) => {
            handleElementRemove(item, list)
          },
          optionsList: localValues,
          setOptionsList: setLocalValues,
        }
        return <SingleFieldItem key={`${index}_${item.element}`} {...optionProps} />
      })
  }
  return (
    <>
      <CustomFieldRow
        field={{ fieldName: label }}
        options={options}
        setIsDelete={setIsDelete}
        setIsEdit={setIsEdit}
        showDelete={false}
        isViewer={isViewer}
      />
      <ConfirmationModal
        cancelMessage={t('common:labels.cancel')}
        confirmMessage={t('common:labels.remove')}
        confirmationMessage={t('customFields:deleteCustomFieldMessage')}
        isModalOpen={isDelete}
        onCancel={() => setIsDelete(false)}
        onConfirm={handleElementRemove}
      />
      <BaseModal
        close={() => setIsEdit(false)}
        content={
          <div className="flex flex-1 min-h-0">
            <div className="w-full p-0.5">
              <CustomScroll maxHeight={'75%'}>{onEdit()}</CustomScroll>
              <div className={tableRowClasses}>
                <input
                  className={classnames('flex-1 p-3', tableCellClasses)}
                  placeholder={placeholder}
                  value={newRecord}
                  onChange={({ target: { value } }: { target: { value: string } }) => setNewRecord(value)}
                  onKeyDown={handleOnKeyDown}
                />
              </div>
              <div className="flex justify-end my-6 gap-1">
                <Button small variant={ButtonVariant.Outline} onClick={() => setIsEdit(false)}>
                  {t('common:labels.cancel')}
                </Button>
                <Button
                  small
                  variant={ButtonVariant.Primary}
                  onClick={() => {
                    onSubmit()
                    setIsEdit(false)
                  }}>
                  {t('common:labels.save')}
                </Button>
              </div>
            </div>
          </div>
        }
        header={<h3 className="m-2 font-bold">{label}</h3>}
        isModalOpen={isEdit}
        type="md"
        withoutCloseBtn
      />
    </>
  )
}

export default ListColorsForm
