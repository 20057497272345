export const tableTranslation = {
  en: {
    clear: 'Clear',
    clearAll: 'Clear all',
    disable: 'Disable',
    dragHeaderToGroup: 'Drag header to group',
    columns: 'Columns',
    enable: 'Enable',
    contains: 'Contains',
    group: 'Group',
    endsWith: 'Ends with',
    hideFilteringRow: 'Hide filtering row',
    lockEnd: 'Lock end',
    lockStart: 'Lock start',
    eq: 'Equals',
    ofText: ' of ',
    empty: 'Empty',
    pageText: 'Page ',
    gt: 'Greater than',
    perPageText: 'Results per page',
    gte: 'Greater than or equal',
    showingText: 'Showing ',
    lt: 'Less than',
    lte: 'Less than or equal',
    neq: 'Does not equal',
    notContains: 'Does not contain',
    showFilteringRow: 'Show filtering row',
    notEmpty: 'Not empty',
    sortAsc: 'Sort ascending',
    sortDesc: 'Sort descending',
    startsWith: 'Starts with',
    ungroup: 'Ungroup',
    unsort: 'Unsort',
    unlock: 'Unlock',
    inlist: 'In list',
    notinlist: 'Not in list',
  },
  ar: {
    clear: 'امسح الحالي',
    clearAll: 'امسح الكل',
    disable: 'تعطيل',
    columns: 'الأعمدة',
    dragHeaderToGroup: 'اسحب الرأس إلى المجموعة',
    contains: 'يتضمن',
    enable: 'تفعيل',
    endsWith: 'ينتهي بـ',
    group: 'مجموعة',
    hideFilteringRow: 'إخفاء صف التصفية',
    lockEnd: 'قفل النهاية',
    eq: 'يساوي',
    lockStart: 'قفل البدايه',
    empty: 'فارغ',
    ofText: ' من ',
    gt: 'أكثر من',
    pageText: 'صفحة ',
    gte: 'أكبر من أو يساوي',
    perPageText: 'النتائج لكل صفحة',
    lt: 'أقل من',
    showingText: 'عرض ',
    lte: 'اصغر من او يساوي',
    neq: 'لا يساوي',
    notContains: 'لا يتضمن',
    showFilteringRow: 'إظهار صف التصفية',
    notEmpty: 'ليس فارغًا',
    sortAsc: 'ترتيب تصاعدي',
    sortDesc: 'ترتيب تنازلي',
    startsWith: 'يبدا ب',
    ungroup: 'فك التجميع',
    unlock: 'الغاء القفل',
    unsort: 'الغاء الترتيب',
    inlist: 'في قائمة',
    notinlist: 'ليس في القائمة',
  },
}
