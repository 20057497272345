import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { ElementMemberType, StationType } from 'utils/types'
import StateType, { DashboardAvailableWidgets, DashboardStateType } from 'utils/types/states'
import { StationDocumentationCommentsType } from 'utils/types/states/StationDocumentationComments'
import { StationDocumentationInfoUpdateResponse } from 'utils/types/states/StationDocumentationInfoType'
import { StationDocumentationSidebarType } from 'utils/types/states/StationDocumentationSidebarType'

const selectStations = (state: StateType) => state.station.stations
const selectStationsLoading = (state: StateType) => state.station.loading
const selectCurrentStationId = (state: StateType) => state.station.currentStationId
const selectCurrentStationLoading = (state: StateType) => state.station.currentStationLoading
const selectCurrentStationObj = (state: StateType) => state.station.currentStation
const selectStationDashboard = (state: StateType) => state.station.dashboard
const selectStationDashboardAvailableWidgets = (state: StateType) => state.station.availableStationDashboardWidgets
const selectStationAllDashboards = (state: StateType) => state.station.dashboards
const selectStationAllDashboardsLoading = (state: StateType) => state.station.dashboardsLoading
const selectStationDashboardLoading = (state: StateType) => state.station.dashboardLoading
const selectStationDocumentationSidebar = (state: StateType) => state.station.documentationSidebar
const selectStationDocumentationSidebarLoading = (state: StateType) => state.station.docSidebarLoading
const selectStationDocumentationSidebarHidden = (state: StateType) => state.station.docSidebarHiddenElements
const selectStationDocumentationOneItemLoading = (state: StateType) => state.station.documentationOneItemLoading
const selectStationDocumentationOneItem = (state: StateType) => state.station.documentationOneItem
const selectStationDocumentationCommentsOpen = (state: StateType) => state.station.documentationItemCommentsOpen
const selectStationDocumentationSearchResultsLoading = (state: StateType) =>
  state.station.documentationSearchResultsLoading
const selectStationDocumentationSearchResults = (state: StateType) => state.station.documentationSearchResults
const selectStationDocumentationComments = (state: StateType) => state.station.documentationItemComments
const selectStationDocumentationAttachmentsOpen = (state: StateType) => state.station.documentationItemAttachmentsOpen
const selectStationDocumentationAttachments = (state: StateType) => state.station.documentationItemAttachments
const selectStationDocumentationAttachmentsLoading = (state: StateType) =>
  state.station.documentationItemAttachmentsLoading
const selectStationDocumentationPublicLink = (state: StateType) => state.station.documentationPublicLink
const selectCurrentStationMembers = (state: StateType) => state.station.currentStation?.stationMembers
const selectStationDocumentationInfoOpen = (state: StateType) => state.station.documentationItemInfoOpen
const selectStationDocumentationLinksOpen = (state: StateType) => state.station.documentationItemLinksOpen
const selectStationDocumentationInfo = (state: StateType) => state.station.documentationItemInfo
const selectStationDocumentationLinks = (state: StateType) => state.station.documentationItemLinks
const selectStationDocumentationAllLabels = (state: StateType) => state.station.documentationAllLabels
const selectCurrentStationDocumentation = (state: StateType) => state.station.currentStationDocumentation
const selectDocumentationDetails = (state: StateType) => state.station.documentationDetails
const selectShowLatest = (state: StateType) => state.station.showLatest
const selectCustomListsFiltersOptions = (state: StateType) => state.station.customListsFiltersOptions

const selectCurrentStation = createSelector(selectCurrentStationId, selectStations, (stationId, stations) =>
  stations.find((station) => station.id === stationId),
)

const selectCurrentStationOwnerId = createSelector(
  selectCurrentStationId,
  selectStations,
  (stationId, stations) => stations.find((station) => station.id === stationId)?.ownerId,
)
const selectCurrentStationName = createSelector(
  selectCurrentStationId,
  selectStations,
  (stationId, stations) => stations.find((station) => station.id === stationId)?.name,
)
const selectCurrentStationCreatedAt = createSelector(
  selectCurrentStationId,
  selectStations,
  (stationId, stations) => stations.find((station) => station.id === stationId)?.createdAt,
)
const selectCurrentStationElementId = createSelector(
  selectCurrentStationId,
  selectStations,
  (stationId, stations) => stations.find((station) => station.id === stationId)?.appElement?.id,
)
const selectStationById = createSelector([selectStations, (_, id) => id], (stations, stationId) =>
  stations.find((station) => station.id === stationId),
)
const selectUpdateDocumentLoading = (state: StateType) => state.station.updateDocumentLoading
const selectIsStationArchived = (state: StateType) => state.station.isStationArchived
const selectDocumentationSidebarAllItems = (state: StateType) => state.station.documentationSidebarAllItems
const selectCurrentOpenItems = (state: StateType) => state.station.openSidebarItems
const selectCurrentStationArchiveStatus = (state: StateType) => state.station.isStationArchived
const selectCustomDashboard = (state: StateType) => state.station.customDashboard
const selectArchivedDocumentsLoading = (state: StateType) => state.station.archivedDocumentsLoading
const selectArchivedDocuments = (state: StateType) => state.station.archivedDocuments
const selectArchivedDocumentsHiddenElements = (state: StateType) => state.station.archivedDocumentsHiddenElements
const selectAllArchivedDocuments = (state: StateType) => state.station.allArchivedDocuments
const selectAllArchivedDocumentsIds = (state: StateType) => state.station.allArchivedDocumentsIds
const selectCurrentDashboardId = (state: StateType) => state.station.currentDashboardId

export const useStationsList: () => StationType[] = () => useSelector(selectStations)
export const useCurrentStation: () => StationType | undefined = () => useSelector(selectCurrentStation)
export const useCurrentStationName: () => string | undefined = () => useSelector(selectCurrentStationName)
export const useCurrentStationOwnerId: () => string | undefined = () => useSelector(selectCurrentStationOwnerId)
export const useCurrentStationCreatedAt: () => string | undefined = () => useSelector(selectCurrentStationCreatedAt)
export const useCurrentStationElementId: () => string = () => useSelector(selectCurrentStationElementId)
export const useCurrentStationLoading: () => boolean = () => useSelector(selectCurrentStationLoading)
export const useCurrentStationObj: () => StationType | undefined = () => useSelector(selectCurrentStationObj)
export const useStationsLoading: () => boolean = () => useSelector(selectStationsLoading)
export const useStationById: (id: number) => StationType | undefined = (id) =>
  useSelector((state: StateType) => selectStationById(state, id))
export const useStationDashboard: () => DashboardStateType | null = () => useSelector(selectStationDashboard)
export const useStationDashboardAvailableWidgets: () => DashboardAvailableWidgets[] | null = () =>
  useSelector(selectStationDashboardAvailableWidgets)
export const useStationAllDashboards: () => DashboardStateType[] | null = () => useSelector(selectStationAllDashboards)
export const useStationAllDashboardsLoading: () => boolean = () => useSelector(selectStationAllDashboardsLoading)
export const useStationDashboardLoading: () => boolean = () => useSelector(selectStationDashboardLoading)
export const useStationDocumentationSidebar: () => StationDocumentationSidebarType[] | null = () =>
  useSelector(selectStationDocumentationSidebar)
export const useStationDocumentationSidebarLoading: () => boolean = () =>
  useSelector(selectStationDocumentationSidebarLoading)

export const useStationDocumentationSidebarHidden: () => number[] = () =>
  useSelector(selectStationDocumentationSidebarHidden)

export const useStationDocumentationOneItemLoading: () => boolean = () =>
  useSelector(selectStationDocumentationOneItemLoading)

export const useStationDocumentationOneItem: () => StationDocumentationSidebarType | null = () =>
  useSelector(selectStationDocumentationOneItem)

export const useStationDocumentationCommentsOpen: () => boolean = () =>
  useSelector(selectStationDocumentationCommentsOpen)

export const useStationDocumentationSearchResultsLoading: () => boolean = () =>
  useSelector(selectStationDocumentationSearchResultsLoading)

export const useStationDocumentationSearchResults: () => null | {
  items: StationDocumentationSidebarType[],
  searchStr: string,
} = () => useSelector(selectStationDocumentationSearchResults)
export const useStationDocumentationComments: () => StationDocumentationCommentsType[] | null = () =>
  useSelector(selectStationDocumentationComments)

export const useStationDocumentationAttachmentsOpen: () => boolean = () =>
  useSelector(selectStationDocumentationAttachmentsOpen)

export const useStationDocumentationAttachments: () => null | any = () =>
  useSelector(selectStationDocumentationAttachments)

export const useStationDocumentationAttachmentsLoading: () => boolean = () =>
  useSelector(selectStationDocumentationAttachmentsLoading)
export const useStationDocumentationPublicLink: () => string = () => useSelector(selectStationDocumentationPublicLink)

export const useCurrentStationMembers: () => ElementMemberType[] | undefined = () =>
  useSelector(selectCurrentStationMembers)
export const useStationDocumentationInfoOpen: () => boolean = () => useSelector(selectStationDocumentationInfoOpen)
export const useStationDocumentationInfo: () => StationDocumentationInfoUpdateResponse | null = () =>
  useSelector(selectStationDocumentationInfo)

export const useStationDocumentationLinksOpen: () => boolean = () => useSelector(selectStationDocumentationLinksOpen)
export const useStationDocumentationLinks: () => StationDocumentationInfoUpdateResponse | null = () =>
  useSelector(selectStationDocumentationLinks)

export const useStationDocumentationAllLabels: () => any = () => useSelector(selectStationDocumentationAllLabels)
export const useCurrentStationDocumentation: () => any = () => useSelector(selectCurrentStationDocumentation)
export const useDocumentationDetails: () => any = () => useSelector(selectDocumentationDetails)
export const useUpdateDocumentLoading: () => boolean = () => useSelector(selectUpdateDocumentLoading)
export const useDocumentationSidebarAllItems: () => any = () => useSelector(selectDocumentationSidebarAllItems)
export const useCurrentOpenItems: () => any[] = () => useSelector(selectCurrentOpenItems)
export const useShowLatest: () => boolean = () => useSelector(selectShowLatest)
export const useIsStationArchived: () => boolean = () => useSelector(selectIsStationArchived)
export const useCurrentStationArchiveStatus: () => boolean = () => useSelector(selectCurrentStationArchiveStatus)
export const useCustomDashboard: () => boolean = () => useSelector(selectCustomDashboard)
export const useCustomListsFiltersOptions: () => any = () => useSelector(selectCustomListsFiltersOptions)
export const useArchivedDocumentsLoading: () => boolean = () => useSelector(selectArchivedDocumentsLoading)
export const useArchivedDocuments: () => any = () => useSelector(selectArchivedDocuments)
export const useArchivedDocumentsHiddenElements: () => any = () => useSelector(selectArchivedDocumentsHiddenElements)
export const useAllArchivedDocuments: () => any = () => useSelector(selectAllArchivedDocuments)
export const useAllArchivedDocumentsIds: () => any = () => useSelector(selectAllArchivedDocumentsIds)
export const useCurrentDashboardId: () => any = () => useSelector(selectCurrentDashboardId)
