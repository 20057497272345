import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store'
import CreateCustomFieldButton from '../../../components/CustomFields/CreateCustomFieldButton'
import { AppElementBaseTypes } from '../../../utils/types/AppElementType'
import {
  fetchGlobalCustomFields,
  useGlobalCustomFields,
  useLoadingGlobalCustomFields,
} from '../../../features/adminElement'
import ListCustomFieldItem from '../../../components/CustomFields/ListCustomFieldItem'
import { CustomFieldType } from '../../../utils/types/CustomFieldsType'

const CustomFieldsForm: FC = () => {
  const { t } = useTranslation('customFields')
  const globalCustomFields = useGlobalCustomFields()
  const loading = useLoadingGlobalCustomFields()
  const appDispatch = useAppDispatch()
  const onRefetch = () => {
    appDispatch(fetchGlobalCustomFields())
  }

  return (
    <div className="w-full p-8">
      <div className="flex items-center justify-between">
        <label className="block mb-2 text-lg font-bold">{t('customFields:customFields')}</label>
        <CreateCustomFieldButton
          currentItem={null}
          elementBaseType={AppElementBaseTypes.WorkspaceAppElement}
          isFromAdmin={true}
          showIcon={true}
          showText={false}
          onRefetch={onRefetch}
        />
      </div>
      <div className="overflow-y-auto" style={{ height: '90%', minHeight: '90%' }}>
        {globalCustomFields?.map((field: CustomFieldType, index: number) => (
          <>
            <ListCustomFieldItem
              key={`${index} ${field.id}`}
              isFromAdmin={true}
              currentItem={null}
              customFieldItem={field}
              elementBaseType={AppElementBaseTypes.ListAppElement}
              item={null}
              onRefetch={onRefetch}
            />
          </>
        ))}
        {globalCustomFields.length === 0 && (
          <div className="flex items-center justify-center w-full h-full text-sm opacity-50">{t('noCustomFields')}</div>
        )}
      </div>
    </div>
  )
}

export default CustomFieldsForm
