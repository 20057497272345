import React, { FC, useCallback } from 'react'
import { CustomFieldsListMessage } from 'utils/types/CustomFieldsType'
import { FormulaType } from '../../../utils/types/FormulaType'
import FormulaItem from './FormulaItem'

interface FormulaListType {
  formulaList: FormulaType[];
  onDeleteOption: (item: boolean) => void;
  setIsDescriptionChange: (item: boolean) => void;
  isDescriptionChange: boolean;
  customFields: CustomFieldsListMessage;
}
const FormulaList: FC<FormulaListType> = ({
  formulaList,
  setIsDescriptionChange,
  isDescriptionChange,
  customFields,
}) => {
  const renderFormula = useCallback((formula: any, index: number) => {
    return (
      <FormulaItem
        key={index}
        formulaList={formulaList}
        index={index}
        isDescriptionChange={isDescriptionChange}
        item={formula}
        setIsDescriptionChange={setIsDescriptionChange}
        customFields={customFields}
      />
    )
  }, [])

  return (
    <div className="w-full overflow-y-auto min-h-32 max-h-48">
      {formulaList?.map((formula, index) => {
        return <div key={index}>{renderFormula(formula, index)}</div>
      })}
    </div>
  )
}

export default FormulaList
