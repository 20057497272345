import React, { createRef, FC, useState } from 'react'
import classnames from 'classnames'

import { EyeIcon } from 'components/Icons'
import { EyeCrossedIcon } from 'components/Icons'

import { ErrorMessageRefType, ErrorMessageType, InputPropsType } from 'utils/types/inputs'
import InputErrorMessage from 'components/inputs/InputErrorMessage'

const PasswordInput: FC<InputPropsType & ErrorMessageType> = ({
  name,
  label,
  error,
  isInlineEditable,
  touched,
  classes,
  ...rest
}) => {
  const [type, setType] = useState('password')
  const errorRef = createRef<ErrorMessageRefType>()

  const toggleType = () => setType(type === 'password' ? 'text' : 'password')

  return (
    <div className={classnames('mb-6', classes.wrapper)}>
      {label && (
        <label className={classnames('block text-sm mb-2', classes.label)} htmlFor={`input-${name}`}>
          {label}
        </label>
      )}
      <div className="relative">
        <div className="flex bg-white border-2 border-gray-300 rounded transition transition-colors focus-within:border-primary">
          <input
            className="flex-1 min-w-0 px-2 py-3 text-gray-600 border-none focus:outline-none leading-6 me-12"
            id={`input-${name}`}
            name={name}
            placeholder={label}
            {...rest}
            type={type}
          />
        </div>

        <button
          className="absolute inset-y-0 px-3 text-gray-400 end-0 transition transition-colors focus:text-primary hover:text-primary"
          type="button"
          onClick={toggleType}>
          {type === 'password' ? <EyeIcon className="w-6" /> : <EyeCrossedIcon className="w-6" />}
        </button>
      </div>

      <InputErrorMessage
        data-testId={`${rest['data-testid'] || name}-error`}
        error={error}
        isInlineEditable={isInlineEditable}
        ref={errorRef}
        touched={touched}
      />
    </div>
  )
}

PasswordInput.defaultProps = {
  classes: {},
}

export default PasswordInput
