import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TranslateIcon } from 'components/Icons'
import { changeUserLanguage } from 'utils/helpers/profileHelpers'

type ChangeLanguageHeaderPropsType = {
  headerText: string,
}

const ChangeLanguageHeader: FC<ChangeLanguageHeaderPropsType> = ({ headerText }) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex flex-wrap items-center justify-between pb-8">
      <h1 className="text-3xl">{headerText}</h1>
      <button
        className="flex items-center py-2 text-sm ms-auto flex-0 text-primary-dark transition transition-colors hover:text-primary focus:text-primary"
        onClick={changeUserLanguage}>
        {t('labels.switchLanguage')}
        <TranslateIcon className="h-6 ms-2" />
      </button>
    </div>
  )
}

export default ChangeLanguageHeader
