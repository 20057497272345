import React, { FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { RecordType } from 'utils/types'
import { useRoles } from 'hooks'
import KanbanColumnItem from '../../Kanban/KanbanColumnItem'

interface DraggableColumnItemPropType extends RecordType {
  index: number;
  baseType: number;
  columnsType: string;
  onClick?: () => void;
  isParentInTheSameCol?: boolean;
}

const Item: FC<DraggableColumnItemPropType> = (props) => {
  const { id, columnsType, index, appElements, baseType } = props
  const { isEditor } = useRoles()
  return (
    <Draggable
      draggableId={`item-${columnsType}-${id}-recordElement-${appElements[0]?.id}-baseType-${baseType}`}
      index={index}
      isDragDisabled={!isEditor || appElements[0]?.isArchived}>
      {(provided: any) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <KanbanColumnItem {...props} formatSubtasks={true} />
        </div>
      )}
    </Draggable>
  )
}

export default Item
