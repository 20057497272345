import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/inputs'
import Button, { ButtonVariant, LinkButton } from 'components/Buttons'

import baseAPI from 'utils/baseAPI'
import { UserInfoPropsType } from 'utils/types'

import { forgotPasswordValidationSchema } from '../validationSchemas'

type ForgotPasswordFormPropsType = { initialValues: UserInfoPropsType }

const ForgotPasswordForm: FC<ForgotPasswordFormPropsType> = ({ initialValues }) => {
  const { t } = useTranslation('signIn')
  const history = useHistory()
  const { values, handleChange, handleBlur, touched, handleSubmit, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: (values) => {
      baseAPI('auth/forgot', {
        body: values,
        method: 'POST',
      }).then(() => {
        history.push('/confirmation-message', {
          message: t('resetPasswordMessage'),
        })
      })
    },
    validationSchema: forgotPasswordValidationSchema,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Input
        classes={{ wrapper: 'mb-6' }}
        error={errors.email}
        label={t('common:labels.email')}
        name="email"
        touched={touched.email}
        value={values.email}
        onBlur={(event: any) => {
          setFieldValue('email', values.email.toLowerCase())
          handleBlur(event)
        }}
        onChange={handleChange}
      />
      <Button full type="submit">
        {t('resetPassword')}
      </Button>
      <p className="mt-12">
        {t('emailTroubles', { returnObjects: true })[0]}
        <a href="https://mohimma.tech/contact" rel="noreferrer" target="_blank">
          {t('emailTroubles', { returnObjects: true })[1]}
        </a>
      </p>
      <p className="mt-16 mb-4 font-bold text-gray-600">{t('backToLogin')}</p>
      <LinkButton full to="/sign-in" variant={ButtonVariant.Outline}>
        {t('signIn:signIn')}
      </LinkButton>
    </form>
  )
}

export default ForgotPasswordForm
