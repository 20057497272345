export const flattenObject = (object: any) => {
  const result: { [key: string]: string | [] } = {}
  for (const i in object) {
    if (!Object.prototype.hasOwnProperty.call(object, i)) continue
    if (Array.isArray(object[i]) && object[i].length === 0) {
      result[i] = []
    }
    if (
      typeof object[i] == 'object' &&
      Object.prototype.toString.call(object[i]) !== '[object Date]' &&
      object[i] !== null
    ) {
      const flatObject = flattenObject(object[i])
      for (const x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue
        result[i + '.' + x] = flatObject[x]
      }
    } else {
      result[i] = object[i]
    }
  }
  return result
}
