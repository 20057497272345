import { createSlice, current } from '@reduxjs/toolkit'
import { RecordStateType } from 'utils/types/states'

import { formattingRecordsInStack, RECORDS_PER_PAGE } from 'utils/helpers/recordHelpers'
import { gridCustomList } from 'utils/helpers/customFields'
import groupBy from 'lodash/groupBy'
import { reorderItems } from '../../utils/helpers/generalHelpers'
import { ListStatusType } from '../../utils/types'
import recordType from '../../utils/types/RecordType'
import ElementOperations from '../../utils/constant/enums/element'
import {
  archiveBulkRecord,
  archiveRecordById,
  createRecord,
  deleteRecordById,
  duplicateRecordById,
  fetchRecordsByParams,
  onMoveRecord,
  onChangeRecordParent,
  onUpdateCustomFieldLinks,
  updateRecordById,
  fetchRecordCustomFields,
  fetchRecordById,
  updateRecordElementById,
} from './actions'

const initialState: RecordStateType = {
  currentCustomGridColumns: [],
  currentRecordDetails: undefined,
  currentRecordDetailsLoading: false,
  currentRecordId: undefined,
  currentRecordsCustomFields: {},
  currentSortColumn: 'id',
  currentSortDirection: 'asc',
  isFetching: true,
  isRejected: false,
  listStatusesGroups: {},
  loading: true,
  records: [],
  recordsChildren: {},
  selectedFilters: [],
  totalPages: 0,
  totalRecords: 0,
}

export type RecordHealth = 'unavailable' | 'onTime' | 'late' | 'veryLate'

export const recordSlice = createSlice({
  extraReducers: (builder) => {
    //fetchRecordsByParams
    builder.addCase(fetchRecordsByParams.fulfilled, (state, action) => {
      const {
        records,
        currentSortColumn,
        currentSortDirection,
        totalPages,
        totalRecords,
        customFields,
        listId,
        listElementId,
        currentView,
        statuses,
      } = action.payload
      state.records = records
        .map((record: any) => ({ ...record, nodes: null }))
        .filter((record: any) => record && record.appElements[0])
        .map((record: any) => {
          const updatedRecord = { ...record, eventOrder: record?.appElements[0].elementOrder }
          record?.appElements[0]?.customFieldValues?.map(
            (field: any) => (updatedRecord[field.customFieldId] = field.customFieldOptionId),
          )
          return updatedRecord
        })
        .sort((a: recordType, b: recordType) => a?.appElements[0]?.elementOrder - b?.appElements[0]?.elementOrder)
      state.recordsChildren = groupBy(records, (record) => record.appElements[0].parentId)
      state.currentRecordsCustomFields = customFields
      const fieldsList =
        Object.keys(state.currentRecordsCustomFields).length === 0
          ? []
          : [
              ...state.currentRecordsCustomFields?.native?.fields,
              ...state.currentRecordsCustomFields?.inherited?.fromAncestors?.fields,
              ...state.currentRecordsCustomFields?.inherited?.fromParent?.fields,
            ]
      state.currentCustomGridColumns = gridCustomList(
        fieldsList,
        listId,
        currentView?.appElementViewAttributes,
        currentView?.defaultAttributeVisibility,
      )
      state.currentSortColumn = currentSortColumn
      state.currentSortDirection = currentSortDirection
      state.totalPages = totalPages
      state.totalRecords = totalRecords
      state.loading = false
      state.isFetching = false
      //format record in the stack
      if (listElementId) {
        const orderedRecords = records.sort((a, b) => a?.appElements[0]?.elementOrder - b?.appElements[0]?.elementOrder)
        const listStatuses = groupBy(orderedRecords, 'statusId')
        statuses.map((status: ListStatusType) => {
          if (!listStatuses[status.id]) {
            listStatuses[status.id] = []
          }
        })
        Object.keys(listStatuses).map((status) => {
          state.listStatusesGroups[status] = formattingRecordsInStack(listStatuses[status], listElementId)
        })
      }
    })

    builder.addCase(fetchRecordsByParams.pending, (state) => {
      state.loading = true
      state.isFetching = true
    })

    builder.addCase(fetchRecordsByParams.rejected, (state, action: any) => {
      if (+action.payload.status !== 429) {
        state.loading = false
        state.isFetching = false
      }
    })

    // fetchRecordById
    builder.addCase(fetchRecordById.fulfilled, (state, action) => {
      if (action.payload) {
        const fetchOnly = action.payload.fetchOnly || false
        const updatedRecordIndex = state.records.findIndex(({ id: recordId }) => action.payload.id === recordId)
        state.records[updatedRecordIndex] = { ...action.payload }
        if (!fetchOnly) {
          state.currentRecordDetails = action.payload
          state.currentRecordId = action.payload.id
          if (action.payload.list !== undefined) {
            state.recordsChildren[action.payload.appElements[0].id] = action.payload.children
          }
        }
        state.currentRecordDetailsLoading = false
      }
    })
    builder.addCase(fetchRecordById.pending, (state, action) => {
      state.currentRecordDetailsLoading = true
    })
    builder.addCase(fetchRecordById.rejected, (state, action) => {
      state.currentRecordDetailsLoading = false
    })

    //createRecord
    builder.addCase(createRecord.fulfilled, (state, action) => {
      if (action.payload) {
        const parentId = action?.payload?.appElements ? action?.payload?.appElements[0]?.parentId : null
        const childrenList = state.recordsChildren[parentId] || []
        state.recordsChildren[parentId] = [...childrenList, action?.payload]
        state.records = [...state.records, action.payload]
        state.loading = false
        state.totalPages = Math.ceil((state.totalRecords + 1) / RECORDS_PER_PAGE)
        state.totalRecords = state.totalRecords + 1
      }
    })

    builder.addCase(createRecord.pending, (state) => {
      state.loading = true
    })

    builder.addCase(createRecord.rejected, (state) => {
      state.loading = false
    })

    // duplicate record

    builder.addCase(duplicateRecordById.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        action?.payload.map((rec: recordType) => {
          const parentId = rec?.appElements ? rec?.appElements[0]?.parentId : null
          const childrenList = parentId ? state.recordsChildren[parentId] || [] : []
          state.recordsChildren[parentId] = [...childrenList, rec]
        })
        state.records = [...state.records, ...action.payload]
        state.loading = false
        state.totalPages = Math.ceil((state.totalRecords + 1) / RECORDS_PER_PAGE)
        state.totalRecords = state.totalRecords + 1
      }
    })

    builder.addCase(duplicateRecordById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(duplicateRecordById.rejected, (state) => {
      state.loading = false
    })

    //updateRecordById
    builder.addCase(updateRecordById.fulfilled, (state, action) => {
      const updatedRecord = action.payload
      if (action?.payload?.appElements)
        action?.payload?.appElements[0]?.customFieldValues?.map(
          (field: any) => (updatedRecord[field.customFieldId] = field.customFieldOptionId),
        )
      if (updatedRecord) {
        const updatedRecordIndex = state.records.findIndex(({ id: recordId }) => updatedRecord?.id === recordId)
        if (updatedRecordIndex > -1) {
          state.records[updatedRecordIndex] = updatedRecord
        } else {
          state.records = [...state.records, { ...updatedRecord }]
        }
        state.currentRecordDetails = updatedRecord
        state.currentRecordId = updatedRecord.id
        // Change the updated record inside recordsChildren object
        const recordParentElementId = updatedRecord?.appElements ? updatedRecord?.appElements[0]?.parentId : null
        const parentRecordsChildren = state.recordsChildren[recordParentElementId]
        const subRecordIndex = parentRecordsChildren?.findIndex(({ id }) => +id === +updatedRecord.id)
        if (subRecordIndex === -1) {
          state.recordsChildren[recordParentElementId] = [...parentRecordsChildren, updatedRecord]
        } else if (parentRecordsChildren) {
          parentRecordsChildren[subRecordIndex] = updatedRecord
          state.recordsChildren[recordParentElementId] = parentRecordsChildren
        }
        if (state.currentRecord?.id === updatedRecord?.id) state.currentRecord = updatedRecord
        state.loading = false
      }
    })
    //updateRecordElementById
    builder.addCase(updateRecordElementById.fulfilled, (state, action) => {
      const updatedRecord = action.payload
      if (action?.payload?.appElements)
        action?.payload?.appElements[0]?.customFieldValues?.map(
          (field: any) => (updatedRecord[field.customFieldId] = field.customFieldOptionId),
        )
      if (updatedRecord) {
        const updatedRecordIndex = state.records.findIndex(({ id: recordId }) => updatedRecord?.id === recordId)
        if (updatedRecordIndex > -1) {
          state.records[updatedRecordIndex] = updatedRecord
        } else {
          state.records = [...state.records, { ...updatedRecord }]
        }
        state.currentRecordDetails = updatedRecord
        state.currentRecordId = updatedRecord.id
        // Change the updated record inside recordsChildren object
        const recordParentElementId = updatedRecord?.appElements ? updatedRecord?.appElements[0]?.parentId : null
        const parentRecordsChildren = state.recordsChildren[recordParentElementId]
        const subRecordIndex = parentRecordsChildren?.findIndex(({ id }) => +id === +updatedRecord.id)
        if (subRecordIndex === -1) {
          state.recordsChildren[recordParentElementId] = [...parentRecordsChildren, updatedRecord]
        } else if (parentRecordsChildren) {
          parentRecordsChildren[subRecordIndex] = updatedRecord
          state.recordsChildren[recordParentElementId] = parentRecordsChildren
        }
        if (state.currentRecord?.id === updatedRecord?.id) state.currentRecord = updatedRecord
        state.loading = false
      }
      // if (updatedRecord.newOrder) {
      //   state.records = [...state.records].sort((a, b) => a.appElements[0].elementOrder - b.appElements[0].elementOrder)
      // }
    })

    //onUpdateCustomFieldLinks
    builder.addCase(onUpdateCustomFieldLinks.fulfilled, (state, action) => {
      const updatedRecord = action.payload
      if (updatedRecord) {
        const updatedRecordIndex = state.records.findIndex(({ id: recordId }) => +updatedRecord?.id === +recordId)
        const updatedRecords = [...state.records]
        updatedRecords[updatedRecordIndex] = updatedRecord
        state.records = [...updatedRecords]
        const updatedCurrentRecord = { ...state.currentRecord }
        if (+updatedCurrentRecord.id === +updatedRecord?.id) {
          updatedCurrentRecord = updatedRecord
          state.currentRecord = { ...updatedCurrentRecord }
        }
        state.loading = false
      }
    })

    builder.addCase(updateRecordById.pending, (state, action) => {
      const { initialRecord, changes } = action.meta.arg
      const recordToUpdateIndex = state.records.findIndex(({ id: recordId }) => initialRecord.id === recordId)
      state.records[recordToUpdateIndex] = { ...initialRecord }
      state.currentRecordDetails = { ...initialRecord }
      const tempRecord = { ...initialRecord, ...changes }
      if (changes?.statusId === null) {
        state.records[recordToUpdateIndex] = { ...tempRecord, statusId: null }
        if (initialRecord.id === state.currentRecord?.id) state.currentRecord = { ...tempRecord, statusId: null }
      } else {
        state.records[recordToUpdateIndex] = { ...tempRecord }

        if (initialRecord.id === state.currentRecord?.id) {
          state.currentRecord = { ...tempRecord }
        }
      }
    })

    builder.addCase(updateRecordById.rejected, (state, action) => {
      const { initialRecord } = action.meta.arg
      const recordToUpdateIndex = state.records.findIndex(({ id: recordId }) => initialRecord.id === recordId)
      state.records[recordToUpdateIndex] = initialRecord
      state.currentRecordDetails = initialRecord
      state.loading = false
    })
    builder.addCase(updateRecordElementById.pending, (state, action) => {
      const { record, changes, newOrder, oldOrder, listElementId, statuses } = action.meta.arg
      const recordToUpdateIndex = state.records.findIndex(({ id: recordId }) => record.id === recordId)
      state.records[recordToUpdateIndex] = { ...record }
      state.currentRecordDetails = { ...record }
      if (changes?.statusId === null) {
        state.records[recordToUpdateIndex] = { ...record, ...changes, statusId: null }
        if (record.id === state.currentRecord?.id) state.currentRecord = { ...record, ...changes, statusId: null }
      } else {
        state.records[recordToUpdateIndex] = { ...record, ...changes }

        if (record.id === state.currentRecord?.id) state.currentRecord = { ...record, ...changes }
      }
      if (newOrder || newOrder === 0) {
        const originalItems = [...state.records]
        const newOrderedList = reorderItems({
          itemId: record.id,
          newOrder,
          oldOrder,
          orderPath: 'appElements[0].elementOrder',
          originalItems,
        })
        state.records = newOrderedList
        if (statuses && listElementId) {
          const orderedList = newOrderedList.sort(
            (a, b) => a?.appElements[0]?.elementOrder - b?.appElements[0]?.elementOrder,
          )
          const listStatuses = groupBy(orderedList, 'statusId')
          statuses.map((status: ListStatusType) => {
            if (!listStatuses[status.id]) {
              listStatuses[status.id] = []
            }
          })
          Object.keys(listStatuses).map((status) => {
            state.listStatusesGroups[status] = formattingRecordsInStack(listStatuses[status], listElementId)
          })
        }
      }
    })

    builder.addCase(updateRecordElementById.rejected, (state, action) => {
      const { record } = action.meta.arg
      const recordToUpdateIndex = state.records.findIndex(({ id: recordId }) => record.id === recordId)
      state.records[recordToUpdateIndex] = record
      state.currentRecordDetails = record
      state.loading = false
    })

    //deleteRecordById
    builder.addCase(deleteRecordById.fulfilled, (state, action) => {
      const deletedRecordId = action.payload
      const deletedRecordIndex = state.records.findIndex(({ id: recordId }) => deletedRecordId === recordId)
      const deletedRecord = state.records[deletedRecordIndex]
      state.records.splice(deletedRecordIndex, 1)
      const recordsList = state.records.filter(
        (rec) => +rec.appElements[0].parentId !== +deletedRecord.appElements[0].id,
      )
      state.records = [...recordsList]
      state.loading = false
      state.totalPages = Math.ceil((state.totalRecords - 1) / RECORDS_PER_PAGE)
      state.totalRecords = state.totalRecords - 1
    })
    //archiveRecords
    builder.addCase(archiveBulkRecord.fulfilled, (state, action) => {
      const archivedRecordsIds = action.payload
      const recordsCopyList = state.records.filter((rec) => !archivedRecordsIds.includes(rec?.appElements[0]?.id))
      Object.keys({ ...state.recordsChildren })?.map((key) => {
        const updatedChildren = [...state.recordsChildren[key]]?.filter(
          (child) => !archivedRecordsIds.includes(child.appElements[0].id),
        )
        state.recordsChildren[key] = updatedChildren.length > 0 ? updatedChildren : undefined
      })
      state.loading = false
      state.totalPages = Math.ceil((state.totalRecords - archivedRecordsIds.length) / RECORDS_PER_PAGE)
      state.totalRecords = state.totalRecords - archivedRecordsIds.length
      state.records = [...recordsCopyList]
    })

    builder.addCase(deleteRecordById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(deleteRecordById.rejected, (state) => {
      state.loading = false
    })
    //archiveRecordById
    builder.addCase(archiveRecordById.fulfilled, (state, action) => {
      const archivedRecordId = action.payload
      const archivedRecordIndex = state.records.findIndex(({ appElements }) => archivedRecordId === +appElements[0].id)
      const archivedRecord = state.records[archivedRecordIndex]
      state.records.splice(archivedRecordIndex, 1)
      const recordsList = state.records.filter(
        (rec) => +rec.appElements[0].parentId !== +archivedRecord.appElements[0].id,
      )
      state.records = [...recordsList]
      state.loading = false
      state.totalPages = Math.ceil((state.totalRecords - 1) / RECORDS_PER_PAGE)
      state.totalRecords = state.totalRecords - 1
    })

    builder.addCase(archiveRecordById.pending, (state) => {
      state.loading = true
    })

    builder.addCase(archiveRecordById.rejected, (state) => {
      state.loading = false
    })
    //onMoveRecord
    builder.addCase(onMoveRecord.fulfilled, (state, action) => {
      const movedRecordId = action.payload.appElementId
      const movedRecordIndex = state.records.findIndex(({ appElements }) => +movedRecordId === +appElements[0].id)
      if (action.payload.newParentId && Number(action.payload.operation) === ElementOperations.MOVE) {
        state.records.splice(movedRecordIndex, 1)
        const recordChildrenIds = state.recordsChildren[movedRecordId]
          ? state.recordsChildren[movedRecordId].map(({ id }) => +id)
          : []
        state.records = [...state.records].filter((rec) => recordChildrenIds.indexOf(+rec.id) === -1)
        state.recordsChildren[movedRecordId] = null
      } else if (!action.payload.newParentId) {
        state.records.push(action.payload.response)
      }
      state.loading = false
      state.totalPages = Math.ceil((state.totalRecords - 1) / RECORDS_PER_PAGE)
      state.totalRecords = state.totalRecords - 1
    })

    builder.addCase(onMoveRecord.pending, (state) => {
      state.loading = true
    })

    builder.addCase(onMoveRecord.rejected, (state) => {
      state.loading = false
    })
    //onChangeRecordParent
    builder.addCase(onChangeRecordParent.fulfilled, (state, action) => {
      const recordsList = [...state.records]
      const changedRecordIndex = recordsList?.findIndex(
        ({ appElements }) => +action?.payload?.appElementId === +appElements[0]?.id,
      )
      const changedRecord = {
        ...state.records[changedRecordIndex],
        appElements: [
          {
            ...action?.payload?.response?.appElements[0],
          },
        ],
      }
      recordsList[changedRecordIndex] = { ...changedRecord }
      state.records = [...recordsList]
      // these lines of codes to remove record from old parent list and add it to the new parent list
      const newParentChildrenList = state.recordsChildren[action.payload.newParentId] || []
      const oldParentChildrenList = state.recordsChildren[action.payload.oldParentId] || []
      const removeIndex = oldParentChildrenList?.findIndex((item) => +item.id === +changedRecord?.id)
      state.recordsChildren[action.payload.newParentId] = [...newParentChildrenList, changedRecord]
      oldParentChildrenList.splice(removeIndex, 1)
      state.recordsChildren[action.payload.oldParentId] = oldParentChildrenList
      state.loading = false
    })

    builder.addCase(onChangeRecordParent.pending, (state) => {
      state.loading = true
    })

    builder.addCase(onChangeRecordParent.rejected, (state) => {
      state.loading = false
    })
    //onChangeRecordParent
    builder.addCase(fetchRecordCustomFields.fulfilled, (state, action) => {
      state.currentRecordsCustomFields = action.payload
    })
  },
  initialState,
  name: 'record',
  reducers: {
    addMultipleFilters: (state, action) => {
      state.selectedFilters = action.payload
    },
    addOrUpdateFilter: (state, action) => {
      const filterToUpdateIndex = state.selectedFilters.findIndex(
        ([type, , id]) => type === action.payload[0] && id === action.payload[2],
      )
      if (filterToUpdateIndex > -1) {
        state.selectedFilters[filterToUpdateIndex] = action.payload
      } else {
        state.selectedFilters = [...state.selectedFilters, action.payload]
      }
    },
    clearCurrentRecord: (state) => {
      state.currentRecordId = undefined
      state.currentRecordDetails = undefined
      state.isRejected = false
    },
    clearFilters: (state) => {
      state.selectedFilters = []
    },
    clearRecords: (state) => {
      state.records = []
      state.currentRecordsCustomFields = {}
      state.currentCustomGridColumns = []
      state.loading = true
      state.isRejected = false
    },
    removeFilterById: (state, action) => {
      const filtersWithoutFilterToRemove = state.selectedFilters.filter(([, , id]) => +id !== action.payload)
      state.selectedFilters = filtersWithoutFilterToRemove
    },
    setCurrentRecord: (state, action) => {
      state.currentRecordId = Number(action.payload) || undefined
      state.isRejected = false
    },
  },
})

export default recordSlice.reducer
