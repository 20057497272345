import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { CellRenderObject } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps'
import { RecordHealth } from 'features/record/recordSlice'

export const healthColors: {
  late: string,
  onTime: string,
  unavailable: string,
  veryLate: string,
} = {
  late: '#FF671D',
  onTime: '#00CE7C',
  unavailable: '#C5D4E2',
  veryLate: '#EB0045',
}

const HealthCell: FC<CellRenderObject & { value: RecordHealth }> = ({ value }: { value: RecordHealth }) => {
  const { t } = useTranslation(['records', 'noAccess'])
  return (
    <div
      className={`inline-flex px-2 py-1 rounded leading-6 whitespace-nowrap w-20 justify-center ${
        value === 'unavailable' ? 'text-gray-600' : 'text-white'
      }`}
      style={{ backgroundColor: healthColors[value] }}>
      {t(`records:health:${value}`)}
    </div>
  )
}

export default HealthCell
