import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import Button, { ButtonVariant } from '../../Buttons'
import { MenuIcon } from '../../Icons'
import { configValues } from '../../../utils/appConfig'

const AdminHeader = ({ title }) => {
  const { t } = useTranslation('admin')
  return (
    <header
      className={classnames('text-white ps-3 md:px-4 py-5', {
        'bg-tertiary-light': !configValues.REACT_APP_IS_CUSTOM_BRANDING,
      })}
      style={configValues.REACT_APP_IS_CUSTOM_BRANDING ? { ...configValues.listNavigationClasses } : {}}>
      <div className="flex">
        <Button
          className="px-2 py-2 -ms-3 lg:hidden"
          data-tut="mobile__sidebar"
          icon={MenuIcon}
          variant={ButtonVariant.Icon}
          onClick={() => console.log(true)}
        />
        <h1 className="flex flex-wrap items-center min-w-0 font-bold text-white me-auto">{t(title)}</h1>
      </div>
    </header>
  )
}

export default AdminHeader
