import React, { FC, useState } from 'react'

import { StationType } from 'utils/types'
import { useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { Container, Draggable } from '@edorivai/react-smooth-dnd'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store'
import { useHasCurrentWorkspacePremiumPlan } from 'features/workspace'
import { updateElementOrder } from 'features/element'
import { fetchStationsByWorkspaceId } from 'features/station'
import SingleStationTile from '../SingleStationTile'

const sortList = (list: any) => {
  const newList = list.map((item: any) => ({ ...item, order: item?.appElement?.elementOrder }))
  return sortBy(newList, ['order'])
}

const renderStyle = (condition: boolean) => {
  if (!condition) {
    return {
      height: 0,
      opacity: 0,
      transition: 'visibility 0s, height 0s, opacity 0.5s linear',
      visibility: 'hidden',
    }
  } else {
    return {
      height: 'auto',
      opacity: 1,
      transition: 'visibility 0s,height 0s, opacity 0.5s linear',
      visibility: 'visible',
    }
  }
}

const StationTileListing: FC<{
  stations: StationType[] | undefined,
  workspaceId: string,
  header: string | undefined,
  isReorderMode: boolean,
  isArchived: boolean,
  isSideBarOpen: boolean,
  setIsReorderMode: () => void,
}> = ({ stations, workspaceId, header, isReorderMode, isArchived, setIsReorderMode, isSideBarOpen }) => {
  const { i18n } = useTranslation()
  const isRtl = i18n.language === 'ar'
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const isMobile = window.innerWidth <= 640
  const isPro = useHasCurrentWorkspacePremiumPlan()
  const sortedList = sortList(stations)
  const resultList = isRtl ? sortedList.slice().reverse() : sortedList
  const [isMovingStation, updateMovingStation] = useState(false)
  if (!stations?.length) return null
  const reorderList = (dropResult: any) => {
    const { removedIndex, addedIndex, payload, element } = dropResult
    updateMovingStation(true)
    if (addedIndex === removedIndex) {
      return updateMovingStation(false)
    }
    const movedItem = payload
    const destinationItem = isRtl ? resultList[addedIndex] : sortedList[addedIndex]
    appDispatch(
      updateElementOrder({ id: +movedItem?.appElement?.id, newPosition: destinationItem?.appElement?.elementOrder }),
    ).then(() => {
      dispatch(fetchStationsByWorkspaceId({ isArchived, workspaceId: +workspaceId }))
      updateMovingStation(false)
    })
  }

  return (
    <>
      <h3 className="mt-2 mb-6 text-lg ms-10 md:mt-0">{header}</h3>
      <div className={`w-full overflow-x-auto overflow-y-hidden`}>
        <div style={renderStyle(isReorderMode)}>
          <Container
            autoScrollEnabled={true}
            dragHandleSelector=".column-drag-handle"
            getChildPayload={(i) => resultList[i]}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            onDrop={reorderList}>
            {resultList.map((station, index) => {
              return (
                <Draggable key={station.id}>
                  <div className="cursor-pointer column-drag-handle" dir={isRtl ? 'rtl' : 'ltr'}>
                    <SingleStationTile
                      key={`${isRtl}_${station.id}`}
                      isLastInRow={index % 3 === 2}
                      isMovingStation={isMovingStation}
                      isReorderMode={isReorderMode}
                      station={station}
                      isArchived={isArchived}
                      workspaceId={workspaceId}
                      isProWorkspace={isPro}
                      isSideBarOpen={isSideBarOpen}
                    />
                  </div>
                </Draggable>
              )
            })}
          </Container>
        </div>
      </div>
      <div className={'flex flex-wrap'} style={renderStyle(!isReorderMode)}>
        {sortedList.map((station, index) => {
          return (
            <SingleStationTile
              key={`${station.id}`}
              isLastInRow={index % 3 === 2}
              isMovingStation={isMovingStation}
              isReorderMode={isReorderMode}
              station={station}
              isArchived={isArchived}
              workspaceId={workspaceId}
              isProWorkspace={isPro}
              isSideBarOpen={isSideBarOpen}
            />
          )
        })}
      </div>
    </>
  )
}

export default StationTileListing
