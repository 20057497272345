import React, { FC } from 'react'
import Modal from 'react-modal'
import ManageViewForm from './ManageViewForm'

interface CustomFieldPopoverProps {
  view: any | null;
  appElementId: string;
  isModalOpen: boolean;
  onCancel: () => void;
}
const ManageViewModal: FC<CustomFieldPopoverProps> = ({ appElementId, isModalOpen, onCancel, view }) => {
  return (
    <Modal
      className="fixed top-0 bottom-0 z-50 flex flex-col w-full max-w-lg bg-white rounded shadow-lg focus:outline-none lg:top-36 lg:bottom-36 left-1/2 transform -translate-x-1/2"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/30 inset-0 fixed z-50"
      onRequestClose={onCancel}>
      <ManageViewForm onCancel={onCancel} appElementId={appElementId} view={view} />
    </Modal>
  )
}

export default ManageViewModal
