import React from 'react'
import { useHistory, useParams } from 'react-router'
import PaymentForm from '../../components/forms/PaymentForm'
import { ChevronLeftIcon } from '../../components/Icons'
import { withAdminRole } from '../../hocs/withRole'
//TODO: add translation
const WorkspacePaymentPage = () => {
  const history = useHistory()
  const workspace = useParams()

  return (
    <div className="w-full p-6 md:p-8">
      <div
        className="flex justify-start mb-5 cursor-pointer"
        onClick={() => {
          history.push(`/workspace/${workspace?.workspaceId}/billing`)
        }}>
        <ChevronLeftIcon className="w-10 -mt-2" />
        <h2>Back</h2>
      </div>
      <div className="p-16 bg-white rounded-ts-none rounded-2xl">
        <h1 className="mb-6 text-lg">Enter payment details</h1>
        <PaymentForm />
      </div>
    </div>
  )
}

export default withAdminRole(WorkspacePaymentPage, true)
