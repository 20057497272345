import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import RolesEnum from 'utils/constant/enums'
import { hasProperRole } from 'utils/helpers/permissionHelpers'
import { useCurrentUserPermissions } from 'features/user'
import { useCurrentWorkspaceLoading, useWorkspacesLoading } from 'features/workspace'
import { setRejectedWorkspace } from 'features/workspace/workspaceSlice'
import { useCurrentStationLoading } from 'features/station'
import EmptyStatePage from 'components/EmptyStatePage'
import { ChevronLeftIcon, EmptyErrorIcon } from 'components/Icons'
import { ButtonVariant, LinkButton } from 'components/Buttons'
import Loader from 'components/Loader'

/* 
  HOC for managing rendering depending on the given role  
*/

const withRole = (requiredRole: RolesEnum, showPage: boolean, customLoader?: any) => (WrappedComponent: any) => {
  const HocComponent = ({ ...props }) => {
    const { t } = useTranslation('noAccess')
    const userRole = useCurrentUserPermissions()

    // roleupdate
    const shouldBeDisplayed = hasProperRole(userRole, requiredRole)

    // useEffect(() => {
    //   if (!shouldBeDisplayed && requiredRole === RolesEnum.ExplicitAccess) {
    //     dispatch(setRejectedWorkspace())
    //   }
    // }, [shouldBeDisplayed, dispatch])

    if (!shouldBeDisplayed && (requiredRole === RolesEnum.ExplicitAccess || showPage)) {
      const NoAccessButton = () => (
        <LinkButton to="/" variant={ButtonVariant.Outline}>
          <ChevronLeftIcon className="w-6 rtl-mirror" />
          {t(`button`)}
        </LinkButton>
      )
      return (
        <EmptyStatePage
          Button={NoAccessButton}
          Icon={EmptyErrorIcon}
          description={t(`description`)}
          header={t(`header`)}
        />
      )
    }

    if (!shouldBeDisplayed) return null

    return <WrappedComponent {...props} />
  }

  return HocComponent
}

export const withAdminRole = (WrappedComponent: any, showPage = false, customLoader?: any) =>
  withRole(RolesEnum.Admin, showPage, customLoader)(WrappedComponent)

export const withEditorRole = (WrappedComponent: any, showPage = false, customLoader?: any) =>
  withRole(RolesEnum.Editor, showPage, customLoader)(WrappedComponent)

export const withViewerRole = (WrappedComponent: any, showPage = false, customLoader?: any) =>
  withRole(RolesEnum.Viewer, showPage, customLoader)(WrappedComponent)

export const withExplicitRole = (WrappedComponent: any, showPage = false, customLoader?: any) =>
  withRole(RolesEnum.ExplicitAccess, showPage, customLoader)(WrappedComponent)

export default withRole
