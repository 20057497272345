import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import ChangeLanguageHeader from 'components/ChangeLanguageHeader'
import { Input } from 'components/inputs'
import Button, { ButtonVariant, LinkButton } from 'components/Buttons'
import { forgotPasswordValidationSchema } from 'components/forms/validationSchemas'
import baseAPI from 'utils/baseAPI'

const ResendEmailPage: FC = () => {
  const { t } = useTranslation('signIn')
  const history = useHistory()
  const { values, handleChange, handleBlur, touched, handleSubmit, errors } = useFormik({
    initialValues: { email: '' },
    onSubmit: (values, { resetForm }) => {
      baseAPI('api/users/resend/email', {
        body: values,
        method: 'POST',
      }).then((response) => {
        if (response.status === 400) {
          resetForm()
        } else {
          history.push('/confirmation-message', {
            message: t('signUp:emailSent'),
          })
        }
      })
    },
    validationSchema: forgotPasswordValidationSchema,
  })

  return (
    <>
      <ChangeLanguageHeader headerText={t('resendConfirmationEmail')} />
      <form onSubmit={handleSubmit}>
        <Input
          classes={{ wrapper: 'mb-6', input: 'lowercase-input' }}
          error={errors.email}
          label={t('common:labels.email')}
          name="email"
          touched={touched.email}
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <Button full type="submit">
          {t('resendConfirmationEmail')}
        </Button>
        <p className="mt-12">
          {t('emailTroubles', { returnObjects: true })[0]}
          <a href="https://mohimma.tech/contact" rel="noreferrer" target="_blank">
            {t('emailTroubles', { returnObjects: true })[1]}
          </a>
        </p>
        <p className="mt-16 mb-4 font-bold text-gray-600">{t('backToLogin')}</p>
        <LinkButton full to="/sign-in" variant={ButtonVariant.Outline}>
          {t('signIn:signIn')}
        </LinkButton>
      </form>
    </>
  )
}

export default ResendEmailPage
