import React, { FC, useEffect, useMemo } from 'react'
import { fetchRecordCustomFields, useCurrentRecordsCustomFields } from 'features/record'
import { RecordType } from 'utils/types'
import { CustomFieldType } from 'utils/types/CustomFieldsType'
import { getFieldReplacingInfo, getFieldVisibility, getInheritedCustomFields } from 'utils/helpers/customFields'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import { AppElementAttributeVisibility } from 'utils/types/AppElementAttributeVisibility'
import { AppElementViewAttribute } from 'utils/types/AppElementViewType'
import AppElementAttributeType from 'utils/types/AppElementAttributeType'

import { useDispatch } from 'react-redux'
import { customFieldBaseTypes } from '../../utils/constant/enums/customFields'
import RecordCustomFieldsEditable from './RecordCustomFieldEditable'

interface RecordCustomFieldsListType {
  isAdmin: boolean;
  isEditor: boolean;
  isLeafNode: boolean;
  currentRecord: Partial<RecordType>;
  handleUpdateRecord: (values: Partial<RecordType>) => void;
  viewAttributes?: AppElementViewAttribute[];
  defaultAttributeVisibility?: AppElementAttributeVisibility;
}
const RecordCustomFieldsList: FC<RecordCustomFieldsListType> = ({
  currentRecord,
  handleUpdateRecord,
  isEditor,
  isAdmin,
  isLeafNode,
  viewAttributes,
  defaultAttributeVisibility,
}) => {
  const dispatch = useDispatch()
  const currentRecordsCustomFields = useCurrentRecordsCustomFields()
  const fieldsList = getInheritedCustomFields(currentRecordsCustomFields)

  useEffect(() => {
    if (currentRecord?.appElements) dispatch(fetchRecordCustomFields(currentRecord?.appElements[0]?.id))
  }, [currentRecord?.id])

  const onUpdate = async (key: string, type: number, value: any[] | string | null | undefined) => {
    const fieldsList = {}
    const changes = { [key]: value }
    const fieldValue = {
      customFieldId: key,
      description: 'custom linked item',
      isLinkedAsChild: false,
    }
    if (type === customFieldBaseTypes.DropDown) {
      fieldValue.customFieldOptionId = value
      fieldValue.value = null
    } else if (type === customFieldBaseTypes.Formula) {
      fieldValue.customFieldFormulaId = value
      fieldValue.value = ''
    } else {
      fieldValue.value = value
    }
    const allFields = [...currentRecord?.appElements[0]?.customFieldValues]
    const updatedFieldIndex = allFields.findIndex((i: any) => +i.customFieldId === +key)
    if (updatedFieldIndex > -1) {
      allFields[updatedFieldIndex] = { ...allFields[updatedFieldIndex], ...fieldValue }
    } else {
      if (type !== 3) allFields.push(fieldValue)
    }

    fieldsList.appElements = [
      {
        ...currentRecord.appElements[0],
        customFieldValues: allFields,
      },
    ]
    handleUpdateRecord({ ...currentRecord, ...fieldsList, changes })
  }
  const customFieldValues = useMemo(() => {
    return currentRecord?.appElements[0]?.customFieldValues
  }, [currentRecord])

  return (
    <>
      {fieldsList
        ?.sort((a, b) => a.baseType - b.baseType)
        ?.map((field: CustomFieldType, index: number) => {
          let visibility
          if (currentRecord?.resultAttributes) {
            const recordAttribute = currentRecord?.resultAttributes?.find(
              (recordAttr) => Number(recordAttr.attributeId) === Number(field.id),
            )
            visibility = recordAttribute?.attributeVisibility ?? AppElementAttributeVisibility.Hidden
          } else if (defaultAttributeVisibility !== undefined && viewAttributes) {
            visibility = getFieldVisibility({
              attributeId: Number(field.id),
              attributeType: AppElementAttributeType.customField,
              defaultAttributeVisibility,
              elementBaseType: AppElementBaseTypes.RecordAppElement,
              field,
              fieldsList,
              viewAttributes,
            })
          } else {
            visibility = AppElementAttributeVisibility.AsPerRole
          }

          if (visibility === AppElementAttributeVisibility.Hidden) return null

          const { shouldBeReplaced, value: customFieldValue } = getFieldReplacingInfo({
            customFieldValues,
            field,
            fieldsList,
            isLeafNode,
          })

          const isCalculatedField =
            field.baseType === customFieldBaseTypes.Formula ||
            field.baseType === customFieldBaseTypes.Aggregate ||
            shouldBeReplaced

          return (
            <tr key={index}>
              <td
                className={`break-words text-sm font-bold ${isCalculatedField && 'italic font-extrabold'}`}
                id={`${index}`}>
                {field.fieldName}
              </td>
              <td>
                <RecordCustomFieldsEditable
                  currentItem={currentRecord}
                  customFieldInfo={field}
                  customFieldValue={customFieldValue}
                  isEditor={
                    isCalculatedField || visibility === AppElementAttributeVisibility.ReadOnly ? false : isEditor
                  }
                  onUpdate={onUpdate}
                />
              </td>
            </tr>
          )
        })}
    </>
  )
}

export default RecordCustomFieldsList
