import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash/groupBy'

import { getElementLinkFromPath, getUserPermissionFromAccessGroup } from 'utils/helpers/generalHelpers'
import { AppElementBaseTypes } from 'utils/types/AppElementType'
import Loader from '../../../Loader'
import {
  deleteElementLink,
  getElementLinkedRecords,
  useLinkedElements,
  useLinkedElementsLoading,
} from '../../../../features/element'
import { CloseIcon } from '../../../Icons'
import ConfirmationModal from '../../../ConfirmationModal'
import { useAppDispatch } from '../../../../store'
import KanbanColumnItem from '../../../Kanban/KanbanColumnItem'
import { fetchRecordById } from '../../../../features/record'
import { AddRecordToListForm } from '../../../forms'
import { usePanel } from '../../../../contexts/PanelContext'
import { updateCurrentListDetails } from '../../../../features/list/listSlice'
import userType from '../../../../utils/types/UserType'
import { useCurrentUser } from '../../../../features/user'
import { refetchAfterUpdate } from '../../../../features/element/elementSlice'
import EmptyLinkedRecords from './EmptyLinkedRecords'

const LinkedRecordsList = () => {
  const dispatch = useAppDispatch()
  const user = useCurrentUser()
  const { location } = useHistory()
  const { search } = useLocation()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const linkedRecords = useLinkedElements()
  const loading = useLinkedElementsLoading()
  const [showDelete, setShowDelete] = useState(false)
  const [deletedItem, setDeletedItem] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const { setPanelContent, openPanel } = usePanel()
  const isEmptyList = !loading && linkedRecords.length === 0
  const sortedList = [...linkedRecords]

  useEffect(() => {
    const recordInfo = location?.search?.split('=')
    dispatch(getElementLinkedRecords(+id))
    if (recordInfo.length > 0) {
      setSelectedRecordId(recordInfo[1])
    }
  }, [location?.search])

  useEffect(() => {
    if (selectedRecordId) {
      dispatch(fetchRecordById({ details: true, id: selectedRecordId })).then((res) => {
        if (res.payload) {
          dispatch(updateCurrentListDetails(res.payload.list))
          const currentRecord = res.payload
          const currentUser = res.payload.list.listMembers.find(
            (userData: userType) => Number(userData.userId) === Number(user.id),
          )
          const isEditorUser = getUserPermissionFromAccessGroup(currentUser?.accessGroupId, 'Editor')
          const isAdminUser = getUserPermissionFromAccessGroup(currentUser?.accessGroupId, 'Admin')
          setPanelContent({
            content: (
              <AddRecordToListForm
                isAdmin={isAdminUser}
                isDetailedRecord={true}
                isEditor={isEditorUser}
                listId={+currentRecord?.listId}
                partialRecord={currentRecord}
                recordId={+currentRecord.id}
              />
            ),
            header: currentRecord.name,
            isBig: true,
            isRecordEditable:
              getUserPermissionFromAccessGroup(currentUser?.accessGroupId) &&
              !currentRecord?.appElements[0]?.isArchived,
            partialRecord: currentRecord,
          })
          openPanel()
          dispatch(refetchAfterUpdate(() => getElementLinkedRecords(+id)))
          setSelectedRecordId(null)
        }
      })
    }
  }, [selectedRecordId, location?.search?.includes('?recordId='), search])

  const renderLinkedRecords = () => {
    const groupedByList = groupBy(sortedList, (record) => record.listId)

    return Object.keys(groupedByList).map((listId) => {
      const listUrl = getElementLinkFromPath(groupedByList[listId][0]?.legacyPath, AppElementBaseTypes.ListAppElement)

      const recordsToDisplay = groupedByList[listId].map((record: any, index: number) => {
        const { recordId, recordData } = record
        return (
          <div key={index} className="flex flex-row">
            <div
              className="flex-1 text-sm"
              style={{ color: 'inherit' }}
              onClick={() => setSelectedRecordId(recordId)}
              // target={'_blank'}
              // to={`/workspace/${workspaceId}/stations/${stationId}/lists/${listId}?recordId=${recordId}`}
            >
              <KanbanColumnItem {...recordData} />
            </div>
            <div className="flex items-center justify-center mb-2 ps-2">
              <CloseIcon
                className="w-3 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  setDeletedItem(+record.id)
                  setShowDelete(true)
                }}
              />
            </div>
          </div>
        )
      })

      return (
        <div key={listId} className="mb-4">
          <NavLink
            className="sticky z-20 block py-2 text-gray-800 bg-white line-clamp-2 -top-6 shadow-bottom-only-sm hover:text-primary"
            rel="noreferrer"
            target="_blank"
            to={listUrl}>
            {groupedByList[listId][0]?.recordData.list.name}
          </NavLink>
          <div className="p-2 pt-3 bg-gray-100 rounded-b-lg">{recordsToDisplay}</div>
        </div>
      )
    })
  }

  return (
    <div className="w-full p-2">
      {isEmptyList && <EmptyLinkedRecords />}
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader />
        </div>
      ) : (
        renderLinkedRecords()
      )}
      <ConfirmationModal
        cancelMessage={t('common:labels.cancel')}
        confirmMessage={t('common:labels.remove')}
        confirmationMessage={t('documentation:confirmDeleteRecordLinkMessage')}
        isModalOpen={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          dispatch(deleteElementLink(deletedItem)).then(() => {
            setShowDelete(false)
            setDeletedItem(null)
          })
        }}
      />
    </div>
  )
}

export default LinkedRecordsList
