import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import Button, { ButtonVariant } from 'components/Buttons'
import { Checkbox } from '../inputs'

type ConfirmationModalPropsType = {
  onCancel: () => void,
  onConfirm: () => void,
  confirmationMessage?: string,
  confirmationDescription?: string,
  isModalOpen: boolean,
  buttonVariant?: string,
  cancelMessage?: string,
  confirmMessage?: string,
  checkBoxMessage?: string,
  checkBox?: boolean,
  isChecked?: boolean,
  onChangeCheckBox?: () => void,
}

const ConfirmationModal: FC<ConfirmationModalPropsType> = ({
  onCancel,
  onConfirm,
  confirmationMessage,
  confirmationDescription,
  isModalOpen,
  buttonVariant,
  cancelMessage,
  confirmMessage,
  checkBoxMessage,
  isChecked,
  onChangeCheckBox,
}) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      className="fixed max-h-screen p-6 overflow-auto bg-white rounded shadow top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 focus:outline-none"
      isOpen={isModalOpen}
      overlayClassName="bg-gray-800/50 fixed inset-0 z-50"
      onRequestClose={onCancel}>
      <h1 className="text-center">{confirmationMessage}</h1>
      {confirmationDescription && <div className="mt-2">{confirmationDescription}</div>}
      {checkBoxMessage && (
        <div className="mt-5 flex items-center">
          <Checkbox
            checked={isChecked}
            isFullRoundedBorder={true}
            name={checkBoxMessage}
            label={checkBoxMessage}
            onChange={(e) => {
              e.stopPropagation()
              onChangeCheckBox()
            }}
          />
        </div>
      )}
      <div className="flex justify-end pt-6">
        <Button className="me-4" variant={ButtonVariant.Ghost} onClick={onCancel}>
          {cancelMessage || t('labels.cancel')}
        </Button>
        <Button variant={buttonVariant !== undefined ? buttonVariant : ButtonVariant.Danger} onClick={onConfirm}>
          {confirmMessage || t('labels.remove')}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
