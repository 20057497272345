import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyStationsIcon } from '../../../Icons'

const EmptyLinkedRecords = () => {
  const { t } = useTranslation('documentation')
  return (
    <div className="w-full text-center py-28">
      <EmptyStationsIcon
        className="flex items-center justify-center w-1/3"
        style={{ margin: '0 33.33% 20px 33.33%' }}
      />
      <h3 className="pb-3 font-bold">{t('noRecordsFound')}</h3>
      <p>{t('noRecordsFoundDes')}</p>
    </div>
  )
}

export default EmptyLinkedRecords
