import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddIcon, DragDropIcon, TrashIcon } from 'components/Icons'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { useAppDispatch } from 'store'
import {
  createStationDocsOneDocAttachment,
  deleteStationDocsOneDocAttachment,
  getStationDocsOneDocAttachment,
  useStationDocumentationAttachments,
  useStationDocumentationAttachmentsLoading,
} from 'features/station'
import { toast } from 'react-toastify'
import Loader from 'components/Loader'
import { format } from 'date-fns'
import { getAttachmentProperties } from 'utils/helpers/attachmentHelpers'
import SingleAttachment from 'components/AttachmentList/SingleAttachment'
import { AttachmentType } from 'utils/types'

const MAX_FILE_SIZE = 100 * 1024 * 1024
const DATE_FORMAT = 'd/M/yyyy'

const directoryValidator = (file: any) => {
  if (file.path.startsWith('/'))
    return {
      code: 'directory-upload',
      message: "You can't upload directory",
    }
  return null
}

const DocumentationDocumentAttachments: FC<{ documentId: string }> = ({ documentId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [localFiles, setLocalFiles] = useState<any[]>([])
  const documentAttachments = useStationDocumentationAttachments()
  const attachmentsLoading = useStationDocumentationAttachmentsLoading()

  const onDrop = useCallback(
    (filesToUpload) => {
      setLocalFiles(filesToUpload)
      dispatch(createStationDocsOneDocAttachment({ files: filesToUpload, documentId }))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(getStationDocsOneDocAttachment(documentId))
  }, [])

  useEffect(() => {
    if (!attachmentsLoading) {
      setLocalFiles([])
    }
  }, [attachmentsLoading])

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach(({ code, message }) => {
          switch (code) {
            case ErrorCode.FileTooLarge:
              return toast.error(t('records:attachmentTooBig', { name: file.name }), { toastId: file.name })
            case 'directory-upload':
              return toast.error(t('records:folderUploadError'), { toastId: 'folderUploadError' })
            default:
              return toast.error(message)
          }
        })
      })
    },
    [t],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    noClick: true,
    validator: directoryValidator,
    onDropRejected,
  })

  const detailsClasses = 'absolute bottom-0 w-full p-1 text-xs text-gray-700 bg-white/80 leading-3'

  return (
    <div className="h-full relative" {...getRootProps()}>
      <div className="flex justify-between items-center">
        <h2 className="font-semibold">{t('records:listing.attachments')}</h2>
        <input id="attachment" {...getInputProps()} />
        <label
          className="block px-2 cursor-pointer md:-mr-3 hover:text-primary transition transition-colors"
          htmlFor="attachment">
          <AddIcon className="w-6" />
        </label>
      </div>
      <div className="flex flex-wrap gap-2 mt-6">
        {attachmentsLoading &&
          localFiles.map((file, idx) => {
            const { fileExtension, fileSizeInKb, fileSizeInMb, fileName } = getAttachmentProperties(file)
            return (
              <div
                style={{
                  width: 'calc(50% - 4px)',
                }}
                key={idx + file.name}>
                <div className="flex items-center justify-center relative overflow-hidden bg-gray-200 rounded h-28">
                  <Loader loaderClasses="mb-8" />
                  <div className={detailsClasses}>
                    <div className="flex mb-1 font-bold">
                      <span className="truncate">{fileName}</span>.{fileExtension}
                    </div>
                    <div className="flex justify-between">
                      <span className="truncate" dir="ltr">
                        {format(new Date(), 'd/M/yyyy')}
                      </span>
                      <span>{fileSizeInMb ? `${fileSizeInMb}MB` : `${fileSizeInKb}KB`}</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        {documentAttachments &&
          documentAttachments.map((attachment: AttachmentType) => (
            <SingleAttachment key={attachment.id} attachment={attachment} isEditor={true} />
          ))}
      </div>

      {isDragActive && (
        <div className="absolute inset-0 z-10 bg-white/80 rounded pointer-events-none rounded-ts-none backdrop-filter backdrop-blur-sm">
          <div className="flex flex-col items-center justify-center flex-1 h-full border-2 border-dashed rounded rounded-ts-none border-primary">
            <DragDropIcon className="w-32 mb-6" />
            <h2 className="font-bold text-center text-gray-700">{t('records:drop.header')}</h2>
            <p className="mt-2 mb-6 text-sm text-center w-80">{t('records:drop.description')}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(DocumentationDocumentAttachments)
