import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import ar from 'react-phone-number-input/locale/ar.json'
import baseAPI from 'utils/baseAPI'

import Button, { ButtonVariant } from 'components/Buttons'
import Loader from 'components/Loader'
import { handleAuthResponse } from 'utils/helpers/authHelpers'

const PhoneNumberForm = () => {
  const { t, i18n } = useTranslation('signIn')
  const history = useHistory()
  const location = useLocation()
  const { token } = useParams<{ token: string }>()
  const [number, setNumber] = useState<string | undefined>('')
  const [isLoading, setLoading] = useState(false)
  const [touched, setTouched] = useState(false)

  const validatePhoneNumber = (number: string | undefined): { error: boolean, message: string } => {
    if (number === undefined || number === '')
      return {
        error: true,
        message: t('validation:errors.requiredField', { name: t('validation:fieldNames.phoneNumber') }),
      }

    if (!isValidPhoneNumber(number))
      return {
        error: true,
        message: t('validation:errors.phoneNumberInvalid'),
      }

    return {
      error: false,
      message: '',
    }
  }

  const { error, message } = validatePhoneNumber(number)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      setTouched(true)

      const response = await baseAPI('auth/addSmsMfa', {
        body: {
          token,
          phoneNumber: number,
        },
        method: 'POST',
      })

      handleAuthResponse({ response, setLoading, history, location, isSettingNewPhone: true })
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="mb-3" dir="ltr">
        <PhoneInput
          countrySelectProps={{ unicodeFlags: true }}
          defaultCountry="SA"
          international
          labels={i18n.language === 'ar' ? ar : undefined}
          placeholder="+9665XXXXXXXX"
          value={number}
          onChange={(newNumber) => setNumber(newNumber)}
          onBlur={() => setTouched(true)}
          className="bg-white border-2 border-gray-300 p-3 rounded transition focus-within:border-primary"
        />
        <p className="text-sm text-danger h-5 mt-2">{error && touched && message}</p>
      </div>
      <Button disabled={isLoading || error} full onClick={handleSubmit}>
        {t('common:labels.save')}
        {isLoading ? <Loader /> : null}
      </Button>
      <Button variant={ButtonVariant.Ghost} full className="mt-2" onClick={() => history.push('/sign-in')}>
        {t('signIn:goBackToSignIn')}
      </Button>
    </>
  )
}

export default PhoneNumberForm
