import { useState, RefObject, useCallback, useLayoutEffect } from 'react'
import useEventListener from 'hooks/useEventListener'

interface Size {
  width: number;
  height: number;
}

function useElementSize(elementRef: RefObject<any> | null): Size {
  const [size, setSize] = useState<Size>({
    height: 0,
    width: 0,
  })

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    const node = elementRef?.current
    if (node) {
      setSize({
        height: node.offsetHeight || 0,
        width: node.offsetWidth || 0,
      })
    }
  }, [elementRef])

  useEventListener('resize', handleSize)

  // Initial size on mount
  useLayoutEffect(() => {
    handleSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return size
}

export default useElementSize
