import React, { FC, ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomFieldsListMessage } from 'utils/types/customFieldsType'
import { useDispatch } from 'react-redux'
import { CheckmarkIcon, CloseIcon, InfoIcon } from '../../Icons'
import Input from '../../inputs/Input'
import { setFormulaList } from '../../../features/element/elementSlice'
import { useFormulaList } from '../../../features/element'
import { FormulaType } from '../../../utils/types/FormulaType'
import { withButtonsAndContentEditable } from '../../../hocs'
import {
  formulaNameValidationSchema,
  formulaValidationSchema,
} from '../../forms/validationSchemas/addFormulaValidation'
import withContentEditable from '../../../hocs/withContentEditable'
import Button, { ButtonVariant } from '../../Buttons'
import FormulaInput from './FormulaInput'

const EditableFormulaInput = withButtonsAndContentEditable(FormulaInput)
const EditableTitleInput = withContentEditable(Input)

interface FormulaItemPropsType {
  item: any;
  key: number;
  index: number;
  setIsDescriptionChange: (item: boolean) => void;
  isDescriptionChange: boolean;
  customFields: CustomFieldsListMessage;
}

const FormulaItem: FC<FormulaItemPropsType> = ({
  item,
  key,
  index,
  setIsDescriptionChange,
  isDescriptionChange,
  customFields,
}) => {
  const formulaRef = useRef(null)
  const elementRef = useRef(null)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('customFields')
  const isRtl = i18n.language === 'ar'
  const formulaList = useFormulaList()
  const [isTitleEditMode, setIsTitleEditMode] = useState<boolean>(false)
  const [name, setName] = useState<string>(item?.name || '')
  const [formulaInstructionTooltip, setFormulaInstructionTooltip] = useState<boolean>(false)
  const [formula, setFormula] = useState<string>(item?.formula || '')
  const [isFormulaStartEditing, setIsFormulaStartEditing] = useState<boolean>(false)

  const renderEditTitle = () => {
    return (
      <div>
        <EditableTitleInput
          autoFocus={isTitleEditMode}
          className={'mb-1 -px-2'}
          classes={{ wrapper: 'px-1 text-sm mb-2' }}
          name="formulaName"
          notEditingClassName={'mx-1 mb-2'}
          placeholder={t('labels.customFieldFormulaOption')}
          type="text"
          validationSchema={formulaNameValidationSchema}
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          onSubmit={(event: any) => {
            const newFormulaList = [...formulaList]
            newFormulaList[index] = { ...item, name }
            dispatch(setFormulaList(newFormulaList))
            setIsTitleEditMode(false)
          }}
        />
      </div>
    )
  }

  const renderDelete = () => {
    return (
      <div className="flex justify-end rounded-sm cursor-pointer ms-3 hover:bg-warning hover:text-white">
        <CloseIcon
          className="w-4"
          onClick={(e) => {
            e.stopPropagation()
            setIsDescriptionChange(false)
            const newList = [...formulaList]?.filter((i: FormulaType, idx: number) => idx !== index)
            const firstItem = [...newList][0]
            if (item.isDefault && formulaList?.length > 1) {
              const updatedList = [{ ...firstItem, isDefault: true }].concat(newList.slice(1))
              dispatch(setFormulaList(updatedList))
            } else {
              dispatch(setFormulaList(newList))
            }
          }}
        />
      </div>
    )
  }

  const renderMakeAsDefault = () => {
    return (
      <div
        className="text-xs cursor-pointer text-primary hover:text-primary-dark"
        onClick={(e) => {
          setIsDescriptionChange(false)
          e.stopPropagation()
          const newFormulaList = [...formulaList]
          const newFormulaList2 = newFormulaList.map((i, idx) => {
            if (idx === index) {
              return { ...i, isDefault: true }
            }
            return { ...i, isDefault: false }
          })
          dispatch(setFormulaList(newFormulaList2))
        }}>
        {t('labels.makeAsDefault')}
      </div>
    )
  }

  const renderDefault = () => {
    return (
      <p
        className="flex text-xs text-primary hover:text-primary-dark"
        onClick={(e) => {
          e.stopPropagation()
        }}>
        {t('labels.default')}{' '}
        <span>
          <CheckmarkIcon className="w-3 ms-1" />
        </span>
      </p>
    )
  }
  const isValidFormula = formulaValidationSchema(formula) && formula.length > 0
  const TooltipBox = ({ children }: { children: ReactNode }) => (
    <div className="px-4 py-2 my-1 mb-3 bg-gray-200 rounded text-small">{children}</div>
  )
  return (
    <div key={key} className={`flex p-1 my-1 w-full`} dir={'ltr'}>
      <div style={{ width: 'inherit' }}>
        <div
          onClick={() => {
            setIsTitleEditMode(true)
            const newFormulaList = [...formulaList]
            newFormulaList[index] = { ...item, name }
            dispatch(setFormulaList(newFormulaList))
          }}>
          {!isTitleEditMode ? (
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <h5 className="text-gray-600">{name}</h5>
                <Button
                  className="text-gray-500"
                  icon={InfoIcon}
                  small
                  variant={ButtonVariant.Icon}
                  onClick={(e) => {
                    e.stopPropagation()
                    setFormulaInstructionTooltip(!formulaInstructionTooltip)
                  }}
                />
              </div>
              <div className="flex">
                {item?.isDefault ? renderDefault() : renderMakeAsDefault()}
                {!isDescriptionChange && renderDelete()}
              </div>
            </div>
          ) : (
            renderEditTitle()
          )}
        </div>
        {formulaInstructionTooltip && (
          <TooltipBox>
            <div className="list-disc list-inside" dir={isRtl ? 'rtl' : 'ltr'}>
              <li>{t('labels.customFieldOwner')}</li>
              <li className="mt-0.5">{t('labels.customFieldDate')}</li>
              <li className="mt-0.5">{t('labels.customFieldHealth')}</li>
              <li className="mt-0.5">{t('labels.customFieldPriority')}</li>
              <li className="mt-0.5">{t('labels.customFieldStatus')}</li>
              <li className="mt-0.5">{t('labels.customFieldCompletion')}</li>
              <li className="mt-0.5">{t('labels.customFieldText')}</li>
              <li className="mt-0.5">{t('labels.customFieldCheckbox')}</li>
            </div>
          </TooltipBox>
        )}
        <div id={`formula-root${index}`} ref={elementRef}>
          <EditableFormulaInput
            className="w-full mb-1 text-sm border-2 rounded"
            customFields={customFields}
            index={index}
            inputWrapperClassName="text-sm"
            isDescriptionChange={isDescriptionChange}
            isOnClickDisabled={false}
            name="formula"
            notEditingClassName="text-start custom-field__input me-0.5 ms-0 mb-1 "
            placeholder={t('labels.customFieldFormulaDescription')}
            ref={formulaRef}
            rows={2}
            setIsDescriptionChange={setIsDescriptionChange}
            shouldShowPlaceholder
            value={formula}
            onCancel={() => {
              formulaRef?.current?.submitField()
              setIsDescriptionChange(false)
            }}
            onChange={(e) => {
              setIsDescriptionChange(true)
              setIsFormulaStartEditing(true)
              setFormula(e.target.value)
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                const newFormulaList = [...formulaList]
                newFormulaList[index] = { ...item, formula }
                if (isValidFormula) {
                  setIsFormulaStartEditing(false)
                  dispatch(setFormulaList(newFormulaList))
                  setIsDescriptionChange(false)
                  formulaRef?.current?.submitField()
                }
              }
            }}
            onSubmit={(values, actions) => {
              const newFormulaList = [...formulaList]
              newFormulaList[index] = { ...item, formula }
              if (isValidFormula) {
                setIsFormulaStartEditing(false)
                dispatch(setFormulaList(newFormulaList))
                setIsDescriptionChange(false)
                actions.resetForm()
              }
            }}
          />
          {!isValidFormula && isFormulaStartEditing && (
            <p className="text-sm text-danger">{t('formulaErrorMessage')}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormulaItem
